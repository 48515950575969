import React from 'react';
import HeaderActions from './HeaderActions';

class ScreenHeaderActions extends React.Component {
  render() {
    const { state: { views } = {}, eventDispatcher } = this.props;
    const currentViewInfo = views[views.length - 1];
    const { routeName, ...restInfo } = currentViewInfo || {};
    return (
      <HeaderActions
        screenName={routeName}
        eventDispatcher={eventDispatcher}
        {...restInfo}
      />
    );
  }
}

export default ScreenHeaderActions;
