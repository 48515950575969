import { FormHoc } from '../../../app-components';
import { salaryHeartIcon } from '../../../images';
import { CompensationFormLayouts } from './commonLayouts';

export const EmployeeCompensationHoc = ({ basicDetails, ...rest }) => FormHoc({
  closeView: 1,
  ...rest,
  formGroups: [
    {
      label: 'salaryCompensationTemplate.labels.basicDetails'.getLabel(),
      icon: salaryHeartIcon,
      groups: [basicDetails],
    },
    {
      icon: salaryHeartIcon,
      label: 'salaryCompensationTemplate.labels.earning'.getLabel(),
      expandable: true,
      columns: [CompensationFormLayouts.EarningBaseComponent],
    },
    // {
    //   icon: salaryHeartIcon,
    //   expandable: true,
    //   label: 'salaryCompensationTemplate.labels.performance'.getLabel(),
    //   columns: [CompensationFormLayouts.PerformanceBaseComponent1],
    // },
    {
      icon: salaryHeartIcon,
      expandable: true,
      label: 'salaryCompensationTemplate.labels.deduction'.getLabel(),
      columns: [CompensationFormLayouts.DeductionComponent],
    },
    // {
    //   icon: salaryHeartIcon,
    //   expandable: true,
    //   label: 'salaryCompensationTemplate.labels.bonus'.getLabel(),
    //   columns: [CompensationFormLayouts.BonusComponent],
    // },
  ],
});

