import React, { useCallback, useEffect, useState } from 'react';

import { psychologicalTreatmentListUri } from '../../../../../../../../Queries/psychologicalTreatment';
import { Form } from '../../../../../../../../app-components/form/Form';
import { fetch, getUser } from '../../../../../../../../AppServices';
import { MODELS } from '../../../../../../../../Lib/constants';
import ListActionModal from '../../../../../../../../Components/ListActionModal';

const ViewLogTreatment = (props) => {
  const { navigation } = props;
  const user = getUser();
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();
  const fetchEmployeeDetails = useCallback(async () => {
    const fetchedItemSuggestionDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'employeeListsForTable',
            addOnFilter: {
              _id: user?.employee?._id,
            },
          },
          model: MODELS.EMPLOYEE,
        },
      },
    });

    setEmployeeId(fetchedItemSuggestionDetails?.data?.[0]?.employeeId);
    setEmployeeName(`${fetchedItemSuggestionDetails?.data?.[0]?.firstName
    } ${fetchedItemSuggestionDetails?.data?.[0]?.lastName}`);
    return fetchedItemSuggestionDetails;
  }, [user]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, [fetchEmployeeDetails]);

  return (
    <ListActionModal
      {...props}
      title="Psychological Treatment"
      description="Logged Psychological treatment of patient."
      content={(
        <Form
          uri={psychologicalTreatmentListUri({ navigation })}
          type="standardShadow"
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'text',
                        render: () => employeeId,
                        label: 'Employee ID',
                        editable: false,
                      },
                      {
                        type: 'text',
                        label: 'Employee Name',
                        render: () => employeeName,
                        editable: false,
                      },
                      {
                        type: 'date',
                        field: 'loggedDate',
                        label: 'Date',
                        format: 'DD.MM.YYYY',
                        editable: false,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'treatment',
                        label: 'Treatment',
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const ViewTreatment = (props) => (<ViewLogTreatment {...props} />);

export default ViewTreatment;
