
import React from 'react';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';
import vars from '../../../theme/vars';
import tabTheme from '../../../theme/childTabTheme';

import Hold from './Hold';
import Released from './Released';
import ToBeReleased from './ToBeReleased';
import ApprovedSalary from './Approved';
import { FilterGroup } from '../../../app-components';
import { filterActive, filterInactive } from '../../../images';
import { MODELS } from '../../../Lib/constants';
import { searchFilter } from '../../../autoSuggestions';

const SalaryTabNavigation = (props) => (
  <TabNavigator
    theme={{
      ...tabTheme,
      tabItemStyle: {
        ...tabTheme.tabItemStyle,
        containerStyle: {
          cursor: 'pointer',
          // width: '200',
          marginTop: 3,
          marginBottom: 11,
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: 'auto',
          height: 40,
          backgroundColor: vars.colors.secondary.color4,
          paddingLeft: 30,
        },
      },
    }}
    actions={[]}
    tabs={{
      Approved: {
        reloadEvent: `reload${MODELS.MONTHLY_ATTENDANCE}`,
        label: 'Approved',
        screen: ApprovedSalary,
        actions: [
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'month_id',
                label: 'Month',
                model: MODELS.MONTHS,
                id: 'MonthTypeSuggestions',
                searchField: 'name',
                placeholder: 'Months',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              {
                type: 'autoSuggest',
                field: 'finantial_year_id',
                label: 'Financial Year',
                model: MODELS.FINANCIAL_YEAR,
                id: 'financialyears',
                searchField: 'name',
                placeholder: 'Year',
                sugestionField: 'financial_year',
                primaryField: 'financial_year',
                valueField: 'financial_year',
              },
              searchFilter({
                field: 'employee.department',
                model: MODELS.DEPARTMENTS,
                id: 'masterDataList',
                searchField: 'name',
                label: 'Department',
                suggestionField: 'name',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              }),
              {
                type: 'autoSuggest',
                field: 'speciality',
                label: 'Speciality',
                model: MODELS.WARDS,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select specialities',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
              searchFilter({
                field: 'employee_id',
                model: MODELS.EMPLOYEE,
                id: 'employeeLists',
                searchField: 'firstName',
                label: 'Employee',
                suggestionField: 'fullName',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              },
              {
                modifyResult: ({ data }) => {
                  const clonedData = data.map((item) => ({
                    ...item,
                    fullName: `${item?.firstName} ${item?.lastName}`,
                  }));
                  return { data: clonedData };
                },
              }),
            ],
          }),
        ],
      },
      'To Be Released': {
        reloadEvent: 'selfMonthlyAttendances',
        label: 'To Be Released',
        screen: ToBeReleased,
        actions: [
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'month_id',
                label: 'Month',
                model: MODELS.MONTHS,
                id: 'MonthTypeSuggestions',
                searchField: 'name',
                placeholder: 'Months',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              {
                type: 'autoSuggest',
                field: 'finantial_year_id',
                label: 'Financial Year',
                model: MODELS.FINANCIAL_YEAR,
                id: 'financialyears',
                searchField: 'name',
                placeholder: 'Year',
                sugestionField: 'financial_year',
                primaryField: 'financial_year',
                valueField: 'financial_year',
              },
              searchFilter({
                field: 'employee.department',
                model: MODELS.DEPARTMENTS,
                id: 'masterDataList',
                searchField: 'name',
                label: 'Department',
                suggestionField: 'name',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              }),
              {
                type: 'autoSuggest',
                field: 'speciality',
                label: 'Speciality',
                model: MODELS.WARDS,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select specialities',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
              searchFilter({
                field: 'employee_id',
                model: MODELS.EMPLOYEE,
                id: 'employeeLists',
                searchField: 'firstName',
                label: 'Employee',
                suggestionField: 'fullName',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              },
              {
                modifyResult: ({ data }) => {
                  const clonedData = data.map((item) => ({
                    ...item,
                    fullName: `${item?.firstName} ${item?.lastName}`,
                  }));
                  return { data: clonedData };
                },
              }),
            ],
          }),
        ],
      },
      Hold: {
        reloadEvent: 'selfMonthlyAttendances',
        label: 'Hold',
        screen: Hold,
        actions: [
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'month_id',
                label: 'Month',
                model: MODELS.MONTHS,
                id: 'MonthTypeSuggestions',
                searchField: 'name',
                placeholder: 'Months',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              {
                type: 'autoSuggest',
                field: 'finantial_year_id',
                label: 'Financial Year',
                model: MODELS.FINANCIAL_YEAR,
                id: 'financialyears',
                searchField: 'name',
                placeholder: 'Year',
                sugestionField: 'financial_year',
                primaryField: 'financial_year',
                valueField: 'financial_year',
              },
              searchFilter({
                field: 'employee.department',
                model: MODELS.DEPARTMENTS,
                id: 'masterDataList',
                searchField: 'name',
                label: 'Department',
                suggestionField: 'name',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              }),
              {
                type: 'autoSuggest',
                field: 'speciality',
                label: 'Speciality',
                model: MODELS.WARDS,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select specialities',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
              searchFilter({
                field: 'employee_id',
                model: MODELS.EMPLOYEE,
                id: 'employeeLists',
                searchField: 'firstName',
                label: 'Employee',
                suggestionField: 'fullName',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              },
              {
                modifyResult: ({ data }) => {
                  const clonedData = data.map((item) => ({
                    ...item,
                    fullName: `${item?.firstName} ${item?.lastName}`,
                  }));
                  return { data: clonedData };
                },
              }),
            ],
          }),
        ],
      },
      Released: {
        reloadEvent: 'selfMonthlyAttendances',
        label: 'Released',
        screen: Released,
        actions: [
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'releaseMonth',
                label: 'Month',
                model: MODELS.MONTHS,
                id: 'MonthTypeSuggestions',
                searchField: 'name',
                placeholder: 'Months',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              {
                type: 'autoSuggest',
                field: 'releaseYear',
                label: 'Financial Year',
                model: MODELS.FINANCIAL_YEAR,
                id: 'financialyears',
                searchField: 'name',
                placeholder: 'Year',
                sugestionField: 'financial_year',
                primaryField: 'financial_year',
                valueField: 'financial_year',
              },
              searchFilter({
                field: 'employee.department',
                model: MODELS.DEPARTMENTS,
                id: 'masterDataList',
                searchField: 'name',
                label: 'Department',
                suggestionField: 'name',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              }),
              {
                type: 'autoSuggest',
                field: 'speciality',
                label: 'Speciality',
                model: MODELS.WARDS,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select specialities',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
              searchFilter({
                field: 'employee_id',
                model: MODELS.EMPLOYEE,
                id: 'employeeLists',
                searchField: 'firstName',
                label: 'Employee',
                suggestionField: 'fullName',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              },
              {
                modifyResult: ({ data }) => {
                  const clonedData = data.map((item) => ({
                    ...item,
                    fullName: `${item?.firstName} ${item?.lastName}`,
                  }));
                  return { data: clonedData };
                },
              }),
            ],
          }),
        ],
      },
    }}
    {...props}
  />
);
export default SalaryTabNavigation;
