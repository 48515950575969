import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import {
  validateAlphabetsWithSpaceOnly,
  validateEmail,
  validateInputLength,
  validateMobile,
} from '../../../../Lib/helpers';
import { editCompanyDetails } from '../../../../Queries/bloodBank';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'companyManagement.label.companyName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
  },
  {
    type: 'text',
    field: 'email',
    label: 'companyManagement.label.email'.getLabel(),
    mandatory: true,
  },
  {
    type: 'number',
    field: 'contactNo',
    label: 'companyManagement.label.contactNo'.getLabel(),
    mandatory: true,
  },

  {
    type: 'textArea',
    field: 'address',
    label: 'companyManagement.label.address'.getLabel(),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'companyManagement.label.description'.getLabel(),
    minHeight: 75,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'companyManagement.label.status'.getLabel(),
  },
];

const validations = {
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
  contactNo: ({ data }) => validateMobile(data?.contactNo),
  email: ({ data }) => validateEmail(data?.email),
};

export default MasterForm({
  uri: editCompanyDetails,
  model: MODELS.COMPANY_MANAGEMENT,
  formFields,
  reloadEvent: `reload${MODELS.COMPANY_MANAGEMENT}`,
  validations,
  submitMessage: 'companyManagement.message.updateMessage'.getLabel(),
  mandatory: {
    name: 1,
    contactNo: 1,
    email: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
