/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { Text, Toast, View } from '../../../app-components';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { submit } from '../../../AppServices';
import { validateAlphabetsWithSpaceOnly } from '../../../Lib/helpers';
import vars from '../../../theme/vars';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../app-components/form/Form';
import { dateInput } from '../../../compositeInputs';

const validateUnknownPatientAdmissionTime = ({ data }) => {
  const { hour = 0, minute = 0, meridiem = 'AM' } = data;
  const format = 'hh:mm:ss';
  const date = moment().startOf('day');

  const time = moment();
  const beforeTime = moment('00:00:00', format);
  const afterTime = moment('00:30:00', format);

  if (meridiem === 'AM' && hour === 12) {
    date.add('hours', 0);
  } else if (meridiem === 'AM') {
    date.add('hours', hour);
  } else if (hour === 12 && meridiem === 'PM') {
    date.add('hours', hour);
  } else {
    date.add('hours', hour + 12);
  }
  date.add('minutes', minute);

  if (time.isBetween(beforeTime, afterTime)) {
    if (meridiem === 'PM') {
      date.subtract('day', 1);
    }
  }

  if (date > moment().add('minutes', 30) || date < moment().subtract('minutes', 30)) {
    return 'Patient should be registered within 30 mins.';
  }

  return false;
};

const UnknownAddPatientForm = (props) => {
  const [serialNo, setSerialNo] = useState(1);
  const { navigation } = props;

  const checkSaveAndFillLater = useCallback(async (props) => {
    const { data } = props;
    const { action: { id } } = props;
    if (id === 'Save') {
      if (data?.patientName && data?.speciality) {
        const submitAPI = submit({
          model: MODELS.PATIENTS,
          ...props,
          data,
        });
        const submitResult = await submitAPI({ data, ...props });
        Toast.show({
          message: 'Success',
          description: 'Patient has been added successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.replace(ROUTES.viewUnknownPatientIPD.name,
          {
            _id: submitResult?.result?._id,
            openAdmitForm: true,
            patient: submitResult?.result,
          });
        return {};
      }
      if (!data?.patientName) {
        Toast.show({
          message: 'Error',
          description: 'Patient is Mandatory while saving data.',
          type: 'error',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        return {};
      }
      if (!data?.speciality?.name) {
        Toast.show({
          message: 'Error',
          description: 'Speciality is Mandatory while saving data.',
          type: 'error',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        return {};
      }
    } else {
      const submitAPI = submit({
        model: MODELS.PATIENTS,
        ...props,
        data,
      });
      const submitResult = await submitAPI({ data, ...props });
      Toast.show({
        message: 'Success',
        description: 'Patient has been added successfully.',
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      navigation.replace(ROUTES.viewUnknownPatientIPD.name,
        {
          _id: submitResult?.result?._id,
          openAdmitForm: true,
          patient: submitResult?.result,
        });
    }
    return {};
  }, [navigation]);

  return (
    <Form
      type="standard"
      validateAllOnChange
      {...props}
      onSubmit={checkSaveAndFillLater}
      defaultValues={{
        department: 'IPD',
        isUnknown: true,
        ageType: 'Years',
        relation: 'S/O',
        gender: 'Male',
        hour: new Date().getHours() >= 12 ? (new Date().getHours() - 12) : new Date().getHours(),
        minute: new Date().getMinutes(),
        meridiem: new Date().getHours() >= 12 ? 'PM' : 'AM',
        date: moment().format('DD/MM/YYYY'),
      }}
      mandatory={{
        belongingsDetails: {
          itemName: 1,
        },
        hour: 1,
        minute: 1,
      }}
      validations={{
        hour: validateUnknownPatientAdmissionTime,
        minute: validateUnknownPatientAdmissionTime,
        meridiem: validateUnknownPatientAdmissionTime,
      }}
      computations={{
        self: {
          autoFill: {
            compute: (data) => {
              const todayStartDate = moment().startOf('day');

              const { hour = 0, minute = 0, meridiem = 'AM' } = data;
              const format = 'hh:mm:ss';
              const date = moment().startOf('day');

              const time = moment();
              const beforeTime = moment('00:00:00', format);
              const afterTime = moment('00:30:00', format);

              if (meridiem === 'AM' && hour === 12) {
                date.add('hours', 0);
              } else if (meridiem === 'AM') {
                date.add('hours', hour);
              } else if (hour === 12 && meridiem === 'PM') {
                date.add('hours', hour);
              } else {
                date.add('hours', hour + 12);
              }
              date.add('minutes', minute);

              if (time.isBetween(beforeTime, afterTime)) {
                if (meridiem === 'PM') {
                  date.subtract('day', 1);
                }
              }

              return { set: { date: todayStartDate.format('DD MMM YYYY') } };
            },
            onChange: ['hour', 'minute', 'meridiem'],
          },
        },
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 1,
                width: 300,
                columns: [
                  {
                    type: 'uploadImage',
                    field: 'patientImage',
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'date',
                    label: 'patient.labels.date'.getLabel(),
                    editable: false,
                  },
                  dateInput,
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'patient.labels.patientName'.getLabel(),
                    allowWhiteSpaces: true,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (validateAlphabetsWithSpaceOnly(value)) {
                        return prevValue;
                      }
                      return value.replace(/ {2,}/g, ' ');
                    },
                  },
                  autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'patient.labels.department'.getLabel(),
                      placeholder: 'patient.placeholders.selectDepartment'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      editable: false,
                    },
                    {
                      addOnFilter: {
                        code: 'IPD',
                      },
                    },
                  ),
                  searchInput(
                    {
                      field: 'speciality',
                      label: 'patient.labels.speciality'.getLabel(),
                      placeholder: 'patient.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      searchField: 'name',
                      searching: true,
                    },
                    {
                      addOnFilter: { department: { code: 'IPD' } },
                    },
                  ),
                  {
                    type: 'textArea',
                    field: 'uniqueMark',
                    label: 'patient.labels.uniqueMark'.getLabel(),
                    placeholder: 'patient.placeholders.uniqueMark'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'remarks',
                    label: 'patient.labels.remarks'.getLabel(),
                    placeholder: 'patient.placeholders.remarks'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            label: 'patient.title.belongingsDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  autoSuggestInput({
                    field: 'belongingsCareBy',
                    type: 'autoSuggest',
                    variant: 'filled',
                    suggestionField: 'fullName',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    placeholder: 'patient.placeholders.belongingsCareBy'.getLabel(),
                    searching: true,
                    searchField: 'firstName',
                  },
                  {
                    modifyResult: ({ data }) => {
                      const newData = data.map((item) => ({
                        ...item,
                        fullName: `${item.firstName} ${item.lastName}`,
                      }));
                      return { data: newData };
                    },
                  }),
                  {
                    type: 'textArea',
                    field: 'additionalComments',
                    variant: 'filled',
                    header: 'patient.labels.additionalComments'.getLabel(),
                    placeholder: 'patient.placeholders.additionalComments'.getLabel(),
                    style: { flex: 2 },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'belongingsDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: (props) => {
                        const { data: { belongingsDetails = [] } } = props;
                        setSerialNo(belongingsDetails?.length);
                        if (serialNo <= belongingsDetails?.length) {
                          belongingsDetails.map((name, index) => name.serialNo = index + 1);
                        }
                        return {
                          leftActions: [{
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                              >
                                <Text
                                  style={{
                                    ...vars.headings.h9,
                                    color: vars.colors.grey.color3,
                                  }}
                                >
                                  {`+ ${'inventory.labels.addRow'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: props?.addRow,
                          }],
                        };
                      },
                      skipTableHeaderOnNoData: false,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          backgroundColor: vars.colors.grey,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 12,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'vaccination.headers.serialNo'.getLabel(),
                            field: 'serialNo',
                            variant: 'filled',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            field: 'itemName',
                            variant: 'filled',
                            header: 'patient.labels.itemName'.getLabel(),
                            placeholder: 'patient.placeholders.itemName'.getLabel(),
                            allowWhiteSpaces: true,
                            width: 200,
                          },
                          {
                            type: 'textArea',
                            field: 'description',
                            variant: 'filled',
                            header: 'patient.labels.description'.getLabel(),
                            placeholder: 'patient.placeholders.description'.getLabel(),
                            width: 700,
                          },
                          {
                            type: 'file',
                            field: 'itemImage',
                            variant: 'filled',
                            header: 'patient.labels.itemImage'.getLabel(),
                            placeholder: 'patient.placeholders.itemImage'.getLabel(),
                            width: 150,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default UnknownAddPatientForm;
