import React, { useCallback, useEffect, useState } from 'react';

import {
  View, LargeButton, OutlineButton, Toast, ActivityIndicator, Text,
} from '../../../../../app-components';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { getErrorString } from '../../../../../Lib/helpers';
import { notificationError } from '../../../../../images';
import { fetch, post } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const EndSessionModalContent = (props) => {
  const { navigation } = props;
  const { navigation: { state: { params: { activeQueues = [] } = {} } } } = props;
  const { session: { _id } = {} } = activeQueues[0] || {};

  const updateSession = useCallback(async () => {
    try {
      const sessionDetails = await post({
        data: { _id },
        updates: { status: 'completed' },
        model: MODELS.SESSION,
      });
      Toast.show({
        message: 'Success',
        description: 'doctor.messages.sessionEndSuccessfully'.getLabel(),
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      navigation.replace({
        view: ROUTES.patientTokenGeneration.name,
      });
      return sessionDetails;
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });

      return err;
    }
  }, [_id, navigation]);

  return (
    <View style={{ flex: 1 }}>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={updateSession}
        defaultValues={{
          status: 'completed',
        }}
      >
        {({ form_context: formContext }) => (
          <View style={{ flexDirection: 'row', marginTop: 38, justifyContent: 'center' }}>
            <View style={{
              flexDirection: 'row', justifyContent: 'space-around', width: 300,
            }}
            >
              <View>
                <LargeButton
                  label="Cancel"
                  width={109}
                  onClick={() => {
                    navigation.pop();
                  }}
                />

              </View>
              <View>
                <OutlineButton
                  label="Yes"
                  width={109}
                  onClick={formContext?.handleSubmit}
                />
              </View>
            </View>
          </View>
        )}
      </Form>
    </View>
  );
};

const EndSessionModal = (props) => {
  const { navigation } = props;
  const { state: { params: { activeQueues = [] } } } = navigation;
  const { session: { _id } = {} } = activeQueues[0] || {};

  const [loading, setLoading] = useState();
  const [activeConsultationCount, setActiveConsultationCount] = useState(0);

  const fetchActiveConsultationsCount = useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'consultationDataList',
              addOnFilter: {
                session: { _id },
                status: { $nin: ['completed', 'not-appeared'] },
              },
            },
            model: MODELS.CONSULTATION,
            limit: 1,
          },
        },
      });
      if (result) {
        setActiveConsultationCount(result?.aggregates?._count);
      }
      setLoading(false);
      return result;
    } catch (err) {
      setLoading(false);
      return err;
    }
  }, [_id]);

  useEffect(() => {
    fetchActiveConsultationsCount();
  }, []);

  if (loading) return <ActivityIndicator />;

  const renderDescription = () => (
    <Text style={{
      display: 'block',
      ...vars.headings.h11,
      color: vars.neutrals.placeholder,
      textAlign: 'center',
    }}
    >
      {'doctor.title.endSessionSubWarning'.getLabel()}
      <span style={{ color: vars.colors.secondary.color2 }}>{activeConsultationCount || 0}</span>
      {(activeConsultationCount > 1) ? 'doctor.title.endSessionWarning'.getLabel() : 'doctor.title.endSessionCountWarning'.getLabel()}
    </Text>
  );
  return (
    <ListActionModal
      icon={notificationError}
      title={'doctor.title.endSession'.getLabel()}
      content={<EndSessionModalContent {...props} />}
      renderDescription={renderDescription}
      {...props}
    />
  );
};
export default EndSessionModal;
