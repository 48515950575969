import React from 'react';

import { save, Text, View } from '../../../../app-components';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../../theme/vars';
import { autoSuggestInput } from '../../../../autoSuggestions';

const LogGradePay = (props) => (
  <Form
    {...props}
    closeView={1}
    reloadEvent={`reload${MODELS.GRADE_PAY_LEVEL}`}
    mandatory={{
      name: 1,
      gradePay: 1,
    }}
    onSubmit={submit({
      model: MODELS.GRADE_PAY_LEVEL,
    })}
    submitMessage="Grade Pay Level had been saved successfully."
    type="standard"
    lg={{
      verticalFormGroups: [
        {

          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      label: 'Name',
                      field: 'name',
                      variant: 'filled',
                    },
                    {
                      type: 'number',
                      label: 'Grade Pay',
                      field: 'gradePay',
                      variant: 'filled',
                    },

                  ],
                },

              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);

export default LogGradePay;
