/* eslint-disable max-len */
import React from 'react';

import { Image, Text, View } from '../../app-components';
import {
  notificationError, confirmation, noStatus,
} from '../../images';
import { vaccinationStatus } from '../../Lib/constants';
import vars from '../../theme/vars';

const statusWiseData = {
  vaccinated: {
    icon: confirmation,
    label: 'Vaccinated',
  },
  'not-appeared': {
    icon: notificationError,
    label: 'Not Appeared',
  },
  'No status': {
    icon: noStatus,
    label: 'No Status',
  },
  'nurse-assigned': {
    icon: confirmation,
    label: 'Nurse Assigned',
  },
};

const Status = (props) => {
  const { item: { status } = {} } = props;
  if (status !== vaccinationStatus.vaccinated && status !== vaccinationStatus.notAppear && status !== vaccinationStatus.noStatus && status !== vaccinationStatus.nurseAssign) {
    return <View />;
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Image
        source={statusWiseData[status]?.icon}
        style={{
          width: 20,
          height: 20,
        }}
      />
      <Text
        style={[
          vars.headings.h9,
          { marginLeft: 10, color: vars.colors.grey.color1 },
        ]}
      >
        {statusWiseData[status]?.label}
      </Text>
    </View>
  );
};

export default Status;
