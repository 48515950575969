import React from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import { Text } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import vars from '../../../../theme/vars';
import { AssetServiceDetailUri } from '../../../../Queries/asset';
import { MODELS } from '../../../../Lib/constants';

const tabListColumns = [
  { width: 14 },
  {
    type: 'date',
    header: 'asset.headers.preventiveDate'.getLabel(),
    field: 'serviceItemDetails.preventiveDate',
    sortable: 'serviceItemDetails.preventiveDate',
  },
  {
    type: 'date',
    header: 'asset.headers.serviceDate'.getLabel(),
    field: 'serviceItemDetails.serviceDate',
  },

  {
    type: 'text',
    header: 'asset.headers.visitStatus'.getLabel(),
    render: ({ item: { serviceItemDetails } }) => {
      if (serviceItemDetails?.serviceStatus) {
        return (
          <Text style={{ ...vars.headings.h9 }}>
            {upperFirst(serviceItemDetails?.serviceStatus)}
          </Text>
        );
      }
      return (
        <Text style={{ ...vars.headings.h9 }}>
          {moment(serviceItemDetails?.serviceDate) > new Date() ? 'Upcoming' : 'Pending'}
        </Text>
      );
    },
  },
  {
    type: 'text',
    header: 'asset.headers.repairPrice'.getLabel(),
    field: 'serviceItemDetails.repairPrice',
  },
];

const AssetServiceDetail = (props) => {
  const { tableColumns = tabListColumns, item: { _id } } = props;

  return (
    <Table
      uri={() => AssetServiceDetailUri({ filter: { _id } })}
      lg={{ columns: tableColumns }}
      {...props}
      reloadEvent={`reload${MODELS.ASSET}`}

    />
  );
};
export default AssetServiceDetail;
