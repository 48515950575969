import React from 'react';

import { NestedAction, NestedTable } from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../app-components/form/Form';
import { Text, View } from '../../../../../../../app-components';
import vars from '../../../../../../../theme/vars';
import { MODELS } from '../../../../../../../Lib/constants';
import { viewProgressReportUri } from '../../../../../../../Queries/progressReport';

const ViewProgressDetail = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Logged Progress for the Patient for the selected date."
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          uri={viewProgressReportUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'text',
                            field: 'progressGc',
                            label: 'GC',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doctorOnRound.name',
                            label: 'Doctor On Round',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsSPO2',
                            label: 'SPO2',
                            mandatory: true,
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>GPE Head to Be </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'date',
                        field: 'gpeDate',
                        editable: false,
                        format: 'DD MM YYYY',
                      },
                      {
                        type: 'text',
                        field: 'place',
                        label: 'Place',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'person',
                        label: 'Person',
                        editable: false,
                      },
                    ],

                  },
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'orientation',
                        label: 'Orientation',
                        container: 'topLabel',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'built',
                        variant: 'filled',
                        label: 'Built',
                        container: 'topLabel',
                        width: 300,
                        editable: false,

                      },
                      {
                        type: 'text',
                        field: 'nourishment',
                        label: 'Nourishment',
                        container: 'topLabel',
                        editable: false,

                      },
                    ],
                  },

                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>CardioVascular </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'cardioInspection',
                            label: 'Inspection',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'cardioPalpation',
                            label: 'Palpation',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'cardioPercussion',
                            label: 'Percussion',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'cardioAusculation',
                            label: 'Ausculation',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>GIT </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'gitInspection',
                            label: 'Inspection',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'gitPalpation',
                            label: 'Palpation',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'gitPercussion',
                            label: 'Percussion',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'gitAusculation',
                            label: 'Ausculation',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>CNS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'hmf',
                            label: 'HMF',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'cravelNerve',
                            label: 'Cravel Nerve',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'neckRegidity',
                            label: 'Neck Regidity',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'motor',
                            label: 'Motor',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'sensory',
                            label: 'Sensory',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'cerebellar',
                            label: 'Cerebellar',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'spine',
                            label: 'Spine',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'others',
                            label: 'others',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Orientation </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'deepRep',
                            label: 'Deep Rep',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'planetary',
                            label: 'Planetary',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: false,
                                    width: 250,
                                    mandatory: true,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    editable: false,
                                    width: 150,
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    editable: false,
                                    width: 100,
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: false,
                                    width: 200,
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    editable: false,
                                    width: 312,
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'text',
                            field: 'transferTo',
                            label: 'Transfer To',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'assignTO',
                            label: 'Assign To',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => 'Today\'s Advice',
                          },
                          {
                            type: 'text',
                            field: 'todaysAdvice',
                            label: 'Today Advice',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />
  );
};

const ViewProgressReport = (props) => (<ViewProgressDetail {...props} />);
export default ViewProgressReport;

