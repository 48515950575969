import React from 'react';

import {
  FormField, save, View, Text,
} from '../../../../../../../app-components';
import { afterFetchForm } from '../../../../../../../app-components/data-fetch/DataFetchFunctions';
import { Form } from '../../../../../../../app-components/form/Form';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import { validateInputLength } from '../../../../../../../Lib/helpers';
import vars from '../../../../../../../theme/vars';
import { afterUriFetch, dateFormatX } from '../../../../../constants';

const TimeIntoHourMinute = (date) => {
  const tempvar = new Date(date);
  const time = [];
  time[0] = tempvar.getHours();
  if (time[0] > 12) { time[2] = 'PM'; time[0] -= 12; } else { time[2] = 'AM'; }
  time[1] = tempvar.getMinutes();
  return time;
};
const BabyMotherInformation = (props) => (
  <Form
    {...props}
    closeView={1}
    type="standardShadow"
    uri={() => ({
      query: {
        id: 'babyInformationList',
        addOnFilter: {
          admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
        },
      },
      model: 'baby_and_mother_information',
    })}
    onSubmit={submit({
      model: 'baby_and_mother_information',
    })}
    beforeSubmit={async (submitProps) => {
      const { updates, data } = submitProps;
      // updates.timeOfBirth = dateFormatX(
      //   data?.BirthHour,
      //   data?.BirthMinute,
      //   data?.BirthFormat,
      // );
      // updates.timeOfAdmission = dateFormatX(
      //   data?.AdmissionHour,
      //   data?.AdmissionMinute,
      //   data?.AdmissionFormat,
      // );
      // updates.timeOfDischarge = dateFormatX(
      //   data?.DischargeHour,
      //   data?.DischargeMinute,
      //   data?.DischargeFormat,
      // );
      // delete updates.BirthHour;
      // delete updates.BirthMinute;
      // delete updates.BirthFormat;
      // delete updates.AdmissionHour;
      // delete updates.AdmissionMinute;
      // delete updates.AdmissionFormat;
      // delete updates.DischargeHour;
      // delete updates.DischargeMinute;
      // delete updates.DischargeFormat;
      return ({
        updates,
      });
    }}
    deepDiff={false}
    afterFetch={(dataAfterfetch) => {
      const { state } = afterFetchForm(dataAfterfetch);
      const { data = {} } = state;
      const { result: { data: { 0: time } } } = dataAfterfetch;
      // const birthTime = TimeIntoHourMinute(time.timeOfBirth);
      // const admissionTime = TimeIntoHourMinute(time.timeOfAdmission);
      // const dischargeTime = TimeIntoHourMinute(time.timeOfDischarge);
      const newData = {
        ...data,
        // BirthHour: birthTime[0],
        // BirthMinute: birthTime[1],
        // BirthFormat: birthTime[2],
        // AdmissionHour: admissionTime[0],
        // AdmissionMinute: admissionTime[1],
        // AdmissionFormat: admissionTime[2],
        // DischargeHour: dischargeTime[0],
        // DischargeMinute: dischargeTime[1],
        // DischargeFormat: dischargeTime[2],
      };
      const result = {
        ...dataAfterfetch,
        state: { ...state, data: newData },
      };
      return result;
    }}
    defaultValues={() => ({
      admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
    })}
    // Finish
    saveOnChange
    {...props}
    afterSubmit={({ submitResult: { result } = {}, data }) => ({
      data: {
        ...data,
        _id: result?._id,
      },
    })}
    // mandatory={{
    //   motherName: 1,
    //   motherSex: 1,
    //   fatherName: 1,
    //   category: 1,
    //   birthWeight: 1,
    //   timeOfAdmission: 1,
    //   dateOfAdmission: 1,
    //   ageOnAdmission: 1,
    //   ageOnDischarge: 1,
    //   timeOfDischarge: 1,
    //   dateOfDischarge: 1,
    //   typeOfAdmission: 1,
    //   placeOfDelivery: 1,
    //   indicationForAdmissionOne: 1,
    //   indicationForAdmissionTwo: 1,
    //   indicationForAdmissionThree: 1,
    //   indicationForAdmissionFour: 1,
    //   indicationForAdmissionFive: 1,
    //   motherAge: 1,
    //   motherWeight: 1,
    //   consanguinity: 1,
    //   birthSpacing: 1,
    //   antenetalVisit: 1,
    //   ttDose: 1,
    //   bloodGroup: 1,
    //   pastPIH: 1,
    //   pastIsDrug: 1,
    //   radiation: 1,
    //   aph: 1,
    //   gdm: 1,
    // }}
    validations={{
      motherName: ({ data }) => validateInputLength(data?.motherName, 1, 50),
      fatherName: ({ data }) => validateInputLength(data?.fatherName, 1, 50),
      ageOnAdmission: ({ data }) => validateInputLength(data?.ageOnAdmission, 1, 3),
      weightOnAdmission: ({ data }) => validateInputLength(data?.weightOnAdmission, 0, 3),
      birthWeight: ({ data }) => validateInputLength(data?.pastHistoryMedical?.birthWeight, 0, 3),
      ageOnDischarge: ({ data }) => validateInputLength(data?.ageOnDischarge, 0, 3),
      weightOnDischarge: ({ data }) => validateInputLength(data?.weightOnDischarge, 0, 3),
      refferedFrom: ({ data }) => validateInputLength(data?.refferedFrom, 0, 50),
      indicationForAdmissionOne: ({ data }) => validateInputLength(
          data?.indicationForAdmissionOne, 0, 100,
      ),
      indicationForAdmissionTwo: ({ data }) => validateInputLength(
          data?.indicationForAdmissionTwo, 0, 100,
      ),
      indicationForAdmissionThree: ({ data }) => validateInputLength(
          data?.indicationForAdmissionThree, 0, 100,
      ),
      indicationForAdmissionFour: ({ data }) => validateInputLength(
          data?.indicationForAdmissionFour, 0, 100,
      ),
      indicationForAdmissionFive: ({ data }) => validateInputLength(
          data?.indicationForAdmissionFive, 0, 100,
      ),
      provisionalDaignosis: ({ data }) => validateInputLength(data?.provisionalDaignosis, 0, 250),
      finalDiagnosis: ({ data }) => validateInputLength(data?.finalDiagnosis, 0, 250),
      motherAge: ({ data }) => validateInputLength(data?.motherAge, 1, 3),
      motherWeight: ({ data }) => validateInputLength(data?.motherWeight, 1, 3),
      gravida: ({ data }) => validateInputLength(data?.gravida, 0, 50),
      lmp: ({ data }) => validateInputLength(data?.lmp, 0, 50),
      edd: ({ data }) => validateInputLength(data?.edd, 0, 50),
      gestationalWeek: ({ data }) => validateInputLength(data?.gestationalWeek, 0, 50),
      hb: ({ data }) => validateInputLength(data?.hb, 0, 50),
      bloodGroup: ({ data }) => validateInputLength(data?.bloodGroup, 1, 4),
      pastDrug: ({ data }) => validateInputLength(data?.pastDrug, 0, 50),
      illnessOther: ({ data }) => validateInputLength(data?.illnessOther, 0, 50),
      pastOtherSignificantInformation: ({ data }) => validateInputLength(
          data?.pastOtherSignificantInformation, 0, 100,
      ),
      timeBetweenLastDoseDeliveryHour: ({ data }) => validateInputLength(
          data?.timeBetweenLastDoseDeliveryHour, 0, 3,
      ),
      timeBetweenLastDoseDeliveryDay: ({ data }) => validateInputLength(
          data?.timeBetweenLastDoseDeliveryDay, 0, 3,
      ),
      otherSignificantInfoDuringLabor: ({ data }) => validateInputLength(
          data?.otherSignificantInfoDuringLabor, 0, 100,
      ),
      babyWeightAtBirth: ({ data }) => validateInputLength(data?.babyWeightAtBirth, 0, 3),
      gestationalAge: ({ data }) => validateInputLength(data?.gestationalAge, 0, 3),
      apgarAtOneMin: ({ data }) => validateInputLength(data?.apgarAtOneMin, 0, 50),
      apgarAtFiveMin: ({ data }) => validateInputLength(data?.apgarAtFiveMin, 0, 50),
      babyInfoOnAdmission: ({ data }) => validateInputLength(data?.babyInfoOnAdmission, 0, 250),
    }}
    computations={{
      self: {
        placeOfDelivery: {
          compute: (data) => {
            if (data?.placeOfDelivery === 'Ambulance' || data?.placeOfDelivery === 'Home') {
              return ({
                set: {
                  hospitalName: '',
                },
              });
            }
          },
          onChange: ['placeOfDelivery'],
        },
        pastPIH: {
          compute: (data) => {
            if (data?.pastPIH === 'No') {
              return ({
                set: {
                  pastIsHypertension: '',
                  pastIsPreEclampsia: '',
                  pastIsEclampsia: '',
                },
              });
            }
          },
          onChange: ['pastPIH'],
        },
        pastIsDrug: {
          compute: (data) => {
            if (data?.pastIsDrug === 'No') {
              return ({
                set: {
                  pastDrug: '',

                },
              });
            }
          },
          onChange: ['pastIsDrug'],
        },
        antenatal: {
          compute: (data) => {
            if (data?.antenatal === 'No') {
              return ({
                set: {
                  antenatalSteroid: '',

                },
              });
            }
          },
          onChange: ['antenatal'],
        },
        laborPIH: {
          compute: (data) => {
            if (data?.laborPIH === 'No') {
              return ({
                set: {
                  isHypertensionDuringLabor: '',
                  isPreEclampsiaDuringLabor: '',
                  isEclampsiaDuringLabor: '',
                },
              });
            }
          },
          onChange: ['laborPIH'],
        },
        resuciatationRequired: {
          compute: (data) => {
            if (data?.resuciatationRequired === 'No') {
              return ({
                set: {
                  isTactileSimulation: '',
                  isOnlyOxygen: '',
                  isBagAndMask: '',
                  bagAndMaskTime: '',
                  isIntubation: '',
                  isChestCompression: '',
                  isAdrenaline: '',
                },
              });
            }
          },
          onChange: ['resuciatationRequired'],
        },

      },
    }}
    // onSubmit={async (submitProps) => {
    //   const { updates, data } = submitProps;
    //   updates.timeOfBirth = dateFormatX(
    //     data?.BirthHour,
    //     data?.BirthMinute,
    //     data?.BirthFormat,
    //   );
    //   updates.timeOfAdmission = dateFormatX(
    //     data?.AdmissionHour,
    //     data?.AdmissionMinute,
    //     data?.AdmissionFormat,
    //   );
    //   updates.timeOfDischarge = dateFormatX(
    //     data?.DischargeHour,
    //     data?.DischargeMinute,
    //     data?.DischargeFormat,
    //   );
    //   delete updates.BirthHour;
    //   delete updates.BirthMinute;
    //   delete updates.BirthFormat;
    //   delete updates.AdmissionHour;
    //   delete updates.AdmissionMinute;
    //   delete updates.AdmissionFormat;
    //   delete updates.DischargeHour;
    //   delete updates.DischargeMinute;
    //   delete updates.DischargeFormat;
    //   const newUpdate = {
    //     ...updates,
    //     admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
    //   };
    //   const submitAPI = submit({
    //     model: MODELS.BABY_MOTHER_INFORMATION,
    //     ...submitProps,
    //     data,
    //     updates: newUpdate,
    //   });
    //   await submitAPI({ data, updates: newUpdate });
    // }}
    scrollable
    style={{ width: 'auto' }}
  >
    { ({ form_state: formState, form_context: formContext }) => {
      const { handleSubmit } = formContext;
      return (
        <View style={{
          display: 'flex', flexDirection: 'column',
        }}
        >
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="motherName"
                variant="filled"
                label="Baby of (Mother's Name)"
                mandatory="true"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="motherSex"
                mandatory="true"
                variant="filled"
                label="Sex"
                container="topLabel"
                options={['Male', 'Female', 'Ambiguous']}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 10,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                variant="filled"
                field="fatherName"
                mandatory="true"
                label="Father's Name"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="category"
                variant="filled"
                mandatory="true"
                label="Category"
                container="topLabel"
                options={['General', 'OBC', 'SC', 'ST']}
              />
            </View>

          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', wdith: '75%',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                {...newDateInput({
                  hour: { field: 'BirthHour', label: 'Time of Birth Hour' },
                  minute: { field: 'BirthMinute', label: ' Minute' },
                  meridiem: { field: 'BirthFormat' },
                })}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="date"
                field="dateOfBirth"
                label="Date of Birth"
                variant="filled"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                mandatory="true"
                field="birthWeight"
                label="Birth Weight(kgs)"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }} />
          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                {...newDateInput({
                  hour: { field: 'AdmissionHour', label: 'Time of Admission Hour' },
                  minute: { field: 'AdmissionMinute', label: 'Minute' },
                  meridiem: { field: 'AdmissionFormat' },
                })}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="date"
                field="dateOfAdmission"
                mandatory="true"
                variant="filled"
                label="Date Of Admission"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                field="weightOnAdmission"
                label="Weight on Admission(kgs)"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                field="ageOnAdmission"
                mandatory="true"
                label="Age on Admission(kgs)"
              />
            </View>

          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                field="ageOnDischarge"
                mandatory="true"
                label="Age on Discharge"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                {...newDateInput({
                  hour: { field: 'DischargeHour', label: 'Time of Discharge Hour' },
                  minute: { field: 'DischargeMinute', label: ' Minute' },
                  meridiem: { field: 'DischargeFormat' },
                })}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="date"
                field="dateOfDischarge"
                mandatory="true"
                variant="filled"
                label="Date of Discharge"
              />
            </View>

            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                field="weightOnDischarge"
                label="Weight on Discharge(kgs)"
              />
            </View>

          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '50%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="typeOfAdmission"
                mandatory="true"
                variant="filled"
                label="Type of Admission"
                container="topLabel"
                options={['InBorn', 'OutBorn(Health Facility Reffered)', 'OutBorn(Community Reffered)']}
              />
            </View>
            <View style={{ flex: 0.7, marginLeft: 14, width: '30%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                mandatory="true"
                field="placeOfDelivery"
                variant="filled"
                label="Place Of Delivery"
                container="topLabel"
                options={['Home', 'Ambulance', 'Pvt. hospital', 'Govt. Hosiptal']}
              />
            </View>

            <View style={{ flex: 0.6, marginLeft: 14, width: '20%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="hospitalName"
                variant="filled"
                label="Name of the Hospital"
                disabled={(disableProps) => {
                  const { data: { placeOfDelivery = 'Ambulance' } = {} } = disableProps;
                  return placeOfDelivery === 'Ambulance' || placeOfDelivery === 'Home';
                }}
              />
            </View>
          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 0.25, marginLeft: 14, width: '30%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="refferedFrom"
                variant="filled"
                label="Refferd From"
              />
            </View>
            <View style={{ flex: 0.3, marginLeft: 14, width: '50%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="modeOfTransport"
                variant="filled"
                label="Mode Of Transport"
                container="topLabel"
                options={['Self-Arranged', 'Govt. Provided']}
              />
            </View>
          </View>

          {/*  Indication For Admission */}
          <View style={{
            flexDirection: 'row', flexWrap: 'wrap', minWidth: '100vw',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, marginTop: 10, width: '100%',
            }}
            >
              <Text style={{
                width: '100vw',
                marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Indication For Admission
              </Text>
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: 16, marginTop: 10,
            }}
            >
              <Text style={{
                width: 40,
                marginTop: 10,
                paddingLeft: 30,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                i.
              </Text>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1400,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 20,
                  borderRadius: 5,
                }}
                type="textArea"
                field="indicationForAdmissionOne"
                mandatory="true"
                variant="filled"
              />
            </View>
            {/* <View style={{
              flex: 1, marginLeft: 14, marginTop: 10, width: 1700,
            }}
            /> */}

            <View style={{
              flexDirection: 'row', flexWrap: 'wrap', marginLeft: 16, marginTop: 10,
            }}
            >
              <Text style={{
                width: 40,
                marginTop: 10,
                paddingLeft: 30,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                ii.
              </Text>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1400,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  marginLeft: 20,
                }}
                type="textArea"
                field="indicationForAdmissionTwo"
                mandatory="true"
                variant="filled"
              />
            </View>
            <View style={{
              flexDirection: 'row', flexWrap: 'wrap', marginLeft: 16, marginTop: 10,
            }}
            >
              <Text style={{
                width: 40,
                marginTop: 10,
                paddingLeft: 30,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                iii.
              </Text>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1400,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 20,
                  borderRadius: 5,
                }}
                type="textArea"
                field="indicationForAdmissionThree"
                mandatory="true"
                variant="filled"
              />
            </View>
            <View style={{
              flexDirection: 'row', flexWrap: 'wrap', marginLeft: 16, marginTop: 10,
            }}
            >
              <Text style={{
                width: 40,
                marginTop: 10,
                paddingLeft: 30,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                iv.
              </Text>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginLeft: 20,
                  width: 1400,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="indicationForAdmissionFour"
                mandatory="true"
                variant="filled"
              />
            </View>
            <View style={{
              flexDirection: 'row', flexWrap: 'wrap', marginLeft: 16, marginTop: 10,
            }}
            >
              <Text style={{
                width: 40,
                marginTop: 10,
                paddingLeft: 30,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                v.
              </Text>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1400,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="indicationForAdmissionFive"
                mandatory="true"
                variant="filled"
              />
            </View>
          </View>

          {/* Provisional Diagnosis */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Provisional Diagnosis
              </Text>
            </View>
            <View style={{
              width: 1450, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1450,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 16,
                  borderRadius: 5,

                }}
                type="textArea"
                variant="filled"
                field="provisionalDaignosis"
              />
            </View>
          </View>

          {/* Final Diagnosis */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Final Diagnosis
              </Text>
            </View>
            <View style={{
              width: 1450, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1450,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 16,
                  borderRadius: 5,

                }}
                type="textArea"
                field="finalDiagnosis"
                variant="filled"
              />
            </View>
          </View>

          {/* Mothers Info */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, marginTop: 10, marginBottom: 10, width: '100%',
            }}
            >
              <Text style={{
                width: 1650,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                marginTop: 10,
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Mothers Information : Past history and Anc Period
              </Text>
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                mandatory="true"
                field="motherAge"
                variant="filled"
                label="Mother's Age(Years)"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 10,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                mandatory="true"
                type="number"
                field="motherWeight"
                variant="filled"
                label="Mother's Weight(Kgs)"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 10,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="number"
                variant="filled"
                field="ageAtMarriage"
                label="Age at Marriage"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="consanguinity"
                variant="filled"
                mandatory="true"
                label="Consanguinity"
                container="topLabel"
                options={['Yes', 'no']}
              />
            </View>

          </View>
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: 1000 }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="birthSpacing"
                mandatory="true"
                variant="filled"
                label="Birth Spacing"
                container="topLabel"
                options={['< 1yr', '1-2yr', '>2-3yr', '>3yr', 'No Applicable']}
              />
            </View>
          </View>

          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="gravida"
                variant="filled"
                label="Gravida"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="para"
                variant="filled"
                label="Para"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                variant="filled"
                field="liveBirth"
                label="Live Birth"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                variant="filled"
                field="abortion"
                label="Abortion"
              />
            </View>

          </View>
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="lmp"
                variant="filled"
                label="LMP"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 10,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="edd"
                variant="filled"
                label="EDD"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 10,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                variant="filled"
                field="gestationalWeek"
                label="Gestational Week"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                field="antenetalVisit"
                mandatory="true"
                variant="filled"
                label="Antenetal Visit's"
                container="topLabel"
                options={['None', '1', '2', '3', '4']}
              />
            </View>

          </View>
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', wdith: '75%',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                }}
                type="radioGroup"
                mandatory="true"
                field="ttDose"
                variant="filled"
                label="T.T Doses"
                container="topLabel"
                options={['Yes', 'No']}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  borderWidth: 1,
                  marginTop: 10,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                variant="filled"
                field="hb"
                label="HB"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  marginTop: 10,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                mandatory="true"
                variant="filled"
                field="bloodGroup"
                label="Blood Group"
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14, width: '23%' }} />
          </View>

          {/* Personal History */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '85%',
            }}
            >
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 600,
                // height: 300,
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    1. PIH
                  </Text>
                </View>
                <View style={{ flex: 1, width: '50%', marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="radioGroup"
                    mandatory="true"
                    field="pastPIH"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    If Yes Then
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 170, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="pastIsHypertension"
                    variant="filled"
                    label="Hypertension"
                    disabled={(disableProps) => {
                      const { data: { pastPIH = false } = {} } = disableProps;
                      return pastPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="pastIsPreEclampsia"
                    variant="filled"
                    label="Pre-Eclampsia"
                    disabled={(disableProps) => {
                      const { data: { pastPIH = false } = {} } = disableProps;
                      return pastPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="pastIsEclampsia"
                    variant="filled"
                    label="Eclampsia"
                    disabled={(disableProps) => {
                      const { data: { pastPIH = false } = {} } = disableProps;
                      return pastPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 130,
                }}
                >
                  <Text style={{
                    width: 130,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    2. Drug
                  </Text>
                </View>
                <View style={{ flex: 1, width: 150 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="radioGroup"
                    mandatory="true"
                    field="pastIsDrug"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, width: 130,
                }}
                >
                  <Text style={{
                    width: 130,
                    marginTop: 25,

                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    If Yes Then
                  </Text>
                </View>
                <View style={{ flex: 1, width: '50%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="pastDrug"
                    variant="filled"
                    disabled={(disableProps) => {
                      const { data: { pastIsDrug = false } = {} } = disableProps;
                      return pastIsDrug !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    3. Radiation
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450, marginBottom: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="radiation"
                    mandatory="true"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>

                <View style={{
                  flex: 0.2, width: 400,
                }}
                >
                  <Text style={{
                    width: 130,
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    4. Illness
                  </Text>
                </View>
                <View style={{ flex: 1, width: 150, marginLeft: 20 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isMalaria"
                    variant="filled"
                    label="Malaria"
                  />
                </View>
                <View style={{ flex: 1, width: 150, marginLeft: 20 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isTB"
                    variant="filled"
                    label="T.B"
                  />
                </View>
                <View style={{ flex: 1, width: 150, marginLeft: 20 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isJaundice"
                    variant="filled"
                    label="Jaundice"
                  />
                </View>
                <View style={{ flex: 1.5, width: 250, marginLeft: 20 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isRushWithFever"
                    variant="filled"
                    label="Rush with Fever"
                  />
                </View>
                <View style={{ flex: 1, width: 150, marginLeft: 165 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isUTI"
                    variant="filled"
                    label="U.T.I"
                  />
                </View>
                <View style={{ flex: 1, width: 150, marginLeft: 20 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                      marginTop: 10,
                    }}
                    type="checkBox"
                    field="isSyphilis"
                    variant="filled"
                    label="Sphyilis"
                  />
                </View>
                <View style={{
                  flex: 0.2, width: 400,
                }}
                >
                  <Text style={{
                    width: 130,
                    marginTop: 8,
                    // marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Others
                  </Text>
                </View>
                <View style={{ flex: 1, width: 130 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 130,
                      //   marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="illnessOther"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    5. APH
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="aph"
                    mandatory="true"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    6. GDM
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    mandatory="true"
                    field="gdm"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
                marginLeft: 160,
                marginBottom: 10,
                width: 500,
                height: 300,
              }}
              >
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  // width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    7. Thyroid
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="thyroid"
                    variant="filled"
                    options={['Euthyroid(N)', 'Hyperthyroid', 'Not Known']}
                  />
                </View>
                {/* <View style={{ flex: 1, width: 450 }} /> */}
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  // width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    8. VDRL
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="vdrl"
                    variant="filled"
                    options={['Not Done', '+ve', '-ve']}
                  />
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    9.HbsAg
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="hbsAg"
                    variant="filled"
                    options={['Not Done', '+ve', '-ve']}
                  />
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    10.HIV Testing
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="hivTesting"
                    variant="filled"
                    options={['Done', 'Not Done']}
                  />
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                >
                  <Text style={{
                    width: 230,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    11. Amnitoic Fluid Volume
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    //   marginTop: 10,
                    }}
                    type="radioGroup"
                    field="amnioticFluidVolume"
                    variant="filled"
                    options={['Adequate', 'Polyhydraminous', 'Oligohyd']}
                  />
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                >
                  <Text style={{
                    width: 270,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    12.Other Significant Information
                  </Text>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="pastOtherSignificantInformation"
                    variant="filled"
                  />
                </View>

              </View>
            </View>
          </View>

          {/* Mother's Info During Labor */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '100%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, marginTop: 10, marginBottom: 10,
            }}
            >
              <Text style={{
                width: 1500,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                marginTop: 10,
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Mothers Information (During labor)
              </Text>
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: 1450,
            }}
            >
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 700,
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    1. Antenatal
                  </Text>
                </View>
                <View style={{ flex: 0.5, width: 150 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 150,
                    }}
                    type="radioGroup"
                    field="antenatal"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 100,
                }}
                >
                  <Text style={{
                    width: 100,
                    // marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    If Yes Then
                  </Text>
                </View>
                <View style={{ flex: 1, width: '50%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 300,
                    }}
                    type="radioGroup"
                    field="antenatalSteroid"
                    variant="filled"
                    options={['Betamethasone', 'Dexamethasone']}
                    disabled={(disableProps) => {
                      const { data: { antenatal = false } = {} } = disableProps;
                      return antenatal !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    2. Number of Doses
                  </Text>
                </View>
                <View style={{ flex: 1, width: 400 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 400,
                    }}
                    type="radioGroup"
                    field="noOfDoses"
                    variant="filled"
                    options={['1', '2', '3', '4']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 350,
                }}
                >
                  <Text style={{
                    width: 350,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    3. Time Between last Dose And Delivery
                  </Text>
                </View>
                <View style={{ flex: 1, width: 90 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 130,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="number"
                    field="timeBetweenLastDoseDeliveryHour"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 50,
                }}
                >
                  <Text style={{
                    width: 50,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginRight: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    /hrs
                  </Text>
                </View>
                <View style={{ flex: 1, width: 90 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 130,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="number"
                    field="timeBetweenLastDoseDeliveryDay"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 50,
                }}
                >
                  <Text style={{
                    width: 50,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Days
                  </Text>
                </View>

                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    4. H/O Fever
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="hoFever"
                    variant="filled"
                    options={['In Ist Trimester', 'In 2nd Trimester', 'In 3rd Trimeter', 'During Labour only if > 100.4F']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    5. Foul Smelling Discharge
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="foulSmellingDischarge"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    6. Uterine tenderness
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="uterineTenderness"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    7.Leaking P.V. 24hrs
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="leakingPV"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    8. PIH
                  </Text>
                </View>
                <View style={{ flex: 1, width: '50%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="radioGroup"
                    field="laborPIH"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    If Yes Then
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 170, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isHypertensionDuringLabor"
                    variant="filled"
                    label="Hypertension"
                    disabled={(disableProps) => {
                      const { data: { laborPIH = false } = {} } = disableProps;
                      return laborPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isPreEclampsiaDuringLabor"
                    variant="filled"
                    label="Pre-Eclampsia"
                    disabled={(disableProps) => {
                      const { data: { laborPIH = false } = {} } = disableProps;
                      return laborPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isEclampsiaDuringLabor"
                    variant="filled"
                    label="Eclampsia"
                    disabled={(disableProps) => {
                      const { data: { laborPIH = false } = {} } = disableProps;
                      return laborPIH !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    9.PPH
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="pphDuringLabor"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    10.Amniotic fluid
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="amnioticFuid"
                    variant="filled"
                    options={['Clear', 'Blood Stained', 'Meconium Stained', 'Foul Smelling']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    11. Presentation
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="presentation"
                    variant="filled"
                    options={['Vertex', 'Breech', 'Transverse']}
                  />
                </View>
              </View>

              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
                marginLeft: 16,
                marginBottom: 10,
                width: '90%',
                height: 300,
              }}
              >
                <View style={{
                  flex: 0.6, width: 170,
                }}
                >
                  <Text style={{
                    width: 170,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    12. Labor
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="labor"
                    variant="filled"
                    options={['Spontaneous', 'Induced']}
                  />
                </View>
                <View style={{
                  flex: 1, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    13. Course if labor
                  </Text>
                </View>
                <View style={{ flex: 1, width: 470 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 470,
                    }}
                    type="radioGroup"
                    field="courseIfLabor"
                    variant="filled"
                    options={['Uneventful', 'Prolonged 1st stage', 'Prolonged 2nd stage', 'Obstructed']}
                  />
                </View>
                <View style={{
                  flex: 0.6, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    14. E/O Feotal Distress
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="eoFeotalDistress"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.6, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    15. Type Of Delivery
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="typeOfDelivery"
                    variant="filled"
                    options={['LSCS', 'AVD', 'NVD']}
                  />
                </View>
                <View style={{
                  flex: 0.6, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    16. Indication of Caesarean
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: 240, marginTop: 10, marginLeft: 40,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 240,
                    }}
                    type="checkBox"
                    field="isCephaloPelvicDisproportion"
                    variant="filled"
                    label="Cephalo Pelvic Disproportion"
                  />
                </View>
                <View style={{ flex: 1, width: 200, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isMalPresentation"
                    variant="filled"
                    label="Malpresentation"
                  />
                </View>
                <View style={{ flex: 1, width: 200, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isPlacentaPrevia"
                    variant="filled"
                    label="Placenta Previa"
                  />
                </View>
                <View style={{
                  flex: 1, width: 200, marginLeft: 240,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isObstructedLabor"
                    variant="filled"
                    label="Obstructed Labor"
                  />
                </View>
                <View style={{ flex: 1, width: 200 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isFoetalDistress"
                    variant="filled"
                    label="Foetal Distress"
                  />
                </View>
                <View style={{
                  flex: 1, width: 200,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isProlongedLabor"
                    variant="filled"
                    label="Prolonged Labour"
                  />
                </View>
                <View style={{
                  flex: 1, width: 200, marginLeft: 240, marginTop: 15,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isCordProlapse"
                    variant="filled"
                    label="Cord Prolapse"
                  />
                </View>
                <View style={{ flex: 1, width: 200, marginTop: 15 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isfailedInduction"
                    variant="filled"
                    label="Failed Induction(Dystocia)"
                  />
                </View>
                <View style={{
                  flex: 1, width: 200, marginTop: 15,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isPreviousLCSC"
                    variant="filled"
                    label="Previous LCSC"
                  />
                </View>
                <View style={{
                  flex: 0.8, width: 100, marginLeft: 170, marginTop: 10,
                }}
                >
                  <Text style={{
                    width: 100,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Other
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="othersDuringLabor"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.6, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    17. Delivery Attend By
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: 240, marginTop: 10, marginLeft: 40,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 240,
                    }}
                    type="checkBox"
                    field="isDoctorAttendDelivery"
                    variant="filled"
                    label="Doctor"
                  />
                </View>
                <View style={{ flex: 1, width: 200, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isNurseAttendDelivery"
                    variant="filled"
                    label="Nurse"
                  />
                </View>
                <View style={{ flex: 1, width: 200, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isANMAttendDelivery"
                    variant="filled"
                    label="ANM"
                  />
                </View>
                <View style={{
                  flex: 1, width: 200, marginLeft: 240, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="checkBox"
                    field="isRelativeAttendDelivery"
                    variant="filled"
                    label="Relative"
                  />
                </View>
                <View style={{
                  flex: 0.8, width: 150,
                }}
                >
                  <Text style={{
                    width: 200,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Other
                  </Text>
                </View>
                <View style={{ flex: 1, width: 250 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="deliveryAttendByOther"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.8, width: 270,
                }}
                >
                  <Text style={{
                    width: 270,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    18.Other Significant Information
                  </Text>
                </View>
                <View style={{ flex: 1, width: 350, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="otherSignificantInfoDuringLabor"
                    variant="filled"
                  />
                </View>

              </View>
            </View>
          </View>

          {/* Baby Info at Birth */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '100%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, marginTop: 10, marginBottom: 10,
            }}
            >
              <Text style={{
                width: 1500,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                marginTop: 10,
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Babys Information at Birth
              </Text>
            </View>
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: 1450,
            }}
            >
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 700,
              }}
              >
                <View style={{ flex: 1, width: 700, marginLeft: 60 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 455,
                    }}
                    type="radioGroup"
                    field="babyInfoAtBirth"
                    variant="filled"
                    options={['Significant', 'Non-Significant']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    1. Cried Immed after Birth
                  </Text>
                </View>
                <View style={{ flex: 1, width: 400 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 400,
                    }}
                    type="radioGroup"
                    field="criedImmedAfterBirth"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    2. Weight a Birth(kgs)
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="number"
                    field="babyWeightAtBirth"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    3. Gestational Age
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="number"
                    field="gestationalAge"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.3, width: 250,
                }}
                >
                  <Text style={{
                    width: 250,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    4. Maturity
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="maturity"
                    variant="filled"
                    options={['Preterm(<37Wk)', 'Full Term', 'Post Term(>42Wk)']}
                  />
                </View>
                <View style={{
                  flex: 0.8, width: 270,
                }}
                >
                  <Text style={{
                    width: 270,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    5. APGAR at 1 min (If Available)
                  </Text>
                </View>
                <View style={{ flex: 1, width: 350, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="apgarAtOneMin"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 0.8, width: 270,
                }}
                >
                  <Text style={{
                    width: 270,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    6. APGAR at 5 min (If Available)
                  </Text>
                </View>
                <View style={{ flex: 1, width: 350, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="text"
                    field="apgarAtFiveMin"
                    variant="filled"
                  />
                </View>
              </View>

              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
                marginLeft: 16,
                marginBottom: 10,
                width: '90%',
                height: 200,
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 220,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    7. Resuscitation Required
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 60, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                    }}
                    type="radioGroup"
                    field="resuciatationRequired"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    If Yes Then
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 170, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      marginTop: 10,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isTactileSimulation"
                    variant="filled"
                    label="Tactile Simulation"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isOnlyOxygen"
                    variant="filled"
                    label="Only Oxygen"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isBagAndMask"
                    variant="filled"
                    label="Bag And Mask"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: 80, marginLeft: 7,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 110,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="bagAndMaskTime"
                    variant="filled"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 20,
                }}
                >
                  <Text style={{
                    width: 20,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    min
                  </Text>
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 170,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      //   marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isIntubation"
                    variant="filled"
                    label="Intubation"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      //   marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isChestCompression"
                    variant="filled"
                    label="Chest Comparison"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>

                <View style={{
                  flex: 1, width: 400, marginLeft: 30,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 200,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isAdrenaline"
                    variant="filled"
                    label="Adrenaline"
                    disabled={(disableProps) => {
                      const { data: { resuciatationRequired = false } = {} } = disableProps;
                      return resuciatationRequired !== 'Yes';
                    }}
                  />
                </View>
                <View style={{
                  flex: 1, width: '50%', marginLeft: 30, marginTop: 10,
                }}
                />
                <View style={{
                  flex: 0.6, width: 170,
                }}
                >
                  <Text style={{
                    width: 170,
                    marginTop: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    8. Vitamin K given
                  </Text>
                </View>
                <View style={{ flex: 1, width: 450 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                    }}
                    type="radioGroup"
                    field="vitaminKGiven"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
                <View style={{
                  flex: 1, width: 200,
                }}
                >
                  <Text style={{
                    width: 200,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 16,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    9. Breast Fed within one hour
                  </Text>
                </View>
                <View style={{ flex: 1, width: 470 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 470,
                    }}
                    type="radioGroup"
                    field="breastFedWithinOneHour"
                    variant="filled"
                    options={['Yes', 'No']}
                  />
                </View>
              </View>
            </View>
          </View>

          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Babys Information On Admission
              </Text>
            </View>
            <View style={{
              width: 1450, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1450,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 16,
                  borderRadius: 5,

                }}
                type="textArea"
                field="babyInfoOnAdmission"
                variant="filled"
              />
            </View>
          </View>
        </View>
      );
    }}
  </Form>
);
export default BabyMotherInformation;
