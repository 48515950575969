import { organizationLabels } from './logoOrganisationLabels';

export default {
  labels: {
    username: 'Username',
    password: 'Password',
  },
  placeholders: {
    username: 'Enter your employee code',
    password: 'Password',
  },
  buttons: {
    login: 'Login',
    save: 'Save',
  },
  title: organizationLabels.loginTitle,
};
