import React from 'react';

import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import ListActionModal from '../../../../../../../../Components/ListActionModal';

const LogTreatment = (props) => (
  <ListActionModal
    {...props}
    title="Psychological Treatment"
    description="Logged Psychological treatment of patient."
    content={(
      <Form
        {...props}
        closeView={1}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Confirm',
            }),
          ],
        }}
        reloadEvent={`reload${MODELS.IPD_PSYCHOLOGICAL_TREATMENT}`}
        mandatory={{
          loggedDate: 1,
          treatment: 1,
        }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            ipdPatient: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.IPD_PSYCHOLOGICAL_TREATMENT,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        defaultValues={() => ({
          loggedDate: new Date(),
        })}
        submitMessage="Psychological treatment had been saved successfully."
        type="standardShadow"
        lg={{
          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 3,
                  columns: [
                    {
                      type: 'date',
                      field: 'loggedDate',
                      label: 'Date',
                      formte: 'DD/MMM/YYYY',
                      editable: true,
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      type: 'textArea',
                      field: 'treatment',
                      label: 'Treatment',
                      mandatory: true,
                      editable: true,
                      allowWhiteSpaces: true,
                      minHeight: 80,
                      style: { flex: 1, overflow: 'hidden' },
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
      )}
  />
);

export default LogTreatment;
