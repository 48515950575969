export const getResource = ({ model, addOnFilter, query }, { id = '_find' }) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
  },
  model,
});

export const ActiveEmployeeCount = () => ({
  id: '_find',
  query: { id: 'activeResourcesForV4' },
  model: 'Resource',
  fetchCount: true,
});

export const InActiveEmployeeCount = () => ({
  id: '_find',
  query: { id: 'inactiveresources' },
  model: 'Resource',
  fetchCount: true,
});

export const InSeperationEmployeeCount = () => ({
  id: '_find',
  query: { id: 'inseprationresources' },
  model: 'Resource',
  fetchCount: true,
});

export const AlumniEmployeeCount = () => ({
  id: '_find',
  query: { id: 'alumniResources' },
  model: 'Resource',
  fetchCount: true,
});
