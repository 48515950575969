/* eslint-disable consistent-return */
import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';

import { MODELS, revaccinateGapOptions, vaccinationType } from '../../../Lib/constants';
import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { EditPharmacyItem } from '../../../Queries/pharmacy';
import { Form } from '../../../app-components/form/Form';
import { ageInput } from '../../../compositeInputs';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';

const getNewId = () => `new_${uuid()}`;

const AddVaccineSchedule = (props) => {
  const { navigation: { state: { params: { item = '' } } } } = props;
  const [noOfDose, setNoOfDose] = useState(item?.noOfDoses || 0);
  const [VaccinationType, setVaccinationType] = useState(item?.vaccinationType || '');
  const [
    isReoccurringIntervalEqual,
    setReoccurringIntervalEqual,
  ] = useState(item?.isReoccurringIntervalEqual || false);

  return (
    <Form
      type="standardShadow"
      uri={EditPharmacyItem}
      closeView={1}
      beforeSubmit={({ updates }) => ({
        updates: {
          ...updates,
          isScheduleAdded: true,
        },
      })}
      onSubmit={submit({
        model: MODELS.PHARMACY_ITEM,
      })}
      reloadEvent={`reload${MODELS.PHARMACY_ITEM}`}
      defaultValues={() => ({
        isActive: true,
        currency: 'INR',
        isScheduleAdded: true,
      })}
      computations={{
        self: {
          autoFill: {
            compute: (data) => {
              if (!data?.noOfDoses || data?.noOfDoses <= 0) {
                setNoOfDose(0);
                return { set: { vaccinationReOccurringDetails: [] } };
              }
              setNoOfDose(data?.noOfDoses);
              item.noOfDoses = data?.noOfDoses;
              setReoccurringIntervalEqual(data?.isReoccurringIntervalEqual);
              if (!isReoccurringIntervalEqual
                && data?.vaccinationType === vaccinationType.reCurring) {
                if (noOfDose < data?.noOfDoses) {
                  let newData = [];
                  newData = data?.vaccinationReOccurringDetails || [];
                  [...Array(1)].map(() => newData.push({ _id: getNewId(), doseNo: data?.vaccinationReOccurringDetails?.length + 1, intervalType: 'Years' }));
                  return { set: { vaccinationReOccurringDetails: newData } };
                }
                if (noOfDose > data?.noOfDoses) {
                  let newData = [];
                  newData = data?.vaccinationReOccurringDetails || [];
                  [...Array(1)].map(() => newData.pop());
                  return { set: { vaccinationReOccurringDetails: newData } };
                }
              }
            },
            onChange: ['noOfDoses'],
          },
          autoFill2: {
            compute: (data) => {
              let dose = 0;
              setVaccinationType(data?.vaccinationType);
              if (data?.vaccinationType === vaccinationType?.onceInLifeTime) {
                setNoOfDose(1);
                dose = 1;
                item.noOfDoses = dose;
              } else {
                dose = data?.noOfDoses >= 2 ? data?.noOfDoses : 2;
                setNoOfDose(dose);
                item.noOfDoses = dose;
              }
              let newData = [];
              newData = [];
              [...Array(dose)].map((_, index) => newData.push({ _id: getNewId(), doseNo: index + 1, intervalType: 'Years' }));
              return { set: { vaccinationReOccurringDetails: newData, noOfDoses: dose } };
            },
            onChange: ['vaccinationType'],
          },
          autoFill3: {
            compute: (data) => {
              setVaccinationType(data?.vaccinationType);
              if (!data?.noOfDoses || data?.noOfDoses <= 0) {
                setNoOfDose(0);
                return { set: { vaccinationReOccurringDetails: [] } };
              }
              setNoOfDose(data?.noOfDoses);
              item.noOfDoses = data?.noOfDoses;
              setReoccurringIntervalEqual(data?.isReoccurringIntervalEqual);
              if (!data?.isReoccurringIntervalEqual
                && data?.vaccinationType === vaccinationType.reCurring) {
                let newData = [];
                newData = [];
                [...Array(data?.noOfDoses)].map((_, index) => newData.push({ _id: getNewId(), doseNo: index + 1, intervalType: 'Years' }));
                return { set: { vaccinationReOccurringDetails: newData } };
              }
            },
            onChange: ['isReoccurringIntervalEqual'],
          },
          autoFill4: {
            compute: (data) => {
              if (data?.isVaccineFree) {
                return {
                  set: { sellingPrice: null },
                };
              }
            },
            onChange: ['isVaccineFree'],
          },

        },
      }}
      submitMessage={'inventory.messages.itemUpdatedSuccessfully'.getLabel()}
      uniqueValidationMessage={'inventory.messages.itemAlreadyExists'.getLabel()}
      lg={{
        validations: {
          vaccinationType: ({ data }) => {
            if (!data?.vaccinationType && data?.isVaccine) {
              return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
            }
          },
          noOfDoses: ({ data }) => {
            if (!data?.noOfDoses && data?.isVaccine) {
              return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
            } if (data?.noOfDoses < 0) {
              return 'inventory.errorMessage.noOfDoses'.getLabel();
            }
          },
          interval: ({ data }) => {
            if (!data?.interval && data?.isReoccurringIntervalEqual && data?.isVaccine) {
              return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
            }
          },
          sellingPrice: ({ data }) => {
            if (!data?.isVaccineFree && !data?.sellingPrice) {
              return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
            }
          },
          vaccinationReOccurringDetails: {
            interval: ({ data }) => {
              if (
                !data?._parent?.interval
                 && data?._parent?.vaccinationType === VaccinationType?.reCurring
                 && !data?._parent?.isReoccurringIntervalEqual
              ) {
                return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
              }
            },
          },
        },
        formGroups: [
          {
            columnsPerRow: 4,
            label: 'VACCINE DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    label: 'vaccination.labels.vaccineName'.getLabel(),
                    type: 'text',
                    field: 'name',
                    disabled: true,
                  },
                  {
                    label: 'vaccination.labels.vaccineType'.getLabel(),
                    type: 'text',
                    disabled: true,
                    value: item?.isVaccineGlobal ? 'GLOBAL' : 'Recommended',
                  },
                  {
                    label: 'vaccination.labels.description'.getLabel(),
                    type: 'text',
                    field: 'description',
                    disabled: true,
                  },
                  {
                    label: 'vaccination.labels.stockAvailable'.getLabel(),
                    type: 'text',
                    disabled: true,
                    value: item?.stockAvailable[0].stockAvailable ? item?.stockAvailable[0].stockAvailable : '0',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.paymentType'.getLabel(),
                    disabled: true,
                    value: item?.isVaccineFree ? 'Free' : 'Paid',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.sellingPrice'.getLabel(),
                    disabled: true,
                    field: 'sellingPrice',
                  },
                  {
                    type: 'toggleSwitch',
                    field: 'isActive',
                    label: 'vaccination.labels.vaccineStatus'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Vaccine Schedule',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'radioGroup',
                    label: 'vaccination.labels.reVaccinationGap'.getLabel(),
                    container: 'topLabel',
                    options: revaccinateGapOptions,
                    field: 'vaccinationType',
                  },
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  VaccinationType === vaccinationType?.reCurring ? {
                    type: 'number',
                    label: 'vaccination.labels.noOfDoses'.getLabel(),
                    showCustomIcon: true,
                    field: 'noOfDoses',
                    editable: true,
                    mandatory: true,
                    disabled: ({ data }) => !data?.vaccinationType,
                  } : null,
                  VaccinationType === vaccinationType?.reCurring ? {
                    type: 'checkBox',
                    label: 'vaccination.labels.allDosesOccuring'.getLabel(),
                    field: 'isReoccurringIntervalEqual',
                    container: 'rightLabel',
                    disabled: ({ data }) => data?.vaccinationType !== vaccinationType?.reCurring,
                  } : null,
                  VaccinationType
                  === vaccinationType?.reCurring
                  && isReoccurringIntervalEqual ? ageInput({
                      age: {
                        field: 'interval',
                        type: 'number',
                        label: 'vaccination.labels.vaccinationPeriod'.getLabel(),
                        mandatory: true,
                        allowedDecimalDigits: false,
                        modifyValueOnChange: ({ prevValue, value }) => {
                          if (value > 999) {
                            return prevValue;
                          }
                          return value;
                        },
                        disabled: ({ data }) => data?.vaccinationType
                        !== vaccinationType?.reCurring || !data?.isReoccurringIntervalEqual,
                      },
                      ageType: {
                        field: 'intervalType',
                      },
                    }) : null,

                ],
              },
              VaccinationType === vaccinationType.reCurring && !isReoccurringIntervalEqual ? {
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        field: 'vaccinationReOccurringDetails',
                        fieldType: 'nested',
                        Component: NestedTable,
                        columnsPerRow: 2,
                        componentProps: {
                          maxHeight: 400,
                          skipTableHeaderOnNoData: true,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              color: vars.colors.grey.color3,
                              backgroundColor: 'white',
                              numberOfLines: 1,
                            },
                            headerRowContainerStyle: { backgroundColor: 'white' },
                            columns: [
                              {
                                type: 'text',
                                field: 'doseNo',
                                header: 'vaccination.labels.doseNo'.getLabel(),
                                disabled: true,
                                width: 100,
                              },
                              ageInput({
                                variant: 'filled',
                                width: 300,
                                header: 'vaccination.labels.vaccinationPeriod'.getLabel(),
                                age: {
                                  type: 'number',
                                  field: 'interval',
                                  mandatory: true,
                                  allowedDecimalDigits: false,
                                  modifyValueOnChange: ({ prevValue, value }) => {
                                    if (value > 999) {
                                      return prevValue;
                                    }
                                    return value;
                                  },
                                },
                                ageType: {
                                  field: 'intervalType',
                                },
                              }),
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              } : null,
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default AddVaccineSchedule;
