/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import moment from 'moment';
import { uniqueId } from 'lodash';

import {
  FormField, StandardButton, Toast, View, Text,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import ListActionModal from '../../../../Components/ListActionModal';
import { searchInput } from '../../../../autoSuggestions';
import { vaccineIcon } from '../../../../images';
import vars from '../../../../theme/vars';
import { MODELS, ROUTES, vaccineItemStockStatus } from '../../../../Lib/constants';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { getUser, submit } from '../../../../AppServices';

const getNewId = () => `new_${uniqueId()}`;

const VaccineDetailModalForPatient = (props) => {
  const [serialNo, setSerialNo] = useState(1);
  const { navigation } = props;
  const { navigation: { state: { params } } = {} } = props;
  const user = getUser();
  let isPaid = true;
  const { employee: { department = [], firstName, lastName } = {} } = user;

  const departmentIds = department?.filter(
    ({ _id }) => _id,
  ).map(({ _id }) => _id);

  const [departmentId] = useState(departmentIds);

  return (
    <ListActionModal
      icon={vaccineIcon}
      title="List of Vaccines"
      closeView={1}
      content={(
        <Form
          {...props}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async ({ data, updates }) => {
            const { pharmacyDetails = [] } = data;
            const paidVaccineDetails = [];
            const freeVaccineDetails = [];

            pharmacyDetails.map((item) => {
              if (item?.name?.isVaccineFree) {
                const items = {};
                items.item = { _id: item?.name?._id };
                items.department = { _id: item?.name?.department?._id };
                items.quantity = 1;
                items.totalPrice = null;
                freeVaccineDetails.push(items);
              } else {
                paidVaccineDetails.push(item);
                isPaid = false;
              }
              return {};
            });

            if (!isPaid) {
              navigation.push({
                view: ROUTES.generateVaccineBill.name,
                params: {
                  data: [params?.patientDetails],
                  pharmacyDetails: paidVaccineDetails,
                  freeVaccineDetails,
                },
              });
              return;
            }

            const itemPharmacyDetails = [];
            pharmacyDetails.map(({ name }) => {
              const items = {};
              items.item = { _id: name?._id };
              items.department = { _id: name?.department?._id };
              items.quantity = 1;
              items.totalPrice = null;
              return itemPharmacyDetails.push(items);
            });

            updates.pharmacyDetails = itemPharmacyDetails;
            data.pharmacyDetails = itemPharmacyDetails;
            const submitAPI = submit({
              model: MODELS.PHARMACY_BILLING,
              data,
            });

            await submitAPI({ data, updates }).then(() => {
              Toast.show({
                message: 'Success',
                description: 'vaccination.messages.patientAddedSuccessfully'.getLabel(),
                type: 'success',
                position: 'top',
                direction: 'right',
                duration: 3000,
              });
              navigation.replace({ view: ROUTES.viewActiveVaccinationSession.name });
            }).catch((error) => {
              throw error;
            });
          }}
          defaultValues={() => ({
            pharmacyDetails: [{ _id: getNewId() }],
            billingDate: new Date(),
            department: departmentId,
            consultationId: { _id: params?.patientDetails?.lastConsultation?._id },
            patientId: {
              _id: params?.patientDetails?._id,
              age: params?.patientDetails?.age,
              uhid: params?.patientDetails?.uhid,
              gender: params?.patientDetails?.gender,
              mobile: params?.patientDetails?.mobile,
              lastConsultation: params?.patientDetails?.lastConsultation,
              patientName: params?.patientDetails?.patientName,
              relation: params?.patientDetails?.relation,
              relationName: params?.patientDetails?.relationName,
              lastConsultationDate: params?.patientDetails?.lastConsultationDate ? moment(params?.patientDetails?.lastConsultationDate).format('DD MMM YYYY') : 'N/A',
            },
            discount: 0,
            dispensedBy: firstName + lastName,
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ width: 300, marginRight: 20 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.patientName'.getLabel()}
                    variant="filled"
                    render={() => params?.patientDetails?.patientName}
                    editable={false}

                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.uhid'.getLabel()}
                    variant="filled"
                    render={() => params?.patientDetails?.uhid}
                    editable={false}

                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 25 }}>
                <View>
                  <FormField
                    {...{
                      fieldVariant: 'filled',
                      field: 'pharmacyDetails',
                      fieldType: 'nested',
                      label: 'vaccination.labels.vaccineDetails'.getLabel(),
                      columnsPerRow: 4,
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        footer: (props) => {
                          const { data: { pharmacyDetails = [] } } = props;
                          setSerialNo(pharmacyDetails?.length);
                          if (serialNo <= pharmacyDetails?.length) {
                            pharmacyDetails.map((name, index) => name.serialNo = index + 1);
                          }
                          return {
                            leftActions: [{
                              render: () => (
                                <View style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  width: 120,
                                  height: 40,
                                  borderColor: 'grey',
                                }}
                                >
                                  <Text
                                    style={{
                                      ...vars.headings.h9,
                                      color: vars.colors.grey.color3,
                                    }}
                                  >
                                    {`+ ${'inventory.labels.addRow'.getLabel()}`}
                                  </Text>
                                </View>
                              ),
                              onPress: props?.addRow,
                            }],
                          };
                        },
                        skipTableHeaderOnNoData: false,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 32,
                            backgroundColor: vars.colors.white,
                          },
                          headerRowColumnTextStyle: {
                            paddingLeft: 12,
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                            numberOfLines: 1,
                            textTransform: 'upperCase',
                          },
                          columns: [
                            {
                              type: 'text',
                              header: 'vaccination.headers.serialNo'.getLabel(),
                              field: 'serialNo',
                              variant: 'filled',
                              editable: false,
                              width: 150,
                            },
                            searchInput({
                              type: 'autoSuggest',
                              field: 'name',
                              header: 'vaccination.headers.vaccineName'.getLabel(),
                              variant: 'filled',
                              keyFiled: 'name',
                              suggestionField: 'name',
                              valueField: 'name',
                              model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
                              query: 'pharmacyDepartmentInventoryStocksDataList',
                              width: 250,
                            },
                            {
                              modifyResult: ({ data }) => {
                                const clonedData = data.map((item) => ({
                                  ...item,
                                  ...item.item,
                                }));

                                return { data: clonedData };
                              },
                              addOnFilter: ({
                                data: {
                                  _parent: { pharmacyDetails } = {},
                                } = {},
                              }) => {
                                const itemIds = pharmacyDetails?.filter(
                                  ({ name }) => name?._id,
                                ).map(({ name }) => name?._id);

                                return {
                                  department: { _id: { $in: departmentId } },
                                  isVaccine: true,
                                  isScheduleAdded: true,
                                  item: { _id: { $nin: itemIds } },
                                };
                              }
                              ,
                            }),
                            {
                              type: 'text',
                              render: ({ item: { name } }) => (
                                <Text style={{ ...vars.headings.h8 }}>
                                  {name ? name?.isVaccineFree ? 'FREE' : 'PAID' : ''}
                                </Text>
                              ),
                              variant: 'filled',
                              header: 'vaccination.headers.paymentStatus'.getLabel(),
                              editable: false,
                              width: 200,
                            },
                            {
                              type: 'text',
                              render: ({ item: { name } }) => {
                                let color = '';
                                if (name?.stockStatus === vaccineItemStockStatus?.inStock) {
                                  color = vars.colors.warning;
                                }
                                if (name?.stockStatus === vaccineItemStockStatus?.outOfStock) {
                                  color = vars.colors.error;
                                }
                                if (name?.stockStatus === vaccineItemStockStatus?.lowOfStock) {
                                  color = vars.colors.emphasis;
                                }
                                return (
                                  <Text style={{ ...vars.headings.h8, color }}>
                                    {name?.stockStatus}
                                  </Text>
                                );
                              },
                              variant: 'filled',
                              header: 'Status',
                              editable: false,
                              width: 150,
                            },
                            NestedAction(),
                          ],
                        },
                      },
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'vaccination.buttons.completed'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default VaccineDetailModalForPatient;
