import React from 'react';

import { DateFilter } from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { MODELS } from '../../../../../Lib/constants';
import { countUri } from '../../../../../Queries/countUri';
import vars from '../../../../../theme/vars';
import BatchUpdateRequestList from '../components/BatchUpdateRequestList';
import LowShelfLifeRequestList from '../components/LowShelfLifeRequestList';
import TransferRequest from '../components/TransferRequestList';
import tabTheme from './tabTheme';

const MSIRequestsTabs = (props) => {
  const {
    lowShelfLifeQueryId,
    batchUpdateRequestsQueryId,
    transferRequestsQueryId,
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      actions={[
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={{
            flexDirection: 'row',
            width: 303,
            height: 36,
            borderWidth: 1,
            padding: 9,
            marginTop: 14,
            marginRight: 40,
            borderRadius: 4,
            borderStyle: 'solid',
            backgroundColor: vars.colors.white,
            borderColor: vars.colors.grey.color6,
          }}
        />,
      ]}
      tabs={{
        lowShelfLife: {
          label: 'Low-Shelf Life',
          screen: LowShelfLifeRequestList,
          screenProps: {
            queryId: lowShelfLifeQueryId,
          },
          countUri: countUri({
            model: MODELS.PHARMACY_BATCH,
            id: lowShelfLifeQueryId || 'pharmacyInventoryBatchLowShelfLifeRequests',
            filter: { isLowShelfLife: true },
          }),
        },
        batchUpdate: {
          label: 'Batch-Update Requests',
          screen: BatchUpdateRequestList,
          screenProps: {
            queryId: batchUpdateRequestsQueryId,
          },
          countUri: countUri({
            model: MODELS.PHARMACY_BATCH_REQUESTS,
            id: batchUpdateRequestsQueryId || 'pharmacyBatchRequestsDataList',
          }),
        },
        transferRequest: {
          label: 'Transfer Requests',
          screen: TransferRequest,
          screenProps: {
            queryId: transferRequestsQueryId,
          },
          countUri: countUri({
            model: MODELS.PHARMACY_TRANSFER_REQUESTS,
            id: transferRequestsQueryId || 'pharmacyTransferRequestsDataList',
          }),
        },
      }}
    />
  );
};
export default MSIRequestsTabs;
