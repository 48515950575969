import React from 'react';

import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { Form } from '../../../../../../app-components/form/Form';
import { EditAssetIndentRequestStatus } from '../../../../../../Queries/asset';

const ViewIndentRequest = (props) =>
  // eslint-disable-next-line no-shadow
  (
    <Form
      {...props}
      type="standard"
      reloadEvent={`reload${MODELS.ASSET_INDENT}`}
      editable={false}
      uri={EditAssetIndentRequestStatus}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.indentNumber'.getLabel(),
                    field: 'indentNumber',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.department'.getLabel(),
                    field: 'department.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.speciality'.getLabel(),
                    field: 'speciality.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.status'.getLabel(),
                    field: 'requestStatus',
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.responseDate'.getLabel(),
                    field: '_updatedOn',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.indentDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'indentDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          paddingLeft: 12,
                          paddingRight: 4,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 2,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'asset.labels.assetName'.getLabel(),
                            label: 'asset.labels.assetName'.getLabel(),
                            variant: 'filled',
                            field: 'item.name',
                            editable: false,
                            width: 120,
                          },
                          {
                            field: 'item.category.name',
                            variant: 'filled',
                            header: 'asset.labels.category'.getLabel(),
                            label: 'asset.labels.category'.getLabel(),
                            editable: false,
                            width: 120,
                          },
                          {
                            field: 'item.subCategory.name',
                            variant: 'filled',
                            header: 'asset.labels.subCategory'.getLabel(),
                            label: 'asset.labels.subCategory'.getLabel(),
                            editable: false,
                            width: 120,
                          },
                          {
                            type: 'text',
                            header: 'asset.labels.stockAvailable'.getLabel(),
                            label: 'asset.labels.stockAvailable'.getLabel(),
                            variant: 'filled',
                            value: (indentDetails) => indentDetails?.item?.item?.stockAvailable[0]?.stockAvailable,
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'text',
                            header: 'asset.labels.reasonForPurchase'.getLabel(),
                            label: 'asset.labels.reasonForPurchase'.getLabel(),
                            variant: 'filled',
                            field: 'reasonForPurchase',
                            editable: false,
                            width: 200,
                          },
                          {
                            type: 'number',
                            header: 'asset.labels.requiredQuantity'.getLabel(),
                            label: 'asset.labels.requiredQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'requiredQuantity',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'asset.labels.providedQuantity'.getLabel(),
                            label: 'asset.labels.providedQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'providedQuantity',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'date',
                            header: 'asset.headers.lastLoanOutDate'.getLabel(),
                            label: 'asset.headers.lastLoanOutDate'.getLabel(),
                            format: 'DD MMM YYYY',
                            field: 'lastIndentDate',
                            variant: 'filled',
                            editable: false,
                            width: 200,
                          },
                          {
                            type: 'text',
                            header: 'asset.headers.lastLoanOutQuantity'.getLabel(),
                            label: 'asset.headers.lastLoanOutQuantity'.getLabel(),
                            field: 'lastIndentQuantity',
                            variant: 'filled',
                            editable: false,
                            width: 200,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
export default ViewIndentRequest;
