import React from 'react';

import { DateFilter } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { bloodUpdationRequestList } from '../../../../Queries/bloodBank';
import { textColorForRequestStatus, timeConversion } from '../../../../Lib/helpers';

const tabListColumns = [
  { width: 14 },
  {
    header: 'bloodBankUpdationRequest.headers.date'.getLabel(),
    type: 'date',
    field: 'issueDate',
    sortable: 'date',
  },
  {
    header: 'bloodBankUpdationRequest.headers.issueDate'.getLabel(),
    type: 'date',
    field: 'issueDate',
    width: 200,
  },
  {
    header: 'bloodBankUpdationRequest.headers.newIssueDate'.getLabel(),
    type: 'date',
    field: 'newDateOfIssue',
    width: 200,
  },
  {
    header: 'bloodBankUpdationRequest.headers.issueTime'.getLabel(),
    type: 'text',
    value: ({ item: { time } = {} } = {}) => timeConversion(time),
    width: 200,
  },
  {
    header: 'bloodBankUpdationRequest.headers.newIssueTime'.getLabel(),
    type: 'text',
    value: ({ item: { newTimeOfIssue } = {} } = {}) => timeConversion(newTimeOfIssue),
  },
  {
    header: 'bloodBankUpdationRequest.headers.status'.getLabel(),
    type: 'text',
    render: ({ item }) => textColorForRequestStatus(item),
  },
];

const BloodUpdationRequestList = (props) => {
  const {
    data, id, model, addOnFilter, tableColumns = tabListColumns, ...restProps
  } = props;

  return (
    <Table
      uri={() => bloodUpdationRequestList()}
      model={MODELS.BLOOD_ISSUE_UPDATION_REQUEST}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent={`reload${MODELS.BLOOD_ISSUE_UPDATION_REQUEST}`}
      header={{
        title: () => <HeaderTitleWithCount label="MS Requests " />,
        actions: [
          () => (
            <FtsSearch
              {...props}
              action={{
                exp: ['patient.patientName', 'doctorName.firstName', 'department.name', 'status'],
              }}
            />
          ),
          <DateFilter field="date" key="date" />,
        ],
      }}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewIssueBloodUpdationRequest.name,
          params: { item },
        },
      })}
      {...restProps}
    />
  );
};
export default BloodUpdationRequestList;
