import AddConsultation from '../PatientManagement/PatientRegistration/AddConsultation';
import PatientRegistrations from '../PatientManagement/PatientRegistration';
import EditDoctorModal from './SessionTabs/Components/EditDoctorModal';
import PatientList from '../PatientManagement/PatientList';
import ViewActiveSession from './ViewActiveSession';
import StartSessionForm from './StartSession';
import { ROUTES } from '../../../Lib/constants';
import EndSessionModal from './SessionTabs/Components/EndSessionModal';
import ExistingPatientModal from '../../../Wired/ExsitingPatientModal/ExistingPatient';

export default {
  [ROUTES.patientTokenGeneration.name]: { screen: StartSessionForm, expanded: true },
  [ROUTES.PatientRegistrations.name]: { screen: PatientRegistrations, expanded: true },
  [ROUTES.existingPatientModal.name]: { screen: ExistingPatientModal, expanded: true },
  [ROUTES.PatientListOPD.name]: { screen: PatientList, expanded: true },
  addConsultation: { screen: AddConsultation, expanded: true },
  [ROUTES.editDoctorModal.name]: { screen: EditDoctorModal, expanded: true },
  [ROUTES.viewActiveSession.name]: { screen: ViewActiveSession, expanded: true },
  [ROUTES.endSessionModal.name]: { screen: EndSessionModal },
};
