import { getDownloadUrl } from '../../../../../../AppServices';
import { getString } from '../../../../../../Lib/helpers';

export const durationForAttendance = (field) => ({ item }) => {
  const value = (item && item[field]) || null;
  if (!value) return null;
  let duration = '';
  const defaultDuration = 60;
  const hours = value && value / defaultDuration;
  const minute = value && value % defaultDuration;
  if (hours && hours > 0) duration += `${hours.toFixed(0)}h `;
  if (minute) duration += `${minute.toFixed(0)}m`;
  return duration;
};

export const MonthLayout = {
  header: 'MONTH',
  type: 'text',
  value: ({ item }) => (item && item.month_id && item.month_id.name) || '--',
};

export const AvailableLayout = {
  type: 'number',
  header: 'AVAILABLE',
  modifyValue: ({ value }) => ((value && Number(value)) || 0) + 1.5,
  toFixed: 1,
  field: 'leaveDetails.opening_leave_balance',
};

export const ConsumedLayout = {
  type: 'number',
  field: 'leaveDetails.consumed_leaves',
  header: 'CONSUMED',
  defaultText: 0,
  toFixed: 1,
};

export const BalanceLayout = {
  type: 'number',
  header: 'BALANCE',
  field: 'leaveDetails.leave_balance',
  defaultText: 0,
  toFixed: 1,
};

export const AttendanceDateLayout = {
  header: 'DATE',
  type: 'date',
  field: 'attendance_date',
};

export const FirstHalfLayout = {
  header: 'FIRST HALF',
  type: 'text',
  value: ({ item }) => item?.first_attendance_type_id?.name,
};

export const LeaveTypeLayout = {
  header: 'Leave Type',
  type: 'text',
  value: ({ item }) => item?.leave_type_id?.name,
};

export const SecondHalfLayout = {
  header: 'SECOND HALF',
  type: 'text',
  value: ({ item }) => item?.second_attendance_type_id?.name,
};

export const IsApprovedStatusLayout = {
  header: 'Status',
  type: 'text',
  value: ({ item }) => (item && item.is_approved && 'Approved') || 'Unapproved',
};

export const FirstHalf = {
  header: 'FIRST HALF',
  type: 'text',
  field: 'first_attendance_type_id.name',
};

export const SecondHalf = {
  header: 'SECOND HALF',
  type: 'text',
  field: 'second_attendance_type_id.name',
};

export const ReasonLayout = {
  header: 'Reason',
  type: 'text',
  field: 'reason',
};

export const RemarksLayout = {
  header: 'REMARK',
  type: 'text',
  field: 'remarks',
};

export const employeeNameLayout = {
  header: 'Employee',
  type: 'text',
  field: 'employee.name',
};
export const employeeNameAndEmailLayout = {
  header: 'Employee',
  type: 'card',
  width: 200,
  left: [
    {
      avatar: true,
      field: 'employee.name',
      iconField: 'employee.photo',
      resolveIcon: getDownloadUrl,
    },
  ],
  title: {
    primaryField: 'employee.name',
  },
  contentItems: [
    {
      value: ({ item }) => item && item.employee && item.employee.official_email_id,
    },
  ],
};
export const emailLayout = {
  header: 'Email',
  type: 'text',
  field: 'employee.official_email_id',
};

export const FromDateLayout = {
  header: 'From Date',
  type: 'date',
  field: 'from_date',
  sortable: true,
};

export const ToDateLayout = {
  header: 'To Date',
  type: 'date',
  field: 'to_date',
  sortable: true,
};

export const RequestedToLayout = {
  header: 'Requested To',
  type: 'text',
  value: ({ item }) => item?.reporting_to && item?.reporting_to[0] && item?.reporting_to[0].name,
};

export const RequestedOnLayout = {
  header: 'Requested On',
  type: 'date',
  field: 'request_date',
  sortable: 'request_date',
};

const getLeaveDuration = (value) => {
  if (value === 'full_day') {
    return 'Full Day';
  } if (value === 'half_day') {
    return 'Half Day';
  } if (value === 'short_leave') {
    return 'Short Leave';
  }

  return '';
};

export const durationOfLeaveLayout = {
  header: 'Duration',
  type: 'text',
  field: 'duration_of_leave',
  value: ({ item }) => getLeaveDuration(item.duration_of_leave),
};

export const StatusLayout = {
  header: 'Status',
  type: 'text',
  field: 'status',
  value: ({ item }) => (item && item.status === 'approved' && 'Approved') || 'Unapproved',
};

export const userNameLayout = {
  header: 'Employee',
  type: 'text',
  value: ({ item }) => getString([item?.user?.firstName, item?.user?.lastName]),
  sortable: 'user.firstName',
};

export const employeeIdLayout = {
  header: 'Employee Code',
  type: 'text',
  value: ({ item }) => item?.user?.employeeId,
  sortable: 'user.firstName',
};
