import React from 'react';
import { upperFirst } from 'lodash';

import { Text } from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { inventoryType, MODELS, ROUTES } from '../../../../../Lib/constants';
import { purchaseRequestListAsset } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';

const tabListColumns = [
  { width: 14 },
  {
    header: 'purchase.header.itemName'.getLabel(),
    type: 'text',
    field: 'purchaseRequestAsset.item.name',
  },
  {
    header: 'purchase.header.requestNumber'.getLabel(),
    type: 'text',
    field: 'purchaseRequestAsset.purchaseRequestNumber',
  },
  {
    header: 'purchase.header.dateRaised'.getLabel(),
    type: 'date',
    field: '_createdOn',
    sortable: true,
  },
  {
    header: 'purchase.header.type'.getLabel(),
    type: 'text',
    render: ({ item }) => (
      <Text
        style={{
          ...vars.headings.h9,
        }}
      >
        {upperFirst(item?.subType)}
      </Text>
    ),
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    render: ({ item: { status = '' } }) => {
      let statusColor = '';
      if (status === 'raised') {
        statusColor = vars.colors.emphasis;
      } else if (status === 'Order  Received') {
        statusColor = vars.colors.primary.color2;
      } else if (status === 'PO Rejected' || status === 'SO Rejected') {
        statusColor = vars.colors.error;
      } else {
        statusColor = vars.colors.warning;
      }
      return (
        <Text style={{ ...vars.headings.h8, color: statusColor }}>
          {upperFirst(status)}
        </Text>
      );
    },
  },
  {
    header: 'purchase.header.action'.getLabel(),
    type: 'openActions',
    openActions: [
      {
        render: () => (
          <Text style={{ ...vars.headings.h8, color: vars.colors.secondary.color2 }}>View</Text>
        ),
        type: 'link',
        link: ({ item }) => ({
          view: ROUTES.viewPurchaseRequestAsset.name,
          params: { item },
        }),
      },
    ],
  },
];

const AssetPurchaseRequestList = (props) => {
  const { tableColumns = tabListColumns } = props;

  return (
    <Table
      lg={{ columns: tableColumns }}
      uri={purchaseRequestListAsset({
        filter: {
          type: inventoryType.asset,
          subType: inventoryType.subTypePurchase,
        },
      })}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPurchaseRequestAsset.name,
          params: { item },
        },
      })}
      {...props}
    />
  );
};
export default AssetPurchaseRequestList;
