import React from 'react';

import { NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
// import { editIpdOrderUri } from '../../../../../../../../Queries/ipdOrders';
import { Form } from '../../../../../../../../app-components/form/Form';
import { Text } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';
import { MODELS } from '../../../../../../../../Lib/constants';
import {
  advice, anyOtherComplaint,
  anySignificantEvent, appetite,
  bloodInfusionNotes, bloodTransfusionNotes,
  changeBedCategory, doctorNotes, entScreen, notes, Orthopedic, paExamination,
  physioAdvice, progressDate, progressEnt, progressGc,
  progression, pvExamination, remark,
  roomCategories, sleep, vitals,
  vitalsBowelSound,
  vitalsDistalNeurologicalStatus,
  vitalsDistalPulse, vitalsDrainOutput,
  vitalsRtOutput,
} from '../../../fieldConfiguration';
import { viewProgressReportUri } from '../../../../../../../../Queries/progressReport';

const ViewProgressDetail = (props) => {
  const { navigation: { state: { params: { addOnFilter: { dataParams } } } } } = props;
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          uri={viewProgressReportUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: false,
                            format: 'DD MM YYYY',
                            visible: progressDate[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'progressGc',
                            label: 'GC',
                            editable: false,
                            mandatory: true,
                            visible: progressGc[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'doctorOnRound.name',
                            label: 'Doctor On Round',
                            mandatory: true,
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: vitals[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter]
                    || vitals[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsInTake',
                            label: 'InTake',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsUrinalOutput',
                            label: 'Urinal Output',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBowelSound',
                            mandatory: true,
                            label: 'Bowel Sound',
                            editable: false,
                            visible: vitalsBowelSound[dataParams?.filters?.specialityFilter],

                          },
                          {
                            type: 'text',
                            field: 'vitalsDrainOutput',
                            label: 'Drain Output',
                            editable: false,
                            visible: vitalsDrainOutput[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsRtOutput',
                            label: 'RT Output',
                            mandatory: true,
                            editable: false,
                            visible: vitalsRtOutput[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsDistalPulse',
                            label: 'Distal Pulse',
                            mandatory: true,
                            editable: false,
                            visible: vitalsDistalPulse[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsDistalNeurologicalStatus',
                            label: 'Distal Neurological Status',
                            mandatory: true,
                            editable: false,
                            visible: vitalsDistalNeurologicalStatus[dataParams
                              ?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'advice',
                            label: 'Advice',
                            editable: false,
                            visible: advice[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'physioAdvice',
                            label: 'Physio Advice',
                            editable: false,
                            visible: physioAdvice[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'anyOtherComplaint',
                            label: 'Any Other Complaint',
                            editable: false,
                            visible: anyOtherComplaint[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'doctorNotes',
                            label: 'Doctor Notes',
                            allowWhiteSpaces: true,
                            editable: false,
                            visible: doctorNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'localSiteExamination',
                            label: 'Local Site Examination',
                            allowWhiteSpaces: true,
                            editable: false,
                            visible: Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'bloodInfusionNotes',
                            label: 'Blood Infusion Notes',
                            editable: false,
                            visible: bloodInfusionNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'P/A Examination',
                            visible: paExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'paExamination',
                            label: 'P/A Examination',
                            editable: false,
                            visible: paExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'P/V Examination',
                            visible: pvExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'pvExamination',
                            label: 'P/V Examination',
                            editable: false,
                            visible: pvExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Progression',
                            visible: progression[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'progression',
                            label: 'Progression',
                            editable: false,
                            visible: progression[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Sleep',
                            visible: sleep[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'sleep',
                            label: 'Sleep',
                            editable: false,
                            visible: sleep[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Appetite',
                            visible: appetite[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'appetite',
                            label: 'Appetite',
                            editable: false,
                            visible: appetite[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Any Significant Event',
                            visible: anySignificantEvent[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'anySignificantEvent',
                            label: 'Any Significant Event',
                            editable: false,
                            visible: anySignificantEvent[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Notes',
                            visible: notes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'notes',
                            label: 'Notes',
                            editable: false,
                            visible: notes[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            editable: false,
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowColumnTextStyle: {
                                  ...vars.headings.h10,
                                  color: vars.colors.grey.color3,
                                  numberOfLines: 1,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: false,
                                    width: 250,
                                    header: 'Drug',
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    mandatory: true,
                                    editable: false,
                                    width: 150,
                                    header: 'Route',
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    mandatory: true,
                                    editable: false,
                                    width: 100,
                                    header: 'Dose',
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: false,
                                    mandatory: true,
                                    width: 200,
                                    header: 'Frequency',
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'specialInstructions',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    editable: false,
                                    width: 312,
                                    header: 'Description',
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'text',
                            field: 'transferTo.name',
                            label: 'Transfer To',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'roomCategories.name',
                            label: 'Rooms Category',
                            editable: false,
                            visible: roomCategories[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'transferBedNumber.name',
                            label: 'Rooms',
                            editable: false,
                            visible: roomCategories[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'assignTO.name',
                            label: 'Assign To',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Change Bed Category </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'text',
                            field: 'changeBedCategory.name',
                            label: 'Category',
                            visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'changeBedNumber.name',
                            label: 'Bed Number',
                            visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => 'Remark',
                            visible: remark[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'remark',
                            label: 'Remark',
                            editable: false,
                            visible: remark[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Blood Transfusion Notes',
                            visible: bloodTransfusionNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'textArea',
                            minHeight: 80,
                            field: 'bloodTransfusionNotes',
                            label: 'Blood Transfusion Notes',
                            editable: false,
                            visible: bloodTransfusionNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Progress',
                            visible: progressEnt[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'textArea',
                            minHeight: 80,
                            field: 'progressEnt',
                            editable: false,
                            visible: progressEnt[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />
  );
};

const ViewProgressReport = (props) => (<ViewProgressDetail {...props} />);
export default ViewProgressReport;

