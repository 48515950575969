import React, { useEffect, useState } from 'react';
import {
  FlatList, Text, View, TouchableOpacity,
} from '../../../../app-components';
import PermissionsViewsForm from './Permissions';
import { getModulesPermissions } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';

const modules = getModulesPermissions();
const modifiedModule = modules;
const moduleKeys = [
  { name: 'Departments', id: 1 },
  { name: 'Analytics', id: 2 },
  { name: 'MDM', id: 3 },
  { name: 'Inventory', id: 4 },
  { name: 'Billing', id: 5 },
  { name: 'Auction', id: 6 },
  { name: 'Mobile', id: 7 },
];

const PermissionsForm = (props) => {
  const [moduleItem, setModuleItem] = useState('');
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    setSelectedId(1);
    setModuleItem({ name: 'Departments', id: 1 });
  }, []);

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          marginTop: 20,
        }}
      >
        <View style={{ backgroundColor: '#EFF3FB', width: 280, height: 345 }}>
          <FlatList
            data={moduleKeys}
            renderItem={({ item }) => {
              const backgroundColor = item.id === selectedId ? '#4070C9' : '#EFF3FB';
              const color = item.id === selectedId ? 'white' : vars.colors.grey.color2;
              return (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedId(item.id);
                    setModuleItem(item);
                  }}
                  style={{
                    height: 45,
                    cursor: 'pointer',
                  }}
                >
                  <View style={{
                    flex: 1, backgroundColor, justifyContent: 'center',
                  }}
                  >
                    <Text style={{ ...vars.headings.h9, color, marginLeft: 16 }}>
                      {item?.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: 1,
                      width: '100%',
                      backgroundColor: '#E0E0E0',
                    }}
                  />

                </TouchableOpacity>
              );
            }}
            keyExtractor={(_, index) => index.toString()}
          />
        </View>
        <View style={{ flex: 8, marginLeft: 15 }}>
          {moduleItem ? (
            <PermissionsViewsForm
              views={modifiedModule[moduleItem?.name?.toLowerCase()].views}
              headings={modifiedModule[moduleItem?.name?.toLowerCase()].headings}
              subLabel={modifiedModule[moduleItem?.name?.toLowerCase()].subLabel}
              selectAllModuleFormValue={modifiedModule[moduleItem?.name?.toLowerCase()].value}
              {...props}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default PermissionsForm;
