import { upperFirst } from 'lodash';
import MasterForm from '../../../Wired/Master/MasterForm';
import { EditLeaveType } from '../../../Queries/leaveType';
import { MODELS } from '../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'leaveType.labels.leaveName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    allowConsecutiveSpaces: true,
    modifyValueOnChange: ({ value }) => upperFirst(value),
  },
  {
    type: 'text',
    field: 'code',
    label: 'leaveType.labels.leaveId'.getLabel(),
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'leaveType.labels.status'.getLabel(),
    container: 'topLabel',
  },
  {
    type: 'checkBox',
    field: 'isLeaveBalanceReqForApply',
    container: 'rightLabel',
    label: 'Is Leave balance required to apply for this Leave Type',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'Description',
    placeholder: 'masters.labels.description'.getLabel(),
    minHeight: 80,
    allowWhiteSpaces: true,
  },
];

export default MasterForm({
  uri: EditLeaveType,
  validations: {
    name: ({ data }) => {
      if (data.name) {
        const message = validateInputLength(data?.name, 2, 32);
        if (message) return message;
        return validateAlphabetsWithSpaceOnly(data?.name);
      }
    },
  },
  model: MODELS.LEAVE_TYPE,
  submitMessage: 'leaveType.messages.updateMessage'.getLabel(),
  formFields,
});
