import React from 'react';

import { FormHoc } from '../../../../../app-components';
import { submit } from '../../../../../AppServices';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import RelationInput from '../../../../../Components/RelationInput';
import { ageInput } from '../../../../../compositeInputs';
import { MODELS, genders, bloodGroup } from '../../../../../Lib/constants';
import {
  validateAlphabetsWithSpaceOnly,
  validateInputLength,
  validateMultipleFileTypesAllowed,
} from '../../../../../Lib/helpers';
import { viewDonorUri } from '../../../../../Queries/bloodBank';

const validations = {
  contactNo: ({ data }) => validateInputLength(data?.contactNo, 10, 10),
  attachment: ({ data }) => validateMultipleFileTypesAllowed(data?.attachment, ['pdf']),
};
export default FormHoc({
  uri: viewDonorUri,
  type: 'standard',
  closeView: 1,
  mandatory: {
    donorId: 1,
    gender: 1,
    age: 1,
    bloodGroup: 1,
    contactNo: 1,
  },
  submitMessage: 'donorAndDonations.message.edit'.getLabel(),
  onSubmit: submit({
    model: MODELS.BLOOD_DONOR,
  }),
  validations,
  editable: true,
  reloadEvent: `reload${MODELS.BLOOD_DONOR}`,
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'donorId',
                placeholder: 'donorAndDonations.placeholders.donorId'.getLabel(),
                label: 'donorAndDonations.labels.donorId'.getLabel(),
                mandatory: true,
                editable: false,
              },
              {
                type: 'text',
                field: 'name',
                mandatory: true,
                placeholder: 'donorAndDonations.placeholders.donorName'.getLabel(),
                label: 'donorAndDonations.labels.donorName'.getLabel(),
                allowWhiteSpaces: true,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                },
              },
              {
                type: 'custom',
                render: (props) => (
                  <RelationInput
                    {...props}
                    mandatory
                    relationNameProps={{
                      allowWhiteSpaces: true,
                    }}
                  />
                ),
              },
              ageInput({
                age: {
                  type: 'number',
                  field: 'age',
                  label: 'bloodInventory.labels.age'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value > 999) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                ageType: {
                  field: 'ageType',
                },
              }),
              autoSuggestInput({
                label: 'donorAndDonations.labels.gender'.getLabel(),
                field: 'gender',
                suggestionField: 'label',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
                options: genders,
                mandatory: true,
              }),
              autoSuggestInput({
                label: 'donorAndDonations.labels.bloodGroup'.getLabel(),
                field: 'bloodGroup',
                suggestionField: 'label',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
                options: bloodGroup,
                mandatory: true,
              }),
              {
                type: 'number',
                field: 'contactNo',
                label: 'donorAndDonations.labels.mobile'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.mobile'.getLabel(),
                allowedDecimalDigits: false,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 10) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'text',
                field: 'occupation',
                placeholder: 'donorAndDonations.placeholders.occupation'.getLabel(),
                label: 'donorAndDonations.labels.occupation'.getLabel(),
                allowWhiteSpaces: true,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                },
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 2,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'address',
                label: 'patient.labels.address'.getLabel(),
                placeholder: 'patient.placeholders.address'.getLabel(),
                allowWhiteSpaces: true,
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 3,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'description',
                label: 'donorAndDonations.labels.additionalInfo'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.additionalInfo'.getLabel(),
                allowWhiteSpaces: true,
              },
              {
                type: 'file',
                field: 'attachment',
                label: 'donorAndDonations.labels.file'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.file'.getLabel(),
                multiple: true,
                allowWhiteSpaces: true,
              },
            ],
          },
        ],
      },
    ],
  },
});
