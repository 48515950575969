/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';

import {
  ActivityIndicator,
  FormField,
  LargeButton,
  OutlineButton,
  Toast,
  View,
} from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import ListActionModal from '../../../Components/ListActionModal';
import { history } from '../../../images';
import { post } from '../../../AppServices';
import { getErrorString } from '../../../Lib/helpers';
import vars from '../../../theme/vars';

const RejectRequestConfirmationModal = (props) => {
  const {
    navigation,
    eventDispatcher,
  } = props;

  const item = navigation.getParam('item');
  const updates = navigation.getParam('updates');
  const model = navigation.getParam('model');
  const reloadEvent = navigation.getParam('reloadEvent');
  const successMessage = navigation.getParam('successMessage');
  const title = navigation.getParam('title');
  const message = navigation.getParam('message');
  const postMessage = navigation.getParam('postMessage');

  const [loading, setLoading] = useState();

  const handleOnPressAction = useCallback(async ({ data = {} }) => {
    const { reason } = data;
    try {
      setLoading(true);

      const result = await post({
        data: item,
        updates: {
          ...updates,
          reason,
        },
        model,
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: successMessage,
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify(reloadEvent);
      }
      setLoading(false);
      navigation.pop();
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      navigation.pop();
      setLoading(false);
    }
  }, [eventDispatcher, item, model, navigation, reloadEvent, successMessage, updates]);

  if (loading) return <ActivityIndicator />;

  return (
    <ListActionModal
      icon={history}
      title={title}
      description={message}
      content={(
        <Form
          onSubmit={handleOnPressAction}
          mandatory={{
            reason: 1,
          }}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          submitMessage={postMessage}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <>
                  <FormField
                    type="textArea"
                    field="reason"
                    placeholder="Enter Reason"
                    label="Enter Reason"
                    variant="filled"
                    mandatory
                  />
                  <View
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <OutlineButton
                      label="Cancel"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                      onPress={navigation.pop}
                    />
                    <LargeButton
                      label="Submit"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default RejectRequestConfirmationModal;
