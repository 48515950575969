import { MODELS } from '../Lib/constants';

export const viewECTNotesUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_ECT_NOTES,
    id: 'ECTNotesList',
    addOnFilter: {
      ipdPatient: { _id: navigation?.state?.params?.patient?._id },
    },
  },
  aggregate: true,
  model: MODELS.IPD_ECT_NOTES,
});

export const viewNoteUri = ({ navigation }) => {
  const { state: { params: { addOnFilter = {} } } } = navigation;
  return ({
    query: {
      model: MODELS.IPD_ECT_NOTES,
      id: 'ECTNotesList',
      addOnFilter,
    },
    model: MODELS.IPD_ECT_NOTES,
  });
};
