import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExamination = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  {...autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'historyTakenBy',
                      label: 'History Taken By',
                      placeholder: 'History Taken By',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    // {
                    //   addOnFilter: () => ({
                    //     role: {
                    //       code: 'Supervisor',
                    //     },
                    //   }),
                    // }
                  )}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}
            </View>
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                width: 800,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="informant"
                  variant="filled"
                  label="Informant"
                  container="topLabel"
                  options={['Reliable', 'Non-Reliable']}
                  mandatory
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="information"
                  variant="filled"
                  label="Information"
                  container="topLabel"
                  options={['Adequate', 'Inadequate']}
                  mandatory
                />
              </View>
            </View>

            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Chief Complaints
              </Text>

              <FormField
                {...{
                  field: 'cheifComplaint',
                  fieldType: 'nested',
                  addInBottom: true,
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        {
                          type: 'textArea',
                          field: 'complaint',
                          variant: 'filled',
                          placeholder: 'Enter Complaint Here',
                          allowWhiteSpaces: true,
                          editable: true,
                        },
                        NestedAction({
                          containerStyle: {
                            marginTop: 25,
                          },
                        }),
                      ],
                    },
                  },
                }}
              />
            </View>

            {/* History Of Present Illness */}
            <View
              style={{
                width: '50%',
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;History Of Present Illness
              </Text>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  a.&nbsp;&nbsp; Duration
                </Text>
                <FormField
                  type="text"
                  field="duration"
                  container="rightLabel"
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  style={{
                    width: 700,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  b.&nbsp;&nbsp;Onset
                </Text>
                <FormField
                  type="text"
                  field="onSet"
                  container="rightLabel"
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  style={{
                    width: 700,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  c.&nbsp;&nbsp;Cause
                </Text>
                <FormField
                  type="text"
                  field="cause"
                  container="rightLabel"
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 700,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 100,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,

                  display: 'block',
                }}
              >
                Treatment History
              </Text>
              <FormField
                type="textArea"
                field="treatmentHistory"
                variant="filled"
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Family */}
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                c.&nbsp;&nbsp;Family
              </Text>
              <PastIllnessRow checkBoxLabel="HTN" fieldLabel="familyHtn" />
              <PastIllnessRow checkBoxLabel="DM" fieldLabel="familyDm" />
              <PastIllnessRow checkBoxLabel="TB" fieldLabel="familyTb" />
              <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="familySteroid" />
              <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="familyThyroid" />
              <PastIllnessRow checkBoxLabel="COPD" fieldLabel="familyCopd" />
              <PastIllnessRow checkBoxLabel="Covid" fieldLabel="familyCovid" />
              <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="familyAsthma" />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    width: 100,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="familyOther"
                    label="Other"
                    container="rightLabel"
                    variant="filled"
                    editable
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    color: vars.colors.grey.color3,
                    width: 150,
                    ...vars.headings.h14,
                  }}
                >
                  If Yes, mention illness
                </Text>
                <FormField
                  type="textArea"
                  field="pastIllness.familyOtherTreatment"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { pastIllness = {} } = {} } = disableProps;
                    return !pastIllness.other;
                  }}
                  editable
                  variant="filled"
                  style={{
                    width: 1500,
                    // minHeight: 30,
                    marginBottom: 5,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,

                  display: 'block',
                }}
              >
                Family Notes
              </Text>
              <FormField
                type="textArea"
                field="familyNotes"
                variant="filled"
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  d.&nbsp;&nbsp;Personal
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    i.&nbsp;&nbsp;Appetite
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="personalData.appetite"
                    container="rightLabel"
                    editable
                    variant="filled"
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ii.&nbsp;&nbsp;Diet
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="personalData.diet"
                    variant="filled"
                    container="rightLabel"
                    options={['Non-Veg', 'Veg']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iii.&nbsp;&nbsp;Sleep
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.sleep"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Addiction
                  </Text>
                  <View
                    style={{
                      width: 100,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="personalData.addictionSmoking"
                      label="Smoking"
                      container="rightLabel"
                      variant="filled"
                      style={{
                        marginTop: 10,
                      }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="personalData.addictionAlcohol"
                        label="Alcohol"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: 131,
                        }}
                      >
                        <FormField
                          type="checkBox"
                          field="personalData.addictionOther"
                          label="Other Drugs"
                          container="rightLabel"
                          variant="filled"
                          labelStyle={{ ...vars.headings.h14 }}
                        />
                      </View>
                      <FormField
                        type="text"
                        field="personalData.addictionOtherText"
                        container="rightLabel"
                        variant="filled"
                        editable
                        allowWhiteSpaces
                        disabled={(disableProps) => {
                          const { data: { personalData = {} } = {} } = disableProps;
                          return !personalData.addictionOther;
                        }}
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          // marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Bowel & Bladder
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bowelAndBladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Other
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.personalOther"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  e.&nbsp;&nbsp;Immnization
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="immunization.bcg"
                    label="BCG"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.hepb"
                      label="HEBP"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.covid"
                      label="Covid"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.polio"
                      label="Polio"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.pneumococcal"
                      label="Pneumococcal"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: 131,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="immunization.immunizationOther"
                        label="Other"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="text"
                      field="immunization.immunizationOtherText"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { immunization = {} } = {} } = disableProps;
                        return !immunization.immunizationOther;
                      }}
                      container="rightLabel"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        // marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Drug Allergies & Obsteric & Menstruation  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Drug Allergies */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Drug Allergies
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iv.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFour"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    v.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFive"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Obsteric & Menstruation */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  g.&nbsp;&nbsp;Obsteric & Menstruation
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;Gravida
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.gravida"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;Para
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.para"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;Lining
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.lining"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iV.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menarche"
                        label="Menarche"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menarcheAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menarche;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        v.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menopause"
                        label="Menopause"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menopauseAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menopause;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Cutaneous Examination */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Cutaneous Examination
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Skin
              </Text>
              <FormField
                type="text"
                field="cutaneousExamination.skin"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  // fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '30',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Mucosa
              </Text>
              <FormField
                type="text"
                field="cutaneousExamination.mocosa"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}

              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Nail
              </Text>
              <FormField
                type="text"
                field="cutaneousExamination.nail"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}

              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Hair
              </Text>
              <FormField
                type="text"
                field="cutaneousExamination.hair"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}

              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Genitals
              </Text>
              <FormField
                type="text"
                field="cutaneousExamination.genitals"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}

              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Percentage of Body Surface Area Involved (%)
              </Text>
              <FormField
                type="number"
                field="percentageOfBodySurface"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  width: 100,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}

              />
            </View>

            {/* Examination */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Examination
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* GPE */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    GPE Head To Be
                  </Text>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      marginTop: 15,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        a.&nbsp;&nbsp;Time
                      </Text>
                    </View>
                    <FormField
                      // variant="filled"
                      {...newDateInput({
                        hour: { field: 'gpe.hour', label: 'Hour' },
                        minute: { field: 'gpe.minute', label: 'Minute' },
                        meridiem: { field: 'gpe.meridiem' },
                      })}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        b.&nbsp;&nbsp;Place
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.place"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        c.&nbsp;&nbsp;Person
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.person"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Orientation
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.orientation"
                      variant="filled"
                      container="rightLabel"
                      options={['Conscious', 'Unconscious']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        e.&nbsp;&nbsp;Built
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.built"
                      variant="filled"
                      container="rightLabel"
                      options={['Poor', 'Moderate', 'Well']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        f.&nbsp;&nbsp;Nourishment
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.nourishment"
                      variant="filled"
                      container="rightLabel"
                      options={['Poor', 'Well']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  {/* {Vitals} */}
                  <View
                    style={{
                      width: '100%',
                      marginTop: 10,
                      // marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      Vitals
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        a.&nbsp;&nbsp;PR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.pr"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        b.&nbsp;&nbsp;BP
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.bp"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        c.&nbsp;&nbsp;RR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.rr"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Temperature
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.temperature"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        e.&nbsp;&nbsp;SPO2
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.spo2"
                        container="rightLabel"
                        variant="filled"
                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      marginTop: 10,
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h9,

                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      Respiratory
                    </Text>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        marginTop: 15,
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,

                          marginTop: 15,
                          width: 190,
                        }}
                      >
                        a.&nbsp;&nbsp;Inspection
                      </Text>
                      <FormField
                        type="text"
                        allowWhiteSpaces
                        field="respiratory.inspection"
                        container="rightLabel"
                        variant="filled"
                        editable
                        style={{
                          width: 600,
                          fontSize: 14,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        marginTop: 15,
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,

                          marginTop: 15,
                          width: 190,
                        }}
                      >
                        b.&nbsp;&nbsp;Palpation
                      </Text>
                      <FormField
                        type="text"
                        allowWhiteSpaces
                        field="respiratory.palpation"
                        container="rightLabel"
                        variant="filled"
                        editable
                        style={{
                          width: 600,
                          fontSize: 14,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        marginTop: 15,
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,

                          marginTop: 15,
                          width: 190,
                        }}
                      >
                        c.&nbsp;&nbsp;Percussion
                      </Text>
                      <FormField
                        type="text"
                        allowWhiteSpaces
                        field="respiratory.percussion"
                        container="rightLabel"
                        variant="filled"
                        editable
                        style={{
                          width: 600,
                          fontSize: 14,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        marginTop: 15,
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,

                          marginTop: 15,
                          width: 190,
                        }}
                      >
                        d.&nbsp;&nbsp;Auscultation
                      </Text>
                      <FormField
                        type="text"
                        allowWhiteSpaces
                        field="respiratory.ausculation"
                        container="rightLabel"
                        variant="filled"
                        editable
                        style={{
                          width: 600,
                          fontSize: 14,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>

                  </View>
                </View>

                {/* Systematic Examination */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Cardio Vascular
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        a.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.icterus"
                        label="Icterus"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        b.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.cynosis"
                        label="Cyanosis"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        c.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.clubbing"
                        label="Clubbing"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.koilonychia"
                        label="Koilonychia"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        e.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.lymphadenopathy"
                        label="Lymphadenopathy"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        f.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.edema"
                        label="Edema"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 70 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 18,
                        }}
                      >
                        If Yes Then
                      </Text>
                    </View>
                    <View style={{ width: 330, marginTop: 6 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.edemaInput"
                        label="Edema"
                        container="rightLabel"
                        variant="filled"
                        options={['Local', 'General']}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                </View>
              </View>

              {/* Local Examination */}

              {/* Provisional Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Provisional Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="provisionalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              {/* Fianl Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Final Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="finalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExamination;
