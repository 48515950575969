import { Table } from '../../../app-components';
import { Layout } from './layouts';
import { CommonTaxDetailsUri } from './uri';
import { MODELS, ROUTES } from '../../../Lib/constants';

export const SavingHeadList = Table({
  listVersion: 2,
  uri: CommonTaxDetailsUri({ query: 'savingHead', model: MODELS.SAVING_HEAD }, {}),
  reloadEvent: 'SavingHeadList',
  sm: {
    columns: [Layout.nameLayout, Layout.max_limit],
  },
  md: {
    columns: [
      { width: 14 },
      { ...Layout.nameLayout, width: 450 },
      {
        ...Layout.amount,
        width: 180,
      },
      {
        ...Layout.max_limit,
        width: 180,
      },
      {
        ...Layout.is_salary_paid_base,
        width: 200,
      },
      {
        ...Layout.benefit_percent,
        width: 150,
      },
      {
        ...Layout.action_saving_head,
        width: 80,
      },
    ],
  },
});

export const TaxSlabsList = Table({
  listVersion: 2,
  uri: () => CommonTaxDetailsUri({ query: 'TaxSlab', model: MODELS.TAX_SLABS }, {}),
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewTaxSlab.name,
      params: { item },
    },
  }),
  reloadEvent: 'TaxSlabsList',
  sm: {
    columns: [Layout.financial_year],
  },
  md: {
    columns: [
      { width: 14 },
      { ...Layout.financial_year, width: 250 },
      {
        ...Layout.tax_percentage,
        width: 250,
      },
      {
        ...Layout.from_amount,
        width: 250,
      },
      {
        ...Layout.to_amount,
        width: 250,
      },
      {
        ...Layout.action_tax_slabs,
        width: 240,
      },
    ],
  },
});
