/* eslint-disable react/jsx-key */
import React from 'react';
import { FilterGroup } from '../../../../../../../app-components';
import { TabNavigator } from '../../../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../../../../../images';
import { assetStatus, MODELS } from '../../../../../../../Lib/constants';
import { countUri } from '../../../../../../../Queries/countUri';
import { dropDownShadow } from '../../../../../../../theme/shadows';
import ItemWiseStockList from './ItemWiseList';

const ViewAssetTabs = (props) => {
  const { navigation: { state: { params: { item: { item, department, speciality } = {} } = {} } = {} } = {} } = props;
  return (
    <TabNavigator
      {...props}
      tabs={{
        itemWiseDistribution: {
          label: 'Item-wise Distribution',
          screen: ItemWiseStockList,
          screenProps: {
            model: MODELS.ASSET,
            id: 'assetInventoryDataList',
            addOnFilter: {
              item: { _id: item?._id },
              isCentral: false,
              department: { _id: department?._id },
              speciality: { _id: speciality?._id },
              assetStatus: { $ne: 'inAuction' },
            },
          },
          actions: [
            <FtsSearch action={{ exp: ['serialNumber'] }} key="search" />,
            FilterGroup({
              dropdownStyle: {
                width: 300,
                height: 300,
                backgroundColor: 'red',
                ...dropDownShadow,
                borderRadius: 8,
                overflow: 'hidden',
              },
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: assetStatus,
                  field: 'assetStatus',
                  label: 'Stock Status',
                  multiSelect: false,
                },
                {
                  type: 'autoSuggest',
                  field: 'batchNumber.manufacturer',
                  label: 'Manufacturers',
                  model: MODELS.PHARMACY_MANUFACTURER,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Manufacturer',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoSuggest',
                  field: 'batchNumber.supplier',
                  label: 'Supplier',
                  model: MODELS.PHARMACY_SUPPLIER,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Supplier',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
              ],
            }),
          ],
          countUri: countUri({
            model: MODELS.ASSET,
            id: 'assetInventoryDataList',
            filter: {
              item: { _id: item?._id },
              isCentral: false,
              department: { _id: department?._id },
              speciality: { _id: speciality?._id },
              assetStatus: { $ne: 'inAuction' },
            },
          }),
        },
      }}
    />
  );
};
export default ViewAssetTabs;
