import React from 'react';

import { DateFilter, TabNavigator } from '../../../../../app-components';
import IndentRequestList from './RaisedIndentList/IndentList';
import childTabTheme from '../../Themes/tabTheme';
import vars from '../../../../../theme/vars';
import Repair from './RaiseRepairList/Repair';

const AssetDepartmentInventoryRequestsTabs = TabNavigator({
  theme: childTabTheme,
  actions: [
    <DateFilter
      field="_createdOn"
      key="_createdOn"
      containerStyle={
        {
          flexDirection: 'row',
          width: 303,
          height: 30,
          borderWidth: 1,
          padding: 9,
          marginTop: 7,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }
      }
    />,
  ],
  tabs: {
    IndentRequests: {
      label: 'Indent Requests',
      screen: IndentRequestList,
    },
    RepairRequests: {
      label: 'Repair Requests',
      screen: Repair,
    },
  },
});

export default AssetDepartmentInventoryRequestsTabs;
