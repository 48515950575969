import React, { useCallback, useEffect, useState } from 'react';
import { invoke } from '../../../../../AppServices';
import { FormField, Toast } from '../../../../../app-components';
import { getErrorString } from '../../../../../Lib/helpers';

const RemarksInput = (props) => {
  const {
    patientDetail, consultationId, remarks,
  } = props;

  const [value, setValue] = useState(remarks);

  useEffect(() => {
    setValue(remarks);
  }, [consultationId]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleBlur = useCallback(async () => {
    try {
      await invoke({
        id: 'assignRemark',
        paramValue: {
          remarks: value,
          item: patientDetail,
          consultationId,
        },
      });
    } catch (err) {
      setValue(null);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [consultationId, patientDetail, value]);

  return (
    <FormField
      value={value}
      placeholder="Remarks"
      label="Remarks"
      topLabel="Remarks"
      variant="filled"
      name="remarks"
      type="textArea"
      minHeight={100}
      onBlur={handleBlur}
      onChange={handleChange}
      inputProps={{
        type: 'textArea',
      }}
    />
  );
};

export default RemarksInput;
