import React from 'react';

import {
  View, LargeButton,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import ListActionModal from '../../../../../Components/ListActionModal';
import { notificationError } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import { submit } from '../../../../../AppServices';

const LowShelfLifeContent = (props) => {
  const { navigation: { state: { params: { data } } } } = props;

  return (
    <View style={{ flex: 1, paddingLeft: 40, paddingRight: 40 }}>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        closeView={2}
        reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
        defaultValues={() => data}
        onSubmit={
          submit({
            model: MODELS.PHARMACY_BATCH,
          })
        }
        submitMessage={'inventory.messages.lowShelfLifeApproval'.getLabel()}
        {...props}
      >
        {({ form_context: formContext }) => (
          <View style={{ flexDirection: 'row', marginTop: 38, justifyContent: 'space-around' }}>
            <View>
              <LargeButton
                label="Send For Approval"
                width={170}
                onClick={formContext?.handleSubmit}
              />
            </View>
          </View>
        )}
      </Form>
    </View>
  );
};
const LowShelfLifeModal = (props) => (
  <ListActionModal
    icon={notificationError}
    title={'inventory.title.lowShelfLifeTitle'.getLabel()}
    description={'inventory.title.lowShelfLifeDescription'.getLabel()}
    content={<LowShelfLifeContent {...props} />}
    {...props}
  />
);

export default LowShelfLifeModal;
