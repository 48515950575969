import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength } from '../../../Lib/helpers';

const validations = {
  code: ({ data }) => validateInputLength(data?.code, 2, 20),
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'department.labels.departmentName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    allowConsecutiveSpaces: true,
  },
  {
    type: 'text',
    field: 'code',
    label: 'department.labels.departmentId'.getLabel(),
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'department.labels.status'.getLabel(),
    placeholder: 'Select',
    container: 'leftLabel',
    align: 'center',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'department.labels.description'.getLabel(),
    allowWhiteSpaces: true,
    minHeight: 80,
  },
  {
    type: 'toggleSwitch',
    showOuterLabel: false,
    field: 'trackBeds',
    label: 'department.labels.containNumberOfBeds'.getLabel(),
    container: 'leftLabel',
  },
];

export default MasterForm({
  model: MODELS.DEPARTMENTS,
  formFields,
  validations,
  submitMessage: 'department.messages.departmentAddedSuccessfully'.getLabel(),
  uniqueValidationMessage: 'department.messages.departmentAlreadyExists'.getLabel(),
  mandatory: {
    name: 1,
    code: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
