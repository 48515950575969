import React, { useCallback, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  ActivityIndicator, Toast, View,
} from '../../../app-components';
import ConsultationLogsTableModal from '../../../Wired/ConsultationLogTableModal';
import VaccinationLogsTableModal from '../../../Wired/VaccinationLogsTableModal';
import NoShiftAssigned from './Components/NoShiftAssigned';
import { getErrorString } from '../../../Lib/helpers';
import SessionInfo from './Components/SessionInfo';
import { ROUTES } from '../../../Lib/constants';
import SessionTabs from './tabs';
import { getUser, gpsStore, invoke } from '../../../AppServices';
import AddDiagnosis from './ConsultedPatients/Components/addDiagnosisModal';

const IncomingPatients = (props) => {
  const user = getUser() || {};

  const { employee: { _id: employeeId } = {} } = user;

  const [loading, setLoading] = useState(true);
  const [sessionQueueInfo, setSessionQueueInfo] = useState();

  const getMySessionQueue = useCallback(async () => {
    try {
      const fetchedSessionQueueDetails = await invoke({
        id: 'getMyQueue',
      });

      if (!isEmpty(fetchedSessionQueueDetails)) {
        setSessionQueueInfo(fetchedSessionQueueDetails);
      } else {
        setSessionQueueInfo(null);
      }
      setLoading(false);
      return fetchedSessionQueueDetails;
    } catch (err) {
      setLoading(false);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      return err;
    }
  }, []);

  const onSessionQueueAssigned = useCallback(() => {
    Toast.show({
      message: 'Success',
      description: 'Room has been assigned from you',
      type: 'success',
      position: 'top',
      direction: 'right',
    });
    getMySessionQueue();
  }, [getMySessionQueue]);

  const onSessionQueueUnAssigned = useCallback(() => {
    Toast.show({
      message: 'Success',
      description: 'Room has been unassigned from you',
      type: 'success',
      position: 'top',
      direction: 'right',
    });
    getMySessionQueue();
  }, [getMySessionQueue]);

  const onSessionQueueEnded = useCallback(() => {
    Toast.show({
      message: 'Success',
      description: 'Session Ended.',
      type: 'success',
      position: 'top',
      direction: 'right',
    });
    getMySessionQueue();
  }, [getMySessionQueue]);

  const subscribeToActiveSessionQueueWithDoctorId = useCallback((employee) => {
    gpsStore.subscribeEvent({
      event: `activeSessionQueue-${employee}-updates`,
      callback: onSessionQueueAssigned,
    });
  }, [onSessionQueueAssigned]);

  const unSubscribeToActiveSessionQueueWithDoctorId = useCallback((employee) => {
    gpsStore.unsubscribeEvent({
      event: `activeSessionQueue-${employee}-updates`,
      callback: onSessionQueueAssigned,
    });
  }, [onSessionQueueAssigned]);

  const subscribeToActiveSessionQueueWithQueueId = useCallback((queueId) => {
    gpsStore.subscribeEvent({
      event: `activeSessionQueue-${queueId}-updates`,
      callback: onSessionQueueUnAssigned,
    });
  }, [onSessionQueueUnAssigned]);

  const unSubscribeToActiveSessionQueueWithQueueId = useCallback((queueId) => {
    gpsStore.unsubscribeEvent({
      event: `activeSessionQueue-${queueId}-updates`,
      callback: onSessionQueueUnAssigned,
    });
  }, [onSessionQueueUnAssigned]);

  const subscribeToActiveSessionQueueWithSessionId = useCallback((queueId) => {
    gpsStore.subscribeEvent({
      event: `activeSessionQueue-${queueId}-updates`,
      callback: onSessionQueueEnded,
    });
  }, [onSessionQueueEnded]);

  const unSubscribeToActiveSessionQueueWithSessionId = useCallback((queueId) => {
    gpsStore.unsubscribeEvent({
      event: `activeSessionQueue-${queueId}-updates`,
      callback: onSessionQueueEnded,
    });
  }, [onSessionQueueEnded]);

  useEffect(() => {
    if (employeeId) {
      subscribeToActiveSessionQueueWithDoctorId(employeeId);
    }
    return () => unSubscribeToActiveSessionQueueWithDoctorId();
  }, [employeeId]);

  useEffect(() => {
    if (sessionQueueInfo?._id) {
      subscribeToActiveSessionQueueWithQueueId(sessionQueueInfo?._id);
      if (sessionQueueInfo?.session?._id) {
        subscribeToActiveSessionQueueWithSessionId(sessionQueueInfo?.session?._id);
      }
    }

    return () => {
      unSubscribeToActiveSessionQueueWithQueueId();
      unSubscribeToActiveSessionQueueWithSessionId();
    };
  }, [sessionQueueInfo]);

  useEffect(() => {
    getMySessionQueue();
  }, []);

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{
        height: 44, marginLeft: 16, marginRight: 16, marginTop: 24,
      }}
      >
        {sessionQueueInfo
          && <SessionInfo sessionQueueInfo={sessionQueueInfo} {...props} />}
      </View>
      <View style={{ flex: 1, marginLeft: 8, overflow: 'hidden' }}>
        {sessionQueueInfo ? <SessionTabs {...props} /> : <NoShiftAssigned />}
      </View>
    </View>
  );
};

export default {
  [ROUTES.opdIncomingPatients.name]: {
    screen: IncomingPatients,
  },
  [ROUTES.opdIncomingPatientsAddDiagnosis.name]: {
    screen: AddDiagnosis,
    modal: true,
    modalProps: {
      width: 950,
      height: 500,
    },
  },
  [ROUTES.doctorConsultationList.name]: {
    screen: ConsultationLogsTableModal,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 900,
      height: 464,
    },
  },
  [ROUTES.doctorVaccinationList.name]: {
    screen: VaccinationLogsTableModal,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 910,
      height: 419,
    },
  },
};
