import { List } from '../../../../../../app-components';
import * as Layouts from './Layouts';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import { getPermission } from '../../../../../../AppServices';
import permissions from '../../../../../../Lib/permissions';

const {
  FromDateLayout,
  ToDateLayout,
  RequestedOnLayout,
  durationOfLeaveLayout,
  userNameLayout,
  employeeIdLayout,
  LeaveTypeLayout,
} = Layouts;

const leaveRequestQuery = (props) => ({
  id: '_find',
  query: {
    id: 'leaveRequests',
    addOnFilter: {
      ...props?.addOnFilter,
    },
  },
  model: MODELS.REQUESTS,
});

export const LeaveRequestList = List({
  listVersion: 2,
  uri: (props) => {
    const { navigation } = props;

    const { selectedTab } = navigation.getTabScreenState();
    if (selectedTab === 'pending') {
      return leaveRequestQuery({ ...props, addOnFilter: { status: 'unapproved' } });
    } if (selectedTab === 'approved') {
      return leaveRequestQuery({ ...props, addOnFilter: { status: 'approve' } });
    } if (selectedTab === 'rejected') {
      return leaveRequestQuery({ ...props, addOnFilter: { status: 'reject' } });
    } if (selectedTab === 'all') {
      return leaveRequestQuery({ ...props, addOnFilter: {} });
    }

    return leaveRequestQuery(props);
  },
  reloadEvent: 'reloadLeaveRequestLists',
  columns: [
    { width: 14 },
    userNameLayout,
    employeeIdLayout,
    FromDateLayout,
    ToDateLayout,
    {
      ...RequestedOnLayout,
    },
    { ...LeaveTypeLayout },
    {
      ...durationOfLeaveLayout,
    },
    {
      type: 'moreActions',
      align: 'left',
      visible: ({ navigation }) => {
        const { selectedTab } = navigation.getTabScreenState();
        return selectedTab === 'pending';
      },
      actions: [
        {
          text: 'Approve',
          type: 'link',
          visible: () => getPermission(
            permissions.departments.views.approveRejectLeaveRequests
              .permissions.approveRejectLeaveRequests.value,
          ),
          link: ({ item }) => ({
            view: ROUTES.approveRequestConfirmationModal.name,
            params: {
              item,
              reloadEvent: 'reloadLeaveRequestLists',
              updates: {
                status: 'approve',
              },
              model: MODELS.REQUESTS,
              title: 'Approve Leave Request !',
              message: 'Do you want to Approve this Leave Request?',
              postMessage: 'Leave Request has been approved successfully.',
            },
          }),
        },
        {
          text: 'Reject',
          type: 'link',
          visible: () => getPermission(
            permissions.departments.views.approveRejectLeaveRequests
              .permissions.approveRejectLeaveRequests.value,
          ),
          link: ({ item }) => ({
            view: ROUTES.rejectRequestConfirmationModal.name,
            params: {
              item,
              reloadEvent: 'reloadLeaveRequestLists',
              updates: {
                status: 'reject',
              },
              model: MODELS.REQUESTS,
              title: 'Reject Leave Request !',
              message: 'Do you want to Reject this Leave Request?',
              postMessage: 'Leave Request has been rejected successfully.',
            },
          }),
        },
      ],
    },
  ],
});
