/* eslint-disable no-shadow */
/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useState } from 'react';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import {
  MODELS, vaccinationStatus,
} from '../../../../Lib/constants';
import { countUri } from '../../../../Queries/countUri';
import tabTheme from '../tabTheme';
import AllVaccinationHistory from '../AllVaccinationHistory';
import { ActivityIndicator } from '../../../../app-components';
import { fetch } from '../../../../AppServices';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';

const PatientHistoryTab = (props) => {
  const [activeSession, setActiveSession] = useState({});
  const [loadingActiveSession, setLoadingActiveSession] = useState(true);

  const fetchMySessions = useCallback(async () => {
    setLoadingActiveSession(true);
    const fetchedSessions = await fetch({
      uri: {
        props: {
          query: {
            id: 'getMyVaccinationSession',
            addOnFilter: {
              isActive: true,
            },
          },
          model: MODELS.VACCINATION_SESSION,
          limit: 1,
        },
      },
    });
    if (fetchedSessions?.data && fetchedSessions.data.length) {
      setActiveSession({
        session: { _id: { $ne: fetchedSessions.data[0]._id } },
      });
    } else {
      setActiveSession({});
    }
    setLoadingActiveSession(false);
    return fetchedSessions;
  }, []);

  useEffect(() => {
    fetchMySessions();
  }, [fetchMySessions]);
  if (loadingActiveSession) return <ActivityIndicator />;

  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      {...props}
      reloadEvent={`reload${MODELS.VACCINATION}`}
      actions={[
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['patient.patientName', 'patient.uhid', 'consultation.ODPNumber'] }}
            key="vaccination"
          />
        ),
      ]}
      tabs={{
        All: {
          label: 'All',
          screen: AllVaccinationHistory,
          screenProps: {
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            addOnFilter: { ...activeSession, status: { $ne: vaccinationStatus.reallign } },
          },
          countUri: countUri({
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            filter: { ...activeSession, status: { $ne: vaccinationStatus.reallign } },
          }),
        },
        notAppeared: {
          label: 'Not Appeared',
          screen: AllVaccinationHistory,
          screenProps: {
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            addOnFilter: { status: vaccinationStatus.notAppear, ...activeSession },
          },
          countUri: countUri({
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            filter: { status: vaccinationStatus.notAppear, ...activeSession },
          }),
          actions: [],
        },
        Vaccinated: {
          label: 'Vaccinated',
          screen: AllVaccinationHistory,
          screenProps: {
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            addOnFilter: { status: vaccinationStatus.vaccinated, ...activeSession },
          },
          countUri: countUri({
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            filter: { status: vaccinationStatus.vaccinated, ...activeSession },
          }),
          actions: [],
        },
        NurseAssigned: {
          label: 'Nurse Assigned',
          screen: AllVaccinationHistory,
          screenProps: {
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            addOnFilter: { status: vaccinationStatus.nurseAssign, ...activeSession },
          },
          countUri: countUri({
            model: MODELS.VACCINATION,
            id: 'patientVaccinationList',
            filter: { status: vaccinationStatus.nurseAssign, ...activeSession },
          }),
          actions: [],
        },
      }}

    />
  );
};
export default PatientHistoryTab;
