import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save, Text } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { validateInputLength } from '../../../../../../../../Lib/helpers';

const LogOrderICUForm = (props) => {
  const {
    navigation: {
      state: {
        params: {
          dataParams: {
            filters: { specialityFilter }
            = {},
          } = {},
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <ListActionModal
      title="Orders"
      description="Log Orders of the patient."
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_ICU_ORDER}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const newUpdate = {
              ...updates,
              admissionId: { _id: submitProps?.navigation?.state?.params?.patient?._id },
            };
            const submitAPI = submit({
              model: MODELS.IPD_ICU_ORDER,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
          }}
          defaultValues={() => ({
            orderDate: new Date(),
            orderTime: new Date(),
            speciality: specialityFilter,
          })}
          validations={{
            medicationOrders_hrOne: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrOne, 0, 50),
            medicationOrders_hrTwo: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrTwo, 0, 50),
            medicationOrders_hrThree: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrThree, 0, 50),
            medicationOrders_hrfour: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrfour, 0, 50),
            medicationOrders_hrfive: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrfive, 0, 50),
            medicationOrders_hrSix: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrSix, 0, 50),
            medicationOrders_hrSeven: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrSeven, 0, 50),
            medicationOrders_hrEight: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrEight, 0, 50),
            medicationOrders_hrNine: ({ data }) => validateInputLength(data
              ?.medicationOrders_hrNine, 0, 50),
            dopamineUgPerKgPerMin: ({ data }) => validateInputLength(data
              ?.dopamineUgPerKgPerMin, 0, 5),
            dopamineMlPerHr: ({ data }) => validateInputLength(data
              ?.dopamineMlPerHr, 0, 5),
            noradrenalineUgPerKgPerMin: ({ data }) => validateInputLength(data
              ?.noradrenalineUgPerKgPerMin, 0, 5),
            noradrenalineMlPerHr: ({ data }) => validateInputLength(data
              ?.noradrenalineMlPerHr, 0, 5),
            dobutamineUgPerKgPerMin: ({ data }) => validateInputLength(data
              ?.dobutamineUgPerKgPerMin, 0, 5),
            dobutamineMlPerHr: ({ data }) => validateInputLength(data
              ?.dobutamineMlPerHr, 0, 5),
            nitroglycerineUgPerKgPerMin: ({ data }) => validateInputLength(data
              ?.nitroglycerineUgPerKgPerMin, 0, 5),
            nitroglycerineMlPerHr: ({ data }) => validateInputLength(data
              ?.nitroglycerineMlPerHr, 0, 100),
            others: ({ data }) => validateInputLength(data
              ?.others, 0, 100),
            nebulizersDalbutamolHr: ({ data }) => validateInputLength(data
              ?.nebulizersDalbutamolHr, 0, 50),
            fluidsCrystalloids: ({ data }) => validateInputLength(data
              ?.fluidsCrystalloids, 0, 50),
            nebulizersMucolyticAgentHr: ({ data }) => validateInputLength(data
              ?.nebulizersMucolyticAgentHr, 0, 50),
            fluidsColloids: ({ data }) => validateInputLength(data
              ?.fluidsColloids, 0, 50),
            nebulizersBidecortHr: ({ data }) => validateInputLength(data
              ?.nebulizersBidecortHr, 0, 50),
            fluidsBloodOrBloodProducts: ({ data }) => validateInputLength(data
              ?.fluidsBloodOrBloodProducts, 0, 50),
            nebulizersOthersHr: ({ data }) => validateInputLength(data
              ?.nebulizersOthersHr, 0, 50),
            respiratoryInstrumentsTrachealSuctionHr: ({ data }) => validateInputLength(data
              ?.respiratoryInstrumentsTrachealSuctionHr, 0, 50),
            nutritionNilOrally: ({ data }) => validateInputLength(data
              ?.nutritionNilOrally, 0, 50),
            respiratoryInstrumentsChestMucolyticAgentHr: ({ data }) => validateInputLength(data
              ?.respiratoryInstrumentsChestMucolyticAgentHr, 0, 50),
            nutritionParentral: ({ data }) => validateInputLength(data
              ?.nutritionParentral, 0, 50),
            respiratoryInstrumentsStemInhalationHr: ({ data }) => validateInputLength(data
              ?.respiratoryInstrumentsStemInhalationHr, 0, 50),
            nutritionOrally: ({ data }) => validateInputLength(data
              ?.nutritionOrally, 0, 50),
            respiratoryInstrumentsIncentiveSpirometryHr: ({ data }) => validateInputLength(data
              ?.respiratoryInstrumentsIncentiveSpirometryHr, 0, 50),
            nutritionThromboprophylaxis: ({ data }) => validateInputLength(data
              ?.nutritionThromboprophylaxis, 0, 100),
          }}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'date',
                        label: 'DATE',
                        field: 'orderDate',
                        format: 'DD/MM/YYYY',
                        editable: false,
                        mandatory: true,
                      },
                      {
                        type: 'date',
                        label: 'TIME',
                        field: 'orderTime',
                        format: 'HH:mm A',
                        editable: false,
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                containerStyle: { paddingLeft: 10 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            MEDICATION ORDERS
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12, margin: '-32px 0px 0px 0px' },
                groups: [
                  {
                    columnsPerRow: 5,
                    columns: [
                      {
                        render: () => 'a.  Hrly PO/i.v./i.m.',
                        style: {
                          fontSize: 14, paddingLeft: 14, paddingTop: 12, color: 'gray', width: '20%',
                        },
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrOne',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrTwo',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrThree',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrFour',
                        editable: true,
                      },
                      {
                        render: () => ' ',
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrFive',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrSix',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrSeven',
                        editable: true,
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrEight',
                        editable: true,
                      },
                      {
                        render: () => ' ',
                      },
                      {
                        type: 'text',
                        label: '',
                        field: 'medicationOrders_hrNine',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            INOTROPES
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { padding: 0, margin: '-32px 0px 0px 0px' },
                columnsPerRow: 2,
                groups: [
                  {
                    columnsPerRow: 5,
                    columns: [
                      /*
                      Dopa Mine
                      */
                      {
                        render: () => 'b.  Dopa Mine ',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '21%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'dopamineUgPerKgPerMin',
                        variant: 'filled',
                        style: { marginLeft: 20, width: '20%' },
                        editable: true,
                      },
                      {
                        render: () => 'ug/kg/min',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 12, color: 'grey', width: '8%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'dopamineMlPerHr',
                        variant: 'filled',
                        style: { marginLeft: 20, width: '20%' },
                        editable: true,
                      },
                      {
                        render: () => 'ml/hr',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey', width: '25%',
                        },
                      },
                      /*
                      Noradrenaline
                      */
                      {
                        render: () => 'c.  Noradrenaline ',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '15%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'noradrenalineUgPerKgPerMin',
                        container: 'leftLabel',
                        variant: 'filled',
                        style: { marginLeft: 24, width: '26%' },
                        editable: true,
                      },
                      {
                        render: () => 'ug/kg/min',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey', width: '8%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'noradrenalineMlPerHr',
                        variant: 'filled',
                        style: { marginLeft: 18, width: '20%' },
                        editable: true,
                      },
                      {
                        render: () => 'ml/hr',
                        style: {
                          fontSize: 14, marginLeft: -10, marginTop: 14, color: 'grey', width: '25%',
                        },
                      },
                      /*
                      Dobutamine
                      */
                      {
                        render: () => 'd.  Dobutamine ',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '15%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'dobutamineUgPerKgPerMin',
                        container: 'leftLabel',
                        variant: 'filled',
                        style: { marginLeft: 24, width: '26%' },
                        editable: true,
                      },
                      {
                        render: () => 'ug/kg/min',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'dobutamineMlPerHr',
                        variant: 'filled',
                        style: { marginLeft: 6, width: '20%' },
                        editable: true,
                      },
                      {
                        render: () => 'ml/hr',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey',
                        },
                      },
                      /*
                      Nitroglycerine
                      */
                      {
                        render: () => 'e.  Nitroglycerine ',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '15%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nitroglycerineUgPerKgPerMin',
                        container: 'leftLabel',
                        variant: 'filled',
                        style: { marginLeft: 24, width: '26%' },
                        editable: true,
                      },
                      {
                        render: () => 'ug/kg/min',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nitroglycerineMlPerHr',
                        variant: 'filled',
                        style: { marginLeft: 6, width: '20%' },
                        editable: true,
                      },
                      {
                        render: () => 'ml/hr',
                        style: {
                          fontSize: 16, marginLeft: -10, marginTop: 14, color: 'grey',
                        },
                      },
                      /*
                      Others
                      */
                      {
                        render: () => 'f.  Others ',
                        style: {
                          fontSize: 14, marginRight: 80, marginLeft: 24, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'others',
                        container: 'leftLabel',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                        width: '80%',
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            NEBULIZERS
                          </Text>
                        ),
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            FLUIDS
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12, margin: '-18px 0px 0px 0px' },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      /*
                      NEBULIZERS & FLUIDS
                      */
                      {
                        render: () => 'g.  Salbutamol (hrly)',
                        style: {
                          fontSize: 14, margin: '14px -31px 0px 24px', color: 'grey', width: '20%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nebulizersDalbutamolHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'k. Crystalloids',
                        style: {
                          fontSize: 14, paddingLeft: 65, margin: '14px 70px 0px 24px', color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'fluidsCrystalloids',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },

                      {
                        render: () => 'h.  Mucolytic agent (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nebulizersMucolyticAgentHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'l. Colloids',
                        style: {
                          fontSize: 14, margin: '14px 70px 0px 24px', color: 'grey', padding: '0px 28px 0px 62px',
                        },
                      },
                      {
                        type: 'text',
                        field: 'fluidsColloids',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },

                      {
                        render: () => 'i.  Bidecort (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '17%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nebulizersBidecortHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'm. Blood/Blood Products',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', paddingLeft: 65,
                        },
                      },
                      {
                        type: 'text',
                        field: 'fluidsBloodOrBloodProducts',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },

                      {
                        render: () => 'j.  Others (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, paddingRight: 60, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nebulizersOthersHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            RESPIRATORY INSTRUCTIONS
                          </Text>
                        ),
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            NUTRITION
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12, margin: '-32px 0px 0px 0px' },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      /*
                      RESPIRATORY INSTRUCTIONS & NUTRITION
                      */
                      {
                        render: () => 'n.  Tracheal Suction (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', width: '20%',
                        },
                      },
                      {
                        type: 'text',
                        field: 'respiratoryInstrumentsTrachealSuctionHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 's. Nil Orally',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, paddingLeft: 18, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nutritionNilOrally',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'o.  Chest Physiotherepy (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'respiratoryInstrumentsChestMucolyticAgentHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 't. Parentral',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', paddingLeft: 18,
                        },
                      },
                      {
                        type: 'text',
                        field: 'nutritionParentral',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },

                      {
                        render: () => 'p.  Stem Inhalation (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, paddingRight: 32, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'respiratoryInstrumentsStemInhalationHr',
                        variant: 'filled',
                        style: { marginLeft: 18 },
                        editable: true,
                      },
                      {
                        render: () => 'u. Orally',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey', paddingLeft: 18, paddingRight: 18,
                        },
                      },
                      {
                        type: 'text',
                        field: 'nutritionOrally',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'r.  Incentive spirometry (hrly)',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'respiratoryInstrumentsIncentiveSpirometryHr',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                      {
                        render: () => 'v. ',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, paddingLeft: 20, color: 'grey',
                        },
                      },
                      {
                        type: 'radioGroup',
                        field: 'nutritionLiquidSaltFreeSemiSolidFull',
                        variant: 'filled',
                        options: ['Liquid', 'salt free', 'semi-solid', 'Full'],
                        optionStyle: { flexDirection: 'row-reverse' },
                        style: { color: 'grey' },
                        editable: true,
                      },
                      {
                        render: () => 'w.  Thromboprophylaxis',
                        style: {
                          fontSize: 14, marginLeft: 24, marginTop: 14, paddingRight: 32, color: 'grey',
                        },
                      },
                      {
                        type: 'text',
                        field: 'nutritionThromboprophylaxis',
                        variant: 'filled',
                        style: { marginLeft: 20 },
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />

  );
};
export default LogOrderICUForm;
