import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { SelectDate } from '@applane/react-date-input';
import {
  StandardButton,
  View,
  Text,
} from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { Form } from '../../../../../../../app-components/form/Form';
import { Table } from '../../../../../../../app-components/table/Table';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const tableItems = {
  lg: {
    columns: [
      {
        header: 'S.No.',
        type: 'number',
        render: (item) => <Text>{item.index + 1}</Text>,
      },
      {
        header: 'Request Date',
        type: 'date',
        format: 'DD/MM/YYYY',
        field: 'requestDate',
      },
      {
        header: 'Date',
        type: 'date',
        format: 'DD/MM/YYYY',
        field: 'testDate',
      },
      {
        header: 'Test Name',
        type: 'text',
      },
      {
        header: 'Reports',
        type: 'text',
        field: 'testReport',
      },
    ],
  },
};

const InvestigationIPDForm = (props) => {
  const [selectedDate, setSelectedDate] = useState();
  const { eventDispatcher } = props || {};
  const { dataParams } = props;
  useEffect(() => {
    eventDispatcher.notify(`reload${MODELS.IPD_ENT_INVESTIGATIONS}`);
  }, [selectedDate, eventDispatcher]);
  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      editable={false}
      flex={false}
      scrollable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(item?.navigation?.state?.params?.patient?._createdOn).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate)
                            .isSame(currentDate);
                          const highlightedDate = moment(currentDate).isAfter(moment(admissionDate))
                           && moment(currentDate).isBefore(moment());
                          if (admittedDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'green', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View style={{ backgroundColor: '#EFF3FB', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View style={{ backgroundColor: '#4070C9', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          return (
                            <View>
                              {date}
                            </View>
                          );
                        }}
                      />
                    ),
                  },
                  {
                    render: () => {
                      const tableProps = {
                        ...props,
                        ...tableItems,
                      };
                      return (
                        <Table
                          {...tableProps}
                          reloadEvent={`reload${MODELS.IPD_ENT_INVESTIGATIONS}`}
                          uri={(item) => ({
                            query: {
                              id: 'ipdENTInvestigations',
                              addOnFilter: {
                                admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
                                testDate: {
                                  $eq: selectedDate,
                                },
                              },
                            },
                            model: MODELS.IPD_ENT_INVESTIGATIONS,
                          })}
                          header={() => ({
                            title: () => (
                              <HeaderTitleWithCount
                                label={`${moment(selectedDate).format('DD MMM YYYY')}`}
                              />
                            ),
                            actions: [
                              {
                                type: 'link',
                                link: ({ navigation: { state: { params = {} } } }) => ({
                                  view: !(props.dataParams.filters.specialityFilter === 'ENT')
                                    ? ROUTES.AddProgress.name : ROUTES.logENTInvestigation.name,
                                  params: { ...params, dataParams, selectedDate },
                                  push: true,
                                }),
                                render: () => (
                                  <StandardButton
                                    label="Add Investigation"
                                  />
                                ),
                              },
                            ],
                          })}
                        />
                      );
                    },
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default InvestigationIPDForm;
