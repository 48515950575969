import React from 'react';

import { save, Toast } from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { dateFormatX } from '../../../../../../constants';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import {
  vitalSignBloodSugar,
  vitalSignCS,
  vitalSignG,
  vitalSignOutput,
  vitalSignTnput,
} from '../../../fieldConfiguration';

const IpdAddVitals = (props) => {
  const {
    navigation: { state: { params: { data: paramsData } = {} } = {} } = {},
  } = props;
  const {
    navigation: {
      state: {
        params: { dataParams },
      },
    },
  } = props;
  return (
    <ListActionModal
      {...props}
      title="Vital Signs"
      description="Log today's Vitals of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [save],
          }}
          defaultValues={{
            Format: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
          }}
          mandatory={{
            vitalSignBP: 1,
            vitalSignTemperature: 1,
            vitalSignPulse: 1,
            vitalSignResp: 1,
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.vitalSignTime = dateFormatX(
              data?.Hour,
              data?.Minute,
              data?.Format,
            );
            delete updates.Hour;
            delete updates.Minute;
            delete updates.Format;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: {
                _id: submitProps?.navigation?.state?.params?.patient?._id,
              },
              speciality: submitProps?.navigation?.state
                ?.params?.dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.IPD_VITALS_SIGN_SHEET,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Details has been added successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          type="standardShadow"
          lg={{
            validations: {
              vitalSignBP: ({ data }) => validateInputLength(data?.vitalsP, 1, 4),
              vitalSignTemperature: ({ data }) => validateInputLength(data
                ?.vitalsTemperature, 1, 4),
              vitalSignPulse: ({ data }) => validateInputLength(data?.vitalsBP, 1, 4),
              vitalSignResp: ({ data }) => validateInputLength(data?.vitalsRR, 1, 4),
              nurseNote: ({ data }) => validateInputLength(data?.vitalsInTake, 0, 200),
            },
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      newDateInput({
                        hour: { field: 'Hour', label: 'Hour' },
                        minute: { field: 'Minute', label: 'Minute' },
                        meridiem: { field: 'Format' },
                      }),
                      {
                        type: 'number',
                        field: 'vitalSignBP',
                        label: 'BP',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignTemperature',
                        label: 'Temperature',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignPulse',
                        label: 'Pulse',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignResp',
                        label: 'Resp.',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignG',
                        label: 'G',
                        visible: vitalSignG[dataParams?.filters?.specialityFilter],
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignCS',
                        label: 'CS',
                        visible: vitalSignCS[dataParams?.filters?.specialityFilter],
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'number',
                        field: 'vitalSignInput',
                        label: 'Input',
                        mandatory: true,
                        visible:
                              vitalSignTnput[
                                dataParams?.filters?.specialityFilter
                              ],
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignOutput',
                        label: 'Output',
                        visible: vitalSignOutput[dataParams?.filters?.specialityFilter],
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'bloodSugar',
                        label: 'Blood Sugar',
                        visible: vitalSignBloodSugar[dataParams?.filters?.specialityFilter],
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'nurseNote',
                        label: 'Nurse Note',
                        placeHolder: 'Type Here....',
                        variant: 'filled',
                        allowWhiteSpaces: true,
                        editable: true,
                        minHeight: 90,
                      },
                    ],
                  },
                ],
              },
            ],

          }}
        />
      )}
    />
  );
};

const AddVitals = (props) => <IpdAddVitals {...props} />;
export default AddVitals;
