import {
  validateInputLength,
} from '../../../Lib/helpers';
import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 20),
};
const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Name',
    allowWhiteSpaces: true,
    inputProps: {
      maxLength: 20,
    },
  },
  {
    type: 'number',
    field: 'charge',
    label: 'Charges',
    inputProps: {
      maxLength: 20,
    },
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'room.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'center',
  },
];

export default MasterForm({
  model: MODELS.ROOM_CATEGORY,
  formFields,
  validations,
  uniqueValidationMessage: 'Ward Category Already Exists',
  submitMessage: 'Ward Category Added Successfully',
  mandatory: {
    name: 1,
    charge: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
