import React from 'react';
import { Text } from 'react-native';
import { upperFirst } from 'lodash';
import { Table } from '../../../../../app-components/table/Table';
import { bagStatusBloodBank, MODELS, ROUTES } from '../../../../../Lib/constants';
import {
  getExpirationStatus,
  getRemainingSelfValue,
} from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';

const tabListColumns = [
  {
    type: 'selection',
  },
  {
    header: 'bloodInventory.headers.bagNo'.getLabel(),
    type: 'text',
    field: 'name',
    width: 80,
  },
  {
    header: 'bloodInventory.headers.tubeNo'.getLabel(),
    type: 'text',
    field: 'tubeNo',
    width: 80,
  },
  {
    header: 'bloodInventory.headers.donationDate'.getLabel(),
    type: 'date',
    format: 'DD MMM YYYY',
    field: 'donationId.date',
    sortable: 'date',
    width: 220,
  },
  {
    header: 'bloodInventory.headers.remainingSelfLife'.getLabel(),
    type: 'number',
    value: ({ item }) => getRemainingSelfValue(item?.expiryDate),
    width: 150,
  },
  {
    header: 'bloodInventory.headers.bagWeight'.getLabel(),
    type: 'text',
    width: 100,
    field: 'bagWeight',
  },
  {
    header: 'bloodInventory.headers.testStatus'.getLabel(),
    type: 'text',
    width: 100,
    render: ({ item }) => {
      if (item?.testStatus === 'tested') {
        return (
          <Text style={{ ...vars.headings.h19, color: '#55B971' }}>
            {upperFirst(item?.testStatus)}
          </Text>
        );
      }

      return (
        <Text style={{ ...vars.headings.h19, color: '#F2994A' }}>
          {upperFirst(item?.testStatus)}
        </Text>
      );
    },
  },
  {
    header: 'bloodInventory.headers.expiryStatus'.getLabel(),
    type: 'text',
    width: 100,
    render: ({ item }) => {
      if (getExpirationStatus(item?.expiryDate) === 'Active') {
        return (
          <Text style={{ ...vars.headings.h19, color: '#55B971' }}>
            {getExpirationStatus(item?.expiryDate)}
          </Text>
        );
      }

      return (
        <Text style={{ ...vars.headings.h19, color: '#F2994A' }}>
          {getExpirationStatus(item?.expiryDate)}
        </Text>
      );
    },
  },
  {
    header: 'bloodInventory.headers.result'.getLabel(),
    type: 'text',
    width: 100,
    value: ({ item }) => {
      if (item?.result?.length) {
        return item?.result?.map((result) => result?.label).toString();
      }

      return '';
    },
  },
  {
    header: 'bloodInventory.headers.bagStatus'.getLabel(),
    type: 'text',
    width: 100,
    render: ({ item }) => {
      if (item?.bagStatus === bagStatusBloodBank.ACTIVE) {
        return (
          <Text style={{ ...vars.headings.h19, color: '#55B971' }}>
            {item?.bagStatus}
          </Text>
        );
      } if (item?.bagStatus === bagStatusBloodBank.DISCARD) {
        return (
          <Text style={{ ...vars.headings.h19, color: '#EB5757' }}>
            {item?.bagStatus}
          </Text>
        );
      } if (item?.bagStatus === bagStatusBloodBank.REQUESTED) {
        return (
          <Text style={{ ...vars.headings.h19, color: '#F2994A' }}>
            {item?.bagStatus}
          </Text>
        );
      }
      if (item?.issuedToPatient) {
        return (
          <Text style={{ ...vars.headings.h19, color: '#F2994A' }}>
            {`Issued to ${item?.issuedToPatient?.patientName}`}
          </Text>
        );
      }
      return (
        <Text style={{ ...vars.headings.h19, color: '#F2994A' }}>
          {`Issued to ${item?.issueToPatientName}`}
        </Text>
      );
    },
  },
  {
    header: 'bloodInventory.headers.expiryDate'.getLabel(),
    type: 'date',
    field: 'expiryDate',
    sortable: 'expiryDate',
    width: 110,
  },
  {
    header: 'bloodInventory.headers.donorName'.getLabel(),
    type: 'text',
    field: 'bloodDonor.name',
    width: 130,
  },
];

const BloodGroupList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: { _id: -1 },
        },
        model,
      })}
      reloadEvent={`reload${MODELS.BLOOD_BANK}`}
      columns={tableColumns}
      showActionIcon={false}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.editBloodCategoryListModal.name,
          params: { item },
          modal: true,
          modalProps: {
            autoHide: true,
            width: 1069,
            height: 633,
          },
        },
      })}
    />
  );
};
export default BloodGroupList;
