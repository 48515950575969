import { ROUTES } from '../../../Lib/constants';
import EmployeeAttendanceTabNavigation from './Tabs/index';

export default {
  [ROUTES.EmployeeAttendance.name]:
   {
     screen: EmployeeAttendanceTabNavigation,
     expanded: true,
   },
};
