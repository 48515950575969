import React from 'react';

import { clearSelection, FilterGroup, Image, selectAll, showSelectedCount, StandardButton, Table, TouchableOpacity } from '../../../app-components';
import { employeeWithScheduleListUri } from '../../../Queries/employee';
import { editRecord, filterActive, filterInactive } from '../../../images';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { getString, timeConversion } from '../../../Lib/helpers';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';

const Shifts = (schedule) => {
  return schedule
    ?.map((item) => {
      if (item?.ShiftType) {
        const { ShiftType } = item;
        return ShiftType?.map(
          (shift) =>
            `${shift?.name}(${timeConversion(
              shift?.startTime,
            )} to ${timeConversion(shift?.endTime)})`,
        );
      }
    })
    .toString();
};

const header = ({ aggregates }) => ({
  title:<HeaderTitleWithCount count={aggregates?._count} label={'employeeSchedule.title.employeeSchedule'.getLabel()}/> , 
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'employee.title.exportToExcel'.getLabel()} />
      ),  
      file: 'EmployeeSchedules',
    type: 'download',
      columns: [
        {
          field: 'firstName',
          header: 'Employee FirstName',
        },
        {
          field: 'lastName',
          header: 'Employee LastName',
        },
        {
          field: 'employeeId',
          header: 'Employee ID',
        },
        {
          field: {
            field: 'schedule.ShiftType',
            parserFunction: 'formatArrayToStringForExcel',
            options: {
              field: 'name',
            },
          },
          header: 'Shifts',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.EMPLOYEE,
          query: {
            id: 'employeeListsForTableWithEffectiveSchedule',
          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState:{selectedIds} } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    }]),
  actions: [
    (props) =><FtsSearch action={{ exp: ['firstName', 'lastName', 'employeeId'] }} {...props}/>,
    FilterGroup({
      icon: filterInactive,
      activeIcon: filterActive,
      filters: [
        {
          type: 'autoSuggest',
          field: 'department',
          label: 'employeeSchedule.labels.department'.getLabel(),
          model: MODELS.DEPARTMENTS,
          id: 'masterDataList',
          searchField: 'name',
          placeholder: 'employeeSchedule.label.employeeType'.getLabel(),
          sugestionField: 'name',
          primaryField: 'name',
          valueField: 'name',
          searching: true,
          multiSelect: true,
        },
        {
          type: 'autoSuggest',
          field: 'speciality',
          label: 'employeeSchedule.labels.speciality'.getLabel(),
          model: MODELS.WARDS,
          id: 'masterDataList',
          searchField: 'name',
          placeholder: 'employeeSchedule.placeholder.employeeType'.getLabel(),
          sugestionField: 'name',
          primaryField: 'name',
          valueField: 'name',
          searching: true,
          multiSelect: true,
        },
      ],
    }),
  ],
});

const tableItems = {
  lg: {
    header,
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'employeeSchedule.labels.employeeName'.getLabel(),
        type: 'text',
        sortable: 'firstName',
        value: ({ item }) => getString([item?.firstName, item?.lastName]),
      },
      {
        header: 'employeeSchedule.labels.employeeId'.getLabel(),
        type: 'text',
        field: 'employeeId',
        sortable: true,
      },
      {
        header: 'employeeSchedule.labels.shiftNames'.getLabel(),
        type: 'text',
        width: 500,
        value: ({ item: { schedule } }) => Shifts(schedule),
        numberOfLines: 5
      },
      {
        header: 'employeeSchedule.labels.actions'.getLabel(),
        type: 'text',
        render: () => (
          <WithPermission
            access_key={
              permissions.mdm.views.modifySchedule.permissions.modifySchedule.value
            }>
            <TouchableOpacity>
              <Image
                style={{
                  height: 24,
                  width: 24,
                }}
                source={editRecord}
              />
            </TouchableOpacity>
          </WithPermission>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.viewEmployeeSchedule.name,
            params: { item },
          },
        }),
      },
    ],
    hoverActions: {
      actions: [
        {
          action: () => { },
        },
      ],
    },
  },
};

export default Table({
  reloadEvent: `reload${MODELS.EMPLOYEE}`,
  uri: (data) =>
    employeeWithScheduleListUri({ filter: { _id: data?.employee?._id } }),
  ...tableItems,
  hoverable: false,
});
