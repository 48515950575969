import React from 'react';

import EmployeeScheduleList from '../../../../../Wired/EmployeeScheduleList/employeeScheduleList';
import { DateFilter, TabNavigator, ActivityIndicator } from '../../../../../app-components';
import tabTheme from '../../../../../theme/childTabTheme';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const ViewScheduleTabs = (props) => {
  const { navigation: { state: { params: { items = {} } } } } = props;
  if (!items?._id) {
    return <ActivityIndicator />;
  }
  const ScheduleTabNavigation = TabNavigator({
    theme: { ...tabTheme },
    tabs: {
      ScheduleCalendar: {
        label: 'Schedule Calendar',
        actions: [() => (
          <DateFilter
            {...props}
            field="fromDate"
            key="name"
            containerStyle={
          {
            flexDirection: 'row',
            width: 303,
            height: 36,
            borderWidth: 1,
            padding: 9,
            marginRight: 40,
            borderRadius: 4,
            borderStyle: 'solid',
            backgroundColor: vars.colors.white,
            borderColor: vars.colors.grey.color6,
          }
        }
          />
        )],
        screen: EmployeeScheduleList,
        screenProps: {
          model: MODELS.EMPLOYEE_SCHEDULE,
          id: 'schedules',
          data: items?.employee,
          addOnFilter: {
            employee: { _id: items?.employee?._id },
          },
        },
      },
    },
  });
  return <ScheduleTabNavigation {...props} />;
};
export default ViewScheduleTabs;
