import React from 'react';
import moment from 'moment';
import { TouchableOpacity } from 'react-native';
import { Table } from '../../../app-components/table/Table';
import {
  MODELS, ROUTES,
} from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { downloadSalarySlip } from '../../../images';
import printPharmacyBill from '../../../Wired/PatientInvoices';
import {
  clearSelection, Image, OutlineButton, selectAll, showSelectedCount, Text,
} from '../../../app-components';
import { InvokeTypeConfirmOpenAction } from '../../../Wired/Attendance/Requests/Actions';

const header = ({
  fetchUriEvent: 'fetchListAggregates',
  selectableActions: (props) => ([
    showSelectedCount(props),
    selectAll,
    clearSelection,
    {
      render: () => (
        <OutlineButton label={'employee.title.exportToExcel'.getLabel()} />
      ),
      file: 'PharmacyBillingExportData',
      type: 'download',
      columns: [
        {
          field: 'patientId.patientName',
          header: 'Patient Name',
        },
        {
          field: 'patientId.uhid',
          header: 'UHID',
        },
        {
          field: 'consultationId.ODPNumber',
          header: 'OPD No.',
        },
        {
          field: '',
          header: 'CR No.',
        },
        {
          field: 'billAmount',
          header: 'Billing Amount',
        },
        {
          field: 'department.name',
          header: 'Department',
        },
        {
          field: 'consultationId.speciality.name',
          header: 'Speciality',
        },
        {
          field: 'consultationId.consultedBy.name',
          header: 'Doctor Name',
        },
        {
          field: 'dispensedBy.name',
          header: 'Dispensed By',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.PHARMACY_BILLING,
          query: {
            id: 'pharmacyBillingDataList',
            addOnFilter: { status: 'active' },

          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (data) => {
          const {
            screenState: { selectedIds },
          } = data;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    },
  ]),
});

const tableItems = {
  lg: {
    header,
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'billing.headers.date'.getLabel(),
        type: 'time',
        width: 200,
        render: ({ item }) => {
          const { _createdOn } = item;
          return (
            <Text style={{ ...vars.headings.h9 }}>
              {moment(_createdOn).format('DD MM YYYY @ hh:mm A')}
            </Text>
          );
        },
      },
      {
        header: 'billing.headers.patientName'.getLabel(),
        type: 'text',
        field: 'patientId.patientName',
        sortable: 'patientId.patientName',
        width: 120,
      },
      {
        header: 'billing.headers.uhid'.getLabel(),
        type: 'text',
        field: 'patientId.uhid',
        width: 100,
      },
      {
        header: 'billing.headers.opdNo'.getLabel(),
        type: 'text',
        field: 'consultationId.ODPNumber',
        sorting: 'consultationId.ODPNumber',
        width: 100,
      },
      {
        header: 'billing.headers.crNo'.getLabel(),
        type: 'text',
        field: '',
        width: 100,
      },
      {
        header: 'billing.headers.billingAmount'.getLabel(),
        type: 'text',
        field: 'billAmount',
      },
      {
        header: 'billing.headers.department'.getLabel(),
        type: 'text',
        value: ({ item: { department = [] } }) => Array.isArray(department)
        && department.map(({ name = '' }) => (` ${name}`
        )).toString(),
      },
      {
        header: 'billing.headers.speciality'.getLabel(),
        type: 'text',
        field: 'consultationId.speciality.name',
      },
      {
        header: 'billing.headers.doctorName'.getLabel(),
        type: 'text',
        field: 'consultationId.consultedBy.name',
        width: 120,
      },
      {
        header: 'billing.headers.generatedBy'.getLabel(),
        type: 'text',
        field: 'dispensedBy.name',
        sortable: true,
        width: 120,
      },
      {
        header: 'Actions',
        type: 'text',
        render: ({ item }) => (
          <TouchableOpacity
            onPress={({ submitResult }) => printPharmacyBill({ data: { ...item }, submitResult })}
          >
            <Image
              source={downloadSalarySlip}
              style={{
                width: 12,
                height: 16,
              }}
            />
          </TouchableOpacity>
        ),
      },
      {
        type: 'moreActions',
        width: 120,
        align: 'left',
        header: 'MoreActions',
        actions: [
          {
            type: 'link',
            text: 'Discard',
            link: ({ item }) => ({
              view: ROUTES.confirmPharmacyLabBillHistory.name,
              params: { item },
            }),
          },
        ],
      },
      // {
      //   header: 'MoreActions',
      //   type: 'moreActions',
      //   width: 120,
      //   actions: [
      //     {
      //       ...InvokeTypeConfirmOpenAction({
      //         text: 'Discard',
      //         title: 'Approval Window',
      //         message: 'Do you want to Discard this Bill?',
      //         postMessage: ' Discarded Successfully',
      //         reloadEvent: 'pharmacyBillingDataList',
      //         updates: {
      //           status: 'discarded',
      //         },
      //         model: MODELS.PHARMACY_BILLING,
      //       }),
      //     },
      //   ],
      // },
    ],
  },
};

const PharmacyBillingRecords = (props) => {
  const { tableColumns = tableItems } = props;
  return (
    <Table
      {...tableColumns}
      uri={() => ({
        query: {
          model: MODELS.PHARMACY_BILLING,
          id: 'pharmacyBillingDataList',
          addOnFilter: { status: 'Active' },
          sort: { _createdOn: -1 },
        },
        model: MODELS.PHARMACY_BILLING,
      })}
      reloadEvent="pharmacyBillingDataList"
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPharmacyBill.name,
          params: { item },
        },
      })}
      {...props}
    />
  );
};

export default PharmacyBillingRecords;
