import React from 'react';

import { View } from '../../../../../../app-components';
import {
  autoSuggestInput, searchInput,
} from '../../../../../../autoSuggestions';
import { MODELS } from '../../../../../../Lib/constants';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { EditPharmacyItem } from '../../../../../../Queries/pharmacy';

import ViewItemTabs from './components/tabs';
import { Form } from '../../../../../../app-components/form/Form';
import vars from '../../../../../../theme/vars';

const ViewItem = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryID,
          defaultValues,
          hidePatientDispensing,
          item,
        },
      },
    },
  } = props;

  let queryId = '';
  if (item?.module === 'assetConsumables') {
    queryId = 'assetConsumablesDepartmentInventoryStocksDataList';
  }
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Form
          type="standard"
          editable={false}
          uri={(uriProps) => EditPharmacyItem({ ...uriProps, queryID })}
          closeView={1}
          reloadEvent={`reload${MODELS.PHARMACY_ITEM}`}
          lg={{
            formGroups: [
              {
                columnsPerRow: 5,
                fieldVariant: 'filled',
                label: 'pharmacy.title.itemDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        field: 'name',
                        label: 'inventory.labels.itemName'.getLabel(),
                      },
                      searchInput({
                        field: 'category',
                        label: 'inventory.labels.category'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.PHARMACY_CATEGORY,
                        query: 'masterDataList',
                      }),
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'subCategory',
                        label: 'inventory.labels.subCategory'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.PHARMACY_SUBCATEGORY,
                        query: 'masterDataList',
                      }),
                      {
                        type: 'number',
                        field: 'reorderLevel',
                        label: 'inventory.labels.reorderLevel'.getLabel(),
                      },
                      {
                        type: 'number',
                        field: 'sellingPrice',
                        label: 'inventory.labels.sellingPrice'.getLabel(),
                      },
                      {
                        type: 'status',
                        field: 'isActive',
                        label: 'inventory.labels.isActive'.getLabel(),
                        container: 'topLabel',
                      },
                      {
                        type: 'text',
                        field: 'avgMonthlyConsumption',
                        label: 'inventory.labels.avgConsumption'.getLabel(),
                      },
                      {
                        type: 'textArea',
                        field: 'description',
                        label: 'inventory.labels.description'.getLabel(),
                        minHeight: 80,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 0 },
                label: 'pharmacy.title.manufactureDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                columns: [
                  {
                    field: 'rateContract',
                    fieldType: 'nested',
                    editable: false,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      listProps: {
                        skipTableHeaderOnNoData: false,
                        columns: [
                          searchInput({
                            field: 'supplier',
                            variant: 'filled',
                            header: 'inventory.labels.supplier'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_SUPPLIER,
                            query: 'masterDataList',
                          }),
                          searchInput({
                            field: 'manufacturer',
                            variant: 'filled',
                            header: 'inventory.labels.manufacturer'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_MANUFACTURER,
                            query: 'masterDataList',
                          }),
                          {
                            type: 'date',
                            field: 'rateContractValidity',
                            variant: 'filled',
                            header: 'inventory.labels.rateContractValidity'.getLabel(),
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          }}
          {...props}
        />
      </View>
      <View style={{ flex: 2, overflow: 'hidden' }}>
        <ViewItemTabs
          {...props}
          defaultValues={defaultValues}
          queryId={queryId}
          hidePatientDispensing={hidePatientDispensing}
        />
      </View>
    </View>
  );
};

export default ViewItem;
