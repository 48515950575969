import React from 'react';

import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { EditShift } from '../../../../Queries/shift';
import ViewShiftTabs from './components/Tabs';
import { timeConversion } from '../../../../Lib/helpers';
import { Text } from '../../../../app-components';
import vars from '../../../../theme/vars';

const formFields = [
  {
    type: 'text',
    field: 'code',
    label: 'shift.labels.shiftId'.getLabel(),
    mandatory: true,
  },
  {
    type: 'text',
    field: 'name',
    label: 'shift.labels.shiftName'.getLabel(),
    mandatory: true,
  },
  {
    type: 'time',
    label: 'shift.labels.startTime'.getLabel(),
    render: ({ data: { startTime } }) => (
      <Text style={{ ...vars.headings.h8 }}>
        {timeConversion(startTime)}
      </Text>
    ),
    sortable: 'startTime',
  },
  {
    type: 'time',
    label: 'shift.labels.endTime'.getLabel(),
    render: ({ data: { endTime } }) => (
      <Text style={{ ...vars.headings.h8 }}>
        {timeConversion(endTime)}
      </Text>
    ),
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'shift.labels.status'.getLabel(),
  },
];

export default MasterForm({
  uri: EditShift,
  formFields,
  model: MODELS.SHIFT,
  editable: false,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewShiftTabs {...props} />
    ),
  }],
});
