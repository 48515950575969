import React from 'react';

import { editIpdNotesUri } from '../../../../../../../../Queries/operationNotes';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { Text } from '../../../../../../../../app-components';
import { MODELS } from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';
import {
  bloodLoss,
  entPosition,
  followUp,
  hpe,
  operationalFindings,
  operationalPosition,
  operationalProcedure,
  Orthopedic,
  patientName,
  physicianName,
  position,
  postOperationOrder,
  preOperationStatus,
  surgeonName,
  unit,
} from '../../../fieldConfiguration';
import { NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
// import { operationalFindings } from '../../../fieldConfiguration';

const ViewOperationNotesIPDForm = (props) => {
  const anesthesiaDose = !!props.data;
  const { navigation } = props;
  const {
    state: {
      params: { dataParams },
    },
  } = navigation;
  return (
    <ListActionModal
      title="Operation/Procedure Notes"
      description="Log Operation notes of the patient."
      content={(
        <Form
          {...props}
          uri={editIpdNotesUri({ navigation })}
          reloadEvent={`reload${MODELS.OPERATION_PROCEDURE_NOTES}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'date',
                        field: 'operationDate',
                        label: 'Date of operation',
                        editable: false,
                        mandatory: true,
                      },
                      {
                        type: 'text',
                        field: 'operationName.name',
                        label: 'Name of operation',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'surgeonName.name',
                        variant: 'filled',
                        label: 'Surgeon Name',
                        editable: false,
                        visible: surgeonName[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'text',
                        field: 'physicianName.name',
                        variant: 'filled',
                        label: 'Physician Specialist',
                        editable: false,
                        visible: physicianName[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'text',
                        field: 'patientName',
                        label: 'Patient Name',
                        editable: false,
                        visible: patientName[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'text',
                        field: 'unit',
                        label: 'unit',
                        editable: false,
                        visible: unit[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        field: 'preOperationDiagnosis',
                        label: 'Pre-operation Diagnosis',
                        editable: false,
                      },
                      {
                        type: 'date',
                        width: 400,
                        field: 'startTime',
                        format: 'HH;mm A',
                        label: 'Start Time',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'date',
                        label: 'Finish Time',
                        width: 400,
                        format: 'HH;mm A',
                        field: 'finishTime',
                        editable: false,
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'text',
                        field: 'assistantsEmployee.name',
                        label: 'Assistant',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'nurseOnDuty.name',
                        label: 'Nurses on duty',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'preOperationStatus',
                        label: 'Pre-operation Status',
                        editable: false,
                        minHeight: 80,
                        visible:
                          preOperationStatus[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        field: 'anesthesia',
                        label: 'ANESTHESIA',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'anesthesiologist',
                        label: 'Anesthesiologist',
                        variant: 'filled',
                        editable: false,
                        visible: anesthesiaDose,
                      },
                      {
                        type: 'text',
                        field: 'anesthesiaType',
                        label: 'Type of Anesthesia',
                        editable: false,
                        visible: anesthesiaDose,
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'Implant Used',
                        visible:
                        Orthopedic[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                  {
                    columnsPerRow: 3,
                    fieldVariant: 'filled',
                    labelStyle: {
                      ...vars.headings.h8,
                      color: vars.colors.grey.color2,
                      textTransform: 'uppercase',
                      paddingLeft: 10,
                    },
                    groupsContainerStyle: { paddingLeft: 12 },
                    groups: [
                      {
                        columns: [
                          {
                            type: 'text',
                            field: 'itemused',
                            label: 'Item Used',
                            editable: false,
                            visible:
                            Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'make',
                            editable: false,
                            label: 'Make',
                            visible:
                            Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'number',
                        field: 'bloodLoss',
                        label: 'Blood loss',
                        editable: false,
                        visible:
                          bloodLoss[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'text',
                        field: 'otTechnicianName.name',
                        label: 'OT Technician Name',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'position',
                        label: 'Position',
                        visible:
                          position[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                style: {
                  marginTop: 0,
                },
                label: 'Position',
                fieldVariant: 'filled',
                visible: entPosition[dataParams?.filters?.specialityFilter],
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 24, color: vars.colors.grey.color7 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        render: () => 'Supine Position Swelling',
                        editable: false,
                        visible: (item) => item.data.isSupinePosition,
                      },
                      {
                        type: 'text',
                        render: () => 'Local infiltration with 2% lignocaine with adrelanine done',
                        editable: false,
                        visible: (item) => item.data.isLocalInfiltration,
                      },

                      {
                        type: 'text',
                        render: () => 'Temporial fancia craft harvested',
                        editable: false,
                        visible: (item) => item.data.isTemporalisFancia,

                      },
                      {
                        type: 'text',
                        render: () => 'Margins of perforation fractured scrapping of undersurfall of reminant Tympanic Membrane done',
                        editable: false,
                        visible: (item) => item.data.isMarginsPerforation,

                      },
                      {
                        type: 'text',
                        render: () => 'Canal Illusion Taken',
                        editable: false,
                        visible: (item) => item.data.isCanalIllusionTaken,

                      },
                      {
                        type: 'text',
                        render: () => 'Tympanomestal Flap Elevated',
                        editable: false,
                        visible: (item) => item.data.isTympanomestalFlapElevated,

                      },
                      {
                        type: 'text',
                        render: () => 'Middle Ear Entered-Ossicular Status Checked',
                        editable: false,
                        visible: (item) => item.data.isMiddleEarEnteredOssicular,

                      },
                      {
                        type: 'text',
                        render: () => 'Middle Ear Packing Done using Medicated Gel Foam',
                        editable: false,
                        visible: (item) => item.data.isMiddleEarPackingDone,

                      },
                      {
                        type: 'text',
                        render: () => 'Temporails fevric Craft Placed By Underlay technique',
                        editable: false,
                        visible: (item) => item.data.isTempralisFevricCraft,
                      },
                      {
                        type: 'text',
                        render: () => 'External Ear Canal Pallery Done Using Medicated gel Foam',
                        editable: false,
                        visible: (item) => item.data.isExternalEarCanal,
                      },
                      {
                        type: 'text',
                        render: () => 'Closure Done',
                        visible: (item) => item.data.isClosureDone,
                        editable: false,
                      },
                      {
                        type: 'text',
                        render: () => 'Illusion',
                        editable: false,
                        visible: (item) => item.data.isIllusion,

                      },
                      {
                        type: 'text',
                        field: 'illusion',
                        editable: false,
                        visible: (item) => item.data.isIllusion,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'OPERATIONAL NOTE',
                editable: false,
                labelStyle: {
                  ...vars.headings.h9,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 7,
                  // marginTop: 25,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'Position',
                        visible:
                          operationalPosition[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'text',
                        field: 'operationalPosition',
                        visible:
                          operationalPosition[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Findings',
                        visible:
                          operationalFindings[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        field: 'operationalFindings',
                        fieldType: 'nested',
                        visible:
                          operationalFindings[
                            dataParams?.filters?.specialityFilter
                          ],
                        Component: NestedTable,
                        componentProps: {
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              color: vars.colors.grey.color3,
                              backgroundColor: 'white',
                              numberOfLines: 1,
                              marginBottom: 7,
                            },
                            headerRowContainerStyle: {
                              backgroundColor: 'white',
                              marginRight: 15,
                            },
                            columns: [
                              {
                                type: 'text',
                                container: 'rightLabel',
                                field: 'description',
                                placeholder: 'This is description box..',
                                variant: 'filled',
                              },
                            ],
                          },
                        },
                      },
                      {
                        render: () => 'Post Operation Orders',
                        visible:
                          postOperationOrder[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'text',
                        field: 'postOperationOrder',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        visible:
                          postOperationOrder[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Procedure',
                        visible:
                          operationalProcedure[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'text',
                        field: 'operationalProcedure',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        visible:
                          operationalProcedure[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Follow-up',
                        visible:
                          followUp[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'text',
                        field: 'followUp',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        visible:
                          followUp[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                visible: hpe[dataParams?.filters?.specialityFilter],
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        field: 'hpe',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                      },
                      {
                        type: 'text',
                        field: 'cytology',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};
export default ViewOperationNotesIPDForm;
