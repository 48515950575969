import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';

import {
  FormField, View,
} from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { autoSuggestInput, searchInput } from '../../../../../../autoSuggestions';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { doseTime, frequency, MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { history } from '../../../../../../images';
import ListActionModal from '../../../../../../Components/ListActionModal';
import { editConsultationUri } from '../../../../../../Queries/consultations';
import TableNoData from '../../../../../../Components/TableNoData';

const getNewId = () => `new_${uuid()}`;

const NewBill = (props) => {
  const { navigation } = props;
  const consultationId = navigation.getParam('consultationId');

  return (
    <ListActionModal
      {...props}
      icon={history}
      title="View Prescription"
      content={(
        <Form
          type="shadow"
          containerStyle={{
            backgroundColor: vars.colors.white,
            flex: 1,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 16,
            marginRight: 16,
          }}
          uri={editConsultationUri({
            consultationId,
          })}
          editable={false}
          closeView={1}
          beforeSubmit={({ data, updates }) => {
            const itemPharmacyDetails = data?.pharmacyDetails?.map((item) => ({
              item: { _id: item?.item?._id },
              quantity: item?.quantity,
              totalPrice: item?.totalPrice,
            }
            ));
            return {
              updates: {
                ...updates,
                pharmacyDetails: itemPharmacyDetails,
              },
            };
          }}
          defaultValues={() => ({
            pharmacyDetails: [{ _id: getNewId() }],
          })}
          computations={{
            children: {
              pharmacyDetails: {
                self: {
                  itemAutofil: {
                    compute: ({ item }) => ({
                      set: {
                        itemName: item?.name,
                        category: item?.category,
                        unitPrice: item?.sellingPrice,
                        manufacturer: item?.rateContract[0]?.manufacturer?.name,
                        stockAvailable: item?.stockAvailable,
                        quantity: '',
                      },
                    }),
                    onChange: ['item'],

                  },
                },
              },
            },
            self: {
              billAmount: {
                compute: ({ pharmacyDetails = [], discount }) => {
                  let totalAmount = 0;
                  let countLength = 0;
                  pharmacyDetails.map(({ totalPrice }) => {
                    totalAmount += totalPrice;
                    countLength += 1;
                    return (totalPrice, countLength);
                  });
                  // eslint-disable-next-line no-multi-assign
                  totalAmount = totalAmount *= (1 - discount / 100);
                  return {
                    set: { billAmount: totalAmount, totalItems: countLength },
                  };
                },
                onChange: ['pharmacyDetails.totalPrice', 'discount'],
              },
            },
          }}
          validations={{
            pharmacyDetails: {
              item: ({ data }) => {
                if (!data?.item) {
                  return 'This field cannot be empty';
                }

                return false;
              },
              quantity: ({ data }) => {
                if (data.quantity) {
                  if (data.quantity > data.stockAvailable) {
                    return 'Requested units can not be more than units available';
                  }
                }
                if (data?.quantity <= 0) {
                  return 'Unit Requested can not be less than or equal to 0';
                }
                if (!data?.quantity) return 'This field cannot be empty';

                return false;
              },
            },
          }}
          mandatory={{
            pharmacyDetails: {
              item: 1,
              quantity: 1,
            },
          }}
          {...props}
        >
          {({ form_state: { data } }) => {
            if (data?.pharmacyDetails) {
              return (
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <View style={{
                    flexDirection: 'row', flex: 1,
                  }}
                  >
                    <FormField
                      {... {
                        field: 'pharmacyDetails',
                        fieldType: 'nested',
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          listProps: {
                            hideColumnHeader: false,
                            columns: [
                              searchInput({
                                type: 'autoSuggest',
                                field: 'item',
                                header: `${'billing.labels.itemName'.getLabel()}`,
                                variant: 'filled',
                                keyFiled: 'name',
                                suggestionField: 'name',
                                valueField: 'name',
                                model: MODELS.PHARMACY_ITEM,
                                query: 'masterDataList',
                                editable: false,
                              }),
                              searchInput({
                                type: 'autoSuggest',
                                field: 'item',
                                header: `${'billing.labels.itemId'.getLabel()}`,
                                variant: 'filled',
                                keyFiled: 'itemCode',
                                suggestionField: 'itemCode',
                                valueField: 'itemCode',
                                model: MODELS.PHARMACY_ITEM,
                                query: 'masterDataList',
                                mandatory: true,
                                editable: false,
                              }),
                              {
                                type: 'number',
                                header: `${'billing.labels.quantity'.getLabel()}`,
                                field: 'quantity',
                                variant: 'filled',
                                mandatory: true,
                                editable: false,
                              },
                              autoSuggestInput({
                                header: `${'billing.labels.frequency'.getLabel()}`,
                                field: 'frequency',
                                suggestionField: 'label',
                                options: frequency,
                                editable: false,
                                variant: 'filled',
                              }),
                              {
                                type: 'text',
                                header: `${'billing.labels.doses'.getLabel()}`,
                                field: 'doses',
                                variant: 'filled',
                                editable: false,
                              },
                              autoSuggestInput({
                                header: `${'billing.labels.doseTime'.getLabel()}`,
                                field: 'doseTime',
                                suggestionField: 'label',
                                options: doseTime,
                                variant: 'filled',
                                width: 150,
                                editable: false,
                              }),
                              {
                                header: 'Comments',
                                type: 'text',
                                label: 'Comments',
                                field: 'comments',
                                variant: 'filled',
                                editable: false,
                              },
                            ],
                          },
                        },
                      }}
                    />
                  </View>
                </View>
              );
            }

            return <TableNoData />;
          }}
        </Form>
    )}
    />
  );
};
export default NewBill;
