import React from 'react';

import { FormHoc } from '../../../../../app-components';
import { EditPharmacyLowShelfLifeRequestStatus } from '../../../../../Queries/pharmacy';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const ViewLowShelfLifeRequest = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryId,
        } = {},
      } = {},
    } = {},
  } = props;

  const Form = FormHoc({
    ...props,
    type: 'standard',
    closeView: 1,
    reloadEvent: `reload${MODELS.PHARMACY_BATCH}`,
    uri: EditPharmacyLowShelfLifeRequestStatus({ ...props, queryId }),
    editable: false,
    lg: {
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          label: 'inventory.title.lowShelfLifeWarning'.getLabel(),
          labelStyle: {
            ...vars.headings.h11,
            color: vars.colors.error,
            paddingLeft: 12,
            paddingTop: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'inventory.labels.dateRaised'.getLabel(),
                  field: '_createdOn',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.itemId'.getLabel(),
                  field: 'item.itemCode',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.itemName'.getLabel(),
                  field: 'item.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.category'.getLabel(),
                  field: 'item.category.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.subCategory'.getLabel(),
                  field: 'item.subCategory.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.description'.getLabel(),
                  field: 'item.description',
                  editable: false,
                },
              ],
            },
          ],
        },
      ],
    },
  });

  return <Form {...props} />;
};

export default ViewLowShelfLifeRequest;
