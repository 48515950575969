import moment from 'moment/moment';
import React from 'react';
import { cancelButton, save, Toast } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { invoke } from '../../../AppServices';
import ListActionModal from '../../../Components/ListActionModal';
import { notificationTickWithBG } from '../../../images';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';

const ReviwedPackageModal = (props) => (
  <ListActionModal
    icon={notificationTickWithBG}
    title="Do you want to apply the yearly increment ?"

    content={(
      <Form
        {...props}
        closeView={1}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        reloadOnChangeEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}
        reloadEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}

        // validations={{
        //   packageReviewDate: ({ data }) => {
        //     const dateofpackage = moment(data.packageReviewDate);
        //     const today = moment();
        //     return dateofpackage.diff(today, 'days') <= 30;
        //   },
        // }}
        onSubmit={async (submitProps) => {
          const { navigation, navigation: { state: { params } } } = submitProps;
          try {
            const result = await invoke({
              id: 'applyYearlyIncrementBasedOnPayMatrix',
              paramValue: {
                employeeIds: params?.selectedIds,
                // packageReviewDate: submitProps?.data?.packageReviewDate,
                // applyDate: submitProps?.data?.applyDate,
                // incrementPercentage: submitProps?.data?.incrementPercentage,
              },
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: 'Successfully Reviewed'.getLabel(),
              });
              navigation.pop();
            }
            params.oldProps.eventDispatcher.notify(`reload${MODELS.MONTHLY_ATTENDANCE}`);
            params.oldProps.setScreenState({
              selectedIds: [],
            });
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: 'Error',
              type: 'error',
            });
          }
        }}
        // mandatory={{
        //   packageReviewDate: 1,
        //   applyDate: 1,
        //   incrementPercentage: 1,
        // }}
        footer={{
          containerStyle: {
            alignItems: 'center',
            height: 80,
          },
          actions: [
            cancelButton(),
            save(),
          ],
        }}
        lg={{
          formGroups: [
            {
              columnsPerRow: 1,
              groupsContainerStyle: { paddingLeft: 0 },
              groups: [
                {
                  columns: [
                    // {
                    //   type: 'date',
                    //   field: 'packageReviewDate',
                    //   label: 'Next Review Date',
                    //   variant: 'filled',
                    //   mandatory: true,
                    // },
                    // {
                    //   type: 'date',
                    //   field: 'applyDate',
                    //   label: 'Apply Date',
                    //   variant: 'filled',
                    //   mandatory: true,
                    // },
                    // {
                    //   type: 'number',
                    //   field: 'incrementPercentage',
                    //   label: 'Increment percentage of Basic',
                    //   variant: 'filled',
                    //   mandatory: true,
                    // },
                  ],
                },
              ],
            },
          ],
        }}
      />
      )}
    {...props}
  />
);
export default ReviwedPackageModal;
