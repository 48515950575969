import React from 'react';
import moment from 'moment';

import { consultationCardLogo, hindiConsultationCardLogo } from '../../images';
import vars from '../../theme/vars';
import { getConfiguration } from '../../AppServices';

const ConsultationReceipt = ({
  patientInfo,
  opdDetails,
  data,
  barcode,
  roomsInfo,
}) => {
  const configData = getConfiguration();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: 592,
      width: 842,
      flex: 2,
    }}
    >
      <div style={{
        flex: 1,
        display: 'flex',
        borderRight: '1px dashed black',
      }}
      />
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: 18,
        marginRight: 5,
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 13,
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 50,
          // position: 'relative',
        }}
        >
          {/* LOGO */}
          <div
            style={{
              // position: 'absolute',
              // left: '-15px',
              marginLeft: '3px',
            }}
          >
            <img
              src={consultationCardLogo}
              alt="logo"
              style={{ height: 50 }}
            />
          </div>

          <div style={{
            flexDirection: 'column', display: 'flex',
          }}
          >
            <div style={{ ...vars.headings.h18, fontSize: 8 }}>
              {`Website: ${configData?.website || '--'}`}
            </div>
            <div style={{ ...vars.headings.h18, fontSize: 8 }}>
              {`Email: ${configData?.email || '--'}`}
            </div>
            <div style={{ ...vars.headings.h18, fontSize: 8 }}>
              {`Phone: ${configData?.contactInfo || '--'}`}
            </div>
          </div>

        </div>

        <div style={{
          display: 'flex',
        }}
        >
          {/* Hospital Name */}
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              textAlign: 'center',
              // marginTop: '5px',
            }}
          >
            <div style={{
              fontFamily: 'Arial',
              fontWeight: 'bold',
              fontSize: 11,
            }}
            >
              {'consultation.labels.hospitalName'.getLabel()}
              {/* {' '} */}
              {/* {'consultation.labels.hospitalAddress'.getLabel()} */}
            </div>

            {/* TODO: add hindi text */}
            <div style={{
              fontFamily: 'Arial',
              fontWeight: 'bold',
              fontSize: 11,
            }}
            >
              <div
                style={{
                  // position: 'absolute',
                  // left: '-15px',
                  // marginLeft: '-15px',
                }}
              >
                <img
                  src={hindiConsultationCardLogo}
                  alt="Hindi Title"
                  style={{ height: 23 }}
                />
              </div>
              {/* {'consultation.labels.hospitalNameHindi'.getLabel()} */}
              {/* {' '} */}
              {/* {'consultation.labels.hospitalAddress'.getLabel()} */}
            </div>
          </div>
        </div>

        <div style={{
          flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'start', margin: 0, padding: 0,
        }}
        >

          {/* OPD */}
          <div style={{
            // marginTop: 5,
          }}
          >
            <div
              style={{
                // ...vars.headings.h14,
                fontSize: '11px',
                fontFamily: 'Arial',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {'consultation.labels.outPatientDepartment'.getLabel()}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <table style={{
              border: 'none!important',
              marginTop: 5,
              width: '100%',
            }}
            >
              <tr>
                <td
                  style={{
                    color: vars.colors.grey.color3,
                    fontSize: '9px',
                    paddingRight: '2px',
                    width: 70,
                    verticalAlign: 'top',
                  }}
                >
                  {`${'consultation.labels.patientName'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  {patientInfo?.patientName}
                </td>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '2px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.gender'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {patientInfo?.gender.charAt(0).toUpperCase() + patientInfo?.gender.slice(1)}
                </td>

                <td rowSpan="4" style={{ textAlign: 'center' }}>
                  <img
                    src={barcode}
                    width={57}
                    alt="barcode"
                  />
                  <div
                    style={{
                      marginTop: 8,
                      color: vars.colors.grey.color1,
                      fontSize: '9px',
                      fontWeight: 800,
                    }}
                  >
                    {`UHID : ${patientInfo.uhid}`}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '2px',
                  width: 70,
                  verticalAlign: 'top',
                }}
                >
                  {`${patientInfo?.relation}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  {patientInfo?.relationName}
                </td>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '2px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.age'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 50,
                }}
                >
                  {`${patientInfo?.age || ''} ${patientInfo?.ageType || ''}`}
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '2px',
                  width: 70,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.mobileNo'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  {patientInfo?.mobile || '--'}
                </td>

                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.opdNumber'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {data?.result?.ODPNumber}
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  width: 70,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.speciality'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  <div>
                    {`${opdDetails?.name}`}
                  </div>
                  <span style={{ marginRight: 6 }}>
                    {`(${'consultation.labels.room'.getLabel()} `}
                    {' '}
                    {`${roomsInfo?.map((item) => item.name).toString()})`}
                  </span>
                </td>

                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {`${'consultation.labels.charge'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 50,
                  verticalAlign: 'top',
                }}
                >
                  {opdDetails?.charge}
                </td>
              </tr>
              <tr>

                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '2px',
                  marginTop: 5,
                  width: 70,
                  verticalAlign: 'top',
                }}
                >
                  {'Address: '}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  {patientInfo?.address}
                </td>

              </tr>
              <tr>

                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  width: 70,
                  verticalAlign: 'top',
                }}
                >
                  {'Date: '}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                  width: 170,
                  verticalAlign: 'top',
                }}
                >
                  {`${moment(data?._createdOn).format('DD.MM.YYYY hh:mm A')}`}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div style={{ flexDirection: 'row', marginTop: 10, display: 'flex' }}>
              <div style={{
                color: vars.colors.grey.color3,
                fontSize: '9px',
              }}
              >
                {`${'consultation.labels.diagnosis'.getLabel()}: `}
              </div>
              <div style={{ paddingLeft: 8 }}>
                ----------------
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            borderTop: '1px solid grey',
            flex: 1,
          }}
          >
            <div style={{
              flex: 2,
              display: 'flex',
              borderRight: '1px solid grey',
              justifyContent: 'center',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: 24,
                width: '100%',
                alignItems: 'center',
              }}
              >
                <span style={vars.headings.h15}>
                  {'consultation.labels.investigations'.getLabel()}
                </span>
              </div>
            </div>
            <div style={{
              flex: 3,
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: 24,
                width: '100%',
                alignItems: 'center',
              }}
              >
                <span
                  style={{
                    ...vars.headings.h15,
                  }}
                >
                  {'consultation.labels.treatments'.getLabel()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default ConsultationReceipt;
