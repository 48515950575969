import React from 'react';
import { FormHoc, Text, View } from '../../../../../../app-components';
import theme from '../../../../../../App/AppContainer/Header/theme';
import { getString } from '../../../../../../Lib/helpers';
import vars from '../../../../../../theme/vars';

const Statutory = (props) => {
  const { profileInfo } = props;

  const StatutoryForm = FormHoc({
    type: 'standardShadow',
    closeView: 1,
    reloadEvent: 'reloadProfile',
    editable: false,
    bodyContainerStyle: { paddingLeft: 16 },
    defaultValues: () => ({ ...profileInfo }),
    formGroups: [
      {
        columnsPerRow: 1,
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => (
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={theme.userInfoWrapper}
                    >
                      <Text numberOfLines={1} style={theme.usernameText}>
                        {profileInfo?.firstName?.charAt(0) + profileInfo?.lastName?.charAt(0)}
                      </Text>
                    </View>
                    <View style={{ paddingLeft: 12, paddingTop: 2 }}>
                      <Text style={{ ...vars.headings.h5, color: vars.colors.secondary.color1 }}>
                        {getString([profileInfo?.firstName, profileInfo?.lastName])}
                      </Text>
                    </View>
                  </View>
                ),
              },
            ],
          },
        ],
      },
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        groupsContainerStyle: { paddingTop: 0 },
        columnContainerStyle: {
          marginLeft: 6, marginRight: 6, marginTop: 12, marginBottom: 12,
        },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'panNumber',
                label: 'profile.labels.panNumber'.getLabel(),
              },
              {
                type: 'text',
                field: 'pfNumber',
                label: 'profile.labels.pfNumber'.getLabel(),
              },
              {
                type: 'number',
                field: 'pranNumber',
                label: 'NPS Number',
                allowedDecimalDigits: false,
              },
              {
                type: 'number',
                field: 'epfNumber',
                label: 'EPF Number',
              },
            ],
          },
          {
            columns: [
              {
                type: 'text',
                field: 'accountNumber',
                label: 'profile.labels.account'.getLabel(),
              },
              {
                type: 'text',
                field: 'bank',
                label: 'profile.labels.bank'.getLabel(),
              },
              {
                type: 'text',
                field: 'ifscCode',
                label: 'profile.labels.ifsc'.getLabel(),
              },
              {
                type: 'text',
                field: 'paymentMode',
                label: 'profile.labels.paymentMode'.getLabel(),
              },
              {
                type: 'number',
                field: 'ecrNumber',
                label: 'employee.labels.ecrNumber'.getLabel(),
                allowedDecimalDigits: false,
              },
              {
                type: 'number',
                field: 'ecrPageNumber',
                label: 'employee.labels.ecrPageNumber'.getLabel(),
                allowedDecimalDigits: false,
              },
            ],
          },
        ],
      },
    ],
  });
  return <StatutoryForm {...props} />;
};

export default Statutory;
