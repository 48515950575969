import { size } from "lodash";

const patientListColumns = [
  {
    type: 'selection',
  },
  {
    header: 'inventory.headers.dateOfDispensing'.getLabel(),
    type: 'date',
    format: 'DD MMM YYYY',
    field: '_createdOn',
  },
  {
    header: 'inventory.headers.patientUHID'.getLabel(),
    type: 'text',
    format: 'DD MMM YYYY',
    field: 'patientId.uhid',
  },
  {
    header: 'inventory.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patientId.patientName',
  },
  {
    header: 'inventory.headers.noOfItems'.getLabel(),
    type: 'number',
    value: ({ item }) => size(item?.pharmacyDetails),
  },
  {
    header: 'inventory.headers.dispensedBy'.getLabel(),
    type: 'text',
    field: 'dispensedBy.name',
  },
];
export default patientListColumns;
