import React from 'react';
import { StandardButton, View } from '../../../../app-components';
import ListActionModal from '../../../../Components/ListActionModal';

const ConfirmTestModal = (props) => {
  const { navigation } = props;
  const { setValue, value } = navigation?.state?.params;
  return (
    <ListActionModal
      title={'lab.title.confirmModalTitle'.getLabel()}
      description="This action will remove test selected components."
      content={(
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        >
          <StandardButton
            label={'lab.buttons.confirm'.getLabel()}
            onPress={() => {
              setTimeout(() => {
                setValue({ field: 'manualTest', value: !value });
                setValue({ field: 'testComponentType', value: null });
              });
              navigation.pop();
            }}
            width={109}
            {...props}
          />
        </View>
  )}
      {...props}
    />
  );
};
export default ConfirmTestModal;
