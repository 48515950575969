import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';

const ViewServiceIPDForm = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      title="Service"
      description="View service details."

      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        value: item?.service,
                        label: 'SERVICE',
                      },
                      {
                        type: 'text',
                        value: item?.unit,
                        label: 'UNIT',
                      },
                      {
                        type: 'text',
                        value: item?.rate,
                        label: 'RATE',
                      },
                      {
                        type: 'number',
                        value: item?.amount,
                        label: 'AMOUNT',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default ViewServiceIPDForm;
