import React from 'react';

import { SubCategoryListUri } from '../../Queries/category';
import { ROUTES } from '../../Lib/constants';
import { Table } from '../../app-components/table/Table';

const card = {
  lg: {
    card: {
      type: 'title',
      title: { primaryField: (item) => item?.name },
      right: [
        {
          width: 90,
          items: [
            {
              value: 'View Details',
              action: {
                type: 'link',
                link: (props) => ({
                  view: ROUTES.viewPharmacySubCategory.name,
                  params: { item: props?.item },
                }),
              },
            },
          ],
        },
      ],
    },
  },
};

export default (props) => {
  const { filter } = props;

  return (
    <Table
      {...props}
      uri={() => SubCategoryListUri({ filter })}
      {...card}
    />
  );
};

