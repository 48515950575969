import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';

import {
  FormField, save, Text, View,
} from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { autoSuggestInput, searchInput } from '../../../../../../autoSuggestions';
import { submit } from '../../../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import {
  frequency, doseTime, MODELS,
} from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { history } from '../../../../../../images';
import ListActionModal from '../../../../../../Components/ListActionModal';
import { editConsultationUri } from '../../../../../../Queries/consultations';

const getNewId = () => `new_${uuid()}`;

const NewBill = (props) => {
  const { navigation } = props;
  const consultationId = navigation.getParam('consultationId');

  return (
    <ListActionModal
      {...props}
      icon={history}
      title="Add Prescription"
      content={(
        <Form
          type="shadow"
          containerStyle={{
            backgroundColor: vars.colors.white,
            flex: 1,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 16,
            marginRight: 16,
          }}
          uri={editConsultationUri({
            consultationId,
          })}
          footer={{
            actions: [save],
          }}
          closeView={1}
          beforeSubmit={({ data, updates }) => {
            const itemPharmacyDetails = data?.pharmacyDetails?.map((item) => ({
              item: { _id: item?.item?._id },
              quantity: item?.quantity,
              totalPrice: item?.totalPrice,
              frequency: item?.frequency,
              doses: item?.doses,
              doseTime: item?.doseTime,
              comments: item.comments,
            }
            ));
            return {
              updates: {
                ...updates,
                pharmacyDetails: itemPharmacyDetails,
              },
            };
          }}
          onSubmit={submit({
            model: MODELS.CONSULTATION,
          })}
          submitMessage="Prescription has been saved successfully."
          defaultValues={() => ({
            pharmacyDetails: [{ _id: getNewId() }],
          })}
          computations={{
            children: {
              pharmacyDetails: {
                self: {
                  itemAutofil: {
                    compute: ({ item }) => ({
                      set: {
                        itemName: item?.name,
                        category: item?.category,
                        unitPrice: item?.sellingPrice,
                        manufacturer: item?.rateContract[0]?.manufacturer?.name,
                        stockAvailable: item?.stockAvailable,
                        quantity: '',
                      },
                    }),
                    onChange: ['item'],

                  },
                },
              },
            },
          }}
          validations={{
            pharmacyDetails: {
              item: ({ data }) => {
                if (!data?.item) {
                  return 'This field cannot be empty';
                }

                return false;
              },
              quantity: ({ data }) => {
                if (data.quantity) {
                  if (data.quantity > data.stockAvailable) {
                    return 'Requested units can not be more than units available';
                  }
                }
                if (data?.quantity <= 0) {
                  return 'Unit Requested can not be less than or equal to 0';
                }
                if (!data?.quantity) return 'This field cannot be empty';

                return false;
              },
            },
          }}
          mandatory={{
            pharmacyDetails: {
              item: 1,
              quantity: 1,
            },
          }}
          {...props}
        >
          {() => (
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <View style={{
                flexDirection: 'row', flex: 1,
              }}
              >
                <FormField
                  {... {
                    field: 'pharmacyDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      header: ({ addRow }) => ({
                        leftActions: [
                          {
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                              >
                                <Text
                                  style={{
                                    ...vars.headings.h9,
                                    color: vars.colors.grey.color3,
                                  }}
                                >
                                  {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: addRow,
                          },
                        ],
                      }),
                      listProps: {
                        hideColumnHeader: true,
                        columns: [
                          searchInput({
                            type: 'autoSuggest',
                            field: 'item',
                            label: `${'billing.labels.itemName'.getLabel()}`,
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.PHARMACY_ITEM,
                            query: 'masterDataList',
                          },
                          {
                            addOnFilter: ({
                              data: {
                                _parent: {
                                  pharmacyDetails = [],
                                } = {},
                              },
                            } = {}) => {
                              const itemIds = pharmacyDetails?.filter(
                                ({ item }) => item?._id,
                              ).map(({ item }) => item?._id);

                              return {
                                _id: { $nin: itemIds },
                              };
                            },
                          }),
                          searchInput({
                            type: 'autoSuggest',
                            field: 'item',
                            label: `${'billing.labels.itemId'.getLabel()}`,
                            variant: 'filled',
                            keyFiled: 'itemCode',
                            suggestionField: 'itemCode',
                            valueField: 'itemCode',
                            model: MODELS.PHARMACY_ITEM,
                            query: 'masterDataList',
                            mandatory: true,
                            disabled: true,
                          }),
                          {
                            type: 'number',
                            label: `${'billing.labels.quantity'.getLabel()}`,
                            field: 'quantity',
                            variant: 'filled',
                            mandatory: true,
                          },
                          autoSuggestInput({
                            label: `${'billing.labels.frequency'.getLabel()}`,
                            field: 'frequency',
                            suggestionField: 'label',
                            placeholder: 'Select frequency',
                            options: frequency,
                            mandatory: true,
                            variant: 'filled',
                          }),
                          {
                            type: 'text',
                            label: `${'billing.labels.doses'.getLabel()}`,
                            field: 'doses',
                            variant: 'filled',
                            mandatory: true,
                          },
                          autoSuggestInput({
                            label: `${'billing.labels.doseTime'.getLabel()}`,
                            field: 'doseTime',
                            suggestionField: 'label',
                            placeholder: 'select dose time',
                            options: doseTime,
                            mandatory: true,
                            variant: 'filled',
                            width: 150,
                          }),
                          {
                            type: 'text',
                            label: 'Comments',
                            field: 'comments',
                            variant: 'filled',
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  }}
                />
              </View>
            </View>
          )}
        </Form>
    )}
    />
  );
};
export default NewBill;
