import React from 'react';

import moment from 'moment';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../../../app-components/form/Form';
import { currencyInput } from '../../../../../compositeInputs';
import { MODELS } from '../../../../../Lib/constants';
import { Text } from '../../../../../app-components';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const RaiseRepairRequest = (props) => {
  const { navigation } = props;

  const item = navigation.getParam('item');

  return (
    <Form
      {...props}
      onSubmit={submit({
        model: MODELS.ASSETS_PURCHASE,
      })}
      reloadEvent={`reload${MODELS.ASSET}`}
      defaultValues={() => ({
        type: 'repair',
        currency: 'INR',
        asset: item,
        item: item?.item,
        batchNumber: item?.batchNumber,
        sendApprovalToRole: 'MS',
      })}
      model={MODELS.ASSETS_PURCHASE}
      submitMessage="Repair Request has been raised successfully."
      closeView={1}
      formGroups={[
        {
          label: 'Repair Request Details',
          columnsPerRow: 3,
          columns: [
            {
              type: 'text',
              placeholder: 'Reason For Repair',
              label: 'Reason For Repair',
              field: 'reasonForRepair',
              variant: 'filled',
              editable: true,
              allowWhiteSpaces: true,
            },
            currencyInput({
              variant: 'filled',
              amount: {
                field: 'approximateCost',
                label: 'Approximate Cost',
                placeholder: 'Approximate Cost',
              },
              currency: {
                field: 'currency',
              },
            }),
            {
              type: 'text',
              placeholder: 'Send For Approval To',
              label: 'Send For Approval To',
              field: 'sendApprovalToRole',
              variant: 'filled',
              editable: true,
              disabled: true,
            },
          ],
        },
        {
          label: 'General Details',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
          },
          groupsContainerStyle: {
            paddingLeft: 8,
            marginTop: 8,
          },
          columnsPerRow: 3,
          columns: [
            {
              type: 'text',
              label: 'asset.labels.serialNo'.getLabel(),
              field: 'asset.serialNumber',
              variant: 'filled',
              editable: false,
            },
            {
              type: 'text',
              label: 'asset.labels.status'.getLabel(),
              field: 'asset.assetStatus',
              variant: 'filled',
              editable: false,
            },
            {
              type: 'text',
              label: 'asset.labels.loanOutDept'.getLabel(),
              field: 'asset.department.name',
              variant: 'filled',
              editable: false,
            },
          ],
        },
        {
          label: 'Finance Details',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
          },
          groupsContainerStyle: {
            paddingLeft: 8,
            marginTop: 8,
          },
          columnsPerRow: 3,
          columns: [
            {
              type: 'text',
              label: 'asset.labels.manufacturer'.getLabel(),
              field: 'asset.batchNumber.manufacturer.name',
              variant: 'filled',
              editable: false,
            },
            {
              type: 'text',
              label: 'asset.labels.supplier'.getLabel(),
              field: 'asset.batchNumber.supplier.name',
              variant: 'filled',
              editable: false,
            },
            {
              type: 'date',
              label: 'asset.labels.rateContractValidity'.getLabel(),
              format: 'DD-MM-YYYY',
              field: 'asset.batchNumber.rateContractValidity',
              render: ({
                data: {
                  item: {
                    rateContract = [],
                  } = {},
                  batchNumber: { supplier: batchSupplier = {} } = {},
                } = {},
              }) => {
                const result = rateContract.find(({
                  supplier = {},
                }) => supplier?._id === batchSupplier?._id);

                return (
                  <Text
                    style={[
                      vars.headings.h9,
                    ]}
                  >
                    {moment(result).format('DD MMM YYYY')}
                  </Text>
                );
              },
              variant: 'filled',
              editable: false,
            },
            {
              type: 'text',
              label: 'asset.labels.contractStatus'.getLabel(),
              field: 'asset.batchNumber.rateContractValidity',
              render: ({
                data: {
                  item: {
                    rateContract = [],
                  } = {},
                  batchNumber: { supplier: batchSupplier = {} } = {},
                } = {},
              }) => {
                const result = rateContract.find(({
                  supplier = {},
                }) => supplier?._id === batchSupplier?._id);

                return (
                  <Text
                    style={[
                      vars.headings.h9,
                    ]}
                  >
                    {new Date(result?.rateContractValidity) > new Date() ? 'On-Going' : 'Expired'}
                  </Text>
                );
              },
              variant: 'filled',
              editable: false,
            },
            {
              type: 'date',
              label: 'asset.labels.supplyReceivedOn'.getLabel(),
              format: 'DD MMM YYYY',
              field: 'asset.batchNumber.supplyReceivedOn',
              variant: 'filled',
              editable: false,
            },
          ],
        },
        {
          label: 'Services',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
          },
          columns: [
            {
              field: 'batchNumber.serviceItemDates',
              fieldType: 'nested',
              editable: false,
              Component: NestedTable,
              componentProps: {
                maxHeight: 400,
                listProps: {
                  columns: [
                    {
                      type: 'date',
                      format: 'DD MMM YYYY',
                      variant: 'filled',
                      header: 'asset.labels.preventiveDate'.getLabel(),
                      field: 'preventiveDate',
                    },
                    {
                      type: 'date',
                      format: 'DD MMM YYYY',
                      variant: 'filled',
                      field: 'serviceDate',
                      header: 'asset.labels.serviceDate'.getLabel(),
                    },
                    {
                      type: 'date',
                      format: 'DD MMM YYYY',
                      variant: 'filled',
                      header: 'asset.headers.visitStatus'.getLabel(),
                      field: 'serviceDate',
                      render: ({ item: data }) => (
                        <Text
                          style={[
                            vars.headings.h9,
                          ]}
                        >
                          {data?.serviceDate > new Date() ? 'Upcoming' : 'Yes'}
                        </Text>
                      ),
                    },
                  ],
                },
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export default RaiseRepairRequest;
