export default {
  labels: {
    room: 'Room Category',
    charges: 'Charges',
    status: 'Status',
    edit: 'Edit',
    addRoom: 'Add room',
  },
  placeholders: {
    rooms: 'Select Rooms',
  },
  title: {
    addRoom: 'Add Room Category',
    editRoom: 'Edit Room',
    subTitle: 'Fill out the form to add new Room Category',
    roomDetails: 'Room Details',
    roomManagement: 'Room Management',
  },
  messages: {
    roomCategoryAddedSuccessfully: 'Room Category added successfully',
    roomCategoryEditedSuccessfully: 'Room Category edited successfully',
    roomCategoryAlreadyExists: 'Room number already exists',
  },
};
