import vars from '../../theme/vars';

const { headings, colors } = vars;

const StatusCellTheme = {
  container: {
    flex: 1,
  },
  text: {
    ...headings.h8,
  },
  dotIndicatorActive: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: colors.primary.color2,
    marginLeft: 8,
  },
  dotIndicatorInActive: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: colors.error,
    marginLeft: 8,
  },
};

export default StatusCellTheme;
