import React from 'react';

import { save, Text, View } from '../../../../app-components';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../../theme/vars';
import { autoSuggestInput } from '../../../../autoSuggestions';

const EditAcpSchedule = (props) => (
  <Form
    {...props}
    closeView={1}
    reloadEvent={`reload${MODELS.ACP_PAY_SCHEDULE}`}
    mandatory={{
      gradePayLevel: 1,
      firstACPGradePayLevel: 1,
      firstACPMinAmount: 1,
      secondACPGradePayLevel: 1,
      secondACPMinAmount: 1,
      thirdACPGradePayLevel: 1,
      thirdACPMinAmount: 1,
    }}
    uri={() => ({
      query: {
        id: 'gradePay',
        addOnFilter: {
          _id: props?.navigation?.state?.params?.addOnFilter?._id,
        },
      },
      model: MODELS.ACP_PAY_SCHEDULE,
    })}
    onSubmit={submit({
      model: MODELS.ACP_PAY_SCHEDULE,
    })}
    submitMessage="Acp Pay Schedule had been updated successfully."
    type="standard"
    lg={{
      verticalFormGroups: [
        {

          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'gradePayLevel',
                      label: 'Grade Pay Level',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      model: MODELS.GRADE_PAY_LEVEL,
                      query: 'gradePay',
                    }),
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'firstACPGradePayLevel',
                      label: 'First Grade Pay Level',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      model: MODELS.GRADE_PAY_LEVEL,
                      query: 'gradePay',
                    }),
                    {
                      type: 'number',
                      label: 'First ACP Min Amount',
                      field: 'firstACPMinAmount',
                      variant: 'filled',
                    },
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'secondACPGradePayLevel',
                      label: 'Second Grade Pay Level',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      model: MODELS.GRADE_PAY_LEVEL,
                      query: 'gradePay',
                    }),
                    {
                      type: 'number',
                      label: 'Second ACP Min Amount',
                      field: 'secondACPMinAmount',
                      variant: 'filled',
                    },
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'thirdACPGradePayLevel',
                      label: 'Third Grade Pay Level',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      model: MODELS.GRADE_PAY_LEVEL,
                      query: 'gradePay',
                    }),
                    {
                      type: 'number',
                      label: 'Third ACP Min Amount',
                      field: 'thirdACPMinAmount',
                      variant: 'filled',
                    },
                  ],
                },

              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);

export default EditAcpSchedule;
