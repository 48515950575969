import vars from '../../../../../../theme/vars';

const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 60,
    },
    tabRowStyle: {
      flex: 1,
      marginLeft: 34,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginTop: 14,
      marginBottom: 14,
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      width: 114,
      height: 30,
      backgroundColor: vars.colors.primary.color4,
    },
    activeContainerStyle: {
      borderRadius: 4,
      backgroundColor: vars.colors.secondary.color2,
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 10,
      backgroundColor: vars.colors.secondary.color2,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...vars.headings.h13,
      color: vars.colors.grey.color3,
    },
    activeTextStyle: {
      ...vars.headings.h12,
      color: vars.colors.white,
    },
    countStyle: {
      marginLeft: 3,
      ...vars.headings.h13,
      color: vars.colors.grey.color3,
    },
    activeCountStyle: {
      ...vars.headings.h13,
      color: vars.colors.white,
    },
  },
};

export default tabTheme;
