import React from 'react';

import { StandardButton } from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FTS from '../../../Components/TableFilters/FtsSearch';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { timeConversion } from '../../../Lib/helpers';
import { countUri } from '../../../Queries/countUri';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'shift.labels.shiftId'.getLabel(),
    type: 'text',
    field: 'code',
  },
  {
    header: 'shift.labels.shiftName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'shift.labels.startTime'.getLabel(),
    type: 'time',
    value: ({ item: { startTime } }) => timeConversion(startTime),
    sortable: 'startTime',
  },
  {
    header: 'shift.labels.endTime'.getLabel(),
    type: 'time',
    value: ({ item: { endTime } }) => timeConversion(endTime),
  },
  {
    header: 'shift.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.SHIFT}
    editFormId={ROUTES.editShift.name}
    tableColumns={tableColumns}
    header={({ aggregates }) => ({
      title: <HeaderTitleWithCount label={'shift.title.shiftManagement'.getLabel()} count={aggregates?._count} />,
      countUri: countUri({
        model: MODELS.SHIFT,
        id: 'masterDataList',
      }),
      selectableActions: [
        {
          render: () => (
            <StandardButton label={'shift.title.exportToExcel'.getLabel()} />
          ),

          file: 'ShiftManagement',
          type: 'download',
          download: () => ({
            uri: {
              model: MODELS.SHIFT,
              query: {
                id: 'masterDataList',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (props) => {
              const { screenState: { selectedIds } } = props;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
      actions: [
        (props) => (<FTS {...props} action={{ exp: ['name', 'code'] }} />),
      ],
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewShift.name,
        params: { item },
      },
    })}
  />
);
