import React from 'react';
import { DateFilter, TabNavigator } from '../../../../../../app-components';
import { countUri } from '../../../../../../Queries/countUri';
import OPDPatientList from './OPD/PatientList';
import IPDPatientList from './IPD/PatientList';
import tabTheme from './childTabTheme';
import vars from '../../../../../../theme/vars';
import { MODELS } from '../../../../../../Lib/constants';

const PatientDispensingTabs = TabNavigator({
  theme: { ...tabTheme },
  actions: [
    <DateFilter
      field="_createdOn"
      key="_createdOn"
      containerStyle={
        {
          flexDirection: 'row',
          width: 303,
          height: 36,
          borderWidth: 1,
          padding: 9,
          marginTop: 7,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }
      }
    />,
  ],
  tabs: {
    OPD: {
      label: 'OPD',
      screen: OPDPatientList,
      countUri: countUri({
        model: MODELS.PHARMACY_BILLING,
        id: 'pharmacyBillingDataList',
      }),
    },
    IPD: {
      label: 'IPD',
      screen: IPDPatientList,
      // countUri: countUri({
      //   model: MODELS.PHARMACY_BILLING,
      //   id: 'pharmacyBillingDataList',
      // }),
    },
  },
});
export default PatientDispensingTabs;
