import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { GetOperationNotesUri } from '../../../../../../../Queries/operationNotes';
import { Table } from '../../../../../../../app-components/table/Table';
import { Image, StandardButton, TouchableOpacity } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { editRecord } from '../../../../../../../images';
import { getPermission } from '../../../../../../../AppServices';
import permissions from '../../../../../../../Lib/permissions';

const extractTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  const tempHour = hours < 10 ? `0${hours}` : hours;
  hours = hours ? tempHour : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes} ${meridiem}`;
  return time;
};

const tabListColumns = (dataParams) => ({
  md: {
    columns: [
      {
        type: 'date',
        header: 'DATE',
        field: 'operationDate',
        format: 'DD/MM/YYYY',
      },
      {
        type: 'text',
        field: 'operationName.name',
        header: 'NAME OF OPERATIONs',
      },
      {
        header: 'SURGEON NAME',
        type: 'text',
        // fontSize: 14,
        // lineHeight: 16,
        value: (data) => data?.item?.surgeonName?.map((item) => item.name).join(', '),
      },
      {
        type: 'text',
        header: 'START TIME',

        value: (data) => {
          const time = data?.item?.startTime;
          if (time) {
            return extractTime(new Date(time));
          }
          return time;
        },
      },
      {
        type: 'text',
        header: 'FINISH TIME',
        value: (data) => {
          const time = data?.item?.finishTime;
          if (time) {
            return extractTime(new Date(time));
          }
          return time;
        },
      },
      {
        header: 'ASSISTANTS',
        type: 'text',
        value: (data) => data?.item?.assistantsEmployee?.map((item) => item.name).join(', '),
      },
      {
        header: 'NURSES',
        type: 'text',
        field: 'nurseOnDuty.name',
      },
      {
        header: 'Edit'.getLabel(),
        type: 'text',
        visible: () => getPermission(
          permissions.departments.views.ipdEditForms
            .permissions.ipdEditForms.value,
        ),
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: ({ item }) => ({
          type: 'link',
          // visible: () => getPermission(
          //   permissions.departments.views.ipdEditForms
          //     .permissions.ipdEditForms.value,
          // ),
          link: {
            modal: true,
            view: ROUTES.editProcedureNoteIPD.name,
            params: { item, dataParams },

          },
        }),
      },
      //
    ],
  },
});
const OperationProcedureNotes = (props) => {
  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  const { dataParams } = props;
  return (
    <Table
      {...props}
      header={(item) => ({
        title: () => (
          <HeaderTitleWithCount
            label="Notes"
            count={item?.aggregates?._count}
          />
        ),
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Add New Notes"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.addProcedureNoteIPD.name,
              params: { ...params, dataParams },
              push: true,
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.OPERATION_PROCEDURE_NOTES}`}
      model={MODELS.OPERATION_PROCEDURE_NOTES}
      uri={() => GetOperationNotesUri({
        admissionId: { _id },
        speciality: props.dataParams?.filters?.specialityFilter,
      })}
      {...tabListColumns(dataParams)}
      action={{
        type: 'link',
        link: (info) => ({
          view: ROUTES.viewNotesIpd.name,
          modal: true,
          params: { item: info.item, dataParams },
        }),
      }}
    />
  );
};
export default OperationProcedureNotes;
