import React from 'react';

import {
  TabNavigator,
} from '../../../../app-components/tab/TabNavigator';
import DiscardBillingHistory from './DiscardBillHistory';
import { tabTheme } from '../../../../theme/tabBarTheme';
import { countUri } from '../../../../Queries/countUri';
import ActiveBillHistory from './ActiveBillHistory';
import { MODELS } from '../../../../Lib/constants';
import CanceledBillingHistory from './CancelBllHistory';
import permissions from '../../../../Lib/permissions';
import { getPermission } from '../../../../AppServices';

const LabBillingHistory = (props) => (
  <TabNavigator
    {...props}
    theme={{ ...tabTheme }}
    tabs={{

      ActiveBills: {
        label: 'Active Bills',
        screen: ActiveBillHistory,
        countUri: countUri({
          model: MODELS.LAB_BILLING,
          id: 'billingDataList',
          filter: { status: 'Active' },
          reloadEvent: 'billingDataList',

        }),
      },

      CanceledBills: {
        label: 'Canceled Bills',
        visible: () => getPermission(
          permissions?.billing?.views?.viewCanceledBill?.permissions?.viewCanceledBill?.value,
        ),
        screen: CanceledBillingHistory,
        countUri: countUri({
          model: MODELS.LAB_BILLING,
          id: 'billingDataList',
          reloadEvent: 'billingDataList',
          filter: { status: 'Pending from MS' },
        }),
      },
      DiscardedBills: {
        label: 'Discard Bills',
        screen: DiscardBillingHistory,
        countUri: countUri({
          model: MODELS.LAB_BILLING,
          id: 'billingDataList',
          reloadEvent: 'billingDataList',
          filter: { billStatus: false },
        }),
      },
    }}
  />
);

export default LabBillingHistory;
