import React from 'react';

import {
  StandardButton,
} from '../../../../../../../app-components';
import {
  MODELS,
  ROUTES,
} from '../../../../../../../Lib/constants';
import { viewECTNotesUri } from '../../../../../../../Queries/ipdEctNotes';
import { Table } from '../../../../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="Notes"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      type: 'link',
      link: ({ navigation: { state: { params = {} } } }) => ({
        view: ROUTES.logECTNotesPsychiatry.name,
        params: { ...params },
      }),
      render: () => (
        <StandardButton
          label="Log ECT Notes"
        />
      ),
    },
  ],
});

const tableItems = {
  header,
  md: {
    columns: [
      {
        header: 'Date',
        type: 'date',
        field: 'ECTlogDate',
        format: 'DD MMM YYYY',
      },
      {
        header: 'Notes',
        type: 'text',
        field: 'notes',
      },
    ],
  },
};

const ECTNote = (props) => (
  <Table
    {...props}
    uri={viewECTNotesUri}
    {...tableItems}
    reloadEvent={`reload${MODELS.IPD_ECT_NOTES}`}
    type="link"
    action={{
      type: 'link',
      link: (data) => ({
        view: ROUTES.viewECTNotesPsychiatry.name,
        params: {
          addOnFilter: {
            _id: data.item._id,
          },
        },
      }),
    }}
  />
);
export default ECTNote;

