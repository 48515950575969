import React from 'react';

import { TabNavigator } from '../../../../../../app-components';
import { countUri } from '../../../../../../Queries/countUri';
// import { MODELS } from '../../../../../../Lib/constants';
import LabList from '../LabList';
import PharmacyList from '../PharmacyList';

const ViewPatientHistoryTabs = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item,
        } = {},
      } = {},
    } = {},
  } = props;

  const PatientHistoryTabNavigations = TabNavigator({
    tabs: {
      Lab: {
        label: 'Lab',
        screen: LabList,
        screenProps: {
          consultation: item,
          // model: MODELS.EMPLOYEE,
          // id: 'employeeLists',
          // addOnFilter: { department: { _id: data?._id } },
        },
        countUri: countUri({
          // model: MODELS.EMPLOYEE,
          // id: 'employeeLists',
          // filter: { department: { _id: data?._id } },
        }),
      },
      pharmacy: {
        label: 'Pharmacy',
        screen: PharmacyList,
        screenProps: {
          consultation: item,
          // model: MODELS.APP_HISTORY_CHANGES,
          // id: 'getHistoryActivity',
          // addOnFilter: { id: data?._id },
        },
        countUri: countUri({
          // model: MODELS.APP_HISTORY_CHANGES,
          // id: 'getHistoryActivityCount',
          // filter: { id: data?._id },
        }),
      },
    },
  });
  return <PatientHistoryTabNavigations {...props} />;
};
export default ViewPatientHistoryTabs;
