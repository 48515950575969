import React from 'react';

import {
  Image,
  StandardButton, TouchableOpacity,
} from '../../../../app-components';
import {
  MODELS,
  ROUTES,
} from '../../../../Lib/constants';
import { Table } from '../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import { editRecord } from '../../../../images';
import { getPermission } from '../../../../AppServices';
import permissions from '../../../../Lib/permissions';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="ACP Schedule"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      type: 'link',
      visible: () => getPermission(
        permissions.mdm.views.editPayMatrix.permissions.editPayMatrix.value,
      ),
      link: ({ navigation: { state: { params = {} } } }) => ({
        view: ROUTES.addPaySchedule.name,
        params: { ...params },
      }),
      render: () => (
        <StandardButton
          label="Add ACP Schedule"
        />
      ),
    },
  ],
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        header: 'Grade Pay Level',
        type: 'text',
        field: 'gradePayLevel.name',
      },
      {
        header: '1st ACPL (8yrs)',
        type: 'text',
        field: 'firstACPGradePayLevel.name',
      },
      {
        header: 'Min. of the ACPL-1',
        type: 'text',
        field: 'firstACPMinAmount',
      },
      {
        header: '2nd ACPL (16yrs)',
        type: 'text',
        field: 'secondACPGradePayLevel.name',
      },
      {
        header: 'Min. of the ACPL-2',
        type: 'text',
        field: 'secondACPMinAmount',
      },
      {
        header: '3rd ACPL (24yrs)',
        type: 'text',
        field: 'thirdACPGradePayLevel.name',
      },
      {
        header: 'Min. of the ACPL-3',
        type: 'text',
        field: 'thirdACPMinAmount',
      },
      {
        header: 'Edit'.getLabel(),
        type: 'text',
        visible: () => getPermission(
          permissions.mdm.views.editPayMatrix.permissions.editPayMatrix.value,

        ),
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: (data) => ({
          type: 'link',
          link: {
            view: ROUTES.editPaySchedule.name,
            params: {
              addOnFilter: {
                _id: data.item._id,
              },
            },

          },
        }),
      },
    ],
  },
};

const AcpSchedule = (props) => (
  <Table
    {...props}
    uri={({
      query: {
        model: MODELS.ACP_PAY_SCHEDULE,
        id: 'gradePay',
      },
      aggregate: true,
      model: MODELS.ACP_PAY_SCHEDULE,
    })}
    {...tableItems}
    reloadEvent={`reload${MODELS.ACP_PAY_SCHEDULE}`}
    type="link"
    action={{
      type: 'link',
      link: (data) => ({
        view: ROUTES.viewPaySchedule.name,
        params: {
          addOnFilter: {
            _id: data.item._id,
          },
        },
      }),
    }}
  />
);
export default AcpSchedule;

