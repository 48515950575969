import React from 'react';

import { TabNavigator } from '../../../../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../../../../Components/TableFilters/FtsSearch';
import ActivityLogsTabList from '../../../../../../../../Wired/ActivityLogList';
import { filterActive, filterInactive } from '../../../../../../../../images';
import { assetStatus, MODELS } from '../../../../../../../../Lib/constants';
import { FilterGroup } from '../../../../../../../../app-components';
import { countUri } from '../../../../../../../../Queries/countUri';
import DepartmentWiseStockList from './DepartmentWiseStockList';
import ItemWiseStockList from './ItemWiseList';
import { dropDownShadow } from '../../../../../../../../theme/shadows';

const ViewAssetTabs = (props) => {
  const { navigation: { state: { params: { item = {} } = {} } = {} } = {} } = props;
  const { data } = props;

  return (
    <TabNavigator
      {...props}
      tabs={{
        itemWiseDistribution: {
          label: 'Item-wise Distribution',
          screen: ItemWiseStockList,
          screenProps: {
            model: MODELS.ASSET,
            id: 'assetInventoryDataList',
            addOnFilter: { item: { _id: item?._id } },
          },
          actions: [
            <FtsSearch action={{ exp: ['serialNumber'] }} key="search" />,
            FilterGroup({
              dropdownStyle: {
                width: 300,
                height: 300,
                backgroundColor: 'red',
                ...dropDownShadow,
                borderRadius: 8,
                overflow: 'hidden',
              },
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: assetStatus,
                  field: 'assetStatus',
                  label: 'Stock Status',
                  multiSelect: false,
                },
                {
                  type: 'autoSuggest',
                  field: 'batchNumber.manufacturer',
                  label: 'Manufacturers',
                  model: MODELS.PHARMACY_MANUFACTURER,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Manufacturer',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoSuggest',
                  field: 'batchNumber.supplier',
                  label: 'Supplier',
                  model: MODELS.PHARMACY_SUPPLIER,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Supplier',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoSuggest',
                  field: 'department',
                  label: 'Departments',
                  model: MODELS.DEPARTMENTS,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Department',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
              ],
            }),
          ],
          countUri: countUri({
            model: MODELS.ASSET,
            id: 'assetInventoryDataList',
            filter: { item: { _id: item?._id } },
          }),
        },
        departmentWiseStock: {
          label: 'Department-wise Stock Distribution',
          screen: DepartmentWiseStockList,
          screenProps: {
            data,
            model: MODELS.ASSET_DEPARTMENT_STOCKS,
            id: 'assetDepartmentInventoryStocksDataList',
            addOnFilter: { item: { _id: item?._id } },
          },
          countUri: countUri({
            model: MODELS.ASSET_DEPARTMENT_STOCKS,
            id: 'assetDepartmentInventoryStocksDataList',
            filter: { item: { _id: item?._id } },
          }),
          actions: [
            <FtsSearch action={{ exp: ['department.name'] }} key="search" />,
          ],
        },
        activityLog: {
          label: 'Activity Log',
          screen: ActivityLogsTabList,
          screenProps: {
            data: item,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: item?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: item?._id },
          }),
        },
      }}
    />
  );
};
export default ViewAssetTabs;
