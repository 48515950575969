import React, { useCallback, useEffect } from 'react';
import { isFunction } from 'lodash';

import { gpsStore } from '../../AppServices';
import RoomDropdown from './RoomDropdown';
import { Table } from '../../app-components/table/Table';

const tabListColumns = () => [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    width: 240,
    field: 'token',
  },
  {
    header: 'Room',
    type: 'text',
    width: 240,
    render: (props) => <RoomDropdown {...props} />,
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'Created On',
    type: 'date',
    field: '_createdOn',
    format: 'DD MM YYYY @ HH:MM A',
  },
];

const ListOfPatients = (props) => {
  const {
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    eventDispatcher,
    speciality,
    subscribeToUpdates,
    sessionId,
  } = props;

  const reloadConsultations = useCallback(() => {
    eventDispatcher.notify('reloadConsultations');
  }, [eventDispatcher]);

  const subscribeToNewConsultations = useCallback((specialityId) => {
    gpsStore.subscribeEvent({
      event: `consultationList-${specialityId}-new`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const unSubscribeToNewConsultations = useCallback((specialityId) => {
    gpsStore.unsubscribeEvent({
      event: `consultationList-${specialityId}-new`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const subscribeToConsultationsUpdates = useCallback((specialityId) => {
    gpsStore.subscribeEvent({
      event: `consultationList-${specialityId}-updates`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const unSubscribeToConsultationsUpdates = useCallback((specialityId) => {
    gpsStore.unsubscribeEvent({
      event: `consultationList-${specialityId}-updates`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  useEffect(() => {
    if (speciality?._id) {
      if (subscribeToUpdates) {
        subscribeToConsultationsUpdates(speciality?._id);
      }
      subscribeToNewConsultations(speciality?._id);
    }

    return () => {
      unSubscribeToConsultationsUpdates();
      unSubscribeToNewConsultations();
    };
  }, [speciality]);

  return (
    <Table
      {...props}
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: {
            statusSortValue: 1,
            virtualToken: 1,
            _createdOn: -1,
          },
        },
        model,
      })}
      lg={{
        columns: isFunction(tableColumns) ? tableColumns({ sessionId }) : tableColumns,
      }}
      showActionIcon={false}
      reloadEvent="reloadConsultations"
    />
  );
};
export default ListOfPatients;
