const permissions = {
  mdm: {
    label: 'MDM',
    value: 'menu_mdm',
    subLabel: 'SUB-MENU',
    views: {
      viewMDM: {
        label: 'View MDM',
        permissions: {
          viewMDM: {
            label: 'View MDM',
            value: 'mdm_view',
          },
        },
      },
      addEmployee: {
        label: 'Add Employee',
        permissions: {
          addEmployee: {
            label: 'Add Employee',
            value: 'mdm_employee_management_add',
          },
        },
      },
      viewEmployee: {
        label: 'View Employee',
        permissions: {
          viewEmployee: {
            label: 'View Employee',
            value: 'mdm_employee_management_view',
          },
        },
      },
      modifyEmployee: {
        label: 'Modify Employee Records',
        permissions: {
          modifyEmployee: {
            label: 'Modify Employee Records',
            value: 'mdm_employee_management_modify_records',
          },
        },
      },
      exportEmployee: {
        label: 'Export Employee Records',
        permissions: {
          exportEmployee: {
            label: 'Export Employee Records',
            value: 'mdm_employee_management_export_records',
          },
        },
      },
      importEmployee: {
        label: 'Import Employee Records',
        permissions: {
          importEmployee: {
            label: 'Import Employee Records',
            value: 'mdm_employee_management_imports_records',
          },
        },
      },
      addDepartment: {
        label: 'Add Department',
        permissions: {
          addDepartment: {
            label: 'Add Department',
            value: 'mdm_department_management_add',
          },
        },
      },
      viewDepartment: {
        label: 'View Department',
        permissions: {
          viewDepartment: {
            label: 'View Department',
            value: 'mdm_department_management_view',
          },
        },
      },
      modifyDepartment: {
        label: 'Modify Department',
        permissions: {
          modifyDepartment: {
            label: 'Modify Department',
            value: 'mdm_department_management_modify',
          },
        },
      },
      exportDepartment: {
        label: 'Export Department Records',
        permissions: {
          exportDepartment: {
            label: 'Export Department records',
            value: 'mdm_department_management_export_records',
          },
        },
      },
      addRole: {
        label: 'Add Role',
        permissions: {
          addRole: {
            label: 'Add Role',
            value: 'mdm_roles_management_add',
          },
        },
      },
      viewRole: {
        label: 'View Role',
        permissions: {
          viewRole: {
            label: 'View Role',
            value: 'mdm_roles_management_view',
          },
        },
      },
      modifyRole: {
        label: 'Modify Role',
        permissions: {
          modifyRole: {
            label: 'Modify Role',
            value: 'mdm_roles_management_modify',
          },
        },
      },
      exportRole: {
        label: 'Export Roles Record',
        permissions: {
          exportRole: {
            label: 'Export Roles Record',
            value: 'mdm_roles_management_export_records',
          },
        },
      },
      viewPatient: {
        label: 'View Patient',
        permissions: {
          viewPatient: {
            label: 'View Patient',
            value: 'mdm-patient-management_view',
          },
        },
      },
      export: {
        label: 'Export Patient Record',
        permissions: {
          export: {
            label: 'Export Patient Record',
            value: 'mdm-patient-management_export_record',
          },
        },
      },
      viewSpeciality: {
        label: 'View Speciality',
        permissions: {
          viewSpeciality: {
            label: 'View Speciality',
            value: 'mdm_speciality_management_view',
          },
        },
      },
      addSpeciality: {
        label: 'Add Speciality',
        permissions: {
          addSpeciality: {
            label: 'Add Speciality',
            value: 'mdm_speciality_management_add',
          },
        },
      },
      modifySpeciality: {
        label: 'Modify Speciality',
        permissions: {
          modifySpeciality: {
            label: 'Modify Speciality',
            value: 'mdm_speciality_management_modify',
          },
        },
      },
      exportSpeciality: {
        label: 'Export Speciality Records',
        permissions: {
          exportSpeciality: {
            label: 'Export Speciality Records',
            value: 'mdm_speciality_management_export',
          },
        },
      },
      addRoom: {
        label: 'Add Room',
        permissions: {
          addRoom: {
            label: 'Add Room',
            value: 'mdm_room_management_add',
          },
        },
      },
      viewRoom: {
        label: 'View Room',
        permissions: {
          viewRoom: {
            label: 'View Room',
            value: 'mdm_room_management_view',
          },
        },
      },
      viewRoomCategories: {
        label: 'View Room Categories',
        permissions: {
          viewRoomCategories: {
            label: 'View Room Categories',
            value: 'mdm_room_management_view',
          },
        },
      },
      modifyRoom: {
        label: 'Edit Room',
        permissions: {
          modifyRoom: {
            label: 'Edit Room',
            value: 'mdm_room_management_modify',
          },
        },
      },
      exportRoom: {
        label: 'Export Room Records',
        permissions: {
          exportRoom: {
            label: 'Export Room Records',
            value: 'mdm_room_management_export_room',
          },
        },
      },
      addEmployeeType: {
        label: 'Add Employee Type',
        permissions: {
          addEmployeeType: {
            label: 'Add Employee Type',
            value: 'mdm_employee_type_add',
          },
        },
      },
      viewEmployeeType: {
        label: 'View Employee Type',
        permissions: {
          viewEmployeeType: {
            label: 'View Employee Type',
            value: 'mdm_employee_type_view',
          },
        },
      },
      modifyEmployeeType: {
        label: 'Edit Employee Type',
        permissions: {
          modifyEmployeeType: {
            label: 'Edit Employee Type',
            value: 'mdm_employee_type_modify',
          },
        },
      },
      exportEmployeeType: {
        label: 'Export Employee Type Records',
        permissions: {
          exportEmployeeType: {
            label: 'Export Employee Type Records',
            value: 'mdm_employee_type_export_records',
          },
        },
      },
      addLab: {
        label: 'Add Lab',
        permissions: {
          addLab: {
            label: 'Add Lab',
            value: 'mdm_lab_management_add',
          },
        },
      },
      viewLab: {
        label: 'View Lab',
        permissions: {
          viewLab: {
            label: 'View Lab',
            value: 'mdm_lab_management_view',
          },
        },
      },
      modifyLab: {
        label: 'Edit Lab',
        permissions: {
          modifyLab: {
            label: 'Edit Lab',
            value: 'mdm_lab_management_edit',
          },
        },
      },
      addSalary: {
        label: 'Add Salary',
        permissions: {
          addSalary: {
            label: 'Add Salary',
            value: 'mdm_salary_setup_management_add',
          },
        },
      },
      addNewTaxSlab: {
        label: 'Add Tax Slab',
        permissions: {
          addNewTaxSlab: {
            label: 'Add Tax Slab',
            value: 'mdm_salary_tax_slab_management_add',
          },
        },
      },
      viewSalary: {
        label: 'View Salary',
        permissions: {
          viewSalary: {
            label: 'View Salary',
            value: 'mdm_salary_setup_management_view',
          },
        },
      },
      payMatrix: {
        label: 'Pay Matrix',
        permissions: {
          payMatrix: {
            label: 'Pay Matrix',
            value: 'mdm_pay_matrix',
          },
        },
      },
      editPayMatrix: {
        label: 'Edit Pay Matrix',
        permissions: {
          editPayMatrix: {
            label: 'Edit Pay Matrix',
            value: 'mdm_edit_pay_matrix',
          },
        },
      },
      patientManagement: {
        label: 'View Salary',
        permissions: {
          export: {
            label: 'View Salary',
            value: 'mdm_salary_setup_management_view',
          },
        },
      },
      modifySalary: {
        label: 'Edit Salary',
        permissions: {
          modifySalary: {
            label: 'Edit Salary',
            value: 'mdm_salary_setup_management_modify',
          },
        },
      },
      addSalaryComponent: {
        label: 'Add Salary Component',
        permissions: {
          addSalaryComponent: {
            label: 'Add Salary Component',
            value: 'mdm_salary_components_management_add',
          },
        },
      },
      viewSalaryComponent: {
        label: 'View Salary Component',
        permissions: {
          viewSalaryComponent: {
            label: 'View Salary Component',
            value: 'mdm_salary_components_management_view',
          },
        },
      },
      modifySalaryComponent: {
        label: 'Edit Salary Component',
        permissions: {
          modifySalaryComponent: {
            label: 'Edit Salary Component',
            value: 'mdm_salary_components_management_edit',
          },
        },
      },
      addSalaryStructureTemplate: {
        label: 'Add Salary Structure Template',
        permissions: {
          addSalaryStructureTemplate: {
            label: 'Add Salary Structure Template',
            value: 'mdm_salary_salary_structure_template_management_add',
          },
        },
      },
      viewSalaryStructureTemplate: {
        label: 'View Salary Structure Template',
        permissions: {
          viewSalaryStructureTemplate: {
            label: 'View Salary Structure Template',
            value: 'mdm_salary_salary_structure_template_management_view',
          },
        },
      },
      viewHouseList: {
        label: 'View Houses',
        permissions: {
          viewHouseList: {
            label: 'View Houses',
            value: 'mdm_house_management_view',
          },
        },
      },
      manageHouseView: {
        label: 'Manage Houses',
        permissions: {
          manageHouseView: {
            label: 'Manage Houses',
            value: 'mdm_house_management_manage_house_view',
          },
        },
      },
      allotHouseView: {
        label: 'Allot House',
        permissions: {
          allotHouseView: {
            label: 'Allot House',
            value: 'mdm_house_management_allot_house_view',
          },
        },
      },
      addElectricityBillOfHouse: {
        label: 'Add Electricity Bill Of House',
        permissions: {
          addElectricityBillOfHouse: {
            label: 'Add Electricity Bill Of House',
            value: 'mdm_house_management_add_electricity_bill_view',
          },
        },
      },
      modifySalaryStructureTemplate: {
        label: 'Edit Salary Structure Template',
        permissions: {
          modifySalaryStructureTemplate: {
            label: 'Edit Salary Structure Template',
            value: 'mdm_salary_salary_structure_template_management_modify',
          },
        },
      },
      addTestManagement: {
        label: 'Add Test Type',
        permissions: {
          addTestManagement: {
            label: 'Add Test Type',
            value: 'mdm_test_management_add',
          },
        },
      },
      viewTestManagement: {
        label: 'View Test Type',
        permissions: {
          viewTestManagement: {
            label: 'View Test Type',
            value: 'mdm_test_management_view',
          },
        },
      },
      modifyTestManagement: {
        label: 'Edit Test Type',
        permissions: {
          modifyTestManagement: {
            label: 'Edit Test Type',
            value: 'mdm_test_management_modify',
          },
        },
      },
      addTestComponent: {
        label: 'Add Test Component',
        permissions: {
          addTestComponent: {
            label: 'Add Test Component',
            value: 'mdm_test_component_management_add',
          },
        },
      },
      viewTestComponent: {
        label: 'View Test Component',
        permissions: {
          viewTestComponent: {
            label: 'View Test Component',
            value: 'mdm_test_component_management_view',
          },
        },
      },
      modifyTestComponent: {
        label: 'Edit Test Component',
        permissions: {
          modifyTestComponent: {
            label: 'Edit Test Component',
            value: 'mdm_test_component_management_edit',
          },
        },
      },
      addPharmacyCategory: {
        label: 'Add Pharmacy Category',
        permissions: {
          addPharmacyCategory: {
            label: 'Add Pharmacy Category',
            value: 'mdm_pharmacy_category_management_add',
          },
        },
      },
      viewPharmacyCategory: {
        label: 'View Pharmacy Category',
        permissions: {
          viewPharmacyCategory: {
            label: 'View Pharmacy Category',
            value: 'mdm_pharmacy_category_management_view',
          },
        },
      },
      modifyPharmacyCategory: {
        label: 'Edit Pharmacy Category',
        permissions: {
          modifyPharmacyCategory: {
            label: 'Edit Pharmacy Category',
            value: 'mdm_pharmacy_category_management_modify',
          },
        },
      },
      addPharmacySupplierAndManufacturer: {
        label: 'Add Pharmacy Supplier And Manufacturer',
        permissions: {
          addPharmacySupplierAndManufacturer: {
            label: 'Add Pharmacy Supplier And Manufacturer',
            value: 'mdm_pharmacy_supplier_and_manufacturer_management_add',
          },
        },
      },
      viewPharmacySupplierAndManufacturer: {
        label: 'View Pharmacy Supplier And Manufacturer',
        permissions: {
          viewPharmacySupplierAndManufacturer: {
            label: 'View Pharmacy Supplier And Manufacturer',
            value: 'mdm_pharmacy_supplier_and_manufacturer_management_view',
          },
        },
      },
      modifyPharmacySupplierAndManufacturer: {
        label: 'Edit Pharmacy Supplier And Manufacturer',
        permissions: {
          modifyPharmacySupplierAndManufacturer: {
            label: 'Edit Pharmacy Supplier And Manufacturer',
            value: 'mdm_pharmacy_supplier_and_manufacturer_management_modify',
          },
        },
      },
      addAssetCategory: {
        label: 'Add Asset Category',
        permissions: {
          addAssetCategory: {
            label: 'Add Asset Category',
            value: 'mdm_asset_category_management_add',
          },
        },
      },
      viewAssetCategory: {
        label: 'View Asset Category',
        permissions: {
          viewAssetCategory: {
            label: 'View Asset Category',
            value: 'mdm_asset_category_management_view',
          },
        },
      },
      modifyAssetCategory: {
        label: 'Edit Asset Category',
        permissions: {
          modifyAssetCategory: {
            label: 'Edit Asset Category',
            value: 'mdm_asset_category_management_modify',
          },
        },
      },
      addAssetSupplierAndManufacturer: {
        label: 'Add Asset Supplier And Manufacturer',
        permissions: {
          addAssetSupplierAndManufacturer: {
            label: 'Add Asset Supplier And Manufacturer',
            value: 'mdm_asset_supplier_and_manufacturer_management_add',
          },
        },
      },
      viewAssetSupplierAndManufacturer: {
        label: 'View Asset Supplier And Manufacturer',
        permissions: {
          viewAssetSupplierAndManufacturer: {
            label: 'View Asset Supplier And Manufacturer',
            value: 'mdm_asset_supplier_and_manufacturer_management_view',
          },
        },
      },
      modifyAssetSupplierAndManufacturer: {
        label: 'Edit Asset Supplier And Manufacturer',
        permissions: {
          modifyAssetSupplierAndManufacturer: {
            label: 'Edit Asset Supplier And Manufacturer',
            value: 'mdm_asset_supplier_and_manufacturer_management_modify',
          },
        },
      },
      addHoliday: {
        label: 'Add Holiday',
        permissions: {
          addHoliday: {
            label: 'Add Holiday',
            value: 'mdm_holiday_management_add',
          },
        },
      },
      viewHoliday: {
        label: 'View Holiday',
        permissions: {
          viewHoliday: {
            label: 'View Holiday',
            value: 'mdm_holiday_management_view',
          },
        },
      },
      modifyHoliday: {
        label: 'Edit Holiday',
        permissions: {
          modifyHoliday: {
            label: 'Edit Holiday',
            value: 'mdm_holiday_management_modify',
          },
        },
      },
      exportHoliday: {
        label: 'Export Holiday Records Holiday',
        permissions: {
          modifyHoliday: {
            label: 'Export Holiday Records Holiday',
            value: 'mdm_holiday_management_export_records',
          },
        },
      },
      addShift: {
        label: 'Add Shift',
        permissions: {
          addShift: {
            label: 'Add Shift',
            value: 'mdm_shift_management_add',
          },
        },
      },
      viewShift: {
        label: 'View Shift',
        permissions: {
          viewShift: {
            label: 'View Shift',
            value: 'mdm_shift_management_view',
          },
        },
      },
      modifyShift: {
        label: 'Edit Shift',
        permissions: {
          modifyShift: {
            label: 'Edit Shift',
            value: 'mdm_shift_management_modify',
          },
        },
      },
      addSchedule: {
        label: 'Add Schedule',
        permissions: {
          addSchedule: {
            label: 'Add Schedule',
            value: 'mdm_schedule_management_add',
          },
        },
      },
      viewSchedule: {
        label: 'View Schedule',
        permissions: {
          viewSchedule: {
            label: 'View Schedule',
            value: 'mdm_schedule_management_view',
          },
        },
      },
      modifySchedule: {
        label: 'Edit Schedule',
        permissions: {
          modifySchedule: {
            label: 'Edit Schedule',
            value: 'mdm_schedule_management_edit',
          },
        },
      },
      addLeaveType: {
        label: 'Add Leave Type',
        permissions: {
          addLeaveType: {
            label: 'Add Leave Type',
            value: 'mdm_leave_type_add',
          },
        },
      },
      viewLeaveType: {
        label: 'View Leave Type',
        permissions: {
          viewLeaveType: {
            label: 'View Leave Type',
            value: 'mdm_leave_type_view',
          },
        },
      },
      modifyLeaveType: {
        label: 'Edit Leave Type',
        permissions: {
          modifyLeaveType: {
            label: 'Edit Leave Type',
            value: 'mdm_leave_type_modify',
          },
        },
      },
      addLeavePolicyType: {
        label: 'Add Leave Policy Type',
        permissions: {
          addLeavePolicyType: {
            label: 'Add Leave Policy Type',
            value: 'mdm_leave_policy_type_add',
          },
        },
      },
      viewLeavePolicyType: {
        label: 'View Leave Policy Type',
        permissions: {
          viewLeavePolicyType: {
            label: 'View Leave Policy Type',
            value: 'mdm_leave_policy_type_view',
          },
        },
      },
      modifyLeavePolicyType: {
        label: 'Edit Leave Policy Type',
        permissions: {
          modifyLeavePolicyType: {
            label: 'Edit Leave Policy Type',
            value: 'mdm_leave_policy_type_modify',
          },
        },
      },
      addPurchaseTAndC: {
        label: 'Add Purchase T&C',
        permissions: {
          addPurchaseTAndC: {
            label: 'Add Purchase T&C',
            value: 'mdm_t&c_type_management_add',
          },
        },
      },
      viewPurchaseTAndC: {
        label: 'View Purchase T&C',
        permissions: {
          viewPurchaseTAndC: {
            label: 'View Purchase T&C',
            value: 'mdm_t&c_type_management_view',
          },
        },
      },
      modifyPurchaseTAndC: {
        label: 'Edit Purchase T&C',
        permissions: {
          modifyPurchaseTAndC: {
            label: 'Edit Purchase T&C',
            value: 'mdm_t&c_type_management_modify',
          },
        },
      },
      viewPurchaseCategory: {
        label: 'View Purchase Category',
        permissions: {
          viewPurchaseCategory: {
            label: 'View Purchase Category',
            value: 'mdm_purchase_category_management_view',
          },
        },
      },
      addPurchaseCategory: {
        label: 'Add Purchase Category',
        permissions: {
          addPurchaseCategory: {
            label: 'Add Purchase Category',
            value: 'mdm_purchase_category_management_add',
          },
        },
      },
      modifyPurchaseCategory: {
        label: 'Edit Purchase Category',
        permissions: {
          modifyPurchaseCategory: {
            label: 'Edit Purchase Category',
            value: 'mdm_purchase_category_management_modify',
          },
        },
      },
      viewVaccine: {
        label: 'View Vaccine',
        permissions: {
          viewVaccine: {
            label: 'View Vaccine',
            value: 'mdm_vaccine_view',
          },
        },
      },
      addVaccineSchedule: {
        label: 'Add Vaccine Schedule',
        permissions: {
          addVaccineSchedule: {
            label: 'Add Vaccine Schedule',
            value: 'mdm_vaccine_add_schedule',
          },
        },
      },
      editHospitalDetails: {
        label: 'Edit hospital details',
        permissions: {
          editHospitalDetails: {
            label: 'Edit hospital details',
            value: 'mdm_edit_hospital_details',
          },
        },
      },
      employeeDesignation: {
        label: 'MDM Employee Designation',
        permissions: {
          employeeDesignation: {
            label: 'MDM Employee Designation',
            value: 'mdm_employee_designation',
          },
        },
      },
      employeeCategory: {
        label: 'MDM Employee Category',
        permissions: {
          employeeCategory: {
            label: 'MDM Employee Category',
            value: 'mdm_employee_category',
          },
        },
      },
      exGratia: {
        label: 'Ex-Gratia and Society Salary',
        permissions: {
          exGratia: {
            label: 'Ex-Gratia and Society Salary',
            value: 'mdm_ex_gratia_society_salary',
          },
        },
      },
      viewOperation: {
        label: 'MDM View Operation',
        permissions: {
          viewOperation: {
            label: ' MDM View Operation',
            value: 'MDM view_operation',
          },
        },
      },
      addDisease: {
        label: 'Add Disease',
        permissions: {
          addDisease: {
            label: 'Add Disease',
            value: 'mdm_disease_management_add',
          },
        },
      },
      viewDisease: {
        label: 'View Disease',
        permissions: {
          viewDisease: {
            label: 'View Disease',
            value: 'mdm_disease_management_view',
          },
        },
      },
      modifyDisease: {
        label: 'Edit Disease',
        permissions: {
          modifyDisease: {
            label: 'Edit Disease',
            value: 'mdm_disease_management_edit',
          },
        },
      },
    },
  },
  departments: {
    label: 'Departments',
    value: 'menu_department',
    subLabel: 'SUB-MENU',
    views: {
      viewDepartments: {
        label: 'View Departments',
        permissions: {
          viewDepartments: {
            label: 'View Departments',
            value: 'department_accounts_accounts',
          },
        },
      },
      opdPatientRegistration: {
        label: 'OPD Patient Registrations',
        permissions: {
          opdPatientRegistration: {
            label: 'OPD Patient Registrations',
            value: 'department_opd_patient_registration',
          },
        },
      },
      opdIncomingPatients: {
        label: 'OPD Incoming Patients',
        permissions: {
          opdIncomingPatients: {
            label: 'OPD Incoming Patients',
            value: 'department_opd_incoming_patients',
          },
        },
      },
      opdPatientTokenGeneration: {
        label: 'OPD Patient Token Generation',
        permissions: {
          opdPatientTokenGeneration: {
            label: 'OPD Patient Token Generation',
            value: 'department_opd_patient_token_generation',
          },
        },
      },
      opdConsultedPatientHistory: {
        label: 'OPD Consulted Patient History',
        permissions: {
          opdConsultedPatientHistory: {
            label: 'OPD Consulted Patient History',
            value: 'department_opd_consulted_patient_history',
          },
        },
      },
      opdReports: {
        label: 'OPD Reports',
        permissions: {
          opdReports: {
            label: 'OPD Reports',
            value: 'department_opd_reports',
          },
        },
      },
      liveConsultation: {
        label: 'OPD Live Consultation',
        permissions: {
          liveConsultation: {
            label: 'OPD Live Consultation',
            value: 'department_opd_live_consultation',
          },
        },
      },
      vaccinationTokenGeneration: {
        label: 'Vaccination Token Generation',
        permissions: {
          vaccinationTokenGeneration: {
            label: 'Vaccination Token Generation',
            value: 'department_vaccination_token_generation',
          },
        },
      },
      vaccinationIncomingPatients: {
        label: 'Vaccination Incoming Patients',
        permissions: {
          vaccinationIncomingPatients: {
            label: 'Vaccination Incoming Patients',
            value: 'department_vaccination_incoming_patients',
          },
        },
      },
      vaccinationHistory: {
        label: 'Vaccination History',
        permissions: {
          vaccinationHistory: {
            label: 'Vaccination History',
            value: 'department_vaccination_history',
          },
        },
      },
      viewAccounts: {
        label: 'View Accounts',
        permissions: {
          viewAccounts: {
            label: 'View Accounts',
            value: 'department_accounts_view',
          },
        },
      },
      accounts: {
        label: 'Accounts',
        permissions: {
          accounts: {
            label: 'Accounts',
            value: 'department_accounts',
          },
        },
      },
      filesAndBills: {
        label: 'Files And Bills',
        permissions: {
          filesAndBills: {
            label: 'Files And Bills',
            value: 'department_files_and_bills',
          },
        },
      },
      Requests: {
        label: 'View Requests',
        permissions: {
          viewRequests: {
            label: 'View Requests',
            value: 'department_requests_view',
          },
        },
      },
      Attendance: {
        label: 'View Attendance',
        permissions: {
          viewAttendance: {
            label: 'View Attendance',
            value: 'department_attendance_view',
          },
        },
      },
      attendanceHold: {
        label: 'Hold Attendance',
        permissions: {
          attendanceHold: {
            label: 'Hold Attendance',
            value: 'department_attendance_hold',
          },
        },
      },
      attendanceReview: {
        label: 'Review Attendance',
        permissions: {
          attendanceReview: {
            label: 'Review Attendance',
            value: 'department_attendance_review',
          },
        },
      },
      attendanceApprove: {
        label: 'Approve Attendance',
        permissions: {
          attendanceApprove: {
            label: 'Approve Attendance',
            value: 'department_attendance_approve',
          },
        },
      },
      SalaryFlow: {
        label: 'Salary Flow',
        permissions: {
          SalaryFlow: {
            label: 'Salary Flow',
            value: 'department_salary_flow',
          },
        },
      },
      EmployeeRemarks: {
        label: 'Employee Remarks',
        permissions: {
          EmployeeRemarks: {
            label: 'Employee Remarks',
            value: 'department_employee_remarks',
          },
        },
      },
      generateEmployeeSalary: {
        label: 'Generate Employee Salary',
        permissions: {
          generateEmployeeSalary: {
            label: 'Generate Employee Salary',
            value: 'mdm_employee_generate_salary',
          },
        },
      },
      importEmployeeSalaryComponents: {
        label: 'Import Employee Salary Components',
        permissions: {
          importEmployeeSalaryComponents: {
            label: 'Import Employee Salary Components',
            value: 'mdm_employee_import_salary_components',
          },
        },
      },
      addEditCompensationStructure: {
        label: 'Add/Edit Compensation structure in employees',
        permissions: {
          addEditCompensationStructure: {
            label: 'Add/Edit Compensation structure in employees',
            value: 'mdm_employee_add_edit_compensation_structure',
          },
        },
      },
      generateSalarySheet: {
        label: 'Generate Salary Sheet',
        permissions: {
          generateSalarySheet: {
            label: 'Generate Salary Sheet',
            value: 'department_accounts_generate_salary_sheet',
          },
        },
      },
      incomeTaxActions: {
        label: 'Approve/Reject/Request/Freeze Tax Declarations',
        permissions: {
          incomeTaxActions: {
            label: 'Approve/Reject/Request/Freeze Tax Declarations',
            value: 'department_accounts_approve_reject_request_freeze_tax_declaration',
          },
        },
      },
      approveRejectLeaveRequests: {
        label: 'Approve/Reject Leave Requests',
        permissions: {
          approveRejectLeaveRequests: {
            label: 'Approve/Reject Leave Requests',
            value: 'department_accounts_approve_reject_leave_requests',
          },
        },
      },
      approveRejectAttendanceRequests: {
        label: 'Approve/Reject Attendance Requests',
        permissions: {
          approveRejectAttendanceRequests: {
            label: 'Approve/Reject Attendance Requests',
            value: 'department_accounts_approve_reject_attendance_requests',
          },
        },
      },
      ipdPatientAdmissions: {
        label: 'IPD Patient Admissions',
        permissions: {
          ipdPatientAdmissions: {
            label: 'IPD Patient Admissions',
            value: 'department_ipd_patient_admission',
          },
        },
      },
      ipdEditForms: {
        label: 'IPD Edit Form',
        permissions: {
          ipdEditForms: {
            label: 'IPD Edit Form',
            value: 'department_ipd_edit_form',
          },
        },
      },
      ipdConsultedPatientHistory: {
        label: 'IPD Consulted PatientHistory',
        permissions: {
          ipdConsultedPatientHistory: {
            label: 'IPD Consulted PatientHistory',
            value: 'ipd_department_consulted_patient_history',
          },
        },
      },
      ipdPatientManagement: {
        label: 'IPD Patient Management',
        permissions: {
          ipdPatientManagement: {
            label: 'IPD Patient Management',
            value: 'ipd_department_patient_management',
          },
        },
      },
      ipdNurseView: {
        label: 'IPD Nurse View',
        permissions: {
          ipdNurseView: {
            label: 'IPD Nurse View',
            value: 'ipd_department_nurseView',
          },
        },
      },
    },
  },
  billing: {
    label: 'Billing',
    value: 'menu_billing',
    subLabel: 'SUB-MENU',
    views: {
      viewBilling: {
        label: 'View  Billing',
        permissions: {
          viewBilling: {
            label: 'View Billing',
            value: 'view_billing',
          },
        },
      },
      generatePharmacyBill: {
        label: 'Generate Pharmacy Bill',
        permissions: {
          generatePharmacyBill: {
            label: 'Generate Pharmacy Bill',
            value: 'Generate_pharmacy_bill',
          },
        },
      },
      pharmacyBillingRecords: {
        label: 'Pharmacy Billing Records',
        permissions: {
          pharmacyBillingRecords: {
            label: 'Pharmacy Billing Records',
            value: 'Pharmacy_billing_records',
          },
        },
      },
      exportPharmacyBillingRecords: {
        label: 'Export Pharmacy Billing Records',
        permissions: {
          exportPharmacyBillingRecords: {
            label: 'Export Pharmacy Billing Records',
            value: 'Pharmacy_billing_records_export',
          },
        },
      },
      generateLabBill: {
        label: 'Generate Lab Bill',
        permissions: {
          generateLabBill: {
            label: 'Generate Lab Bill',
            value: 'Generate_lab_bill',
          },
        },
      },
      generateOPDBill: {
        label: 'Generate OPD Bill',
        permissions: {
          generateOPDBill: {
            label: 'Generate OPD Bill',
            value: 'Generate_opd_bill',
          },
        },
      },
      generateIPDBill: {
        label: 'Generate IPD Bill',
        permissions: {
          generateIPDBill: {
            label: 'Generate IPD Bill',
            value: 'Generate_ipd_bill',
          },
        },
      },
      generateAssetBill: {
        label: 'Generate Asset Bill',
        permissions: {
          generateAssetBill: {
            label: 'Generate Asset Bill',
            value: 'Generate_asset_bill',
          },
        },
      },
      viewCanceledBill: {
        label: 'Canceled Bills',
        permissions: {
          viewCanceledBill: {
            label: 'Canceled Bills',
            value: 'canceled_bills',
          },
        },
      },
    },
  },
  inventory: {
    label: 'Inventory',
    value: 'menu_inventory',
    subLabel: 'SUB-MENU',
    views: {
      inventory: {
        label: 'View Inventory',
        permissions: {
          inventory: {
            label: 'View Inventory',
            value: 'view_inventory',
          },
        },
      },
      viewBloodBankCentralInventory: {
        label: 'View Blood Bank Central Inventory',
        permissions: {
          viewBloodBankCentralInventory: {
            label: 'View Blood Bank Central Inventory',
            value: 'view_blood_inventory_central_inventory',
          },
        },
      },
      updateBloodBagCentralInventory: {
        label: 'Update Blood Bag Details',
        permissions: {
          updateBloodBagCentralInventory: {
            label: 'Update Blood Bag Details',
            value: 'view_blood_inventory_update_bag',
          },
        },
      },
      raiseBloodRequest: {
        label: 'Raise Blood Request',
        permissions: {
          raiseBloodRequest: {
            label: 'Raise Blood Request',
            value: 'inventory_blood_bank_raise_blood_request',
          },
        },
      },
      viewCompanies: {
        label: 'View Companies',
        permissions: {
          viewCompanies: {
            label: 'View Companies',
            value: 'inventory_blood_bank_view_companies',
          },
        },
      },
      addCompany: {
        label: 'Add Company',
        permissions: {
          addCompany: {
            label: 'Add Company',
            value: 'inventory_blood_bank_add_companies',
          },
        },
      },
      editCompany: {
        label: 'Edit Company',
        permissions: {
          editCompany: {
            label: 'Edit Company',
            value: 'inventory_blood_bank_edit_companies',
          },
        },
      },
      viewDonors: {
        label: 'View Donors',
        permissions: {
          viewDonors: {
            label: 'View Donors',
            value: 'inventory_blood_bank_view_donors',
          },
        },
      },
      addDonor: {
        label: 'Add Donors',
        permissions: {
          addDonor: {
            label: 'Add Donors',
            value: 'inventory_blood_bank_add_donors',
          },
        },
      },
      editDonor: {
        label: 'Edit Donors',
        permissions: {
          editDonor: {
            label: 'Edit Donors',
            value: 'inventory_blood_bank_edit_donors',
          },
        },
      },
      addDonation: {
        label: 'Add Donation',
        permissions: {
          addDonation: {
            label: 'Add Donation',
            value: 'inventory_blood_bank_add_donation',
          },
        },
      },
      viewBloodBankRecords: {
        label: 'View Blood Bank Records',
        permissions: {
          viewBloodBankRecords: {
            label: 'View Blood Bank Records',
            value: 'inventory_blood_bank_view_records',
          },
        },
      },
      viewBloodBankRequests: {
        label: 'View Blood Bank Requests',
        permissions: {
          viewBloodBankRequests: {
            label: 'View Blood Bank Requests',
            value: 'inventory_blood_bank_view_requests',
          },
        },
      },
      viewBloodBankMSRequests: {
        label: 'View Blood Bank MS Requests',
        permissions: {
          viewBloodBankMSRequests: {
            label: 'View Blood Bank MS Requests',
            value: 'inventory_blood_bank_ms_view_requests',
          },
        },
      },
      issueBlood: {
        label: 'Issue Blood(Patient)',
        permissions: {
          issueBlood: {
            label: 'Issue Blood',
            value: 'inventory_blood_bank_issue_blood',
          },
        },
      },
      issueBloodToCompany: {
        label: 'Issue Blood(Company)',
        permissions: {
          issueBloodToCompany: {
            label: 'Issue Blood',
            value: 'inventory_blood_bank_issue_blood_company',
          },
        },
      },
      approveRejectBloodRequests: {
        label: 'Approve / Reject Blood Requests',
        permissions: {
          approveRejectBloodRequests: {
            label: 'Approve / Reject Blood Requests',
            value: 'inventory_blood_bank_approve_reject',
          },
        },
      },
      viewPharmacyCentralInventory: {
        label: 'View Pharmacy Central Inventory',
        permissions: {
          viewPharmacyCentralInventory: {
            label: 'View Pharmacy Central Inventory',
            value: 'inventory_pharmacy_central_inventory_view',
          },
        },
      },
      viewPharmacyDepartmentInventory: {
        label: 'View Pharmacy Department Inventory',
        permissions: {
          viewPharmacyDepartmentInventory: {
            label: 'View Pharmacy Department Inventory',
            value: 'inventory_pharmacy_department_inventory_view',
          },
        },
      },
      viewPharmacyCentralRequests: {
        label: 'View Pharmacy Central Requests',
        permissions: {
          viewPharmacyCentralRequests: {
            label: 'View Pharmacy Central Requests',
            value: 'inventory_pharmacy_central_requests_view',
          },
        },
      },
      viewPharmacyDepartmentRequests: {
        label: 'View Pharmacy Department Requests',
        permissions: {
          viewPharmacyDepartmentRequests: {
            label: 'View Pharmacy Department Requests',
            value: 'inventory_pharmacy_department_requests_view',
          },
        },
      },
      viewPharmacyCentralRecords: {
        label: 'View Pharmacy Central Records',
        permissions: {
          viewPharmacyCentralRecords: {
            label: 'View Pharmacy Central Records',
            value: 'inventory_pharmacy_central_records_view',
          },
        },
      },
      viewPharmacyDepartmentRecords: {
        label: 'View Pharmacy Department Records',
        permissions: {
          viewPharmacyDepartmentRecords: {
            label: 'View Pharmacy Department Records',
            value: 'inventory_pharmacy_department_records_view',
          },
        },
      },
      viewPharmacyMSIIndentRequests: {
        label: 'View Pharmacy MS Indent Requests',
        permissions: {
          viewPharmacyMSIIndentRequests: {
            label: 'View Pharmacy MS Indent Requests',
            value: 'inventory_pharmacy_msi_indent_requests_view',
          },
        },
      },
      transferPharmacyStocks: {
        label: 'Transfer Pharmacy Stocks',
        permissions: {
          transferPharmacyStocks: {
            label: 'Transfer Pharmacy Stocks',
            value: 'inventory_pharmacy_transfer_stocks',
          },
        },
      },
      addPharmacyItem: {
        label: 'Add Pharmacy Item',
        permissions: {
          addPharmacyItem: {
            label: 'Add Pharmacy Item',
            value: 'inventory_pharmacy_add_item',
          },
        },
      },
      editPharmacyItem: {
        label: 'Edit Pharmacy Item',
        permissions: {
          editPharmacyItem: {
            label: 'Edit Pharmacy Item',
            value: 'inventory_pharmacy_edit_item',
          },
        },
      },
      exportCentralPharmacyItem: {
        label: 'Export Central Pharmacy Item',
        permissions: {
          exportCentralPharmacyItem: {
            label: 'Export Central Pharmacy Item',
            value: 'inventory_central_pharmacy_export_item',
          },
        },
      },
      exportDepartmentPharmacyItem: {
        label: 'Export Department Pharmacy Item',
        permissions: {
          exportDepartmentPharmacyItem: {
            label: 'Export Department Pharmacy Item',
            value: 'inventory_department_pharmacy_export_item',
          },
        },
      },
      raisePurchaseRequestPharmacy: {
        label: 'Raise Purchase Request Pharmacy',
        permissions: {
          raisePurchaseRequestPharmacy: {
            label: 'Raise Purchase Request Pharmacy',
            value: 'inventory_pharmacy_raise_purchase_request',
          },
        },
      },
      viewPurchaseRequest: {
        label: 'View Purchase Requests',
        permissions: {
          viewPurchaseRequest: {
            label: 'View Purchase Requests',
            value: 'inventory_purchase_indent_view_purchase_request',
          },
        },
      },
      raiseIndentRequestPharmacy: {
        label: 'Raise Indent Request Pharmacy',
        permissions: {
          raiseIndentRequestPharmacy: {
            label: 'Raise Indent Request Pharmacy',
            value: 'inventory_pharmacy_indent_purchase_request',
          },
        },
      },
      viewAssetConsumablesCentralInventory: {
        label: 'View Store Consumables Central Inventory',
        permissions: {
          viewAssetConsumablesCentralInventory: {
            label: 'View Store Consumables Central Inventory',
            value: 'inventory_asset_consumables_central_inventory_view',
          },
        },
      },
      viewAssetConsumebalesDepartmentInventory: {
        label: 'View Store Consumables Department Inventory',
        permissions: {
          viewAssetConsumebalesDepartmentInventory: {
            label: 'View Store Consumables Department Inventory',
            value: 'inventory_asset_consumables_department_inventory_view',
          },
        },
      },
      viewAssetConsumablesCentralRequests: {
        label: 'View Store Consumables Central Requests',
        permissions: {
          viewAssetConsumablesCentralRequests: {
            label: 'View Store Consumables Central Requests',
            value: 'inventory_asset_consumables_central_requests_view',
          },
        },
      },
      viewAssetConsumablesDepartmentRequests: {
        label: 'View Store Consumables Department Requests',
        permissions: {
          viewAssetConsumablesDepartmentRequests: {
            label: 'View Store Consumables Department Requests',
            value: 'inventory_asset_consumables_department_requests_view',
          },
        },
      },
      viewAssetConsumablesCentralRecords: {
        label: 'View Store Consumables Central Records',
        permissions: {
          viewAssetConsumablesCentralRecords: {
            label: 'View Store Consumables Central Records',
            value: 'inventory_asset_consumables_central_records_view',
          },
        },
      },
      viewAssetConsumablesDepartmentRecords: {
        label: 'View Store Consumables Department Records',
        permissions: {
          viewAssetConsumablesDepartmentRecords: {
            label: 'View Store Consumables Department Records',
            value: 'inventory_asset_consumables_department_records_view',
          },
        },
      },
      viewAssetConsumablesMSIIndentRequests: {
        label: 'View Store Consumables MS Indent Requests',
        permissions: {
          viewAssetConsumablesMSIIndentRequests: {
            label: 'View Store Consumables MS Indent Requests',
            value: 'inventory_asset_consumables_msi_indent_requests_view',
          },
        },
      },
      transferAssetConsumablesStocks: {
        label: 'Transfer Store Consumables Stocks',
        permissions: {
          transferAssetConsumablesStocks: {
            label: 'Transfer Store Consumables Stocks',
            value: 'inventory_asset_consumables_transfer_stocks',
          },
        },
      },
      addAssetConsumablesItem: {
        label: 'Add Store Consumables Item',
        permissions: {
          addAssetConsumablesItem: {
            label: 'Add Store Consumables Item',
            value: 'inventory_asset_consumables_add_item',
          },
        },
      },
      editAssetConsumablesItem: {
        label: 'Edit Store Consumables Item',
        permissions: {
          editAssetConsumablesItem: {
            label: 'Edit Store Consumables Item',
            value: 'inventory_asset_consumables_edit_item',
          },
        },
      },
      exportCentralAssetConsumablesItem: {
        label: 'Export Central Store Consumables Item',
        permissions: {
          exportCentralAssetConsumablesItem: {
            label: 'Export Central Store Consumables Item',
            value: 'inventory_central_asset_consumables_export_item',
          },
        },
      },
      exportDepartmentAssetConsumablesItem: {
        label: 'Export Department Store Consumables Item',
        permissions: {
          exportDepartmentAssetConsumablesItem: {
            label: 'Export Department Store Consumables Item',
            value: 'inventory_department_asset_consumables_export_item',
          },
        },
      },
      raisePurchaseRequestAssetConsumables: {
        label: 'Raise Purchase Request Store Consumables',
        permissions: {
          raisePurchaseRequestAssetConsumables: {
            label: 'Raise Purchase Request Store Consumables',
            value: 'inventory_asset_consumables_raise_purchase_request',
          },
        },
      },
      raiseIndentRequestAssetConsumables: {
        label: 'Raise Indent Request Store Consumables',
        permissions: {
          raiseIndentRequestAssetConsumables: {
            label: 'Raise Indent Request Store Consumables',
            value: 'inventory_asset_consumables_indent_purchase_request',
          },
        },
      },
      viewPurchaseSanction: {
        label: 'View Purchase Sanction',
        permissions: {
          viewPurchaseSanction: {
            label: 'View Purchase Sanction',
            value: 'inventory_purchase_indent_view_purchase_sanction',
          },
        },
      },
      createPurchaseOrder: {
        label: 'Create Purchase Sanction',
        permissions: {
          createPurchaseOrder: {
            label: 'Create Purchase Sanction',
            value: 'inventory_purchase_create_purchase_order',
          },
        },
      },
      createAssetBOQ: {
        label: 'Create Asset BOQ',
        permissions: {
          createAssetBOQ: {
            label: 'Create Asset BOQ',
            value: 'inventory_purchase_create_asset_boq',
          },
        },
      },
      createPharmacyBOQ: {
        label: 'Create Pharmacy BOQ',
        permissions: {
          createPharmacyBOQ: {
            label: 'Create Pharmacy BOQ',
            value: 'inventory_purchase_create_pharmacy_boq',
          },
        },
      },
      createConsumableBOQ: {
        label: 'Create Consumable BOQ',
        permissions: {
          createConsumableBOQ: {
            label: 'Create Consumable BOQ',
            value: 'inventory_purchase_create_consumable_boq',
          },
        },
      },
      viewPurchaseAndSupplyOrders: {
        label: 'View Purchase Sanction and Supply Orders',
        permissions: {
          viewPurchaseAndSupplyOrders: {
            label: 'View Purchase Order and Supply Orders',
            value: 'inventory_purchase_indent_view_purchase_supply_orders',
          },
        },
      },
      createSupplyOrder: {
        label: 'Create Supply Order',
        permissions: {
          createSupplyOrder: {
            label: 'Create Supply Order',
            value: 'inventory_purchase_create_supply_order',
          },
        },
      },
      approvePurchaseOrdersAsPSI: {
        label: 'As PSI (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsPSI: {
            label: 'As PSI (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_psi',
          },
        },
      },
      approvePurchaseOrdersAsDMS: {
        label: 'As DMS (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsDMS: {
            label: 'As DMS (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_dms',
          },
        },
      },
      approvePurchaseOrdersAsFA: {
        label: 'As FA (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsFA: {
            label: 'As FA (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_fa',
          },
        },
      },
      approvePurchaseOrdersAsMSI: {
        label: 'As MS (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsMSI: {
            label: 'As MS (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_msi',
          },
        },
      },
      approvePurchaseOrdersAsDA: {
        label: 'As DA (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsDA: {
            label: 'As DA (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_da',
          },
        },
      },
      approvePurchaseOrdersAsDIRECTOR: {
        label: 'As DIRECTOR (approve/send for review/reject) PS',
        permissions: {
          approvePurchaseOrdersAsDIRECTOR: {
            label: 'As DIRECTOR (approve/send for review/reject) PS',
            value: 'inventory_purchase_approve_purchase_orders_as_director',
          },
        },
      },
      approvePurchaseOrdersAsHOD: {
        label: 'As HOD (approve/send for review) PS',
        permissions: {
          approvePurchaseOrdersAsHOD: {
            label: 'As HOD (approve/send for review) PS',
            value: 'inventory_purchase_review_purchase_orders_as_hod',
          },
        },
      },
      approveSupplyOrdersAsPSI: {
        label: 'As PSI (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsPSI: {
            label: 'As PSI (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_supply_orders_as_psi',
          },
        },
      },
      approveSupplyOrdersAsDMS: {
        label: 'As DMS (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsDMS: {
            label: 'As DMS (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_supply_orders_as_dms',
          },
        },
      },
      approveSupplyOrdersAsFA: {
        label: 'As FA (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsFA: {
            label: 'As FA (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_supply_orders_as_fa',
          },
        },
      },
      approveSupplyOrdersAsMSI: {
        label: 'As MS (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsMSI: {
            label: 'As MS (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_Supply_orders_as_msi',
          },
        },
      },
      approveSupplyOrdersAsDA: {
        label: 'As DA (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsDA: {
            label: 'As DA (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_supply_orders_as_da',
          },
        },
      },
      approveSupplyOrdersAsDIRECTOR: {
        label: 'As DIRECTOR (approve/send for review/reject) SO',
        permissions: {
          approveSupplyOrdersAsDIRECTOR: {
            label: 'As DIRECTOR (approve/send for review/reject) SO',
            value: 'inventory_purchase_approve_supply_orders_as_director',
          },
        },
      },
      approveSupplyOrdersAsHOD: {
        label: 'As HOD (approve/send for review) SO',
        permissions: {
          approveSupplyOrdersAsHOD: {
            label: 'As HOD (approve/send for review) SO',
            value: 'inventory_purchase_review_supply_orders__as_hod',
          },
        },
      },
      viewAssetCentralInventory: {
        label: 'View Asset Central Inventory',
        permissions: {
          viewAssetCentralInventory: {
            label: 'View Asset Central Inventory',
            value: 'inventory_asset_central_inventory_view',
          },
        },
      },
      viewAssetDepartmentInventory: {
        label: 'View Asset Department Inventory',
        permissions: {
          viewAssetDepartmentInventory: {
            label: 'View Asset Department Inventory',
            value: 'inventory_asset_department_inventory_view',
          },
        },
      },
      viewAssetCentralRequests: {
        label: 'View Asset Central Requests',
        permissions: {
          viewAssetCentralRequests: {
            label: 'View Asset Central Requests',
            value: 'inventory_asset_central_requests_view',
          },
        },
      },
      viewAssetDepartmentRequests: {
        label: 'View Asset Department Requests',
        permissions: {
          viewAssetDepartmentRequests: {
            label: 'View Asset Department Requests',
            value: 'inventory_asset_department_requests_view',
          },
        },
      },
      viewAssetCentralRecords: {
        label: 'View Asset Central Records',
        permissions: {
          viewAssetCentralRecords: {
            label: 'View Asset Central Records',
            value: 'inventory_asset_central_records_view',
          },
        },
      },
      viewAssetDepartmentRecords: {
        label: 'View Asset Department Records',
        permissions: {
          viewAssetDepartmentRecords: {
            label: 'View Asset Department Records',
            value: 'inventory_asset_department_records_view',
          },
        },
      },
      viewAssetMSIIndentRequests: {
        label: 'View Asset MS Indent Requests',
        permissions: {
          viewAssetMSIIndentRequests: {
            label: 'View Asset MS Indent Requests',
            value: 'inventory_asset_msi_indent_requests_view',
          },
        },
      },
      raisePurchaseRequestAsset: {
        label: 'Raise Purchase Request Asset',
        permissions: {
          raisePurchaseRequestAsset: {
            label: 'Raise Purchase Request Asset',
            value: 'inventory_asset_raise_purchase_request',
          },
        },
      },
      transferAssetStocks: {
        label: 'Transfer Asset Stocks',
        permissions: {
          transferAssetStocks: {
            label: 'Transfer Asset Stocks',
            value: 'inventory_asset_transfer_stocks',
          },
        },
      },
      loanOutAssetStocks: {
        label: 'Loan Out Asset Stocks',
        permissions: {
          loanOutAssetStocks: {
            label: 'Loan Out Asset Stocks',
            value: 'inventory_asset_loanOut_stocks',
          },
        },
      },
      editInventoryAsset: {
        label: 'Edit Inventory Asset',
        permissions: {
          editInventoryAsset: {
            label: 'Edit Inventory Asset',
            value: 'inventory_asset_edit_item',
          },
        },
      },
      raiseIndentRequestAsset: {
        label: 'Raise Indent Request Asset',
        permissions: {
          raiseIndentRequestAsset: {
            label: 'Raise Indent Request Asset',
            value: 'inventory_asset_indent_purchase_request',
          },
        },
      },
      addAssetItem: {
        label: 'Add Asset Item',
        permissions: {
          addAssetItem: {
            label: 'Add Asset Item',
            value: 'inventory_asset_add_item',
          },
        },
      },
      repairItem: {
        label: 'Repair Asset Item',
        permissions: {
          repairItem: {
            label: 'Repair Asset Item',
            value: 'inventory_asset_repair_item',
          },
        },
      },
      exportToExcel: {
        label: 'Export To Excel',
        permissions: {
          exportToExcel: {
            label: 'Export To Excel',
            value: 'inventory_asset_export_to_excel',
          },
        },
      },
      approveRejectMSIRepairRequest: {
        label: 'Approve / Reject MS Repair Request',
        permissions: {
          approveRejectMSIRepairRequest: {
            label: 'Approve / Reject MS Repair Request',
            value: 'inventory_asset_approve_reject__msi_repair_request',
          },
        },
      },
      approveRejectMSIRequest: {
        label: 'Approve / Reject MS Transfer Request',
        permissions: {
          approveRejectMSIRequest: {
            label: 'Approve / Reject MS Transfer Request',
            value: 'inventory_asset_approve_reject__msi_transfer_request',
          },
        },
      },
      viewInChargeAssetList: {
        label: 'View InCharge Record',
        permissions: {
          viewInChargeAssetList: {
            label: 'View InCharge Records',
            value: 'inventory_asset_view_inchagre_records',
          },
        },
      },
      sendToAuction: {
        label: 'Send To Auction',
        permissions: {
          sendToAuction: {
            label: 'Send To Auction',
            value: 'inventory_asset_send_to_auction',
          },
        },
      },
      addRemarks: {
        label: 'Add Remarks',
        permissions: {
          addRemarks: {
            label: 'Add Remarks',
            value: 'inventory_asset_add_remarks',
          },
        },
      },
      modifyRemarks: {
        label: 'Edit Remarks',
        permissions: {
          modifyRemarks: {
            label: 'Edit Remarks',
            value: 'inventory_asset_edit_remarks',
          },
        },
      },
      modifyInChargeRecords: {
        label: 'Modify InCharge Records',
        permissions: {
          modifyInChargeRecords: {
            label: 'Modify InCharge Records',
            value: 'inventory_asset_modify_incharge_records',
          },
        },
      },
      viewSupervisorRecords: {
        label: 'View Supervisor Record',
        permissions: {
          viewSupervisorRecords: {
            label: 'View Supervisor Record',
            value: 'inventory_asset_view_supervisor_records',
          },
        },
      },
      modifySupervisorRecords: {
        label: 'Modify Supervisor Records',
        permissions: {
          modifySupervisorRecords: {
            label: 'Modify Supervisor Records',
            value: 'inventory_asset_modify_supervisor_records',
          },
        },
      },
    },
  },
  auction: {
    label: 'Auction',
    value: 'menu_auction',
    subLabel: 'SUB-MENU',
    views: {
      viewAuction: {
        label: 'View Auction',
        permissions: {
          viewAuction: {
            label: 'View Auction',
            value: 'view_auction',
          },
        },
      },
      viewInventory: {
        label: 'View Inventory',
        permissions: {
          viewInventory: {
            label: 'View Inventory',
            value: 'view_auction_central_inventory',
          },
        },
      },
      editAuctionRecords: {
        label: 'Edit Auction Item',
        permissions: {
          editAuctionRecords: {
            label: 'Edit Auction Item',
            value: 'auction_inventory_edit_records',
          },
        },
      },
      assignBuyer: {
        label: 'Assign Buyer',
        permissions: {
          assignBuyer: {
            label: 'Assign Buyer',
            value: 'auction_inventory_assign_buyer',
          },
        },
      },
      setPrice: {
        label: 'Set Price',
        permissions: {
          setPrice: {
            label: 'Set Price',
            value: 'auction_inventory_set_price',
          },
        },
      },
      viewAuctionBuyers: {
        label: 'View Buyers',
        permissions: {
          viewAuctionBuyers: {
            label: 'View Buyers',
            value: 'view_auction_buyers_management',
          },
        },
      },
      modifyAuctionBuyers: {
        label: 'Edit Buyers',
        permissions: {
          modifyAuctionBuyers: {
            label: 'Edit Buyers',
            value: 'edit_auction_buyers_management',
          },
        },
      },
      addAuctionBuyers: {
        label: 'Add Buyers',
        permissions: {
          addAuctionBuyers: {
            label: 'Add Buyers',
            value: 'add_auction_buyers_management',
          },
        },
      },
      exportAuctionBuyers: {
        label: 'Export Buyers',
        permissions: {
          exportAuctionBuyers: {
            label: 'Export Buyers',
            value: 'export_auction_buyers_management',
          },
        },
      },
      exportAuctionItems: {
        label: 'Export Auction Items',
        permissions: {
          exportAuctionItems: {
            label: 'Export Auction Items',
            value: 'export_auction_items_management',
          },
        },
      },
    },
  },
  analytics: {
    label: 'Analytics',
    value: 'menu_auction',
    subLabel: 'SUB-MENU',
    views: {

    },
  },
  mobile: {
    label: 'Mobile Permission',
    value: 'menu_mobile_permission',
    subLabel: 'SUB-MENU',
    views: {
      viewOpd: {
        label: 'View OPD',
        permissions: {
          viewOpd: {
            label: 'View OPD',
            value: 'mobile_permission_view_opd',
          },
        },
      },
      viewMdm: {
        label: 'View MDM',
        permissions: {
          viewMDM: {
            label: 'View MDM',
            value: 'mobile_permission_view_mdm',
          },
        },
      },
      viewAnalyts: {
        label: 'View Analyts',
        permissions: {
          viewAnalyts: {
            label: 'View Analyts',
            value: 'mobile_permission_view_analyts',
          },
        },
      },
      appPatient: {
        label: 'Add Patient',
        permissions: {
          appPatient: {
            label: 'Add Patient',
            value: 'mobile_permission_add_patient',
          },
        },
      },
      appConsultation: {
        label: 'Add Consultation',
        permissions: {
          appConsultation: {
            label: 'Add Consultation',
            value: 'mobile_permission_add_consultation',
          },
        },
      },
    },
  },
};

export default permissions;
