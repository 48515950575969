import { Platform } from '@applane/react-core-components';
import { colors } from './colors';

const { shadowBlack } = colors;

export const doctorProfileContainerShadow = {
  shadowOffset: { width: 0, height: 1 },
  shadowRadius: 6,
  shadowColor: '#90a4b7', // rgba(144, 164, 183, 0.22)
  shadowOpacity: 0.22,
  elevation: 3,
};

export const shadow1 = {
  shadowOffset: { width: 0, height: 1 },
  shadowRadius: 6,
  shadowColor: '#90a4b7', // rgba(144, 164, 183, 0.22)
  shadowOpacity: 0.22,
  elevation: 3,
};

export const tripsTabCardShadow = {
  shadowColor: shadowBlack,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,

  elevation: 5,
};

export const dropDownShadow = {
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
};

export const slideContainerShadow = {
  boxShadow: '0 4px 4px 2px rgba(0, 0, 0, 0.06)',
};

export const sessionShadow = {
  boxShadow: '0 4px 11px 4px rgba(0, 0, 0, 0.06)',
};
export const moreActionsShadow = {
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.17)',
};
export const shadow = Platform.OS === 'web' ? dropDownShadow : tripsTabCardShadow;
export const moreActionsDropdownShadow = Platform.OS === 'web' ? moreActionsShadow : tripsTabCardShadow;

export const gradiantButtonShadow = Platform.OS === 'web'
  ? { boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.06)' }
  : tripsTabCardShadow;

export const cancelModalButton = Platform.OS === 'web'
  ? { boxShadow: '10px 2px 6px 0 rgba(0, 0, 0, 0.06)' }
  : tripsTabCardShadow;

export const patientWatingRoomCard = Platform.OS === 'web'
  ? { boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.14)' }
  : tripsTabCardShadow;
