import React from 'react';

import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import ViewConsentForm from '../GeneralSurgery/ConsentForm';
import History from '../GeneralSurgery/History';
import Investigation from '../GeneralSurgery/Investigation';
import Outcome from '../GeneralSurgery/Outcome';
import PatientDetails from '../GeneralSurgery/PatientDetails';
import References from '../GeneralSurgery/References';
import preAnestheticCheckup from './PreAnestheticCheckup';

const ViewOperationTheatreTabs = (props) => (
  <TabNavigator
    {...props}
    tabs={{
      PatientDetails: {
        label: 'Patient Details',
        screen: PatientDetails,
        screenProps: props,
      },
      PreAnestheticCheckUpForm: {
        label: 'Pre-Anesthetic Check -up Form',
        screen: preAnestheticCheckup,
        screenProps: props,
      },
      ConsentForm: {
        label: 'Consent Form',
        screen: ViewConsentForm,
        screenProps: props,
      },
      Investigations: {
        label: 'Investigations',
        screen: Investigation,
        screenProps: props,
      },
      Outcome: {
        label: 'Outcome',
        screen: Outcome,
        screenProps: props,
      },
      History: {
        label: 'History',
        screen: History,
        screenProps: props,
      },
      References: {
        label: 'References',
        screen: References,
        screenProps: props,
      },
    }}
  />
);
export default ViewOperationTheatreTabs;
