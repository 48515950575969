/* eslint-disable camelcase */
import React from 'react';

import { Image } from '../../app-components';
import { Table } from '../../app-components/table/Table';
import WithPermission from '../../Components/WithPermission';
import { editRecord } from '../../images';

const defaultColumns = [
  {
    header: 'Name',
    type: 'text',
    field: 'name',
  },
  {
    header: 'Code',
    type: 'text',
    field: 'code',
  },
  {
    header: 'Description',
    type: 'text',
    field: 'description',
  },
];

const tableData = ({
  editFormId,
  tableColumns = defaultColumns,
  tableColumnsAfterEditAction = [],
  showActionIcon = true,
  access_key,
}) => ({
  lg: {
    columns: [
      ...tableColumns,
      {
        header: 'Actions',
        // align: 'right',
        width: 80,
        visible: showActionIcon,
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <WithPermission access_key={access_key}>
                <Image source={editRecord} />
              </WithPermission>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: editFormId,
              params: { item },
            }),
          },
        ],
      },
      ...tableColumnsAfterEditAction,
    ],
  },
});

export default (props) => {
  const { model, uri, filter = {} } = props;

  return (
    <Table
      reloadEvent={`reload${model}`}
      uri={uri || (() => ({
        query: {
          id: 'masterDataListForTable',
          sort: { _id: -1 },
          addOnFilter: filter,
        },
        aggregate: true,
        model,
      }))}
      {...props}
      {...tableData(props)}
    />
  );
};
