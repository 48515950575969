import React from 'react';
import ViewAttendanceTabs from './ChildTabs/AttendanceRequests';
import ViewLeaveRequestsTabs from './ChildTabs/LeaveRequests';
import ViewSalaryTabs from './ChildTabs/Salary';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';
import {
  FilterGroup, OutlineButton, Text, View,
} from '../../../app-components';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { UserTaxDeclarationListAll } from '../../../Wired/Salary/TaxDeclarations/payroll/list';
import { searchFilter } from '../../../autoSuggestions';
import { filterActive, filterInactive } from '../../../images';
import vars from '../../../theme/vars';
import tabTheme from './tabTheme';
import ViewTaxDeclarationTabs from './ChildTabs/TaxDeclaration';

const AccountsTabNavigation = (props) => (
  <TabNavigator
    theme={{ ...tabTheme }}
    tabs={{
      Salary: {
        label: 'Salary',
        screen: ViewSalaryTabs,
      },
      TaxDeclaration: {
        label: 'Tax Declaration',
        screen: ViewTaxDeclarationTabs,
        // screenProps: {
        //   header: {
        //     headerStyle: {

        //     },
        //     containerStyle: {
        //       height: 50,
        //       backgroundColor: '#eff3fb',
        //     },
        //     actions: [
        //       FilterGroup({
        //         icon: filterInactive,
        //         activeIcon: filterActive,
        //         filters: [
        //           {
        //             type: 'autoSuggest',
        //             field: 'finantial_year_id',
        //             label: 'Financial Year',
        //             model: MODELS.FINANCIAL_YEAR,
        //             id: 'financialyears',
        //             searchField: 'name',
        //             placeholder: 'Year',
        //             sugestionField: 'financial_year',
        //             primaryField: 'financial_year',
        //             valueField: 'financial_year',
        //           },
        //           searchFilter({
        //             field: 'employee_id',
        //             model: MODELS.EMPLOYEE,
        //             id: 'employeeLists',
        //             searchField: 'firstName',
        //             label: 'Employee',
        //             suggestionField: 'fullName',
        //             multiSelect: true,
        //             filterTabs: ['pending', 'approved', 'rejected'],
        //           },
        //           {
        //             modifyResult: ({ data }) => {
        //               const clonedData = data.map((item) => ({
        //                 ...item,
        //                 fullName: `${item?.firstName} ${item?.lastName}`,
        //               }));
        //               return { data: clonedData };
        //             },
        //           }),
        //         ],
        //       }),
        //     ],
        //     // title: (titleProps) => {
        //     //   if (titleProps?.selectionCount) {
        //     //     return (
        //     //       <View style={{
        //     //         alignTtems: 'center',
        //     //         borderWidth: 0,
        //     //         borderStyle: 'solid',
        //     //         display: 'flex',
        //     //         margin: '3px 0px 11px',
        //     //         padding: '0px 0px 0px 30px',
        //     //         position: 'relative',
        //     //         cursor: 'pointer',
        //     //         justifyContent: 'center',
        //     //         width: 'auto',
        //     //         height: '40px',
        //     //       }}
        //     //       >
        //     //         <Text style={{
        //     //           ...vars.headings.h12,
        //     //           color: vars.colors.secondary.color1,
        //     //         }}
        //     //         >
        //     //           {`${titleProps?.selectionCount} Selected`}
        //     //         </Text>
        //     //       </View>
        //     //     );
        //     //   }
        //     //   return (
        //     //     <View style={{
        //     //       alignTtems: 'center',
        //     //       borderWidth: 0,
        //     //       borderStyle: 'solid',
        //     //       display: 'flex',
        //     //       margin: '3px 0px 11px',
        //     //       padding: '0px 0px 0px 30px',
        //     //       position: 'relative',
        //     //       cursor: 'pointer',
        //     //       justifyContent: 'center',
        //     //       width: 'auto',
        //     //       height: '40px',
        //     //     }}
        //     //     >
        //     //       <Text style={{
        //     //         ...vars.headings.h12,
        //     //         color: vars.colors.secondary.color1,
        //     //       }}
        //     //       >
        //     //         12B B Verification
        //     //       </Text>
        //     //       <span style={{
        //     //         position: 'absolute',
        //     //         height: 3,
        //     //         width: 10,
        //     //         backgroundColor: 'rgb(85, 185, 113)',
        //     //         left: 75,
        //     //         bottom: 0,
        //     //       }}
        //     //       />
        //     //     </View>
        //     //   );
        //     // },
        //     selectableActions: [
        //       {
        //         // eslint-disable-next-line no-shadow
        //         render: (props) => (
        //           <WithPermission
        //             access_key={
        //               permissions.departments.views.generateEmployeeSalary.permissions
        //                 .generateEmployeeSalary.value
        //             }
        //           >
        //             <OutlineButton size="medium" {...props} label="Generate Income Tax" />
        //           </WithPermission>
        //         ),
        //         type: 'link',
        //         link: ({ state }) => ({
        //           view: ROUTES.generateIncomeTax.name,
        //           params: { selectedIds: state.selectedIds },
        //         }),
        //       },
        //     ],
        //   },
        // },

      },
      // LeaveRequests: {
      //   label: 'Leave Requests',
      //   screen: ViewLeaveRequestsTabs,
      // },
      // AttendanceRequests: {
      //   label: 'Attendance Requests',
      //   screen: ViewAttendanceTabs,
      // },
    }}
    {...props}
  />
);
export default AccountsTabNavigation;
