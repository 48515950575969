import React from 'react';

import vars from '../../../../../theme/vars';

import { Form } from '../../../../../app-components/form/Form';

const ViewRepairRequests = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
    // reloadEvent: `reload${MODELS.PHARMACY_BATCH_REQUESTS}`,
    // uri: EditPharmacyBatchRequestStatus,
      defaultValues={() => ({
        _createdOn: item?._createdOn,
        name: item?.purchaseRequestAsset?.item?.name,
        category: item?.purchaseRequestAsset?.item?.category?.name,
        subCategory: item?.purchaseRequestAsset?.item?.subCategory?.name,
        approximateCost: item?.purchaseRequestAsset?.approximateCost,
        reasonForRepair: item?.purchaseRequestAsset?.reasonForRepair,
        requestStatus: item?.purchaseRequestAsset?.requestStatus,
        description: item?.purchaseRequestAsset?.item?.description,
      })}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    field: 'name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'category',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.subCategory'.getLabel(),
                    field: 'subCategory',
                    editable: false,
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.approximateCost'.getLabel(),
                    field: 'approximateCost',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.reasonForRepair'.getLabel(),
                    field: 'reasonForRepair',
                    editable: false,
                  },
                  {
                    label: 'asset.labels.status'.getLabel(),
                    type: 'text',
                    field: 'requestStatus',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.description'.getLabel(),
                    field: 'description',
                    editable: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewRepairRequests;
