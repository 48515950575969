import { post } from '../../../AppServices';
import { Form, save, Toast } from '../../../app-components';
import { searchInput, autoSuggestInput } from '../../../autoSuggestions';

export const linkTypeOpenAction = ({
  text = '',
  view,
  type,
  params = '',
  modal,
  modalProps = {},
  icon,
  iconStyle = {},
}) => ({
  type,
  text,
  icon,
  iconStyle,
  link: ({ item }) => ({
    view,
    params: {
      [params]: item,
      item,
    },
    modal,
    modalProps,
  }),
});
export const InvokeTypeConfirmOpenAction = ({
  text,
  title,
  message,
  postMessage,
  reloadEvent,
  model,
  updates,
}) => ({
  type: 'invoke',
  text,
  confirm: {
    title,
    message,
    confirmText: 'Yes',
    cancelText: 'No',
  },
  postMessage,
  reloadEvent,
  invoke: ({ item }) => post({
    data: item,
    updates,
    model,
  }),
});
export const LinkTypeActionsForPost = ({
  icon,
  iconStyle = {},
  text,
  updates = {},
  model = '',
  postMessage = '',
}) => {
  if (!Object.keys(updates).length && !model) {
    return {
      text,
      icon,
      iconStyle,
    };
  }

  return {
    text,
    icon,
    iconStyle,
    action: ({ item }) => post({
      data: item,
      updates,
      model,
    }).then(() => {
      Toast.show({ message: postMessage });
    }),
  };
};

export const DrillAction = ({
  item, type, view, params = '', routeOptions = {},
}) => ({
  type,
  link: {
    view,
    params: params === '' ? { item } : { [params]: item },
    routeOptions,
  },
});

export const EwdApprovalForm = Form({
  onSubmit: ({ updates, navigation }) => {
    const { Resource, payrollupdates } = navigation && navigation.getParams() || {};
    let updatedAttendance = updates;
    if (updates && updates.projectFilter) {
      const { projectFilter, project, ...rest } = updates;
      rest.project_id = project;
      if (payrollupdates) {
        updatedAttendance = { ...rest, ...payrollupdates };
      } else {
        updatedAttendance = rest;
      }
    }
    return post({
      data: Resource,
      updates: {
        ...updatedAttendance,
      },
      model: 'Attendance',
    }).then(() => {
      if (payrollupdates) {
        navigation.pop(2);
      } else {
        navigation.pop();
      }
    });
  },
  compact: 1,
  reloadEvent: 'TeamAttendanceList',
  footer: {
    actions: [save({ label: 'Save' })],
  },
  submitMessage: 'Request Approved',
  closeView: 1,
  computations: {
    self: {
      'set Project of employee which apear first in list': {
        compute: (data, { fetch, navigation: { state: { params: { Resource = {} } } } }) => {
          const queryProps = {
            query: {
              id: 'projectResources',
              fields: {
                project: { _id: 1 },
              },
              addOnFilter: {
                resource: {
                  employee: { _id: Resource && Resource.employee && Resource.employee._id },
                },
              },
            },
            model: 'ProjectResource',
          };
          return fetch({
            uri: queryProps,
          }).then(({ data: self }) => {
            if (self && self.length) {
              let resultArray = [];
              resultArray = self.reduce((total, one) => {
                if (one && one.project && one.project._id) {
                  total.push(one.project._id);
                }
                return total;
              }, []);
              return { set: { projectFilter: resultArray } };
            }
            return {};
          });
        },
        onChange: ['purpose'],
      },
      'set primary default': {
        compute: () => ({
          set: { status: 'approve', is_approved: true },
        }),
        onChange: ['_id', 'purpose'],
      },
    },
  },
  mandatory: { purpose: 1 },
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 1,
          columns: [
            autoSuggestInput({
              field: 'purpose',
              header: 'Purpose',
              placeholder: 'Purpose',
              valueField: 'label',
              suggestionField: 'label',
              keyField: 'value',
              options: [
                { value: 'drive', label: 'Drive' },
                { value: 'onProject', label: 'OnProject' },
                { value: 'other', label: 'Other' },
              ],
              variant: 'filled',
            }),
            {
              field: 'reason',
              visible: ({ data }) => data && data.purpose == 'other',
              type: 'text',
              label: 'Reason',
              placeholder: 'Type here',
              mandatory: true,
            },
            searchInput({
              field: 'project',
              label: 'Project',
              mandatory: true,
              visible: ({ data }) => data && data.purpose == 'onProject',
              query: 'projectSuggestions',
              model: 'Project',
              placeholder: 'Project',
              searchField: 'project',
              suggestionField: 'project',
            }, {
              addOnFilter: ({ data }) => ({
                _id: { $in: data && data.projectFilter },
              }),
            }),
          ],
        },
      ],
    },
  ],
});
