import { MODELS } from '../Lib/constants';

export const EditSupplier = ({ navigation }) => ({
  query: {
    model: MODELS.PHARMACY_SUPPLIER,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.PHARMACY_SUPPLIER,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_SUPPLIER,
});

export const SupplierList = ({ filter = {} }) => ({
  query: {
    model: MODELS.PHARMACY_SUPPLIER,
    id: 'masterDataListForTable',
    addOnFilter: filter,
  },
  model: MODELS.PHARMACY_SUPPLIER,
});
