import moment from 'moment';
import {
  searchInput,
  autoSuggestInput,
} from '../../../../autoSuggestions';
import {
  NestedAction,
  NestedTable,
} from '../../../../app-components/input-components/nestedTable/NestedTable';
import { post } from '../../../../AppServices';
import { addBlueSmallIcon } from '../../../../images';
import { } from '../../../../app-components/action/DownloadAction';
import { levelPayOptions } from '../../../../Lib/constants';

export const LinkTypeOpenAction = (
  {
    type = 'link', text, view, params = 'item',
  },
  extraParams,
) => ({
  text,
  type,
  link: ({ item }) => ({
    view,
    params: {
      [params]: item,
    },
    extraParams,
  }),
});

export const InvokeTypeOpenAction = ({
  text,
  title,
  message,
  postMessage,
  reloadEvent,
  model = 'Resource',
  updates,
}) => ({
  type: 'invoke',
  text,
  confirm: {
    title,
    message,
    confirmText: 'Yes',
    cancelText: 'No',
  },
  postMessage,
  reloadEvent,
  invoke: ({ item }) => post({
    data: item,
    updates: {
      ...updates,
    },
    model,
  }),
});

export const DrillAction = ({ view, params, ...rest }) => ({
  type: 'link',
  link: {
    view,
    params,
    ...rest,
  },
});
export const Layouts = {
  GroupFields: {
    type: 'card',
    header: 'Name',
    width: 200,
    title: {
      primaryField: 'name',
    },
    contentSeparator: true,
    left: [
      {
        avatar: true,
        avatarIcon: 'reviewer.photo',
        field: 'name',
      },
    ],
    contentItems: [
      [
        {
          field: 'employee_code',
        },
        {
          field: 'experience',
          suffix: ' yrs',
        },
      ],
    ],
  },
  Name: {
    type: 'text',
    field: 'name',
    header: 'Name',
  },
  Designation: {
    type: 'text',
    field: 'designation',
    header: 'DESIGNATION',
    value: ({ item }) => (item && item.designation && item.designation.name) || ' ',
  },
  Joining: {
    type: 'text',
    field: 'joining_date',
    header: 'JOINING',
    value: ({ item }) => (item
        && item.joining_date
        && moment(item.joining_date).format('DD MMM YY'))
      || ' ',
  },
  InactiveFrom: {
    type: 'text',
    header: 'INACTIVE FROM',
    value: ({ item }) => (item
        && item.relieving_date
        && moment(item.relieving_date).format('DD MMM YY'))
      || ' ',
  },
  AlumniFrom: {
    type: 'text',
    header: 'ALUMNI FROM',
    value: ({ item }) => (item
        && item.relieving_date
        && moment(item.relieving_date).format('DD MMM YY'))
      || ' ',
  },
  In_seperation_from: {
    type: 'text',
    header: 'IN SEPERATION FROM',
    value: ({ item }) => (item
        && item.resign_date
        && moment(item.resign_date).format('DD MMM YY'))
      || ' ',
  },
  Reporting_to: {
    type: 'text',
    field: 'reportingTo',
    header: 'REPORTING TO',
    value: ({ item }) => (item
        && item.reportingTo
        && item.reportingTo[0]
        && item.reportingTo[0].name)
      || ' ',
  },
  Unit: {
    type: 'text',
    field: 'business_unit',
    header: 'UNIT',
    value: ({ item }) => (item && item.accountingUnit && item.accountingUnit.name) || ' ',
  },
  employee_code: {
    type: 'text',
    field: 'employee_code',
    header: 'EMPLOYEE CODE',
    value: ({ item }) => (item && item.employee_code) || ' ',
  },
  experience: {
    type: 'text',
    field: 'experience',
    header: 'EXPERIENCE',
    value: ({ item }) => (item && item.experience && `${item.experience} Yrs`) || 'N/A',
  },
  People_manager: {
    type: 'text',
    field: 'functional_manager',
    header: 'PEOPLE MANAGER',
    value: ({ item }) => item && item.functional_manager && item.functional_manager.name,
  },
  Package: {
    type: 'openActions',
    header: 'ACTION',
    align: 'right',
    width: 100,
    openActions: [
      {
        type: 'link',
        text: ({ item }) => (item.packages ? 'Package' : '+ Package'),
        link: ({ item }) => ({
          view: 'employee-package',
          params: { item },
        }),
      },
    ],
  },
  mark_alumni: {
    type: 'openActions',
    header: 'ACTION',
    align: 'right',
    width: 120,
    openActions: [
      InvokeTypeOpenAction({
        text: 'MARK ALUMNI',
        type: 'invoke',
        postMessage: 'Mark Alumni successfully',
        title: 'Update',
        message: 'Do you want to set Alumni?',
        confirmText: 'Yes',
        cancelText: 'No',
        updates: { is_alumni: true },
      }),
    ],
  },
  Remove: {
    type: 'openActions',
    header: 'ACTION',
    align: 'right',
    width: 120,
    openActions: [
      InvokeTypeOpenAction({
        text: 'Remove',
        type: 'invoke',
        postMessage: 'Remove Alumni successfully',
        reloadEvent: 'EmployeeAlumni',
        title: 'Update',
        message: 'Do you want to remove Alumni?',
        confirmText: 'Yes',
        cancelText: 'No',
        updates: { is_alumni: false },
      }),
    ],
  },
};

export const packageLayouts = {
  FromDate: {
    type: 'date',
    field: 'from_date',
    header: 'From Date',
    format: 'DD MMM YY',
  },
  ToDate: {
    header: 'To Date',
    field: 'to_date',
    type: 'date',
    format: 'DD MMM YY',
  },
  CTCAmt: {
    header: 'CTC(Per Month)',
    field: 'ctc_amount',
    value: ({ item }) => item && item.ctc_amount && item.ctc_amount.toFixed(2),
  },
  level: {
    type: 'text',
    header: 'Employee Level',
    field: 'level',
  },
  packageReviewDate: {
    type: 'date',
    header: 'Package Review date',
    field: 'package_review_date',
  },
};

export const CompensationFormLayouts = {
  Employee: searchInput({
    // icon:greenFlagIcon,
    // container: 'leftIcon',
    suggestionField: 'name',
    query: 'resourceSuggestions',
    searchField: 'name',
    field: 'employee_id',
    model: 'Resource',
    placeholder: 'Employee',
    secondaryField: 'official_email_id',
    label: 'Employee',
  }),
  LevelPayEmployee: autoSuggestInput({
    field: 'level',
    suggestionField: 'label',
    model: 'Resource',
    placeholder: 'Level Of Employee',
    label: 'Level Of Employee',
    options: levelPayOptions,
    mandatory: true,
  }),
  FromDate: {
    label: 'From Date',
    type: 'date',
    field: 'from_date',
    placeholder: 'From Date',
  },
  ToDate: {
    label: 'To Date',
    type: 'date',
    field: 'to_date',
    placeholder: 'To Date',
  },
  CTCAmt: {
    field: 'ctc_amount',
    label: 'CTC(Per Month)',
    type: 'number',
    placeholder: 'CTC(Per Month)',
  },
  PackageReviewDate: {
    field: 'package_review_date',
    label: 'Package Review Date',
    type: 'date',
    placeholder: 'Package Review Date',
  },
  FixedSalary: {
    field: 'fixed_salary',
    label: 'Fixed',
    type: 'number',
    placeholder: 'Fixed',
  },
  Incentive: {
    field: 'incentive_salary',
    label: 'Incentive',
    type: 'number',
    placeholder: 'Incentive',
  },
  MarkSalary: {
    field: 'mark_salary',
    type: 'checkBox',
    label: 'Unapprove Package',
  },
  EarningBaseComponent: {
    field: 'attendance_base_components',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow, editable }) => ({
        leftAction: editable
          ? {
            icon: addBlueSmallIcon,
            text: 'Add',
            onPress: addRow,
          }
          : null,
      }),
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'salaryComponentSuggestions',
            searchField: 'name',
            field: 'salary_component_id',
            model: 'SalaryComponent',
            placeholder: 'Salary Component',
            header: 'Salary Component',
            width: 170,
          }),
          {
            type: 'text',
            placeholder: 'Flat Amount',
            field: '',
            header: '',
          },
          {
            type: 'number',
            placeholder: 'Percentage',
            field: '',
            header: 'Percentage',
          },
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'amount',
            header: 'Amount',
          },
          {
            type: 'date',
            placeholder: 'From Date',
            field: 'from_date',
            header: 'From Date',
          },
          {
            type: 'date',
            placeholder: 'To Date',
            field: 'to_date',
            header: 'To Date',
          },
          NestedAction(),
        ],
      },
    },
  },
  PerformanceBaseComponent: {
    field: 'performance_base_components',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow, editable }) => ({
        leftAction: editable
          ? {
            icon: addBlueSmallIcon,
            text: 'Add',
            onPress: addRow,
          }
          : null,
      }),
      listProps: {
        md: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
            },
            NestedAction(),
          ],
        },
        lg: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
            },
            NestedAction(),
          ],
        },
      },
    },
  },
  DeductionComponent: {
    field: 'deductions_components',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow, editable }) => ({
        leftAction: editable
          ? {
            icon: addBlueSmallIcon,
            text: 'Add',
            onPress: addRow,
          }
          : null,
      }),
      listProps: {
        sm: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
            },
            {
              field: 'from_date',
              type: 'date',
              placeholder: 'From Date',
              header: 'From Date',
            },
            {
              field: 'to_date',
              type: 'date',
              placeholder: 'To Date',
              header: 'To Date',
            },
            NestedAction(),
          ],
        },
        lg: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
            },
            {
              field: 'from_date',
              type: 'date',
              placeholder: 'From Date',
              header: 'From Date',
            },
            {
              field: 'to_date',
              type: 'date',
              placeholder: 'To Date',
              header: 'To Date',
            },
            {
              type: 'text',
              header: 'Reason',
              placeholder: 'Reason',
              field: 'reason',
            },
            NestedAction(),
          ],
        },
      },
    },
  },
  BonusComponent: {
    field: 'fix_base_components',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow, editable }) => ({
        leftAction: editable
          ? {
            icon: addBlueSmallIcon,
            text: 'Add',
            onPress: addRow,
          }
          : null,
      }),
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'salaryComponentSuggestions',
            searchField: 'name',
            field: 'salary_component_id',
            model: 'SalaryComponent',
            placeholder: 'Salary Component',
            header: 'Salary Component',
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'amount',
            header: 'Amount',
          },
          {
            field: 'from_date',
            type: 'date',
            placeholder: 'From Date',
            header: 'From Date',
          },
          {
            field: 'to_date',
            type: 'date',
            placeholder: 'To Date',
            header: 'To Date',
          },
          NestedAction(),
        ],
      },
    },
  },
  salaryTemplate: searchInput(
    {
      suggestionField: 'name',
      query: 'salaryTemplateDetails',
      searchField: 'name',
      field: 'parent',
      model: 'EmployeeSalaryComponent',
      placeholder: 'Salary Template',
      label: 'Salary Template',
    },
    {
      addOnFilter: ({ data: { level } }) => ({
        level,
      }),
    },
  ),
};

export const FormLayouts = {
  employee_code: {
    label: 'EMPLOYEE CODE',
    type: 'text',
    field: 'employee_code',
    defaultValue: '-',
  },
  employee_name: {
    label: 'EMPLOYEE NAME  ',
    type: 'text',
    field: 'name',
    defaultValue: '-',
  },
  email: {
    label: 'EMAIL',
    type: 'text',
    field: 'official_email_id',
    defaultValue: '-',
  },
  mobile_number: {
    label: 'MOBILE NO ',
    type: 'number',
    defaultValue: '-',
    field: 'mobile_number',
  },
  reporting_to: searchInput({
    // icon: greenFlagIcon,
    label: 'REPORTING TO',
    field: 'reportingTo',
    query: 'resourceSuggestions',
    model: 'Resource',
    defaultValue: '-',
    placeholder: 'Reporting To',
    searchField: 'reportingTo',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
    // mandatory: true,
  }),
  people_manager: searchInput({
    label: 'PEOPLE MANAGER',
    field: 'functional_manager',
    query: 'resourceSuggestions',
    model: 'Resource',
    defaultValue: '-',
    placeholder: 'People Manager',
    searchField: 'functional_manager',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
  }),
  reward: {
    label: 'CATEGORY OF REWARD',
    defaultValue: '-',
    type: 'text',
    field: 'category_of_reward',
  },
  joining_date: {
    label: 'JOINING DATE',
    defaultValue: '-',
    type: 'date',
    field: 'joining_date',
  },
  employee_level: searchInput({
    label: 'LEVEL',
    field: 'employee_level',
    query: 'EmployeeLevelSuggestions',
    model: 'EmployeeLevel',
    defaultValue: '-',
    placeholder: 'Level',
    searchField: 'name',
    suggestionField: 'name',
  }),
  designation: searchInput({
    label: 'DESIGNATION',
    field: 'designation',
    query: 'designations',
    model: 'Designations',
    defaultValue: '-',
    placeholder: 'ENTER DESIGNATION',
    searchField: 'name',
    suggestionField: 'name',
  }),
  employee_type: searchInput({
    label: 'EMPLOYEE TYPE',
    field: 'employee_type',
    query: 'EmployeeTypeSuggestions',
    model: 'EmployeeTypes',
    defaultValue: '-',
    placeholder: 'Employee Type',
    searchField: 'name',
    suggestionField: 'name',
  }),
  bond_duration: {
    label: 'BOND DURATION',
    defaultValue: '-',
    type: 'text',
    field: 'bond_duration',
  },
  notice_period_days: {
    label: 'NOTICE PERIOD DAYS',
    defaultValue: '-',
    type: 'number',
    field: 'notice_period_days',
  },
  biometric_code: {
    label: 'BIOMETRIC CODE',
    defaultValue: '-',
    type: 'text',
    field: 'biometric_code',
  },
  attendance_type: {
    label: 'ATTENDANCE TYPE',
    defaultValue: '-',
    type: 'text',
    field: 'attendancetype',
  },
  // attendance_type: searchInput({
  //   label: 'ATTENDANCE TYPE',
  //   field: 'teamattendance',
  //   query: 'attendanceTypeSuggestion',
  //   model: 'Attendance',
  //   defaultValue: '-',
  //   placeholder: 'Type',
  //   searchField: 'attendancetype',
  //   suggestionField: 'attendancetype',
  // }),
  pan_number: {
    label: 'PAN NO.',
    defaultValue: '-',
    type: 'text',
    field: 'pan_number',
  },
  adhar_number: {
    label: 'AADHAR NO.',
    defaultValue: '-',
    type: 'text',
    field: 'adhar_number',
  },
  uan_number: {
    label: 'UAN NO.',
    defaultValue: '-',
    type: 'text',
    field: 'uan_number',
  },
  esi_number: {
    label: 'ESI NO.',
    defaultValue: '-',
    type: 'text',
    field: 'esi_number',
  },
  pf_number: {
    label: 'PF NO.',
    defaultValue: '-',
    type: 'text',
    field: 'pf_number',
  },
  accountingSubsidiary: searchInput({
    label: 'SUBSIDIARY',
    field: 'accountingSubsidiary',
    query: 'subsidiarySuggestions',
    model: 'Subsidiary',
    defaultValue: '-',
    placeholder: 'SUBSIDIARY',
    searchField: 'name',
    suggestionField: 'name',
  }),
  accountingUnit: searchInput({
    label: 'UNIT',
    field: 'accountingUnit',
    query: 'allUnitSuggestions',
    model: 'Unit',
    defaultValue: '-',
    placeholder: 'UNIT',
    searchField: 'name',
    suggestionField: 'name',
  }),
  accountingDepartment: searchInput({
    label: 'DEPARTMENT',
    field: 'accountingDepartment',
    query: 'departmentSuggestions',
    model: 'Department',
    defaultValue: '-',
    placeholder: 'Department',
    searchField: 'name',
    suggestionField: 'name',
  }),
  sitting_location: searchInput({
    label: 'SITTING LOCATION',
    field: 'sitting_location',
    query: 'locationSuggestions',
    model: 'Location',
    defaultValue: '-',
    placeholder: 'LOCATION',
    searchField: 'name',
    suggestionField: 'name',
  }),
  accounting_location: searchInput({
    label: 'ACCOUNTING LOCATION',
    field: 'accounting_location',
    query: 'locationSuggestions',
    model: 'Location',
    defaultValue: '-',
    placeholder: 'LOCATION',
    searchField: 'name',
    suggestionField: 'name',
  }),
  salary_payment_mode: {
    label: 'SALARY PAYMENT MODE',
    defaultValue: '-',
    type: 'text',
    field: 'salary_payment_mode',
  },
  BankAccountComponent: {
    field: 'bank_accounts',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow, editable }) => ({
        leftAction: editable
          ? {
            icon: addBlueSmallIcon,
            text: 'Add',
            onPress: addRow,
          }
          : null,
      }),
      listProps: {
        columns: [
          {
            type: 'text',
            placeholder: 'BANK NAME',
            field: 'name_id.name',
            header: 'BANK NAME',
          },

          {
            type: 'number',
            placeholder: 'ACCOUNT NUMBER',
            field: 'account_number',
            header: 'ACCOUNT NO.',
          },
          {
            type: 'text',
            placeholder: 'ACCOUNT NAME',
            field: 'name_in_bank',
            header: 'ACCOUNT NAME',
          },
          {
            type: 'text',
            placeholder: 'BRANCH',
            field: 'branch',
            header: 'BRANCH',
          },
          {
            type: 'text',
            placeholder: 'IFSC CODE',
            field: 'ifsc_code',
            header: 'IFSC CODE',
          },
          NestedAction(),
        ],
      },
    },
  },
};

export const Filter = {
  ReportingTo: searchInput({
    field: 'reportingTo',
    model: 'Resource',
    id: 'resourceSuggestions',
    searchField: 'name',
    label: 'Reporting To',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
    showAvatar: true,
    multiSelect: true,
  }),
  PeopleManager: searchInput({
    field: 'functional_manager',
    model: 'Resource',
    id: 'resourceSuggestions',
    searchField: 'name',
    label: 'People Manager',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
    showAvatar: true,
    multiSelect: true,
  }),
  Designation: searchInput({
    field: 'designation',
    model: 'Designations',
    id: 'designations',
    searchField: 'name',
    label: 'Designation',
    suggestionField: 'name',
    multiSelect: true,
  }),
  Subsidiary: searchInput({
    field: 'accountingSubsidiary',
    model: 'Subsidiary',
    id: 'subsidiaries',
    searchField: 'name',
    label: 'Subsidiary',
    suggestionField: 'name',
    multiSelect: true,
  }),
  Unit: searchInput({
    field: 'accountingUnit',
    model: 'Unit',
    id: 'units',
    searchField: 'name',
    label: 'Unit',
    suggestionField: 'name',
    multiSelect: true,
  }),
  Department: searchInput({
    field: 'accountingDepartment',
    model: 'Department',
    id: 'departmentSuggestions',
    searchField: 'name',
    label: 'Department',
    suggestionField: 'name',
    multiSelect: true,
  }),
  SittingLocation: searchInput({
    field: 'sitting_location',
    model: 'Location',
    id: 'locationSuggestions',
    searchField: 'name',
    label: 'Sitting Location',
    suggestionField: 'name',
    multiSelect: true,
  }),
  MonthlyAttendanceSittingLocation: searchInput({
    field: 'sitting_loc',
    model: 'Location',
    id: 'locationSuggestions',
    searchField: 'name',
    label: 'Sitting Location',
    suggestionField: 'name',
    multiSelect: true,
  }),
  AccountingLocation: searchInput({
    field: 'accounting_location',
    model: 'Location',
    id: 'locationSuggestions',
    searchField: 'name',
    label: 'Accounting Location',
    suggestionField: 'name',
    multiSelect: true,
  }),
  HoldSalary: autoSuggestInput({
    field: 'hold_salary',
    label: 'On Hold salary',
    options: ['True', 'False'],
    multiSelect: false,
  }),
  FinancialYear: searchInput({
    field: 'finantial_year_id',
    model: 'FinancialYearDBs',
    id: 'financialyears',
    searchField: 'financial_year',
    label: 'Financial Year',
    suggestionField: 'financial_year',
    multiSelect: true,
  }),
  Employee: searchInput({
    field: 'employee_id',
    model: 'Resource',
    id: 'resourceSuggestions',
    searchField: 'name',
    label: 'Employee',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
    multiSelect: true,
  }),
  AttendanceEmployee: searchInput({
    field: 'employee',
    model: 'Resource',
    id: 'resourceSuggestions',
    searchField: 'name',
    label: 'Employee',
    suggestionField: 'name',
    secondaryField: 'official_email_id',
    multiSelect: true,
  }),
  MonthFilter: searchInput({
    field: 'month_id',
    model: 'Month',
    id: 'MonthTypeSuggestions',
    searchField: 'name',
    label: 'Month',
    suggestionField: 'name',
  }),

  YearFilter: searchInput({
    field: 'year_id',
    model: 'Year',
    id: 'YearTypeSuggestions',
    searchField: 'name',
    label: 'Year',
    suggestionField: 'name',
  }),

  FirstHalfAttendanceFilter: searchInput({
    field: 'first_attendance_type_id',
    model: 'AttendanceTypes',
    id: 'AttendanceTypeSuggestions',
    searchField: 'name',
    label: 'First Half',
    suggestionField: 'name',
    multiSelect: true,
  }),
  SecondHalfAttendanceFilter: searchInput({
    field: 'second_attendance_type_id',
    model: 'AttendanceTypes',
    id: 'AttendanceTypeSuggestions',
    searchField: 'name',
    label: 'Second Half',
    suggestionField: 'name',
    multiSelect: true,
  }),
};

export const AttendanceStatusFilter = searchInput({
  field: 'is_approved',
  label: 'Status',
  options: [
    { value: true, label: 'Approved' },
    { value: false, label: 'Pending' },
  ],
  multiSelect: false,
});

export const ActiveRightActions = [
  InvokeTypeOpenAction({
    text: 'Mark Inactive',
    type: 'invoke',
    postMessage: 'Mark Inactive successfully',
    title: 'Update',
    message: 'Do you want to set Inactive?',
    confirmText: 'Yes',
    cancelText: 'No',
    updates: { employee_status: 'Inactive' },
  }),
];

export const InSeperationRightActions = [
  InvokeTypeOpenAction({
    text: 'Mark Inactive',
    type: 'invoke',
    postMessage: 'Mark Inactive successfully',
    title: 'Update',
    message: 'Do you want to set Inactive?',
    confirmText: 'Yes',
    cancelText: 'No',
    updates: { employee_status: 'Inactive' },
  }),
];

export const EmployeeGroupActions = [
  Filter.ReportingTo,
  Filter.PeopleManager,
  Filter.Designation,
  Filter.Subsidiary,
  Filter.Unit,
  Filter.Department,
  Filter.SittingLocation,
  Filter.AccountingLocation,
  Filter.HoldSalary,
];