import React, { useCallback } from 'react';

import {
  View, Text, StandardButton, Toast,
} from '../../../../app-components';
import { downloadData } from '../../../../AppServices';

const Summary = (props) => {
  const { filters: { filters: { customFilter: { value } = {} } = {} } = {} } = props;
  const downloadSummarySheetOPD = useCallback(async () => {
    await downloadData({
      service: {
        id: 'OPDSummarySheet',
        paramValue: {
          value,
        },
      },
    });
    Toast.show({
      message: 'Success',
      description: 'OPD summary report is generated successfully',
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
  }, [value]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        width: '402px',
        height: '158px',
        left: '566px',
        top: '100px',
        background: '#EFF3FB',
        borderRadius: '12px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        marginTop: '20px',
        justifyContent: 'space-between',
      }}
    >
      <Text>
        <h5>Download Summary Report!</h5>
      </Text>
      <StandardButton
        label="Export"
        width="150px"
        onClick={downloadSummarySheetOPD}
      />
    </View>
  );
};
export default Summary;
