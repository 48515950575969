import { ROUTES } from '../../../../../../Lib/constants';

import DischargeCard from './Summary/DischargedCard';
import DeathCard from './Summary/DeathCard';
import LogOperativeNoteForm from './OperativeNotes/LogOperativeNote';
import ViewOperativeNote from './OperativeNotes/ViewDetail';
import logEYEInvestigations from './Investigations/AddInvestigation';
import AddOperativeCheckList from './PreOperativeCheckList/AddOperativeCheckList';
import ViewPreOperativeCheckList from './PreOperativeCheckList/ViewOperativeCheckList';

const eyeScreens = {
  [ROUTES.dischargeCardSummary.name]: {
    screen: DischargeCard,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.addOperativeCheckList.name]: {
    screen: AddOperativeCheckList,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.viewOperativeCheckList.name]: {
    screen: ViewPreOperativeCheckList,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.deathCardSummary.name]: {
    screen: DeathCard,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.logOperativeNotes.name]: {
    screen: LogOperativeNoteForm,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 1270,
      height: 'auto',
    },
  },
  [ROUTES.viewOperativeNotes.name]: {
    screen: ViewOperativeNote,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 1270,
      height: 'auto',
    },
  },
  [ROUTES.logEYEInvestigation.name]: {
    screen: logEYEInvestigations,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 970,
      height: 'auto',
    },
  },
};

export default eyeScreens;
