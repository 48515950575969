import React from 'react';

import ActivityLogsTabList from '../../../../../../../Wired/ActivityLogList';
import { countUri } from '../../../../../../../Queries/countUri';
import { MODELS } from '../../../../../../../Lib/constants';
import { TabNavigator } from '../../../../../../../app-components/tab/TabNavigator';

const ViewCompanyTabs = (props) => {
  const { navigation: { state: { params: { item: data } = {} } = {} } = {} } = props;

  if (!data?._id) {
    return null;
  }

  return (
    <TabNavigator
      tabs={{
        ActivityLogs: {
          label: 'Activity Logs',
          reloadEvent: `reload${MODELS.APP_HISTORY_CHANGES}`,
          screen: ActivityLogsTabList,
          screenProps: {
            data,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: data?._id },
            hoverable: false,

          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: data?._id },

          }),
        },

      }}
      {...props}
    />
  );
};
export default ViewCompanyTabs;
