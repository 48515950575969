import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { Form } from '../../../../app-components/form/Form';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';
import { EditCategory } from '../../../../Queries/category';
import { View } from '../../../../app-components';
import ViewPurchaseCategoryTabs from './ViewCategory/components/Tabs';

const EditCategoryForm = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        {...props}
        uri={EditCategory}
        type="standard"
        closeView={1}
        onSubmit={submit({
          model: MODELS.PHARMACY_CATEGORY,
        })}
        reloadEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
        uniqueValidationMessage={'purchase.message.categoryAlreadyExists'.getLabel()}
        submitMessage={'purchase.message.categoryUpdatedSuccessfully'.getLabel()}
        mandatory={{
          name: 1,
          description: 1,
        }}
        validations={{
          name: ({ data }) => validateInputLength(data?.name, 2, 26),
          description: ({ data }) => validateInputLength(data?.description, 0, 200),
        }}
        lg={{
          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'text',
                      field: 'name',
                      label: 'purchase.labels.category'.getLabel(),
                      mandatory: true,
                      allowWhiteSpaces: true,
                      modifyValueOnChange: ({ prevValue, value }) => {
                        if (validateAlphabetsWithSpaceOnly(value)) {
                          return prevValue;
                        }
                        return value.replace(/ {2,}/g, ' ');
                      },
                    },
                    {
                      type: 'textArea',
                      field: 'description',
                      label: 'purchase.labels.description'.getLabel(),
                      minHeight: 100,
                    },
                    {
                      type: 'toggleSwitch',
                      field: 'isContractual',
                      label: 'purchase.labels.contractual'.getLabel(),
                      container: 'topLabel',
                      offValueLabel: 'Non-Contractual',
                      onValueLabel: 'Contractual',
                    },
                    {
                      type: 'toggleSwitch',
                      field: 'isActive',
                      label: 'purchase.labels.status'.getLabel(),
                      container: 'topLabel',
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewPurchaseCategoryTabs {...props} />
    </View>
  </View>
);

export default EditCategoryForm;
