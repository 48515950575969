import { upperFirst } from 'lodash';

import { autoSuggestInput } from '../../../../autoSuggestions';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS, unit } from '../../../../Lib/constants';
import { TestComponent } from '../../../../Queries/testComponent';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'testComponent.label.testComponentName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      // eslint-disable-next-line no-param-reassign
      value = upperFirst(value);
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
  },
  autoSuggestInput({
    field: 'unit',
    label: 'testComponent.label.unit'.getLabel(),
    placeholder: 'testComponent.placeholder.unit'.getLabel(),
    keyField: 'value',
    valueField: 'value',
    searching: true,
    suggestionField: 'value',
    idField: '_id',
    options: unit,
  }),
  {
    header: 'testComponent.header.status'.getLabel(),
    type: 'toggleSwitch',
    field: 'isActive',
  },
  {
    type: 'textArea',
    label: 'testComponent.label.description'.getLabel(),
    field: 'description',
  },
];

const validations = { description: ({ data }) => validateInputLength(data?.description, 0, 200) };

export default MasterForm({
  uri: TestComponent,
  model: MODELS.TEST_COMPONENT_TYPE,
  formFields,
  validations,
  submitMessage: 'testComponent.message.testComponentUpdated'.getLabel(),
  testComponentUnique: 'Test Component Already exists',
  reloadOnChangeEvent: `reload${MODELS.LAB_TEST}`,
});
