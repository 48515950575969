import React from 'react';

import { FilterGroup } from '../../../../../app-components';
import { TableActionButton } from '../../../../../app-components/buttons/Buttons';
import { ROUTES, MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import tabTheme from '../../../../../theme/childTabTheme';
import SalaryRecordList from './salaryRecordsList';
import { filterInactive, filterActive } from '../../../../../images';
import { searchFilter } from '../../../../../autoSuggestions';
import WithPermission from '../../../../../Components/WithPermission';
import permissions from '../../../../../Lib/permissions';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';

const ViewSalaryTabs = (props) => (
  <TabNavigator
    theme={{
      ...tabTheme,
      tabItemStyle: {
        ...tabTheme.tabItemStyle,
        containerStyle: {
          cursor: 'pointer',
          marginTop: 3,
          marginBottom: 11,
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: 'auto',
          height: 40,
          backgroundColor: vars.colors.secondary.color4,
          paddingLeft: 30,
        },
      },
    }}
    actions={[
    ]}
    tabs={{
      SalaryGenerationRecord: {
        label: 'Salary Generation Record',
        screen: SalaryRecordList,
        actions: [
          {
            render: (renderProps) => (
              // <WithPermission
              //   access_key={
              //   permissions.departments.views.generateSalarySheet
              //     .permissions.generateSalarySheet.value
              // }
              // >
              <TableActionButton
                label="Generate Arrears"
                width={150}
                color={vars.colors.secondary.color2}
                hoverColor={vars.colors.secondary.color2}
                labelStyle={{
                  ...vars.headings.h7,
                  color: vars.colors.white,
                  numberOfLines: 1,
                }}
                {...renderProps}
              />
              // </WithPermission>
            ),
            type: 'link',
            link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
              view: ROUTES.generateArrears.name,
              params: { ...filters },
              modal: true,
            }),
          },
          {
            render: (renderProps) => (
              // <WithPermission
              //   access_key={
              //   permissions.departments.views.generateSalarySheet
              //     .permissions.generateSalarySheet.value
              // }
              // >
              <TableActionButton
                label="Payment Done"
                width={150}
                color={vars.colors.secondary.color2}
                hoverColor={vars.colors.secondary.color2}
                labelStyle={{
                  ...vars.headings.h7,
                  color: vars.colors.white,
                  numberOfLines: 1,
                }}
                {...renderProps}
              />
              // </WithPermission>
            ),
            type: 'link',
            link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
              view: ROUTES.paymentDone.name,
              params: { ...filters },
              modal: true,
            }),
          },
          {
            render: (renderProps) => (
              <WithPermission
                access_key={
                permissions.departments.views.generateSalarySheet
                  .permissions.generateSalarySheet.value
              }
              >
                <TableActionButton
                  label="Download Salary Statement & Arrears"
                  width={300}
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                  {...renderProps}
                />
              </WithPermission>
            ),
            type: 'link',
            link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
              view: ROUTES.allSalarySheetModal.name,
              params: { ...filters },
              modal: true,
            }),
          },
          {
            render: (renderProps) => (
              <WithPermission
                access_key={
                permissions.departments.views.generateSalarySheet
                  .permissions.generateSalarySheet.value
              }
              >
                <TableActionButton
                  label="Download Monthly Salary Sheet"
                  width={270}
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                  {...renderProps}
                />
              </WithPermission>
            ),
            type: 'link',
            link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
              view: ROUTES.salarySheetModal.name,
              params: { ...filters },
              modal: true,
            }),
          },
          {
            render: (renderProps) => (
              <WithPermission
                access_key={
                permissions.departments.views.generateSalarySheet
                  .permissions.generateSalarySheet.value
              }
              >
                <TableActionButton
                  label="Download Released Salary Sheet"
                  width={270}
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                  {...renderProps}
                />
              </WithPermission>
            ),
            type: 'link',
            link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
              view: ROUTES.salarySheetModal.name,
              params: { ...filters, fromReleasedTab: true },
              modal: true,
            }),
          },
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'month_id',
                label: 'Month',
                model: MODELS.MONTHS,
                id: 'MonthTypeSuggestions',
                searchField: 'name',
                placeholder: 'Months',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              {
                type: 'autoSuggest',
                field: 'year_id',
                label: 'Year',
                model: MODELS.years,
                id: 'YearTypeSuggestions',
                searchField: 'name',
                placeholder: 'Year',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: '_id',
              },
              searchFilter({
                field: 'employee',
                model: MODELS.EMPLOYEE,
                id: 'employeeLists',
                searchField: 'name',
                label: 'Employee',
                suggestionField: 'fullName',
                multiSelect: true,
                filterTabs: ['pending', 'approved', 'rejected'],
              },
              {
                modifyResult: ({ data }) => {
                  const clonedData = data.map((item) => ({
                    ...item,
                    fullName: `${item?.firstName} ${item?.lastName}`,
                  }));

                  return { data: clonedData };
                },
              }),
              {
                type: 'autoSuggest',
                field: 'employee.employeeType',
                label: 'Employee Type',
                model: MODELS.EMPLOYEETYPE,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select Employee Type',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
              {
                type: 'autoSuggest',
                field: 'employee.staffType',
                label: 'Staff Type',
                model: MODELS.STAFFTYPE,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select Staff Type',
                suggestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
            ],
          }),
        ],
      },
    }}
    {...props}
  />
);
export default ViewSalaryTabs;
