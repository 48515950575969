/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ActivityIndicator, Toast } from '../../../../../../app-components';
import {
  autoSuggestInput,
  multiAutoSuggestInput,
  searchInput,
} from '../../../../../../autoSuggestions';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import {
  bloodGroup, genders, MODELS, ROUTES,
} from '../../../../../../Lib/constants';
import RelationInput from '../../../../../../Components/RelationInput';
import { ageInput } from '../../../../../../compositeInputs';
import { getUser, invoke, submit } from '../../../../../../AppServices';
import vars from '../../../../../../theme/vars';
import {
  getErrorString,
  getExpirationStatus,
  getRemainingSelfValue,
  validateAlphabetsWithSpaceOnly,
} from '../../../../../../Lib/helpers';
import { Form } from '../../../../../../app-components/form/Form';

const IssueBlood = (props) => {
  const { navigation } = props;
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const timeOnVisiting = ((moment(new Date()).format('h') / 1) + (moment(new Date()).format('mm') / 60)).toFixed(2);
  const dateOnVisiting = new Date();
  const getDataForNestedTable = useCallback(async () => {
    setLoading(true);
    try {
      const result = await invoke({
        id: '_find',
        paramValue: {
          query: {
            id: 'bloodGroupListForTable',
            model: MODELS.BLOOD_BANK,
            addOnFilter: {
              _id: {
                $in: props?.navigation?.state?.params?.item?.state?.selectedIds,
              },
            },
          },
          model: MODELS.BLOOD_BANK,
        },
      });
      setData(result?.result);
      setLoading(false);
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  useEffect(() => {
    getDataForNestedTable();
  }, []);

  if (loading) return <ActivityIndicator />;

  return (
    <Form
      {...props}
      type="standard"
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        if (!user?.employee) {
          Toast.show({
            message: 'Error',
            description: 'asset.messages.loginUserError'.getLabel(),
            type: 'error',
            position: 'top',
            direction: 'right',
            duration: 3000,
          });
          return {};
        }
        if (timeOnVisiting !== submitProps?.data?.time || moment(dateOnVisiting).format('DD MM YYYY') !== moment(submitProps?.data?.issueDate).format('DD MM YYYY')) {
          navigation.push({
            view: ROUTES.issueBloodRequestMSModal.name,
            modal: true,
            params: {
              data,
              updates: {
                ...updates,
                is_requested: true,
                requestedBy: user?.employee,
                newDateOfIssue: updates?.issueDate,
                issueDate: dateOnVisiting,
                newTimeOfIssue: updates?.time,
                time: timeOnVisiting,
              },
            },
          });
          return {};
        }
        const newUpdates = {
          ...updates,
          is_approved: true,
          is_requested: false,
          approvedOrRejectedBy: user?.employee,
        };
        const submitAPI = submit({
          model: MODELS.BLOOD_REQUEST,
          ...submitProps,
          data,
        });
        await submitAPI({ data, updates: newUpdates });
        Toast.show({
          message: 'Success',
          description: 'bloodInventory.messages.issueBloodMessage'.getLabel(),
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
        return {};
      }}
      reloadEvent={`reload${MODELS.BLOOD_BANK}`}
      defaultValues={() => ({
        bag_details: data,
        category: data[0]?.category,
        'patient.ageType': 'Years',
        issueDate: new Date(),
        time: ((moment(new Date()).format('h') / 1) + (moment(new Date()).format('mm') / 60)).toFixed(2),
      })}
      mandatory={{
        bloodGroup: 1,
        time: 1,
        issueDate: 1,
      }}
      computations={{
        self: {
          speciality: {
            compute: (data) => {
              if (!data?.speciality?.length) {
                return {
                  set: {
                    doctorName: null,
                  },
                };
              }
              return {
                set: {
                  doctorName: null,
                },
              };
            },
            onChange: ['speciality'],
          },
          department: {
            compute: () => ({
              set: {
                speciality: null,
              },
            }),
            onChange: ['department'],
          },
        },
      }}
      validations={{
        'patient.patientName': ({ data = {} }) => {
          if (!data?.patient?.patientName) {
            return 'This field cannot be empty';
          }
          return false;
        },
        'patient.age': ({ data }) => {
          if (!data?.patient?.age) return 'This field cannot be empty';
          return false;
        },
        'patient.gender': ({ data }) => {
          if (!data?.patient?.gender) return 'This field cannot be empty';
          return false;
        },
        'patient.relationName': ({ data }) => {
          if (!data?.patient?.relationName) return 'This field cannot be empty';
          return false;
        },
        'patient.relation': ({ data }) => {
          if (!data?.patient?.relation) return 'Please select relation type';
          return false;
        },
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            label: 'Bag Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    field: 'bag_details',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          {
                            header: 'bloodInventory.headers.bagNo'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'name',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.tubeNo'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'tubeNo',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.donationDate'.getLabel(),
                            variant: 'filled',
                            width: 180,
                            type: 'date',
                            format: 'DD MMM YYYY',
                            field: 'donationId.date',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.category'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'custom',
                            render: () => data[0]?.category,
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.remainingSelfLife'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'custom',
                            render: (props) => getRemainingSelfValue(props?.item?.expiryDate),
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.bagWeight'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'bagWeight',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.testStatus'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'testStatus',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.expiryStatus'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            render: (props) => getExpirationStatus(props?.expiryDate),
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.result'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            render: ({ item }) => {
                              if (item?.result?.length) {
                                return item?.result
                                  ?.map((result) => result?.label)
                                  .toString();
                              }

                              return '';
                            },
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.bagStatus'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'bagStatus',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.expiryDate'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'date',
                            field: 'expiryDate',
                            editable: false,
                          },
                          {
                            header: 'bloodInventory.headers.donorName'.getLabel(),
                            variant: 'filled',
                            width: 120,
                            type: 'text',
                            field: 'bloodDonor.name',
                            editable: false,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'Patient Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  searchInput({
                    label: 'bloodInventory.labels.UHID'.getLabel(),
                    field: 'patient',
                    suggestionField: 'uhid',
                    model: MODELS.PATIENTS,
                    query: 'patientDataList',
                  }),
                  {
                    type: 'text',
                    label: 'bloodInventory.labels.patientName'.getLabel(),
                    field: 'patient.patientName',
                    placeholder: 'bloodInventory.placeholders.patientName'.getLabel(),
                    mandatory: true,
                    allowWhiteSpaces: true,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (validateAlphabetsWithSpaceOnly(value)) {
                        return prevValue;
                      }
                      return value.replace(/ {2,}/g, ' ');
                    },
                    disabled: ({ data }) => !!data?.patient?._id,
                  },
                  {
                    type: 'custom',
                    render: () => (
                      <RelationInput
                        {...props}
                        disabled={({ data }) => !!data?.patient?._id}
                        relationFieldName="patient.relation"
                        relationNameFieldName="patient.relationName"
                        relationNameProps={{
                          allowWhiteSpaces: true,
                        }}
                      />
                    ),
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  ageInput({
                    age: {
                      type: 'number',
                      field: 'patient.age',
                      label: 'patient.labels.age'.getLabel(),
                      mandatory: true,
                      allowedDecimalDigits: false,
                      modifyValueOnChange: ({ prevValue, value }) => {
                        if (value > 999) {
                          return prevValue;
                        }
                        return value;
                      },
                    },
                    disabled: ({ data }) => !!data?.patient?._id,
                    ageType: {
                      field: 'patient.ageType',
                    },
                  }),
                  autoSuggestInput({
                    label: 'bloodInventory.labels.gender'.getLabel(),
                    field: 'patient.gender',
                    valueField: 'value',
                    suggestionField: 'label',
                    placeholder: 'bloodInventory.placeholders.selectgender'.getLabel(),
                    options: genders,
                    disabled: ({ data }) => !!data?.patient?._id,
                  }),
                  autoSuggestInput({
                    label: 'bloodInventory.labels.bloodGroup'.getLabel(),
                    field: 'bloodGroup',
                    valueField: 'value',
                    mandatory: true,
                    suggestionField: 'label',
                    placeholder: 'bloodInventory.placeholders.selectbloodGroup'.getLabel(),
                    options: bloodGroup,
                  }),
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'date',
                    field: 'issueDate',
                    mandatory: true,
                    label: 'bloodInventory.labels.dateOfIssue'.getLabel(),
                  },
                  {
                    type: 'time',
                    field: 'time',
                    mandatory: true,
                    label: 'bloodInventory.labels.timeOfIssue'.getLabel(),
                    container: 'leftLabel',
                  },
                  {
                    type: 'textArea',
                    field: 'description',
                    label: 'bloodInventory.labels.reasonOfIssue'.getLabel(),
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'current Admission Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  autoSuggestInput({
                    field: 'department',
                    label: 'bloodInventory.labels.department'.getLabel(),
                    sugestionField: 'name',
                    primaryField: 'name',
                    valueField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                  }),
                  multiAutoSuggestInput(
                    {
                      field: 'speciality',
                      label: 'bloodInventory.labels.speciality'.getLabel(),
                      placeholder: 'bloodInventory.labels.speciality'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                    },
                    {
                      addOnFilter: ({ data } = {}) => ({
                        department: { _id: data?.department?._id },
                      }),
                    },
                  ),
                  searchInput(
                    {
                      label: 'bloodInventory.labels.doctor'.getLabel(),
                      field: 'doctorName',
                      variant: 'filled',
                      suggestionField: 'firstName',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                    },
                    {
                      addOnFilter: ({ data = {} }) => (
                        {
                          speciality: {
                            _id: {
                              $in: data.speciality.map(
                                (specialityId) => specialityId._id,
                              ),
                            },
                          },
                        }),
                    },
                  ),
                ],
              },
              {
                columns: [
                  {
                    type: 'file',
                    field: 'attachment',
                    multiple: true,
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default IssueBlood;
