/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator, Toast,
} from '../../../../app-components';
import { fetch, post } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import PatientDetails from './Components/PatientDetails';

const IncomingPatientDetails = (props) => {
  const {
    navigation,
    patientId,
    setPatientDetailsVisible,
    status,
    eventDispatcher,
    consultationId,
    setPatientDetails: unsetSelectedPatient,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [patientDetails, setPatientDetails] = useState({});
  const [consultationDetails, setConsultationDetails] = useState({});

  const fetchConsultationDetails = useCallback(async () => {
    const fetchedConsultationDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'consultationDataList',
            addOnFilter: {
              _id: consultationId,
            },
          },
          model: MODELS.CONSULTATION,
          limit: 1,
        },
      },
    });
    if (fetchedConsultationDetails?.data && fetchedConsultationDetails.data.length) {
      setConsultationDetails(fetchedConsultationDetails.data[0]);
    }
    setIsLoading2(false);
    return fetchedConsultationDetails;
  }, [consultationId]);

  const fetchPatientDetails = useCallback(async () => {
    const fetchedPatientDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientDetails',
            addOnFilter: {
              _id: patientId,
            },
          },
          model: MODELS.PATIENTS,
          limit: 1,
        },
      },
    });
    if (fetchedPatientDetails?.data && fetchedPatientDetails.data.length) {
      setPatientDetails(fetchedPatientDetails.data[0]);
    }
    setIsLoading(false);
    return fetchedPatientDetails;
  }, [patientId]);

  const handleOnPressViewConsultationLog = useCallback(() => {
    navigation.push(ROUTES.doctorConsultationList.name, {
      model: MODELS.CONSULTATION,
      id: 'consultationDataList',
      addOnFilter: { patient: { _id: patientId }, status: { $in: ['completed', 'not-appeared'] } },
      patientName: patientDetails?.patientName,
    });
  }, [navigation, patientDetails.patientName, patientId]);

  const handleOnPressViewVaccinationLog = useCallback(() => {
    navigation.push(ROUTES.doctorVaccinationList.name, {
      model: MODELS.PATIENT_VACCINATION_HISTORY,
      id: 'patientVaccinationHistory',
      addOnFilter: { patient: { _id: patientId } },
      patientName: patientDetails?.patientName,
    });
  }, [navigation, patientDetails.patientName, patientId]);

  const handleOnPressAddDiagnosis = useCallback(() => {
    navigation.push(ROUTES.opdIncomingPatientsAddDiagnosis.name, {
      consultationId,
    });
  }, [consultationId, navigation]);

  const updateConsultationStatus = useCallback(async (updatedStatus) => {
    try {
      setIsLoading(true);
      const fetchedPatientDetails = await post({
        data: { _id: consultationId },
        updates: { status: updatedStatus },
        model: MODELS.CONSULTATION,
      });
      Toast.show({
        message: 'Success',
        description: 'Consultation Updated successfully',
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      setPatientDetailsVisible(false);
      eventDispatcher.notify('reloadConsultedPatients');
      fetchPatientDetails();
      fetchConsultationDetails();
      unsetSelectedPatient({});
      setIsLoading(false);
      return fetchedPatientDetails;
    } catch (err) {
      setIsLoading(false);
    }
  }, [
    consultationId,
    eventDispatcher,
    fetchPatientDetails,
    fetchConsultationDetails,
    setPatientDetailsVisible,
    unsetSelectedPatient,
  ]);

  const handleOnPressConsulted = useCallback(() => {
    updateConsultationStatus('completed');
  }, [updateConsultationStatus]);

  const handleOnPressNotAppeared = useCallback(() => {
    updateConsultationStatus('not-appeared');
  }, [updateConsultationStatus]);

  const handleOnPressHideDetails = useCallback(() => {
    setPatientDetailsVisible(false);
    unsetSelectedPatient({});
  }, [setPatientDetailsVisible]);

  useEffect(() => {
    setIsLoading(true);
    fetchPatientDetails();
    fetchConsultationDetails();
  }, [fetchPatientDetails, fetchConsultationDetails]);

  if (isLoading || isLoading2) {
    return <ActivityIndicator />;
  }

  return (
    <PatientDetails
      handleOnPressViewConsultationLog={handleOnPressViewConsultationLog}
      handleOnPressViewVaccinationLog={handleOnPressViewVaccinationLog}
      patientDetails={patientDetails}
      allowToUpdateConsultation={status === 'assigned'}
      setPatientDetailsVisible={setPatientDetailsVisible}
      handleOnPressConsulted={handleOnPressConsulted}
      handleOnPressNotAppeared={handleOnPressNotAppeared}
      handleOnPressHideDetails={handleOnPressHideDetails}
      handleOnPressAddDiagnosis={handleOnPressAddDiagnosis}
      token={consultationDetails?.token}
      consultationId={consultationId}
      remarks={consultationDetails?.remarks}
      prescription={consultationDetails?.prescription}
      diagnosis={consultationDetails?.diagnosis}
    />
  );
};
export default IncomingPatientDetails;
