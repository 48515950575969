/* eslint-disable max-len */
import React from 'react';
import { Text } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import vars from '../../../../theme/vars';
import { AssetItemMonthlyRemarksUri } from '../../../../Queries/asset';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';

const tabListColumns = [
  { width: 14 },
  {
    type: 'date',
    header: 'asset.headers.date'.getLabel(),
    field: 'remarksDate',
    sortable: 'remarksDate',
  },
  {
    type: 'text',
    header: 'asset.headers.month'.getLabel(),
    field: 'month',
  },
  {
    type: 'date',
    header: 'asset.headers.year'.getLabel(),
    field: 'remarksDate',
    format: 'YYYY',
  },
  {
    type: 'text',
    header: 'asset.headers.remarks'.getLabel(),
    field: 'monthlyRemarks',
    width: 500,
  },
  {
    header: 'asset.headers.action'.getLabel(),
    type: 'openActions',
    visible: ({ user }) => {
      if (user?.username === 'superadmin') return true;
      const { role = [] } = user;
      return role?.some(({ code }) => code === 'Incharge');
    },
    openActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.modifyRemarks.permissions.modifyRemarks.value}
          >
            <Text style={{ color: vars.colors.secondary.color2 }}>Edit Remark</Text>
          </WithPermission>
        ),
        type: 'link',
        link: ({ item }) => ({
          view: ROUTES.editMonthlyRemarks.name,
          params: { item },
        }),
      },
    ],
  },
];

const AssetItemMonthlyRemarksList = (props) => {
  const { tableColumns = tabListColumns, item: { _id } } = props;

  return (
    <Table
      uri={() => AssetItemMonthlyRemarksUri({ filter: { item: { _id } } })}
      reloadEvent={`reload${MODELS.ASSET_MONTHLY_REMARKS}`}
      lg={{ columns: tableColumns }}
      {...props}
    />
  );
};
export default AssetItemMonthlyRemarksList;
