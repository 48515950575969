import {Dimensions} from '@applane/react-core-components';

export const breakPoints = [
  {width: 1200, activeMQ: 'lg'},
  {width: 600, activeMQ: 'md'},
  {width: 320, activeMQ: 'sm'},
  {width: 0, activeMQ: 'xs'},
];

export const getActiveMQ = ({width} = {}) => {
  if (!width) {
    width = Dimensions.get('window').width;
  }
  for (let breakPoint of breakPoints) {
    if (width >= breakPoint.width) {
      return breakPoint.activeMQ;
    }
  }
};

export const resolvedActiveMQ = ({activeMQ, mqDefs = {}} = {}) => {
  if (!activeMQ) {
    for (let breakpoint of breakPoints) {
      if (mqDefs[breakpoint.activeMQ]) {
        return mqDefs[breakpoint.activeMQ];
      }
    }
    return;
  }

  let newMq = void 0;
  if (mqDefs[activeMQ]) {
    newMq = activeMQ;
  } else {
    let mqs = breakPoints.map((item) => item.activeMQ);
    for (let mq of mqs) {
      if (Object.keys(mqDefs).indexOf(mq) > -1) {
        if (mqs.indexOf(mq) > mqs.indexOf(activeMQ)) {
          newMq = mq;
          break;
        } else {
          newMq = mq;
        }
      }
    }
  }
  return newMq && mqDefs[newMq];
};

export const getResolvedMQProps = (props, {activeMQ} = {}) => {
  if (!activeMQ) {
    activeMQ = getActiveMQ();
  }
  let newProps = {...props};
  let mqProps = void 0;
  breakPoints.forEach((value) => {
    let mq = value.activeMQ;
    if (newProps[mq]) {
      mqProps = mqProps || {};
      mqProps[mq] = newProps[mq];
      delete newProps[mq];
    }
  });
  if (mqProps) {
    let resolvedMQProps = resolvedActiveMQ({activeMQ, mqDefs: mqProps});
    if (resolvedMQProps) {
      newProps = Object.assign(newProps, resolvedMQProps);
    }
  }
  return newProps;
};
