import MasterForm from '../../../Wired/Master/MasterForm';
import { editRoomCategory } from '../../../Queries/roomCategories';
import { MODELS } from '../../../Lib/constants';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Edit Ward Category'.getLabel(),
    allowWhiteSpaces: true,
    mandatory: true,
  },
  {
    type: 'number',
    field: 'charge',
    label: 'Charges',
    inputProps: {
      maxLength: 20,
    },
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'room.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'center',
  },
];

export default MasterForm({
  uri: editRoomCategory,
  formFields,
  model: MODELS.ROOM_CATEGORY,
  uniqueValidationMessage: 'Ward Category Already Exists',
  submitMessage: 'Ward Category Added Successfully',
  closeView: 1,
});
