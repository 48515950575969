/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-extraneous-dependencies

import React, { useCallback } from 'react';
import uuid from 'uuid/v4';

import { Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import {
  NestedAction,
  NestedTable,
} from '../../../../../app-components/input-components/nestedTable/NestedTable';
import {
  autoSuggestInput, searchInput,
} from '../../../../../autoSuggestions';
import { assetStatuses, MODELS } from '../../../../../Lib/constants';
import { invoke } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const getNewId = () => `new_${uuid()}`;

const LoanOutAssetForm = (props) => {
  const { navigation: { state: { params: { item: { item } = {} } = {} } = {} } = {}, eventDispatcher } = props;
  const handleLoanOutRequest = useCallback(async (props) => {
    const { data } = props;
    await invoke({
      id: 'handleLoanOutRequest',
      paramValue: { data },
    });
    eventDispatcher.notify(`reload${MODELS.ASSET}`);
  }, []);
  return (
    <Form
      type="standardShadow"
      closeView={1}
      onSubmit={(props) => handleLoanOutRequest(props)}
      reloadEvent={`reload${MODELS.ASSET_ITEM}`}
      submitMessage={'asset.messages.itemAddedSuccessfully'.getLabel()}
      uniqueValidationMessage={'asset.messages.itemAlreadyExists'.getLabel()}
      computations={{
        children: {
          assetDetails: {
            self: {
              itemAutofill: {
                compute: (item) => ({
                  set: {
                    assetStatus: item?.asset?.assetStatus,
                  },
                }),
                onChange: ['asset'],
              },
            },
          },
        },
      }}
      mandatory={{ description: 1 }}
      defaultValues={() => ({
        assetDetails: [{ _id: getNewId() }],
      })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            label: 'asset.title.loanOutDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'department',
                    variant: 'filled',
                    label: 'asset.labels.department'.getLabel(),
                    placeholder: 'asset.labels.department'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                  }),
                  autoSuggestInput({
                    field: 'speciality',
                    label: 'asset.labels.speciality'.getLabel(),
                    placeholder: 'asset.placeholders.speciality'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.WARDS,
                    query: 'masterDataList',
                  },
                  {
                    addOnFilter: ({ data } = {}) => ({
                      department: { _id: data?.department?._id },
                    }),
                  }),
                  {
                    type: 'number',
                    field: 'days',
                    variant: 'filled',
                    label: 'asset.labels.days'.getLabel(),
                    placeholder: 'asset.placeholders.days'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'textArea',
                    field: 'description',
                    variant: 'filled',
                    label: 'Description',
                    placeholder: 'Description',
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'assetDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'asset.labels.addRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: true,
                        columns: [
                          searchInput({
                            field: 'asset',
                            variant: 'filled',
                            label: 'asset.labels.serialNo'.getLabel(),
                            placeholder: 'asset.placeholders.serialNo'.getLabel(),
                            keyField: 'serialNumber',
                            valueField: 'serialNumber',
                            suggestionField: 'serialNumber',
                            model: MODELS.ASSET,
                            query: 'assetInventoryDataList',
                            mandatory: true,
                            width: 300,
                          },
                          {
                            addOnFilter: ({
                              data:
                                { _parent: { assetDetails = [] } = {} },
                            } = {}) => {
                              const itemIds = assetDetails?.filter(
                                ({ asset }) => asset?._id,
                              ).map(({ asset }) => asset?._id);
                              return {
                                _id: { $nin: itemIds },
                                item: { _id: item._id },
                                isCentral: true,
                                assetStatus: assetStatuses.IN_STORAGE,
                              };
                            },
                          }),
                          {
                            label: 'asset.headers.stockStatus'.getLabel(),
                            type: 'text',
                            variant: 'filled',
                            disabled: true,
                            width: 300,
                            field: 'assetStatus',
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default LoanOutAssetForm;
