import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { StandardButton, Text } from '../../../../../../../app-components';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { GetOTListUri } from '../../../../../../../Queries/otList';
import vars from '../../../../../../../theme/vars';

const tabListColumns = () => ([
  {
    header: 'Serial Number',
    render: (item) => item?.index + 1,
  },
  {
    header: 'Date',
    type: 'date',
    field: 'otDate',
    format: 'DD/MM/YYYY',
  },
  {
    header: 'Number Of Patients',
    type: 'number',
    field: 'numberOfPatient',
  },
  {
    header: 'Number Of Patients Approved',
    type: 'number',
    field: 'numberOfPatientApproved',
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>View</Text>
      ),
      type: 'link',
      link: ({ item }) => ({
        view: ROUTES.viewOTList.name,
        params: { item },
      }),
    },
    ],
  },
]);

const OtList = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;
  const { dataParams } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="Lists"
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Create New List"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.createNewOTList.name,
              params: { ...params, dataParams },
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.IPD_OT_LIST}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={GetOTListUri}
    />
  );
};
export default OtList;
