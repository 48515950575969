import React, { useCallback } from 'react';

import {
  StandardButton,
  Toast,
  View,
  Text,
} from '../../../../app-components';
import { downloadData } from '../../../../AppServices';

const ChronicPatient = (props) => {
  const { filters: { filters: { customFilter: { value } = {} } = {} } = {} } = props;
  const month = new Date(value.to).getMonth() + 1;
  const year = new Date(value.to).getFullYear();

  const downloadChronicPatientSheetOPD = useCallback(async () => {
    await downloadData({
      service: {
        id: 'OPDChronicPatientSheet',
        paramValue: {
          month,
          year,
        },
      },
    });
    Toast.show({
      message: 'Success',
      description: 'OPD chronic patients report is generated successfully',
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
  }, [month, year]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        width: '402px',
        height: '158px',
        left: '566px',
        top: '100px',
        background: '#EFF3FB',
        borderRadius: '12px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        marginTop: '20px',
        justifyContent: 'space-between',
      }}
    >
      <Text>
        <h5>Download Chronic Patient Report!</h5>
      </Text>
      <StandardButton
        label="Export"
        width="150px"
        onClick={downloadChronicPatientSheetOPD}
      />
    </View>
  );
};
export default ChronicPatient;
