import { MODELS } from '../Lib/constants';

export const viewMLCSampleListUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_MLC_SAMPLES,
    id: 'MLCSamplesList',
    addOnFilter: {
      ipdPatient: { _id: navigation?.state?.params?.patient?._id },
    },
  },
  aggregate: true,
  model: MODELS.IPD_MLC_SAMPLES,
});
