import React, { useEffect } from 'react';
import moment from 'moment';

import {
  TouchableOpacity,
  Image,
  View,
  Text,
  showSelectedCount,
  clearSelection,
  selectAll,
  StandardButton,
} from '../../../../../app-components';
import { editRecord } from '../../../../../images';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { patientListUri } from '../../../../../Queries/patient';
import { getString } from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';
import { Table } from '../../../../../app-components/table/Table';

const header = ({ aggregates }) => ({
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'patient.buttons.exportToExcel'.getLabel()} />
      ),
      file: 'PatientsManagementList',
      type: 'download',
      columns: [
        {
          field: 'patientName',
          header: 'Patient Name',
        },
        {
          field: 'gender',
          header: 'Gender',
        },
        {
          field: 'age',
          header: 'Age',
        },
        {
          field: 'relation',
          header: 'Relation Type',
        },
        {
          field: 'relationName',
          header: 'Relation Name',
        },
        // {
        //   field: 'aadharNumber',
        //   header: 'Aadhar Number',
        // },
        // {
        //   field: 'BPLNumber',
        //   header: 'BPL Number',
        // },
        // {
        //   field: 'mobile',
        //   header: 'Mobile',
        // },
        // {
        //   field: 'address',
        //   header: 'Address',
        // },
        // {
        //   field: 'remarks',
        //   header: 'Remarks',
        // },
        {
          field: {
            field: 'lastConsultation[0].date',
            parserFunction: 'formatDateForExcel',
          },
          header: 'Last Consultation Date',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.PATIENTS,
          query: {
            id: 'patientDataList',

          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState: { selectedIds } } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    },
  ]),
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'patient.headers.uhid'.getLabel(),
        type: 'text',
        field: 'uhid',
      },
      {
        header: 'patient.headers.patientName'.getLabel(),
        type: 'text',
        sortable: 'patientName',
        field: 'patientName',
      },
      {
        header: 'patient.headers.relation'.getLabel(),
        type: 'text',
        value: ({ item }) => getString([item?.relation, item?.relationName]),
      },
      {
        header: 'patient.headers.gender'.getLabel(),
        type: 'text',
        value: ({ item }) => item?.gender.charAt(0).toUpperCase() + item?.gender.slice(1),
      },
      {
        header: 'patient.headers.age'.getLabel(),
        type: 'text',
        field: 'age',
      },
      {
        header: 'patient.headers.speciality'.getLabel(),
        type: 'text',
        field: 'lastIPD.speciality.name',
      },
      {
        header: 'patient.headers.remarks'.getLabel(),
        type: 'text',
        field: 'lastIPD.remarks',
      },
      {
        header: 'patient.headers.lastConsultationDate'.getLabel(),
        type: 'date',
        sortable: 'lastConsultationDate',
        width: 165,
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.doctorConsultationList.name,
              modal: true,
              params: {
                item,
                model: MODELS.CONSULTATION,
                id: 'consultationDataList',
                patientName: item?.patientName,
                addOnFilter: {
                  patient: {
                    _id: item?._id,
                  },
                },
              },
            };
          },
        },
        render: ({ item }) => (
          <View>
            <Text style={{ color: vars.colors.secondary.color2, textDecorationLine: 'underline' }}>
              {item?.lastConsultationDate ? moment(item?.lastConsultationDate).format('DD MMM YYYY') : 'N/A'}
            </Text>
          </View>
        ),
      },
      {
        header: 'patient.headers.action'.getLabel(),
        type: 'link',
        value: 'Admit to IPD',
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.admitPatientToIPD.name,
              params: { patient: item },
            };
          },
        },
      },
      {
        header: '',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.editPatient.name,
              params: { _id: item._id },
            };
          },
        },
      },
    ],
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewPatientIPD.name,
        params: { _id: item?._id, patient: item },
      },
    }),
  },
};

const PatientList = (props) => {
  const { searchData, navigation } = props;
  useEffect(() => {
    navigation.setScreenState({ dataParams: searchData });
  }, [navigation, searchData]);
  return (
    <Table
      uri={patientListUri}
      {...tableItems}
      {...props}
      reloadEvent="reloadOPDRegisteredPatients"
    />
  );
};
export default PatientList;

