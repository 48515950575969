import React from 'react';

import { editAdmitIPDPatientUri } from '../../../../../../../Queries/patient';
import { newDateInput } from '../../../../../../../compositeInputs';
import { Form } from '../../../../../../../app-components/form/Form';
import vars from '../../../../../../../theme/vars';
import {
  autoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../../../../../../autoSuggestions';
import { MODELS } from '../../../../../../../Lib/constants';
import { afterFetchForm } from '../../../../../../../app-components/data-fetch/DataFetchFunctions';
import { afterUriFetch } from '../../../../../constants';
import { Text } from '../../../../../../../app-components';

const outcomeTypes = [
  'Discharge',
  'Left against medical advice',
  'Absconded',
  'Referred to Higher Center',
  'Death',
];
const operationDegreeTypes = ['Minor', 'Major'];
const now = new Date();
const currentHour = now.getHours() % 12 || 12;
const currentMeridiem = currentHour >= 12 ? 'PM' : 'AM';
const currencyMinute = now.getMinutes();

const IpdForm = (props) => {
  const {
    navigation: {
      state: {
        params: { patient },
      },
    },
  } = props;
  const dateOfDischargeOrDeath = patient.outcome ? patient.outcome.dischargedDate
  || patient.outcome.deathDate
  || patient.outcome || patient._createdOn
    : '--';


  const causeOfDischargeOrDeath = patient.outcome ? patient.outcome.abscondedNote
  || patient.outcome.causeOfDischarge
  || patient.outcome.deathCause
  || patient.outcome.lamaNote
    : '--';

  const { dataParams } = props;

  const notify = dataParams?.filters?.specialityFilter === 'General Medicine'
    || dataParams?.filters?.specialityFilter === 'Orthopedic'
    || dataParams?.filters?.specialityFilter === 'ICU';

  return (
    <Form
      {...props}
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      closeView={1}
      editable={false}
      afterFetch={(dataAfterfetch) => afterUriFetch({
        dataAfterfetch,
        insertData: {
          hour: currentHour,
          minute: currencyMinute,
          meridiem: currentMeridiem,
        },
      })}
      uri={editAdmitIPDPatientUri({ ...props })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'number',
                    field: 'CRNumber',
                    label: 'CR Number',
                  },
                  {
                    type: 'number',
                    label: 'OPD Number',
                  },
                  {
                    type: 'text',
                    field: 'department.name',
                    label: 'Department',
                  },
                  {
                    type: 'text',
                    field: 'speciality.name',
                    label: 'Speciality',
                  },
                  {
                    type: 'date',
                    field: '_createdOn',
                    label: 'Date Of Admission',
                    format: 'MMM DD YYYY',
                  },
                  {
                    type: 'date',
                    field: '_createdOn',
                    format: 'HH:mm A',
                    label: 'Time Of Admission',
                  },
                  {
                    type: 'text',
                    field: 'rooms.name',
                    label: 'Bed Number',
                  },
                  {
                    type: 'text',
                    field: '',
                    label: 'Number Of Days Stay',
                  },
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'Patient Name',
                  },
                  {
                    type: 'text',
                    label: 'Relation',
                    render: (item) => (
                      <Text style={{ fontSize: 14, lineHeight: 16 }}>
                        {item?.data?.patient?.relation}
                        {' '}
                        {item?.data?.patient?.relationName}
                        `
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    render: ({ data }) => (
                      <Text style={{ fontSize: 14, lineHeight: 16 }}>
                        {data?.patient?.gender?.charAt(0)?.toUpperCase()}
                        {''}
                        {data?.patient?.gender?.slice(1)}
                      </Text>
                    ),
                    label: 'Gender',
                  },
                  {
                    type: 'number',
                    field: 'patient.age',
                    label: 'Age',
                  },
                  {
                    type: 'number',
                    field: 'patient.mobile',
                    label: 'Mobile',
                  },
                  {
                    type: 'text',
                    label: 'Marital Status',
                  },
                  {
                    type: 'text',
                    label: 'Bed Category',
                    field: 'rooms.roomCategories.name',
                  },
                  {
                    type: 'text',
                    field: 'rooms.name',
                    label: 'Room No.',
                  },
                  {
                    type: 'text',
                    field: 'patient.address',
                    label: 'Address',
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'number',
                    field: 'annualIncome',
                    label: 'Annual Income',
                  },
                  {
                    type: 'text',
                    field: 'ICDCode',
                    label: 'ICD Code',
                  },
                  autoSuggestInput({
                    label: 'Unit',
                    field: 'unit',
                    suggestionField: 'label',
                    options: [1, 2, 3, 4, 5],
                    editable: true,
                  }),
                  {
                    type: 'text',
                    field: 'consultant',
                    label: 'Consultant',
                    allowWhiteSpaces: true,
                    editable: true,
                  },
                  {
                    type: 'text',
                    field: 'finalDiagnosis',
                    label: 'Final Diagnosis',
                    allowWhiteSpaces: true,
                    editable: true,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: notify ? 2 : 1,
                columns: [
                  multiSearchInput({
                    field: 'notifyTo',
                    variant: 'filled',
                    label: 'Notify To',
                    keyField: 'name',
                    valueField: 'name',
                    searchField: 'name',
                    suggestionField: 'name',
                    model: MODELS.WARDS,
                    query: 'masterDataList',
                    editable: true,
                    visible: notify,
                    width: 396,
                  }),
                  {
                    type: 'text',
                    field: 'outcome.outcome',
                    label: 'Outcome',
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            editable: false,
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    render: ({ data }) => data?.surgeonName?.map((item) => item?.name?.charAt(0)?.toUpperCase() + item?.name?.slice(1)).join(', '),
                    variant: 'filled',
                    label: 'Surgeon',
                    style: { fontSize: 14 },
                    width: '25%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'operationName.name',
                    label: 'Name Of Operation',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'operationName.operationDegree',
                    label: 'Operation Degree',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'operationName.code',
                    label: 'Operation Code',
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            editable: true,

            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    // format: 'MMM DD YYYY',
                    // field: 'dateOfDischargeOrDeath',
                    value: dateOfDischargeOrDeath === '--' ? '--' : dateOfDischargeOrDeath,
                    label: ' Date Of Discharge/Death/Lama',
                    editable: false,
                    width: '25%',
                  },
                  newDateInput({
                    hour: { field: 'hour', label: 'Hour' },
                    minute: { field: 'minute', label: 'Minute' },
                    meridiem: { field: 'meridiem' },
                  }),
                  {
                    type: 'text',
                    value: causeOfDischargeOrDeath,
                    label: 'Cause Of Discharge/Discharge/Lama',
                    allowWhiteSpaces: true,
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'checkBox',
                    field: 'noDues',
                    label: 'No Dues',
                    editable: false,
                    showOuterLabel: false,
                  },
                  {
                    type: 'text',
                    field: 'paymentStatus',
                    label: 'Payment Status',
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  searchInput({
                    field: 'consultant',
                    variant: 'filled',
                    label: 'Consultant',
                    keyField: 'name',
                    valueField: 'name',
                    searchField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    editable: true,
                  }),
                  searchInput({
                    field: 'seniorResident',
                    variant: 'filled',
                    label: 'Senior Resident',
                    keyField: 'name',
                    valueField: 'name',
                    searchField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    editable: true,
                  }),
                  searchInput({
                    field: 'juniorResident',
                    variant: 'filled',
                    label: 'Junior Resident',
                    keyField: 'name',
                    searchField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    editable: true,
                  }),
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default IpdForm;
