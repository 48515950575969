import React from 'react';

import {
  TouchableOpacity,
  Image,
  Text,
} from '../../../app-components';
import { EmployeeMonthlySalaryRemarkList } from '../../../Queries/employeeMonthlyRemark';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { Table } from '../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { editRecord } from '../../../images';

const header = ({ aggregates }) => ({
  title: <HeaderTitleWithCount
    count={aggregates?._count}
    label={'Employee Remarks'.getLabel()}
  />,
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        header: 'Employee Name',
        type: 'text',
        render: (data) => <Text>{data?.item?.employee?.name}</Text>,
      },
      {
        header: 'Month',
        type: 'text',
        field: 'month',
      },
      {
        header: 'Year',
        type: 'text',
        field: 'year',
      },
      {
        header: 'remark',
        type: 'text',
        render: (data) => <Text>{data.item.remark}</Text>,
      },
      {
        header: 'Action',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.EditRemark.name,
              params: { _id: item._id },
              modal: true,
              modalProps: {
                width: 600,
              },
            };
          },
        },
      },
    ],
  },
};

const EmployeeRemarkList = (props) => (
  <Table
    uri={EmployeeMonthlySalaryRemarkList}
    reloadEvent={`reload${MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK}`}
    {...tableItems}
    {...props}
  />
);
export default EmployeeRemarkList;

