import React from 'react';
import { TabPanel } from '@applane/react-tab-panel-v1';
import { DataFetch } from '../data-fetch/DataFetch';
import { Action } from '../action/Action';
import { getResolvedMQProps } from '../../BreakPoints';
import { TabBar, TabItem } from '@applane/react-tab-bar-v1';
import { DropDownAction } from '@applane/react-drop-down-v1';
import ScreenComponent from '../screen/ScreenComponent';
import { tabTheme, tabMobileTheme } from '../../theme/tabBarTheme';
import { getUser } from '../../AppServices';
import { isMobile } from '../UtilityFunctions';
import ToolBar from '../toolBar/ToolBar';
import {
  closeNextViews,
  resolveTabItemLabel,
  resolveTabItemCount,
  resolveTabState,
  resolveDataFetchProps,
  DEFAULT_GROUP_NAME_IN_LABEL,
} from './TabNavigatorUtility';

let tabNavigatorTheme = isMobile ? tabMobileTheme : tabTheme;

class GroupTabItem extends React.Component {
  render() {
    let { items, theme, ...restProps } = this.props;
    return (
      <DropDownAction
        options={items}
        Action={Action}
        {...theme}
        {...restProps}
      />
    );
  }
}
export class TabNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = this.getResolvedTabs();
  }

  getResolvedTabs = () => {
    let { tabsFunc, tabs, navigation } = this.props;
    if (typeof tabsFunc === 'function') {
      tabs = tabsFunc(this.props);
    }
    if (!tabs) {
      return tabs;
    }
    const activeMQ =
      navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    let newTabs = {};
    Object.keys(tabs).forEach((tab) => {
      let tabDetail = tabs[tab];
      tabDetail = getResolvedMQProps(tabDetail, { activeMQ });
      let { visible = true } = tabDetail;
      if (visible && typeof visible === 'function') {
        visible = visible({
          user: getUser && getUser(),
          navigation,
        });
      }
      if (visible === true) {
        newTabs[tab] = tabDetail;
      }
    });

    return newTabs;
  };

  getTheme = () => {
    return this.props.theme || tabNavigatorTheme || {};
  };

  onTabChange = (props) => {
    closeNextViews(props);
  };

  renderHeader = ({ header, ...restHeaderProps }) => {
    let { tabHeaderStyle } = this.getTheme() || {};
    if (typeof header === 'function') {
      header = header(...restHeaderProps);
    }
    if (header) {
      return <ToolBar {...tabHeaderStyle} {...restHeaderProps} {...header} />;
    } else {
      return null;
    }
  };

  render() {
    const { navigation } = this.props;
    const activeMQ =
      navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    let resolvedProps = getResolvedMQProps(this.props, { activeMQ });
    let { screenProps } = resolvedProps;
    if (screenProps) {
      resolvedProps.screenProps = getResolvedMQProps(screenProps, { activeMQ });
    }
    let {
      minTabScrollCount,
      tabPanelStyle,
      tabBarStyle,
      tabItemStyle,
      tabActionStyle,
      tabGroupItemsStyle,
    } = this.getTheme();
    return (
      <TabPanel
        onTabChange={this.onTabChange}
        {...tabPanelStyle}
        renderHeader={this.renderHeader}
        renderTabBar={
          <TabBar
            minTabScrollCount={
              resolvedProps.minTabScrollCount || minTabScrollCount
            }
            showScrollIcon={!isMobile}
            {...tabBarStyle}
            user={getUser && getUser()}
            renderItem={
              <TabItem
                {...tabItemStyle}
                resolveCount={resolveTabItemCount}
                DataFetch={DataFetch}
                dataFetchProps={resolveDataFetchProps}
                renderGroupItems={<GroupTabItem theme={tabGroupItemsStyle} />}
                resolveLabel={resolveTabItemLabel}
                defaultGroupNameInLabel={DEFAULT_GROUP_NAME_IN_LABEL}
              />
            }
          />
        }
        renderLeftAction={<ToolBar {...tabActionStyle} />}
        renderActions={<ToolBar {...tabActionStyle} />}
        ScreenComponent={ScreenComponent}
        resolveTabState={resolveTabState}
        {...resolvedProps}
        tabs={this.tabs}
      />
    );
  }
}

export const TabNavigatorHoc = (defs) => {
  class TabNavigatorComponent extends React.Component {
    render() {
      let _defs = defs;
      if (typeof _defs === 'function') {
        _defs = _defs(this.props);
      }
      return <TabNavigator {..._defs} {...this.props} />;
    }
  }
  return TabNavigatorComponent;
};

export default TabNavigatorHoc;
