import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { TableActionButton } from '../../../../../../../app-components/buttons/Buttons';
import WithPermission from '../../../../../../../Components/WithPermission';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import permissions from '../../../../../../../Lib/permissions';
import vars from '../../../../../../../theme/vars';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="Employee Forms"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      render: (renderProps) => (
        <WithPermission
          access_key={
          permissions.departments.views.generateSalarySheet
            .permissions.generateSalarySheet.value
        }
        >
          <TableActionButton
            label="Add Form"
            width={230}
            color={vars.colors.secondary.color2}
            hoverColor={vars.colors.secondary.color2}
            labelStyle={{
              ...vars.headings.h7,
              color: vars.colors.white,
              numberOfLines: 1,
            }}
            {...renderProps}
          />
        </WithPermission>
      ),
      type: 'link',
      link: ({ screenState: { dataParams: { filters } = {} } = {} } = {}) => ({
        view: ROUTES.formBulkUpload.name,
        params: { ...filters },
        // modal: true,
      }),
    }],
});

const tabListColumns = {
  header,
  lg: {
    columns: [
      {
        type: 'text',
        header: 'Year',
        field: 'financialYear.name',
        width: '20%',
      },
      //   {
      //     type: 'text',
      //     header: 'Employee',
      //     field: 'employee.name',
      //     width: '20%',
      //   },
      {
        type: 'file',
        width: '80%',
        field: 'attachedFile',
        header: 'File Uploaded',
        container: 'topLabel',
        // render: (data) => {
        //   const value = data?.attachedFile;
        //   if (value) {
        //     return <FileLink file={value.file} />;
        //   }
        //   return <View />;
        // },
      },
    ],
  },
};
const EmployeeForms = (props) => {
  const { addOnFilter: { employee } } = props;
  return (
    <Table
      {...props}
      header
      uri={() => ({
        query: {
          model: MODELS.EMPLOYEE_FORM,
          id: 'employeeFormModel',
          addOnFilter: { employee: { _id: employee._id } },
          sort: { _createdOn: -1 },
        },
        model: MODELS.EMPLOYEE_FORM,
      })}
      reloadEvent={`reload${MODELS.EMPLOYEE_FORM}`}
      model={MODELS.EMPLOYEE_FORM}
      {...tabListColumns}
    />
  );
};
export default EmployeeForms;
