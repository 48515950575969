import React from 'react';

import { autoSuggestInput } from '../../../autoSuggestions';
import { EditRoom } from '../../../Queries/room';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength } from '../../../Lib/helpers';
import { Form } from '../../../app-components/form/Form';
import { submit } from '../../../AppServices';
import { View } from '../../../app-components';

import ViewRoomTabs from './ViewRoom/components/Tabs/index';

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 20),
};

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'room.labels.room'.getLabel(),
    mandatory: true,
    editable: false,
  },
  autoSuggestInput({
    field: 'speciality',
    label: 'room.labels.speciality'.getLabel(),
    placeholder: 'room.placeholders.speciality'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.WARDS,
    query: 'masterDataList',
    searching: true,
  },
  {
    addOnFilter: ({ data } = {}) => ({
      department: { _id: data?.department?._id },
    }),
  }),
  autoSuggestInput({
    mandatory: true,
    field: 'department',
    label: 'room.labels.department'.getLabel(),
    placeholder: 'room.placeholders.department'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.DEPARTMENTS,
    query: 'masterDataList',
    searching: true,
  }),
  {
    type: 'number',
    field: 'totalBeds',
    label: 'room.labels.totalBeds'.getLabel(),
    visible: ({ data }) => data?.department?.trackBeds,
  },
  autoSuggestInput({
    type: 'autoSuggest',
    field: 'roomCategories',
    label: 'Ward Category',
    placeholder: 'Ward Category',
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.ROOM_CATEGORY,
    query: 'masterDataList',
    mandatory: true,
    visible: ({ data }) => data?.department,
    editable: true,
  }),
  {
    editable: true,
    type: 'text',
    field: 'ward',
    label: 'Ward',
    mandatory: true,
    visible: ({ data }) => data?.roomCategories?.name === 'General Ward',
    inputProps: {
      maxLength: 20,
    },
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'room.labels.status'.getLabel(),
    mandatory: true,
    container: 'leftLabel',
    align: 'center',
  },
];

// export default MasterForm({
// uri: EditRoom,
//   formFields,
//   model: MODELS.ROOM,
//   validations,
//   uniqueValidationMessage: 'room.messages.roomAlreadyExists'.getLabel(),
//   submitMessage: 'room.messages.roomEditedSuccessfully'.getLabel(),
//   closeView: 1,
//   computations: {
//     self: {
//       department: {
//         compute: () => ({
//           set: { speciality: null },
//         }),
//         onChange: ['department'],
//       },
//     },
//   },
// });

const EditRoomForm = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        renderLoading={false}
        validations={validations}
        mandatory={{ name: 1 }}
        uniqueValidationMessage={'pharmacy.messages.categoryAlreadyExists'.getLabel()}
        submitMessage={'pharmacy.messages.categoryUpdatedSuccessfully'.getLabel()}
        uri={EditRoom}
        onSubmit={submit({
          model: MODELS.ROOM,
        })}
        closeView={1}
        model={MODELS.ROOM}
        editable
        columnsPerRow={3}
        formGroups={[
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groups: [
              {
                columns: [...formFields],
              },
            ],
          },
        ]}
        reloadEvent={`reload${MODELS.ROOM}`}
        defaultValues={() => ({
          isActive: true,
          // module: modules.pharmacy,
        })}
        {...props}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewRoomTabs {...props} />
    </View>
  </View>
);
export default EditRoomForm;
