import { MODELS } from '../Lib/constants';

export const EditEmployeeCategory = ({ navigation }) => ({
  query: {
    model: MODELS.EMPLOYEE_CATEGORY,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.EMPLOYEE_CATEGORY,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.EMPLOYEE_CATEGORY,

});

