/* eslint-disable no-shadow */
/* eslint-disable react/jsx-key */
import React from 'react';
import {
  FilterGroup,
  StandardButton,
} from '../../../../../../app-components';
import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../../Components/TableFilters/FtsSearch';
import WithPermission from '../../../../../../Components/WithPermission';
import { filterActive, filterInactive } from '../../../../../../images';
import {
  bloodBankTestStatus,
  bloodCategoriesMap,
  bloodGroup,
  MODELS,
  ROUTES,
} from '../../../../../../Lib/constants';
import permissions from '../../../../../../Lib/permissions';
import { countUri } from '../../../../../../Queries/countUri';
import { dropDownShadow } from '../../../../../../theme/shadows';
import BloodGroupList from '../BloodGroupList';

const BloodGroupTabs = (props) => {
  const {
    navigation: { state: { params: { item: data } = {} } = {} } = {},
  } = props;
  return (
    <TabNavigator
      tabs={{
        Listing: {
          label: 'Listing',
          screen: BloodGroupList,
          screenProps: {
            model: MODELS.BLOOD_BANK,
            id: 'bloodGroupListForTable',
            addOnFilter: { category: data?.category },
          },
          countUri: countUri({
            model: MODELS.BLOOD_BANK,
            id: 'bloodGroupListForTable',
            filter: { category: data?.category },
          }),
          actions: [
            {
              visible: ({ navigation }) => {
                const { selectedIds } = navigation.getScreenState();
                if (selectedIds && selectedIds.length) {
                  return true;
                }
                return false;
              },
              render: () => (
                <WithPermission
                  access_key={
                    permissions.inventory.views.issueBlood.permissions.issueBlood.value
                  }
                >
                  <StandardButton
                    label={'bloodInventory.buttons.issueBlood'.getLabel()}
                  />
                </WithPermission>
              ),
              type: 'link',
              link: (item) => ({
                view: ROUTES.issueBloodGroup.name,
                params: { item },
              }),
            },
            {
              visible: ({ navigation }) => {
                const { selectedIds } = navigation.getScreenState();
                const {
                  state: {
                    params: {
                      item: { category },
                    },
                  },
                } = navigation;
                if (selectedIds && selectedIds.length && category === bloodCategoriesMap.FFP.toLowerCase()) {
                  return true;
                }
                return false;
              },
              render: () => (
                <WithPermission
                  access_key={
                    permissions.inventory.views.bloodBankInventory.permissions.add
                      .value
                  }
                >
                  <StandardButton
                    label={'bloodInventory.buttons.issueToCompany'.getLabel()}
                  />
                </WithPermission>
              ),
              type: 'link',
              link: (item) => ({
                view: ROUTES.issueToCompanyModal.name,
                modal: true,
                params: { item },
              }),
            },
            {
              visible: ({ navigation }) => {
                const { selectedIds } = navigation.getScreenState();
                if (selectedIds && selectedIds.length) {
                  return false;
                }
                return true;
              },
              render: (props) => (
                <FtsSearch
                  {...props}
                  action={{
                    exp: ['name', 'bagStatus', 'bloodGroup', 'testStatus'],
                  }}
                />
              ),
            },
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              dropdownStyle: {
                width: 300,
                height: 350,
                backgroundColor: 'white',
                ...dropDownShadow,
                borderRadius: 8,
                overflow: 'hidden',
              },
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: bloodGroup,
                  field: 'bloodGroup',
                  label: 'Blood Group',
                  multiSelect: true,
                },
                {
                  type: 'autoSuggestOptions',
                  options: bloodBankTestStatus,
                  field: 'testStatus',
                  label: 'Test Status',
                },
                {
                  type: 'autoSuggestOptions',
                  options: [
                    { label: 'Active', value: { $gte: new Date() } },
                    { label: 'Expired', value: { $lt: new Date() } },
                  ],
                  field: 'expiryDate',
                  label: 'Expiry Status',
                },
              ],
            }),
          ],
        },
      }}
      {...props}
    />
  );
};
export default BloodGroupTabs;
