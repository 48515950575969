import React, { useCallback, useState } from 'react';

import {
  FormField, LargeButton, Toast, View,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import { invoke } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import { entryLevels, MODELS } from '../../Lib/constants';
import { getErrorString } from '../../Lib/helpers';
import vars from '../../theme/vars';

const PromotionModal = (props) => {
  const {
    navigation,
  } = props;

  const selectedIds = navigation.getParam('selectedIds');

  const handleOnSubmitPromote = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'createPromotionEntry',
        paramValue: { employeeIds: selectedIds, ...data },
        // paramValue: { selectedIds, ...data },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Employees promoted successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [navigation, selectedIds]);

  return (
    <ListActionModal
      icon={history}
      title="Promote Employees"
      description={`This action will promote ${selectedIds.length} employees to below selected grade pay and level.`}
      content={(
        <Form
          onSubmit={handleOnSubmitPromote}
          mandatory={{
            grade_pay: 1,
            entry_level: 1,
            promotion_date: 1,
            current_designation: 1,
          }}

          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'grade_pay',
                        label: 'Select Grade Pay',
                        placeholder: 'Select Grade Pay',
                        variant: 'filled',
                        suggestionField: 'name',
                        model: MODELS.GRADE_PAY_LEVEL,
                        query: 'gradePay',
                        mandatory: true,
                      },
                    )}
                  />
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'entry_level',
                          label: 'Select Entry Level',
                          placeholder: 'Select Entry Level',
                          variant: 'filled',
                          suggestionField: 'label',
                          options: entryLevels,
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'held_designation',
                          label: 'Select Held Designation',
                          placeholder: 'Select Held Designation',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.DESIGNATION,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'current_designation',
                          label: 'Select Current Designation',
                          placeholder: 'Select Current Designation',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.DESIGNATION,
                          mandatory: true,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...
                        {
                          field: 'promotion_date',
                          label: 'Select Promotion Date',
                          placeholder: 'Select Promotion Date',
                          variant: 'filled',
                          type: 'date',
                          mandatory: true,
                        }
                      }
                    />
                  </View>
                  {/* <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'promotion_month_id',
                          label: 'Select Promotion Month',
                          placeholder: 'Select Promotion Month',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.MONTHS,
                          query: 'MonthTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'promotion_year_id',
                          label: 'Select Promotion Year',
                          placeholder: 'Select Promotion Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View> */}

                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Promote"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default PromotionModal;
