import React from 'react';

import {
  View, Text, save, Toast,
} from '../../../../../../../../app-components';
import { NestedAction, NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const IpdAddProgressPediatrics = (props) => {
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          computations={{
            self: {
              transferTo: {
                compute: () => ({
                  set: { roomCategories: '', transferBedNumber: '', assignTO: '' },
                }),
                onChange: ['transferTo'],
              },
              changeBedCategory: {
                compute: () => ({
                  set: { changeBedNumber: '', transferBedNumber: '', assignTO: '' },
                }),
                onChange: ['changeBedCategory'],
              },
            },
          }}

          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
            };

            const submitAPI = submit({
              model: MODELS.IPD_PROGRESS_REPORT,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            validations: {
              vitalsP: ({ data }) => validateInputLength(data?.vitalsP, 1, 4),
              vitalsBP: ({ data }) => validateInputLength(data?.vitalsBP, 1, 4),
              vitalsRR: ({ data }) => validateInputLength(data?.vitalsRR, 1, 5),
              vitalsTemperature: ({ data }) => validateInputLength(data?.vitalsTemperature, 1, 4),
              vitalsInTake: ({ data }) => validateInputLength(data?.vitalsInTake, 1, 4),
              vitalsUrinalOutput: ({ data }) => validateInputLength(data?.vitalsUrinalOutput, 1, 4),
              vitalsDrainOutput: ({ data }) => validateInputLength(data?.vitalsDrainOutput, 1, 5),
              vitalsRtOutput: ({ data }) => validateInputLength(data?.vitalsRtOutput, 1, 5),
              route: ({ data }) => validateInputLength(data?.route, 1, 20),

              frequency: ({ data }) => validateInputLength(data?.frequency, 1, 50),
              description: ({ data }) => validateInputLength(data?.description, 1, 100),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: true,
                            defaultValue: new Date().toLocaleDateString(),
                          },
                          {
                            type: 'number',
                            field: 'daysOfStays',
                            label: 'Days of Stays',
                            editable: true,
                          },
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'doctorOnDuty',
                            label: 'Doctor On Duty',
                            variant: 'filled',
                            keyFiled: 'firstName',
                            suggestionField: 'firstName',
                            valueField: 'firstName',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            mandatory: true,
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'doctorOnRound',
                            label: 'Doctor On Round',
                            variant: 'filled',
                            keyFiled: 'firstName',
                            suggestionField: 'firstName',
                            valueField: 'firstName',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            mandatory: true,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Complaints </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            allowWhiteSpaces: true,
                            field: 'complaintOne',
                            editable: true,
                          },
                          {
                            type: 'text',
                            allowWhiteSpaces: true,
                            field: 'complaintTwo',
                            editable: true,
                          },
                          {
                            type: 'text',
                            allowWhiteSpaces: true,
                            field: 'complaintThree',
                            editable: true,
                          },
                          {
                            type: 'text',
                            allowWhiteSpaces: true,
                            field: 'complaintFour',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Examination </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'examination',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              footer: ({ addRow }) => ({
                                leftActions: [{
                                  render: () => (
                                    <View style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      width: 120,
                                      height: 40,
                                      borderColor: vars.colors.secondary.color2,
                                    }}
                                    >
                                      <Text style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.secondary.color2,
                                      }}
                                      >
                                        {`+ ${'Add New Item'.getLabel()}`}
                                      </Text>
                                    </View>
                                  ),
                                  onPress: addRow,
                                }],
                              }),
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: true,
                                    width: '18%',
                                    mandatory: true,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    editable: true,
                                    width: '18%',
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    editable: true,
                                    width: '18%',
                                  },
                                  {
                                    type: 'number',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: true,
                                    width: '18%',
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    allowWhiteSpaces: true,
                                    editable: true,
                                    width: 312,
                                  },
                                  NestedAction(),
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'transferTo',
                            label: 'Transfer To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.WARDS,
                            query: 'masterDataList',
                            mandatory: true,
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'assignTO',
                            label: 'Assign To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.EMPLOYEE,
                            query: 'getEmployeeDetails',
                            mandatory: true,
                          },
                          {
                            addOnFilter: ({ data } = {}) => ({
                              speciality: { _id: data?.transferTo?._id },
                            })
                            ,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const AddProgressPediatrics = (props) => (<IpdAddProgressPediatrics {...props} />);
export default AddProgressPediatrics;
