/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FormField, Image, View,
} from '../../../../app-components';
import { autoSuggestInput } from '../../../../autoSuggestions';
import vars from '../../../../theme/vars';
import {
  expandIcon,
} from '../../../../images';
import { getString } from '../../../../Lib/helpers';

const styles = {
  autoSuggestContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: vars.colors.grey.color6,
  },
  autoSuggestWrapper: {
    width: 300,
    height: 44,
    marginLeft: 20,
  },
};

const Selector = () => (
  <View
    style={{
      width: 26,
      justifyContent: 'center',
    }}
  >
    <Image
      source={expandIcon}
      style={{
        width: 11,
        height: 6,
      }}
    />
  </View>
);

const SessionInfo = (props) => {
  const { setScreenState, sessionInfo } = props;
  const { session } = sessionInfo;
  useEffect(() => {
    if (!isEmpty(sessionInfo)) {
      setScreenState({ sessionQueue: sessionInfo });
    }
  }, [sessionInfo, setScreenState]);

  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          width: 300,
          height: 44,
        }}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'vaccination.labels.assistant'.getLabel(),
            value: getString([
              session?.assistant?.firstName,
               session?.assistant?.lastName,
            ]),
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
          }
        />
      </View>
      <View
        style={styles.autoSuggestWrapper}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'vaccination.labels.nurse'.getLabel(),
            value: getString([
              session?.nurse?.firstName,
               session?.nurse?.lastName,
            ]),
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
        }
        />
      </View>
      <View
        style={{
          width: 300,
          height: 44,
          marginLeft: 20,
        }}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'vaccination.labels.department'.getLabel(),
            value: 'Vaccination',
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
        }
        />
      </View>
    </View>
  );
};

export default SessionInfo;
