import React, { useState, useCallback, useEffect } from 'react';

import { ActivityIndicator } from '../../../../app-components/loader/Loader';
import { ROUTES, MODELS } from '../../../../Lib/constants';
import { fetch, getUser } from '../../../../AppServices';
import SessionTabs from '../SessionTabs';

const ViewActiveSession = (props) => {
  const { navigation, setScreenState, eventDispatcher } = props;
  const [loadingActiveSession, setLoadingActiveSession] = useState(true);
  const [activeSession, setActiveSession] = useState(false);

  const fetchMySessions = useCallback(async () => {
    setLoadingActiveSession(true);
    const fetchedSessions = await fetch({
      uri: {
        props: {
          query: {
            id: 'getMyVaccinationSession',
            addOnFilter: {
              _createdBy: { _id: getUser()._id },
              isActive: true,
            },
          },
          model: MODELS.VACCINATION_SESSION,
          limit: 1,
        },
      },
    });
    if (fetchedSessions?.data && fetchedSessions.data.length) {
      setActiveSession(fetchedSessions.data[0]);
      setScreenState(fetchedSessions.data[0]);
    }
    setLoadingActiveSession(false);
    return fetchedSessions;
  }, [setScreenState]);

  useEffect(() => {
    fetchMySessions();
    eventDispatcher.listen('reloadAssistantVaccinationData', fetchMySessions);
    return () => eventDispatcher.unlisten('reloadAssistantVaccinationData');
  }, [fetchMySessions]);

  if (!loadingActiveSession) {
    if (!activeSession) {
      navigation.replace(ROUTES.vaccinationTokenGeneration.name, { isSessionInActive: true });
    } else {
      return (
        <SessionTabs
          {...props}
          activeSession={activeSession}
          screenProps={{ activeSession }}
        />
      );
    }
  }
  return <ActivityIndicator />;
};

export default ViewActiveSession;
