import React from 'react';
import moment from 'moment';

import {
  close, save, Text, Toast, View,
} from '../../../../app-components';
// import printConsultationReceipt from '../../../../Wired/ConsutationReceipt';
import { autoSuggestInput, searchInput } from '../../../../autoSuggestions';
import { fetch, submit } from '../../../../AppServices';
import { consultationTypes, MODELS, ROUTES } from '../../../../Lib/constants';
import Chips from '../../../../Components/Chips';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { ageInput } from '../../../../compositeInputs';
import { printConsultationCard } from '../../../../Wired/OPDCard';
import { Form } from '../../../../app-components/form/Form';

const header = {
  title: 'patient.title.consultationRegistration'.getLabel(),
  subTitle: 'patient.subTitle.consultationSubTitle'.getLabel(),
  actions: [close],
  containerStyle: {
    height: 60,
    paddingLeft: 10,
    paddingTop: 14,
  },
};

const footer = {
  containerStyle: {
    height: 70,
    paddingTop: 5,
  },
  actions: [
    save,
    {
      ...save({
        label: 'patient.buttons.printOpdCard'.getLabel(),
      }),
      printReceipt: true,
    },
  ],
};

const AddConsultation = (props) => {
  const { navigation } = props;

  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      onSubmit={async ({ data, updates }) => {
        if (data?.type === 'videoAppointment') {
          if (!data?.slot?._id) {
            throw new Error(
              'Please select a slot for Video Appointment',
            );
          }
          const submitAPI = submit({
            model: MODELS.CONSULTATION,
            data,
          });
          await submitAPI({ data, updates }).then(() => {
            Toast.show({
              message: 'Success',
              description: 'patient.messages.consultationAddedSuccessfully'.getLabel(),
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }).catch((error) => {
            throw error;
          });
        } else {
            const submitAPI = submit({
              model: MODELS.CONSULTATION,
              data,
            });
            const response = await submitAPI({ data, updates })
            try{
              if(Object.keys(response.result).length){
                Toast.show({
                  message: 'Success',
                  description: 'patient.messages.consultationAddedSuccessfully'.getLabel(),
                  type: 'success',
                  position: 'top',
                  direction: 'right',
                  duration: 3000,
                });
                return response;
              }
            } catch (error) {
              throw error;
            }
          }
        }
      }
      reloadEvent="reloadConsultations"
      submitNext={(submitNextProps) => {
        const {
          data,
          action: { printReceipt },
          submitResult,
        } = submitNextProps;
        if (printReceipt) {
          printConsultationCard({
              _id: submitResult?.result?._id,
              uhid: data?.patient?.uhid,
       }, () => {
          });
        }
      }}
      defaultValues={({
        navigation: {
          state: {
            params: { patient },
          },
        } = {},
      }) => ({
        department: {
          name: 'OPD',
        },
        patient,
        age: patient.age,
        ageType: patient.ageType,
        remarks: patient?.lastConsultation?.remarks,
      })}
      computations={{
        self: {
          speciality: {
            compute: async (data) => {
              if (data?.speciality) {
                const response = await fetch({
                  uri: {
                    props: {
                      query: {
                        id: 'masterDataList',
                        addOnFilter: {
                          speciality: { _id: data?.speciality?._id },
                        },
                      },
                      model: MODELS.ROOM,
                    },
                  },
                });
                return {
                  set: { rooms: response?.data },
                };
              }

              return {};
            },
            onChange: ['speciality'],
          },
        },
      }}
      mandatory={{
        speciality: 1,
        type: 1,
      }}
      lg={{
        header,
        formGroups: [
          {
            fieldVariant: 'filled',
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'uploadImage',
                    defaultImage: barcode,
                    imageWidth: 75,
                    imageHeight: 75,
                    editable: false,
                    width: 100,
                  },
                  {
                    type: 'custom',
                    editable: false,
                    render: ({ data: { patient } }) => (
                      <View style={{ marginTop: 20 }}>
                        <Text
                          style={{
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                          }}
                        >
                          {'patient.labels.uhid'.getLabel()}
                        </Text>
                        <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                          {patient?.uhid}
                        </Text>
                      </View>
                    ),
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'patient.patientName',
                    label: 'patient.labels.name'.getLabel(),
                    editable: false,
                  },
                  ageInput({
                    age: {
                      type: 'number',
                      field: 'age',
                      label: 'patient.labels.age'.getLabel(),
                      mandatory: true,
                      allowedDecimalDigits: false,
                      modifyValueOnChange: ({ prevValue, value }) => {
                        if (value > 999) {
                          return prevValue;
                        }
                        return value;
                      },
                    },
                    ageType: {
                      field: 'ageType',
                    },
                  }),
                  autoSuggestInput({
                    label: 'patient.labels.gender'.getLabel(),
                    field: 'patient.gender',
                    suggestionField: 'label',
                    placeholder: 'patient.placeholders.selectGender'.getLabel(),
                    options: [
                      { value: 'male', label: 'Male' },
                      { value: 'female', label: 'Female' },
                      { value: 'other', label: 'Other' },
                    ],
                    editable: false,
                  }),
                  autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'patient.labels.department'.getLabel(),
                      placeholder: 'patient.placeholders.selectDepartment'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      editable: false,
                    },
                    {
                      addOnFilter: {
                        code: 'OPD',
                      },
                    },
                  ),
                  autoSuggestInput({
                    label: 'Type',
                    field: 'type',
                    suggestionField: 'label',
                    placeholder: 'Select Type',
                    options: consultationTypes,
                    mandatory: true,
                  }),
                  {
                    type: 'custom',
                    visible: ({ data }) => data.type === 'videoAppointment',
                    render: ({ setValue, data }) => {
                      if (data?.slot?._id) {
                        return (
                          <View
                            onPress={() => {
                              navigation.push({
                                view: ROUTES.addAppointment.name,
                                params: {
                                  setValue,
                                },
                              });
                            }}
                          >
                            <Text style={{
                              ...vars.headings.h9, color: vars.colors.secondary.color2, marginTop: 10, cursor: 'pointer',
                            }}
                            >
                              {moment(data?.slot?.start_time).format('DD MMM YYYY, hh:mm A')}
                            </Text>
                          </View>
                        );
                      }
                      return (
                        <View
                          onPress={() => {
                            navigation.push({
                              view: ROUTES.addAppointment.name,
                              params: {
                                setValue,
                              },
                            });
                          }}
                        >
                          <Text style={{
                            ...vars.headings.h9, color: vars.colors.secondary.color2, marginTop: 10, cursor: 'pointer',
                          }}
                          >
                            Select Slot
                          </Text>
                        </View>
                      );
                    },
                  },
                  searchInput(
                    {
                      field: 'speciality',
                      label: 'patient.labels.speciality'.getLabel(),
                      placeholder: 'patient.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      searchField: 'name',
                      mandatory: true,
                      searching: true,
                    },
                    {
                      addOnFilter: { department: { code: 'OPD' } },
                    },
                  ),
                  {
                    type: 'textArea',
                    field: 'remarks',
                    label: 'patient.labels.remarks'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'custom',
                    field: 'rooms',
                    visible: ({ data }) => data?.speciality,
                    render: ({ data }) => <Chips data={data?.rooms} field="name" />,
                    editable: false,
                  },
                ],
              },
            ],
          },
        ],
        footer,
      }}
    />
  );
};

export default AddConsultation;
