import React from 'react';

import MasterList from '../Master/MasterList';
import { ActivityIndicator } from '../../app-components';

const tabListColumns = [
  {
    header: 'testComponent.title.name'.getLabel(),
    type: 'text',
    value: ({ item }) => item?.name,
  },
];

const TestComponentTabList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;
  if (!data?._id) {
    return <ActivityIndicator />;
  }
  return (
    <MasterList
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        limit: 10,
        model,
      })}
      tableColumns={tableColumns}
      showActionIcon={false}
      hoverable={false}
    />
  );
};
export default TestComponentTabList;
