import { MODELS } from '../Lib/constants';

export const AuctionItemList = () => ({
  query: {
    model: MODELS.AUCTION_ITEMS,
    id: 'auctionItemList',
  },
  aggregate: true,
  model: MODELS.AUCTION_ITEMS,
});

export const BuyerListUri = () => ({
  query: {
    id: 'masterDataListForTable',
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.AUCTION_BUYER,
});

export const EditBuyer = ({ navigation }) => ({
  query: {
    model: MODELS.AUCTION_BUYER,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.AUCTION_BUYER,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.AUCTION_BUYER,
});

export const AuctionItemDetails = ({ navigation = {} }) => ({
  query: {
    model: MODELS.AUCTION_ITEMS,
    id: 'auctionItemDetails',
    relationValue: {
      model: MODELS.AUCTION_ITEMS,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.AUCTION_ITEMS,
});

