import React from 'react';

import { DateFilter } from '../../../../app-components';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import vars from '../../../../theme/vars';
import IndentRequestList from './components/IndentRequestList';
import OthersTabs from './OthersTab';
import { tabTheme } from './tabTheme';

const MSIRequestsTabs = (props) => {
  const {
    indentRequestsQueryId,
    lowShelfLifeQueryId,
    batchUpdateRequestsQueryId,
    transferRequestsQueryId,
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        IndentRequests: {
          label: 'Indent Requests',
          screen: IndentRequestList,
          screenProps: {
            queryId: indentRequestsQueryId,
          },
          actions: [
            <DateFilter
              field="_createdOn"
              key="_createdOn"
              containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
            />,
          ],
        },
        others: {
          label: 'Others',
          screen: OthersTabs,
          screenProps: {
            lowShelfLifeQueryId,
            batchUpdateRequestsQueryId,
            transferRequestsQueryId,
          },
        },
      }}
    />
  );
};
export default MSIRequestsTabs;
