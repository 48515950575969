/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React from 'react';
import moment from 'moment';

import { Text } from 'react-native';
import { cancelButton, save } from '../../../../../app-components';
import {
  autoSuggestInput,
  multiAutoSuggestInput,
  multiSearchInput,
} from '../../../../../autoSuggestions';
import {
  validatePassword,
  validateInputLength, getShiftType,
} from '../../../../../Lib/helpers';
import { entryLevels, hardCodedData, MODELS } from '../../../../../Lib/constants';
import { EditEmployee as EditEmployeeQuery } from '../../../../../Queries/employee';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { Form } from '../../../../../app-components/form/Form';

const RegistrationDetails = (props) => {
  const { visible = true, fromView = false } = props;

  return (
    <Form
      type="standardShadow"
      uri={EditEmployeeQuery}
      closeView={1}
      onSubmit={submit({
        model: MODELS.EMPLOYEE,
      })}
      reloadEvent={`reload${MODELS.EMPLOYEE}`}
      reloadOnChangeEvent={`reload${MODELS.EMPLOYEE}`}
      computations={{
        self: {
          department: {
            compute: () => ({
              set: { speciality: null },
            }),
            onChange: ['department'],
          },
          category: {
            compute: () => ({
              set: { held_position: null },
            }),
            onChange: ['category'],
          },
          staffType: {
            compute: (data) => {
              if (data?.staffType?.code === hardCodedData.SOCIETY_SALARY_CODE) {
                return { set: { isEmployeeExGratiaAndSociety: null } };
              }
            },
            onChange: ['staffType'],
          },
          experience: {
            compute: (data) => {
              const today = moment();
              const experianceYear = moment(today).diff(data?.joiningDate, 'years');
              if (experianceYear > 0) {
                return { set: { experience: experianceYear } };
              }
              return { set: { experience: 0 } };
            },
            onChange: ['joiningDate'],

          },
        },
      }}
      submitMessage={'employee.messages.updateMessage'.getLabel()}
      uniqueValidationMessage={'employee.messages.uniqueError'.getLabel()}
      mandatory={{
        employeeId: 1,
        department: 1,
        role: 1,
        staffType: 1,
        isActive: 1,
        employeeType: 1,
        joiningDate: 1,
        bondDuration: 1,
        noticePeriod: 1,
        retirementDate: 1,
        gradePayLevel: 1,
        entryLevel: 1,
      }}
      lg={{
        validations: {
          employeeId: ({ data }) => validateInputLength(data?.employeeId, 4, 30),
          password: ({ data }) => validatePassword(data?.password),
        },
        header: visible
          ? {
            actions: [
              cancelButton,
              save({ label: 'employee.buttons.update'.getLabel() }),
            ],
          }
          : null,
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'employee.labels.registrationDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'employeeId',
                    label: 'employee.labels.employeeId'.getLabel(),
                    placeholder: 'employee.placeholders.enterEmployeeId'.getLabel(),
                    mandatory: true,
                  },
                  multiAutoSuggestInput({
                    field: 'department',
                    label: 'employee.labels.department'.getLabel(),
                    placeholder: 'employee.placeholders.selectDepartment'.getLabel(),
                    mandatory: true,
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const departmentId = data?.department?.map(({ _id }) => _id);
                      if (departmentId?.length) {
                        return {
                          _id: { $nin: departmentId },
                        };
                      }
                      return {};
                    },
                  }),
                  multiAutoSuggestInput(
                    {
                      field: 'speciality',
                      label: 'employee.labels.speciality'.getLabel(),
                      placeholder: 'employee.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      visible: ({ data }) => data?.department,
                    },
                    {
                      addOnFilter: ({ data } = {}) => {
                        const specialityId = data?.speciality?.map(({ _id }) => _id);
                        if (specialityId?.length) {
                          return {
                            _id: { $nin: specialityId },
                            department: { _id: { $in: data?.department?.map(({ _id }) => _id) } },
                          };
                        }
                        return { department: { _id: { $in: data?.department?.map(({ _id }) => _id) } } };
                      },
                    },
                  ),
                  multiSearchInput({
                    field: 'role',
                    label: 'employee.labels.role'.getLabel(),
                    placeholder: 'employee.placeholders.selectRole'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.ROLE,
                    query: 'masterDataList',
                    mandatory: true,
                    searching: true,
                    searchField: 'name',
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const roleId = data?.role?.map(({ _id }) => _id);
                      if (roleId?.length) {
                        return {
                          _id: { $nin: roleId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'supervisor',
                    label: 'Supervisor',
                    placeholder: 'Please select supervisor',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    visible: ({ data: { role = [] } }) => {
                      let visibility = true;
                      if (Array.isArray(role)) {
                        role.map(({ name }) => {
                          if (name === 'Supervisor') {
                            visibility = false;
                          }
                        });
                      } else if (role?.name === 'Supervisor') {
                        visibility = false;
                      }

                      return visibility;
                    },
                  }, {
                    addOnFilter: ({ data }) => {
                      const supervisorId = data?.supervisor?._id;
                      if (supervisorId) {
                        return {
                          _id: { $ne: supervisorId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'labType',
                    label: 'employee.labels.labType'.getLabel(),
                    placeholder: 'employee.placeholders.selectDepartment'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.LAB_TYPE,
                    query: 'masterDataList',
                    visible: ({ data: { department } = {} }) => {
                      if (Array.isArray(department)) {
                        return department?.some(({ code }) => code === 'LAB');
                      }
                      if (department?.code === 'LAB') {
                        return true;
                      }
                      return false;
                    },
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const labTypeId = data?.labType?._id;
                      if (labTypeId) {
                        return {
                          _id: { $ne: labTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    field: 'staffType',
                    label: 'employee.labels.staffType'.getLabel(),
                    placeholder: 'employee.placeholders.selectStaffType'.getLabel(),
                    suggestionField: 'name',
                    keyField: 'name',
                    valueField: 'name',
                    model: MODELS.STAFFTYPE,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const staffTypeId = data?.staffType?._id;
                      if (staffTypeId) {
                        return {
                          _id: { $ne: staffTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: fromView ? 'text' : 'checkBox',
                    field: 'isEmployeeExGratiaAndSociety',
                    label: 'Is Employee is Both Ex-Gratia And Society Salary',
                    container: fromView ? 'topLabel' : 'leftLabel',
                    disabled: ({ data }) => data?.staffType?.code === hardCodedData.SOCIETY_SALARY_CODE,
                  },
                  fromView ? {
                    type: 'text',
                    render: ({ data }) => {
                      const { schedule } = data;
                      if (schedule?.length) {
                        const { ShiftType = [] } = schedule[0];
                        return (
                          <Text style={{ ...vars.headings.h8 }}>
                            {getShiftType(ShiftType)}
                          </Text>
                        );
                      }
                      return <Text style={{ ...vars.headings.h8 }}>No Active Schedule</Text>;
                    },
                    label: 'employee.labels.employeeSchedule'.getLabel(),
                    placeholder: 'employee.placeholders.employeeSchedule'.getLabel(),
                  } : null,
                  autoSuggestInput({
                    field: 'leavePolicy',
                    label: 'employee.labels.leave'.getLabel(),
                    placeholder: 'employee.placeholders.selectleaves'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.LEAVE_POLICY_TYPE,
                    query: 'leavePolicySuggestions',
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const leavePolicyId = data?.leavePolicy?._id;
                      if (leavePolicyId) {
                        return {
                          _id: { $ne: leavePolicyId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: fromView ? 'status' : 'toggleSwitch',
                    field: 'isActive',
                    label: 'employee.labels.status'.getLabel(),
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'employee.labels.contractDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'category',
                    label: 'Category',
                    placeholder: 'Category',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE_CATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'held_position',
                    label: 'Held Position',
                    placeholder: 'Held Position',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DESIGNATION,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const categoryId = data?.category?._id;
                      if (categoryId) {
                        return {
                          sanctionSeatsCategory: { category: { _id: { $eq: categoryId } } },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'current_position',
                    label: 'Current Position',
                    placeholder: 'Current Position',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DESIGNATION,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'employeeType',
                    label: 'employee.labels.employeeType'.getLabel(),
                    placeholder: 'employee.placeholders.selectEmployeeType'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEETYPE,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const employeeTypeId = data?.employeeType?._id;
                      if (employeeTypeId) {
                        return {
                          _id: { $ne: employeeTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'date',
                    field: 'joiningDate',
                    label: 'employee.labels.joiningDate'.getLabel(),
                    placeholder: 'employee.placeholders.selectjoiningDate'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'bondDuration',
                    label: 'employee.labels.bondDuration'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'noticePeriod',
                    label: 'employee.labels.noticePeriod'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'date',
                    field: 'retirementDate',
                    label: 'Tenure/Retirement Date',
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'experience',
                    disabled: true,
                    label: 'employee.labels.experience'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Employee Salary Related Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    field: 'gradePayLevel',
                    label: 'Grade Pay Level',
                    placeholder: 'Grade Pay Level',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.GRADE_PAY_LEVEL,
                    query: 'gradePay',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    label: 'Entry Level',
                    field: 'entryLevel',
                    suggestionField: 'label',
                    placeholder: 'Entry Levels',
                    options: entryLevels,
                    mandatory: true,
                  }),
                  {
                    type: 'date',
                    field: 'lastPromotionDate',
                    label: 'Last Promotion Date',
                  },
                  {
                    type: 'checkBox',
                    field: 'isAcp',
                    label: 'Is on ACP',
                  },
                  {
                    type: 'number',
                    field: 'currentAcp',
                    label: 'Current ACP Type',
                  },
                  {
                    type: 'date',
                    field: 'expectedAcpDate',
                    label: 'Expected ACP Date',
                    disabled: true,
                  },
                  {
                    type: 'date',
                    field: 'revisedAcpDate',
                    label: 'Revised ACP Date',
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default RegistrationDetails;

