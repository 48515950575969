import React from 'react';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';
import tabThemeOverride from '../../../theme/childTabTheme';
import ReviewedPackage from './ReviewedPackage';
import ReviewPackage from './PendingReviewPackage';
import AcpPackageReview from './AcpPackageReview';
import ApprovedAcpPackage from './ApprovedAcpPackage';

const PreviewPackageTabNavigation = (props) => (
  <TabNavigator
    theme={{ ...tabThemeOverride }}
    tabs={{
      PendingReivews: {
        label: 'Pending Yearly Increment',
        screen: ReviewPackage,
      },
      Reviewed: {
        label: 'Approve Yearly Increment',
        screen: ReviewedPackage,
      },
      AcpReview: {
        label: 'Pending ACP Increment ',
        screen: AcpPackageReview,
      },
      ApprovedAcpReview: {
        label: 'Approved ACP Increment ',
        screen: ApprovedAcpPackage,
      },
    }}
    {...props}
  />
);
export default PreviewPackageTabNavigation;
