/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import moment from 'moment';

import {
  Text, TouchableOpacity, View,
} from '../../../../app-components';
import { h14_AvenirNext } from '../../../../theme/fonts';
import { colors } from '../../../../theme/colors';

const { themeColor, themeTextColor } = colors;

let allArray = [];
let weekNumber = 0;

const DateRange = (props) => {
  const {
    setSelectedDate,
    selectdDate,
    checkToday = () => {},
    switchToNextWeek = 0,
    checkSelectedDate = () => {},
  } = props;

  const getCurrentWeek = (time) => {
    const currentDate = moment(time);
    const weekStart = currentDate.clone().startOf('isoWeek');
    const days = [];
    let i;
    for (i = 0; i <= 6; i += 1) {
      days.push(moment(weekStart).add(i, 'days').toDate());
    }
    return days;
  };

  useEffect(() => {
    (async () => {
      const firstDate = new Date();
      allArray = [];
      const arr = getCurrentWeek(firstDate);
      const day = firstDate.getDay();
      if (day === 0) {
        setSelectedDate(arr[6]);
      } else {
        setSelectedDate(arr[day - 1]);
      }
      allArray = arr;
      weekNumber = moment(allArray[0]).isoWeek();
      if (switchToNextWeek) {
        updateDate(switchToNextWeek);
      }
    })();
  }, [switchToNextWeek]);

  let updateDate = (switchToNextWeek) => {
    new Array(switchToNextWeek).fill(0).forEach(async () => {
      await nextDate();
      weekNumber = moment(allArray[0]).isoWeek();
    });
  };

  const nextDate = async () => {
    const next = new Date(allArray[6]);
    next.setDate(next.getDate() + 1);
    const arr = getCurrentWeek(next);
    allArray = arr;
    setSelectedDate(arr[0]);
  };

  const prevDate = () => {
    const prev = new Date(allArray[0]);
    prev.setDate(prev.getDate() - 1);
    const arr = getCurrentWeek(prev);
    allArray = arr;
    const day = new Date().getDay();
    if (checkToday(arr[day - 1])) {
      setSelectedDate(arr[day - 1]);
    } else {
      setSelectedDate(arr[0]);
    }
  };

  const visiblePrev = (dateArr) => {
    if (dateArr.length) {
      const currentWeek = moment(dateArr[0]).isoWeek();
      if (!weekNumber && !currentWeek) {
        return false;
      }
      if (weekNumber === currentWeek) {
        return false;
      }
      return true;
    }
  };

  const passedDay = (date_) => {
    const today = new Date();
    const givenDay = new Date(date_);
    const year = today.getFullYear() - givenDay.getFullYear();
    const month = today.getMonth() - givenDay.getMonth();
    const date = today.getDate() - givenDay.getDate();
    if (year > 0 || (year === 0 && month > 0) || (month === 0 && date > 0)) {
      return true;
    }
    return false;
  };
  return (
    <View style={{}}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {visiblePrev(allArray) ? (
          <TouchableOpacity
            style={{ cursor: 'pointer' }}
            onPress={() => prevDate()}
          >
            <Text>{'<'}</Text>
          </TouchableOpacity>
        ) : (
          <View style={{ width: 24, height: 24 }} />
        )}
        {allArray.map((item, index) => (
          <TouchableOpacity
            style={{
              height: 79,
              backgroundColor: checkSelectedDate(item, selectdDate)
                ? themeColor
                : 'transparent',
              borderRadius: 8,
              margin: 1,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              cursor: passedDay(item) ? '' : 'pointer',
              paddingTop: 9,
              paddingBottom: 10,
            }}
            key={index.toString()}
            onPress={() => {
              if (!passedDay(item)) {
                setSelectedDate(item);
              }
            }}
          >
            <View style={{ textAlign: 'center' }}>
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: checkSelectedDate(item, selectdDate)
                    ? themeTextColor
                    : themeTextColor,
                  width: 'min-content',
                }}
              >
                {checkToday(item) ? 'Today  ' : null}
                {moment(item).format('ddd')}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  color: themeTextColor,
                  ...h14_AvenirNext,
                  fontWeight: 600,
                }}
              >
                {moment(item).format('MMM  D')}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          style={{ cursor: 'pointer' }}
          onPress={() => {
            nextDate();
          }}
        >
          <Text>{'>'}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default DateRange;
