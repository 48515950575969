import React, { useCallback } from 'react';
import {
  FormField, Image, LargeButton, Text, Toast, View,
} from '../../../../app-components';

import { barcode, greenTick, labTestIcon } from '../../../../images';
import { Form } from '../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { fetch } from '../../../../AppServices';
import vars from '../../../../theme/vars';

const PharmacyTestContent = (props) => {
  const { navigation } = props;

  const consultationDetails = useCallback(async (uhid) => {
    const fetchedConsultationDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientDetailsWithConsultation',
            addOnFilter: { uhid },
          },
          model: MODELS.PATIENTS,
          limit: 1,
        },
      },
    });
    return fetchedConsultationDetails;
  }, []);

  const handleSearchPatientUHID = useCallback(async ({ data: { uhid } = {} } = {}) => {
    if (!uhid) {
      Toast.show({
        message: 'Error',
        description: 'billing.messages.enterUHID'.getLabel(),
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    } else {
      const patientInfo = await consultationDetails(uhid);
      if (patientInfo?.data.length > 0) {
        navigation.push({
          view: ROUTES.newPharmacyBill.name,
          params: patientInfo,
        });
      } else {
        Toast.show({
          message: 'Error',
          description: 'billing.messages.incorrectUHID'.getLabel(),
          type: 'error',
          position: 'top',
          direction: 'right',
        });
      }
    }
  }, [consultationDetails, navigation]);

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          display: 'block',
          textAlign: 'center',
        }}
      >
        <Image source={barcode} />
      </View>
      <View
        style={{
          justifyContent: 'center',
          marginTop: 10,
          flexDirection: 'row',
        }}
      >
        <Text
          style={{
            padding: '6px 12px 6px 12px',
            ...vars.headings.h13,
            color: vars.colors.secondary.color2,
          }}
        >
          {'billing.labels.scanner'.getLabel()}
        </Text>
        <Image source={greenTick} />
      </View>

      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={handleSearchPatientUHID}
      >
        {({ form_context: formContext }) => (
          <View
            style={{
              flex: 1,
              marginTop: 26,
            }}
          >
            <View style={{ width: 312 }}>
              <FormField
                label={'billing.labels.enterUHID'.getLabel()}
                field="uhid"
                placeholder={'billing.placeholders.enterUHID'.getLabel()}
                type="text"
                variant="filled"
                mandatory
                upperCase
                onSubmitEditing={formContext.handleSubmit}
                autoFocus
              />
            </View>
            <View style={{ marginTop: 24, alignItems: 'center' }}>
              <LargeButton
                label={'billing.buttons.search'.getLabel()}
                onClick={formContext.handleSubmit}
                width={109}
              />
            </View>
          </View>
        )}
      </Form>

    </View>
  );
};

const PharmacyTestBill = (props) => (
  <View style={{
    flex: 1, overflow: 'hidden', marginTop: 75, width: 372, alignSelf: 'center',
  }}
  >
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Image
        source={labTestIcon}
        style={{
          objectFit: 'contains',
          height: 40,
          marginTop: 40,
        }}
      />
      <View style={{
        marginLeft: 46,
        marginRight: 46,
        marginTop: 22,
      }}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              ...vars.headings.h5,
              color: vars.neutrals.text1,
              textAlign: 'center',
            }}
          >
            {'billing.title.pharmacyTestBill'.getLabel()}
          </Text>
        </View>
        <View
          style={{
            alignItems: 'center',
            marginTop: 22,
          }}
        >
          <PharmacyTestContent {...props} />
        </View>
      </View>
    </View>
  </View>
);

export default PharmacyTestBill;
