import React from 'react';
import { ToolBar } from '../../../app-components';
import { appHeaderToolBarTheme } from '../../../theme/toolBarTheme';

class HeaderActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.viewUIds = {};
    this.formDataState = {};
  }
  getCurrentActiveFormUid = (uid) => {
    this.viewUIds[this.props.screenName] = uid;
  };

  getCurrentActiveFormData = (data) => {
    this.setState({ [this.props.screenName]: data });
  };

  componentDidMount() {
    const { eventDispatcher, screenName } = this.props;

    eventDispatcher &&
      eventDispatcher.listen(
        `${screenName}_loadData`,
        this.getCurrentActiveFormData,
      );
    eventDispatcher &&
      eventDispatcher.listen(
        `${screenName}_formUid`,
        this.getCurrentActiveFormUid,
      );
  }
  componentDidUpdate(prevProps) {
    const { eventDispatcher, screenName, navigation } = this.props;
    if (prevProps.screenName != this.props.screenName) {
      let activeRoutes = navigation.getActiveRoutes() || [];
      for (let key in this.viewUIds) {
        if (activeRoutes.indexOf(key) === -1) {
          delete this.viewUIds[key];
          eventDispatcher && eventDispatcher.unlisten(`${key}_formUid`);
          eventDispatcher && eventDispatcher.unlisten(`${key}_loadData`);
        }
      }
      eventDispatcher &&
        eventDispatcher.listen(
          `${screenName}_formUid`,
          this.getCurrentActiveFormUid,
        );
      eventDispatcher &&
        eventDispatcher.listen(
          `${screenName}_loadData`,
          this.getCurrentActiveFormData,
        );
    }
  }

  getUid = () => this.viewUIds[this.props.screenName];
  render() {
    const {
      navigation,
      screenState,
      setScreenState,
      view,
      eventDispatcher,
      screenName,
    } = this.props;
    const { headerActions } = view || {};
    if (!headerActions || !headerActions.length) {
      return null;
    }
    return (
      <ToolBar
        key={screenName}
        uid={this.getUid}
        actions={headerActions}
        navigation={navigation}
        screenState={screenState}
        setScreenState={setScreenState}
        eventDispatcher={eventDispatcher}
        data={this.state[this.props.screenName]}
        {...appHeaderToolBarTheme}
      />
    );
  }
}

export default HeaderActions;
