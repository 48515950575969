import React from 'react';

import {
  View, FormField, Image,
} from '../../../../../app-components';
import { Action } from '../../../../../app-components/action/Action';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { startSessionButton } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';

const TokenGenerationForm = ({ form_context: formContext }) => {
  const { handleSubmit } = formContext || {};

  return (
    <View style={{ alignItems: 'center', marginTop: 50, justifyContent: 'space-evenly' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 24,
        }}
      >
        <View>
          <FormField
            {
        ...autoSuggestInput({
          field: 'assistant',
          type: 'autoSuggest',
          variant: 'filled',
          suggestionField: 'fullName',
          model: MODELS.EMPLOYEE,
          query: 'employeeLists',
          placeholder: 'vaccination.placeholders.assistant'.getLabel(),
          searching: true,
          searchField: 'firstName',
        },
        {
          modifyResult: ({ data }) => {
            const newData = data.map((item) => ({
              ...item,
              fullName: `${item.firstName} ${item.lastName}`,
            }));
            return { data: newData };
          },
          addOnFilter: {
            role: { code: 'ASSISTANT' },
          },
        })
      }
          />
        </View>
        <View style={{ marginLeft: 24 }}>
          <FormField
            {
          ...autoSuggestInput({
            field: 'nurse',
            type: 'autoSuggest',
            variant: 'filled',
            suggestionField: 'fullName',
            model: MODELS.EMPLOYEE,
            query: 'employeeLists',
            placeholder: 'vaccination.placeholders.nurse'.getLabel(),
            searching: true,
            searchField: 'firstName',
          },
          {
            modifyResult: ({ data }) => {
              const newData = data.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.lastName}`,
              }));
              return { data: newData };
            },
            addOnFilter: {
              role: { code: 'NURSE' },
            },
          })
        }
          />
        </View>
      </View>
      <View
        style={{
          flex: 0,
          width: 152,
          height: 152,
          marginTop: 40,
        }}
      >
        <Action
          action={{
            type: 'link',
            push: true,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
            onClick={handleSubmit}
          >
            <Image source={startSessionButton} />
          </View>
        </Action>
      </View>
    </View>
  );
};
export default TokenGenerationForm;
