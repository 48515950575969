import React from 'react';
import { View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS } from '../../../../../Lib/constants';
import { EditSupplier } from '../../../../../Queries/supplier';
import vars from '../../../../../theme/vars';
import ViewSupplierTabs from './components/Tabs';

const ViewSupplier = (props) => {

  return (
    <Form
      closeView={1}
      editable={false}
      uri={EditSupplier}
      renderLoading={false}
      reloadOnChangeEvent={`reload${MODELS.PHARMACY_SUPPLIER}`}
      model={MODELS.PHARMACY_SUPPLIER}
      lg={{
        formGroups: [
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            label: 'PURCHASE REQUEST DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'name',
                    label: 'pharmacy.labels.supplierName'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'email',
                    label: 'pharmacy.labels.email'.getLabel(),
                    editable: false,
                  },

                  {
                    type: 'number',
                    field: 'mobile',
                    editable: false,
                    label: 'pharmacy.labels.contact'.getLabel(),
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 10) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'textArea',
                    field: 'address',
                    label: 'pharmacy.labels.address'.getLabel(),
                    width: 830,
                    minHeight: 75,
                    editable: false,
                  },
                ],
              },
            ],
          },

        ],
      }}
      {...props}
    />
  );
};

export default (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1 }}>
      <ViewSupplier {...props} />
    </View>
    <View style={{ flex: 4, overflow: 'hidden' }}>
      <ViewSupplierTabs {...props} showAddButton={false} />
    </View>
  </View>
);
