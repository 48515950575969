import React from 'react';
import moment from 'moment';
import { Snackbar } from '@applane/react-snack-bar';
import { Platform } from '@applane/react-core-components';

const ObjConstructor = {}.constructor;

export const getAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let ageNow = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  const d = today.getDate() - birthDate.getDate();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageNow--;
  } else if (ageNow === 0 && m > 0) {
    ageNow = m;
    return `${ageNow} months`;
  } else if (m === 0 && ageNow === 0) {
    ageNow = d;
    return `${ageNow} days`;
  }
  return `${ageNow} years`;
};

export const endTime = () => {
  const date = new Date();
  return moment(date).endOf('day').toDate();
};
export const timeFormator = ({ item }) => {
  const { _createdOn } = item || {};
  return moment(_createdOn).format('hh:mm A');
};
export const showDate = (date) => {
  date = new Date(date);
  return moment(date).format('DD MMM | hh:mm A');
};

export const getDateRange = (rangeStr = '', rangeOptions = {}) => {
  const { date = new Date() } = rangeOptions;
  let from; let
    to;
  switch (rangeStr) {
    case 'Today':
      from = moment(date).startOf('day').toDate();
      to = moment(date).endOf('day').toDate();
      break;

    case 'Yesterday':
      from = moment(date).subtract(1, 'day').startOf('day').toDate();
      to = moment(date).subtract(1, 'day').endOf('day').toDate();
      break;
    case 'This week':
      from = moment(date).startOf('week').toDate();
      to = moment(date).endOf('week').toDate();
      break;

    case 'Last week':
      from = moment(date).subtract(1, 'weeks').startOf('Week').toDate();
      to = moment(date).subtract(1, 'weeks').endOf('Week').toDate();
      break;
  }
  return { from, to };
};
export const setUtcTime = (time) => {
  time = time.split(':');
  const utc = Number(time[0]) * 60 + Number(time[1]);
  return utc;
};
export const resolveExpFormatter = ({ item, column }) => {
  const { field } = column;
  if (item && item[field]) {
    return item[field];
  }
  return 'N/A';
};

export const dateFormatter = ({ item, format = 'DD MMM YYYY' }) => {
  let { date } = item;
  date = moment(date).format(format);
  return date;
};
export const lastSignalFormatter = ({ item }) => {
  const lastSignalTime = item && item.lastSignalTime;
  let date = '-';
  if (lastSignalTime) {
    let text = moment(lastSignalTime).fromNow();
    text = text.split(' ');
    if (text.length === 4) {
      const time = text[0];
      date = `${time}${text[2].substring(0, 1)} ago`;
    } else if (text[1] === 'hours' || text[1] === 'minutes') {
      date = `${text[0]}${text[1].substring(0, 1)} ago`;
    } else {
      date = `${text[0]} ${text[1]} ago`;
    }
  }
  return date;
};

export const format = ({ dateValue }) => {
  const { data, field, valueField } = dateValue;
  const value = data && field && data[field];
  let date = value && valueField ? value[valueField] : value;
  const todayDate = new Date().getDate();
  const selectedDate = new Date(date).getDate();
  if (todayDate === selectedDate) {
    date = moment(date).format('h:mm A');
    date = `${date} (current time)`;
  } else {
    date = moment(date).format('YYYY-MM-DD h:mm:ss A');
  }
  return date;
};
export const resolveStatus = ({ item }) => {
  let text = 'N/A';
  const {
    status, start_time, plant_in, plant_out, reached_status,
  } = item;
  if (status === 'New') {
    text = '';
  } else if (status === 'Active' && !plant_out) {
    text = 'In Plant';
  } else {
    text = reached_status === 'Delay'
      ? `${reached_status} | ${resolveValue(
        item,
        'delayed_minutes',
        formatHours,
      )}`
      : 'On-Time';
  }

  return text;
};
export const date = (date) => {
  if (!date) {
    return;
  }
  const value = `${(date && moment(date).format('HH:mm A')) || '-'} | ${
    (date && moment(date).format('DD, MMM')) || '-'
  }`;
  return value;
};
export const formatAddress = (fieldValue) => {
  let value = void 0;

  if (fieldValue && (fieldValue.address_levels || fieldValue.description)) {
    const { address_levels, description } = fieldValue;
    if (address_levels && address_levels.locality) {
      value = address_levels.locality;
    } else {
      value = description;
    }
  }

  return value;
};

export const formatHours = (fieldValue) => {
  let day = 0;
  let hour = 0;
  if (fieldValue) {
    day = Math.floor(fieldValue / (60 * 24));
    hour = Math.floor((fieldValue / (60 * 24) - day) * 24);
  }

  let value = '';

  if (day) {
    value = `${day}d`;
  }
  if (hour) {
    value = `${value} ${hour}h`;
  }
  return value;
};

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    const result = [];
    for (let i = 0; i < values.length; i++) {
      const row = values[i];
      const resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (let j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17 */
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
          result.push(resolvedValue);
        }
      }
    }
    return result;
  }
  const value = values[key];
  if (value !== undefined) {
    return value;
  }
  const index = key.indexOf('.');
  if (index === -1) {
    return;
  }
  const firstPart = key.substring(0, index);
  const nextPart = key.substring(index + 1);
  return resolveExp(values[firstPart], nextPart);
};
export const resolveValue = (data, field, display, defaultValue = 'N/A') => {
  let value = data[field];
  if (display && value) {
    if (typeof display === 'function') {
      value = display(value);
    } else {
      value = value[display];
    }
  }
  if (!value) {
    value = defaultValue;
  }
  return value;
};
const getIndianNumberFormat = (value, format, skipRoundOff = true) => {
  let zeroCount = 0;
  const space = format.indexOf(' i') !== -1 ? ' ' : ' ';
  const fragments = format.split('.');

  if (fragments.length === 2) {
    zeroCount = (fragments[1].match(/0/g) || []).length;
  } else if (fragments.length > 2) {
    return 'Invalid Format';
  }

  const crore = 10000000;
  const lakh = 100000;
  const thousand = 1000;

  let abbr;
  const abs = Math.abs(value);

  if (abs >= crore) {
    value /= crore;
    abbr = 'CR';
  } else if (abs < crore && abs >= lakh) {
    value /= lakh;
    abbr = 'L';
  } else if (abs < lakh && abs >= thousand) {
    value /= thousand;
    abbr = 'K';
  } else if (abs < thousand) {
    abbr = '';
  }
  value = value.toFixed(zeroCount);
  if (value % 1 === 0 && skipRoundOff) {
    value = parseInt(value);
  }
  return value + space + abbr;
};

export const numberFormatter = (value, format = '0,0.00', skipRoundOff) => {
  if (value === undefined || value === null || value === '' || !format) {
    return value;
  }
  return getIndianNumberFormat(value, format, skipRoundOff);
};

export const isJSONObject = (obj) => {
  if (
    obj === undefined
    || obj === null
    || obj === true
    || obj === false
    || typeof obj !== 'object'
    || Array.isArray(obj)
  ) {
    return false;
  } if (
    obj.constructor === ObjConstructor
    || obj.constructor === undefined
  ) {
    return true;
  }
  return false;
};

export const getDateFilter = (date) => {
  if (!date) {
    return null;
  }
  const backDate = new Date(date);
  backDate.setUTCHours(0);
  backDate.setUTCMinutes(0);
  backDate.setUTCSeconds(0);
  backDate.setUTCMilliseconds(0);
  backDate.setUTCDate(backDate.getUTCDate());

  const nxtDate = new Date(date);
  nxtDate.setUTCHours(0);
  nxtDate.setUTCMinutes(0);
  nxtDate.setUTCSeconds(0);
  nxtDate.setUTCMilliseconds(0);

  nxtDate.setUTCDate(nxtDate.getUTCDate() + 1);
  const a = { $gte: backDate, $lt: nxtDate };
  return a;
};

export const showError = (error, duration, theme) => {
  let message = '';

  try {
    if (error && error.message) {
      const parsed = JSON.parse(error.message);
      if (parsed && parsed.error && parsed.error.message) {
        message = parsed.error.message;
      } else {
        message = (error && error.message) || error || '';
      }
    } else {
      message = (error && error.message) || error || '';
    }
  } catch (e) {
    message = (error && error.message) || error || '';
  }

  Snackbar.show({
    text: `${message}`,
    duration: duration || Snackbar.LENGTH_SHORT,
    theme,
  });
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    }
    return Component(props);
  }
  return Component;
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web'
    || navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

export const isMobile = detectMob();
