/* eslint-disable max-len */
import React from 'react';

import {
  Image,
  Text,
  TouchableOpacity,
} from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { editRecord } from '../../../../../images';
import WithPermission from '../../../../../Components/WithPermission';
import { expiryStatusOptions, MODELS, ROUTES } from '../../../../../Lib/constants';
import { calculateRemainingSelfLife } from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';
import permissions from '../../../../../Lib/permissions';

const tabListColumns = ({ defaultValues }) => ([
  { width: 14 },
  {
    header: 'inventory.headers.batchNo'.getLabel(),
    type: 'text',
    width: 120,
    field: 'batchNumber',
  },
  {
    header: 'inventory.headers.supplyReceivedOn'.getLabel(),
    type: 'date',
    format: 'DD/MM/YYYY',
    width: 120,
    field: 'supplyReceivedOn',
  },
  {
    header: 'inventory.headers.stockEntryDate'.getLabel(),
    type: 'date',
    format: 'DD/MM/YYYY',
    width: 120,
    field: 'stockEntryDate',
  },
  {
    header: 'Manufacturer',
    type: 'text',
    field: 'manufacturer.name',
    width: 130,
  },
  {
    header: 'inventory.headers.manufacturingDate'.getLabel(),
    type: 'date',
    format: 'DD/MM/YYYY',
    width: 130,
    field: 'manufacturingDate',
  },
  {
    header: 'inventory.headers.expiryDate'.getLabel(),
    type: 'date',
    format: 'DD/MM/YYYY',
    width: 120,
    field: 'expiryDate',
  },
  {
    header: 'inventory.headers.remainingSelfLife'.getLabel(),
    type: 'number',
    width: 120,
    value: ({ item }) => {
      let remainingSelfLife = calculateRemainingSelfLife(item?.expiryDate);
      if (remainingSelfLife < 0) {
        remainingSelfLife = 0;
      }
      return remainingSelfLife;
    },
  },
  {
    header: 'inventory.headers.stockAvailable'.getLabel(),
    type: 'text',
    width: 120,
    field: 'quantityAvailable',
  },
  {
    header: 'inventory.headers.quantityReceived'.getLabel(),
    type: 'text',
    width: 120,
    field: 'quantityReceived',
  },
  {
    header: 'inventory.headers.purchasedPrice'.getLabel(),
    type: 'text',
    width: 120,
    field: 'purchasedPrice',
  },
  {
    header: 'inventory.headers.active'.getLabel(),
    type: 'status',
    width: 120,
    field: 'isActive',
  },
  {
    header: 'inventory.headers.stockStatus'.getLabel(),
    type: 'text',
    width: 120,
    render: ({ item: { item: { reorderLevel } = {}, quantityAvailable } = {} }) => {
      let stockStatus = '';
      let statusColor = '';
      if (quantityAvailable <= reorderLevel) {
        stockStatus = expiryStatusOptions.critical;
        statusColor = vars.colors.error;
      } else {
        stockStatus = expiryStatusOptions.optimal;
        statusColor = vars.colors.primary.color2;
      }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {stockStatus}
        </Text>
      );
    },
  },
  {
    header: 'inventory.headers.expiryStatus'.getLabel(),
    type: 'text',
    width: 120,
    render: ({ item }) => {
      const expiryDays = calculateRemainingSelfLife(item?.expiryDate);
      let expiryStatus = expiryStatusOptions.active;
      let statusColor = vars.colors.primary.color2;
      if (expiryDays === 0 || expiryDays < 0) {
        expiryStatus = expiryStatusOptions.expired;
        statusColor = vars.colors.error;
      } else if (expiryDays > 0 && expiryDays <= 60) {
        expiryStatus = expiryStatusOptions.nearToExpiry;
        statusColor = vars.colors.warning;
      } else if (expiryDays > 60 && expiryDays < 90) {
        expiryStatus = expiryStatusOptions.nearToExpiry;
        statusColor = vars.colors.emphasis;
      }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {expiryStatus}
        </Text>
      );
    },
  },
  {
    header: 'Actions',
    type: 'text',
    render: () => (
      <WithPermission
        access_key={permissions.inventory.views.viewPharmacyCentralInventory.permissions.viewPharmacyCentralInventory.value}
      >
        <TouchableOpacity>
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        </TouchableOpacity>
      </WithPermission>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editBatch.name,
        modal: true,
        modalProps: {
          autoHide: true,
          width: 1178,
          height: 547,
        },
        params: {
          item,
          defaultValues,
          remainingSelfLife:
          calculateRemainingSelfLife(item?.expiryDate) > 0
            ? calculateRemainingSelfLife(item?.expiryDate) : 0,
        },
      },
    }),
  },
]);

const BatchList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    defaultValues,
    ...restProps
  } = props;
  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      hoverable={false}
      reloadEvent={`reload${MODELS.PHARMACY_BATCH}`}
      lg={{ columns: tableColumns({ defaultValues }) }}
      {...restProps}
    />
  );
};
export default BatchList;
