import React from 'react';

import { Image, Text, View } from '../../../../../app-components';
import {
  notificationError, notificationTick, noStatus,
} from '../../../../../images';
import vars from '../../../../../theme/vars';

const statusWiseData = {
  completed: {
    icon: notificationTick,
    label: 'Completed',
  },
  'not-appeared': {
    icon: notificationError,
    label: 'Not Appeared',
  },
  assigned: {
    icon: noStatus,
    label: 'No Status',
  },
};

const Status = (props) => {
  const { item: { status, _id } = {}, patientDetails } = props;

  if ((status !== 'completed' && status !== 'not-appeared' && status !== 'assigned')) {
    return <View />;
  }

  return _id !== patientDetails?._id && (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Image
        source={statusWiseData[status]?.icon}
        style={{
          width: 20,
          height: 20,
        }}
      />
      <Text
        style={[
          vars.headings.h9,
          { marginLeft: 10, color: vars.colors.grey.color1 },
        ]}
      >
        {statusWiseData[status]?.label}
      </Text>
    </View>
  );
};

export default Status;
