import React from 'react';
import { FormField, StandardButton, View } from '../../../../app-components';
import { notificationTickWithBG } from '../../../../images';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import vars from '../../../../theme/vars';
import { submit } from '../../../../AppServices';
import { MODELS } from '../../../../Lib/constants';

const EditBedModal = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  return (
    <ListActionModal
      icon={notificationTickWithBG}
      title={'Edit Bed'.getLabel()}
      description={'Edit Bed of Particular Room'.getLabel()}
      content={(
        <Form
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          // mandatory={{
          //   name: 1,
          // }}
          reloadEvent={`reload${MODELS.BED}`}
          onSubmit={submit({
            model: MODELS.BED,
          })}
          submitMessage={'pharmacy.messages.subCategoryUpdatedSuccessfully'.getLabel()}
          defaultValues={() => ({
            ...item,
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View style={{
              paddingBottom: 24, paddingTop: 24, paddingLeft: 22, paddingRight: 22,
            }}
            >
              <FormField
                type="text"
                label="Bed"
                variant="filled"
                field="name"
                style={{
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                allowWhiteSpaces
                mandatory
              />
              <View style={{ marginTop: 16 }}>
                <FormField
                  type="toggleSwitch"
                  label="Status"
                  container="topLabel"
                  variant="filled"
                  field="isActive"
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'pharmacy.buttons.update'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default EditBedModal;
