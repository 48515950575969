import { tabActionStyle, tabGroupItemsStyle } from '../../../../theme/tabBarTheme';
import vars from '../../../../theme/vars';

const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 60,
    },
    tabRowStyle: {
      flex: 1,
      height: 70,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      marginTop: 14,
      marginBottom: 16,
      marginLeft: 30,
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      minWidth: 130,
      height: 30,
    },
    activeContainerStyle: {
      borderRadius: 4,
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 10,
      backgroundColor: vars.colors.primary.color2,
      position: 'absolute',
      bottom: 0,
    },
    itemContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...vars.headings.h9,
      color: vars.colors.grey.color3,
    },
    activeTextStyle: {
      ...vars.headings.h7,
      color: vars.colors.grey.color1,
    },
    countStyle: {
      marginLeft: 3,
      ...vars.headings.h13,
      color: vars.colors.grey.color3,
    },
    activeCountStyle: {
      ...vars.headings.h13,
      color: vars.colors.white,
    },
  },
  tabActionStyle: {
    ...tabActionStyle,
  },
  tabGroupItemsStyle: {
    ...tabGroupItemsStyle,
  },
};

export default tabTheme;
