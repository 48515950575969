import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { Form } from '../../../app-components/form/Form';
import { EditDiseaseUri } from '../../../Queries/disease';

const ViewDisease = (props) => (
  <Form
    {...props}
    uri={EditDiseaseUri}
    editable={false}
    type="standard"
    closeView={1}
    reloadOnChangeEvent={`reload${MODELS.DISEASE}`}
    uniqueValidationMessage={'disease.message.diseaseAlreadyExists'.getLabel()}
    submitMessage={'disease.message.diseaseUpdatedSuccessfully'.getLabel()}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'disease.labels.diseaseName'.getLabel(),
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'disease.labels.description'.getLabel(),
                  minHeight: 100,
                },
                {
                  type: 'status',
                  field: 'isActive',
                  label: 'disease.labels.status'.getLabel(),
                  container: 'topLabel',
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default ViewDisease;
