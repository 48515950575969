import React from 'react';
import uuid from 'uuid/v4';

import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { Form } from '../../../../app-components/form/Form';
import { Text, View } from '../../../../app-components';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';

const LogAcpMatrix = (props) => (
  <Form
    {...props}
    closeView={1}
    reloadEvent={`reload${MODELS.ACP_PAY_MATRIX}`}
    mandatory={{
      gradePayLevel: 1,
    }}
    defaultValues={() => ({
      levels: new Array(40)
        .fill()
        .map((item, index) => ({ _id: `new_${uuid()}`, entry_level: index + 1 })),
    })}
    onSubmit={submit({
      model: MODELS.ACP_PAY_MATRIX,
    })}
    submitMessage="Acp Pay Matrix had been saved successfully."
    type="standard"
    lg={{
      verticalFormGroups: [
        {

          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'gradePayLevel',
                      label: 'Grade Pay Level',
                      variant: 'filled',
                      mandatory: true,
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      model: MODELS.GRADE_PAY_LEVEL,
                      query: 'gradePay',
                    }),
                  ],
                },

              ],
            },
            {
              fieldVariant: 'filled',
              label: 'Levels',
              labelStyle: {
                ...vars.headings.h10,
                color: vars.colors.grey.color2,
              },
              groupsContainerStyle: { paddingLeft: 0 },
              groups: [
                {
                  columns: [
                    {

                      field: 'levels',
                      fieldType: 'nested',
                      addInBottom: true,
                      Component: NestedTable,
                      componentProps: {
                        skipTableHeaderOnNoData: true,
                        columnsPerRow: 2,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 20,
                            paddingLeft: 12,
                            paddingRight: 4,
                          },
                          columns: [
                            {
                              type: 'number',
                              variant: 'filled',
                              field: 'entry_level',
                              header: 'Entry Level',
                              label: 'Entry Level',
                              editable: false,
                              width: 100,
                              style: {
                                marginTop: 16,
                              },
                            },
                            {
                              type: 'number',
                              variant: 'filled',
                              field: 'amount',
                              header: 'Amount',
                              label: 'Amount',
                              width: 450,
                              editable: true,
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);

export default LogAcpMatrix;
