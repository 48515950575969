import React from 'react';
import { Text, View } from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { MODELS, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import { PharmacyPurchaseEntityRequestUri } from '../../../../../../Queries/pharmacy';
import vars from '../../../../../../theme/vars';

const ViewPurchaseRequest = (props) => {
  const {
    navigation: {
      state: {
        params: { item: purchaseRequest, queryId } = {},
      } = {},
    } = {},
  } = props;

  return (
    <Form
      type="standard"
      closeView={1}
      reloadOnChangeEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      editable={false}
      uri={PharmacyPurchaseEntityRequestUri({ filter: { type: 'pharmacy', _id: purchaseRequest._id }, queryId })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'inventory.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.purchaseRequestNumber'.getLabel(),
                    field: 'purchaseRequestPharmacy.purchaseRequestNumber',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.status'.getLabel(),
                    field: 'purchaseRequestPharmacy.requestStatus',
                    render: ({ data: { status = '' } = {} }) => {
                      let purchaseRequestStatus = '';
                      let statusColor = '';
                      const dotStyle = {
                        height: 8,
                        width: 8,
                        borderRadius: 4,
                        backgroundColor: vars.colors.warning,
                        marginLeft: 8,
                      };
                      if (status === purchaseSupplyOrderStatus.RAISED) {
                        purchaseRequestStatus = 'Raised';
                        statusColor = vars.colors.emphasis;
                      } else if (status === purchaseSupplyOrderStatus.STOCK_PROVIDED) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_PROVIDED;
                        statusColor = vars.colors.primary.color2;
                      } else if (status === purchaseSupplyOrderStatus.PO_IN_APPROVAL) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PO_IN_APPROVAL;
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus.PO_APPROVED) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PO_APPROVED;
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus.SO_IN_APPROVAL) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.SO_IN_APPROVAL;
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PAYMENT_PENDING;
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PARTIALLY_RECEIVED;
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING;
                        statusColor = vars.colors.warning;
                      } else if (status
                          === purchaseSupplyOrderStatus.LOW_SHELF_LIFE_REQUEST_RAISED) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus
                          .LOW_SHELF_LIFE_REQUEST_RAISED;
                        statusColor = vars.colors.warning;
                      } else {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.Rejected;
                        statusColor = vars.colors.error;
                      }
                      return (
                        <View style={{
                          flex: 1,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        >
                          <Text style={{ ...vars.headings.h8 }}>
                            {purchaseRequestStatus}
                          </Text>
                          <View style={{ ...dotStyle, backgroundColor: statusColor }} />
                        </View>
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.title.purchaseRequestDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'number',
                    label: 'inventory.labels.requiredQuantity'.getLabel(),
                    field: 'purchaseRequestPharmacy.requiredQuantity',
                  },
                  {
                    type: 'textArea',
                    label: 'inventory.labels.reasonForPurchase'.getLabel(),
                    field: 'purchaseRequestPharmacy.reasonForPurchase',
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.item.itemCode',
                    label: 'inventory.labels.itemId'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.item.name',
                    label: 'inventory.labels.itemName'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.item.category.name',
                    label: 'inventory.labels.category'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.item.sellingPrice',
                    label: 'inventory.labels.unitPrice'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.item.avgMonthlyConsumption',
                    label: 'inventory.labels.avgConsumption'.getLabel(),
                  },
                  {
                    type: 'date',
                    label: 'inventory.labels.lastPurchaseDate'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'purchaseRequestPharmacy.lastPurchaseDate',
                  },
                  {
                    type: 'number',
                    field: 'purchaseRequestPharmacy.lastPurchaseQuantity',
                    label: 'inventory.labels.lastPurchaseQuantity'.getLabel(),
                  },
                  {
                    type: 'number',
                    field: 'purchaseRequestPharmacy.item.stockAvailable.stockAvailable',
                    label: 'inventory.labels.stockAvailable'.getLabel(),
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.manufacturer'.getLabel(),
                    field: 'purchaseRequestPharmacy.manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.supplier'.getLabel(),
                    field: 'purchaseRequestPharmacy.supplier.name',
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: '_id',
                    id: queryId || 'purchaseRequestPharmacyList',
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPurchaseRequest;
