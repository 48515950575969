import React from 'react';
import moment from 'moment';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';

const ViewReferencesIPDForm = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      title="Reference"
      description="Logged reference for the patient."
      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        render: () => moment(item?.referenceDate).format('DD/MM/YYYY'),
                        label: 'Date',
                        format: 'DD/MM/YYYY',
                      },
                      {
                        type: 'text',
                        render: () => item?.department?.name || 'N/A',
                        label: 'Department',
                      },
                      {
                        type: 'text',
                        render: () => item?.doctorName?.name || 'N/A',
                        label: 'Doctor',
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        render: () => item?.advice || 'N/A',
                        label: 'Advice From Department',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};
export default ViewReferencesIPDForm;
