import React, { useCallback, useEffect, useState } from 'react';

import {
  View, LargeButton, OutlineButton, Toast, ActivityIndicator, Text,
} from '../../../../../app-components';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { getErrorString } from '../../../../../Lib/helpers';
import { notificationError } from '../../../../../images';
import { fetch, post } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const EndSessionModalContent = (props) => {
  const { navigation, provided } = props;
  const { navigation: { state: { params: { activeSession } = {} } } } = props;
  const { _id } = activeSession;
  const updateSession = useCallback(async () => {
    try {
      const sessionDetails = await post({
        data: { _id },
        updates: { isActive: false, endDate: new Date() },
        model: MODELS.VACCINATION_SESSION,
      });
      Toast.show({
        message: 'Success',
        description: 'vaccination.messages.sessionEndSuccessfully'.getLabel(),
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      navigation.replace({
        view: ROUTES.vaccinationTokenGeneration.name,
      });
      return sessionDetails;
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });

      return err;
    }
  }, [_id, navigation]);

  return (
    <View style={{ flex: 1 }}>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={provided ? () => {
          Toast.show({
            message: 'Error',
            description: 'Mark all patients vaccinated who have been provided vaccination.',
            type: 'error',
            position: 'top',
            direction: 'right',
            duration: 3000,
          });
        } : updateSession}
        defaultValues={{
          isActive: false,
        }}
      >
        {({ form_context: formContext }) => (
          <View style={{ flexDirection: 'row', marginTop: 38, justifyContent: 'center' }}>
            <View style={{
              flexDirection: 'row', justifyContent: 'space-around', width: 300,
            }}
            >
              <View>
                <LargeButton
                  label="Cancel"
                  width={109}
                  onClick={() => {
                    navigation.pop();
                  }}
                />

              </View>
              <View>
                <OutlineButton
                  label="Yes"
                  width={109}
                  onClick={formContext?.handleSubmit}
                />
              </View>
            </View>
          </View>
        )}
      </Form>
    </View>
  );
};

const EndSessionModal = (props) => {
  const { navigation } = props;
  const { state: { params: { activeSession } } } = navigation;
  const { _id } = activeSession;
  const [loading, setLoading] = useState();
  const [providedCount, setProvidedCount] = useState(0);
  const [queuedCount, setQueuedCount] = useState(0);

  const fetchActiveConsultationsCount = useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'patientVaccinationList',
              addOnFilter: {
                session: { _id },
                status: { $ne: 'vaccinated' },
              },
            },
            model: MODELS.VACCINATION,
            limit: 1,
          },
        },
      });
      if (result) {
        let provided = 0;
        let queued = 0;
        result.data.map((item) => {
          if (item.status === 'provided') {
            provided += 1;
          } else {
            queued += 1;
          }
          return item;
        });
        setProvidedCount(provided);
        setQueuedCount(queued);
      }
      setLoading(false);
      return result;
    } catch (err) {
      setLoading(false);
      return err;
    }
  }, [_id]);

  useEffect(() => {
    fetchActiveConsultationsCount();
  }, []);

  if (loading) return <ActivityIndicator />;

  const renderDescription = () => (
    <Text style={{
      display: 'block',
      ...vars.headings.h11,
      color: vars.neutrals.placeholder,
      textAlign: 'center',
    }}
    >
      {'vaccination.title.endSessionSubWarning'.getLabel()}
      <span style={{ color: vars.colors.secondary.color2 }}>
        {queuedCount || 0}
      </span>
      {(queuedCount > 1) ? 'vaccination.title.endSessionWarning'.getLabel() : 'vaccination.title.endSessionCountWarning'.getLabel()}
      <span style={{ color: vars.colors.secondary.color2 }}>
        {providedCount || 0}
      </span>
      {(providedCount > 1) ? 'vaccination.title.endSessionWarningNotVaccinated'.getLabel() : 'vaccination.title.endSessionCountWarningNotVaccinated'.getLabel()}
    </Text>
  );
  return (
    <ListActionModal
      icon={notificationError}
      title={'vaccination.title.endSession'.getLabel()}
      content={<EndSessionModalContent {...props} provided={providedCount} />}
      renderDescription={renderDescription}
      {...props}
    />
  );
};
export default EndSessionModal;
