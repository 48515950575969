import React from 'react';
import { Table, Text } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
// import { PharmacyIndentRequestsListUri } from '../../../../../../../Queries/pharmacy';
import vars from '../../../../../../../theme/vars';
import patientListColumns from '../components/PatientListColumns';

const tableItems = {
  lg: {
    columns: [
      ...patientListColumns,
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewIPDPatientDispensingRecords.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.PHARMACY_ITEM}`,
  // uri: PharmacyIndentRequestsListUri,
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewIPDPatientDispensingRecords.name,
      params: { item },
    },
  }),
});
