import { cancelButton, save } from '../../../app-components';

import MasterForm from '../../../Wired/Master/MasterForm';
import { EditState } from '../../../Queries/state';
import { MODELS } from '../../../Lib/constants';

const header = {
  title: 'Edit State',
  actions: [cancelButton(), save()],
};

export default MasterForm({
  uri: EditState,
  header,
  model: MODELS.STATE,
});
