import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { assetRequestsStatus, MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { submit } from '../../../../../AppServices';
import { EditAssetIndentRequestStatus } from '../../../../../Queries/asset';
import { Form } from '../../../../../app-components/form/Form';

const ViewIndentRequest = (props) => {
  const [formData, setData] = useState(true);

  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      reloadEvent={`reload${MODELS.ASSET_INDENT}`}
      uri={EditAssetIndentRequestStatus({ ...props, callback: setData })}
      onSubmit={submit({
        model: MODELS.ASSET_INDENT,
      })}
      submitMessage={'asset.messages.indentUpdateSuccessfully'.getLabel()}
      mandatory={{
        indentDetails: {
          providedQuantity: 1,
        },
      }}
      validations={{
        indentDetails: {
          // eslint-disable-next-line consistent-return
          providedQuantity: ({ data }) => {
            if (data?.requiredQuantity < data?.providedQuantity) {
              return 'Provided Quantity can not be more than Required Quantity';
            }
          },
        },
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.indentNumber'.getLabel(),
                    field: 'indentNumber',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.department'.getLabel(),
                    field: 'department.name',
                    editable: false,
                  },
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'requestStatus',
                    label: 'asset.labels.status'.getLabel(),
                    options: assetRequestsStatus,
                    keyField: 'value',
                    valueField: 'label',
                    suggestionField: 'label',
                    editable: formData.requestStatus === 'raised',
                  }),
                  {
                    type: 'textArea',
                    label: 'asset.labels.reasonForRejection'.getLabel(),
                    field: 'reasonForRejection',
                    style: { flex: 2 },
                    visible: ({ data }) => data?.requestStatus === 'rejected',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.indentDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'indentDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          paddingLeft: 12,
                          paddingRight: 4,
                          // backgroundColor: vars.colors.white,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 2,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'asset.labels.assetName'.getLabel(),
                            label: 'asset.labels.assetName'.getLabel(),
                            variant: 'filled',
                            field: 'item.name',
                            editable: false,
                            width: 120,
                          },
                          {
                            field: 'item.category.name',
                            variant: 'filled',
                            header: 'asset.labels.category'.getLabel(),
                            label: 'asset.labels.category'.getLabel(),
                            editable: false,
                            width: 120,
                          },
                          {
                            field: 'item.subCategory.name',
                            variant: 'filled',
                            header: 'asset.labels.subCategory'.getLabel(),
                            label: 'asset.labels.subCategory'.getLabel(),
                            editable: false,
                            width: 120,
                          },
                          {
                            type: 'text',
                            header: 'asset.labels.stockAvailable'.getLabel(),
                            label: 'asset.labels.stockAvailable'.getLabel(),
                            value: ({ item }) => item.item.stockAvailable[0].stockAvailable,
                            variant: 'filled',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'text',
                            header: 'asset.labels.reasonForPurchase'.getLabel(),
                            label: 'asset.labels.reasonForPurchase'.getLabel(),
                            variant: 'filled',
                            field: 'reasonForPurchase',
                            editable: false,
                            width: 180,
                          },
                          {
                            type: 'number',
                            header: 'asset.labels.requiredQuantity'.getLabel(),
                            label: 'asset.labels.requiredQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'requiredQuantity',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'asset.labels.providedQuantity'.getLabel(),
                            label: 'asset.labels.providedQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'providedQuantity',
                            editable: formData.requestStatus === 'raised',
                            mandatory: true,
                            width: 150,
                          },
                          {
                            type: 'date',
                            header: 'asset.headers.lastLoanOutDate'.getLabel(),
                            label: 'asset.labels.lastLoanOutDate'.getLabel(),
                            variant: 'filled',
                            format: 'DD MMM YYYY',
                            field: 'lastIndentDate',
                            editable: false,
                            width: 180,
                          },
                          {
                            type: 'text',
                            header: 'asset.headers.lastLoanOutQuantity'.getLabel(),
                            label: 'asset.labels.lastLoanOutQuantity'.getLabel(),
                            field: 'lastIndentQuantity',
                            variant: 'filled',
                            editable: false,
                            width: 200,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewIndentRequest;
