import React from 'react';

import { DateFilter, TabNavigator } from '../../../../../app-components';
import vars from '../../../../../theme/vars';
import OtherRecordsTabs from './OtherRequest/index';
import IndentsList from './components/IndentsList';
import tabTheme from '../../Themes/tabTheme';

const DepartmentRecordsTabs = TabNavigator({
  theme: { ...tabTheme },
  actions: [
    <DateFilter
      field="_createdOn"
      key="_createdOn"
      containerStyle={
        {
          flexDirection: 'row',
          width: 303,
          height: 36,
          borderWidth: 1,
          padding: 9,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }
      }
    />,
  ],
  tabs: {
    indents: {
      label: 'Indent Request',
      screen: IndentsList,
    },
    Other: {
      label: 'Others',
      screen: OtherRecordsTabs,
    },
  },
});
export default DepartmentRecordsTabs;
