import React from 'react';
import { ToggleFilter } from '../../../../app-components';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import permissions from '../../../../Lib/permissions';
import MasterList from '../../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 14 },
  {
    header: 'companyManagement.header.companyName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'companyManagement.header.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'companyManagement.header.address'.getLabel(),
    type: 'text',
    field: 'address',
  },
  {
    header: 'companyManagement.header.email'.getLabel(),
    type: 'text',
    field: 'email',
    sortable: 'email',
  },
  {
    header: 'companyManagement.header.contactNo'.getLabel(),
    type: 'number',
    field: 'contactNo',
    width: 250,
  },
  {
    header: 'companyManagement.header.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.COMPANY_MANAGEMENT}
    editFormId={ROUTES.editCompany.name}
    reloadEvent={`reload${MODELS.COMPANY_MANAGEMENT}`}
    tableColumns={tableColumns}
    access_key={permissions.inventory.views.editCompany.permissions.editCompany.value}
    header={{
      leftActions: [
        (props) => (
          <ToggleFilter
            {...props}
            model={MODELS.COMPANY_MANAGEMENT}
            id="masterDataListForTable"
          />
        ),
      ],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewCompany.name,
        params: { item },
      },
    })}
  />
);
