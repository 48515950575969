
import { MODELS } from '../Lib/constants';

export const viewPsychologicalTreatmentsUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_PSYCHOLOGICAL_TREATMENT,
    id: 'pschologicalTreatmentList',
    addOnFilter: {
      ipdPatient: { _id: navigation?.state?.params?.patient?._id },
    },
  },
  aggregate: true,
  model: MODELS.IPD_PSYCHOLOGICAL_TREATMENT,
});

export const psychologicalTreatmentListUri = ({ navigation }) => {
  const { state: { params: { addOnFilter = {} } } } = navigation;
  return ({
    query: {
      model: MODELS.IPD_PSYCHOLOGICAL_TREATMENT,
      id: 'pschologicalTreatmentList',
      addOnFilter,
    },
    model: MODELS.IPD_PSYCHOLOGICAL_TREATMENT,
  });
};
