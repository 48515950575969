import React, { useState } from 'react';
import { View } from '../../../../app-components';
import ListActionModal from '../../../../Components/ListActionModal';
import FTS from '../../../../Components/TableFilters/FtsSearch';
import { history } from '../../../../images';
import { MODELS } from '../../../../Lib/constants';
import VaccinationHistoryLogs from './viewVaccinationHistoryLogsTable';

const VaccinationHistoryLogsTableModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item,
        } = {},
      } = {},
    } = {},
  } = props;
  const {
    navigation: {
      getScreenState,
    },
  } = props;
  const [screenState, setScreenState] = useState(getScreenState());

  return (
    <ListActionModal
      icon={history}
      title="Vaccination Logs"
      description={`List of Vaccination of ${item?.patient?.patientName}`}
      content={(
        <>
          <View style={{ alignItems: 'center', marginBottom: 8 }}>
            <View>
              <FTS {...props} screenState={screenState} setScreenState={setScreenState} action={{ exp: ['item.name'] }} />
            </View>
          </View>
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <VaccinationHistoryLogs
              {...props}
              addOnFilter={{ patient: { _id: item?.patient?._id } }}
              model={MODELS.PATIENT_VACCINATION_HISTORY}
              dataParams={screenState?.dataParams}
              id="patientVaccinationHistory"
            />
          </View>
        </>
        )}
      {...props}
    />
  );
};

export default VaccinationHistoryLogsTableModal;
