import React, { useCallback, useEffect, useState } from 'react';
import { invoke } from '../../../../../AppServices';
import { FormField, Toast } from '../../../../../app-components';
import { getErrorString } from '../../../../../Lib/helpers';

const UploadInputOnChange = (props) => {
  const {
    patientDetail, consultationId, prescription,
  } = props;

  const [value, setValue] = useState(prescription);

  useEffect(() => {
    setValue(prescription);
  }, [consultationId]);

  const handleChange = useCallback((input) => {
    setValue(input);
  }, []);

  const handleBlur = useCallback(async () => {
    try {
      await invoke({
        id: 'updatePrescription',
        paramValue: {
          prescription: value,
          item: patientDetail,
          consultationId,
        },
      });
    } catch (err) {
      setValue(null);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [consultationId, patientDetail, value]);

  return (
    <FormField
      value={value}
      placeholder="Prescription"
      label="Prescription"
      variant="filled"
      name="Prescription"
      type="file"
      maxWidth={150}
      onBlur={handleBlur}
      onChangeValue={handleChange}
    />
  );
};

export default UploadInputOnChange;
