import { MODELS } from '../Lib/constants';

export const AddOperationNotes = ({ navigation }) => ({
  query: {
    model: 'operation_procedure_notes',
    id: 'operationProcedureNotesIPDDataList',
    relationValue: {
      model: 'operation_procedure_notes',
      _id: navigation.state.params.item._id,
    },
    limit: 1,
  },
  model: 'operation_procedure_notes',
});

export const GetOperationNotesUri = (filter = {}) => ({
  query: {
    model: MODELS.OPERATION_PROCEDURE_NOTES,
    id: 'operationProcedureNotesIPDDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.OPERATION_PROCEDURE_NOTES,
});

export const editIpdNotesUri = ({ navigation }) => {
  const { state: { params: { item: { _id } } } } = navigation;
  return ({
    query: {
      model: MODELS.OPERATION_PROCEDURE_NOTES,
      id: 'operationProcedureNotesIPDDataList',
      addOnFilter: { _id },
    },
    model: MODELS.OPERATION_PROCEDURE_NOTES,
  });
};

export const editOperationMasterURI = ({ navigation }) => ({
  query: {
    model: MODELS.OPERATIONS,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.OPERATIONS,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
  },
  model: MODELS.OPERATIONS,
});
