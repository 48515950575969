import React from 'react';
import { ROUTES } from '../../../../../../Lib/constants';
import { ScreenComponent } from '../../../../../../app-components/screen/ScreenComponent';

import StackNavigator from '../../../../../../app-components/stack/StackNavigator';
import ViewInputOutputVitals from './VitalSignSheet/InputOutput/ViewInputOutput';
import ViewBloodSugarVitals from './VitalSignSheet/BloodSugar/ViewBloodSugar';
import LogInputOutput from './VitalSignSheet/InputOutput/LogInputOutput';
import AddBloodSugar from './VitalSignSheet/BloodSugar/AddBloodSugar';
import ViewProgressDetail from './ViewProgressReport';
import AddPatientOTListForm from './OTList/AddPatients';
import ViewVitals from './VitalSignSheet/ViewVitals';
import AddVitals from './VitalSignSheet/AddVitals';
import AddProgressReport from './AddProgressReport';
import ViewDetailsForm from './OTList/ViewDetails';
import NewOtList from './OTList/NewOTList';
import OtList from './OTList';

const GeneralMedicineOtListStackNavigator = StackNavigator(
  {
    [ROUTES.viewOTListScreen.name]: {
      screen: OtList,
      expanded: true,
    },
    [ROUTES.viewOTList.name]: {
      screen: ViewDetailsForm,
      modal: true,
      modalProps: {
        autoHide: true,
        width: '732px',
        height: '570px',
      },
    },
    [ROUTES.createNewOTList.name]: {
      screen: NewOtList,
      expanded: true,
    },
    [ROUTES.addPatientToOTList.name]: {
      screen: AddPatientOTListForm,
      modal: true,
      modalProps: {
        autoHide: true,
        maxHeight: '100%',
        width: 'auto',
        height: 'auto',
      },
    },
  },
  {
    ScreenComponent,
    navigationKey: 'main',
  },
);
export const generalMedicineOtListScreens = (props) => <GeneralMedicineOtListStackNavigator {...props} navigationOptions={{ dataParams: props?.dataParams }} />;

export const generalMedicineScreens = {
  [ROUTES.addProgressReportGeneralMedicine.name]: {
    screen: AddProgressReport,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.viewProgressReportGeneralMedicine.name]: {
    screen: ViewProgressDetail,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.logInputOutput.name]: {
    screen: LogInputOutput,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.addBloodSugar.name]: {
    screen: AddBloodSugar,
    expanded: true,
    modal: true,
    modalProps: {
      width: 1000,
    },
  },
  [ROUTES.ViewInputOutputVitals.name]: {
    screen: ViewInputOutputVitals,
    expanded: true,
    modal: true,
    modalProps: {
      width: 1000,
    },
  },
  [ROUTES.ViewBloodSugarVitals.name]: {
    screen: ViewBloodSugarVitals,
    expanded: true,
    modal: true,
    modalProps: {
      width: 1000,
    },
  },
  [ROUTES.addVitalsGeneralMedicine.name]: {
    screen: AddVitals,
    expanded: true,
  },
  [ROUTES.ViewVitalsGeneralMedicine.name]: {
    screen: ViewVitals,
    expanded: true,
  },
};
const ipdGeneralMedicineScreen = {
  generalMedicineOtListScreens,
  ...generalMedicineScreens,
};

export default ipdGeneralMedicineScreen;
