import { Form } from '../../../../app-components';
import { salaryHeartIcon } from '../../../../images';
import { CompensationFormLayouts } from './Commonlayouts';

export const EmployeeCompensationHoc = ({
  basicDetails,
  earningsNestedField,
  deductionsNestedField,
  ...rest
}) => Form({
  fieldVariant: 'filled',
  ...rest,
  formGroups: [
    {
      label: 'BASIC DETAILS',
      icon: salaryHeartIcon,
      groups: [basicDetails],
    },
    {
      icon: salaryHeartIcon,
      expandable: true,
      label: 'EARNINGS',
      columns: earningsNestedField,
    },
    {
      fieldVariant: 'filled',
      icon: salaryHeartIcon,
      expandable: true,
      label: 'DEDUCTION',
      columns: deductionsNestedField || [CompensationFormLayouts.DeductionComponent],
    },
  ],
});
