import { upperFirst } from 'lodash';
import React from 'react';

import { Text, View } from '../../../../app-components';
import { Action } from '../../../../app-components/action/Action';
import { Table } from '../../../../app-components/table/Table';
import {
  purchaseSupplyOrderStatus,
  Roles,
  ROUTES,
} from '../../../../Lib/constants';
import { supplyOrderRequest } from '../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../theme/vars';

const tabListColumns = (props) => [
  { type: 'selection' },
  {
    header: 'Creation Date',
    type: 'date',
    field: 'SOCreatedOn',
    format: 'DD/MM/YY',
  },
  {
    header: 'SO Number',
    type: 'text',
    field: 'uniqueNumber',
  },
  {
    header: 'Department',
    type: 'text',
    value: ({ item }) => upperFirst(item?.inventoryType),
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    render: ({ item }) => {
      const { currentApprovalQueue } = item;
      const assignedToRole = currentApprovalQueue?.assignedToRoleKey === 7
        ? 'HOD'
        : currentApprovalQueue?.assignedToRole;

      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.PENDING) {
        return (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.warning,
            }}
          >
            {`SO in Approval (By ${assignedToRole})`}
          </Text>
        );
      }
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.REJECTED) {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>
            SO Rejected
          </Text>
        );
      }
      if (
        (currentApprovalQueue?.status === purchaseSupplyOrderStatus?.APPROVED,
        currentApprovalQueue?.assignedToRole === Roles?.director)
      ) {
        return (
          <Text
            style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}
          >
            SO Approved
          </Text>
        );
      }

      return <Text />;
    },
  },
  {
    header: 'Urgent',
    type: 'text',
    value: ({ item }) => (item?.urgent ? 'Yes' : 'No'),
  },
  {
    header: 'Actions',
    type: 'custom',
    width: 250,
    render: ({ item }) => (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* {item.status === purchaseSupplyOrderStatus.PAYMENT_PENDING ? ( */}
        <Action
          {...props}
          action={{
            type: 'link',
            push: true,
            link: () => ({
              view: ROUTES.newContingentForm.name,
              params: { item },
            }),
          }}
        >
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.secondary.color2,
            }}
          >
            Create Contingent Voucher
          </Text>
        </Action>
        {/* ) : (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.grey.color3,
              cursor: 'disabled',
            }}
          >
            Create Contingent Voucher
          </Text>
        )} */}
        <Text
          style={{
            ...vars.headings.h11,
            color: vars.colors.secondary.color2,
          }}
        >
          View
        </Text>
      </View>
    ),
  },
  {
    width: 50,
  },
];

const SupplyOrderRequestList = (props) => (
  <Table
    lg={{ columns: tabListColumns(props) }}
    reloadEvent="contingent_voucher_list"
    uri={() => supplyOrderRequest({
      addOnFilter: {
        type: 'SO',
        purchaseRequestEntity: { status: 'Payment Pending' },
      },
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewSupplyRequest.name,
        params: { item },
      },
    })}
    {...props}
  />
);
export default SupplyOrderRequestList;
