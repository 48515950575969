import React from 'react';

import permissions from '../../../Lib/permissions';
import { ToggleFilter } from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { LeaveTypeList } from '../../../Queries/leaveType';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 15 },
  {
    header: 'leaveType.labels.leaveName'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'leaveType.labels.leaveId'.getLabel(),
    type: 'text',
    field: 'code',
  },
  {
    header: 'leaveType.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'leaveType.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.LEAVE_TYPE}
    uri={LeaveTypeList}
    editFormId={ROUTES.editLeaveType.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyLeavePolicyType.permissions.modifyLeavePolicyType.value}
    header={{
      leftActions: [(actionProps) => <ToggleFilter {...actionProps} model={MODELS.LEAVE_TYPE} id="leaveTypes" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewLeaveType.name,
        params: { item },
      },
    })}
  />
);
