import React from 'react';

import {
  Text, View,
} from '../../../../app-components';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import {
  autoSuggestInput, multiAutoSuggestInput, searchInput,
} from '../../../../autoSuggestions';
import { bloodRequestTypes, MODELS } from '../../../../Lib/constants';
import { ViewRequestRaised } from '../../../../Queries/bloodBank';
import FileLink from '../../../../Components/FileLink';
import { getString } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';
import { Form } from '../../../../app-components/form/Form';
import { approveBloodRequest } from '../helpers';
import { BLOOD_REQUEST_STATUSES } from '../constants';

const ViewBloodRequestForm = (props) => {
  const { navigation } = props;

  return (
    <Form
      {...props}
      type="standard"
      onSubmit={approveBloodRequest}
      uri={ViewRequestRaised}
      reloadEvent={`reload${MODELS.BLOOD_REQUEST}`}
      editable={false}
      validations={{
        raise_blood_request: {
          unitProvided: ({ data }) => {
            if (data.unitRequested < data.unitProvided) {
              return 'Unit provided  can not be greater than unit requested';
            }
            if (!data.unitProvided) {
              return 'This field cannot be empty';
            }

            return false;
          },
        },
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'Patient Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  searchInput({
                    label: 'bloodInventory.labels.uhid'.getLabel(),
                    field: 'patient.uhid',
                    placeholder: 'bloodInventory.placeholders.uhid'.getLabel(),
                  }),
                  searchInput({
                    label: 'bloodInventory.labels.patientName'.getLabel(),
                    field: 'patient.patientName',
                    placeholder: 'bloodInventory.placeholders.patientName'.getLabel(),
                  }),
                  {
                    type: 'text',
                    label: 'patient.labels.relation'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getString([
                        data?.patient?.relation,
                        data?.patient?.relationName,
                        ])}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'number',
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {`${data?.patient?.age || ''} ${data?.patient?.ageType || ''}`}
                      </Text>
                    ),
                    placeholder: 'bloodInventory.placeholders.age'.getLabel(),
                    label: 'bloodInventory.labels.age'.getLabel(),
                  },
                  autoSuggestInput({
                    label: 'bloodInventory.labels.gender'.getLabel(),
                    field: 'patient.gender',
                    placeholder: 'bloodInventory.placeholders.selectGender'.getLabel(),
                  }),
                  {
                    type: 'date',
                    field: 'date',
                    placeholder: 'bloodInventory.placeholders.dateIssue'.getLabel(),
                    label: 'bloodInventory.labels.dateIssue'.getLabel(),
                  },
                  {
                    type: 'textArea',
                    field: 'description',
                    label: 'bloodInventory.labels.reason'.getLabel(),
                    placeholder: 'bloodInventory.placeholders.reason'.getLabel(),
                  },
                  autoSuggestInput({
                    label: 'bloodInventory.labels.requestType'.getLabel(),
                    field: 'requestType',
                    suggestionField: 'label',
                    options: bloodRequestTypes,
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Current Admission Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            columnsPerRow: 4,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'department.name',
                    label: 'bloodInventory.labels.department'.getLabel(),
                    placeholder: 'bloodInventory.placeholders.department'.getLabel(),
                  }),
                  multiAutoSuggestInput({
                    field: 'speciality.name',
                    label: 'bloodInventory.labels.speciality'.getLabel(),
                    placeholder: 'bloodInventory.placeholders.speciality'.getLabel(),
                  }),
                  searchInput({
                    label: 'bloodInventory.labels.doctorName'.getLabel(),
                    field: 'doctorName.firstName',
                    placeholder: 'bloodInventory.placeholders.doctorName'.getLabel(),
                  }),
                  {
                    type: 'file',
                    field: 'attachment',
                    label: 'bloodInventory.labels.attachments'.getLabel(),
                    container: 'topLabel',
                    render: (item) => {
                      const value = item?.data?.attachment;
                      if (Array.isArray(value)) {
                        return value.map((file, index) => <FileLink key={index} file={file} />);
                      }
                      return <View>--</View>;
                    },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'REQUESTED BY',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            columnsPerRow: 4,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'requestedBy.name',
                    variant: 'filled',
                    label: 'bloodInventory.labels.employeeName'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'requestedBy.employee_code',
                    variant: 'filled',
                    label: 'bloodInventory.labels.employeeId'.getLabel(),
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: ' APPROVED/REJECTED BY',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            columnsPerRow: 4,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'approvedOrRejectedBy.employee.name',
                    variant: 'filled',
                    label: 'bloodInventory.labels.employeeName'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'approvedOrRejectedBy.employee.employee_code',
                    variant: 'filled',
                    label: 'bloodInventory.labels.employeeId'.getLabel(),
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Issue Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    field: 'raise_blood_request',
                    fieldType: 'nested',
                    editable: true,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            header: 'bloodInventory.labels.bloodGroup'.getLabel(),
                            field: 'bloodGroup.category',
                            variant: 'filled',
                            editable: false,
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            header: 'bloodInventory.labels.category'.getLabel(),
                            field: 'category.category',
                            variant: 'filled',
                            editable: false,
                          }),
                          {
                            type: 'number',
                            field: 'plateletsCount',
                            variant: 'filled',
                            header: 'bloodInventory.labels.plateletsCount'.getLabel(),
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'number',
                            value: ({ item }) => {
                              if (item?.category) return item?.category.unitInStock;
                              return item?.bloodGroup.unitInStock;
                            },
                            variant: 'filled',
                            placeholder: 'bloodInventory.placeholders.unitInStocks'.getLabel(),
                            header: 'bloodInventory.labels.unitInStocks'.getLabel(),
                            width: 300,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'unitRequested',
                            variant: 'filled',
                            placeholder: 'bloodInventory.placeholders.unitRequested'.getLabel(),
                            header: 'bloodInventory.labels.unitRequested'.getLabel(),
                            width: 300,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'unitProvided',
                            variant: 'filled',
                            placeholder: 'bloodInventory.placeholders.unitProvided'.getLabel(),
                            header: 'Unit Provided',
                            width: 300,
                            editable: navigation?.state?.params?.item?.status
                            === BLOOD_REQUEST_STATUSES.REQUESTED,
                            mandatory: true,
                            disabled: navigation?.state?.params?.item?.status
                             !== BLOOD_REQUEST_STATUSES.REQUESTED,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewBloodRequestForm;
