import React from 'react';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { pharmacyAndAssetStocksStatus } from '../../../../../Lib/constants';
import AssetPurchaseRequestList from './assetPurchaseRequest';
import AssetRepairRequestList from './assetRepairRequest';
import tabTheme from './tabTheme';

const AssetTabs = (props) => (
  <TabNavigator
    {...props}
    theme={{ ...tabTheme }}
    tabs={{
      Repair: {
        label: 'Purchase',
        screen: AssetPurchaseRequestList,
      },
      transfer: {
        label: 'Repair',
        screen: AssetRepairRequestList,
        filters: [
          {
            type: 'autoSuggestOptions',
            options: pharmacyAndAssetStocksStatus,
            field: 'status',
            label: 'Status',
            multiSelect: true,
          },
        ],
      },

    }}
  />
);
export default AssetTabs;
