export const EditZipCode = ({ navigation }) => {
  return {
    query: {
      model: 'zipCodes',
      id: 'masterDataList',
      relationValue: {
        model: 'zipCodes',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'zipCodes',
  };
};
