import React from 'react';

import permissions from '../../../Lib/permissions';
import { gendersAll, MODELS, ROUTES } from '../../../Lib/constants';
import { LeavePolicyTypeList } from '../../../Queries/leavePolicyType';
import MasterList from '../../../Wired/Master/MasterList';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import {
  FilterGroup, Text, TouchableOpacity,
} from '../../../app-components';
import { filterActive, filterInactive } from '../../../images';
import vars from '../../../theme/vars';
import { Action } from '../../../app-components/action/Action';

const tableColumns = [
  { width: 14 },
  {
    header: 'leaveType.labels.leavePolicyName'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'leaveType.labels.staffType'.getLabel(),
    type: 'text',
    value: ({ item: { staffType } }) => staffType?.name,
  },
  {
    header: 'leaveType.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'Activity Logs',
    type: 'text',
    render: (props) => (
      <Action
        {...props}
        action={{
          type: 'link',
          link: ({ item }) => ({
            view: ROUTES.viewLeavePolicyActivityLog.name,
            expanded: false,
            params: {
              data: item,
              addOnFilter: { id: item?._id },
              header: {
                title: `Activity Logs for ${item?.name}`,
              },
            },
          }),
        }}
      >
        <TouchableOpacity
          style={{
            flex: 1,
          }}
        >
          <Text
            style={{
              marginLeft: 15,
              ...vars.headings.h11,
              color: vars.colors.secondary.color2,
            }}
          >
            view
          </Text>
        </TouchableOpacity>
      </Action>
    ),
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.LEAVE_POLICY_TYPE}
    uri={LeavePolicyTypeList}
    editFormId={ROUTES.editLeavePolicyType.name}
    header={{
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'leaveType.title.leavePolicyManagement'.getLabel()}
        />
      ),
      actions: [
        (actionProps) => <FtsSearch {...actionProps} action={{ exp: ['name', 'description'] }} />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'staffType',
              label: 'Staff Type',
              placeholder: 'Staff Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              model: MODELS.STAFFTYPE,
              id: 'masterDataList',
            },
            {
              type: 'autoSuggest',
              field: 'gender',
              label: 'Gender',
              placeholder: 'Gender',
              options: gendersAll,
            },
          ],
        }),
      ],
    }}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyLeavePolicyType.permissions.modifyLeavePolicyType.value}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewLeavePolicyType.name,
        params: { item },
      },
    })}
  />
);
