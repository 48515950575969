import { MODELS } from '../Lib/constants';

export const LeavePolicyTypeList = ({ addOnFilter }) => ({
  query: {
    model: MODELS.LEAVE_POLICY_TYPE,
    id: 'leavePolicies',
    addOnFilter,
  },
  model: MODELS.LEAVE_POLICY_TYPE,
});

export const EditLeavePolicyType = ({ navigation }) => ({
  query: {
    model: MODELS.LEAVE_POLICY_TYPE,
    id: 'leavePolicyDetail',
    relationValue: {
      model: MODELS.LEAVE_POLICY_TYPE,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.LEAVE_POLICY_TYPE,
});
