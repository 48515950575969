import React from 'react';

import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { EditHouseDetails } from '../uri';
import ViewHouseTabs from './Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'House Number.',
    mandatory: true,
    editable: false,
  },
  {
    type: 'number',
    field: 'rentAmount',
    label: 'Rent Amount',
    mandatory: true,
    editable: false,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    editable: false,
  },
  {
    type: 'number',
    field: 'waterCharge',
    label: 'Water Charge',
    mandatory: true,
    editable: false,
  },
  {
    type: 'number',
    field: 'noOfRooms',
    label: 'Number of Rooms',
    mandatory: true,
    editable: false,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'Status',
    placeholder: 'Select',
    mandatory: true,
    editable: false,
  },
];

export default MasterForm({
  mandatory: {
    name: 1,
    rentCharges: 1,
    waterCharge: 1,
    noOfRooms: 1,
    isActive: 1,
  },
  uri: EditHouseDetails,
  model: MODELS.HOUSE_DETAILS,
  formFields,
  uniqueValidationMessage: 'Check the Validation',
  submitMessage: 'Successfully Edited',
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewHouseTabs {...props} />
    ),
  }],
});
