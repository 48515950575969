import React, { useEffect } from 'react';

import { FormField, View } from '../../../../../app-components';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';

const handleAddOnFiltersForDoctors = (props) => {
  const alreadySelectedDoctors = [];
  Object.keys(props?.data?.queues || {}).forEach((key) => {
    const queue = props.data.queues[key];
    if (queue?.doctor?._id) {
      alreadySelectedDoctors.push(queue?.doctor?._id);
    }
  });

  return {
    _id: { $nin: alreadySelectedDoctors },
    department: { code: 'OPD' },
    speciality: { _id: props?.data?.speciality?._id },
    role: { code: 'DOCTOR' },
  };
};

const Room = ({
  code, index, setValue, roomId, name,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setValue({ field: `queues.${index}.room`, value: { _id: roomId, name } });
    }, 100);
  }, []);

  return (
    <View
      key={code}
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: index ? 24 : 0,
      }}
    >
      <View>
        <FormField
          {...autoSuggestInput({
            label: 'patientToken.labels.room'.getLabel(),
            field: `queues.${index}.room`,
            value: code,
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            skipRemoveValue: true,
          })}
        />
      </View>
      <View style={{ marginLeft: 24 }}>
        <FormField
          {...searchInput(
            {
              label: 'patientToken.labels.doctor'.getLabel(),
              field: `queues.${index}.doctor`,
              variant: 'filled',
              suggestionField: 'fullName',
              placeholder: 'patientToken.placeholders.searchDoctor'.getLabel(),
              model: MODELS.EMPLOYEE,
              query: 'employeeLists',
              searching: true,
              searchField: 'firstName',
            },
            {
              modifyResult: ({ data }) => {
                const newData = data.map((item) => ({
                  ...item,
                  fullName: `${item.firstName} ${item.lastName}`,
                }));
                return { data: newData };
              },
              addOnFilter: handleAddOnFiltersForDoctors,
            },
          )}
        />
      </View>
    </View>
  );
};

const RenderRooms = ({ rooms, setValue }) => (
  <View style={{ marginTop: 24, width: '100%', alignItems: 'center' }}>
    {rooms.map(({ code = '', name, _id: roomId }, index) => (
      <Room
        name={name}
        code={code}
        key={code}
        index={index}
        setValue={setValue}
        roomId={roomId}
      />
    ))}
  </View>
);

export default RenderRooms;
