/* eslint-disable max-len */
import React from 'react';

import {
  AssetCentralInventoryToggleFilter,
  Image,
  Text,
  TouchableOpacity,
} from '../../../../../app-components';
import { editRecord } from '../../../../../images';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import WithPermission from '../../../../../Components/WithPermission';
import permissions from '../../../../../Lib/permissions';
import vars from '../../../../../theme/vars';
import { AssetCentralInventoryItemListUri } from '../../../../../Queries/asset';
import { Table } from '../../../../../app-components/table/Table';

const header = {
  leftActions: [(props) => (
    <AssetCentralInventoryToggleFilter
      {...props}
      model={MODELS.ASSET_ITEM}
      id="masterDataListForTable"
    />
  )],
};

const tableItems = [
  {
    type: 'selection',
  },
  {
    header: 'asset.headers.assetName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'asset.headers.category'.getLabel(),
    type: 'text',
    value: ({ item }) => item?.category?.name,
  },
  {
    header: 'asset.headers.subCategory'.getLabel(),
    type: 'text',
    value: ({ item }) => item?.subCategory?.name,
  },
  {
    header: 'asset.headers.quantityInStocks'.getLabel(),
    type: 'text',
    value: ({
      item:
      { stockAvailable },
    }) => stockAvailable?.map((item) => item?.stockAvailable).toString(),
  },
  {
    header: 'asset.headers.status'.getLabel(),
    type: 'text',
    render: ({ item: { stockStatus } = {} }) => {
      const stocksStatus = stockStatus?.map((item) => item?.stockStatus).toString();
      let statusColor = vars.colors.warning;
      if (stocksStatus === 'In-Stocks') {
        statusColor = vars.colors.primary.color2;
      } else if (stocksStatus === 'Out-of-Stock') {
        statusColor = vars.colors.error;
      }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {stocksStatus}
        </Text>
      );
    },
  },
  {
    header: 'Actions',
    type: 'text',
    render: () => (
      <WithPermission
        access_key={permissions.inventory.views.editInventoryAsset.permissions.editInventoryAsset.value}
      >
        <TouchableOpacity>
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        </TouchableOpacity>
      </WithPermission>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editInventoryAsset.name,
        params: { item },
      },
    }),
  },
  {
    type: 'moreActions',
    width: 15,
    actions: [
      {
        type: 'link',
        text: 'asset.buttons.raisePurchaseRequest'.getLabel(),
        link: ({ item }) => ({
          view: ROUTES.purchaseRequestAsset.name,
          params: {
            item: {
              item: {
                _id: item._id,
                category: item.category,
                subCategory: item.subCategory,
                reorderLevel: item.reorderLevel,
                rateContract: item.rateContract,
                name: item?.name,
                code: item?.itemCode,
                stockAvailable: item?.stockAvailable,
              },
            },
          },
        }),
      },
    ],
  },
];

const AssetList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tableItems,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      reloadEvent={`reload${MODELS.ASSET_ITEM}`}
      uri={() => AssetCentralInventoryItemListUri({ filter: addOnFilter })}
      model={MODELS.ASSET_ITEM}
      header={header}
      columns={tableColumns}
      showActionIcon={false}

      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewInventoryAsset.name,
          params: { item },
        },
      })}
      {...restProps}
    />
  );
};
export default AssetList;
