import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { Text } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';

const PreOperativeCheckList = (props) => {
  const { navigation: { state: { params: { item: filter } } } } = props;
  const slExamination = [
    {
      field: 'isSlExaminationSelf',
      label: 'Self',
    }, {
      field: 'isSlExaminationHardNSI',
      label: 'Hard-NS-I',
    },
    {
      field: 'isSlExaminationII',
      label: 'II',
    },
    {
      field: 'isSlExaminationIII',
      label: 'III',
    },
    {
      field: 'isSlExaminationIV',
      label: 'IV',
    },
    {
      field: 'isSlExaminationPSC',
      label: 'PSC',
    },
    {
      field: 'isSlExaminationBrown',
      label: 'Brown',
    },
  ];
  return (
    <ListActionModal
      {...props}
      title="Pre-Operative CheckList"
      description="View pre-operative checklist details for the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.PRE_OPERATIVE_CHECKLIST}`}
          uri={() => ({
            query: {
              id: 'preOperativeCheckList',
              addOnFilter: {
                _id: filter._id,
              },
            },
            model: MODELS.PRE_OPERATIVE_CHECKLIST,
          })}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'systematicDisease',
                            label: 'Systematic Disease',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Eye',
                            render: ({ data }) => {
                              const result = data.isEyeLeft ? `${data?.isEyeLeft}  ${data?.eyeLeft}` : data?.isEyeRight ? `${data?.isEyeRight}  ${data?.eyeRight}` : '--';
                              return result;
                            },
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Vision',
                            render: ({ data }) => {
                              const result = data.isVisionLeft ? `${data?.isVisionLeft}  ${data?.visionLeft}` : data?.isVisionRight ? `${data?.isVisionRight}  ${data?.visionRight}` : '--';
                              return result;
                            },
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'IIOP',
                            render: ({ data }) => {
                              const result = data.isIOPLeft ? `${data?.isIOPLeft}  ${data?.iopLeft}` : data?.isIOPRight ? `${data?.isIOPRight}  ${data?.iopRight}` : '--';
                              return result;
                            },
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Roplas',
                            render: ({ data }) => {
                              const result = data.isRoplasLeft ? `${data?.isRoplasLeft}  ${data?.roplasLeft}` : data?.isRoplasRight ? `${data?.isRoplasRight}  ${data?.roplasRight}` : '--';
                              return result;
                            },
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'xylocameSensitivity',
                            label: 'Xylocame Sensitivity',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'pupilDolation',
                            label: 'Pupil Dolation',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: '',
                            render: (renderProps) => {
                              const { navigation: { state: { params: { item } } } } = renderProps;
                              return slExamination.map((value) => {
                                if (Object.keys(item).includes(value.field)) {
                                  return value.label;
                                }
                              }).join(' ');
                            },
                            label: 'SL Examination',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'consent',
                            label: 'consent',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>BioMetry</Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'biometryK1',
                            label: 'K1',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'biometryK2',
                            label: 'K2',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'biometryAXL',
                            label: 'AXL',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'biometryIOL',
                            label: 'IOL',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Less Tests </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    columnsPerRow: 3,
                    columns: [
                      {
                        render: () => 'Parameter',
                        style: { width: '20%' },
                      },
                      {
                        render: () => 'Value',
                        style: { width: '20%' },
                      },
                      {
                        render: () => 'Range',
                        style: { width: '20%' },
                      },
                      {
                        render: () => 'Blood Sugar',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        type: 'text',
                        field: 'bloodSugar',
                        editable: false,
                        style: { width: '20%' },
                      },
                      {
                        render: () => 'FBS<140mg%RBS<180',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        render: () => 'Blood Pressure',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        type: 'text',
                        field: 'bloodPressure',
                        editable: false,
                        style: { width: '20%' },
                      },
                      {
                        render: () => '<140/90 mmHg',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        render: () => 'ATI-Coagulants',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        type: 'text',
                        field: 'atiCoagulants',
                        style: { width: '20%' },
                        editable: false,
                      },
                      {
                        render: () => 'PT<18sec',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        render: () => 'HB',
                        style: { marginTop: 25, width: '20%' },
                      },
                      {
                        type: 'text',
                        field: 'hb',
                        style: { width: '20%' },
                        editable: false,
                      },
                    ],
                  },
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'text',
                        field: 'tripleTests',
                        label: 'tripleTest',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'comment',
                        label: 'Comment',
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />
  );
};

const ViewPreOperativeCheckList = (props) => (<PreOperativeCheckList {...props} />);
export default ViewPreOperativeCheckList;

