import React from 'react';

import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import TemperatureOrPulseTimeChart from './TemperatureOrPulseTimeChart';
import CaseHistoryAndExamination from './CaseHistoryAndExamination';
import OperationProcedureNotes from './OperationOrProcedureNotes';
import Miscellaneous from './MiscellaneousAccount';
import AnesthesiaReport from './AnesthesiaReport';
import VitalSignsSheet from './VitalSignsSheet';
import ProgressReport from './ProgressReport';
import Investigation from './Investigation';
import Patient from './PatientDetails';
import References from './References';
import History from './History';
import Outcome from './Outcome';
// import OtList from './OTList';
import Orders from './Orders';
import ConsultationLogs from '../../../../../../Wired/ConsultationLogTable';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import IpdForm from './IPDForm';
import ViewConsentForm from './ConsentForm';
import TreatmentChart from './TreatmentChart';
import { StandardButton } from '../../../../../../app-components';

const ViewGeneralSurgeryTabs = (props) => {
  const {
    navigation: { state: { params = {} } = {} },
  } = props;

  return (
    <TabNavigator
      {...props}
      tabs={{
        PatientDetails: {
          label: 'Patient Details',
          screen: Patient,
          screenProps: props,
        },
        IPDForm: {
          label: 'IPD Form',
          screen: IpdForm,
          screenProps: props,
        },
        Consulationlog: {
          label: 'Consultation Log',
          screen: ConsultationLogs,
          screenProps: {
            props,
            model: MODELS.CONSULTATION,
            id: 'consultationDataList',
            addOnFilter: { patient: { _id: params?.patient?.patient?._id } },
          },
        },
        ViewConsentForm: {
          label: 'Consent Form',
          screen: ViewConsentForm,
          screenProps: props,
        },
        CaseHistoryAndExamination: {
          label: 'Case History & Examination',
          screen: CaseHistoryAndExamination,
          screenProps: props,
        },
        Investigation: {
          label: 'Investigation',
          screen: Investigation,
          screenProps: props,
        },
        OperationProcedureNotes: {
          label: 'Operation/Procedure Notes',
          screen: OperationProcedureNotes,
          screenProps: props,
          expanded: true,
        },
        AnesthesiaReport: {
          label: 'Anesthesia Report',
          screen: AnesthesiaReport,
          screenProps: props,
        },
        ProgressReport: {
          label: 'Progress Report',
          screen: ProgressReport,
          screenProps: props,
        },
        VitalSignsSheet: {
          label: 'Vital Signs Sheet',
          screen: VitalSignsSheet,
          screenProps: props,
        },
        TemperatureOrPulseTimeChart: {
          label: 'Temperature/Pulse Time Chart',
          screen: TemperatureOrPulseTimeChart,
          screenProps: props,
        },
        Outcome: {
          label: 'Outcome',
          screen: Outcome,
          screenProps: props,
        },
        History: {
          label: 'History',
          screen: History,
          screenProps: props,
        },
        Treatment: {
          label: 'Daily Treatment Chart',
          screen: TreatmentChart,
          screenProps: props,
          headerActions: [
            {
              render: () => (
                // <WithPermission access_key={permissions.inventory.views.raisePurchaseRequestAsset.permissions.raisePurchaseRequestAsset.value}>
                <StandardButton label="Add Medicine" />
                // </WithPermission>
              ),
              type: 'link',
              link: () => ({
                view: ROUTES.purchaseRequestAsset.name,
                params: { item: { ...props?.navigation?.state?.params } },
                push: true,
                expanded: true,
              }),
            },
          ],
        },
        Orders: {
          label: 'Orders',
          screen: Orders,
          screenProps: props,
        },
        References: {
          label: 'References',
          screen: References,
          screenProps: props,
        },
        MiscellaneousAccount: {
          label: 'Miscellaneous Account',
          screen: Miscellaneous,
          screenProps: props,
        },
      }}
    />
  );
};
export default ViewGeneralSurgeryTabs;
