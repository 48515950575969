import React from 'react';
import { ROUTES } from '../../../../../Lib/constants';
import { Image, TouchableOpacity } from '../../../../../app-components';
import { checkSelectedIcon, unCheckboxIcon } from '../../../../../images';

class CheckBoxComponent extends React.Component {
  onPress = () => {
    const {
      value, setValue, navigation,
    } = this.props;
    if (value) {
      navigation.push({
        view: ROUTES.confirmTestModal.name,
        params: { value, setValue, navigation },
      });
    } else {
      setValue({ field: 'manualTest', value: !value });
    }
  };

  render() {
    const { value } = this.props;
    return (
      <TouchableOpacity
        style={{ paddingLeft: 6, paddingRight: 6 }}
        onPress={this.onPress}
      >
        <Image
          source={value ? checkSelectedIcon : unCheckboxIcon}
          style={{ width: 20, height: 20 }}
          resizeMode="contain"
        />
      </TouchableOpacity>
    );
  }
}

export default CheckBoxComponent;
