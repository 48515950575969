import React from 'react';

import {
  Text, save, Toast,
} from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { dateFormatX } from '../../../../../../constants';

const IpdLogInputOutputChart = (props) => {
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Input/Output Chart"
      description="Log Input Output of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.INPUT_OUTPUT_CHART}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          computations={{
            self: {
              totalIntakeOneToThree: {
                compute: (item) => ({
                  set: {
                    totalIntakeOneToThree:
                    item.intakeOne
                    + item.intakeTwo
                    + item.intakeThree,
                  },
                }),
                onChange: ['intakeOne',
                  'intakeTwo',
                  'intakeThree'],
              },
              totalUrineOutput: {
                compute: (item) => ({
                  set: {
                    totalUrineOutput:
                    item.urineHrly,
                  },
                }),
                onChange: ['urineHrly'],
              },
              totalIntake: {
                compute: (data) => ({
                  set: {
                    totalIntake: data.intakeOne
                    + data.intakeTwo
                    + data.intakeThree + data.rtOral,
                  },
                }),
                onChange: ['intakeOne',
                  'intakeTwo',
                  'intakeThree', 'rtOral'],
              },
              drainage: {
                compute: (item) => ({
                  set: {
                    totalDrainageOneToThree:
                    item.drainageOne
                    + item.drainageTwo
                    + item.drainageThree + item.urineHrly,
                  },
                }),
                onChange: ['drainageOne',
                  'drainageTwo',
                  'drainageThree', 'urineHrly'],
              },
              output: {
                compute: (data) => ({
                  set: {
                    totalOutPut: data.drainageOne
                    + data.drainageTwo
                    + data.drainageThree + data.rtAspiration + data.urineHrly + data.stool,
                  },
                }),
                onChange: ['drainageOne',
                  'drainageTwo',
                  'drainageThree', 'rtAspiration', 'urineHrly', 'stool'],
              },
            },
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.inputOutputTime = dateFormatX(
              data?.inputOutputHour,
              data?.inputOutputMinute,
              data?.inputOutputMeridiem,
            );
            delete updates.inputOutputHour;
            delete updates.inputOutputMinute;
            delete updates.inputOutputMeridiem;
            updates.inputOutput = new Date(
              new Date(
                new Date(
                  new Date(
                    new Date().setUTCHours(0),
                  ).setUTCMinutes(0),
                ).setUTCSeconds(0),
              ).setUTCMilliseconds(0),
            );
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
              speciality: submitProps?.navigation?.state
                .params?.dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.INPUT_OUTPUT_CHART,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Chart has been Created successfully.',
              type: 'number',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            validations: {
              intakeOne: ({ data }) => validateInputLength(data?.intakeOne, 0, 5),
              intakeTwo: ({ data }) => validateInputLength(data?.intakeTwo, 0, 5),
              intakeThree: ({ data }) => validateInputLength(data?.intakeThree, 0, 5),
              rtOral: ({ data }) => validateInputLength(data?.rtOral, 0, 5),
              urineHrly: ({ data }) => validateInputLength(data?.urineHrly, 0, 5),
              drainageOne: ({ data }) => validateInputLength(data?.drainageOne, 0, 5),
              drainageTwo: ({ data }) => validateInputLength(data?.drainageTwo, 0, 5),
              drainageThree: ({ data }) => validateInputLength(data?.drainageThree, 0, 5),
              rtAspiration: ({ data }) => validateInputLength(data?.rtAspiration, 0, 5),
              stool: ({ data }) => validateInputLength(data?.stool, 0, 5),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            ...newDateInput({
                              hour: { field: 'inputOutputHour', label: 'Hour' },
                              minute: { field: 'inputOutputMinute', label: 'Minute' },
                              meridiem: { field: 'inputOutputMeridiem', label: 'Meridiem' },
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>In Take </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'number',
                            field: 'intakeOne',
                            label: '1',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'intakeTwo',
                            label: '2',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'intakeThree',
                            label: '3',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'totalIntakeOneToThree',
                            label: 'Total',
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'rtOral',
                            label: 'Rt/Oral',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'totalIntake',
                            label: 'Total Intake',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>OutPut </Text>
                            ),
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Urine(ml) </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'number',
                            field: 'urineHrly',
                            label: 'Hrly',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'totalUrineOutput',
                            label: 'Total',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>drainage(ml) </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'number',
                            field: 'drainageOne',
                            label: '1',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'drainageTwo',
                            label: '2',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'drainageThree',
                            label: '3',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'totalDrainageOneToThree',
                            label: 'Total',
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'rtAspiration',
                            label: 'RT Aspiration',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'stool',
                            label: 'Stool',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'number',
                            field: 'totalOutPut',
                            label: 'Total',
                            editable: false,
                            variant: 'filled',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => 'Balance',
                          },
                          {
                            type: 'text',
                            field: 'balance',
                            label: 'Balance',
                            editable: true,
                            variant: 'filled',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const LogInputOutputChart = (props) => (<IpdLogInputOutputChart {...props} />);
export default LogInputOutputChart;
