import React from 'react';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import { EmployeePackageStructure } from '../Salary/actions';
import tabTheme from './TabTheme';
import Profile from '../Profile';

const EditEmployeeTabs = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        Profile: {
          label: 'Profile',
          screen: Profile,
        },
        Salary: {
          label: 'Salary',
          screen: EmployeePackageStructure,
          screenProps: {
            addOnFilter: {
              employee_id: { _id: item?._id },
            },
            canAddSalaryStructure: true,
          },
        },
      }}
    />
  );
};

export default EditEmployeeTabs;
