import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput, multiAutoSuggestInput, searchInput } from '../../../autoSuggestions';
import { EditLeavePolicyType } from '../../../Queries/leavePolicyType';
import { FormHoc } from '../../../app-components';
import vars from '../../../theme/vars';
import {
  genders,
  gendersAll,
  leaveCreditType,
  MODELS,
} from '../../../Lib/constants';

const ViewLeavePolicy = FormHoc({
  type: 'standard',
  closeView: 1,
  uri: EditLeavePolicyType,
  reloadEvent: `reload${MODELS.LEAVE_POLICY_TYPE}`,
  editable: false,
  mandatory: {
    name: 1,
    staffType: 1,
  },
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'name',
                label: 'leaveType.labels.leavePolicyName'.getLabel(),
                mandatory: true,
                allowWhiteSpaces: true,
              },
              autoSuggestInput({
                label: 'leaveType.labels.staffType'.getLabel(),
                field: 'staffType',
                valueField: 'name',
                suggestionField: 'name',
                placeholder: 'leaveType.placeholders.selectStaffType'.getLabel(),
                model: MODELS.STAFFTYPE,
                query: 'masterDataList',
                mandatory: true,
              }),
              autoSuggestInput({
                field: 'gender',
                variant: 'filled',
                header: 'leaveType.labels.gender'.getLabel(),
                suggestionField: 'label',
                placeholder: 'leaveType.placeholders.selectGender'.getLabel(),
                options: gendersAll,
                mandatory: true,
              }),
              {
                type: 'status',
                field: 'isActive',
                label: 'leaveType.labels.status'.getLabel(),
                container: 'topLabel',
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'Short Leave Details',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 0 },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'shortLeaves',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      paddingLeft: 12,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      searchInput({
                        field: 'leave_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveType'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 185,
                        mandatory: true,
                      }),
                      {
                        type: 'number',
                        field: 'priority',
                        variant: 'filled',
                        header: 'Priority',
                        placeholder: 'Priority',
                        width: 200,
                        mandatory: true,
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'Leave Policy',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 0 },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'leave_policy_details',
                fieldType: 'nested',
                editable: false,
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      paddingLeft: 12,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      searchInput({
                        field: 'leave_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveType'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 150,
                      }),
                      autoSuggestInput({
                        field: 'gender',
                        variant: 'filled',
                        header: 'leaveType.labels.gender'.getLabel(),
                        suggestionField: 'label',
                        placeholder: 'leaveType.placeholders.selectGender'.getLabel(),
                        options: genders,
                        width: 150,
                      }),
                      {
                        type: 'text',
                        field: 'no_of_leaves_assigned',
                        variant: 'filled',
                        header: 'leaveType.labels.noOfLeaves'.getLabel(),
                        placeholder: 'leaveType.placeholders.noOfLeaves'.getLabel(),
                        width: 150,
                      },
                      searchInput({
                        field: 'leave_credit_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveCreditType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveCredit'.getLabel(),
                        suggestionField: 'label',
                        options: leaveCreditType,
                        width: 150,
                      }),
                      multiAutoSuggestInput({
                        field: 'accuralExclude',
                        variant: 'filled',
                        header: 'leaveType.labels.accuralExcluding'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectaccuralExcluding'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 150,
                      }),
                      {
                        type: 'text',
                        field: 'minExperienceMonths',
                        variant: 'filled',
                        header: 'leaveType.labels.minExperience'.getLabel(),
                        placeholder: 'leaveType.placeholders.minExp'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'text',
                        field: 'maxExperienceMonths',
                        variant: 'filled',
                        header: 'leaveType.labels.maxExperience'.getLabel(),
                        placeholder: 'leaveType.placeholders.maxExp'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'checkBox',
                        field: 'is_carry_forward',
                        variant: 'filled',
                        header: 'leaveType.labels.isCarryForward'.getLabel(),
                        width: 125,
                      },
                      {
                        type: 'text',
                        field: 'carry_forward_leaves',
                        variant: 'filled',
                        header: 'leaveType.labels.carryForwardLeaves'.getLabel(),
                        placeholder: 'leaveType.placeholders.carryForwardLeaves'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'text',
                        field: 'maximum_leave_accumulated',
                        variant: 'filled',
                        header: 'leaveType.labels.maximum_leave_accumulated'.getLabel(),
                        placeholder: 'leaveType.placeholders.maximum_leave_accumulated'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'text',
                        field: 'maximum_leave_allowed',
                        variant: 'filled',
                        header: 'leaveType.labels.maximum_leave_allowed'.getLabel(),
                        placeholder: 'leaveType.placeholders.maximum_leave_allowed'.getLabel(),
                        width: 150,
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ViewLeavePolicy;
