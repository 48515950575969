import React from 'react';

import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { Table } from '../../../app-components/table/Table';
import { MODELS } from '../../../Lib/constants';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="Payment"
      count={item?.aggregates?._count}
    />
  ),
});

const tabListColumns = {
  header,
  lg: {
    columns: [
      {
        type: 'date',
        header: 'DATE',
        field: 'date',
        format: 'DD/MM/YYYY',
        width: '20%',
      },
      {
        type: 'text',
        field: 'payment_type.name',
        header: 'Payment Type',
      },
      {
        type: 'text',
        field: 'employee_id.name',
        header: 'Employee',
      },
      //   {
      //     type: 'text',
      //     field: 'payee.name',
      //     header: 'Payee',
      //   },
      {
        type: 'number',
        field: 'total_payment_amount',
        header: 'Total Payment Amount',
      },
      {
        type: 'text',
        field: 'purpose',
        header: 'Purpose',
      },
    ],
  },
};
const Payment = (props) => (
  <Table
    {...props}
    header
    reloadEvent={`reload${MODELS.PAYMENT}`}
    model={MODELS.PAYMENT}
    uri={() => ({
      query: {
        id: 'payments',
      },
      model: MODELS.PAYMENT,
    })}
    //   uri={() => GetHistoryUri({ admissionId: { _id } })}
    {...tabListColumns}
  />
);
export default Payment;
