import React from 'react';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import AttendanceTabs from '../Attendance';
import Profile from '../Profile';
import Schedule from '../Schedule';
import tabTheme from './TabTheme';
import ViewSalaryTabs from '../../../../Wired/Salary';

const ViewEmployeeTabs = (props) => {
  const { navigation: { state: { params: { item = {} } = {} } = {} } = {} } = props;

  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      tabs={{
        Profile: {
          label: 'Profile',
          screen: Profile,
        },
        Attendance: {
          label: 'Attendance',
          screen: AttendanceTabs,
          screenProps: {
            user: item,
            isEmployeeDetail: true,
          },
        },
        Schedule: {
          label: 'Schedule',
          screen: Schedule,
          screenProps: {
            user: item,
          },
        },
        Salary: {
          label: 'Salary',
          screen: ViewSalaryTabs,
          screenProps: {
            employee: item,
            item,
            canAddSalaryStructure: false,
          },
        },
      }}
      {...props}
    />
  );
};

export default ViewEmployeeTabs;
