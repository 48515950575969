import React from 'react';

import { MODELS, ROUTES } from '../../../Lib/constants';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
//   { type: 'selection' },
  {
    header: 'Operation Name',
    type: 'text',
    field: 'name',
    sortable: true,
    mandatory: true,
    width: 400,

  },
  {
    header: 'Cost',
    type: 'number',
    field: 'cost',
    sortable: true,
    width: 400,
  },
  {
    header: 'Description',
    type: 'text',
    field: 'description',
    // sortable: true,
    width: 400,
  },

];

export default (props) => (

  <MasterList
    {...props}
    model={MODELS.OPERATIONS}
    editFormId={ROUTES.editOperationMaster.name}
    tableColumns={tableColumns}
  />

);
