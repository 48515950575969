/* eslint-disable no-shadow */
import React from 'react';

import { Text } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { MODELS, vaccinationType } from '../../../Lib/constants';
import { VaccinationItemList } from '../../../Queries/vaccination';
import vars from '../../../theme/vars';

const ViewVaccineSchedule = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  const { vaccinationType: VaccinationType, _id } = item;

  return (
    <Form
      {...props}
      editable={false}
      type="standardShadow"
      reloadOnChangeEvent={`reload${MODELS.PHARMACY_ITEM}`}
      uri={VaccinationItemList({ addOnFilter: { isVaccine: true, _id } })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            label: 'VACCINE DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    label: 'vaccination.labels.vaccineName'.getLabel(),
                    type: 'text',
                    field: 'name',
                  },
                  {
                    label: 'vaccination.labels.vaccineType'.getLabel(),
                    type: 'text',
                    render: () => (item?.isVaccineGlobal ? 'GLOBAL' : 'Recommended'),
                  },
                  {
                    label: 'vaccination.labels.description'.getLabel(),
                    type: 'text',
                    field: 'description',
                  },
                  {
                    label: 'vaccination.labels.stockAvailable'.getLabel(),
                    type: 'text',
                    render: () => (item?.stockAvailable[0].stockAvailable ? item?.stockAvailable[0].stockAvailable : '0'),
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.paymentType'.getLabel(),
                    render: ({ data: { isVaccineFree } }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {isVaccineFree ? 'Free' : 'Paid'}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.sellingPrice'.getLabel(),
                    render: ({ data: { sellingPrice } }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {sellingPrice}
                      </Text>
                    ),
                  },
                  {
                    type: 'status',
                    field: 'isActive',
                    label: 'vaccination.labels.vaccineStatus'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Vaccine Schedule',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'vaccination.labels.reVaccinationGap'.getLabel(),
                    container: 'topLabel',
                    value: VaccinationType === vaccinationType?.reCurring ? 'Recurring' : 'Once In LIfeTime',
                  },
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  VaccinationType === vaccinationType?.reCurring ? {
                    type: 'number',
                    label: 'vaccination.labels.noOfDoses'.getLabel(),
                    showCustomIcon: true,
                    field: 'noOfDoses',
                    mandatory: true,
                    disabled: ({ data }) => !data?.vaccinationType,
                  } : null,
                  VaccinationType === vaccinationType?.reCurring ? {
                    type: 'checkBox',
                    label: 'vaccination.labels.allDosesOccuring'.getLabel(),
                    field: 'isReoccurringIntervalEqual',
                    container: 'rightLabel',
                    disabled: ({ data }) => data?.vaccinationType !== vaccinationType?.reCurring,
                  } : null,
                  VaccinationType
                  === vaccinationType?.reCurring && item?.isReoccurringIntervalEqual ? {
                      type: 'text',
                      label: 'vaccination.labels.vaccinationPeriod'.getLabel(),
                      render: () => (
                        <Text style={{ ...vars.headings.h8 }}>
                          {
                    `${item?.interval}  ${item?.intervalType}`
                    }
                        </Text>
                      ),
                    } : null,

                ],
              },
              VaccinationType
              === vaccinationType?.reCurring && !item?.isReoccurringIntervalEqual ? {
                  fieldVariant: 'filled',
                  labelStyle: {
                    ...vars.headings.h8,
                    color: vars.colors.grey.color2,
                    textTransform: 'uppercase',
                    paddingLeft: 12,
                  },
                  groupsContainerStyle: { paddingLeft: 0 },
                  groups: [
                    {
                      columns: [
                        {
                          field: 'vaccinationReOccurringDetails',
                          fieldType: 'nested',
                          Component: NestedTable,
                          columnsPerRow: 2,
                          componentProps: {
                            maxHeight: 400,
                            skipTableHeaderOnNoData: true,
                            listProps: {
                              hideColumnHeader: false,
                              headerRowColumnTextStyle: {
                                ...vars.headings.h13,
                                color: vars.colors.grey.color3,
                                backgroundColor: 'white',
                                numberOfLines: 1,
                              },
                              headerRowContainerStyle: { backgroundColor: 'white' },
                              columns: [
                                {
                                  type: 'text',
                                  field: 'doseNo',
                                  header: 'vaccination.headers.doseNo'.getLabel(),
                                  disabled: true,
                                  width: 100,
                                },
                                {
                                  type: 'text',
                                  render: ({ item }) => (
                                    <Text style={{ ...vars.headings.h8 }}>
                                      {`${item?.interval}  ${item?.intervalType}` }
                                    </Text>
                                  ),
                                  header: 'inventory.headers.vaccinationPeriod'.getLabel(),
                                },
                              ],
                            },
                          },
                        },
                      ],
                    },
                  ],
                } : null,
            ],
          },
        ],
      }}
    />
  );
};

export default ViewVaccineSchedule;
