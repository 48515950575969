import React from 'react';
import { View } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import FileLink from '../../../Components/FileLink';
import { EditHolidaysCalendar } from '../../../Queries/holidaysCalendar';
import ViewHolidayTabs from './Components/tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'holidayCalendar.labels.year'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.year'.getLabel(),
  },
  {
    type: 'date',
    field: 'fromDate',
    label: 'holidayCalendar.labels.fromDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.fromDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'date',
    field: 'toDate',
    label: 'holidayCalendar.labels.toDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.toDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'holidayCalendar.labels.status'.getLabel(),
    mandatory: true,
  },
  {
    type: 'file',
    field: 'attachment',
    label: 'bloodInventory.labels.file'.getLabel(),
    container: 'topLabel',
    placeholder: 'holidayCalendar.placeholders.file'.getLabel(),
    render: (item) => {
      const value = item?.data?.attachment;
      return <FileLink file={value} />;
    },
  },

  {
    type: 'textArea',
    field: 'description',
    label: 'holidayCalendar.labels.description'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.description'.getLabel(),
  },
];

const ViewHolidayCalendar = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        uri={EditHolidaysCalendar}
        model={MODELS.HOLIDAYCALENDAR}
        editable={false}
        columnsPerRow={4}
        formGroups={[
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groups: [
              {
                columns: [...formFields],
              },
            ],
          },
        ]}
        defaultValues={() => ({
          isActive: true,
        })}
        {...props}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewHolidayTabs
        showAddButton={false}
        {...props}
      />
    </View>
  </View>
);
export default ViewHolidayCalendar;
