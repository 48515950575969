import React from 'react';
import { upperFirst } from 'lodash';

import { Text, View } from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import vars from '../../../../../../theme/vars';

const ViewDepartmentRepairRequests = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item: purchaseRequest,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      defaultValues={() => ({ purchaseRequest })}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'purchaseRequest._createdOn',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.item.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.item.category.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.subCategory'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.item.subCategory.name',
                    editable: false,
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.approximateCost'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.approximateCost',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.reasonForRepair'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.reasonForRepair',
                    editable: false,
                  },
                  {
                    label: 'asset.labels.status'.getLabel(),
                    type: 'text',
                    render: ({ data }) => {
                      let statusColor = '';
                      const status = data?.purchaseRequest?.purchaseRequestAsset?.requestStatus;
                      if (status === 'raised') { statusColor = vars.colors.emphasis; } else if (status === 'Order  Received') { statusColor = vars.colors.primary.color2; } else if (status === 'PO Rejected' || status === 'SO Rejected') { statusColor = vars.colors.error; } else { statusColor = vars.colors.warning; }
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                          <View style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: statusColor,
                            marginTop: 4.5,
                            marginLeft: 7,
                          }}
                          />
                        </View>
                      );
                    },
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.description'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestAsset.item.description',
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: 'purchaseRequest._id',
                    id: 'purchaseRequestAssetList',
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewDepartmentRepairRequests;
