/* eslint-disable no-shadow */
import React, { useState } from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { ViewPurchaseRequestListPharmacy } from '../../../../../Queries/purchaseRequestPharmacy';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { Action } from '../../../../../app-components/action/Action';
import { StandardButton, Text } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const CreatePurchaseOrder = (props) => {
  const { navigation } = props;
  const {
    state: {
      params: {
        purchaseSupplyOrderId = '',
        fromSupplyOrder = false,
        manufacturer,
        supplier,
        queryId,
        mandatoryFields = {},
        isMandatory = false,
      },
    },
  } = navigation;

  const [isCodeSelect, setIsCodeSelect] = useState(false);

  return (
    <Form
      {...props}
      type="standard"
      editable={false}
      uri={ViewPurchaseRequestListPharmacy({
        ...props,
        queryId,
        computation: (data) => {
          const newResult = {
            ...data[0],
            manufacturer: { name: manufacturer?.name },
            supplier: { name: supplier?.name },
          };
          return manufacturer && supplier ? [newResult] : data;
        },
      })}
      onSubmit={(submitProps) => {
        const { data } = submitProps;
        data.fromSupplyOrder = fromSupplyOrder;
        data.purchaseSupplyOrderId = purchaseSupplyOrderId;
        // if (fromSupplyOrder) {
        //   data.manufacturer = manufacturer;
        //   data.supplier = supplier;
        // }
        navigation.push({
          view: ROUTES.purchaseOrderModal.name,
          params: data,
        });
      }}
      reloadOnChangeEvent={`reload${MODELS.PHARMACY_ITEM}`}
      mandatory={{
        daysLimitForApproval: 1,
        code: 1,
        ...mandatoryFields,
      }}
      computations={{
        self: {
          autoFill: {
            compute: (data) => {
              const { code } = data || {};
              if (code?.conditions?.length) {
                setIsCodeSelect(true);
                const newConditions = code?.conditions?.map((el, index) => ({
                  ...el,
                  code: data?.code?.code,
                  sNo: index + 1,
                  selected: false,
                }));
                return {
                  set: {
                    conditions: newConditions,
                  },
                };
              }

              setIsCodeSelect(false);
              return {
                set: {
                  conditions: [],
                },
              };
            },
            onChange: ['code', 'checkBoxGroupInput'],
          },
          supplier: {
            compute: () => ({
              set: { manufacturer: null },
            }),
            onChange: ['supplier'],
          },
        },
        children: {
          conditions: {
            self: {
              conditions: {
                compute: (data) => {
                  const {
                    _parent: { conditions = [] },
                  } = data;
                  conditions[data?.sNo - 1].selected = !conditions[
                  data?.sNo - 1
                  ].selected;
                  const updatedConditions = conditions;
                  return {
                    set: {
                      conditions: updatedConditions,
                    },
                  };
                },
                onChange: ['checkBoxGroupInput'],
              },
            },
          },
        },
      }}
      closeView={0}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'purchase.labels.purchaseRequestNumber'.getLabel(),
                    field: 'purchaseRequestPharmacy.purchaseRequestNumber',
                    editable: false,
                  },
                  {
                    type: 'date',
                    label: 'purchase.labels.date'.getLabel(),
                    value: new Date(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.department'.getLabel(),
                    render: ({ data }) => (
                      <Text
                        style={{ ...vars.headings.h8 }}
                      >
                        {upperFirst(data?.type)}
                      </Text>
                    ),
                  },
                  {
                    type: 'checkBox',
                    label: 'purchase.labels.urgent'.getLabel(),
                    container: 'topLabel',
                    options: ['urgent'],
                    field: 'urgent',
                    editable: true,
                  },
                  {
                    type: 'number',
                    skipSelector: true,
                    label: 'purchase.labels.daysLimitForApproval'.getLabel(),
                    field: 'daysLimitForApproval',
                    editable: true,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Item Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            columnsPerRow: 6,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'purchase.labels.itemName'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.name',
                  },
                  // fromSupplyOrder
                  //   ? {
                  //     type: 'text',
                  //     label: 'purchase.labels.supplier'.getLabel(),

                  //     render: () => (
                  //       <Text style={{ ...vars.headings.h8 }}>
                  //         {
                  //           supplier?.name
                  //         }
                  //       </Text>
                  //     ),
                  //   }
                  //   :
                  searchInput({
                    field: 'supplier',
                    label: 'purchase.labels.supplier'.getLabel(),
                    placeholder: 'purchase.placeholders.selectSupplier'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUPPLIER,
                    query: 'masterDataList',
                    searching: true,
                    editable: true,
                    mandatory: isMandatory,
                  },
                  {
                    addOnFilter: ({
                      data: {
                        purchaseRequestPharmacy: {
                          item: {
                            rateContract,
                          },
                        },
                      },
                    }) => ({
                      _id: {
                        $in: rateContract.map(({ supplier, rateContractValidity }) => {
                          if (moment().diff(rateContractValidity, 'days') < 0) {
                            return supplier?._id;
                          }
                        }),
                      },
                    }),
                  }),
                  // fromSupplyOrder
                  //   ? {
                  //     type: 'text',
                  //     label: 'purchase.labels.manufacturer'.getLabel(),
                  //     render: () => (
                  //       <Text style={{ ...vars.headings.h8 }}>
                  //         {
                  //           manufacturer?.name
                  //         }
                  //       </Text>
                  //     ),
                  //   }
                  //   :
                  searchInput({
                    field: 'manufacturer',
                    label: 'purchase.labels.manufacturer'.getLabel(),
                    placeholder: 'purchase.placeholders.selectManufacturer'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_MANUFACTURER,
                    query: 'masterDataList',
                    searching: true,
                    editable: true,
                    mandatory: isMandatory,
                  },
                  {
                    addOnFilter: ({
                      data: {
                        purchaseRequestPharmacy: {
                          item: {
                            rateContract,
                          },
                        },
                        supplier: { _id },
                      },
                    }) => ({
                      _id: {
                        $in: rateContract.map(({ manufacturer }) => manufacturer?._id),
                      },
                      supplier: { _id },
                    }),
                  }),
                  {
                    type: 'text',
                    label: 'purchase.labels.purchaseQuantity'.getLabel(),
                    field: 'purchaseRequestPharmacy.requiredQuantity',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.unitPrice'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.sellingPrice',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.totalPrice'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {
                          data?.purchaseRequestPharmacy?.requiredQuantity
                          * data?.purchaseRequestPharmacy?.item?.sellingPrice
                        }
                      </Text>
                    ),
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: ' ',
                    value: '',
                    editable: false,
                  },
                  {
                    type: 'link',
                    render: (renderProps) => {
                      const { data, setValue } = renderProps;
                      return (
                        <Action
                          navigation={navigation}
                          action={{
                            type: 'link',
                            link: () => ({
                              view: ROUTES.editPurchaseDepartmentTC.name,
                              params: {
                                item: { _id: data?.code?._id },
                                setValue,
                                data,
                              },
                            }),
                          }}
                        >
                          {data?.code ? (
                            <StandardButton label="Add T&C" width={100} />
                          ) : null}
                        </Action>
                      );
                    },
                  },
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'code',
                    label: 'purchase.labels.typeTAndC'.getLabel(),
                    variant: 'filled',
                    keyFiled: 'name',
                    suggestionField: 'name',
                    valueField: 'name',
                    model: MODELS.PURCHASE_DEPARTMENT_TC,
                    query: 'masterDataListForTable',
                    editable: true,
                    mandatory: true,
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            label: isCodeSelect ? 'purchase.labels.termsAndConditions'.getLabel() : '',
            groups: [
              {

                columns: [
                  {
                    field: 'conditions',
                    fieldType: 'nested',
                    columnsPerRow: 3,
                    editable: true,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          {
                            type: 'checkBoxGroup',
                            field: 'checkBoxGroupInput',
                            header: ' ',
                            options: [{ value: '1' }],
                            width: 30,
                          },
                          {
                            type: 'text',
                            field: 'sNo',
                            variant: 'filled',
                            header: 'S No.',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            field: 'name',
                            variant: 'filled',
                            header: 'purchase.header.tAndCName'.getLabel(),
                            width: 300,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'description',
                            variant: 'filled',
                            header: 'purchase.header.description'.getLabel(),
                            editable: false,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default CreatePurchaseOrder;
