import React from 'react';
import MasterList from '../../../Wired/Master/MasterList';
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import { ToggleFilter } from '../../../app-components';

const tableColumns = [
  { width: 20 },
  {
    header: 'role.labels.role'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'role.labels.employee'.getLabel(),
    type: 'link',
    value: (props) => props?.item?.employee_count?._count,
    align: 'center',
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewUserListModal.name,
          modal: true,
          params: {
            item,
            filter: { role: { _id: item._id } },
            description: 'role.title.description'.getLabel(),
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'role.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
    width: 400,
  },
  {
    header: 'role.labels.status'.getLabel(),
    field: 'isActive',
    sortable: 'isActive',
    type: 'status',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.ROLE}
    editFormId={ROUTES.editRole.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyRole.permissions.modifyRole.value}
    header={{
      leftActions: [(actionProps) => <ToggleFilter {...actionProps} model={MODELS.ROLE} id="masterDataListForTable" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewRole.name,
        params: { item },
      },
    })}
  />
);
