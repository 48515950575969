import React from 'react';

import { MODELS, ROUTES } from '../../../Lib/constants';
import MasterList from '../../../Wired/Master/MasterList';
import vars from '../../../theme/vars';
import { Text } from '../../../app-components';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'Ward Category',
    type: 'text',
    field: 'name',
    sortable: true,
  },
  {
    header: 'roomCategory.labels.charges'.getLabel(),
    type: 'number',
    field: 'charge',
    sortable: true,
    width: 300,
  },
  {
    header: 'Status',
    type: 'text',
    render: ({ item: { isActive } = {} }) => {
      let statusColor = vars.colors.warning;
      if (isActive) {
        statusColor = vars.colors.primary.color2;
        return (
          <Text style={{ ...vars.headings.h11, color: statusColor }}>
            Active
          </Text>
        );
      }
      statusColor = vars.colors.error;
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          In-Active
        </Text>

      );
    },
  },
];

export default (props) => (

  <MasterList
    {...props}
    model={MODELS.ROOM_CATEGORY}
    editFormId={ROUTES.editRoomCategory.name}
    tableColumns={tableColumns}
  />

);
