import React from 'react';

import { viewVitalSignSheetUri } from '../../../../../../../../../Queries/vitalSign';
import ListActionModal from '../../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../../Lib/constants';

const IpdViewBloodSugarVitals = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Blood Sugar"
      description="Daily Blood Sugar of Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          type="standardShadow"
          uri={viewVitalSignSheetUri(filter)}
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'number',
                            field: 'employeeId',
                            label: 'Employee ID',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'name',
                            label: 'Employee name',
                            editable: false,
                          },
                        ],
                      },
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'vitalSignTime',
                            label: 'Date',
                            editable: false,
                            format: 'DD MM YYYY',
                          },
                          {
                            type: 'text',
                            field: 'nurseName',
                            label: 'Nurse Name',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'rbs',
                            label: 'RBS',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doseOfInsulin',
                            label: 'Dose Of Insulin (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'adviceBy',
                            label: 'Advice By',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const ViewBloodSugarVitals = (props) => (<IpdViewBloodSugarVitals {...props} />);
export default ViewBloodSugarVitals;
