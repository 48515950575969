import React from 'react';

import { cancelButton, save, StandardButton } from '../../../app-components';
import { AccountDetail, AccountNonEditDetail } from './AccountsForm';
import { ROUTES } from '../../../Lib/constants';
import { AccountList } from './AccountList';
import AddAccount from './AddAccount';

export default {
  [ROUTES.accountMasterList.name]: {
    screen: AccountList,
    headerActions: [{
      render: <StandardButton label="Add Account" />,
      type: 'link',
      link: {
        view: [ROUTES.addAccountMaster.name],
        routeOptions: {
          expanded: true,
        },
      },
    }],
  },
  [ROUTES.addAccountMaster.name]: {
    screen: AddAccount,
    headerActions: [cancelButton, save],
  },
  [ROUTES.editAccountMaster.name]: {
    screen: AccountDetail,
    headerActions: [cancelButton, save],
  },
  [ROUTES.viewAccountMaster.name]: {
    screen: AccountNonEditDetail,
    expanded: true,
    headerActions: [
      cancelButton,
      {
        render: <StandardButton label="Edit Account" />,
        type: 'link',
        link: ({ navigation }) => ({
          view: [ROUTES.editAccountMaster.name],
          routeOptions: {
            expanded: true,
          },
          params: {
            ...navigation?.state?.params,
          },
        }),
      },
    ],
  },
};
