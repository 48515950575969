/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import {
  ActivityIndicator,
  FormField,
  StandardButton,
  Toast,
  View,
} from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import ListActionModal from '../../../Components/ListActionModal';
import { detailIcon } from '../../../images';
import vars from '../../../theme/vars';
import { MODELS } from '../../../Lib/constants';
import { invoke } from '../../../AppServices';
import { autoSuggestInput } from '../../../autoSuggestions';
import { getErrorString } from '../../../Lib/helpers';

const AddAssetReturnDateModal = (props) => {
  const { navigation: { state: { params: { item: { _id } } } } } = props;

  const [loading, setLoading] = useState();

  const handleOnPressAction = useCallback(async (props) => {
    const { updates = {}, navigation, eventDispatcher } = props;
    try {
      setLoading(true);

      const result = await invoke({
        id: 'updateServiceStatusOnRecordDate',
        paramValue: {
          data: {
            ...updates,
            inService: false,
            _id,
          },
        },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Record return date has been added successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify(`reload${MODELS.ASSET}`);
      }
      setLoading(false);
      navigation.pop();
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      setLoading(false);
    }
  }, [_id]);

  if (loading) return <ActivityIndicator />;

  return (
    <ListActionModal
      icon={detailIcon}
      description="Update details of the item."
      title="Item Details"
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          mandatory={{
            recordReturnDate: 1,
            serviceDate: 1,
          }}
          defaultValues={{
            recordReturnDate: new Date(),
          }}
          closeView={1}
          onSubmit={handleOnPressAction}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{
                justifyContent: 'center',
              }}
              >
                <FormField
                  type="date"
                  label="Return Date"
                  variant="filled"
                  field="recordReturnDate"
                  mandatory
                />
              </View>
              <View style={{
                justifyContent: 'center',
                marginTop: 20,
              }}
              >
                <FormField
                  {...autoSuggestInput({
                    field: 'serviceDate',
                    variant: 'filled',
                    header: 'asset.headers.visitStatus'.getLabel(),
                    placeholder: 'asset.placeholders.serviceStatus'.getLabel(),
                    suggestionField: 'serviceDate',
                    keyField: 'serviceDate',
                    valueField: 'serviceDate',
                    model: MODELS.ASSET,
                    query: 'assetServiceDetail',
                    mandatory: true,
                  },
                  {
                    modifyResult: ({ data }) => {
                      const newData = [];
                      data.forEach((item) => {
                        if (item?.serviceItemDetails?.serviceStatus !== 'completed') {
                          newData.push({
                            ...item,
                            serviceDate: moment(item?.serviceItemDetails?.serviceDate).format('DD MMM YYYY'),
                          });
                        }
                      });
                      return { data: newData };
                    },
                    addOnFilter: () => ({
                      _id,
                    }),
                  })
                  }
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label="Confirm"
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default AddAssetReturnDateModal;
