import React, { useState } from 'react';

import {
  autoSuggestInput,
  multiAutoSuggestInput,
} from '../../../../../autoSuggestions';
import { editRoomAdmitIPDPatientUri } from '../../../../../Queries/patient';
// import { newDateInput } from '../../../../../compositeInputs';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { getString } from '../../../../../Lib/helpers';
import { Text } from '../../../../../app-components';
import vars from '../../../../../theme/vars';

const RoomAllot = (props) => {
  const { navigation } = props;
  const { tempLabel, setLabel } = useState('');
  return (
    <Form
      {...props}
      uri={editRoomAdmitIPDPatientUri({ navigation })}
      onSubmit={(data) => {
        navigation.push({
          view: ROUTES.consentForm.name,
          params: { data: data.data },
        });
      }}
      mandatory={{
        roomCategories: 1,
        rooms: 1,
        annualIncome: 1,
        'patient.address': 1,
        isMlrReport: 1,
        mlrReport: 1,
        refferalDoctor: 1,
        isRefferalDoctor: 1,
      }}
      reloadEvent={`reload${MODELS.IPD_ADMISSION}`}
      reloadOnChangeEvent={`reload${MODELS.IPD_ADMISSION}`}
      computations={{
        self: {
          // experience: {
          //   compute: (data) => {
          //     const beds = data?.rooms?.availableBeds;
          //     if (beds > 0) {
          //       return { set: { 'rooms.availableBeds': beds } };
          //     }
          //     return { set: { 'rooms.availableBeds': 0 } };
          //   },
          //   onChange: ['rooms'],
          // },
          roomCategories: {
            compute: (data) => {
              if (data?.roomCategories) {
                return {
                  set: { rooms: '', bed: '' },
                };
              }
              return { set: { rooms: data?.room } };
            },
            onChange: ['roomCategories'],
          },
          isRefferalDoctor: {
            compute: (data) => {
              if (data?.isRefferalDoctor) {
                return { set: { refferalDoctor: '' } };
              }
              return { set: { refferalDoctor: data?.refferalDoctor } };
            },
            onChange: ['isRefferalDoctor'],
          },
          isMlrReport: {
            compute: (data) => {
              if (data?.isMlrReport) {
                return {
                  set: {
                    mlrReport: '', mlrNo: '', mlrPlace: '', mlrPreparedBy: '',
                  },
                };
              }
            },
            onChange: ['isMlrReport'],
          },

          room: {
            compute: (data) => {
              if (data?.rooms) {
                return { set: { bed: '' } };
              }
              return { set: { bed: data?.bed } };
            },
            onChange: ['rooms'],
          },

        },
      }}
      type="standard"
      lg={{
        verticalFormGroups: [
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'number',
                        field: 'CRNumber',
                        label: 'CR Number',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'patient.lastConsultation.ODPNumber',
                        label: 'OPD Number',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'department.name',
                        label: 'Department',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'speciality.name',
                        label: 'Speciality',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'date',
                        defaultValue: new Date().toLocaleDateString(),
                        field: 'iPDBedsAllocation.startTime',
                        label: 'Time of Admission',
                        editable: false,
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'roomCategories',
                        label: 'Ward Category',
                        placeholder: 'Ward Category',
                        suggestionField: 'name',
                        model: MODELS.ROOM_CATEGORY,
                        query: 'masterDataList',
                        mandatory: true,
                        editable: true,
                      }),
                      autoSuggestInput(
                        {
                          type: 'autoSuggest',
                          field: 'rooms',
                          label: 'Room/Ward',
                          placeholder: 'Room/Ward',
                          suggestionField: 'name',
                          model: MODELS.ROOM,
                          query: 'masterDataList',
                          mandatory: true,
                          editable: true,
                        },
                        {
                          addOnFilter: ({ data } = {}) => ({
                            speciality: { _id: data?.speciality?._id },
                            roomCategories: { _id: data?.roomCategories?._id },
                            availableBeds: { $gt: 1 },
                          }),
                        },
                      ),
                      autoSuggestInput(
                        {
                          type: 'autoSuggest',
                          field: 'bed',
                          label: 'Bed',
                          placeholder: 'Bed',
                          suggestionField: 'name',
                          model: MODELS.BED,
                          query: 'masterDataList',
                          mandatory: true,
                          editable: true,
                          visible: ({ data }) => data?.roomCategories?.name === 'General Ward',
                        },
                        {
                          addOnFilter: ({ data } = {}) => ({
                            room: { _id: data?.rooms?._id },
                            // availableBeds: { $gt: 1 },
                          }),
                        },
                      ),
                      {
                        type: 'date',
                        field: 'startTime',
                        label: 'Date of Admission',
                        editable: false,
                        defaultValue: new Date().toLocaleTimeString(),
                      },
                      {
                        type: 'text',
                        field: 'patient.patientName',
                        label: 'Patient Name',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        label: 'patient.labels.relation'.getLabel(),
                        render: ({ data }) => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {getString([
                              data?.patient?.relation,
                              data?.patient?.relationName,
                            ])}
                          </Text>
                        ),
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'patient.gender',
                        label: 'Gender',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'patient.age',
                        label: 'Age',
                        mandatory: true,
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'text',
                        field: 'patient.address',
                        label: 'Address',
                        allowWhiteSpaces: true,
                        mandatory: true,
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'number',
                        field: 'patient.mobile',
                        label: 'Mobile',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: '',
                        label: 'Martial Status',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'annualIncome',
                        label: 'Annual Income',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'ICDCode',
                        label: 'ICD Code',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'checkBox',
                        field: 'isMlrReport',
                        showOuterLabel: true,
                        label: 'MLR Report',
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'file',
                        field: 'mlrReport',
                        label: 'MLR Report',
                        container: 'topLabel',
                        multiple: true,
                        mandatory: true,
                        visible: (visibleProps) => visibleProps?.data?.isMlrReport,
                      },
                      {
                        type: 'text',
                        field: 'mlrNo',
                        label: 'MLR No',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                        visible: (visibleProps) => visibleProps?.data?.isMlrReport,
                      },
                      {
                        type: 'text',
                        field: 'mlrPlace',
                        label: 'MLR Place',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                        visible: (visibleProps) => visibleProps?.data?.isMlrReport,

                      },
                      {
                        type: 'text',
                        field: 'mlrPreparedBy',
                        label: 'MLR Prepared By',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                        visible: (visibleProps) => visibleProps?.data?.isMlrReport,

                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        type: 'checkBox',
                        field: 'isRefferalDoctor',
                        showOuterLabel: true,
                        mandatory: true,
                        label: 'Refferal Doctor',
                      },
                      {
                        type: 'file',
                        field: 'refferalDoctor',
                        label: 'Refferal Doctor',
                        container: 'topLabel',
                        multiple: true,
                        mandatory: true,
                        visible: (visibleProps) => visibleProps?.data?.isRefferalDoctor,
                      },

                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'text',
                        field: 'finalDiagnosis',
                        label: 'Final Diagnosis',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      multiAutoSuggestInput({
                        field: 'notifyto',
                        variant: 'filled',
                        label: 'Notify To'.getLabel(),
                        placeholder: 'Notify To'.getLabel(),
                        // mandatory: true,
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.WARDS,
                        query: 'masterDataList',
                      }),
                      {
                        type: 'radioGroup',
                        field: 'outcome',
                        container: 'topLabel',
                        label: 'OutCome',
                        style: { flex: 2, overflow: 'hidden' },
                        options: [
                          { value: 'Discharge', label: 'Discharge' },
                          {
                            value: 'Left against medical Advice',
                            label: 'Left against medical Advice',
                          },
                          { value: 'Absconded', label: 'Absconded' },
                          {
                            value: 'Reffered to Higher Center',
                            label: 'Reffered to Higher Center',
                          },
                          { value: 'Death', label: 'Death' },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      multiAutoSuggestInput({
                        field: 'surgeonName',
                        variant: 'filled',
                        label: 'Surgeon Name'.getLabel(),
                        placeholder: 'Surgeon Name'.getLabel(),
                        // mandatory: true,
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                      }),
                      // {
                      //   addOnFilter: ({ data }) => {
                      //     const departmentId = data?.department?.map(({ _id }) => _id);
                      //     if (departmentId?.length) {
                      //       return {
                      //         _id: { $nin: departmentId },
                      //       };
                      //     }
                      //     return {};
                      //   },
                      // }
                      // {
                      //   type: 'text',
                      //   field: 'operationName',
                      //   label: 'Name of the Operation',
                      //   // mandatory: true,
                      //   editable: true,
                      // },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'operationName',
                        label: 'NAME OF OPERATION',
                        placeholder: 'name of operation',
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.OPERATIONS,
                        query: 'masterDataList',
                        editable: true,
                      }),
                      {
                        type: 'radioGroup',
                        field: 'operationDegree',
                        container: 'topLabel',
                        label: 'Operation Degree',
                        options: [
                          { value: 'Major', label: 'Major' },
                          { value: 'Minor', label: 'Minor' },
                        ],
                      },
                      {
                        type: 'text',
                        field: 'operationCode',
                        label: 'Operation Code',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'date',
                        field: 'dateOfDischarge',
                        label: 'Date of Discharge/Death/Lama',
                        // mandatory: true,
                      },
                      // newDateInput,
                      {
                        type: 'text',
                        field: 'causeOfDischarge',
                        label: 'Cause of Discharge/Death/Lama',
                        allowWhiteSpaces: true,
                        // mandatory: true,
                        editable: true,
                        style: { flex: 2, overflow: 'hidden' },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'checkBox',
                        field: 'noDues',
                        showOuterLabel: true,
                        label: 'No Dues',
                      },
                      {
                        type: 'text',
                        field: 'paymentStatus',
                        label: 'Payment Status',
                        container: 'topLabel',
                        mandatory: true,
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'consultant',
                        label: 'Consultant',
                        keyField: 'name',
                        searchField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'seniorResident',
                        label: 'Senior Resident',
                        keyField: 'name',
                        searchField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'juniorResident',
                        label: 'Junior Resident',
                        keyField: 'name',
                        searchField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                    ],
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default RoomAllot;
