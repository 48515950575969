import React, { useCallback, useEffect, useState } from 'react';

import { updateScale } from '@vx/scale';
import { update } from 'lodash';
import { save, Text, View } from '../../../../../../../../app-components';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { fetch, invoke, submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { FormField } from '../../../../../../../../app-components/form/field';
import MonitoringGraph from '../MonitoringGraph';

const NewAnesthesiaIPDForm = (props) => {
  const { eventDispatcher } = props;
  const [chartData, setChartData] = useState([]);
  const fetchChartData = useCallback(async () => {
    const fetchedData = await fetch({
      uri: {
        props: {
          query: {
            id: 'anesthesiaReportIPDDataList',
            addOnFilter: {
              _id: props?.navigation?.state?.params?.item?._id,
            },
          },
          model: MODELS.ANESTHESIA_REPORT,
        },
      },
    });
    setChartData(fetchedData?.data[0]?.monitoring);
  }, []);

  useEffect(() => {
    fetchChartData();
    eventDispatcher.listen(`reload${MODELS.Monitoring_Chart_Anesthesia}`, fetchChartData);
    return () => eventDispatcher.unlisten(`reload${MODELS.Monitoring_Chart_Anesthesia}`);
  }, [fetchChartData, eventDispatcher]);
  const HandleSubmit = async (submitProps) => {
    const { updates, data } = submitProps;
    const { navigation: { state: { params: { item } } } } = submitProps;

    const result = await invoke({
      id: 'updateAnesthesiaGraph',
      paramValue: {
        data: { ...data, anesthesiaId: item?._id },
        updates,
      },
    });
    setChartData(result?.monitoring);
  };
  return (
    <Form
      {...props}
      closeView={1}
      header={{ actions: [save()] }}
      type="standardShadow"
      uri={() => ({
        query: {
          id: 'anesthesiaReportIPDDataList',
          addOnFilter: {
            _id: props?.navigation?.state?.params?.item?._id,
          },
        },
        model: MODELS.ANESTHESIA_REPORT,
      })}
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        const { navigation: { state: { params: { item } } } } = props;
        updates._id = item._id;
        // updates.admissionID = item.admissionID;
        // updates.speciality = item.speciality;
        // updateScale.operationProcedure = item.operationProcedure;
        const newUpdate = {
          ...updates,
        };
        const submitAPI = submit({
          model: MODELS.ANESTHESIA_REPORT,
          ...submitProps,
          data,
          updates: newUpdate,
        });
        await submitAPI({ data, updates: newUpdate });
      }}
      reloadEvent={`reload${MODELS.ANESTHESIA_REPORT}`}
      reloadOnChangeEvent={`reload${MODELS.ANESTHESIA_REPORT}`}

      scrollable
    // defaultValues={{
    //   anesthesiaStartingMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    //   anesthesiaFinidhingMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    //   surgeryStartingMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    //   surgeryFinishingMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    //   monitoringMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    //   transferMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
    // }}
      mandatory={{
        otNumber: 1,
        // staffNurseName: 1,
        department: 1,
        isWrittenInformedConsent: 1,
        npoSince: 1,
        'gaExtubation.packremoved': 1,
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 16,
            }}
          >

            <View style={{
              marginLeft: 16,
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="number"
                  label="OT Number"
                  field="otNumber"
                  variant="filled"
                  mandatory
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {... autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'staffNurseName',
                    label: 'Staff Nurse Name',

                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    variant: 'filled',
                  },
                  {
                    addOnFilter: () => ({
                      role: {
                        code: 'Nurse',
                      },
                    }),
                  })}
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'department',
                    label: 'Department Name',

                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    variant: 'filled',
                    mandatory: true,
                  })}
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >
                <FormField
                  type="radioGroup"
                  field="isWrittenInformedConsent"
                  variant="filled"
                  label="WRITTEN INFORMED CONSENT"
                  container="topLabel"
                  options={['Yes', 'No']}
                  mandatory="true"
                />
              </View>
            </View>
            <View style={{
              marginLeft: 16,
              width: '24%',
              marginRight: '1%',
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                {...autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'npoSince',
                  mandatory: true,
                  label: 'NPO Since (Hrs.)',
                  defaultValue: 0,
                  options: [0, 2, 4, 6, 8, 10],
                  variant: 'filled',
                })}
              />
            </View>

            {/* Pre-Anesthesia */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
            >

              1. Pre-Anesthetic Status
            </Text>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            >
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="CC"
                  field="preAnesthesiaState.cc"
                  variant="filled"
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >

                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Pulse"
                  field="preAnesthesiaState.pulse"
                  variant="filled"
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >

                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="BP"
                  field="preAnesthesiaState.bp"
                  variant="filled"
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >

                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="RR"
                  field="preAnesthesiaState.rr"
                  variant="filled"
                />
              </View>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
            }}
            >
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >

                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="SPO2"
                  field="preAnesthesiaState.spo2"
                  variant="filled"
                />
              </View>
              <View style={{
                width: '24%',
                marginRight: '1%',
              }}
              >

                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Temperature"
                  field="preAnesthesiaState.temperature"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ marginBottom: 10 }}>
              <Text
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',

                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#333333',
                }}
              >

                Systematic Examination
              </Text>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <Text
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                  width: '50%',
                }}
              >

                a. CVS
              </Text>
              <Text
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                  width: '50%',

                }}
              >

                b. Respiratory
              </Text>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="S1S2"
                  field="preAnesthesiaCVS.s1s2"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '4%' }}>
                {' '}
                <Text
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    fontSize: 14,
                    lineHeight: 14,
                    color: '#333333',
                    width: '50%',

                  }}
                >
                  Type
                </Text>
              </View>
              <View style={{
                marginRight: '1%', width: '24%', display: 'flex', flexDirection: 'row',
              }}
              >
                {' '}

                <View style={{ marginTop: 13 }}>
                  <FormField
                    type="checkBox"
                    field="preAnesthesiaCVS.type"
                    variant="filled"
                    label="Murmur"
                  />
                </View>
                <View style={{ marginLeft: 10 }}>
                  <FormField
                    type="text"
                    field="preAnesthesiaCVS.murmurDescription"
                    variant="filled"
                    label="Description"
                  />

                </View>
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  field="preAnesthesiaRespiratory.isBilairentry"
                  type="checkBox"
                  label="Bilairentry"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                  // height: 100,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="textArea"
                  label="Other Sounds"
                  field="preAnesthesiaRespiratory.otherSounds"
                  variant="filled"
                />
              </View>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <Text
                style={{
                  width: '50%',
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                }}
              >

                c. P/A
              </Text>
              <Text
                style={{
                  width: '50%',
                  marginTop: 20,
                  marginBottom: 20,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                }}
              >

                d. Indwelling Catheters
              </Text>
            </View>

            <View style={{
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <View style={{ width: '24%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaAbd.isSoft"
                    type="checkBox"
                    label="Soft"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '24%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaAbd.isDistended"
                    type="checkBox"
                    label="Distended"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '24%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaAbd.isBowelSound"
                    type="checkBox"
                    label="Bowel Sound"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '24%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaAbd.isCreptzOrWheez"
                    type="checkBox"
                    label="Creptz/Wheez"
                    variant="filled"
                  />
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >

                <View style={{ width: '17%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaIndwellingCatheters.isRylesTube"
                    type="checkBox"
                    label="Ryles tube"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '17%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaIndwellingCatheters.isFoleys"
                    type="checkBox"
                    label="Foleys"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '17%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaIndwellingCatheters.isETT"
                    type="checkBox"
                    label="Ett"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '17%', marginRight: '1%' }}>
                  <FormField
                    field="preAnesthesiaIndwellingCatheters.isCVC"
                    type="checkBox"
                    label="CVC"
                    variant="filled"
                  />
                </View>
                <View style={{ width: '17%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      width: 250,
                      position: 'relative',
                      right: 50,
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    field="preAnesthesiaIndwellingCatheters.catheters"
                    type="textArea"
                    label="Catheters"
                    variant="filled"
                  />
                </View>
              </View>

            </View>

            {/* Anesthesia */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              2. Anesthesia
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...newDateInput({
                    hour: { field: 'anesthesiaStartingHour', label: 'Hour' },
                    minute: { field: 'anesthesiaStartingMinute', label: 'Minute' },
                    meridiem: { field: 'anesthesiaStartingMeridiem', label: 'Meridiem' },
                  })}
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...newDateInput({
                    hour: { field: 'anesthesiaFinidhingHour', label: 'Hour' },
                    minute: { field: 'anesthesiaFinidhingMinute', label: 'Minute' },
                    meridiem: { field: 'anesthesiaFinidhingMeridiem' },
                  })}
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Duration (Hrs.)"
                  field="anesthesiaDuration"
                  variant="filled"
                />
              </View>
            </View>

            {/* Surgery */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              3. Surgery
            </Text>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...newDateInput({
                    hour: { field: 'surgeryStartingHour', label: 'Hour' },
                    minute: { field: 'surgeryStartingMinute', label: 'Minute' },
                    meridiem: { field: 'surgeryStartingMeridiem' },
                  })}
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...newDateInput({
                    hour: { field: 'surgeryFinishinghour', label: 'Hour' },
                    minute: { field: 'surgeryFinishingMinute', label: 'Minute' },
                    meridiem: { field: 'surgeryFinishingMeridiem' },
                  })}
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Duration (Hrs.)"
                  field="surgeryDuration"
                  variant="filled"
                />
              </View>
            </View>

            {/* PLAN OF ANESTHESIA */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              4. Plan Of Anaesthesia
            </Text>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 16,
            }}
            >
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isGA"
                  label="i. GA"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isMAC"
                  label="ii. MAC"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isSA"
                  label="iii. SA"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isCSE"
                  label="iv. CSE"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isEpidural"
                  label="v. EPIDURAL"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isTiva"
                  label="vi. TIVA"
                />
              </View>
              <View style={{ marginRight: '1%', width: '10%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="planOfAnesthesia.isRegionalBlock"
                  label="vii. REGIONAL BLOCK"
                />
              </View>
            </View>

            {/* CONDUCT OF ANESTHESIA */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              5. Conduct Of Anesthesia
            </Text>
            <Text
              style={{
              // marginTop: 10,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              a. Monitoring
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isECG"
                  label="i ECG"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isHR"
                  label="ii HR"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isSPO2"
                  label="iii SPO2"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isNIBP"
                  label="iv NIBP"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isIBP"
                  label="v IBP"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isTemp"
                  label="vi TEMP"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="anesthesiaMonitoring.isEtCO2"
                  label="vii EtCO2"
                />
              </View>
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              b. Intravenous Line
            </Text>

            <View
              style={{
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  width: '50%',
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                }}
              >

                i. Peripheral Line
              </Text>
              <Text
                style={{
                  width: '50%',
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  color: '#333333',
                }}
              >

                ii. Central VC
              </Text>
            </View>

            <View
              style={{
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '50%',
                }}
              >
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is16G"
                    label="16G"
                  />
                </View>
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is18G"
                    label="18G"
                  />
                </View>
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is20G"
                    label="20G"
                  />
                </View>
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is22G"
                    label="22G"
                  />
                </View>
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is24G"
                    label="24G"
                  />
                </View>
                <View style={{ width: '15%', marginRight: '1%' }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="anesthesiaIntravenousPeripheralLine.is26G"
                    label="26G"
                  />
                </View>
              </View>

              <View style={{ width: '25%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  field="anesthesiaIntravenousCentralVC"
                  type="text"
                  label=""
                  variant="filled"
                />
              </View>
            </View>

            {/* GA */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              6. GA
            </Text>
            <Text
              style={{
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              A. Pre-Medication
            </Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '25%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="GlycoPyrolate"
                  field="gaPreMediation.glycoPyrolate"
                  variant="filled"
                />
              </View>
              <View style={{ width: '25%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Midazolam"
                  field="gaPreMediation.midazolam"
                  variant="filled"
                />
              </View>
              <View style={{ width: '25%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Fentanyl"
                  field="gaPreMediation.fentanyl"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ width: '50%', marginRight: '1%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  marginTop: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="Others"
                field="gaPreMediation.others"
                variant="filled"
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              b. Pre-Oxygenation
            </Text>

            <View
              style={{
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormField
                type="radioGroup"
                field="gaPreOxygenation"
                options={['Yes', 'No', 'RSI']}
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              c. Induction
            </Text>

            <Text
              style={{
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              i. Drugs
            </Text>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Propofol"
                  field="gaInductionDrugAgent.propofol"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Thiopentane"
                  field="gaInductionDrugAgent.thiopentane"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Ketamine"
                  field="gaInductionDrugAgent.ketamine"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Etomidate"
                  field="gaInductionDrugAgent.etomidate"
                  variant="filled"
                />
              </View>
            </View>

            <View style={{ width: '49%', marginTop: 10 }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="Others"
                field="gaInductionDrugAgent.others"
                variant="filled"
              />
            </View>

            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              Muscle Relaxant
            </Text>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            >
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Succinylcholine"
                  field="gaInductionDrugRelaxant.succinylcholine"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Vesuronium"
                  field="gaInductionDrugRelaxant.vesuronium"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Rocuronium"
                  field="gaInductionDrugRelaxant.rocuronium"
                  variant="filled"
                />
              </View>
              <View style={{ marginRight: '1%', width: '24%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Atracurium"
                  field="gaInductionDrugRelaxant.atracurium"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ width: '49%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  marginTop: 20,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="Others"
                field="gaInductionDrugRelaxant.others"
                variant="filled"
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              Miscellaneous
            </Text>
            <View style={{ }}>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                label="Succinylcholine"
                field="gaInductionDrugMiscellaneous"
                variant="filled"
              />
            </View>

            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              ii. Airway
            </Text>
            <View>
              <FormField
                type="radioGroup"
                options={['ETT', 'SGA', 'Mask']}
                field="gaAirway"
                variant="filled"
              />
            </View>
            <Text
              style={{
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              ETT
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >

              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                  }}
                  container="topLabel"
                  type="radioGroup"
                  label="Type"
                  options={['PVC', 'Armoured', 'RAC', 'DLT']}
                  field="gaAirwayETT.ettType"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'gaAirwayETT.ettSize',
                    label: 'Size',
                    options: [
                      2.5, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
                    ],
                    variant: 'filled',
                  })}
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                  }}
                  type="radioGroup"
                  container="topLabel"
                  label="Cuffed/Uncuffed"
                  options={['Cuffed', 'Uncuffed']}
                  field="gaAirwayETT.ettCuffedorNot"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Fixed AT"
                  field="gaAirwayETT.ettFixedAt"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ width: '24%', marginRight: '1%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                }}
                container="topLabel"
                label="DL/VC/FO"
                type="radioGroup"
                options={['DL', 'VC', 'FO Guided']}
                field="gaAirwayETT.dlOrVCorFO"
                variant="filled"
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              SGA
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '25%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="type"
                  field="gaAirwaySGA.sgaType"

                  variant="filled"
                />
              </View>
              <View style={{ width: '25%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'gaAirwaySGA.sgaSize',
                    label: 'Size',
                    options: [1, 1.5, 2, 2.5, 3, 4, 5],
                    variant: 'filled',
                  })}
                />
              </View>
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              Confirmation
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  container="topLabel"
                  type="radioGroup"
                  label="B/L A/E"
                  options={['Yes', 'No']}
                  field="gaAirwayConfirmation.borLorAorE"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  container="topLabel"
                  type="radioGroup"
                  label="Capnography"
                  options={['Yes', 'No']}
                  field="gaAirwayConfirmation.capnography"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  container="topLabel"
                  type="radioGroup"
                  label="Chest Rise"
                  options={['Yes', 'No']}
                  field="gaAirwayConfirmation.chestRise"
                  variant="filled"
                />
              </View>
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              d. Maintenance
            </Text>
            <View>
              <FormField
                container="topLabel"
                type="radioGroup"
                options={['SPONT', 'IPPV', 'PSV']}
                field=""
                variant="filled"
              />
            </View>
            <Text
              style={{
                marginTop: 10,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              i. Induction Agent
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >

              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgent.isHalothane"
                  label="i Halothane"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgent.isSevoflurane"
                  label="ii Sevoflurane"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgent.isIsoflurane"
                  label="iii isoflurane"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgent.isDesflurane"
                  label="iv Desflurane"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgent.isNitrusOxide"
                  label="v Nitrus Oxide"
                />
              </View>
              <View style={{ width: '7%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaInductionAgentis.MAC"
                  label="vi MAC"
                />
              </View>
            </View>
            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              ii. Muscle Relaxant
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >

              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="SUCCINYLCHOLINE"
                  field="gaMuscleRelaxant.succinylcholine"

                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="VESURONIUM"
                  field="gaMuscleRelaxant.vesuronium"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="ROCURONIUM"
                  field="gaMuscleRelaxant.rocuronium"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="ETOMIDATE"
                  field="gaMuscleRelaxant.atracurium"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ width: '49%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  marginTop: 20,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="OTHERS"
                field="gaMuscleRelaxant.others"
                variant="filled"
              />
            </View>
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              iii. Miscellaneous
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                label=""
                field="gaMiscellaneous"
                variant="filled"
              />
            </View>

            {/* done */}

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              e. Infusion
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="PROPOFOL"
                  field="gaInfusion.propofol"

                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="VECURONIUM"
                  field="gaInfusion.vecuronium"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="ATRACURIUM"
                  field="gaInfusion.atracurium"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="FENTANYL"
                  field="gaInfusion.fentanyl"
                  variant="filled"
                />
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 14 }}>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="dEXMED"
                  field="gaInfusion.dexmed"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="NOR-ADR"
                  field="gaInfusion.norADR"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="ADR"
                  field="gaInfusion.adr"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="VASOPRESSIN"
                  field="gaInfusion.vasopressin"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 14 }}>

              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="Dopamine"
                  field="gaInfusion.dopamine"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"

                  label="NTG"
                  field="gaInfusion.ntg"
                  variant="filled"
                />
              </View>
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              f. IVF
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                label=""
                field="gaIvf"
                variant="filled"
              />
            </View>
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              g. BLOOD PRODUCTS
            </Text>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="WHOLE BLOOD"
                  field="gaBloodProduct.wholeBlood"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="PRBC"
                  field="gaBloodProduct.prbc"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="FFP"
                  field="gaBloodProduct.ffp"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Platelet"
                  field="gaBloodProduct.platelet"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ width: '49%', marginRight: '1%' }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  marginTop: 20,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="Others"
                field="gaBloodProduct.others"
                variant="filled"
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              h. Reversal
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '1%', marginRight: '0.5%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaReversal.isNeostigmine"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Neostigmine"
                  field="gaReversal.Neostigmine"
                  variant="filled"
                />
              </View>
              <View style={{ width: '1%', marginRight: '0.5%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaReversal.isGlycopyrrolate"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="glycopyrrolate"
                  field="gaReversal.glycopyrrolate"
                  variant="filled"
                />
              </View>
              <View style={{ width: '1%', marginRight: '0.5%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="checkBox"
                  field="gaReversal.isAtropine"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Atropine"
                  field="gaReversal.atropine"
                  variant="filled"
                />
              </View>
            </View>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  marginTop: 20,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                label="Details"
                field="gaReversal.details"
                variant="filled"
              />
            </View>

            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              i. Extubated
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  type="radioGroup"
                  field="gaExtubation.packremoved"
                  variant="filled"
                  label="Pack Removed"
                  container="topLabel"
                  mandatory
                  options={['Yes', 'No']}
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  type="radioGroup"
                  field="gaExtubation.suctionDone"
                  variant="filled"
                  label="Suction Done"
                  container="topLabel"
                  options={['Yes', 'No']}
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  type="radioGroup"
                  field="gaExtubation.extubated"
                  variant="filled"
                  label="Extubated"
                  container="topLabel"
                  options={['Yes', 'No']}
                />
              </View>
            </View>

            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  marginTop: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="gaExtubation.details"
                variant="filled"
                label="Details"
              />
            </View>

            {/* REGIONAL BLOCK */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              7. Regional Block
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Name"
                  field="regionalBlock.name"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Drug"
                  field="regionalBlock.drug"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Technique"
                  field="regionalBlock.technique"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Needle"
                  field="regionalBlock.needle"
                  variant="filled"
                />
              </View>
            </View>

            {/* NEURAXIAL BLOCK */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              8. Neuraxial Block
            </Text>

            <View>
              <FormField
                type="radioGroup"
                field="neuraxialBlock"
                options={['SA', 'Epidural', 'CSE']}
              />
            </View>

            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              a. SA
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Lumbar Puncture Level"
                  field="neuraxialSA.lumbarPunctureLevel"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Needle"
                  field="neuraxialSA.needle"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Drug"
                  field="neuraxialSA.drug"
                  variant="filled"
                />
              </View>
            </View>
            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              b. Epidural
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Site of needle puncture"
                  field="neuraxialEpidural.siteOfNeedlePuncture"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Size"
                  field="neuraxialEpidural.size"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Cathefar Fixed At"
                  field="neuraxialEpidural.cathefarFixedAt"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Text Dose"
                  field="neuraxialEpidural.textDose"
                  variant="filled"
                />
              </View>
            </View>

            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              c. CSE
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Site Of Needle Puncture"
                  field="neuraxialCSE.siteOfNeedlePuncture"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Size"
                  field="neuraxialCSE.size"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Cathefar Fixed At"
                  field="neuraxialCSE.cathefarFixedAt"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Text Dose"
                  field="neuraxialCSE.textDose"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    marginTop: 20,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Spinal Drug"
                  field="neuraxialCSE.spinalDrug"
                  variant="filled"
                />
              </View>
              <View style={{ width: '72%', marginRight: '1%' }}>
                <FormField
                  style={{
                    height: 100,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    marginTop: 20,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="textArea"
                  label="Miscellaneous"
                  field="neuraxialCSE.miscellaneous"
                  variant="filled"
                />
              </View>
            </View>

            {/* MAC */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              8. MAC
            </Text>
            <View style={{ width: '24%', marginRight: '1%' }}>
              <FormField
                style={{
                  width: 300,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                label="Mac Supplementary Oxygen"
                field="macSupplementaryOxygen"
                variant="filled"
              />
            </View>

            {/* MONITORING */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              MONITORING
            </Text>
            <Text
              style={{
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              9. SPO2/HR/ETCO2/SBP/DBP?/CVP/TEMP
            </Text>
            <View>
              <Form
                // {...props}
                footer={{
                  actions: [
                    save,
                  ],
                }}
                bodyContainerStyle={{ backgroundColor: vars.colors.white }}
                mandatory={{
                  'monitoring.time': 1,
                }}
                uri={() => ({
                  query: {
                    id: 'monitoringChartDataList',
                    addOnFilter: {
                      _id: props?.navigation?.state?.params?.item?._id,
                    },
                  },
                  model: MODELS.Monitoring_Chart_Anesthesia,
                })}
                onSubmit={HandleSubmit}
                {...props}
                lg={{
                  formGroups: [
                    {
                      columnsPerRow: 2,
                      fieldVariant: 'filled',
                      groupsContainerStyle: { paddingLeft: 12 },
                      groups: [
                        {
                          columnsPerRow: 4,
                          columns: [
                            {
                              type: 'text',
                              field: 'monitoring.spo2',
                              editable: true,
                              label: 'SPO2',
                            },
                            // dateInputForOutcome({
                            //   hourField: 'hour',
                            //   minField: 'minute',
                            //   meridiemField: 'meridiem',
                            // }),
                            {
                              type: 'text',
                              field: 'monitoring.hr',
                              editable: true,
                              label: 'HR',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.etco2',
                              editable: true,
                              label: 'ETCO2',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.sbp',
                              editable: true,
                              label: 'SBP',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.dbp',
                              editable: true,
                              label: 'DBP',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.cvp',
                              editable: true,
                              label: 'CVP',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.temp',
                              editable: true,
                              label: 'TEMPERATURE',
                            },
                            {
                              type: 'text',
                              field: 'monitoring.time',
                              editable: true,
                              label: 'TIME',
                              mandatory: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                }}
              />
            </View>

            <View
              style={{
                height: 500,
                marginTop: '10px',
                marginBottom: '22px',
              }}
            >
              {/*
              <MonitoringGraph data={data} />
              data will have in formate - array of JSON
              */}
              {chartData
                ? (
                  <MonitoringGraph
                    data={chartData}
                    {...props}
                  />
                ) : (
                  <MonitoringGraph
                    {...props}
                  />
                  // <MonitoringGraph />
                )}
            </View>

            {/* URINE OUTPUT */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              10. Urine Output
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="urineOutput"
                variant="filled"
              />
            </View>

            {/* BLOOD LOSS */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              11. Blood Loss
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="bloodLoss"
                variant="filled"
              />
            </View>

            {/* INTRA OPERATIVE EVENT */}

            <Text
              style={{
                width: 1500,
                marginTop: 20,
                marginBottom: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',

                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              12. Intra-Operative Event
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="intraOperativeEvent"
                variant="filled"
              />
            </View>

            {/* RECOVERY */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              13. Recovery
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '49%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Consciousness"
                  field="recovery.consciousness"
                  variant="filled"
                />
              </View>
              <View style={{ width: '49%', marginRight: '1%' }}>
                <FormField
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Reflexes"
                  field="recovery.reflexes"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              <View style={{ width: '49%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Spontaneous Respiration"
                  field="recovery.spontaneousRespiration"
                  variant="filled"
                />
              </View>
              <View style={{ width: '49%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Muscle Power"
                  field="recovery.musclePower"
                  variant="filled"
                />
              </View>
            </View>

            {/* VITALS */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              14. Vitals
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="PULSE"
                  field="vitals.pulse"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="BP"
                  field="vitals.bp"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="RR"
                  field="vitals.rr"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="SPO2"
                  field="vitals.spo2"
                  variant="filled"
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="G.C"
                  field="vitals.gc"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="text"
                  label="Others"
                  field="vitals.others"
                  variant="filled"
                />
              </View>
            </View>

            {/* ADVICE */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >

              15. Advice
            </Text>
            <View>
              <FormField
                style={{
                  height: 100,
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="advice"
                variant="filled"
              />
            </View>

            {/* SHIFTED TO */}
            <Text
              style={{
                marginTop: 20,
                marginBottom: 20,
                fontFamily: 'public_sans_regular',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                color: '#333333',
              }}
            >
              16. Shifted To
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 20,
              }}
            >
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'shiftedTo.department',
                    label: 'Department Name',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    variant: 'filled',
                  })}
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="date"
                  label="Date"
                  field="shiftedTo.date"
                  variant="filled"
                />
              </View>
              <View style={{ width: '24%', marginRight: '1%' }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  {...newDateInput({
                    hour: { field: 'transferHour', label: 'Hour' },
                    minute: { field: 'transferMinute', label: 'Minutes' },
                    meridiem: { field: 'transferMeridiem' },
                  })}
                />
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};
export default NewAnesthesiaIPDForm;
