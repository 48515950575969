/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';

import {
  ActivityIndicator,
  FormField, LargeButton, Toast, View,
} from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { invoke } from '../../../AppServices';
import ListActionModal from '../../../Components/ListActionModal';
import { history } from '../../../images';
import { getErrorString } from '../../../Lib/helpers';
import vars from '../../../theme/vars';

const RejectRequestSavingHead = (props) => {
  const {
    navigation,
    eventDispatcher,
  } = props;

  const data = navigation.getParam('data');
  const field = navigation.getParam('field');
  const service = navigation.getParam('service');
  const status = navigation.getParam('status');
  const successMessage = navigation.getParam('successMessage');
  const tax_id = navigation.getParam('tax_id');
  const parser = navigation.getParam('parser');
  const updates = navigation.getParam('updates');

  const [loading, setLoading] = useState();

  const handleOnPressAction = useCallback(async (params) => {
    const { reason } = params?.data || {};

    try {
      setLoading(true);
      let finalUpdates = {};

      if (updates) {
        finalUpdates = parser({
          updates,
          status,
          reason,
        });
      }

      const result = await invoke({
        id: service,
        paramValue: {
          data,
          tax_id,
          field,
          status,
          reason,
          updates: finalUpdates,
        },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: successMessage,
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify('UserTaxDeclarationList');
        eventDispatcher.notify('reloadTaxSectionCards');
      }
      setLoading(false);
      navigation.pop();
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      navigation.pop();
      setLoading(false);
    }
  }, [
    data,
    eventDispatcher,
    field,
    navigation,
    parser,
    service,
    status,
    successMessage,
    tax_id,
    updates,
  ]);

  if (loading) return <ActivityIndicator />;

  return (
    <ListActionModal
      icon={history}
      title="Update"
      description="Please write the reason"
      content={(
        <Form
          onSubmit={handleOnPressAction}
          mandatory={{
            reason: 1,
          }}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <>
                  <FormField
                    type="textArea"
                    field="reason"
                    placeholder="Enter Reason"
                    label="Enter Reason"
                    variant="filled"
                  />
                  <View
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <LargeButton
                      label="Submit"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default RejectRequestSavingHead;
