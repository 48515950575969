import React from 'react';

import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../../Components/TableFilters/FtsSearch';
import ViewInChargeAssetDetail from '../../../../../../Wired/AssetDetails/ViewInchargeRecords/Components/assetItemDetail';
import { tabTheme } from '../../tabTheme';
import AssetServiceDetail from '../../../../../../Wired/AssetDetails/ViewInchargeRecords/Components/assetServiceDetail';
import AssetLoanOutHistory from '../../../../../../Wired/AssetDetails/ViewInchargeRecords/Components/loanOutHistory';
import AssetRepairHistory from '../../../../../../Wired/AssetDetails/ViewInchargeRecords/Components/repairHistory';
import AssetItemMonthlyRemarksList from '../../../../../../Wired/AssetDetails/ViewInchargeRecords/Components/monthlyRemarksHistory';

const ViewInChargeTabs = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        itemDetail: {
          label: 'Item Detail',
          screen: ViewInChargeAssetDetail,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        serviceHistory: {
          label: 'Service History',
          screen: AssetServiceDetail,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        loanOutHistory: {
          label: 'Loan Out History',
          screen: AssetLoanOutHistory,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        repairHistory: {
          label: 'Repair History',
          screen: AssetRepairHistory,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        monthWiseRemarks: {
          label: 'Monthwise Remark',
          screen: AssetItemMonthlyRemarksList,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
          actions: [
            <FtsSearch
              action={{ exp: ['month'] }}
              placeholder="Search by month name"
              key="search"
            />,
          ],
        },
      }}

    />
  );
};
export default ViewInChargeTabs;
