import React from 'react';
import {
  FormField, LargeButton, OutlineButton, View,
} from '../../../app-components';
import RelationInput from '../../../Components/RelationInput';
import { ROUTES } from '../../../Lib/constants';

const ExistingPatientForm = (props) => {
  const { navigation } = props;
  const {
    form_state: { data = {} } = {},
    form_context: { handleSubmit },
  } = props;

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View>
          <FormField
            label={'patient.labels.patientName'.getLabel()}
            field="patientName"
            placeholder={'patient.placeholders.patientName'.getLabel()}
            type="text"
            variant="filled"
            mandatory
            allowWhiteSpaces
          />
        </View>
        <View
          style={{
            marginTop: 20,
            flexDirection: 'row',
          }}
        >
          <FormField
            type="custom"
            render={RelationInput}
            // label="S/O. D/O, W/O"
            // field="relationName"
            // placeholder="Relation Name"
            // type="text"
            // variant="filled"
            // allowWhiteSpaces
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
        <View>
          <OutlineButton
            label={'patient.labels.register'.getLabel()}
            width={120}
            borderRadius={4}
            onClick={() => navigation.push({ view: ROUTES.addPatient.name })}
          />
        </View>
        <View style={{ marginLeft: 22 }}>
          <LargeButton
            label={'patient.labels.searchPatient'.getLabel()}
            width={160}
            borderRadius={4}
            onClick={() => handleSubmit(data?.patientName)}
          />
        </View>
      </View>
    </View>
  );
};

export default ExistingPatientForm;
