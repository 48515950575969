/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { size } from 'lodash';
import {
  FormField,
  Image,
  Text,
  Toast,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { autoSuggestInput, searchInput } from '../../../../autoSuggestions';
import { fetch, getUser, submit } from '../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import printPharmacyBill from '../../../../Wired/PatientInvoices';
import { getString, sortByKey } from '../../../../Lib/helpers';
import { MODELS } from '../../../../Lib/constants';

const NewBill = (props) => {
  const { navigation: { state: { params: { data: patientInfo } } } } = props;

  const [loading, setLoading] = useState(true);
  const [itemSuggestion, setItemSuggestion] = useState();
  const [prescribedItems, setPrescribedItems] = useState([]);
  const [notAvailableStocks, setNotAvailableStocks] = useState([]);

  const fetchItemSuggestionDetails = useCallback(async () => {
    const fetchedItemSuggestionDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'pharmacyFrequentlySuggestedItemList',
          },
          model: MODELS.PHARMACY_FREQUENTLY_ITEM_SUGGESTION,
          limit: 10,
        },
      },
    });
    setItemSuggestion(fetchedItemSuggestionDetails?.data);
    return fetchedItemSuggestionDetails;
  }, []);

  const fetchItemFromDepartmentStocks = useCallback(async () => {
    const itemIds = patientInfo[0]?.lastConsultation?.pharmacyDetails?.map(({ item }) => item._id);
    const itemIdsWithQuantity = patientInfo[0]?.lastConsultation?.pharmacyDetails?.map((
      { quantity, item },
    ) => ({ item: { _id: item?._id, name: item.name }, quantity }));

    const fetchedDepartmentStocks = await fetch({
      uri: {
        props: {
          query: {
            id: 'pharmacyDepartmentInventoryStocksDataList',
            addOnFilter: {
              item: {
                _id: {
                  $in: itemIds,
                },
              },
              department: {
                _id: { $in: departmentId },
              },
            },
          },
          model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
          limit: 10,
        },
      },
    });

    const clonedFetchedDepartmentStocks = sortByKey(fetchedDepartmentStocks.data, 'stockAvailable');

    const clonedStocks = [];
    clonedFetchedDepartmentStocks.forEach((item) => {
      const quantity = itemIdsWithQuantity.find((
        { item: clonedItem },
      ) => clonedItem?._id === item?.item?._id)?.quantity;

      const found = clonedStocks.find((stock) => stock.item._id === item.item._id);

      if (!found) {
        clonedStocks.push({
          ...item,
          item: {
            ...item.item,
            department: item.department,
          },
          itemName: item.item,
          department: item.department,
          _id: item.item._id,
          category: item?.item?.category,
          unitPrice: item?.item?.sellingPrice,
          manufacturer: item?.item?.rateContract[0]?.manufacturer?.name,
          stockAvailable: item?.stockAvailable,
          totalPrice: item?.item?.sellingPrice * quantity,
          quantity,
        });
      }
    });

    setPrescribedItems(clonedStocks || []);

    const stocksNotAvailable = [];
    itemIdsWithQuantity.forEach((item) => {
      const found = clonedStocks.find((stock) => stock?._id === item?.item?._id);
      if (!found) {
        stocksNotAvailable.push(item);
      }
    });

    setNotAvailableStocks(stocksNotAvailable);

    return fetchedDepartmentStocks;
  }, [patientInfo]);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        await fetchItemSuggestionDetails();
        await fetchItemFromDepartmentStocks();
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }());
  }, [fetchItemSuggestionDetails]);

  const user = getUser();
  const { employee: { department = [] } = {} } = user;
  const { employee } = user;

  const [departmentId] = useState(department?.map(({ _id }) => _id));
  if (!department[0]?._id) {
    Toast.show({
      message: 'Error',
      description: 'inventory.messages.loginUserError'.getLabel(),
      type: 'error',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    return null;
  }

  if (loading) return <ActivityIndicator />;

  return (
    <Form
      containerStyle={{
        backgroundColor: vars.colors.secondary.color4,
        flex: 1,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 16,
        marginRight: 16,
      }}
      closeView={1}
      beforeSubmit={({ data, updates }) => {
        const itemPharmacyDetails = data?.pharmacyDetails?.map((item) => ({
          item: { _id: item?.item?._id },
          quantity: item?.quantity,
          totalPrice: item?.totalPrice,
          department: item?.item?.department,
        }
        ));
        return {
          updates: {
            ...updates,
            pharmacyDetails: itemPharmacyDetails,
          },
        };
      }}
      onSubmit={submit({
        model: MODELS.PHARMACY_BILLING,
      })}
      submitNext={({ data, submitResult }) => printPharmacyBill({ data, submitResult })}
      submitMessage={'billing.messages.invoiceGenerateSuccessfully'.getLabel()}
      defaultValues={() => ({
        billingDate: new Date(),
        pharmacyDetails: prescribedItems,
        documents: patientInfo[0]?.lastConsultation?.prescription,
        consultationId: { _id: patientInfo[0]?.lastConsultation?._id },
        patientId: {
          _id: patientInfo[0]?._id,
          age: patientInfo[0]?.age,
          uhid: patientInfo[0]?.uhid,
          gender: patientInfo[0]?.gender,
          mobile: patientInfo[0]?.mobile,
          lastConsultation: patientInfo[0]?.lastConsultation,
          patientName: patientInfo[0]?.patientName,
          relation: patientInfo[0]?.relation,
          relationName: patientInfo[0]?.relationName,
          lastConsultationDate: patientInfo[0]?.lastConsultationDate ? moment(patientInfo[0]?.lastConsultationDate).format('DD MMM YYYY') : 'N/A',
        },
        discount: 0,
        status: 'Active',
        dispensedBy: { _id: employee._id },
      })}
      computations={{
        children: {
          pharmacyDetails: {
            self: {
              itemAutofill: {
                compute: ({ item, itemName }) => {
                  if (!itemName && item) {
                    return {
                      set: {
                        itemName: item?.name,
                        category: item?.category,
                        unitPrice: item?.sellingPrice,
                        manufacturer: item?.rateContract[0]?.manufacturer?.name,
                        stockAvailable: item?.stockAvailable,
                        department: item?.stockAvailable,
                        quantity: '',
                      },
                    };
                  }

                  return {};
                },
                onChange: ['item'],
              },
              itemNameAutofill: {
                compute: ({ itemName: item }) => ({
                  set: {
                    item,
                    category: item?.category,
                    unitPrice: item?.sellingPrice,
                    manufacturer: item?.rateContract[0]?.manufacturer?.name,
                    stockAvailable: item?.stockAvailable,
                    quantity: '',
                  },
                }),
                onChange: ['itemName'],
              },
              priceSet: {
                compute: ({ quantity, itemName }) => ({
                  set: {
                    totalPrice: itemName?.sellingPrice * quantity,
                    billAmount: itemName?.sellingPrice * quantity,
                  },
                }),
                onChange: ['quantity', 'item', 'itemName'],
              },
            },
          },
        },
        self: {
          billAmount: {
            compute: ({ pharmacyDetails = [], discount }) => {
              let totalAmount = 0;
              let countLength = 0;
              pharmacyDetails.map(({ totalPrice }) => {
                totalAmount += totalPrice;
                countLength += 1;
                return (totalPrice, countLength);
              });
              // eslint-disable-next-line no-multi-assign
              totalAmount = totalAmount *= (1 - (discount || 0) / 100);
              return {
                set: { billAmount: totalAmount, totalItems: countLength },
              };
            },
            onChange: ['pharmacyDetails.totalPrice', 'discount'],
          },
        },
      }}
      validations={{
        pharmacyDetails: {
          item: ({ data }) => {
            if (!data?.item) {
              return 'This field cannot be empty';
            }

            return false;
          },
          quantity: ({ data }) => {
            if (data.quantity) {
              if (data.quantity > data.stockAvailable) {
                return 'Requested units can not be more than units available';
              }
            }
            if (data?.quantity <= 0) {
              return 'Unit Requested can not be less than or equal to 0';
            }
            if (!data?.quantity) return 'This field cannot be empty';

            return false;
          },
        },
      }}
      mandatory={{
        pharmacyDetails: {
          item: 1,
          quantity: 1,
        },
      }}
      {...props}
    >
      {({ form_context: { setValue }, form_state: { data } }) => (
        <>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flexDirection: 'column', flex: 0.2 }}>
              <View style={{ flexDirection: 'row', marginLeft: 24, marginTop: 20 }}>
                <View>
                  <Image
                    source={barcode}
                  />
                </View>
                <View style={{ marginTop: 20, marginLeft: 24 }}>
                  <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>
                    {'billing.labels.uhid'.getLabel()}
                  </Text>
                  <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                    {patientInfo[0]?.uhid}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'column', flex: 0.8 }}>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.name'.getLabel()}
                    field="patientId.patientName"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.relation'.getLabel()}
                    editable={false}
                    render={() => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getString([patientInfo[0]?.relation, patientInfo[0]?.relationName])}
                      </Text>
                    )}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.gender'.getLabel()}
                    field="patientId.gender"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.age'.getLabel()}
                    field="patientId.age"
                    variant="filled"
                    editable={false}
                  />
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36 }}>
                  <FormField
                    type="file"
                    label={'billing.labels.documents'.getLabel()}
                    container="topLabel"
                    field="documents"
                    variant="filled"
                  />
                </View>

                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.mobile'.getLabel()}
                    field="patientId.mobile"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.latestOPD'.getLabel()}
                    field="patientId.lastConsultation.ODPNumber"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'billing.labels.lastConsultation'.getLabel()}
                    field="patientId.lastConsultationDate"
                    variant="filled"
                    editable={false}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View style={{
              flexDirection: 'row', flex: 1, marginTop: 14, marginLeft: 36,
            }}
            >
              <FormField
                {... {
                  field: 'pharmacyDetails',
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{
                                ...vars.headings.h9,
                                color: vars.colors.grey.color3,
                              }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                        {
                          render: () => (
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'billing.labels.suggestions'.getLabel()}`}
                              </Text>
                              {itemSuggestion?.map(({ item, stockAvailable }) => {
                                const newItem = {
                                  ...item,
                                  item: {
                                    ...item,
                                    department: { _id: stockAvailable[0].department?._id },
                                  },
                                  itemName: {
                                    ...item,
                                    department: { _id: stockAvailable[0].department?._id },
                                  },
                                  stockAvailable: stockAvailable[0]?.stockAvailable,
                                  unitPrice: item?.sellingPrice,
                                  manufacturer: item?.rateContract[0]?.manufacturer?.name,
                                  quantity: 0,
                                  totalPrice: 0,
                                  department: { _id: stockAvailable[0].department?._id },
                                };
                                return (
                                  <TouchableOpacity
                                    key={item._id}
                                    onPress={() => {
                                      setValue({
                                        value: [
                                          ...(data?.pharmacyDetails ? data?.pharmacyDetails : []),
                                          newItem,
                                        ],
                                        data,
                                        field: 'pharmacyDetails',
                                      });
                                    }}
                                  >
                                    <Text
                                      key={item._id}
                                      style={{
                                        paddingLeft: 12,
                                        ...vars.headings.h10,
                                        textDecoration: 'underLine',
                                        color: vars.colors.secondary.color2,
                                      }}
                                    >
                                      {item?.name}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              })}

                            </View>
                          ),
                        },
                        {
                          render: () => (
                            <View
                              style={{
                                flexDirection: 'column',
                              }}
                            >
                              {size(notAvailableStocks) ? (
                                <View
                                  style={{
                                    marginBottom: 10,
                                    marginTop: 6,
                                  }}
                                >
                                  <Text
                                    style={{
                                      ...vars.headings.h9,
                                      color: vars.colors.error,
                                    }}
                                  >
                                    Following Items are not available :
                                    {' '}
                                    {notAvailableStocks.map(({ item }) => item.name).toString()}
                                  </Text>
                                </View>
                              ) : <View />}
                            </View>
                          ),
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        searchInput({
                          type: 'autoSuggest',
                          field: 'item',
                          label: `${'billing.labels.itemId'.getLabel()}`,
                          variant: 'filled',
                          keyFiled: 'itemCode',
                          suggestionField: 'itemCode',
                          valueField: 'itemCode',
                          model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
                          query: 'pharmacyDepartmentInventoryStocksDataList',
                          mandatory: true,
                          disabled: true,
                        },
                        {
                          modifyResult: ({ data }) => {
                            const newData = data.map(({
                              item,
                              stockAvailable,
                              department,
                            }) => ({ ...item, stockAvailable, department }));
                            return { data: newData };
                          },
                          addOnFilter: ({
                            data: {
                              _parent: {
                                pharmacyDetails = [],
                              } = {},
                            },
                          } = {}) => {
                            const itemIds = pharmacyDetails?.filter(
                              ({ item }) => item?._id,
                            ).map(({ item }) => item?._id);

                            return {
                              department: { _id: { $in: departmentId } },
                              item: { _id: { $nin: itemIds } },
                            };
                          },
                        }),
                        searchInput({
                          type: 'autoSuggest',
                          field: 'itemName',
                          label: `${'billing.labels.itemName'.getLabel()}`,
                          variant: 'filled',
                          keyFiled: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                          model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
                          query: 'pharmacyDepartmentInventoryStocksDataList',
                          mandatory: true,
                        },
                        {
                          modifyResult: ({ data }) => {
                            const newData = data.map(({
                              item,
                              stockAvailable,
                              department,
                            }) => ({ ...item, stockAvailable, department }));
                            return { data: newData };
                          },
                          addOnFilter: ({
                            data: {
                              _parent: {
                                pharmacyDetails = [],
                              } = {},
                            },
                          } = {}) => {
                            const itemIds = pharmacyDetails?.filter(
                              ({ item }) => item?._id,
                            ).map(({ item }) => item?._id);

                            return {
                              department: { _id: { $in: departmentId } },
                              item: { _id: { $nin: itemIds } },
                            };
                          },
                        }),
                        autoSuggestInput({
                          type: 'autoSuggest',
                          field: 'category',
                          label: 'billing.labels.category'.getLabel(),
                          variant: 'filled',
                          keyFiled: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                          disabled: ({ data }) => !!data?.item,
                        }),
                        {
                          type: 'text',
                          field: 'manufacturer',
                          label: `${'billing.labels.manufacturer'.getLabel()}`,
                          variant: 'filled',
                          disabled: ({ data }) => !!data?.item,
                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.quantity'.getLabel()}`,
                          field: 'quantity',
                          variant: 'filled',
                          mandatory: true,
                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.stockAvailable'.getLabel()}`,
                          field: 'stockAvailable',
                          variant: 'filled',
                          disabled: true,
                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.unitPrice'.getLabel()}`,
                          field: 'unitPrice',
                          variant: 'filled',
                          disabled: true,

                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.price'.getLabel()}`,
                          field: 'totalPrice',
                          variant: 'filled',
                          disabled: true,
                        },
                        NestedAction(),
                      ],
                    },
                  },
                }}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View style={{ flexDirection: 'column', flex: 0.7, marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36, height: 10 }}>
                  <FormField
                    type="text"
                    editable={false}
                    field="totalItems"
                    variant="filled"
                    label="Total No. of items"
                    container="topLabel"
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 0.3, marginTop: 10 }}>
                <View style={{ flex: 1, marginLeft: 36 }} />
                <View style={{ marginLeft: 14, width: 309 }}>
                  <FormField
                    type="number"
                    label="Discount (In %)"
                    field="discount"
                    variant="filled"
                  />
                </View>
                <View style={{ marginTop: 20, marginLeft: 14, width: 309 }}>
                  <FormField
                    type="number"
                    editable={false}
                    label="Bill Amount"
                    field="billAmount"
                    variant="filled"
                  />
                </View>
              </View>
            </View>
          </View>
        </>
      )}
    </Form>
  );
};
export default NewBill;
