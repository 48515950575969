import React from 'react';
import MasterForm from '../../../Wired/Master/MasterForm';
import { autoSuggestInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';
import { Text, View } from '../../../app-components';
import {
  validateInputLength,
} from '../../../Lib/helpers';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../theme/vars';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Designation Name',
    mandatory: true,
    placeholder: 'Designation',
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'Description',
    allowWhiteSpaces: true,
  },
  {
    type: 'number',
    field: 'sanctionSeats',
    label: 'Sanction Seats',
    mandatory: true,
    placeholder: 'Sanction Seats',
    allowWhiteSpaces: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'employeeType.labels.status'.getLabel(),
    container: 'leftLabel',
  },
];

const otherFormGroups = [
  {
    fieldVariant: 'filled',
    label: 'Sanction Seats',
    labelStyle: {
      ...vars.headings.h10,
      color: vars.colors.grey.color2,
    },
    groupsContainerStyle: { paddingLeft: 0 },
    groups: [
      {
        columns: [
          {

            field: 'sanctionSeatsCategory',
            fieldType: 'nested',
            Component: NestedTable,
            componentProps: {
              maxHeight: 400,
              footer: ({ addRow }) => ({
                leftActions: [{
                  render: () => (
                    <View style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderWidth: 1,
                      borderRadius: 5,
                      width: 220,
                      height: 40,
                      borderColor: vars.colors.secondary.color2,
                    }}
                    >
                      <Text style={{
                        ...vars.headings.h9,
                        color: vars.colors.secondary.color2,
                      }}
                      >
                        {`+ ${'Add New Category'}`}
                      </Text>
                    </View>
                  ),
                  onPress: addRow,
                }],
              }),
              skipTableHeaderOnNoData: true,
              columnsPerRow: 4,
              listProps: {
                hideColumnHeader: true,
                headerRowContainerStyle: {
                  minHeight: 20,
                  paddingLeft: 12,
                  paddingRight: 4,
                  backgroundColor: vars.colors.white,
                },
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'category',
                    label: 'Category',
                    placeholder: 'Category',
                    header: 'Category',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE_CATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                    variant: 'filled',
                    width: 350,
                  }),
                  {
                    type: 'number',
                    field: 'sanction_seats_category',
                    label: 'Sanction Seats',
                    variant: 'filled',
                    editable: true,
                    mandatory: true,
                    width: 350,
                  },
                  // {
                  //   type: 'number',
                  //   field: 'allocated_seats_category',
                  //   label: 'Allocated Seats',
                  //   variant: 'filled',
                  //   editable: true,
                  //   width: 350,
                  // },
                  // {ds
                  //   type: 'number',
                  //   field: 'vacant_seats_category',
                  //   label: 'Vacant Seats',
                  //   variant: 'filled',
                  //   editable: true,
                  //   width: 350,
                  // },
                  NestedAction(),
                ],
              },
            },
          },
        ],
      },
    ],
  },
];
export default MasterForm({
  model: MODELS.DESIGNATION,
  defaultValues: () => ({
    isActive: true,
  }),
  submitMessage: 'Designation Added SuccessFully',
  formFields,
  otherFormGroups,
  mandatory: {
    name: 1,
    sanctionSeats: 1,
    sanctionSeatsCategory: { category: 1, sanction_seats_category: 1 },
  },
  validations: {
    name: ({ data }) => validateInputLength(data?.name, 2, 20),
    'sanctionSeatsCategory.sanction_seats_category': ({ data }) => {
      let sumOfSanctionSeats = 0;
      const tempData = data?.sanctionSeatsCategory?.map(
        (value) => { sumOfSanctionSeats += value.sanction_seats_category; },
      );
      if (sumOfSanctionSeats > data.sanctionSeats) {
        throw new Error('Category wise sanction seats is greater than sanction seats');
      }
    },
  },
});
