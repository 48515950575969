import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';
import { greenFileIcon } from '../../../../../../../../images';

const ViewBirthForm = (props) => (
  <ListActionModal
    title="Birth Form"
    description="Logged birth form for the patient."
    content={(
      <Form
        {...props}
        closeView={1}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        editable={false}
        lg={{
          formGroups: [
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                padding: '0px 8px 0px 8px',
                fontSize: '20px',
              },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      render: () => 'Details',
                    },
                  ],
                },
              ],
            },
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              editable: false,
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'date',
                      label: 'BIRTHDATE',
                      field: 'dob',
                      mandatory: true,
                      formate: 'DD/MM/YYYY',
                    },
                    {
                      type: 'radioGroup',
                      field: 'item.gender',
                      variant: 'filled',
                      label: 'GENDER',
                      container: 'topLabel',
                      options: ['Female', 'Male'],
                      optionStyle: { flexDirection: 'row-reverse' },
                      mandatory: true,
                    },
                    {
                      type: 'text',
                      field: 'childName',
                      label: 'CHILD NAME (IF ANY)',
                    },
                    {
                      type: 'text',
                      field: 'fatherName',
                      label: 'FATHER\'S NAME (AS MENTIONED ON RECORDS)',
                      mandatory: true,
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'motherName',
                      label: 'MOTHER\'S NAME (AS MENTIONED ON RECORDS)',
                      allowWhiteSpaces: true,
                      editable: true,
                      mandatory: true,
                      width: '25%',
                    },
                    {
                      type: 'text',
                      field: 'address',
                      label: 'ADDRESS',
                      allowWhiteSpaces: true,
                      mandatory: true,
                      width: '50%',
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 2,
                  columns: [
                    {
                      type: 'radioGroup',
                      field: 'birthPlace',
                      variant: 'filled',
                      label: 'PLACE OF BIRTH',
                      container: 'topLabel',
                      options: ['HOSPITAL', 'HOME'],
                      editable: true,
                      mandatory: true,
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      field: 'informerName',
                      label: 'INFORMER\'S NAME',
                      allowWhiteSpaces: true,
                      editable: true,
                      mandatory: true,
                      width: '25%',
                    },
                    {
                      type: 'text',
                      field: 'informerAddress',
                      label: 'INFORMER\'S ADDRESS',
                      allowWhiteSpaces: true,
                      editable: true,
                      mandatory: true,
                      width: '50%',
                    },
                    {
                      type: 'date',
                      field: 'informDate',
                      label: 'DATE',
                      editable: false,
                      formate: 'DD/MMM/YYYY',
                      width: '25%',
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'number',
                      field: 'registrationNumber',
                      label: 'REGISTRATION NUMBER',
                      editable: true,
                      mandatory: true,
                    },
                    {
                      type: 'date',
                      field: 'registrationDate',
                      label: 'REGISTRATION DATE',
                      editable: true,
                      mandatory: true,
                      formate: 'DD/MM/YYYY',
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      render: () => 'FAMILY\'S RESIDENCE',
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      label: 'VILLAGE/TOWN NAME',
                      field: 'villageOrTownName',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'radioGroup',
                      field: 'locationType',
                      variant: 'filled',
                      label: 'TYPE',
                      container: 'topLabel',
                      options: ['Village', 'Town'],
                      optionStyle: { flexDirection: 'row-reverse' },
                      editable: true,
                      mandatory: true,
                    },
                    {
                      type: 'text',
                      field: 'stateName',
                      allowWhiteSpaces: true,
                      label: 'STATE',
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'districtName',
                      label: 'DISTRICT',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'radioGroup',
                      field: 'familyReligion',
                      variant: 'filled',
                      label: 'FAMILY RELIGION',
                      container: 'topLabel',
                      options: ['Hindu', 'Muslim', 'Sikh', 'Christian', 'Other'],
                      width: '50%',
                      optionStyle: { flexDirection: 'row-reverse' },
                      editable: true,
                      mandatory: true,
                    },

                    {
                      type: 'text',
                      label: 'FATHER\'S EDUCATION',
                      field: 'fatherEducation',
                      allowWhiteSpaces: true,
                      width: '25%',
                      editable: true,
                    },
                    {
                      type: 'text',
                      label: 'MOTHER\'S EDUCATION',
                      field: 'motherEducation',
                      allowWhiteSpaces: true,
                      width: '25%',
                      editable: true,
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      label: 'FATHER\'S OCCUPATION',
                      field: 'fatherOccupation',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      label: 'MOTHER\'S OCCUPATION',
                      field: 'motherOccupation',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      label: 'MOTHER\'S AGE AT THE TIME OF MARRIAGE',
                      field: 'motherAgeAtMarriage',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      label: 'MOTHER\'S AGE WHEN GIVING BIRTH',
                      field: 'motherAgeAtBirth',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'number',
                      field: 'numberOfChildren',
                      label: 'MOTHER\'S TOTAL NUMBER OF CHILDREN',
                      editable: true,
                    },
                    {
                      type: 'number',
                      field: 'childWeight',
                      label: 'WEIGHT OF THE CHILD',
                      editable: true,
                    },
                    {
                      type: 'number',
                      field: 'pregnancyTime',
                      label: 'TIME OF THE PREGNANCY (IN WEEKS)',
                      editable: true,
                    },
                    {
                      type: 'number',
                      field: 'registrationNumber',
                      label: 'REGISTRATION NUMBER',
                      editable: true,
                    },

                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              groupsContainerStyle: {
                paddingLeft: 12,
              },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'date',
                      field: 'registrationDate',
                      label: ' REGISTRATION DATE',
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'registratorName',
                      label: 'REGISTRATOR\'S NAME',
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    {
                      type: 'file',
                      field: 'birthCertificate',
                      icon: greenFileIcon,
                      placeholder: 'Document',
                      label: 'Drag & Drop your Birth Certification here',
                    },

                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default ViewBirthForm;
