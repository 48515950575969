import React from 'react';
import { JSONReactForm } from '@applane/react-form';
import { Toast } from '../index';
import {
  formTheme,
  formMobileTheme,
  formHeaderTheme,
  formHeaderMobileTheme,
  compactFormHeaderTheme,
  formFooterTheme,
  formFooterMobileTheme,
  compactFormFooterTheme,
  compactFormTheme,
} from '../../theme/formTheme';
import { fetchQuery, getUser } from '../../AppServices';
import {
  beforeFetchForm,
  afterFetchForm,
} from '../data-fetch/DataFetchFunctions';
import { getRenderComponent, isMobile } from '../UtilityFunctions';
import { ActivityIndicator } from '../loader/Loader';
import ToolBar from '../toolBar/ToolBar';
import { renderFormGroup } from './FormGroup';
import { FormTab } from './FormTab';
import { getResolvedGroups, getResolvedProps } from './Utility';
import { HorizontalFormGroups } from './HorizontalFormGroups';
import { VerticalFormGroups } from './VerticalFormGroups';
import { deepDiff } from './DeepCompare';

export const FormHeader = (props) => {
  const { form_context, form_state, theme, ...restProps } = props;
  const { uid } = form_state || {};
  return <ToolBar {...theme} uid={uid} state={form_state} {...restProps} />;
};

const renderFormHeader = (props) => {
  let { header, formType, ...restProps } = props;
  header = getResolvedProps(header, restProps);
  const { type = formType, ...restHeader } = header;
  let headerTheme = isMobile ? formHeaderMobileTheme : formHeaderTheme;
  if (type === 'compact') {
    headerTheme = compactFormHeaderTheme;
  }
  return <FormHeader theme={headerTheme} {...restProps} {...restHeader} />;
};

export const FormFooter = (props) => {
  const { form_context, form_state, theme, ...restProps } = props;
  const { uid } = form_state || {};
  return <ToolBar {...theme} uid={uid} state={form_state} {...restProps} />;
};

const renderFormFooter = (props) => {
  let { footer, formType, ...restProps } = props;
  footer = getResolvedProps(footer, restProps);
  const { type = formType, ...restFooter } = footer;
  let footerTheme = isMobile ? formFooterMobileTheme : formFooterTheme;
  if (type === 'compact') {
    footerTheme = compactFormFooterTheme;
  }
  return <FormFooter theme={footerTheme} {...restProps} {...restFooter} />;
};

const typeWiseFormTheme = {
  standard: isMobile ? formMobileTheme : formTheme,
  compact: compactFormTheme,
};
export class FormRender extends React.Component {
  renderTab = (props) => {
    const { navigation, eventDispatcher } = this.props;
    return (
      <FormTab
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        {...props}
      />
    );
  };

  renderHorizontalFormGroup = (props) => {
    const { horizontalSeparatorStyle } = this.props;
    return (
      <HorizontalFormGroups
        horizontalSeparatorStyle={horizontalSeparatorStyle}
        {...props}
      />
    );
  };

  renderVerticalFormGroup = (props) => {
    const { verticalSeparatorStyle } = this.props;
    return (
      <VerticalFormGroups
        verticalSeparatorStyle={verticalSeparatorStyle}
        {...props}
      />
    );
  };

  renderFormGroup = (props) => {
    const { type } = this.props;
    return renderFormGroup({ ...props, formType: type });
  };

  renderHeader = (props) => {
    const { renderHeader, header, type } = this.props;
    if (renderHeader) {
      return getRenderComponent(renderHeader, { ...props });
    }
    if (header) {
      return renderFormHeader({ ...props, header, formType: type });
    }
    return null;
  };

  renderFooter = (props) => {
    const { renderFooter, footer, type } = this.props;
    if (renderFooter) {
      return getRenderComponent(renderFooter, { ...props });
    }
    if (footer) {
      return renderFormFooter({ ...props, footer, formType: type });
    }
    return null;
  };

  resolveVisible = ({ visible, ...restProps }) => {
    if (typeof visible === 'function') {
      const { user, data, navigation } = restProps;
      visible = visible({
        user,
        data,
        navigation,
      });
    }
    return visible;
  };

  onSubmitSuccess = () => {
    const { submitMessage } = this.props;
    if (!submitMessage) {
      return null;
    }
    Toast.show({
      message: 'Success',
      description: submitMessage,
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
  };

  onSubmitError = (err) => {
    let errorMessage = '';
    if (err && err.message.indexOf('duplicate key error') !== -1) {
      errorMessage = this.props.uniqueValidationMessage || err.message || err;
    } else {
      errorMessage = err.message || err;
    }
    Toast.show({
      message: 'Error',
      description: errorMessage,
      type: 'error',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    if (this.props.popOnError) {
      this.props.navigation.pop(this.props.popOnError);
    }
  };

  renderLoading = ({ form_state } = {}) => {
    const { loading, submitting } = form_state || {};
    if (loading || submitting) {
      return <ActivityIndicator key="loading_form" />;
    }
  };
  getDataMode = ({ data }) => {
    if (data?._id?.startsWith('new_')) {
      return 'insert';
    } else {
      return 'update';
    }
  };
  render() {
    let {
      type,
      computeProps,
      renderHeader,
      renderFooter,
      header,
      footer,
      formGroups,
      ...restProps
    } = this.props;
    const user = getUser && getUser();
    if (formGroups) {
      formGroups = getResolvedGroups(formGroups, restProps);
    }
    return (
      <JSONReactForm
        fetch={fetchQuery}
        getUser={getUser}
        user={user}
        beforeFetch={beforeFetchForm}
        afterFetch={afterFetchForm}
        reloadOnNavigationChange
        validateOnBlur
        mandatoryMessage="This field cannot be empty"
        renderTab={this.renderTab}
        renderHorizontalFormGroup={this.renderHorizontalFormGroup}
        renderVerticalFormGroup={this.renderVerticalFormGroup}
        renderFormGroup={this.renderFormGroup}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        resolveVisible={this.resolveVisible}
        renderLoading={this.renderLoading}
        onSubmitSuccess={this.onSubmitSuccess}
        onSubmitError={this.onSubmitError}
        deepDiff={deepDiff}
        getDataMode={this.getDataMode}
        computeProps={{
          user,
          fetch: fetchQuery,
          ...computeProps,
        }}
        formGroups={formGroups}
        {...restProps}
      />
    );
  }
}

export class Form extends React.Component {
  render() {
    const { type = 'standard', ...restResolvedProps } = getResolvedProps(
      this.props
    );
    const { navigation } = restResolvedProps;
    const screenName = navigation?.getScreenName();

    return (
      <FormRender
        validateOnUpdate={false}
        screenName={screenName}
        type={type}
        {...typeWiseFormTheme[type]}
        {...restResolvedProps}
      />
    );
  }
}

const FormHoc = (_defs) => {
  class FormComponent extends React.Component {
    render() {
      if (typeof _defs === 'function') {
        _defs = _defs(this.props);
      }
      return <Form {..._defs} {...this.props} />;
    }
  }
  return FormComponent;
};

export default FormHoc;
