import React, { useCallback, useEffect } from 'react';

import { View, Text } from '../../app-components';
import { Table } from '../../app-components/table/Table';
import { gpsStore } from '../../AppServices';
import vars from '../../theme/vars';
import ConsultationActions from './Components/ConsultationActions';
import ConsultationStatus from './Components/ConsultationStatus';

const tabListColumns = [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
    width: 120,
    render: ({ item }) => (
      <View style={{}}>
        <Text
          style={{
            ...vars.headings.h2,
          }}
        >
          {item?.token}
        </Text>
      </View>
    ),
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'Status',
    type: 'custom',
    render: ConsultationStatus,
    width: 200,
  },
  {
    header: 'Actions',
    type: 'custom',
    render: (props) => <ConsultationActions {...props} />,
    width: 400,
  },
  {
    width: 50,
  },
];

const ConsultedPatients = (props) => {
  const {
    tableColumns = tabListColumns, addOnFilter, model, id, roomId, eventDispatcher,
  } = props;

  const reloadConsultations = useCallback(() => {
    eventDispatcher.notify('reloadConsultedPatients');
  }, [eventDispatcher]);

  const subscribeToConsultationsUpdates = useCallback((room) => {
    gpsStore.subscribeEvent({
      event: `consultedPatient-${room}-updates`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const unSubscribeToConsultationsUpdates = useCallback((room) => {
    gpsStore.unsubscribeEvent({
      event: `consultedPatient-${room}-updates`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  useEffect(() => {
    if (roomId) {
      subscribeToConsultationsUpdates(roomId);
    }

    return () => unSubscribeToConsultationsUpdates(roomId);
  }, [roomId]);

  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: {
            token: -1,
          },
        },
        model,
      })}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent="reloadConsultedPatients"
      {...props}
    />
  );
};
export default ConsultedPatients;
