import React from 'react';

import {
  Text,
} from '../../../../app-components';
import vars from '../../../../theme/vars';
import permissions from '../../../../Lib/permissions';
import { AuctionItemList } from '../../../../Queries/auction';
import { Table } from '../../../../app-components/table/Table';
import WithPermission from '../../../../Components/WithPermission';
import { MODELS, ROUTES, auctionItemStatus } from '../../../../Lib/constants';
import { AuctionInventoryToggleFilter } from '../../../../app-components/filter/MamcToggleFilters';
import { getEstimatedDepreciationValue, getMonthOfOperation } from '../../../../Lib/helpers';

const header = ({ queryID = 'auctionItemList' }) => ({
  leftActions: [
    (props) => (
      <AuctionInventoryToggleFilter
        {...props}
        model={MODELS.AUCTION_ITEMS}
        id={queryID}
      />
    ),
  ],
});

const tableItems = ({
  queryID,
}) => ({
  lg: {
    header: header({ queryID }),
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'Date',
        type: 'date',
        field: '_createdOn',
        width: 150,
      },
      {
        header: 'Serial no.',
        type: 'text',
        field: 'item.serialNumber',
        width: 150,
      },
      {
        header: 'Item Name',
        type: 'text',
        field: 'item.item.name',
        width: 150,
      },
      {
        header: 'Purchase Price',
        type: 'number',
        field: 'item.batchNumber.purchasedPrice',
        sortable: true,
        width: 150,
      },
      {
        header: 'Description',
        type: 'text',
        field: 'item.item.description',
        width: 200,
      },
      {
        header: 'ESTIMATED DEPRECIATION',
        type: 'number',
        render: ({ item }) => {
          if (item?.currentValue) {
            return (
              <Text
                style={{
                  ...vars.headings.h9,
                }}
              >
                {Math.abs(Number(item?.item?.batchNumber?.purchasedPrice) - Number(item?.currentValue))}
              </Text>
            );
          }
          const rate = (item?.rate);
          const Principle = item?.item?.batchNumber?.purchasedPrice;
          const months = getMonthOfOperation(item?.item?._createdOn);
          return (
            <Text
              style={{
                ...vars.headings.h9,
              }}
            >
              {getEstimatedDepreciationValue(rate, Principle, months)}
            </Text>
          );
        },
        width: 150,
      },
      {
        header: 'CURRENT VALUE',
        type: 'number',
        width: 150,
        field: 'currentValue',
      },
      {
        header: 'Sold Price',
        type: 'number',
        field: 'soldAtPrice',
        sortable: 'soldAtPrice',
        width: 150,
      },
      {
        header: 'Sold out Date',
        type: 'date',
        field: 'soldOnDate',
        width: 150,
      },
      {
        header: 'Manufacturer',
        type: 'text',
        field: 'manufacturer.name',
        width: 150,
      },
      {
        header: 'Status',
        type: 'text',
        width: 150,
        render: ({ item: { status } = {} }) => {
          const color = status === auctionItemStatus.soldOut
            ? vars.colors.error : vars.colors.primary.color2;
          const itemStatus = status === auctionItemStatus.soldOut
            ? 'Sold-Out' : 'In-Stock';

          return (
            <Text style={{ ...vars.headings.h11, color }}>
              {itemStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <WithPermission
            access_key={
              permissions.auction.views.editAuctionRecords.permissions.editAuctionRecords.value
            }
          >
            <Text style={{ color: vars.colors.secondary.color2 }}>Edit</Text>
          </WithPermission>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.editAuctionItemDetails.name,
            params: { item },
          },
        }),
      },
    ],
  },
});

export default (props) => {
  const {
    queryID = 'auctionItemList',
    defaultValues,
  } = props;

  return (
    <Table
      {...props}
      reloadEvent={`reload${MODELS.AUCTION_ITEMS}`}
      uri={() => AuctionItemList()}
      {...tableItems({
        queryID,
      })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewAuctionItem.name,
          params: {
            item,
            defaultValues,
          },
        },
      })}
    />
  );
};

