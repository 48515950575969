export const EditDepartment = ({ navigation }) => ({
  query: {
    model: 'departments',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'departments',
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'departments',
});

export const GetOPDDepartment = () => ({
  props: {
    query: {
      model: 'departments',
      id: 'masterDataList',
      addOnFilter: { code: 'OPD' },
      limit: 1,
    },
    model: 'departments',
  },
});
