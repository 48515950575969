import React from 'react';

import RelationInput from '../../../../../../../Components/RelationInput';
import { Form } from '../../../../../../../app-components/form/Form';
import { iPDPatientUri } from '../../../../../../../Queries/patient';
import { Text } from '../../../../../../../app-components';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';
import Consent from '../../../../NurseView/ConsentForm/Consent';

const ViewConsentForm = (props) => {
  const { navigation: { state: { params: { patient: paramsData } = {} } = {} } = {} } = props;
  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      uri={iPDPatientUri({ data: paramsData })}
      reloadEvent="reloadIPDRegisteredPatients"
      reloadOnChangeEvent={`reload${MODELS.IPD_ADMISSION}`}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'text',
                    field: 'patient.patientName',
                    label: 'Patient Name',
                    width: '25%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    render: ({ data }) => (
                      <Text style={{ fontSize: 14, lineHeight: 16 }}>
                        {data?.patient?.gender?.charAt(0)?.toUpperCase()}
                        {''}
                        {data?.patient?.gender?.slice(1)}
                      </Text>
                    ),
                    label: 'Gender',
                    width: '25%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'patient.age',
                    label: 'Age',
                    width: '25%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    width: '25%',
                    label: 'Relation',
                    field: 'consentForm.patientRelativeName',
                    render: ({ navigation: { state: { params: { patient } } } }) => (
                      <Text style={{ lineHeight: 16, fontSize: 14 }}>
                        {patient?.consentForm?.patientRelation}
                        {' '}
                        {' '}
                        {patient?.consentForm?.patientRelativeName}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'consentForm.consentingPersonName',
                    label: 'Name of the Consenting Person',
                    mandatory: false,
                    editable: false,
                    width: '25%',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 1,
            render: () => (
              <Text> CONSENT  </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            render: () => (
              <Text>
                <Consent {...paramsData} />
              </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 1,
            render: () => (
              <Text> FORM INFORMATION   </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'consentForm.witnessOneName',
                    label: 'Witness 1 Name',
                    width: '23%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessOneAddress',
                    label: 'Witness 1 Address',
                    width: '74%',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessTwoName',
                    label: 'Witness 2 Name',
                    editable: false,
                    width: '23%',
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessTwoAddress',
                    label: 'Witness 2 Address',
                    editable: false,
                    width: '74%',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'consentForm.relativeName',
                    label: 'Patient/Relative Name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    editable: false,
                    label: 'Relative Name',
                    render: ({ navigation: { state: { params: { patient } } } }) => (
                      <Text style={{ lineHeight: 16, fontSize: 14 }}>
                        {patient?.consentForm?.consentingRelation}
                        {' '}
                        {' '}
                        {patient?.consentForm?.consentingRelativeName}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    field: 'consentForm.consentingMobileNumber',
                    label: 'Mobile No. Of patient/Relative',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'consentForm.consentingAddress',
                    label: 'Address of Patient/Relative',
                    editable: false,
                  },
                  {
                    type: 'date',
                    field: '_createdOn',
                    format: 'DD MM YYYY',
                    label: 'Date of Admission',
                    editable: false,
                  },
                  {
                    type: 'date',
                    field: '_createdOn',
                    format: 'HH:mm A',
                    label: 'Time of Admission',
                    editable: false,
                  },
                  {
                    type: 'file',
                    field: 'consentForm.attachments',
                    label: 'Drop & Drag Your File',
                    editable: false,
                    multiple: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default ViewConsentForm;
