import React from 'react';

import ListOfPatient from '../../../../Wired/ListOfPatients';
import { MODELS } from '../../../../Lib/constants';
import { View } from '../../../../app-components';

const tabListColumns = [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
  },
  {
    header: 'Room',
    type: 'text',
    value: ({ item }) => item?.sessionQueue?.room?.name,
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'Created On',
    type: 'date',
    field: '_createdOn',
    format: 'DD MM YYYY @ HH:MM A',
  },
];

const ListOfPatients = (props) => {
  const { navigation } = props;

  const { session = {} } = (
    navigation?._parent()?.getScreenState()?.sessionQueue) || {};

  const { speciality, _id, department } = session;

  if (!navigation?._parent() || !_id) {
    return <View />;
  }

  return (
    <ListOfPatient
      tableColumns={tabListColumns}
      model={MODELS.CONSULTATION}
      id="consultationDataList"
      subscribeToUpdates
      {...props}
      addOnFilter={{
        department,
        speciality,
        $or: [
          {
            session: {
              _id,
            },
          }, {
            status: 'new',
          },
        ],
      }}
      speciality={speciality}
    />
  );
};
export default ListOfPatients;
