import React from 'react';
import { size } from 'lodash';

import {
  Text,
} from '../../../../../../app-components';
import { AssetIndentRequestsListUri } from '../../../../../../Queries/asset';
import { MODELS, assetInventoryStockRequestStatus, ROUTES } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetInventoryIndentRequestToggleFilter } from '../../../../../../app-components/filter/MamcToggleFilters';
import { Table } from '../../../../../../app-components/table/Table';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.responseDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_updatedOn',
      },
      {
        header: 'asset.headers.dateRaised'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.indentNumber'.getLabel(),
        type: 'text',
        field: 'indentNumber',
      },
      {
        header: 'asset.headers.noOfItems'.getLabel(),
        type: 'number',
        value: ({ item: { indentDetails = [] } = {} }) => size(indentDetails),
      },
      {
        header: 'asset.headers.department'.getLabel(),
        type: 'text',
        value: ({ item: { department = {} } = {} }) => department?.name,
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { requestStatus } }) => {
          let indentRequestStatus = '';
          let statusColor = '';
          if (requestStatus === assetInventoryStockRequestStatus.approved) {
            indentRequestStatus = assetInventoryStockRequestStatus.approvedByMSI;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === assetInventoryStockRequestStatus.raised) {
            indentRequestStatus = assetInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          } else if (requestStatus === assetInventoryStockRequestStatus.stockProvided) {
            indentRequestStatus = assetInventoryStockRequestStatus.stockProvided;
            statusColor = vars.colors.primary.color2;
          } else {
            indentRequestStatus = assetInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {indentRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewIndentRequestAsset.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

const IndentRequest = (props) => (
  <Table
    {...props}
    reloadEvent={`reload${MODELS.ASSET_INDENT}`}
    reloadEventOnChange={`reload${MODELS.ASSET_INDENT}`}
    uri={AssetIndentRequestsListUri}
    {...tableItems}
    header={{
      leftActions: [() => (
        <AssetInventoryIndentRequestToggleFilter
          {...props}
          model={MODELS.ASSET_INDENT}
          id="assetInventoryIndentDataList"
        />
      )],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewIndentRequestAsset.name,
        params: { item },
      },
    })}
  />
);
export default IndentRequest;
