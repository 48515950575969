import React from 'react';

import { MODELS, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import { countUri } from '../../../../../../Queries/countUri';
import { getUser } from '../../../../../../AppServices';
import TransferRequestList from './transferRequest';
import tabTheme from '../../../Themes/nestedTabTheme';
import Repair from './repairRequest';

const OtherRecordsTabs = (props) => {
  const user = getUser();
  const { employee: { department = [] } = {} } = user;
  const departmentIds = department?.map((item) => item?._id);

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        Repair: {
          label: 'Repair',
          screen: Repair,
          countUri: countUri({
            model: MODELS.ASSETS_PURCHASE,
            id: 'assetInventoryRequestDataList',
            filter: {
              type: 'repair',
              asset: { department: { _id: { $in: departmentIds } } },
              requestStatus: {
                $in:
         [purchaseSupplyOrderStatus.PAYMENT_DONE],
              },
            },
          }),
        },
        transfer: {
          label: 'Transfer',
          screen: TransferRequestList,
          countUri: countUri({
            model: MODELS.ASSET_TRANSFER_REQUESTS,
            id: 'assetTransferRequestsDataList',
            filter: {
              requestStatus: {
                $in:
           [purchaseSupplyOrderStatus.APPROVED],
              },
            },
          }),
        },
      }}
    />
  );
};
export default OtherRecordsTabs;
