import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SelectDate } from '@applane/react-date-input';

import {
  StandardButton,
  View,
  Text,
} from '../../../../../../../app-components';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import { Table } from '../../../../../../../app-components/table/Table';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import tableItemsBloodSugar from './BloodSugar';
import tableItemsInputOutput from './InputOutput';
import { VitalSignSheetToggleFilter } from '../../../../../../../app-components/filter/MamcToggleFilters';
import ToggleFilter from '../../../../../../../app-components/filter/ToggleFilter';

const VitalSignsSheet = (props) => {
  const [key, setKey] = useState(Date.now());
  const [selectedDate, setSelectedDate] = useState();
  const { eventDispatcher, dataParams } = props || {};
  useEffect(() => {
    eventDispatcher.notify(`reload${MODELS.IPD_VITALS_SIGN_SHEET}`);
  }, [selectedDate, eventDispatcher]);
  useEffect(() => {
    props.setScreenState({
      dataParams: {
        filters: {
          toggle: {
            value: 'vitals',
            filter: { value: 'vitals' },
          },
        },
      },
    });
  }, []);
  useEffect(() => {
    setKey(Date.now());
  }, [dataParams?.filters?.toggle]);

  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      editable={false}
      flex={false}
      scrollable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(
                            item?.navigation?.state?.params?.patient?._createdOn,
                          ).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate).isSame(currentDate);
                          const highlightedDate = moment(currentDate).isAfter(
                            moment(admissionDate),
                          ) && moment(currentDate).isBefore(moment());

                          if (admittedDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text
                                  style={{
                                    margin: 'auto',
                                    backgroundColor: '#EFF3FB',
                                    marginBottom: -15,
                                    padding: 12,
                                  }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text
                                  style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: 'green',
                                    backgroundColor: '#EFF3FB',
                                    marginTop: -10,
                                  }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View
                                style={{
                                  backgroundColor: '#EFF3FB',
                                  padding: 13,
                                }}
                              >
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View
                                style={{
                                  backgroundColor: '#4070C9',
                                  padding: 13,
                                }}
                              >
                                {date}
                              </View>
                            );
                          }
                          return <View>{date}</View>;
                        }}
                      />
                    ),
                  },
                  {
                    render: () => {
                      const tableProps = {
                        ...props,
                        ...{ selectedDate },
                      };
                      const {
                        screenState: {
                          dataParams: {
                            filters: { toggle: { value } = {} } = {},
                          } = {},
                        } = {},
                      } = props;
                      const inputOutput = value === 'input-output';
                      return (
                        <Table
                          {...tableProps}
                          key={key}
                          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
                          columns={[
                            {
                              header: 'S.No.'.getLabel(),
                              type: 'number',
                              render: (item) => <Text>{item.index + 1}</Text>,
                            },
                            {
                              header: 'TIME'.getLabel(),
                              type: 'date',
                              format: 'HH:mm A',
                              field: 'vitalSignTime',
                            },
                            {
                              header: 'BP',
                              type: 'text',
                              field: 'vitalSignBP',
                            },
                            {
                              header: 'TEMPERATURE',
                              type: 'text',
                              field: 'vitalSignTemperature',
                            },
                            {
                              header: 'PULSE',
                              type: 'text',
                              field: 'vitalSignPulse',
                            },
                            {
                              header: 'RESP.',
                              type: 'text',
                              field: 'vitalSignResp',
                            },
                            {
                              header: 'NURSE NOTES',
                              type: 'text',
                              field: 'nurseNote',
                            },
                            {
                              header: 'NURSE NAME',
                              type: 'text',
                              field: 'nurseName',
                            },
                            {
                              header: 'Total Input',
                              type: 'text',
                              field: 'totalInput',
                            },
                            {
                              header: 'Total Output',
                              type: 'text',
                              visible: inputOutput,
                              field: 'totalOutput',
                            },
                            {
                              header: 'RBS',
                              type: 'text',
                              visible: inputOutput,
                              field: 'rbs',
                            },
                            {
                              header: 'Dose Of Insulin(ml)',
                              type: 'text',
                              visible: inputOutput,
                              field: 'doseOfInsulin',
                            },
                            {
                              header: 'Advice by',
                              type: 'text',
                              field: 'adviceBy',
                              visible: inputOutput,
                            },
                          ]}
                          {...{
                            header: {
                              leftAction: {
                                render: (actionProps) => (
                                  <VitalSignSheetToggleFilter
                                    {...actionProps}
                                  />
                                ),
                              },
                              actions: [
                                {
                                  type: 'link',
                                  link: () => {
                                    const {
                                      navigation: {
                                        state: { params = {} },
                                      },
                                    } = props;
                                    const {
                                      screenState: {
                                        dataParams: {
                                          filters: {
                                            toggle: {
                                              filter: { value } = {},
                                            } = {},
                                          } = {},
                                        } = {},
                                      },
                                    } = props || {};
                                    if (value === 'blood-sugar') {
                                      return {
                                        view: ROUTES.addBloodSugar.name,
                                        params: {
                                          ...params,
                                          dataParams,
                                          value,
                                        },
                                        push: true,
                                        modal: true,
                                        modalProps: {
                                          autoHide: true,
                                          maxHeight: '100%',
                                          width: 1000,
                                          height: 600,
                                        },
                                      };
                                    }
                                    if (value === 'input-output') {
                                      return {
                                        view: ROUTES.logInputOutput.name,
                                        params: { ...params, dataParams },
                                        push: true,
                                        modal: true,
                                        modalProps: {
                                          autoHide: true,
                                          maxHeight: '100%',
                                          width: 1000,
                                          height: 600,
                                        },
                                      };
                                    }
                                    return {
                                      view: ROUTES.addVitalsGeneralMedicine
                                        .name,
                                      params: { ...params, dataParams },
                                      push: true,
                                      modal: true,
                                      modalProps: {
                                        autoHide: true,
                                        maxHeight: '100%',
                                        width: 1000,
                                        height: 600,
                                      },
                                    };
                                  },
                                  render: () => <StandardButton label="Log" />,
                                },
                              ],
                            },
                          }}
                          uri={(item) => ({
                            query: {
                              id: 'EditIPDVitalSheet',
                              addOnFilter: {
                                admissionID: {
                                  _id: item?.navigation?.state?.params?.patient
                                    ?._id,
                                },
                                // typeOfVital: value,
                                speciality:
                                  props.dataParams?.filters?.specialityFilter,
                                _createdOn: {
                                  $gt: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(0),
                                        ).setUTCMinutes(0),
                                      ).setUTCSeconds(0),
                                    ).setUTCMilliseconds(0),
                                  ),
                                  $lt: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(23),
                                        ).setUTCMinutes(59),
                                      ).setUTCSeconds(59),
                                    ).setUTCMilliseconds(0),
                                  ),
                                },
                              },
                            },
                            model: MODELS.IPD_VITALS_SIGN_SHEET,
                          })}
                          action={{
                            type: 'link',
                            link: (data) => ({
                              view:
                                props.dataParams.filters.specialityFilter
                                === 'PediatricsNICU'
                                  ? ROUTES.ViewVitalsPediatrics.name
                                  : ROUTES.ViewDailyVitals.name,
                              modal: true,
                              params: {
                                addOnFilter: {
                                  _id: data.item._id,
                                },
                                dataParams: props.dataParams,
                              },
                              modalProps: {
                                autoHide: true,
                                maxHeight: '100%',
                                width: 1000,
                                height: 600,
                              },
                            }),
                          }}
                        />
                      );
                    },
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default VitalSignsSheet;
