import { MODELS } from '../Lib/constants';

export const Miscellaneous = ({ navigation }) => ({
  query: {
    model: 'miscellaneous_amounts',
    id: 'miscellaneousIPDDataList',
    relationValue: {
      model: 'miscellaneous_amounts',
      _id: navigation.state.params.item._id,
    },
  },
  model: 'miscellaneous_amounts',
});

export const MiscellaneousUri = (filter = {}) => ({
  query: {
    model: MODELS.MISCELLANEOUS_ACCOUNT,
    id: 'miscellaneousIPDDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: 'miscellaneous_amounts',
});
