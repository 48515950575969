/* eslint-disable camelcase */
import React, {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import {
  ActivityIndicator, View, ScrollView,
} from '../../../../../app-components';
import SalaryDetails from '../../../../../Wired/GenerateSalary/SalaryDetail';

import { Table } from '../../../../../app-components/table/Table';
import { fetch, invoke } from '../../../../../AppServices';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { getString } from '../../../../../Lib/helpers';

const tabListColumns = [
  {
    width: 14,
  },
  {
    header: 'Month',
    type: 'text',
    field: 'month_id',
    // width: 120,
    value: ({ item }) => item.month_id.name,
  },
  {
    header: 'Year',
    type: 'text',
    field: 'year_id',
    // width: 120,
    value: ({ item }) => item.year_id.name,
  },
  {
    header: 'account.headers.ecrNo'.getLabel(),
    type: 'number',
    field: 'employee.ecrNumber',
    // width: 120,
  },
  {
    header: 'account.headers.ecrPageNo'.getLabel(),
    type: 'number',
    field: 'employee.ecrPageNumber',
    // width: 120,
  },
  {
    header: 'account.headers.employeeName'.getLabel(),
    type: 'text',
    // width: 140,
    value: ({ item }) => getString([item.employee.firstName, item.employee.lastName]),
  },
  {
    header: 'account.headers.employeeId'.getLabel(),
    type: 'text',
    field: 'employee.employeeId',
    // width: 120,
  },
  {
    header: 'account.headers.department'.getLabel(),
    type: 'text',
    field: 'employee.department.name',
    // width: 120,
    value: ({ item: { employee: { department = [] } = {} } = {} }) => department?.map(({ name }) => name).join(', '),
  },
  {
    header: 'account.headers.grossSalary'.getLabel(),
    type: 'number',
    value: ({ item }) => item?.gross_amount?.toFixed(2),
    // width: 120,
  },
  {
    header: 'account.headers.salaryGenerated'.getLabel(),
    type: 'number',
    value: ({ item }) => item?.payable_amount?.toFixed(2),
    width: 150,
  },
];

const SalaryRecordList = (props) => {
  const { navigation } = props;
  const [salaryComponents, setSalaryComponents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salaryDetails, setSalaryDetails] = useState();
  const currentMonth = new Date().toLocaleString('default', { month: 'long' }).toString();
  const currentYear = new Date().getFullYear().toString();

  const fetchMonthAndYearId = useCallback(async () => {
    const result = await invoke({
      id: 'getMonthAndYearId',
      paramValue: { currentMonth, currentYear },
    });
    return result;
  }, [currentMonth, currentYear]);

  const setDefaultFilter = useCallback((monthAndYearId = {}) => {
    let dataParams = {};
    dataParams = {
      filters: {
        month_id: { filter: { value: monthAndYearId?.monthId }, value: monthAndYearId?.monthId },
        year_id: { filter: { value: monthAndYearId?.yearId }, value: monthAndYearId?.yearId },
      },
    };
    return { dataParams };
  }, []);

  useEffect(() => {
    (async () => {
      const monthAndYearId = await fetchMonthAndYearId();
      const { dataParams } = setDefaultFilter(monthAndYearId);
      navigation.setScreenState({ dataParams });
    })();
  }, []);

  const fetchSalaryComponents = useCallback(async () => {
    const fetchedSalaryComponents = await fetch({
      uri: {
        props: {
          query: {
            id: 'salaryComponent',
            addOnFilter: {
              isActive: true,
            },
            sort: { sequence_for_salary_sheet: 1 },
          },
          model: 'SalaryComponent',
        },
      },
    });
    setSalaryComponents(fetchedSalaryComponents?.data);
    setLoading(false);

    return fetchedSalaryComponents;
  }, []);

  const salaryComponentColumns = useMemo(() => salaryComponents?.map((item) => ({
    header: item?.name,
    type: 'text',
    width: 150,
    value: ({ item: { component_details = [] } = {} }) => {
      const data = component_details.find(
        ({ salary_component_id }) => salary_component_id._id === item._id,
      );
      return data ? parseFloat(data?.payable_amount).toFixed(2) : '';
    },
  })), [salaryComponents]);

  useEffect(() => {
    fetchSalaryComponents();
  }, [fetchSalaryComponents]);

  const tableColumns = useMemo(() => (
    [
      ...tabListColumns,
      // ...salaryComponentColumns,
      {
        type: 'moreActions',
        // width: 15,
        actions: [
          {
            type: 'link',
            text: 'Generate Salary',
            visible: () => true,
            link: ({
              item: {
                _id, employee, month_id, year_id,
              },
            }) => ({
              view: ROUTES.generateSalaryAction.name,
              params: {
                monthlyAttendanceId: _id,
                employee,
                month_id,
                year_id,
              },
            }),
          },
        ],
      },
    ]),
  [salaryComponentColumns]);

  return (
    !loading ? (
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <View style={{ flex: 1, overflow: 'hidden' }}>
          <Table
            {...props}
            showSelected
            isSelected={({ item }) => item?._id === salaryDetails?._id}
            action={({ item }) => ({
              type: 'link',
              link: () => {
                setSalaryDetails(item);
              },
            })}
            uri={() => ({
              query: {
                id: 'selfMonthlyAttendances',
                addOnFilter: {
                  invoiceDate: {
                    $exists: true,
                  },
                },
              },
              model: MODELS.MONTHLY_ATTENDANCE,
            })}
            columns={tableColumns}
            showActionIcon={false}
          />
        </View>
        {salaryDetails && (
        <ScrollView
          style={{
            width: '50%',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <SalaryDetails
            salaryDetails={salaryDetails}
            setSalaryDetails={setSalaryDetails}
            {...props}
          />
        </ScrollView>
        )}
      </View>
    ) : <ActivityIndicator />
  );
};
export default SalaryRecordList;
