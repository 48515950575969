import { Headline11 } from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import vars from './vars';

const { colors, headings } = vars;

const { neutral_1, neutral_3, neutral_4 } = manazeV6Colors;

export const breadcrumbStyle = {
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  viewRowStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  activeTextStyle: {
    ...headings.h8,
    color: neutral_1,
    numberOfLines: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  hoverTextStyle: {
    color: neutral_3,
    textDecoration: 'underline',
  },
  textStyle: {
    ...headings.h14,
    color: colors.primary.color1,
    numberOfLines: 1,
    fontWeight: 300,
  },
  iconStyle: {
    height: 20,
    width: 20,
    objectFit: 'contain',
    marginRight: 10,
  },
};
export const breadcrumbMobileStyle = {
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  viewRowStyle: { flexDirection: 'row', alignItems: 'center', cursor: 'pointer' },
  activeTextStyle: {
    ...Headline11,
    color: neutral_1,
    numberOfLines: 1,
  },
  hoverTextStyle: {
    color: neutral_3,
    textDecoration: 'underline',
  },
  textStyle: { ...Headline11, color: neutral_4, numberOfLines: 1 },
  iconStyle: {
    height: 20, width: 20, objectFit: 'contain', marginRight: 10,
  },
};
