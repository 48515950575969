import React from 'react';

import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';

import ConsultationLogs from '../../../../../../Wired/ConsultationLogTable';
import { MODELS } from '../../../../../../Lib/constants';
import PatientDetails from '../GeneralSurgery/PatientDetails';
import IpdForm from '../GeneralSurgery/IPDForm';
import ViewConsentForm from '../GeneralSurgery/ConsentForm';
import Investigation from '../GeneralSurgery/Investigation';
import ProgressReport from '../GeneralSurgery/ProgressReport';
import Outcome from '../GeneralSurgery/Outcome';
import History from '../GeneralSurgery/History';
import References from '../GeneralSurgery/References';
import OtList from '../GeneralMedicine/OTList';
import CaseHistoryAndExamination from '../PediatricsPICU/CaseHistoryAndExamination';
import TreatmentChart from '../GeneralSurgery/TreatmentChart';
import VitalSignsSheet from '../GeneralSurgery/VitalSignsSheet';

const ViewDayCareCentreTabs = (props) => {
  const {
    navigation: { state: { params = {} } = {} },
  } = props;

  return (
    <TabNavigator
      {...props}
      tabs={{
        PatientDetails: {
          label: 'Patient Details',
          screen: PatientDetails,
          screenProps: props,
        },
        ConsultationLogs: {
          label: 'Consultation Log',
          screen: ConsultationLogs,
          screenProps: {
            props,
            model: MODELS.CONSULTATION,
            id: 'consultationDataList',
            addOnFilter: { patient: { _id: params?.patient?.patient?._id } },
          },
        },
        IPDForm: {
          label: 'IPD Form',
          screen: IpdForm,
          screenProps: props,
        },
        ConsentForm: {
          label: 'Consent Form',
          screen: ViewConsentForm,
          screenProps: props,
        },
        CaseHistoryAndExamination: {
          label: 'Case History & Examination',
          screen: CaseHistoryAndExamination,
          screenProps: props,
        },
        Investigation: {
          label: 'Investigation',
          screen: Investigation,
          screenProps: props,
        },
        ProgressReport: {
          label: 'Progress Report',
          screen: ProgressReport,
          screenProps: props,
        },
        MonitoringSheet: {
          label: 'Monitoring Sheet',
          screen: VitalSignsSheet,
          screenProps: props,
        },
        TreatmentChart: {
          label: 'Treatment Chart',
          screen: TreatmentChart,
          screenProps: props,
        },
        Outcome: {
          label: 'Outcome',
          screen: Outcome,
          screenProps: props,
        },
        History: {
          label: 'History',
          screen: History,
          screenProps: props,
        },
        References: {
          label: 'References',
          screen: References,
          screenProps: props,
        },
        OtList: {
          label: 'OT List',
          screen: OtList,
          screenProps: props,
        },
      }}
    />
  );
};
export default ViewDayCareCentreTabs;
