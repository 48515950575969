import React from 'react';

import ListActionModal from '../../../../../Components/ListActionModal';

import {
  save, View,
} from '../../../../../app-components';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { Form } from '../../../../../app-components/form/Form';
import { EditElectricityBillDetails } from '../../uri';
import { MODELS } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';

const EditBillingDetails = (props) => {
  const footer = {
    actions: [
      save,
    ],
  };
  return (
    <ListActionModal
      {...props}
      title="Edit Electricity Billing Details"
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            {...props}
            onSubmit={submit({
              model: MODELS.ELECTRICITY_BILL,
            })}
            uri={EditElectricityBillDetails({ props })}
            reloadEvent="electricityBillDataList"
            reloadOnChangeEvent={`reload${MODELS.ELECTRICITY_BILL}`}
            closeView={1}
            mandatory={{
              units: 1,
              amount: 1,
              billingMonth: 1,
              billingYear: 1,
            }}
            type="standardShadow"
            lg={{
              verticalFormGroups: [
                {
                  formGroups: [
                    {
                      fieldVariant: 'filled',
                      direction: 'row',
                      columnsPerRow: 3,
                      columns: [
                        {
                          type: 'text',
                          render: (data) => (data.navigation.state.params.patient.houseID.name),
                          label: 'House ID',
                          mandatory: true,
                          editable: false,
                        },
                        {
                          type: 'number',
                          field: 'units',
                          label: 'Units',
                          editable: true,
                          mandatory: true,
                        },
                        {
                          type: 'number',
                          field: 'amount',
                          label: 'Amount',
                          editable: true,
                          mandatory: true,
                        },
                        {
                          ...autoSuggestInput(
                            {
                              field: 'billingMonth',
                              label: 'Select Month',
                              placeholder: 'Select Month',
                              variant: 'filled',
                              suggestionField: 'name',
                              model: MODELS.MONTHS,
                              query: 'MonthTypeSuggestions',
                              mandatory: true,
                            },
                          ),
                        },
                        {
                          ...autoSuggestInput(
                            {
                              field: 'billingYear',
                              label: 'Select Year',
                              placeholder: 'Select Year',
                              variant: 'filled',
                              suggestionField: 'name',
                              model: MODELS.YEARS,
                              query: 'YearTypeSuggestions',
                              mandatory: true,
                            },
                          ),
                        },
                      ],
                    },
                  ],
                },
              ],
              footer,
            }}
          />
        </View>
      )}
    />
  );
};
export default EditBillingDetails;
