import React, { useState } from 'react';
import uuid from 'uuid/v4';

import {
  NestedAction,
  NestedTable,
} from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { save, Text, View } from '../../../../../../../../app-components';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import { dateFormatX } from '../../../../../../constants';
import vars from '../../../../../../../../theme/vars';
import {
  autoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../../../../../../../autoSuggestions';
import {
  bloodLoss,
  entPosition,
  followUp,
  hpe,
  operationalFindings,
  operationalPosition,
  operationalProcedure,
  Orthopedic,
  patientName,
  physicianName,
  position,
  postOperationOrder,
  preOperationStatus,
  surgeonName,
  unit,
} from '../../../fieldConfiguration';
import { editIpdNotesUri } from '../../../../../../../../Queries/operationNotes';

const EditOperationNotesIPDForm = (props) => {
  const [anesthesiaDose, setAnesthesiaDose] = useState(true);
  const [illusionPosition, setIllusion] = useState(true);
  const { navigation } = props;
  const {
    navigation: {
      state: {
        params: { dataParams },
      },
    },
  } = props;

  return (
    <ListActionModal
      title="Operation/Procedure Notes"
      description="Log Operation notes of the patient."
      content={(
        <Form
          {...props}
          closeView={1}
          uri={editIpdNotesUri({ navigation })}

          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          reloadEvent={`reload${MODELS.OPERATION_PROCEDURE_NOTES}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.finishTime = dateFormatX(
              data?.endHour,
              data?.endMinute,
              data?.endFormat,
            );
            updates.startTime = dateFormatX(
              data?.startHour,
              data?.startMinute,
              data?.startFormat,
            );
            delete updates.startHour;
            delete updates.startMinute;
            delete updates.startFormat;
            delete updates.endHour;
            delete updates.endMinute;
            delete updates.endFormat;
            const newUpdate = {
              ...updates,
            };
            const submitAPI = submit({
              model: MODELS.OPERATION_PROCEDURE_NOTES,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            return submitAPI({ data, updates: newUpdate });
          }}
          mandatory={{
            operationDate: 1,
            operationName: 1,
            surgeonName: dataParams?.filters?.specialityFilter === 'General Surgery' ? 1 : 0,
            startHour: 1,
            startMinute: 1,
            startFormat: 1,
            endHour: 1,
            endMinute: 1,
            endFormat: 1,
          }}
          computations={{
            self: {
              anesthesia: {
                compute: (data) => {
                  if (data.anesthesia === 'No') {
                    setAnesthesiaDose(true);
                    return ({
                      set: {
                        anesthesiologist: '',
                        anesthesiaType: '',
                      },
                    });
                  }
                  setAnesthesiaDose(false);
                },
                onChange: ['anesthesia'],
              },
              illusion: {
                compute: (data) => {
                  if (data.isIllusion) {
                    setIllusion(false);
                  } else {
                    setIllusion(true);
                    return ({
                      set: {
                        illusion: '',
                      },
                    });
                  }
                },
                onChange: ['isIllusion'],
              },
            },
          }}
          validations={{
            bloodLoss: ({ data }) => validateInputLength(data?.bloodLoss, 1, 4),
            preOperationDiagnosis: ({ data }) => validateInputLength(data
              ?.preOperationDiagnosis, 1, 300),
            preOperationStatus: ({ data }) => validateInputLength(data?.preOperationStatus, 1, 300),
            anesthesiaType: ({ data }) => validateInputLength(data?.anesthesiaType, 1, 50),
            operationalPosition: ({ data }) => validateInputLength(data
              ?.operationalPosition, 1, 250),
            operationalFindings: ({ data }) => validateInputLength(data
              ?.operationalFindings, 1, 300),
            operationalProcedure: ({ data }) => validateInputLength(data
              ?.operationalProcedure, 1, 500),
          }}
        //   defaultValues={{
        //     operationalFindings: new Array(4)
        //       .fill()
        //       .map(() => ({ _id: `new_${uuid()}` })),
        //     startFormat: 'AM',
        //     endFormat: 'AM',
        //     startHour: new Date(item.startTime).getHours(),
        //     operationDate: new Date(),
        //   }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'date',
                        field: 'operationDate',
                        label: 'Date of operation',
                        editable: true,
                        mandatory: true,
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'operationName',
                        label: 'Name of operation',
                        placeholder: 'name of operation',
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.OPERATIONS,
                        query: 'masterDataList',
                        mandatory: true,
                        editable: true,
                      }),
                      multiSearchInput({
                        field: 'surgeonName',
                        variant: 'filled',
                        label: 'Surgeon Name',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        mandatory: true,
                        editable: true,
                        visible: surgeonName[dataParams?.filters?.specialityFilter],
                      }),
                      multiSearchInput({
                        field: 'physicianName',
                        variant: 'filled',
                        label: 'Physician Specialist',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                        visible: physicianName[dataParams?.filters?.specialityFilter],
                      }),
                      {
                        type: 'text',
                        field: 'patientName',
                        label: 'Patient Name',
                        editable: true,
                        visible: patientName[dataParams?.filters?.specialityFilter],
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'unit',
                        label: 'unit',
                        placeholder: 'unit',
                        keyField: 'name',
                        options: [1, 2, 3, 4, 5, 6],
                        editable: true,
                        visible: unit[dataParams?.filters?.specialityFilter],
                      }),
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        field: 'preOperationDiagnosis',
                        label: 'Pre-operation Diagnosis',
                        allowWhiteSpaces: true,
                        editable: true,
                        width: 300,
                        minHeight: 80,
                      },
                      {
                        ...newDateInput({
                          hour: { field: 'startHour', label: 'Start Hour' },
                          minute: { field: 'startMinute', label: 'Start Minute' },
                          meridiem: { field: 'startFormat' },
                        }),
                        width: 400,
                        mandatory: true,
                      },
                      newDateInput({
                        hour: { field: 'endHour', label: 'Finish Hour', mandatory: true },
                        minute: { field: 'endMinute', label: 'Finish Minute', mandatory: true },
                        meridiem: { field: 'endFormat', mandatory: true },
                      }),
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      multiSearchInput({
                        field: 'assistantsEmployee',
                        variant: 'filled',
                        label: 'Assistant',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                      searchInput({
                        field: 'nurseOnDuty',
                        variant: 'filled',
                        label: 'Nurses on duty',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                      {
                        type: 'textArea',
                        field: 'preOperationStatus',
                        label: 'Pre-operation Status',
                        allowWhiteSpaces: true,
                        editable: true,
                        minHeight: 80,
                        visible:
                          preOperationStatus[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'radioGroup',
                        field: 'anesthesia',
                        label: 'ANESTHESIA',
                        container: 'topLabel',
                        editable: true,
                        options: ['Yes', 'No'],
                      },
                      searchInput({
                        field: 'anesthesiologist',
                        variant: 'filled',
                        label: 'Anesthesiologist Name',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                        disabled: anesthesiaDose,
                      }),
                      {
                        type: 'text',
                        field: 'anesthesiaType',
                        label: 'Type of Anesthesia',
                        allowWhiteSpaces: true,
                        disabled: anesthesiaDose,
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'Implant Used',
                        visible:
                        Orthopedic[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                  {
                    columnsPerRow: 3,
                    fieldVariant: 'filled',
                    labelStyle: {
                      ...vars.headings.h8,
                      color: vars.colors.grey.color2,
                      textTransform: 'uppercase',
                      paddingLeft: 10,
                    },
                    groupsContainerStyle: { paddingLeft: 12 },
                    groups: [
                      {
                        columns: [
                          {
                            type: 'text',
                            field: 'itemused',
                            label: 'Item Used',
                            editable: true,
                            visible:
                            Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'make',
                            editable: true,
                            label: 'Make',
                            visible:
                            Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'number',
                        field: 'bloodLoss',
                        label: 'Blood loss',
                        editable: true,
                        visible:
                          bloodLoss[dataParams?.filters?.specialityFilter],
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'otTechnicianName',
                        label: 'OT Technician Name',
                        placeholder: 'OT Technician Name',
                        keyField: 'name',
                        valueField: 'name',
                        searchField: 'name',
                        suggestionField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        editable: true,
                      }),
                      {
                        type: 'text',
                        field: 'position',
                        label: 'Position',
                        visible:
                          position[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                visible: entPosition[dataParams?.filters?.specialityFilter],
                label: 'Position',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'a.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isSupinePosition',
                        label: 'Supine Position Swelling',
                        editable: true,
                      },
                      {
                        render: () => 'b.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isLocalInfiltration',
                        label: 'Local infiltration with 2% lignocaine with adrelanine done',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 5,
                fieldVariant: 'filled',
                visible: entPosition[dataParams?.filters?.specialityFilter],
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'c.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isIllusion',
                        label: 'Illusion',
                        editable: true,
                      },
                      {
                        type: 'radioGroup',
                        field: 'illusion',
                        editable: true,
                        disabled: illusionPosition,
                        width: 600,
                        options: ['Postural Illusion', 'Supra aural illusion', 'Endaural illusion'],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                visible: entPosition[dataParams?.filters?.specialityFilter],
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'd.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isTemporalisFancia',
                        label: 'Temporial fancia craft harvested',
                        editable: true,
                      },
                      {
                        render: () => 'e.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isMarginsPerforation',
                        label: 'Margins of perforation fractured scrapping of undersurfall of reminant Tympanic Membrane done',
                        editable: true,
                      },
                      {
                        render: () => 'f.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isCanalIllusionTaken',
                        label: 'Canal Illusion Taken',
                        editable: true,
                      },
                      {
                        render: () => 'g.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isTympanomestalFlapElevated',
                        label: 'Tympanomestal Flap Elevated',
                        editable: true,
                      },
                      {
                        render: () => 'h.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isMiddleEarEnteredOssicular',
                        label: 'Middle Ear Entered-Ossicular Status Checked',
                        editable: true,
                      },
                      {
                        render: () => 'i.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isMiddleEarPackingDone',
                        label: 'Middle Ear Packing Done using Medicated Gel Foam',
                        editable: true,
                      },
                      {
                        render: () => 'j.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isTempralisFevricCraft',
                        label: 'Temporails fevric Craft Placed By Underlay technique',
                        editable: true,
                      },
                      {
                        render: () => 'k.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isExternalEarCanal',
                        label: 'External Ear Canal Pallery Done Using Medicated gel Foam',
                        editable: true,
                      },
                      {
                        render: () => 'l.',
                        width: 50,
                      },
                      {
                        type: 'checkBox',
                        field: 'isClosureDone',
                        label: 'Closure Done',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'OPERATIONAL NOTE',
                editable: true,
                labelStyle: {
                  ...vars.headings.h9,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 7,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        render: () => 'Position',
                        visible:
                          operationalPosition[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'textArea',
                        field: 'operationalPosition',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                        visible:
                          operationalPosition[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Findings',
                        visible:
                          operationalFindings[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        field: 'operationalFindings',
                        fieldType: 'nested',
                        visible:
                          operationalFindings[
                            dataParams?.filters?.specialityFilter
                          ],
                        Component: NestedTable,
                        componentProps: {
                          footer: ({ addRow }) => ({
                            leftActions: [
                              {
                                render: () => (
                                  <View
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 3,
                                      width: 120,
                                      height: 40,
                                      borderColor: 'blue',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.secondary.color2,
                                      }}
                                    >
                                      + Add Row
                                    </Text>
                                  </View>
                                ),
                                onPress: addRow,
                              },
                            ],
                          }),
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              color: vars.colors.grey.color3,
                              backgroundColor: 'white',
                              numberOfLines: 1,
                              marginBottom: 7,
                            },
                            headerRowContainerStyle: {
                              backgroundColor: 'white',
                              marginRight: 15,
                            },
                            columns: [
                              {
                                type: 'textArea',
                                container: 'rightLabel',
                                field: 'description',
                                placeholder: 'This is description box..',
                                variant: 'filled',
                              },
                              NestedAction(),
                            ],
                          },
                        },
                      },
                      {
                        render: () => 'Post Operation Orders',
                        visible:
                          postOperationOrder[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'textArea',
                        field: 'postOperationOrder',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                        visible:
                          postOperationOrder[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Procedure',
                        visible:
                          operationalProcedure[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        type: 'textArea',
                        field: 'operationalProcedure',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                        visible:
                          operationalProcedure[
                            dataParams?.filters?.specialityFilter
                          ],
                      },
                      {
                        render: () => 'Follow-up',
                        visible:
                          followUp[dataParams?.filters?.specialityFilter],
                      },
                      {
                        type: 'textArea',
                        field: 'followUp',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                        visible:
                          followUp[dataParams?.filters?.specialityFilter],
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                visible: hpe[dataParams?.filters?.specialityFilter],
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        field: 'hpe',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                      },
                      {
                        type: 'textArea',
                        field: 'cytology',
                        container: 'topLabel',
                        placeholder:
                          'This is description box with description of it self',
                        minHeight: 80,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
      {...props}
    />
  );
};
export default EditOperationNotesIPDForm;
