import React, { useState } from 'react';

import {
  FormField,
  StandardButton,
  View,
  Toast,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import ListActionModal from '../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../autoSuggestions';
import vars from '../../../../theme/vars';
import {
  RolesOrder,
} from '../../../../Lib/constants';
import { invoke } from '../../../../AppServices';

const SendToReviewModal = (props) => {
  const { navigation, eventDispatcher } = props;
  const { navigation: { state: { params } } = {} } = props;
  // const [isReviewDaysLimit, setIsReviewDaysLimit] = useState('');

  return (
    <ListActionModal
      title={'purchase.title.sendForReview'.getLabel()}
      description={
          `This action will send the ${
              params?.item?.type === 'PO' ? 'Purchase Sanction' : 'Supply Order'
          } for review.`
      }
      content={(
        <Form
          closeView={2}
          Purchase
          Order
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          // eslint-disable-next-line no-shadow
          onSubmit={async ({ updates }) => {
            const paramValue = {
              _id: params?.data?._id,
              subject: params?.data?.subject,
              amount: params?.data?.amount,
              attachment: params?.data?.attachment,
              billDate: params?.data?.billDate,
              billNumber: params?.data?.billNumber,
              approvalRequest: updates.assignedToRole,
              accountant: params?.data?.accountant?._id,
              head: params?.data?.head?._id,
              assignToRole: params?.data?.assignedToRole,
              remarks: updates?.remarks,
              nameOfFirm: params?.data?.nameOfFirm,
              isReview: true,
            };

            const result = await invoke({
              id: 'createContingentVoucher',
              paramValue,
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: 'Contingent Voucher has been sent for review successfully',
                type: 'success',
                position: 'top',
                direction: 'right',
              });
              eventDispatcher.notify('reload contingent_voucher_list');
              navigation.pop(params?.item?.formData ? 3 : 2);
            }
          }}
          reloadEvent="contingent_voucher_list"
          defaultValues={() => ({
            assignedToRole: 'PSI',
            // assignedToRoleKey: 1,
            // status: params?.item?.currentApprovalQueue?.status,
            // _id: params?.item?.currentApprovalQueue?._id,
          })}
          mandatory={{
            remarks: 1,
            assignedToRole: 1,
            // approvalDaysLimit: 1,
          }}
          submitMessage="Request had been sent for review successfully."
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1, marginRight: 20 }}>
                  <FormField
                    {... autoSuggestInput({
                      label: 'purchase.labels.sendForApprovalTo'.getLabel(),
                      variant: 'filled',
                      keyFiled: 'label',
                      field: 'assignedToRole',
                      suggestionField: 'label',
                      options: RolesOrder,
                      valueField: 'label',
                      mandatory: true,
                    })}
                  />
                </View>
                {/* <View style={{ flex: 1 }}>
                  <FormField
                    type="number"
                    label={'purchase.labels.noOfDays'.getLabel()}
                    variant="filled"
                    editable
                    field="approvalDaysLimit"
                    mandatory
                  />
                </View> */}
              </View>

              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                {/* <View style={{ flex: 1, marginRight: 25 }}>
                  <FormField
                    {... autoSuggestInput({
                      field: 'reviewByDepartment',
                      label: 'Department',
                      placeholder: 'Select department',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      variant: 'filled',
                    },
                    {
                      addOnFilter: ({ data: { reviewByDepartment = [] } }) => {
                        const departmentId = reviewByDepartment?._id;
                        if (departmentId) {
                          return {
                            _id: { $ne: departmentId },
                          };
                        }
                        return {};
                      },
                    })}
                  />
                </View> */}
                <View style={{ flex: 1 }}>
                  <FormField
                    type="textArea"
                    label={'purchase.labels.remark'.getLabel()}
                    variant="filled"
                    field="remarks"
                    mandatory
                    allowWhiteSpaces
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'purchase.button.confirm'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default SendToReviewModal;
