/* eslint-disable consistent-return */
import React from 'react';
import { Table } from '../../../../app-components/table/Table';
import { Text } from '../../../../app-components';
import vars from '../../../../theme/vars';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { bloodCategoryAggregatedList } from '../../../../Queries/bloodBank';

const tabListColumns = [
  { width: 14 },
  {
    header: 'bloodInventory.headers.bloodCategory'.getLabel(),
    type: 'text',
    field: 'category',
    sortable: 'category',
  },
  {
    header: 'bloodInventory.headers.unitInStocks'.getLabel(),
    type: 'number',
    value: ({ item }) => `${item.unitInStock}`,
    width: 300,
  },
  {
    header: 'bloodInventory.headers.status'.getLabel(),
    type: 'text',
    render: ({ item }) => {
      if (item?.status === 'Out of Stock') {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>
            {item?.status}
          </Text>
        );
      }
      if (item?.status === 'In-Stock') {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}>
            {item?.status}
          </Text>
        );
      }
      if (item?.status === 'Low on Stock') {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.warning }}>
            {item?.status}
          </Text>
        );
      }
    },
  },
];

const BloodCategoryList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      {...restProps}
      uri={() => bloodCategoryAggregatedList({ filter: addOnFilter })}
      model={MODELS.BLOOD_CATEGORY}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent={`reload${MODELS.BLOOD_BANK}`}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewBloodCategory.name,
          params: { item },
        },
      })}
    />
  );
};
export default BloodCategoryList;
