import React, { useCallback, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import PatientDetails from './ViewPatientDetail/index';
import { ROUTES, vaccinationStatus } from '../../../Lib/constants';

import {
  ActivityIndicator, OutlineButton, Text, Toast, View,
} from '../../../app-components';
import NoShiftAssigned from './Components/NoShiftAssigned';
import { getErrorString } from '../../../Lib/helpers';
import SessionInfo from './Components/SessionInfo';
import SessionTabs from './tabs';
import { getUser, gpsStore, invoke } from '../../../AppServices';
import RepeatedDoseVaccineDetail from './ViewPatientDetail/PatientVaccineDetailRepeatedDose';
import SingleDoseVaccineDetail from './ViewPatientDetail/PatientVaccineDetailSingleDose';
import VaccinatedPatientDetailModal from './ViewPatientDetail/VaccinatedPatientDetailModal';
import vars from '../../../theme/vars';

const IncomingPatients = (props) => {
  const user = getUser() || {};
  const { employee: { _id: employeeId } = {} } = user;

  const [loading, setLoading] = useState(true);
  const [sessionInfo, setSessionInfo] = useState();

  const getMyVaccinationSession = useCallback(async () => {
    try {
      const fetchedSessionDetails = await invoke({
        id: 'getVaccinationSession',
      });

      if (!isEmpty(fetchedSessionDetails)) {
        setSessionInfo(fetchedSessionDetails);
      } else {
        setSessionInfo(null);
      }
      setLoading(false);
      return fetchedSessionDetails;
    } catch (err) {
      setLoading(false);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      return err;
    }
  }, []);

  const onSessionAssigned = useCallback(() => {
    Toast.show({
      message: 'Success',
      description: 'Vaccination Room has been assigned from you',
      type: 'success',
      position: 'top',
      direction: 'right',
    });
    getMyVaccinationSession();
  }, [getMyVaccinationSession]);

  const onSessionEnded = useCallback(() => {
    Toast.show({
      message: 'Success',
      description: 'Session Ended.',
      type: 'success',
      position: 'top',
      direction: 'right',
    });
    getMyVaccinationSession();
  }, [getMyVaccinationSession]);

  const subscribeToSessionEnded = useCallback((employee) => {
    gpsStore.subscribeEvent({
      event: `activeSession-${employee}-ended`,
      callback: onSessionEnded,
    });
  }, [onSessionEnded]);

  const unSubscribeToSessionEnded = useCallback((employee) => {
    gpsStore.unsubscribeEvent({
      event: `activeSession-${employee}-ended`,
      callback: onSessionEnded,
    });
  }, [onSessionEnded]);

  const subscribeToSessionStarted = useCallback((employee) => {
    gpsStore.subscribeEvent({
      event: `activeSession-${employee}-started`,
      callback: onSessionAssigned,
    });
  }, [onSessionAssigned]);

  const unSubscribeToSessionStarted = useCallback((employee) => {
    gpsStore.unsubscribeEvent({
      event: `activeSession-${employee}-started`,
      callback: onSessionAssigned,
    });
  }, [onSessionAssigned]);

  useEffect(() => {
    if (sessionInfo?.session?.nurse?._id) {
      subscribeToSessionEnded(sessionInfo?.session?.nurse?._id);
    }

    return () => {
      unSubscribeToSessionEnded();
    };
  }, [sessionInfo]);

  useEffect(() => {
    if (employeeId) {
      subscribeToSessionStarted(employeeId);
    }

    return () => {
      unSubscribeToSessionStarted();
    };
  }, [employeeId]);

  useEffect(() => {
    getMyVaccinationSession();
  }, []);

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{
        height: 44, marginLeft: 16, marginRight: 16, marginTop: 24,
      }}
      >
        {sessionInfo
          && <SessionInfo sessionInfo={sessionInfo} {...props} />}
      </View>
      <View style={{ flex: 1, marginLeft: 8, overflow: 'hidden' }}>
        {sessionInfo ? <SessionTabs sessionInfo={sessionInfo} {...props} /> : <NoShiftAssigned />}
      </View>
    </View>
  );
};

export default {
  [ROUTES.viewIncomingPatientNursePortal.name]: {
    screen: IncomingPatients,
  },
  [ROUTES.viewPatientDetail.name]: {
    screen: PatientDetails,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.patient?.uhid}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => <OutlineButton label="Vaccinated" />,
        type: 'invoke',
        invoke: async (props) => {
          try {
            const { navigation, eventDispatcher, data } = props;
            const modifiedItem = { ...data };
            modifiedItem.vaccinated = vaccinationStatus.vaccinated;
            const result = await invoke({
              id: 'updatePatientVaccinationStatus',
              paramValue: { modifiedItem },
            });

            if (result) {
              Toast.show({
                message: 'Success',
                description: `Patient with token number ${data?.token} has been marked as vaccinated`,
                type: 'success',
                position: 'top',
                direction: 'right',
                duration: 3000,
              });
              eventDispatcher.notify('vaccinationAssigned');
              navigation.pop();
            }
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: `${getErrorString(e)}`,
              type: 'error',
              position: 'top',
              direction: 'right',
            });
          }
        },
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (
            item?.status === vaccinationStatus.vaccinated
            || item.status === vaccinationStatus.notAppear
          ) {
            return false;
          }
          return true;
        },
      },
      {
        render: () => <OutlineButton label="Not Appeared" />,
        type: 'invoke',
        invoke: async (props) => {
          try {
            const { navigation, eventDispatcher, data } = props;
            const modifiedItem = { ...data };
            modifiedItem.notAppeared = vaccinationStatus.notAppear;
            const result = await invoke({
              id: 'updatePatientVaccinationStatus',
              paramValue: { modifiedItem },
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: `Patient with token number ${data?.token} has been marked as not appeared`,
                type: 'success',
                position: 'top',
                direction: 'right',
                duration: 3000,
              });
              eventDispatcher.notify('vaccinationAssigned');
              navigation.pop();
            }
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: `${getErrorString(e)}`,
              type: 'error',
              position: 'top',
              direction: 'right',
            });
          }
        },
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (
            item?.status === vaccinationStatus.vaccinated
            || item.status === vaccinationStatus.notAppear
          ) {
            return false;
          }
          return true;
        },
      },
      {
        render: () => (
          <OutlineButton label="Generate Vaccine Card" />
        ),
        type: 'save',
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (item?.status === vaccinationStatus.vaccinated) return true;
          return false;
        },
      },
    ],
  },
  [ROUTES.patientVaccineDetailRepeatedDoseModal.name]: {
    screen: RepeatedDoseVaccineDetail,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 1060,
      height: 550,
    },
  },
  [ROUTES.patientVaccineDetailSingleDoseModal.name]: {
    screen: SingleDoseVaccineDetail,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 950,
      height: 500,
    },
  },
  [ROUTES.patientVaccineDetailConfirmationModal.name]: {
    screen: VaccinatedPatientDetailModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 1050,
      height: 550,
    },
  },
};
