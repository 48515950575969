import React from 'react';

import {
  DateFilter,
  FilterGroup,
} from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { filterActive, filterInactive } from '../../../../../images';
import FTS from '../../../../../Components/TableFilters/FtsSearch';
import { genders, MODELS } from '../../../../../Lib/constants';
import { countUri } from '../../../../../Queries/countUri';
import UnknownPatientList from '../UnknownPatientList';
import AdmittedList from '../AdmittedList/AdmittedPatientList';
import PatientList from '../PatientList';

const ViewPatientTabs = (props) => {
  const { dataParams } = props;

  return (
    <TabNavigator
      {...props}
      actions={[
        (actionProps) => <FTS {...actionProps} action={{ exp: ['patientName', 'uhid', 'relationName'] }} />,
        <DateFilter field="_createdOn" key="dateFilter" />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggestOptions',
              options: genders,
              field: 'gender',
              label: 'Gender',
              multiSelect: false,
            },
            {
              type: 'autoSuggestOptions',
              options: ['S/O', 'D/O', 'W/O'],
              field: 'relation',
              label: 'Relation',
              multiSelect: false,
            },
          ],
        }),
      ]}
      tabs={{
        registeredPatient: {
          label: 'Registered Patient',
          screen: PatientList,
          screenProps: {
            searchData: dataParams,
            dataParams,
          },
          countUri: countUri({
            model: MODELS.PATIENTS,
            id: 'patientDataList',
          }),
        },
        unknownPatient: {
          label: 'Unknown Patient',
          screen: UnknownPatientList,
          countUri: countUri({
            model: MODELS.PATIENTS,
            id: 'unknownPatientDataList',
          }),
        },
      }}
    />
  );
};
export default ViewPatientTabs;
