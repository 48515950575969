import React from 'react';
import { FormHoc, Text } from '../../../../../app-components';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { EditAssetTransferRequestStatus } from '../../../../../Queries/asset';

const ViewOtherRequests = FormHoc({
  type: 'standard',
  closeView: 1,
  reloadEvent: `reload${MODELS.ASSET_TRANSFER_REQUESTS}`,
  uri: EditAssetTransferRequestStatus,
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'date',
                label: 'asset.labels.dateRaised'.getLabel(),
                format: 'DD MMM YYYY',
                field: '_createdOn',
                editable: false,
              },
              {
                type: 'text',
                label: 'asset.labels.fromDepartment'.getLabel(),
                field: 'fromDepartment.name',
                editable: false,
              },
              {
                type: 'text',
                label: 'asset.labels.fromSpeciality'.getLabel(),
                field: 'fromSpeciality.name',
                editable: false,
              },
              {
                type: 'text',
                label: 'asset.labels.toDepartment'.getLabel(),
                field: 'toDepartment.name',
                editable: false,
              },
              {
                type: 'text',
                label: 'asset.labels.toSpeciality'.getLabel(),
                field: 'toSpeciality.name',
                editable: false,
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'asset.title.assetDetails'.getLabel(),
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 4,
        },
        groupsContainerStyle: { paddingLeft: 0 },
        groups: [
          {
            columns: [
              {
                field: 'departmentStocks',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowContainerStyle: {
                      minHeight: 32,
                      paddingLeft: 12,
                      paddingRight: 4,
                    },
                    headerRowColumnTextStyle: {
                      paddingLeft: 2,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                      textTransform: 'upperCase',
                    },
                    columns: [
                      {
                        type: 'text',
                        header: 'asset.labels.assetName'.getLabel(),
                        label: 'asset.labels.assetName'.getLabel(),
                        variant: 'filled',
                        field: 'serialNumber.serialNumber',
                        editable: false,
                      },
                      {
                        type: 'text',
                        header: 'asset.labels.status'.getLabel(),
                        label: 'asset.labels.status'.getLabel(),
                        variant: 'filled',
                        // field: 'assetStatus',
                        editable: false,
                        render: ({ item: { assetStatus } = {} } = {}) => {
                          const stocksStatus = assetStatus.toString();
                          let statusColor = vars.colors.warning;
                          if (stocksStatus === 'In-Storage') {
                            statusColor = vars.colors.primary.color2;
                          }
                          return (
                            <Text style={{ ...vars.headings.h11, color: statusColor }}>
                              {stocksStatus}
                            </Text>
                          );
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ViewOtherRequests;
