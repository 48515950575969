import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const CaseHistoryAndExamination = (props) => (
  <Form
      // scrollable
    uri={(uriProps) => ({
      query: {
        id: 'caseHistoryData',
        addOnFilter: {
          admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
          speciality: props?.dataParams?.filters?.specialityFilter,
        },
      },
      model: 'case_history_and_examination',
    })}
    onSubmit={submit({
      model: 'case_history_and_examination',
    })}
    defaultValues={(deafultProps) => ({
      cheifComplaint: new Array(4)
        .fill()
        .map(() => ({ _id: `new_${uuid()}` })),
      gpe: {
        meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
      },
      admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
      speciality: props?.dataParams?.filters?.specialityFilter,
    })}
    bodyContainerStyle={{
      margin: 0,
      marginRight: '2%',
    }}
      // In case submodal are used
      // Start
    beforeSubmit={({ data }) => ({
      data,
      updates: data,
    })}
    deepDiff={false}
      // Finish
    saveOnChange
    {...props}
    afterSubmit={({ submitResult: { result } = {}, data }) => ({
      data: {
        ...data,
        _id: result._id,
      },
    })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
    computations={{
      self: {
        'pastIllness.htnName': {
          compute: (data) => {
            if (data?.pastIllness?.htnName === false) {
              return ({
                set: {
                  'pastIllness.htnType': '',
                  'pastIllness.htnTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.htnName'],
        },
        'pastIllness.dmName': {
          compute: (data) => {
            if (data?.pastIllness?.dmName === false) {
              return ({
                set: {
                  'pastIllness.dmType': '',
                  'pastIllness.dmTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.dmName'],
        },
        'pastIllness.tbName': {
          compute: (data) => {
            if (data?.pastIllness?.tbName === false) {
              return ({
                set: {
                  'pastIllness.tbType': '',
                  'pastIllness.tbTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.tbName'],
        },
        'pastIllness.thyroidName': {
          compute: (data) => {
            if (data?.pastIllness?.thyroidName === false) {
              return ({
                set: {
                  'pastIllness.thyroidType': '',
                  'pastIllness.thyroidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.thyroidName'],
        },
        'pastIllness.steroidName': {
          compute: (data) => {
            if (data?.pastIllness?.steroidName === false) {
              return ({
                set: {
                  'pastIllness.steroidType': '',
                  'pastIllness.steroidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.steroidName'],
        },
        'pastIllness.covidName': {
          compute: (data) => {
            if (data?.pastIllness?.covidName === false) {
              return ({
                set: {
                  'pastIllness.covidType': '',
                  'pastIllness.covidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.covidName'],
        },
        'pastIllness.copdName': {
          compute: (data) => {
            if (data?.pastIllness?.copdName === false) {
              return ({
                set: {
                  'pastIllness.copdType': '',
                  'pastIllness.copdTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.copdName'],
        },
        'pastIllness.asthmaName': {
          compute: (data) => {
            if (data?.pastIllness?.asthmaName === false) {
              return ({
                set: {
                  'pastIllness.asthmaType': '',
                  'pastIllness.asthmaTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.asthmaName'],
        },
        'pastIllness.otherName': {
          compute: (data) => {
            if (data?.pastIllness?.otherName === false) {
              return ({
                set: {
                  'pastIllness.otherTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.otherName'],
        },
        'pastIllness.htn': {
          compute: (data) => {
            if (data?.pastIllness?.htnType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.htnTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.htnType'],
        },
        'pastIllness.dm': {
          compute: (data) => {
            if (data?.pastIllness?.dmType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.dmTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.dmType'],
        },
        'pastIllness.tb': {
          compute: (data) => {
            if (data?.pastIllness?.tbType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.tbTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.tbType'],
        },
        'pastIllness.thyroid': {
          compute: (data) => {
            if (data?.pastIllness?.thyroidType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.thyroidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.thyroidType'],
        },
        'pastIllness.steroid': {
          compute: (data) => {
            if (data?.pastIllness?.steroidType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.steroidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.steroidType'],
        },
        'pastIllness.covid': {
          compute: (data) => {
            if (data?.pastIllness?.covidType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.covidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.covidType'],
        },
        'pastIllness.copd': {
          compute: (data) => {
            if (data?.pastIllness?.copdType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.copdTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.copdType'],
        },
        'pastIllness.asthma': {
          compute: (data) => {
            if (data?.pastIllness?.asthmaType === 'No Treatment') {
              return ({
                set: {
                  'pastIllness.asthmaTreatment': '',
                },
              });
            }
          },
          onChange: ['pastIllness.asthmaType'],
        },
        'personalData.addictionOtherText': {
          compute: (data) => {
            if (data?.personalData?.addictionOther === false) {
              return ({
                set: {
                  'personalData.addictionOtherText': '',
                },
              });
            }
          },
          onChange: ['personalData.addictionOther'],
        },
        'immunization.immunizationOtherText': {
          compute: (data) => {
            if (data?.immunization?.immunizationOther === false) {
              return ({
                set: {
                  'immunization.immunizationOtherText': '',
                },
              });
            }
          },
          onChange: ['immunization.immunizationOther'],
        },
        'gpe.edemaInput': {
          compute: (data) => {
            if (data?.gpe?.edema === false) {
              return ({
                set: {
                  'gpe.edemaInput': '',
                },
              });
            }
          },
          onChange: ['gpe.edema'],
        },
        'localExamination.dreText': {
          compute: (data) => {
            if (data?.localExamination?.dre === false) {
              return ({
                set: {
                  'localExamination.dreText': '',
                },
              });
            }
          },
          onChange: ['localExamination.dre'],
        },
        'localExamination.proctoscopyText': {
          compute: (data) => {
            if (data?.localExamination?.proctoscopy === false) {
              return ({
                set: {
                  'localExamination.proctoscopyText': '',
                },
              });
            }
          },
          onChange: ['localExamination.proctoscopy'],
        },
        'generalExamination.jaundice': {
          compute: (data) => {
            if (data?.generalExamination?.jaundice === 'No') {
              return ({
                set: {
                  'generalExamination.isJaundiceFace': '',
                  'generalExamination.isJaundiceChest': '',
                  'generalExamination.isJaundiceAbdomen': '',
                  'generalExamination.isJaundiceLegs': '',
                  'generalExamination.isJaundicePalm': '',
                  'generalExamination.isJaundiceSoles': '',
                },
              });
            }
          },
          onChange: ['generalExamination.jaundice'],
        },
        'generalExamination.bleeding': {
          compute: (data) => {
            if (data?.generalExamination?.bleeding === 'No') {
              return ({
                set: {
                  'generalExamination.isSkin': '',
                  'generalExamination.isMouth': '',
                  'generalExamination.isRectal': '',
                  'generalExamination.isLegs': '',
                  'generalExamination.isUmbilicius': '',
                },
              });
            }
          },
          onChange: ['generalExamination.bleeding'],
        },
        'generalExamination.congenitalMalFormation': {
          compute: (data) => {
            if (data?.generalExamination?.congenitalMalFormation === 'No') {
              return ({
                set: {
                  'generalExamination.isDiaphragmaticHernia': '',
                  'generalExamination.isHydrocephalus': '',
                  'generalExamination.isMMC': '',
                  'generalExamination.isImperforateAnus': '',
                  'generalExamination.isTOFistula': '',
                  'generalExamination.isCongHeartDisease': '',
                  'generalExamination.isCleftPalate': '',
                  'generalExamination.isCleftLip': '',
                  'generalExamination.isCleftPalatewithCleftLip': '',
                  'generalExamination.isCongDeformityOfHip': '',
                  'generalExamination.isCongDeformityOfFeet': '',
                  'generalExamination.others': '',
                },
              });
            }
          },
          onChange: ['generalExamination.congenitalMalFormation'],
        },
      },
    }}
  >
    {({ form_state: formState, form_context: formContext }) => {
      const { handleSubmit } = formContext;
      return (
        <View style={{
          marginBottom: 10,
          overflow: 'hidden',
        }}
        >

          {/* Examination */}
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginTop: 10,
              marginLeft: 20,
              paddingTop: 0,
            }}
          >
            <Text style={{ ...vars.headings.h8, display: 'block' }}>
              General Examination
            </Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* GPE */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        1.&nbsp;&nbsp; General Condition
                      </Text>
                  </View>
                  <FormField
                    variant="filled"
                    type="radioGroup"
                    field="generalExamination.generalCondition"
                    container="rightLabel"
                    options={['Alert', 'Lethargic', 'Comatose']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        2.&nbsp;&nbsp;Temperature(F)
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.temperature"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 330,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        3.&nbsp;&nbsp;Heart rate(/min)
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.heartRate"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 330,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                  <Text
                    style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 250,
                      }}
                  >
                      &nbsp;&nbsp;/ Min
                  </Text>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        4.&nbsp;&nbsp;Apnea
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.apnea"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        5.&nbsp;&nbsp;RR(/min)
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.rr"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 330,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                  <Text
                    style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 250,
                      }}
                  >
                      &nbsp;&nbsp;/ Min
                  </Text>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        7.&nbsp;&nbsp;Grunting
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.grunting"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        8.&nbsp;&nbsp;Chest Indrawing
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.chestIndrawing"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        9.&nbsp;&nbsp;Head Circumference
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.headCircumference"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 330,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                  <Text
                    style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 250,
                      }}
                  >
                      &nbsp;&nbsp;cm
                  </Text>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        10.&nbsp;&nbsp;Length
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.length"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 330,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                  <Text
                    style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 250,
                      }}
                  >
                      &nbsp;&nbsp;cm
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        11.&nbsp;&nbsp;Color
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.color"
                    variant="filled"
                    container="rightLabel"
                    options={['Pink', 'Pale', 'Central Cyanosis', 'Peripheral Cyanosis']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        12.&nbsp;&nbsp;CRT > 3 sec
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.crtGreaterThanThree"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        13.&nbsp;&nbsp;Skin Punch  2 secs
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.skinPunchGreaterThanTwo"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        14.&nbsp;&nbsp;Meconium Stained Cord
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.meconiumStainedCord"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        15.&nbsp;&nbsp;Cry
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.cry"
                    variant="filled"
                    container="rightLabel"
                    options={['Absent', 'Feeble', 'Normal', 'High Pitch']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        16.&nbsp;&nbsp;Tone
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.tone"
                    variant="filled"
                    container="rightLabel"
                    options={['Limp', 'Active', 'Increase Tone']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        17.&nbsp;&nbsp;Convulsions
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.convulsions"
                    variant="filled"
                    container="rightLabel"
                    options={['Present on Admission', 'Past History', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        18.&nbsp;&nbsp;Jaundice
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.jaundice"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 170,
                  }}
                >
                  <View style={{ width: 100 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Extent
                      </Text>
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundiceFace"
                        label="Face"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundiceChest"
                        label="Chest"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundiceAbdomen"
                        label="Abdomen"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundiceLegs"
                        label="Legs"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundicePalm"
                        label="Palm"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { jaundice = false } = {} } = {} } = disableProps;
                          return !(jaundice === 'Yes');
                        }}
                        field="generalExamination.isJaundiceSoles"
                        label="Soles"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
              </View>

              {/* Systematic Examination */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        18.&nbsp;&nbsp;Bleeding
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.bleeding"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 170,
                  }}
                >
                  <View style={{ width: 100 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Specific Site
                      </Text>
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { bleeding = false } = {} } = {} } = disableProps;
                          return !(bleeding === 'Yes');
                        }}
                        field="generalExamination.isSkin"
                        label="Skin"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { bleeding = false } = {} } = {} } = disableProps;
                          return !(bleeding === 'Yes');
                        }}
                        field="generalExamination.isMouth"
                        label="Mouth"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { bleeding = false } = {} } = {} } = disableProps;
                          return !(bleeding === 'Yes');
                        }}
                        field="generalExamination.isRectal"
                        label="Rectal"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { bleeding = false } = {} } = {} } = disableProps;
                          return !(bleeding === 'Yes');
                        }}
                        field="generalExamination.isLegs"
                        label="Legs"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { bleeding = false } = {} } = {} } = disableProps;
                          return !(bleeding === 'Yes');
                        }}
                        field="generalExamination.isUmbilicius"
                        label="Umbilicius"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        20.&nbsp;&nbsp;Bulging Anterior Fontanel
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.bulgingAnteriorFontanel"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        21.&nbsp;&nbsp;Taking BreastFeeds
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.takingBreastFeeds"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        22.&nbsp;&nbsp;Sucking
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.sucking"
                    variant="filled"
                    container="rightLabel"
                    options={['Good', 'Poor', 'No Sucking']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        23.&nbsp;&nbsp;Attachment
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.attachment"
                    variant="filled"
                    container="rightLabel"
                    options={['Well Attached', 'Poorly Attached', 'Not Attached']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        24.&nbsp;&nbsp;Umbilicus
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.umbilicus"
                    variant="filled"
                    container="rightLabel"
                    options={['Red', 'Discharge', 'Normal']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        25.&nbsp;&nbsp;Skin Pustules
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.skinPustules"
                    variant="filled"
                    container="rightLabel"
                    options={['No', 'Yes<10', 'Yes>=10', 'Abscess']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        26.&nbsp;&nbsp;Congenital Malformation
                      </Text>
                  </View>
                  <FormField
                    type="radioGroup"
                    field="generalExamination.congenitalMalFormation"
                    variant="filled"
                    container="rightLabel"
                    options={['Yes', 'No']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >

                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isDiaphragmaticHernia"
                        label="Diaphragmatic Hernia"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isHydrocephalus"
                        label="Hydrocephalus"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isMMC"
                        label="M.M.C"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isImperforateAnus"
                        label="ImperforateAnus"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isTOFistula"
                        label="T.O.Fistula"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCongHeartDisease"
                        label="Cong. HeartDisease"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCleftPalate"
                        label="Cleft Palate"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCleftLip"
                        label="Cleft Lip"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCleftPalatewithCleftLip"
                        label="Cleft Palate with Cleft Lip"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCongDeformityOfHip"
                        label="Cong. Deformity Of Hip"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                  <View style={{ width: 130, marginTop: 15 }}>
                    <FormField
                        type="checkBox"
                        disabled={(disableProps) => {
                          const { data: { generalExamination: { congenitalMalFormation = false } = {} } = {} } = disableProps;
                          return !(congenitalMalFormation === 'Yes');
                        }}
                        field="generalExamination.isCongDeformityOfFeet"
                        label="Cong. Deformity Of Feet"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 270,
                  }}
                >
                  <View style={{ width: 90 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 90,
                        }}
                      >
                        &nbsp;&nbsp;Others
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.others"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 230,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        27. &nbsp;&nbsp;Blood Sugar
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.bloodSugar"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 230,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        28. &nbsp;&nbsp;Oxygen Saturation
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.oxygenSaturation"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 230,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 250,
                        }}
                      >
                        29. &nbsp;&nbsp;Other Significant Information
                      </Text>
                  </View>
                  <FormField
                    type="text"
                    field="generalExamination.otherSignificantInformation"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                    style={{
                        width: 230,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                  />
                </View>
              </View>
            </View>
            {/* Systematic Examination */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Systematic Examination
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Provisional Diagnosys
              </Text>
              <FormField
                type="text"
                field="generalExamination.provisionalDiagnosys"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  // fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '30',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;CVS
              </Text>
              <FormField
                type="text"
                field="systematicExamination.cvs"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Respiratory
              </Text>
              <FormField
                type="text"
                field="systematicExamination.respiratory"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Per Abdomen
              </Text>
              <FormField
                type="text"
                field="systematicExamination.perAbdomen"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;CNS
              </Text>
              <FormField
                type="text"
                field="systematicExamination.cns"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}
              />
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;Other Significant Findings
              </Text>
              <FormField
                type="text"
                field="systematicExamination.otherSignificantFindings"
                variant="filled"
                style={{
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  minHeight: '20',
                }}
              />
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginTop: 10,
              marginLeft: 20,
              paddingTop: 0,
            }}
          >
            <Text style={{ ...vars.headings.h8, display: 'block' }}>
              Treatment
            </Text>

            <FormField
              {...{
                field: 'treatment',
                fieldType: 'nested',
                addInBottom: true,
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  footer: ({ addRow }) => ({
                    leftActions: [
                      {
                        render: () => (
                          <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                          >
                            <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                          </View>
                        ),
                        onPress: addRow,
                      },
                    ],
                  }),
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: true,
                    columns: [
                      {
                        type: 'text',
                        variant: 'filled',
                        field: 'drug',
                        label: 'Drug',
                        editable: true,
                        width: '18%',
                        mandatory: true,
                      },
                      {
                        type: 'text',
                        variant: 'filled',
                        field: 'route',
                        label: 'Route',
                        editable: true,
                        width: '18%',
                      },
                      {
                        type: 'text',
                        field: 'dose',
                        variant: 'filled',
                        label: 'Dose (mg)',
                        editable: true,
                        width: '18%',
                      },
                      {
                        type: 'number',
                        field: 'frequency',
                        label: 'Frequency',
                        variant: 'filled',
                        editable: true,
                        width: '18%',
                      },
                      {
                        type: 'textArea',
                        field: 'description',
                        label: 'Special Instruction',
                        variant: 'filled',
                        allowWhiteSpaces: true,
                        editable: true,
                        width: 312,
                      },
                      NestedAction(),
                    ],
                  },
                },
              }}
            />
          </View>
        </View>
      );
    }}
  </Form>
);

export default CaseHistoryAndExamination;
