import React from 'react';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { UserTaxDeclarationListAll } from '../../../../../Wired/Salary/TaxDeclarations/payroll/list';
import tabTheme from '../../../../../theme/childTabTheme';
import { FilterGroup } from '../../../../../app-components';
import { filterActive, filterInactive } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import { searchFilter } from '../../../../../autoSuggestions';
import EmployeeForm from '../../../EmployeeForm';

const ViewTaxDeclarationTabs = (props) => (
  <TabNavigator
    actions={[
      FilterGroup({
        icon: filterInactive,
        activeIcon: filterActive,
        filters: [
          {
            type: 'autoSuggest',
            field: 'finantial_year_id',
            label: 'Financial Year',
            model: MODELS.FINANCIAL_YEAR,
            id: 'financialyears',
            searchField: 'name',
            placeholder: 'Year',
            sugestionField: 'financial_year',
            primaryField: 'financial_year',
            valueField: 'financial_year',
          },
          searchFilter({
            field: 'employee_id',
            model: MODELS.EMPLOYEE,
            id: 'employeeLists',
            searchField: 'firstName',
            label: 'Employee',
            suggestionField: 'fullName',
            multiSelect: true,
            filterTabs: ['pending', 'approved', 'rejected'],
          },
          {
            modifyResult: ({ data }) => {
              const clonedData = data.map((item) => ({
                ...item,
                fullName: `${item?.firstName} ${item?.lastName}`,
              }));

              return { data: clonedData };
            },
          }),
        ],
      }),
    ]}
    theme={{ ...tabTheme }}
    tabs={{
      taxDeclaration: {
        label: '12B B Verification',
        screen: UserTaxDeclarationListAll,
      },
      form: {
        label: 'Employee Form',
        screen: EmployeeForm,
        // reloadEvent: 'reloadLeaveRequestLists',
        // countUri: countUri({
        //   model: MODELS.REQUESTS,
        //   id: 'leaveRequests',
        //   filter: getLeaveRequestFilter('unapproved'),
        // }),
      },
    }}
    {...props}
  />
);
export default ViewTaxDeclarationTabs;
