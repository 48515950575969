import React from 'react';

import { autoSuggestInput } from '../../../../../autoSuggestions';
import MasterForm from '../../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../../Lib/constants';
import ViewTestTabs from './component';
import { TestComponent } from '../../../../../Queries/testComponent';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'testComponent.label.testComponentName'.getLabel(),
  },
  autoSuggestInput({
    field: 'unit',
    label: 'testComponent.label.unit'.getLabel(),
    placeholder: 'testComponent.placeholder.unit'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.TEST_COMPONENT_TYPE,
    query: 'masterDataList',
    searching: true,
  }),

  {
    header: 'testComponent.header.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    type: 'text',
    label: 'testComponent.label.description'.getLabel(),
    field: 'description',
  },
];

export default MasterForm({
  uri: TestComponent,
  model: MODELS.TEST_COMPONENT_TYPE,
  formFields,
  editable: false,
  submitMessage: 'lab.messages.testUpdatedSuccessfully'.getLabel(),
  reloadOnChangeEvent: `reload${MODELS.TEST_COMPONENT_TYPE}`,
  extraFormGroups: [
    {
      type: 'custom',
      render: (props) => <ViewTestTabs {...props} />,
    },
  ],
});
