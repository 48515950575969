import React from 'react';

import {
  OutlineButton, StandardButton,
} from '../../../../app-components';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import NurseEdit from './Components/EditNurse';
import VaccinationPatientsListTabs from './Components/VaccinationPatientsList';
import VaccinationPatientsList from '../../../../Wired/VaccinationListOfPatient';

const SessionTabs = (props) => {
  const { activeSession } = props;
  return (
    <TabNavigator
      {...props}
      tabs={{
        vaccinatedPatientsList: {
          label: 'Vaccinated Patients',
          screen: VaccinationPatientsListTabs,
          actions: [
            {
              render: () => (
                <NurseEdit {...props} />
              ),
            },
          ],
        },
        ListOfPatients: {
          label: 'List Of Patients',
          screen: (props) => {
            const { activeSession } = props;

            const { _id } = activeSession;

            return (
              <VaccinationPatientsList
                sessionId={_id}
                addOnFilter={{
                  session: {
                    _id,
                  },
                }}
                model={MODELS.VACCINATION}
                id="patientVaccinationList"
                {...props}
              />
            );
          },
        },
      }}
      actions={[
        {
          render: (props) => (
            <StandardButton
              label="Add Patient"
              {...props}
            />
          ),
          type: 'link',
          link: () => ({
            view: ROUTES.addPatientModal.name,
            params: { activeSession },
            push: true,
            modal: true,
            ...props,
          }),

        },
        {
          type: 'link',
          link: () => ({
            view: ROUTES.endVaccinationSessionModal.name,
            params: { activeSession },
            push: true,
            modal: true,
            modalProps: {
              autoHide: true,
              width: 436,
              height: 279,
            },
          }),
          render: () => (
            <OutlineButton
              label="End Session"
            />
          ),
        },
      ]}
    />
  );
};
export default SessionTabs;
