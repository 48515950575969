import React from 'react';
import { View } from '../../../../../app-components';

import { editAdmitIPDPatientUri } from '../../../../../Queries/patient';
import { Form } from '../../../../../app-components/form/Form';
import FileLink from '../../../../../Components/FileLink';
import vars from '../../../../../theme/vars';
import { invoke } from '../../../../../AppServices';

const ViewPatientForm = (props) => {
  const { navigation } = props;
  const handleOnSubmit = async (item) => {
    const { updates, data } = item;
    await invoke({
      id: 'consentFormUpdation',
      paramValue: {
        ...updates,
        _id: data._id,
      },
    });
  };
  return (
    <Form
      {...props}
      uri={editAdmitIPDPatientUri({ navigation })}
      onSubmit={handleOnSubmit}
      closeView={1}
      reloadEvent="reloadIPDRegisteredPatients"
      type="standard"
      lg={{
        verticalFormGroups: [
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'patient.uhid',
                        label: 'UHID',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'patient.patientName',
                        label: 'patient.labels.patientName'.getLabel(),
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'date',
                        field: '_createdOn',
                        format: 'DD MM YYYY',
                        label: 'Date of Admission',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'date',
                        field: '_createdOn',
                        format: 'HH:mm A',
                        label: 'Time of Admission',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: '',
                        label: 'Nurse Name',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'speciality.name',
                        label: 'Speciality',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: '',
                        label: 'Unit',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'rooms.name',
                        label: 'Room Number',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: '',
                        label: 'Doctor',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'seniorResident',
                        label: 'Senior Resident',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'juniorResident',
                        label: 'Junior Resident',
                        mandatory: true,
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        type: 'file',
                        label: 'Consent Form',
                        style: { flex: 2, overflow: 'hidden' },
                        render: ({ data }) => {
                          if (data?.consentForm?.attachments) {
                            return (
                              data?.consentForm?.attachments.map(
                                (item) => (
                                  <FileLink
                                    style={{ ...vars.headings.h9 }}
                                    file={item}
                                    key={props}
                                  />
                                ),
                              )
                            );
                          }
                          return <View />;
                        },
                        editable: false,
                        multiple: true,
                      },
                      {
                        type: 'file',
                        field: 'consentForm.attachments',
                        label: 'DROP & DRAG YOUR FILE',
                        editable: true,
                        multiple: true,
                        width: 350,
                      },

                    ],
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

const NurseViewPatient = (props) => (<ViewPatientForm {...props} />);
export default NurseViewPatient;
