import MasterForm from '../../../Wired/Master/MasterForm';
import { autoSuggestInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';
import {
  validateInputLength,
  removeAllSpacesOnChange,
  formatNumberMaxTwoDecimalOnChange,
} from '../../../Lib/helpers';

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 20),
};

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'room.labels.room'.getLabel(),
    mandatory: true,
    modifyValueOnChange: removeAllSpacesOnChange,
    inputProps: {
      maxLength: 20,
    },
  },
  autoSuggestInput({
    mandatory: true,
    field: 'department',
    label: 'room.labels.department'.getLabel(),
    placeholder: 'room.placeholders.department'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.DEPARTMENTS,
    query: 'masterDataList',
  }),
  autoSuggestInput({
    field: 'speciality',
    label: 'room.labels.speciality'.getLabel(),
    placeholder: 'room.placeholders.speciality'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.WARDS,
    query: 'masterDataList',
    visible: ({ data }) => data?.department,
  },
  {
    addOnFilter: ({ data } = {}) => ({
      department: { _id: data?.department?._id },
    }),
  }),
  {
    type: 'number',
    field: 'totalBeds',
    label: 'room.labels.totalBeds'.getLabel(),
    visible: ({ data }) => data?.department?.trackBeds,
    modifyValueOnChange: formatNumberMaxTwoDecimalOnChange,
  },
  autoSuggestInput({
    type: 'autoSuggest',
    field: 'roomCategories',
    label: 'Ward Category',
    placeholder: 'Ward Category',
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.ROOM_CATEGORY,
    query: 'masterDataList',
    mandatory: true,
    visible: ({ data }) => data?.department,
    editable: true,
  }),
  {
    type: 'text',
    field: 'ward',
    label: 'Ward',
    mandatory: true,
    visible: ({ data }) => data?.roomCategories?.name === 'General Ward',
    inputProps: {
      maxLength: 20,
    },
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'room.labels.status'.getLabel(),
    mandatory: true,
    container: 'leftLabel',
    align: 'center',
  },
];

export default MasterForm({
  model: MODELS.ROOM,
  formFields,
  validations,
  uniqueValidationMessage: 'room.messages.roomAlreadyExists'.getLabel(),
  submitMessage: 'room.messages.roomAddedSuccessfully'.getLabel(),
  mandatory: {
    name: 1,
    department: 1,
    // ward: 1,
    roomCategories: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
  computations: {
    self: {
      department: {
        compute: () => ({
          set: { speciality: null },
        }),
        onChange: ['department'],
      },
    },
  },
});
