import React from 'react';

import {
  StandardButton,
  Text,
} from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants';

const tableItemsInputOutput = [
  {
    header: 'S.No.'.getLabel(),
    type: 'number',
    render: (item) => <Text>{item.index + 1}</Text>,
  },
  {
    header: 'Time'.getLabel(),
    type: 'date',
    format: 'HH:mm A',
    field: '_createdOn',
  },
  {
    header: 'Nurse Name',
    type: 'text',
    field: 'nurseName',
  },
  {
    header: 'total Input',
    type: 'text',
    field: 'totalInput',
  },
  {
    header: 'Total Output',
    type: 'text',
    field: 'totalOutput',
  },
];

// const InputOutputDetails = (props) => (
//   <Table
//     lg={{ columns: tableItems }}
//     reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
//     header={() => {
//       const temp = props?.dataParams;
//       return ({
//         actions: [
//           {
//             type: 'link',
//             link: ({ navigation: { state: { params = {} } } }) => ({
//               view: ROUTES.logInputOutput.name,
//               params: { ...params, dataParams: temp },
//               push: true,
//               modal: true,
//               modalProps: {
//                 autoHide: true,
//                 maxHeight: '100%',
//                 width: 724,
//                 height: 457,
//               },
//             }),
//             render: () => (
//               <StandardButton
//                 label="Log Input Output"
//               />
//             ),
//           },
//         ],
//       });
//     }}
//     uri={(item) => ({
//       query: {
//         id: 'EditIPDVitalSheet',
//         addOnFilter: {
//           admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
//         },
//       },
//       model: MODELS.IPD_VITALS_SIGN_SHEET,
//     })}
//     action={{
//       type: 'link',
//       link: (data) => ({
//         view: ROUTES.ViewInputOutputVitals.name,
//         modal: true,
//         params: {
//           addOnFilter: {
//             _id: data.item._id,
//           },
//         },
//         modalProps: {
//           autoHide: true,
//           maxHeight: '100%',
//           width: 724,
//           height: 457,
//         },

//       }),
//     }}
//   />
// );
export default tableItemsInputOutput;
