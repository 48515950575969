import React from 'react';
import { MODELS } from '../../../Lib/constants';
import { getString } from '../../../Lib/helpers';
// import permissions from '../../../Lib/permissions';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  {
    header: 'Employee',
    type: 'text',
    field: 'employee_id.name',
    sortable: 'employee_id.name',
  },
  {
    header: 'Employee ID',
    type: 'text',
    value: ({ item }) => item?.employee_id?.employeeId,
  },
  {
    header: 'Speciality',
    type: 'text',
    value: ({ item }) => (Array.isArray(item?.employee_id?.speciality) && getString(item?.employee_id?.speciality?.map((speciality) => speciality.name), ', ')),

  },
  {
    header: 'Department',
    type: 'text',
    field: 'department',
    value: ({ item }) => (Array.isArray(item?.employee_id?.department) && getString(item?.employee_id?.department?.map((department) => department.name), ', ')),

  },
  {
    header: 'StaffType',
    type: 'text',
    value: ({ item }) => item?.employee_id?.staffType?.name,
  },
  {
    header: 'Next Increment Date',
    type: 'date',
    field: 'employee_id.lastIncrementDate',
    format: 'DD/MM/YYYY',
  },
  // {
  //   header: 'To Date',
  //   type: 'date',
  //   field: 'to_date',
  //   format: 'DD/MM/YYYY',
  // },
];

export default (props) => (
  <MasterList
    {...props}
    showActionIcon={false}
    model={MODELS.EMPLOYEE_SALARY_COMPONENT}
    reloadOnChangeEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}
    reloadEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}
    uri={() => ({
      query: {
        id: 'employeePackageReviewed',
      },
      model: MODELS.EMPLOYEE_SALARY_COMPONENT,
    })}
    tableColumns={tableColumns}
  />
);
