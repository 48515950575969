/* eslint-disable max-len */
import React from 'react';
import { Text, View } from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { MODELS, pharmacyInventoryStockRequestStatus } from '../../../../../../Lib/constants';
import { searchInput } from '../../../../../../autoSuggestions';
import vars from '../../../../../../theme/vars';
import { EditPharmacyIndentRequestStatus } from '../../../../../../Queries/pharmacy';

const ViewIndentRequest = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryId,
        } = {},
      } = {},
    } = {},
  } = props;

  // eslint-disable-next-line no-shadow

  return (
    <Form
      type="standard"
      reloadEvent={`reload${MODELS.PHARMACY_INDENT}`}
      editable={false}
      uri={EditPharmacyIndentRequestStatus({ ...props, queryId })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'inventory.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.indentNumber'.getLabel(),
                    field: 'indentNumber',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.department'.getLabel(),
                    field: 'department.name',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.status'.getLabel(),
                    field: 'requestStatus',
                    render: ({ data: { requestStatus } = {} } = {}) => {
                      const dotStyle = {
                        height: 8,
                        width: 8,
                        borderRadius: 4,
                        backgroundColor: vars.colors.warning,
                        marginLeft: 8,
                      };
                      let indentRequestStatus = '';
                      let statusColor = '';
                      if (requestStatus === pharmacyInventoryStockRequestStatus.approved) {
                        indentRequestStatus = pharmacyInventoryStockRequestStatus.approvedByMSI;
                        statusColor = vars.colors.primary.color2;
                      } else if (requestStatus === pharmacyInventoryStockRequestStatus.raised) {
                        indentRequestStatus = pharmacyInventoryStockRequestStatus.pending;
                        statusColor = vars.colors.warning;
                      } else if (requestStatus === pharmacyInventoryStockRequestStatus.stockProvided) {
                        indentRequestStatus = pharmacyInventoryStockRequestStatus.stockProvided;
                        statusColor = vars.colors.primary.color2;
                      } else {
                        indentRequestStatus = pharmacyInventoryStockRequestStatus.Rejected;
                        statusColor = vars.colors.error;
                      }
                      return (
                        <View style={{
                          flex: 1,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        >
                          <Text style={{ ...vars.headings.h8 }}>
                            {indentRequestStatus}
                          </Text>
                          <View style={{ ...dotStyle, backgroundColor: statusColor }} />
                        </View>
                      );
                    },
                  },
                  {
                    type: 'date',
                    label: 'inventory.labels.responseDate'.getLabel(),
                    field: '_updatedOn',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.indentDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'indentDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          paddingLeft: 12,
                          paddingRight: 4,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 2,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'inventory.labels.itemId'.getLabel(),
                            label: 'inventory.labels.itemId'.getLabel(),
                            variant: 'filled',
                            field: 'item.itemCode',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.itemName'.getLabel(),
                            label: 'inventory.labels.itemName'.getLabel(),
                            variant: 'filled',
                            field: 'item.name',
                            editable: false,
                            width: 120,
                          },
                          searchInput({
                            field: 'item',
                            variant: 'filled',
                            header: 'inventory.labels.category'.getLabel(),
                            label: 'inventory.labels.category'.getLabel(),
                            placeholder: 'inventory.placeholders.supplier'.getLabel(),
                            keyField: 'category.name',
                            valueField: 'name',
                            editable: false,
                            width: 120,
                          }),
                          {
                            type: 'text',
                            header: 'inventory.labels.unitPrice'.getLabel(),
                            label: 'inventory.labels.unitPrice'.getLabel(),
                            variant: 'filled',
                            field: 'item.sellingPrice',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.reasonForPurchase'.getLabel(),
                            label: 'inventory.labels.reasonForPurchase'.getLabel(),
                            variant: 'filled',
                            field: 'reasonForPurchase',
                            editable: false,
                            width: 180,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.requiredQuantity'.getLabel(),
                            label: 'inventory.labels.requiredQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'requiredQuantity',
                            editable: false,
                            width: 180,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.providedQuantity'.getLabel(),
                            label: 'inventory.labels.providedQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'providedQuantity',
                            editable: false,
                            width: 180,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.avgConsumption'.getLabel(),
                            label: 'inventory.labels.avgConsumption'.getLabel(),
                            variant: 'filled',
                            field: 'item.avgDepartmentMonthlyConsumption',
                            editable: false,
                            width: 200,
                          },
                          {
                            type: 'date',
                            header: 'inventory.labels.lastIndentDate'.getLabel(),
                            label: 'inventory.labels.lastIndentDate'.getLabel(),
                            variant: 'filled',
                            field: 'lastIndentDate',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.lastIndentQuantity'.getLabel(),
                            label: 'inventory.labels.lastIndentQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'lastIndentQuantity',
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.manufacturer'.getLabel(),
                            label: 'inventory.labels.manufacturer'.getLabel(),
                            variant: 'filled',
                            render: ({ item: { item: { rateContract = [] } = {} } }) => (
                              <Text style={{ ...vars.headings.h9 }}>
                                {rateContract[0]?.manufacturer?.name}
                              </Text>
                            ),
                            editable: false,
                            width: 150,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewIndentRequest;
