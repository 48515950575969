import React from 'react';

import {
  FormField,
  Image,
  Text,
  View,
} from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { barcode } from '../../../images';
import vars from '../../../theme/vars';
import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { getString } from '../../../Lib/helpers';
import { PharmacyPatientDispensingUri } from '../../../Queries/pharmacy';
import FileLink from '../../../Components/FileLink';

const ViewPharmacyBill = (props) => (
  <Form
    containerStyle={{
      backgroundColor: vars.colors.secondary.color4,
      flex: 1,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 16,
      marginRight: 16,
    }}
    closeView={1}
    uri={PharmacyPatientDispensingUri}
    {...props}
  >
    {({ form_state: { data } }) => (
      <>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flexDirection: 'column', flex: 0.2 }}>
            <View style={{ flexDirection: 'row', marginLeft: 24, marginTop: 20 }}>
              <View>
                <Image
                  source={barcode}
                />
              </View>
              <View style={{ marginTop: 20, marginLeft: 24 }}>
                <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>
                  {'billing.labels.uhid'.getLabel()}
                </Text>
                <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                  {data?.patientId?.uhid}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 0.8 }}>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
              <View style={{ flex: 1, marginLeft: 36 }}>
                <FormField
                  type="text"
                  label={'billing.labels.name'.getLabel()}
                  field="patientId.patientName"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.relation'.getLabel()}
                  editable={false}
                  render={() => (
                    <Text style={{ ...vars.headings.h8 }}>
                      {getString([data?.patientId?.relation, data?.patientId?.relationName])}
                    </Text>
                  )}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.gender'.getLabel()}
                  field="patientId.gender"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.age'.getLabel()}
                  field="patientId.age"
                  variant="filled"
                  editable={false}
                />
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
              <View style={{ flex: 1, marginLeft: 36 }}>
                <FormField
                  type="file"
                  label={'billing.labels.documents'.getLabel()}
                  container="topLabel"
                  field="documents"
                  variant="filled"
                  editable={false}
                  render={() => {
                    const value = data?.documents;
                    if (value) {
                      return <FileLink file={value} />;
                    }
                  }}
                />
              </View>

              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.mobile'.getLabel()}
                  field="patientId.mobile"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.latestOPD'.getLabel()}
                  field="patientId.lastConsultation.ODPNumber"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.lastConsultation'.getLabel()}
                  field="patientId.lastConsultationDate"
                  variant="filled"
                  editable={false}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View style={{
            flexDirection: 'row', flex: 1, marginTop: 14, marginLeft: 36,
          }}
          >
            <FormField
              {... {
                field: 'pharmacyDetails',
                fieldType: 'nested',
                editable: false,
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    columns: [
                      {
                        type: 'text',
                        field: 'item.itemCode',
                        header: `${'billing.labels.itemId'.getLabel()}`,
                        variant: 'filled',
                      },
                      {
                        type: 'text',
                        field: 'item.name',
                        header: `${'billing.labels.itemName'.getLabel()}`,
                        variant: 'filled',
                      },
                      {
                        type: 'text',
                        field: 'item.category.name',
                        header: 'billing.labels.category'.getLabel(),
                        variant: 'filled',
                      },
                      {
                        type: 'number',
                        header: `${'billing.labels.quantity'.getLabel()}`,
                        field: 'quantity',
                        variant: 'filled',
                      },
                      {
                        type: 'number',
                        header: `${'billing.labels.unitPrice'.getLabel()}`,
                        field: 'item.sellingPrice',
                        variant: 'filled',

                      },
                      {
                        type: 'number',
                        header: `${'billing.labels.price'.getLabel()}`,
                        field: 'totalPrice',
                        variant: 'filled',
                      },
                    ],
                  },
                },
              }}
            />
          </View>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flexDirection: 'column', flex: 0.7, marginTop: 14 }}>
              <View style={{ flex: 1, marginLeft: 36, height: 10 }}>
                <FormField
                  type="text"
                  editable={false}
                  field="totalItems"
                  variant="filled"
                  label="Total No. of items"
                  container="topLabel"
                />
              </View>
            </View>
            <View style={{ flexDirection: 'column', flex: 0.3, marginTop: 10 }}>
              <View style={{ flex: 1, marginLeft: 36 }} />
              <View style={{ marginLeft: 14, width: 309 }}>
                <FormField
                  type="number"
                  label="Discount (In %)"
                  field="discount"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ marginTop: 20, marginLeft: 14, width: 309 }}>
                <FormField
                  type="number"
                  editable={false}
                  label="Bill Amount"
                  field="billAmount"
                  variant="filled"
                />
              </View>
            </View>
          </View>
        </View>
      </>
    )}
  </Form>
);
export default ViewPharmacyBill;
