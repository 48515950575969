import React from 'react';

import { View, Text } from '../../app-components';
import vars from '../../theme/vars';

const ToastValidation = (props) => {
  const { errors, mapping } = props;

  return Object.keys(errors).map((error, index) => {
    const message = mapping[error];

    return (
      <View
        key={index}
        style={{
          marginTop: 6,
        }}
      >
        <Text
          style={{
            ...vars.headings.h11,
          }}
        >
          {message}
        </Text>
      </View>
    );
  });
};

export default ToastValidation;
