import { upperFirst } from 'lodash';

import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS, unit } from '../../../../Lib/constants';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'testComponent.label.testComponentName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      // eslint-disable-next-line no-param-reassign
      value = upperFirst(value);
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
  },

  autoSuggestInput({
    field: 'unit',
    label: 'testComponent.label.unit'.getLabel(),
    placeholder: 'testComponent.placeholder.unit'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    options: unit,
    mandatory: true,
  }),
  {
    type: 'textArea',
    field: 'description',
    label: 'testComponent.label.description'.getLabel(),
    minHeight: 80,
    width: 650,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'testComponent.label.status'.getLabel(),
    container: 'leftLabel',
  },
];
const validations = { description: ({ data }) => validateInputLength(data?.description, 0, 200) };

export default MasterForm({
  model: MODELS.TEST_COMPONENT_TYPE,
  formFields,
  submitMessage: 'testComponent.message.testComponentAdded'.getLabel(),
  uniqueValidationMessage: 'testComponent.message.testComponentUnique'.getLabel(),
  mandatory: {
    name: 1,
    unit: 1,
  },
  validations,
  defaultValues: () => ({
    isActive: true,
  }),
});
