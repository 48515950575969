/* eslint-disable no-param-reassign */
import { inventoryType, MODELS } from '../Lib/constants';

export const purchaseRequestListPharmacy = ({ model, id, addOnFilter }) => ({
  query: {
    model: model || MODELS.PURCHASE_REQUEST,
    id: id || 'purchaseRequestPharmacyList',
    addOnFilter: addOnFilter || { type: inventoryType?.pharmacy },
  },

  model: MODELS.PURCHASE_REQUEST,
});

export const purchaseRequestListAsset = ({ filter }) => ({
  query: {
    model: MODELS.PURCHASE_REQUEST,
    id: 'purchaseRequestAssetList',
    addOnFilter: filter,
  },
  model: MODELS.PURCHASE_REQUEST,
});

export const ViewPurchaseRequestListPharmacy = ({ queryId, navigation = {}, computation }) => ({
  query: {
    computation,
    model: MODELS.PURCHASE_REQUEST,
    id: queryId || 'purchaseRequestPharmacyList',
    relationValue: {
      model: MODELS.PURCHASE_REQUEST,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.PURCHASE_REQUEST,
});

export const ViewPurchaseRequestListAsset = ({ navigation = {} }) => {
  const { state: { params: { manufacturer, supplier } } } = navigation;

  return ({
    query: {
      computation: (data) => {
        const newResult = {
          ...data[0],
          manufacturer: { name: manufacturer?.name },
          supplier: { name: supplier?.name },
        };
        return manufacturer && supplier ? [newResult] : data;
      },
      model: MODELS.PURCHASE_REQUEST,
      id: 'purchaseRequestAssetList',
      relationValue: {
        model: MODELS.PURCHASE_REQUEST,
        _id: navigation?.state?.params?.item?._id,
        relation: '_id',
      },
    },
    model: MODELS.PURCHASE_REQUEST,
  });
};

export const purchaseOrderRequest = ({ addOnFilter }) => ({
  query: {
    model: MODELS.PURCHASE_SUPPLY_ORDER,
    id: 'purchaseSupplyOrderList',
    addOnFilter,
  },
  model: MODELS.PURCHASE_SUPPLY_ORDER,
});

export const supplyOrderRequest = ({ addOnFilter }) => ({
  query: {
    model: MODELS.PURCHASE_SUPPLY_ORDER,
    id: 'purchaseSupplyOrderList',
    addOnFilter,
  },
  model: MODELS.PURCHASE_SUPPLY_ORDER,
});
export const ViewPurchaseOrder = ({ navigation = {} }) => ({
  query: {
    model: MODELS.PURCHASE_SUPPLY_ORDER,
    id: 'purchaseSupplyOrderDetails',
    computation: (data) => {
      const {
        purchaseRequestEntity: { queues = [] },
        finalApprovalRemarks,
      } = data[0];
      const newQueue = queues?.filter(
        (queue) => queue?.purchaseSupplyOrder?.type === 'PO',
      );

      const queuesWithTrueMessages = [];
      for (let i = 0; i < (newQueue?.length - 1); i += 1) {
        queuesWithTrueMessages.push({
          ...newQueue[i],
          remarks: newQueue[i + 1].remarks,
        });
      }

      queuesWithTrueMessages.push({
        ...newQueue[newQueue?.length - 1],
        remarks: finalApprovalRemarks,
      });

      data[0].purchaseRequestEntity.queues = queuesWithTrueMessages;
      data[0].remarks = newQueue.length ? newQueue[0].remarks : '--';
      return data;
    },
    relationValue: {
      model: MODELS.PURCHASE_SUPPLY_ORDER,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.PURCHASE_SUPPLY_ORDER,
});

export const ViewSupplyOrder = ({ navigation = {} }) => ({
  query: {
    model: MODELS.PURCHASE_SUPPLY_ORDER,
    id: 'purchaseSupplyOrderDetails',
    computation: (data) => {
      const {
        purchaseRequestEntity: { queues = [] },
        finalApprovalRemarks,
      } = data[0];
      const newQueue = queues?.filter(
        (queue) => queue?.purchaseSupplyOrder?.type === 'SO',
      );

      const queuesWithTrueMessages = [];
      for (let i = 0; i < (newQueue?.length - 1); i += 1) {
        queuesWithTrueMessages.push({
          ...newQueue[i],
          remarks: newQueue[i + 1].remarks,
        });
      }

      queuesWithTrueMessages.push({
        ...newQueue[newQueue?.length - 1],
        remarks: finalApprovalRemarks,
      });

      data[0].purchaseRequestEntity.queues = queuesWithTrueMessages;
      data[0].remarks = newQueue.length ? newQueue[0].remarks : '--';
      return data;
    },
    relationValue: {
      model: MODELS.PURCHASE_SUPPLY_ORDER,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.PURCHASE_SUPPLY_ORDER,
});
