import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { Form } from '../../../../app-components/form/Form';
import {
  validateAlphabetsWithSpaceOnly, validateEmail, validateMobile, validateMultipleFileTypesAllowed,
} from '../../../../Lib/helpers';
import { EditBuyer } from '../../../../Queries/auction';

const EditBuyerForm = (props) => (
  <Form
    {...props}
    type="standardShadow"
    uri={EditBuyer}
    closeView={1}
    onSubmit={submit({
      model: MODELS.AUCTION_BUYER,
    })}
    reloadEvent={`reload${MODELS.AUCTION_BUYER}`}
    submitMessage={'buyer.messages.buyerUpdatedSuccessfully'.getLabel()}
    uniqueValidationMessage={'buyer.messages.buyerAlreadyExists'.getLabel()}
    mandatory={{
      name: 1,
      email: 1,
      contactNo: 1,
      address: 1,
    }}
    validations={{
      email: ({ data }) => validateEmail(data?.email),
      contactNo: ({ data }) => validateMobile(data?.contactNo),
      documents: ({ data }) => validateMultipleFileTypesAllowed(data?.documents, ['pdf', 'docx']),
    }}
    lg={{
      formGroups: [
        {
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'buyer.labels.buyerName'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'buyer.labels.email'.getLabel(),
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'contactNo',
                  label: 'buyer.labels.contactNumber'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value?.toString()?.length > 10) {
                      return prevValue;
                    }
                    return value;
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'textArea',
                  field: 'address',
                  label: 'buyer.labels.address'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  minHeight: 80,
                  style: { flex: 1, overflow: 'hidden' },
                },
                {
                  type: 'file',
                  field: 'documents',
                  multiple: true,
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default EditBuyerForm;
