import React from 'react';

import {
  Text,
} from '../../../../../../../../app-components';
import { NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { viewProgressReportUri } from '../../../../../../../../Queries/progressReport';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';

const IpdViewProgressPediatrics = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          uri={viewProgressReportUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: false,
                            defaultValue: new Date().toLocaleDateString(),
                          },
                          {
                            type: 'text',
                            field: 'daysofStays',
                            label: 'Days of Stays',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doctorOnDuty.name',
                            label: 'Doctor On Duty',
                            variant: 'filled',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doctorOnRound.name',
                            label: 'Doctor On Round',
                            variant: 'filled',
                            mandatory: true,
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Complaints </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'complaintOne',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'complaintTwo',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'complaintThree',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'complaintFour',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Examination </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'examination',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowColumnTextStyle: {
                                  ...vars.headings.h10,
                                  color: vars.colors.grey.color3,
                                  numberOfLines: 1,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: false,
                                    width: 250,
                                    mandatory: true,
                                    header: 'Drug',
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    editable: false,
                                    width: 150,
                                    header: 'Route',
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    editable: false,
                                    width: 100,
                                    header: 'Dose',
                                  },
                                  {
                                    type: 'number',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: false,
                                    width: 200,
                                    header: 'Frequency',
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    editable: false,
                                    width: 312,
                                    header: 'Description',
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'text',
                            field: 'transferTo.name',
                            label: 'Transfer To',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'assignTO',
                            label: 'Assign To',
                            editable: false,
                            variant: 'filled',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const ViewProgressReportPediatric = (props) => (<IpdViewProgressPediatrics {...props} />);
export default ViewProgressReportPediatric;
