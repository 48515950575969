import React from 'react';
import { Text, TouchableOpacity, View } from '../../app-components';
import vars from '../../theme/vars';

const Chips = (props) => {
  const {
    data = [], field = 'name', label = 'Rooms Available', onPress, pressable = false, cursor = '',
  } = props;

  return (
    <View style={{
      marginBottom: 10,
      marginLeft: 4,
    }}
    >
      <Text
        style={[
          vars.headings.h9,
          { color: vars.colors.grey.color1 },
        ]}
      >
        {label}
      </Text>
      <View
        style={{
          marginTop: 10,
          flexDirection: 'row',
        }}
      >
        {data?.map((item, index) => (
          <View
            style={{
              display: 'block',
              flex: 0,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
              marginRight: 10,
              borderRadius: 10,
              backgroundColor: vars.colors.secondary.color4,
              borderColor: vars.colors.secondary.color2,
              borderWidth: 1,
            }}
            key={index}
          >
            <TouchableOpacity
              style={{ cursor }}
              onPress={() => (pressable ? onPress(item) : null)}
            >
              <Text>
                {item[field]}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </View>
  );
};

export default Chips;
