import React from 'react';
import { Table } from '../../../../../../app-components/table/Table';

const tableItems = [
  {
    width: 14,
  },
  {
    header: 'inventory.headers.dateOfDispensing'.getLabel(),
    type: 'date',
    format: 'DD MMM YYYY',
    field: '_createdOn',
  },
  {
    header: 'inventory.headers.patientUHID'.getLabel(),
    type: 'text',
    format: 'DD MMM YYYY',
    field: 'patientId.uhid',
  },
  {
    header: 'inventory.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patientId.patientName',
  },
  {
    header: 'inventory.headers.quantity'.getLabel(),
    type: 'number',
    field: 'pharmacyDetails.quantity',
  },
  {
    header: 'inventory.headers.OPDNumber'.getLabel(),
    type: 'number',
    field: 'patientId.lastConsultation.ODPNumber',
  },
  {
    header: 'inventory.headers.dispensedBy'.getLabel(),
    type: 'text',
    field: 'dispensedBy.name',
  },
];
const PatientWiseDispensingList = (props) => {
  const {
    id,
    model,
    addOnFilter,
    ...restProps
  } = props;
  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: { _createdOn: -1 },
        },
        model,
      })}
      hoverable={false}
      lg={{ columns: tableItems }}
      {...restProps}
    />
  );
};
export default PatientWiseDispensingList;
