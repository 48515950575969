import React from 'react';

import { FilterGroup, Table } from '../../../app-components';
import { CommonSalaryTemplate } from './uri';
import { Layouts } from './layouts';
import { levelPayOptions, MODELS, ROUTES } from '../../../Lib/constants';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../images';

export const DraftTemplateList = Table({
  listVersion: 2,
  header: {
    title: 'Salary Structure Templates',
    actions: [
      (props) => <FtsSearch {...props} action={{ exp: ['name', 'code'] }} />,
      FilterGroup({
        icon: filterInactive,
        activeIcon: filterActive,
        filters: [
          {
            type: 'autoSuggest',
            field: 'employeeType',
            label: 'Employee Type',
            placeholder: 'Employee Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
            model: MODELS.EMPLOYEETYPE,
            id: 'masterDataList',
          },
          {
            type: 'autoSuggest',
            field: 'staffType',
            label: 'Staff Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
            model: MODELS.STAFFTYPE,
            id: 'masterDataList',
          },
          {
            type: 'autoSuggestOptions',
            field: 'level',
            label: 'Level Type',
            options: levelPayOptions,
            multiSelect: false,
          },
        ],
      }),
    ],
  },
  uri: () => CommonSalaryTemplate({ query: 'employeeSuggestions' }, {}),
  reloadEvent: 'reloadSalaryTemplateList',
  action: () => ({
    type: 'link',
    link: ({ item }) => ({
      view: ROUTES.viewSalaryCompensationStructure.name,
      params: { item },
    }),
  }),
  // sm: {
  //   columns: [Layouts.nameLayout, Layouts.max_limit],
  // },
  // lg: {
  columns: [
    {
      type: 'selection',
    },
    Layouts.name,
    Layouts.code,
    {
      ...Layouts.employee_type,
      value: ({ item: { employeeType = [] } = {} }) => employeeType?.length && employeeType?.map(({ name }) => name).join(' , '),
    },
    {
      ...Layouts.status,
    },
    {
      ...Layouts.level,
    },
    {
      ...Layouts.ctc_amount,
    },
    {
      ...Layouts.action,
    },
  ],
  // },
});
