import React, { useCallback } from 'react';

import { editTemperaturePulseUri } from '../../../../../../../../Queries/temperaturePulseChart';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { Form } from '../../../../../../../../app-components/form/Form';
import { save, Toast } from '../../../../../../../../app-components';
import { getErrorString } from '../../../../../../../../Lib/helpers';
import { MODELS } from '../../../../../../../../Lib/constants';
import { invoke, submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const EditMonitoringModal = (props) => {
  const { navigation } = props;

  const handleSubmit = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'anesthesiaMonitoringChartDetailUpdate',
        paramValue: { data },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Entry updated successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  return (
    <ListActionModal
      title="Monitoring Detail"
      description="You can edit detail of fields"
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            mandatory: {
              time: 1,
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          uri={() => ({
            query: {
              model: MODELS.Monitoring_Chart_Anesthesia,
              id: 'monitoringChartDataList',
              relationValue: {
                model: MODELS.Monitoring_Chart_Anesthesia,
                _id: navigation?.state?.params?._id,
                relation: '_id',
              },
              limit: 1,
            },
            model: MODELS.Monitoring_Chart_Anesthesia,
            // query: {
            //   id: 'monitoringChartDataList',
            //   addOnFilter: {
            //     _id: navigation?.state?.params?._id,
            //   },
            // },
            // model: MODELS.Monitoring_Chart_Anesthesia,
          })}
          reloadEvent={`reload${MODELS.Monitoring_Chart_Anesthesia}`}
          reloadOnChangeEvent={`reload${MODELS.Monitoring_Chart_Anesthesia}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={submit({
            model: MODELS.Monitoring_Chart_Anesthesia,
          })}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'spo2',
                        editable: true,
                        label: 'SPO2',
                      },
                      // dateInputForOutcome({
                      //   hourField: 'hour',
                      //   minField: 'minute',
                      //   meridiemField: 'meridiem',
                      // }),
                      {
                        type: 'text',
                        field: 'hr',
                        editable: true,
                        label: 'HR',
                      },
                      {
                        type: 'text',
                        field: 'etco2',
                        editable: true,
                        label: 'ETCO2',
                      },
                      {
                        type: 'text',
                        field: 'sbp',
                        editable: true,
                        label: 'SBP',
                      },
                      {
                        type: 'text',
                        field: 'dbp',
                        editable: true,
                        label: 'DBP',
                      },
                      {
                        type: 'text',
                        field: 'cvp',
                        editable: true,
                        label: 'CVP',
                      },
                      {
                        type: 'text',
                        field: 'temp',
                        editable: true,
                        label: 'TEMPERATURE',
                      },
                      {
                        type: 'text',
                        field: 'time',
                        editable: true,
                        label: 'TIME',
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
      {...props}
    />
  );
};
export default EditMonitoringModal;
