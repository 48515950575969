import React, { useState } from 'react';

import { View } from '../../app-components';
import { componentTest } from '../../images';
import ListActionModal from '../../Components/ListActionModal';
import TestCardList from '../TestTypeCardList';
import FtsSearch from '../../Components/TableFilters/FtsSearch';

const TestLinkedModal = (props) => {
  const {
    navigation: {
      getScreenState,
      state: {
        params: { item, filter },
      },
    },
  } = props;
  const [screenState, setScreenState] = useState(getScreenState());
  return (
    <ListActionModal
      icon={componentTest}
      title={"testComponent.title.test".getLabel()}
      description={"testComponent.title.test".getLabel()}
      content={
        <>
          <View style={{ alignItems: 'center', marginBottom: 8 }}>
            <View>
              <FtsSearch
                {...props}
                screenState={screenState}
                setScreenState={setScreenState}
                action={{ exp: ['name'] }}
              />
            </View>
          </View>
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <TestCardList
              filter={filter}
              {...props}
              dataParams={screenState.dataParams}
            />
          </View>
        </>
      }
      {...props}
    />
  );
};
export default TestLinkedModal;
