export const EditLeaveType = ({ navigation }) => ({
  query: {
    model: 'leaveTypes',
    id: 'leaveTypes',
    relationValue: {
      model: 'leaveTypes',
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'leaveTypes',
});

export const LeaveTypeList = ({ addOnFilter }) => ({
  query: {
    model: 'leaveTypes',
    id: 'leaveTypes',
    addOnFilter,
  },
  model: 'leaveTypes',
});
