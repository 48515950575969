import React, { useCallback } from 'react';

import {
  FormField, LargeButton, Toast, View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { invoke } from '../../../../../AppServices';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import ListActionModal from '../../../../../Components/ListActionModal';
import { history } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import { getErrorString } from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';

const GenerateArrearsModal = (props) => {
  const {
    navigation,
  } = props;
  const { navigation: { state: { params: { salaryStatus } } } } = props;
  const selectedIds = navigation.getParam('selectedIds');

  const getLastDayOfMonth = (y, m) => new Date(Date.UTC(y, m + 1, 0, 18, 29, 59));

  const handleOnSubmitGenerateSalary = useCallback(async ({ data }) => {
    try {
      const { month_id, year_id, employeeType } = data;
      const invoiceDate = getLastDayOfMonth(Number(year_id.name), (Number(month_id.key) - 1));

      const result = await invoke({
        id: 'generateArrears',
        paramValue: {
          month_id, year_id, invoiceDate, employeeType,
        },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Arrears has been generated successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [navigation, selectedIds]);

  return (
    <ListActionModal
      icon={history}
      title="Generate Arrears"
      description={`This action will generate Arrears of ${selectedIds?.length} employees`}
      content={(
        <Form
          onSubmit={handleOnSubmitGenerateSalary}
          mandatory={{
            month_id: 1,
            year_id: 1,
          }}

          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'month_id',
                        label: 'Select Month',
                        placeholder: 'Select Month',
                        variant: 'filled',
                        suggestionField: 'name',
                        model: MODELS.MONTHS,
                        query: 'MonthTypeSuggestions',
                        mandatory: true,
                      },
                    )}
                  />
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'year_id',
                          label: 'Select Year',
                          placeholder: 'Select Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'employeeType',
                          label: 'Select Employee Type',
                          placeholder: 'Select Employee Type',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEETYPE,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      type="date"
                      field="invoiceDate"
                      label="Invoice Date"
                      variant="filled"
                      mandatory
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Generate"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default GenerateArrearsModal;
