import React from 'react';

import { OutlineButton } from '../../../../../../../app-components';
import { TabNavigator } from '../../../../../../../app-components/tab/TabNavigator';
import { countUri } from '../../../../../../../Queries/countUri';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import ActivityLogsTabList from '../../../../../../../Wired/ActivityLogList';
import ManufacturerList from '../../../../Manufacturers/ManufacturerList';
import ItemList from '../../ItemList';

const ViewSupplierTabs = (props) => {
  const { navigation, showAddButton = true } = props;
  const { navigation: { state: { params: { item: { _id } } } } } = props;
  const { state: { params: { item: data = {} } = {} } = {} } = navigation;

  if (!data?._id) {
    return null;
  }

  return (
    <TabNavigator
      tabs={{
        Manufacturer: {
          label: 'Manufacturers',
          screen: ManufacturerList,
          screenProps: {
            data,
            model: MODELS.PHARMACY_MANUFACTURER,
            id: 'masterDataListForTable',
            addOnFilter: {
              supplier: { _id: data?._id },
            },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_MANUFACTURER,
            id: 'masterDataListForTable',
            filter: {
              supplier: { _id: data?._id },
            },
          }),
          actions: [
            {
              type: 'link',
              render: () => (showAddButton ? <OutlineButton label={'pharmacy.buttons.addManufacturer'.getLabel()} /> : null),
              link: () => {
                const {
                  navigation: { state: { params: { item } = {} } = {} } = {},
                } = props;
                return {
                  view: ROUTES.addPharmacyManufacturer.name,
                  modal: true,
                  params: {
                    supplier: {
                      _id: item?._id,
                    },
                  },
                  modalProps: {
                    autoHide: true,
                    width: 404,
                    height: 424,
                  },
                };
              },
            },
          ],
        },
        Item: {
          label: 'Item',
          screen: ItemList,
          screenProps: {
            data,
            model: MODELS.PHARMACY_ITEM,
            id: 'masterDataListForTable',
            addOnFilter: {
              rateContract: { supplier: { _id }, rateContractValidity: { $gte: new Date() } },
            },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_ITEM,
            id: 'masterDataListForTable',
            filter: {
              rateContract: { supplier: { _id }, rateContractValidity: { $gte: new Date() } },
            },
          }),
        },
        ActivityLogs: {
          label: 'Activity Logs',
          screen: ActivityLogsTabList,
          screenProps: {
            data,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: data?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: data?._id },
          }),
        },
      }}
      {...props}
    />
  );
};
export default ViewSupplierTabs;
