import React from 'react';

import {
  save, FormField, Text, View,
} from '../../../../../../../../app-components';
import { multiSearchInput } from '../../../../../../../../autoSuggestions';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import ListActionModal from '../../../../../../../../Components/ListActionModal';

const LogOperativeNoteForm = (props) => (
  <ListActionModal
    {...props}
    title="Operative Notes"
    description="Log operative notes of the patients."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Confirm',
            }),
          ],
        }}
        closeView={1}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        reloadEvent={`reload${MODELS.IPD_OPERATIVE_NOTES}`}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            admissionId: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.IPD_OPERATIVE_NOTES,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        defaultValues={{
          currentLogDate: new Date(),
        }}
        mandatory={{
          _id: 1,
          procedure: 1,
          eye: 1,
          surgeons: 1,
          assistants: 1,
        }}
        lg={{
          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'text',
                      field: 'typeofAnesthesia',
                      label: 'Type Of Anesthesia',
                      allowWhiteSpace: true,
                      editable: true,
                    },
                    {
                      type: 'radioGroup',
                      field: 'anesthesia',
                      variant: 'filled',
                      label: 'Anesthesia',
                      container: 'topLabel',
                      options: ['TA', 'LA', 'GA'],
                      style: {
                        flex: 2, overflow: 'hidden', alignItems: 'Left', paddingLeft: 100,
                      },
                    },
                    {
                      type: 'radioGroup',
                      field: 'eye',
                      variant: 'filled',
                      label: 'Eye',
                      container: 'topLabel',
                      options: ['Right', 'Left', 'Both'],
                      style: {
                        flex: 2, overflow: 'hidden', alignItems: 'Left', paddingLeft: 100,
                      },
                      mandatory: true,
                    },
                  ],
                },
              ],
            },
            {
              columnsPerRow: 3,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'textArea',
                      field: 'procedure',
                      label: 'Procedure',
                      editable: true,
                      mandatory: true,
                      allowWhiteSpaces: true,
                      minHeight: 80,
                    },
                    multiSearchInput({
                      field: 'assistants',
                      variant: 'filled',
                      label: 'Assistant',
                      keyField: 'name',
                      valueField: 'name',
                      searchField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      editable: true,
                      mandatory: true,
                    }),
                    // {
                    //   addOnFilter: {
                    //     employeeType: { name: 'Assistant' },
                    //   },
                    // }),
                    multiSearchInput({
                      field: 'surgeons',
                      variant: 'filled',
                      label: 'Surgeon',
                      keyField: 'name',
                      valueField: 'name',
                      searchField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      editable: true,
                      mandatory: true,
                    }),
                    {
                      type: 'textArea',
                      field: 'remarks',
                      label: 'Remarks/Specific Notes',
                      editable: true,
                      allowWhiteSpaces: true,
                      minHeight: 80,
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              editable: false,
              groupsContainerStyle: { paddingLeft: 12 },
              columnsPerRow: 1,
              groups: [
                {
                  columns: [
                    {
                      render: () => 'POST-OPERATIVE NOTES',
                    },
                    {
                      render: () => 'i. Normal dies',
                    },
                    {
                      render: () => 'ii. No restriction on any normal physical activity. ',
                    },
                    {
                      render: () => 'iii. Analgesis',
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            1.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              field=""
                              type="checkBox"
                              label="Tab brufen 2 tab 4 hrlt PC"
                              variant="filled"
                              style={{
                                flex: 2, overflow: 'hidden', alignItems: 'Left', paddingLeft: 100, width: '100%',
                              }}
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            2.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab paracetamol tab 4 hrlt PC"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            3.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab voveran 1 bd PC"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => 'iv. Antibiotics',
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            1.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              field=""
                              type="checkBox"
                              label="Inj Gentamycin 80 mg IM 8 hrly"
                              variant="filled"
                              style={{
                                flex: 2, overflow: 'hidden', alignItems: 'Left', paddingLeft: 100, width: '100%',
                              }}
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, paddingLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            2.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Inj Amplicillin 500 mg IM 6 hrly"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, paddingLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text>
                            3.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: '15px' }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab Ciprofloxacillin 5000 mg BD"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, paddingLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            4.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: '15px' }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab septan 2 BD"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 0, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text>
                            v.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab Diaxepam 1 tab after lunch and after dinner"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 0, marginBottom: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text>
                            vi.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Tab diamox 1 to 8 hrly"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => (
                        <View style={{
                          flex: 1, marginLeft: 0, marginBottom: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        }}
                        >
                          <Text state={{ fontSize: 17 }}>
                            vii.
                            {'  '}
                          </Text>
                          <View style={{ flex: 1, paddingLeft: 15 }}>
                            <FormField
                              style={{
                                width: 100,
                              }}
                              field=""
                              type="checkBox"
                              label="Continue antihypertensive/antibiatic treatment"
                              variant="filled"
                            />
                          </View>
                        </View>
                      ),
                    },
                    {
                      render: () => 'viii. Monitor TPR',
                    },
                    {
                      render: () => 'ix. Inform sos in case of pain, vomiting, fevera',
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
  />
);
export default LogOperativeNoteForm;
