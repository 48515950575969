import React from 'react';
import {
  FormHoc, View, Text, Toast,
} from '../../../../app-components';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { invoke, submit } from '../../../../AppServices';
import { MODELS } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';

const supplierForm = (props) => {
  const Form = FormHoc({
    ...props,
    type: 'standard',
    reloadEvent: `reload${MODELS.PHARMACY_ITEM}`,

    onSubmit: async (submitProps) => {
      const { navigation } = submitProps;
      try {
        const result = await invoke({
          id: 'addNewSupplierFromPurchase',
          paramValue: {
            module: navigation?.state?.params?.type === 'pharmacy' ? 'pharmacyManagement' : 'assetManagement',
            itemId: navigation?.state?.params?.itemId,
            ...submitProps.data,
          },
        });
        if (result) {
          Toast.show({
            message: 'Success',
            description: 'pharmacy.messages.supplierAddedSuccessfully'.getLabel(),
          });
          navigation.pop();
        }
      } catch (e) {
        Toast.show({
          message: 'Error',
          description: 'Error',
          type: 'error',
        });
      }
    },
    mandatory: {
      mobile: 1, email: 1, address: 1, name: 1, manufacturerName: 1, manufacturerAddress: 1, rateContractValidity: 1,
    },
    lg: {
      formGroups: [
        {
          columnsPerRow: 3,
          label: 'Supplier Details',
          fieldVariant: 'filled',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'pharmacy.labels.supplierName'.getLabel(),
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'email',
                  label: 'pharmacy.labels.email'.getLabel(),
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'mobile',
                  label: 'pharmacy.labels.contact'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value?.toString()?.length > 10) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                {
                  type: 'textArea',
                  field: 'address',
                  label: 'Supplier Address',
                  width: '50%',
                  minHeight: 55,
                  mandatory: true,
                },
              ],
            },
          ],
        },
        {
          columnsPerRow: 3,
          label: 'Manufacturer Details',
          fieldVariant: 'filled',
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'manufacturerName',
                  label: 'Manufacturer Name',
                  mandatory: true,
                  allowWhiteSpaces: true,
                },
                {
                  type: 'textArea',
                  field: 'manufacturerAddress',
                  label: 'Manufacturer Address',
                  width: '50%',
                  minHeight: 55,
                  mandatory: true,
                  allowWhiteSpaces: true,
                },
                {
                  type: 'date',
                  field: 'rateContractValidity',
                  label: 'Rate Contract Validity',
                  mandatory: true,
                },
              ],
            },
          ],
        },

        // {
        //   fieldVariant: 'filled',
        //   label: 'List of Manufacturers',
        //   groups: [
        //     {
        //       columnsPerRow: 2,
        //       columns: [
        //         {
        //           field: 'manufacturer',
        //           fieldType: 'nested',
        //           Component: NestedTable,
        //           componentProps: {
        //             maxHeight: 400,
        //             footer: ({ addRow }) => ({
        //               leftActions: [
        //                 {
        //                   render: () => (
        //                     <View
        //                       style={{
        //                         justifyContent: 'center',
        //                         alignItems: 'center',
        //                         borderWidth: 1,
        //                         borderRadius: 5,
        //                         width: 150,
        //                         height: 40,
        //                         borderColor: vars.colors.secondary.color2,
        //                       }}
        //                     >
        //                       <Text
        //                         style={{
        //                           ...vars.headings.h9,
        //                           color: vars.colors.secondary.color2,
        //                         }}
        //                       >
        //                         Add Manufacturer
        //                       </Text>
        //                     </View>
        //                   ),
        //                   onPress: addRow,
        //                 },
        //               ],
        //             }),
        //             skipTableHeaderOnNoData: true,
        //             listProps: {
        //               hideColumnHeader: false,
        //               headerRowColumnTextStyle: {
        //                 paddingLeft: 12,
        //                 ...vars.headings.h13,
        //                 color: vars.colors.grey.color3,
        //                 numberOfLines: 1,
        //               },
        //               columns: [
        //                 {
        //                   type: 'text',
        //                   field: 'manufacturerName',
        //                   variant: 'filled',
        //                   //   placeholder: 'purchaseDepartmentTC.labels.name'.getLabel(),
        //                   header: 'Manufacturer Name',
        //                   mandatory: true,
        //                   allowWhiteSpaces: true,
        //                 },
        //                 {
        //                   type: 'textArea',
        //                   field: 'manufacturerAddress',
        //                   variant: 'filled',
        //                   //   placeholder: 'purchaseDepartmentTC.labels.description'.getLabel(),
        //                   header: 'Address',
        //                   mandatory: true,
        //                   allowWhiteSpaces: true,
        //                 },
        //                 // NestedAction(),
        //               ],
        //             },
        //           },
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
  });
  return <Form {...props} />;
};

export default supplierForm;
