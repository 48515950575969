import React from 'react';

import { searchInput } from '../../../../autoSuggestions';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { EditRoom } from '../../../../Queries/room';
import { MODELS } from '../../../../Lib/constants';
import ViewRoomTabs from './components/Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'room.labels.room'.getLabel(),
    mandatory: true,
    editable: false,
  },
  searchInput({
    field: 'speciality',
    label: 'room.labels.speciality'.getLabel(),
    placeholder: 'room.placeholders.speciality'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.WARDS,
    query: 'masterDataList',
    searching: true,
    editable: false,
  }),
  searchInput({
    mandatory: true,
    field: 'department',
    label: 'room.labels.department'.getLabel(),
    placeholder: 'room.placeholders.department'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.DEPARTMENTS,
    query: 'masterDataList',
    searching: true,
    editable: false,
  }),
  searchInput({
    mandatory: true,
    field: 'roomCategories',
    label: 'Room Categories',
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.ROOM_CATEGORY,
    query: 'masterDataList',
    searching: true,
    editable: false,
  }),
  {
    type: 'number',
    field: 'totalBeds',
    label: 'room.labels.totalBeds'.getLabel(),
    editable: false,
  },
  {
    type: 'number',
    field: 'allocatedBeds',
    label: 'Allocated Beds',
    editable: false,
  },
  {
    type: 'number',
    field: 'availableBeds',
    label: 'room.labels.emptyBeds'.getLabel(),
    editable: false,
    value: '-NA-',
    container: 'leftLabel',
  },
  {
    type: 'date',
    format: 'DD MMM YYYY @ hh:mm A',
    field: '_createdOn',
    label: 'room.labels.createdOn'.getLabel(),
    editable: false,
  },
];

export default MasterForm({
  uri: EditRoom,
  formFields,
  model: MODELS.ROOM,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewRoomTabs {...props} />
    ),
  }],
});
