import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { Table } from '../../../../../../../app-components/table/Table';
import { GetHistoryUri } from '../../../../../../../Queries/ipdHistory';
import { MODELS } from '../../../../../../../Lib/constants';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="history"
      count={item?.aggregates?._count}
    />
  ),
});

const tabListColumns = {
  header,
  md: {
    columns: [
      {
        type: 'date',
        header: 'DATE',
        field: 'date',
        format: 'DD/MM/YYYY',
        width: '20%',
      },
      {
        type: 'text',
        width: '80%',
        field: 'description',
        header: 'DESCRIPTION',
      },
    ],
  },
};
const History = (props) => {
  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      {...props}
      header
      reloadEvent={`reload${MODELS.IPD_HISTORY}`}
      model={MODELS.IPD_HISTORY}
      uri={() => GetHistoryUri({ admissionId: { _id } })}
      {...tabListColumns}
    />
  );
};
export default History;
