import React from 'react';

import ListActionModal from '../../../../Components/ListActionModal';
import {
  save, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { EditHouseListDetails } from '../uri';

const EditHouseList = (props) => {
  const footer = {
    actions: [
      save,
    ],
  };
  return (
    <ListActionModal
      {...props}
      title="Edit House Details"
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            {...props}
            onSubmit={submit({
              model: MODELS.HOUSE_DETAILS,
            })}
            uri={EditHouseListDetails({ props })}
            reloadEvent="masterDataList"
            reloadOnChangeEvent={`reload${MODELS.HOUSE_DETAILS}`}
            closeView={1}
            mandatory={{
              name: 1,
              rentAmount: 1,
              waterCharge: 1,
              noOfRooms: 1,
              isActive: 1,
            }}
            type="standardShadow"
            lg={{
              verticalFormGroups: [
                {
                  formGroups: [
                    {
                      fieldVariant: 'filled',
                      direction: 'row',
                      columnsPerRow: 3,
                      columns: [
                        {
                          type: 'text',
                          field: 'name',
                          label: 'H. No.',
                          mandatory: true,
                          editable: true,
                        },
                        {
                          type: 'number',
                          field: 'rentAmount',
                          label: 'Rent Amount',
                          mandatory: true,
                          editable: true,
                        },
                        {
                          type: 'number',
                          field: 'waterCharge',
                          label: 'Water Charges',
                          mandatory: true,
                          editable: true,
                        },
                        {
                          type: 'text',
                          field: 'description',
                          label: 'Description',
                          allowWhiteSpaces: true,
                          mandatory: true,
                          editable: true,
                        },
                        {
                          type: 'number',
                          field: 'noOfRooms',
                          label: 'No. Of Rooms',
                          mandatory: true,
                          editable: true,
                        },
                        {
                          label: 'Status',
                          field: 'isActive',
                          type: 'toggleSwitch',
                          mandatory: true,
                        },
                      ],
                    },
                  ],
                },
              ],
              footer,
            }}
          />
        </View>
      )}
    />
  );
};
export default EditHouseList;
