import React from 'react';

import ActivityLogsTabList from '../../../../../../../Wired/ActivityLogList';
import { TabNavigator } from '../../../../../../../app-components';
import { countUri } from '../../../../../../../Queries/countUri';
import { MODELS } from '../../../../../../../Lib/constants';

const ViewTestTabs = (props) => {
  const { data } = props;
  if (!data?._id) {
    return null;
  }

  const TestTabNavigations = TabNavigator({
    tabs: {
      ActivityLogs: {
        label: 'Activity Logs',
        screen: ActivityLogsTabList,
        screenProps: {
          data,
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivity',
          addOnFilter: { id: data?._id },
        },
        countUri: countUri({
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivityCount',
          filter: { id: data?._id },
        }),
      },
    },
  });
  return <TestTabNavigations {...props} />;
};
export default ViewTestTabs;
