import React, { useCallback } from 'react';

import { Table, close, Text } from '../../app-components';
import { Action } from '../../app-components/action/Action';
import { downloadData } from '../../AppServices';

// const handleOnErrorClick = useCallback(async ({ data }) => {
//   await downloadData({
//     service: {
//       id: 'errorLogExcelExport',
//       paramValue: { ...data },
//     },
//   });
//   // navigation.pop();
// }, []);

const header = {
  title: 'Import History Logs',
  actions: [
    close,
  ],
};

const columns = [
  {
    width: 20,
  },
  {
    type: 'text',
    header: 'File',
    field: 'file.name',
  },
  {
    type: 'text',
    header: 'Status',
    field: 'status',
  },
  {
    header: 'Total Records',
    type: 'text',
    field: 'total',
  },
  {
    header: 'Success',
    type: 'text',
    field: 'success',
  },
  {
    header: 'Error',
    type: 'link',
    field: 'error',
    action: {
      type: 'invoke',
      invoke: (invokeProps) => downloadData({
        service: {
          id: 'errorLogExcelExport',
          paramValue: {
            file: invokeProps?.item?.file,
            id: invokeProps?.item?._id,
          },
        },
      }),
    },
  },
  {
    header: 'Error Messages',
    type: 'text',
    field: 'errorLogs',
    value: ({ item }) => item?.errorLogs?.map((log) => log?.error).join('\r\n'),
  },
  {
    header: 'Created On',
    type: 'date',
    field: '_createdOn',
    format: 'DD MMM YYYY @ hh:mm A',
  },
  {
    width: 20,
  },
];

const ImportHistoryList = (props) => {
  const { navigation: { state: { params } = {} } = {} } = props;
  const { addOnFilter } = params;

  const List = Table({
    header,
    columns,
    uri: {
      query: {
        id: 'importHistoryLogList',
        addOnFilter,
      },
      model: 'ImportHistoryLogs',
    },
    reloadEvent: 'reloadImport',
  });

  return <List {...props} />;
};

export default ImportHistoryList;
