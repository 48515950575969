import React from 'react';
import { Text } from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import vars from '../../../../../../../../theme/vars';

const tabListColumns = [
  { width: 14 },
  {
    header: 'inventory.headers.department'.getLabel(),
    type: 'text',
    field: 'department.name',
  },
  {
    header: 'inventory.headers.stockAvailable'.getLabel(),
    type: 'text',
    field: 'stockAvailable',
  },
  {
    header: 'inventory.headers.avgConsumption'.getLabel(),
    type: 'text',
    field: 'item.avgDepartmentMonthlyConsumption',
  },
  {
    header: 'inventory.headers.stockStatus'.getLabel(),
    type: 'text',
    render: ({ item: { stockStatus } = {} }) => {
      let statusColor = vars.colors.warning;
      if (stockStatus === 'In-Stocks') {
        statusColor = vars.colors.primary.color2;
      } else if (stockStatus === 'Out-of-Stock') {
        statusColor = vars.colors.error;
      }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {stockStatus}
        </Text>
      );
    },
  },
];

const DepartmentWiseStockList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;

  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      lg={{ columns: tableColumns }}
      hoverable={false}
      {...restProps}
    />
  );
};
export default DepartmentWiseStockList;
