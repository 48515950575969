import React from 'react';
import HeaderTitleWithCount from '../../Components/HeaderTitileWithCount';
import WithPermission from '../../Components/WithPermission';
import permissions from '../../Lib/permissions';

import { auctionItemStatus, MODELS, ROUTES } from '../../Lib/constants';
import BuyersList from './Buyers/BuyersList';
import ItemsList from './Inventory/ItemsList';
import AddBuyer from './Buyers/AddBuyer';
import {
  cancelButton,
  clearSelection, FilterGroup, save, selectAll, showSelectedCount, StandardButton, Text,
} from '../../app-components';
import EditBuyer from './Buyers/EditBuyer';
import ViewBuyer from './Buyers/ViewBuyer';
import vars from '../../theme/vars';
import FtsSearch from '../../Components/TableFilters/FtsSearch';
import ViewAuctionItem from './Inventory/ViewItem';
import EditAuctionItem from './Inventory/EditItem/index';
import AssignBuyer from './Inventory/AssignBuyer';
import SetPrice from './Inventory/SetPrice';
import { filterActive, filterInactive } from '../../images';
import AssignBuyerItem from './Inventory/ViewItem/Components/AssignBuyerItem';
import { ScreenDataConsumer } from '../../app-components/stack/ScreenDataContext';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

export default {
  [ROUTES.inventoryManagementAuction.name]: {
    screen: ItemsList,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label="Inventory Management"
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['item.item.name', 'item.serialNumber'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'manufacturer',
              label: 'Manufacturers',
              model: MODELS.PHARMACY_MANUFACTURER,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Manufacturer',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              searching: true,
              multiSelect: true,
            },
          ],
        }),
      ],
    },
    screenProps: {
      selectableActions: (props) => [
        showSelectedCount(props),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.auction.views.exportAuctionItems.permissions.exportAuctionItems
                  .value
              }
            >
              <StandardButton
                label={'buyer.title.exportToExcel'.getLabel()}
              />
            </WithPermission>
          ),
          file: 'AuctionItems',
          type: 'download',
          columns: [
            {
              field: 'item.serialNumber',
              header: 'Serial Number',
            },
            {
              field: 'item.item.name',
              header: 'Item Name',
            },
            {
              field: 'item.batchNumber.purchasedPrice',
              header: 'Purchase Price',
            },
            {
              field: 'currentValue',
              header: 'Current Value',
            },
            {
              field: 'soldAtPrice',
              header: 'Sold Price',
            },
            {
              field: 'item.batchNumber.manufacturer.name',
              header: 'Manufacturer',
            },
            {
              field: 'status',
              header: 'Item Status',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.AUCTION_ITEMS,
              query: {
                id: 'auctionItemList',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (messageProps) => {
              const { screenState: { selectedIds } } = messageProps;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.auction.views.assignBuyer
              .permissions.assignBuyer.value}
          >
            <StandardButton label="Set Price" />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.setPrice.name,
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.auction.views.assignBuyer
                .permissions.assignBuyer.value
            }
          >
            <StandardButton label="Assign Buyer" />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.assignBuyer.name,
        }),
      },
    ],
  },

  [ROUTES.buyerManagementAuction.name]: {
    screen: BuyersList,
    expanded: true,
    screenProps: {
      selectableActions: (props) => [
        showSelectedCount(props),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.auction.views.exportAuctionBuyers.permissions.exportAuctionBuyers
                  .value
              }
            >
              <StandardButton
                label={'buyer.title.exportToExcel'.getLabel()}
              />
            </WithPermission>
          ),
          file: 'Buyer',
          type: 'download',
          columns: [
            {
              field: 'name',
              header: 'Buyer Name',
            },
            {
              field: 'address',
              header: 'Address',
            },
            {
              field: 'email',
              header: 'Email',
            },
            {
              field: 'contactNo',
              header: 'Contact Number',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.AUCTION_BUYER,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (messageProps) => {
              const { screenState: { selectedIds } } = messageProps;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission access_key={permissions.auction.views.addAuctionBuyers
            .permissions.addAuctionBuyers.value}
          >
            <StandardButton label={'buyer.buttons.addBuyer'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addBuyer.name,
          push: true,
        }),
      },
    ],
  },

  [ROUTES.addBuyer.name]: {
    screen: AddBuyer,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },

  [ROUTES.viewAuctionItem.name]: {
    screen: ViewAuctionItem,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.status === auctionItemStatus.inStock) {
                return (
                  <WithPermission
                    access_key={
                    permissions.auction.views.assignBuyer.permissions
                      .assignBuyer.value
                  }
                  >
                    <StandardButton label="Assign Buyer" />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.assignBuyerToItem.name,
          params: { ...props?.navigation?.state?.params },
        }),
      },
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.status === auctionItemStatus.inStock) {
                return (
                  <WithPermission
                    access_key={permissions.auction.views.editAuctionRecords
                      .permissions.editAuctionRecords.value}
                  >
                    <StandardButton label="Edit" />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editAuctionItemDetails.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          expanded: true,
        }),
      },

    ],
  },
  [ROUTES.editBuyer.name]: {
    screen: EditBuyer,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },

  [ROUTES.editAuctionItemDetails.name]: {
    screen: EditAuctionItem,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewBuyer.name]: {
    screen: ViewBuyer,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission access_key={permissions.auction.views
            .modifyAuctionBuyers.permissions.modifyAuctionBuyers.value}
          >
            <StandardButton label={'buyer.buttons.editBuyer'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editBuyer.name,
          push: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
    ],
  },
  [ROUTES.assignBuyer.name]: {
    screen: AssignBuyer,
    modal: true,
    modalProps: {
      height: 600,
      width: 800,
    },
  },
  [ROUTES.assignBuyerToItem.name]: {
    screen: AssignBuyerItem,
    modal: true,
    modalProps: {
      height: 600,
      width: 800,
    },
  },
  [ROUTES.setPrice.name]: {
    screen: SetPrice,
    modal: true,
    modalProps: {
      height: 600,
      width: 800,
    },
  },
};
