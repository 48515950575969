import React from 'react';

import { Text } from '@applane/react-core-components';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import MasterForm from '../../../../../Wired/Master/MasterForm';
import { EditTest } from '../../../../../Queries/test';
import { MODELS } from '../../../../../Lib/constants';
import ViewTestTabs from './components/Tabs';
import { getString } from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'lab.labels.testName'.getLabel(),
  },
  {
    type: 'text',
    field: 'code',
    label: 'lab.labels.testID'.getLabel(),
  },
  autoSuggestInput({
    field: 'labType',
    label: 'lab.labels.labType'.getLabel(),
    placeholder: 'lab.placeholders.labType'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.LAB_TYPE,
    query: 'masterDataList',
    searching: true,
  }),
  {
    type: 'status',
    field: 'isActive',
    label: 'lab.labels.status'.getLabel(),
    container: 'topLabel',
  },
  {
    type: 'text',
    label: 'lab.labels.charge'.getLabel(),
    render: ({ data }) => (
      <Text style={{ ...vars.headings.h8 }}>
        {getString([data?.charge, data?.currency])}
      </Text>
    ),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'lab.labels.description'.getLabel(),
    minHeight: 38,
  },
  {
    type: 'date',
    format: 'DD MMM YYYY @ hh:mm A',
    field: '_createdOn',
    label: 'lab.labels.createdOn'.getLabel(),
    editable: false,
  },
];

export default MasterForm({
  uri: EditTest,
  formFields,
  editable: false,
  submitMessage: 'lab.messages.testUpdatedSuccessfully'.getLabel(),
  reloadOnChangeEvent: `reload${MODELS.LAB_TEST}`,
  extraFormGroups: [
    {
      type: 'custom',
      render: ViewTestTabs,
    },
  ],
});
