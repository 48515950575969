import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Table } from '../../../app-components/table/Table';
import {
  MODELS,
  ROUTES, vaccinationStatus,

} from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { downloadSalarySlip } from '../../../images';
import printPharmacyBill from '../../../Wired/PatientInvoices';
import { VaccinationList } from '../../../Queries/vaccination';
import {
  clearSelection, Image, selectAll, showSelectedCount, StandardButton, Text,
} from '../../../app-components';

const header = ({ aggregates }) => ({
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'consultation.buttons.exportToExcel'.getLabel()} />
      ),
      file: 'VaccinatedPatientHistory',
      type: 'download',
      download: () => ({
        uri: {
          model: MODELS.VACCINATION,
          query: {
            id: 'patientVaccinationList',
          },
        },
      }),
      columns: [
        {
          field: 'patient.uhid',
          header: 'UHID',
        },
        {
          header: 'vaccination.headers.patientName'.getLabel(),
          field: 'patient.patientName',
        },
        {
          header: 'vaccination.headers.mobile'.getLabel(),
          field: 'patient.mobile',
        },
        {
          header: 'vaccination.headers.vaccinationStatus'.getLabel(),
          field: 'status',
        },
      ],
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState: { selectedIds } } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    }]),
});
const tableItems = {
  lg: {
    header,
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'vaccination.headers.vaccinatedDate'.getLabel(),
        type: 'date',
        field: '_createdOn',
        sortable: true,
      },
      {
        header: 'vaccination.headers.patientName'.getLabel(),
        type: 'text',
        field: 'patient.patientName',
        sortable: true,
      },
      {
        header: 'vaccination.headers.uhid'.getLabel(),
        type: 'text',
        field: 'patient.uhid',
      },
      {
        header: 'vaccination.headers.opdNo'.getLabel(),
        type: 'text',
        field: 'consultation.ODPNumber',
        sortable: true,
      },
      {
        header: 'vaccination.headers.crNo'.getLabel(),
        type: 'text',
        field: '',
        sortable: true,
      },
      {
        header: 'vaccination.headers.nurseName'.getLabel(),
        type: 'text',
        field: 'vaccinatedByName',
        sortable: true,
      },
      {
        header: 'vaccination.headers.vaccinationStatus'.getLabel(),
        type: 'text',
        render: ({ item: { status } = {} }) => {
          if (status === vaccinationStatus.vaccinated) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                Vaccinated
              </Text>
            );
          }
          if (status === vaccinationStatus.nurseAssign) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                Nurse Assigned
              </Text>
            );
          }
          if (status === vaccinationStatus.notAppear) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.error }}>
                Not Appeared
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h8, color: vars.colors.warning }}>
              No Status
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.noOfVaccine'.getLabel(),
        type: 'text',
        render: ({ item: { patientVaccineDetails } }) => (
          <Text style={{ color: vars.colors.secondary.color2, fontSize: 14 }}>
            {patientVaccineDetails.length}
          </Text>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.viewVaccineList.name,
            params: { item },
          },
        }),
      },
      {
        header: 'vaccination.headers.bill'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { billing } = item;
          return (
            <TouchableOpacity onPress={({ submitResult }) => printPharmacyBill({ data: { ...billing }, submitResult })}>
              <Image
                source={downloadSalarySlip}
                style={{
                  width: 12,
                  height: 16,
                }}
              />
            </TouchableOpacity>
          );
        },
      },
      {
        header: 'vaccination.headers.action'.getLabel(),
        type: 'openActions',
        openActions: [
          {
            render: ({ item: { status = '' } }) => (
              <Text style={{ color: vars.colors.secondary.color2 }}>{status !== vaccinationStatus.vaccinated ? 'Reallign' : 'View'}</Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewPatientVaccinationHistoryDetails.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

const AllVaccinationHistory = (props) => {
  const { tableColumns = tableItems, addOnFilter } = props;
  return (
    <Table
      {...tableColumns}
      uri={() => VaccinationList({ filter: addOnFilter })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPatientVaccinationHistoryDetails.name,
          params: { item },
        },
      })}
      reloadEvent={`reload${MODELS.VACCINATION}`}
      {...props}
    />
  );
};
export default AllVaccinationHistory;
