export const getEmployeeSalary = (
  {
    addOnFilter, query, paramValue = {}, model,
  },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
    paramValue,
    sort: {
      _id: -1,
    },
  },
  model,
});

export const getVariableMonthlyAttendance = (
  {
    addOnFilter, query, paramValue = {}, extraParam = {}, model,
  },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
    paramValue,
    extraParam,
    sort: {
      _id: -1,
    },
  },
  model,
});
