import React from 'react';
import { Image, TouchableOpacity } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import { editRecord } from '../../../../images';
import { MODELS, ROUTES } from '../../../../Lib/constants';

const tabListColumns = [
  { type: 'selection' },
  {
    header: 'purchase.labels.subCategory'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'purchase.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
    numberOfLines: 2,
    width: 300,
  },
  {
    header: 'purchase.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
  {
    header: 'purchase.labels.actions'.getLabel(),
    type: 'text',
    render: () => (
      <TouchableOpacity>
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </TouchableOpacity>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        modal: true,
        view: ROUTES.editPurchaseSubCategory.name,
        params: { item },
        modalProps: {
          autoHide: true,
          width: 404,
          height: 496,
        },
      },
    }),
  },
];

const SubCategoryList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;
  return (
    <Table
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      reloadEvent={`reload${MODELS.PHARMACY_SUBCATEGORY}`}
      lg={{ columns: tableColumns }}
    />
  );
};
export default SubCategoryList;
