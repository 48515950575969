import React from 'react';
import { Text, View } from '../../../../app-components';
import vars from '../../../../theme/vars';

const NoShiftAssigned = () => (
  <View
    style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <View
      style={{
        width: 286,
        height: 59,
        backgroundColor: vars.colors.primary.color5,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={[
          vars.headings.h5,
          {
            color: vars.colors.primary.color1,
          }]}
      >
        No Shift Assigned
      </Text>
    </View>
  </View>
);

export default NoShiftAssigned;
