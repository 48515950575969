import React from 'react';

import MasterList from '../../../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 20 },
  {
    header: 'consultation.labels.labType'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'consultation.labels.testName'.getLabel(),
    type: 'text',
    field: 'code',
  },
  {
    header: 'consultation.labels.reportGenerationDate'.getLabel(),
    type: 'date',
    field: '_createdOn',
  },
  {
    header: 'consultation.labels.price'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'consultation.labels.attachment'.getLabel(),
    type: 'file',
    field: 'attachment',
  },
];

export default (props) => (
  <MasterList
    {...props}
    tableColumns={tableColumns}
    data={[]}
  />
);
