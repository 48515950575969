import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { viewHistoryUri } from '../../../../../../../../Queries/history';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';

const ViewHistoryIPDForm = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      title="History"
      description="View History of the Patient."
      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          uri={() => viewHistoryUri(item?._id)}

          lg={{
            formGroups: [
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                  paddingTop: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'date',
                        editable: false,
                        label: 'Date',
                        field: 'date',
                        format: 'DD MM YYYY',
                        style: {
                          width: 200,
                        },
                      },
                      {
                        type: 'textArea',
                        editable: false,
                        label: 'Description',
                        field: 'description',
                        width: 500,
                        minHeight: 95,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default ViewHistoryIPDForm;
