import React from 'react';

import ListActionModal from '../../../../Components/ListActionModal';

import { autoSuggestInput } from '../../../../autoSuggestions';
import { Form } from '../../../../app-components/form/Form';
import { save, View } from '../../../../app-components';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';

const AllotHouse = (props) => {
  const footer = {
    actions: [
      save,
    ],
  };
  return (
    <ListActionModal
      title="House Allotment"
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            {...props}
            onSubmit={submit({
              model: MODELS.HOUSE_ALLOTMENT,
            })}
            defaultValues={() => ({
              houseID: { _id: props?.navigation?.state?.params?.item?._id },
              rentCharge: props?.navigation?.state?.params?.item?.rentAmount,
              waterCharge: props?.navigation?.state?.params?.item?.waterCharge,
            })}
            mandatory={{
              allottee: 1,
              fromDate: 1,
              toDate: 1,
            }}
            reloadEvent="houseAllotmentDataList"
            reloadOnChangeEvent={`reload${MODELS.HOUSE_ALLOTMENT}`}
            closeView={1}
            type="standardShadow"
            lg={{
              verticalFormGroups: [
                {
                  formGroups: [
                    {
                      fieldVariant: 'filled',
                      direction: 'row',
                      columnsPerRow: 3,
                      columns: [
                        autoSuggestInput({
                          field: 'allottee',
                          type: 'autoSuggest',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEE,
                          query: 'employeeLists',
                          placeholder: 'Select Employee',
                          searching: true,
                          searchField: 'name',
                          mandatory: true,
                        },
                        {
                          modifyResult: ({ data }) => {
                            const newData = data.map((item) => ({
                              ...item,
                              fullName: `${item.firstName} ${item.lastName}`,
                            }));
                            return { data: newData };
                          },
                        }),
                        {
                          type: 'date',
                          field: 'fromDate',
                          label: 'From',
                          mandatory: true,
                        },
                        {
                          type: 'date',
                          field: 'toDate',
                          label: 'To',
                          mandatory: true,
                        },
                        {
                          type: 'text',
                          render: (data) => (data?.navigation?.state?.params?.item?.rentAmount),
                          label: 'Rent Charges',
                          editable: false,
                        },
                        {
                          type: 'text',
                          render: (data) => (data?.navigation?.state?.params?.item?.waterCharge),
                          label: 'Water Charges',
                          editable: false,
                        },
                      ],
                    },
                  ],
                },
              ],
              footer,
            }}
          />
        </View>
      )}
      {...props}
    />
  );
};
export default AllotHouse;
