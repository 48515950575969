import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExamination = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.chemoName': {
            compute: (data) => {
              if (data?.pastIllness?.chemoName === false) {
                return ({
                  set: {
                    'pastIllness.chemoType': '',
                    'pastIllness.chemoTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.chemoName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  {...autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'historyTakenBy',
                      label: 'History Taken By',
                      placeholder: 'History Taken By',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    // {
                    //   addOnFilter: () => ({
                    //     role: {
                    //       code: 'Supervisor',
                    //     },
                    //   }),
                    // }
                  )}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}
            </View>

            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Chief Complaints
              </Text>

              <FormField
                {...{
                  field: 'cheifComplaint',
                  fieldType: 'nested',
                  addInBottom: true,
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        {
                          type: 'textArea',
                          field: 'complaint',
                          variant: 'filled',
                          placeholder: 'Enter Complaint Here',
                          allowWhiteSpaces: true,
                          editable: true,
                        },
                        NestedAction({
                          containerStyle: {
                            marginTop: 25,
                          },
                        }),
                      ],
                    },
                  },
                }}
              />
            </View>

            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                History Of Present Illness
              </Text>
              <FormField
                type="textArea"
                field="presentIllness"
                variant="filled"
                placeholder="Enter present illness details..."
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '100',
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>
            {/* HISTORY OF */}
            {/* Previous Surgery */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                HISTORY OF
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                a.&nbsp;&nbsp;Previous Surgery
              </Text>
              <FormField
                type="textArea"
                field="previousSurgery"
                variant="filled"
                placeholder="Enter previous surgery details..."
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '100',
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <PastIllnessRow checkBoxLabel="Chemo" fieldLabel="chemo" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 150,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Family */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    display: 'block',
                    fontWeight: 700,
                    marginTop: 15,
                    marginLeft: 20,
                    marginBottom: 10,
                  }}
                >
                  c.&nbsp;&nbsp;Family
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    marginLeft: 30,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="seizures"
                    label="Seizures"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginLeft: 30,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="htn"
                    label="HTN"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  marginLeft: 20,
                  marginTop: 45,
                }}
              >
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="dm"
                    label="DM"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="bleeding"
                    label="Bleeding"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
              </View>
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  d.&nbsp;&nbsp;Personal
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    i.&nbsp;&nbsp;Appetite
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="personalData.appetite"
                    container="rightLabel"
                    editable
                    variant="filled"
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ii.&nbsp;&nbsp;Diet
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="personalData.diet"
                    variant="filled"
                    container="rightLabel"
                    options={['Non-Veg', 'Veg']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iii.&nbsp;&nbsp;Sleep
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.sleep"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Bowel & Bladder
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bowelAndBladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Addiction
                  </Text>
                  <View
                    style={{
                      width: 150,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="personalData.addictionSmoking"
                      label="Smoking"
                      container="rightLabel"
                      variant="filled"
                      style={{
                        marginTop: 10,
                      }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="personalData.addictionAlcohol"
                        label="Alcohol"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: 131,
                        }}
                      >
                        <FormField
                          type="checkBox"
                          field="personalData.addictionOther"
                          label="Other"
                          container="rightLabel"
                          variant="filled"
                          labelStyle={{ ...vars.headings.h14 }}
                        />
                      </View>
                      <FormField
                        type="text"
                        field="personalData.addictionOtherText"
                        container="rightLabel"
                        variant="filled"
                        editable
                        allowWhiteSpaces
                        disabled={(disableProps) => {
                          const { data: { personalData = {} } = {} } = disableProps;
                          return !personalData.addictionOther;
                        }}
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          // marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Other
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.personalOther"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vii.&nbsp;&nbsp;Occupation
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.occupation"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Drug Allergies
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iv.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFour"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    v.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFive"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* On Going Medication & Menstruation  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Drug Allergies */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Ongoing Medication
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="onGoingMedicationOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="onGoingMedicationTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="onGoingMedicationThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Obsteric & Menstruation */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  g.&nbsp;&nbsp;Obsteric & Menstruation
                </Text>
                <View
                  style={{
                    width: 150,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;Gravida
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.gravida"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;Para
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.para"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;Lining
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.lining"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iV.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menarche"
                        label="Menarche"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menarcheAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menarche;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        v.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menopause"
                        label="Menopause"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menopauseAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menopause;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Examination  */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Examination
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* GPE */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Conscious Levels
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="gpe.consciousLevel"
                    variant="filled"
                    container="rightLabel"
                    options={['Conscious', 'Drowsy', 'Stupor', 'Coma']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Orientation
                  </Text>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      marginTop: 15,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        a.&nbsp;&nbsp;Time
                      </Text>
                    </View>
                    <FormField
                      // variant="filled"
                      {...newDateInput({
                        hour: { field: 'gpe.hour', label: 'Hour' },
                        minute: { field: 'gpe.minute', label: 'Minute' },
                        meridiem: { field: 'gpe.meridiem' },
                      })}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        b.&nbsp;&nbsp;Place
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.place"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        c.&nbsp;&nbsp;Person
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.person"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      width: '100%',
                      marginTop: 10,
                      // marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      Vitals
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        a.&nbsp;&nbsp;PR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.pr"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        b.&nbsp;&nbsp;BP
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.bp"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        c.&nbsp;&nbsp;RR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.RR"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Temperature
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.temperature"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                {/* GCS */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    GCS
                  </Text>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>CNS</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        i.&nbsp;&nbsp;E
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gcsE"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        ii.&nbsp;&nbsp;V
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gcsV"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        iii.&nbsp;&nbsp;M
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gcsM"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>

              {/* Systematic Examination */}
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Systematic Examination
                  </Text>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>CNS</Text>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>Sensory</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        width: 150,
                        marginTop: 15,
                      }}
                    >
                      i.&nbsp;&nbsp;Touch
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.touch"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Pain
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.pain"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iii.&nbsp;&nbsp;Temperature
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.temperature"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iv.&nbsp;&nbsp;Vibration
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.vibration"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>Motor</Text>
                  <Text style={{ marginLeft: 40, marginTop: 15 }}>i Power</Text>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>a. Upper Limb</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;Abduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.abduction"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Adduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.adduction"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iii.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.flexion"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iv.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.extension"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>b. Elbow</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.elbowFlexion"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.elbowExtension"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>c. Wrist and hands</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.wristFlexion"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.wristExtension"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>d. Lower Limb</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;Hip
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hip"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      1.&nbsp;&nbsp;Abduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hipAbduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      2.&nbsp;&nbsp;Adduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hipAdduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      3.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hipFlexion"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      4.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hipExtension"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Knee
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.knee"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      1.&nbsp;&nbsp;Abduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.kneeAbduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      2.&nbsp;&nbsp;Adduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.kneeAdduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      3.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.kneeFlexion"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      4.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.kneeExtension"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iii.&nbsp;&nbsp;Ankle and Foot
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ankleAndFoot"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      1.&nbsp;&nbsp;Abduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ankleAndFootAbduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      2.&nbsp;&nbsp;Adduction
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ankleAndFootAdduction"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      3.&nbsp;&nbsp;Flexion
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ankleAndFootFlexion"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 100,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      4.&nbsp;&nbsp;Extension
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ankleAndFootExtension"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>e. Tohe</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;UL
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ul"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;LL
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.ll"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>f. Reflexes</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;BJ
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.reflexesBJ"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;TJ
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.reflexesTJ"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iii.&nbsp;&nbsp;KJ
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.reflexesKJ"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      iv.&nbsp;&nbsp;AJ
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.reflexesAJ"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 60, marginTop: 15 }}>f. Reflexes</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;UL
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.bulkUL"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 80,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Bulk LL
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.bulkLL"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      ii.&nbsp;&nbsp;Cerebelium
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.cerebelium"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>iii Cranial Nerves</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      a.&nbsp;&nbsp;Smell
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.smell"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      b.&nbsp;&nbsp;Vision
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.vision"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      c.&nbsp;&nbsp;Fundus
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.fundus"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      d.&nbsp;&nbsp;Pupil
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.pupil"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      e.&nbsp;&nbsp;Eye Movement
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.eyeMovement"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      f.&nbsp;&nbsp;Face Sensation
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.faceSensation"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      g.&nbsp;&nbsp;Hearing
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.hearing"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      h.&nbsp;&nbsp;Balance
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.balance"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      i.&nbsp;&nbsp;Swallowing
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.swallowing"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      j.&nbsp;&nbsp;Gag
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.gag"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      k.&nbsp;&nbsp;Cough
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.cough"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      l.&nbsp;&nbsp;Neck Movement
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.neckMovement"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      m.&nbsp;&nbsp;Shoulder Shrug
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.shoulderShrug"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 40,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      n.&nbsp;&nbsp;TongueMovement
                    </Text>
                    <FormField
                      type="text"
                      field="systematicExamination.tongueMovement"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                      // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                </View>
              </View>
              {/* Provisional Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Provisional Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="provisionalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Plan
                </Text>
                <FormField
                  type="text"
                  field="plan"
                  variant="filled"
                  style={{
                    height: 40,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                  }}
                />
              </View>
              {/* Fianl Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Final Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="finalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExamination;
