import { MODELS } from '../Lib/constants';

export const LogReferences = ({ navigation }) => ({
  query: {
    model: 'ipd_references',
    id: 'referencesIPDDataList',
    relationValue: {
      model: 'ipd_references',
      _id: navigation.state.params.item._id,
    },
  },
  model: 'ipd_references',
});

export const GetReferencesUri = (filter = {}) => ({
  query: {
    model: MODELS.IPD_REFERENCE,
    id: 'referencesIPDDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: 'ipd_references',
});
