import React from 'react';

import { auctionItemStatus, MODELS } from '../../../../Lib/constants';
import { Form } from '../../../../app-components/form/Form';
import { Text } from '../../../../app-components';
import { AuctionItemDetails } from '../../../../Queries/auction';
import vars from '../../../../theme/vars';
import { submit } from '../../../../AppServices';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { getEstimatedDepreciationValue, getMonthOfOperation } from '../../../../Lib/helpers';

const EditAuctionItem = (props) => {
  const { navigation } = props;
  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      closeView={1}
      editable={false}
      uri={AuctionItemDetails({ navigation })}
      onSubmit={submit({
        model: MODELS.AUCTION_ITEMS,
      })}
      submitMessage={'auction.messages.updatedMessage'.getLabel()}
      reloadEvent={`reload${MODELS.AUCTION_ITEMS}`}
      mandatory={{
        currentValue: 1,
        soldAtPrice: 1,
        buyer: 1,
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'auction.labels.serialNo'.getLabel(),
                    field: 'item.serialNumber',
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.selectItemName'.getLabel(),
                    field: 'item.item.name',
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.description'.getLabel(),
                    field: 'item.item.description',
                  },
                  {
                    type: 'text',
                    label: 'Status',
                    render: ({ data: { status } = {} }) => {
                      const color = status === auctionItemStatus.soldOut
                        ? vars.colors.error : vars.colors.primary.color2;
                      const itemStatus = status === auctionItemStatus.soldOut
                        ? 'Sold-Out' : 'In-Stock';
                      return (
                        <Text style={{ ...vars.headings.h8, color }}>
                          {itemStatus}
                        </Text>
                      );
                    },
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'FINANCE DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 7,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'number',
                    label: 'auction.labels.purchasePrice'.getLabel(),
                    field: 'item.batchNumber.purchasedPrice',
                  },
                  {
                    type: 'number',
                    label: 'auction.labels.currentValue'.getLabel(),
                    editable: true,
                    value: ({ data }) => (
                      <Text
                        style={{
                          ...vars.headings.h9,
                        }}
                      >
                        {data?.currentValue}
                      </Text>
                    ),
                    field: 'currentValue',
                  },
                  {
                    type: 'number',
                    label: 'auction.labels.estimatedDepreciation'.getLabel(),
                    render: ({ data }) => {
                      if (data?.currentValue) {
                        return (
                          <Text
                            style={{
                              ...vars.headings.h9,
                            }}
                          >
                            {Math.abs(Number(data?.item?.batchNumber?.purchasedPrice) - Number(data?.currentValue))}
                          </Text>
                        );
                      }
                      const rate = (data?.rate);
                      const Principle = data?.item?.batchNumber?.purchasedPrice;
                      const months = getMonthOfOperation(data?.item?._createdOn);
                      return (
                        <Text
                          style={{
                            ...vars.headings.h9,
                          }}
                        >
                          {getEstimatedDepreciationValue(rate, Principle, months)}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.manufacturer'.getLabel(),
                    field: 'manufacturer.name',
                  },
                  {
                    type: 'number',
                    label: 'auction.labels.soldAtPrice'.getLabel(),
                    field: 'soldAtPrice',
                    editable: ({ data: { status } }) => (status === auctionItemStatus.soldOut),
                    mandatory: true,
                  },
                  {
                    type: 'date',
                    label: 'auction.labels.soldOnDate'.getLabel(),
                    field: 'soldOnDate',
                  },
                  {
                    type: 'number',
                    label: 'auction.labels.yearOpOperation'.getLabel(),
                    render: ({ data: { item: renderedItem, _createdOn } }) => {
                      const totalMonths = getMonthOfOperation(_createdOn, renderedItem?._createdOn);
                      const months = totalMonths % 12;
                      const years = (totalMonths - months) / 12;
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          {`${years} Years ${months} Months`}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.supervisor'.getLabel(),
                    field: 'item.supervisor.name',
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.inCharge'.getLabel(),
                    field: 'item.inCharge.name',
                  },
                  {
                    type: 'file',
                    field: 'attachments',
                    label: 'auction.labels.attachDocuments'.getLabel(),
                    container: 'topLabel',
                    editable: true,
                    multiple: true,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            label: 'CONDEMNATION DETAILS',
            editable: true,
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'auction.labels.date'.getLabel(),
                    field: '_createdOn',
                    editable: false,
                  },
                  {
                    type: 'textArea',
                    label: 'auction.labels.reason'.getLabel(),
                    field: 'reason',
                    editable: false,
                  },
                  {
                    type: 'textArea',
                    label: 'auction.labels.remarks'.getLabel(),
                    field: 'remarks',
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'BUYER DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  autoSuggestInput({
                    field: 'buyer',
                    label: 'auction.labels.name'.getLabel(),
                    placeholder: 'auction.placeholders.name'.getLabel(),
                    suggestionField: 'name',
                    keyField: 'name',
                    valueField: 'name',
                    model: MODELS.AUCTION_BUYER,
                    query: 'masterDataList',
                    mandatory: true,
                    editable: ({ data: { status } }) => (status === auctionItemStatus.soldOut),
                  }),
                  {
                    type: 'text',
                    label: 'auction.labels.email'.getLabel(),
                    render: ({ data: { buyer } = {} } = {}) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {buyer?.email}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.phone'.getLabel(),
                    render: ({ data: { buyer } = {} }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {buyer?.contactNo}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'auction.labels.address'.getLabel(),
                    render: ({ data: { buyer } = {} }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {buyer?.address}
                      </Text>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default EditAuctionItem;
