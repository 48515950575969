import React, {
  useState, useCallback, useMemo, useLayoutEffect,
} from 'react';
import { debounce } from 'lodash';

import { View } from '../../app-components';
import { dropDownShadow } from '../../theme/shadows';
import { colors, bgs } from '../../theme/colors';

const ToolTip = ({
  containerStyle,
  tooltipContainerStyle,
  children,
  component,
  noPadding,
}) => {
  const borderStyles = {
    width: 1,
    color: colors.gainsBoro,
  };

  let ref = null;
  const isTooltipBottom = true;

  const [dynamicStyles, setDynamicStyles] = useState({});
  const [tooltipVisible, setTooltipVisibility] = useState(false);

  const debouncedSetVisibility = useMemo(() => debounce(setTooltipVisibility, 100), []);

  const onHover = useCallback((status) => () => {
    debouncedSetVisibility(status);
  }, []);

  useLayoutEffect(() => {
    if (ref) {
      const resizeObserver = new ResizeObserver((data) => {
        const [container] = data;
        if (container?.target) {
          const { offsetHeight } = container.target;
          if (offsetHeight) setDynamicStyles({ top: offsetHeight });
        }
      });
      resizeObserver.observe(ref);
      return () => { resizeObserver.disconnect(); };
    }
    return undefined;
  }, []);

  return (
    <View
      getRef={(container) => { ref = container; }}
      style={{ ...containerStyle, position: 'relative' }}
      onMouseEnter={onHover(true)}
      onMouseLeave={onHover(false)}
    >
      {children}
      {tooltipVisible && (
        <View style={{
          position: 'absolute',
          left: 0,
          right: 0,
          alignItems: 'center',
          zIndex: 1,
          pointerEvents: 'none',
          ...dynamicStyles,
        }}
        >
          <View style={{ position: 'relative', alignItems: 'center' }}>
            <View style={{
              backgroundColor: bgs.whiteBg,
              borderWidth: borderStyles.width,
              borderColor: borderStyles.color,
              borderRadius: 4,
              marginTop: isTooltipBottom ? 6 : 0,
              marginBottom: isTooltipBottom ? 0 : 6,
              padding: noPadding ? 0 : 10,
              pointerEvents: 'auto',
              ...dropDownShadow,
              ...tooltipContainerStyle,
            }}
            >
              {component}
            </View>
            <View style={{
              width: 12,
              height: 12,
              position: 'absolute',
              borderTopWidth: borderStyles.width,
              borderLeftWidth: borderStyles.width,
              borderColor: borderStyles.color,
              backgroundColor: bgs.whiteBg,
              zIndex: 1,
              top: isTooltipBottom ? 0 : undefined,
              bottom: !isTooltipBottom ? 0 : undefined,
              transform: `rotate(${isTooltipBottom ? 45 : -135}deg)`,
            }}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default ToolTip;
