export const EditConfiguration = ({ navigation }) => {
  return {
    query: {
      model: 'configurations',
      id: 'configurationsDataList',
      // relationValue: {
      //   model: 'configurations',
      // },
      // limit: 1,
    },
    model: 'configurations',
  };
};
