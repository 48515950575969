import { downloadData, invoke } from '../../AppServices';

const printOPDCard = (patientInfo, cb) => {
  downloadData({
    file: 'OPD Card',
    service: () => ({
      id: 'generateOPDCard',
      paramValue: {
        orientation: 'landscape',
        uhid: patientInfo?.uhid,
        patientName: patientInfo?.patientName,
        isWeb: true,
      },
    }),
    download: () => ({
      id: 'generateOPDCard',
      paramValue: {
        orientation: 'landscape',
        uhid: patientInfo?.uhid,
        patientName: patientInfo?.patientName,
        isWeb: true,
      },
    }),
  });
  cb();
};

export const printConsultationCard = async (patientInfo, cb) => {
  const result = await invoke({
    id: 'generateConsultationCard',
    paramValue: {
      orientation: 'landscape',
      uhid: patientInfo?.uhid,
      consultationId: patientInfo?._id,
      isWeb: true,
    },
  });

  const byteCharacters = atob(result.base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: 'application/pdf;base64',
  });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
  cb();
};

export default printOPDCard;
