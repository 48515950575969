import React from 'react';
import { save, Toast } from '../../../../app-components';

import { Form } from '../../../../app-components/form/Form';
import { invoke } from '../../../../AppServices';
import { autoSuggestInput } from '../../../../autoSuggestions';
import ListActionModal from '../../../../Components/ListActionModal';
import { RolesOrder } from '../../../../Lib/constants';
import { getErrorString } from '../../../../Lib/helpers';

const CotingentVoucherApproval = (props) => {
  const { navigation: { state: { params } }, eventDispatcher, navigation } = props;

  const handleVoucherSubmit = async ({ updates }) => {
    try {
      const paramValue = {
        _id: params.item
          ? params?.item?.formData?._id
          : params?.data._id,
        subject: params.item
          ? params?.item?.formData?.subject
          : params?.data?.subject,
        amount: params.item
          ? params?.item?.formData?.amount
          : params?.data?.amount,
        attachment: params.item
          ? params?.item?.formData?.attachment
          : params?.data?.attachment,
        billDate: params.item
          ? params?.item?.formData?.date
          : params?.data?.billDate,
        billNumber: params.item
          ? params?.item?.formData?.billNumber
          : params?.data?.billNumber,
        approvalRequest: updates.assignedToRole
          ? updates.assignedToRole : '',
        accountant: params.item
          ? params?.item?.formData?.accountant?._id
          : params?.data?.accountant?._id,
        head: params.item
          ? params?.item?.formData?.head?._id
          : params?.data?.head?._id,
        assignToRole: params.item
          ? params?.item?.assignedToRole
          : params?.data?.assignedToRole,
        remarks: updates.remarks,
        nameOfFirm: params.item
          ? params?.item?.formData?.nameOfTheFirm
          : params?.data?.nameOfFirm,
        // SOId: item._id,
      };

      const result = await invoke({
        id: 'createContingentVoucher',
        paramValue,
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Contingent Voucher has been created successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
        });
        eventDispatcher.notify('reload contingent_voucher_list');
        navigation.pop(params?.item?.formData ? 3 : 2);
      }
    } catch (error) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(error)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  };
  return (
    <ListActionModal
    //   icon={setPrice}
      title="Send for Approval"
      description="This action will send the contingent voucher to the chosen official."
      content={(
        <Form
          type="standardShadow"
          {...props}
          onSubmit={handleVoucherSubmit}
          reloadEvent="contingent_voucher_list"
          defaultValues={{
            formData: params?.item?.formData,
            soNumber: params?.item?.uniqueNumber,
          }}
          footer={{
            containerStyle: {
              alignItems: 'center',
              height: 80,
            },
            actions: [
              save({ label: 'confirm' }),
            ],
          }}
          mandatory={{
            assignedToRole: !params?.approve ? 1 : 0,
            remarks: 1,
          }}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                editable: true,
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      autoSuggestInput({
                        label: 'purchase.labels.sendForApprovalTo'.getLabel(),
                        variant: 'filled',
                        keyFiled: 'label',
                        field: 'assignedToRole',
                        suggestionField: 'label',
                        options: RolesOrder,
                        valueField: 'label',
                        mandatory: true,
                        visible: !params?.approve,
                      }),
                      {
                        type: 'textArea',
                        field: 'remarks',
                        label: 'Remarks',
                        numberOfLines: 2,
                        minHeight: 80,
                        mandatory: true,
                        style: { flex: 1, overflow: 'hidden' },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default CotingentVoucherApproval;
