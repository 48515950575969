import { upperFirst } from 'lodash';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { multiSearchInput } from '../../../../autoSuggestions';
import { validateInputLength } from '../../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'lab.labels.labName'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ value }) => upperFirst(value),
  },
  {
    type: 'text',
    field: 'code',
    label: 'lab.labels.labID'.getLabel(),
    mandatory: true,
  },
  multiSearchInput({
    field: 'rooms',
    label: 'lab.labels.rooms'.getLabel(),
    placeholder: 'lab.placeholders.selectRooms'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    idField: '_id',
    suggestionField: 'name',
    model: MODELS.ROOM,
    query: 'masterDataList',
    searchField: 'name',
    searching: true,
    mandatory: true,
  },
  {
    addOnFilter: {
      department: { code: 'LAB' },
    },
  }),
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'lab.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'center',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'lab.labels.description'.getLabel(),
    minHeight: 38,
  },
];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  code: ({ data }) => validateInputLength(data?.code, 2, 20),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

export default MasterForm({
  model: MODELS.LAB_TYPE,
  formFields,
  validations,
  uniqueValidationMessage: 'lab.messages.labAlreadyExists'.getLabel(),
  submitMessage: 'lab.messages.labAddedSuccessfully'.getLabel(),
  mandatory: {
    code: 1,
    rooms: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
