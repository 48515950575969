import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SelectDate } from '@applane/react-date-input';

import {
  StandardButton,
  View,
  Text,
} from '../../../../../../../app-components';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import { Table } from '../../../../../../../app-components/table/Table';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const MonitoringChart = (props) => {
  const [selectedDate, setSelectedDate] = useState();
  const { eventDispatcher } = props || {};
  const { dataParams } = props;
  const tableItems = {
    lg: {
      columns: [
        {
          header: 'S.No.',
          type: 'number',
          render: (item) => <Text>{item.index + 1}</Text>,
        },
        {
          header: 'Time',
          type: 'date',
          format: 'HH:mm A',
          field: 'monitoringDate',
        },
        {
          header: 'BPssssssss',
          type: 'text',
          field: 'bpsd',

        },
        {
          header: 'Temperature',
          type: 'text',
          field: 'temp',

        },
        {
          header: 'Pulse',
          type: 'text',
          field: 'pr',
        },
        {
          header: 'Resp.',
          type: 'text',
          field: 'resp',
        },
      ],
    },
  };

  useEffect(() => {
    eventDispatcher.notify(`reload${MODELS.MONITORING_CHART}`);
  }, [selectedDate, eventDispatcher]);
  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      editable={false}
      flex={false}
      scrollable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(item?.navigation?.state?.params?.patient?._createdOn).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate)
                            .isSame(currentDate);
                          const highlightedDate = moment(currentDate).isAfter(moment(admissionDate))
                           && moment(currentDate).isBefore(moment());
                          if (admittedDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'green', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View style={{ backgroundColor: '#EFF3FB', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View style={{ backgroundColor: '#4070C9', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          return (
                            <View>
                              {date}
                            </View>
                          );
                        }}
                      />
                    ),
                  },
                  {
                    render: () => {
                      const tableProps = {
                        ...props,
                        ...tableItems,
                      };
                      return (
                        <Table
                          {...tableProps}
                          reloadEvent={`reload${MODELS.MONITORING_CHART}`}
                          uri={(item) => ({
                            query: {
                              id: 'monitoringChartList',
                              addOnFilter: {
                                admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
                                speciality: props.dataParams?.filters?.specialityFilter,
                                monitoringDate: {
                                  $eq: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(0),
                                        ).setUTCMinutes(0),
                                      ).setUTCSeconds(0),
                                    ).setUTCMilliseconds(0),
                                  ),
                                },
                              },
                            },
                            model: MODELS.MONITORING_CHART,
                          })}
                          header={() => ({
                            title: () => (
                              <HeaderTitleWithCount
                                label={`Monitoring:  ${moment(selectedDate).format('DD MMM YYYY')}`}
                              />
                            ),
                            actions: [
                              {
                                type: 'link',
                                link: ({ navigation: { state: { params = {} } } }) => ({
                                  view: ROUTES.logMonitoringChart.name,
                                  params: { ...params, dataParams },
                                  push: true,
                                  modal: true,
                                  modalProps: {
                                    autoHide: true,
                                    maxHeight: '100%',
                                    width: 1196,
                                    height: 547,
                                  },
                                }),
                                render: () => (
                                  <StandardButton
                                    label="Log"
                                  />
                                ),
                              },
                            ],
                          })}
                          action={{
                            type: 'link',
                            link: (data) => ({
                              view: ROUTES.viewMonitoringChart.name,
                              expanded: true,
                              push: true,
                              params: {
                                item: data.item,
                                dataParams,
                                selectedDate,
                                patient: data.navigation.state.params.patient,
                              },

                            }),
                          }}
                        />
                      );
                    },
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default MonitoringChart;

