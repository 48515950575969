/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import { Form } from '../../../../../app-components/form/Form';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const RaisePurchaseRequest = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
          defaultValues = {},
        } = {},
      } = {},
    } = {},
  } = props;
  const { rateContract = [] } = item?.item || {};
  const supplierIds = rateContract.filter(({ rateContractValidity }) => moment().diff(rateContractValidity, 'days') < 0);
  const supplierExist = supplierIds.length !== 0;

  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      defaultValues={() => ({ ...defaultValues, ...item })}
      onSubmit={submit({
        model: MODELS.PHARMACY_PURCHASE,
      })}
      mandatory={supplierExist ? {
        requiredQuantity: 1,
        reasonForPurchase: 1,
        manufacturer: 1,
        supplier: 1,
      } : {
        requiredQuantity: 1,
        reasonForPurchase: 1,
      }}
      submitMessage={'inventory.messages.requestRaisedSuccessfully'.getLabel()}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            label: 'inventory.title.purchaseRequestDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    field: 'supplier',
                    variant: 'filled',
                    label: 'inventory.labels.supplier'.getLabel(),
                    placeholder: 'inventory.labels.supplier'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUPPLIER,
                    query: 'masterDataList',
                    mandatory: supplierExist,
                  },
                  {
                    addOnFilter: ({ data: { item: { rateContract } } }) => ({
                      _id: {
                        $in:
                      rateContract.map(({ supplier, rateContractValidity }) => {
                        if (moment().diff(rateContractValidity, 'days') < 0) {
                          return supplier._id;
                        }
                      }),
                      },
                    }),
                  }),
                  autoSuggestInput({
                    field: 'manufacturer',
                    variant: 'filled',
                    label: 'inventory.labels.manufacturer'.getLabel(),
                    placeholder: 'inventory.labels.manufacturer'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_MANUFACTURER,
                    query: 'masterDataList',
                    mandatory: supplierExist,
                  },
                  {
                    addOnFilter: ({ data }) => ({
                      _id: {
                        $in:
                      data?.item?.rateContract.map(({ manufacturer }) => manufacturer._id),
                      },
                      supplier: {
                        _id: data.supplier._id,
                      },
                    }),
                  }),
                  {
                    type: 'number',
                    label: 'inventory.labels.requiredQuantity'.getLabel(),
                    field: 'requiredQuantity',
                    mandatory: supplierExist,
                  },
                  {
                    type: 'textArea',
                    label: 'inventory.labels.reasonForPurchase'.getLabel(),
                    field: 'reasonForPurchase',
                    mandatory: supplierExist,
                    style: { flex: 2 },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    label: 'inventory.labels.itemId'.getLabel(),
                    field: 'item.itemCode',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.itemName'.getLabel(),
                    field: 'item.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'item.category.name',
                    label: 'inventory.labels.category'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.unitPrice'.getLabel(),
                    field: 'item.sellingPrice',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.avgConsumption'.getLabel(),
                    field: 'item.avgMonthlyConsumption',
                    editable: false,
                  },
                  {
                    type: 'date',
                    label: 'inventory.labels.lastPurchaseDate'.getLabel(),
                    field: 'item.lastPurchaseDate',
                    editable: false,
                  },
                  {
                    type: 'number',
                    label: 'inventory.labels.lastPurchaseQuantity'.getLabel(),
                    field: 'item.lastPurchaseQuantity',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.stockAvailable'.getLabel(),
                    field: 'item.stockAvailable.stockAvailable',
                    editable: false,
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default RaisePurchaseRequest;
