import React from 'react';

import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';

import { Table } from '../../../../app-components/table/Table';
import { Image } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';
import { editRecord } from '../../../../images';
import { ViewElectricityBillURI } from '../uri';

const tableItems = {
  lg: {
    columns: [
      {
        header: 'HouseID',
        type: 'text',
        render: (data) => (data?.navigation?.state?.params?.item?.name),
      },
      {
        header: 'Units',
        type: 'text',
        field: 'units',
      },
      {
        header: 'Amount',
        type: 'text',
        field: 'amount',
      },
      {
        header: 'Billing Year',
        type: 'text',
        field: 'billingYear.name',
      },
      {
        header: 'Billing Month',
        type: 'text',
        field: 'billingMonth.name',
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <WithPermission
                access_key={permissions.mdm.views
                  .addElectricityBillOfHouse.permissions.addElectricityBillOfHouse.value}
              >
                <Image
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  source={editRecord}
                />
              </WithPermission>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.editElectricityBill.name,
              params: { _id: item?._id, patient: item },
            }),
          },
        ],
      },
    ],
  },
};

const ElectricityBillTabList = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <Table
      uri={ViewElectricityBillURI(item._id)}
      {...tableItems}
      {...props}
      defaultValues={() => ({
        houseID: item?.name,
      })}
      reloadEvent="electricityBillDataList"
    />
  );
};

export default ElectricityBillTabList;
