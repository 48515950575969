import React from 'react';

import ListActionModal from '../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../Lib/constants';
import { save, Text, Toast, View } from '../../../../../../../app-components';
import { invoke, submit } from '../../../../../../../AppServices';
import vars from '../../../../../../../theme/vars';
import { NestedAction, NestedTable } from '../../../../../../../app-components/input-components/nestedTable/NestedTable';

const AddMedicine = (props) => (
  <ListActionModal
    title="Add Medicine "
    description="Add Medicine For This Patient."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Save',
            }),
          ],
        }}
        closeView={1}
        reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          // const tempData = updates?.treatment?.insert;
          const treatmentInvoke = await invoke({
            id: 'addTreatments',
            paramValue: {
              data: {
                ...data,
                admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
                addDrugFromProgress: true,
              },
            },
          });

          // if (tempData) updates.treatment = tempData;
          updates.treatment = treatmentInvoke.result.map((item) => ({ _id: item._id }));
          updates.progressDate = new Date(
            new Date(
              new Date(
                new Date(
                  new Date(updates?.progressDate).setUTCHours(0),
                ).setUTCMinutes(0),
              ).setUTCSeconds(0),
            ).setUTCMilliseconds(0),
          );
          const newUpdate = {
            ...updates,
            ...submitProps?.navigation?.state?.paramsData,
            admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
            speciality: submitProps?.navigation?.state
              .params?.dataParams?.filters?.specialityFilter,
          };

          const submitAPI = submit({
            model: MODELS.IPD_PROGRESS_REPORT,
            ...submitProps,
            data,
            updates: newUpdate,
          });

          await submitAPI({ data, updates: newUpdate });

          Toast.show({
            message: 'Success',
            description: 'Drug has been Created successfully.',
            type: 'success',
            position: 'top',
            direction: 'right',
            duration: 3000,
          });
        }}
        // defaultValues={() => ({
        //   referenceDate: new Date(),
        // })}
        // mandatory={{
        //   _id: 1,
        // }}

        lg={{
          formGroups: [
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {

                      field: 'treatment',
                      fieldType: 'nested',
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        footer: ({ addRow }) => ({
                          leftActions: [{
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: vars.colors.secondary.color2,
                              }}
                              >
                                <Text style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.secondary.color2,
                                }}
                                >
                                  {`+ ${'Add New Item'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: addRow,
                          }],
                        }),
                        skipTableHeaderOnNoData: true,
                        columnsPerRow: 1,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 20,
                            paddingLeft: 12,
                            paddingRight: 4,
                            backgroundColor: vars.colors.white,
                          },
                          columns: [
                            {
                              type: 'text',
                              variant: 'filled',
                              field: 'drug',
                              label: 'Drug',
                              editable: true,
                              width: 250,
                            },
                            {
                              type: 'text',
                              variant: 'filled',
                              field: 'route',
                              label: 'Route',
                              mandatory: true,
                              editable: true,
                              width: 150,
                            },
                            {
                              type: 'number',
                              field: 'dose',
                              variant: 'filled',
                              label: 'Dose (mg)',
                              mandatory: true,
                              editable: true,
                              width: 100,
                            },
                            {
                              type: 'number',
                              field: 'frequency',
                              label: 'Frequency',
                              variant: 'filled',
                              editable: true,
                              mandatory: true,
                              width: 200,
                            },
                            {
                              type: 'textArea',
                              field: 'specialInstructions',
                              label: 'Special Instruction',
                              allowWhiteSpaces: true,
                              variant: 'filled',
                              editable: true,
                              width: 312,
                            },
                            NestedAction(),
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default AddMedicine;
