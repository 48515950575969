import React from 'react';
import { FilterGroup } from '../../../../app-components';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../../images';
import {
  inventoryType, MODELS, pharmacyAndAssetStocksStatus,
} from '../../../../Lib/constants';
import AssetTabs from './AssetPurchaseRequest/assetTabs';
import PharmacyPurchaseRequestList from './PharmacyPurchaseRequest/pharmacyPurchaceRequest';
import { tabTheme } from './tabTheme';

const PurchaseRequestTab = (props) => {
  const { navigation } = props;
  const data = navigation?.state?.params?.item;

  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      tabs={{
        CentralPharmacy: {
          label: 'Pharmacy',
          screen: PharmacyPurchaseRequestList,
          screenProps: {
            data,
            model: MODELS.PURCHASE_REQUEST,
            id: 'purchaseRequestPharmacyList',
            addOnFilter: { type: inventoryType?.pharmacy },
          },
          actions: [
            <FtsSearch
              action={{
                exp: ['purchaseRequestPharmacy.purchaseRequestNumber'],
              }}
              key="fts"
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: pharmacyAndAssetStocksStatus,
                  field: 'status',
                  label: 'Status',
                  multiSelect: true,
                },
              ],
            }),
          ],
        },
        CentralAsset: {
          label: 'Asset',
          screen: AssetTabs,
          actions: [
            <FtsSearch
              action={{
                exp: ['purchaseRequestPharmacy.purchaseRequestNumber'],
              }}
              key="fts"
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: pharmacyAndAssetStocksStatus,
                  field: 'status',
                  label: 'Status',
                  multiSelect: true,
                },
              ],
            }),
          ],
        },
        CentralAssetConsumables: {
          label: 'Consumables',
          screen: PharmacyPurchaseRequestList,
          screenProps: {
            data,
            model: MODELS.PURCHASE_REQUEST,
            id: 'purchaseRequestAssetConsumablesList',
            addOnFilter: { type: inventoryType?.pharmacy },
          },
          actions: [
            <FtsSearch
              action={{
                exp: ['purchaseRequestPharmacy.purchaseRequestNumber'],
              }}
              key="fts"
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: pharmacyAndAssetStocksStatus,
                  field: 'status',
                  label: 'Status',
                  multiSelect: true,
                },
              ],
            }),
          ],
        },
      }}
      {...props}
    />
  );
};
export default PurchaseRequestTab;
