import React from 'react';
import { FormHoc, Text, View } from '../../../../../../app-components';
import theme from '../../../../../../App/AppContainer/Header/theme';
import { getString } from '../../../../../../Lib/helpers';
import vars from '../../../../../../theme/vars';

const PersonalDetails = (props) => {
  const { profileInfo } = props;

  const PersonalDetailsForm = FormHoc({
    type: 'standardShadow',
    closeView: 1,
    editable: false,
    reloadEvent: 'reloadProfile',
    defaultValues: () => ({ ...profileInfo }),
    bodyContainerStyle: { paddingLeft: 16 },
    formGroups: [
      {
        columnsPerRow: 1,
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => (
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={theme.userInfoWrapper}
                    >
                      <Text numberOfLines={1} style={theme.usernameText}>
                        {profileInfo?.firstName?.charAt(0) + profileInfo?.lastName?.charAt(0)}
                      </Text>
                    </View>
                    <View style={{ paddingLeft: 12, paddingTop: 2 }}>
                      <Text style={{ ...vars.headings.h5, color: vars.colors.secondary.color1 }}>
                        {getString([profileInfo?.firstName, profileInfo?.lastName])}
                      </Text>
                    </View>
                  </View>
                ),
              },
            ],
          },
        ],
      },
      {
        columnsPerRow: 4,
        groupsContainerStyle: { paddingTop: 0 },
        columnContainerStyle: {
          marginLeft: 6, marginRight: 6, marginTop: 12, marginBottom: 12,
        },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'firstName',
                label: 'profile.labels.firstName'.getLabel(),
              },
              {
                type: 'text',
                field: 'lastName',
                label: 'profile.labels.lastName'.getLabel(),
              },
              {
                type: 'text',
                field: 'gender',
                label: 'profile.labels.gender'.getLabel(),
              },
              {
                type: 'date',
                field: 'dob',
                label: 'profile.labels.dob'.getLabel(),
              },
              {
                type: 'text',
                field: 'bloodGroup',
                label: 'profile.labels.bloodGroup'.getLabel(),
              },
              {
                type: 'text',
                field: 'maritalStatus',
                label: 'profile.labels.maritalStatus'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        label: 'Contact Information',
        labelStyle: { ...vars.headings.h9, color: vars.colors.secondary.color2 },
        groupsContainerStyle: { paddingTop: 0 },
        columnContainerStyle: {
          marginLeft: 6, marginRight: 6, marginTop: 12, marginBottom: 12,
        },
        groups: [
          {
            columnsPerRow: 4,
            columns: [
              {
                type: 'text',
                field: 'mobile',
                label: 'profile.labels.mobile'.getLabel(),
              },
              {
                type: 'text',
                field: 'email',
                label: 'profile.labels.email'.getLabel(),
              },
            ],
          },
          {
            columnsPerRow: 4,
            columns: [
              {
                type: 'text',
                field: 'addressLine1',
                label: 'profile.labels.addressLine1'.getLabel(),
                width: 636,
              },
              {
                type: 'text',
                field: 'addressLine2',
                label: 'profile.labels.addressLine2'.getLabel(),
              },
            ],
          },
          {
            columnsPerRow: 4,
            columns: [
              {
                type: 'text',
                field: 'city',
                label: 'profile.labels.city'.getLabel(),
              },
              {
                type: 'text',
                field: 'state',
                label: 'profile.labels.state'.getLabel(),
              },
              {
                type: 'text',
                field: 'pinCode',
                label: 'profile.labels.zipcode'.getLabel(),
              },
            ],
          },
        ],
      },
    ],
  });
  return <PersonalDetailsForm {...props} />;
};

export default PersonalDetails;
