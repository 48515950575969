// import { autosuggestFetch } from '../../../AppServices';
import React from 'react';
import { Image } from '../../../app-components';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import WithPermission from '../../../Components/WithPermission';
import { editRecord } from '../../../images';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../Lib/helpers';
import permissions from '../../../Lib/permissions';

export const Layout = {
  nameLayout: {
    type: 'text',
    header: 'organisationTaxDetails.headers.name'.getLabel(),
    field: 'name',
    sortable: 'name',
  },
  amount: {
    type: 'number',
    header: 'organisationTaxDetails.headers.amount'.getLabel(),
    field: 'amount',
    sortable: true,
  },
  max_limit: {
    type: 'number',
    header: 'organisationTaxDetails.headers.max_limit'.getLabel(),
    field: 'max_limit',
  },
  benefit_percent: {
    type: 'number',
    header: 'organisationTaxDetails.headers.benefit_percent'.getLabel(),
    field: 'benefit_percent',
  },
  is_salary_paid_base: {
    type: 'text',
    header: 'organisationTaxDetails.headers.is_based_on_salary_paid'.getLabel(),
    value: ({ item }) => (null || item.is_salary_paid_base ? 'Yes' : 'No'),
    sortable: 'is_salary_paid_base',
  },
  section: {
    type: 'text', /// probably
    header: 'organisationTaxDetails.headers.section'.getLabel(),
    field: 'section.name',
    width: 300,
  },
  financial_year: autoSuggestInput({
    type: 'autoSuggest',
    header: 'organisationTaxDetails.headers.finantial_year_id'.getLabel(),
    label: 'organisationTaxDetails.headers.finantial_year_id'.getLabel(),
    field: 'finantial_year_id',
    valueField: 'name',
    suggestionField: 'name',
    keyField: 'name',
    model: MODELS.FINANCIAL_YEAR,
    query: 'financialYearSuggestions',
    sortable: 'finantial_year_id',
    mandatory: true,
  }),
  tax_percentage: {
    type: 'number',
    header: 'organisationTaxDetails.headers.tax_percentage'.getLabel(),
    label: 'organisationTaxDetails.headers.tax_percentage'.getLabel(),
    field: 'tax_percentage',
    mandatory: true,
  },
  to_amount: {
    type: 'number',
    header: 'organisationTaxDetails.headers.to_amount'.getLabel(),
    label: 'organisationTaxDetails.headers.to_amount'.getLabel(),
    field: 'to_amount',
    mandatory: true,
  },
  from_amount: {
    type: 'number',
    header: 'organisationTaxDetails.headers.from_amount'.getLabel(),
    label: 'organisationTaxDetails.headers.from_amount'.getLabel(),
    field: 'from_amount',
    mandatory: true,
  },
  section_name: {
    type: 'text',
    header: 'organisationTaxDetails.headers.name'.getLabel(),
    field: 'name',
  },
  is_for_senior_citizen: {
    type: 'toggleSwitch',
    label: 'organisationTaxDetails.labels.is_for_senior_citizen'.getLabel(),
    field: 'is_for_senior_citizen',
    container: 'topLabel',
  },
  action_saving_head: {
    header: 'organisationTaxDetails.headers.action'.getLabel(),
    type: 'text',
    render: () => (
      <WithPermission
        access_key={permissions.mdm.views.modifySalary.permissions.modifySalary.value}
      >
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </WithPermission>
    ),
    action: () => ({
      type: 'link',
      link: ({ item }) => ({
        view: [ROUTES.editSavingHead.name],
        modal: true,
        modalProps: {
          autoHide: true,
          width: 800,
          height: 400,
        },
        routeOptions: {
          expanded: true,
        },
        params: { ...item },
      }),
    }),
  },
  action_tax_slabs: {
    header: 'organisationTaxDetails.headers.action'.getLabel(),
    type: 'text',
    render: () => (
      <WithPermission
        access_key={permissions.mdm.views.modifySalary.permissions.modifySalary.value}
      >
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </WithPermission>
    ),
    action: () => ({
      type: 'link',
      link: ({ item }) => ({
        view: [ROUTES.editTaxSlab.name],
        modal: true,
        modalProps: {
          autoHide: true,
          width: 800,
          height: 400,
        },
        params: { ...item },
        routeOptions: {
          expanded: true,
        },
      }),
    }),
  },
};

export const FormLayout = {
  section: searchInput({
    field: 'section',
    query: 'taxDeclarationSectionSuggestions',
    model: MODELS.TAX_DECLARATION_SECTIONS,
    label: 'organisationTaxDetails.labels.section'.getLabel(),
    placeholder: 'Section',
    searchField: 'name',
    suggestionField: 'name',
    mandatory: true,
    sortable: 'section',
  }),
  name: {
    type: 'text',
    field: 'name',
    header: 'Name',
    label: 'organisationTaxDetails.labels.name'.getLabel(),
    placeholder: 'Name',
    mandatory: true,
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
  },
  amount: {
    type: 'number',
    header: 'organisationTaxDetails.headers.amount'.getLabel(),
    placeholder: 'Amount',
    label: 'organisationTaxDetails.headers.amount'.getLabel(),
    field: 'amount',
    mandatory: true,
  },
  max_limit: {
    type: 'number',
    header: 'organisationTaxDetails.headers.max_limit'.getLabel(),
    placeholder: 'Max Limit',
    label: 'organisationTaxDetails.headers.max_limit'.getLabel(),
    field: 'max_limit',
    mandatory: true,
  },
  is_salary_paid_base: {
    type: 'toggleSwitch',
    placeholder: 'Is Salary Paid Base',
    label: 'organisationTaxDetails.headers.is_based_on_salary_paid'.getLabel(),
    field: 'is_salary_paid_base',
    container: 'topLabel',
    mandatory: true,
  },
  benefit_percent: {
    type: 'number',
    header: 'organisationTaxDetails.headers.benefit_percent'.getLabel(),
    placeholder: 'Benefit Percent',
    label: 'organisationTaxDetails.headers.benefit_percent'.getLabel(),
    field: 'benefit_percent',
  },
  section_name: {
    type: 'text',
    header: 'organisationTaxDetails.headers.name'.getLabel(),
    label: 'organisationTaxDetails.headers.name'.getLabel(),
    placeholder: 'Name',
    field: 'name',
    mandatory: true,
  },
};
