
import MasterForm from '../../../Wired/Master/MasterForm';
import { EditDepartment } from '../../../Queries/department';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength } from '../../../Lib/helpers';

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'department.labels.departmentName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    allowConsecutiveSpaces: true,
  },
  {
    type: 'text',
    field: 'code',
    label: 'department.labels.departmentId'.getLabel(),
    mandatory: true,
    editable: false,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'department.labels.status'.getLabel(),
    placeholder: 'Select',
    container: 'leftLabel',
    align: 'center',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'department.labels.description'.getLabel(),
    allowWhiteSpaces: true,
    minHeight: 80,
  },
  {
    type: 'toggleSwitch',
    showOuterLabel: false,
    field: 'trackBeds',
    label: 'department.labels.containNumberOfBeds'.getLabel(),
    container: 'leftLabel',
  },
];

export default MasterForm({
  uri: EditDepartment,
  model: MODELS.DEPARTMENTS,
  formFields,
  validations,
  uniqueValidationMessage: 'department.messages.departmentAlreadyExists'.getLabel(),
  submitMessage: 'department.messages.departmentEditedSuccessfully'.getLabel(),
  closeView: 1,
});
