import React from 'react';
import { Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { UploadImage } from '../../../../../app-components/input-components/Editors';

import ListActionModal from '../../../../../Components/ListActionModal';
import { belongingsImage } from '../../../../../images';
import vars from '../../../../../theme/vars';

const PatientBelongingsTableModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item,
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <ListActionModal
      icon={belongingsImage}
      title="Belonging/Clothing Details"
      description="List of Belongings of the unknown patient."
      content={(
        <Form
          closeView={1}
          editable={false}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          {...props}
          defaultValues={{ ...item }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        label: 'patient.labels.belongingsCareBy'.getLabel(),
                        render: ({ data }) => {
                          const { belongingsCareBy } = data;
                          if (belongingsCareBy) {
                            return (
                              <Text style={{ ...vars.headings.h9 }}>
                                {belongingsCareBy?.firstName}
                                {' '}
                                {belongingsCareBy?.lastName}
                              </Text>
                            );
                          }
                          return '--';
                        },
                      },
                      {
                        type: 'textArea',
                        field: 'additionalComments',
                        variant: 'filled',
                        label: 'patient.labels.additionalComments'.getLabel(),
                        placeholder: 'patient.placeholders.additionalComments'.getLabel(),
                        style: { flex: 2 },
                      },
                    ],
                  },
                ],
              },
              {
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'custom',
                        render: ({ data: { belongingsDetails } = [] }) => {
                          if (belongingsDetails) {
                            return (
                              <View
                                style={{
                                  backgroundColor: vars.colors.white,
                                  flexDirection: 'row',
                                  flex: 1,
                                  flexWrap: 'wrap',
                                }}
                              >
                                {belongingsDetails.map((need, index) => (
                                  <View
                                    style={{
                                      backgroundColor: vars.colors.white,
                                      flexBasis: '50%',
                                      flexDirection: 'row',
                                      marginTop: 10,
                                    }}
                                    key="description"
                                  >
                                    <View>
                                      <UploadImage
                                        data={need}
                                        field="itemImage"
                                        editable={false}
                                        imageWidth={100}
                                        imageHeight={130}
                                      />
                                    </View>
                                    <View style={{ marginLeft: 10 }}>
                                      <View>
                                        <Text style={{ ...vars.headings.h9 }}>
                                          {index + 1}
                                          {'.'}
                                          {need?.itemName}
                                        </Text>
                                      </View>
                                      <View style={{
                                        marginTop: 20,
                                      }}
                                      >
                                        <Text
                                          style={{
                                            ...vars.headings.h9,
                                            width: 200,
                                            textOverFlow: 'scroll',
                                            color: vars.colors.grey.color3,
                                          }}
                                          numberOfLines={4}
                                        >
                                          {need?.description}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            );
                          }
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default PatientBelongingsTableModal;
