import vars from '../../../theme/vars';

const loginTheme = {
  scrollViewStyle: {
    flex: 1,
    overflow: 'hidden',
  },
  containerStyle: {
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  infoFormWrapper: {
    height: 100,
    flexDirection: 'row',
    backgroundColor: vars.colors.secondary.color4,
    borderRadius: 4,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  roleInputWrapper: {
    width: '25%',
  },
  statusWrapper: {
    width: '25%',
    marginLeft: 24,
  },
  descriptionWrapper: {
    flex: 1,
    marginLeft: 24,
  },
  permissionView: {
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
  },
  completeWidth: {
    width: '100%',
    justifyContent: 'center',
  },
  center: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  width200: {
    width: 300,
    paddingLeft: 7,
  },
  switchInputWrapper: {
    margin: 10,
    minWidth: 80,
    alignItems: 'center',
  },
};

export default loginTheme;
