import React from 'react';
import moment from 'moment';

import { Text } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import vars from '../../../../theme/vars';
import { AssetLoanOutHistoryUri } from '../../../../Queries/asset';

const tabListColumns = [
  { width: 14 },
  {
    type: 'date',
    header: 'asset.headers.dateOfEvent'.getLabel(),
    field: 'loanedOutDate',
  },
  {
    type: 'date',
    header: 'asset.headers.returnDate'.getLabel(),
    render: ({ item: { loanedOutDate, loanedOutDays } }) => (
      <Text style={{ ...vars.headings.h9 }}>
        { moment(loanedOutDate).add(loanedOutDays, 'days').format('DD MMM YYYY')}
      </Text>
    ),
  },
  {
    type: 'text',
    header: 'asset.headers.toDepartment'.getLabel(),
    field: 'loanedOutDept.name',
  },
  {
    type: 'text',
    header: 'asset.headers.toSpeciality'.getLabel(),
    field: 'loanedOutSpeciality.name',
  },
  {
    type: 'text',
    header: 'asset.headers.noOfDays'.getLabel(),
    field: 'loanedOutDays',
  },
  {
    type: 'text',
    header: 'asset.headers.description'.getLabel(),
    field: 'description',
  },
];

const AssetLoanOutHistory = (props) => {
  const { tableColumns = tabListColumns, item: { _id } } = props;

  return (
    <Table
      uri={() => AssetLoanOutHistoryUri({ filter: { assets: { asset: { _id } } } })}
      lg={{ columns: tableColumns }}
      {...props}
    />
  );
};
export default AssetLoanOutHistory;
