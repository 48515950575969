import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import {
  View, Text,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import vars from '../../../../../theme/vars';
import { submit } from '../../../../../AppServices';
import { assetStatuses, MODELS } from '../../../../../Lib/constants';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';

const getNewId = () => `new_${uuid()}`;

const TransferRequestModalAsset = (props) => {
  const { navigation: { state: { params: { item: { item } = {} } = {} } = {} } = {} } = props;
  return (
    <Form
      {...props}
      closeView={1}
      bodyContainerStyle={{ backgroundColor: vars.colors.white }}
      defaultValues={() => ({ departmentStocks: [{ _id: getNewId() }], type: 'Transfer Request' })}
      onSubmit={submit({
        model: MODELS.ASSET_TRANSFER_REQUESTS,
      })}
      mandatory={{
        departmentStocks: {
          serialNumber: 1,
        },
        fromDepartment: 1,
        toDepartment: 1,
        fromSpeciality: 1,
        toSpeciality: 1,
      }}
      computations={{
        children: {
          departmentStocks: {
            self: {
              itemAutofill: {
                compute: (item) => ({
                  set: {
                    assetStatus: item?.serialNumber?.assetStatus,
                  },
                }),
                onChange: ['serialNumber'],
              },
            },
          },
        },
        self: {
          fromDepartment: {
            compute: () => ({
              set: {
                fromSpeciality: null,
              },
            }),
            onChange: ['fromDepartment'],
          },
          fromSpeciality: {
            compute: () => ({
              set: {
                departmentStocks: [{ _id: getNewId() }],
                toSpeciality: null,
              },
            }),
            onChange: ['fromSpeciality'],
          },
          toDepartment: {
            compute: () => ({
              set: {
                toSpeciality: null,
              },
            }),
            onChange: ['toDepartment'],
          },
        },
      }}
      submitMessage={'asset.messages.transferRequest'.getLabel()}
      {...props}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'asset.title.transferDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'fromDepartment',
                    variant: 'filled',
                    label: 'asset.labels.fromDepartment'.getLabel(),
                    placeholder: 'asset.labels.fromDepartment'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput(
                    {
                      field: 'fromSpeciality',
                      variant: 'filled',
                      label: 'asset.labels.fromSpeciality'.getLabel(),
                      placeholder: 'asset.placeholders.fromSpeciality'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      mandatory: true,
                    },
                    {
                      addOnFilter: ({ data }) => ({
                        department: { _id: data?.fromDepartment?._id },
                      }),
                    },
                  ),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'toDepartment',
                    variant: 'filled',
                    label: 'asset.labels.toDepartment'.getLabel(),
                    placeholder: 'asset.placeholders.toDepartment'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput(
                    {
                      field: 'toSpeciality',
                      variant: 'filled',
                      label: 'asset.labels.toSpeciality'.getLabel(),
                      placeholder: 'asset.placeholders.toSpeciality'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      mandatory: true,
                    },
                    {
                      addOnFilter: ({ data }) => ({
                        department: { _id: data?.toDepartment?._id },
                        _id: { $ne: data?.fromSpeciality?._id },
                      }),
                    },
                  ),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'departmentStocks',
                    fieldType: 'nested',
                    Component: NestedTable,
                    label: 'asset.title.itemDetails'.getLabel(),
                    componentProps: {
                      maxHeight: 400,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'asset.labels.addRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: true,
                        columns: [
                          searchInput(
                            {
                              field: 'serialNumber',
                              variant: 'filled',
                              header: 'asset.labels.serialNo'.getLabel(),
                              label: 'asset.labels.serialNo'.getLabel(),
                              placeholder: 'asset.placeholders.serialNo'.getLabel(),
                              keyField: 'serialNumber',
                              valueField: 'serialNumber',
                              suggestionField: 'serialNumber',
                              model: MODELS.ASSET,
                              query: 'assetInventoryDataList',
                              width: 400,
                              mandatory: true,
                              disabled: ({ data: { _parent: { fromSpeciality } } }) => {
                                if (!fromSpeciality?._id) return true;
                                return false;
                              },
                            },
                            {
                              addOnFilter: ({
                                data:
                                  { _parent: { departmentStocks = [], fromSpeciality } = {} },
                              } = {}) => {
                                const itemIds = departmentStocks?.filter(
                                  ({ serialNumber }) => serialNumber?._id,
                                ).map(({ serialNumber }) => serialNumber?._id);
                                return {
                                  _id: { $nin: itemIds },
                                  speciality: { _id: fromSpeciality?._id },
                                  item: { _id: item?._id },
                                  assetStatus: assetStatuses.ISSUED,
                                };
                              },
                            },
                          ),
                          {
                            type: 'text',
                            label: 'asset.headers.status'.getLabel(),
                            header: 'asset.headers.status'.getLabel(),
                            variant: 'filled',
                            disabled: true,
                            field: 'assetStatus',
                            width: 400,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default TransferRequestModalAsset;
