import { MODELS } from '../Lib/constants';
export const EditTest = ({ navigation }) => ({
  query: {
    model: MODELS.LAB_TEST,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.LAB_TEST,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.LAB_TEST,
});

export const testTypeListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataListForTable',
    addOnFilter: filter,
  },
  model: MODELS.LAB_TEST,
});
