import React from 'react';
import {
  View,
  Text,
  Image,
  FormField,
  LargeButton,
  Toast,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import ListActionModal from '../../Components/ListActionModal';

import { importFile } from '../../AppServices';
import { bulkUploadIcon, downloadIcon } from '../../images';
import vars from '../../theme/vars';
import { Action } from '../../app-components/action/Action';
import { ROUTES } from '../../Lib/constants';
import Anchor from '../../app-components/Anchor';

const ImportSalaryComponents = (props) => {
  const { navigation: { state: { params } = {} } = {} } = props;

  return (
    <View>
      <Action
        style={{ alignItems: 'flex-end' }}
        action={{
          type: 'link',
          view: 'importList',
          link: () => ({
            view: ROUTES.importList.name,
            modal: true,
            params: {
              addOnFilter: {
                model: 'EmployeeSalaryComponents',
              },
            },
            modalProps: {
              autoHide: true,
              width: 1200,
              height: 525,
            },
          }),
        }}
        {...props}
      >
        <Action
          style={{
            position: 'static',
            marginLeft: 15,
            ...vars.headings.h13,
            color: vars.colors.secondary.color2,
          }}
        >
          {'bulkUploadModal.labels.viewImportHistory'.getLabel()}
        </Action>
      </Action>
      <View style={{ marginTop: 24 }}>
        <Text style={{ ...vars.headings.h8 }}>
          {'bulkUploadModal.labels.step1'.getLabel()}
        </Text>
      </View>
      <View
        style={{
          marginTop: 4,
          height: 14,
          whiteSpace: 'noWrap',
        }}
      >
        <Text
          style={{
            ...vars.headings.h13,
            color: vars.neutrals.color3,
          }}
        >
          {'bulkUploadModal.labels.step2'.getLabel()}
        </Text>
      </View>
      <View style={{ alignItems: 'end', marginTop: 10 }}>
        <Image source={downloadIcon} style={{ position: 'absolute' }} />
        <View
          style={{
            position: 'static',
            marginLeft: 15,
            ...vars.headings.h13,
            color: vars.colors.secondary.color2,
          }}
        >
          <Anchor
            href={`${`${window.location.protocol}//${window.location.host}`}/${params.fileName}`}
            download
            label={'bulkUploadModal.labels.downloadTemplate'.getLabel()}
          >
            {'bulkUploadModal.labels.downloadTemplate'.getLabel()}
          </Anchor>
        </View>
      </View>
      <View style={{ marginTop: 25 }}>
        <Text style={{ ...vars.headings.h8 }}>
          {'bulkUploadModal.labels.step3'.getLabel()}
        </Text>
      </View>
      <View
        style={{
          marginTop: 4,
          height: 14,
          whiteSpace: 'noWrap',
        }}
      >
        <Text
          style={{
            ...vars.headings.h13,
            color: vars.neutrals.color3,
          }}
        >
          {'bulkUploadModal.labels.step4'.getLabel()}
        </Text>
      </View>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        scrollable={false}
        onSubmit={
          importFile({
            model: 'ImportHistoryLogs',
            id: 'importSalaryComponentsInCompensationStructure',
          })
        }
        submitNext={() => {
          Toast.show({
            message: 'Success',
            description: 'Upload request initiated successfully.\n To view status of your request please check import history logs',
            type: 'success',
            position: 'top',
            direction: 'right',
          });
        }}
        defaultValues={{
          model: 'employees',
        }}
      >
        {({ form_context: formContext }) => (
          <>
            <View
              style={{
                marginTop: 48,
                alignItems: 'center',
                flex: 1,
              }}
            >
              <FormField
                field="file"
                placeholder="File"
                label="File"
                type="file"
              />
            </View>
            <View
              style={{
                marginTop: 24,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LargeButton
                label="Upload"
                onClick={formContext.handleSubmit}
                width={109}
                height={40}
                borderRadius={4}
              />
            </View>
          </>
        )}
      </Form>
    </View>
  );
};

const BulkUploadModal = (props) => (
  <ListActionModal
    icon={bulkUploadIcon}
    title="Add Components in Employee compensation structure"
    description="Follow the two-step process for uploading massive amount of data."
    content={<ImportSalaryComponents {...props} />}
    {...props}
  />
);
export default BulkUploadModal;
