import React, { useCallback } from 'react';

import {
  FormField, LargeButton, Toast, View, Text,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import { downloadData } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import { MODELS } from '../../Lib/constants';
import vars from '../../theme/vars';

const GenerateSalaryStatementModal = (props) => {
  const {
    navigation,
  } = props;

  const handleOnSubmitGenerateSalary = useCallback(async ({ data }) => {
    const {
      start_month_id, start_year_id, end_month_id, end_year_id,
    } = data;
    if ((start_year_id.name === end_year_id.name && start_month_id.key < 4 && end_month_id.key > 3) || (start_year_id.name !== end_year_id.name && start_month_id.key > 3 && end_month_id.key > 3)) {
      throw new Error('Please select valid Finanical Year.');
    }
    await downloadData({
      service: {
        id: 'salaryStatementExcelInvoke',
        paramValue: { ...data, employee_id: navigation?.state?.params.employee_id },
      },
    });

    Toast.show({
      message: 'Success',
      description: 'Salary Statement has been generated successfully',
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });

    navigation.pop();
  }, [navigation]);

  return (
    <ListActionModal
      icon={history}
      title="Generate Salary Statement"
      description="This action will download salary statement for selected range of month and year"
      content={(
        <Form
          onSubmit={handleOnSubmitGenerateSalary}
          mandatory={{
            start_month_id: 1,
            start_year_id: 1,
            end_month_id: 1,
            end_year_id: 1,
          }}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          defaultValues={() => ({
            month_id: props?.navigation?.state?.params?.month_id?.value,
            year_id: props?.navigation?.state?.params?.year_id?.value,
          })}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 18,
                      marginTop: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text>
                      FROM
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'start_month_id',
                          label: 'Select Start Month',
                          placeholder: 'Select Start Month',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.MONTHS,
                          query: 'MonthTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'start_year_id',
                          label: 'Select Start Year',
                          placeholder: 'Select Start Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      fontSize: 18,
                      marginTop: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text>
                      TO
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'end_month_id',
                          label: 'Select End Month',
                          placeholder: 'Select End Month',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.MONTHS,
                          query: 'MonthTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'end_year_id',
                          label: 'Select End Year',
                          placeholder: 'Select End Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Generate"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </View>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default GenerateSalaryStatementModal;
