import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MODELS, modules } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { Form } from '../../../../app-components/form/Form';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';

const AddCategory = (props) => (
  <Form
    {...props}
    type="standard"
    closeView={1}
    onSubmit={submit({
      model: MODELS.PHARMACY_CATEGORY,
    })}
    reloadEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
    defaultValues={() => ({
      isContractual: false,
      isActive: true,
      module: modules.purchase,
    })}
    uniqueValidationMessage={'purchase.message.categoryAlreadyExists'.getLabel()}
    submitMessage={'purchase.message.categoryAddedSuccessfully'.getLabel()}
    mandatory={{
      name: 1,
      description: 1,
    }}
    validations={{
      name: ({ data }) => validateInputLength(data?.name, 2, 26),
      description: ({ data }) => validateInputLength(data?.description, 0, 200),
    }}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'purchase.labels.category'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'purchase.labels.description'.getLabel(),
                  minHeight: 100,
                },
                {
                  type: 'toggleSwitch',
                  field: 'isContractual',
                  label: 'purchase.labels.contractual'.getLabel(),
                  container: 'topLabel',
                  offValueLabel: 'Non-Contractual',
                  onValueLabel: 'Contractual',
                  tintColor: vars.colors.secondary.color2,
                  activeTintColor: vars.colors.secondary.color2,
                },
                {
                  type: 'toggleSwitch',
                  field: 'isActive',
                  label: 'purchase.labels.status'.getLabel(),
                  container: 'topLabel',
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default AddCategory;
