
import React from 'react';

import {
  Text,
  View,
  StandardButton,
  TouchableOpacity,
  Image,
} from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants';
import {
  breadCrumbBack,
  breadcrumbSeperator,
} from '../../../../../../../../images';

const tableItemHeading = [
  {
    heading: 'Time',
    paddingLeft: 15,
  },
  {
    heading: 'Pulse',
    subHeading: ['PR',
      'Rhythm',
      'BP/SD',
      'RR',
      'SPO2',
      'Temperature',
      'CVP',
      'Ibp'],
  },
  {
    heading: 'Respiratory System',
    subHeading: [
      'Mode',
      'TB/PI',
      'Rate',
      'Fio2',
      'Ppeak',
      'Pplat',
      'Peep',
      'Psupp',
    ],
  },
  {
    heading: 'Drug Infusion',
    subHeading: [
      'Dopa',
      'Norad',
      'Ntg',
      'Mida Zolam',
      'Fent Anyl',
      'Insulin',
      'Nebulizer',
    ],
  },
  {
    heading: 'Other Events',
    subHeading: [
      '1',
      '2',
      '3',
      'Suction Positioning',
    ],
  },
];
const tableHeadingWidth = [
  150, 1200, 1200, 1050, 600];

const heading = (item, widthHeader) => (
  <View style={{
    width: widthHeader,
    display: 'flex',
    flexFlow: 'wrap',
    flexDirection: 'row',
    backgroundColor: '#EFF3FB',
  }}
  >
    <Text
      style={{
        width: widthHeader,
        display: 'flex',
        flexFlow: 'wrap',
        marginBottom: 15,
        marginTop: 15,
        fontSize: 15,
        paddingLeft: item.paddingLeft || 0,
      }}
    >
      {item.heading}
    </Text>
    { item.subHeading ? item.subHeading.map((data, index) => (
      <Text
        key={index}
        style={{
          width: 150,
          display: 'flex',
          lineHeight: 4,
          marginBottom: 12,
          flexFlow: 'wrap',
          paddingBottom: 10,
        }}
      >
        {data}
      </Text>
    )) : (
      <Text
        style={{
          width: 150,
          display: 'flex',
          flexFlow: 'wrap',
          lineHeight: 4,
          marginBottom: 12,
        }}
      >
        {'  '}
      </Text>
    )}
  </View>
);

const tableItems = {
  lg: {
    columns: [
      {
        type: 'date',
        format: 'HH:mm A',
        width: 150,
        field: 'monitoringDate',
      },
      {
        type: 'text',
        width: 150,
        field: 'pr',

      },
      {
        type: 'text',
        width: 150,
        field: 'rhythm',
      },
      {
        type: 'text',
        width: 150,
        field: 'bpsd',
      },
      {
        type: 'text',
        width: 150,
        field: 'rr',
      },
      {
        type: 'text',
        width: 150,
        field: 'spo2',
      },
      {
        type: 'text',
        width: 150,
        field: 'temp',
      },
      {
        type: 'text',
        width: 150,
        field: 'cvp',
      },
      {
        type: 'text',
        width: 150,
        field: 'ibp',
      },
      {
        type: 'text',
        width: 150,
        field: 'mode',
      },
      {
        type: 'text',
        width: 150,
        field: 'tbpi',
      },
      {
        type: 'text',
        width: 150,
        field: 'rate',
      },
      {
        type: 'text',
        width: 150,
        field: 'fio2',
      },
      {
        type: 'text',
        width: 150,
        field: 'ppeak',
      },
      {
        type: 'text',
        width: 150,
        field: 'pplat',
      },
      {
        type: 'text',
        width: 150,
        field: 'peep',
      },
      {
        type: 'text',
        width: 150,
        field: 'psupp',
      },
      {
        type: 'text',
        width: 150,
        field: 'dopa',
      },
      {
        type: 'text',
        width: 150,
        field: 'norad',
      },
      {
        type: 'text',
        width: 150,
        field: 'ntg',
      },
      {
        type: 'text',
        width: 150,
        field: 'midaZolam',
      },
      {
        type: 'text',
        width: 150,
        field: 'fentAnyl',
      },
      {
        type: 'text',
        width: 150,
        field: 'insulin',
      },
      {
        type: 'text',
        width: 150,
        field: 'nebulizer',
      },
      {
        type: 'text',
        width: 150,
        field: 'eventOne',
      },
      {
        type: 'text',
        width: 150,
        field: 'eventTwo',
      },
      {
        type: 'text',
        width: 150,
        field: 'eventThree',
      },
      {
        type: 'text',
        width: 150,
        field: 'suctionPositioning',
      },

    ],
  },
};

const ViewMonitoringChart = (props) => {
  const { navigation: { state: { params } } } = props;
  const { navigation } = props;
  return (
    <Table
      {...props}
      scrollable
      renderHeaderRow={tableItemHeading.map(
        (item, index) => heading(item, tableHeadingWidth[index]),
      )}
      header={() => ({
        leftAction: {
          render: () => (
            <View
              style={{
                height: 64,
                flexDirection: 'row',
                margin: -23,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.replace({
                    view: ROUTES.monitoringChart.name,
                    params: {
                      patient: params?.patient,
                    },
                  });
                }}
              >
                <Image
                  source={breadCrumbBack}
                  style={{
                    margin: '16px',
                  }}
                />
              </TouchableOpacity>
              <Text
                style={
                  {
                    margin: '12px 0px 0px 3px',
                    textAlign: 'center',
                    color: '#307343',
                  }
                }
              >
                Monitoring Chart
                <Image
                  source={breadcrumbSeperator}
                  style={{ margin: 10 }}
                />
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  padding: '13px 0px 0px 0px',
                  fontSize: '18px',
                  color: '#333333',
                }}
              >
                {params.selectedDate}
              </Text>
            </View>
          ),
        },
        actions: [

          {
            type: 'link',
            style: {
              justifyContent: 'space-between',
            },
            render: () => (
              <StandardButton
                label="Log"
              />
            ),
            link: () => ({
              view: ROUTES.logMonitoringChart.name,
              params: { ...params },
              push: true,
              modal: true,
              modalProps: {
                autoHide: true,
                maxHeight: '100%',
                height: 547,
              },
            }),
          },
        ],
      })}
      uri={() => ({
        query: {
          id: 'monitoringChartList',
          addOnFilter: {
            admissionID: { _id: params?.item?.admissionID?._id },
            speciality: params?.dataParams?.filters?.specialityFilter,
            monitoringDate: {
              $eq: new Date(
                new Date(
                  new Date(
                    new Date(
                      new Date(params?.selectedDate).setUTCHours(0),
                    ).setUTCMinutes(0),
                  ).setUTCSeconds(0),
                ).setUTCMilliseconds(0),
              ),
            },
          },
        },
        model: MODELS.MONITORING_CHART,
      })}
      {...tableItemHeading}
      {...tableItems}
      reloadEvent={`reload${MODELS.MONITORING_CHART}`}
      reloadOnChangeEvent={`reload${MODELS.MONITORING_CHART}`}
    />
  );
};

const ViewMonitoringChartModel = (props) => (<ViewMonitoringChart {...props} />);
export default ViewMonitoringChartModel;

