import React from 'react';

import {
  StandardButton,
} from '../../../../../../../app-components';
import {
  MODELS,
  ROUTES,
} from '../../../../../../../Lib/constants';
import { viewPsychologicalTreatmentsUri } from '../../../../../../../Queries/psychologicalTreatment';
import { Table } from '../../../../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="Treatment"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      type: 'link',
      link: ({ navigation: { state: { params = {} } } }) => ({
        view: ROUTES.logPsychologicalTreatment.name,
        params: { ...params },
      }),
      render: () => (
        <StandardButton
          label="Log Treatment"
        />
      ),
    },
  ],
});

const tableItems = {
  header,
  md: {
    columns: [
      {
        header: 'Date',
        type: 'date',
        field: 'loggedDate',
        format: 'DD MMM YYYY',
      },
      {
        header: 'Treatment',
        type: 'text',
        field: 'treatment',
      },
    ],
  },
};

const PsychologicalTreatment = (props) => (
  <Table
    {...props}
    uri={viewPsychologicalTreatmentsUri}
    {...tableItems}
    reloadEvent={`reload${MODELS.IPD_PSYCHOLOGICAL_TREATMENT}`}
    type="link"
    action={{
      type: 'link',
      link: (data) => ({
        view: ROUTES.viewPsychologicalTreatment.name,
        params: {
          addOnFilter: {
            _id: data.item._id,
          },
        },
      }),
    }}
  />
);
export default PsychologicalTreatment;
