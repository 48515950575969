import React, { useCallback, useEffect, useState } from 'react';

import { MODELS } from '../../../Lib/constants';
import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../theme/vars';
import { EditEmployeeDesignation } from '../../../Queries/employeeDesignation';
import { fetch, submit } from '../../../AppServices';
import { FormHoc, Text, View } from '../../../app-components';
import { autoSuggestInput } from '../../../autoSuggestions';
import { validateInputLength } from '../../../Lib/helpers';

const ViewDesignation = (props) => {
  const [allocatedSanctionSeatData, setSanctionSeat] = useState([]);

  const fetchAllocatedData = useCallback(async () => {
    const allocatedSeats = await fetch({
      uri: {
        props: {
          query: {
            id: 'sanctionSeatData',
            addOnFilter: {
              held_position: { _id: props?.navigation?.state?.params?.item?._id },
            },
          },
          model: MODELS.EMPLOYEE,
        },
      },
    });
    setSanctionSeat(allocatedSeats?.data);
  }, []);

  useEffect(() => {
    fetchAllocatedData();
  }, []);

  const ViewDesignationForm = FormHoc({
    type: 'standard',
    closeView: 1,
    uri: EditEmployeeDesignation,
    onSubmit: submit({
      model: MODELS.DESIGNATION,
    }),
    validations: {
      name: ({ data }) => validateInputLength(data?.name, 2, 20),
      'sanctionSeatsCategory.sanction_seats_category': ({ data }) => {
        let sumOfSanctionSeats = 0;
        const tempData = data?.sanctionSeatsCategory?.map(
          (value) => { sumOfSanctionSeats += value.sanction_seats_category; },
        );
        if (sumOfSanctionSeats > data.sanctionSeats) {
          throw new Error('Category wise sanction seats is greater than sanction seats');
        }
      },
    },
    // reloadEvent: `reload${MODELS.DESIGNATION}`,
    // uri: EditPharmacyTransferRequestStatus({ ...props, queryId }),
    lg: {
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Category Name',
                  editable: true,
                },
                {
                  type: 'number',
                  field: 'sanctionSeats',
                  label: 'Sanction Seats',
                  editable: true,
                },
                {
                  type: 'text',
                  field: 'description',
                  label: 'Description',
                  editable: true,
                },
                {
                  type: 'toggleSwitch',
                  field: 'isActive',
                  label: 'Status',
                  editable: true,
                  container: 'topLabel',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'Sanction Seats',
          labelStyle: {
            ...vars.headings.h10,
            color: vars.colors.grey.color2,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {

                  field: 'sanctionSeatsCategory',
                  addInBottom: true,
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [{
                        render: () => (
                          <View style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 5,
                            width: 220,
                            height: 40,
                            borderColor: vars.colors.secondary.color2,
                          }}
                          >
                            <Text style={{
                              ...vars.headings.h9,
                              color: vars.colors.secondary.color2,
                            }}
                            >
                              {`+ ${'Add New Category'}`}
                            </Text>
                          </View>
                        ),
                        onPress: addRow,
                      }],
                    }),
                    skipTableHeaderOnNoData: false,
                    columnsPerRow: 4,
                    listProps: {
                      hideColumnHeader: false,
                      headerRowColumnTextStyle: {
                        ...vars.headings.h10,
                        color: vars.colors.grey.color3,
                        numberOfLines: 1,
                      },
                      columns: [
                        autoSuggestInput({
                          type: 'autoSuggest',
                          field: 'category',
                          label: 'Category',
                          placeholder: 'Category',
                          keyField: 'name',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEE_CATEGORY,
                          query: 'masterDataList',
                          mandatory: true,
                          variant: 'filled',
                          width: 350,
                          header: 'Category',
                        }),
                        {
                          type: 'number',
                          field: 'sanction_seats_category',
                          header: 'Sanction Seats',
                          width: 350,
                          variant: 'filled',
                          editable: true,
                        },
                        {
                          type: 'number',
                          // field: 'allocated_seats_category',
                          value: ({ item }) => {
                            const sanctionCategoryData = allocatedSanctionSeatData?.filter((value) => value?._children?.[0]?.category?._id === item?.category?._id);
                            if (sanctionCategoryData?.length) return sanctionCategoryData?.[0]?.count;
                            return '0';
                          },
                          header: 'Allocated Seats',
                          width: 350,
                          variant: 'filled',
                          editable: false,
                        },
                        {
                          type: 'number',
                          value: ({ item }) => {
                            const sanctionCategoryData = allocatedSanctionSeatData?.filter((value) => (value?._children?.[0]?.category?._id === item?.category?._id));
                            if (sanctionCategoryData.length) {
                              const vacantSeats = item?.sanction_seats_category - sanctionCategoryData?.[0]?.count;
                              return vacantSeats === 0 ? '0' : vacantSeats;
                            }
                            return item?.sanction_seats_category;
                          },
                          header: 'Vacant Seats',
                          width: 350,
                          variant: 'filled',
                          editable: false,

                        },
                        // NestedAction(),
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  });

  return <ViewDesignationForm {...props} />;
};

export default ViewDesignation;
