import React from 'react';
import { FormField, Image, View } from '../../app-components';
import { autoSuggestInput } from '../../autoSuggestions';
import { downArrow_blue as downArrowBlue } from '../../images';
import { relations } from '../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../Lib/helpers';
import vars from '../../theme/vars';

const RelationSelector = () => (
  <View
    style={{
      width: 20,
      justifyContent: 'center',
      alignItems: 'flex-start',
    }}
  >
    <Image
      source={downArrowBlue}
      style={{
        width: 11,
        height: 6,
      }}
    />
  </View>
);

const RelationInput = (props) => {
  const {
    relationFieldName = 'relation',
    relationNameFieldName = 'relationName',
    mandatory = false,
    editable = true,
    relationProps = {},
    relationNameProps = {},
    disabled = false,
  } = props;

  return (
    <View style={{
      flex: 1,
      flexDirection: 'row',
    }}
    >
      <View
        style={{
          width: 80,
        }}
      >
        <FormField
          {...autoSuggestInput({
            field: relationFieldName,
            type: 'autoSuggest',
            variant: 'filled',
            suggestionField: 'value',
            label: editable ? '' : 'Relation',
            valueField: 'value',
            showArrow: false,
            options: relations,
            renderSelector: RelationSelector,
            editable,
            disabled,
            dropdownStyle: {
              width: 125,
              height: 140,
              backgroundColor: vars.colors.white,
            },
            ...relationProps,
          })}
        />
      </View>
      <View
        style={{
          flex: 1,
        }}
      >
        <FormField
          label="Relation Name"
          field={relationNameFieldName}
          placeholder="Relation Name"
          type="text"
          variant="filled"
          mandatory={mandatory}
          editable={editable}
          disabled={disabled}
          modifyValueOnChange={({ prevValue, value }) => {
            if (validateAlphabetsWithSpaceOnly(value)) {
              return prevValue;
            }
            return value.replace(/ {2,}/g, ' ');
          }}
          {...relationNameProps}
        />
      </View>
    </View>
  );
};

export default RelationInput;
