import React from 'react';

import { NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { editIpdOrderUri } from '../../../../../../../../Queries/ipdOrders';
import { Form } from '../../../../../../../../app-components/form/Form';
import { Text } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';

const ViewOrderDetail = (props) => {
  const { navigation } = props;
  return (
    <ListActionModal
      {...props}
      title="Orders"
      description="Order of patient for "
      content={(
        <Form
          uri={editIpdOrderUri({ navigation })}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'dateOfOperation',
                            label: 'Date of Operation',
                            format: 'DD MM YYYY',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'operation.name',
                            label: 'Name of Operation'.getLabel(),
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'surgeonName.name',
                            label: 'Name of Surgeon',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> PRE OP-ORDERS  </Text>
                            ),
                          },
                          {
                            type: 'text',
                            field: 'preOpOrders',
                            editable: false,
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> POST OP-ORDERS  </Text>
                            ),
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> i. DOS </Text>
                            ),
                          },
                          {
                            type: 'text',
                            field: 'postOpDo',
                            editable: false,
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> ii. DONTS </Text>
                            ),
                          },
                          {
                            type: 'text',
                            field: 'postOpDonts',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {
                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowColumnTextStyle: {
                                  ...vars.headings.h10,
                                  color: vars.colors.grey.color3,
                                  numberOfLines: 1,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    field: 'drug',
                                    label: 'Drug',
                                    width: 250,
                                    editable: false,
                                    header: 'Drug',
                                  },
                                  {
                                    type: 'text',
                                    field: 'route',
                                    label: 'Route',
                                    width: 150,
                                    editable: false,
                                    header: 'Route',
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    label: 'Dose (mg)',
                                    width: 100,
                                    editable: false,
                                    header: 'Dose',
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    width: 200,
                                    editable: false,
                                    header: 'Frequency',
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Description',
                                    width: 312,
                                    editable: false,
                                    header: 'Description',
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const ViewDetail = (props) => (<ViewOrderDetail {...props} />);
export default ViewDetail;
