import React from 'react';
import { Image, TouchableOpacity } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import { editRecord } from '../../../../images';
import { MODELS, ROUTES } from '../../../../Lib/constants';

const tabListColumns = [
  { width: 20 },
  {
    header: 'pharmacy.labels.manufacturerName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'pharmacy.labels.address'.getLabel(),
    type: 'text',
    field: 'address',
    numberOfLines: 2,
    width: 500,
  },
  {
    header: 'pharmacy.labels.actions'.getLabel(),
    type: 'text',
    render: () => (
      <TouchableOpacity>
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </TouchableOpacity>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        modal: true,
        view: ROUTES.editPharmacyManufacturer.name,
        params: { item },
        modalProps: {
          autoHide: true,
          width: 404,
          height: 424,
        },
      },
    }),
  },
];

const ManufacturerList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;
  return (
    <Table
      {...restProps}
      reloadEvent={`reload ${MODELS.PHARMACY_MANUFACTURER}`}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      lg={{ columns: tableColumns }}
    />
  );
};
export default ManufacturerList;
