import React from 'react';

import { Form } from '../../../../app-components/form/Form';
import { searchInput } from '../../../../autoSuggestions';
import { MODELS, ROUTES } from '../../../../Lib/constants';

const NewContingentForm = (props) => {
  const { navigation, navigation: { state: { params: { item } } } } = props;
  return (
    <Form
      type="standard"
      {...props}
      onSubmit={({ data }) => navigation.push({
        view: ROUTES.cotingentVoucherPreview.name,
        params: { item: { formData: data, ...item } },
      })}
      defaultValues={{
        date: new Date(),
      }}
      validations={{
        amount: ({ data }) => {
          if (data?.amount && data?.amount < 1000000000) {
            return '';
          }
          return 'Amount should be between 1 and 1000000000';
        },
      }}
      mandatory={{
        head: 1,
        accountant: 1,
        subject: 1,
        nameOfTheFirm: 1,
        billNumber: 1,
        amount: 1,
        date: 1,

      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            editable: true,
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  searchInput({
                    suggestionField: 'name',
                    query: 'employeeLists',
                    searchField: 'name',
                    field: 'head',
                    model: MODELS.EMPLOYEE,
                    placeholder: 'Head',
                    variant: 'filled',
                    mandatory: true,
                  }),
                  searchInput({
                    suggestionField: 'name',
                    query: 'employeeLists',
                    searchField: 'name',
                    field: 'accountant',
                    model: MODELS.EMPLOYEE,
                    placeholder: 'Accountant',
                    variant: 'filled',
                    mandatory: true,
                  }),
                  {
                    type: 'text',
                    field: 'subject',
                    label: 'Subject',
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'nameOfTheFirm',
                    label: 'Name Of The Firm',
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'billNumber',
                    label: 'Bill Number',
                    mandatory: true,
                  },
                  {
                    type: 'date',
                    format: 'DD/MM/YYYY',
                    field: 'date',
                    label: 'Date',
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'amount',
                    label: 'Amount (Rs)',
                    mandatory: true,
                  },
                  {
                    type: 'file',
                    field: 'attachment',
                    label: 'Upload bill',
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default NewContingentForm;
