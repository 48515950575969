import { upperFirst } from 'lodash';
import React from 'react';
import { Text } from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { purchaseRequestListPharmacy } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';

const tabListColumns = (id) => [
  { width: 14 },
  {
    header: 'purchase.header.itemName'.getLabel(),
    type: 'text',
    field: 'purchaseRequestPharmacy.item.name',
  },
  {
    header: 'purchase.header.requestNumber'.getLabel(),
    type: 'text',
    field: 'purchaseRequestPharmacy.purchaseRequestNumber',
  },
  {
    header: 'purchase.header.dateRaised'.getLabel(),
    type: 'date',
    field: '_createdOn',
    sortable: true,
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    render: ({ item: { status = '' } }) => {
      let statusColor = '';
      if (status === 'raised') { statusColor = vars.colors.emphasis; } else if (status === 'Order Received') { statusColor = vars.colors.primary.color2; } else if (status === 'PS Rejected' || status === 'SO Rejected') { statusColor = vars.colors.error; } else { statusColor = vars.colors.warning; }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {upperFirst(status)}
        </Text>
      );
    },
  },
  {
    header: 'purchase.header.action'.getLabel(),
    type: 'openActions',
    openActions: [
      {
        render: () => (
          <Text style={{ ...vars.headings.h8, color: vars.colors.secondary.color2 }}>View</Text>
        ),
        type: 'link',
        link: ({ item }) => ({
          view: ROUTES.viewPurchaseRequestPharmacy.name,
          params: { item, queryId: id },
        }),
      },
    ],
  },
];

const PharmacyPurchaseRequestList = (props) => {
  const {
    tableColumns = tabListColumns,
    model,
    id,
    addOnFilter,
  } = props;

  return (
    <Table
      lg={{ columns: tableColumns(id) }}
      uri={purchaseRequestListPharmacy({ model, id, addOnFilter })}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPurchaseRequestPharmacy.name,
          params: { item, queryId: id },
        },
      })}
      {...props}
    />
  );
};
export default PharmacyPurchaseRequestList;
