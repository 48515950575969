/* eslint-disable max-len */
import React from 'react';
import { Table } from '../../../../app-components/table/Table';
import { AssetRepairHistoryUri } from '../../../../Queries/asset';

const tabListColumns = [
  { width: 14 },
  {
    type: 'date',
    header: 'asset.headers.dateOfEvent'.getLabel(),
    field: '_createdOn',
  },
  {
    type: 'date',
    header: 'asset.headers.returnDate'.getLabel(),
    field: '',
  },
  {
    type: 'text',
    header: 'asset.headers.reasonForRepair'.getLabel(),
    field: 'reasonForRepair',
  },
];

const AssetRepairHistory = (props) => {
  const { tableColumns = tabListColumns, item: { _id } } = props;

  return (
    <Table
      uri={() => AssetRepairHistoryUri({ filter: { asset: { _id } }, type: 'repair' })}
      lg={{ columns: tableColumns }}
      {...props}
    />
  );
};
export default AssetRepairHistory;
