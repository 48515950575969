import React from 'react';

import { MODELS, ROUTES } from '../../../Lib/constants';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 15 },
  {
    header: 'Name',
    type: 'text',
    field: 'name',
  },
  {
    header: 'Status',
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'Description',
    type: 'text',
    field: 'description',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.EMPLOYEE_CATEGORY}
    editFormId={ROUTES.EditEmployeeCategory.name}
    tableColumns={tableColumns}
    header={{
      title: 'Employee Category',
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.ViewEmployeeCategory.name,
        params: { item },
      },
    })}
  />
);
