import React from 'react';

import {
  FormField, save, View, Text,
} from '../../../../../../../app-components';
import { Form } from '../../../../../../../app-components/form/Form';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { MODELS } from '../../../../../../../Lib/constants';
import { validateInputLength } from '../../../../../../../Lib/helpers';
import vars from '../../../../../../../theme/vars';

const GeneralExamination = ({
  label, field, secondLabel, width, fieldWidth, marginLeft, data,

}) => (
  <View style={{
    display: 'flex', flexDirection: 'row', width: '82%', marginTop: 5,
  }}
  >
    <View style={{ flex: 1, marginLeft: marginLeft || 16 }}>
      <Text style={{
        width: 70,
        paddingLeft: 40,
        marginTop: 15,
        fontFamily: 'public_sans_regular',
        fontStyle: 'normal',
        //
        fontSize: 14,
        lineHeight: 14,
        display: 'flex',
        alignItems: 'center',
        // color: '#333333',
      }}
      >
        {' '}
        {label}
      </Text>
    </View>
    <View style={{
      width: 200, marginTop: 15,
    }}
    >
      <Text style={{
        width: 200,
        fontFamily: 'public_sans_regular',
        fontStyle: 'normal',
        fontSize: 14,
        lineHeight: 14,
        display: 'flex',
        alignItems: 'center',
      }}
      >
        {' '}
        {secondLabel}
      </Text>
    </View>
    <View style={{
      width: fieldWidth || width || 400,
    }}
    >
      <FormField
        style={{
          backgroundColor: 'white',
          width: width || 200,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,

        }}
        type="text"
        variant="filled"
        field={field}
      />
    </View>
  </View>
);

const DrugAllegry = ({
  field, label, width, textWidth,
}) => (
  <View style={{
    display: 'flex', flexDirection: 'row', width: '60%', marginTop: 10, marginLeft: 30,
  }}
  >
    <View style={{
      flex: 1, marginBottom: 10, width: width || 60,
    }}
    >
      <Text style={{
        width: width || 60,
        marginTop: 15,
        // marginBottom: 10,
        fontFamily: 'public_sans_regular',
        fontStyle: 'normal',
        marginLeft: 26,
        fontSize: 14,
        lineHeight: 14,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 30,
        // color: '#333333',
      }}
      >
        {' '}
        {label}
      </Text>
    </View>
    <View style={{ width: textWidth || 580, marginLeft: 40 }}>
      <FormField
        style={{
          backgroundColor: 'white',
          width: textWidth || 580,
          borderWidth: 1,
          // marginTop: 10,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
          //   marginRight: 100,
        }}
        type="text"
        field={field}
      />
    </View>
  </View>
);

const FamilyHistory = ({
  checkField, field, label, checkBoxWidth, width,
}) => (
  <View style={{
    display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, marginLeft: 30,
  }}
  >
    <View style={{
      width: checkBoxWidth || 250, marginTop: 20, marginLeft: 40,
    }}
    >
      <FormField
        style={{
          backgroundColor: 'white',
          width: checkBoxWidth || 250,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,

        }}
        type="checkBox"
        field={checkField}
        label={label}
      />
    </View>
    <View style={{ width: width || 350 }}>
      <FormField
        style={{
          backgroundColor: 'white',
          width: width || 350,
          borderWidth: 1,
          marginTop: 5,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
          //   marginRight: 100,
        }}
        type="text"
        variant="filled"
        disabled={(disableProps) => {
          const { data: { familyHistory = {} } = {} } = disableProps;
          const { data } = disableProps;
          const temp = checkField.includes('.') ? checkField.split('.') : checkField;
          const tempVar = Array.isArray(temp) ? !familyHistory[temp[1]] : !data[temp];
          return tempVar;
        }}
        field={field}
      />
    </View>
  </View>
);

const HOPIRow = ({
  field, durationField, treatmentField, label,
}) => (
  <View style={{
    display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, marginLeft: 30,
  }}
  >
    <View style={{ flex: 1, width: 350, marginLeft: 10 }}>
      <FormField
        style={{
          backgroundColor: 'white',
          width: 100,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
        type="checkBox"
        field={field}
        label={label}
      />
    </View>
    <View style={{ flex: 1, width: 60 }}>
      <Text
        style={{
          fontSize: 12,
          color: vars.colors.grey.color3,
          width: 70,
          marginTop: 5,
          textAlign: 'centre',
          ...vars.headings.h14,
          paddingLeft: 80,
        }}
      >
        Duration
      </Text>
    </View>
    <View style={{ flex: 1, width: 350 }}>
      <FormField
        style={{
          backgroundColor: 'white',
          width: 350,
          height: 35,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
        variant="filled"
        type="text"
        disabled={(disableProps) => {
          const fieldArray = field.split('.');
          const { data: { hopi = {} } = {} } = disableProps;
          const { data: { pastHistoryMedical = {} } = {} } = disableProps;
          return !hopi[fieldArray[1]] && !pastHistoryMedical[fieldArray[1]];
        }}
        field={durationField}
      />
    </View>
    <View style={{ flex: 1, width: 150, marginLeft: 100 }}>
      <Text
        style={{
          fontSize: 12,
          color: vars.colors.grey.color3,
          marginTop: 5,
          width: 150,
          ...vars.headings.h14,
        }}
      >
        Mention Treatment
      </Text>
    </View>
    <View style={{ flex: 1, width: 400 }}>
      <FormField
        style={{
          backgroundColor: 'white',
          height: 35,
          width: 400,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
        type="text"
        variant="filled"
        disabled={(disableProps) => {
          const fieldArray = field.split('.');
          const { data: { hopi = {} } = {} } = disableProps;
          const { data: { pastHistoryMedical = {} } = {} } = disableProps;
          return !hopi[fieldArray[1]] && !pastHistoryMedical[fieldArray[1]];
        }}
        field={treatmentField}
      />
    </View>
  </View>
);

const preAnestheticCheckup = (props) => (
  <Form
    {...props}
    closeView={1}
    header={
    { actions: [save()] }
  }
    type="standardShadow"
    uri={(uriProps) => ({
      query: {
        id: 'preAnestheticDataList',
        addOnFilter: {
          admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
        },
      },
      model: MODELS.PRE_ANESTHETIC_CHECKUP,
    })}
    onSubmit={submit({
      model: MODELS.PRE_ANESTHETIC_CHECKUP,
    })}
    defaultValues={(deafultProps) => ({
      admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
    })}
    bodyContainerStyle={{
      margin: 0,
      marginRight: '2%',
    }}
    // In case submodal are used
    // Start
    beforeSubmit={({ data }) => ({
      data,
      updates: data,
    })}
    deepDiff={false}
    // Finish
    saveOnChange
    {...props}
    afterSubmit={({ submitResult: { result } = {}, data }) => ({
      data: {
        ...data,
        _id: result._id,
      },
    })}
    // onSubmit={async (submitProps) => {
    //   const { updates, data } = submitProps;
    //   const newUpdate = {
    //     ...updates,
    //     admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
    //   };
    //   const submitAPI = submit({
    //     model: MODELS.PRE_ANESTHETIC_CHECKUP,
    //     ...submitProps,
    //     data,
    //     updates: newUpdate,
    //   });
    //   await submitAPI({ data, updates: newUpdate });
    // }}
    scrollable
    style={{ width: 'auto' }}
    computations={{
      self: {
        'hopi.isCoughSputum': {
          compute: (data) => {
            if (data?.hopi?.isCoughSputum === false) {
              return ({
                set: {
                  'hopi.coughSputumDuration': '',
                  'hopi.coughSputumTreatment': '',
                },
              });
            }
          },
          onChange: ['hopi.isCoughSputum'],
        },
        'hopi.isChestPainorTightness': {
          compute: (data) => {
            if (data?.hopi?.isChestPainorTightness === false) {
              return ({
                set: {
                  'hopi.chestPainorTightnessDuration': '',
                  'hopi.chestPainorTightnessTreatment': '',
                },
              });
            }
          },
          onChange: ['hopi.isChestPainorTightness'],
        },
        'hopi.isDyspnoea': {
          compute: (data) => {
            if (data?.hopi?.isDyspnoea === false) {
              return ({
                set: {
                  'hopi.dyspnoeaDuration': '',
                  'hopi.dyspnoeaTreatment': '',
                },
              });
            }
          },
          onChange: ['hopi.isDyspnoea'],
        },
        'hopi.isCyanosis': {
          compute: (data) => {
            if (data?.hopi?.isCyanosis === false) {
              return ({
                set: {
                  'hopi.CyanosisTreatment': '',
                  'hopi.CyanosisDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isCyanosis'],
        },
        'hopi.isPalpitation': {
          compute: (data) => {
            if (data?.hopi?.isPalpitation === false) {
              return ({
                set: {
                  'hopi.PalpitationTreatment': '',
                  'hopi.PalpitationDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isPalpitation'],
        },
        'hopi.isPedalOedema': {
          compute: (data) => {
            if (data?.hopi?.isPedalOedema === false) {
              return ({
                set: {
                  'hopi.PedalOedemaTreatment': '',
                  'hopi.PedalOedemaDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isPedalOedema'],
        },
        'hopi.isNauseaOrVomiting': {
          compute: (data) => {
            if (data?.hopi?.isNauseaOrVomiting === false) {
              return ({
                set: {
                  'hopi.NauseaOrVomitingTreatment': '',
                  'hopi.NauseaOrVomitingDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isNauseaOrVomiting'],
        },
        'hopi.isAbdominalPain': {
          compute: (data) => {
            if (data?.hopi?.isAbdominalPain === false) {
              return ({
                set: {
                  'hopi.AbdominalPainTreatment': '',
                  'hopi.AbdominalPainDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isAbdominalPain'],
        },
        'hopi.isUrineOutput': {
          compute: (data) => {
            if (data?.hopi?.isUrineOutput === false) {
              return ({
                set: {
                  'hopi.UrineOutputTreatment': '',
                  'hopi.UrineOutputDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isUrineOutput'],
        },
        'hopi.isFever': {
          compute: (data) => {
            if (data?.hopi?.isFever === false) {
              return ({
                set: {
                  'hopi.FeverTreatment': '',
                  'hopi.FeverDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isFever'],
        },
        'hopi.isBackache': {
          compute: (data) => {
            if (data?.hopi?.isBackache === false) {
              return ({
                set: {
                  'hopi.BackacheTreatment': '',
                  'hopi.BackacheDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isBackache'],
        },
        'hopi.isLOC': {
          compute: (data) => {
            if (data?.hopi?.isLOC === false) {
              return ({
                set: {
                  'hopi.LOCTreatment': '',
                  'hopi.LOCDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isLOC'],
        },
        'hopi.isConvulsion': {
          compute: (data) => {
            if (data?.hopi?.isConvulsion === false) {
              return ({
                set: {
                  'hopi.ConvulsionTreatment': '',
                  'hopi.ConvulsionDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isConvulsion'],
        },
        'hopi.isNumbness': {
          compute: (data) => {
            if (data?.hopi?.isNumbness === false) {
              return ({
                set: {
                  'hopi.NumbnessTreatment': '',
                  'hopi.NumbnessDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isNumbness'],
        },
        'hopi.isRunningNose': {
          compute: (data) => {
            if (data?.hopi?.isRunningNose === false) {
              return ({
                set: {
                  'hopi.RunningNoseTreatment': '',
                  'hopi.RunningNoseDuration': '',
                },
              });
            }
          },
          onChange: ['hopi.isRunningNose'],
        },
        'pastHistoryMedical.isDM': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isDM === false) {
              return ({
                set: {
                  'pastHistoryMedical.dmDuration': '',
                  'pastHistoryMedical.dmTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isDM'],
        },
        'pastHistoryMedical.isHTN': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isHTN === false) {
              return ({
                set: {
                  'pastHistoryMedical.htnDuration': '',
                  'pastHistoryMedical.htnTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isHTN'],
        },
        'pastHistoryMedical.isTuberculosis': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isTuberculosis === false) {
              return ({
                set: {
                  'pastHistoryMedical.tuberculosisDuration': '',
                  'pastHistoryMedical.tuberculosisTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isTuberculosis'],
        },
        'pastHistoryMedical.isJaundice': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isJaundice === false) {
              return ({
                set: {
                  'pastHistoryMedical.jaundiceDuration': '',
                  'pastHistoryMedical.jaundiceTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isJaundice'],
        },
        'pastHistoryMedical.isCAD': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isCAD === false) {
              return ({
                set: {
                  'pastHistoryMedical.cadDuration': '',
                  'pastHistoryMedical.cadTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isCAD'],
        },
        'pastHistoryMedical.isAsthma': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isAsthma === false) {
              return ({
                set: {
                  'pastHistoryMedical.asthmaDuration': '',
                  'pastHistoryMedical.asthmaTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isAsthma'],
        },
        'pastHistoryMedical.isSeizures': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isSeizures === false) {
              return ({
                set: {
                  'pastHistoryMedical.seizuresDuration': '',
                  'pastHistoryMedical.seizuresTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isSeizures'],
        },
        'pastHistoryMedical.isCovid': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isCovid === false) {
              return ({
                set: {
                  'pastHistoryMedical.covidDuration': '',
                  'pastHistoryMedical.covidTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isCovid'],
        },
        'pastHistoryMedical.isCOPD': {
          compute: (data) => {
            if (data?.pastHistoryMedical?.isCOPD === false) {
              return ({
                set: {
                  'pastHistoryMedical.copdDuration': '',
                  'pastHistoryMedical.copdTreatment': '',
                },
              });
            }
          },
          onChange: ['pastHistoryMedical.isCOPD'],
        },
        'familyHistory.isAnesthesiaRelatedComplications': {
          compute: (data) => {
            if (data?.familyHistory?.isAnesthesiaRelatedComplications === false) {
              return ({
                set: {
                  'familyHistory.anesthesiaRelatedComplications': '',
                },
              });
            }
          },
          onChange: ['familyHistory.isAnesthesiaRelatedComplications'],
        },
        'familyHistory.isBleedingTendency': {
          compute: (data) => {
            if (data?.familyHistory?.isBleedingTendency === false) {
              return ({
                set: {
                  'familyHistory.bleedingTendency': '',
                },
              });
            }
          },
          onChange: ['familyHistory.isBleedingTendency'],
        },
        'familyHistory.isTumour': {
          compute: (data) => {
            if (data?.familyHistory?.isTumour === false) {
              return ({
                set: {
                  'familyHistory.tumour': '',
                },
              });
            }
          },
          onChange: ['familyHistory.isTumour'],
        },
        'familyHistory.isHeartProblem': {
          compute: (data) => {
            if (data?.familyHistory?.isHeartProblem === false) {
              return ({
                set: {
                  'familyHistory.heartProblem': '',
                },
              });
            }
          },
          onChange: ['familyHistory.isHeartProblem'],
        },
        'familyHistory.isJointsArthritis': {
          compute: (data) => {
            if (data?.familyHistory?.isJointsArthritis === false) {
              return ({
                set: {
                  'familyHistory.jointsArthritis': '',
                },
              });
            }
          },
          onChange: ['familyHistory.isJointsArthritis'],
        },

        isObstreticLMP: {
          compute: (data) => {
            if (data?.isObstreticLMP === false) {
              return ({
                set: {
                  obstreticLMP: '',
                },
              });
            }
          },
          onChange: ['isObstreticLMP'],
        },
        isObstreticGPLA: {
          compute: (data) => {
            if (data?.isObstreticGPLA === false) {
              return ({
                set: {
                  obstreticGPLA: '',
                },
              });
            }
          },
          onChange: ['isObstreticGPLA'],
        },
        isObstreticOCPIntake: {
          compute: (data) => {
            if (data?.isObstreticOCPIntake === false) {
              return ({
                set: {
                  obstreticOCPIntake: '',
                },
              });
            }
          },
          onChange: ['isObstreticOCPIntake'],
        },
        isSpineExaminationDeformity: {
          compute: (data) => {
            if (data?.isSpineExaminationDeformity === 'No') {
              return ({
                set: {
                  spineExaminationDeformity: '',
                },
              });
            }
          },
          onChange: ['isSpineExaminationDeformity'],
        },
      },
    }}
    validations={{
      diagnosis: ({ data }) => validateInputLength(data?.diagnosis, 1, 150),
      procedure: ({ data }) => validateInputLength(data?.procedure, 1, 150),
      weight: ({ data }) => validateInputLength(data?.weight, 1, 3),
      presentingComplaints: ({ data }) => validateInputLength(data?.presentingComplaints, 1, 200),
      others: ({ data }) => validateInputLength(data?.pastHistoryMedical?.others, 0, 100),
      pastSurgicalHistory: ({ data }) => validateInputLength(data?.pastSurgicalHistory, 0, 200),
      pastHistorySurgicalComplaints: ({ data }) => validateInputLength(data?.pastHistorySurgicalComplaints, 0, 150),
      drinkingPackYear1: ({ data }) => validateInputLength(data?.personalHistory?.drinkingPackYear1, 0, 3),
      drinkingPackMonth1: ({ data }) => validateInputLength(data?.personalHistory?.drinkingPackMonth1, 0, 3),
      drinkingPackYear2: ({ data }) => validateInputLength(data?.personalHistory?.drinkingPackYear2, 0, 3),
      drinkingPackMonth2: ({ data }) => validateInputLength(data?.personalHistory?.drinkingPackMonth2, 0, 3),
      anesthesiaRelatedComplications: ({ data }) => validateInputLength(data?.familyHistory?.anesthesiaRelatedComplications, 1, 100),
      anesthesiaAnyHistoryIntubation: ({ data }) => validateInputLength(data?.anesthesiaAnyHistoryIntubation, 0, 100),
      anesthesiaAdverseReaction: ({ data }) => validateInputLength(data?.anesthesiaAdverseReaction, 0, 100),
      anesthesiaPONV: ({ data }) => validateInputLength(data?.anesthesiaPONV, 0, 100),
      medicationHistoryExamination: ({ data }) => validateInputLength(data?.medicationHistoryExamination, 0, 200),
      pr: ({ data }) => validateInputLength(data?.vitals?.pr, 0, 20),
      abnormal: ({ data }) => validateInputLength(data?.systematicExamination?.abnormal, 0, 50),
      addedSounds: ({ data }) => validateInputLength(data?.systematicExamination?.addedSounds, 0, 50),
      s1s2: ({ data }) => validateInputLength(data?.systematicExamination?.s1s2, 0, 50),
      murmursAndOthers: ({ data }) => validateInputLength(data?.systematicExamination?.murmursAndOthers, 0, 50),
      gcs: ({ data }) => validateInputLength(data?.systematicExamination?.gcs, 0, 3),
      sensoryMotor: ({ data }) => validateInputLength(data?.systematicExamination?.sensoryMotor, 0, 50),
      airwayMouthOpening: ({ data }) => validateInputLength(data?.airwayMouthOpening, 0, 50),
      airwayMMPS: ({ data }) => validateInputLength(data?.airwayMMPS, 0, 50),
      airwayEdentulous: ({ data }) => validateInputLength(data?.airwayEdentulous, 0, 50),
      airwayNeckMovement: ({ data }) => validateInputLength(data?.airwayNeckMovement, 0, 50),
      airwayOther: ({ data }) => validateInputLength(data?.airwayOther, 0, 50),
      spineExaminationLocalInfection: ({ data }) => validateInputLength(data?.spineExaminationLocalInfection, 0, 150),
      spineExaminationDeformity: ({ data }) => validateInputLength(data?.spineExaminationDeformity, 0, 150),
      advice: ({ data }) => validateInputLength(data?.advice, 0, 150),
      reference: ({ data }) => validateInputLength(data?.reference, 0, 150),
    }}
  >
    { ({ form_state: formState, form_context: formContext }) => {
      const { handleSubmit } = formContext;
      return (
        <View style={{
          display: 'flex', flexDirection: 'column',
        }}
        >
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 14 }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 350,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                {... autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'department',
                  label: 'Department Name',
                  placeholder: 'Department Name',
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.DEPARTMENTS,
                  query: 'masterDataList',
                  variant: 'filled',
                })}
              />
            </View>
          </View>

          {/*  Diagnosis */}
          <View style={{
            flex: 1, flexDirection: 'row', flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 16, marginTop: 10 }}>
              <Text style={{
                width: 1300,
                marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Diagnosis
              </Text>
            </View>
            <View style={{ flex: 1, marginLeft: 14, marginTop: 10 }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1500,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="diagnosis"
                variant="filled"
              />
            </View>
          </View>

          {/* Procedure */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 16, marginTop: 10 }}>
              <Text style={{
                width: 1200,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Procedure
              </Text>
            </View>
            <View style={{
              flex: 1, marginLeft: 14, marginTop: 10, width: 1700,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1500,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="procedure"
                variant="filled"
              />
            </View>
            <View style={{
              flex: 1, marginLeft: 16, marginTop: 20, flexWrap: 'wrap', flexFlow: 'row',
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 350,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="weight"
                label="Weight(kgs)"
                variant="filled"
              />
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 350,
                  marginLeft: 16,
                }}
                type="radioGroup"
                field="type"
                label="Type"
                container="topLabel"
                options={['Emergency', 'Elective']}
              />
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 350,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginRight: 190,
                  borderRadius: 5,
                }}
                type="date"
                field="date"
                variant="filled"
                label="Date"
              />
            </View>
            {/* <View style={{
              flex: 1, marginLeft: 14, marginTop: 14,
            }}
            />
            <View style={{ flex: 1, marginLeft: 20, marginTop: 14 }} /> */}
          </View>

          {/* Presenting complaints */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 10, flexWrap: 'wrap',
          }}
          >
            <View style={{ flex: 1, marginLeft: 16 }}>
              <Text style={{
                width: 1200,

                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                //
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
                // color: '#333333',
              }}
              >
                {' '}
                Presenting Complaints
              </Text>
            </View>
            <View style={{ flex: 1, marginLeft: 14, marginTop: 10 }}>
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1500,
                  borderWidth: 1,
                  height: 50,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="textArea"
                field="presentingComplaints"
                variant="filled"
              />
            </View>
          </View>

          {/* HOPI */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 16, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '70%',
            }}
            >
              <View style={{ flex: 1, marginLeft: 16 }}>
                <Text style={{
                  width: 1500,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  HOPI
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                <HOPIRow field="hopi.isCoughSputum" durationField="hopi.coughSputumDuration" treatmentField="hopi.coughSputumTreatment" label="Cough/Sputum" />
                <HOPIRow field="hopi.isChestPainorTightness" durationField="hopi.chestPainorTightnessDuration" treatmentField="hopi.chestPainorTightnessTreatment" label="Chest Pain/Tightness" />
                <HOPIRow field="hopi.isDyspnoea" durationField="hopi.dyspnoeaDuration" treatmentField="hopi.dyspnoeaTreatment" label="Dyspnoea" />
                <HOPIRow field="hopi.isCyanosis" durationField="hopi.CyanosisDuration" treatmentField="hopi.CyanosisTreatment" label="Cyanosis" />
                <HOPIRow field="hopi.isPalpitation" durationField="hopi.PalpitationDuration" treatmentField="hopi.PalpitationTreatment" label="Palpitation" />
                <HOPIRow field="hopi.isPedalOedema" durationField="hopi.PedalOedemaDuration" treatmentField="hopi.PedalOedemaTreatment" label="Pedal Oedema" />
                <HOPIRow field="hopi.isNauseaOrVomiting" durationField="hopi.NauseaOrVomitingDuration" treatmentField="hopi.NauseaOrVomitingTreatment" label="Nausea/Vomiting" />
                <HOPIRow field="hopi.isAbdominalPain" durationField="hopi.AbdominalPainDuration" treatmentField="hopi.AbdominalPainTreatment" label="Abdominal pain" />
                <HOPIRow field="hopi.isUrineOutput" durationField="hopi.UrineOutputDuration" treatmentField="hopi.UrineOutputTreatment" label="urine output" />
                <HOPIRow field="hopi.isFever" durationField="hopi.FeverDuration" treatmentField="hopi.FeverTreatment" label="Fever" />
                <HOPIRow field="hopi.isBackache" durationField="hopi.BackacheDuration" treatmentField="hopi.BackacheTreatment" label="Backache" />
                <HOPIRow field="hopi.isLOC" durationField="hopi.LOCDuration" treatmentField="hopi.LOCTreatment" label="LOC" />
                <HOPIRow field="hopi.isConvulsion" durationField="hopi.ConvulsionDuration" treatmentField="hopi.ConvulsionTreatment" label="Convulsions" />
                <HOPIRow field="hopi.isNumbness" durationField="hopi.NumbnessDuration" treatmentField="hopi.NumbnessTreatment" label="Numbness" />
                <HOPIRow field="hopi.isRunningNose" durationField="hopi.RunningNoseDuration" treatmentField="hopi.RunningNoseTreatment" label="Running Nose" />
                <HOPIRow field="hopi.others" durationField="" treatmentField="" label="hopi.others" />
              </View>
            </View>
          </View>

          {/* Past history */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '70%',
            }}
            >
              <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                <Text style={{
                  width: 1500,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  Past History
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                <Text style={{
                  width: 1500,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 20,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  i. Medical
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                <HOPIRow field="pastHistoryMedical.isDM" durationField="pastHistoryMedical.dmDuration" treatmentField="pastHistoryMedical.dmTreatment" label="DM" />
                <HOPIRow field="pastHistoryMedical.isHTN" durationField="pastHistoryMedical.htnDuration" treatmentField="pastHistoryMedical.htnTreatment" label="HTN" />
                <HOPIRow field="pastHistoryMedical.isTuberculosis" durationField="pastHistoryMedical.tuberculosisDuration" treatmentField="pastHistoryMedical.tuberculosisTreatment" label="Tuberculosis" />
                <HOPIRow field="pastHistoryMedical.isJaundice" durationField="pastHistoryMedical.jaundiceDuration" treatmentField="pastHistoryMedical.jaundiceTreatment" label=" Jaundice" />
                <HOPIRow field="pastHistoryMedical.isCAD" durationField="pastHistoryMedical.cadDuration" treatmentField="pastHistoryMedical.cadTreatment" label="CAD" />
                <HOPIRow field="pastHistoryMedical.isAsthma" durationField="pastHistoryMedical.asthmaDuration" treatmentField="pastHistoryMedical.asthmaTreatment" label=" Asthma" />
                <HOPIRow field="pastHistoryMedical.isSeizures" durationField="pastHistoryMedical.seizuresDuration" treatmentField="pastHistoryMedical.seizuresTreatment" label=" Seizures" />
                <HOPIRow field="pastHistoryMedical.isCovid" durationField="pastHistoryMedical.covidDuration" treatmentField="pastHistoryMedical.covidTreatment" label="Covid" />
                <HOPIRow field="pastHistoryMedical.isCOPD" durationField="pastHistoryMedical.copdDuration" treatmentField="pastHistoryMedical.copdTreatment" label="COPD" />
              </View>
              <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                <Text style={{
                  width: 1500,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 20,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  ii. Surgical
                </Text>
              </View>
              <View style={{
                flex: 1, marginLeft: 24, marginBottom: 10, width: 300,
              }}
              >
                <Text style={{
                  width: 300,
                  marginTop: 6,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 26,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  a. History of Operative Procedures
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 24, width: 1000 }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 1500,
                    borderWidth: 1,
                    height: 60,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="textArea"
                  field="pastSurgicalHistory"
                  variant="filled"
                />
              </View>
              <View style={{
                flex: 1, marginLeft: 24, marginBottom: 10, width: 300,
              }}
              >
                <Text style={{
                  width: 300,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 26,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  b.Complication(if Any)
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 24 }}>
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 1500,
                    borderWidth: 1,
                    height: 60,
                    marginTop: 10,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  type="textArea"
                  field="pastHistorySurgicalComplaints"
                  variant="filled"
                />
              </View>
            </View>
          </View>

          {/* Personal History */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '95%',
            }}
            >
              <View style={{
                marginLeft: 24, flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10,
              }}
              >
                <Text style={{
                  width: 650,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  Personal History
                </Text>
                <Text style={{
                  width: 650,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  Family History
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 600,
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    a.Appetite
                  </Text>
                </View>
                <View style={{ width: 300, marginRight: 70 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 300,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.appetite"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    b.Diet
                  </Text>
                </View>
                <View style={{ width: 300, marginRight: 70 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 300,
                      // marginTop: 10,
                    }}
                    type="radioGroup"
                    field="personalhistory.diet"
                    options={['Non Veg', 'veg', 'Mix']}
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 100,
                }}
                >
                  <Text style={{
                    width: 100,
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    c.Sleep
                  </Text>
                </View>
                <View style={{ width: 300, marginRight: 70 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 300,
                      borderWidth: 1,
                      // marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.sleep"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 500,
                }}
                >
                  <Text style={{
                    width: 500,
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    d. Addiction
                  </Text>
                </View>
                <View style={{ width: 500, marginLeft: 100 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 500,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="personalhistory.isSmoking"
                    label="Smoking"
                  />
                </View>

                <View style={{ width: 500, marginLeft: 100, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 500,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="personalhistory.isDrinking"
                    label="Drinking"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90, marginLeft: 100, paddingLeft: 40,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 14,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    borderColor: vars.colors.grey.color5,
                    fontStyle: 'normal',
                    // marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // paddingLeft: 50,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    i. Pack
                  </Text>
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Years
                  </Text>
                </View>
                <View style={{ width: 100 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 100,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.drinkingPackYear1"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    Months
                  </Text>
                </View>
                <View style={{ width: 100 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 100,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.drinkingPackMonth1"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90, marginLeft: 100, paddingLeft: 40,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    // marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // paddingLeft: 50,
                    color: '#333333',
                  }}
                  >
                    {' '}
                    ii. Pack
                  </Text>
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#333333',
                  }}
                  >
                    {' '}
                    Years
                  </Text>
                </View>
                <View style={{ width: 100 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 100,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.drinkingPackYear2"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 90,
                }}
                >
                  <Text style={{
                    width: 90,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#333333',
                  }}
                  >
                    {' '}
                    Months
                  </Text>
                </View>
                <View style={{ width: 100 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 100,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.drinkingPackMonth2"
                    variant="filled"
                  />
                </View>
                <View style={{ width: 500, marginLeft: 100, marginTop: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 500,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    type="checkBox"
                    field="personalhistory.isTobacco"
                    label="Tobacco"
                  />
                </View>

                <View style={{
                  width: 100, marginTop: 20, marginLeft: 100,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 100,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="personalhistory.isOtherDrugs"
                    label="Others"
                  />
                </View>
                <View style={{ width: 350 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="personalhistory.otherDrugs"
                    variant="filled"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 10, width: 40, marginLeft: 50,
                }}
                >
                  <Text style={{
                    width: 40,
                    marginTop: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    e.
                  </Text>
                </View>
                <View style={{ width: 400, marginTop: 10, marginRight: 95 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 450,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="personalhistory.motionSickness"
                    label="Motion Sickness"
                  />
                </View>
                <View style={{
                  flex: 1, marginBottom: 20, width: 80, marginLeft: 50,
                }}
                >
                  <Text style={{
                    width: 80,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    f. Others
                  </Text>
                </View>
                <View style={{ width: 350, marginTop: 10, marginRight: 90 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 350,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="text"
                    field="personalhistory.others"
                  />
                </View>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
                marginLeft: 16,
                marginBottom: 10,
                width: 600,
                height: 300,
              }}
              >
                <FamilyHistory label="Anesthesia-related Complications" field="familyHistory.anesthesiaRelatedComplications" checkField="familyHistory.isAnesthesiaRelatedComplications" />
                <FamilyHistory label="Bleeding Tendency" field="familyHistory.bleedingTendency" checkField="familyHistory.isBleedingTendency" />
                <FamilyHistory label="Tumour" field="familyHistory.tumour" checkField="familyHistory.isTumour" />
                <FamilyHistory label="Heart/Respiratory Problem" field="familyHistory.heartProblem" checkField="familyHistory.isHeartProblem" />
                <FamilyHistory label="Joints Arthritis" field="familyHistory.jointsArthritis" checkField="familyHistory.isJointsArthritis" />
                {/* <FamilyHistory label="Others" field="familyHistory.others" /> */}
              </View>
            </View>
          </View>

          {/* obstertic */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '70%',
            }}
            >
              <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                <Text style={{
                  width: 1500,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  Obstretics / Gynaecology History
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                <FamilyHistory label="LMP" field="obstreticLMP" checkField="isObstreticLMP" width={1000} checkBoxWidth={140} />
                <FamilyHistory label="G/P/L/A" field="obstreticGPLA" checkField="isObstreticGPLA" width={1000} checkBoxWidth={140} />
                <FamilyHistory label="OCP Intake" field="obstreticOCPIntake" checkField="isObstreticOCPIntake" width={1000} checkBoxWidth={140} />
              </View>
            </View>
          </View>

          {/* Anesthesia History */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '95%',
            }}
            >
              <View style={{
                marginLeft: 16,
                marginBottom: 10,
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 1700,
              }}
              >
                <Text style={{
                  width: '47%',
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  Anesthesia History
                </Text>
                <Text style={{
                  width: 700,
                  marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  Medication History
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: 740,
              }}
              >
                <View style={{
                  flex: 1, width: 60,
                }}
                >
                  <Text style={{
                    width: 20,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    a.
                  </Text>
                </View>
                <View style={{ width: 246, marginTop: 17 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 246,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="checkBox"
                    field="isAnesthesiaPreviousSurgery"
                    label="Previous Surgery And Anesthesia"
                  />
                </View>
                <View style={{ width: 380, marginLeft: 40 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 380,
                      marginTop: 10,
                    //   marginRight: 100,
                    }}
                    type="radioGroup"
                    field="anesthesiaPreviousSurgery"
                    disabled={(disableProps) => {
                      const { data: { isAnesthesiaPreviousSurgery = false } = {} } = disableProps;
                      return !isAnesthesiaPreviousSurgery;
                    }}
                    options={['GA', 'SAB', 'Epidural', 'CSE', 'Reginal Block', 'LA', 'MAC', 'TIVA']}
                  />
                </View>
                <View style={{ width: 380, marginLeft: 340 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 380,
                      borderWidth: 1,
                      // marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    variant="filled"
                    field="anesthesiaPreviousSurgeryText"
                    disabled={(disableProps) => {
                      const { data: { isAnesthesiaPreviousSurgery = false } = {} } = disableProps;
                      return !isAnesthesiaPreviousSurgery;
                    }}
                  />
                </View>
                <View style={{
                  width: 260,
                }}
                >
                  <Text style={{
                    width: 260,
                    marginTop: 14,
                    // marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    b. Any History of Difficult Intubation
                  </Text>
                </View>
                <View style={{ width: 380, marginLeft: 40 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 380,
                      borderWidth: 1,
                      // marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    variant="filled"
                    type="text"
                    field="anesthesiaAnyHistoryIntubation"
                  />
                </View>

                <View style={{
                  flex: 1, marginBottom: 5, width: 260,
                }}
                >
                  <Text style={{
                    width: 260,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    c. Adverse reaction to Anesthesia
                  </Text>
                </View>
                <View style={{ width: 380, marginLeft: 40 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 380,
                      borderWidth: 1,
                      // marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    variant="filled"
                    type="text"
                    field="anesthesiaAdverseReaction"
                  />
                </View>

                <View style={{
                  flex: 1, marginBottom: 10, width: 260,
                }}
                >
                  <Text style={{
                    width: 260,
                    marginTop: 15,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    d. PONV
                  </Text>
                </View>
                <View style={{ width: 380, marginLeft: 40 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 380,
                      borderWidth: 1,
                      // marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="anesthesiaPONV"
                  />
                </View>

              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
                marginLeft: 16,
                marginBottom: 10,
                width: 400,
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 660,
                }}
                >
                  <Text style={{
                    width: 660,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 34,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    a. Drug Allergies
                  </Text>
                </View>
                <DrugAllegry field="drugAllergies1" label="i" />
                <DrugAllegry field="drugAllergies2" label="ii" />
                <DrugAllegry field="drugAllergies3" label="iii" />
                <DrugAllegry field="drugAllergies4" label="iv" />
                <DrugAllegry field="drugAllergies5" label="v" />
                <View style={{
                  flex: 1, marginBottom: 10, width: 660,
                }}
                >
                  <Text style={{
                    width: 660,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 26,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    b.Examination
                  </Text>
                </View>
                <View style={{ width: 630, marginLeft: 40 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 630,
                      borderWidth: 1,
                      marginTop: 10,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    //   marginRight: 100,
                    }}
                    type="text"
                    field="medicationHistoryExamination"
                  />
                </View>
              </View>
            </View>
          </View>

          {/* general Examination */}
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap',
          }}
          >
            <View style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '95%',
            }}
            >
              <View style={{
                flex: 1, marginLeft: 16, marginBottom: 10, width: 1200,
              }}
              >
                <Text style={{
                  width: 1200,
                  // marginTop: 10,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  General Examination
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '44%',
              }}
              >
                <View style={{
                  flex: 1, marginBottom: 10, width: 150,
                }}
                >
                  <Text style={{
                    width: 150,
                    marginTop: 16,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 45,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    a. General Condition
                  </Text>
                </View>
                <View style={{
                  width: 500, marginLeft: 40,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 246,
                      // marginTop: 10,
                    }}
                    type="radioGroup"
                    field="generalCondition"
                    options={['Good', 'Fair', 'Sick']}
                  />
                </View>

                <View style={{
                  flex: 1, width: 70,
                }}
                >
                  <Text style={{
                    width: 70,
                    marginTop: 13,
                    // marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 45,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    b.
                  </Text>
                </View>
                <View style={{ width: 640 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 246,
                      // marginTop: 10,
                    //   marginRight: 100,
                    }}
                    type="radioGroup"
                    field="isConsious"
                    options={['Consious', 'UnConsious']}
                  />
                </View>

                <View style={{
                  flex: 1, width: 70,
                }}
                >
                  <Text style={{
                    width: 70,
                    marginTop: 13,
                    // marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 45,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    c.
                  </Text>
                </View>
                <View style={{ width: 640, marginBottom: 10 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 246,
                      // marginTop: 10,
                    //   marginRight: 100,
                    }}
                    type="radioGroup"
                    field="isOriented"
                    options={['Oriented', 'DisOriented']}
                  />
                </View>
                <View style={{
                  flex: 1, width: 70,
                }}
                >
                  <Text style={{
                    width: 70,
                    marginTop: 13,
                    // marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 45,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    d.
                  </Text>
                </View>
                <View style={{ width: 640 }}>
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 246,
                      // marginTop: 10,
                    //   marginRight: 100,
                    }}
                    type="radioGroup"
                    field="isFebrite"
                    options={['Febrile', 'Afebrile']}
                  />
                </View>

                <View style={{
                  marginBottom: 10, width: 600,
                }}
                >
                  <Text style={{
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    marginLeft: 45,
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 5,
                    // color: '#333333',
                  }}
                  >
                    {' '}
                    e. Vitals
                  </Text>
                </View>
                <DrugAllegry field="vitals.pr" label="i PR" textWidth={540} width={90} />
                <DrugAllegry field="vitals.bp" label="ii BP" textWidth={540} width={90} />
                <DrugAllegry field="vitals.spo2" label="iii SPO2" textWidth={540} width={90} />
                <DrugAllegry field="vitals.rr" label="iv RR" textWidth={540} width={90} />
                <DrugAllegry field="vitals.temp" label="v Temp" textWidth={540} width={90} />
                <DrugAllegry field="vitals.jbp" label="vi JBP" textWidth={540} width={90} />
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 0.8,
                marginLeft: 16,
                marginBottom: 10,
                // width: 600,
                height: 300,
              }}
              >
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    f.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isClubbing"
                    label="Clubbing"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    g.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isPallor"
                    label="Pallor"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    h.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isIcterus"
                    label="Icterus"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    i.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isCyanosis"
                    label="Cyanosis"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    j.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isLymphadenopathy"
                    label="Lymphadenopathy"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    paddingLeft: 40,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    //
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    k.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isEdema"
                    label="Edema"
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                  <Text style={{
                    width: 70,
                    marginTop: 20,
                    marginBottom: 10,
                    fontFamily: 'public_sans_regular',
                    fontStyle: 'normal',
                    fontSize: 14,
                    lineHeight: 14,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 40,
                  // color: '#333333',
                  }}
                  >
                    {' '}
                    j.
                  </Text>
                </View>
                <View style={{
                  width: 600, marginTop: 20,
                }}
                >
                  <FormField
                    style={{
                      backgroundColor: 'white',
                      width: 250,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,

                    }}
                    type="checkBox"
                    field="vitals.isIVP"
                    label="IVP"
                  />
                </View>
              </View>
            </View>
          </View>

          {/* Systematic Examination */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >

            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                // marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Systematic Examination
              </Text>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '41%',
              height: 400,
            }}
            >
              <View style={{
                flex: 1, width: 650,
              }}
              >
                <Text style={{
                  width: 600,
                  marginTop: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 45,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 5,
                  // color: '#333333',
                }}
                >
                  {' '}
                  i. R/S
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 16 }}>
                <Text style={{
                  width: 70,
                  paddingLeft: 40,
                  marginTop: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  a.
                </Text>
              </View>
              <View style={{
                width: 600, marginTop: 10,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 250,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,

                  }}
                  type="checkBox"
                  field="systematicExamination.isNVBS"
                  label="NVBS"
                />
              </View>
              <View style={{ flex: 1, marginLeft: 16 }}>
                <Text style={{
                  width: 70,
                  paddingLeft: 40,
                  marginTop: 5,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  b.
                </Text>
              </View>
              <View style={{
                width: 200,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 200,
                    borderWidth: 1,
                    marginTop: 5,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,

                  }}
                  type="checkBox"
                  field="systematicExamination.isAbnormal"
                  label="Abnormal"
                />
              </View>
              <View style={{
                width: 400,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 200,
                    marginTop: -10,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,

                  }}
                  type="text"
                  variant="filled"
                  field="systematicExamination.abnormal"
                />
              </View>
              <GeneralExamination label="c." secondLabel="Added Sounds" field="systematicExamination.addedSounds" />
              <View style={{
                flex: 1, width: 650,
              }}
              >
                <Text style={{
                  width: 600,
                  marginTop: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 45,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 5,
                  // color: '#333333',
                }}
                >
                  {' '}
                  ii. CVS
                </Text>
              </View>
              <GeneralExamination label="a." secondLabel="S1S2" field="systematicExamination.s1s2" />
              <GeneralExamination label="b." secondLabel="Murmurs and Others" field="systematicExamination.murmursAndOthers" />
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              // flex: 1,
              marginLeft: 16,
              // marginBottom: 10,
              width: '41%',
              height: 300,
            }}
            >
              <View style={{
                flex: 1, width: 650,
              }}
              >
                <Text style={{
                  width: 600,
                  marginTop: 15,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 45,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 5,
                  // color: '#333333',
                }}
                >
                  {' '}
                  iii. CNS
                </Text>
              </View>
              <GeneralExamination label="a." secondLabel="GCS" field="systematicExamination.gcs" />
              <GeneralExamination label="b." secondLabel="Sensory motors" field="systematicExamination.sensoryMotor" />

              <View style={{
                flex: 1, marginBottom: 10, width: 650,
              }}
              >
                <Text style={{
                  width: 600,
                  marginTop: 15,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  marginLeft: 45,
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 5,
                  // color: '#333333',
                }}
                >
                  {' '}
                  ii. Abdomen
                </Text>
              </View>
              <GeneralExamination label="a." secondLabel="Bowels Sounds" field="systematicExamination.bowelSounds" />
              <View style={{ flex: 1, marginLeft: 16, marginBottom: 10 }}>
                <Text style={{
                  width: 70,
                  paddingLeft: 40,
                  marginTop: 20,
                  marginBottom: 10,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  b.
                </Text>
              </View>
              <View style={{
                width: 600, marginTop: 10,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 250,
                  }}
                  type="radioGroup"
                  field="systematicExamination.isSoft"
                  options={['Soft', 'Distended']}
                />
              </View>
            </View>
          </View>

          {/* Airway */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Airway
              </Text>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '42%',
              height: 150,
            }}
            >
              <GeneralExamination label="a." secondLabel="Mouth Opening" field="airwayMouthOpening" width={400} />
              <GeneralExamination label="b." secondLabel="MMPS" field="airwayMMPS" width={400} />
              <GeneralExamination label="a." secondLabel="Edentulous" field="airwayEdentulous" width={400} />
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginLeft: 16,
              width: '42%',
              height: 150,
            }}
            >
              <View style={{ flex: 1, marginLeft: 16, width: 30 }}>
                <Text style={{
                  width: 20,
                  marginTop: 15,
                  paddingLeft: 40,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  d.
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{
                  width: 70,
                  marginTop: 15,
                  marginRight: 130,
                  // paddingRight: 80,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  // alignItems: 'center',
                }}
                >
                  {' '}
                  Teeth
                </Text>
              </View>
              <View style={{
                width: 400, marginTop: 10,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 400,
                  }}
                  type="radioGroup"
                  field="airwayTeeth"
                  options={['Loose', 'Crown', 'Buck', 'Capping']}
                />
              </View>
              <GeneralExamination label="e." secondLabel="Neck Movement" field="airwayNeckMovement" />
              <GeneralExamination label="f." secondLabel="Other" field="airwayOther" />
            </View>
          </View>

          {/* Spine Examination */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Spine Examination
              </Text>
            </View>

            <GeneralExamination label="a." secondLabel="Local Site Infection" field="spineExaminationLocalInfection" width={1200} />
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 10,
            }}
            >
              <View style={{ flex: 1, marginLeft: 16, width: 20 }}>
                <Text style={{
                  width: 20,
                  paddingLeft: 40,
                  marginTop: 15,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  b.
                </Text>
              </View>
              <View style={{
                width: 100, marginTop: 15,
              }}
              >
                <Text style={{
                  width: 200,
                  marginLeft: 50,

                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  paddingRight: 50,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  Any Deformity
                </Text>
              </View>
              <View style={{
                width: 150,
                marginLeft: 150,
                marginTop: 5,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 150,
                  }}
                  type="radioGroup"
                  field="isSpineExaminationDeformity"
                  options={['Yes', 'No']}
                />
              </View>
              <View style={{
                width: 1050, marginTop: 10,
              }}
              >
                <FormField
                  style={{
                    backgroundColor: 'white',
                    width: 1050,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,

                  }}
                  type="text"
                  variant="filled"
                  disabled={(disableProps) => {
                    const { data: { isSpineExaminationDeformity = false } = {} } = disableProps;
                    return isSpineExaminationDeformity === 'No'                     ;
                  }}
                  field="spineExaminationDeformity"
                />
              </View>
            </View>
          </View>

          {/* General Examination */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >

            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 10,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                General Examination
              </Text>
            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '50%',
              height: 600,
            }}
            >
              <GeneralExamination label="a." secondLabel="HB" field="generalExamination.hb" width={400} />
              <GeneralExamination label="b." secondLabel="TLC" field="generalExamination.tlc" width={400} />
              <GeneralExamination label="c." secondLabel="DLC" field="generalExamination.dlc" width={400} />
              <GeneralExamination label="d." secondLabel="BT" field="generalExamination.bt" width={400} />
              <GeneralExamination label="e." secondLabel="CT" field="generalExamination.ct" width={400} />
              <GeneralExamination label="f." secondLabel="PT/INR" field="generalExamination.ptINR" width={400} />
              <GeneralExamination label="g." secondLabel="RBS" field="generalExamination.rbs" width={400} />
              <GeneralExamination label="h." secondLabel="Blood Area" field="generalExamination.bloodUrea" width={400} />
              <GeneralExamination label="i." secondLabel="Serum Creatinine" field="generalExamination.serumCreatinine" width={400} />
              <View style={{ flex: 1, marginLeft: 16 }}>
                <Text style={{
                  width: 70,
                  paddingLeft: 40,
                  marginTop: 15,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  //
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                  // color: '#333333',
                }}
                >
                  {' '}
                  j.
                </Text>
              </View>
              <View style={{
                width: 600, marginTop: 15,
              }}
              >
                <Text style={{
                  width: 200,
                  fontFamily: 'public_sans_regular',
                  fontStyle: 'normal',
                  fontSize: 14,
                  lineHeight: 14,
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  {' '}
                  Serum Electrolytes
                </Text>
              </View>
              <GeneralExamination label="i." secondLabel="Na+" field="generalExamination.serumElectrolyteNa" fieldWidth={400} marginLeft={60} />
              <GeneralExamination label="ii." secondLabel="K+" field="generalExamination.serumElectrolytek" fieldWidth={400} marginLeft={60} />
              <GeneralExamination label="iii." secondLabel="Ca++" field="generalExamination.serumElectrolyteCa" fieldWidth={400} marginLeft={60} />
              <GeneralExamination label="iv." secondLabel="Cl-" field="generalExamination.serumElectrolytecl" fieldWidth={400} marginLeft={60} />

            </View>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              flex: 1,
              marginLeft: 16,
              marginBottom: 10,
              width: 600,
              height: 300,
            }}
            >
              <GeneralExamination label="k." secondLabel="FT" field="generalExamination.ft" width={400} />
              <GeneralExamination label="l." secondLabel="SGOT" field="generalExamination.sgot" width={400} />
              <GeneralExamination label="m." secondLabel="SGPT" field="generalExamination.sgpt" width={400} />
              <GeneralExamination label="n." secondLabel="ALP" field="generalExamination.apl" width={400} />
              <GeneralExamination label="o." secondLabel="S.Albumin" field="generalExamination.sAlbumin" width={400} />
              <GeneralExamination label="p." secondLabel="A:G" field="generalExamination.ag" width={400} />
              <GeneralExamination label="q." secondLabel="Urine" field="generalExamination.urine" width={400} />
              <GeneralExamination label="r." secondLabel="Triffle Test" field="generalExamination.trifleTest" width={400} />
              <GeneralExamination label="s." secondLabel="Hbs Ag" field="generalExamination.hbsAg" width={400} />
              <GeneralExamination label="t." secondLabel="HCV" field="generalExamination.hcv" width={400} />
              <GeneralExamination label="u." secondLabel="HIV" field="generalExamination.hiv" width={400} />
            </View>
          </View>

          {/* Advice */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Advice
              </Text>
            </View>
            <View style={{
              width: 1500, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1500,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  marginLeft: 16,
                  borderRadius: 5,

                }}
                type="text"
                field="advice"
              />
            </View>
          </View>

          {/* Reference */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1500,
            }}
            >
              <Text style={{
                width: 1500,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Reference
              </Text>
            </View>
            <View style={{
              width: 1500, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 1500,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  marginLeft: 16,

                }}
                type="text"
                field="reference"
              />
            </View>
          </View>

          {/* ASA Grade */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              flex: 1, marginLeft: 16, width: 1200,
            }}
            >
              <Text style={{
                width: 1200,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                ASA Grade
              </Text>
            </View>
            <View style={{
              width: 1200, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 400,
                  marginLeft: 10,
                }}
                type="radioGroup"
                field="asGrade"
                options={['i', 'ii', 'iii', 'iv', 'v']}
              />
            </View>
          </View>

          {/* Plan of anesthesia */}
          <View style={{
            display: 'flex', flex: 1, flexDirection: 'row', marginTop: 14, flexWrap: 'wrap', width: '95%',
          }}
          >
            <View style={{
              marginLeft: 16, width: 1500,
            }}
            >
              <Text style={{
                width: 1500,
                marginTop: 20,
                fontFamily: 'public_sans_regular',
                fontStyle: 'normal',
                fontSize: 14,
                lineHeight: 14,
                display: 'flex',
                alignItems: 'center',
              }}
              >
                {' '}
                Plan Of Anesthesia
              </Text>
            </View>
            <View style={{
              width: 400, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 400,
                  marginLeft: 20,
                }}
                type="radioGroup"
                field="planOfAnesthesia"
                options={['General', 'Regional', 'Other']}
              />
            </View>
            <View style={{
              width: 1200, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  width: 300,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                type="text"
                field="planOfAnesthesiaOther"
              />
            </View>

            <View style={{
              flex: 1, marginLeft: 14, width: 1200, marginTop: 10,
            }}
            >
              <FormField
                style={{
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  width: 300,
                }}
                {... autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'doctor',
                  label: 'Doctor Name',
                  placeholder: 'Doctor Name',
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.EMPLOYEE,
                  query: 'masterDataList',
                  variant: 'filled',
                })}
              />
            </View>
          </View>
        </View>
      );
    }}
  </Form>
);
export default preAnestheticCheckup;
