import { ROUTES } from '../../../../../../Lib/constants';

import fillBirthList from './ViewBirthList/BirthForm';
import ViewDetails from './ViewBirthList/ViewDetail';

const gynecologyScreens = {
  [ROUTES.fillBirthForm.name]: {
    screen: fillBirthList,
    expanded: true,
  },
  [ROUTES.viewBirthForm.name]: {
    screen: ViewDetails,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 'auto',
      height: 'auto',
    },
  },
};

export default gynecologyScreens;
