import React from 'react';

import ConsultationLogTable from '../ConsultationLogTable';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';

const ConsultationLogTableModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          patientName, addOnFilter, model, id,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <ListActionModal
      icon={history}
      title="Consultation History"
      description={`List of Consultation of ${patientName}`}
      content={(
        <ConsultationLogTable
          addOnFilter={addOnFilter}
          model={model}
          id={id}
          {...props}
        />
        )}
      {...props}
    />
  );
};

export default ConsultationLogTableModal;
