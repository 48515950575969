/* eslint-disable camelcase */
import React from 'react';

import {
  cancelButton,
  save,
  StandardButton,
  clearSelection,
  FilterGroup,
  selectAll,
  showSelectedCount,
  DateFilter,
  Text,
  OutlineButton,
} from '../../app-components';
import AddDepartment from './Departments/AddDepartment';
import EditDepartment from './Departments/EditDepartment';
import DepartmentList from './Departments/DepartmentList';
import AddRole from './Role/AddRole';
import EditRole from './Role/EditRole';
import RoleList from './Role/RoleList';
import AddZipCode from './ZipCode/AddZipCode';
import EditZipCode from './ZipCode/EditZipCode';
import AddCity from './City/AddCity';
import EditCity from './City/EditCity';
import AddState from './State/AddState';
import EditState from './State/EditState';
import EditEmployeeType from './EmployeeType/EditEmployeeType';
import AddNewEmployeeType from './EmployeeType/AddNewEmployeeType';
import EmployeeTypesList from './EmployeeType/EmployeeTypeList';
import AddRoom from './Room/AddRoom';
import EditRoom from './Room/EditRoom';
import RoomList from './Room/RoomList';
import AddWard from './Speciality/AddWard';
import WardList from './Speciality/WardList';
import EditWard from './Speciality/EditWard';
import ViewRoom from './Room/ViewRoom';
import ViewUserListModal from '../../Wired/ViewUserListModal';
import ViewRoomsListModal from '../../Wired/ViewRoomsListModal';
import ViewWardsListModal from '../../Wired/ViewWardsListModal';
import ViewWard from './Speciality/ViewWard';
import viewDepartment from './Departments/ViewDepartment';
import ViewRole from './Role/ViewRole';
import ExportModal from '../../Wired/ExportModal';
import TestLinkedModal from '../../Wired/ViewTestTypeListModal ';
import BulkUploadModal from '../../Wired/BulkUploadModal';
import EditConfiguration from './Configuration/editConfiguration';
import patientList from './Patients/patientList';
import ViewPatient from './Patients/ViewPatient';
import TestList from './Lab/TestType/TestList';
import AddTest from './Lab/TestType/AddTest';
import EditTest from './Lab/TestType/EditTest';
import ViewTest from './Lab/TestType/ViewTest';
import LabList from './Lab/LabType/LabList';
import AddLab from './Lab/LabType/AddLab';
import EditLab from './Lab/LabType/EditLab';
import ViewLab from './Lab/LabType/ViewLab';
import ViewEmployeeListModal from '../../Wired/ViewEmployeeListModal';
import { printConsultationCard } from '../../Wired/OPDCard';
import WithPermission from '../../Components/WithPermission';
import permissions from '../../Lib/permissions';
import ShiftList from './Shift/ShiftList';
import AddShift from './Shift/AddShift';
import EditShift from './Shift/EditShift';
import ViewShift from './Shift/ViewShift';
import savingHeadScreens from './OrganisationTaxDetails';
import ViewHolidaysCalendar from './HolidayCalendar/ViewHolidaysCalendar';
import EditHolidaysCalendar from './HolidayCalendar/EditHolidaysCalendar';
import AddHolidayCalendar from './HolidayCalendar/AddHolidayCalendar';
import HolidayListCalendar from './HolidayCalendar/HolidayListCalendar';
import AddHoliday from './Holiday/AddHoliday';
import EditHoliday from './Holiday/EditHoliday';
import HeaderTitleWithCount from '../../Components/HeaderTitileWithCount';
import FtsSearch from '../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../images';
import CategoryList from './Pharmacy/Category/CategoryList';
import AddCategory from './Pharmacy/Category/AddCategory';
import EditCategory from './Pharmacy/Category/EditCategory';
import ViewCategory from './Pharmacy/Category/ViewCategory';
import AddSubCategoryModal from './Pharmacy/SubCategory/AddSubCategory';
import EditSubCategoryModal from './Pharmacy/SubCategory/EditSubCategory';
import ViewSubCategoryListModal from '../../Wired/ViewSubCategoryListModal';
import ViewSubCategory from './Pharmacy/SubCategory/ViewSubCategory';
import SuppliersList from './Pharmacy/Suppliers/SuppliersList';
import AddSupplier from './Pharmacy/Suppliers/AddSupplier';
import EditSupplier from './Pharmacy/Suppliers/EditSupplier';
import ViewSupplier from './Pharmacy/Suppliers/ViewSupplier';
import AddManufacturerModal from './Pharmacy/Manufacturers/AddManufacturer';
import EditManufacturerModal from './Pharmacy/Manufacturers/EditManufacturer';
import salaryComponentScreens from './SalaryComponents';
import LeaveTypeList from './LeaveType/LeaveTypeList';
import AddLeaveType from './LeaveType/AddLeaveType';
import EditLeaveType from './LeaveType/EditLeaveType';
import ViewLeaveType from './LeaveType/ViewLeaveType';
import SalaryTemplate from './SalaryTemplate';
import LeavePolicyList from './LeavePolicy/LeavePolicyList';
import AddLeavePolicy from './LeavePolicy/AddLeavePolicy';
import ViewLeavePolicy from './LeavePolicy/ViewLeavePolicy';
import EditLeavePolicy from './LeavePolicy/EditLeavePolicy';
import TestComponentList from './Lab/TestComponent/TestComponent';
import AddTestComponent from './Lab/TestComponent/addComponent';
import ViewTestComponent from './Lab/TestComponent/ViewComponent';
import EditTestComponent from './Lab/TestComponent/editComponent';
import ConfirmTestModal from './Lab/TestType/confirmTestModal';
import AssetCategoryList from './Assets/Category/categoryList';
import AddAssetCategory from './Assets/Category/addCategory';
import EditAssetCategory from './Assets/Category/editCategory';
import ViewAssetCategory from './Assets/Category/ViewCategory';
import AddAssetSubCategoryModal from './Assets/SubCategory/AddSubCategory';
import EditAssetSubCategoryModal from './Assets/SubCategory/EditSubCategory';
import ViewAssetSubCategory from './Assets/SubCategory/ViewSubCategory';
import AssetSupplierList from './Assets/Suppliers/SupplierList';
import AddAssetSupplier from './Assets/Suppliers/addSupplier';
import EditAssetSupplier from './Assets/Suppliers/editSupplier';
import ViewAssetSupplier from './Assets/Suppliers/ViewSupplier';
import AddAssetManufacturer from './Assets/Manufacturers/AddManufacturer';
import EditAssetManufacturer from './Assets/Manufacturers/EditManufacturer';
import PurchaseDepartmentTCList from './PurchaseDepartmentTC/PurchaseDepartmentTCList';
import AddPurchaseDepartmentTC from './PurchaseDepartmentTC/AddPurchaseDepartmentTC';
import EditPurchaseDepartmentTC from './PurchaseDepartmentTC/EditPurchaseDepartmentTC';
import ViewPurchaseDepartmentTC from './PurchaseDepartmentTC/ViewPurchaseDepartmentTC';
import ActivityLogsTabList from '../../Wired/ActivityLogList';
import EmployeeScheduleScreens from './EmployeeSchedule';
import ViewVaccineSchedule from './Vaccination/viewVaccineDetail';
import AddVaccineSchedule from './Vaccination/AddVaccineSchedule';
import vaccineScheduleList from './Vaccination/vaccineScheduleList';
import { MODELS, ROUTES } from '../../Lib/constants';
import Accounts from './Accounts';
import vars from '../../theme/vars';
import PurchaseCategoryList from './PurchaseDepartmentTC/Category/categoryList';
import AddPurchaseCategory from './PurchaseDepartmentTC/Category/addCategory';
import EditPurchaseCategory from './PurchaseDepartmentTC/Category/editCategory';
import ViewPurchaseCategory from './PurchaseDepartmentTC/Category/ViewCategory';
import AddPurchaseSubCategoryModal from './PurchaseDepartmentTC/SubCategory/AddSubCategory';
import EditPurchaseSubCategoryModal from './PurchaseDepartmentTC/SubCategory/EditSubCategory';
import ViewPurchaseSubCategory from './PurchaseDepartmentTC/SubCategory/ViewSubCategory';
import ViewPatientVaccinationLogs from './Patients/Component/ViewVaccinationLog';
import RoomCategoryList from './RoomCategories/index';
import AddRoomCategory from './RoomCategories/AddRoomsCotegory';
import EditRoomCategory from './RoomCategories/EditRoomsCategory';
import editOperation from './Operation/editOperation';
import addOperation from './Operation/addOperation';
import Operation from './Operation';
import House from './House';
import AddRoomModal from './Room/Bed/AddBed';
import ViewBed from './Room/Bed/ViewBed';
import EditBedModal from './Room/Bed/EditBed';
import BedList from './Room/Bed/BedList';
import PreviewPackageTabNavigation from './ReviewPackage/tabs';
import PreviwedPackageModal from './ReviewPackage/ReviewedPackageForm';
import basicPayMatrix from './BasicPayMatrix';
import LogAcpMatrix from './BasicPayMatrix/ACpMatrix/addAcpMatrix';
import EditAcpMatrix from './BasicPayMatrix/ACpMatrix/editAcpMatrix';
import ViewAcpMatrix from './BasicPayMatrix/ACpMatrix/viewAcpMatrix';
import LogAcpSchedule from './BasicPayMatrix/AcpSchedule/addAcpSchedule';
import ViewAcpSchedule from './BasicPayMatrix/AcpSchedule/viewAcpSchedule';
import EditAcpSchedule from './BasicPayMatrix/AcpSchedule/editAcpSchedule';
import EditGradePay from './BasicPayMatrix/GradePayLevel/editGradePayLevel';
import LogGradePay from './BasicPayMatrix/GradePayLevel/addGradePayLevel';
import ViewGradePay from './BasicPayMatrix/GradePayLevel/viewGradePayLevel';
import ViewPayMatrix from './BasicPayMatrix/PayMatrix/viewPayMatrix';
import EditPayMatrix from './BasicPayMatrix/PayMatrix/editPayMatrix';
import LogPayMatrix from './BasicPayMatrix/PayMatrix/addPayMatrix';
import PranPercentage from './SalaryComponents/PranPercentage';
import categoryList from './EmployeeCategory/categoryList';
import addEmployeeCategory from './EmployeeCategory/addEmployeeCategory';
import editEmployeeCategory from './EmployeeCategory/editEmployeeCategory';
import designationList from './Designation/designationList';
import AddDesignation from './Designation/AddDesignation';
import EditDesignation from './Designation/EditDesignation';
import ViewDesignation from './Designation/ViewDesignation';
import viewEmployeeCategory from './EmployeeCategory/viewEmployeeCategory';
import ExGratiaSocietyList from './ExGratiaSociety/ExGratiaSocietyList';
import viewExGratiaSociety from './ExGratiaSociety/viewExGratiaSociety';
import addExGratiaSociety from './ExGratiaSociety/addExGratiaSociety';
import { getPermission } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import AcpPackageReviewModal from './ReviewPackage/AcpReviewModal';
import DiseaseList from './Disease/diseaseList';
import AddDisease from './Disease/addDisease';
import EditDisease from './Disease/editDisease';
import ViewDisease from './Disease/viewDisease';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

const getHeaderActionToRoute = (params) => {
  const { route, label, access_key } = params;

  return {
    render: () => (
      <WithPermission access_key={access_key}>
        <StandardButton label={label} />
      </WithPermission>
    ),
    type: 'link',
    link: (props) => ({
      view: route,
      push: true,
      expanded: true,
      params: { ...props?.navigation?.state?.params },
    }),
  };
};

export default {

  [ROUTES.roomCategories.name]: {
    screen: RoomCategoryList,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <StandardButton label={'Add Ward Category'.getLabel()} />
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addRoomCategory.name,
        }),
      },
    ],
  },
  [ROUTES.addRoomCategory.name]: {
    screen: AddRoomCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editRoomCategory.name]: {
    screen: EditRoomCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.reviewedPackageForm.name]: {
    screen: PreviwedPackageModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 500,
    },
  },
  [ROUTES.acpReviewPackageForm.name]: {
    screen: AcpPackageReviewModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 500,
    },
  },
  [ROUTES.packageReview.name]: {
    screen: PreviewPackageTabNavigation,
    expanded: true,
    // headerActions: headerActionsForAddForm,
  },
  [ROUTES.RoomsList.name]: {
    screen: RoomList,
    screenProps: {
      selectableActions: (selectedActionProps) => [
        showSelectedCount(selectedActionProps),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.mdm.views.roomManagement.permissions.export.value
              }
            >
              <StandardButton label={'room.title.exportToExcel'.getLabel()} />
            </WithPermission>
          ),

          file: 'RoomsManagement',
          type: 'download',
          columns: [
            {
              field: 'name',
              header: 'room.labels.room'.getLabel(),
            },
            {
              field: 'department.name',
              header: 'room.labels.department'.getLabel(),
            },
            {
              field: 'speciality.name',
              header: 'room.labels.speciality'.getLabel(),
            },
            {
              field: 'room.labels.totalBeds'.getLabel(),
              header: 'Total Beds',
            },
            {
              field: 'emptyBeds',
              header: 'room.labels.emptyBeds'.getLabel(),
            },
            {
              field: {
                field: 'isActive',
                parserFunction: 'formatBooleanValueForExcel',
              },
              header: 'room.labels.status'.getLabel(),
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.ROOM,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (props) => {
              const {
                screenState: { selectedIds = [] },
              } = props;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          label={'room.title.roomManagement'.getLabel()}
          count={selectionCount}
        />
      ),

      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name', 'speciality.name', 'department.name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Department',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select departments',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'speciality',
              label: 'Speciality',
              model: MODELS.WARDS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select specialities',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
          ],
        }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'room.title.addRoom'.getLabel(),
        route: ROUTES.addRoom.name,
        access_key: permissions.mdm.views.addRoom.permissions.addRoom.value,
      }),
    ],
  },
  [ROUTES.addRoom.name]: {
    screen: AddRoom,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },

  [ROUTES.addBed.name]: {
    screen: AddRoomModal,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewBed.name]: {
    screen: ViewBed,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editBed.name]: {
    screen: EditBedModal,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.bedList.name]: {
    screen: BedList,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },

  [ROUTES.editRoom.name]: {
    screen: EditRoom,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewRoom.name]: {
    screen: ViewRoom,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'room.title.editRoom'.getLabel(),
        route: ROUTES.editRoom.name,
        access_key:
          permissions.mdm.views.modifyRoom.permissions.modifyRoom.value,
      }),
    ],
  },
  [ROUTES.DepartmentsList.name]: {
    screen: DepartmentList,
    screenProps: {
      selectableActions: (props) => [
        showSelectedCount(props),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.mdm.views.exportDepartment.permissions.exportDepartment
                  .value
              }
            >
              <StandardButton
                label={'department.title.exportToExcel'.getLabel()}
              />
            </WithPermission>
          ),

          file: 'Department',
          type: 'download',
          columns: [
            {
              field: 'name',
              header: 'Department Name',
            },
            {
              field: 'code',
              header: 'Department ID',
            },
            {
              field: 'department.name',
              header: 'Department',
            },
            {
              field: 'trackBeds',
              header: 'Track Beds',
            },
            {
              field: {
                field: 'isActive',
                parserFunction: 'formatBooleanValueForExcel',
              },
              header: 'Status',
            },
            {
              field: 'rooms_count._count',
              header: 'Rooms',
            },
            {
              field: 'employee_count._count',
              header: 'Employees',
            },
            {
              field: 'wards_count._count',
              header: 'Specialities',
            },
            {
              field: 'description',
              header: 'Description',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.DEPARTMENTS,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (messageProps) => {
              const { screenState: { selectedIds } } = messageProps;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'department.title.departmentManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name', 'code', 'description'] }}
          />
        ),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'department.title.addDepartment'.getLabel(),
        route: ROUTES.addDepartment.name,
        access_key:
          permissions.mdm.views.addDepartment.permissions.addDepartment.value,
      }),
    ],
  },
  [ROUTES.addDepartment.name]: {
    screen: AddDepartment,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editDepartment.name]: {
    screen: EditDepartment,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewDepartment.name]: {
    screen: viewDepartment,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'department.title.editDepartment'.getLabel(),
        route: ROUTES.editDepartment.name,
        access_key:
          permissions.mdm.views.modifyDepartment.permissions.modifyDepartment.value,
      }),
    ],
  },
  [ROUTES.EmployeeTypesList.name]: {
    screen: EmployeeTypesList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'employeeType.title.employeeType'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch {...props} action={{ exp: ['name', 'description'] }} />
        ),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'employeeType.title.addEmployeeType'.getLabel(),
        route: ROUTES.addEmployeeType.name,
        access_key: permissions.mdm.views.addEmployeeType.permissions.addEmployeeType.value,
      }),
    ],
  },
  [ROUTES.addEmployeeType.name]: {
    screen: AddNewEmployeeType,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editEmployeeType.name]: {
    screen: EditEmployeeType,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.RolesList.name]: {
    screen: RoleList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'role.title.roleManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch {...props} action={{ exp: ['name', 'description'] }} />
        ),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'role.title.addRole'.getLabel(),
        route: ROUTES.addRole.name,
        access_key: permissions.mdm.views.addRole.permissions.addRole.value,
      }),
    ],
  },
  [ROUTES.addRole.name]: {
    screen: AddRole,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editRole.name]: {
    screen: EditRole,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewRole.name]: {
    screen: ViewRole,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'role.title.EditRole'.getLabel(),
        route: ROUTES.editRole.name,
        access_key:
          permissions.mdm.views.modifyRole.permissions.modifyRole.value,
      }),
    ],
  },
  // [ROUTES.uploadZipCode]: { screen: UploadZipCode({ submitmessage, header }) },
  [ROUTES.WardsList.name]: {
    screen: WardList,
    expanded: true,
    screenProps: {
      selectableActions: (props) => [
        showSelectedCount(props),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.mdm.views.exportSpeciality.permissions.exportSpeciality
                  .value
              }
            >
              <StandardButton
                label={'speciality.title.exportToExcel'.getLabel()}
              />
            </WithPermission>
          ),
          file: 'WardsManagement',
          type: 'download',
          columns: [
            {
              field: 'name',
              header: 'speciality.labels.wardName'.getLabel(),
            },
            {
              field: 'code',
              header: 'speciality.labels.wardId'.getLabel(),
            },
            {
              field: 'department.name',
              header: 'speciality.labels.department'.getLabel(),
            },
            {
              field: {
                field: 'isActive',
                parserFunction: 'formatBooleanValueForExcel',
              },
              header: 'speciality.labels.status'.getLabel(),
            },
            {
              field: {
                field: 'rooms_count',
                parserFunction: 'formatArrayToStringForExcel',
                options: {
                  field: 'name',
                },
              },
              header: 'speciality.labels.roomsAllocated'.getLabel(),
            },
            {
              field: 'employee_count._count',
              header: 'speciality.labels.users'.getLabel(),
            },
            {
              field: 'description',
              header: 'Description',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.WARDS,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (messageProps) => {
              const { screenState: { selectedIds } } = messageProps;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'speciality.title.wardManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name', 'description', 'code'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Department',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select departments',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
          ],
        }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'speciality.title.addWard'.getLabel(),
        route: ROUTES.addWard.name,
        access_key:
          permissions.mdm.views.addSpeciality.permissions.addSpeciality.value,
      }),
    ],
  },
  [ROUTES.addWard.name]: {
    screen: AddWard,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editWard.name]: {
    screen: EditWard,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewWard.name]: {
    screen: ViewWard,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'speciality.title.editWard'.getLabel(),
        route: ROUTES.editWard.name,
        access_key:
          permissions.mdm.views.modifySpeciality.permissions.modifySpeciality.value,
      }),
    ],
  },
  [ROUTES.addZipCode.name]: {
    screen: AddZipCode,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editZipCode.name]: {
    screen: EditZipCode,
    headerActions: headerActionsForEditForm,
  },
  addCity: { screen: AddCity, expanded: true },
  editCity: { screen: EditCity, expanded: true },
  addState: { screen: AddState, expanded: true },
  editState: { screen: EditState, expanded: true },

  /* Modals */

  [ROUTES.viewUserListModal.name]: { screen: ViewUserListModal },
  [ROUTES.viewRoomsListModal.name]: {
    screen: ViewRoomsListModal,
    expanded: true,
  },
  [ROUTES.viewWardsListModal.name]: {
    screen: ViewWardsListModal,
    expanded: true,
  },
  [ROUTES.viewWardsListModal]: { screen: ViewWardsListModal, expanded: true },
  [ROUTES.bulkUploadModal.name]: { screen: BulkUploadModal },
  [ROUTES.editConfiguration.name]: {
    screen: EditConfiguration,
    expanded: true,
  },
  [ROUTES.exportModal.name]: { screen: ExportModal },
  [ROUTES.TestType.name]: {
    screen: TestList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'lab.title.testManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => <FtsSearch {...props} action={{ exp: ['name', 'code'] }} />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'labType',
              label: 'Lab Type',
              model: MODELS.LAB_TYPE,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Lab Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
          ],
        }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'lab.buttons.addTest'.getLabel(),
        route: ROUTES.addTest.name,
        access_key: permissions.mdm.views.addTestManagement.permissions.addTestManagement.value,
      }),
    ],
  },
  [ROUTES.addTest.name]: {
    screen: AddTest,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.confirmTestModal.name]: {
    screen: ConfirmTestModal,
    modal: true,
    modalProps: {
      width: 500,
      height: 220,
    },
  },
  [ROUTES.editTest.name]: {
    screen: EditTest,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewTest.name]: {
    screen: ViewTest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'lab.buttons.editTest'.getLabel(),
        route: ROUTES.editTest.name,
        access_key:
          permissions.mdm.views.modifyTestManagement.permissions.modifyTestManagement.value,
      }),
    ],
  },
  [ROUTES.TestComponentType.name]: {
    screen: TestComponentList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'testComponent.label.testComponent'.getLabel()}
        />
      ),
      actions: [
        (props) => <FtsSearch {...props} action={{ exp: ['name'] }} />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: '_id',
              label: 'Test Type',
              model: MODELS.LAB_TEST,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Test Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              filterValueField: 'filter',
              filterType: 'primary',
              modifyResult: ({ data }) => {
                const clonedData = data.map((item) => ({
                  ...item,
                  filter: { $in: item?.testComponentType?.map((test) => test?._id) },
                }));

                return { data: clonedData };
              },
            },
          ],
        }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'testComponent.label.addComponent'.getLabel(),
        route: ROUTES.addComponent.name,
        access_key:
          permissions.mdm.views.addTestComponent.permissions.addTestComponent.value,
      }),
    ],
  },
  [ROUTES.addComponent.name]: {
    screen: AddTestComponent,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewComponent.name]: {
    screen: ViewTestComponent,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        route: ROUTES.editComponent.name,
        label: 'testComponent.label.edit'.getLabel(),
        access_key:
          permissions.mdm.views.modifyTestComponent.permissions.modifyTestComponent
            .value,
      }),
    ],
  },
  [ROUTES.editComponent.name]: {
    screen: EditTestComponent,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewTestLinked.name]: {
    screen: TestLinkedModal,
    // headerActions: [
    //   getHeaderActionToRoute({
    //     access_key:
    //       permissions.mdm.views.testComponentManagement.permissions.modify
    //         .value,
    //   }),
    // ],
  },
  [ROUTES.LabType.name]: {
    screen: LabList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'lab.title.labManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => <FtsSearch {...props} action={{ exp: ['name', 'code'] }} />,
        // FilterGroup({
        //   icon: filterInactive,
        //   activeIcon: filterActive,
        //   filters: [
        //     {
        //       type: 'autosuggest',
        //       field: 'department',
        //       label: 'Department',
        //       model: MODELS.DEPARTMENTS,
        //       id: 'masterDataList',
        //       searchField: 'name',
        //       placeholder: 'Select departments',
        //       sugestionField: 'name',
        //       primaryField: 'name',
        //       valueField: 'name',
        //     },
        //   ],
        // }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'lab.buttons.addLab'.getLabel(),
        route: ROUTES.addLab.name,
        access_key: permissions.mdm.views.addLab.permissions.addLab.value,
      }),
    ],
  },
  [ROUTES.addLab.name]: {
    screen: AddLab,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editLab.name]: {
    screen: EditLab,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewLab.name]: {
    screen: ViewLab,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'lab.buttons.editLab'.getLabel(),
        route: ROUTES.editLab.name,
        access_key:
          permissions.mdm.views.modifyLab.permissions.modifyLab.value,
      }),
    ],
  },
  [ROUTES.viewEmployeeListModal.name]: {
    screen: ViewEmployeeListModal,
    expanded: true,
  },
  [ROUTES.patientListMDM.name]: { screen: patientList },
  [ROUTES.ViewPatientMDM.name]: {
    screen: ViewPatient,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.uhid}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => (
          <StandardButton
            label="Print OPD card"
            onPress={() => {
              printConsultationCard({
                _id: props?.data?.lastConsultation?._id,
                uhid: props?.navigation?.state?.params?.item?.uhid,
              }, () => {});
            }}
          />
        ),
      },
    ],
  },
  [ROUTES.shiftList.name]: {
    screen: ShiftList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'shift.title.addShift'.getLabel(),
        route: ROUTES.addShift.name,
        access_key:
          permissions.mdm.views.addShift.permissions.addShift.value,
      }),
    ],
  },
  [ROUTES.addShift.name]: {
    screen: AddShift,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editShift.name]: {
    screen: EditShift,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewShift.name]: {
    screen: ViewShift,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'shift.title.editShift'.getLabel(),
        route: ROUTES.editShift.name,
        access_key:
          permissions.mdm.views.modifyShift.permissions.modifyShift.value,
      }),
    ],
  },
  ...savingHeadScreens,
  [ROUTES.holidayListCalendar.name]: {
    screen: HolidayListCalendar,
    headerActions: [
      getHeaderActionToRoute({
        label: 'holidayCalendar.buttons.addHoliday'.getLabel(),
        route: ROUTES.addHolidayCalendar.name,
        access_key:
          permissions.mdm.views.addHoliday.permissions.addHoliday.value,
      }),
    ],
    header: {
      title: (
        <HeaderTitleWithCount label={'holidayCalendar.title.name'.getLabel()} />
      ),
      actions: [
        (props) => (
          <FtsSearch {...props} action={{ exp: ['name', 'description'] }} />
        ),
        <DateFilter field="lastConsultationDate" key="lastConsultationDate" />,
      ],
    },
  },
  [ROUTES.addHolidayCalendar.name]: {
    screen: AddHolidayCalendar,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewHolidaysCalendar.name]: {
    screen: ViewHolidaysCalendar,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'holidayCalendar.buttons.editHoliday'.getLabel(),
        route: ROUTES.editHolidaysCalendar.name,
        access_key:
          permissions.mdm.views.modifyHoliday.permissions.modifyHoliday.value,
      }),
    ],
  },
  [ROUTES.editHolidaysCalendar.name]: {
    screen: EditHolidaysCalendar,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.addHoliday.name]: {
    screen: AddHoliday,
  },
  [ROUTES.editHoliday.name]: {
    screen: EditHoliday,
  },
  [ROUTES.pharmacyCategory.name]: {
    screen: CategoryList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'pharmacy.buttons.addCategory'.getLabel(),
        route: ROUTES.addPharmacyCategory.name,
        access_key:
          permissions.mdm.views.addPharmacyCategory.permissions.addPharmacyCategory.value,
      }),
    ],
  },
  [ROUTES.addPharmacyCategory.name]: {
    screen: AddCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editPharmacyCategory.name]: {
    screen: EditCategory,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewPharmacyCategory.name]: {
    screen: ViewCategory,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'pharmacy.buttons.editCategory'.getLabel(),
        route: ROUTES.editPharmacyCategory.name,
        access_key:
          permissions.mdm.views.modifyPharmacyCategory.permissions.modifyPharmacyCategory.value,
      }),
    ],
  },
  [ROUTES.addPharmacySubCategory.name]: {
    screen: AddSubCategoryModal,
  },
  [ROUTES.editPharmacySubCategory.name]: {
    screen: EditSubCategoryModal,
  },
  [ROUTES.subCategoryListModal.name]: {
    screen: ViewSubCategoryListModal,
  },
  [ROUTES.viewPharmacySubCategory.name]: {
    screen: ViewSubCategory,
    expanded: true,
  },
  [ROUTES.pharmacySupplierAndManufacturer.name]: {
    screen: SuppliersList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'pharmacy.buttons.addSupplier'.getLabel(),
        route: ROUTES.addPharmacySupplier.name,
        access_key:
          permissions.mdm.views.addPharmacySupplierAndManufacturer
            .permissions.addPharmacySupplierAndManufacturer.value,
      }),
    ],
  },
  [ROUTES.addPharmacySupplier.name]: {
    screen: AddSupplier,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editPharmacySupplier.name]: {
    screen: EditSupplier,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewPharmacySupplier.name]: {
    screen: ViewSupplier,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'pharmacy.buttons.editSupplier'.getLabel(),
        route: ROUTES.editPharmacySupplier.name,
        access_key:
          permissions.mdm.views.modifyPharmacySupplierAndManufacturer.permissions
            .modifyPharmacySupplierAndManufacturer.value,
      }),
    ],
  },
  [ROUTES.addPharmacyManufacturer.name]: {
    screen: AddManufacturerModal,
  },
  [ROUTES.editPharmacyManufacturer.name]: {
    screen: EditManufacturerModal,
  },

  [ROUTES.assetCategory.name]: {
    screen: AssetCategoryList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'asset.buttons.addCategory'.getLabel(),
        route: ROUTES.addAssetCategory.name,
        access_key: permissions.mdm.views.addAssetCategory.permissions.addAssetCategory.value,
      }),
    ],
  },
  [ROUTES.addAssetCategory.name]: {
    screen: AddAssetCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editAssetCategory.name]: {
    screen: EditAssetCategory,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewAssetCategory.name]: {
    screen: ViewAssetCategory,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'asset.buttons.editCategory'.getLabel(),
        route: ROUTES.editAssetCategory.name,
        access_key: permissions.mdm.views.modifyAssetCategory.permissions.modifyAssetCategory.value,
      }),
    ],
  },
  [ROUTES.addAssetSubCategory.name]: {
    screen: AddAssetSubCategoryModal,
  },
  [ROUTES.editAssetSubCategory.name]: {
    screen: EditAssetSubCategoryModal,
  },
  [ROUTES.viewAssetSubCategory.name]: {
    screen: ViewAssetSubCategory,
  },
  [ROUTES.assetSupplierAndManufacturer.name]: {
    screen: AssetSupplierList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'asset.buttons.addSupplier'.getLabel(),
        route: ROUTES.addAssetSupplier.name,
        access_key: permissions.mdm.views.addAssetSupplierAndManufacturer
          .permissions.addAssetSupplierAndManufacturer.value,
      }),
    ],
  },

  [ROUTES.addAssetSupplier.name]: {
    screen: AddAssetSupplier,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editAssetSupplier.name]: {
    screen: EditAssetSupplier,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewAssetSupplier.name]: {
    screen: ViewAssetSupplier,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'asset.buttons.editSupplier'.getLabel(),
        route: ROUTES.editAssetSupplier.name,
        access_key: permissions.mdm.views.modifyAssetSupplierAndManufacturer
          .permissions.modifyAssetSupplierAndManufacturer.value,
      }),
    ],
  },
  [ROUTES.addAssetManufacturer.name]: {
    screen: AddAssetManufacturer,
  },
  [ROUTES.editAssetManufacturer.name]: {
    screen: EditAssetManufacturer,
  },
  ...salaryComponentScreens,
  ...SalaryTemplate,
  ...Accounts,
  [ROUTES.leaveTypeList.name]: {
    screen: LeaveTypeList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'leaveType.title.leaveTypeManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => <FtsSearch {...props} action={{ exp: ['name', 'code'] }} />,
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'leaveType.buttons.addLeaveType'.getLabel(),
        route: ROUTES.addLeaveType.name,
        access_key: permissions.mdm.views.addLeaveType.permissions.addLeaveType.value,
      }),
    ],
  },
  [ROUTES.addLeaveType.name]: {
    screen: AddLeaveType,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editLeaveType.name]: {
    screen: EditLeaveType,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewLeaveType.name]: {
    screen: ViewLeaveType,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'leaveType.buttons.editLeaveType'.getLabel(),
        route: ROUTES.editLeaveType.name,
        access_key: permissions.mdm.views.modifyLeaveType.permissions.modifyLeaveType.value,
      }),
    ],
  },
  [ROUTES.leavePolicyTypeList.name]: {
    screen: LeavePolicyList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'leaveType.buttons.addLeavePolicy'.getLabel(),

        route: ROUTES.addLeavePolicyType.name,

        access_key: permissions.mdm.views.addLeavePolicyType.permissions.addLeavePolicyType.value,
      }),
    ],
  },
  [ROUTES.addLeavePolicyType.name]: {
    screen: AddLeavePolicy,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewLeavePolicyType.name]: {
    screen: ViewLeavePolicy,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'leaveType.buttons.editLeavePolicy'.getLabel(),
        route: ROUTES.editLeavePolicyType.name,
        access_key:
          permissions.mdm.views.modifyLeavePolicyType.permissions.modifyLeavePolicyType.value,
      }),
    ],
  },
  [ROUTES.editLeavePolicyType.name]: {
    screen: EditLeavePolicy,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.purchaseDepartmentTCList.name]: {
    screen: PurchaseDepartmentTCList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'purchaseDepartmentTC.title.addPurchaseDepartmentTC'.getLabel(),
        route: ROUTES.addPurchaseDepartmentTC.name,
        access_key:
          permissions.mdm.views.addPurchaseTAndC.permissions.addPurchaseTAndC.value,
      }),
    ],
  },
  [ROUTES.addPurchaseDepartmentTC.name]: {
    screen: AddPurchaseDepartmentTC,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editPurchaseDepartmentTC.name]: {
    screen: EditPurchaseDepartmentTC,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewPurchaseDepartmentTC.name]: {
    screen: ViewPurchaseDepartmentTC,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'purchaseDepartmentTC.title.editPurchaseDepartmentTC'.getLabel(),
        route: ROUTES.editPurchaseDepartmentTC.name,
        access_key:
          permissions.mdm.views.modifyPurchaseTAndC.permissions.modifyPurchaseTAndC.value,
      }),
    ],
  },
  [ROUTES.viewLeavePolicyActivityLog.name]: {
    screen: ActivityLogsTabList,
    screenProps: {
      hoverable: false,
      model: MODELS.APP_HISTORY_CHANGES,
      id: 'getHistoryActivity',
    },
    modal: true,
    modalProps: {
      width: 800,
      height: 500,
      autoHide: true,
    },
  },
  [ROUTES.vaccineScheduleList.name]: {
    screen: vaccineScheduleList,
    expanded: true,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label="Scheduling"
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggestOptions',
              options: [{ label: 'Recurring', value: 'reCurring' }, { label: 'Once In LIfeTime', value: 'onceInLifeTime' }],
              field: 'vaccinationType',
              label: 'ReVaccination Type',
              multiSelect: true,
            },
          ],
        }),
      ],
    },
  },
  [ROUTES.viewVaccineSchedule.name]: {
    screen: ViewVaccineSchedule,
    expanded: true,
    headerActions: [
      getHeaderActionToRoute({
        label: 'Edit',
        route: ROUTES.addVaccineSchedule.name,
        access_key:
          permissions.mdm.views.addVaccineSchedule.permissions.addVaccineSchedule.value,
      }),
    ],
  },
  [ROUTES.addVaccineSchedule.name]: {
    screen: AddVaccineSchedule,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.purchaseCategory.name]: {
    screen: PurchaseCategoryList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'purchase.button.addCategory'.getLabel(),
        route: ROUTES.addPurchaseCategory.name,
        access_key: permissions.mdm.views.addPurchaseCategory.permissions.addPurchaseCategory.value,
      }),
    ],
  },
  [ROUTES.addPurchaseCategory.name]: {
    screen: AddPurchaseCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editPurchaseCategory.name]: {
    screen: EditPurchaseCategory,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewPurchaseCategory.name]: {
    screen: ViewPurchaseCategory,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'purchase.button.editCategory'.getLabel(),
        route: ROUTES.editPurchaseCategory.name,
        access_key: permissions.mdm.views.modifyPurchaseCategory
          .permissions.modifyPurchaseCategory.value,
      }),
    ],
  },
  [ROUTES.addPurchaseSubCategory.name]: {
    screen: AddPurchaseSubCategoryModal,
  },
  [ROUTES.editPurchaseSubCategory.name]: {
    screen: EditPurchaseSubCategoryModal,
  },
  [ROUTES.viewPurchaseSubCategory.name]: {
    screen: ViewPurchaseSubCategory,
  },
  [ROUTES.viewVaccinationLogPatientManagement.name]: {
    screen: ViewPatientVaccinationLogs,
    expanded: true,
  },
  [ROUTES.operationMaster.name]: {
    screen: Operation,
    expanded: true,
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Operation',
        route: ROUTES.addOperationMaster.name,
      }),
    ],
  },
  [ROUTES.editOperationMaster.name]: {
    screen: editOperation,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Update' }),
    ],

  },
  [ROUTES.addOperationMaster.name]: {
    screen: addOperation,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save(),
    ],
  },

  [ROUTES.basicPayMatrixes.name]: {
    screen: basicPayMatrix,
    expanded: true,
  },

  [ROUTES.addPayMatrixes.name]: {
    screen: LogPayMatrix,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewPayMatrixes.name]: {
    screen: ViewPayMatrix,
    expanded: true,
  },
  [ROUTES.editPayMatrixes.name]: {
    screen: EditPayMatrix,
    headerActions: headerActionsForEditForm,
    expanded: true,
  },

  [ROUTES.addPaySchedule.name]: {
    screen: LogAcpSchedule,
    expanded: true,
    headerActions: headerActionsForAddForm,

  },
  [ROUTES.viewPaySchedule.name]: {
    screen: ViewAcpSchedule,
    expanded: true,
  },
  [ROUTES.editPaySchedule.name]: {
    screen: EditAcpSchedule,
    expanded: true,
    headerActions: headerActionsForEditForm,

  },

  [ROUTES.addGradePayLevel.name]: {
    screen: LogGradePay,
    expanded: true,
    headerActions: headerActionsForAddForm,

  },
  [ROUTES.viewGradePayLevel.name]: {
    screen: ViewGradePay,
    expanded: true,
  },
  [ROUTES.editGradePayLevel.name]: {
    screen: EditGradePay,
    expanded: true,
    headerActions: headerActionsForEditForm,

  },

  [ROUTES.addAcpMatrixes.name]: {
    screen: LogAcpMatrix,
    expanded: true,
    headerActions: headerActionsForAddForm,

  },
  [ROUTES.viewAcpMatrixes.name]: {
    screen: ViewAcpMatrix,
    expanded: true,
  },
  [ROUTES.editAcpMatrixes.name]: {
    screen: EditAcpMatrix,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.EmployeeDesignation.name]: {
    screen: designationList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Designation',
        route: ROUTES.AddEmployeeDesignation.name,
        // access_key: permissions.mdm.views.addEmployeeType.permissions.addEmployeeType.value,
      }),
    ],
  },
  [ROUTES.AddEmployeeDesignation.name]: {
    screen: AddDesignation,
    expanded: true,
    headerActions: headerActionsForAddForm,
    // modal: true,
    // modalProps: {
    //   width: 800,
    //   height: 500,
    // },
  },
  [ROUTES.EditEmployeeDesignation.name]: {
    screen: EditDesignation,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Update' }),
    ],

  },
  [ROUTES.ViewEmployeeDesignation.name]: {
    screen: ViewDesignation,
    expanded: true,
  },
  [ROUTES.EmployeeCategory.name]: {
    screen: categoryList,
    expanded: true,
    title: 'Employee Category',
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Category',
        route: ROUTES.AddEmployeeCategory.name,
        // access_key: permissions.mdm.views.addEmployeeType.permissions.addEmployeeType.value,
      }),
    ],
  },
  [ROUTES.AddEmployeeCategory.name]: {
    screen: addEmployeeCategory,
    expanded: true,
    headerActions: headerActionsForAddForm,
    // modal: true,
    // modalProps: {
    //   width: 800,
    //   height: 500,
    // },
  },
  [ROUTES.EditEmployeeCategory.name]: {
    screen: editEmployeeCategory,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Update' }),
    ],

  },
  [ROUTES.ViewEmployeeCategory.name]: {
    screen: viewEmployeeCategory,
    expanded: true,
  },
  [ROUTES.ExGratiaSalary.name]: {
    screen: ExGratiaSocietyList,
    expanded: true,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label="Ex-Gratia and Society Salary"
        />
      ),
      actions: [
        {
          type: 'link',
          visible: () => getPermission(
            permissions.mdm.views.exGratia.permissions.exGratia.value,
          ),
          link: ({ navigation: { state: { params = {} } } }) => ({
            view: ROUTES.AddExGratiaSalary.name,
            params: { ...params },
          }),
          render: () => (
            <OutlineButton
              label="Add Ex-Gratia and Society Salary"
            />
          ),
        },
        (props) => <FtsSearch {...props} action={{ exp: ['month.name', 'year.name', 'employee.name'] }} />,

        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'month',
              label: 'Month',
              variant: 'filled',
              primaryField: 'name',
              suggestionField: 'name',
              valueField: 'name',
              model: MODELS.MONTHS,
              placeholder: 'Select Month',
              id: 'MonthTypeSuggestions',
            },
            {
              type: 'autoSuggest',
              field: 'year',
              label: 'Year',
              variant: 'filled',
              primaryField: 'name',
              suggestionField: 'name',
              valueField: 'name',
              model: MODELS.YEARS,
              id: 'YearTypeSuggestions',
            },
            autoSuggestInput({
              type: 'autoSuggest',
              field: 'employee',
              label: 'Employee',
              variant: 'filled',
              keyFiled: 'name',
              suggestionField: 'name',
              valueField: 'name',
              model: MODELS.EMPLOYEE,
              query: 'employeeLists',
              mandatory: true,
            },
            {
              addOnFilter: () => ({
                staffType: { code: { $in: ['Ex-Gratia', 'Society Salary'] } },
              }),
            }),
          ],
        }),

      ],
    },
  },
  [ROUTES.AddExGratiaSalary.name]: {
    screen: addExGratiaSociety,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.ViewExGratiaSalary.name]: {
    screen: viewExGratiaSociety,
    expanded: true,

  },
  [ROUTES.ViewEmployeeCategory.name]: {
    screen: viewEmployeeCategory,
    expanded: true,
  },
  [ROUTES.diseaseList.name]: {
    screen: DiseaseList,
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Disease',

        route: ROUTES.addDisease.name,

        access_key: permissions.mdm.views.addDisease.permissions.addDisease.value,
      }),
    ],
  },
  [ROUTES.addDisease.name]: {
    screen: AddDisease,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editDisease.name]: {
    screen: EditDisease,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewDisease.name]: {
    screen: ViewDisease,
    expanded: true,
    headerActions: [
      getHeaderActionToRoute({
        label: 'disease.button.editDisease'.getLabel(),
        route: ROUTES.editDisease.name,
        access_key: permissions.mdm.views.modifyDisease.permissions.modifyDisease.value,
      }),
    ],
  },
  ...EmployeeScheduleScreens,
  ...House,
};
