export const surgeonName = {
  'General Surgery': true,
  'General Medicine': false,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: true,
};

export const physicianName = {
  'General Surgery': false,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};

export const patientName = {
  'General Surgery': false,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};

export const unit = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const preOperationStatus = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const bloodLoss = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: true,
};
export const Orthopedic = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const doctorNotes = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const entPosition = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: true,
};
export const position = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const operationalPosition = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const operationalFindings = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: true,
};

export const postOperationOrder = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const operationalProcedure = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: false,
  Gynaecology: true,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const followUp = {
  'General Surgery': false,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const hpe = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const progressDate = {
  'General Surgery': true,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: true,
  Psychiatry: false,
  Skin: true,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: true,
  ENT: false,
};
export const entScreen = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: true,
  Skin: false,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const neurologyScreen = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: true,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const neurology = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: true,
  Skin: true,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: true,
};
export const progressEnt = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: true,
};
export const progressGc = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const vitals = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const vitalsBowelSound = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: false,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const vitalsDrainOutput = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const vitalsRtOutput = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: true,
  Urology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': true,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: true,
  ENT: false,
};
export const vitalsDistalPulse = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const vitalsDistalNeurologicalStatus = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const advice = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const physioAdvice = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const anyOtherComplaint = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const bloodInfusionNotes = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const paExamination = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const pvExamination = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: true,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const progression = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: true,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const sleep = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: true,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const appetite = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: true,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const anySignificantEvent = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: true,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const notes = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: true,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const roomCategories = {
  'General Surgery': true,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const changeBedCategory = {
  'General Surgery': true,
  'General Medicine': true,
  'Operation Theatre': true,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Urology: false,
  Neurology: true,
  'Pediatrics NICU': true,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': true,
  ICU: true,
  Eye: false,
  ENT: false,
};
export const remark = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: true,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};

export const bloodTransfusionNotes = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: true,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const vitalSignG = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: true,
  ENT: false,
};
export const vitalSignCS = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: true,
  ENT: false,
};
export const vitalSignTnput = {
  'General Surgery': false,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const vitalSignOutput = {
  'General Surgery': false,
  'General Medicine': false,
  'Operation Theatre': false,
  Orthopedic: true,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
export const vitalSignBloodSugar = {
  'General Surgery': false,
  'General Medicine': true,
  'Operation Theatre': false,
  Orthopedic: false,
  Gynaecology: false,
  Psychiatry: false,
  Skin: false,
  Emergency: false,
  Neurology: false,
  Urology: false,
  'Pediatrics NICU': false,
  'Pediatrics PICU': false,
  'Pediatrics Ward (PICU)': false,
  ICU: false,
  Eye: false,
  ENT: false,
};
//   export const vitalsDrainOutput = {
//     'General Surgery': true,
//     'General Medicine': true,
//     'Operation Theatre': true,
//     Orthopedic: true,
//     Gynaecology: false,
//     Psychiatry: true,
//     Skin: true,
//     Emergency: true,
//     Neurology: true,
//     Urology: true,
//     'Pediatrics NICU': true,
//     'Pediatrics PICU': true,
//     'Pediatrics Ward (PICU)': true,
//     ICU: true,
//     Eye: true,
//     ENT: true,
//   };
//   export const vitalsDrainOutput = {
//     'General Surgery': true,
//     'General Medicine': true,
//     'Operation Theatre': true,
//     Orthopedic: true,
//     Gynaecology: false,
//     Psychiatry: true,
//     Skin: true,
//     Emergency: true,
//     Neurology: true,
//     Urology: true,
//     'Pediatrics NICU': true,
//     'Pediatrics PICU': true,
//     'Pediatrics Ward (PICU)': true,
//     ICU: true,
//     Eye: true,
//     ENT: true,
//   };
//   export const vitalsDrainOutput = {
//     'General Surgery': true,
//     'General Medicine': true,
//     'Operation Theatre': true,
//     Orthopedic: true,
//     Gynaecology: false,
//     Psychiatry: true,
//     Skin: true,
//     Emergency: true,
//     Neurology: true,
//     Urology: true,
//     'Pediatrics NICU': true,
//     'Pediatrics PICU': true,
//     'Pediatrics Ward (PICU)': true,
//     ICU: true,
//     Eye: true,
//     ENT: true,
//   };

