import React from 'react';
import ListActionModal from '../../../../../../Components/ListActionModal';
import {
  FormField, LargeButton, View,
} from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { issueToCompanyIcon } from '../../../../../../images';
import { issueBloodUpdationRequestStatus, MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { getUser, submit } from '../../../../../../AppServices';

const IssueBloodRequestMSModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          updates: propsUpdate = {},
        } = {},
      } = {},
    } = {},
  } = props;
  const user = getUser();

  return (
    <ListActionModal
      icon={issueToCompanyIcon}
      title={'bloodBankUpdationRequest.title.updationRequest'.getLabel()}
      description={'bloodBankUpdationRequest.message.updationDescription'.getLabel()}
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            submitMessage={'bloodBankUpdationRequest.message.requestMessage'.getLabel()}
            mandatory={{
              reasonForDateChange: 1,
            }}
            reloadEvent={`reload${MODELS.BLOOD_BANK}`}
            bodyContainerStyle={{
              backgroundColor: vars.colors.white,
              flex: 1,
              overflow: 'hidden',
            }}
            onSubmit={async (submitProps) => {
              const { updates, data } = submitProps;
              const newUpdates = {
                ...propsUpdate,
                ...updates,
                is_requested: true,
                requestedBy: { _id: user?.employee?._id },
                status: issueBloodUpdationRequestStatus.PENDING,
              };
              const submitAPI = submit({
                model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
                ...submitProps,
                data,
              });
              await submitAPI({ data, updates: newUpdates });
            }}
            closeView={2}
            {...props}
          >
            {({ form_context: formContext }) => (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  marginHorizontal: 42,
                  justifyContent: 'space-around',
                }}
              >
                <View style={{ flex: 2 }}>
                  <FormField
                    type="textArea"
                    field="reasonForDateChange"
                    label={'bloodBankUpdationRequest.labels.reasonForUpdation'.getLabel()}
                    placeholder={'bloodBankUpdationRequest.placeholders.reasonForUpdation'.getLabel()}
                    variant="filled"
                    mandatory
                    minHeight={92}
                  />
                </View>
                <View
                  style={{
                    position: 'absolute',
                    bottom: 15,
                  }}
                >
                  <LargeButton
                    label={'bloodBankUpdationRequest.buttons.sendForApproval'.getLabel()}
                    onClick={formContext.handleSubmit}
                    width={180}
                    height={40}
                    borderRadius={4}
                  />
                </View>
              </View>
            )}
          </Form>
        </View>
      )}
      {...props}
    />
  );
};
export default IssueBloodRequestMSModal;
