import React from 'react';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import ViewAuctionItemDetail from './Components/ItemDetail';
import { tabTheme } from '../tabTheme';
import ServiceDetail from './Components/ServiceDetail';
import LoanOutHistory from './Components/loanOutHistory';
import RepairHistory from './Components/repairHistory';
import ItemMonthlyRemarksList from './Components/monthlyRemarksHistory';

const ViewAuctionItem = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        itemDetail: {
          label: 'Item Detail',
          screen: ViewAuctionItemDetail,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        serviceHistory: {
          label: 'Service History',
          screen: ServiceDetail,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        loanOutHistory: {
          label: 'Loan Out History',
          screen: LoanOutHistory,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        repairHistory: {
          label: 'Repair History',
          screen: RepairHistory,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
        },
        monthWiseRemarks: {
          label: 'Monthwise Remark',
          screen: ItemMonthlyRemarksList,
          screenProps: {
            stateRequiredOnTop: true,
            item,
          },
          actions: [
            <FtsSearch
              action={{ exp: ['month'] }}
              placeholder="Search by month name"
              key="search"
            />,
          ],
        },
      }}

    />
  );
};
export default ViewAuctionItem;
