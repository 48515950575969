import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  Toast,
  TouchableOpacity,
  View,
} from '../../app-components';
import { AutoSuggestInput } from '../../app-components/input-components/Editors';
import { invoke } from '../../AppServices';
import { getErrorString } from '../../Lib/helpers';
import { moreActionsShadow } from '../../theme/shadows';
import vars from '../../theme/vars';
import {
  backCrossIcon,
  collapseIcon,
  expandIcon,
  newSearchIcon,
} from '../../images';

export const RenderSelector = ({
  value,
  searching,
  modalOpen,
  searchText,
  clearValue,
  clearText,
}) => {
  let onPress = void 0;
  let selectorIcon;
  const extraStyle = {};
  if (searching) {
    if (searchText) {
      selectorIcon = backCrossIcon;
      onPress = clearText;
    } else if (modalOpen) {
      selectorIcon = newSearchIcon;
    } else if (value) {
      selectorIcon = backCrossIcon;
      onPress = clearValue;
    } else {
      selectorIcon = newSearchIcon;
    }
  } else if (modalOpen) {
    selectorIcon = collapseIcon;
    extraStyle.marginTop = 4;
  } else if (value) {
    selectorIcon = backCrossIcon;
    onPress = clearValue;
  } else {
    selectorIcon = expandIcon;
    extraStyle.marginTop = 4;
  }
  const touchProps = {};
  let RenderComponent = View;
  if (onPress) {
    RenderComponent = TouchableOpacity;
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = (e) => {
        e.stopPropagation();
        onPress && onPress(e);
      };
    } else {
      touchProps.onPress = onPress;
    }
  }

  return (
    <RenderComponent
      {...touchProps}
      style={{
        paddingTop: 6,
        paddingBottom: 6,
        cursor: 'pointer',
      }}
    >
      <Image
        source={selectorIcon}
        style={{
          cursor: 'pointer',
          resizeMode: 'contain',
          height: 12.45,
          width: 12.45,
          marginLeft: 4,
          marginRight: 17,
        }}
      />
    </RenderComponent>
  );
};

const RoomDropdown = (props) => {
  const { navigation, item, eventDispatcher } = props;

  const [roomNumber, setRoomNumber] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const room = item?.sessionQueue?.room || {};
    setRoomNumber({
      label: room?.name,
      value: { label: room?.name, value: item?.sessionQueue?._id },
    });
  }, [item]);

  const onSelect = useCallback(async ({ item: room }) => {
    const { value } = room;
    setRoomNumber(room);
    try {
      setLoading(true);
      const result = await invoke({
        id: 'assignQueue',
        paramValue: {
          queueId: value,
          consultationId: item?._id,
        },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'incomingPatient.messages.roomAssignedSuccessfully'.getLabel(),
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        if (result?.token) {
          eventDispatcher.notify('reloadConsultations');
        }
      }
      setLoading(false);
      setLoading(false);
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      setLoading(false);
    }
  }, [eventDispatcher, item._id]);

  const options = useMemo(() => (navigation?._parent().getScreenState()?.activeQueues?.map(
    (queue) => ({
      label: queue?.room?.name,
      value: { label: queue?.room?.name, value: queue?._id },
    }),
  )), [navigation]);

  if (loading) return <ActivityIndicator />;

  // if (item?.status !== 'new' && item?.status !== 'queued') {
  return (
    <Text
      style={[
        vars.headings.h9,
        { color: vars.colors.grey.color1 },
      ]}
    >
      {item?.sessionQueue?.room?.name || '--'}
    </Text>
  );
  // }

  // return (
  //   <AutoSuggestInput
  //     renderSelector={RenderSelector}
  //     variant="filled"
  //     keyField="value"
  //     valueField="value"
  //     sugestionField="label"
  //     placeholder="--"
  //     options={options}
  //     value={roomNumber?.label}
  //     onItemSelect={onSelect}
  //     style={{
  //       backgroundColor: vars.colors.grey.color6,
  //       width: 142,
  //       height: 24,
  //       borderRadius: 4,
  //       outline: 'none',
  //     }}
  //     inputStyle={{
  //       outline: 'none',
  //       border: 0,
  //       paddingLeft: 12,
  //       backgroundColor: 'transparent',
  //       ...vars.headings.h13,
  //       color: vars.colors.grey.color3,
  //       width: 142,
  //       height: 24,
  //     }}
  //     dropdownStyle={{
  //       height: 150,
  //       borderRadius: 8,
  //       backgroundColor: vars.colors.white,
  //       ...moreActionsShadow,
  //     }}
  //   />
  // );
};
export default RoomDropdown;
