import React from 'react';
import { save } from '../../../../../app-components';

import { Form } from '../../../../../app-components/form/Form';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../AppServices';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import ListActionModal from '../../../../../Components/ListActionModal';
import { history } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import { ViewBillHistoryUri } from '../../../../../Queries/billing';
import vars from '../../../../../theme/vars';

const ViewBillHistory = (props) => (
  <ListActionModal
    icon={history}
    title="Bill history"
    content={(
      <Form
        type="standardShadow"
        uri={ViewBillHistoryUri}
        closeView={1}
        lg={{
          formGroups: [
            {
              columnsPerRow: 3,
              fieldVariant: 'filled',
              label: 'employee.labels.personalDetails'.getLabel(),
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'text',
                      field: 'patientId.uhid',
                      label: 'Uhid',
                      editable: false,
                    },
                    {
                      type: 'text',
                      field: 'patientId.patientName',
                      label: 'Patient Name',
                      editable: false,
                    },
                    {
                      type: 'text',
                      field: 'patientType',
                      label: 'Patient Type',
                      editable: false,
                    },
                    {
                      type: 'text',
                      field: 'billAmount',
                      label: 'Bill Amount',
                      editable: false,
                    },
                  ],
                },
              ],
            },
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              label: 'Test Details',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      field: 'labDetails',
                      fieldType: 'nested',
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        skipTableHeaderOnNoData: true,
                        listProps: {
                          hideColumnHeader: false,
                          columns: [
                            autoSuggestInput({
                              type: 'autoSuggest',
                              field: 'testType',
                              header: 'Test Type',
                              label: 'billing.labels.testType'.getLabel(),
                              variant: 'filled',
                              valueField: 'name',
                              editable: false,
                            }),
                            autoSuggestInput({
                              type: 'autoSuggest',
                              field: 'labType',
                              header: 'Lab Type',
                              label: `${'billing.labels.labType'.getLabel()}`,
                              variant: 'filled',
                              valueField: 'name',
                              editable: false,
                            }),
                            {
                              type: 'text',
                              header: 'Amount',
                              variant: 'filled',
                              field: 'amount',
                              editable: false,
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        }}

        {...props}
      />
        )}
    {...props}
  />
);

export default ViewBillHistory;
