import React, { useMemo } from 'react';
import moment from 'moment';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from '../../app-components';

import { getRedirectionViewDetails } from '../../Lib/helpers';
import { notificationReadIcon } from '../../images';
import { notificationCardTheme } from './theme';
import { markRead } from '../../AppServices';

export const dateFormatter = (value, isZeroTime, format = 'DD MMM,YY') => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  let formattedValue = moment(value);
  if (isZeroTime) {
    formattedValue = formattedValue.utc();
  }
  if (format === 'fromNow') {
    /* return how much time is elapsed like == 1 min ago or 24 days ago etc */
    return formattedValue.fromNow();
  }
  return formattedValue.format(format);
};

const handleOnPressNotification = ({
  navigation, hideModal, eventDispatcher, item = {}, view, params,
}) => {
  markRead({ id: item?._id })
    .then((res) => {
      eventDispatcher.notify('updateNotifications');
      return res;
    })
    .catch((err) => err);

  if (view) {
    navigation.reset({
      view: 'home',
    });

    setTimeout(() => {
      navigation.push({ view, params });
    }, 100);
  }
  hideModal();
};

const {
  containerStyle,
  infocontainerStyle,
  headerViewStyle,
  headerTextStyle,
  messageViewStyle,
  messageTextStyle,
  dateTextStyle,
} = notificationCardTheme;

const NotificationCard = ({
  eventDispatcher, hideModal, navigation, item = {},
}) => {
  const {
    message = '', title = 'N/A', _createdOn,
  } = item;

  const { action, view, params = {} } = useMemo(
    () => getRedirectionViewDetails(item?.type, item), [item],
  );
  const newCreationDate = useMemo(() => moment(_createdOn).format('YYYY-MM-DD'), [_createdOn]);
  const notificationDayAndTime = useMemo(() => (moment(newCreationDate).isSame(moment().format('YYYY-MM-DD'))
    ? dateFormatter(_createdOn, false, 'fromNow') : dateFormatter(_createdOn, false)), [_createdOn, newCreationDate]);

  return (
    <TouchableOpacity
      onPress={() => handleOnPressNotification({
        navigation,
        eventDispatcher,
        hideModal,
        title,
        item,
        view,
        params,
      })}
      style={containerStyle}
    >
      <View style={infocontainerStyle}>
        <View style={headerViewStyle}>
          <View style={{ flex: 6, marginTop: 9, marginBottom: 4 }}>
            <Text title={message} style={headerTextStyle}>
              {title}
            </Text>
          </View>
          <View style={{
            flex: 4, alignItems: 'flex-end', marginTop: 9, marginBottom: 4,
          }}
          >
            <Text style={{ ...dateTextStyle }}>
              {notificationDayAndTime}
            </Text>
          </View>
        </View>
        <View style={{ ...messageViewStyle }}>
          <View style={{ flex: 9.5 }}>
            <Text title={message} style={{ ...messageTextStyle }} numberOfLines={2}>
              {message}
            </Text>
          </View>
          <View style={{
            marginTop: 7, flex: 0.5, alignItems: 'center',
          }}
          >
            {item?.isRead ? null
              : <Image source={notificationReadIcon} style={{ height: 8, width: 8 }} />}
          </View>
        </View>
        {action && action.render && action.render({
          ...params, navigation, eventDispatcher, afterClick: handleOnPressNotification, hideModal,
        })}
      </View>
    </TouchableOpacity>
  );
};

export default NotificationCard;
