import React from 'react';
import { OutlineButton, Text } from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import PatientHistoryList from './PatientHistoryList';
import ViewPatientHistory from './ViewPatientHistory';
import ViewDiagnosis from '../IncomingPatients/ConsultedPatients/Components/viewDiagnosisModal';

export default {
  [ROUTES.opdConsultedPatientList.name]: {
    screen: PatientHistoryList,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
    },
  },
  [ROUTES.opdIncomingPatientsViewDiagnosis.name]: {
    screen: ViewDiagnosis,
    modal: true,
    modalProps: {
      width: 800,
      height: 500,
    },
  },
  [ROUTES.viewOpdConsultedPatientHistory.name]: {
    screen: ViewPatientHistory,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.patient?.uhid}
        </Text>
      );
    },
    headerActions: [
      {
        render: ({ navigation }) => (
          <OutlineButton
            label={'consultation.buttons.viewConsultationLog'.getLabel()}
            onPress={
              () => {
                const item = navigation?.getParams('item');
                navigation.push({
                  view: ROUTES.doctorConsultationList.name,
                  modal: true,
                  params: {
                    item,
                    model: MODELS.CONSULTATION,
                    id: 'consultationDataList',
                    patientName: item?.patient?.patientName,
                    addOnFilter: {
                      patient: {
                        _id: item?.patient?._id,
                      },
                    },
                  },
                });
              }
            }
          />
        ),
      },
      {
        render: ({ navigation }) => (
          <OutlineButton
            label={'consultation.buttons.viewVaccinationLog'.getLabel()}
            onPress={
              () => {
                const item = navigation?.getParams('item');
                navigation.push({
                  view: ROUTES.doctorVaccinationList.name,
                  modal: true,
                  params: {
                    item,
                    model: MODELS.PATIENT_VACCINATION_HISTORY,
                    id: 'patientVaccinationHistory',
                    patientName: item?.patient?.patientName,
                    addOnFilter: {
                      patient: {
                        _id: item?.patient?._id,
                      },
                    },
                  },
                  modalProps: {
                    autoHide: true,
                    width: 696,
                    height: 516,
                  },
                });
              }
            }
          />
        ),
      },
    ],
  },
};
