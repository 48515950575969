import React, { useState } from 'react';
import moment from 'moment';

import { Text, View } from '../../../../app-components';
import { autoSuggestInput, searchInput } from '../../../../autoSuggestions';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../../app-components/form/Form';
import { EditUnknownPatientUri } from '../../../../Queries/patient';

const UnknownEditPatientForm = (props) => {
  const [serialNo, setSerialNo] = useState(1);

  return (
    <Form
      type="standard"
      {...props}
      closeView={1}
      onSubmit={submit({
        model: MODELS.PATIENTS,
      })}
      uri={EditUnknownPatientUri}
      submitMessage={'patient.messages.patientUpdatedSuccessfully'.getLabel()}
      defaultValues={{
        department: 'IPD',
        isUnknown: true,
      }}
      mandatory={{
        belongingsDetails: {
          itemName: 1,
        },
        patientName: 1,
        speciality: 1,
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 1,
                width: 300,
                columns: [
                  {
                    type: 'uploadImage',
                    topLabel: 'Upload Image here',
                    field: 'patientImage',
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'date',
                    field: 'date',
                    label: 'patient.labels.date'.getLabel(),
                    render: () => (
                      <Text style={{ ...vars.headings.h9 }}>
                        {moment().format('DD/MM/YYYY')}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'time',
                    field: 'time',
                    label: 'patient.labels.time'.getLabel(),
                    // value:()=>{}
                    // render: () => (
                    //   <Text style={{ ...vars.headings.h9 }}>
                    //     {moment().format('HH MM')}
                    //   </Text>
                    // ),
                    editable: false,
                  },
                  {
                    label: 'patient.headers.uhid'.getLabel(),
                    type: 'text',
                    field: 'uhid',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'patient.labels.patientName'.getLabel(),
                    allowWhiteSpaces: true,
                    mandatory: true,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (validateAlphabetsWithSpaceOnly(value)) {
                        return prevValue;
                      }
                      return value.replace(/ {2,}/g, ' ');
                    },
                  },
                  autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'patient.labels.department'.getLabel(),
                      placeholder: 'patient.placeholders.selectDepartment'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      editable: false,
                    },
                    {
                      addOnFilter: {
                        code: 'IPD',
                      },
                    },
                  ),
                  searchInput(
                    {
                      field: 'speciality',
                      label: 'patient.labels.speciality'.getLabel(),
                      placeholder: 'patient.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      searchField: 'name',
                      searching: true,
                      mandatory: true,
                    },
                    {
                      addOnFilter: { department: { code: 'IPD' } },
                    },
                  ),
                  {
                    type: 'textArea',
                    field: 'uniqueMark',
                    label: 'patient.labels.uniqueMark'.getLabel(),
                    placeholder: 'patient.placeholders.uniqueMark'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'remarks',
                    label: 'patient.labels.remarks'.getLabel(),
                    placeholder: 'patient.placeholders.remarks'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            label: 'patient.title.belongingsDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  searchInput({
                    field: 'belongingsCareBy',
                    type: 'autoSuggest',
                    variant: 'filled',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    placeholder: 'patient.placeholders.belongingsCareBy'.getLabel(),
                    searching: true,
                    searchField: 'name',
                  }),
                  {
                    type: 'textArea',
                    field: 'additionalComments',
                    variant: 'filled',
                    header: 'patient.labels.additionalComments'.getLabel(),
                    placeholder: 'patient.placeholders.additionalComments'.getLabel(),
                    style: { flex: 2 },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'belongingsDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: (props) => {
                        const { data: { belongingsDetails = [] } } = props;
                        setSerialNo(belongingsDetails?.length);
                        if (serialNo <= belongingsDetails?.length) {
                          belongingsDetails.map((name, index) => name.serialNo = index + 1);
                        }
                        return {
                          leftActions: [{
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                              >
                                <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                  {`+ ${'inventory.labels.addRow'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: props?.addRow,
                          }],
                        };
                      },
                      skipTableHeaderOnNoData: false,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          backgroundColor: vars.colors.grey,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 12,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'vaccination.headers.serialNo'.getLabel(),
                            field: 'serialNo',
                            variant: 'filled',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            field: 'itemName',
                            variant: 'filled',
                            header: 'patient.labels.itemName'.getLabel(),
                            placeholder: 'patient.placeholders.itemName'.getLabel(),
                            allowWhiteSpaces: true,
                            width: 200,
                          },
                          {
                            type: 'textArea',
                            field: 'description',
                            variant: 'filled',
                            header: 'patient.labels.description'.getLabel(),
                            placeholder: 'patient.placeholders.description'.getLabel(),
                            width: 700,
                          },
                          {
                            type: 'file',
                            field: 'itemImage',
                            variant: 'filled',
                            header: 'patient.labels.itemImage'.getLabel(),
                            placeholder: 'patient.placeholders.itemImage'.getLabel(),
                            width: 150,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default UnknownEditPatientForm;
