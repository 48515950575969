import React, { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import {
  View,
  Text,
  FormField,
} from '../../../../app-components';
import { getModulesPermissions } from '../../../../Lib/helpers';
import Collapsible from '../../../../Components/Collapsible';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { EditRole } from '../../../../Queries/role';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import theme from '../theme';
// eslint-disable-next-line import/no-cycle
import ViewRolesTabs from './components/Tabs';

const modules = getModulesPermissions();

const ViewRole = (props) => {
  const submitAPI = submit({
    model: MODELS.ROLE,
    ...props,
  });

  const handleOnChangeOnSubmit = useCallback((data) => {
    const clonedData = cloneDeep(data);
    if (clonedData?.updates?.permissions) {
      clonedData.updates.editablePermissions = clonedData?.updates?.permissions;
      delete clonedData?.updates?.permissions;
    }
    submitAPI(clonedData);
  }, [submitAPI]);

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Form
        scrollable
        onSubmit={handleOnChangeOnSubmit}
        uri={EditRole}
        submitMessage="Role edited successfully"
        reloadEvent="reloadroles"
        reloadOnChangeEvent="reloadroles"
        {...props}
      >
        <ViewRoleInfoForm {...props} />
      </Form>
    </View>

  );
};

const ViewRoleInfoForm = (props) => {
  const {
    form_context: formContext = {}, form_state: { data } = {},
  } = props;
  const { setValue } = formContext;
  return (
    <View
      style={theme.containerStyle}
    >
      <View style={theme.infoFormWrapper}>
        <View
          style={theme.roleInputWrapper}
        >
          <FormField
            field="name"
            label="Role"
            type="text"
            placeholder="Role"
            variant="filled"
            mandatory
            editable={false}
          />
        </View>
        <View
          style={theme.statusWrapper}
        >
          <FormField
            field="isActive"
            type="status"
            label="Status"
            placeholder="Status"
            variant="filled"
            mandatory
            editable={false}
          />
        </View>
        <View
          style={theme.descriptionWrapper}
        >
          <FormField
            field="description"
            type="textArea"
            label="Description"
            placeholder="Description"
            variant="filled"
            editable={false}
          />
        </View>
        <View
          style={theme.descriptionWrapper}
        >
          <FormField
            field="_createdOn"
            type="date"
            label="Created On"
            placeholder="Created On"
            variant="filled"
            editable={false}
            format="DD MMM YYYY @ hh:mm A"
          />
        </View>
      </View>
      <ViewRolesTabs setValue={setValue} formContext={formContext} data={data} />
    </View>
  );
};
export const PermissionsForm = (props) => {
  const keys = Object.keys(modules);
  const { data: { permissions } = {} } = props;

  return (
    <View
      style={{
        marginLeft: 18,
        marginRight: 30,
        flex: 1,
        overflow: 'auto',
      }}
    >
      <View
        style={theme.permissionView}
      >
        <Text
          style={
            [vars.headings.h16, { color: vars.colors.grey.color3 }]
          }
        >
          PERMISSIONS
        </Text>
      </View>
      {
        keys.map((item, index) => (
          <View
            key={index}
          >
            <PermissionsViewsForm
              views={modules[item].views}
              value={modules[item].value}
              subLabel={modules[item].subLabel}
              label={modules[item].label}
              permissions={permissions}
              key={permissions}
              {...props}
            />
          </View>
        ))
      }
    </View>
  );
};

const PermissionsViewsForm = (props) => {
  const {
    views, label, permissions, value,
  } = props;

  return (
    <View
      style={theme.completeWidth}
    >
      { permissions && permissions[value] && (
        <Collapsible
          title={label}
          content={

            views.map((item, index) => (
              <View key={index}>
                <View
                  style={theme.center}
                  key={index}
                >
                  {
                    item.permissions.map((
                      permission, uniqueIndex,
                    ) => permissions && permissions[permission.value] && (
                      <View
                        key={uniqueIndex}
                        style={{
                          margin: 10,
                          height: 24,
                          borderRadius: 12,
                          backgroundColor: vars.colors.secondary.color4,
                          padding: 4,
                        }}
                      >
                        <Text
                          style={
                            [vars.headings.h9, { color: vars.colors.grey.color1 }]
                          }
                        >
                          {
                            permission.all ? item.label
                              : `${item.label} (${permission.label})`
                          }
                        </Text>

                      </View>
                    ))
                  }
                </View>
              </View>
            ))
          }
        />
      )}
    </View>
  );
};

export default ViewRole;
