import React from 'react';

import { editAdmitIPDPatientUri } from '../../../../../../../Queries/patient';
import { Form } from '../../../../../../../app-components/form/Form';
import vars from '../../../../../../../theme/vars';
import { Text } from '../../../../../../../app-components';

const Patient = (props) => (
  <Form
    type="standardShadow"
    bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
    closeView={1}
    editable={false}
    uri={editAdmitIPDPatientUri}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 10,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'patient.uhid',
                  label: 'UHID',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'patient.patientName',
                  label: 'Full Name',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'Gender',
                  render: ({ data }) => (
                    <Text style={{ fontSize: 14, lineHeight: 16 }}>
                      {data?.patient?.gender?.charAt(0)?.toUpperCase()}
                      {''}
                      {data?.patient?.gender?.slice(1)}
                    </Text>
                  ),
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'patient.age',
                  label: 'Age',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'Relation',
                  render: ({ data }) => (
                    <Text style={{ fontSize: 14, lineHeight: 16, color: 'rgb(51, 51, 51)' }}>

                      {data?.patient?.relation}
                      {' '}
                      {data?.patient?.relationName}
                    </Text>
                  ),
                },
                {
                  type: 'number',
                  field: 'patient.aadharNumber',
                  label: 'Aadhar Number',
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'patient.BPLNumber',
                  label: 'BPL Number',
                  mandatory: true,
                },
                {
                  type: 'number',
                  field: 'patient.mobile',
                  label: 'Mobile',
                  mandatory: true,
                },
                {
                  type: 'date',
                  label: 'Date of Admission',
                  field: '_createdOn',
                  format: 'MMM DD YYYY',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'No of Attendant Card issued',
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'patient.address',
                  label: 'Address',
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);
export default Patient;
