import React from 'react';

import RelationInput from '../../../../../Components/RelationInput';
import { Form } from '../../../../../app-components/form/Form';
import { iPDPatientUri } from '../../../../../Queries/patient';
import { Text } from '../../../../../app-components';
import { MODELS } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import Consent from './Consent';

const ConsentForm = (props) => {
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      uri={iPDPatientUri({ data: paramsData })}
      closeView={2}
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        const newUpdate = { ...updates, ...paramsData };
        delete newUpdate._id;
        const submitAPI = submit({
          model: MODELS.IPD_ADMISSION,
          ...submitProps,
          data,
          updates: newUpdate,
        });
        await submitAPI({ data, updates: newUpdate });
      }}
      mandatory={{
        'consentForm.consentingPersonName': 1,
      }}
      submitMessage="Consent Form Saved"
      reloadEvent="reloadIPDRegisteredPatients"
      reloadOnChangeEvent={`reload${MODELS.IPD_ADMISSION}`}
      defaultValues={() => ({
        patient: paramsData.patient,
        patientName: paramsData.patientName,
        CRNumber: paramsData.CRNumber,
        age: paramsData.age,
        ageType: paramsData.ageType,
        department: paramsData.department,
        speciality: paramsData.speciality,
        remarks: paramsData.remarks,
        room: paramsData.rooms,
        annualIncome: paramsData.annualIncome,
        ICDCode: paramsData.ICDCode,
        consentForm: ConsentForm,
      })}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'text',
                    field: 'patient.patientName',
                    label: 'Patient Name',
                    width: '25%',
                  },
                  {
                    type: 'text',
                    field: 'patient.gender',
                    label: 'Gender',
                    width: '25%',
                  },
                  {
                    type: 'text',
                    field: 'patient.age',
                    label: 'Age',
                    width: '25%',
                  },
                  {
                    type: 'custom',
                    defaultValues: 'S/O',
                    render: () => (
                      <RelationInput
                        relationFieldName="consentForm.patientRelation"
                        relationNameFieldName="consentForm.patientRelativeName"
                        relationNameProps={{
                          allowWhiteSpaces: true,
                        }}
                      />
                    ),
                    width: '25%',
                  },
                  {
                    type: 'text',
                    field: 'consentForm.consentingPersonName',
                    label: 'Name of the Consenting Person',
                    allowWhiteSpaces: true,
                    editable: true,
                    mandatory: true,
                    width: '25%',

                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 1,
            render: () => (
              <Text> CONSENT  </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            render: () => (
              <Text>
                <Consent {...paramsData} />
              </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 1,
            render: () => (
              <Text> FORM INFORMATION   </Text>
            ),
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'consentForm.witnessOneName',
                    label: 'Witness 1 Name',
                    allowWhiteSpaces: true,
                    editable: true,
                    width: '23%',
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessOneAddress',
                    label: 'Witness 1 Address',
                    allowWhiteSpaces: true,
                    editable: true,
                    width: '74%',
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessTwoName',
                    label: 'Witness 2 Name',
                    allowWhiteSpaces: true,
                    editable: true,
                    width: '23%',
                  },
                  {
                    type: 'text',
                    field: 'consentForm.witnessTwoAddress',
                    label: 'Witness 2 Address',
                    allowWhiteSpaces: true,
                    editable: true,
                    width: '74%',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            columnsPerRow: 4,
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'consentForm.relativeName',
                    label: 'Patient/Relative Name',
                    allowWhiteSpaces: true,
                    editable: true,
                  },
                  {
                    type: 'custom',
                    render: () => (
                      <RelationInput
                        relationFieldName="consentForm.consentingRelation"
                        relationNameFieldName="consentForm.consentingRelativeName"
                        relationNameProps={{
                          allowWhiteSpaces: true,
                        }}
                      />
                    ),
                  },
                  {
                    type: 'number',
                    field: 'consentForm.consentingMobileNumber',
                    label: 'Mobile No. of Patient/Relative',
                    editable: true,
                  },
                  {
                    type: 'text',
                    field: 'consentForm.consentingAddress',
                    label: 'Address of Patient/Relative',
                    allowWhiteSpaces: true,
                    editable: true,
                  },
                  {
                    type: 'date',
                    field: 'startTime',
                    label: 'Date of Admission',
                    editable: false,
                    defaultValue: new Date().toLocaleTimeString(),
                  },
                  {
                    type: 'date',
                    defaultValue: new Date().toLocaleDateString(),
                    field: 'iPDBedsAllocation.startTime',
                    label: 'Time of Admission',
                    editable: false,
                  },
                  {
                    type: 'file',
                    field: 'consentForm.attachments',
                    label: 'Drop & Drag Your File',
                    editable: true,
                    multiple: true,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default ConsentForm;
