import React from 'react';
import { DateFilter, FilterGroup, TabNavigator } from '../../../../../app-components';
import { searchFilter } from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';
import { countUri } from '../../../../../Queries/countUri';
import vars from '../../../../../theme/vars';
import tabTheme from '../../../../../theme/childTabTheme';
import { AllList } from './Attendance/lists';

export default TabNavigator({
  theme: { ...tabTheme },
  actions: [
    <DateFilter
      field="attendance_date"
      key="attendance_date"
      containerStyle={{
        flexDirection: 'row',
        width: 303,
        height: 36,
        borderWidth: 1,
        padding: 9,
        borderRadius: 4,
        borderStyle: 'solid',
        backgroundColor: vars.colors.white,
        borderColor: vars.colors.grey.color6,
      }}
    />,
    FilterGroup({
      filters: [
        searchFilter({
          field: 'employee',
          model: MODELS.EMPLOYEE,
          id: 'employeeLists',
          searchField: 'name',
          label: 'Employee',
          suggestionField: 'fullName',
          multiSelect: true,
          filterTabs: ['pending', 'approved', 'rejected'],
        },
        {
          modifyResult: ({ data }) => {
            const clonedData = data.map((item) => ({
              ...item,
              fullName: `${item?.firstName} ${item?.lastName}`,
            }));

            return { data: clonedData };
          },
        }),
        searchFilter({
          field: 'employee.department',
          model: MODELS.DEPARTMENTS,
          id: 'masterDataList',
          searchField: 'name',
          label: 'Department',
          suggestionField: 'name',
          multiSelect: true,
          filterTabs: ['pending', 'approved', 'rejected'],
        }),
      ],
    }),
  ],
  tabs: {
    pending: {
      label: 'Pending',
      reloadEvent: 'AllList',
      screen: AllList,
      countUri: countUri({
        model: MODELS.ATTENDANCE_REQUESTS,
        id: 'attendanceRequests',
        filter: {
          status: 'unapproved',
        },
      }),
    },
    approved: {
      label: 'Approved',
      reloadEvent: 'AllList',
      screen: AllList,
      countUri: countUri({
        model: MODELS.ATTENDANCE_REQUESTS,
        id: 'attendanceRequests',
        filter: {
          status: 'approve',
        },
      }),
    },
    rejected: {
      label: 'Rejected',
      reloadEvent: 'AllList',
      screen: AllList,
      countUri: countUri({
        model: MODELS.ATTENDANCE_REQUESTS,
        id: 'attendanceRequests',
        filter: {
          status: 'reject',
        },
      }),
    },
    all: {
      label: 'All',
      reloadEvent: 'AllList',
      screen: AllList,
      countUri: countUri({
        model: MODELS.ATTENDANCE_REQUESTS,
        id: 'attendanceRequests',
        filter: { },
      }),
    },
  },
});
