import React from 'react';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import BillsAndContingentOrders from '../BillsAndContingentOrders';
import SupplyOrders from '../SupplyOrders';

const AccountsTabNavigation = (props) => (
  <TabNavigator
    tabs={{
      Salary: {
        label: 'Supply Orders',
        screen: SupplyOrders,
      },
      TaxDeclaration: {
        label: 'Bills and Contingent Voucher',
        screen: BillsAndContingentOrders,
      },
    }}
    {...props}
  />
);

export default AccountsTabNavigation;
