import React from 'react';

import {
  FormField,
  View,
  Text,
} from '../../../../app-components';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../../app-components/form/Form';
import ListActionModal from '../../../../Components/ListActionModal';
import { vaccinationType } from '../../../../Lib/constants';
import { detailIcon } from '../../../../images';
import vars from '../../../../theme/vars';

const VaccinatedPatientDetailModal = (props) => {
  const { navigation: { state: { params } } } = props;
  const { vaccinationSoFar = [], vaccineDoseDetail = [], item } = params;
  for (const eachVaccineIdx in vaccineDoseDetail) {
    if (vaccinationSoFar[eachVaccineIdx]?.doseProvidedDate) {
      vaccineDoseDetail[eachVaccineIdx].doseProvidedDate = vaccinationSoFar[eachVaccineIdx].doseProvidedDate;
      vaccineDoseDetail[eachVaccineIdx].status = 'Provided';
    } else {
      vaccineDoseDetail[eachVaccineIdx].status = 'Pending';
    }
  }

  return (
    <ListActionModal
      icon={detailIcon}
      popCount={2}
      description="Details and status of vaccine"
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({
            VaccineDoseDetail: vaccineDoseDetail,
          })}
          {...props}
        >
          {() => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ width: 228 }}>
                <FormField
                  type="text"
                  label={'vaccination.labels.status'.getLabel()}
                  variant="filled"
                  editable={false}
                  render={() => {
                    const { vaccinationSoFar } = params;
                    let status = '';
                    let color = '';
                    if (item?.noOfDoses === vaccinationSoFar?.length) {
                      status = 'Vaccinated';
                      color = vars.colors.primary.color2;
                    } else {
                      color = vars.colors.warning;
                      status = 'On-going';
                    }
                    return (
                      <Text style={{ ...vars.headings.h8, color }}>
                        {status}
                      </Text>
                    );
                  }}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <Text style={{ ...vars.headings.h9 }}>
                  VACCINE INFORMATION
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.vaccineName'.getLabel()}
                    variant="filled"
                    value={params.item?.name}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.reVaccinationGap'.getLabel()}
                    variant="filled"
                    field=""
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.noOfDose'.getLabel()}
                    variant="filled"
                    value={params.item?.noOfDoses}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.description'.getLabel()}
                    variant="filled"
                    value={params?.item?.description}
                  />
                </View>
              </View>

              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                {item?.vaccinationType === vaccinationType?.onceInLifeTime ? (
                  <View style={{ flex: 1 }}>
                    <FormField
                      editable={false}
                      type="date"
                      label={'vaccination.labels.lastVaccinationDate'.getLabel()}
                      variant="filled"
                    />
                  </View>
                ) : null}
                {item?.vaccinationType === vaccinationType?.onceInLifeTime ? (
                  <View style={{ flex: 1 }}>
                    <FormField
                      editable={false}
                      type="text"
                      label={'vaccination.labels.paymentType'.getLabel()}
                      variant="filled"
                      value={item?.item?.isVaccineFree ? 'Free' : 'Paid'}
                    />
                  </View>
                ) : null}
                {item?.vaccinationType === vaccinationType?.onceInLifeTime ? (
                  <View style={{ flex: 1 }}>
                    <FormField
                      editable={false}
                      type="date"
                      label={'vaccination.labels.paymentStatus'.getLabel()}
                      variant="filled"
                      render={() => (
                        <Text
                          style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}
                        >
                          Paid
                        </Text>
                      )}
                    />
                  </View>
                ) : null}
                <View style={{ flex: 1 }} />
              </View>
              {item?.vaccinationType === vaccinationType?.reCurring ? (
                <View style={{ flexDirection: 'row', marginTop: 10 }}>
                  <Text style={{ ...vars.headings.h9 }}>
                    DOSE DETAILS
                  </Text>
                </View>
              ) : null}
              {item?.vaccinationType === vaccinationType?.reCurring ? (
                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                  <View>
                    <FormField
                      {...{
                        fieldVariant: 'filled',
                        field: 'VaccineDoseDetail',
                        fieldType: 'nested',
                        label: 'DOSE DETAILS ',
                        columnsPerRow: 4,
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowContainerStyle: {
                              minHeight: 32,
                              backgroundColor: vars.colors.white,
                            },
                            headerRowColumnTextStyle: {
                              paddingLeft: 12,
                              ...vars.headings.h13,
                              color: vars.colors.grey.color3,
                              numberOfLines: 1,
                              textTransform: 'upperCase',
                            },
                            columns: [
                              {
                                type: 'text',
                                header: 'vaccination.headers.doseNo'.getLabel(),
                                field: 'serialNo',
                                variant: 'filled',
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'date',
                                variant: 'filled',
                                header: 'vaccination.headers.doseDuseDate'.getLabel(),
                                field: 'doseDueDate',
                                editable: false,
                                width: 200,
                              },
                              {
                                type: 'date',
                                variant: 'filled',
                                header: 'vaccination.headers.doseProvidedDate'.getLabel(),
                                field: 'doseProvidedDate',
                                editable: false,
                                width: 200,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.doseStatus'.getLabel(),
                                render: (renderProps) => {
                                  const { item: { doseProvidedDate = '' } } = renderProps;
                                  let statusColor = '';

                                  if (doseProvidedDate === '') {
                                    statusColor = vars.colors.warning;
                                  } else {
                                    statusColor = vars.colors.primary.color2;
                                  }

                                  return (
                                    <Text style={{ color: statusColor }}>
                                      {doseProvidedDate !== '' ? 'Provided' : 'Pending'}
                                    </Text>
                                  );
                                },
                                editable: false,
                                width: 200,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.paymentStatus'.getLabel(),
                                field: 'payment',
                                editable: false,
                                width: 200,

                              },
                            ],
                          },
                        },
                      }}
                    />
                  </View>
                </View>
              ) : null}
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default VaccinatedPatientDetailModal;
