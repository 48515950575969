import React, { useCallback, useEffect } from 'react';

import { DateFilter, FilterGroup } from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { searchFilter } from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';
import { countUri } from '../../../../../Queries/countUri';
import vars from '../../../../../theme/vars';
import tabTheme from '../../../../../theme/childTabTheme';
import { LeaveRequestList } from './Requests/Lists';

const getLeaveRequestFilter = (status) => ({
  status,
});

const LeaveRequestTabNavigation = (props) => {
  const { navigation } = props;
  const { state: { params: { item: { user = {} } = {} } = {} } = {} } = navigation;
  const setDefaultFilter = useCallback(() => {
    let dataParams = {};
    dataParams = {
      filters: {
        user: { filter: { value: user }, value: user },
      },
    };
    navigation.setScreenState({ dataParams });

    return { dataParams };
  }, []);

  useEffect(() => {
    setDefaultFilter();
  }, [setDefaultFilter]);

  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      actions={[
        <DateFilter
          field="request_date"
          key="_createdOn"
          containerStyle={{
            flexDirection: 'row',
            width: 303,
            height: 36,
            borderWidth: 1,
            padding: 9,
            borderRadius: 4,
            borderStyle: 'solid',
            backgroundColor: vars.colors.white,
            borderColor: vars.colors.grey.color6,
          }}
        />,
        FilterGroup({
          filterTabs: true,
          filters: [
            searchFilter({
              field: 'user',
              model: MODELS.EMPLOYEE,
              id: 'employeeLists',
              searchField: 'firstName',
              label: 'Employee',
              suggestionField: 'fullName',
              multiSelect: true,
              filterTabs: ['pending', 'approved', 'rejected'],
            },
            {
              modifyResult: ({ data }) => {
                const clonedData = data.map((item) => ({
                  ...item,
                  fullName: `${item?.firstName} ${item?.lastName}`,
                }));

                return { data: clonedData };
              },
            }),
            searchFilter({
              field: 'leave_type_id',
              model: MODELS.LEAVE_TYPE,
              id: 'leaveTypeSuggestions',
              searchField: 'name',
              label: 'Leave Type',
              suggestionField: 'name',
              multiSelect: true,
              filterTabs: true,
            }),
          ],
        }),
      ]}
      tabs={{
        pending: {
          label: 'Pending',
          screen: LeaveRequestList,
          reloadEvent: 'reloadLeaveRequestLists',
          countUri: countUri({
            model: MODELS.REQUESTS,
            id: 'leaveRequests',
            filter: getLeaveRequestFilter('unapproved'),
          }),
        },
        approved: {
          label: 'Approved',
          screen: LeaveRequestList,
          reloadEvent: 'reloadLeaveRequestLists',
          countUri: countUri({
            model: MODELS.REQUESTS,
            id: 'leaveRequests',
            filter: getLeaveRequestFilter('approve'),
          }),
        },
        rejected: {
          label: 'Rejected',
          screen: LeaveRequestList,
          reloadEvent: 'reloadLeaveRequestLists',
          countUri: countUri({
            model: MODELS.REQUESTS,
            id: 'leaveRequests',
            filter: getLeaveRequestFilter('reject'),
          }),
        },
        all: {
          label: 'All',
          screen: LeaveRequestList,
          reloadEvent: 'reloadLeaveRequestLists',
          countUri: countUri({
            model: MODELS.REQUESTS,
            id: 'leaveRequests',
            filter: {},
          }),
        },
      }}
      {...props}
    />
  );
};

export default LeaveRequestTabNavigation;
