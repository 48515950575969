import { MODELS } from '../Lib/constants';

export const EditDiseaseUri = ({ navigation }) => ({
  query: {
    model: MODELS.DISEASE,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.DISEASE,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.DISEASE,
});

export const DiseaseList = () => ({
  query: {
    model: MODELS.DISEASE,
    id: 'masterDataListForTable',
  },
  model: MODELS.DISEASE,
});
