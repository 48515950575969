import React from 'react';

import {
  View, Text, LargeButton, OutlineButton, Image, TouchableOpacity,
} from '../../../../../app-components';
import { backCrossIcon } from '../../../../../images';
import vars from '../../../../../theme/vars';
import DiagnosisInput from './diagnosisInput';
import RemarksInput from './remarksInput';
import UploadInputOnChange from './UploadInputOnChange';

const FieldItem = ({ label, value = '--', style = {} }) => (
  <View style={{ ...style }}>
    <Text style={{ color: vars.colors.grey.color3, marginBottom: 4 }}>{label}</Text>
    <Text style={{
      fontSize: 14,
      color: vars.colors.grey.color1,
      fontWeight: 500,
    }}
    >
      {value}
    </Text>
  </View>
);

const IncomingPatientDetails = (props) => {
  const {
    patientDetails,
    handleOnPressViewConsultationLog,
    handleOnPressViewVaccinationLog,
    allowToUpdateConsultation,
    token,
    handleOnPressConsulted,
    handleOnPressNotAppeared,
    handleOnPressHideDetails,
    consultationId,
    remarks,
    prescription,
    diagnosis,
    handleOnPressAddDiagnosis,

  } = props;

  return (
    <View style={{
      borderColor: vars.colors.secondary.color2,
      borderWidth: 2,
      borderRadius: 10,
      padding: 20,
      margin: 40,
      minWidth: 350,
    }}
    >
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      >
        <View>
          <Text style={{
            fontSize: 42,
            color: vars.colors.secondary.color2,
            marginBottom: 12,
          }}
          >
            {token}
          </Text>
          <Text style={{
            fontSize: 18,
            color: vars.colors.grey.color1,
            marginBottom: 4,
          }}
          >
            {patientDetails?.patientName}
          </Text>
          <Text style={{
            fontSize: 14,
            color: vars.colors.grey.color1,
          }}
          >
            {`UHID: ${patientDetails?.uhid}`}
          </Text>
        </View>
        <View>
          <TouchableOpacity
            onPress={handleOnPressHideDetails}
            style={{
              alignItems: 'flex-end',
              height: 32,
              marginRight: 4,
            }}
          >
            <Image
              style={{
                height: 24,
                width: 24,
                cursor: 'pointer',
              }}
              source={backCrossIcon}
            />
          </TouchableOpacity>
          {allowToUpdateConsultation && (
            <View>
              <View style={{ marginBottom: 8 }}>
                <LargeButton
                  label="Consulted"
                  color={vars.colors.primary.color2}
                  hoverColor={vars.colors.primary.color2}
                  onPress={handleOnPressConsulted}
                />
              </View>
              <View style={{ marginBottom: 8 }}>
                <OutlineButton
                  label="Not Appeared"
                  color={vars.colors.error}
                  hoverColor={vars.colors.error}
                  onPress={handleOnPressNotAppeared}
                />
              </View>
            </View>
          )}
        </View>
      </View>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 24,
      }}
      >
        <View style={{ flex: 1 }}>
          <FieldItem label="Gender" value={patientDetails?.gender} style={{ marginBottom: 16 }} />
          <FieldItem label="Relation" value={`${patientDetails?.relation} ${patientDetails?.relationName}`} style={{ marginBottom: 16 }} />
          <FieldItem label="BPL No." value={patientDetails?.BPLNumber} />
        </View>
        <View style={{ flex: 1 }}>
          <FieldItem label="Age" value={patientDetails?.age} style={{ marginBottom: 16 }} />
          <FieldItem label="Aadhar No." value={patientDetails?.aadharNumber} style={{ marginBottom: 16 }} />
          <FieldItem label="Mobile" value={patientDetails?.mobile} style={{ marginBottom: 16 }} />
        </View>
      </View>
      <View>
        <FieldItem label="Address line 1" value={patientDetails?.address} style={{ marginBottom: 16 }} />
        <DiagnosisInput
          consultationId={consultationId}
          patientDetail={patientDetails}
          diagnosis={diagnosis}
          {...props}
        />
        <View style={{ marginTop: 20 }}>
          <RemarksInput
            consultationId={consultationId}
            patientDetail={patientDetails}
            remarks={remarks}
            {...props}
          />
        </View>
        <View style={{ marginTop: 20 }}>
          <UploadInputOnChange
            consultationId={consultationId}
            patientDetail={patientDetails}
            prescription={prescription}
            {...props}
          />
        </View>
      </View>
      <View style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
      >
        <View style={{
          flexDirection: 'column',
          marginTop: 24,
          minWidth: 230,
        }}
        >
          <LargeButton
            width={200}
            label="View Consultation Log"
            color={vars.colors.secondary.color4}
            hoverColor={vars.colors.secondary.color2}
            labelStyle={{ color: vars.colors.secondary.color2, fontWeight: 700 }}
            hoverLabelStyle={{ color: vars.colors.white }}
            onPress={handleOnPressViewConsultationLog}
          />
        </View>
        <View style={{
          flexDirection: 'column',
          marginTop: 24,
          minWidth: 230,
        }}
        >
          <LargeButton
            width={200}
            label="View Vaccination Log"
            color={vars.colors.secondary.color4}
            hoverColor={vars.colors.secondary.color2}
            labelStyle={{ color: vars.colors.secondary.color2, fontWeight: 700 }}
            hoverLabelStyle={{ color: vars.colors.white }}
            onPress={handleOnPressViewVaccinationLog}
          />
        </View>
        <View style={{
          flexDirection: 'column',
          marginTop: 24,
          minWidth: 230,
        }}
        >
          <LargeButton
            label="Add Prescription"
            width={200}
            color={vars.colors.secondary.color4}
            hoverColor={vars.colors.secondary.color2}
            labelStyle={{ color: vars.colors.secondary.color2, fontWeight: 700 }}
            hoverLabelStyle={{ color: vars.colors.white }}
            onPress={handleOnPressAddDiagnosis}
          />
        </View>
      </View>
    </View>
  );
};
export default IncomingPatientDetails;
