import React from 'react';

import tabTheme from '../../../../theme/childTabTheme';
import { TabNavigator } from '../../../../app-components';
import PersonalDetails from './PersonalDetails';
import RegistrationDetails from './RegistrationDetails';
import Statutory from './Statutory';

const Profile = (props) => {
  const ProfileTabNavigation = TabNavigator({
    theme: { ...tabTheme },
    tabs: {
      PersonalDetails: {
        label: 'Personal Details',
        screen: PersonalDetails,
      },
      RegistrationDetails: {
        label: 'Registration Details',
        screen: RegistrationDetails,
      },
      Statutory: {
        label: 'Statutory',
        screen: Statutory,
      },
    },
  });
  return <ProfileTabNavigation {...props} />;
};
export default Profile;
