/* eslint-disable no-shadow */
import React from 'react';
import { upperFirst } from 'lodash';

import { Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, purchaseSupplyOrderStatus } from '../../../../../Lib/constants';
import { ViewPurchaseRequestListPharmacy } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';

const ViewPharmacyPurchaseRequest = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryId,
        } = {},
      } = {},
    },
  } = props;

  return (
    <Form
      type="standard"
      uri={ViewPurchaseRequestListPharmacy({
        ...props,
        queryId,
        computation: (data) => data,
      })}
      editable={false}
      reloadEventOnChange={`reload${MODELS.PURCHASE_REQUEST}`}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'PURCHASE REQUEST DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'purchase.labels.dateRaised'.getLabel(),
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.requestNumber'.getLabel(),
                    field: 'purchaseRequestPharmacy.purchaseRequestNumber',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.department'.getLabel(),
                    render: ({ data }) => (
                      <Text
                        style={{ ...vars.headings.h8 }}
                      >
                        {upperFirst(data?.type)}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.status'.getLabel(),
                    render: ({ data }) => {
                      let statusColor = '';
                      const status = data?.purchaseRequestPharmacy?.requestStatus;
                      if (status === purchaseSupplyOrderStatus?.RAISED) {
                        statusColor = vars.colors.emphasis;
                      } else if (status === purchaseSupplyOrderStatus?.ORDER_RECEIVED) {
                        statusColor = vars.colors.primary.color2;
                      } else if (status === purchaseSupplyOrderStatus?.PO_REJECTED
                          || status === purchaseSupplyOrderStatus?.SO_REJECTED) {
                        statusColor = vars.colors.error;
                      } else { statusColor = vars.colors.warning; }

                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                          <View style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: statusColor,
                            marginTop: 4.5,
                            marginLeft: 7,
                          }}
                          />
                        </View>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.itemId'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.itemCode',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.itemName'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.name',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.reasonForPurchase'.getLabel(),
                    field: 'purchaseRequestPharmacy.reasonForPurchase',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.category'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.category.name',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.description'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.description',
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequestPharmacy.requiredQuantity',
                    label: 'purchase.labels.requiredQuantity'.getLabel(),
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.unitPrice'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.sellingPrice',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.totalPrice'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {data?.purchaseRequestPharmacy?.requiredQuantity
                    * data?.purchaseRequestPharmacy?.item?.sellingPrice}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.avgMonthlyConsuption'.getLabel(),
                    field: 'purchaseRequestPharmacy.item.avgMonthlyConsumption',
                  },
                  {
                    type: 'date',
                    label: 'purchase.labels.lastPurchaseDate'.getLabel(),
                    field: 'purchaseRequestPharmacy.lastPurchaseDate',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.lastPurchaseQuantity'.getLabel(),
                    field: 'purchaseRequestPharmacy.lastPurchaseQuantity',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.stockAvailable'.getLabel(),
                    render: ({ data }) => {
                      const {
                        purchaseRequestPharmacy: {
                          item: { stockAvailable = [] } = {},
                        } = {},
                      } = data;
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          {stockAvailable
                            ?.map(({ stockAvailable }) => stockAvailable)
                            .toString()}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.manufacturer'.getLabel(),
                    field: 'purchaseRequestPharmacy.manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.supplier'.getLabel(),
                    field: 'purchaseRequestPharmacy.supplier.name',

                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: '_id',
                    id: queryId || 'purchaseRequestPharmacyList',
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPharmacyPurchaseRequest;
