import React, { useCallback } from 'react';

import { editTemperaturePulseUri } from '../../../../../../../../Queries/temperaturePulseChart';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { Form } from '../../../../../../../../app-components/form/Form';
import { save, Toast } from '../../../../../../../../app-components';
import { getErrorString } from '../../../../../../../../Lib/helpers';
import { MODELS } from '../../../../../../../../Lib/constants';
import { invoke } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const EditTemperaturePulseTimeView = (props) => {
  const { navigation } = props;

  const handleSubmit = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'ipdTemperaturePulseDetailUpdate',
        paramValue: { data },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Entry updated successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  return (
    <ListActionModal
      title="Temperature/Pulse Time Detail"
      description="You can edit detail of fields"
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          uri={editTemperaturePulseUri({ navigation })}
          reloadEvent={`reload${MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={handleSubmit}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      newDateInput({
                        hour: { field: 'hour', label: 'Hour' },
                        minute: { field: 'minute', label: 'Minute' },
                        meridiem: { field: 'meridiem' },
                      }),
                      {
                        type: 'number',
                        field: 'temperature',
                        label: 'Temperature',
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'pulse',
                        label: 'Pulse',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
      {...props}
    />
  );
};
export default EditTemperaturePulseTimeView;
