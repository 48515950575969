/* eslint-disable max-len */
import { MODELS } from '../Lib/constants';

export const bloodCategoryList = ({ filter = {} }) => ({
  query: {
    model: MODELS.BLOOD_CATEGORY,
    id: 'bloodCategoryList',
    addOnFilter: filter,
  },
  model: MODELS.BLOOD_CATEGORY,
});

export const bloodCategoryAggregatedList = ({ filter = {} }) => ({
  query: {
    model: MODELS.BLOOD_CATEGORY,
    id: 'bloodCategoryAggregatedList',
    addOnFilter: filter,
  },
  model: MODELS.BLOOD_CATEGORY,
});

export const viewBloodCategoryAggregatedList = ({ navigation = {} }) => ({
  query: {
    model: MODELS.BLOOD_CATEGORY,
    id: 'bloodCategoryAggregatedList',
    addOnFilter: navigation?.state?.params?.item?._id ? navigation?.state?.params?.item?._id : { category: navigation?.state?.params?.item?.category },
  },
  model: MODELS.BLOOD_CATEGORY,
});

export const bloodDonationListUri = ({ navigation, addOnFilter }) => ({
  query: {
    model: MODELS.BLOOD_DONATION,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.BLOOD_DONATION,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    addOnFilter,
    limit: 1,
  },
  model: MODELS.BLOOD_DONATION,
});

export const editCompanyDetails = ({ navigation }) => ({
  query: {
    model: 'company',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'company',
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'company',
});

export const viewDonorUri = ({ navigation = {} }) => ({
  query: {
    model: MODELS.BLOOD_DONOR,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.BLOOD_DONOR,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.BLOOD_DONOR,
});

export const raiseBloodRequestList = ({ filter = {} }) => ({
  query: {
    model: MODELS.BLOOD_REQUEST,
    id: 'bloodRequestRaiseDetail',
    addOnFilter: filter,
  },
  model: MODELS.BLOOD_REQUEST,
});

export const bloodUpdationRequestList = () => ({
  query: {
    model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
    id: 'issueBloodUpdationRequestRaiseDetail',
  },
  model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
});

export const bloodRequestApprovedList = ({ filter = {} }) => ({
  query: {
    model: MODELS.BLOOD_REQUEST,
    id: 'bloodRequestApprovedDetail',
    addOnFilter: filter,
  },
  model: MODELS.BLOOD_REQUEST,
});

export const DonationRecordsList = () => ({
  query: {
    model: MODELS.BLOOD_DONATION,
    id: 'masterDataListForTable',
    sort: { date: -1 },
  },
  model: MODELS.BLOOD_DONATION,
});

export const ViewRequestRaised = ({ navigation = {} }) => ({
  query: {
    model: MODELS.BLOOD_REQUEST,
    id: 'bloodRequestRaiseDetail',
    relationValue: {
      model: MODELS.BLOOD_REQUEST,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.BLOOD_REQUEST,
});

export const ViewIssueBloodUpdationRequest = ({ navigation = {} }) => ({
  query: {
    model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
    id: 'issueBloodUpdationRequestRaiseDetail',
    relationValue: {
      model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.BLOOD_ISSUE_UPDATION_REQUEST,
});

export const ViewBloodIssueUri = ({ navigation = {} }) => ({
  query: {
    model: MODELS.BLOOD_REQUEST,
    id: 'bloodRequestApprovedDetail',
    relationValue: {
      model: MODELS.BLOOD_REQUEST,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.BLOOD_REQUEST,
});

