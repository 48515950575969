import React, { useCallback, useState } from 'react';
import moment from 'moment';

import useResizeObserver from 'use-resize-observer';

import { AxisLeft, AxisBottom, AxisRight } from '@vx/axis';
import { scaleLinear } from '@vx/scale';
import { curveLinear } from '@vx/curve';
import { LinePath } from '@vx/shape';
import { Group } from '@vx/group';
import { localPoint } from '@vx/event';

import { ROUTES } from '../../../../../../../../Lib/constants';
import { Text, TouchableOpacity } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';

const x1 = (d) => d.hour + d.minute / 100;
const y = (d) => d.temperature;
const y1 = (d) => d.pulse;

const LineChart = (props) => {
  const { data = {}, navigation = {} } = props;
  const [tooltipData, setTooltipData] = useState();
  const [showTooltip, setShowTooltip] = useState();
  const [insideTooltip, setInsideTooltip] = useState();
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const xMax = width - 120;
  const yMax = height - 80;
  const xScale = scaleLinear({
    rangeRound: [0, xMax],
    domain: [0, 12],
  });
  const yScale = scaleLinear({
    rangeRound: [0, yMax],
    domain: [data.length > 0 ? Math.max(...data.map(y)) : 5, 0],
  });
  const y1Scale = scaleLinear({
    rangeRound: [0, yMax],
    domain: [data.length > 0 ? Math.max(...data.map(y1)) : 5, 0],
  });
  const handleTooltip = useCallback((event, toolTipData) => {
    // eslint-disable-next-line no-shadow
    const { x, y } = localPoint(event);
    setTooltipData({ ...toolTipData, position: { x, y } });
    setShowTooltip(true);
  }, [setTooltipData]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }} ref={ref}>
      <svg width={width} height={height}>
        <Group top={25} left={65}>
          <AxisRight
            scale={yScale}
            label="Temperature"
            stroke="#000000"
            fillOpacity={10}
            left={width - 120}
          />
          <AxisBottom
            scale={xScale}
            label="TIME"
            stroke="#696969"
            top={yMax}
          />
          {data.map((point, pointIndex) => (
            <circle
              key={pointIndex}
              r={5}
              cx={xScale(x1(point))}
              cy={yScale(y(point))}
              stroke="#000"
              fill="#000"
              fillOpacity={2}
              onMouseLeave={() => {
                setShowTooltip(false);
              }}
              onMouseEnter={(event) => {
                handleTooltip(event, point);
              }}
            />
          ))}
          <LinePath
            data={data}
            curve={curveLinear}
            x={(d) => xScale(x1(d))}
            y={(d) => yScale(y(d))}
            stroke="#000000"
            strokeWidth={2}
          />
          <AxisLeft
            scale={y1Scale}
            label="Pulse"
            stroke="#FF0000"
          />
          {data.map((point, pointIndex) => (
            <circle
              key={pointIndex}
              r={5}
              cx={xScale(x1(point))}
              cy={y1Scale(y1(point))}
              stroke="#ff0000"
              fill="#ff0000"
              fillOpacity={2}
              onMouseLeave={() => {
                setShowTooltip(false);
              }}
              onMouseEnter={(event) => {
                handleTooltip(event, point);
              }}
            />
          ))}
          <LinePath
            data={data}
            curve={curveLinear}
            x={(d) => xScale(x1(d))}
            y={(d) => y1Scale(y1(d))}
            stroke="#ff0000"
            strokeWidth={2}
          />
        </Group>
      </svg>
      {tooltipData && (showTooltip || insideTooltip) && (
        <div
          onMouseEnter={() => setInsideTooltip(true)}
          onMouseLeave={() => setInsideTooltip(false)}
          style={{
            position: 'absolute',
            left: tooltipData?.position?.x,
            top: tooltipData?.position?.y,
          }}
        >
          <div style={{
            background: '#ffffff',
            justifyContent: 'flex-start',
            border: 4,
            padding: '5px',
            borderRadius: '5px',
          }}
          >
            <strong style={{
              color: '#4F4F4F', paddingTop: 12, margin: 5, fontSize: 12,
            }}
            >
              {'Temperature :   '}
              {tooltipData.temperature}
              {' °C'}
            </strong>
            <br />
            <strong style={{
              color: '#4F4F4F', paddingTop: 10, margin: 5, fontSize: 12,
            }}
            >
              {'Pulse :   '}
              {tooltipData.pulse}
            </strong>
            <br />
            <strong style={{
              color: '#4F4F4F', paddingTop: 10, margin: 5, fontSize: 12,
            }}
            >
              {'Time :   '}
              {moment(tooltipData.date).format('DD MMM YYYY')}
              {' | '}
              {tooltipData.hour}
              {':'}
              {tooltipData.minute}
              {' '}
              {tooltipData.meridiem}
            </strong>
            <TouchableOpacity
              style={{ backgroundColor: 'white', paddingTop: '5px', alignItems: 'center' }}
              onPress={() => {
                navigation.push({
                  view: ROUTES.editTemperaturePulseTimeChartDetails.name,
                  params: tooltipData,
                });
              }}
            >
              <Text style={{
                textAlign: 'center',
                padding: '8px',
                width: '50px',
                backgroundColor: vars.colors.secondary.color3,
                borderRadius: 5,
                paddingTop: 5,
                fontSize: 'larger',
                fontWeight: 900,
                color: 'white',
                cursor: 'pointer',
              }}
              >
                Edit
              </Text>
            </TouchableOpacity>
          </div>
        </div>
      )}
    </div>
  );
};

export default LineChart;
