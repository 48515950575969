import React from 'react';

import { DateFilter } from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import vars from '../../../../../theme/vars';
import IndentsList from './components/IndentsList';
import PatientDispensing from '../CentralRecords/PatientDispensing';
import { tabTheme } from './tabTheme';

const DepartmentRecordsTabs = (props) => {
  const {
    indentRequestsQueryId,
    hidePatientDispensing,
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        indents: {
          label: 'Indents',
          screen: IndentsList,
          screenProps: {
            queryId: indentRequestsQueryId,
          },
          actions: [
            <DateFilter
              field="_createdOn"
              key="_createdOn"
              containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
            />,
          ],
        },
        patientInvoices: {
          visible: !hidePatientDispensing,
          label: 'Patient Dispensing',
          screen: PatientDispensing,
        },
      }}
    />
  );
};
export default DepartmentRecordsTabs;
