import React from 'react';
import moment from 'moment';

import {
  View,
  Text,
  showSelectedCount,
  clearSelection,
  selectAll,
  StandardButton,
} from '../../../../../../app-components';
import { registeredIPDListUri } from '../../../../../../Queries/patient';
import { Table } from '../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import { getString } from '../../../../../../Lib/helpers';
import vars from '../../../../../../theme/vars';

const header = ({ aggregates }) => ({
  selectableActions: (props) => [
    showSelectedCount(props, aggregates),

    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'patient.buttons.exportToExcel'.getLabel()} />
      ),
      file: 'PatientsManagementList',
      type: 'download',
      columns: [
        {
          field: 'patientName',
          header: 'Patient Name',
        },
        {
          field: 'gender',
          header: 'Gender',
        },
        {
          field: 'age',
          header: 'Age',
        },
        {
          field: 'relation',
          header: 'Relation Type',
        },
        {
          field: 'relationName',
          header: 'Relation Name',
        },
        {
          field: {
            field: 'lastConsultation[0].date',
            parserFunction: 'formatDateForExcel',
          },

          header: 'Last Consultation Date',
        },
      ],

      download: () => ({
        uri: {
          model: MODELS.PATIENTS,

          query: {
            id: 'patientDataList',
          },
        },
      }),

      confirmModalProps: { autoHide: true, minWidth: 436 },

      confirm: {
        title: 'Export Records!',

        hideCross: false,

        message: (messageProps) => {
          const {
            screenState: { selectedIds },
          } = messageProps;

          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },

        actions: ({ action }) => [
          { text: 'Cancel' },

          {
            text: 'Export All',

            ...action,

            allPageSelected: true,
          },

          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    },
  ],
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'patient.headers.uhid'.getLabel(),
        type: 'text',
        field: 'patient.uhid',
      },
      {
        header: 'patient.headers.patientName'.getLabel(),
        type: 'text',
        sortable: 'patientName',
        field: 'patientName',
      },
      {
        header: 'patient.headers.relation'.getLabel(),
        type: 'text',
        value: ({ item }) => getString([item?.patient?.relation, item?.patient?.relationName]),
      },
      {
        header: 'patient.headers.gender'.getLabel(),
        type: 'text',
        value: ({ item }) => item?.patient?.gender.charAt(0).toUpperCase()
         + item?.patient?.gender.slice(1),
      },
      {
        header: 'patient.headers.age'.getLabel(),
        type: 'text',
        field: 'patient.age',
      },
      {
        header: 'patient.headers.speciality'.getLabel(),
        type: 'text',
        field: 'speciality.name',
      },
      {
        header: 'patient.headers.remarks'.getLabel(),
        type: 'text',
        field: 'remarks',
      },
      {
        header: 'OutCome',
        type: 'text',
        field: 'outcome.outcome',
      },
      {
        header: 'patient.headers.lastConsultationDate'.getLabel(),
        type: 'date',
        sortable: 'lastConsultationDate',
        width: 165,
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.doctorConsultationList.name,
              modal: true,
              params: {
                item,
                model: MODELS.CONSULTATION,
                id: 'consultationDataList',
                patientName: item?.patientName,
                addOnFilter: {
                  patient: {
                    _id: item?._id,
                  },
                },
              },
            };
          },
        },
        render: ({ item }) => (
          <View>
            <Text
              style={{
                color: vars.colors.secondary.color2,
                textDecorationLine: 'underline',
              }}
            >
              {item?.lastConsultationDate
                ? moment(item?.lastConsultationDate).format('DD MMM YYYY')
                : 'N/A'}
            </Text>
          </View>
        ),
      },
    ],
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewPatientTabs.name,
        params: { _id: item?._id, patient: item },
      },
    }),
  },
};

const AdmittedList = (props) => (
  <Table
    {...props}
    uri={registeredIPDListUri}
    {...tableItems}
    reloadEvent="reloadOPDRegisteredPatients"
  />
);

export default AdmittedList;
