import React from 'react';

import { Form } from '../../../../../../../../app-components/form/Form';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { MODELS } from '../../../../../../../../Lib/constants';
import { validateInputLength, validateMultipleFileTypesAllowed } from '../../../../../../../../Lib/helpers';
import { greenFileIcon } from '../../../../../../../../images';
import { save } from '../../../../../../../../app-components';

const BirthForm = (props) => (
  <Form
    type="standardShadow"
    bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
    closeView={1}
    onSubmit={async (submitProps) => {
      const { updates, data } = submitProps;
      const newUpdate = {
        ...updates,
        admissionId:
          {
            _id:
            submitProps?.navigation?.state?.params?.patient?._id,
          },
      };
      const submitAPI = submit({
        model: MODELS.IPD_BIRTH_FORM,
        data,
        updates: newUpdate,
      });
      await submitAPI({ data, updates: newUpdate });
    }}
    submitMessage="Your birth form Successfully Saved"
    defaultValues={() => ({
      dob: new Date(),
      informDate: new Date(),
    })}
    mandatory={{
      dob: 1,
      gender: 1,
      fatherName: 1,
      motherName: 1,
      address: 1,
      birthPlace: 1,
      registrationNumber: 1,
      registrationDate: 1,
      villageOrTownName: 1,
      familyReligion: 1,
      fatherEducation: 1,
      motherEducation: 1,
      fatherOccupation: 1,
      motherOccupation: 1,
      motherAgeAtMarriage: 1,
      motherAgeAtBirth: 1,
      numberOfChildren: 1,
      childWeight: 1,
      pregnancyTime: 1,
      registratorName: 1,
    }}
    validations={{
      childName: ({ data }) => validateInputLength(data?.childName, 0, 50),
      fatherName: ({ data }) => validateInputLength(data?.fatherName, 1, 50),
      motherName: ({ data }) => validateInputLength(data?.motherName, 1, 50),
      address: ({ data }) => validateInputLength(data?.address, 1, 150),
      informerName: ({ data }) => validateInputLength(data?.informerName, 0, 50),
      informerAddress: ({ data }) => validateInputLength(data?.informerAddress, 0, 150),
      registrationNumber: ({ data }) => validateInputLength(data?.registrationNumber, 1, 50),
      villageOrTownName: ({ data }) => validateInputLength(data?.villageOrTownName, 1, 50),
      fatherEducation: ({ data }) => validateInputLength(data?.fatherEducation, 1, 50),
      motherEducation: ({ data }) => validateInputLength(data?.motherEducation, 1, 50),
      fatherOccupation: ({ data }) => validateInputLength(data?.fatherOccupation, 1, 50),
      motherOccupation: ({ data }) => validateInputLength(data?.motherOccupation, 1, 50),
      motherAgeAtMarriage: ({ data }) => validateInputLength(data?.motherAgeAtMarriage, 1, 3),
      motherAgeAtBirth: ({ data }) => validateInputLength(data?.motherAgeAtBirth, 1, 3),
      childWeight: ({ data }) => validateInputLength(data?.childWeight, 1, 3),
      pregnancyTime: ({ data }) => validateInputLength(data?.pregnancyTime, 1, 3),
      numberOfChildren: ({ data }) => validateInputLength(data?.numberOfChildren, 1, 3),
      registratorName: ({ data }) => validateInputLength(data?.registratorName, 1, 50),
      birthCertificate: ({ data }) => validateMultipleFileTypesAllowed(data?.birthCertificate),
    }}
    lg={{
      header: {
        actions: [save()],
      },
      formGroups: [
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            padding: '0px 8px 0px 8px',
            fontSize: '20px',
          },
          groups: [
            {
              columnsPerRow: 1,
              columns: [
                {
                  render: () => 'Details',
                },
              ],
            },
          ],
        },
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 10,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'date',
                  label: 'BIRTHDATE',
                  field: 'dob',
                  mandatory: true,
                  formate: 'DD/MM/YYYY',
                  editable: true,
                },
                {
                  type: 'radioGroup',
                  field: 'gender',
                  variant: 'filled',
                  label: 'GENDER',
                  container: 'topLabel',
                  options: ['Female', 'Male'],
                  optionStyle: { flexDirection: 'row-reverse' },
                  editable: true,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'childName',
                  label: 'CHILD NAME (IF ANY)',
                },
                {
                  type: 'text',
                  field: 'fatherName',
                  label: 'FATHER\'S NAME (AS MENTIONED ON RECORDS)',
                  allowWhiteSpaces: true,
                  editable: true,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'motherName',
                  label: 'MOTHER\'S NAME (AS MENTIONED ON RECORDS)',
                  allowWhiteSpaces: true,
                  editable: true,
                  mandatory: true,
                  width: '25%',
                },
                {
                  type: 'text',
                  field: 'address',
                  label: 'ADDRESS',
                  allowWhiteSpaces: true,
                  editable: true,
                  mandatory: true,
                  width: '50%',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'radioGroup',
                  field: 'birthPlace',
                  variant: 'filled',
                  label: 'PLACE OF BIRTH',
                  container: 'topLabel',
                  options: ['HOSPITAL', 'HOME'],
                  editable: true,
                  mandatory: true,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  field: 'informerName',
                  label: 'INFORMER\'S NAME',
                  allowWhiteSpaces: true,
                  editable: true,
                  mandatory: true,
                  width: '25%',
                },
                {
                  type: 'text',
                  field: 'informerAddress',
                  label: 'INFORMER\'S ADDRESS',
                  allowWhiteSpaces: true,
                  editable: true,
                  mandatory: true,
                  width: '50%',
                },
                {
                  type: 'date',
                  field: 'informDate',
                  label: 'DATE',
                  editable: false,
                  formate: 'DD/MMM/YYYY',
                  width: '25%',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'number',
                  field: 'registrationNumber',
                  label: 'REGISTRATION NUMBER',
                  editable: true,
                  mandatory: true,
                },
                {
                  type: 'date',
                  field: 'registrationDate',
                  label: 'REGISTRATION DATE',
                  editable: true,
                  mandatory: true,
                  formate: 'DD/MM/YYYY',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 1,
              columns: [
                {
                  render: () => 'FAMILY\'S RESIDENCE',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'VILLAGE/TOWN NAME',
                  field: 'villageOrTownName',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'radioGroup',
                  field: 'locationType',
                  variant: 'filled',
                  label: 'TYPE',
                  container: 'topLabel',
                  options: ['Village', 'Town'],
                  optionStyle: { flexDirection: 'row-reverse' },
                  editable: true,
                  mandatory: true,
                },
                {
                  type: 'text',
                  field: 'stateName',
                  allowWhiteSpaces: true,
                  label: 'STATE',
                  editable: true,
                },
                {
                  type: 'text',
                  field: 'districtName',
                  label: 'DISTRICT',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'radioGroup',
                  field: 'familyReligion',
                  variant: 'filled',
                  label: 'FAMILY RELIGION',
                  container: 'topLabel',
                  options: ['Hindu', 'Muslim', 'Sikh', 'Christian', 'Other'],
                  width: '50%',
                  optionStyle: { flexDirection: 'row-reverse' },
                  editable: true,
                  mandatory: true,
                },

                {
                  type: 'text',
                  label: 'FATHER\'S EDUCATION',
                  field: 'fatherEducation',
                  allowWhiteSpaces: true,
                  width: '25%',
                  editable: true,
                },
                {
                  type: 'text',
                  label: 'MOTHER\'S EDUCATION',
                  field: 'motherEducation',
                  allowWhiteSpaces: true,
                  width: '25%',
                  editable: true,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'FATHER\'S OCCUPATION',
                  field: 'fatherOccupation',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'text',
                  label: 'MOTHER\'S OCCUPATION',
                  field: 'motherOccupation',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'text',
                  label: 'MOTHER\'S AGE AT THE TIME OF MARRIAGE',
                  field: 'motherAgeAtMarriage',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'text',
                  label: 'MOTHER\'S AGE WHEN GIVING BIRTH',
                  field: 'motherAgeAtBirth',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'number',
                  field: 'numberOfChildren',
                  label: 'MOTHER\'S TOTAL NUMBER OF CHILDREN',
                  editable: true,
                },
                {
                  type: 'number',
                  field: 'childWeight',
                  label: 'WEIGHT OF THE CHILD',
                  editable: true,
                },
                {
                  type: 'number',
                  field: 'pregnancyTime',
                  label: 'TIME OF THE PREGNANCY (IN WEEKS)',
                  editable: true,
                },
                {
                  type: 'number',
                  field: 'registrationNumber',
                  label: 'REGISTRATION NUMBER',
                  editable: true,
                },

              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          groupsContainerStyle: {
            paddingLeft: 12,
          },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'date',
                  field: 'registrationDate',
                  label: ' REGISTRATION DATE',
                  editable: true,
                },
                {
                  type: 'text',
                  field: 'registratorName',
                  label: 'REGISTRATOR\'S NAME',
                  allowWhiteSpaces: true,
                  editable: true,
                },
                {
                  type: 'file',
                  field: 'birthCertificate',
                  icon: greenFileIcon,
                  placeholder: 'Document',
                  label: 'Drag & Drop your Birth Certification here',
                },

              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);
export default BirthForm;
