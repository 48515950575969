import React from 'react';

import {
  cancelButton, OutlineButton, save, StandardButton, Text,
} from '../../../app-components';
import PatientBelongingsTableModal from './Patient/Components/belongingsItemModal';
import EmergencyModal from './Patient/Components/emergencyModal';
import ViewPatient from '../../OPD/PatientManagement/ViewPatient';
import ConsentForm from './NurseView/ConsentForm/index';
import UnknownAddPatient from './unknownPatientAdd';
import AdmitPatient from './AdmitToIPD/AdmitPatient';
import ViewUnknownPatient from './ViewUnknownPatient';
import UnknownEditPatient from './EditUnknownPatient';
import NurseViewPatient from './NurseView/viewPatient';
import { ROUTES } from '../../../Lib/constants';
import NurseViewPatientTabs from './NurseView';
import RoomAllot from './NurseView/RoomAllot';
import ViewPatientTabs from './Patient/tabs';
import PatientAdmission from './Patient';
import vars from '../../../theme/vars';
import AdmittedList from './Patient/AdmittedList/AdmittedPatientList';
import ViewAdmittedPatientTabs from './Patient/AdmittedList';

export default {
  [ROUTES.patientAdmissionIPD.name]: {
    screen: PatientAdmission,
    expanded: true,
  },
  [ROUTES.emergencyIPDModal.name]: {
    screen: EmergencyModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 426,
      height: 310,
    },
  },
  [ROUTES.unknownPatientAdd.name]: {
    screen: UnknownAddPatient,
    expanded: true,
    headerActions: [
      cancelButton,
      save({ label: 'patient.buttons.fillLater'.getLabel() }),
      save({ label: 'patient.buttons.save'.getLabel() }),
    ],
  },
  [ROUTES.unknownPatientEdit.name]: {
    screen: UnknownEditPatient,
    expanded: true,
    headerActions: [
      cancelButton,
      save({ label: 'patient.buttons.update'.getLabel() }),
    ],
  },
  [ROUTES.patientAdmissionListIPD.name]: {
    screen: ViewPatientTabs,
    expanded: true,
    headerActions: [
      {
        type: 'link',
        link: {
          view: ROUTES.existingPatientModal.name,
          push: true,
          modal: true,
          params: {
            routes: {
              listRoute: ROUTES.patientAdmissionListIPD.name,
              viewRoute: ROUTES.viewPatientIPD.name,
            },
          },
          modalProps: {
            autoHide: true,
            maxHeight: '100%',
            width: 373,
            height: 535,
          },
        },
        render: () => (
          <StandardButton label={'patient.buttons.scan'.getLabel()} />
        ),
      },
    ],
  },
  [ROUTES.viewPatientIPD.name]: {
    screen: ViewPatient,
    title: (props) => {
      const { navigation: { state: { params: { patient } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {patient?.uhid}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <OutlineButton label={'patient.buttons.edit'.getLabel()} />
        ),
        type: 'link',
        link: ({ navigation }) => ({
          view: ROUTES.editPatient.name,
          push: true,
          expanded: true,
          params: { _id: navigation?.state?.params?._id },
        }),
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.admitToIPD'.getLabel()} />
        ),
        type: 'link',
        link: (props) => {
          const { navigation: { state: { params: { patient } = {} } = {} } = {} } = props;
          return {
            view: ROUTES.admitPatientToIPD.name,
            params: { patient },
          };
        },
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.generateAttendantCard'.getLabel()} />
        ),
        type: 'link',
        // link: (props) => {
        //   const { navigation } = props;

        //   return {
        //     view: ROUTES.addConsultation.name,
        //     modal: true,
        //     params: { patient: navigation?.state?.params?.patient },
        //     modalProps: {
        //       autoHide: true,
        //       width: 696,
        //       height: 516,
        //     },
        //   };
        // },
      },
    ],
  },
  [ROUTES.viewUnknownPatientIPD.name]: {
    screen: ViewUnknownPatient,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { patient } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {patient?.uhid}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => (
          <OutlineButton label={'patient.buttons.edit'.getLabel()} />
        ),
        type: 'link',
        link: ({ navigation }) => ({
          view: ROUTES.unknownPatientEdit.name,
          push: true,
          expanded: true,
          params: { _id: navigation?.state?.params?._id },
        }),
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.admitToIPD'.getLabel()} />
        ),
        type: 'link',
        link: (props) => {
          const { navigation: { state: { params: { patient } = {} } = {} } = {} } = props;
          return {
            view: ROUTES.admitPatientToIPD.name,
            params: { patient },
          };
        },
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.register'.getLabel()} />
        ),
        type: 'link',
        link: (props) => {
          const { navigation } = props;

          return {
            view: ROUTES.editPatient.name,
            push: true,
            expanded: true,
            params: { _id: navigation?.state?.params?._id },
          };
        },
      },
    ],
  },
  [ROUTES.patientBelongingsList.name]: {
    screen: PatientBelongingsTableModal,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 522,
      width: 832,
    },
  },
  [ROUTES.admitPatientToIPD.name]: {
    screen: AdmitPatient,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 696,
      height: 550,
    },
  },
  [ROUTES.nurseViewIPD.name]: {
    screen: NurseViewPatientTabs,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <Text style={{ ...vars.headings.h8, float: 'left' }}>
            Patient Admission
          </Text>
        ),
      },
    ],
  },

  [ROUTES.nurseViewPatientIPD.name]: {
    screen: NurseViewPatient,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { patient = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {patient?.patient?.uhid}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => {
          const {
            navigation:
            {
              state:
              {
                params:
                 {
                   patient:
                   { rooms },
                 } = {},
              } = {},
            } = {},
          } = props;
          if (rooms) {
            return '';
          }
          return <StandardButton label="Allot Bed" />;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.roomAllot.name,
          push: true,
          params: { item: props?.navigation?.state?.params },
        }),
      },
      save({ label: 'Upload' }),
    ],
  },
  [ROUTES.roomAllot.name]: {
    screen: RoomAllot,
    expanded: true,
    headerActions: [
      cancelButton,
      save({ label: 'Save & next' }),
    ],
  },
  [ROUTES.consentForm.name]: {
    screen: ConsentForm,
    expanded: true,
    headerActions: [
      cancelButton,
      save,
    ],
  },
  [ROUTES.admittedPatient.name]: {
    screen: ViewAdmittedPatientTabs,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <Text style={{ ...vars.headings.h8, float: 'left' }}>
            Admitted Patient
          </Text>
        ),
      },
    ],
  },

};
