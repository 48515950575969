import moment from 'moment';
export const timeZoneOffset = new Date().getTimezoneOffset() * -1;

export const getZeroTimeDate = (date, eod) => {
  if (!date) {
    return date;
  }
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }
  let minutes = date.getTimezoneOffset() * -1;
  if (eod) {
    return moment(date).endOf('day').add(minutes, 'minutes').utc().toDate();
  } else {
    return moment(date).startOf('day').add(minutes, 'minutes').utc().toDate();
  }
};

export const getRangeData = ({filterOf, from, to, source}) => {
  if ((source === 'Prev' || source === 'Next') && filterOf !== 'Custom') {
    if (source === 'Prev') {
      from = moment(from).subtract(1, filterOf).toDate();
      to = moment(to).subtract(1, filterOf).toDate();
    } else {
      from = moment(from).add(1, filterOf).toDate();
      to = moment(to).add(1, filterOf).toDate();
    }
  } else if (filterOf !== 'Custom') {
    const today = moment().toDate();
    if (filterOf === 'Day' || filterOf === 'Month') {
      from = getZeroTimeDate(today);
      to = getZeroTimeDate(today, true);
      if (filterOf === 'Month') {
        from = moment(today).startOf(filterOf).toDate();
        to = moment(today).endOf(filterOf).toDate();
        from = getZeroTimeDate(from);
        to = getZeroTimeDate(to, true);
      }
    } else if (filterOf === 'Week') {
      from = moment(today).subtract(3, 'day').startOf('day').toDate();
      from = getZeroTimeDate(from);
      to = moment(today).add(3, 'day').endOf('day').toDate();
      to = getZeroTimeDate(to, true);
    } else if (filterOf === 'Year') {
      const month = moment(today).format('M');
      const year = moment(today).format('YYYY');
      if (month > 3) {
        from = moment(`${4} ${year}`, 'MM YYYY').startOf('month').toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year + 1}`, 'MM YYYY')
          .endOf('month')
          .toDate();
        to = getZeroTimeDate(to, true);
      } else {
        from = moment(`${4} ${year - 1}`, 'MM YYYY')
          .startOf('month')
          .toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year}`, 'MM YYYY').endOf('month').toDate();
        to = getZeroTimeDate(to, true);
      }
    }
  } else if (filterOf === 'Custom') {
    from = from && getZeroTimeDate(from);
    to = to && getZeroTimeDate(to, true);
  }
  return {from, to};
};

const getUTCMonthNumber = (index) => {
  const monthMap = {};
  monthMap.January = 1;
  monthMap.February = 2;
  monthMap.March = 3;
  monthMap.April = 4;
  monthMap.May = 5;
  monthMap.June = 6;
  monthMap.July = 7;
  monthMap.August = 8;
  monthMap.September = 9;
  monthMap.October = 10;
  monthMap.November = 11;
  monthMap.December = 12;
  return monthMap[index];
};

export const getMonthFirstLastDate = (month, year) => {
  if (!month || !year) {
    return {};
  }
  const monthNumber = getUTCMonthNumber(month);
  const day = 2;
  const dateString = `${year}-${monthNumber}-${day}`;
  const d1 = new Date(dateString);
  const d2 = new Date(dateString);

  d1.setUTCFullYear(year);
  d1.setUTCMonth(monthNumber - 1);
  d1.setUTCDate(1);
  d1.setUTCHours(0);
  d1.setUTCMinutes(0);
  d1.setUTCSeconds(0);
  d1.setUTCMilliseconds(0);

  d2.setUTCDate(1);
  d1.setUTCFullYear(year);
  d2.setUTCMonth(monthNumber);
  d2.setUTCDate(0);
  d2.setUTCHours(0);
  d2.setUTCMinutes(0);
  d2.setUTCSeconds(0);
  d2.setUTCMilliseconds(0);
  return { monthFirstDate: d1, monthLastDate: d2 };
};