import React from 'react';

import { MODELS } from '../../../../../Lib/constants';
import MasterList from '../../../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 20 },
  {
    header: 'consultation.labels.date'.getLabel(),
    type: 'date',
    field: 'billingDate',
  },
  {
    header: 'consultation.labels.itemName'.getLabel(),
    type: 'text',
    field: 'pharmacyDetails.item.name',
  },
  {
    header: 'Item Id',
    type: 'text',
    field: 'pharmacyDetails.item.itemCode',
  },
  {
    header: 'consultation.labels.category'.getLabel(),
    type: 'text',
    field: 'pharmacyDetails.item.category.name',
  },
  {
    header: 'Quantity',
    type: 'text',
    field: 'pharmacyDetails.quantity',
  },
];

export default (props) => {
  const { consultation } = props;

  return (
    <MasterList
      {...props}
      tableColumns={tableColumns}
      showActionIcon={false}
      uri={() => ({
        model: MODELS.PHARMACY_BILLING,
        query: {
          id: 'pharmacyBillingForConsultation',
          model: MODELS.PHARMACY_BILLING,
          addOnFilter: {
            consultationId: { _id: consultation?._id },
          },
        },
      })}
    />
  );
};
