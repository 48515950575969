import React from 'react';
import {
  View,
  Text,
  StandardButton,
  Image,
} from '../../app-components';
import { crossIcon } from '../../images';
import vars from '../../theme/vars';

const ExportRecordsModal = (props) => {
  const {
    navigation: { state: { params } = {} } = {}, title, subtitlePrefix, subtitleSuffix,
  } = props;

  const { selectedIds } = params;

  return (
    <View style={{
      flex: 1,
    }}
    >
      <View
        style={{
          flex: 0.6,
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            alignSelf: 'flex-end',
            height: 40,
            width: 40,
          }}
          source={crossIcon}
        />
        <Text style={[vars.headings.h5]}>
          {title}
        </Text>
        <View style={{
          flexDirection: 'row',
        }}
        >
          <Text style={[vars.headings.h9, { color: vars.colors.grey.color3 }]}>
            {subtitlePrefix}
          </Text>
          <Text
            style={[
              vars.headings.h9,
              {
                marginLeft: 4,
                marginRight: 4,
                color: vars.colors.secondary.color2,
              }]}
          >
            {` ${selectedIds?.length} `}
          </Text>
          <Text style={[vars.headings.h9, { color: vars.colors.grey.color3 }]}>
            {subtitleSuffix}
          </Text>
        </View>
      </View>
      <View style={{
        flex: 0.4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <StandardButton label="Download" />
      </View>
    </View>
  );
};

ExportRecordsModal.defaultProps = {
  title: 'Export Records!',
  subtitlePrefix: 'This action will export',
  subtitleSuffix: 'selected records in the excel format',
};

export default ExportRecordsModal;
