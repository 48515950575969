/* eslint-disable max-len */
import React from 'react';
import { Text, View } from 'react-native';
import { Form } from '../../../app-components/form/Form';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../AppServices';
import { autoSuggestInput } from '../../../autoSuggestions';
import { inChargeAssetStatus, MODELS } from '../../../Lib/constants';
import { InChargeAssetList } from '../../../Queries/asset';
import vars from '../../../theme/vars';

const editAssetDetail = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  const { _id } = item;
  return (
    <Form
      type="standardShadow"
      editable={false}
      submitMessage="Item has been updated successfully."
      uri={InChargeAssetList({ filter: { _id } })}
      onSubmit={submit({
        model: MODELS.ASSET,
      })}
      reloadEvent={`reload${MODELS.ASSET}`}
      mandatory={{
        assetStatus: 1,
        serviceItemDetails: {
          preventiveDate: 1, serviceDate: 1,
        },
      }}
      closeView={1}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.serialNo'.getLabel(),
                    field: 'serialNumber',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.selectItemName'.getLabel(),
                    field: 'item.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'item.category.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.repairReturnDate'.getLabel(),
                    field: '',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.cost'.getLabel(),
                    field: 'purchasedPrice',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.service'.getLabel(),
                    field: 'isAMC',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            columnsPerRow: 2,
            editable: true,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    container: 'topLabel',
                    field: 'inService',
                    type: 'toggleSwitch',
                    label: 'Status',
                    offValueLabel: 'In-Storage',
                    onValueLabel: 'In-Service',
                    width: 800,
                  },
                  autoSuggestInput({
                    field: 'department',
                    label: 'asset.labels.loanedOutDept'.getLabel(),
                    placeholder: 'asset.placeholders.selectLoanedOutDept'.getLabel(),
                    mandatory: true,
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    editable: true,
                    width: 350,
                    visible: ({ data: { assetStatus } }) => (assetStatus === inChargeAssetStatus[2]),
                  }),
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'FINANCE DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 7,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.manufacturer'.getLabel(),
                    field: 'batchNumber.manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.supplier'.getLabel(),
                    field: 'batchNumber.supplier.name',
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.contractEndDate'.getLabel(),
                    field: 'item.rateContractValidity',
                  },
                  {
                    type: 'text',
                    label: 'Contract Status',
                    field: '',
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.supplyReceivedOn'.getLabel(),
                    field: 'supplyReceivedOn',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.lastRepairDate'.getLabel(),
                    field: 'lastRepairDate',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.supervisor'.getLabel(),
                    field: 'supervisor.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.inCharge'.getLabel(),
                    field: 'inCharge.name',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'SERVICES',
            editable: true,
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 7,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'serviceItemDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                + Add Row
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      skipTableHeaderOnNoData: false,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          backgroundColor: 'white',
                          numberOfLines: 1,
                          marginBottom: 10,
                        },
                        headerRowContainerStyle: { backgroundColor: 'white', marginLeft: 10 },
                        columns: [
                          {
                            type: 'date',
                            header: 'asset.headers.preventiveDate'.getLabel(),
                            field: 'preventiveDate',
                            placeholder: 'asset.placeholders.preventiveDate'.getLabel(),
                            variant: 'filled',
                          },
                          {
                            type: 'date',
                            header: 'asset.headers.serviceDate'.getLabel(),
                            field: 'serviceDate',
                            placeholder: 'asset.placeholders.serviceDate'.getLabel(),
                            variant: 'filled',
                          },
                          {
                            type: 'number',
                            header: 'asset.headers.repairCharge'.getLabel(),
                            field: 'repairPrice',
                            placeholder: 'asset.placeholders.repairPrice'.getLabel(),
                            variant: 'filled',
                            width: 300,

                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            columnsPerRow: 2,
            editable: true,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    type: 'file',
                    field: 'documents',
                    label: 'Upload Attachments here',
                    multiple: true,
                    allowWhiteSpaces: true,
                    width: 320,
                  },

                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default editAssetDetail;
