import { MODELS } from '../Lib/constants';

export const EditCategory = ({ navigation }) => ({
  query: {
    model: MODELS.PHARMACY_CATEGORY,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.PHARMACY_CATEGORY,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_CATEGORY,
});

export const SubCategoryListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataListForTable',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PHARMACY_SUBCATEGORY,
});

export const CategoryList = ({ filter = {} }) => ({
  query: {
    model: MODELS.PHARMACY_CATEGORY,
    id: 'masterDataListForTable',
    addOnFilter: filter,
  },
  model: MODELS.PHARMACY_CATEGORY,
});

export const RoomList = ({ filter = {} }) => ({
  query: {
    model: MODELS.ROOM,
    id: 'masterDataListForTable',
    addOnFilter: filter,
  },
  model: MODELS.ROOM,
});

