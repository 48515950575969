import React from 'react';

import { Table } from '../../app-components/table/Table';
import { testTypeListUri } from '../../Queries/test';

const card = {
  lg: {
    card: {
      type: 'title',
      title: { primaryField: 'name' },
    },
  },
};

export default (props) => {
  const { filter } = props;

  return (
    <Table
      {...props}
      uri={() => testTypeListUri({ filter })}
      {...card}
    />
  );
};
