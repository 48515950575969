import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const LogReferencesIPDForm = (props) => (
  <ListActionModal
    title="Log Reference"
    description="Log reference requested by the department."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Confirm',
            }),
          ],
        }}
        closeView={1}
        reloadEvent={`reload${MODELS.IPD_ADMISSION}`}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.IPD_REFERENCE,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        defaultValues={() => ({
          referenceDate: new Date(),
          department: { name: 'IPD' },
        })}
        mandatory={{
          _id: 1,
          advice: 1,
        }}

        lg={{
          formGroups: [
            {
              columnsPerRow: 2,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'date',
                      field: 'referenceDate',
                      label: 'DATE',
                      editable: true,
                    },
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'DEPARTMENT',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                    }),
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'doctorName',
                      label: 'DOCTOR',
                      suggestionField: 'firstName',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                    }),
                  ],
                },
              ],
            },
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'textArea',
                      field: 'advice',
                      label: 'ADVICE',
                      editable: true,
                      mandatory: true,
                      allowWhiteSpaces: true,
                      minHeight: 80,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />

);
export default LogReferencesIPDForm;
