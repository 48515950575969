import React from 'react';
import { Text } from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { ROUTES } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import {
  AddNewSavingHead,
  TaxDetailsTabNavigator,
  AddNewTaxSlab,
  SavingHeadDrill,
  TaxSlabsDrill,
  ViewTaxSlabs,
} from './actions';

export default {
  [ROUTES.salarySetUp.name]: {
    screen: TaxDetailsTabNavigator,
    header: {
      title: () => (
        <HeaderTitleWithCount label="SALARY SETUP" />
      ),
    },
  },
  [ROUTES.addSavingHead.name]: {
    screen: AddNewSavingHead,
  },
  [ROUTES.addTaxSlab.name]: {
    screen: AddNewTaxSlab,
  },
  [ROUTES.editSavingHead.name]: {
    screen: SavingHeadDrill,
  },
  [ROUTES.editTaxSlab.name]: {
    screen: TaxSlabsDrill,
  },
  [ROUTES.viewTaxSlab.name]: {
    screen: ViewTaxSlabs,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.finantial_year_id?.name}
        </Text>
      );
    },
    expanded: true,
  },
};
