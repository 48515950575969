import { searchInput } from '../../../../autoSuggestions';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS, category, modules } from '../../../../Lib/constants';
import { validateInputLength } from '../../../../Lib/helpers';

const formFields = [

  {
    type: 'text',
    field: 'name',
    label: 'pharmacy.labels.category'.getLabel(),
    mandatory: true,
  },

  {
    type: 'textArea',
    field: 'description',
    label: 'pharmacy.labels.description'.getLabel(),
    minHeight: 100,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'pharmacy.labels.status'.getLabel(),
    container: 'topLabel',
  },

];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

export default MasterForm({
  model: MODELS.PHARMACY_CATEGORY,
  formFields,
  validations,
  uniqueValidationMessage: 'pharmacy.messages.categoryAlreadyExists'.getLabel(),
  submitMessage: 'pharmacy.messages.categoryAddedSuccessfully'.getLabel(),
  defaultValues: () => ({
    isActive: true,
    module: modules.pharmacy,
  }),
});
