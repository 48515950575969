import React from 'react';
import { FormHoc, Text, View } from '../../../../../../app-components';
import theme from '../../../../../../App/AppContainer/Header/theme';
import { autoSuggestInput } from '../../../../../../autoSuggestions';
import { MODELS } from '../../../../../../Lib/constants';
import { getShiftType, getString } from '../../../../../../Lib/helpers';
import vars from '../../../../../../theme/vars';

const RegistrationDetails = (props) => {
  const { profileInfo } = props;

  const RegistrationDetailsForm = FormHoc({
    type: 'standardShadow',
    closeView: 1,
    editable: false,
    reloadEvent: 'reloadProfile',
    bodyContainerStyle: { paddingLeft: 16 },
    defaultValues: () => ({ ...profileInfo }),
    formGroups: [
      {
        columnsPerRow: 1,
        groups: [
          {
            columns: [
              {
                type: 'custom',
                render: () => (
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={theme.userInfoWrapper}
                    >
                      <Text numberOfLines={1} style={theme.usernameText}>
                        {profileInfo?.firstName?.charAt(0) + profileInfo?.lastName?.charAt(0)}
                      </Text>
                    </View>
                    <View style={{ paddingLeft: 12, paddingTop: 2 }}>
                      <Text style={{ ...vars.headings.h5, color: vars.colors.secondary.color1 }}>
                        {getString([profileInfo?.firstName, profileInfo?.lastName])}
                      </Text>
                    </View>
                  </View>
                ),
              },
            ],
          },
        ],
      },
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingTop: 0 },
        columnContainerStyle: {
          marginLeft: 6, marginRight: 6, marginTop: 12, marginBottom: 12,
        },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'employeeId',
                label: 'profile.labels.employeeId'.getLabel(),
              },
              {
                type: 'text',
                field: 'department.name',
                label: 'profile.labels.department'.getLabel(),
              },
              {
                type: 'text',
                render: ({ data }) => (<Text style={{ ...vars.headings.h8 }}>{(Array.isArray(data?.role) && getString(data?.role?.map((role) => role.name), ', '))}</Text>),
                label: 'profile.labels.role'.getLabel(),
              },
              {
                type: 'text',
                field: 'speciality.name',
                label: 'profile.labels.speciality'.getLabel(),
              },
              {
                type: 'text',
                field: 'labType.name',
                label: 'Lab Type',
              },
              {
                type: 'text',
                value: '*****',
                label: 'Password',
              },
              {
                type: 'text',
                field: 'staffType.name',
                label: 'profile.labels.staffType'.getLabel(),
              },
              {
                type: 'text',
                field: 'shiftType.name',
                render: ({ data }) => {
                  const { schedule } = data;
                  if (schedule?.length) {
                    const { ShiftType = [] } = schedule[0];
                    return (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getShiftType(ShiftType)}
                      </Text>
                    );
                  }
                  return <Text style={{ ...vars.headings.h8 }}>No Active Schedule</Text>;
                },
                label: 'profile.labels.schedule'.getLabel(),
              },
              autoSuggestInput({
                field: 'leavePolicy',
                label: 'profile.labels.leavePolicy'.getLabel(),
                keyField: 'name',
                valueField: 'name',
                suggestionField: 'name',
                model: MODELS.LEAVE_POLICY_TYPE,
                query: 'leavePolicySuggestions',
              }),
              {
                type: 'status',
                field: 'isActive',
                label: 'profile.labels.status'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        fieldContainer: 'topLabel',
        label: 'Contract Details',
        labelStyle: { ...vars.headings.h9, color: vars.colors.secondary.color2 },
        groupsContainerStyle: { paddingTop: 0 },
        columnContainerStyle: {
          marginLeft: 6, marginRight: 6, marginTop: 12, marginBottom: 12,
        },
        groups: [
          {
            columnsPerRow: 4,
            columns: [
              {
                type: 'date',
                field: 'joiningDate',
                // format: 'DD.MM.YYYY',
                label: 'profile.labels.joiningDate'.getLabel(),
              },
              {
                type: 'text',
                field: 'employeeType.name',
                label: 'profile.labels.employeeType'.getLabel(),
              },
              {
                type: 'text',
                field: 'bondDuration',
                label: 'profile.labels.bondDuration'.getLabel(),
              },
              {
                type: 'text',
                field: 'noticePeriod',
                label: 'profile.labels.noticePeriod'.getLabel(),
              },
              {
                type: 'text',
                field: 'experience',
                label: 'profile.labels.experience'.getLabel(),
              },
            ],
          },
        ],
      },
    ],
  });
  return <RegistrationDetailsForm {...props} />;
};

export default RegistrationDetails;
