import { MODELS } from '../../../Lib/constants';
import MasterForm from '../../../Wired/Master/MasterForm';
import { validateFileTypesAllowed, validateInputLength, validToAndFromDate } from '../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'holidayCalendar.labels.year'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.year'.getLabel(),
    mandatory: true,
  },
  {
    type: 'date',
    field: 'fromDate',
    label: 'holidayCalendar.labels.fromDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.fromDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'date',
    field: 'toDate',
    label: 'holidayCalendar.labels.toDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.toDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'holidayCalendar.labels.status'.getLabel(),
    mandatory: true,
  },
  {
    type: 'file',
    field: 'attachment',
    label: 'holidayCalendar.labels.file'.getLabel(),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'holidayCalendar.labels.description'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.description'.getLabel(),
  },
];
const validations = {
  fromDate: ({ data }) => validToAndFromDate(data),
  toDate: ({ data }) => validToAndFromDate(data),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
  attachment: ({ data }) => validateFileTypesAllowed(data?.attachment?.name, ['pdf']),
};

export default MasterForm({
  model: MODELS.HOLIDAYCALENDAR,
  formFields,
  validations,
  columnsPerRow: 4,
  submitMessage: 'holidayCalendar.messages.holidayCalendarAddedSuccessfully'.getLabel(),
  mandatory: {
    name: 1,
    fromDate: 1,
    toDate: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
