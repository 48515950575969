import { MODELS } from '../Lib/constants';

export const EditPurchaseDepartmentTC = ({ navigation }) => ({
  query: {
    model: MODELS.PURCHASE_DEPARTMENT_TC,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.PURCHASE_DEPARTMENT_TC,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PURCHASE_DEPARTMENT_TC,
});
