import moment from 'moment';

export const durationForAttendance = (field) => ({ item }) => {
  const value = (item && item[field]) || null;
  if (!value) return undefined;
  let duration = '';
  const defaultDuration = 60;
  const hours = value && value / defaultDuration;
  const minute = value && value % defaultDuration;
  if (hours && hours > 0) duration += `${hours.toFixed(0)}h `;
  if (minute) duration += `${minute.toFixed(0)}m`;
  return duration;
};

export const MonthLayout = {
  header: 'MONTH',
  type: 'text',
  value: ({ item }) => (item && item.month_id && item.month_id.name) || '--',
};

export const TotalLayout = {
  header: 'TOTAL',
  type: 'text',
  value: ({ item }) => (item && item.total_days_id_month) || '0',
};

export const PayableLayout = {
  header: 'Payable',
  type: 'text',
  value: ({ item }) => item?.details?.total_salary_days || '0',
};

export const LOPLayout = {
  header: 'LOP',
  type: 'text',
  value: ({ item }) => (((item.details && item.details.absent_days) || 0)) || '0',
};

export const totalLeavesAccruedInMonth = {
  header: 'Leaves Accrued',
  type: 'text',
  field: 'totalLeavesAccruedInMonth',
};

export const AttendanceDateLayout = {
  header: 'DATE',
  type: 'date',
  field: 'attendance_date',
};

export const YearLayout = {
  header: 'Year',
  type: 'text',
  value: ({ item }) => item && item.month_last_date && moment(item.month_last_date).format('YYYY'),
};

export const InTimeLayout = {
  header: 'IN TIME',
  type: 'text',
  field: 'in_time',
};

export const OutTimeLayout = {
  header: 'OUT TIME',
  type: 'text',
  field: 'out_time',
};

export const FirstHalfLayout = {
  header: 'FIRST HALF',
  type: 'text',
  field: 'first_attendance_type_id.name',
};

export const SecondHalfLayout = {
  header: 'SECOND HALF',
  type: 'text',
  field: 'second_attendance_type_id.name',
};

export const FirstHalfLayoutWithType = {
  header: 'FIRST HALF',
  type: 'card',
  title: {
    primaryField: 'first_attendance_type_id.name',
  },
  contentItems: [
    {
      visible: ({ item }) => item?.first_attendance_type_id?.code === 'leave',
      value: ({ item }) => item && item.leave_type_id && item.leave_type_id.name,
    },
  ],
};

export const SecondHalfLayoutWithType = {
  header: 'SECOND HALF',
  type: 'card',
  title: {
    primaryField: 'second_attendance_type_id.name',
  },
  contentItems: [
    {
      visible: ({ item }) => item?.second_attendance_type_id?.code === 'leave',
      value: ({ item }) => item && item.leave_type_id && item.leave_type_id.name,
    },
  ],
};

export const DurationForAttendanceLayout = {
  header: 'DURATION',
  type: 'text',
  value: durationForAttendance('time_in_office'),
};

const getAttendanceRequestStatus = (status) => {
  if (status === 'approve') {
    return 'Approved';
  } if (status === 'reject') {
    return 'Rejected';
  } if (status === 'unapproved') {
    return 'Pending';
  } if (status === 'cancelled') {
    return 'Cancelled';
  }

  return 'UNKNOWN';
};

export const RequestsStatusLayots = {
  header: 'STATUS',
  type: 'text',
  value: ({ item }) => getAttendanceRequestStatus(item?.status),
};

export const FirstHalfLOPLayout = {
  header: 'FIRST HALF',
  type: 'text',
  value: ({ item }) => item?.first_attendance_type_id?.name,
};

export const SecondHalfLOPLayout = {
  header: 'SECOND HALF',
  type: 'text',
  value: ({ item }) => item?.second_attendance_type_id?.name,
};

export const LeaveType = {
  header: 'TYPE',
  type: 'text',
  field: 'leave_type_id.name',
};

export const TimeInOfficeLayout = {
  header: 'TIME IN OFFICE',
  type: 'text',
  field: 'time_in_office',
};

export const BreakTimeDurationLayout = {
  header: 'BREAK TIME',
  type: 'text',
  field: 'break_time',
};

export const ProgressHoursDurationLayout = {
  header: 'PROGRESS',
  type: 'text',
  field: 'progress_hours',
};
