import { ROUTES } from '../../Lib/constants';
import Consultation from './Consultation';
import PatientAdmission from './PatientAdmission';

const IPDScreens = {
  ...PatientAdmission,
  [ROUTES.ipdConsultation.name]: {
    screen: Consultation,
    expanded: true,
  },
};

export default IPDScreens;
