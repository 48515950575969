import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExamination = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          fhs: {
            compute: (data) => {
              if (data?.fhs !== 'N') {
                return ({
                  set: {
                    nDescription: '',
                  },
                });
              }
            },
            onChange: ['fhs'],
          },
          contraction: {
            compute: (data) => {
              if (data?.contraction === 'No') {
                return ({
                  set: {
                    contractionDescription: '',
                  },
                });
              }
            },
            onChange: ['contraction'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="text"
                  field="relativeName"
                  variant="filled"
                  label="Relative Name"
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}
            </View>
            <View
              style={{
                flex: 1,
                marginLeft: 20,
              }}
            >
              <FormField
                type="text"
                field="provisionalDiagnosis"
                variant="filled"
                label="Provisional Diagnosis"
                mandatory
              />
            </View>
            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Chief Complaints
              </Text>

              <FormField
                {...{
                  field: 'cheifComplaint',
                  fieldType: 'nested',
                  addInBottom: true,
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        {
                          type: 'textArea',
                          field: 'complaint',
                          variant: 'filled',
                          placeholder: 'Enter Complaint Here',
                          allowWhiteSpaces: true,
                          editable: true,
                        },
                        NestedAction({
                          containerStyle: {
                            marginTop: 25,
                          },
                        }),
                      ],
                    },
                  },
                }}
              />
            </View>

            {/* HISTORY OF */}
            {/* Previous Surgery */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                HISTORY OF
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                a.&nbsp;&nbsp;Obstetric(G/L/P/A)
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,

                    marginTop: 15,
                    width: 190,
                  }}
                >
                  i.&nbsp;&nbsp;Gravida
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="obstetric.gravida"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginLeft: 100,
                    marginTop: 15,
                    width: 190,
                  }}
                >
                  i.&nbsp;&nbsp;LMP
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="lmp"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,

                    marginTop: 15,
                    width: 190,
                  }}
                >
                  ii.&nbsp;&nbsp;Parity
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="obstetric.parity"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginLeft: 100,
                    marginTop: 15,
                    width: 190,
                  }}
                >
                  ii.&nbsp;&nbsp;EDD
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="edd"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,

                    marginTop: 15,
                    width: 190,
                  }}
                >
                  iii.&nbsp;&nbsp;Live
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="obstetric.live"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginLeft: 100,
                    marginTop: 15,
                    width: 190,
                  }}
                >
                  iii.&nbsp;&nbsp;menstrual cycle
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="menstrualCycle"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,

                    marginTop: 15,
                    width: 190,
                  }}
                >
                  iv.&nbsp;&nbsp;Abortion
                </Text>
                <FormField
                  type="text"
                  allowWhiteSpaces
                  field="obstetric.abortion"
                  container="rightLabel"
                  variant="filled"
                  editable
                  style={{
                    width: 400,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 100,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Surgery */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                d.&nbsp;&nbsp;Drug Allergies
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                  marginLeft: 550,
                }}
              >
                e.&nbsp;&nbsp;Surgery
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,

                  marginTop: 15,
                }}
              >
                i.&nbsp;&nbsp;
              </Text>
              <FormField
                type="text"
                allowWhiteSpaces
                field="drugAllergiesOne"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <FormField
                type="text"
                allowWhiteSpaces
                field="surgery"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  marginLeft: 100,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,

                  marginTop: 15,
                }}
              >
                ii.&nbsp;&nbsp;
              </Text>
              <FormField
                type="text"
                allowWhiteSpaces
                field="drugAllergiesTwo"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,

                  marginTop: 15,
                }}
              >
                iii.&nbsp;&nbsp;
              </Text>
              <FormField
                type="text"
                allowWhiteSpaces
                field="drugAllergiesThree"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,

                  marginTop: 15,
                }}
              >
                iv.&nbsp;&nbsp;
              </Text>
              <FormField
                type="text"
                allowWhiteSpaces
                field="drugAllergiesFour"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 20,
                marginTop: 15,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,

                  marginTop: 15,
                }}
              >
                v.&nbsp;&nbsp;
              </Text>
              <FormField
                type="text"
                allowWhiteSpaces
                field="drugAllergiesFive"
                container="rightLabel"
                variant="filled"
                editable
                style={{
                  width: 400,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  &nbsp;&nbsp;OE
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    a.&nbsp;&nbsp;Pt.conscious oriented
                  </Text>
                  <FormField
                    type="text"
                    field="ptConsciousOreinted"
                    variant="filled"
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>
                <View
                  style={{
                    width: '100%',
                    marginTop: 10,
                    // marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Vitals
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      a.&nbsp;&nbsp;PR
                    </Text>
                    <FormField
                      type="number"
                      field="gpe.pr"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      b.&nbsp;&nbsp;BP
                    </Text>
                    <FormField
                      type="number"
                      field="gpe.bp"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      c.&nbsp;&nbsp;RR
                    </Text>
                    <FormField
                      type="number"
                      field="gpe.RR"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      d.&nbsp;&nbsp;Temperature
                    </Text>
                    <FormField
                      type="number"
                      field="gpe.temperature"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      e.&nbsp;&nbsp;SPO2
                    </Text>
                    <FormField
                      type="number"
                      field="gpe.spo2"
                      container="rightLabel"
                      variant="filled"
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    marginTop: 10,
                    // marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Chest
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      i.&nbsp;&nbsp;N
                    </Text>
                    <FormField
                      type="text"
                      field="gpe.n"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      ii.&nbsp;&nbsp;ASA
                    </Text>
                    <FormField
                      type="text"
                      field="gpe.asa"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: 10,
                    marginLeft: 10,
                    paddingTop: 0,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      display: 'block',
                    }}
                  >
                    CVS
                  </Text>
                  <FormField
                    type="text"
                    field="gpe.cvs"
                    variant="filled"
                    style={{
                      paddingLeft: 2,
                      width: 600,
                      height: 30,
                      paddingTop: 2,
                      marginTop: 16,
                      marginLeft: 15,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    inputStyle={{
                      outline: 'none',
                      border: 0,
                      padding: '13 12 9',
                      backgroundColor: 'transparent',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'left',
                    }}
                  />
                </View>
              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  GPE
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      a.&nbsp;&nbsp;Pallor
                    </Text>
                    <View style={{ marginTop: 16, marginLeft: 20 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.pallor"
                        container="rightLabel"
                        options={['Yes', 'No']}
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      b.&nbsp;&nbsp;Ictrus
                    </Text>
                    <View style={{ marginTop: 16, marginLeft: 20 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.icterus"
                        container="rightLabel"
                        options={['Yes', 'No']}
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      c.&nbsp;&nbsp;Cyanosis
                    </Text>
                    <View style={{ marginTop: 16, marginLeft: 20 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.cynosis"
                        container="rightLabel"
                        options={['Yes', 'No']}
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      d.&nbsp;&nbsp;Clubbing
                    </Text>
                    <View style={{ marginTop: 16, marginLeft: 20 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.clubbing"
                        container="rightLabel"
                        options={['Yes', 'No']}
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                        width: 150,
                      }}
                    >
                      e.&nbsp;&nbsp;Pymphedenopathy
                    </Text>
                    <View style={{ marginTop: 16, marginLeft: 20 }}>
                      <FormField
                        type="radioGroup"
                        field="gpe.pymphedenopathy"
                        container="rightLabel"
                        options={['Yes', 'No']}
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      marginTop: 10,
                      marginLeft: 20,
                      paddingTop: 0,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,

                        display: 'block',
                      }}
                    >
                      P/A
                    </Text>
                    <FormField
                      type="text"
                      field="pa"
                      variant="filled"
                      style={{
                        paddingLeft: 2,
                        width: 600,
                        height: 30,
                        paddingTop: 2,
                        marginTop: 16,
                        marginLeft: 15,
                        fontSize: 14,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                      inputStyle={{
                        outline: 'none',
                        border: 0,
                        padding: '13 12 9',
                        backgroundColor: 'transparent',
                        fontStyle: 'normal',
                        fontSize: 14,
                        textAlign: 'left',
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      marginTop: 10,
                      marginLeft: 20,
                      paddingTop: 0,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,

                        display: 'block',
                      }}
                    >
                      P/S
                    </Text>
                    <FormField
                      type="text"
                      field="ps"
                      variant="filled"
                      style={{
                        paddingLeft: 2,
                        width: 600,
                        height: 30,
                        paddingTop: 2,
                        marginTop: 16,
                        marginLeft: 15,
                        fontSize: 14,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                      inputStyle={{
                        outline: 'none',
                        border: 0,
                        padding: '13 12 9',
                        backgroundColor: 'transparent',
                        fontStyle: 'normal',
                        fontSize: 14,
                        textAlign: 'left',
                      }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      marginTop: 10,
                      marginLeft: 20,
                      paddingTop: 0,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        display: 'block',
                      }}
                    >
                      P/U
                    </Text>
                    <FormField
                      type="text"
                      field="pu"
                      variant="filled"
                      style={{
                        paddingLeft: 2,
                        width: 600,
                        height: 30,
                        paddingTop: 2,
                        marginTop: 16,
                        marginLeft: 15,
                        fontSize: 14,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                      inputStyle={{
                        outline: 'none',
                        border: 0,
                        padding: '13 12 9',
                        backgroundColor: 'transparent',
                        fontStyle: 'normal',
                        fontSize: 14,
                        textAlign: 'left',
                      }}
                    />

                  </View>
                </View>
              </View>
            </View>

            {/* Examination  */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              {/* Obstretics */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    display: 'block',
                  }}
                >
                  Obstretics
                </Text>
                <Text
                  style={{
                    display: 'block',
                  }}
                >
                  a. &nbsp; &nbsp; Fundal Height
                </Text>
                <FormField
                  type="radioGroup"
                  field="fundalHeight"
                  variant="filled"
                  options={['12', '14', '16', '18', '20', '24', '28', '30', '32', '34', '36']}
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    display: 'block',
                  }}
                >
                  b. &nbsp; &nbsp; Presentation
                </Text>
                <FormField
                  type="radioGroup"
                  field="presentation"
                  variant="filled"
                  options={['Cephalic', 'Breech', 'Transverse', 'Oblique']}
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    display: 'block',
                  }}
                >
                  c. &nbsp; &nbsp; Liquor
                </Text>
                <FormField
                  type="radioGroup"
                  field="liquor"
                  variant="filled"
                  options={['N', 'Reduced', 'Increased']}
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    display: 'block',
                  }}
                >
                  d. &nbsp; &nbsp; fhs
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <FormField
                    type="radioGroup"
                    field="fhs"
                    variant="filled"
                    options={['N', '<100', '>160', 'Acceleration', 'Deceleration']}
                    style={{
                      height: 100,
                      paddingLeft: 2,
                      paddingTop: 2,
                      marginTop: 16,
                      marginLeft: 15,
                      fontSize: 14,
                    }}
                    inputStyle={{
                      outline: 'none',
                      border: 0,
                      padding: '13 12 9',
                      backgroundColor: 'transparent',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'left',
                      minHeight: 95,
                    }}
                  />
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 100,
                      marginLeft: 30,
                    }}
                  >
                      &nbsp;&nbsp;If N Then
                  </Text>
                  <FormField
                    type="text"
                    field="nDescription"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { fhs = {} } = {} } = disableProps;
                      return !(fhs === 'N');
                    }}
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 350,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    display: 'block',
                  }}
                >
                  e. &nbsp; &nbsp; contraction
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <FormField
                    type="radioGroup"
                    field="contraction"
                    variant="filled"
                    options={['Yes', 'No']}
                    style={{
                      height: 100,
                      paddingLeft: 2,
                      paddingTop: 2,
                      marginTop: 16,
                      marginLeft: 15,
                      fontSize: 14,
                    }}
                    inputStyle={{
                      outline: 'none',
                      border: 0,
                      padding: '13 12 9',
                      backgroundColor: 'transparent',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'left',
                      minHeight: 95,
                    }}
                  />
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 100,
                      marginLeft: 30,
                    }}
                  >
                      &nbsp;&nbsp;If Yes Then
                  </Text>
                  <FormField
                    type="text"
                    field="contractionDescription"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { contraction = {} } = {} } = disableProps;
                      return !(contraction === 'Yes');
                    }}
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 350,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExamination;
