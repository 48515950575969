import vars from '../../../../theme/vars';

const tabTheme = {
  tabPanelStyle: {
    tabRowContainerStyle: {
      height: 36,
    },
    tabRowStyle: {
      flex: 1,
      marginLeft: 14,
    },
  },
  tabItemStyle: {
    containerStyle: {
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      width: 137,
      backgroundColor: vars.colors.white,
    },
    activeContainerStyle: {
      borderRadius: 4,
      backgroundColor: vars.colors.secondary.color4,
    },
    activeBorderStyle: {
      height: 3,
      borderRadius: 2,
      width: 10,
      position: 'absolute',
      bottom: 0,

    },
    itemContainerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconStyle: {
      height: 28,
      width: 28,
      marginRight: 12,
    },
    textStyle: {
      ...vars.headings.h13,
      color: vars.colors.grey.color3,
    },
    activeTextStyle: {
      ...vars.headings.h12,
      color: vars.colors.secondary.color1,
    },
    countStyle: {
      marginLeft: 3,
      ...vars.headings.h13,
      color: vars.colors.grey.color3,
    },
    activeCountStyle: {
      ...vars.headings.h13,
      color: vars.colors.grey.color1,
    },
  },
};

export default tabTheme;
