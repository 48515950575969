import React from 'react';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import childtabTheme from '../../../../theme/childTabTheme';
import { UserLOPList } from '../../../../Wired/Attendance/Self/AttendanceLists';
import LeaveRequests from './LeaveRequests ';
import AttendanceRequests from './AtttendanceRequests';
import { DateFilter, FilterGroup } from '../../../../app-components';
import { filterActive, filterInactive } from '../../../../images';
import { MODELS } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const AttendanceTabs = (props) => {
  const { user } = props;

  return (
    <TabNavigator
      theme={{ ...childtabTheme }}
      tabs={{
        attendance: {
          label: 'Months',
          screen: UserLOPList,
          actions: [
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'month_id',
                  label: 'Month',
                  model: MODELS.MONTHS,
                  id: 'MonthTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Months',
                  sugestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoSuggest',
                  field: 'year_id',
                  label: 'Year',
                  model: MODELS.years,
                  id: 'YearTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Year',
                  sugestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
              ],
            }),
          ],
          screenProps: {
            user,
            isEmployeeDetail: true,
          },
        },
        attendanceRequests: {
          label: 'Attendance Requests',
          screen: AttendanceRequests,
          actions: [
            <DateFilter
              field="attendance_date"
              key="_createdOn"
              containerStyle={{
                flexDirection: 'row',
                width: 303,
                height: 36,
                borderWidth: 1,
                padding: 9,
                borderRadius: 4,
                borderStyle: 'solid',
                backgroundColor: vars.colors.white,
                borderColor: vars.colors.grey.color6,
              }}
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'is_approved',
                  label: 'Status',
                  searchField: 'name',
                  placeholder: 'Status',
                  sugestionField: 'label',
                  primaryField: 'label',
                  valueField: 'value',
                  options: [
                    { label: 'Approved', value: true },
                    { label: 'Pending', value: { $ne: true } },
                  ],
                },
              ],
            }),
          ],
          screenProps: {
            user,
            isEmployeeDetail: true,
          },
        },
        leaveRequests: {
          label: 'Leave Requests',
          screen: LeaveRequests,
          actions: [
            <DateFilter
              field="request_date"
              key="_createdOn"
              containerStyle={{
                flexDirection: 'row',
                width: 303,
                height: 36,
                borderWidth: 1,
                padding: 9,
                borderRadius: 4,
                borderStyle: 'solid',
                backgroundColor: vars.colors.white,
                borderColor: vars.colors.grey.color6,
              }}
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'status',
                  label: 'Status',
                  searchField: 'name',
                  placeholder: 'Status',
                  sugestionField: 'label',
                  primaryField: 'label',
                  valueField: 'value',
                  options: [
                    { label: 'Pending', value: 'unapproved' },
                    { label: 'Approved', value: 'approve' },
                    { label: 'Reject', value: 'reject' },
                  ],
                },
              ],
            }),
          ],
          screenProps: {
            user,
            isEmployeeDetail: true,
          },
        },
      }}
      {...props}
    />
  );
};
export default AttendanceTabs;
