import { MODELS } from '../Lib/constants';

export const BillingHistory = () => ({
  query: {
    model: MODELS.LAB_BILLING,
    id: 'billingDataList',
    addOnFilter: { status: 'Active' },
  },
  aggregate: true,
  model: MODELS.LAB_BILLING,
});

export const DiscardBillingHistoryUri = () => ({
  query: {
    model: MODELS.LAB_BILLING,
    id: 'billingDataList',
    addOnFilter: { billStatus: false },
  },
  aggregate: true,
  model: MODELS.LAB_BILLING,
});

export const CanceledBillingHistoryUri = () => ({
  query: {
    model: MODELS.LAB_BILLING,
    id: 'billingDataList',
    addOnFilter: { status: 'Pending from MS' },
  },
  aggregate: true,
  model: MODELS.LAB_BILLING,
});

export const ViewBillHistoryUri = ({ navigation }) => ({
  query: {
    model: MODELS.LAB_BILLING,
    id: 'billingDataList',
    relationValue: {
      model: MODELS.LAB_BILLING,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.LAB_BILLING,
});
