export const EditShift = ({ navigation }) => ({
  query: {
    model: 'shifts',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'shifts',
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'shifts',
});
