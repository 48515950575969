import React, { useCallback } from 'react';

import {
  FormField, LargeButton, Toast, View,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import { invoke, submit } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import { MODELS } from '../../Lib/constants';
import { getErrorString } from '../../Lib/helpers';
import vars from '../../theme/vars';

const ApplySalaryComponent = (props) => {
  const { navigation, navigation: { state: { params: { _id: componentId } = {} } = {} } = {} } = props;

  const handleOnSubmit = useCallback(async ({ data }) => {
    try {
      const {
        fromDate, employeeType, staffType,
      } = data;

      const result = await invoke({
        id: 'applyComponent',
        paramValue: {
          fromDate, employeeType, staffType, componentId,
        },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Component has been applied successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [navigation]);
  return (
    <ListActionModal
      icon={history}
      title="Apply Salary Components"
      description="This action will update the salary structure of all the employees for the given filter. Please do it carefully."
      content={(
        <Form
          onSubmit={handleOnSubmit}
          {...props}
          mandatory={{
            fromDate: 1,
          }}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          defaultValues={() => ({
            componentId,
          })}
          closeView={1}
        >
          {
                ({ form_context: formContext = {} }) => {
                  const { handleSubmit } = formContext;

                  return (
                    <View>
                      <FormField
                        {...{
                          type: 'date',
                          variant: 'filled',
                          field: 'fromDate',
                          label: 'From Date',
                          editable: true,
                          madatory: true,
                        }}
                      />
                      <View
                        style={{
                          flex: 1,
                          marginTop: 18,
                        }}
                      >
                        <FormField
                          {...autoSuggestInput(
                            {
                              field: 'employeeType',
                              label: 'Select Employee Type',
                              placeholder: 'Select Employee Type',
                              variant: 'filled',
                              suggestionField: 'name',
                              model: MODELS.EMPLOYEETYPE,
                              query: 'masterDataList',
                            },
                          )}
                        />
                      </View>
                      <View
                        style={{
                          flex: 1,
                          marginTop: 18,
                        }}
                      >
                        <FormField
                          {...autoSuggestInput(
                            {
                              field: 'staffType',
                              label: 'Select Staff Type',
                              placeholder: 'Select Staff Type',
                              variant: 'filled',
                              suggestionField: 'name',
                              model: MODELS.STAFFTYPE,
                              query: 'masterDataList',
                            },
                          )}
                        />
                      </View>

                      <View
                        style={{
                          flex: 1,
                          marginTop: 20,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <LargeButton
                          label="Done"
                          onClick={handleSubmit}
                          width={109}
                          height={40}
                          borderRadius={4}
                        />
                      </View>
                    </View>
                  );
                }
              }
        </Form>
            )}
      {...props}
    />
  );
};

export default ApplySalaryComponent;
