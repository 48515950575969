/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { upperFirst } from 'lodash';
import moment from 'moment';

import {
  FormField,
  Image, StandardButton, Text, Toast, TouchableOpacity, View,
} from '../../../../../app-components';
import {
  MODELS,
  modules,
  ROUTES,
} from '../../../../../Lib/constants';
import { ViewPurchaseRequestListAsset } from '../../../../../Queries/purchaseRequestPharmacy';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../../../theme/vars';
import { Action } from '../../../../../app-components/action/Action';
import { Form } from '../../../../../app-components/form/Form';
import { edit_icon as editIcon } from '../../../../../images';
import { tabTheme } from '../../../../../theme/tabBarTheme';
import ToastValidation from '../../../../../Components/ToastValidations';

const errorsMapping = {
  daysLimitForApproval: 'Days limit for approval is required.',
  code: 'Terms and conditions type is required.',
};

const CreatePurchaseAndSupplyOrderForAssetOrder = (props) => {
  const { navigation } = props;

  const {
    state:
    {
      params:
      {
        item:
        { purchaseRequestAsset, isContractual = true },
      } = {},
    } = {},
  } = navigation;

  const {
    state: {
      params: {
        purchaseSupplyOrderId = '',
        fromSupplyOrder = false,
        manufacturer,
        supplier,
        fromPS2 = false,
        isContractQueueCreated = false,
        mandatoryFields = {},
        isMandatory = false,
      },
    },
  } = navigation;

  const [isCodeSelect, setIsCodeSelect] = useState(false);

  return (
    <Form
      type="standard"
      editable={false}
      onValidationError={(errors) => {
        Toast.show({
          message: 'Errors',
          CustomDescription: () => (
            <ToastValidation
              errors={{ ...errors?.validationErrors, ...errors?.mandatoryErrors }}
              mapping={errorsMapping}
            />
          ),
          type: 'error',
          position: 'top',
          direction: 'right',
        });
      }}
      reloadEvent="reloadPurchaseAssetForm"
      reloadOnChangeEvent={`reload${MODELS.PHARMACY_ITEM}`}
      uri={ViewPurchaseRequestListAsset}
      passDataToNext={(props) => {
        const { data } = props;
        data.fromSupplyOrder = fromSupplyOrder;
        data.purchaseSupplyOrderId = purchaseSupplyOrderId;
        // if (fromPS2) {
        //   data.isContractQueueCreated = isContractQueueCreated;
        //   data.fromPS2 = fromPS2;
        // }
        // if ((fromSupplyOrder || !isContractual) && !fromPS2) {
        //   data.manufacturer = !isContractual
        //     ? purchaseRequestAsset?.manufacturer
        //     : manufacturer;
        //   data.supplier = !isContractual
        //     ? purchaseRequestAsset?.supplier
        //     : supplier;
        // }
        return data;
      }}
      next={fromPS2 ? ROUTES.PS2Modal.name : ROUTES.assetCreatePurchaseAndSupplyOrderModal.name}
      mandatory={{
        daysLimitForApproval: 1,
        code: 1,
        ...mandatoryFields,
      }}
      computations={{
        self: {
          autoFill: {
            compute: (data) => {
              const { code } = data;
              if (code?.conditions?.length) {
                setIsCodeSelect(true);
                const newConditions = code?.conditions?.map((el, index) => ({
                  ...el,
                  code: data?.code?.code,
                  sNo: index + 1,
                  selected: false,
                }));
                return {
                  set: {
                    conditions: newConditions,
                  },
                };
              }

              setIsCodeSelect(false);
              return {
                set: {
                  conditions: [],
                },
              };
            },
            onChange: ['code', 'checkBoxGroupInput'],
          },
          supplier: {
            compute: () => ({
              set: { manufacturer: null },
            }),
            onChange: ['supplier'],
          },
        },
        children: {
          conditions: {
            self: {
              conditions: {
                compute: (data) => {
                  const {
                    _parent: { conditions = [] },
                  } = data;
                  conditions[data?.sNo - 1].selected = !conditions[
                  data?.sNo - 1
                  ].selected;
                  const updatedConditions = conditions;
                  return {
                    set: {
                      conditions: updatedConditions,
                    },
                  };
                },
                onChange: ['checkBoxGroupInput'],
              },
            },
          },
        },
      }}
      lg={{
        tabProps: { ...tabTheme },
        tabs: {
          BasicDetails: {
            label: 'Basic Details',
            formGroups: [
              {
                columnsPerRow: 5,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        label: 'purchase.labels.purchaseRequestNumber'.getLabel(),
                        field: 'purchaseRequestAsset.purchaseRequestNumber',
                        editable: false,
                      },
                      {
                        type: 'date',
                        label: 'purchase.labels.date'.getLabel(),
                        value: new Date(),
                        editable: false,
                      },
                      {
                        type: 'text',
                        label: 'purchase.labels.department'.getLabel(),
                        field: 'type',
                      },
                      {
                        type: 'checkBox',
                        label: 'purchase.labels.urgent'.getLabel(),
                        container: 'topLabel',
                        options: ['urgent'],
                        field: 'urgent',
                        editable: true,
                      },
                      {
                        type: 'number',
                        skipSelector: true,
                        label: 'purchase.labels.daysLimitForApproval'.getLabel(),
                        field: 'daysLimitForApproval',
                        editable: true,
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'Item Details',
                columnsPerRow: 6,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        label: 'purchase.labels.itemName'.getLabel(),
                        field: 'purchaseRequestAsset.item.name',
                      },
                      //                       (fromSupplyOrder || !isContractual) && !fromPS2
                      //                         ? {
                      //                           type: 'text',
                      //                           label: 'purchase.labels.supplier'.getLabel(),
                      //                           render: () => (
                      //                             <Text style={{ ...vars.headings.h8 }}>
                      //                               {
                      //                           !isContractual
                      //                             ? purchaseRequestAsset?.supplier?.name
                      //                             : supplier?.name
                      // }
                      //                             </Text>
                      //                           ),
                      //                         }
                      //                         :
                      searchInput({
                        field: 'supplier',
                        label: 'purchase.labels.supplier'.getLabel(),
                        placeholder: 'purchase.placeholders.selectSupplier'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.PHARMACY_SUPPLIER,
                        query: 'masterDataList',
                        searching: true,
                        editable: true,
                        mandatory: isMandatory,
                      }, {
                        addOnFilter: ({
                          data: {
                            purchaseRequestAsset: {
                              item: {
                                rateContract,
                              },
                            },
                          },
                        }) => ({
                          _id: {
                            $in: rateContract.map(({ supplier, rateContractValidity = new Date() }) => (moment().diff(rateContractValidity, 'days') < 0 ? supplier._id : null)),
                          },
                        }),
                      }),
                      // (fromSupplyOrder || !isContractual) && !fromPS2
                      //   ? {
                      //     type: 'text',
                      //     label: 'purchase.labels.manufacturer'.getLabel(),
                      //     render: () => (
                      //       <Text style={{ ...vars.headings.h8 }}>
                      //         {
                      //         !isContractual
                      //           ? purchaseRequestAsset?.manufacturer?.name
                      //           : manufacturer?.name
                      //           }
                      //       </Text>
                      //     ),
                      //   }
                      //   :
                      searchInput({
                        field: 'manufacturer',
                        label: 'purchase.labels.manufacturer'.getLabel(),
                        placeholder: 'purchase.placeholders.selectManufacturer'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.PHARMACY_MANUFACTURER,
                        query: 'masterDataList',
                        searching: true,
                        editable: true,
                        mandatory: isMandatory,
                        addOnFilter: {
                          module: modules?.asset,
                        },
                      },
                      {
                        addOnFilter: ({
                          data: {
                            purchaseRequestAsset: {
                              item: {
                                rateContract,
                              },
                            },
                            supplier: { _id },
                          },
                        }) => ({
                          _id: {
                            $in: rateContract.map(({ manufacturer }) => manufacturer?._id),
                          },
                          supplier: { _id },
                        }),
                      }),
                      {
                        type: 'text',
                        label: 'purchase.labels.purchaseQuantity'.getLabel(),
                        field: 'purchaseRequestAsset.requiredQuantity',
                      },
                      {
                        type: 'text',
                        label: 'purchase.labels.unitPrice'.getLabel(),
                        field: 'purchaseRequestAsset.unitPrice',
                      },
                      {
                        type: 'text',
                        label: 'purchase.labels.totalPrice'.getLabel(),
                        render: ({ data }) => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {data?.purchaseRequestAsset?.requiredQuantity
                    * data?.purchaseRequestAsset?.unitPrice}
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        label: '',
                        value: '',
                        editable: false,
                      },
                      {
                        type: 'link',
                        render: ({ data }) => (
                          <Action
                            navigation={navigation}
                            action={{
                              type: 'link',
                              link: () => ({
                                view: ROUTES.editPurchaseDepartmentTC.name,
                                params: { item: { _id: data?.code?._id } },
                              }),
                            }}
                          >
                            {data?.code ? (
                              <StandardButton label="Add T&C" width={100} />
                      ) : null}
                          </Action>
                        ),
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'code',
                        label: 'purchase.labels.typeTAndC'.getLabel(),
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.PURCHASE_DEPARTMENT_TC,
                        query: 'masterDataListForTable',
                        editable: true,
                        mandatory: true,
                      }),
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: isCodeSelect ? 'purchase.labels.termsAndConditions'.getLabel() : '',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        field: 'conditions',
                        fieldType: 'nested',
                        editable: true,
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          skipTableHeaderOnNoData: true,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              numberOfLines: 1,
                            },
                            columns: [
                              {
                                type: 'checkBoxGroup',
                                field: 'checkBoxGroupInput',
                                header: ' ',
                                options: [{ value: '1' }],
                                width: 30,
                              },
                              {
                                type: 'text',
                                field: 'sNo',
                                variant: 'filled',
                                header: 'S No.',
                                editable: false,
                                width: 100,
                              },

                              {
                                type: 'text',
                                field: 'name',
                                variant: 'filled',
                                header: 'purchase.header.tAndCName'.getLabel(),
                                editable: false,
                              },
                              {
                                type: 'text',
                                field: 'description',
                                variant: 'filled',
                                header: 'purchase.header.description'.getLabel(),
                                editable: false,
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          TechnicalDetails: {
            label: 'Technical Specification',
            formGroups: [
              {
                fieldVariant: 'filled',
                label: 'asset.title.technicalDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: (props) => (
                          <FormField
                            {...props}
                            {...{
                              field: 'purchaseRequestAsset.technicalDetails',
                              fieldType: 'nested',
                              Component: NestedTable,
                              componentProps: {
                                maxHeight: 400,
                                skipTableHeaderOnNoData: true,
                                listProps: {
                                  hideColumnHeader: true,
                                  columnsPerRow: 1,
                                  columns: [
                                    {
                                      type: 'custom',
                                      render: (nestedTableProps) => {
                                        const { item } = nestedTableProps;
                                        const {
                                          technicalListDetails,
                                          technicalTitle,
                                          serialNo,
                                        } = item;

                                        const technicalDetailsList = [];
                                        if (technicalListDetails) {
                                          technicalListDetails.forEach((element) => {
                                            technicalDetailsList.push({
                                              description: element?.description,
                                            });
                                          });
                                        }

                                        if (item) {
                                          return (
                                            <View
                                              style={{
                                                flex: 1,
                                                width: 1200,
                                                marginTop: 20,
                                              }}
                                            >
                                              <View
                                                style={{
                                                  flexDirection: 'row',
                                                  flex: 1,
                                                  justifyContent: 'space-between',
                                                }}
                                              >
                                                <Text style={{
                                                  ...vars.headings.h9,
                                                  color: vars.colors.secondary.color2,
                                                  paddingLeft: 5,
                                                }}
                                                >
                                                  {serialNo}
                                                  {'. '}
                                                  {upperFirst(technicalTitle)}
                                                </Text>
                                                <TouchableOpacity
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onPress={() => {
                                                    navigation.push({
                                                      view: ROUTES.editTechnicalModalPurchase.name,
                                                      params: {
                                                        item: purchaseRequestAsset,
                                                        serialNo,
                                                        setValue: props?.setValue,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <Image source={editIcon} />
                                                </TouchableOpacity>
                                              </View>
                                              {technicalDetailsList.map((need) => (
                                                <View
                                                  style={{
                                                    flexBasis: '50%',
                                                    flexDirection: 'row',
                                                    marginTop: 10,
                                                  }}
                                                  key="description"
                                                >
                                                  <View style={{ marginLeft: 10 }}>
                                                    <View
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          height: 5,
                                                          width: 5,
                                                          borderRadius: 2.5,
                                                          backgroundColor: vars.colors.grey.color3,
                                                        }}
                                                        numberOfLines={4}
                                                      />
                                                      <Text
                                                        style={{
                                                          ...vars.headings.h9,
                                                          textOverFlow: 'scroll',
                                                          color: vars.colors.grey.color3,
                                                          marginLeft: 4,
                                                        }}
                                                        numberOfLines={4}
                                                      >
                                                        {` ${need?.description}`}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              ))}
                                            </View>
                                          );
                                        }
                                        return <View />;
                                      },
                                    },
                                  ],
                                },
                              },
                            }}
                          />
                        ),

                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      }}
      {...props}
    />
  );
};

export default CreatePurchaseAndSupplyOrderForAssetOrder;

