/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { Text } from 'react-native';
import {
  FormField,
  StandardButton,
  View,
  Toast,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import ListActionModal from '../../../../Components/ListActionModal';
import { detailIcon } from '../../../../images';
import { invoke } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { getErrorString } from '../../../../Lib/helpers';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import {
  MODELS, ROUTES, vaccinationStatus, vaccinationType,
} from '../../../../Lib/constants';

const RepeatedDoseVaccineDetail = (props) => {
  const { navigation, eventDispatcher } = props;
  const { navigation: { state: { params } = {} } = {} } = props;
  const [vaccineDoseDetail, setVaccineDoseDetail] = useState([]);

  const updatePatientVaccinationHistory = useCallback(async (data) => {
    try {
      data.patientVaccinationHistory = params;
      const result = await invoke({
        id: 'updatePatientVaccinationHistory',
        paramValue: data,
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'vaccination.messages.vaccineDoseProvideSuccessfully'.getLabel(),
          type: 'success',
          position: 'top',
          direction: 'right',
        });
        eventDispatcher.notify(`reload${MODELS.PATIENT_VACCINATION_HISTORY}`);
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [eventDispatcher, params]);

  useEffect(() => {
    const { vaccinationSoFar = [], item = {}, vaccineDoseTaken = 0 } = params;
    const firstBillignDate = vaccinationSoFar[0]?.vaccination?.billing?._createdOn;
    const doseSuccessfullyTaken = vaccinationSoFar?.filter((item) => item?.status === vaccinationStatus.provided.toLowerCase());
    const doseSuccessfullyTakenLength = doseSuccessfullyTaken?.length;
    let temp = 1;
    let doseDueDateIndex = 0;
    let prevDoseDate = firstBillignDate;
    const modifiiedVaccineDetail = [...Array(params?.item?.noOfDoses)]?.map((_, index) => {
      let doseDueDate = '';
      const doseDetail = {};
      doseDetail.serialNo = index + 1;
      if (temp <= vaccineDoseTaken) {
        doseDetail.doseStatus = vaccinationStatus.provided;
        temp += 1;
      } else {
        doseDetail.doseStatus = vaccinationStatus.pending;
      }
      const intervalType = item?.vaccinationReOccurringDetails[doseDueDateIndex]?.intervalType;
      const doseDueDateInterval = item?.vaccinationReOccurringDetails[doseDueDateIndex]?.interval;
      if (!doseDueDateIndex) {
        doseDueDate = moment(firstBillignDate)?.add(doseDueDateInterval, intervalType);
        prevDoseDate = doseDueDate;
        doseDetail.doseDueDate = moment(doseDueDate).format('DD MMM YYYY');
      } else {
        const { doseProvidedDate } = vaccinationSoFar?.[doseDueDateIndex - 1];
        if (doseProvidedDate) {
          doseDueDate = moment(doseProvidedDate)?.add(doseDueDateInterval, intervalType);
          doseDetail.doseDueDate = moment(doseDueDate).format('DD MMM YYYY');
        }
        doseDueDate = moment(prevDoseDate)?.add(doseDueDateInterval, intervalType);
        prevDoseDate = doseDueDate;
        doseDetail.doseDueDate = moment(doseDueDate).format('DD MMM YYYY');
      }
      doseDueDateIndex += 1;
      doseDetail.payment = 'Paid';
      setVaccineDoseDetail(vaccineDoseDetail.push(doseDetail));
    });
    doseSuccessfullyTaken.map((item, index) => {
      vaccineDoseDetail[index].doseProvidedDate = moment(doseSuccessfullyTaken[index]?._createdOn).format('DD MMM YYYY');
    });
    if (vaccineDoseTaken < item?.noOfDoses) {
      vaccinationSoFar[doseSuccessfullyTakenLength].doseProvidedDate = moment().format('DD MMM YYYY');
    }
  }, []);

  return (
    <ListActionModal
      icon={detailIcon}
      description="Details and status of vaccine."
      reloadEvent={`reload${MODELS.PATIENT_VACCINATION_HISTORY}`}
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={({ data }) => {
            updatePatientVaccinationHistory(data);
            const paramValue = params;
            paramValue.vaccineDoseDetail = data?.VaccineDetail;
            navigation.push({ view: ROUTES.patientVaccineDetailConfirmationModal.name, params: paramValue });
          }}
          defaultValues={() => ({
            VaccineDetail: vaccineDoseDetail || [],
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ width: 228 }}>
                <FormField
                  type="text"
                  label={'vaccination.labels.status'.getLabel()}
                  variant="filled"
                  editable={false}
                  render={() => {
                    const { item, vaccineDoseTaken } = params;
                    let status = '';
                    let color = '';
                    if (item?.noOfDoses === vaccineDoseTaken) {
                      status = 'Vaccinated';
                      color = vars.colors.primary.color2;
                    } else {
                      color = vars.colors.warning;
                      status = 'On-going';
                    }
                    return (
                      <Text style={{ ...vars.headings.h8, color }}>
                        {status}
                      </Text>
                    );
                  }}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <Text style={{ ...vars.headings.h9 }}>
                  VACCINE INFORMATION
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.vaccineName'.getLabel()}
                    variant="filled"
                    value={params.item?.name}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.reVaccinationGap'.getLabel()}
                    variant="filled"
                    render={() => {
                      const { item, vaccineDoseTaken = 0 } = params;
                      let daysBeforeRevaccination = '';
                      if (!vaccineDoseTaken) {
                        return '--';
                      }
                      if (item?.vaccinationType === vaccinationType.reCurring && item?.isReoccurringIntervalEqual) {
                        daysBeforeRevaccination = `${item?.vaccinationReOccurringDetails[0]?.interval} ${item?.vaccinationReOccurringDetails[0]?.intervalType}`;
                      }
                      if (item?.vaccinationType === vaccinationType.reCurring && !item?.isReoccurringIntervalEqual) {
                        daysBeforeRevaccination = `${item?.vaccinationReOccurringDetails[vaccineDoseTaken]?.interval} ${item?.vaccinationReOccurringDetails[vaccineDoseTaken]?.intervalType}`;
                      }
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          {daysBeforeRevaccination}
                        </Text>
                      );
                    }}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.noOfDose'.getLabel()}
                    variant="filled"
                    value={params.item?.noOfDoses}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.description'.getLabel()}
                    variant="filled"
                    value={params?.item?.description}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <Text style={{ ...vars.headings.h9 }}>
                  DOSE DETAILS
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 5 }}>
                <View>
                  <FormField
                    {...{
                      fieldVariant: 'filled',
                      field: 'VaccineDetail',
                      fieldType: 'nested',
                      label: 'DOSE DETAILS ',
                      columnsPerRow: 4,
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        skipTableHeaderOnNoData: false,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 32,
                            backgroundColor: vars.colors.white,
                          },
                          headerRowColumnTextStyle: {
                            paddingLeft: 12,
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                            numberOfLines: 1,
                            textTransform: 'upperCase',
                          },
                          columns: [
                            {
                              type: 'text',
                              header: 'vaccination.headers.doseNo'.getLabel(),
                              field: 'serialNo',
                              variant: 'filled',
                              editable: false,
                              width: 150,
                            },
                            {
                              type: 'date',
                              format: 'DD MM YYYY',
                              variant: 'filled',
                              header: 'vaccination.headers.doseDuseDate'.getLabel(),
                              field: 'doseDueDate',
                              editable: false,
                              width: 200,
                            },
                            {
                              type: 'text',
                              variant: 'filled',
                              header: 'vaccination.headers.doseProvidedDate'.getLabel(),
                              field: 'doseProvidedDate',
                              editable: false,
                              width: 200,
                            },
                            {
                              type: 'text',
                              variant: 'filled',
                              header: 'vaccination.headers.doseStatus'.getLabel(),
                              render: ({ item }) => {
                                let statusColor = '';
                                if (item?.doseStatus === vaccinationStatus?.pending) {
                                  statusColor = vars.colors.warning;
                                } else {
                                  statusColor = vars.colors.primary.color2;
                                }
                                return (
                                  <Text style={{ color: statusColor }}>
                                    {item?.doseStatus}
                                  </Text>
                                );
                              },
                              editable: false,
                              width: 200,
                            },
                            {
                              type: 'text',
                              variant: 'filled',
                              header: 'vaccination.headers.paymentStatus'.getLabel(),
                              field: 'payment',
                              editable: false,
                              width: 200,

                            },
                          ],
                        },
                      },
                    }}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }} />
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'vaccination.buttons.provideNextDose'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={180}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default RepeatedDoseVaccineDetail;
