import React from 'react';

import {
  Text,
} from '../../../../../../app-components';
import {
  Table,
} from '../../../../../../app-components/table/Table';
import { PharmacyMSILowShelfLifeRequestsListUri } from '../../../../../../Queries/pharmacy';
import { MODELS, pharmacyInventoryStockRequestStatus, ROUTES } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';

const tableItems = (queryId, defaultValues) => ({
  lg: {
    columns: [
      { width: 14 },
      {
        header: 'inventory.headers.date'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
        sortable: '_createdOn',
      },
      {
        header: 'inventory.headers.itemId'.getLabel(),
        type: 'text',
        field: 'item.itemCode',
      },
      {
        header: 'inventory.headers.itemName'.getLabel(),
        type: 'text',
        field: 'item.name',
      },
      {
        header: 'inventory.headers.category'.getLabel(),
        type: 'text',
        field: 'item.category.name',
      },
      {
        header: 'inventory.headers.subCategory'.getLabel(),
        type: 'text',
        field: 'item.subCategory.name',
      },
      {
        header: 'inventory.headers.description'.getLabel(),
        type: 'text',
        field: 'item.description',
      },
      {
        header: 'inventory.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { isLowShelfLifeRejected, isLowShelfLifeApproved } }) => {
          let otherRequestStatus = '';
          let statusColor = '';
          if (isLowShelfLifeApproved) {
            otherRequestStatus = pharmacyInventoryStockRequestStatus.Approved;
            statusColor = vars.colors.primary.color2;
          } else if (isLowShelfLifeRejected) {
            otherRequestStatus = pharmacyInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          } else {
            otherRequestStatus = pharmacyInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {otherRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewLowShelfLifeRequest.name,
              params: { item, queryId, defaultValues },
            }),
          },
        ],
      },
    ],
  },
});

export default (props) => {
  const { queryId, defaultValues } = props;

  return (
    <Table
      {...props}
      reloadEvent={`reload${MODELS.PHARMACY_BATCH}`}
      uri={PharmacyMSILowShelfLifeRequestsListUri(
        {
          filter: {
            isLowShelfLife: true,
          },
          queryId,
        },
      )}
      {...tableItems(queryId, defaultValues)}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewLowShelfLifeRequest.name,
          params: { item, defaultValues, queryId },
        },
      })}
    />
  );
};
