/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useCallback } from 'react';

import {
  FormField,
  StandardButton,
  View,
  Toast,
} from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import ListActionModal from '../../../../../../Components/ListActionModal';
import {
  autoSuggestInput,
  autoSuggestOptionsInput,
  multiAutoSuggestInput,
} from '../../../../../../autoSuggestions';
import {
  bloodBankTestResult,
  bloodBankTestStatus,
  bloodBankBagStatus, MODELS,
} from '../../../../../../Lib/constants';
import { detailIcon } from '../../../../../../images';
import { invoke } from '../../../../../../AppServices';
import vars from '../../../../../../theme/vars';
import WithPermission from '../../../../../../Components/WithPermission';
import permissions from '../../../../../../Lib/permissions';
import {
  getRemainingSelfValue,
  getExpirationStatus,
  getErrorString,
} from '../../../../../../Lib/helpers';

const BloodBagListModal = (props) => {
  const { eventDispatcher } = props;
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;

  const handleTestStatusChanges = useCallback(async ({ data }) => {
    try {
      await invoke({
        id: 'handleAddStockToBloodBankInventory',
        paramValue: data,
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_BANK}`);
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  return (
    <ListActionModal
      icon={detailIcon}
      title={'bloodInventory.title.bloodBagDetails'.getLabel()}
      description={'bloodInventory.title.bloodBagDescription'.getLabel()}
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          mandatory={{
            name: 1,
          }}
          onSubmit={(submitProps) => {
            handleTestStatusChanges(submitProps);
          }}
          reloadEvent={`reload${MODELS.BLOOD_BANK}`}
          closeView={1}
          computations={{
            self: {
              bagStatus: {
                compute: (data) => {
                  if (data?.result?.length) {
                    return { set: { bagStatus: 'Discard' } };
                  }
                  return { set: { bagStatus: 'Active' } };
                },
                onChange: ['result'],
              },
            },
          }}
          submitMessage={'bloodInventory.messages.bloodBagUpdate'.getLabel()}
          defaultValues={() => ({
            ...item,
          })}
          {...props}
        >
          {({ form_context: formContext, form_state: { data = {} } = {} }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ width: 228 }}>
                <FormField
                  type="text"
                  label={'bloodInventory.labels.expiryStatus'.getLabel()}
                  variant="filled"
                  editable={false}
                  value={getExpirationStatus(item?.expiryDate)}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'bloodInventory.labels.bagNo'.getLabel()}
                    variant="filled"
                    field="name"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'bloodInventory.labels.tubeNo'.getLabel()}
                    variant="filled"
                    field="tubeNo"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'bloodInventory.labels.bagWeight'.getLabel()}
                    variant="filled"
                    field="bagWeight"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="date"
                    label={'bloodInventory.labels.donationDate'.getLabel()}
                    variant="filled"
                    field="date"
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'bloodInventory.labels.donorName'.getLabel()}
                    variant="filled"
                    field="bloodDonor.name"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="date"
                    label={'bloodInventory.labels.expiryDate'.getLabel()}
                    variant="filled"
                    field="expiryDate"
                  />
                </View>
                <View style={{ flex: 2 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'bloodInventory.labels.remainingSelfLife'.getLabel()}
                    variant="filled"
                    value={getRemainingSelfValue(item?.expiryDate)}
                  />
                </View>
              </View>
              <View style={{ marginTop: 46, flexDirection: 'row' }}>
                <FormField
                  {...autoSuggestInput({
                    label: 'bloodInventory.labels.testStatus'.getLabel(),
                    field: 'testStatus',
                    variant: 'filled',
                    keyFiled: 'label',
                    suggestionField: 'label',
                    options: bloodBankTestStatus,
                    valueField: 'label',
                    disabled: () => (item?.testStatus !== 'untested'),
                  })}
                />
                {data?.testStatus === 'tested' ? (
                  <View style={{ paddingLeft: 16 }}>
                    <FormField
                      {...multiAutoSuggestInput({
                        label: 'bloodInventory.labels.result'.getLabel(),
                        field: 'result',
                        variant: 'filled',
                        keyFiled: 'label',
                        suggestionField: 'label',
                        options: bloodBankTestResult,
                        valueField: 'label',
                        disabled: () => (item?.testStatus !== 'untested'),
                      })}
                    />
                  </View>
                ) : null}
              </View>

              <View style={{ marginTop: 16, flexDirection: 'row' }}>
                <FormField
                  {...autoSuggestOptionsInput({
                    label: 'bloodInventory.labels.bagStatus'.getLabel(),
                    variant: 'filled',
                    keyFiled: 'label',
                    field: 'bagStatus',
                    suggestionField: 'label',
                    options: bloodBankBagStatus,
                    valueField: 'label',
                    disabled: ({ data }) => (data?.testStatus !== 'untested'),
                  })}
                />

                {data?.bagStatus === 'discard' ? (
                  <View style={{ paddingLeft: 16, width: 456 }}>
                    <FormField
                      type="textArea"
                      label={'bloodInventory.labels.remarks'.getLabel()}
                      variant="filled"
                      field="remarks"
                    />
                  </View>
                ) : null}
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                {item?.testStatus === 'untested' ? (
                  <WithPermission
                    access_key={
                      permissions.inventory.views.updateBloodBagCentralInventory.permissions
                        .updateBloodBagCentralInventory.value
                    }
                  >
                    <StandardButton
                      label={'bloodInventory.buttons.save'.getLabel()}
                      onClick={formContext.handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </WithPermission>
                ) : null}
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default BloodBagListModal;
