import { MODELS } from '../Lib/constants';

export const EditEmployeeDesignation = ({ navigation }) => ({
  query: {
    model: MODELS.DESIGNATION,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.DESIGNATION,
      // eslint-disable-next-line no-underscore-dangle
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.DESIGNATION,
});

export const EmployeeDesignation = () => ({
  query: {
    model: MODELS.EMPLOYEE,
    id: 'sanctionSeatData',
    // limit: 1,
  },
  model: MODELS.EMPLOYEE,
});
