import MasterForm from '../../../Wired/Master/MasterForm';
import { save, close } from '../../../app-components';
import { MODELS } from '../../../Lib/constants';

const header = {
  title: 'Add State',
  subTitle: 'Fill out the form to add new state',
  actions: [close],
};
const footer = {
  actions: [save()],
};

export default MasterForm({
  header,
  model: MODELS.STATE,
  footer,
});
