import React from 'react';
import { View } from '@applane/react-core-components';
import { MODELS, modules } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';
import { EditCategory } from '../../../../Queries/category';
import ViewCategoryTabs from './ViewCategory/components/Tabs';
import { Form } from '../../../../app-components/form/Form';
import { submit } from '../../../../AppServices';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'asset.labels.category'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'asset.labels.description'.getLabel(),
    minHeight: 100,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'asset.labels.status'.getLabel(),
    container: 'topLabel',
  },
];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

const EditCategoryForm = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        renderLoading={false}
        validations={validations}
        mandatory={{ name: 1 }}
        uniqueValidationMessage={'asset.messages.categoryAlreadyExists'.getLabel()}
        submitMessage={'asset.messages.categoryUpdatedSuccessfully'.getLabel()}
        uri={EditCategory}
        onSubmit={submit({
          model: MODELS.PHARMACY_CATEGORY,
        })}
        closeView={1}
        model={MODELS.PHARMACY_CATEGORY}
        editable
        columnsPerRow={3}
        formGroups={[
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groups: [
              {
                columns: [...formFields],
              },
            ],
          },
        ]}
        reloadEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
        defaultValues={() => ({
          isActive: true,
          module: modules.asset,
        })}
        {...props}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewCategoryTabs {...props} />
    </View>
  </View>
);
export default EditCategoryForm;
