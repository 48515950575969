export default {
  header: {
    status: 'Status',
    action: 'Actions',
    description: 'Description',
    diseaseName: 'Disease Name',
  },
  labels: {
    status: 'Status',
    diseaseName: 'Disease Name',
    description: 'Description',
    action: 'Actions',
  },
  placeholders: {
    diseaseName: 'Disease Name',
    description: 'Description',
  },
  message: {
    diseaseAlreadyExists: 'Disease Already Exists',
    diseaseUpdatedSuccessfully: 'Disease has been Updated successfully.',
    diseaseAddedSuccessfully: 'Disease has been Added successfully.',
  },
  button: {
    addDisease: 'Add Disease',
    editDisease: 'Edit Disease',
    create: 'Create',
    update: 'Update',
  },
};
