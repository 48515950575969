import React from 'react';
import {
  OutlineButton, Text, Toast,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { MODELS, ROUTES, vaccinationStatus } from '../../../Lib/constants';
import { getErrorString } from '../../../Lib/helpers';
import ViewPatientVaccinationHistoryDetails from './ViewPatientHistoryDetails';
import ViewVaccine from './ViewVaccine';
import { invoke } from '../../../AppServices';
import PatientHistoryTab from './Tabs';
import { Action } from '../../../app-components/action/Action';
import VaccinationHistoryLogsTableModal from './ViewVaccinationHistoryLogs/viewVaccinationHistoryLogsModal';
import ViewVaccineDetailModal from './ViewVaccinationHistoryLogs/viewVaccineDetailModal';
import vars from '../../../theme/vars';

export default {
  [ROUTES.viewPatientVaccinationHistory.name]: {
    screen: PatientHistoryTab,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Vaccination History" />
      ),
    },
  },
  [ROUTES.viewPatientVaccinationHistoryDetails.name]: {
    screen: ViewPatientVaccinationHistoryDetails,
    title: (props) => {
      const { navigation: { state: { params: { item } } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.patient?.uhid}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { navigation } = props;
          const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
          return (
            <Action
              navigation={navigation}
              action={{
                type: 'link',
                link: () => ({
                  view: ROUTES.viewVaccinationHistoryItemDetails.name,
                  params: {
                    item,
                  },
                }),
              }}
            >
              <OutlineButton
                label={'vaccination.buttons.viewVaccinationLogs'.getLabel()}
              />
            </Action>
          );
        },
      },
      {
        render: () => (
          <OutlineButton label="Reallign" />
        ),
        type: 'invoke',
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (item?.status !== vaccinationStatus.vaccinated) return true;
          return false;
        },
        invoke: async (props) => {
          try {
            const { navigation, eventDispatcher } = props;
            const {
              navigation: {
                state: {
                  params: { item = {} },
                },
              },
            } = props;
            const result = await invoke({
              id: 'reAllignPatientForVaccination',
              paramValue: item,
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: 'Patient has been Reallign',
                type: 'success',
                position: 'top',
                direction: 'right',
                duration: 3000,
              });
              eventDispatcher.notify(`reload${MODELS.VACCINATION}`);
              navigation.pop();
            }
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: `${getErrorString(e)}`,
              type: 'error',
              position: 'top',
              direction: 'right',
            });
          }
        },
      },
    ],
  },
  [ROUTES.viewVaccineList.name]: {
    screen: ViewVaccine,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 594,
      height: 522,
    },
  },
  [ROUTES.viewVaccinationHistoryItemDetails.name]: {
    screen: VaccinationHistoryLogsTableModal,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 1120,
      height: 463,
    },
  },
  [ROUTES.viewVaccineDetailList.name]: {
    screen: ViewVaccineDetailModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 1120,
      height: 522,
    },
  },
};
