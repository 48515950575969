import { MODELS } from '../Lib/constants';

export const EmployeeMonthlySalaryRemarkList = () => ({
  query: {
    id: 'employeeMonthlyRemarkDataList',
  },
  aggregate: true,
  model: MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK,
});

export const EditEmployeeMonthlySalaryRemarkList = (filter) => ({
  query: {
    id: 'employeeMonthlyRemarkDataList',
    relationValue: {
      model: MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK,
      _id: filter,
      relation: '_id',
    },
  },
  aggregate: true,
  model: MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK,
});
