import React from 'react';

import { viewDailyExaminationNoteUri } from '../../../../../../../../Queries/dailyExaminationNote';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { Text } from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import vars from '../../../../../../../../theme/vars';

const ViewDailyExaminationNote = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Examination Notes"
      description="Logged daily examination note for the Patient for the selected date."
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_DAILY_EXAMINATION_NOTES}`}
          uri={viewDailyExaminationNoteUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'date',
                            field: 'currentDate',
                            label: 'Date',
                            editable: false,
                            mandatory: true,
                          },
                          {
                            type: 'number',
                            field: 'bedNumber',
                            label: 'Bed Number',
                            editable: false,
                            mandatory: true,
                          },
                          {
                            type: 'number',
                            field: 'weight',
                            label: 'Weight',
                            editable: false,
                          },
                          {
                            type: 'date',
                            field: 'dateOfAdmission',
                            label: 'Date Of Admission',
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'crNumber',
                            label: 'Cr. Number',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'department',
                            label: 'Department',
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'ventilatorDay',
                            label: 'Ventilator Day',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'ettDay',
                            label: 'ETT Day',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'rtDay',
                            label: 'RT Day',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'foleyDay',
                            label: 'foley\'s Day',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doctorName.name',
                            label: 'Doctor\'s Name',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'sr',
                            label: 'R/S',
                            editable: false,
                            mandatory: true,
                            allowWhiteSpaces: true,
                          },
                          {
                            type: 'text',
                            field: 'cvs',
                            label: 'CVS',
                            editable: false,
                            mandatory: true,
                            allowWhiteSpaces: true,
                          },
                          {
                            type: 'text',
                            field: 'giS',
                            label: 'GI/S',
                            editable: false,
                            mandatory: true,
                            allowWhiteSpaces: true,
                          },
                          {
                            type: 'text',
                            field: 'cns',
                            label: 'CNS',
                            editable: false,
                            mandatory: true,
                            allowWhiteSpaces: true,
                          },
                          {
                            type: 'text',
                            field: 'other',
                            label: 'Others',
                            editable: false,
                            mandatory: true,
                            allowWhiteSpaces: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            type: 'textArea',
                            field: 'diagnosis',
                            label: 'Daignosis',
                            editable: false,
                          },
                          {
                            type: 'textArea',
                            field: 'history',
                            label: 'History',
                            editable: false,
                          },
                          {
                            type: 'textArea',
                            field: 'generalExamination',
                            label: 'General Examination',
                            editable: false,
                          },
                          {
                            type: 'textArea',
                            field: 'systemicExamination',
                            label: 'Systemic Examination',
                            editable: false,
                          },
                          {
                            type: 'textArea',
                            field: 'dailyNotes',
                            label: 'Daily Notes',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />
  );
};

const ViewDailyExamination = (props) => (<ViewDailyExaminationNote {...props} />);
export default ViewDailyExamination;
