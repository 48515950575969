import React from 'react';
import uuid from 'uuid/v4';

import {
  save, Text, View,
} from '../../../app-components';
import ListActionModal from '../../../Components/ListActionModal';
import { notificationTickWithBG } from '../../../images';
import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';

const PranPercentage = (props) => (
  <ListActionModal
    icon={notificationTickWithBG}
    title={'PRAN Percentage'.getLabel()}
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save,
          ],
        }}
        uri={{
          query: {
            model: MODELS.PRAN_PERCENTAGE,
            id: 'PRANPercentageQuery',
            // limit: 1,
          },
          model: MODELS.PRAN_PERCENTAGE,
        }}
        mandatory={{
          pranPercentage: {
            fromDate: 1,
            toDate: 1,
            percentage: 1,
          },
        }}
        closeView={1}
        reloadEvent={`reload${MODELS.PRAN_PERCENTAGE}`}

        defaultValues={() => ({
          pranPercentage: new Array(1)
            .fill()
            .map(() => ({ _id: `new_${uuid()}` })),
        })}
        onSubmit={submit({
          model: MODELS.PRAN_PERCENTAGE,
        })}
        submitMessage="Percentage had been saved successfully"
        type="standard"
        lg={{
          verticalFormGroups: [
            {
              formGroups: [
                {
                  fieldVariant: 'filled',
                  label: 'Pran Percentage',
                  labelStyle: {
                    ...vars.headings.h10,
                    color: vars.colors.grey.color2,
                  },
                  groupsContainerStyle: { paddingLeft: 0 },
                  groups: [
                    {
                      columns: [
                        {

                          field: 'pranPercentage',
                          addInBottom: true,
                          fieldType: 'nested',
                          Component: NestedTable,
                          componentProps: {
                            footer: ({ addRow }) => ({
                              leftActions: [{
                                render: () => (
                                  <View style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    width: 120,
                                    height: 40,
                                    borderColor: vars.colors.secondary.color2,
                                  }}
                                  >
                                    <Text style={{
                                      ...vars.headings.h9,
                                      color: vars.colors.secondary.color2,
                                    }}
                                    >
                                      {`+ ${'Add New Item'.getLabel()}`}
                                    </Text>
                                  </View>
                                ),
                                onPress: addRow,
                              }],
                            }),
                            skipTableHeaderOnNoData: true,
                            columnsPerRow: 3,
                            listProps: {
                              hideColumnHeader: false,
                              headerRowContainerStyle: {
                                minHeight: 20,
                                paddingLeft: 12,
                                paddingRight: 4,
                              },
                              columns: [
                                {
                                  type: 'date',
                                  label: 'From',
                                  variant: 'filled',
                                  field: 'fromDate',
                                },
                                {
                                  type: 'date',
                                  label: 'To',
                                  variant: 'filled',
                                  field: 'toDate',
                                },
                                {
                                  type: 'number',
                                  variant: 'filled',
                                  field: 'percentage',
                                  label: 'Percentage',
                                  width: 200,
                                },
                                NestedAction(),
                              ],
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        }}
        {...props}
      />
    )}
    {...props}
  />
);
export default PranPercentage;
