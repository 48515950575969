import React, { useCallback, useState } from 'react';

import {
  FormField, LargeButton, Toast, View,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import { invoke } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import { MODELS } from '../../Lib/constants';
import { getErrorString } from '../../Lib/helpers';
import vars from '../../theme/vars';

const GenerateIncomeTaxModal = (props) => {
  const {
    navigation,
  } = props;

  const selectedIds = navigation.getParam('selectedIds');
  const [includeCurrentMonthVisibility, setIncludeCurrentMonthVisibility] = useState(false);

  // const getLastDayOfMonth = (y, m) => new Date(y, m + 1, 0);

  const handleOnSubmitGenerateSalary = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'incomeTaxCalculate',
        paramValue: { employeeSelectedIds: selectedIds, ...data },
        // paramValue: { selectedIds, ...data },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Income tax has been generated successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [navigation, selectedIds]);

  return (
    <ListActionModal
      icon={history}
      title="Generate Income Tax"
      description={`This action will generate Income Tax of ${selectedIds.length} employees`}
      content={(
        <Form
          computations={{
            self: {
              tds_month_id: {
                compute: (data) => {
                  if (['3', '4'].includes(data?.tds_month_id?.key)) {
                    setIncludeCurrentMonthVisibility(true);
                  } else {
                    setIncludeCurrentMonthVisibility(false);
                  }
                },
                onChange: ['tds_month_id'],
              },
            },
          }}
          onSubmit={handleOnSubmitGenerateSalary}
          mandatory={{
            tds_month_id: 1,
            tds_year_id: 1,
          }}

          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'tds_month_id',
                        label: 'Select TDS Month',
                        placeholder: 'Select TDS Month',
                        variant: 'filled',
                        suggestionField: 'name',
                        model: MODELS.MONTHS,
                        query: 'MonthTypeSuggestions',
                        mandatory: true,
                      },
                    )}
                  />
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'tds_year_id',
                          label: 'Select TDS Year',
                          placeholder: 'Select TDS Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    {includeCurrentMonthVisibility ? (
                      <FormField
                        type="checkBox"
                        field="include_current_month"
                        label="Include Current Month in tax generation."
                        editable
                      />
                    ) : ''}
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Generate"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default GenerateIncomeTaxModal;
