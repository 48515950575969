import React from 'react';

import { Image } from '../../../../app-components';
import {
  mamcLogo,
} from '../../../../images';

const Logo = () => (
  <Image
    source={mamcLogo}
    style={{ height: 50 }}
  />
);

export default Logo;
