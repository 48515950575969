import { Form } from '../../app-components';
import { importFile } from '../../AppServices';

export default ({ submitMessage, uri, header }) => {
  const ImportForm = Form({
    type: 'standardShadow',
    onSubmit: importFile({ model: 'ImportHistoryLogs' }),
    uri,
    closeView: 1,
    reloadEvent: 'reloadimport',
    submitMessage,
    header,
    computations: {
      defaultSchoolBranch: {
        compute: async ({ data }) => {
          const { model } = data;
          return {
            source: model,
          };
        },
        onChange: ['model'],
      },
    },
    formGroups: [
      {
        columnsPerRow: 2,
        labelDirection: 'vertical',
        columns: [
          {
            field: 'model',
            placeholder: 'Select',
            label: 'Type',
            mandatory: true,
            type: 'search',
            options: ['departments'],
            searching: true,
            showArrow: true,
          },
          {
            field: 'file',
            placeholder: 'File',
            label: 'File',
            type: 'file',
          },
        ],
      },
    ],
  });

  return ImportForm;
};
