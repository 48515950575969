import Form from '../../app-components/form/Form';
import { submit } from '../../AppServices';
import vars from '../../theme/vars';

const defaultColumns = [
  {
    type: 'text',
    field: 'name',
    label: 'Name',
    mandatory: true,
    placeholder: 'masters.labels.name'.getLabel(),
  },
  {
    type: 'text',
    field: 'code',
    label: 'Code',
    placeholder: 'masters.labels.code'.getLabel(),
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'masters.labels.description'.getLabel(),
  },
];

const defaultMandatoryFields = { name: 1 };

export default ({
  uri,
  header,
  model,
  footer,
  formFields = defaultColumns,
  validations,
  mandatory = defaultMandatoryFields,
  extraFormGroups = [],
  otherFormGroups = [],
  closeView = 1,
  columnsPerRow = 3,
  ...otherProps
}) => {
  const MasterForm = Form({
    type: 'standard',
    uri,
    closeView,
    reloadEvent: `reload${model}`,
    reloadOnChangeEvent: `reload${model}`,

    onSubmit: submit({
      model,
    }),
    mandatory,
    ...otherProps,
    lg: {
      header,
      validations,
      verticalFormGroups: [
        {
          formGroups: [
            {
              columnsPerRow,
              fieldVariant: 'filled',
              groups: [
                {
                  columns: [...formFields],
                },
              ],
            },
            ...otherFormGroups,
          ],
        },
        {
          formGroupsContainerStyle: { backgroundColor: vars.colors.white },
          formGroups: [
            {
              groups: [
                {
                  columns: [...extraFormGroups],
                },
              ],
            },
          ],
        },
      ],
      footer,
    },
  });
  return MasterForm;
};
