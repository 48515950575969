import React from 'react';

import { FormHoc } from '../../../../app-components';
import RelationInput from '../../../../Components/RelationInput';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { genders, MODELS, ROUTES } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';
import printOPDCard from '../../../../Wired/OPDCard';
import { ageInput } from '../../../../compositeInputs';

const validations = {
  aadharNumber: ({ data }) => validateInputLength(data?.aadharNumber, 12, 12),
  BPLNumber: ({ data }) => validateInputLength(data?.BPLNumber, 10, 10),
  age: ({ data }) => validateInputLength(data?.age, 0, 3),
};

const AddPatientForm = FormHoc({
  type: 'standard',
  onSubmit: submit({
    model: MODELS.PATIENTS,
  }),
  reloadEvent: 'reloadOPDRegisteredPatients',
  validations,
  submitMessage: 'patient.messages.patientAddedSuccessfully'.getLabel(),
  uniqueValidationMessage: 'Aadhaar no. already exist.',
  submitNext: ({ navigation, submitResult }) => {
    const { state: { params: { viewRoute } = {} } = {} } = navigation;
    printOPDCard({
      patientName: submitResult?.result?.patientName,
      uhid: submitResult?.result?.uhid,
    }, () => {
      if (viewRoute === ROUTES.viewPatientIPD.name) {
        navigation.push(viewRoute,
          {
            _id: submitResult?.result?._id,
            openAdmitPatientForm: true,
            patient: submitResult?.result,
          });
      } else {
        navigation.push(ROUTES.viewPatientOPD.name,
          {
            _id: submitResult?.result?._id,
            openAddConsultationForm: true,
            patient: submitResult?.result,
          });
      }
    });
  },
  defaultValues: {
    relation: 'S/O',
    gender: 'male',
    ageType: 'Years',
  },
  mandatory: {
    patientName: 1,
    gender: 1,
    age: 1,
    relationName: 1,
    address: 1,
  },
  lg: {
    formGroups: [
      {
        columnsPerRow: 2,
        fieldVariant: 'filled',
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'patientName',
                label: 'patient.labels.patientName'.getLabel(),
                mandatory: true,
                allowWhiteSpaces: true,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                },
              },
              autoSuggestInput({
                label: 'patient.labels.gender'.getLabel(),
                field: 'gender',
                suggestionField: 'label',
                placeholder: 'patient.placeholders.selectGender'.getLabel(),
                options: genders,
                mandatory: true,
              }),
              ageInput({
                age: {
                  type: 'number',
                  field: 'age',
                  label: 'patient.labels.age'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value > 999) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                ageType: {
                  field: 'ageType',
                },
              }),
              {
                type: 'custom',
                render: (props) => (
                  <RelationInput
                    {...props}
                    mandatory
                    relationNameProps={{
                      field: 'relationName',
                      label: 'S/O. D/O, W/O',
                      mandatory: true,
                      allowWhiteSpaces: true,
                    }}
                  />
                ),
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                },
              },
              {
                type: 'number',
                field: 'aadharNumber',
                label: 'patient.labels.aadhar'.getLabel(),
                allowedDecimalDigits: false,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 12) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'number',
                field: 'BPLNumber',
                label: 'patient.labels.bpl'.getLabel(),
                inputProps: {
                  maxLength: 10,
                },
              },
              {
                type: 'number',
                field: 'mobile',
                label: 'patient.labels.mobile'.getLabel(),
                placeholder: 'patient.placeholders.mobile'.getLabel(),
                allowedDecimalDigits: false,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 10) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'text',
                field: 'address',
                label: 'patient.labels.address'.getLabel(),
                placeholder: 'patient.placeholders.address'.getLabel(),
                allowWhiteSpaces: true,
                mandatory: true,
              },
              // {
              //   type: 'textArea',
              //   field: 'remarks',
              //   label: 'patient.labels.remarks'.getLabel(),
              //   placeholder: 'patient.placeholders.remarks'.getLabel(),
              //   width: '50%',
              //   allowWhiteSpaces: true,
              // },
            ],
          },
        ],
      },
    ],
  },
});

const AddPatient = (props) => <AddPatientForm {...props} />;

export default AddPatient;
