import React from 'react';
import { TabNavigator } from '../../../../../app-components';
import { countUri } from '../../../../../Queries/countUri';
import { MODELS } from '../../../../../Lib/constants';
import EmployeeScheduleTabList from '../../../../../Wired/EmployeeScheduleTabList';

const EmployeeTabs = (props) => {
  const { navigation } = props;
  const { item } = navigation.state.params;
  if (!item?._id) {
    return null;
  }

  const EmployeeTabNavigations = TabNavigator({
    tabs: {
      Employees: {
        label: 'Schedule',
        screen: EmployeeScheduleTabList,
        screenProps: {
          model: MODELS.EMPLOYEE_SCHEDULE,
          id: 'schedules',
          data: item,
          addOnFilter: {
            employee: { _id: item?._id },
          },
        },
        countUri: countUri({
          model: MODELS.EMPLOYEE_SCHEDULE,
          id: 'schedules',
          filter: { employee: { _id: item?._id } },
        }),
      },
    },
  });
  return <EmployeeTabNavigations {...props} />;
};
export default EmployeeTabs;
