import { ROUTES } from '../../../../../../Lib/constants';

import logEYEInvestigations from './Investigations/AddInvestigation';

const entScreens = {
  [ROUTES.logENTInvestigation.name]: {
    screen: logEYEInvestigations,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 970,
      height: 'auto',
    },
  },
};

export default entScreens;
