/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';

import {
  FormHoc, Image, Text, View,
} from '../../../../../app-components';
import vars from '../../../../../theme/vars';

import { expiryStatusOptions, MODELS } from '../../../../../Lib/constants';
import { calculateRemainingSelfLife } from '../../../../../Lib/helpers';
import { EditPharmacyBatchRequestStatus } from '../../../../../Queries/pharmacy';
import { warningIcon } from '../../../../../images';

const ViewBatchUpdateRequests = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryId,
        } = {},
      } = {},
    } = {},
  } = props;

  const Form = FormHoc({
    type: 'standard',
    closeView: 1,
    reloadEvent: `reload${MODELS.PHARMACY_BATCH_REQUESTS}`,
    uri: EditPharmacyBatchRequestStatus({ ...props, queryId }),
    editable: false,
    lg: {
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  label: 'inventory.labels.itemId'.getLabel(),
                  field: 'item.itemCode',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.itemName'.getLabel(),
                  field: 'item.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.category'.getLabel(),
                  field: 'item.category.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.subCategory'.getLabel(),
                  field: 'item.subCategory.name',
                  editable: false,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'inventory.title.BatchDetailsAfterUpdation'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.primary.color2,
            textTransform: 'uppercase',
            paddingLeft: 4,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columnsPerRow: 5,
              columns: [
                {
                  type: 'text',
                  variant: 'filled',
                  label: 'inventory.labels.batchNumber'.getLabel(),
                  placeholder: 'inventory.placeholders.batchNumber'.getLabel(),
                  render: ({
                    data,
                  }) => {
                    const { batchNumber, requestStatus } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      if (batchNumber !== batchDetailsBeforeUpdation[0]?.batchNumber) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchNumber}</Text>
                          </View>
                        );
                      }
                    } else {
                      const { batchId } = data;
                      if (batchNumber !== batchId?.batchNumber) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchNumber}</Text>
                          </View>
                        );
                      }
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{batchNumber}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.supplyReceivedOn'.getLabel(),
                  placeholder: 'inventory.placeholders.supplyReceivedOn'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], supplyReceivedOn, requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      if (!moment(supplyReceivedOn).isSame(
                        moment(batchDetailsBeforeUpdation[0]?.supplyReceivedOn),
                      )) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(supplyReceivedOn).format('DD MMM YYYY')}</Text>
                          </View>
                        );
                      }
                    } else if (!moment(supplyReceivedOn).isSame(
                      moment(batchId?.supplyReceivedOn),
                    )) {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Image source={warningIcon} style={{ width: 20 }} />
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(supplyReceivedOn).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(supplyReceivedOn).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.stockEntryDate'.getLabel(),
                  placeholder: 'inventory.placeholders.stockEntryDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], stockEntryDate, requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      if (!moment(stockEntryDate).isSame(
                        moment(batchDetailsBeforeUpdation[0]?.stockEntryDate),
                      )) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(stockEntryDate).format('DD MMM YYYY')}</Text>
                          </View>
                        );
                      }
                    } else if (!moment(stockEntryDate).isSame(
                      moment(batchId?.stockEntryDate),
                    )) {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Image source={warningIcon} style={{ width: 20 }} />
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(stockEntryDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(stockEntryDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.manufacturingDate'.getLabel(),
                  placeholder: 'inventory.placeholders.manufacturingDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], manufacturingDate, requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      if (!moment(manufacturingDate).isSame(
                        moment(batchDetailsBeforeUpdation[0]?.manufacturingDate),
                      )) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(manufacturingDate).format('DD MMM YYYY')}</Text>
                          </View>
                        );
                      }
                    } else if (!moment(manufacturingDate).isSame(
                      moment(batchId?.manufacturingDate),
                    )) {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Image source={warningIcon} style={{ width: 20 }} />
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(manufacturingDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(manufacturingDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.expiryDate'.getLabel(),
                  placeholder: 'inventory.placeholders.expiryDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], expiryDate, requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      if (!moment(expiryDate).isSame(
                        moment(batchDetailsBeforeUpdation[0]?.expiryDate),
                      )) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(expiryDate).format('DD MMM YYYY')}</Text>
                          </View>
                        );
                      }
                    } else if (!moment(expiryDate).isSame(
                      moment(batchId?.expiryDate),
                    )) {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Image source={warningIcon} style={{ width: 20 }} />
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(expiryDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(expiryDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'text',
                  variant: 'filled',
                  label: 'inventory.labels.remainingSelfLife'.getLabel(),
                  placeholder: 'inventory.placeholders.remainingSelfLife'.getLabel(),
                  render: ({ data }) => (
                    <Text style={{ ...vars.headings.h8 }}>
                      { calculateRemainingSelfLife(data?.expiryDate)}
                    </Text>
                  ),
                },
                {
                  type: 'number',
                  variant: 'filled',
                  label: 'inventory.labels.quantityReceived'.getLabel(),
                  render: ({
                    data,
                  }) => {
                    const { quantityReceived, requestStatus } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      if (quantityReceived !== batchDetailsBeforeUpdation[0]?.quantityReceived) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{quantityReceived}</Text>
                          </View>
                        );
                      }
                    } else {
                      const { batchId } = data;
                      if (quantityReceived !== batchId?.quantityReceived) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{quantityReceived}</Text>
                          </View>
                        );
                      }
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{quantityReceived}</Text>;
                  },
                },
                {
                  type: 'number',
                  variant: 'filled',
                  label: 'inventory.labels.purchasedPrice'.getLabel(),
                  render: ({
                    data = {},
                  }) => {
                    const { purchasedPrice, requestStatus } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      if (purchasedPrice !== batchDetailsBeforeUpdation[0]?.purchasedPrice) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{purchasedPrice}</Text>
                          </View>
                        );
                      }
                    } else {
                      const { batchId } = data;
                      if (purchasedPrice !== batchId?.purchasedPrice) {
                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{purchasedPrice}</Text>
                          </View>
                        );
                      }
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{purchasedPrice}</Text>;
                  },
                },
                {
                  type: 'custom',
                  label: 'inventory.labels.isActive'.getLabel(),
                  container: 'topLabel',
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [],
                      isActive,
                      requestStatus,
                      batchId,
                    },
                  } = {}) => {
                    if (requestStatus === 'approved') {
                      if (batchDetailsBeforeUpdation[0]?.isActive !== isActive) {
                        return (
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Image source={warningIcon} style={{ width: 20 }} />
                            <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{isActive ? 'Active' : 'In-Active'}</Text>
                            <View style={isActive ? {
                              height: 8,
                              width: 8,
                              borderRadius: 4,
                              backgroundColor: vars.colors.primary.color2,
                              marginLeft: 8,
                            } : {
                              height: 8,
                              width: 8,
                              borderRadius: 4,
                              backgroundColor: vars.colors.error,
                              marginLeft: 8,
                            }}
                            />

                          </View>
                        );
                      }
                    } else if (batchId?.isActive !== isActive) {
                      return (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Image source={warningIcon} style={{ width: 20 }} />
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{isActive ? 'Active' : 'In-Active'}</Text>
                          <View style={isActive ? {
                            height: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: vars.colors.primary.color2,
                            marginLeft: 8,
                          } : {
                            height: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: vars.colors.error,
                            marginLeft: 8,
                          }}
                          />

                        </View>
                      );
                    }
                    return (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{isActive ? 'Active' : 'In-Active'}</Text>
                        <View style={isActive ? {
                          height: 8,
                          width: 8,
                          borderRadius: 4,
                          backgroundColor: vars.colors.primary.color2,
                          marginLeft: 8,
                        } : {
                          height: 8,
                          width: 8,
                          borderRadius: 4,
                          backgroundColor: vars.colors.error,
                          marginLeft: 8,
                        }}
                        />
                      </View>
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'inventory.title.BatchDetailsBeforeUpdation'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 4,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columnsPerRow: 5,
              columns: [
                {
                  type: 'text',
                  variant: 'filled',
                  label: 'inventory.labels.batchNumber'.getLabel(),
                  placeholder: 'inventory.placeholders.batchNumber'.getLabel(),
                  render: ({
                    data,
                  }) => {
                    const { requestStatus, batchId } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchDetailsBeforeUpdation[0]?.batchNumber}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{batchId?.batchNumber}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.supplyReceivedOn'.getLabel(),
                  placeholder: 'inventory.placeholders.supplyReceivedOn'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(batchDetailsBeforeUpdation[0]?.supplyReceivedOn).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(batchId?.supplyReceivedOn).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.stockEntryDate'.getLabel(),
                  placeholder: 'inventory.placeholders.stockEntryDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(batchDetailsBeforeUpdation[0]?.stockEntryDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(batchId?.stockEntryDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.manufacturingDate'.getLabel(),
                  placeholder: 'inventory.placeholders.manufacturingDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(batchDetailsBeforeUpdation[0]?.manufacturingDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(batchId?.manufacturingDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'date',
                  variant: 'filled',
                  label: 'inventory.labels.expiryDate'.getLabel(),
                  placeholder: 'inventory.placeholders.expiryDate'.getLabel(),
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [], requestStatus, batchId,
                    },
                  }) => {
                    if (requestStatus === 'approved') {
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{moment(batchDetailsBeforeUpdation[0]?.expiryDate).format('DD MMM YYYY')}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{moment(batchId?.expiryDate).format('DD MMM YYYY')}</Text>;
                  },
                },
                {
                  type: 'text',
                  variant: 'filled',
                  label: 'inventory.labels.remainingSelfLife'.getLabel(),
                  placeholder: 'inventory.placeholders.remainingSelfLife'.getLabel(),
                  render: ({ data }) => {
                    if (data?.requestStatus === 'approved') {
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          { calculateRemainingSelfLife(data?.batchDetailsBeforeUpdation[0]?.expiryDate)}
                        </Text>
                      );
                    }
                    return (
                      <Text style={{ ...vars.headings.h8 }}>
                        { calculateRemainingSelfLife(data?.batchId?.expiryDate)}
                      </Text>
                    );
                  },
                },
                {
                  type: 'number',
                  variant: 'filled',
                  label: 'inventory.labels.quantityReceived'.getLabel(),
                  render: ({
                    data,
                  }) => {
                    const { requestStatus, batchId } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchDetailsBeforeUpdation[0]?.quantityReceived}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{batchId?.quantityReceived}</Text>;
                  },
                },
                {
                  type: 'number',
                  variant: 'filled',
                  label: 'inventory.labels.purchasedPrice'.getLabel(),
                  render: ({
                    data,
                  }) => {
                    const { requestStatus, batchId } = data;
                    if (requestStatus === 'approved') {
                      const { batchDetailsBeforeUpdation = [] } = data;
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchDetailsBeforeUpdation[0]?.purchasedPrice}</Text>
                        </View>
                      );
                    }
                    return <Text style={{ ...vars.headings.h8 }}>{batchId?.purchasedPrice}</Text>;
                  },
                },
                {
                  type: 'custom',
                  label: 'inventory.labels.isActive'.getLabel(),
                  container: 'topLabel',
                  render: ({
                    data: {
                      batchDetailsBeforeUpdation = [],
                      requestStatus,
                      batchId,
                    },
                  } = {}) => {
                    if (requestStatus === 'approved') {
                      return (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchDetailsBeforeUpdation[0]?.isActive ? 'Active' : 'In-Active'}</Text>
                          <View style={batchDetailsBeforeUpdation[0]?.isActive ? {
                            height: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: vars.colors.primary.color2,
                            marginLeft: 8,
                          } : {
                            height: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: vars.colors.error,
                            marginLeft: 8,
                          }}
                          />

                        </View>
                      );
                    }
                    return (
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ ...vars.headings.h8, paddingLeft: 6, paddingTop: 2 }}>{batchId?.isActive ? 'Active' : 'In-Active'}</Text>
                        <View style={batchId?.isActive ? {
                          height: 8,
                          width: 8,
                          borderRadius: 4,
                          backgroundColor: vars.colors.primary.color2,
                          marginLeft: 8,
                        } : {
                          height: 8,
                          width: 8,
                          borderRadius: 4,
                          backgroundColor: vars.colors.error,
                          marginLeft: 8,
                        }}
                        />

                      </View>
                    );
                  },
                },
                {
                  type: 'number',
                  field: 'batchId.quantityAvailable',
                  variant: 'filled',
                  label: 'inventory.labels.stockAvailable'.getLabel(),
                },
                {
                  type: 'text',
                  label: 'inventory.labels.stockStatus'.getLabel(),
                  render: ({ data: { requestStatus, batchDetailsBeforeUpdation = [], batchId = {} } } = {}) => {
                    const { quantityAvailable } = batchId;
                    if (requestStatus === 'approved') {
                      const { item: { reorderLevel } = 0 } = batchDetailsBeforeUpdation[0];
                      let stockStatus = '';
                      let statusColor = '';
                      if (quantityAvailable <= reorderLevel) {
                        stockStatus = expiryStatusOptions.critical;
                        statusColor = vars.colors.error;
                      } else {
                        stockStatus = expiryStatusOptions.optimal;
                        statusColor = vars.colors.primary.color2;
                      }
                      return (
                        <Text style={{ ...vars.headings.h11, color: statusColor }}>
                          {stockStatus}
                        </Text>
                      );
                    }
                    const { item: { reorderLevel } = 0 } = batchId;
                    let stockStatus = '';
                    let statusColor = '';
                    if (quantityAvailable <= reorderLevel) {
                      stockStatus = expiryStatusOptions.critical;
                      statusColor = vars.colors.error;
                    } else {
                      stockStatus = expiryStatusOptions.optimal;
                      statusColor = vars.colors.primary.color2;
                    }
                    return (
                      <Text style={{ ...vars.headings.h11, color: statusColor }}>
                        {stockStatus}
                      </Text>
                    );
                  },
                },
                {
                  type: 'text',
                  label: 'inventory.labels.expiryStatus'.getLabel(),
                  render: ({ data: { batchId, batchDetailsBeforeUpdation, requestStatus } }) => {
                    if (requestStatus === 'approved') {
                      const expiryDays = calculateRemainingSelfLife(batchDetailsBeforeUpdation[0]?.expiryDate);
                      let expiryStatus = expiryStatusOptions.active;
                      let statusColor = vars.colors.primary.color2;
                      if (expiryDays === 0 || expiryDays < 0) {
                        expiryStatus = expiryStatusOptions.expired;
                        statusColor = vars.colors.error;
                      } else if (expiryDays > 0 && expiryDays < 60) {
                        expiryStatus = expiryStatusOptions.nearToExpiry;
                        statusColor = vars.colors.warning;
                      } else if (expiryDays > 60 && expiryDays < 90) {
                        expiryStatus = expiryStatusOptions.nearToExpiry;
                        statusColor = vars.colors.emphasis;
                      }
                      return (
                        <Text style={{ ...vars.headings.h11, color: statusColor }}>
                          {expiryStatus}
                        </Text>
                      );
                    }
                    const expiryDays = calculateRemainingSelfLife(batchId?.expiryDate);
                    let expiryStatus = expiryStatusOptions.active;
                    let statusColor = vars.colors.primary.color2;
                    if (expiryDays === 0 || expiryDays < 0) {
                      expiryStatus = expiryStatusOptions.expired;
                      statusColor = vars.colors.error;
                    } else if (expiryDays > 0 && expiryDays < 60) {
                      expiryStatus = expiryStatusOptions.nearToExpiry;
                      statusColor = vars.colors.warning;
                    } else if (expiryDays > 60 && expiryDays < 90) {
                      expiryStatus = expiryStatusOptions.nearToExpiry;
                      statusColor = vars.colors.emphasis;
                    }
                    return (
                      <Text style={{ ...vars.headings.h11, color: statusColor }}>
                        {expiryStatus}
                      </Text>
                    );
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return <Form {...props} />;
};

export default ViewBatchUpdateRequests;
