import { MODELS } from '../Lib/constants';

export const GetBirthFormUri = (filter = {}) => ({
  query: {
    model: MODELS.IPD_BIRTH_FORM,
    id: 'birthFormsDetails',
    addOnFilter: filter,
  },
  aggregate: true,
  model: 'ipd_birth_forms',
});
