import { invoke } from '../../AppServices';

export const supplyOrder = async (orderInfo, cb) => {
  const result = await invoke({
    id: 'generateSOPdf',
    paramValue: {
      orientation: 'portrait',
      supplyOrderId: orderInfo?.supplyOrderId,
      itemType: orderInfo?.itemType,
      isWeb: true,
    },
  });

  const byteCharacters = atob(result.base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: 'application/pdf;base64',
  });
  const url = URL.createObjectURL(blob);
  const x = window.open(url, '_blank');
  // x.print();
  cb();
};
