import React, { useState } from 'react';
import { View } from '../../app-components';
import { userView } from '../../images';
import UserCardList from '../UserCardList';
import FTS from '../../Components/TableFilters/FtsSearch';
import ListActionModal from '../../Components/ListActionModal';

const ViewUserListModal = (props) => {
  const {
    navigation: {
      getScreenState,
      state: { params: { item, filter, description } },
    },
  } = props;
  const [screenState, setScreenState] = useState(getScreenState());
  return (
    <ListActionModal
      icon={userView}
      title={item?.code}
      description={description}
      content={(
        <>
          <View style={{ alignItems: 'center', marginBottom: 8 }}>
            <View>
              <FTS {...props} screenState={screenState} setScreenState={setScreenState} action={{ exp: ['firstName'] }} />
            </View>
          </View>
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <UserCardList filter={filter} {...props} dataParams={screenState.dataParams} />
          </View>
        </>
      )}
      {...props}
    />
  );
};
export default ViewUserListModal;
