import { EditConfiguration } from '../../../Queries/configuration';
import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { save } from '../../../app-components';

const header = {
  title: 'Edit Configuration',
  actions: [save({ label: 'Update' })],
};

const formFields = [
  {
    type: 'number',
    field: 'userInactivityTimeoutTime',
    label: 'User Inactivity TimeOut Time',
  },
  {
    type: 'text',
    field: 'adminContactNumber',
    label: 'Admin Contact Number',
  },
  {
    type: 'text',
    field: 'email',
    label: 'Email',
  },
  {
    type: 'text',
    field: 'website',
    label: 'Website',
  },
  {
    type: 'text',
    field: 'contactInfo',
    label: 'Contact Info',
  },
  {
    type: 'text',
    field: 'ddoRegnNo',
    label: 'DDO Regn No.',
  },
  // {
  //   type: 'file',
  //   field: 'attachment',
  //   label: '12BB Form',
  // },
  // {
  //   type: 'file',
  //   field: 'attachmentForm16',
  //   label: 'Form 16',
  // },
  {
    type: 'file',
    field: 'signature',
    label: 'Upload Signature',
    multiple: false,
  },
];

export default MasterForm({
  uri: EditConfiguration,
  header,
  formFields,
  model: MODELS.CONFIGURATION,
  mandatory: {},
  closeView: 0,
  submitMessage: 'Configuration details saved successfully',
});
