import React from 'react';

import { FormHoc } from '../../../../../../app-components';
import { MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { searchInput } from '../../../../../../autoSuggestions';
import { EditPharmacyTransferRequestStatus } from '../../../../../../Queries/pharmacy';

const ViewTransferRequests = (props) => {
  const {
    navigation: {
      state: {
        params: {
          queryId,
        } = {},
      } = {},
    } = {},
  } = props;

  const Form = FormHoc({
    ...props,
    type: 'standard',
    closeView: 1,
    reloadEvent: `reload${MODELS.PHARMACY_TRANSFER_REQUESTS}`,
    uri: EditPharmacyTransferRequestStatus({ ...props, queryId }),
    lg: {
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'inventory.labels.dateRaised'.getLabel(),
                  format: 'DD MMM YYYY',
                  field: '_createdOn',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.type'.getLabel(),
                  field: 'type',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.fromDepartment'.getLabel(),
                  field: 'fromDepartment.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'inventory.labels.toDepartment'.getLabel(),
                  field: 'toDepartment.name',
                  editable: false,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'inventory.title.itemDetails'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 4,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {
                  field: 'departmentStocks',
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: false,
                      headerRowContainerStyle: {
                        minHeight: 32,
                        paddingLeft: 12,
                        paddingRight: 4,
                      },
                      headerRowColumnTextStyle: {
                        paddingLeft: 2,
                        ...vars.headings.h13,
                        color: vars.colors.grey.color3,
                        numberOfLines: 1,
                        textTransform: 'upperCase',
                      },
                      columns: [
                        {
                          type: 'text',
                          header: 'inventory.labels.itemId'.getLabel(),
                          label: 'inventory.labels.itemId'.getLabel(),
                          variant: 'filled',
                          field: 'item.itemCode',
                          editable: false,
                          width: 120,
                        },
                        {
                          type: 'text',
                          header: 'inventory.labels.itemName'.getLabel(),
                          label: 'inventory.labels.itemName'.getLabel(),
                          variant: 'filled',
                          field: 'item.name',
                          editable: false,
                          width: 120,
                        },
                        searchInput({
                          field: 'item.category',
                          variant: 'filled',
                          header: 'inventory.labels.category'.getLabel(),
                          label: 'inventory.labels.category'.getLabel(),
                          placeholder: 'inventory.placeholders.supplier'.getLabel(),
                          keyField: 'name',
                          valueField: 'name',
                          editable: false,
                          width: 120,
                        }),
                        {
                          type: 'number',
                          header: 'inventory.labels.stockAvailable'.getLabel(),
                          label: 'inventory.labels.stockAvailable'.getLabel(),
                          variant: 'filled',
                          field: 'stockAvailable',
                          editable: false,
                          width: 150,
                        },
                        {
                          type: 'number',
                          header: 'inventory.labels.transferQuantity'.getLabel(),
                          label: 'inventory.labels.transferQuantity'.getLabel(),
                          variant: 'filled',
                          field: 'transferQuantity',
                          editable: false,
                          width: 150,
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return <Form />;
};

export default ViewTransferRequests;
