import React from 'react';
import {
  close, Form, save,
} from '../../../app-components';
import { Form as TagForm } from '../../../app-components/form/Form';
import {
  getUser, post, submit,
} from '../../../AppServices';
import { searchInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';

export const linkTypeOpenAction = ({
  text = '',
  view,
  type,
  params = '',
  modal,
  modalProps = {}, icon,
  iconStyle = {},
  extraNavProps = {},
}) => ({
  type,
  text,
  icon,
  iconStyle,
  link: ({ item }) => ({
    view,
    params: {
      [params]: item,
      item,
      ...extraNavProps,
    },
    modal,
    modalProps,
  }),
});

export const DrillAction = ({
  item,
  type,
  view,
  params = '',
  routeOptions = {},
}) => ({
  type,
  link: {
    view,
    params: params === '' ? { item } : { [params]: item },
    routeOptions,
  },
});

const submitMessage = 'Attendance Updated Successfully';

const AttendanceFormHoc = ({ submitMessage: submitMessageByProp, footer, header }) => Form({
  containerStyle: {
    paddingTop: 20,
    paddingBottom: 0,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: '#fff',
  },
  onSubmit: ({ navigation, updates }) => {
    const { item = {} } = (navigation && navigation.getParams()) || {};
    const response = { ...updates, is_approved: false };
    return post({
      data: item,
      updates: {
        ...response,
        was_manual_update: true,
      },
      model: MODELS.ATTENDANCE,
    });
  },
  footer,
  submitMessageByProp,
  reloadEvent: 'UserLOPDrillAttendaceList',
  closeView: 1,
  header,
  uri: ({ navigation }) => {
    const { attendance = {} } = (navigation && navigation.state && navigation.state.params) || {};
    const addOnFilter = attendance && attendance._id ? { _id: attendance._id } : {};
    return {
      query: {
        id: 'attendances',
        addOnFilter,
        fields: {
          _id: 1,
          second_attendance_type_id: { _id: 1, name: 1 },
          first_attendance_type_id: { _id: 1, name: 1 },
          remarks: 1,
        },
        only: true,
      },
      model: MODELS.ATTENDANCE,
    };
  },
  fieldVariant: 'filled',
  formGroups: [
    {
      columns: [
        searchInput(
          {
            field: 'first_attendance_type_id',
            label: 'First Half Day',
            query: 'AttendanceTypeSuggestions',
            model: MODELS.ATTENDANCE_TYPES,
            placeholder: 'First Half day',
            searchField: 'name',
            suggestionField: 'name',
            varient: 'filled',
          },
          {
            addOnFilter: () => ({
              code: {
                $in: [
                  'present',
                  'absent',
                  'ewd',
                ],
              },
            }),
          },
        ),
        searchInput(
          {
            field: 'second_attendance_type_id',
            label: 'Second Half Day',
            query: 'AttendanceTypeSuggestions',
            model: MODELS.ATTENDANCE_TYPES,
            placeholder: 'Second Half Day',
            searchField: 'name',
            suggestionField: 'name',
            varient: 'filled',
          },
          {
            addOnFilter: () => ({
              code: {
                $in: [
                  'present',
                  'absent',
                  'ewd',
                ],
              },
            }),
          },
        ),
        {
          type: 'textArea',
          placeholder: 'Remark',
          field: 'remarks',
          label: 'Remark',
        },
      ],
    },
  ],
});
const header = {
  containerStyle: {
    height: 44,
    paddingLeft: 14,
    paddingTop: 10,
  },
  title: 'Update Attendance',
  actions: [close],
};

const footer = {
  containerStyle: {
    height: 60,
    paddingTop: 5,
  },
  actions: [save({ label: 'Update' })],
};

export const AttendanceFormWithDateHoc = (props) => {
  const user = getUser();

  const { navigation } = props;
  const { employee: { _id } = {} } = user;

  const attendance = navigation.getParams('attendance');

  return (
    <TagForm
      type="standardShadow"
      containerStyle={{
        padding: 10,
      }}
      defaultValues={() => {
        if (attendance) {
          return {
            employee: {
              _id,
            },
            attendance_date: attendance?.attendance_date,
          };
        }
        return {
          employee: {
            _id,
          },
        };
      }}
      mandatory={{
        attendance_date: 1,
        first_attendance_type_id: 1,
        second_attendance_type_id: 1,
        remarks: 1,
      }}
      onSubmit={submit({
        model: MODELS.ATTENDANCE_REQUESTS,
      })}
      footer={footer}
      submitMessage="Attendance request has been raised successfully."
      reloadEvent="UserLOPDrillAttendaceList"
      closeView={1}
      header={header}
      fieldVariant="filled"
      formGroups={[
        {
          columnsPerRow: 2,
          groupsContainerStyle: {
            paddingTop: 10,
            flex: 1,
            overflow: 'hidden',
          },
          columns: [
            {
              type: 'date',
              label: 'Date',
              field: 'attendance_date',
              mandatory: true,
            },
            searchInput(
              {
                field: 'first_attendance_type_id',
                label: 'First Half Day',
                query: 'AttendanceTypeSuggestions',
                model: MODELS.ATTENDANCE_TYPES,
                placeholder: 'First Half day',
                searchField: 'name',
                suggestionField: 'name',
                varient: 'filled',
                mandatory: true,
              },
              {
                addOnFilter: () => ({
                  code: {
                    $in: [
                      'off',
                      'absent',
                      'present',
                    ],
                  },
                }),
              },
            ),
            searchInput(
              {
                field: 'second_attendance_type_id',
                label: 'Second Half Day',
                query: 'AttendanceTypeSuggestions',
                model: MODELS.ATTENDANCE_TYPES,
                placeholder: 'Second Half Day',
                searchField: 'name',
                suggestionField: 'name',
                varient: 'filled',
                mandatory: true,
              },
              {
                addOnFilter: () => ({
                  code: {
                    $in: [
                      'off',
                      'absent',
                      'present',
                    ],
                  },
                }),
              },
            ),
            {
              type: 'textArea',
              placeholder: 'Remark',
              field: 'remarks',
              label: 'Remark',
              mandatory: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const UpdateUserAttendance = AttendanceFormHoc({
  submitMessage,
  header,
  footer,
});

