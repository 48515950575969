import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength } from '../../../Lib/helpers';
import { EditEmployeeCategory } from '../../../Queries/employeeCategory';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Category Name'.getLabel(),
    mandatory: true,
    placeholder: 'masters.labels.name'.getLabel(),
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'masters.labels.description'.getLabel(),
    allowWhiteSpaces: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'Status',
    container: 'leftLabel',
  },
];

export default MasterForm({
  uri: EditEmployeeCategory,
  model: MODELS.EMPLOYEE_CATEGORY,
  submitMessage: 'Category Updated Successfully',
  formFields,
  mandatory: {
    name: 1,
  },
  validations: {
    name: ({ data }) => validateInputLength(data?.name, 2, 20),
  },
});
