import React from 'react';

import { autoSuggestInput } from '../../../../autoSuggestions';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { EditWard } from '../../../../Queries/speciality';
import WardTabs from './components/Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'speciality.labels.wardName'.getLabel(),
    mandatory: true,
    editable: false,
  },
  {
    type: 'text',
    field: 'code',
    label: 'speciality.labels.wardId'.getLabel(),
    mandatory: true,
    editable: false,
  },
  autoSuggestInput({
    mandatory: true,
    field: 'department',
    label: 'room.labels.department'.getLabel(),
    placeholder: 'room.placeholders.department'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.DEPARTMENTS,
    query: 'masterDataList',
    searching: true,
    editable: false,
  }),
  {
    type: 'number',
    field: 'charge',
    label: `${'speciality.labels.charge'.getLabel()}(INR)`,
    editable: false,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'speciality.labels.status'.getLabel(),
    editable: false,
  },
  {
    type: 'text',
    field: 'description',
    label: 'speciality.labels.description'.getLabel(),
    editable: false,
    minHeight: 38,
  },
  {
    type: 'date',
    format: 'DD MMM YYYY @ hh:mm A',
    field: '_createdOn',
    label: 'speciality.labels.createdOn'.getLabel(),
    editable: false,
  },
];

export default MasterForm({
  uri: EditWard,
  formFields,
  model: MODELS.WARDS,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <WardTabs {...props} />
    ),
  }],
});
