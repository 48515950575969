import React from 'react';

import {
  cancelButton,
  save,
  StandardButton,
  Text,
} from '../../../app-components';
import {
  AddTemplateForm,
  EditTemplateForm,
  ViewTemplateForm,
} from './actions';
import {
  DraftTemplateList,
} from './lists';
import { ROUTES } from '../../../Lib/constants';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import vars from '../../../theme/vars';

export default {
  [ROUTES.salaryCompensationList.name]: {
    screen: DraftTemplateList,
    headerActions: [{
      render: (
        <WithPermission
          access_key={permissions.mdm.views.addSalaryStructureTemplate.permissions.addSalaryStructureTemplate.value}
        >
          <StandardButton label="Add Salary Structure Template" />
        </WithPermission>
      ),
      type: 'link',
      link: {
        view: [ROUTES.addSalaryCompensationStructure.name],
        routeOptions: {
          expanded: true,
        },
      },
    }],
  },
  [ROUTES.addSalaryCompensationStructure.name]: {
    screen: AddTemplateForm,
    headerActions: [cancelButton, save()],

  },
  [ROUTES.editSalaryCompensationStructure.name]: {
    screen: EditTemplateForm,
    expanded: true,
    headerActions: [cancelButton, save({ label: 'Update' })],
  },
  [ROUTES.viewSalaryCompensationStructure.name]: {
    screen: ViewTemplateForm,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [cancelButton, {
      render: (
        <WithPermission
          access_key={
            permissions.mdm.views.modifySalaryStructureTemplate.permissions.modifySalaryStructureTemplate.value
          }
        >
          <StandardButton label="Edit" />
        </WithPermission>
      ),
      type: 'link',
      link: ({ navigation }) => ({
        view: [ROUTES.editSalaryCompensationStructure.name],
        params: { ...navigation.state.params },
        routeOptions: {
          expanded: true,
        },
      }),
    }],
  },
};
