import React from 'react';
import moment from 'moment';

import {
  TouchableOpacity,
  Image,
  View,
  Text,
  showSelectedCount,
  clearSelection,
  selectAll,
  StandardButton,
} from '../../../../../app-components';
import { editRecord } from '../../../../../images';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { unknownPatientListUri } from '../../../../../Queries/patient';
import vars from '../../../../../theme/vars';
import { Table } from '../../../../../app-components/table/Table';
import { UploadImage } from '../../../../../app-components/input-components/Editors';

const header = ({ aggregates }) => ({
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'patient.buttons.exportToExcel'.getLabel()} />
      ),
      file: 'PatientsManagementList',
      type: 'download',
      columns: [
        // {
        //   field: 'time',
        //   header: 'Date & Time',
        // },
        {
          field: 'uhid',
          header: 'Patient Id',
        },
        {
          field: 'uniqueMark',
          header: 'Unique Mark',
        },
        {
          field: 'bedNo',
          header: 'Bed No.',
        },
        {
          field: {
            field: 'belongingsDetails',
            parserFunction: 'formatArrayForLength',
          },
          header: 'Clothings/Belongings',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.PATIENTS,
          query: {
            id: 'unknownPatientDataList',

          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState: { selectedIds } } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    },
  ]),
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'patient.headers.dateAndTime'.getLabel(),
        type: 'text',
        render: ({ item } = {}) => {
          const { date } = item;
          return (
            <Text style={{ ...vars.headings.h9 }}>
              {moment(date).format('DD MMM YYYY')}
              {' @ '}
              {item?.time}
            </Text>
          );
        },
        width: 200,
      },
      {
        header: 'patient.headers.uhid'.getLabel(),
        type: 'text',
        field: 'uhid',
      },
      {
        header: 'patient.headers.image'.getLabel(),
        type: 'custom',
        render: ({ item }) => (
          <View style={{ paddingTop: 5, paddingBottom: 5 }}>
            <UploadImage
              data={item}
              field="patientImage"
              editable={false}
              nonEditable
              imageWidth={100}
              imageHeight={130}
            />
          </View>
        ),
        width: 200,
      },
      {
        header: 'patient.headers.uniqueMark'.getLabel(),
        type: 'text',
        field: 'uniqueMark',
      },
      {
        header: 'patient.headers.remarks'.getLabel(),
        type: 'text',
        field: 'remarks',
      },
      {
        header: 'patient.headers.clothingAndBelongings'.getLabel(),
        type: 'text',
        width: 150,
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.patientBelongingsList.name,
              modal: true,
              params: {
                item,
                model: MODELS.PATIENTS,
                id: 'unknownPatientDataList',
              },
              modalProps: {
                autoHide: true,
                width: 832,
                height: 522,
              },
            };
          },
        },
        render: ({ item }) => {
          const { belongingsDetails } = item;
          if (belongingsDetails && belongingsDetails.length) {
            return (
              <View>
                <Text style={{
                  ...vars.headings.h9, color: vars.colors.secondary.color2, textDecorationLine: 'underline', textAlign: 'center',
                }}
                >
                  {belongingsDetails ? belongingsDetails.length : 'N/A'}
                </Text>
              </View>
            );
          }
        },
      },
      {
        header: 'patient.headers.bedNo'.getLabel(),
        type: 'text',
        field: 'bedNo',
      },
      {
        header: 'patient.headers.action'.getLabel(),
        type: 'link',
        value: 'Admit to IPD',
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.viewUnknownPatientIPD.name,
              params: {
                _id: item?._id,
                openAdmitForm: true,
                patient: item,
              },
            };
          },
        },
      },
      {
        header: '',
        type: 'link',
        value: 'Register',
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.editPatient.name,
              push: true,
              expanded: true,
              params: { _id: item?._id },
            };
          },
        },
      },
      {
        header: '',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.unknownPatientEdit.name,
              params: { _id: item._id },
            };
          },
        },
      },
    ],
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewUnknownPatientIPD.name,
        params: { _id: item?._id, patient: item },
      },
    }),
  },
};

const UnknownPatientList = (props) => (
  <Table
    uri={unknownPatientListUri}
    {...tableItems}
    {...props}
    reloadEvent="reloadOPDRegisteredPatients"
  />
);
export default UnknownPatientList;

