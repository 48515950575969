import React from 'react';
import { FormHoc, Text, View } from '../../../../../../app-components';
import { autoSuggestInput } from '../../../../../../autoSuggestions';
import FileLink from '../../../../../../Components/FileLink';
import { MODELS } from '../../../../../../Lib/constants';
import { getString } from '../../../../../../Lib/helpers';
import { viewDonorUri } from '../../../../../../Queries/bloodBank';
import vars from '../../../../../../theme/vars';
import ViewDonorTabs from './components/tabs';

const ViewDonor = FormHoc({
  uri: viewDonorUri,
  editable: false,
  reloadOnChangeEvent: `reload${MODELS.BLOOD_DONOR}`,
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'donorId',
                placeholder: 'donorAndDonations.placeholders.donorId'.getLabel(),
                label: 'donorAndDonations.labels.donorId'.getLabel(),
              },
              {
                type: 'text',
                field: 'name',
                placeholder: 'donorAndDonations.placeholders.donorName'.getLabel(),
                label: 'donorAndDonations.labels.donorName'.getLabel(),
              },
              {
                type: 'text',
                label: 'patient.labels.relation'.getLabel(),
                render: ({ data }) => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {getString([data?.relation, data?.relationName])}
                  </Text>
                ),
                editable: false,
              },
              {
                type: 'number',
                render: ({ data }) => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {`${data?.age || ''} ${data?.ageType || ''}`}
                  </Text>
                ),
                placeholder: 'donorAndDonations.placeholders.age'.getLabel(),
                label: 'donorAndDonations.labels.age'.getLabel(),
              },
              autoSuggestInput({
                label: 'donorAndDonations.labels.gender'.getLabel(),
                field: 'gender',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
              }),
              autoSuggestInput({
                label: 'donorAndDonations.labels.bloodGroup'.getLabel(),
                field: 'bloodGroup',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
              }),
              {
                type: 'number',
                field: 'contactNo',
                label: 'donorAndDonations.labels.mobile'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.mobile'.getLabel(),
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 10) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'text',
                field: 'occupation',
                placeholder: 'donorAndDonations.placeholders.occupation'.getLabel(),
                label: 'donorAndDonations.labels.occupation'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 2,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'address',
                label: 'patient.labels.address'.getLabel(),
                placeholder: 'patient.placeholders.address'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 3,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'description',
                label: 'donorAndDonations.labels.additionalInfo'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.additionalInfo'.getLabel(),
              },
              {
                type: 'file',
                field: 'attachment',
                render: (item) => {
                  const file = item?.data?.attachment || {};
                  return <FileLink file={file} />;
                },
                label: 'donorAndDonations.labels.file'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.file'.getLabel(),
              },
            ],
          },
        ],
      },
    ],
  },
});

export default (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <ViewDonor {...props} />
    </View>
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <ViewDonorTabs {...props} />
    </View>
  </View>
);
