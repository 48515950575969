import React from 'react';
import {
  Image,
  TouchableOpacity,
} from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import {
  packageLayouts,
} from './layouts';
import { getResource } from './uri';
import {
  editRecord,
} from '../../../../images';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';

export const EmployeePackage = (props) => {
  const { canAddSalaryStructure, addOnFilter } = props;

  return (
    <Table
      {...props}
      uri={getResource(
        {
          model: MODELS.EMPLOYEE_SALARY_COMPONENT,
          addOnFilter,
          query: 'employeeSalaryComponents',
        },
        { id: '_find' },
      )}
      action={() => ({
        type: 'link',
        link: ({ item: data }) => ({
          view: ROUTES.viewEmployeeSalaryCompensationStructure.name,
          params: {
            item: data,
          },
          routeOptions: {
            expanded: true,
          },
        }),
      })}
      listVersion={2}
      reloadEvent="UserCompensationList"
      columns={[
        { width: 14 },
        packageLayouts.FromDate,
        packageLayouts.ToDate,
        packageLayouts.level,
        packageLayouts.packageReviewDate,
        packageLayouts.CTCAmt,
        {
          header: 'Actions',
          type: 'text',
          align: 'center',
          render: () => (
            <WithPermission
              access_key={
                permissions.departments.views.addEditCompensationStructure
                  .permissions.addEditCompensationStructure.value
              }
            >
              <TouchableOpacity
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                <Image
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  source={editRecord}
                />
              </TouchableOpacity>
            </WithPermission>
          ),
          visible: canAddSalaryStructure,
          action: ({ item: data }) => ({
            type: 'link',
            link: {
              view: ROUTES.editEmployeeSalaryCompensationStructure.name,
              params: { item: data },
            },
          }),
        },
      ]}
    />
  );
};

