import React from 'react';
import ReactDOMServer from 'react-dom/server';
import JsBarcode from 'jsbarcode';
import { printPdf } from '../../Lib/pdfUtil';
import LabInvoice from './invoice';

// eslint-disable-next-line no-unused-vars
const printLabBill = ({ data, submitResult }, cb) => {
  data.invoiceNumber = submitResult.result.invoiceNumber;

  const tag = document.getElementById('printable-area');
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, data?.patientId?.uhid, {
    format: 'CODE128', height: 140, fontSize: 28, textMargin: 1, margin: 0,
  });
  const image = canvas.toDataURL('image/png');
  const test = ReactDOMServer.renderToString(<LabInvoice barcode={image} labInfo={data} />);
  tag.innerHTML = test;
  printPdf({
    tag,
    cb,
    format: [420, 650],
    orientation: 'p',
    unit: 'px',
  });
};
export default printLabBill;
