import React, { useState } from 'react';

import {
  FormField,
  StandardButton,
  View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import ListActionModal from '../../../../../Components/ListActionModal';
import { autoSuggestInput, autoSuggestOptionsInput } from '../../../../../autoSuggestions';
import vars from '../../../../../theme/vars';
import {
  inventoryType,
  MODELS,
  RolesIndex,
  RolesOrder,
} from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';

const SendToReviewModal = (props) => {
  const { navigation: { state: { params } } = {} } = props;
  const [isReviewDaysLimit, setIsReviewDaysLimit] = useState('');

  return (
    <ListActionModal
      title={'purchase.title.sendForReview'.getLabel()}
      description={
          `This action will send the ${
              params?.item?.type === 'PO' ? 'Purchase Sanction' : 'Supply Order'
          } for review.`
      }
      content={(
        <Form
          closeView={2}
          Purchase
          Order
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          // eslint-disable-next-line no-shadow
          onSubmit={(props) => {
            const { data, updates } = props;
            setIsReviewDaysLimit(updates?.approvalDaysLimit);
            data.purchaseRequestEntity = params?.item?.purchaseRequestEntity;
            data.purchaseSupplyOrder = { _id: params?.item?._id };

            updates.purchaseRequestEntity = params?.item?.purchaseRequestEntity;
            updates.purchaseSupplyOrder = { _id: params?.item?._id };
            delete data._id;
            delete updates._id;
            if (params?.item?.isContractQueueCreated && params?.item?.type === 'PO' && params?.item?.inventoryType === inventoryType.asset) {
              data.isContractQueueCreated = true;
              updates.isContractQueueCreated = true;
            }

            if (updates?.assignedToRole) {
              data.assignedToRole = updates.assignedToRole;
              data.assignedToRoleKey = RolesIndex[updates.assignedToRole];
              // updates.assignedToRole = updates.assignedToRole;
              updates.assignedToRoleKey = RolesIndex[updates.assignedToRole];
            }

            if (updates?.reviewByDepartment) {
              data.assignedToRole = 'HOD';
              data.assignedToRoleKey = 7;
              updates.assignedToRole = 'HOD';
              updates.assignedToRoleKey = 7;
            }
            const submitAPI = submit({
              model: MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE,
              ...props,
              data,
            });
            const result = submitAPI({ data, updates });
            return result;
          }}
          reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
          defaultValues={() => ({
            approvalDaysLimit: isReviewDaysLimit,
            assignedToRole: 'PSI',
            assignedToRoleKey: 1,
            status: params?.item?.currentApprovalQueue?.status,
            _id: params?.item?.currentApprovalQueue?._id,
            isReview: true,
          })}
          mandatory={{
            remarks: 1,
            assignedToRole: 1,
            approvalDaysLimit: 1,
          }}
          submitMessage="Request had been sent for review successfully."
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1, marginRight: 20 }}>
                  {/* <FormField
                    type="text"
                    label={'purchase.labels.sendTo'.getLabel()}
                    variant="filled"
                    // disabled
                    // value="PSI"
                    mandatory
                  /> */}
                  <FormField
                    {...autoSuggestOptionsInput({
                      label: 'purchase.labels.sendTo'.getLabel(),
                      variant: 'filled',
                      keyFiled: 'label',
                      field: 'assignedToRole',
                      suggestionField: 'label',
                      valueField: 'label',
                      options: RolesOrder,
                      mandatory: true,
                    })}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="number"
                    label={'purchase.labels.noOfDays'.getLabel()}
                    variant="filled"
                    editable
                    field="approvalDaysLimit"
                    mandatory
                  />
                </View>
              </View>

              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1, marginRight: 25 }}>
                  <FormField
                    {... autoSuggestInput({
                      field: 'reviewByDepartment',
                      label: 'Department',
                      placeholder: 'Select department',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      variant: 'filled',
                    },
                    {
                      addOnFilter: ({ data: { reviewByDepartment = [] } }) => {
                        const departmentId = reviewByDepartment?._id;
                        if (departmentId) {
                          return {
                            _id: { $ne: departmentId },
                          };
                        }
                        return {};
                      },
                    })}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="textArea"
                    label={'purchase.labels.remark'.getLabel()}
                    variant="filled"
                    field="remarks"
                    mandatory
                    allowWhiteSpaces
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'purchase.button.confirm'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default SendToReviewModal;
