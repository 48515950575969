export const EditWard = ({ navigation }) => ({
  query: {
    model: 'specialities',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'specialities',
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'specialities',
});

export const wardListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataList',
    addOnFilter: filter,
  },
  model: 'specialities',
});
