import React from 'react';

import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';

import { Table, Image } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';
import { editRecord } from '../../../../images';
import { houseListURI } from '../uri';

const House = Table({
  listVersion: 2,
  header: {
    title: ({ selectionCount }) => (
      <HeaderTitleWithCount
        count={selectionCount}
        label="House Management"
      />
    ),
  },
  uri: () => houseListURI({ query: 'masterDataList' }, {}),
  reloadEvent: 'masterDataList',
  action: () => ({
    type: 'link',
    access_key: permissions.mdm.views.manageHouseView.permissions.manageHouseView.value,
    link: ({ item }) => ({
      view: ROUTES.viewHouseDetails.name,
      params: { item },
    }),
  }),
  lg: {
    columns: [
      {
        width: 10,
      },
      {
        header: 'H. No.',
        type: 'text',
        field: 'name',
        width: '10%',
      },
      {
        header: 'Rent Amount',
        type: 'number',
        field: 'rentAmount',
        width: '15%',
      },
      {
        header: 'Water Charges',
        type: 'number',
        field: 'waterCharge',
        width: '15%',
      },
      {
        header: 'Description',
        type: 'text',
        field: 'description',
        width: '15%',
      },
      {
        header: 'No. Of Rooms',
        type: 'number',
        field: 'noOfRooms',
        width: '15%',
      },
      {
        label: 'Status',
        type: 'text',
        field: 'status',
        width: '15%',
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <WithPermission
                access_key={permissions.mdm.views.manageHouseView.permissions.manageHouseView.value}
              >
                <Image
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  source={editRecord}
                />
              </WithPermission>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.editHouseDetails.name,
              params: { item },
            }),

          },
        ],
      },
    ],
  },
});

export default House;
