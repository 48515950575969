export default {
  labels: {
    buyerName: 'Buyer name',
    address: 'Address',
    email: 'Email',
    contactNumber: 'Contact No.',
    action: 'Action',
  },
  placeholders: {
    buyerName: 'Buyer name',
    address: 'Address',
    email: 'Email',
    contactNumber: 'Contact No.',
  },
  buttons: {
    addBuyer: 'Add Buyer',
    editBuyer: 'Edit Buyer',
  },
  messages: {
    buyerAlreadyExists: 'Buyer already exists.',
    buyerAddedSuccessfully: 'Buyer has been added successfully.',
    buyerUpdatedSuccessfully: 'Buyer has been updated successfully.',
  },
  title: {
    buyerManagement: 'Buyer Management',
    exportToExcel: 'Export To Excel',
  },
};
