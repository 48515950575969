import React from 'react';
import { DateFilter, FilterGroup } from '../../../../app-components';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';

import { getUser } from '../../../../AppServices';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../../images';
import {
  MODELS, supplyOrderStatus, purchaseOrderStatus, RolesIndex, inventoryTypeFilter, urgentFilter,
} from '../../../../Lib/constants';
import { countUri } from '../../../../Queries/countUri';
import { tabTheme } from '../PurchaseRequest/tabTheme';
import PurchaseOrderRequestList from './PurchaseOrderRequest/purchaseOrderRequestList';
import SupplyOrderRequestList from './SupplyOrderRequest/supplyOrderRequestList';
import { isSuperadmin, getRoleIndexes, isCreateSoPermissionGiven } from '../../../../Lib/helpers';

const PurchaseAndSupplyOrderTabs = (props) => {
  const { navigation } = props;
  const userRole = getUser();
  let POFilter = {};
  let SOFilter = {};
  if (isSuperadmin(userRole) || isCreateSoPermissionGiven(userRole)) {
    POFilter = { isPOCreated: true };
    SOFilter = { isSOCreated: true, isPOCreated: false };
  } else {
    POFilter = {
      isPOCreated: true,
      $or: [
        {
          allAssignees: {
            $in: getRoleIndexes(RolesIndex, userRole, 'PS'),
          },
        },
        {
          assignToDepartmentForSendForReview: {
            _id: { $in: userRole?.employee?.department.map((dep) => dep?._id) },
          },
        },
      ],
    };
    SOFilter = {
      isSOCreated: true,
      isPOCreated: false,
      $or: [
        {
          allAssignees: {
            $in: getRoleIndexes(RolesIndex, userRole, 'SO'),
          },
        },
        {
          assignToDepartmentForSendForReview: {
            _id: { $in: userRole.employee.department.map((dep) => dep?._id) },
          },
        },
      ],
    };
  }

  const data = navigation?.state?.params?.item;
  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      tabs={{
        PurchaseOrder: {
          label: 'Purchase Sanction Requests',
          screen: PurchaseOrderRequestList,
          actions: [
            <FtsSearch
              action={{
                exp: ['date', 'uniqueNumber'],
              }}
              key="fts"
            />,
            <DateFilter field="POCreatedOn" key="POCreatedOn" />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: purchaseOrderStatus,
                  field: 'status',
                  label: 'Status',
                  multiSelect: true,
                },
                {
                  type: 'autoSuggestOptions',
                  options: inventoryTypeFilter,
                  field: 'inventoryType',
                  label: 'Department',
                  multiSelect: true,
                },
                {
                  type: 'autoSuggestOptions',
                  options: urgentFilter,
                  field: 'urgent',
                  label: 'Urgent',
                  multiSelect: false,
                },
              ],
            }),
          ],
          screenProps: {
            data,
            model: MODELS.PURCHASE_SUPPLY_ORDER,
            id: 'purchaseSupplyOrderList',
            addOnFilter: { isPOCreated: true },
          },
          countUri: countUri({
            model: MODELS.PURCHASE_SUPPLY_ORDER,
            id: 'purchaseSupplyOrderList',
            filter: POFilter,
          }),
        },
        SupplyOrder: {
          label: 'Supply Order Requests',
          screen: SupplyOrderRequestList,
          actions: [
            <FtsSearch
              action={{
                exp: ['date', 'uniqueNumber'],
              }}
              key="fts"
            />,
            <DateFilter field="SOCreatedOn" key="SOCreatedOn" />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: supplyOrderStatus,
                  field: 'status',
                  label: 'Status',
                  multiSelect: true,
                },
                {
                  type: 'autoSuggestOptions',
                  options: inventoryTypeFilter,
                  field: 'inventoryType',
                  label: 'Department',
                  multiSelect: true,
                },
                {
                  type: 'autoSuggestOptions',
                  options: urgentFilter,
                  field: 'urgent',
                  label: 'Urgent',
                  multiSelect: false,
                },
              ],
            }),
          ],
          screenProps: {
            data,
            model: MODELS.PURCHASE_SUPPLY_ORDER,
            id: 'purchaseSupplyOrderList',
            addOnFilter: { isSOCreated: true, isPOCreated: false },
          },
          countUri: countUri({
            model: MODELS.PURCHASE_SUPPLY_ORDER,
            id: 'purchaseSupplyOrderList',
            filter: SOFilter,
          }),
        },
      }}
      {...props}
    />
  );
};
export default PurchaseAndSupplyOrderTabs;
