/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';

import {
  FormField,
  StandardButton,
  View,
  Toast,
  Text,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import ListActionModal from '../../../../Components/ListActionModal';
import { detailIcon } from '../../../../images';
import { invoke } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { getErrorString } from '../../../../Lib/helpers';
import { MODELS, ROUTES } from '../../../../Lib/constants';

const SingleDoseVaccineDetail = (props) => {
  const { navigation, eventDispatcher } = props;
  const { navigation: { state: { params } = {} } = {} } = props;

  const updatePatientVaccinationHistory = useCallback(async (data) => {
    try {
      data.patientVaccinationHistory = params;
      eventDispatcher.notify(`reload${MODELS.PATIENT_VACCINATION_HISTORY}`);
      await invoke({
        id: 'updatePatientVaccinationHistory',
        paramValue: data,
      });
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [eventDispatcher, params]);

  return (
    <ListActionModal
      icon={detailIcon}
      description="Details and status of vaccine"
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async ({ data }) => {
            await updatePatientVaccinationHistory(data);
            const paramValue = params;
            paramValue.vaccineDoseDetail = data?.VaccineDetail?.map((item) => {
              if (item?.doseProvidedDate) {
                return {
                  ...item,
                  doseStatus: 'Provided',
                };
              }
              return {
                ...item,
              };
            });
            navigation.push({
              view: ROUTES.patientVaccineDetailConfirmationModal.name,
              params: paramValue,
            });
          }}
          reloadEvent={`reload${MODELS.PATIENT_VACCINATION_HISTORY}`}
          submitMessage={'vaccination.messages.vaccineSingleDose'.getLabel()}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ width: 228 }}>
                <FormField
                  type="text"
                  label={'vaccination.labels.status'.getLabel()}
                  variant="filled"
                  editable={false}
                  render={() => {
                    const { item, vaccineDoseTaken = 0 } = params;
                    let status = '';
                    let color = '';
                    if (item?.noOfDoses === vaccineDoseTaken) {
                      status = 'Vaccinated';
                      color = vars.colors.primary.color2;
                    } else {
                      color = vars.colors.warning;
                      status = 'Pending';
                    }
                    return (
                      <Text style={{ ...vars.headings.h8, color }}>
                        {status}
                      </Text>
                    );
                  }}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <Text style={{ ...vars.headings.h9 }}>
                  VACCINE INFORMATION
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 15 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.vaccineName'.getLabel()}
                    variant="filled"
                    value={params.item?.name}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.reVaccinationGap'.getLabel()}
                    variant="filled"
                    value="--"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.noOfDose'.getLabel()}
                    variant="filled"
                    value="1"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.description'.getLabel()}
                    variant="filled"
                    value={params?.item?.description}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.lastVaccinationDate'.getLabel()}
                    variant="filled"
                    value="--"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.paymentType'.getLabel()}
                    variant="filled"
                    render={() => {
                      const { item } = params;
                      if (item?.isVaccineFree) return (<Text>Free</Text>);
                      return (<Text>Paid</Text>);
                    }}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'vaccination.labels.paymentStatus'.getLabel()}
                    variant="filled"
                    value="Paid"
                  />
                </View>
                <View style={{ flex: 1 }} />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label="Vaccinate"
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default SingleDoseVaccineDetail;
