import React from 'react';
import v4 from 'uuid/v4';

import { Form } from '../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../Lib/constants';
import { save } from '../../../../../../app-components';
import { submit } from '../../../../../../AppServices';
import ListActionModal from '../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../autoSuggestions';

const ConfirmationModal = (props) => (
  <ListActionModal
    {...props}
    title="Approval Window"
    description="Do you want to Discard this Request?"
    content={(
      <Form
        {...props}
        closeView={1}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Confirm',
            }),
          ],
        }}
        reloadEvent="billingDataList"
        mandatory={{
          discardedReason: 1,
        }}
        uri={() => ({
          query: {
            id: 'billingDataList',
            addOnFilter: {
              _id: props?.navigation?.state?.params?.item?._id,
            },
          },
          model: MODELS.LAB_BILLING,
        })}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const tempStatus = data.status ? updates.status = 'Pending from MS' : false;
          //   const tempBillStats = data.billStatus ? updates.billStatus = false : false;
          const newUpdate = {
            ...updates,
            // billStatus: false,
            status: 'Pending from MS',
          };
          const submitAPI = submit({
            model: MODELS.LAB_BILLING,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        submitMessage="Bill Discarded successfully."
        type="standardShadow"
        lg={{
          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    autoSuggestInput({
                      label: 'Reason',
                      field: 'discardedReason',
                      suggestionField: 'label',
                      options: ['Self Mistake', 'Patient Mistake', 'Other'],
                      editable: true,
                    }),
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      type: 'text',
                      label: 'Reason',
                      field: 'discardedReasonOther',
                      suggestionField: 'label',
                      editable: true,
                      visible: ({ data }) => data?.discardedReason === 'Other',
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
      )}
  />
);

export default ConfirmationModal;
