import React from 'react';
import {
  Image, TouchableOpacity, View, Text, WithModalButton,
} from '../../app-components';
import NotificationList from './NotificationList';

import { favorite, backCrossIcon } from '../../images';
import withNotificationContext from '../../AppNotification';
import vars from '../../theme/vars';

class Notification extends React.Component {
  headerComponent = ({ hideModal }) => (
    <TouchableOpacity
      onPress={() => {
        hideModal();
      }}
    >
      <View
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          paddingBottom: 16,
          borderBottomColor: 'rgba(217, 217, 224, 0.3)',
          borderBottomWidth: 1,
          backgroundColor: '#EFF3FB',
        }}
      >
        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
          <Text style={{
            color: vars.colors.secondary.color3, ...vars.headings.h9, flex: 1, marginLeft: 5,
          }}
          >
            Notifications
          </Text>
          <View
            style={{
              height: 16,
              width: 16,
              cursor: 'pointer',
              paddingLeft: 10,
              paddingRight: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image source={backCrossIcon} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );

  listComponent = ({ hideModal }) => {
    const {
      store: { notificationData },
    } = this.props;

    if (notificationData && !notificationData.length) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ ...vars.headings.h9, color: vars.colors.error }}>
            No notification available
          </Text>
        </View>
      );
    }

    return (
      <TouchableOpacity
        style={{ flex: 1, overflow: 'auto' }}
        onPress={() => {
          hideModal();
        }}
      >
        <NotificationList
          {...this.props}
          hideModal={hideModal}
          data={notificationData}
        />
      </TouchableOpacity>
    );
  };

  renderModal = ({ frameStyle, hideModal }) => {
    const { left } = frameStyle;
    return (
      <TouchableOpacity
        style={{
          ...frameStyle,
          left,
          right: 0,
          top: 0,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: vars.colors.white,
            borderRadius: 4,
            flex: 1,
            overflow: 'hidden',
            boxShadow: '0 0 10px 0 rgba(53, 53, 53, 0.08)',
            border: 'solid 0.2px #c3c3c3',
          }}
          onPress={() => {
            hideModal();
          }}
        >
          <View
            style={{
              borderRadius: 4,
              flex: 1,
              overflow: 'hidden',
              backgroundColor: vars.colors.white,
            }}
          >
            {this.headerComponent({ hideModal })}
            {this.listComponent({ hideModal })}
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      store: { notificationCount = 0, markRead },
    } = this.props;

    return (
      <WithModalButton
        renderModal={this.renderModal}
        autoHide
        backdropStyle={{
          backgroundColor: 'rgba(0, 0, 0, .30)',
          overflow: 'hidden',
          top: 0,
        }}
        dropdownStyle={{ width: 420, height: '100%', overflow: 'hidden' }}
      >
        <View
          onClick={markRead}
          style={{
            width: 100,
            height: 50,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {notificationCount ? (
            <View
              style={{
                top: 0,
                right: 0,
                bottom: 40,
                left: 20,
                minHeight: 20,
                minWidth: 20,
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: vars.colors.secondary.color2,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h14,
                  color: 'white',
                }}
              >
                {notificationCount}
              </Text>
            </View>
          ) : <View />}
          <View
            style={{
              height: 20,
              width: 22,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              style={{ width: 25, height: 22 }}
              source={notificationCount ? favorite : favorite}
            />
          </View>
        </View>
      </WithModalButton>
    );
  }
}

const WrappedNotificationChat = withNotificationContext(Notification);
export default WrappedNotificationChat;
