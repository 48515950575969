import { get } from 'lodash';
import login from './login';
import zipCode from './zipCode';
import masters from './masters';
import department from './department';
import room from './room';
import employeeType from './employeeType';
import role from './role';
import resetPassword from './resetPassword';
import speciality from './speciality';
import bulkUploadModal from './bulkUploadModal';
import user from './user';
import employee from './employee';
import patient from './patient';
import consultation from './consultation';
import patientToken from './patientToken';
import incomingPatient from './incomingPatients';
import tokenGeneration from './tokenGeneration';
import doctor from './doctor';
import shift from './shift';
import holidayCalendar from './holidayCalendar';
import holiday from './holiday';
import profile from './profile';
import billing from './billing';
import lab from './lab';
import employeeSchedule from './employeeSchedule';
import pharmacy from './pharmacy';
import inventory from './inventory';
import updateEmployeeSchedule from './updateEmployeeSchedule';
import attendance from './attendance';
import salaryComponents from './salaryComponents';
import organisationTaxDetails from './organisationTaxDetails';
import leaveType from './leaveType';
import companyManagement from './companyManagement';
import donorAndDonations from './donorAndDonations';
import bloodInventory from './bloodInventory';
import bloodBankRecords from './bloodBankRecords';
import bloodBankUpdationRequest from './bloodBankUpdationRequest';
import testComponent from './testComponent';
import salaryCompensationTemplate from './salaryCompensationTemplate';
import account from './account';
import asset from './asset';
import purchaseDepartmentTC from './purchaseDepartmentTC';
import purchase from './purchase';
import vaccination from './vaccination';
import buyer from './buyer';
import auction from './auction';
import roomCategory from './roomCategory';
import disease from './disease';

const labels = {
  login,
  zipCode,
  masters,
  department,
  room,
  employeeType,
  role,
  resetPassword,
  speciality,
  bulkUploadModal,
  user,
  employee,
  patient,
  patientToken,
  consultation,
  incomingPatient,
  tokenGeneration,
  doctor,
  shift,
  holidayCalendar,
  holiday,
  profile,
  billing,
  lab,
  employeeSchedule,
  pharmacy,
  inventory,
  updateEmployeeSchedule,
  attendance,
  salaryComponents,
  organisationTaxDetails,
  leaveType,
  companyManagement,
  donorAndDonations,
  bloodInventory,
  bloodBankRecords,
  bloodBankUpdationRequest,
  salaryCompensationTemplate,
  testComponent,
  account,
  asset,
  purchaseDepartmentTC,
  purchase,
  vaccination,
  buyer,
  auction,
  roomCategory,
  disease,
};

// eslint-disable-next-line func-names
// eslint-disable-next-line no-extend-native
String.prototype.getLabel = function () {
  return get(labels, this, this);
};
