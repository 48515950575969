/* eslint-disable max-len */
/* eslint-disable camelcase */
import { cloneDeep, uniq, uniqueId } from 'lodash';
import {
  submit,
} from '../../../AppServices';
import { CompensationFormLayouts } from './commonLayouts';
import { CommonSalaryTemplate } from './uri';
import { EmployeeCompensationHoc } from './commonHoc';
import { salaryHeartIcon } from '../../../images';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength } from '../../../Lib/helpers';

const calculateDependantComponentsAmount = (components, allComponents, returnPercentage) => {
  let amount = 0;
  for (let c = 0; c < components?.dependentComponents?.length; c += 1) {
    if (components.dependentComponents[c]?.dependentComponents) {
      const cAmount = calculateDependantComponentsAmount(
        components.dependentComponents[c],
        allComponents,
        true,
      );
      amount += cAmount;
    } else {
      const component = allComponents?.find(
        (com) => com?.salary_component_id?._id?.toString()
          === components?.dependentComponents[c]?._id?.toString(),
      );

      if (component) {
        amount += component.amount;
      }
    }
  }

  if (returnPercentage) {
    return ((amount / 100)
    * (components?.dependentPercentage || 0));
  }
  return Math.round(amount);
};

const handleNPAAmount = (components) => {
  const bp = components.find(((com) => com?.salary_component_id?.code === 'BP'));
  const npa = components.find(((com) => com?.salary_component_id?.code === 'NPA'));
  if (!bp || !bp.amount) return 0;

  if (bp.amount <= 187125) return Math.round((bp.amount / 100) * npa?.dependentPercentage || 0) || 0;

  if (bp.amount > 187125 && bp.amount < 224550) return Math.round(224550 - bp.amount);

  return 0;
};

const handleEPFAmount = (components, employee = false) => {
  const bp = components.find(((com) => com?.salary_component_id?.code === 'BP'));
  const da = components.find(((com) => com?.salary_component_id?.code === 'DA'));
  const epfEmployer = components.find(((com) => com?.salary_component_id?.code === 'EPF'));
  const epfEmployee = components.find(((com) => com?.salary_component_id?.code === 'EPF Deductable'));

  const epf = employee ? epfEmployee : epfEmployer;

  const daAndBp = da?.amount || 0 + bp?.amount || 0;

  if (!daAndBp) return 0;

  if (daAndBp > 15000) return 1800;

  return Math.round((daAndBp / 100) * (epf?.dependentPercentage || 0));
};

export const DrillAction = ({
  item,
  type,
  view,
  params = '',
  routeOptions = {},
}) => ({
  type,
  link: {
    view,
    params: params === '' ? { item } : { [params]: item },
    routeOptions,
  },
});

const calculateComponentsAmount = (
  primaryComponents, calculatedComponents = {}, componentNames, attendance_base_components = [],
) => {
  const components = [...primaryComponents, ...attendance_base_components];
  for (let i = 0; i < components?.length; i += 1) {
    if (components[i]?.salary_component_id?.dependentComponents) {
      let isAllDependentCalculated = true;
      let amount = 0;
      for (let j = 0; j < components[i]?.salary_component_id?.dependentComponents.length; j += 1) {
        const componentName = components[i]?.salary_component_id?.dependentComponents[j].name;
        if (componentNames.indexOf(componentName) >= 0
        && Object.keys(calculatedComponents).indexOf(componentName) < 0) {
          isAllDependentCalculated = false;
          break;
        }
        amount += calculatedComponents[componentName] || 0;
      }
      if (isAllDependentCalculated) {
        if (primaryComponents[i]) {
          primaryComponents[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          components[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          if (components[i]?.salary_component_id?.code === 'NPA') {
            primaryComponents[i].amount = handleNPAAmount(components);
            components[i].amount = handleNPAAmount(components);
          }
          if (components[i]?.salary_component_id?.code === 'EPF Deductable') {
            primaryComponents[i].amount = handleEPFAmount(components, true);
            components[i].amount = handleEPFAmount(components, true);
          }
          if (components[i]?.salary_component_id?.code === 'EPF') {
            primaryComponents[i].amount = handleEPFAmount(components);
            components[i].amount = handleEPFAmount(components);
          }
        } else {
          components[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          if (components[i]?.salary_component_id?.code === 'NPA') {
            components[i].amount = handleNPAAmount(components);
          }
          if (components[i]?.salary_component_id?.code === 'EPF Deductable') {
            components[i].amount = handleEPFAmount(components, true);
          }
          if (components[i]?.salary_component_id?.code === 'EPF') {
            components[i].amount = handleEPFAmount(components);
          }
        }
        calculatedComponents[components[i]?.salary_component_id?.name] = components[i].amount;
      }
    } else {
      calculatedComponents[components[i]?.salary_component_id?.name] = components[i].amount;
    }
  }
  if (!primaryComponents?.length || Object.keys(calculatedComponents).length === componentNames.length) {
    return primaryComponents;
  }
  return calculateComponentsAmount(
    primaryComponents, calculatedComponents, componentNames, attendance_base_components,
  );
};

export const AddTemplateForm = EmployeeCompensationHoc({
  closeView: 1,
  onSubmit: submit({
    model: MODELS.EMPLOYEE_SALARY_COMPONENT,
  }),
  submitMessage: 'salaryCompensationTemplate.messages.salaryStructureSavedSuccessfully'.getLabel(),
  defaultValues: () => {
    const Defaults = {
      is_master_component: true,
      isActive: true,
      createdOn: new Date(),
    };
    return Defaults;
  },
  validations: {
    name: ({ data }) => validateInputLength(data?.name, 2, 26),
    code: ({ data }) => validateInputLength(data?.code, 2, 20),
  },
  mandatory: {
    code: 1,
    employeeType: 1,
    staffType: 1,
  },
  computations: {
    self: {
      ctc_amount: {
        compute: ({ attendance_base_components, deductions_components }) => {
          const earningComponentSum = attendance_base_components?.reduce((acc, { amount }) => acc + amount, 0) || 0;
          const deductionComponentSum = deductions_components?.reduce((acc, { amount }) => acc + amount, 0) || 0;
          return { set: { ctc_amount: earningComponentSum - deductionComponentSum } };
        },
        onChange: ['attendance_base_components',
          'performance_base_components',
          'deductions_components',
          'fix_base_components',
          'attendance_base_components.amount',
          'performance_base_components.amount',
          'performance_base_components.link_to_project',
          'deductions_components.amount',
          'deductions_components.salary_component_id. _id',
          'fix_base_components.amount',
          '_set_ctc'],
      },
      'set attendance component amount': {
        compute: (data) => {
          const { attendance_base_components } = data;

          // const clonedAttendanceComponent = [...attendance_base_components];
          // for (let i = 0; i < clonedAttendanceComponent?.length; i += 1) {
          //   if (clonedAttendanceComponent[i]?.salary_component_id?.dependentComponents) {
          //     const amount = calculateDependantComponentsAmount(
          //       {
          //         ...clonedAttendanceComponent[i],
          //         dependentComponents: clonedAttendanceComponent[i]
          //           .salary_component_id?.dependentComponents,
          //         dependentPercentage: clonedAttendanceComponent[i]
          //           .salary_component_id?.dependentPercentage,
          //       }, clonedAttendanceComponent,
          //     );
          //     clonedAttendanceComponent[i].amount = ((amount / 100)
          //     * (clonedAttendanceComponent[i]?.salary_component_id?.dependentPercentage || 0));
          //     if (clonedAttendanceComponent[i]?.salary_component_id?.code === 'NPA') {
          //       clonedAttendanceComponent[i].amount = handleNPAAmount(clonedAttendanceComponent);
          //     }
          //     clonedAttendanceComponent[i].amount = Math.round(clonedAttendanceComponent[i].amount);
          //     clonedAttendanceComponent[i]._id = `new_${uniqueId()}`;
          //   }
          // }
          return {
            set: {
              attendance_base_components: calculateComponentsAmount(
                cloneDeep(attendance_base_components).filter((com) => com.salary_component_id),
                {},
                uniq(attendance_base_components.filter((com) => com.salary_component_id)?.map((com) => com?.salary_component_id.name)),
              ),
            },
          };
        },
        onChange: ['attendance_base_components.amount'],
      },
      'set deduction component amount': {
        compute: (data) => {
          const { deductions_components = [], attendance_base_components } = data;

          // const clonedAttendanceComponent = [...deductions_components];
          // for (let i = 0; i < clonedAttendanceComponent?.length; i += 1) {
          //   if (clonedAttendanceComponent[i]?.salary_component_id?.dependentComponents
          //     || clonedAttendanceComponent[i]?.dependentComponents) {
          //     const amount = calculateDependantComponentsAmount(
          //       {
          //         ...clonedAttendanceComponent[i],
          //         dependentComponents: clonedAttendanceComponent[i]
          //           .salary_component_id?.dependentComponents,
          //         dependentPercentage: clonedAttendanceComponent[i]
          //           .salary_component_id?.dependentPercentage,
          //       }, [...(clonedAttendanceComponent || []), ...(attendance_base_components || [])],
          //     );
          //     clonedAttendanceComponent[i].amount = ((amount / 100)
          //     * (clonedAttendanceComponent[i]?.salary_component_id?.dependentPercentage || 0));
          //     if (clonedAttendanceComponent[i]?.salary_component_id?.code === 'EPF') {
          //       clonedAttendanceComponent[i].amount = handleEPFAmount(attendance_base_components);
          //     }
          //     clonedAttendanceComponent[i].amount = Math.round(clonedAttendanceComponent[i].amount);
          //     clonedAttendanceComponent[i]._id = `new_${uniqueId()}`;
          //   }
          // }
          return {
            set: {
              deductions_components: calculateComponentsAmount(
                cloneDeep(deductions_components).filter((com) => com.salary_component_id),
                {},
                uniq([...deductions_components, ...attendance_base_components].filter(
                  (com) => com.salary_component_id,
                )?.map((com) => com?.salary_component_id.name)),
                attendance_base_components.filter(
                  (com) => com.salary_component_id,
                ),
              ),
            },
          };
        },
        onChange: ['deductions_components.amount', 'attendance_base_components.amount'],
      },
      fixed_salary: {
        compute: (data) => {
          const response = {};
          response.fixed_salary = ((data
            && data.attendance_base_components
            && data.attendance_base_components.reduce(
              (accum, doc) => accum + doc.amount,
              0,
            ))
            || 0)
            + ((data.deductions_components
              && data.deductions_components.reduce((accum, doc) => {
                let total = 0;
                // Esi employer,gratuity, pf employer ,health insurance
                if (
                  doc
                  && doc.salary_component_id
                  && (doc.salary_component_id._id.toString()
                    === '53ae739a79df44bac9b0b9f1'
                    || doc.salary_component_id._id.toString()
                    === '53ae73a779df44bac9b0ba0a'
                    || doc.salary_component_id._id.toString()
                    === '53ae738f79df44bac9b0b9da'
                    || doc.salary_component_id._id.toString()
                    === '53ae739d79df44bac9b0b9f7')
                ) {
                  total = doc.amount || 0;
                }
                return accum + total;
              }, 0))
              || 0);
          return { set: { ...response } };
        },
        onChange: [
          '_id',
          'attendance_base_components',
          'attendance_base_components.amount',
          'deductions_components',
          'deductions_components.amount',
          'deductions_components.salary_component_id._id',
        ],
      },
    },
    children: {
      attendance_base_components: {
        self: {
          'set amount on select dependent components': {
            compute: (data) => {
              const amount = calculateDependantComponentsAmount(
                {
                  ...data,
                  dependentComponents: data
                    .salary_component_id?.dependentComponents,
                  dependentPercentage: data
                    .salary_component_id?.dependentPercentage,
                }, data?._parent?.attendance_base_components,
              );

              return {
                set: {
                  amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                  dependentComponents: data.salary_component_id?.dependentComponents,
                  dependentPercentage: data.salary_component_id?.dependentPercentage,
                },
              };
            },
            onChange: ['salary_component_id'],
          },
          'set from and to date ': {
            compute: (data) => {
              const { _parent } = data;
              return {
                set: {
                  from_date: _parent.from_date,
                  to_date: _parent.to_date,
                },
              };
            },
            onChange: ['_id', '_parent.from_date', '_parent.to_date'],
          },
          'set amount on percentage change': {
            compute: (data) => {
              let mainJson = {};
              let amount = 0;
              let deductableAmt = 0;
              const {
                _parent: { attendance_base_components = [] },
                ctc_percentage: dataMainPercentage = 0,
                salary_component_id: { _id, dependency_field = [] },
              } = data;

              if (
                attendance_base_components
                && attendance_base_components.length
              ) {
                mainJson = attendance_base_components.reduce((json, one) => {
                  const id = one && one.salary_component_id && one.salary_component_id._id;
                  if (id) json[id] = one;
                  return json;
                }, {});
              }
              if (dependency_field) {
                for (const dependancyId of dependency_field) {
                  if (dependancyId._id.toString() === '11111111111111') {
                    amount += (data._parent && data._parent.ctc_amount) || 0;
                  } else {
                    amount
                      += (mainJson
                        && mainJson[dependancyId._id]
                        && mainJson[dependancyId._id].amount)
                      || 0;
                  }
                }
                deductableAmt = (amount * dataMainPercentage) / 100 || 0;
                amount = deductableAmt;
              }
              mainJson[_id].amount = amount;
              return {
                set: {
                  amount: Math.round(amount),
                },
              };
            },
            onChange: ['ctc_percentage'],
          },
        },
      },
      deductions_components: {
        self: {
          'set from and to date': {
            compute: (data) => {
              const { _parent } = data;
              return {
                set: {
                  from_date: _parent.from_date,
                  to_date: _parent.to_date,
                },
              };
            },
            onChange: ['_id', '_parent.from_date', '_parent.to_date'],
          },
          'set amount on select dependent components': {
            compute: (data) => {
              const amount = calculateDependantComponentsAmount(
                {
                  ...data,
                  dependentComponents: data
                    .salary_component_id?.dependentComponents,
                  dependentPercentage: data
                    .salary_component_id?.dependentPercentage,
                }, [
                  ...(data?._parent?.attendance_base_components || []),
                  ...(data?._parent?.deductions_components || [])],
              );

              return {
                set: {
                  amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                  dependentComponents: data.salary_component_id?.dependentComponents,
                  dependentPercentage: data.salary_component_id?.dependentPercentage,
                },
              };
            },
            onChange: ['salary_component_id'],
          },
        },
      },
    },
  },
  editable: true,
  reloadEvent: 'reloadSalaryTemplateList',
  fieldVariant: 'filled',
  basicDetails: {
    label: 'salaryCompensationTemplate.labels.basicDetails'.getLabel(),
    columnsPerRow: 2,
    icon: salaryHeartIcon,
    groups: [
      {
        columns: [CompensationFormLayouts.name, CompensationFormLayouts.code],
      },
      {
        columns: [CompensationFormLayouts.employee_type, CompensationFormLayouts.staffType],
      },
      {
        columns: [CompensationFormLayouts.CTCAmt, CompensationFormLayouts.description],
      },
      {
        columns: [CompensationFormLayouts.level, CompensationFormLayouts.status],
      },
    ],
  },
});

export const EditTemplateForm = EmployeeCompensationHoc({
  closeView: 1,
  onSubmit: submit({
    model: MODELS.EMPLOYEE_SALARY_COMPONENT,
  }),
  submitMessage: 'salaryCompensationTemplate.messages.salaryStructureUpdatedSuccessfully'.getLabel(),
  defaultValues: () => {
    const Defaults = {
      is_master_component: true,
      createdOn: new Date(),
    };
    return Defaults;
  },
  uri: ({ navigation }) => CommonSalaryTemplate(
    {
      query: 'salaryTemplateDetails',
      model: MODELS.EMPLOYEE_SALARY_COMPONENT,
      addOnFilter: { _id: navigation?.state?.params?.item?._id },
    },
    {},
  ),
  validations: {
    name: ({ data }) => validateInputLength(data?.name, 2, 26),
    code: ({ data }) => validateInputLength(data?.code, 2, 20),
  },
  mandatory: {
    code: 1,
    employeeType: 1,
    staffType: 1,
  },
  computations: {
    self: {
      ctc_amount: {
        compute: ({ attendance_base_components, deductions_components }) => {
          const earningComponentSum = attendance_base_components?.reduce((acc, { amount }) => acc + amount, 0) || 0;
          const deductionComponentSum = deductions_components?.reduce((acc, { amount }) => acc + amount, 0) || 0;
          return { set: { ctc_amount: earningComponentSum - deductionComponentSum } };
        },
        onChange: ['attendance_base_components',
          'performance_base_components',
          'deductions_components',
          'fix_base_components',
          'attendance_base_components.amount',
          'performance_base_components.amount',
          'performance_base_components.link_to_project',
          'deductions_components.amount',
          'deductions_components.salary_component_id. _id',
          'fix_base_components.amount',
          '_set_ctc'],
      },
      'set attendance component amount': {
        compute: (data) => {
          const { attendance_base_components } = data;

          return {
            set: {
              attendance_base_components: calculateComponentsAmount(
                cloneDeep(attendance_base_components).filter((com) => com.salary_component_id),
                {},
                uniq(attendance_base_components.filter((com) => com.salary_component_id)?.map((com) => com?.salary_component_id.name)),
              ),
            },
          };
        },
        onChange: ['attendance_base_components.amount'],
      },
      'set deduction component amount': {
        compute: (data) => {
          const { deductions_components = [], attendance_base_components } = data;

          return {
            set: {
              deductions_components: calculateComponentsAmount(
                cloneDeep(deductions_components).filter((com) => com.salary_component_id),
                {},
                uniq([...deductions_components, ...attendance_base_components].filter(
                  (com) => com.salary_component_id,
                )?.map((com) => com?.salary_component_id.name)),
                attendance_base_components.filter(
                  (com) => com.salary_component_id,
                ),
              ),
            },
          };
        },
        onChange: ['deductions_components.amount', 'attendance_base_components.amount'],
      },
      fixed_salary: {
        compute: (data) => {
          const response = {};
          response.fixed_salary = ((data
              && data.attendance_base_components
              && data.attendance_base_components.reduce(
                (accum, doc) => accum + doc.amount,
                0,
              ))
              || 0)
              + ((data.deductions_components
                && data.deductions_components.reduce((accum, doc) => {
                  let total = 0;
                  // Esi employer,gratuity, pf employer ,health insurance
                  if (
                    doc
                    && doc.salary_component_id
                    && (doc.salary_component_id._id.toString()
                      === '53ae739a79df44bac9b0b9f1'
                      || doc.salary_component_id._id.toString()
                      === '53ae73a779df44bac9b0ba0a'
                      || doc.salary_component_id._id.toString()
                      === '53ae738f79df44bac9b0b9da'
                      || doc.salary_component_id._id.toString()
                      === '53ae739d79df44bac9b0b9f7')
                  ) {
                    total = doc.amount || 0;
                  }
                  return accum + total;
                }, 0))
                || 0);
          return { set: { ...response } };
        },
        onChange: [
          '_id',
          'attendance_base_components',
          'attendance_base_components.amount',
          'deductions_components',
          'deductions_components.amount',
          'deductions_components.salary_component_id._id',
        ],
      },
    },
    children: {
      attendance_base_components: {
        self: {
          'set amount on select dependent components': {
            compute: (data) => {
              const amount = calculateDependantComponentsAmount(
                {
                  ...data,
                  dependentComponents: data
                    .salary_component_id?.dependentComponents,
                  dependentPercentage: data
                    .salary_component_id?.dependentPercentage,
                }, data?._parent?.attendance_base_components,
              );
              const calculatedComAmount = ((amount / 100) * (data.salary_component_id?.dependentPercentage || 0));
              return {
                set: {
                  amount: Math.round(calculatedComAmount),
                  dependentComponents: data.salary_component_id?.dependentComponents,
                  dependentPercentage: data.salary_component_id?.dependentPercentage,
                },
              };
            },
            onChange: ['salary_component_id'],
          },
          'set from and to date ': {
            compute: (data) => {
              const { _parent } = data;
              return {
                set: {
                  from_date: _parent.from_date,
                  to_date: _parent.to_date,
                },
              };
            },
            onChange: ['_id', '_parent.from_date', '_parent.to_date'],
          },
          'set amount on percentage change': {
            compute: (data) => {
              let mainJson = {};
              let amount = 0;
              let deductableAmt = 0;
              const {
                _parent: { attendance_base_components = [] },
                ctc_percentage: dataMainPercentage = 0,
                salary_component_id: { _id, dependency_field = [] },
              } = data;

              if (
                attendance_base_components
                  && attendance_base_components.length
              ) {
                mainJson = attendance_base_components.reduce((json, one) => {
                  const id = one && one.salary_component_id && one.salary_component_id._id;
                  if (id) json[id] = one;
                  return json;
                }, {});
              }
              if (dependency_field) {
                for (const dependancyId of dependency_field) {
                  if (dependancyId._id.toString() === '11111111111111') {
                    amount += (data._parent && data._parent.ctc_amount) || 0;
                  } else {
                    amount
                        += (mainJson
                          && mainJson[dependancyId._id]
                          && mainJson[dependancyId._id].amount)
                        || 0;
                  }
                }
                deductableAmt = (amount * dataMainPercentage) / 100 || 0;
                amount = deductableAmt;
              }
              mainJson[_id].amount = amount;
              return {
                set: {
                  amount: Math.round(amount),
                },
              };
            },
            onChange: ['ctc_percentage'],
          },
        },
      },
      deductions_components: {
        self: {
          'set from and to date': {
            compute: (data) => {
              const { _parent } = data;
              return {
                set: {
                  from_date: _parent.from_date,
                  to_date: _parent.to_date,
                },
              };
            },
            onChange: ['_id', '_parent.from_date', '_parent.to_date'],
          },
          'set amount on select dependent components': {
            compute: (data) => {
              const amount = calculateDependantComponentsAmount(
                {
                  ...data,
                  dependentComponents: data
                    .salary_component_id?.dependentComponents,
                  dependentPercentage: data
                    .salary_component_id?.dependentPercentage,
                }, [
                  ...(data?._parent?.attendance_base_components || []),
                  ...(data?._parent?.deductions_components || [])],
              );

              return {
                set: {
                  amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                  dependentComponents: data.salary_component_id?.dependentComponents,
                  dependentPercentage: data.salary_component_id?.dependentPercentage,
                },
              };
            },
            onChange: ['salary_component_id'],
          },
        },
      },
    },
  },
  editable: true,
  reloadEvent: 'reloadSalaryTemplateList',
  fieldVariant: 'filled',
  basicDetails: {
    label: 'salaryCompensationTemplate.labels.basicDetails'.getLabel(),
    columnsPerRow: 2,
    groups: [
      {
        columns: [CompensationFormLayouts.name, { ...CompensationFormLayouts.code, disabled: true }],
      },
      {
        columns: [CompensationFormLayouts.employee_type, CompensationFormLayouts.staffType],
      },
      {
        columns: [CompensationFormLayouts.CTCAmt, CompensationFormLayouts.description],
      },
      {
        columns: [CompensationFormLayouts.level, CompensationFormLayouts.status],
      },
    ],
  },
});

export const ViewTemplateForm = EmployeeCompensationHoc({
  closeView: 1,
  onSubmit: submit({
    model: MODELS.EMPLOYEE_SALARY_COMPONENT,
  }),
  defaultValues: () => {
    const Defaults = {
      is_master_component: true,
      createdOn: new Date(),
    };
    return Defaults;
  },
  uri: ({ navigation }) => CommonSalaryTemplate(
    {
      query: 'salaryTemplateDetails',
      model: MODELS.EMPLOYEE_SALARY_COMPONENT,
      addOnFilter: { _id: navigation?.state?.params?.item?._id },
    },
    {},
  ),
  editable: false,
  reloadEvent: 'reloadSalaryTemplateList',
  reloadOnChangeEvent: 'reloadSalaryTemplateList',
  fieldVariant: 'filled',
  basicDetails: {
    label: 'salaryCompensationTemplate.labels.basicDetails'.getLabel(),
    columnsPerRow: 2,
    groups: [
      {
        columns: [CompensationFormLayouts.name, CompensationFormLayouts.CTCAmt],
      },
      {
        columns: [CompensationFormLayouts.employee_type, CompensationFormLayouts.staffType],
      },
      {
        columns: [{ ...CompensationFormLayouts.status, type: 'status' }, CompensationFormLayouts.description],
      },
    ],
  },
});
