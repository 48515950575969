
import { useState, useCallback, useEffect } from 'react';
import {
  close,
  Form,
  save,
} from '../../../app-components';
import {
  submit, fetch, getUser, invoke,
} from '../../../AppServices';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';

export const linkTypeOpenAction = ({
  text = '',
  view,
  type,
  params = '',
  modal,
  modalProps = {},
  icon,
  iconStyle = {},
}) => ({
  type,
  text,
  icon,
  iconStyle,
  link: ({ item }) => ({
    view,
    params: {
      [params]: item,
      item,
    },
    modal,
    modalProps,
  }),
});

export const DrillAction = ({
  item,
  type,
  view,
  params = '',
  routeOptions = {},
}) => ({
  type,
  link: {
    view,
    params: params === '' ? { item } : { [params]: item },
    routeOptions,
  },
});

const user = getUser();
const submitMessage = 'Leave Request Added Successfully';

const LeaveFormHoc = ({ footer, header }) => Form({
  containerStyle: {
    padding: 10,
  },
  onSubmit: submit({
    model: 'Request',
  }),
  type: 'standardShadow',
  footer,
  submitMessage,
  closeView: 1,
  header,
  reloadEvent: 'LeaveRequestList',
  computations: {
    self: {
      'set default Date': {
        compute: (data, { user }) => {
          const response = {
            from_date: new Date(),
            to_date: new Date(),
            request_date: new Date(),
            status: 'unapproved',
            // duration_of_leave: 'Full Day',
            type_of_request: { code: 'attendance' },
          };
          if (user && user.employee) {
            response.user = user.employee;
          }
          return { set: { ...response } };
        },
        onChange: ['_id'],
      },
      'attendance Type on Default': {
        compute: async (data) => {
          if (data && data.duration_of_leave && data.duration_of_leave === 'half_day') {
            if (data && data.type_of_half_day && data.type_of_half_day === 'First Half') {
              return {
                set: {
                  first_attendance_type_id: { code: 'leave' },
                  second_attendance_type_id: null,
                },
              };
            }
            return {
              set: {
                first_attendance_type_id: null,
                second_attendance_type_id: { code: 'leave' },
              },
            };
          }
          if (data && data.duration_of_leave && data.duration_of_leave === 'full_day') {
            return {
              set: {
                first_attendance_type_id: { code: 'leave' },
                second_attendance_type_id: { code: 'leave' },
              },
            };
          }

          if (data && data.duration_of_leave && data.duration_of_leave === 'short_leave') {
            return {
              set: {
                is_short_leave: true,
              },
            };
          }
          return { set: { first_attendance_type_id: null, second_attendance_type_id: null } };
        },
        onChange: ['duration_of_leave', 'type_of_half_day'],
      },
    },
  },
  mandatory: {
    from_date: 1,
    to_date: 1,
    duration_of_leave: 1,
    remarks: 1,
  },
  formGroups: [
    {
      fieldVariant: 'filled',
      groups: [
        {
          columnsPerRow: 2,
          groupsContainerStyle: {
            paddingTop: 10,
            flex: 1,
            overflow: 'hidden',
          },
          columns: [
            {
              type: 'date',
              placeholder: 'DD/MM/YYYY',
              field: 'from_date',
              label: 'From Date',
              mandatory: true,
            },
            {
              type: 'date',
              placeholder: 'DD/MM/YYYY',
              field: 'to_date',
              label: 'To Date',
              mandatory: true,
            },
            autoSuggestInput({
              field: 'duration_of_leave',
              header: 'Duration',
              options: [{
                label: 'Full Day',
                value: 'full_day',
              },
              {
                label: 'Half Day',
                value: 'half_day',
              },
              {
                label: 'Short Leave',
                value: 'short_leave',
              }],
              placeholder: 'Duration',
              mandatory: true,
              suggestionField: 'label',
            }),
            autoSuggestInput({
              field: 'type_of_half_day',
              header: 'First Half/Second Half',
              options: ['First Half', 'Second Half'],
              placeholder: 'First Half/Second Half',
              visible: ({ data }) => data?.duration_of_leave === 'half_day',
            }),
            searchInput({
              field: 'first_attendance_type_id',
              label: 'First Half Day',
              query: 'AttendanceTypeSuggestions',
              model: MODELS.ATTENDANCE_TYPES,
              placeholder: 'First Half day',
              searchField: 'name',
              suggestionField: 'name',
              visible: ({ data }) => data && data.type_of_half_day && data.type_of_half_day === 'First Half' && data.duration_of_leave === 'half_day',
            }, {
              addOnFilter: () => ({
                code: 'leave',
              }),
            }),
            searchInput({
              field: 'second_attendance_type_id',
              label: 'Second Half Day',
              query: 'AttendanceTypeSuggestions',
              model: MODELS.ATTENDANCE_TYPES,
              placeholder: 'Second Half day',
              searchField: 'name',
              mandatory: true,
              suggestionField: 'name',
              visible: ({ data }) => data && data.type_of_half_day && data.type_of_half_day === 'Second Half' && data.duration_of_leave === 'half_day',
            }, {
              addOnFilter: () => ({
                code: 'leave',
              }),
            }),
            searchInput({
              field: 'leave_type_id',
              label: 'Leave Type',
              query: 'leaveRegister',
              model: MODELS.MONTHLY_ATTENDANCE,
              placeholder: 'Leave Type',
              searchField: 'name',
              suggestionField: 'name',
              visible: ({ data }) => data?.duration_of_leave !== 'short_leave',
            }, {
              modifyResult: ({ data }) => {
                const newData = data.leaveDetails.map((
                  leaveDetails,
                ) => {
                  const name = leaveDetails.leave_type_id.isLeaveBalanceReqForApply === true
                    ? `(${leaveDetails.leave_balance})`
                    : '';
                  return ({
                    _id: leaveDetails.leave_type_id._id,
                    name: `${leaveDetails.leave_type_id.name}
                    ${name}`,
                  });
                });
                return { data: newData };
              },

              addOnFilter: ({ user }) => ({
                employee: { _id: user.employee._id },
                // monthKey: (new Date().getMonth() + 1).toString(),
                // yearName: (new Date().getFullYear()).toString(),
              }),
            }),
            {
              type: 'textArea',
              placeholder: 'Remarks',
              field: 'remarks',
              label: 'Remarks',
              mandatory: true,
            },
          ],
        },
      ],
    },
  ],
});

const header = {
  containerStyle: {
    height: 44,
    paddingLeft: 8,
    paddingTop: 10,
  },
  title: 'Leave Request',
  actions: [close],
};

const footer = {
  containerStyle: {
    height: 60,
    paddingTop: 5,
  },
  actions: [save({ label: 'Send Request' })],
};

export const NewLeaveRequest = LeaveFormHoc({
  submitMessage,
  header,
  footer,
});
