import React, { useCallback } from 'react';

import {
  Toast, View,
} from '../../../app-components';
import { Action } from '../../../app-components/action/Action';
import { MODELS, ROUTES } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { post } from '../../../AppServices';
import { TableActionButton } from '../../../app-components/buttons/Buttons';

const Actions = (props) => {
  const {
    item: { status, _id } = {}, eventDispatcher,
  } = props;

  const updateConsultationStatus = useCallback(async (update) => {
    const fetchedPatientDetails = await post({
      data: { _id },
      updates: { status: update },
      model: MODELS.CONSULTATION,
    });
    Toast.show({
      message: 'Success',
      description: 'Consultation Updated successfully',
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    eventDispatcher.notify('reloadConsultedPatients');
    return fetchedPatientDetails;
  }, [_id, eventDispatcher]);

  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      <Action
        action={{
          type: 'link',
          link: ({ item }) => ({
            view: ROUTES.opdIncomingPatientDetails.name,
            expanded: false,
            params: { item },
          }),
        }}
        {...props}
      >
        <TableActionButton label="View Details" />
      </Action>
      {status === 'assigned' && (
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: 10,
            }}
          />
          <TableActionButton
            label="Consulted"
            color={vars.colors.primary.color2}
            hoverColor={vars.colors.primary.color2}
            onPress={() => updateConsultationStatus('completed')}
            labelStyle={{
              ...vars.headings.h7,
              color: vars.colors.white,
              numberOfLines: 1,
            }}
          />
          <View
            style={{
              width: 10,
            }}
          />
          <TableActionButton
            label="Not Appeared"
            color={vars.colors.secondary.color4}
            borderWidth={1}
            borderColor={vars.colors.error}
            onPress={() => updateConsultationStatus('not-appeared')}
            width="auto"
            labelStyle={{
              ...vars.headings.h7,
              color: vars.colors.error,
              numberOfLines: 1,
            }}
          />
        </View>
      )}
    </View>
  );
};

export default Actions;
