import React from 'react';
import moment from 'moment';

import { FilterGroup } from '../../../../../../../../app-components';
import { TabNavigator } from '../../../../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../../../../Components/TableFilters/FtsSearch';
import { MODELS } from '../../../../../../../../Lib/constants';
import { countUri } from '../../../../../../../../Queries/countUri';
import ActivityLogsTabList from '../../../../../../../../Wired/ActivityLogList';
import BatchList from '../../../../Batches/BatchList';
import DepartmentWiseStockList from './DepartmentWiseStockList';
import { filterActive, filterInactive } from '../../../../../../../../images';
import PatientWiseDispensingList from './PatientWiseDispensing';
import { dropDownShadow } from '../../../../../../../../theme/shadows';

const ViewItemTabs = (props) => {
  const {
    defaultValues = {},
    queryId = {},
    hidePatientDispensing,
    navigation: {
      state: {
        params: { item = {} } = {},
      } = {},
    } = {},
  } = props;
  const { data } = props;

  return (
    <TabNavigator
      tabs={{
        BatchInformation: {
          label: 'Batch Information',
          screen: BatchList,
          screenProps: {
            data,
            model: MODELS.PHARMACY_BATCH,
            defaultValues,
            id: 'pharmacyInventoryBatchesDataList',
            addOnFilter: { item: { _id: item?._id } },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_BATCH,
            id: 'pharmacyInventoryBatchesDataList',
            filter: { item: { _id: item?._id } },
          }),
          actions: [
            <FtsSearch action={{ exp: ['batchNumber'] }} key="search" />,
            FilterGroup({
              dropdownStyle: {
                width: 300,
                height: 300,
                backgroundColor: 'red',
                ...dropDownShadow,
                borderRadius: 8,
                overflow: 'hidden',
              },
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggestOptions',
                  options: [{ value: true, label: 'Active' }, { value: { $ne: true }, label: 'In-active' }],
                  field: 'isActive',
                  label: 'Status',
                  multiSelect: false,
                },
                {
                  type: 'autoSuggestOptions',
                  options: [
                    { label: 'Active', value: { $gte: moment().add(90, 'days').toISOString() } },
                    { label: 'Expired', value: { $lt: new Date() } },
                    { label: 'Near to Expiry', value: { $gt: new Date(), $lte: moment().add(90, 'days').toISOString() } },
                  ],
                  field: 'expiryDate',
                  label: 'Expiry Status',
                  multiSelect: false,
                },
              ],
            }),
          ],
        },
        departmentWiseStock: {
          label: 'Department-wise Stock Distribution',
          screen: DepartmentWiseStockList,
          screenProps: {
            data,
            model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
            id: queryId || 'pharmacyDepartmentInventoryStocksDataList',
            addOnFilter: { item: { _id: item?._id } },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
            id: queryId || 'pharmacyDepartmentInventoryStocksDataList',
            filter: { item: { _id: item?._id } },
          }),
          actions: [
            <FtsSearch action={{ exp: ['batchNumber'] }} key="search" />,
          ],
        },
        patientWiseDispensing: {
          visible: !hidePatientDispensing,
          label: 'Patient-Wise Dispensing',
          screen: PatientWiseDispensingList,
          screenProps: {
            model: MODELS.PHARMACY_BILLING,
            id: 'pharmacyBillingDataList',
            addOnFilter: { pharmacyDetails: { item: { _id: item?._id } } },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_BILLING,
            id: 'pharmacyBillingDataList',
            filter: { pharmacyDetails: { item: { _id: item?._id } } },
          }),
          actions: [
            <FtsSearch action={{ exp: ['patientId.patientName', 'patientId.uhid'] }} key="search" />,
          ],
        },
        activityLog: {
          label: 'Activity Log',
          screen: ActivityLogsTabList,
          screenProps: {
            data: item,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: item?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: item?._id },
          }),
        },
      }}
      {...props}
    />
  );
};
export default ViewItemTabs;
