import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { GetBirthFormUri } from '../../../../../../../Queries/birthForm';
import { Table } from '../../../../../../../app-components/table/Table';
import { StandardButton, Text } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const tabListColumns = () => ([
  {
    header: 'Serial Number',
    type: 'number',
  },
  {
    header: 'Child Name',
    type: 'text',
    field: 'childName',
  },
  {
    header: 'Mother Name',
    type: 'text',
    field: 'motherName',
  },
  {
    header: 'Father Name',
    type: 'text',
    field: 'fatherName',
  },
  {
    header: 'Mother UHID',
    type: 'text',
    field: 'admissionId.patient.uhid',
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>View</Text>
      ),
      type: 'link',
      link: ({ item }) => ({
        view: ROUTES.viewBirthForm.name,
        params: { item },
      }),
    },
    ],
  },
]);

const BirthFormList = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;

  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="Form"
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Fill Birth Form"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.fillBirthForm.name,
              params: { ...params },
              push: true,
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.IPD_BIRTH_FORM}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={() => GetBirthFormUri({ admissionId: { _id } })}
    />
  );
};
export default BirthFormList;
