import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { Table } from '../../../../../../../app-components/table/Table';
import { StandardButton } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';

const tabListColumns = {
  md: {
    columns: [
      {
        type: 'date',
        header: 'CheckList Date',
        field: 'checkList',
        format: 'DD/MM/YYYY',
      },
      {
        type: 'date',
        header: 'Date Of Surgery',
        field: 'dateOfSurgery',
        format: 'DD/MM/YYYY',
      },
      {
        type: 'text',
        field: 'systematicDisease',
        header: 'Systematic Disease',
        width: 500,
      },
      {
        type: 'text',
        render: ({ item }) => {
          const result = item.isEyeLeft ? 'Left' : 'Right';
          return result;
        },
        header: 'Eye',
      },
    ],
  },
};

const PreOperativeCheckList = (props) => {
  const { navigation: { state: { params: { patient } } } } = props;
  const { dataParams } = props;
  return (
    <Table
      {...props}
      header={(item) => ({
        title: () => (
          <HeaderTitleWithCount
            label="Pre-Operative CheckList"
            count={item?.aggregates?._count}
          />
        ),
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Log Pre-Operative CheckList"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.addOperativeCheckList.name,
              params: { ...params, dataParams },
              push: true,
            }),
          },
        ],
      })}
      uri={() => ({
        query: {
          id: 'preOperativeCheckList',
          addOnFilter: {
            admissionID: { _id: patient._id },
            speciality: dataParams?.filters?.specialityFilter,
          },
        },
        model: MODELS.PRE_OPERATIVE_CHECKLIST,
      })}
      reloadEvent={`reload${MODELS.PRE_OPERATIVE_CHECKLIST}`}
      model={MODELS.PRE_OPERATIVE_CHECKLIST}
      {...tabListColumns}
      action={{
        type: 'link',
        link: (info) => ({
          view: ROUTES.viewOperativeCheckList.name,
          modal: true,
          params: { item: info.item, dataParams },
        }),
      }}
    />
  );
};
export default PreOperativeCheckList;
