import vars from './vars';

const saveButtonTheme = {
  containerStyle: {
    minWidth: 109,
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    paddingLeft: 19,
    paddingRight: 19,
    backgroundColor: vars.colors.secondary.color2,
    paddingLeft: 10,
    paddingRight: 10,
  },
  labelStyle: {
    ...vars.headings.h7,
    color: vars.colors.white,
  },
};

const cancelButtonTheme = {
  containerStyle: {
    minWidth: 109,
    minHeight: 40,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    borderColor: vars.colors.secondary.color2,
    borderWidth: 1,
  },
  labelStyle: {
    ...vars.headings.h7,
    color: vars.colors.secondary.color2,
  },
};

export { saveButtonTheme, cancelButtonTheme };
