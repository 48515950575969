import React from 'react';

import {
  View, Text, save, Toast,
} from '../../../../../../../app-components';
import {
  NestedAction,
  NestedTable,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../Lib/constants';
import { submit } from '../../../../../../../AppServices';
import vars from '../../../../../../../theme/vars';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { validateInputLength } from '../../../../../../../Lib/helpers';
import { newDateInput } from '../../../../../../../compositeInputs';
import { dateFormatX } from '../../../../../constants';

const IpdAddProgressGeneralMedicine = (props) => {
  const {
    navigation: { state: { params: { data: paramsData } = {} } = {} } = {},
  } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [save],
          }}
          mandatory={{
            doctorOnRound: 1,
            vitalsP: 1,
            vitalsBP: 1,
            vitalsRR: 1,
            progressGc: 1,
            vitalsTemperature: 1,
            vitalsSPO2: 1,
            orientation: 1,
            built: 1,
            nourishment: 1,
            treatment: {
              drug: 1,
              route: 1,
              dose: 1,
              frequency: 1,
              description: 1,
            },
          }}
          defaultValues={{
            meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            updates.gpeDate = dateFormatX(
              data?.hour,
              data?.minute,
              data?.meridiem,
            );
            delete updates.hour;
            delete updates.minute;
            delete updates.meridiem;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: {
                _id: submitProps?.navigation?.state?.params?.patient?._id,
              },
              speciality: submitProps?.navigation?.state?.params
                .dataParams?.filters?.specialityFilter,
              progressDate:
       new Date(
         new Date(
           new Date(
             new Date(
               new Date().setUTCHours(0),
             ).setUTCMinutes(0),
           ).setUTCSeconds(0),
         ).setUTCMilliseconds(0),
       ),

            };

            const submitAPI = submit({
              model: MODELS.IPD_PROGRESS_REPORT,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          type="standardShadow"
          lg={{
            validations: {
              vitalsP: ({ data }) => validateInputLength(data?.vitalsP, 1, 4),
              vitalsBP: ({ data }) => validateInputLength(data?.vitalsBP, 1, 4),
              vitalsRR: ({ data }) => validateInputLength(data?.vitalsRR, 1, 5),
              vitalsTemperature: ({ data }) => validateInputLength(data?.vitalsTemperature, 1, 4),
              vitalsSPO2: ({ data }) => validateInputLength(data?.vitalsSPO2, 1, 5),
              place: ({ data }) => validateInputLength(data?.place, 0, 20),
              person: ({ data }) => validateInputLength(data?.person, 0, 20),
              drug: ({ data }) => validateInputLength(data?.drug, 1, 100),
              route: ({ data }) => validateInputLength(data?.route, 1, 20),
              dose: ({ data }) => validateInputLength(data?.dose, 1, 5),
              frequency: ({ data }) => validateInputLength(data?.frequency, 1, 50),
              description: ({ data }) => validateInputLength(data?.description, 1, 100),
              cardioInspection: ({ data }) => validateInputLength(data?.cardioInspection, 0, 20),
              cardioPalpation: ({ data }) => validateInputLength(data?.cardioPalpation, 0, 20),
              cardioPercussion: ({ data }) => validateInputLength(data?.cardioPercussion, 0, 20),
              cardioAusculation: ({ data }) => validateInputLength(data?.cardioAusculation, 0, 20),
              gitInspection: ({ data }) => validateInputLength(data?.gitInspection, 0, 20),
              gitPalpation: ({ data }) => validateInputLength(data?.gitPalpation, 0, 20),
              gitPercussion: ({ data }) => validateInputLength(data?.gitPercussion, 0, 20),
              gitAusculation: ({ data }) => validateInputLength(data?.gitAusculation, 0, 20),
              hmf: ({ data }) => validateInputLength(data?.hmf, 0, 20),
              cravelNerve: ({ data }) => validateInputLength(data?.cravelNerve, 0, 20),
              neckRegidity: ({ data }) => validateInputLength(data?.neckRegidity, 0, 20),
              motor: ({ data }) => validateInputLength(data?.motor, 0, 20),
              sensory: ({ data }) => validateInputLength(data?.sensory, 0, 20),
              cerebellar: ({ data }) => validateInputLength(data?.cerebellar, 0, 20),
              spine: ({ data }) => validateInputLength(data?.spine, 0, 20),
              others: ({ data }) => validateInputLength(data?.others, 0, 20),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'radioGroup',
                            field: 'progressGc',
                            container: 'topLabel',
                            label: 'GC',
                            mandatory: true,
                            options: [
                              { value: 'Fair', label: 'Fair' },
                              { value: 'Good', label: 'Good' },
                              { value: 'Poor', label: 'Poor' },
                            ],
                          },
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'doctorOnRound',
                            label: 'Doctor On Round',
                            variant: 'filled',
                            keyFiled: 'firstName',
                            suggestionField: 'firstName',
                            valueField: 'firstName',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            mandatory: true,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>VITALS </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsSPO2',
                            label: 'SPO2',
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>GPE Head to Be </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    columnsPerRow: 4,
                    columns: [
                      newDateInput({
                        hour: { field: 'hour', label: 'Hour' },
                        minute: { field: 'minute', label: 'Minute' },
                        meridiem: { field: 'meridiem' },
                      }),
                      {
                        type: 'text',
                        field: 'place',
                        label: 'Place',
                        allowWhiteSpaces: true,
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'person',
                        label: 'Person',
                        allowWhiteSpaces: true,
                        editable: true,
                      },
                    ],
                  },
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'radioGroup',
                        field: 'orientation',
                        label: 'Orientation',
                        container: 'topLabel',
                        mandatory: true,
                        options: ['Conscious', 'Uncounsious'],
                      },
                      {
                        type: 'radioGroup',
                        field: 'built',
                        variant: 'filled',
                        label: 'Built',
                        container: 'topLabel',
                        mandatory: true,
                        width: 300,
                        options: ['Poor', 'Moderate', 'Well'],
                      },
                      {
                        type: 'radioGroup',
                        field: 'nourishment',
                        label: 'Nourishment',
                        mandatory: true,
                        container: 'topLabel',
                        options: ['poor', 'well'],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>CardioVascular </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'cardioInspection',
                            label: 'Inspection',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cardioPalpation',
                            label: 'Palpation',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cardioPercussion',
                            label: 'Percussion',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cardioAusculation',
                            label: 'Ausculation',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>GIT </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'gitInspection',
                            label: 'Inspection',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'gitPalpation',
                            label: 'Palpation',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'gitPercussion',
                            label: 'Percussion',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'gitAusculation',
                            label: 'Ausculation',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>CNS </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'hmf',
                            label: 'HMF',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cravelNerve',
                            label: 'Cravel Nerve',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'neckRegidity',
                            label: 'Neck Regidity',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'motor',
                            label: 'Motor',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'sensory',
                            label: 'Sensory',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cerebellar',
                            label: 'Cerebellar',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'spine',
                            label: 'Spine',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'others',
                            label: 'others',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>Orientation </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'deepRep',
                            label: 'Deep Rep',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'planetary',
                            label: 'Planetary',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {
                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              footer: ({ addRow }) => ({
                                leftActions: [
                                  {
                                    render: () => (
                                      <View
                                        style={{
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderWidth: 1,
                                          borderRadius: 5,
                                          width: 120,
                                          height: 40,
                                          borderColor:
                                            vars.colors.secondary.color2,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            ...vars.headings.h9,
                                            color: vars.colors.secondary.color2,
                                          }}
                                        >
                                          {`+ ${'Add New Item'.getLabel()}`}
                                        </Text>
                                      </View>
                                    ),
                                    onPress: addRow,
                                  },
                                ],
                              }),
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: true,
                                    width: 250,
                                    mandatory: true,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    editable: true,
                                    mandatory: true,
                                    width: 150,
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    editable: true,
                                    mandatory: true,
                                    width: 100,
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: true,
                                    mandatory: true,
                                    width: 200,
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    allowWhiteSpaces: true,
                                    editable: true,
                                    mandatory: true,
                                    width: 312,
                                  },
                                  NestedAction(),
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'transferTo',
                            label: 'Transfer To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.WARDS,
                            query: 'masterDataList',
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'assignTO',
                            label: 'Assign To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.EMPLOYEE,
                            query: 'getEmployeeDetails',
                          },
                          {
                            addOnFilter: ({ data } = {}) => ({
                              speciality: { _id: data?.transferTo?._id },
                            })
                            ,
                          }),
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => 'Today \'s Advice',
                          },
                          {
                            type: 'text',
                            field: 'todaysAdvice',
                            label: 'Today \'s Advice',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const AddProgressReport = (props) => (
  <IpdAddProgressGeneralMedicine {...props} />
);
export default AddProgressReport;
