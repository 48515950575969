import React from 'react';
import {
  OutlineButton, StandardButton, View,
} from '../../../../../../../../app-components';

import { Form } from '../../../../../../../../app-components/form/Form';
import { invoke } from '../../../../../../../../AppServices';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { history } from '../../../../../../../../images';
import { ROUTES } from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';

const AnesthesiaModal = (props) => {
  const { navigation } = props;
  const {
    navigation: {
      state: {
        params: {
          submitResult,
          description,
        },
      } = {},
    } = {},
  } = props;
  const { result } = submitResult;
  return (
    <ListActionModal
      icon={history}
      title="Confirmation"
      description={description}
      content={(
        <Form
          closeView={2}
          popOnError={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 0.5,
                  bottom: 15,
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <OutlineButton
                  label="No"
                  onClick={async (data) => {
                    await invoke({
                      id: 'addNewEntry',
                      paramValue: {
                        result,
                      },
                    });
                    navigation.pop(2);
                  }}
                  width={109}
                  height={40}
                  borderRadius={4}
                />

              </View>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 0.5,
                  bottom: 15,
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label="Yes"
                  onClick={async () => {
                    const Anesthesia = await invoke({
                      id: 'addNewEntry',
                      paramValue: {
                        result,
                      },
                    });
                    navigation.replace({
                      view: ROUTES.logAnaesthesia.name,
                      params: { item: Anesthesia },
                    });
                  }}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
        )}
      {...props}
    />
  );
};

export default AnesthesiaModal;
