import React from 'react';

import { FormHoc } from '../../../../app-components';
import RelationInput from '../../../../Components/RelationInput';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { genders, MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';
import { EditPatientUri } from '../../../../Queries/patient';
import { ageInput } from '../../../../compositeInputs';

const validations = {
  aadharNumber: ({ data }) => validateInputLength(data?.aadharNumber, 12, 12),
  BPLNumber: ({ data }) => validateInputLength(data?.BPLNumber, 10, 10),
  age: ({ data }) => validateInputLength(data?.age, 0, 3),
};
const EditPatientForm = FormHoc({
  uri: EditPatientUri,
  type: 'standard',
  submitMessage: 'patient.messages.patientUpdatedSuccessfully'.getLabel(),
  reloadEvent: 'reloadOPDRegisteredPatients',
  onSubmit: async (submitProps) => {
    const { updates, data } = submitProps;
    const newUpdate = { ...updates, isUnknown: null };

    const submitAPI = submit({
      model: MODELS.PATIENTS,
      ...submitProps,
      data,
      updates: newUpdate,
    });
    await submitAPI({ data, updates: newUpdate });
  },
  validations,
  closeView: 1,
  defaultValues: {
    relation: 'S/O',
  },
  mandatory: {
    patientName: 1,
    gender: 1,
    age: 1,
    relationName: 1,
    address: 1,
  },
  lg: {
    formGroups: [
      {
        columnsPerRow: 2,
        fieldVariant: 'filled',
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'patientName',
                label: 'patient.labels.patientName'.getLabel(),
                mandatory: true,
                allowWhiteSpaces: true,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value;
                },
              },
              autoSuggestInput({
                label: 'patient.labels.gender'.getLabel(),
                field: 'gender',
                suggestionField: 'label',
                placeholder: 'patient.placeholders.selectGender'.getLabel(),
                options: genders,
                mandatory: true,
              }),
              ageInput({
                age: {
                  type: 'number',
                  field: 'age',
                  label: 'patient.labels.age'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value > 999) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                ageType: {
                  field: 'ageType',
                },
              }),
              {
                type: 'custom',
                render: (props) => (
                  <RelationInput
                    {...props}
                    mandatory
                    relationNameProps={{
                      allowWhiteSpaces: true,
                    }}
                  />
                ),
              },
              {
                type: 'number',
                field: 'aadharNumber',
                label: 'patient.labels.aadhar'.getLabel(),
                inputProps: {
                  maxLength: 12,
                },
              },
              {
                type: 'number',
                field: 'BPLNumber',
                label: 'patient.labels.bpl'.getLabel(),
                inputProps: {
                  maxLength: 10,
                },
              },
              {
                type: 'number',
                field: 'mobile',
                label: 'patient.labels.mobile'.getLabel(),
                placeholder: 'patient.placeholders.mobile'.getLabel(),
                allowedDecimalDigits: false,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 10) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'text',
                field: 'address',
                label: 'patient.labels.address'.getLabel(),
                placeholder: 'patient.placeholders.address'.getLabel(),
                width: '50%',
                allowWhiteSpaces: true,
                mandatory: true,
              },
              // {
              //   type: 'textArea',
              //   field: 'remarks',
              //   label: 'patient.labels.remarks'.getLabel(),
              //   placeholder: 'patient.placeholders.remarks'.getLabel(),
              //   width: '50%',
              // },
            ],
          },
        ],
      },
    ],
  },
});

const EditPatient = (props) => <EditPatientForm {...props} />;

export default EditPatient;
