/* eslint-disable max-len */
import React, { useCallback } from 'react';
import moment from 'moment';

import {
  FormField, OutlineButton, StandardButton, Text, Toast, View,
} from '../../../../../app-components';
import { batchIcon } from '../../../../../images';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import vars from '../../../../../theme/vars';
import { submit } from '../../../../../AppServices';
import { expiryStatusOptions, MODELS } from '../../../../../Lib/constants';
import { calculateRemainingSelfLife } from '../../../../../Lib/helpers';
import { currencyInput } from '../../../../../compositeInputs';

const EditBatch = (props) => {
  const { navigation } = props;
  const {
    navigation: {
      state: {
        params: {
          defaultValues = {},
        } = {},
      } = {},
    } = {},
  } = props;

  const {
    navigation: {
      state: {
        params: {
          item: {
            _id,
            stockEntryDate,
            ...restItem
          },
          remainingSelfLife,
        },
      },
    },
  } = props;

  const todayDate = moment();
  const diffDays = moment(todayDate).diff(stockEntryDate, 'days');
  // eslint-disable-next-line no-shadow
  const getBatchUpdateDays = useCallback(async (props) => {
    const { data, updates } = props;
    if (defaultValues.module) {
      updates.module = defaultValues.module;
    }
    if (diffDays <= 2) {
      const newUpdate = updates;
      delete newUpdate.batchId;

      const submitAPI = submit({
        model: MODELS.PHARMACY_BATCH,
        ...props,
        data,
        updates: newUpdate,
      });
      await submitAPI({ data, updates: newUpdate });
      Toast.show({
        message: 'Success',
        description: 'Batch has been update successfully.',
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      navigation.pop();
      return {};
    }

    delete data._id;
    delete updates._id;

    const submitAPI = submit({
      model: MODELS.PHARMACY_BATCH_REQUESTS,
      ...props,
      data,
    });
    await submitAPI({ data, updates });
    Toast.show({
      message: 'Success',
      description: 'inventory.messages.sendForApproval'.getLabel(),
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    navigation.pop();
    return {};
  }, [defaultValues.module, diffDays, navigation]);

  return (
    <ListActionModal
      icon={batchIcon}
      title={'inventory.title.batchDetails'.getLabel()}
      description={'inventory.title.batchDescription'.getLabel()}
      content={(
        <Form
          {...props}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          reloadEvent={`reload${MODELS.PHARMACY_BATCH}`}
          // eslint-disable-next-line no-unused-vars
          beforeSubmit={({ data, updates }) => ({
            updates: {
              batchId: { _id },
              batchNumber: updates.batchNumber,
              currency: updates.currency,
              expiryDate: updates.expiryDate,
              isActive: updates.isActive,
              item: updates.item,
              manufacturingDate: updates.manufacturingDate,
              purchasedPrice: updates.purchasedPrice,
              quantityReceived: updates.quantityReceived,
              stockEntryDate: updates.stockEntryDate,
              supplyReceivedOn: updates.supplyReceivedOn,
            },
          })}
          onSubmit={getBatchUpdateDays}
          defaultValues={() => ({
            ...restItem,
            ...defaultValues,
            stockEntryDate,
            remainingSelfLife,
            _id,
          })}
          mandatory={{ reason: 1 }}
        >
          {({ form_context: formContext }) => (
            <View style={{
              paddingBottom: 12, paddingLeft: 86, paddingRight: 86,
            }}
            >
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ paddingRight: 24, width: 320 }}>
                  <FormField
                    type="text"
                    label={'inventory.labels.stockStatus'.getLabel()}
                    variant="filled"
                    field="item.stockStatus.stockStatus"
                    editable={false}
                    style={{
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    render={({ data } = {}) => {
                      let stockStatus = '';
                      let statusColor = '';
                      if (data?.quantityAvailable <= data?.item?.reorderLevel) {
                        stockStatus = expiryStatusOptions.critical;
                        statusColor = vars.colors.error;
                      } else {
                        stockStatus = expiryStatusOptions.optimal;
                        statusColor = vars.colors.primary.color2;
                      }
                      return (
                        <Text style={{ ...vars.headings.h11, color: statusColor }}>
                          {stockStatus}
                        </Text>
                      );
                    }}
                  />
                </View>
                <View>
                  <FormField
                    type="text"
                    label={'inventory.labels.expiryStatus'.getLabel()}
                    variant="filled"
                    field="expiryStatus"
                    editable={false}
                    style={{
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    render={({ data }) => {
                      const expiryDays = calculateRemainingSelfLife(data?.expiryDate);
                      let expiryStatus = expiryStatusOptions.active;
                      let statusColor = vars.colors.primary.color2;
                      if (expiryDays === 0 || expiryDays < 0) {
                        expiryStatus = expiryStatusOptions.expired;
                        statusColor = vars.colors.error;
                      } else if (expiryDays > 0 && expiryDays <= 60) {
                        expiryStatus = expiryStatusOptions.nearToExpiry;
                        statusColor = vars.colors.warning;
                      } else if (expiryDays > 60 && expiryDays < 90) {
                        expiryStatus = expiryStatusOptions.nearToExpiry;
                        statusColor = vars.colors.emphasis;
                      }
                      return (
                        <Text style={{ ...vars.headings.h11, color: statusColor }}>
                          {expiryStatus}
                        </Text>
                      );
                    }}
                  />
                </View>
              </View>
              <Text style={{
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'upperCase',
                paddingTop: 15,
              }}
              >
                Batch Information
              </Text>
              <View style={{ flex: 1, flexDirection: 'row', paddingTop: 15 }}>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="text"
                    label={'inventory.labels.batchNumber'.getLabel()}
                    variant="filled"
                    field="batchNumber"
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="date"
                    label={'inventory.labels.manufacturingDate'.getLabel()}
                    variant="filled"
                    field="manufacturingDate"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="date"
                    label={'inventory.labels.expiryDate'.getLabel()}
                    variant="filled"
                    field="expiryDate"
                  />
                </View>

              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="date"
                    label={'inventory.labels.stockEntryDate'.getLabel()}
                    variant="filled"
                    field="stockEntryDate"
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="date"
                    label={'inventory.labels.supplyReceivedOn'.getLabel()}
                    variant="filled"
                    field="supplyReceivedOn"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="number"
                    label={'inventory.labels.remainingSelfLife'.getLabel()}
                    variant="filled"
                    value={calculateRemainingSelfLife(restItem?.expiryDate) > 0 ? calculateRemainingSelfLife(restItem?.expiryDate) : 0}
                    disabled
                  />
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    {
                    ...currencyInput({
                      variant: 'filled',
                      amount: {
                        field: 'purchasedPrice',
                        label: 'inventory.labels.purchasePrice'.getLabel(),
                      },
                      currency: {
                        field: 'currency',
                        width: 100,
                      },
                    })
                  }
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="number"
                    label={'inventory.labels.stockAvailable'.getLabel()}
                    variant="filled"
                    field="item.stockAvailable.stockAvailable"
                    disabled
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="number"
                    label={'inventory.labels.quantityReceived'.getLabel()}
                    variant="filled"
                    field="quantityReceived"
                  />
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 1, paddingRight: 8 }}>
                  <FormField
                    type="toggleSwitch"
                    label={'inventory.labels.status'.getLabel()}
                    container="topLabel"
                    variant="filled"
                    field="isActive"
                  />
                </View>
                <View style={{ flex: 2 }}>
                  <FormField
                    type="text"
                    label={'inventory.labels.reasonForUpdation'.getLabel()}
                    variant="filled"
                    field="reason"
                    mandatory
                    allowWhiteSpaces
                  />
                </View>
              </View>
              <View
                style={{
                  alignSelf: 'center',
                  marginTop: 25,
                  flexDirection: 'row',
                }}
              >
                <View style={{ marginRight: 24 }}>
                  <OutlineButton
                    label={'inventory.buttons.cancel'.getLabel()}
                    onClick={() => {
                      navigation.pop();
                    }}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
                <StandardButton
                  label={diffDays <= 2 ? 'Updates' : 'inventory.buttons.sendForApproval'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={166}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default EditBatch;
