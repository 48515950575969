import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import {
  ActivityIndicator, Image, Text, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { fetch } from '../../../../AppServices';
import { noStatus } from '../../../../images';
import { MODELS, vaccinationStatus, vaccinationType } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import printPharmacyBill from '../../../../Wired/PatientInvoices';
import FileLink from '../../../../Components/FileLink';

const ViewPatientDetails = (props) => {
  const { navigation } = props;
  const { state: { params: { item } = {} } = {} } = navigation;
  const [patientDetails, setPatientDetails] = useState();
  const [loading, setLoading] = useState(true);
  const fetchPatientDetails = useCallback(async () => {
    const fetchedPatientDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientVaccinationList',
            addOnFilter: {
              _id: item?._id,
            },
          },
          model: MODELS.VACCINATION,
        },
      },
    });
    setPatientDetails(fetchedPatientDetails?.data[0]);
    setLoading(false);
    return fetchedPatientDetails;
  }, [item]);
  useEffect(() => {
    fetchPatientDetails();
  }, [item]);
  if (loading) return <ActivityIndicator />;
  return (
    <Form
      type="standard"
      closeView={1}
      defaultValues={{ ...patientDetails }}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'vaccination.labels.vaccinationDate'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.status'.getLabel(),
                    render: () => {
                      const { status } = patientDetails;
                      if (status === vaccinationStatus.vaccinated) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                            Vaccinated
                          </Text>
                        );
                      }
                      if (status === vaccinationStatus.nurseAssign) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                            Nurse Assigned
                          </Text>
                        );
                      }
                      if (status === vaccinationStatus.notAppear) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.error }}>
                            Not Appeared
                          </Text>
                        );
                      }
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          No Status
                        </Text>
                      );
                    },
                    field: 'status',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.bill'.getLabel(),
                    render: ({ data }) => {
                      const { billing } = data;
                      return (
                        <TouchableOpacity onPress={({ submitResult }) => printPharmacyBill({ data: { ...billing }, submitResult })}>
                          <Text style={{ color: vars.colors.secondary.color2 }}>
                            Invoice_vcc_128.pdf
                          </Text>
                        </TouchableOpacity>
                      );
                    },
                  },
                  {
                    type: 'file',
                    label: 'vaccination.labels.prescription'.getLabel(),
                    render: () => {
                      const { billing } = patientDetails;
                      if (billing?.documents) {
                        return (
                          <FileLink file={billing.documents} />
                        );
                      }
                    },
                  },
                ],
              },
            ],
          },
          {
            label: 'vaccination.title.patientDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    label: 'vaccination.labels.patientName'.getLabel(),
                    field: 'patient.patientName',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.uhid'.getLabel(),
                    field: 'patient.uhid',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.gender'.getLabel(),
                    field: 'patient.gender',
                  },
                  {
                    type: 'number',
                    label: 'vaccination.labels.age'.getLabel(),
                    render: () => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {patientDetails.patient.age}
                        {' '}
                        {patientDetails.patient.ageType}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.bloodGroup'.getLabel(),
                    field: 'patient.name',
                  },
                  {
                    type: 'number',
                    label: 'vaccination.labels.mobile'.getLabel(),
                    field: 'patient.mobile',
                  },
                ],
              },
            ],
          },
          {
            label: 'vaccination.labels.vaccineDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'text',
                    label: 'vaccination.labels.nurse'.getLabel(),
                    render: () => {
                      const { session: { nurse: { firstName, lastName } = {} } = {} } = patientDetails;
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {firstName}
                          {' '}
                          {lastName}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.assistant'.getLabel(),
                    render: () => {
                      const { session: { assistant: { firstName, lastName } = {} } = {} } = patientDetails;
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {firstName}
                          {' '}
                          {lastName}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.department'.getLabel(),
                    field: 'consultation.department.name',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.speciality'.getLabel(),
                    field: 'consultation.speciality.name',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.doctorName'.getLabel(),
                    field: 'consultation.consultedByName',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'vaccination.title.vaccineList'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    field: 'patientVaccineDetails',
                    fieldType: 'nested',
                    editable: false,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      listProps: {
                        columns: [
                          {
                            type: 'text',
                            variant: 'filled',
                            field: 'item.name',
                            header: 'vaccination.headers.vaccineName'.getLabel(),
                          },
                          {
                            type: 'number',
                            variant: 'filled',
                            field: 'dose',
                            header: 'vaccination.headers.doseNo'.getLabel(),
                            width: 180,
                          },
                          {
                            type: 'text',
                            variant: 'filled',
                            render: ({ item: { item } = {} }) => (
                              <Text style={{ ...vars.headings.h9 }}>
                                {item?.isVaccineFree ? 'Free' : 'Paid'}
                              </Text>
                            ),
                            header: 'vaccination.headers.paymentType'.getLabel(),
                          },
                          {
                            type: 'text',
                            variant: 'filled',
                            render: ({ item: { item, dose = 0 } }) => {
                              const { vaccinationReOccurringDetails } = item;
                              let daysBeforeRevaccination = '';
                              if (item?.vaccinationType === vaccinationType.reCurring && item?.isReoccurringIntervalEqual) {
                                const { intervalType, interval } = vaccinationReOccurringDetails[0];
                                daysBeforeRevaccination = `${interval} ${intervalType}`;
                              } else if (item?.vaccinationType === vaccinationType.reCurring) {
                                const { interval, intervalType } = vaccinationReOccurringDetails[dose - 1];
                                daysBeforeRevaccination = `${interval} ${intervalType}`;
                              } else if (item?.vaccinationType === vaccinationType.onceInLifeTime) {
                                daysBeforeRevaccination = 'Once in Lifetime';
                              } else {
                                daysBeforeRevaccination = '--';
                              }
                              return (
                                <Text>
                                  {daysBeforeRevaccination}
                                </Text>
                              );
                            },
                            header: 'vaccination.headers.reVaccinateGap'.getLabel(),
                          },
                          {
                            header: 'vaccination.headers.paymentStatus'.getLabel(),
                            type: 'text',
                            render: () => {
                              const { isPaymentDone } = patientDetails;
                              if (isPaymentDone) {
                                return (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Text
                                      style={[
                                        vars.headings.h9,
                                        { marginLeft: 10, color: vars.colors.primary.color2 },
                                      ]}
                                    >
                                      Completed
                                    </Text>
                                  </View>
                                );
                              }
                              return (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Image
                                    source={noStatus}
                                    style={{
                                      width: 20,
                                      height: 20,
                                    }}
                                  />
                                  <Text
                                    style={[
                                      vars.headings.h9,
                                      { marginLeft: 10, color: vars.colors.grey.color1 },
                                    ]}
                                  >
                                    Free
                                  </Text>
                                </View>
                              );
                            },
                          },
                          {
                            type: 'text',
                            variant: 'filled',
                            render: ({ item }) => {
                              if (item?.dose === item?.item?.noOfDoses && patientDetails?.status === vaccinationStatus.vaccinated) {
                                return (
                                  <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                                    Provided
                                  </Text>
                                );
                              }
                              return (
                                <Text style={{ ...vars.headings.h8, color: vars.colors.warning }}>
                                  Pending
                                </Text>
                              );
                            },
                            header: 'vaccination.headers.doseStatus'.getLabel(),
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPatientDetails;

