import { MODELS } from '../Lib/constants';

export const ContingentVoucherListUri = () => ({
  query: {
    model: MODELS.CONTINGENT_VOUCHER,
    id: 'contingentVoucherDataList',
  },
  model: MODELS.CONTINGENT_VOUCHER,
});

export const contingentVoucherRequest = ({ navigation = {} }) => ({
  query: {
    model: MODELS.CONTINGENT_VOUCHER,
    id: 'contingentVoucherDataList',
    relationValue: {
      model: MODELS.CONTINGENT_VOUCHER,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
  },
  model: MODELS.CONTINGENT_VOUCHER,
});
