export default {
  labels: {
    patientName: 'Patient name',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    bpl: 'BPL No.',
    aadhar: 'Aadhar No.',
    mobile: 'Mobile No.',
    age: 'Age',
    fatherName: 'Father Name',
    address: 'Address',
    scanner: 'Scanner Available',
    register: 'Register',
    searchPatient: 'Search Patient',
    enterUHID: 'Enter UHID',
    viewRegisteredPatients: 'View Registered Patients',
    remarks: 'Remarks',
    department: 'Department',
    speciality: 'Speciality',
    saveAndPrintCard: 'Save & Print Card',
    patientUnique: 'PATIENT UNIQUE ID',
    name: 'Name',
    uhid: 'UHID',
    date: 'Date',
    relation: 'Relation',
    uniqueMark: 'Unique Marks',
    itemName: 'Item Name',
    description: 'Description',
    itemImage: 'Image',
    additionalComments: 'Additional Comments',
    belongingsCareBy: 'Belongings Taken Care By',
    time: 'Time',
  },
  placeholders: {
    mobile: 'Enter Mobile Number',
    address: 'Enter Building name, street etc',
    patientName: 'Enter Patient Name',
    fatherName: 'Enter Father Name',
    selectGender: 'Select Gender',
    enterUHID: 'Enter UHID',
    remarks: 'Remarks',
    selectDepartment: 'Select Department',
    selectWard: 'Select Speciality',
    uniqueMark: 'Unique Marks',
    itemName: 'Item Name',
    description: 'Description',
    itemImage: 'Drag & Drop',
    belongingsCareBy: 'Belongings Taken Care By',
    additionalComments: 'Additional Comments',
  },
  buttons: {
    exportToExcel: 'Export To Excel',
    printOpdCard: 'Save & Print OPD Card',
    edit: 'Edit',
    update: 'Update',
    save: 'Save',
    register: 'Register',
    scan: 'Scan',
    addConsultation: 'Add Consultation',
    admitToIPD: 'Admit To IPD',
    generateAttendantCard: 'Generate Attendant Card',
    printIpdCard: 'Save & Print IPD Card',
    fillLater: 'Fill Later',
  },
  title: {
    addNewPatient: 'Add New Patient',
    existingPatient: 'Existing Patient',
    registeredPatients: 'Registered Patients',
    consultationRegistration: 'Consultation Registration',
    belongingsDetails: 'Belongings Details',
    IPDAdmission: 'IPD Admission Form',
  },
  subTitle: {
    consultationSubTitle: 'Fill out the form to consult doctor.',
    IPDSubtitle: 'Fill out form to admit patient to a speciality.',
  },
  messages: {
    patientUpdatedSuccessfully: 'Patient has been updated successfully.',
    patientAddedSuccessfully: 'Patient has been added successfully.',
    consultationAddedSuccessfully: 'Consultation has been added successfully.',
    incorrectUHID: 'Patient not found for entered UHID.',
    enterUHIDToSearch: 'Please enter UHID to search patient.',
    patientAdmissionIPD: 'Patient has been admitted Successfully.',
  },
  headers: {
    uhid: 'UHID',
    patientName: 'Patient name',
    gender: 'Gender',
    age: 'Age',
    relation: 'Relation',
    action: 'Actions',
    speciality: 'Speciality',
    remarks: 'Remarks',
    lastConsultationDate: 'Last Consultation Date',
    dateAndTime: 'Date And Time',
    image: 'Image',
    clothingAndBelongings: 'Clothing/Belongings',
    bedNo: 'Bed No.',
    uniqueMark: 'Unique Marks',
  },
};
