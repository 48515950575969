import React from 'react';
import * as RequestLayouts from '../../../../../../Wired/Attendance/Requests/Layouts';
import * as LeaveRegisterLayouts from '../../../../../../Wired/Attendance/LeaveRegister/Layouts';
import { userLeaveLopSheetUri } from '../../../../../../Wired/Attendance/LeaveRegister/Uri';
import { List, Text, View } from '../../../../../../app-components';
import { getPermission, getUser, post } from '../../../../../../AppServices';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import permissions from '../../../../../../Lib/permissions';
import vars from '../../../../../../theme/vars';
import { isArray } from 'lodash';

const {
  employeeNameLayout,
  employeeIdLayout,
  AttendanceDateLayout,
  RequestedOnLayout,
  RemarksLayout,
} = RequestLayouts;

const {
  YearLayout,
  TotalSalaryDaysLayout,
  presentDaysLayout,
  HolidaysLayout,
  compensatoryOffLayout,
  ewdPayableLayout,
  ewdAdjustWithLeavesLayout,
  employeeLayout,
  AvailableLayout,
  ConsumedLayout,
  BalanceLayout,
  accuredLeaveLayout,
} = LeaveRegisterLayouts;

export const InvokeTypeConfirmOpenAction = ({
  text,
  title,
  message,
  postMessage,
  reloadEvent,
  model,
  updates,
  id,
}) => ({
  type: 'invoke',
  text,
  confirm: {
    title,
    message,
    confirmText: 'Yes',
    cancelText: 'No',
  },
  postMessage,
  reloadEvent,
  invoke: (data) => {
    const { item, selectedIds } = data;

    return post({
      data: item,
      updates,
      model,
      id,
      extraParams: {
        selectedIds,
      },
    });
  },
});

export const AllList = List({
  uri: ({ navigation }) => {
    let addOnFilter = {};

    const { selectedTab } = navigation.getTabScreenState();
    if (selectedTab === 'pending') {
      addOnFilter = { status: 'unapproved' };
    } if (selectedTab === 'approved') {
      addOnFilter = { status: 'approve' };
    } if (selectedTab === 'rejected') {
      addOnFilter = { status: 'reject' };
    }

    return {
      query: {
        id: 'attendanceRequests',
        sort: {
          _id: -1,
        },
        addOnFilter,
      },
      model: MODELS.ATTENDANCE_REQUESTS,
    };
  },
  reloadEvent: 'AllList',
  sm: {},
  md: {
    columns: [
      { width: 14 },
      RequestLayouts.employeeNameLayout,
      employeeIdLayout,
      {
        type: 'text',
        header: 'Department',
        mandatory: true,
        render: ({ item:{ employee } }) => 
        Array.isArray(employee?.department) ? employee?.department.map(({name})=>` ${name}`).toString() : '--' ,  
        editable: false,
      },
      AttendanceDateLayout,
      RequestedOnLayout,
      RequestLayouts.FirstHalfLayout,
      RequestLayouts.SecondHalfLayout,
      RemarksLayout,
      {
        type: 'moreActions',
        align: 'left',
        width: 80,
        visible: ({ navigation }) => {
          const { selectedTab } = navigation.getTabScreenState();
          return selectedTab === 'pending';
        },
        actions: [
          {
            text: 'Approve',
            type: 'link',
            visible: () => getPermission(
              permissions.departments.views.approveRejectAttendanceRequests
                .permissions.approveRejectAttendanceRequests.value,
            ),
            link: ({ item }) => ({
              view: ROUTES.approveRequestConfirmationModal.name,
              params: {
                item,
                reloadEvent: 'AllList',
                updates: {
                  status: 'approve',
                },
                model: MODELS.ATTENDANCE_REQUESTS,
                title: 'Approve Attendance Request',
                message: 'Do you want to Approve this Attendance Request?',
                postMessage: 'Attendance Request has been approved successfully.',
              },
            }),
          },
          {
            text: 'Reject',
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.rejectRequestConfirmationModal.name,
              params: {
                item,
                reloadEvent: 'AllList',
                updates: {
                  status: 'reject',
                },
                model: MODELS.ATTENDANCE_REQUESTS,
                title: 'Reject Attendance Request',
                message: 'Do you want to Reject this Attendance Request?',
                postMessage: 'Attendance Request has been rejected successfully.',
                visible: () => getPermission(
                  permissions.departments.views.approveRejectAttendanceRequests
                    .permissions.approveRejectAttendanceRequests.value,
                ),
              },
            }),
          },
        ],
      },
    ],
  },
});

export const LOPSheet = List({
  listVersion: 2,
  uri: () => {
    const user = getUser();
    const relationValue = {
      model: 'Resource',
      relation: 'lopSheetMonthlyAttendanceDetail',
      _id: user && user.employee && user.employee._id,
    };

    return {
      model: 'MonthlyAttendance',
      query: {
        id: 'lopSheetmonthlyAttendanceQuery',
        sort: {
          _id: -1,
        },
        relationValue,
      },
    };
  },
  reloadEvent: 'LOPSheet',
  sm: {},
  md: {
    columns: [
      {
        width: 190,
        ...employeeNameLayout,
      },
      // MonthLayout,
      YearLayout,
      // LOPLayout,
      TotalSalaryDaysLayout,
      presentDaysLayout,
      HolidaysLayout,
      compensatoryOffLayout,
      ewdPayableLayout,
      ewdAdjustWithLeavesLayout,
    ],
  },
});

export const LeaveRegisterPayroll = List({
  listVersion: 2,
  uri: ({ user }) => {
    const relationValue = {
      model: 'Resource',
      relation: 'leaveRegisterMonthlyAttendanceDetail',
      _id: user && user.employee && user.employee._id,
    };
    return userLeaveLopSheetUri({
      id: 'leaveRegistermonthlyAttendanceQuery',
      relationValue,
    });
  },
  reloadEvent: 'LeaveRegisterPayroll',
  sm: {},
  md: {
    columns: [
      employeeNameLayout,
      employeeLayout,
      // MonthLayout,
      YearLayout,
      AvailableLayout,
      accuredLeaveLayout,
      ConsumedLayout,
      BalanceLayout,
    ],
  },
});
