import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text, View } from '../../../../../app-components';
import {
  searchInput,
} from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import ViewAssetTabs from './components/tabs';
import vars from '../../../../../theme/vars';
import { Form } from '../../../../../app-components/form/Form';
import { fetch } from '../../../../../AppServices';
import { ViewDepartmentAssetItem } from '../../../../../Queries/asset';

const ViewAsset = (props) => {
  const { navigation: { state: { params: { item: { item } = {} } = {} } = {} } = {} } = props;

  const [quantityAssignedDep, setDepartmentStock] = useState();

  const fetchDepartmentStocksAvailable = useCallback(async () => {
    const fetchedStockDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'assetDepartmentInventoryStocksDataList',
            addOnFilter: {
              item: { _id: item?._id },
            },
          },
          model: MODELS.ASSET_DEPARTMENT_STOCKS,
        },
      },
    });
    let stocks = 0;
    const data = fetchedStockDetails?.data;
    data.forEach(({ stockAvailable }) => {
      stocks += stockAvailable;
    });
    setDepartmentStock(stocks);
    return fetchedStockDetails;
  }, [item]);
  useEffect(() => {
    fetchDepartmentStocksAvailable();
  }, [fetchDepartmentStocksAvailable]);
  // if (!quantityAssignedDep) return <ActivityIndicator />;
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{ flex: 1.2, overflow: 'hidden' }}>
        <Form
          {...props}
          type="standard"
          uri={ViewDepartmentAssetItem}
          editable={false}
          closeView={1}
          reloadEvent={`reload${MODELS.ASSET_ITEM}`}
          lg={{
            formGroups: [
              {
                columnsPerRow: 5,
                fieldVariant: 'filled',
                label: 'asset.title.itemDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        field: 'item.name',
                        label: 'asset.labels.assetName'.getLabel(),
                      },
                      {
                        type: 'text',
                        field: 'item.category.name',
                        label: 'asset.labels.category'.getLabel(),
                      },
                      {
                        type: 'text',
                        field: 'item.subCategory.name',
                        label: 'asset.labels.subCategory'.getLabel(),
                      },
                      {
                        type: 'number',
                        field: 'item.reorderLevel',
                        label: 'asset.labels.reorderLevel'.getLabel(),
                      },
                      {
                        type: 'number',
                        field: 'stockAvailable',
                        label: 'asset.labels.quantityInStocks'.getLabel(),
                      },
                      {
                        type: 'number',
                        render: () => <Text style={{ ...vars.headings.h9 }}>{quantityAssignedDep}</Text>,
                        label: 'asset.labels.quantityAssignedDep'.getLabel(),
                      },
                      {
                        type: 'status',
                        field: 'item.isActive',
                        label: 'asset.labels.isActive'.getLabel(),
                        container: 'topLabel',
                      },
                      {
                        type: 'textArea',
                        field: 'item.description',
                        label: 'asset.labels.description'.getLabel(),
                        minHeight: 80,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'asset.title.manufacturerSupplierDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 0 },

                columns: [
                  {
                    field: 'item.rateContract',
                    fieldType: 'nested',
                    editable: false,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      listProps: {
                        skipTableHeaderOnNoData: false,
                        columns: [
                          searchInput({
                            field: 'supplier',
                            variant: 'filled',
                            header: 'asset.labels.supplier'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_SUPPLIER,
                            query: 'masterDataList',
                          }),
                          searchInput({
                            field: 'manufacturer',
                            variant: 'filled',
                            header: 'asset.labels.manufacturer'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_MANUFACTURER,
                            query: 'masterDataList',
                          }),
                          {
                            type: 'date',
                            field: 'rateContractValidity',
                            variant: 'filled',
                            header: 'asset.labels.rateContractValidity'.getLabel(),
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          }}
        />
        ;
      </View>
      <View style={{ flex: 2, overflow: 'hidden' }}>
        <ViewAssetTabs {...props} />
      </View>
    </View>
  );
};

export default ViewAsset;
