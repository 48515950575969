import React from 'react';

import {
  cancelButton,
  save,
  StandardButton,
  Text,
} from '../../../app-components';
import { ROUTES } from '../../../Lib/constants';
import EmployeeScheduleList from './EmployeeScheduleList';
import EditEmployeeSchedule from './editEmployeeSchedule';
import ViewEmployeeSchedule from './ViewEmployeeSchedule';
import AddEmployeSchedule from './AddEmployeeSchedule';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import vars from '../../../theme/vars';

const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

const employeeScheduleScreens = {
  [ROUTES.employeeScheduleList.name]: {
    screen: EmployeeScheduleList,
  },
  // edit
  [ROUTES.editEmployeeSchedule.name]: {
    screen: EditEmployeeSchedule,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  // add
  [ROUTES.addEmployeeSchedule.name]: {
    screen: AddEmployeSchedule,
    expanded: true,
    headerActions: headerActionsForEditForm,
    // modal: true,
  },
  // view
  [ROUTES.viewEmployeeSchedule.name]: {
    screen: ViewEmployeeSchedule,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.firstName}
          {' '}
          {item?.lastName}
        </Text>
      );
    },
    headerActions: [
      cancelButton,
      {
        render: () => (
          <WithPermission
            access_key={permissions.mdm.views.addSchedule.permissions.addSchedule.value}
          >
            <StandardButton
              label={'employeeSchedule.buttons.addSchedule'.getLabel()}
            />
          </WithPermission>

        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.addEmployeeSchedule.name,
          push: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
    ],
  },
};

export default employeeScheduleScreens;
