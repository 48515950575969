/* eslint-disable camelcase */
import React from 'react';

import {
  searchInput,
  multiSearchAvatarInput,
  multiSearchInput,
} from '../../../../autoSuggestions';
import {
  NestedAction,
  NestedTable,
} from '../../../../app-components/input-components/nestedTable/NestedTable';
import { addBlueSmallIcon } from '../../../../images';
import { Text, View } from '../../../../app-components';
import vars from '../../../../theme/vars';

export const CompensationFormLayouts = {
  name: {
    type: 'text',
    label: 'Name',
    placeholder: 'Template Name',
    field: 'name',
  },
  description: {
    type: 'richText',
    label: 'Description',
    placeholder: 'Description',
    field: 'description',
  },
  Employee: searchInput({
    suggestionField: 'name',
    query: 'resourceSuggestions',
    searchField: 'name',
    field: 'employee_id',
    model: 'Resource',
    placeholder: 'Employee',
    secondaryField: 'official_email_id',
    label: 'Employee',
  }),
  FromDate: {
    label: 'From Date',
    type: 'date',
    field: 'from_date',
    placeholder: 'From Date',
  },
  ToDate: {
    label: 'To Date',
    type: 'date',
    field: 'to_date',
    placeholder: 'To Date',
  },
  CTCAmt: {
    field: 'ctc_amount',
    label: 'CTC(Per Month)',
    type: 'number',
    placeholder: 'CTC(Per Month)',
  },
  PackageReviewDate: {
    field: 'package_review_date',
    label: 'Package Review Date',
    type: 'date',
    placeholder: 'Package Review Date',
  },
  FixedSalary: {
    field: 'fixed_salary',
    label: 'Fixed',
    type: 'number',
    placeholder: 'Fixed',
  },
  Incentive: {
    field: 'incentive_salary',
    label: 'Incentive',
    type: 'number',
    placeholder: 'Incentive',
  },
  MarkSalary: {
    field: 'mark_salary',
    type: 'checkBox',
    label: 'Unapprove Package',
  },
  EarningBaseComponent: {
    field: 'attendance_base_components',
    fieldType: 'nested',
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow }) => ({
        leftAction: {
          icon: addBlueSmallIcon,
          text: 'New Row',
          onPress: addRow,
        },
      }),
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'salaryComponent',
            searchField: 'name',
            field: 'salary_component_id',
            model: 'SalaryComponent',
            placeholder: 'Salary Component',
            header: 'Salary Component',
          }),
          {
            type: 'number',
            placeholder: 'Percentage',
            field: 'ctc_percentage',
            header: 'Percentage',
            width: 120,
          },
          multiSearchAvatarInput({
            field: 'salary_component_id',
            query: 'salaryComponentSuggestions',
            model: 'SalaryComponent',
            placeholder: 'Dependency Field',
            header: 'Dependency Field',
            suggestionField: 'dependency_field.name',
            searchField: 'dependency_field.name',
            mandatory: true,
            editable: false,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'amount',
            header: 'Amount',
            width: 120,
          },
          {
            type: 'date',
            placeholder: 'From Date',
            field: 'from_date',
            header: 'From Date',
          },
          {
            type: 'date',
            placeholder: 'To Date',
            field: 'to_date',
            header: 'To Date',
          },
          NestedAction(),
        ],
      },
    },
  },
  PerformanceBaseComponent: {
    field: 'performance_base_components',
    fieldType: 'nested',
    Component: NestedTable,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow }) => ({
        leftAction: {
          icon: addBlueSmallIcon,
          text: 'New Row',
          onPress: addRow,
        },
      }),
      listProps: {
        md: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              width: 120,
            },
            {
              type: 'date',
              placeholder: 'From Date',
              field: 'from_date',
              header: 'From Date',
            },
            {
              type: 'date',
              placeholder: 'To Date',
              field: 'to_date',
              header: 'To Date',
            },
            NestedAction(),
          ],
        },
        lg: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              width: 120,
            },
            {
              type: 'date',
              placeholder: 'From Date',
              field: 'from_date',
              header: 'From Date',
            },
            {
              type: 'date',
              placeholder: 'To Date',
              field: 'to_date',
              header: 'To Date',
            },
            NestedAction(),
          ],
        },
      },
    },
  },
  DeductionComponent: {
    field: 'deductions_components',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow }) => ({
        leftActions: [{
          render: () => (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderRadius: 5,
              width: 120,
              height: 40,
              borderColor: 'grey',
            }}
            >
              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                {`+ ${'asset.labels.addRow'.getLabel()}`}
              </Text>
            </View>
          ),
          onPress: addRow,
        }],
      }),
      listProps: {
        lg: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'activeSalaryComponent',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
              variant: 'filled',
            },
            {
              addOnFilter: ({ data: { _parent: { deductions_components } = {} } = {} }) => {
                const supplierIds = deductions_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                  component_type: 'deduction_type',
                };
              },
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              width: 120,
              variant: 'filled',
              disabled: ({ data }) => data?.salary_component_id?.dependentComponents,
            },
            multiSearchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'dependentComponents',
              model: 'SalaryComponent',
              placeholder: 'Dependent Salary Component',
              header: 'Dependent Salary Component',
              variant: 'filled',
              disabled: true,
            }),
            {
              type: 'number',
              placeholder: 'Dependent Percentage',
              field: 'dependentPercentage',
              header: 'Dependent Percentage',
              width: 120,
              variant: 'filled',
              disabled: true,
            },
            {
              field: 'from_date',
              type: 'date',
              placeholder: 'From Date',
              header: 'From Date',
              variant: 'filled',
            },
            {
              field: 'to_date',
              type: 'date',
              placeholder: 'To Date',
              header: 'To Date',
              variant: 'filled',
            },
            {
              type: 'text',
              header: 'Reason',
              placeholder: 'Reason',
              field: 'reason',
              variant: 'filled',
              allowWhiteSpaces: true,
            },
            NestedAction(),
          ],
        },
      },
    },
  },
};
