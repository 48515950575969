import { bgs, colors } from '../../theme/colors';
import vars from '../../theme/vars';

const { tableHoverColor } = colors;
const { borderColor20 } = bgs;

const notficiationTableStyle = {
  rowStyle: {
    cardWrapperStyle: {
      marginTop: 2,
      marginBottom: 2,
    },
    rowContainerStyle: {
      minHeight: 48,
      paddingLeft: 26,
      borderBottomColor: borderColor20,
      borderBottomWidth: 1,
    },
    rowSelectedContainerStyle: {
      backgroundColor: vars.colors.secondary.color4,
      borderRadius: 4,
    },
    rowHoverContainerStyle: {
      cursor: 'pointer',
      backgroundColor: vars.colors.secondary.color4,
      borderRadius: 4,
    },
    rowColumnContainerStyle: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...vars.headings.h9,
      color: vars.colors.grey.color1,
      numberOfLines: 1,
      paddingRight: 8,
    },
    hoverActionsWrapperStyle: {
      top: 1,
      bottom: 1,
      right: 1,
      backgroundColor: tableHoverColor,
    },
    selectedHoverActionsWrapperStyle: {
      backgroundColor: tableHoverColor,
    },
    hoverActionsContainerStyle: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 8,
    },
  },
};

export const notificationCardTheme = {
  containerStyle: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: 2,
    paddingRight: 7,
  },
  imgcontainerStyle: {
    alignItems: 'center',
    width: 48,
    height: 48,
    marginRight: 18,
    justifyContent: 'center',
  },
  imgViewStyle: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: { objectFit: 'contain' },

  infocontainerStyle: {
    flex: 1,
    overflow: 'hidden',
  },
  headerViewStyle: {
    marginVertical: 14,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-between',
    justifyContent: 'space-between',
  },
  headerTextStyle: {
    ...vars.headings.h12,
    maxWidth: '80%',
    color: vars.colors.grey.color1,
    numberOfLines: 1,

  },
  messageViewStyle: {
    paddingBottom: 2,
    flexDirection: 'row',
    alignItems: 'flex-between',
    justifyContent: 'space-between',
    flex: 1,

  },
  messageTextStyle: {
    ...vars.headings.h14,
    maxWidth: '90%',
    color: vars.neutrals.text2,
    numberOfLines: 2,
    marginBottom: 3,
    verticalAlign: 'Center',
  },
  dateAndActioncontainerStyle: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },

  actionViewStyle: {
    flex: 1,
  },
  dateViewStyle: { alignItems: 'flex-end', flex: 1 },
  dateTextStyle: {
    ...vars.headings.h16,
    color: vars.colors.grey.color4,
    paddingRight: 5,
    Weight: '500',
  },
};

export default notficiationTableStyle;
