import { manazeV6Colors } from '../../theme/manazeV6Colors';
import vars from '../../theme/vars';

import { Headline11, Headline15 } from '../../theme/Headlines';

const {
  // eslint-disable-next-line camelcase
  neutral_4,
} = manazeV6Colors;

const defaultInputStyle = {
  outline: 'none',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
};

const errorMessageStyle = {
  errorMessageContainerStyle: { paddingTop: 3 },
  errorMessageIconStyle: {
    paddingRight: 3,
  },
  errorMessageStyle: {
    ...Headline11,
    color: vars.colors.error,
  },
};

const helperTextStyle = {
  helperTextContainerStyle: { paddingTop: 3 },
  helperTextIconStyle: {
    paddingRight: 3,
  },
  helperTextStyle: {
    ...Headline11,
    color: neutral_4,
  },
};

const labelStyle = {
  labelStyle: {
    ...vars.headings.h13,
    color: vars.neutrals.placeholder,
  },
  floatingLabelStyle: {
    ...Headline15,
    color: vars.neutrals.placeholder,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: vars.neutrals.placeholder,
  },
};

export const TokenInputTheme = {
  style: {
    justifyContent: 'flex-end',
    width: 142,
    height: 24,
    border: '1px solid',
    borderColor: vars.colors.grey.color3,
    borderRadius: 4,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: vars.colors.error,
  },
  activeStyle: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // border: '1px solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 12,
    ...vars.headings.h13,
    color: vars.colors.grey.color3,
  },
  labelContainerStyle: {
    top: 17,
    left: 0,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    alignItems: 'flex-end',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    justifyContent: 'flex-end',
  },
};

