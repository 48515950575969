import React, { useCallback, useEffect } from 'react';
import {
  FlatList, Text, View,
} from '../../../app-components';
import { gpsStore } from '../../../AppServices';
import TableNoData from '../../../Components/TableNoData';
import vars from '../../../theme/vars';

const LiveConsultationPatientList = (props) => {
  const {
    speciality,
    fetchPatientData,
    LastPatient,
    loading,
    upcoming,
  } = props;

  const subscribeToConsultationsUpdates = useCallback((specialityId) => {
    gpsStore.subscribeEvent({
      event: `consultationList-${specialityId}-updates`,
      callback: () => fetchPatientData({ data: { speciality } }),
    });
  }, [fetchPatientData, speciality]);

  const unSubscribeToConsultationsUpdates = useCallback((specialityId) => {
    gpsStore.unsubscribeEvent({
      event: `consultationList-${specialityId}-updates`,
      callback: () => fetchPatientData({ data: { speciality } }),
    });
  }, [fetchPatientData, speciality]);

  useEffect(() => {
    subscribeToConsultationsUpdates(speciality?._id);
    return () => {
      unSubscribeToConsultationsUpdates(speciality?._id);
    };
  }, [speciality._id, subscribeToConsultationsUpdates, unSubscribeToConsultationsUpdates]);

  if (loading) return <TableNoData />;

  return (
    <View style={{ justifyContent: 'space-between', flex: 1 }}>
      <FlatList
        style={{
          marginTop: 20,
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'space-between',
          flexWrap: 'wrap',
          overFlow: 'none',
          marginRight: 20,
        }}
        data={LastPatient}
        renderItem={({ item }) => (
          <View
            style={{
              borderWidth: 0,
              // borderColor: vars.colors.grey.color3,
              backgroundColor: vars.colors.secondary.color1,
              borderRadius: 15,
              flex: 1,
              marginLeft: 20,
              flexDirection: 'column',
              minWidth: 180,
              marginBottom: 18,
            }}
          >
            <View
              style={{
                flex: 0.3,
                justifyContent: 'center',
                flexDirection: 'column',
                // marginTop: 10,
                borderBottomWidth: 1,
                borderColor: vars.colors.grey.color3,
              }}
            >
              {/* <Text
                style={{
                  // color: vars.colors.primary.color1,
                  textAlign: 'center',
                  fontSize: 28,
                }}
              >
                Room No.
              </Text> */}
              <Text
                style={{
                  // color: vars.colors.error,
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: 45,
                  marginTop: 5,
                }}
              >
                Room No.
                {' '}
                {item?.room?.name}

              </Text>

            </View>
            <View
              style={{
                flex: 0.7,
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {/* <Text
                style={{
                  // color: vars.colors.primary.color1,
                  textAlign: 'center',
                  fontSize: 28,
                }}
              >
                Token
              </Text> */}
              <Text
                style={{
                  // color: vars.colors.primary.color1,
                  color: vars.colors.warning,
                  textAlign: 'center',
                  fontSize: 100,
                  // marginTop: 5,
                }}
              >
                {item?.currentConsultation[0]?.token || '--'}
              </Text>

            </View>
          </View>
        )}
      />
      <View
        style={{
          fontSize: 20,
          marginLeft: 20,
        }}
      >
        Upcoming tokens
      </View>
      <FlatList
        style={{
          marginTop: 20,
          flexDirection: 'row',
          flexWrap: 'wrap',
          overFlow: 'none',
          marginLeft: 20,
        }}
        data={upcoming}
        renderItem={({ item }) => (
          <View
            style={{
              borderWidth: 0,
              borderRadius: 15,
              flex: 1,
              height: 85,
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: 110,
              marginBottom: 18,
              boxShadow: '5px 5px 5px 5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <View
              style={{
                flex: 0.7,
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >

              <Text
                style={{
                  // color: vars.colors.primary.color1,
                  color: vars.colors.warning,
                  textAlign: 'center',
                  fontSize: 52,
                  // marginTop: 5,
                }}
              >
                {item?.token || '--'}
              </Text>

            </View>
          </View>
        )}
      />

    </View>
  );
};

export default LiveConsultationPatientList;
