import React from 'react';
import { Stack } from '@applane/react-stack-navigator-v1';
import { Platform, View } from '@applane/react-core-components';
import { setLocationView } from './Location';
import { stackTheme, stackMobileTheme } from '../../theme/stackTheme';
import { getActiveMQ, getResolvedMQProps } from '../../BreakPoints';
import {
  stackHeaderTheme,
  stackModalHeaderTheme,
  stackFooterTheme,
  stackModalFooterTheme,
  stackModalHeaderMobileTheme,
  stackHeaderMobileTheme,
} from '../../theme/toolBarTheme';
import { Snackbar } from '@applane/react-snack-bar';
import { isMobile } from '../UtilityFunctions';
import ToolBar from '../toolBar/ToolBar';
import { ScreenDataProvider } from './ScreenDataContext';

let theme = isMobile ? stackMobileTheme : stackTheme;

const showBackPressMessage = () => {
  Snackbar.show({
    text: 'Double Press to Exit!',
    duration: Snackbar.LENGTH_SHORT,
  });
};

export default (views, stackOptions) => {
  class StackNavigator extends React.Component {
    state = {};

    setActiveScreenState = ({ key, value }) => {
      this.setState(({ activeScreenState, activeScreenKeys }) => {
        activeScreenKeys = activeScreenKeys ? [...activeScreenKeys] : [];
        activeScreenState = { ...activeScreenState };
        let indexOf = activeScreenKeys.indexOf(key);
        if (value === undefined) {
          if (indexOf >= 0) {
            activeScreenKeys.splice(indexOf, 1);
          }
          delete activeScreenState[key];
        } else {
          if (indexOf === -1) {
            activeScreenKeys.push(key);
          }
          activeScreenState[key] = value;
        }
        return {
          activeScreenState,
          activeScreenKeys,
        };
      });
    };

    getActiveScreenState = (key) => {
      let { activeScreenKeys, activeScreenState } = this.state;
      if (!key) {
        key =
          activeScreenKeys && activeScreenKeys.length
            ? activeScreenKeys[activeScreenKeys.length - 1]
            : void 0;
      }
      if (key && activeScreenState) {
        return activeScreenState[key];
      }
    };

    onRouteChange = ({ views }) => {
      if (views && views.length) {
        let view = views[0];
        const { navigationKey } = stackOptions || {};
        if (view) {
          const {
            routeName,
            navigation: { action },
          } = view;
          if (Platform.OS === 'web' && navigationKey === 'main') {
            setLocationView({ view: routeName, action });
          }
        }
      }
    };
    renderHeader = (props) => {
      const { modal, header, ...rest } = props;
      if (header) {
        return (
          <ToolBar
            {...(modal
              ? (isMobile && stackModalHeaderMobileTheme) ||
                stackModalHeaderTheme
              : (isMobile && stackHeaderMobileTheme) || stackHeaderTheme)}
            {...header}
            {...rest}
          />
        );
      } else {
        return null;
      }
    };
    renderFooter = (props) => {
      const { modal, footer, ...rest } = props;
      if (footer) {
        return (
          <ToolBar
            {...(modal ? stackModalFooterTheme : stackFooterTheme)}
            {...rest}
            {...footer}
          />
        );
      } else {
        return null;
      }
    };

    render() {
      return (
        <ScreenDataProvider
          value={{
            activeScreenState: this.state.activeScreenState,
            setActiveScreenState: this.setActiveScreenState,
            getActiveScreenState: this.getActiveScreenState,
          }}>
          <Stack
            getActiveMQ={getActiveMQ}
            getResolvedMQProps={getResolvedMQProps}
            {...theme}
            {...stackOptions}
            {...this.props}
            routes={views}
            onRouteChange={this.onRouteChange}
            renderHeader={this.renderHeader}
            renderFooter={this.renderFooter}
            showBackPressMessage={showBackPressMessage}
          />
        </ScreenDataProvider>
      );
    }
  }
  return StackNavigator;
};
