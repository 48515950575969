import { TabNavigator } from '../../../../../../app-components';
import { MODELS } from '../../../../../../Lib/constants';
import { countUri } from '../../../../../../Queries/countUri';
import TransferRequestList from './TransferRequestList';
import tabTheme from '../../../Themes/nestedTabTheme';
import Repair from './Repair';

const OtherRequestsTabs = TabNavigator({
  theme: tabTheme,
  tabs: {
    Repair: {
      label: 'Repair',
      screen: Repair,
      screenProps: {
        model: MODELS.ASSET_PURCHASE,
        id: 'assetInventoryRequestDataList',
        addOnFilter: { type: 'repair' },
      },
      countUri: countUri({
        model: MODELS.ASSET_PURCHASE,
        id: 'assetInventoryRequestDataList',
        filter: { type: 'repair' },
      }),
    },
    transfer: {
      label: 'Transfer',
      screen: TransferRequestList,
      countUri: countUri({
        model: MODELS.ASSET_TRANSFER_REQUESTS,
        id: 'assetTransferRequestsDataList',
      }),
    },
  },
});
export default OtherRequestsTabs;
