export const EditCity = ({ navigation }) => {
  return {
    query: {
      model: 'cities',
      id: 'masterDataList',
      relationValue: {
        model: 'cities',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'cities',
  };
};
