import React from 'react';

import {
  View, Text, save, Toast,
} from '../../../../../../../../app-components';
import { NestedAction, NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { invoke, submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import {
  advice, anyOtherComplaint,
  anySignificantEvent, appetite,
  bloodInfusionNotes,
  bloodTransfusionNotes,
  changeBedCategory, doctorNotes, entScreen, notes, Orthopedic, paExamination,
  physioAdvice, progressDate, progressEnt, progressGc,
  progression, pvExamination, remark,
  roomCategories, sleep, vitals,
  vitalsBowelSound,
  vitalsDistalNeurologicalStatus,
  vitalsDistalPulse, vitalsDrainOutput,
  vitalsRtOutput,
} from '../../../fieldConfiguration';
import { viewProgressReportUri } from '../../../../../../../../Queries/progressReport';

const IpdEditProgress = (props) => {
  const { navigation: { state: { params: { dataParams } } } } = props;
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Edit Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          closeView={1}
          uri={viewProgressReportUri(filter)}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          defaultValues={{
            progressDate: new Date(),
          }}
          mandatory={{
            vitalsP: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsBP: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsRR: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            progressGc: progressGc[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsTemperature: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            // doctorOnRound: entScreen[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsInTake: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsUrinalOutput: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsBowelSound: vitalsBowelSound[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsDrainOutput: vitalsDrainOutput[dataParams?.filters?.specialityFilter] ? 1 : 0,
            vitalsRtOutput: vitalsRtOutput[dataParams?.filters?.specialityFilter] ? 1 : 0,
            // treatment: {
            //   route: 1,
            //   dose: 1,
            //   frequency: 1,
            // },
          }}
          computations={{
            self: {
              transferTo: {
                compute: () => ({
                  set: { roomCategories: '', transferBedNumber: '', assignTO: '' },
                }),
                onChange: ['transferTo'],
              },
              changeBedCategory: {
                compute: () => ({
                  set: { changeBedNumber: '', transferBedNumber: '', assignTO: '' },
                }),
                onChange: ['changeBedCategory'],
              },
            },
          }}

          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            // const tempData = updates?.treatment?.insert;

            // if (tempData) updates.treatment = tempData;
            // updates.treatment = treatmentInvoke.result.map((item) => ({ _id: item._id }));
            const newUpdate = {
              ...updates,
              ...paramsData,
            };

            const submitAPI = submit({
              model: MODELS.IPD_PROGRESS_REPORT,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Progress has been Edited successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            validations: {
              vitalsP: ({ data }) => validateInputLength(data?.vitalsP, 1, 4),
              vitalsBP: ({ data }) => validateInputLength(data?.vitalsBP, 1, 4),
              vitalsRR: ({ data }) => validateInputLength(data?.vitalsRR, 1, 5),
              vitalsTemperature: ({ data }) => validateInputLength(data?.vitalsTemperature, 1, 4),
              vitalsInTake: ({ data }) => validateInputLength(data?.vitalsInTake, 1, 4),
              vitalsUrinalOutput: ({ data }) => validateInputLength(data?.vitalsUrinalOutput, 1, 4),
              vitalsDrainOutput: ({ data }) => validateInputLength(data?.vitalsDrainOutput, 1, 5),
              vitalsRtOutput: ({ data }) => validateInputLength(data?.vitalsRtOutput, 1, 5),
              route: ({ data }) => validateInputLength(data?.route, 1, 20),
              dose: ({ data }) => validateInputLength(data?.dose, 1, 5),
              frequency: ({ data }) => validateInputLength(data?.frequency, 1, 50),
              description: ({ data }) => validateInputLength(data?.description, 1, 100),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: true,
                            visible: progressDate[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'radioGroup',
                            field: 'progressGc',
                            container: 'topLabel',
                            label: 'GC',
                            mandatory: true,
                            visible: progressGc[dataParams?.filters?.specialityFilter],
                            options: [
                              { value: 'Fair', label: 'Fair' },
                              { value: 'Good', label: 'Good' },
                              { value: 'Poor', label: 'Poor' },
                            ],
                          },
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'doctorOnRound',
                            label: 'Doctor On Round',
                            variant: 'filled',
                            keyFiled: 'firstName',
                            suggestionField: 'firstName',
                            valueField: 'firstName',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            mandatory: true,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: vitals[dataParams?.filters?.specialityFilter],
                    // || entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: vitals[dataParams?.filters?.specialityFilter],
                    //  || entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsInTake',
                            label: 'InTake',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsUrinalOutput',
                            label: 'Urinal Output',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'radioGroup',
                            field: 'vitalsBowelSound',
                            container: 'topLabel',
                            mandatory: true,
                            label: 'Bowel Sound',
                            visible: vitalsBowelSound[dataParams?.filters?.specialityFilter],
                            options: [
                              { value: 'Present', label: 'Present' },
                              { value: 'Absent', label: 'Absent' },
                            ],
                          },
                          {
                            type: 'text',
                            field: 'vitalsDrainOutput',
                            label: 'Drain Output',
                            mandatory: true,
                            editable: true,
                            visible: vitalsDrainOutput[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsRtOutput',
                            label: 'RT Output',
                            mandatory: true,
                            editable: true,
                            visible: vitalsRtOutput[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsDistalPulse',
                            label: 'Distal Pulse',
                            mandatory: true,
                            editable: true,
                            visible: vitalsDistalPulse[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'vitalsDistalNeurologicalStatus',
                            label: 'Distal Neurological Status',
                            mandatory: true,
                            editable: true,
                            visible: vitalsDistalNeurologicalStatus[dataParams
                              ?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'advice',
                            label: 'Advice',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: advice[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'physioAdvice',
                            label: 'Physio Advice',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: physioAdvice[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'anyOtherComplaint',
                            label: 'Any Other Complaint',
                            editable: true,
                            visible: anyOtherComplaint[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'doctorNotes',
                            label: 'Doctor Notes',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: doctorNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'localSiteExamination',
                            label: 'Local Site Examination',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: Orthopedic[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'bloodInfusionNotes',
                            label: 'Blood Infusion Notes',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: bloodInfusionNotes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'P/A Examination',
                            visible: paExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'paExamination',
                            label: 'P/A Examination',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: paExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'P/V Examination',
                            visible: pvExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'pvExamination',
                            label: 'P/V Examination',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: pvExamination[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Progression',
                            visible: progression[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'progression',
                            label: 'Progression',
                            editable: true,
                            allowWhiteSpaces: true,
                            visible: progression[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Sleep',
                            visible: sleep[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'sleep',
                            label: 'Sleep',
                            editable: true,
                            allowWhiteSpaces: true,
                            visible: sleep[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Appetite',
                            visible: appetite[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'appetite',
                            label: 'Appetite',
                            editable: true,
                            allowWhiteSpaces: true,
                            visible: appetite[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Any Significant Event',
                            visible: anySignificantEvent[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'anySignificantEvent',
                            label: 'Any Significant Event',
                            editable: true,
                            allowWhiteSpaces: true,
                            visible: anySignificantEvent[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Notes',
                            visible: notes[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'notes',
                            label: 'Notes',
                            editable: true,
                            allowWhiteSpaces: true,
                            visible: notes[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              footer: ({ addRow }) => ({
                                leftActions: [{
                                  render: () => (
                                    <View style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      width: 120,
                                      height: 40,
                                      borderColor: vars.colors.secondary.color2,
                                    }}
                                    >
                                      <Text style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.secondary.color2,
                                      }}
                                      >
                                        {`+ ${'Add New Item'.getLabel()}`}
                                      </Text>
                                    </View>
                                  ),
                                  onPress: addRow,
                                }],
                              }),
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: true,
                                    width: 250,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    mandatory: true,
                                    editable: true,
                                    width: 150,
                                  },
                                  {
                                    type: 'number',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    mandatory: true,
                                    editable: true,
                                    width: 100,
                                  },
                                  {
                                    type: 'number',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: true,
                                    mandatory: true,
                                    width: 200,
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'specialInstructions',
                                    label: 'Special Instruction',
                                    allowWhiteSpaces: true,
                                    variant: 'filled',
                                    editable: true,
                                    width: 312,
                                  },
                                  NestedAction(),
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'transferTo',
                            label: 'Transfer To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.WARDS,
                            query: 'masterDataList',
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'roomCategories',
                            label: 'Rooms Category',
                            placeholder: 'Rooms Category',
                            suggestionField: 'name',
                            model: MODELS.ROOM_CATEGORY,
                            query: 'masterDataList',
                            editable: true,
                            visible: roomCategories[dataParams?.filters?.specialityFilter],
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'transferBedNumber',
                            label: 'Rooms',
                            placeholder: 'Rooms',
                            suggestionField: 'name',
                            model: MODELS.ROOM,
                            query: 'masterDataList',
                            editable: true,
                            visible: roomCategories[dataParams?.filters?.specialityFilter],
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'assignTO',
                            label: 'Assign To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.EMPLOYEE,
                            query: 'getEmployeeDetails',
                          },
                          {
                            addOnFilter: ({ data } = {}) => ({
                              speciality: { _id: data?.transferTo?._id },
                            })
                            ,
                          }),
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                    //  || entScreen[dataParams?.filters?.specialityFilter],
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Change Bed Category </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    visible: entScreen[dataParams?.filters?.specialityFilter],
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'changeBedCategory',
                            label: 'Category',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.ROOM_CATEGORY,
                            query: 'masterDataList',
                            visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                            mandatory: true,
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'changeBedNumber',
                            label: 'Bed Number',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.ROOM,
                            visible: changeBedCategory[dataParams?.filters?.specialityFilter],
                            query: 'masterDataList',
                          },
                          {
                            addOnFilter: ({ data } = {}) => ({
                              changeBedCategory: { _id: data?.changeBedCategory?._id },
                            }),
                          }),
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => 'Remark',
                            visible: remark[dataParams?.filters?.specialityFilter],
                            // || entScreen[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'text',
                            field: 'remark',
                            label: 'Remark',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: remark[dataParams?.filters?.specialityFilter],
                            // || entScreen[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Blood Transfusion Notes',
                            visible: bloodTransfusionNotes[dataParams?.filters?.specialityFilter],
                            // || entScreen[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'textArea',
                            minHeight: 80,
                            field: 'bloodTransfusionNotes',
                            label: 'Blood Transfusion Notes',
                            allowWhiteSpaces: true,
                            editable: true,
                            visible: bloodTransfusionNotes[dataParams?.filters?.specialityFilter],
                            // || entScreen[dataParams?.filters?.specialityFilter],
                          },
                          {
                            render: () => 'Progress',
                            visible: progressEnt[dataParams?.filters?.specialityFilter],
                          },
                          {
                            type: 'textArea',
                            minHeight: 80,
                            field: 'progressEnt',
                            label: 'Progress',
                            editable: true,
                            visible: progressEnt[dataParams?.filters?.specialityFilter],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const EditProgress = (props) => (<IpdEditProgress {...props} />);
export default EditProgress;
