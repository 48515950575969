import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS, modules } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'asset.labels.category'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
    mandatory: true,
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'asset.labels.description'.getLabel(),
    minHeight: 100,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'asset.labels.status'.getLabel(),
    container: 'topLabel',
  },
];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

export default MasterForm({
  model: MODELS.PHARMACY_CATEGORY,
  formFields,
  validations,
  uniqueValidationMessage: 'asset.messages.categoryAlreadyExists'.getLabel(),
  submitMessage: 'asset.messages.categoryAddedSuccessfully'.getLabel(),
  defaultValues: () => ({
    isActive: false,
    module: modules.asset,
  }),
});
