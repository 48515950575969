import React, { useCallback, useEffect, useState } from 'react';
import { invoke } from '../../../../../AppServices';
import { FormField, Toast } from '../../../../../app-components';
import { getErrorString } from '../../../../../Lib/helpers';

const DiagnosisInput = (props) => {
  const {
    patientDetail, consultationId, diagnosis,
  } = props;

  const [value, setValue] = useState(diagnosis);

  useEffect(() => {
    setValue(diagnosis);
  }, [consultationId]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleBlur = useCallback(async () => {
    try {
      await invoke({
        id: 'addDiagnosis',
        paramValue: {
          diagnosis: value,
          item: patientDetail,
          consultationId,
        },
      });
    } catch (err) {
      setValue(null);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [consultationId, patientDetail, value]);

  return (
    <FormField
      value={value}
      placeholder="Diagnosis"
      label="Diagnosis"
      topLabel="Diagnosis"
      variant="filled"
      name="Diagnosis"
      type="textArea"
      minHeight={100}
      onBlur={handleBlur}
      onChange={handleChange}
      inputProps={{
        type: 'textArea',
      }}
    />
  );
};

export default DiagnosisInput;
