import React from 'react';
import { upperFirst } from 'lodash';

import { MODELS, modules, ROUTES } from '../../../../Lib/constants';
import MasterList from '../../../../Wired/Master/MasterList';
import FTS from '../../../../Components/TableFilters/FtsSearch';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import permissions from '../../../../Lib/permissions';
import { CategoryList } from '../../../../Queries/category';
import { Text } from '../../../../app-components';
import vars from '../../../../theme/vars';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'purchase.labels.category'.getLabel(),
    type: 'text',
    field: 'name',
    value: ({ item }) => upperFirst(item?.name),
    sortable: 'name',
  },
  {
    header: 'purchase.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
    numberOfLines: 2,
    width: 300,
  },
  {
    header: 'purchase.labels.subCategory'.getLabel(),
    type: 'link',
    align: 'center',
    value: ({ item }) => item?.subCategory_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item = {} } = props;
        return {
          view: ROUTES.subCategoryListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              category: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'purchase.labels.contractual'.getLabel(),
    render: ({ item }) => {
      const { isContractual } = item;
      if (isContractual) {
        return (
          <Text style={{ ...vars.headings.h9 }}>
            Contractual
          </Text>
        );
      }
      return (
        <Text style={{ ...vars.headings.h9 }}>
          Non-Contractual
        </Text>
      );
    },
    sortable: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.PHARMACY_CATEGORY}
    uri={() => CategoryList({ filter: { module: modules.purchase } })}
    editFormId={ROUTES.editPurchaseCategory.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views
      .modifyPurchaseCategory.permissions.modifyPurchaseCategory.value}
    header={({ aggregates }) => ({
      title: (
        <HeaderTitleWithCount
          count={aggregates?._count}
          label={'purchase.title.categoryManagement'.getLabel()}
        />
      ),
      actions: [(actionProps) => <FTS {...actionProps} action={{ exp: ['name'] }} />],
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewPurchaseCategory.name,
        params: { item },
      },
    })}
  />
);
