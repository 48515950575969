import { isFunction } from 'lodash';
import { MODELS } from '../Lib/constants';

export const AssetCentralInventoryItemListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataListForTable',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_ITEM,
});
export const EditAssetItem = ({ navigation }) => ({
  query: {
    model: MODELS.ASSET_ITEM,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.ASSET_ITEM,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ASSET_ITEM,
});

export const ViewDepartmentAssetItem = ({ navigation }) => ({
  query: {
    model: MODELS.ASSET_DEPARTMENT_STOCKS,
    id: 'assetDepartmentInventoryStocksDataList',
    relationValue: {
      model: MODELS.ASSET_DEPARTMENT_STOCKS,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ASSET_DEPARTMENT_STOCKS,
});
export const EditAsset = ({ navigation }) => ({
  query: {
    model: MODELS.ASSET,
    id: 'assetInventoryDataList',
    addOnFilter: { _id: navigation?.state?.params?.item?._id },
    limit: 1,
  },
  aggregate: true,
  model: MODELS.ASSET,
});
export const AssetPurchaseRequestsListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryRequestDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_PURCHASE,
});
export const InChargeAssetList = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET,
});
export const AssetDetails = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET,
});
export const AssetServiceDetailUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetServiceDetail',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.ASSET,
});
export const AssetLoanOutHistoryUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryLoanedOutHistoryList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_LOANED_OUT_HISTORY,
});
export const AssetItemMonthlyRemarksUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'monthWiseHistoryDetail',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_MONTHLY_REMARKS,
});
export const AssetRepairHistoryUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetRepairHistory',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSETS_PURCHASE,
});
export const SupervisorAssetList = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET,
});
export const AssetPurchaseEntityRequestListUri = ({ filter }) => ({
  query: {
    id: 'assetInventoryRequestDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_PURCHASE,
});

export const AssetPurchaseEntityRequestUri = ({ filter }) => ({
  query: {
    id: 'purchaseRequestAssetList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PURCHASE_REQUEST,
});
export const AssetIndentRequestsListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryIndentDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_INDENT,
});
export const AssetMSITransferRequestsListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetTransferRequestsDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_TRANSFER_REQUESTS,
});
export const AssetDepartmentInventoryItemListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetDepartmentInventoryStocksDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.ASSET_DEPARTMENT_STOCKS,
});

export const EditAssetIndentRequestStatus = ({ navigation, callback }) => ({
  query: {
    model: MODELS.ASSET_INDENT,
    id: 'assetInventoryIndentDataList',
    afterFetch: (data) => {
      if (isFunction(callback)) {
        callback(data);
      }
    },
    relationValue: {
      model: MODELS.ASSET_INDENT,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ASSET_INDENT,
});
export const EditAssetTransferRequestStatus = ({ navigation }) => ({
  query: {
    model: MODELS.ASSET_TRANSFER_REQUESTS,
    id: 'assetTransferRequestsDataList',
    relationValue: {
      model: MODELS.ASSET_TRANSFER_REQUESTS,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ASSET_TRANSFER_REQUESTS,
});
export const AssetLoanedOutListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'assetInventoryLoanedOutHistoryList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.ASSET_LOANED_OUT_HISTORY,
});
