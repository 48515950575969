/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React from 'react';
import {
  FormField,
  StandardButton,
  View,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import ListActionModal from '../../Components/ListActionModal';
import { detailIcon } from '../../images';
import vars from '../../theme/vars';
import { MODELS } from '../../Lib/constants';
import { submit } from '../../AppServices';

const PatientMeasurmentForVaccine = (props) => {
  const { eventDispatcher } = props;

  return (
    <ListActionModal
      icon={detailIcon}
      description="Input the height, weight and the hc of the Patient."
      title="Growth Details"
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          reloadEvent="reloadVaccinations"
          mandatory={{
            patientWeight: 1,
            patientHeight: 1,
            patientHeadCircumfrence: 1,
          }}
          closeView={1}
          validations={{
            patientHeight: ({ data }) => {
              if (data?.patientHeight <= 0) {
                return 'This field cannot be less than or equal to zero';
              }
            },
            patientWeight: ({ data }) => {
              if (data?.patientHeight <= 0) {
                return 'This field cannot be less than or equal to zero';
              }
            },
            patientHeadCircumfrence: ({ data }) => {
              if (data?.patientHeight <= 0) {
                return 'This field cannot be less than or equal to zero';
              }
            },

          }}
          onSubmit={(props) => {
            const { data, updates } = props;
            delete updates?._id;
            const { navigation: { state: { params: { item: { _id } } } } } = props;
            data._id = _id;
            const submitAPI = submit({
              model: MODELS.VACCINATION,
              ...props,
            });
            const result = submitAPI({ data, updates });
            eventDispatcher.notify('reloadVaccinations');
            return result;
          }}
          submitMessage="Patient measurement has been added successfully."
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >

              <View style={{ flexDirection: 'row', marginTop: 5 }} />
              <FormField
                type="number"
                label="HEIGHT (IN CM)"
                variant="filled"
                field="patientHeight"
                mandatory
              />
              <View style={{ marginTop: 14 }}>
                <FormField
                  type="number"
                  label="WEIGHT (IN KG)"
                  variant="filled"
                  field="patientWeight"
                  mandatory
                />
              </View>
              <View style={{ marginTop: 14 }}>
                <FormField
                  type="number"
                  label="HEAD CIRCUMFERENCE (IN CM)"
                  variant="filled"
                  field="patientHeadCircumfrence"
                  mandatory
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label="Confirm"
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default PatientMeasurmentForVaccine;
