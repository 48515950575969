import React from 'react';
import { auctionItemStatus, bloodStockStatus, MODELS } from '../../Lib/constants';
// eslint-disable-next-line import/no-cycle
import ToggleFilter from './ToggleFilter';

const options = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'InActive',
  },
];

const countUri = (props) => {
  const { id, model, addOnFilter = {} } = props;

  return {
    query: {
      id,
      addOnFilter,
    },
    fetchCount: true,
    model,
  };
};
export const MamcToggleFilter = (props) => (
  <ToggleFilter
    options={options}
    countUri={countUri}
    field="isActive"
    {...props}
  />
);

const PharmacyInventoryOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'In-Stocks',
    label: 'In Stock',
  },
  {
    value: 'Low-on-Stock',
    label: 'Low On Stock',
  },
  {
    value: 'Out-of-Stock',
    label: 'Out of Stock',
  },
];
const SalaryComponentOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'earning_type',
    label: 'Earning',
  },
  {
    value: 'deduction_type',
    label: 'Deduction',
  },
];

const AuctionInventoryOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: auctionItemStatus.inStock,
    label: 'In Stock',
  },
  {
    value: auctionItemStatus.soldOut,
    label: 'Sold Out',
  },
];

export const PharmacyDepartmentInventoryToggleFilter = (props) => (
  <ToggleFilter
    options={PharmacyInventoryOptions}
    countUri={countUri}
    field="stockStatus"
    {...props}
  />
);
export const SalaryComponentToggleFilter = (props) => (
  <ToggleFilter
    options={SalaryComponentOptions}
    countUri={countUri}
    field="component_type"
    {...props}
  />
);
const AssetInventoryOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'In-Stocks',
    label: 'In Stock',
  },
  {
    value: 'Low-on-Stock',
    label: 'Low On Stock',
  },
  {
    value: 'Out-of-Stock',
    label: 'Out of Stock',
  },
];

const AssetInventoryInChargeOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'In-Storage',
    label: 'In Storage',
  },
  {
    value: 'Loaned-Out',
    label: 'Loaned Out',
  },
  {
    value: 'Issued',
    label: 'Issued',
  },
  {
    value: 'In-Repair',
    label: 'In Repair',
  },
  {
    value: 'In-Service',
    label: 'In Service',
  },
  {
    value: 'inAuction',
    label: 'In-Auction',
  },
];

export const AssetDepartmentInventoryToggleFilter = (props) => (
  <ToggleFilter
    options={AssetInventoryOptions}
    countUri={countUri}
    field="stockStatus"
    {...props}
  />
);

export const PharmacyCentralInventoryToggleFilter = (props) => (
  <ToggleFilter
    options={PharmacyInventoryOptions}
    countUri={countUri}
    field="stockStatus.stockStatus"
    {...props}
  />
);
export const AssetCentralInventoryToggleFilter = (props) => (
  <ToggleFilter
    options={AssetInventoryOptions}
    countUri={countUri}
    field="stockStatus.stockStatus"
    {...props}
  />
);

export const AssetInventoryInChargeToggleFilter = (props) => {
  const { id, model, filter } = props;

  return (
    <ToggleFilter
      options={AssetInventoryInChargeOptions}
      countUri={countUri({ addOnFilter: filter, id, model })}
      field="assetStatus"
      {...props}
    />
  );
};

const statusFilter = [
  // eslint-disable-next-line no-void
  {
    value: bloodStockStatus.all.value,
    label: bloodStockStatus.all.label,
  },
  {
    value: bloodStockStatus.inStock.value,
    label: bloodStockStatus.inStock.label,
  },
  {
    value: bloodStockStatus.lowOfStock.value,
    label: bloodStockStatus.lowOfStock.label,
  },
  {
    value: bloodStockStatus.outOfStock.value,
    label: bloodStockStatus.outOfStock.label,
  },
];

export const BloodInventoryToggleFilter = (props) => (
  <ToggleFilter
    countUri={countUri}
    field="status"
    options={statusFilter}
    {...props}
  />
);

const PharmacyInventoryRequestOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'raised',
    label: 'Pending',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
];

export const PharmacyInventoryRequestToggleFilter = (props) => (
  <ToggleFilter
    options={PharmacyInventoryRequestOptions}
    countUri={countUri}
    field="requestStatus"
    {...props}
  />
);
const AssetInventoryRequestOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'raised',
    label: 'Pending',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
];
const VaccinationHistoryRequestOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'not-appeared',
    label: 'Not Appeared',
  },
  {
    value: 'vaccinated',
    label: 'Vaccinated',
  },
  {
    value: 'nurse-assigned',
    label: 'Nurse Assigned',
  },
];

export const AssetInventoryRequestToggleFilter = (props) => (
  <ToggleFilter
    options={AssetInventoryRequestOptions}
    countUri={countUri}
    field="requestStatus"
    {...props}
  />
);
export const VaccinationHistoryToggleFilter = (props) => (
  <ToggleFilter
    options={VaccinationHistoryRequestOptions}
    countUri={countUri}
    field="status"
    {...props}
  />
);
const PharmacyInventoryIndentRequestOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'Stock-Provided',
    label: 'Stock-Provided',
  },
  {
    value: 'rejected',
    label: 'No Stock Provided',
  },
];
const AssetInventoryIndentRequestOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: 'Stock-Provided',
    label: 'Stock-Provided',
  },
  {
    value: 'rejected',
    label: 'No Stock Provided',
  },
];
const VaccineScheduleOptions = [
  // eslint-disable-next-line no-void
  { value: void 0, label: 'All' },
  {
    value: { isVaccineGlobal: true, isVaccine: true },
    label: 'Global',
  },
  {
    value: { isVaccineRecommended: true, isVaccine: true },
    label: 'Recommended',
  },
];
export const PharmacyInventoryIndentRequestToggleFilter = (props) => (
  <ToggleFilter
    options={PharmacyInventoryIndentRequestOptions}
    countUri={countUri}
    field="requestStatus"
    {...props}
  />
);
export const AssetInventoryIndentRequestToggleFilter = (props) => (
  <ToggleFilter
    options={AssetInventoryIndentRequestOptions}
    countUri={countUri}
    field="requestStatus"
    {...props}
  />
);

export const VaccineScheduleToggleFilter = (props) => (
  <ToggleFilter
    options={VaccineScheduleOptions}
    countUri={countUri}
    field="isVaccine"
    {...props}
  />
);

export const AuctionInventoryToggleFilter = (props) => (
  <ToggleFilter
    options={AuctionInventoryOptions}
    countUri={countUri}
    field="status"
    {...props}
  />
);

const VitalSignOptions = [
  { value: "vitals", label: 'Vitals' },
  {
    value: 'input-output',
    label: 'Input Output',
  },
  {
    value: 'blood-sugar',
    label: 'Blood Sugar',
  },
];

export const VitalSignSheetToggleFilter = (props) => {
  return (
    <ToggleFilter
      options={VitalSignOptions}
      countUri={{
        query: {
          id: 'IPDVitalsList',
        },
        fetchCount: true,
        model: MODELS.IPD_VITALS_SIGN_SHEET,
      }}
      field="toggle"
      {...props}
    />
  );
};
