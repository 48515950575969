import React, { useCallback, useState } from 'react';

import {
  View,
  Text,
  Image,
  LargeButton,
  Toast,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import ListActionModal from '../../Components/ListActionModal';

import { barcode, greenTick, personIcon } from '../../images';
import { MODELS } from '../../Lib/constants';
import vars from '../../theme/vars';
import { fetch } from '../../AppServices';
import ExistingPatientNameSearchForm from './Components/SearchExistingPatientOnNameForm';
import ExistingPatientUHIDSearchForm from './Components/SearchExistingPatientOnUHIDForm';

const ExistingPatientContent = (props) => {
  const { navigation: { state: { params: { routes } } = {} } = {} } = props;
  const { navigation } = props;
  const [isUHIDEnabled, setUHIDEnabled] = useState(true);
  const [isNameEnabled, setNameEnabled] = useState(false);

  const handleUHID = useCallback(() => {
    setUHIDEnabled(true);
    setNameEnabled(false);
  }, []);

  const handleName = useCallback(() => {
    setNameEnabled(true);
    setUHIDEnabled(false);
  }, []);

  const setDefaultScreenStateOnPatientsList = ({
    navigation: patientsListScreenNavigation,
  }) => {
    const {
      state: {
        params: { searchData: { relationName, patientName, relation } } = {},
      } = {},
    } = patientsListScreenNavigation;

    let dataParams = {};

    if (patientName) {
      dataParams = {
        ftsFilter: {
          value: patientName,
          regExpFilter: { patientName: { $regex: patientName, $options: 'i' } },
        },
      };
      if (relationName) {
        dataParams = {
          ftsFilter: {
            value: patientName,
            regExpFilter: {
              ...dataParams?.ftsFilter?.regExpFilter,
              relationName: { $regex: relationName, $options: 'i' },
              relation: { $regex: relation, $options: 'i' },
            },
          },
          filters: {
            relation: { filter: { value: relation }, value: relation },
          },
        };
      }
    }

    return { dataParams };
  };

  const handleSearchPatient = useCallback(
    ({ data: { patientName, relationName } = {} } = {}) => {
      navigation.push({
        view: routes?.listRoute,
        params: {
          searchData: {
            patientName,
            relationName,
          },
        },
        routeOptions: {
          defaultScreenState: setDefaultScreenStateOnPatientsList,
        },
      });
    },
    [navigation],
  );

  const handleSearchPatientByUHID = useCallback(
    async (data) => {
      const { data: { UHID: uhid } = {} } = data;
      if (!uhid) {
        Toast.show({
          message: 'Error',
          description: 'patient.messages.enterUHIDToSearch'.getLabel(),
          type: 'error',
          position: 'top',
          direction: 'right',
        });
      } else {
        const patientInfo = await fetch({
          uri: {
            props: {
              query: {
                id: 'patientDataList',
                addOnFilter: {
                  uhid,
                },
              },
              model: MODELS.PATIENTS,
            },
          },
        });

        if (patientInfo?.data.length > 0) {
          navigation.push({
            view: routes?.viewRoute,
            params: {
              _id: patientInfo?.data[0] && patientInfo?.data[0]._id,
              patient: patientInfo?.data[0],
            },
          });
        } else {
          Toast.show({
            message: 'Error',
            description: 'patient.messages.incorrectUHID'.getLabel(),
            type: 'error',
            position: 'top',
            direction: 'right',
          });
        }
      }
    },
    [navigation],
  );

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          display: 'block',
          textAlign: 'center',
        }}
      >
        <Image source={barcode} />
      </View>
      <View
        style={{
          justifyContent: 'center',
          marginTop: 20,
          flexDirection: 'row',
        }}
      >
        <Text
          style={{
            padding: '6px 12px 6px 12px',
            ...vars.headings.h13,
            color: vars.colors.secondary.color2,
          }}
        >
          {'patient.labels.scanner'.getLabel()}
        </Text>
        <Image source={greenTick} />
      </View>
      <View
        style={{
          marginTop: 25,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            marginLeft: 4,
            display: 'flex',
          }}
        >
          <LargeButton
            label="UHID"
            onClick={handleUHID}
            width={96}
            borderRadius={4}
            color={
              isUHIDEnabled
                ? vars.colors.secondary.color2
                : vars.colors.grey.color8
            }
            labelStyle={{
              color: isUHIDEnabled
                ? vars.colors.white
                : vars.colors.grey.color3,
            }}
            hoverColor=""
          />
        </View>
        <View
          style={{
            marginLeft: 4,
            display: 'flex',
          }}
        >
          <LargeButton
            label="Name"
            onClick={handleName}
            width={96}
            borderRadius={4}
            color={
              isNameEnabled
                ? vars.colors.secondary.color2
                : vars.colors.grey.color8
            }
            labelStyle={{
              color: isNameEnabled
                ? vars.colors.white
                : vars.colors.grey.color3,
            }}
            hoverColor=""
          />
        </View>
      </View>
      {isUHIDEnabled ? (
        <Form
          defaultValues={() => ({
            relation: 'S/O',
          })}
          onSubmit={handleSearchPatientByUHID}
          bodyContainerStyle={{
            backgroundColor: vars.colors.white,
            flex: 1,
            justifyContent: 'space-between',
            marginTop: 20,
            marginBottom: 30,
          }}
          {...props}
        >
          <ExistingPatientUHIDSearchForm
            isUHIDEnabled={isUHIDEnabled}
            {...props}
          />
        </Form>
      ) : (
        <Form
          defaultValues={() => ({
            relation: 'S/O',
          })}
          onSubmit={handleSearchPatient}
          bodyContainerStyle={{
            backgroundColor: vars.colors.white,
            flex: 1,
            justifyContent: 'space-between',
            marginTop: 20,
            marginBottom: 30,
          }}
          mandatory={{
            patientName: 'Patient name is mandatory',
          }}
          {...props}
        >
          <ExistingPatientNameSearchForm
            isUHIDEnabled={isUHIDEnabled}
            {...props}
          />
        </Form>
      )}
    </View>
  );
};

const ExistingPatientModal = (props) => (
  <ListActionModal
    icon={personIcon}
    title={'patient.title.existingPatient'.getLabel()}
    content={<ExistingPatientContent {...props} />}
    {...props}
  />
);
export default ExistingPatientModal;
