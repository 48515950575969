import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const AddPatientOTListForm = (props) => (
  <ListActionModal
    title="New Patient"
    description="Logged Ot list for the patients."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'save',
            }),
          ],
        }}
        closeView={1}
        reloadEvent={`reload${MODELS.IPD_OT_LIST}`}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
          };
          const submitAPI = submit({
            model: MODELS.IPD_OT_LIST,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        submitMessage={'asset.messages.itemAddedSuccessfully'.getLabel()}
        mandatory={{
          _id: 1,
          otNumber: 1,
          operationType: 1,
        }}
        computations={{
          self: {
            patientUHID: {
              compute: (data) => ({ set: { patientName: data?.patientUHID?.patientName } }),
              onChange: ['patientUHID'],
            },
          },
        }}
        lg={{
          validations: {
            otNumber: ({ data }) => validateInputLength(data?.otNumber, 1, 10),
            operationType: ({ data }) => validateInputLength(data?.operationType, 1, 50),
          },
          formGroups: [
            {
              columnsPerRow: 2,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'patientUHID',
                      label: 'Patient UHID',
                      suggestionField: 'uhid',
                      model: MODELS.PATIENTS,
                      query: 'patientDataList',
                    }),
                    {
                      type: 'text',
                      field: 'patientName',
                      label: 'Patient Name',
                      editable: true,
                    },
                    {
                      type: 'number',
                      field: 'otNumber',
                      label: 'OT Number',
                      mandatory: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'operationType',
                      label: 'Type Of Operation',
                      mandatory: true,
                      allowWhiteSpaces: true,
                      editable: true,
                    },
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'surgeonName',
                      label: 'Surgeon Name',
                      suggestionField: 'firstName',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                    }),
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default AddPatientOTListForm;
