/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { ActivityIndicator, TabNavigator, Toast } from '../../../app-components';
import { LoadingButton } from '../../../app-components/buttons/Buttons';
import { getErrorString } from '../../../Lib/helpers';
import IncomingPatients from './ConsultedPatients';
import { invoke } from '../../../AppServices';
import ListOfPatients from './ListOfPatients';
import vars from '../../../theme/vars';

const SessionNavigation = TabNavigator({
  tabs: {
    ConsultedPatients: {
      label: 'Consulted Patients',
      screen: (props) => (props?.navigation?._parent().getScreenState()?.sessionQueue?._id
        ? (
          <IncomingPatients
            {...props}
            addOnFilter={{ status: { $in: ['assigned', 'not-appeared', 'completed'] }, sessionQueue: { _id: props?.navigation?._parent().getScreenState()?.sessionQueue?._id } }}
          />
        ) : <ActivityIndicator />),
      actions: [
        {
          render: ({ eventDispatcher }) => (
            <LoadingButton
              label="Call In Patient"
              color={vars.colors.secondary.color2}
              hoverColor={vars.colors.secondary.color2}
              width={150}
              labelStyle={{
                ...vars.headings.h7,
                color: vars.colors.white,
                numberOfLines: 1,
              }}
              loading
              onPress={async () => {
                try {
                  const result = await invoke({
                    id: 'callInPatient',

                  });

                  if (result.status === 'assigned') {
                    eventDispatcher.notify('reloadConsultedPatients');
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              }}
            />
          ),
        },
      ],
    },
    ListOfPatients: {
      label: 'List Of Patients',
      screen: (screenProps) => <ListOfPatients {...screenProps} addOnFilter={{}} />,
    },
  },
});
export default SessionNavigation;
