import React, { useState, useCallback } from 'react';

import { View, Text, Image } from '../../app-components';
import vars from '../../theme/vars';
import { arrowUpFilled, arrowDownFilled } from '../../images';

const Collapsible = ({
  title, description, rightContent, content,
}) => {
  const [height, setHeight] = useState(false);

  const toggleHeight = useCallback(() => {
    setHeight(!height);
  }, [height, setHeight]);

  return (
    <>
      <View style={{
        backgroundColor: vars.colors.white,
        borderRadius: 4,
        paddingLeft: 8,
        paddingTop: 14,
        paddingRight: 8,
        paddingBottom: 14,
      }}
      >
        <View
          style={{
            position: 'relative',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={toggleHeight}
        >
          <View style={{
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
            <View>
              <Image
                source={height ? arrowDownFilled : arrowUpFilled}

                style={{
                  objectFit: 'contains',
                  height: 8,
                  width: 17,
                }}
              />
            </View>
            <View style={{
              marginLeft: 16,
            }}
            >
              <Text
                style={{
                  ...vars.headings.h9,
                  color: vars.colors.grey1,
                }}
              >
                {title}

              </Text>
              <Text
                style={{
                  ...vars.headings.h9,
                  color: vars.colors.grey1,
                }}
              >
                {description}
              </Text>
            </View>
          </View>

          <View>
            {rightContent}
          </View>
        </View>
      </View>
      <View style={{
        padding: '0 18px',
        maxHeight: height ? 248 : 0,
        overflow: 'scroll',
        backgroundColor: vars.colors.white,
        transition: 'max-height 0.2s ease-out',
      }}
      >
        {content}
      </View>
    </>
  );
};

export default Collapsible;
