import React from 'react';
import { MODELS, opdConsultationStatus, ROUTES } from '../../../Lib/constants';
import { consultationListUri } from '../../../Queries/consultations';

import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import {
  clearSelection, DateFilter, FilterGroup, Fts, selectAll,
  showSelectedCount, StandardButton, Table, Text,
} from '../../../app-components';
import { filterActive, filterInactive } from '../../../images';
import vars from '../../../theme/vars';

const header = ({ aggregates }) => ({
  title: <HeaderTitleWithCount
    count={aggregates?._count}
    label={'consultation.title.consultedPatientHistory'.getLabel()}
  />,
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <StandardButton label={'consultation.buttons.exportToExcel'.getLabel()} />
      ),
      file: 'ConsultedPatientHistory',
      type: 'download',
      columns: [
        {
          field: {
            field: '_createdOn',
            parserFunction: 'formatDateForExcel',
          },
          header: 'Consultation Date',
        },
        {
          field: 'patient.uhid',
          header: 'UHID',
        },
        {
          field: 'patientName',
          header: 'Patient Name',
        },
        {
          field: 'consultedByName',
          header: 'Doctor Name',
        },
        {
          field: 'speciality.name',
          header: 'Speciality',
        },
        {
          field: 'status',
          header: 'Consultation Status',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.CONSULTATION,
          query: {
            id: 'consultationDataList',

          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState: { selectedIds } } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    }]),
  actions: [
    (props) => (
      <Fts
        {...props}
        action={{ exp: ['patient.patientName', 'patient.uhid'] }}
        placeholder="Search by Patient name, UHID"
      />
    ),
    <DateFilter field="_createdOn" key="_createdOn" />,
    FilterGroup({
      icon: filterInactive,
      activeIcon: filterActive,
      filters: [
        {
          type: 'autoSuggest',
          field: 'speciality',
          label: 'Speciality',
          model: MODELS.WARDS,
          id: 'masterDataList',
          searchField: 'name',
          placeholder: 'Select departments',
          sugestionField: 'name',
          primaryField: 'name',
          valueField: 'name',
        },
        {
          type: 'autoSuggestOptions',
          field: 'status',
          label: 'Consultation Status',
          options: opdConsultationStatus,
          searchField: 'label',
          primaryField: 'value',
          valueField: 'value',
        },
        {
          type: 'autoSuggest',
          field: 'consultedBy',
          label: 'Doctor',
          model: MODELS.EMPLOYEE,
          id: 'employeeLists',
          searchField: 'name',
          sugestionField: 'fullName',
          primaryField: 'fullName',
          valueField: '_id',
          addOnFilter: { department: { code: 'OPD' } },
          modifyResult: ({ data }) => {
            const newData = data.map((item) => ({
              ...item,
              fullName: `${item.firstName} ${item.lastName}`,
            }));
            return { data: newData };
          },
        },
      ],
    }),
  ],
});

const tableColumns = {
  lg: {
    header,
    columns: [
      { type: 'selection' },
      {
        header: 'consultation.headers.consultationDate'.getLabel(),
        type: 'date',
        field: '_createdOn',
        sortable: '_createdOn',
      },
      {
        header: 'consultation.headers.uhid'.getLabel(),
        type: 'text',
        field: 'patient.uhid',
      },
      {
        header: 'consultation.headers.patientName'.getLabel(),
        type: 'text',
        field: 'patientName',
        sortable: 'patientName',
      },
      {
        header: 'consultation.headers.doctorName'.getLabel(),
        field: 'consultedByName',
        sortable: 'consultedByName',
      },
      {
        header: 'consultation.headers.speciality'.getLabel(),
        type: 'text',
        field: 'speciality.name',
      },
      {
        header: 'consultation.headers.consultationStatus'.getLabel(),
        type: 'consultationStatus',
        field: 'status',
      },
      {
        header: 'consultation.headers.actions'.getLabel(),
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewOpdConsultedPatientHistory.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  uri: consultationListUri,
  ...tableColumns,
});

