import React from 'react';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { EditPurchaseDepartmentTC } from '../../../../Queries/purchaseDepartmentTC';
import ViewPurchaseDepartmentTCTabs from './components/Tabs';
import vars from '../../../../theme/vars';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'purchaseDepartmentTC.labels.purchaseDepartmentTCName'.getLabel(),
    mandatory: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'purchaseDepartmentTC.labels.description'.getLabel(),
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'purchaseDepartmentTC.labels.status'.getLabel(),
  },
];

export default MasterForm({
  uri: EditPurchaseDepartmentTC,
  formFields,
  otherFormGroups: [
    {
      fieldVariant: 'filled',
      label: 'List of Terms & Conditions',
      groups: [
        {
          columnsPerRow: 1,
          columns: [
            {
              field: 'conditions',
              fieldType: 'nested',
              Component: NestedTable,
              componentProps: {
                maxHeight: 400,
                skipTableHeaderOnNoData: true,
                listProps: {
                  hideColumnHeader: false,
                  headerRowColumnTextStyle: {
                    ...vars.headings.h13,
                    color: vars.colors.grey.color3,
                    numberOfLines: 1,
                  },
                  columns: [
                    {
                      type: 'text',
                      field: 'description',
                      variant: 'filled',
                      placeholder: 'purchaseDepartmentTC.labels.SNO'.getLabel(),
                      header: 'purchaseDepartmentTC.labels.SNO'.getLabel(),
                      value: (data) => `${data?.index + 1}`,
                    },
                    {
                      type: 'text',
                      field: 'name',
                      variant: 'filled',
                      placeholder: 'purchaseDepartmentTC.labels.name'.getLabel(),
                      header: 'purchaseDepartmentTC.labels.name'.getLabel(),
                    },
                    {
                      type: 'text',
                      field: 'description',
                      variant: 'filled',
                      placeholder: 'purchaseDepartmentTC.labels.description'.getLabel(),
                      header: 'purchaseDepartmentTC.labels.description'.getLabel(),
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  ],
  model: MODELS.PURCHASE_DEPARTMENT_TC,
  editable: false,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewPurchaseDepartmentTCTabs {...props} />
    ),
  }],
});
