import React from 'react';
import { MODELS, ROUTES } from '../../../Lib/constants';
// import permissions from '../../../Lib/permissions';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'room.labels.room'.getLabel(),
    type: 'text',
    field: 'code',
    sortable: 'code',
  },
  {
    header: 'room.labels.speciality'.getLabel(),
    type: 'text',
    field: 'speciality',
    value: ({ item }) => item?.speciality?.name || '-NA-',
    sortable: 'speciality._id',
  },
  {
    header: 'room.labels.department'.getLabel(),
    type: 'text',
    field: 'department',
    value: ({ item }) => item?.department?.name,
  },
  {
    header: 'room.labels.totalBeds'.getLabel(),
    type: 'text',
    field: 'totalBeds',
    sortable: 'totalBeds',
    value: ({ item }) => item?.totalBeds || '-NA-',
  },
  {
    header: 'Ward Category',
    type: 'text',
    field: 'roomCategories.name',
    sortable: 'roomCategories.name',
    value: ({ item }) => item?.roomCategories?.name || '-NA-',
  },
  {
    header: 'room.labels.emptyBeds'.getLabel(),
    type: 'text',
    field: 'emptyBeds',
    value: ({ item }) => item?.availableBeds || '-NA-',
  },
  {
    header: 'room.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.ROOM}
    // uri={() => RoomList({ filter: { module: modules.pharmacy } })}
    editFormId={ROUTES.editRoom.name}
    tableColumns={tableColumns}
    // access_key={permissions.mdm.views.modifyPharmacyCategory
    //   .permissions.modifyPharmacyCategory.value
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewRoom.name,
        params: { item },
      },
    })}
  />
);
