import React from 'react';

import {
  FormField, StandardButton, View,
} from '../../../../../app-components';
import { emergencyIconImage } from '../../../../../images';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import vars from '../../../../../theme/vars';
import { ROUTES } from '../../../../../Lib/constants';

const EmergencyModal = (props) => {
  const { navigation } = props;

  return (
    <ListActionModal
      icon={emergencyIconImage}
      title="Emergency"
      description="Select & confirm the status of the patient identity."
      content={(
        <Form
          {...props}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={(submitProps) => {
            const { data: { isActive } = {} } = submitProps;
            if (!isActive) {
              navigation.push({
                view: ROUTES.existingPatientModal.name,
                params: {
                  routes: {
                    listRoute: ROUTES.patientAdmissionListIPD.name,
                    viewRoute: ROUTES.viewPatientIPD.name,
                  },
                },
                modal: true,
                modalProps: {
                  autoHide: true,
                  maxHeight: '100%',
                  width: 373,
                  height: 535,
                },
              });
            } else {
              navigation.push(ROUTES.unknownPatientAdd.name);
            }
          }}
          defaultValues={() => ({
            isActive: false,
          })}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 24,
                paddingLeft: 22,
                paddingRight: 22,
              }}
            >
              <View style={{ alignItems: 'center' }}>
                <FormField
                  type="toggleSwitch"
                  container="topLabel"
                  variant="filled"
                  field="isActive"
                  offValueLabel="Known"
                  onValueLabel="Unknown"
                  tintColor={vars.colors.secondary.color2}
                  activeTintColor={vars.colors.secondary.color2}
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'asset.buttons.create'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default EmergencyModal;
