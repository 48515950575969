import React from 'react';

import { ToggleFilter } from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 15 },
  {
    header: 'employeeType.labels.employeeType'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'employeeType.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'employeeType.labels.employees'.getLabel(),
    type: 'link',
    value: ({ item: { employee_count } = {} }) => employee_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewUserListModal.name,
          modal: true,
          params: {
            item,
            description: 'employeeType.title.description'.getLabel(),
            filter: {
              employeeType: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'employeeType.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.EMPLOYEETYPE}
    editFormId={ROUTES.editEmployeeType.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyEmployeeType.permissions.modifyEmployeeType.value}
    header={{
      leftActions: [(actionProps) => <ToggleFilter {...actionProps} model={MODELS.EMPLOYEETYPE} id="masterDataListForTable" />],
    }}
  />
);
