import React from 'react';
import moment from 'moment';

import {
  close, FormHoc, save, Text, View,
} from '../../../../app-components';
import printConsultationReceipt from '../../../../Wired/ConsutationReceipt';
import { autoSuggestInput, searchInput } from '../../../../autoSuggestions';
import { submit } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { ageInput } from '../../../../compositeInputs';

const header = {
  title: 'patient.title.IPDAdmission'.getLabel(),
  subTitle: 'patient.subTitle.IPDSubtitle'.getLabel(),
  actions: [close],
};

const footer = {
  actions: [
    save,
    {
      ...save({
        label: 'patient.buttons.printIpdCard'.getLabel(),
      }),
      printReceipt: true,
    },
  ],
};

const AdmitPatientIPDForm = FormHoc({
  type: 'standard',
  closeView: 1,
  onSubmit: submit({
    model: MODELS.IPD_ADMISSION,
  }),
  submitMessage: 'patient.messages.patientAdmissionIPD'.getLabel(),
  submitNext: (props) => {
    const {
      data,
      action: { printReceipt },
      submitResult,
      navigation,
    } = props;
    if (printReceipt) {
      printConsultationReceipt({ data, submitResult }, () => {
        navigation.replace(ROUTES.patientAdmissionIPD.name);
      });
    } else {
      navigation.replace(ROUTES.patientAdmissionIPD.name);
    }
  },
  defaultValues: ({
    navigation: {
      state: {
        params: { patient },
      },
    } = {},
  }) => ({
    department: {
      code: 'IPD',
    },
    patient,
    'patient.ageType': 'Years',
  }),
  mandatory: {
    speciality: 1,
  },
  lg: {
    header,
    formGroups: [
      {
        fieldVariant: 'filled',
        groups: [
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'uploadImage',
                defaultImage: barcode,
                imageWidth: 75,
                imageHeight: 75,
                editable: false,
                width: 100,
              },
              {
                type: 'custom',
                editable: false,
                render: ({ data: { patient } }) => (
                  <View style={{ marginTop: 20 }}>
                    <Text
                      style={{
                        ...vars.headings.h13,
                        color: vars.colors.grey.color3,
                      }}
                    >
                      {'patient.labels.uhid'.getLabel()}
                    </Text>
                    <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                      {patient?.uhid}
                    </Text>
                  </View>
                ),
              },
            ],
          },
          {
            columnsPerRow: 2,
            columns: [
              {
                type: 'text',
                field: 'patient.patientName',
                label: 'patient.labels.name'.getLabel(),
                editable: false,
              },
              {
                type: 'text',
                field: 'date',
                label: 'patient.labels.date'.getLabel(),
                render: () => (
                  <Text style={{ ...vars.headings.h9 }}>
                    {moment().format('DD MMM YYYY')}
                  </Text>
                ),
                editable: false,
              },

              ageInput({
                age: {
                  type: 'number',
                  field: 'patient.age',
                  label: 'patient.labels.age'.getLabel(),
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value > 999) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                ageType: {
                  field: 'patient.ageType',
                },
              }),
              autoSuggestInput({
                label: 'patient.labels.gender'.getLabel(),
                field: 'patient.gender',
                suggestionField: 'label',
                placeholder: 'patient.placeholders.selectGender'.getLabel(),
                options: [
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'other', label: 'Other' },
                ],
                editable: false,
              }),
              {
                type: 'text',
                label: 'patient.labels.relation'.getLabel(),
                editable: false,
                render: ({ data: { patient } }) => {
                  if (patient?.relationName) {
                    return (
                      <Text style={{ ...vars.headings.h9 }}>
                        {`${patient?.relation} ${patient?.relationName}`}
                      </Text>
                    );
                  }
                  return '--';
                },
              },
              autoSuggestInput(
                {
                  type: 'autoSuggest',
                  field: 'department',
                  label: 'patient.labels.department'.getLabel(),
                  placeholder: 'patient.placeholders.selectDepartment'.getLabel(),
                  keyField: 'name',
                  valueField: 'code',
                  suggestionField: 'name',
                  model: MODELS.DEPARTMENTS,
                  query: 'masterDataList',
                  editable: false,
                },
                {
                  addOnFilter: {
                    code: 'IPD',
                  },
                },
              ),
              searchInput(
                {
                  field: 'speciality',
                  label: 'patient.labels.speciality'.getLabel(),
                  placeholder: 'patient.placeholders.selectWard'.getLabel(),
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.WARDS,
                  query: 'masterDataList',
                  searchField: 'name',
                  mandatory: true,
                  searching: true,
                },
                {
                  addOnFilter: { department: { code: 'IPD' } },
                },
              ),
              {
                type: 'text',
                field: 'remarks',
                label: 'patient.labels.remarks'.getLabel(),
                allowWhiteSpaces: true,
              },
            ],
          },
        ],
      },
    ],
    footer,
  },
});

const AdmitPatient = (props) => <AdmitPatientIPDForm {...props} />;

export default AdmitPatient;
