import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'employeeType.labels.employeeType'.getLabel(),
    mandatory: true,
    placeholder: 'masters.labels.name'.getLabel(),
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'employeeType.labels.description'.getLabel(),
    placeholder: 'masters.labels.description'.getLabel(),
    allowWhiteSpaces: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'employeeType.labels.status'.getLabel(),
    container: 'leftLabel',
  },
];

export default MasterForm({
  model: MODELS.EMPLOYEETYPE,
  defaultValues: () => ({
    isActive: true,
  }),
  submitMessage: 'employeeType.messages.submitMessage'.getLabel(),
  formFields,
  validations: {
    name: ({ data }) => validateAlphabetsWithSpaceOnly(data?.name),
  },
});
