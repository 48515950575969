import React, { useCallback, useEffect, useState } from 'react';

import {
  View, Text, save, Toast,
} from '../../../../../../../../app-components';
import { NestedAction, NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { fetch, submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { autoSuggestInput, multiAutoSuggestInput } from '../../../../../../../../autoSuggestions';

const IpdLogOrder = (props) => {
  const { navigation: { state: { params: { patient: paramsData } = {} } = {} } = {} } = props;
  const [item, setItem] = useState([]);

  const fetchItems = useCallback(async () => {
    const infoFromOperationProcedure = await fetch({
      uri: {
        props: {
          query: {
            id: 'infoForOrderScreen',
            addOnFilter: { admissionId: { _id: paramsData?._id } },
          },
          model: MODELS.OPERATION_PROCEDURE_NOTES,
        },
      },
    });
    setItem(infoFromOperationProcedure?.data);
    return infoFromOperationProcedure;
  }, []);
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);
  return (
    <ListActionModal
      {...props}
      title="Orders"
      description="Log Orders for the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_ORDERS}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          mandatory={{
            treatment: {
              drug: 1,
              route: 1,
              dose: 1,
              frequency: 1,
            },
          }}
          defaultValues={{
            dateOfOperation: new Date(),
          }}
          computations={{
            self: {
              transferTo: {
                compute: () => ({
                  set: { surgeonName: null },
                }),
                onChange: ['operation'],
              },
            },
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            const newUpdate = {
              ...updates,
              admissionID: { _id: submitProps?.navigation?.state?.params?._id },
              speciality: submitProps?.navigation?.state?.params?.dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.IPD_ORDERS,
              ...submitProps,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'dateOfOperation',
                            label: 'Date of Operation',
                            editable: true,
                          },
                          autoSuggestInput({
                            field: 'operation',
                            label: 'Name Of Operation',
                            suggestionField: 'name',
                            model: MODELS.OPERATIONS,
                            query: 'masterDataList',
                            editable: true,
                          },
                          {
                            addOnFilter: () => ({
                              _id: { $in: item?.map((eachItem) => eachItem.operationName._id) },
                            }),
                          }),
                          multiAutoSuggestInput({
                            field: 'surgeonName',
                            label: 'Name Of Surgeon',
                            suggestionField: 'name',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            editable: true,
                          },
                          {
                            addOnFilter: () => ({
                              _id: {
                                $in: item?.map((eachItem) => {
                                  for (let i = 0; i < eachItem.surgeonName.length; i++) {
                                    return eachItem.surgeonName[i]._id;
                                  }
                                }),
                              },

                            }),
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> PRE OP-ORDERS  </Text>
                            ),
                          },
                          {
                            type: 'textArea',
                            field: 'preOpOrders',
                            editable: true,
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> POST OP-ORDERS  </Text>
                            ),
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> i. DOS </Text>
                            ),
                          },
                          {
                            type: 'text',
                            field: 'postOpDo',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text> ii. DONTS </Text>
                            ),
                          },
                          {
                            type: 'text',
                            field: 'postOpDonts',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {
                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              footer: ({ addRow }) => ({
                                leftActions: [{
                                  render: () => (
                                    <View style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      width: 120,
                                      height: 40,
                                      borderColor: vars.colors.secondary.color2,
                                    }}
                                    >
                                      <Text style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.secondary.color2,
                                      }}
                                      >
                                        {`+ ${'Add New Item'.getLabel()}`}
                                      </Text>
                                    </View>
                                  ),
                                  onPress: addRow,
                                }],
                              }),
                              skipTableHeaderOnNoData: true,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    mandatory: true,
                                    editable: true,
                                    width: 250,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    mandatory: true,
                                    editable: true,
                                    width: 150,
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    mandatory: true,
                                    editable: true,
                                    width: 100,
                                  },
                                  {
                                    type: 'number',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    mandatory: true,
                                    editable: true,
                                    width: 200,
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Description',
                                    variant: 'filled',
                                    allowWhiteSpaces: true,
                                    editable: true,
                                    width: 312,
                                  },
                                  NestedAction(),
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
      )}
    />

  );
};

const LogOrder = (props) => (<IpdLogOrder {...props} />);
export default LogOrder;
