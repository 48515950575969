import React from 'react';

import { EditEmployeeMonthlySalaryRemarkList } from '../../../Queries/employeeMonthlyRemark';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import ListActionModal from '../../../Components/ListActionModal';
import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';
import {
  save,
} from '../../../app-components';

const EditRemark = (props) => {
  const { navigation: { state: { params: { _id } } } } = props;
  return (
    <ListActionModal
      title="REMARK"
      description="Edit Remark of the Employee."

      content={(
        <Form
          {...props}
          closeView={1}

          uri={EditEmployeeMonthlySalaryRemarkList(_id)}

          footer={{
            actions: [
              save,
            ],
          }}

          reloadEvent={`reload${MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK}`}

          bodyContainerStyle={{ backgroundColor: vars.colors.white }}

          onSubmit={(params) => {
            const { data, updates } = params;
            updates.month = updates?.month?.key;
            updates.year = updates?.year?.name;
            const submitAPI = submit({
              model: MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK,
            });
            const result = submitAPI({ updates, data });
            return result;
          }}

          mandatory={{
            employee: 1,
            month: 1,
            year: 1,
            remark: 1,
          }}

          {...props}

          lg={{
            formGroups: [
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      searchInput({
                        suggestionField: 'firstName',
                        query: 'getEmployeeDetails',
                        searchField: 'firstName',
                        label: 'Employee Name',
                        field: 'employee.name',
                        model: MODELS.EMPLOYEE,
                        placeholder: 'Select Employee',
                        header: 'Select Employee',
                        mandatory: true,
                        editable: false,
                      }),
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [

                      autoSuggestInput(
                        {
                          field: 'month',
                          label: 'Select Month',
                          placeholder: 'Select Month',
                          suggestionField: 'name',
                          model: MODELS.MONTHS,
                          query: 'MonthTypeSuggestions',
                          mandatory: true,
                          editable: true,

                        },
                      ),
                      autoSuggestInput(
                        {
                          field: 'year',
                          label: 'Select Year',
                          placeholder: 'Select Year',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          editable: true,
                          mandatory: true,
                        },
                      ),

                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'remark',
                        editable: true,
                        label: 'Remark',
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default EditRemark;
