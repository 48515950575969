import React from 'react';
import { ToggleFilter } from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'speciality.labels.wardName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'speciality.labels.wardId'.getLabel(),
    type: 'text',
    field: 'code',
    sortable: 'code',
  },
  {
    header: 'speciality.labels.roomsAllocated'.getLabel(),
    type: 'text',
    field: 'code',
    value: ({ item }) => item?.rooms_count?.map((room) => room.code)?.join(','),
  },
  {
    header: 'speciality.labels.department'.getLabel(),
    type: 'text',
    field: 'department',
    value: ({ item }) => item?.department?.name,
  },
  {
    header: 'speciality.labels.employees'.getLabel(),
    type: 'link',
    align: 'center',
    value: (props) => props?.item?.employee_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewUserListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              speciality: { _id: item?._id },
            },
            description: 'speciality.title.description'.getLabel(),
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'speciality.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
];

const tableColumnsAfterEditAction = [
  {
    header: 'speciality.labels.description'.getLabel(),
    type: 'text',
    position: 'center',
    width: 250,
    field: 'description',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.WARDS}
    editFormId={ROUTES.editWard.name}
    tableColumns={tableColumns}
    tableColumnsAfterEditAction={tableColumnsAfterEditAction}
    access_key={permissions.mdm.views.modifySpeciality.permissions.modifySpeciality.value}
    header={{
      leftActions: [(props) => <ToggleFilter {...props} model={MODELS.WARDS} id="masterDataListForTable" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewWard.name,
        params: { item },
      },
    })}
  />
);
