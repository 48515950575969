import moment from 'moment';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

import { SelectDate } from '@applane/react-date-input';
import { ReactFormContext } from '@applane/react-form';

import {
  StandardButton,
  Toast,
  View,
  Text,
  ScrollView,
} from '../../../../../../../app-components';
import { NumberInput } from '../../../../../../../app-components/input-components/Editors';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import { newDateInput } from '../../../../../../../compositeInputs';
import { FormField } from '../../../../../../../app-components/form/field';
import { Form } from '../../../../../../../app-components/form/Form';
import { getErrorString } from '../../../../../../../Lib/helpers';
import { fetch, invoke } from '../../../../../../../AppServices';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';
import LineChart from './LineChart';

const TemperatureOrPulseTimeChart = (props) => {
  const { eventDispatcher } = props;
  const [selectedDate, setSelectedDate] = useState();
  const [chartDetails, setChartDetails] = useState([]);
  const handleSubmit = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'ipdTemperaturePulseChart',
        paramValue: { data },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'New Entry added successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  const SubmitButton = () => {
    const formContext = useContext(ReactFormContext);
    return (
      <StandardButton
        label="Save"
        onClick={() => {
          formContext.handleSubmit();
        }}
      />
    );
  };

  const fetchChartData = useCallback(async () => {
    const fetchedChartData = await fetch({
      uri: {
        props: {
          query: {
            id: 'temperaturePulseChartIPDDataList',
            addOnFilter: {
              date: selectedDate,
            },
          },
          model: MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART,
        },
      },
    });
    setChartDetails(fetchedChartData?.data);
    return fetchedChartData;
  }, [selectedDate]);

  useEffect(() => {
    fetchChartData();
    eventDispatcher.listen(`reload${MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART}`, fetchChartData);
    return () => eventDispatcher.unlisten(`reload${MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART}`);
  }, [fetchChartData, eventDispatcher]);

  return (
    <Form
      {...props}
      reloadEvent={`reload${MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART}`}
      reloadOnChangeEvent={`reload${MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART}`}
      bodyContainerStyle={{ backgroundColor: vars.colors.white }}
      onSubmit={handleSubmit}
      type="standardShadow"
      editable={false}
      flex={false}
      scrollable
      computations={{
        self: {
          pulse: {
            compute: () => ({
              set: { date: selectedDate },
            }),
            onChange: ['pulse'],
          },
        },
      }}
      defaultValues={{
        meridiem: 'AM',
      }}
      mandatory={{
        temperature: 1,
        pulse: 1,
        hour: 1,
        minute: 1,
        meridiem: 1,
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(
                            item?.navigation?.state?.params?.patient?._createdOn,
                          ).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate).isSame(currentDate);
                          const highlightedDate = moment(currentDate).isAfter(
                            moment(admissionDate),
                          ) && moment(currentDate).isBefore(moment());
                          if (admittedDate) {
                            return (
                              <View
                                style={{
                                  width: 'auto',
                                  alignItem: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    backgroundColor: '#EFF3FB',
                                    marginBottom: -15,
                                    padding: 12,
                                  }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text
                                  style={{
                                    margin: 'auto',
                                    fontSize: 10,
                                    color: 'green',
                                    backgroundColor: '#EFF3FB',
                                    marginTop: -10,
                                    textAlign: 'center',
                                  }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View
                                style={{
                                  backgroundColor: '#EFF3FB',
                                  padding: 13,
                                }}
                              >
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View
                                style={{
                                  backgroundColor: '#4070C9',
                                  padding: 13,
                                }}
                              >
                                {date}
                              </View>
                            );
                          }
                          return <View>{date}</View>;
                        }}
                      />
                    ),
                  },
                  {
                    render: () => (
                      <ScrollView>
                        <View
                          style={{
                            alignItem: 'center',
                            position: 'relative',
                            margin: '-19px',
                            padding: '14px',
                            flexDirection: 'row',
                            // overflow: 'scroll',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text
                            style={{
                              font: 'Arial',
                              fontSize: 25,
                              padding: 16,
                            }}
                          >
                            Temperature/Pulse:
                            {'   '}
                            {moment(selectedDate).format('DD MMM YYYY')}
                            {' '}
                          </Text>

                          <FormField>
                            <SubmitButton />
                          </FormField>
                        </View>
                        <View
                          style={{
                            overflow: 'hidden',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            marginBottom: 32,
                          }}
                        >
                          <View
                            style={{
                              overflow: 'hidden',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <FormField
                              field="temperature"
                              label="Temperature"
                              placeholder="Enter temperature"
                              mandatory
                              variant="filled"
                              style={{
                                marginLeft: 10,
                                border: '1px solid',
                                borderColor: '#E0E0E0',
                                borderRadius: 4,
                                overflow: 'hidden',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <NumberInput />
                            </FormField>
                          </View>
                          <View
                            style={{
                              overflow: 'hidden',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <FormField
                              field="pulse"
                              label="Pulse"
                              placeholder="Enter pulse"
                              variant="filled"
                              mandatory
                              style={{
                                marginLeft: 10,
                                border: '1px solid',
                                borderColor: '#E0E0E0',
                                borderRadius: 4,
                              }}
                            >
                              <NumberInput />
                            </FormField>
                          </View>
                          <View
                            style={{
                              overflow: 'hidden',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <FormField
                              style={{
                                marginLeft: 10,
                                width: 320,
                                border: '1px solid',
                                borderColor: '#E0E0E0',
                                borderRadius: 4,
                              }}
                              {...newDateInput({
                                hour: { field: 'hour', label: 'Hour', mandatory: true },
                                minute: { field: 'minute', label: 'Minute', mandatory: true },
                                meridiem: { field: 'meridiem', mandatory: true },
                              })
                          }
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            height: 370,
                            width: '98%',
                            marginLeft: 10,
                            marginRight: 40,
                            background: '#EFF3FB',
                          }}
                        >
                          {chartDetails ? (
                            <LineChart
                              data={chartDetails}
                              {...props}
                              {...{ selectedDate }}
                            />
                          ) : null}
                        </View>
                      </ScrollView>
                    ),
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default TemperatureOrPulseTimeChart;
