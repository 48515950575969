import React, { useCallback } from 'react';
import { uniqueId } from 'lodash';

import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { currencyInput } from '../../../../compositeInputs';
import { searchInput } from '../../../../autoSuggestions';
import {
  save, Text, Toast, View,
} from '../../../../app-components';
import { auctionItemStatus, MODELS } from '../../../../Lib/constants';
import { setPrice } from '../../../../images';
import vars from '../../../../theme/vars';
import { invoke } from '../../../../AppServices';
import { getErrorString } from '../../../../Lib/helpers';

const getNewId = () => `new_${uniqueId()}`;

const SetPrice = (props) => {
  const handleSetPrice = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'assignPriceToMultipleAuctionItems',
        paramValue: { data },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Price has been updated in selected items successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  return (
    <ListActionModal
      icon={setPrice}
      title="Set Price"
      description="Set a price for all the selected items."
      content={(
        <Form
          {...props}
          footer={{
            actions: [
              save,
            ],
          }}
          closeView={1}
          reloadEvent={`reload${MODELS.AUCTION_ITEMS}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({ items: [{ _id: getNewId() }], currency: 'INR' })}
          onSubmit={handleSetPrice}
          mandatory={{
            currency: 1,
            price: 1,
            items: {
              item: 1,
            },
          }}
          {...props}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      currencyInput({
                        variant: 'filled',
                        mandatory: true,
                        amount: {
                          field: 'price',
                          label: 'Price',
                          placeholder: 'Enter Amount',
                        },
                        currency: {
                          field: 'currency',
                        },
                      }),
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'LIST OF ITEMS',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        field: 'items',
                        fieldType: 'nested',
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          footer: ({ addRow }) => ({
                            leftActions: [{
                              render: () => (
                                <View style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  width: 120,
                                  height: 40,
                                  borderColor: vars.colors.secondary.color2,
                                }}
                                >
                                  <Text style={{
                                    ...vars.headings.h9,
                                    color: vars.colors.secondary.color2,
                                  }}
                                  >
                                    {`+ ${'asset.labels.addRow'.getLabel()}`}
                                  </Text>
                                </View>
                              ),
                              onPress: addRow,
                            }],
                          }),
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowContainerStyle: {
                              minHeight: 32,
                              paddingLeft: 12,
                              paddingRight: 4,
                              backgroundColor: vars.colors.white,
                            },
                            columns: [
                              searchInput(
                                {
                                  field: 'item',
                                  variant: 'filled',
                                  header: 'SERIAL NO.',
                                  label: 'Select Serial No.',
                                  placeholder: 'Select Serial No.',
                                  keyField: 'name',
                                  valueField: 'name',
                                  suggestionField: 'name',
                                  model: MODELS.AUCTION_ITEMS,
                                  query: 'auctionItemList',
                                  width: 260,
                                  mandatory: true,
                                },
                                {
                                  addOnFilter: ({
                                    data:
                                      { _parent: { items = [] } = {} },
                                  } = {}) => {
                                    const itemIds = items?.filter(
                                      ({ item }) => item?.item?._id,
                                    ).map(({ item }) => item?.item?._id);
                                    return {
                                      item: { _id: { $nin: itemIds } },
                                      status: auctionItemStatus.inStock,
                                    };
                                  },
                                  modifyResult: ({ data }) => {
                                    const clonedData = data.map((item) => ({
                                      ...item,
                                      name: item?.item?.serialNumber,
                                    }));

                                    return { data: clonedData };
                                  },
                                },
                              ),
                              {
                                type: 'custom',
                                label: 'ASSET/ITEM NAME',
                                header: 'ASSET/ITEM NAME',
                                variant: 'filled',
                                editable: false,
                                field: 'item.item.name',
                                render: ({ item }) => (
                                  <View
                                    style={{
                                      flex: 1,
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                      }}
                                    >
                                      {item?.item?.item?.item?.name}
                                    </Text>
                                  </View>
                                ),
                                width: 180,
                              },
                              {
                                type: 'custom',
                                label: 'CURRENT VALUE',
                                header: 'CURRENT VALUE',
                                variant: 'filled',
                                editable: false,
                                field: 'currentValue',
                                render: ({ item }) => (
                                  <View
                                    style={{
                                      flex: 1,
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                      }}
                                    >
                                      {item?.item?.currentValue}
                                    </Text>
                                  </View>
                                ),
                                width: 180,
                              },
                              NestedAction(),
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default SetPrice;
