/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { Text } from 'react-native';
import { Table } from '../../../../app-components/table/Table';
import {
  MODELS, ROUTES, vaccinationStatus, vaccinationType,
} from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const tabListColumns = [
  { width: 14 },
  {
    header: 'vaccination.headers.vaccineName'.getLabel(),
    type: 'text',
    field: 'item.name',
  },
  {
    header: 'vaccination.headers.doseNo'.getLabel(),
    type: 'text',
    render: ({ item: { item, vaccineDoseTaken = 0, vaccinationSoFar = [] } }) => {
      if (!vaccineDoseTaken) return 1;
      const vaccinationSoFarLength = vaccinationSoFar?.length;
      if (vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase() || item?.noOfDoses === vaccineDoseTaken) {
        if (item?.noOfDoses >= vaccineDoseTaken) return vaccineDoseTaken;
      } else if (item?.noOfDoses > vaccineDoseTaken) return vaccineDoseTaken + 1;
    },
  },
  {
    header: 'vaccination.headers.doseStatus'.getLabel(),
    type: 'text',
    render: ({ item: { vaccinationSoFar = [] } }) => {
      const vaccinationSoFarLength = vaccinationSoFar?.length;
      if (vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase()) {
        return (
          <Text style={{ color: vars.colors.primary.color2 }}>
            Provided
          </Text>
        );
      }
      return (
        <Text style={{ color: vars.colors.warning }}>
          Pending
        </Text>
      );
    },
  },
  {
    header: 'Action',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>Provide</Text>
      ),
      type: 'link',
      visible: ({ item }) => {
        const { vaccinationSoFar = [], vaccineDoseTaken, item: { noOfDoses } } = item;
        const vaccinationSoFarLength = vaccinationSoFar?.length;

        if (vaccineDoseTaken === noOfDoses || vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase()) return false;
        return true;
      },
      link: ({ item }) => ({
        view: item?.item?.vaccinationType === vaccinationType.onceInLifeTime ? ROUTES.patientVaccineDetailSingleDoseModal.name : ROUTES.patientVaccineDetailRepeatedDoseModal.name,
        params: item,
      }),
    },
    {
      render: () => (
        <Text style={{ color: '#BDBDBD' }}>Provide</Text>
      ),
      type: 'link',
      visible: ({ item }) => {
        const { vaccinationSoFar = [], vaccineDoseTaken, item: { noOfDoses } } = item;

        const vaccinationSoFarLength = vaccinationSoFar?.length;
        if (vaccineDoseTaken === noOfDoses || vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase()) return true;
        return false;
      },
    }],
  },
];

const PatientDueVaccineList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      reloadEvent={`reload${MODELS.PATIENT_VACCINATION_HISTORY}`}
      columns={tableColumns}
      showActionIcon={false}
    />
  );
};
export default PatientDueVaccineList;
