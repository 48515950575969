import React from 'react';
import moment from 'moment';

import {
  FormHoc, Toast,
} from '../../app-components';
import {
  autoSuggestInput,
  multiAutoSuggestInput,
  multiSearchInput,
  searchInput,
} from '../../autoSuggestions';
import {
  validatePassword,
  validateEmail,
  validateInputLength,
  validatePinCode,
  validateMobile,
  validatePanCard,
  validFutureDate,
} from '../../Lib/helpers';
import {
  bloodGroup,
  entryLevels,
  genders,
  hardCodedData,
  maritalStatus,
  MODELS,
  ROUTES,
  states,
} from '../../Lib/constants';
import { submit } from '../../AppServices';
import vars from '../../theme/vars';
import { tabTheme } from '../../theme/tabBarTheme';
import ToastValidation from '../../Components/ToastValidations';

const errorsMapping = {
  addressLine1: 'Address line 1 is required.',
  bloodGroup: 'Blood group is required.',
  city: 'City is required.',
  department: 'Department is required.',
  dob: 'Date of birth is required.',
  employeeId: 'Employee Id of birth is required.',
  employeeType: 'Employee type is required.',
  firstName: 'First name is required.',
  gender: 'Gender is required.',
  fatherName: 'Father Name is required.',
  joiningDate: 'Joining date is required.',
  lastName: 'Last name is required.',
  mobile: 'Mobile is required.',
  noticePeriod: 'Notice Period is required.',
  panNumber: 'PAN number is required.',
  password: 'Password is required.',
  pinCode: 'Pin code is required.',
  role: 'Role is required.',
  staffType: 'Staff type is required.',
  state: 'State is required.',
  held_position: 'Held Position is required',
  retirementDate: 'Tenure/Retirement Date is required.',
  ecrNumber: 'ECR Number is required.',
  ecrPageNumber: 'ECR Page Number is required.',
  dcrNumber: 'DCR Number is required.',
};

const AddEmployeeForm = FormHoc({
  type: 'standardShadow',
  onSubmit: submit({
    model: MODELS.EMPLOYEE,
    lowerCaseFields: ['email'],
  }),
  // onSubmit: async (submitProps) => {
  //   const { updates, data } = submitProps;
  //   const newUpdate = { ...updates, isUnknown: null };
  //   console.log('😄 ~ file: AddEmployee.js ~ line 68 ~ onSubmit: ~ data', data);

  //   const submitAPI = submit({
  //     model: MODELS.EMPLOYEE,
  //     lowerCaseFields: ['email'],

  //     ...submitProps,
  //     data,
  //     updates: newUpdate,
  //   });
  //   await submitAPI({ data, updates: newUpdate });
  // },
  onValidationError: (errors) => {
    Toast.show({
      message: 'Errors',
      CustomDescription: () => (
        <ToastValidation
          errors={{ ...errors?.validationErrors, ...errors?.mandatoryErrors }}
          mapping={errorsMapping}
        />
      ),
      type: 'error',
      position: 'top',
      direction: 'right',
    });
  },
  submitNext: (submitProps) => {
    const {
      submitResult,
      navigation,
    } = submitProps;
    navigation.replace({
      view: ROUTES.salaryStructureConfirmation.name,
      params: {
        submitResult,
        description: 'Do you want to add Salary Structure?',
      },
    });
  },
  reloadEvent: `reload${MODELS.EMPLOYEE}`,
  defaultValues: () => ({
    isActive: true,
    isEPF: false,
    isNPS: false,
  }),
  computations: {
    self: {
      department: {
        compute: () => ({
          set: { speciality: null },
        }),
        onChange: ['department'],
      },
      category: {
        compute: () => ({
          set: { held_position: null },
        }),
        onChange: ['category'],
      },
      staffType: {
        compute: (data) => {
          if (data?.staffType?.code === hardCodedData.SOCIETY_SALARY_CODE) {
            return { set: { isEmployeeExGratiaAndSociety: null } };
          }
        },
        onChange: ['staffType'],
      },
      experience: {
        compute: (data) => {
          const today = moment();
          const experianceYear = moment(today).diff(data?.joiningDate, 'years');
          if (experianceYear > 0) {
            return { set: { experience: experianceYear } };
          }
          return { set: { experience: 0 } };
        },
        onChange: ['joiningDate'],

      },
    },
  },
  submitMessage: 'employee.messages.submitMessage'.getLabel(),
  uniqueValidationMessage: 'employee.messages.uniqueError'.getLabel(),
  mandatory: {
    firstName: 1,
    lastName: 1,
    mobile: 1,
    employeeId: 1,
    password: 1,
    department: 1,
    role: 1,
    employeeType: 1,
    isActive: 1,
    addressLine1: 1,
    dob: 1,
    fatherName: 1,
    gender: 1,
    pinCode: 1,
    state: 1,
    city: 1,
    panNumber: 1,
    staffType: 1,
    bloodGroup: 1,
    joiningDate: 1,
    noticePeriod: 1,
    held_position: 1,
    ecrNumber: 1,
    ecrPageNumber: 1,
    dcrNumber: 1,
    retirementDate: 1,
    gradePayLevel: 1,
    entryLevel: 1,
  },
  lg: {
    validations: {
      password: ({ data }) => validatePassword(data?.password),
      email: ({ data }) => validateEmail(data?.email),
      employeeId: ({ data }) => validateInputLength(data?.employeeId, 4, 30),
      mobile: ({ data }) => validateMobile(data?.mobile),
      firstName: ({ data }) => validateInputLength(data?.firstName, 2, 26),
      lastName: ({ data }) => validateInputLength(data?.lastName, 2, 26),
      addressLine1: ({ data }) => validateInputLength(data?.addressLine1, 0, 100),
      addressLine2: ({ data }) => validateInputLength(data?.addressLine2, 0, 100),
      pinCode: ({ data }) => validatePinCode(data?.pinCode),
      panNumber: ({ data }) => validatePanCard(data?.panNumber),
      dob: ({ data }) => validFutureDate(data?.dob, 'Date Of Birth Should be less than current Date'),
    },
    tabProps: { ...tabTheme },
    tabs: {
      PersonalDetails: {
        label: 'employee.labels.personalDetails'.getLabel(),
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'employee.labels.personalDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'firstName',
                    label: 'employee.labels.firstName'.getLabel(),
                    placeholder: 'employee.placeholders.enterName'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'lastName',
                    label: 'employee.labels.lastName'.getLabel(),
                    placeholder: 'employee.placeholders.enterName'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'fatherName',
                    label: 'Father Name',
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                  autoSuggestInput({
                    label: 'employee.labels.gender'.getLabel(),
                    field: 'gender',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectGender'.getLabel(),
                    options: genders,
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const genderId = data?.gender?._id;
                      if (genderId) {
                        return {
                          _id: { $ne: genderId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'date',
                    field: 'dob',
                    label: 'employee.labels.dob'.getLabel(),
                    placeholder: 'employee.placeholders.selectDOB'.getLabel(),
                    mandatory: true,
                    // maxDate: true,
                  },
                  autoSuggestInput({
                    label: 'employee.labels.bloodGroup'.getLabel(),
                    field: 'bloodGroup',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectbloodGroup'.getLabel(),
                    options: bloodGroup,
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const bloodGroupId = data?.bloodGroup?._id;
                      if (bloodGroupId) {
                        return {
                          _id: { $ne: bloodGroupId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    label: 'employee.labels.maritalStatus'.getLabel(),
                    field: 'maritalStatus',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectmaritalStatus'.getLabel(),
                    options: maritalStatus,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const maritalStatusId = data?.maritalStatus?._id;
                      if (maritalStatusId) {
                        return {
                          _id: { $ne: maritalStatusId },
                        };
                      }
                      return {};
                    },
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Contact Information',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'number',
                    field: 'mobile',
                    label: 'employee.labels.mobileNo'.getLabel(),
                    placeholder: 'employee.placeholders.enterMobile'.getLabel(),
                    mandatory: true,
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 10) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'text',
                    field: 'email',
                    label: 'employee.labels.email'.getLabel(),
                    placeholder: 'employee.placeholders.enterEmail'.getLabel(),
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'addressLine1',
                    label: 'employee.labels.addressLine1'.getLabel(),
                    placeholder: 'employee.placeholders.addressLine1'.getLabel(),
                    mandatory: true,
                    width: 636,
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'addressLine2',
                    label: 'employee.labels.addressLine2'.getLabel(),
                    placeholder: 'employee.placeholders.addressLine2'.getLabel(),
                    width: 636,
                    allowWhiteSpaces: true,
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'city',
                    label: 'employee.labels.city'.getLabel(),
                    placeholder: 'employee.placeholders.selectCity'.getLabel(),
                    mandatory: true,
                  },
                  searchInput({
                    type: 'autoSuggest',
                    label: 'employee.labels.state'.getLabel(),
                    placeholder: 'employee.placeholders.selectState'.getLabel(),
                    mandatory: true,
                    valueField: 'value',
                    options: states,
                    suggestionField: 'label',
                    field: 'state',
                  }),
                  {
                    type: 'number',
                    field: 'pinCode',
                    label: 'employee.labels.pinCode'.getLabel(),
                    placeholder: 'employee.placeholders.enterPincode'.getLabel(),
                    mandatory: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      RegistrationDetails: {
        label: 'employee.labels.registrationDetails'.getLabel(),
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'employee.labels.registrationDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'employeeId',
                    label: 'employee.labels.employeeId'.getLabel(),
                    placeholder: 'employee.placeholders.enterEmployeeId'.getLabel(),
                    mandatory: true,
                  },
                  multiAutoSuggestInput({
                    field: 'department',
                    variant: 'filled',
                    label: 'employee.labels.department'.getLabel(),
                    placeholder: 'employee.placeholders.selectDepartment'.getLabel(),
                    mandatory: true,
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const departmentId = data?.department?.map(({ _id }) => _id);
                      if (departmentId?.length) {
                        return {
                          _id: { $nin: departmentId },
                        };
                      }
                      return {};
                    },
                  }),
                  multiAutoSuggestInput(
                    {
                      field: 'speciality',
                      label: 'employee.labels.speciality'.getLabel(),
                      placeholder: 'employee.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      disabled: ({ data }) => !data?.department,
                    },
                    {
                      addOnFilter: ({ data } = {}) => {
                        const specialityId = data?.speciality?.map(({ _id }) => _id);
                        if (specialityId?.length) {
                          return {
                            _id: { $nin: specialityId },
                            department: { _id: { $in: data?.department?.map(({ _id }) => _id) } },
                          };
                        }
                        return {
                          department: {
                            _id: { $in: data?.department?.map(({ _id }) => _id) },
                          },
                        };
                      },
                    },
                  ),
                  multiSearchInput({
                    field: 'role',
                    label: 'employee.labels.role'.getLabel(),
                    placeholder: 'employee.placeholders.selectRole'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.ROLE,
                    query: 'masterDataList',
                    mandatory: true,
                    searching: true,
                    searchField: 'name',
                  }, {
                    addOnFilter: ({ data }) => {
                      const roleId = data?.role?.map(({ _id }) => _id);
                      if (roleId?.length) {
                        return {
                          _id: { $nin: roleId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'supervisor',
                    label: 'Supervisor',
                    placeholder: 'Supervisor',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE,
                    query: 'employeeLists',
                    visible: ({ data: { role = [] } }) => {
                      let visibility = false;
                      role.map(({ name }) => {
                        if (name === 'Incharge') {
                          visibility = true;
                        }
                        if (name === 'Supervisor') {
                          visibility = false;
                        }
                        return {};
                      });
                      return visibility;
                    },
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const supervisorId = data?.supervisor?._id;
                      if (supervisorId) {
                        return {
                          _id: { $ne: supervisorId },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'labType',
                    label: 'employee.labels.labType'.getLabel(),
                    placeholder: 'employee.placeholders.selectDepartment'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.LAB_TYPE,
                    query: 'masterDataList',
                    visible: ({ data: { department } }) => department?.some(({ code }) => code === 'LAB'),
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const labTypeId = data?.labType?._id;
                      if (labTypeId) {
                        return {
                          _id: { $ne: labTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'password',
                    field: 'password',
                    label: 'employee.labels.password'.getLabel(),
                    placeholder: 'employee.placeholders.enterPassword'.getLabel(),
                    mandatory: true,
                  },
                  autoSuggestInput({
                    field: 'staffType',
                    label: 'employee.labels.staffType'.getLabel(),
                    placeholder: 'employee.placeholders.selectStaffType'.getLabel(),
                    suggestionField: 'name',
                    keyField: 'name',
                    valueField: 'name',
                    model: MODELS.STAFFTYPE,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const staffTypeId = data?.staffType?._id;
                      if (staffTypeId) {
                        return {
                          _id: { $ne: staffTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'checkBox',
                    field: 'isEmployeeExGratiaAndSociety',
                    label: 'Is Employee is Both Ex-Gratia And Society Salary',
                    container: 'leftLabel',
                    disabled: ({ data }) => data?.staffType?.code === hardCodedData.SOCIETY_SALARY_CODE,
                  },
                  autoSuggestInput({
                    field: 'leaveType',
                    label: 'employee.labels.leave'.getLabel(),
                    placeholder: 'employee.placeholders.selectleaves'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.LEAVE_POLICY_TYPE,
                    query: 'leavePolicySuggestions',
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const leaveTypeId = data?.leaveType?._id;
                      if (leaveTypeId) {
                        return {
                          _id: { $ne: leaveTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'toggleSwitch',
                    field: 'isActive',
                    label: 'employee.labels.status'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'employee.labels.contractDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'category',
                    label: 'Category',
                    placeholder: 'Category',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEE_CATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'held_position',
                    label: 'Held Position',
                    placeholder: 'Held Position',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DESIGNATION,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const categoryId = data?.category?._id;
                      if (categoryId) {
                        return {
                          sanctionSeatsCategory: { category: { _id: { $eq: categoryId } } },
                        };
                      }
                      return {};
                    },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'current_position',
                    label: 'Current Position',
                    placeholder: 'Current Position',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DESIGNATION,
                    query: 'masterDataList',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'employeeType',
                    label: 'employee.labels.employeeType'.getLabel(),
                    placeholder: 'employee.placeholders.selectEmployeeType'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.EMPLOYEETYPE,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const employeeTypeId = data?.employeeType?._id;
                      if (employeeTypeId) {
                        return {
                          _id: { $ne: employeeTypeId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'date',
                    field: 'joiningDate',
                    label: 'employee.labels.joiningDate'.getLabel(),
                    placeholder: 'employee.placeholders.selectjoiningDate'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'bondDuration',
                    label: 'employee.labels.bondDuration'.getLabel(),
                  },
                  {
                    type: 'number',
                    field: 'noticePeriod',
                    label: 'employee.labels.noticePeriod'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'date',
                    field: 'retirementDate',
                    label: 'Tenure/Retirement Date',
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'experience',
                    disabled: true,
                    label: 'employee.labels.experience'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Employee Salary Related Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    field: 'gradePayLevel',
                    label: 'Grade Pay Level',
                    placeholder: 'Grade Pay Level',
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.GRADE_PAY_LEVEL,
                    query: 'gradePay',
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    label: 'Entry Level',
                    field: 'entryLevel',
                    suggestionField: 'label',
                    placeholder: 'Entry Levels',
                    options: entryLevels,
                    mandatory: true,
                  }),
                  {
                    type: 'date',
                    field: 'lastPromotionDate',
                    label: 'Last Promotion Date',
                  },
                  {
                    type: 'checkBox',
                    field: 'isAcp',
                    label: 'Is on ACP',
                  },
                  {
                    type: 'number',
                    field: 'currentAcp',
                    label: 'Current ACP Type',
                  },
                  {
                    type: 'date',
                    field: 'expectedAcpDate',
                    label: 'Expected ACP Date',
                  },
                ],
              },
            ],
          },
        ],
      },

      Statutory: {
        label: 'Statutory',
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'accountNumber',
                    label: 'employee.labels.account'.getLabel(),
                  },
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'bank',
                    label: 'employee.labels.bank'.getLabel(),
                    placeholder: 'employee.placeholders.bank'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.BANKS,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const bankId = data?.bank?._id;
                      if (bankId) {
                        return {
                          _id: { $ne: bankId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'text',
                    field: 'ifscCode',
                    label: 'employee.labels.ifsc'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'panNumber',
                    label: 'employee.labels.panNumber'.getLabel(),
                    placeholder: 'employee.placeholders.enterPAN'.getLabel(),
                    mandatory: true,
                    upperCase: true,
                    inputProps: {
                      maxLength: 10,
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    type: 'number',
                    field: 'aadharNumber',
                    label: 'employee.labels.aadhar'.getLabel(),
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 12) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'number',
                    field: 'dcrNumber',
                    label: 'DCR Number',
                    allowedDecimalDigits: false,
                  },
                  {
                    type: 'number',
                    field: 'ecrNumber',
                    label: 'employee.labels.ecrNumber'.getLabel(),
                    mandatory: true,
                    allowedDecimalDigits: false,
                  },
                  {
                    type: 'number',
                    field: 'ecrPageNumber',
                    label: 'employee.labels.ecrPageNumber'.getLabel(),
                    allowedDecimalDigits: false,
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'dcrNumber',
                    label: 'DCR Number',
                    allowedDecimalDigits: false,
                    mandatory: true,
                  },

                ],
              },
              {
                columns: [
                  {
                    type: 'number',
                    field: 'pranNumber',
                    label: 'NPS Number',
                    allowedDecimalDigits: false,
                    disabled: ({ data }) => !data?.isNPS,
                  },
                  {
                    type: 'checkBox',
                    field: 'isNPS',
                    showOuterLabel: false,
                  },

                  {
                    type: 'number',
                    field: 'epfNumber',
                    label: 'EPF Number',
                    disabled: ({ data }) => !data?.isEPF,
                  },
                  {
                    type: 'checkBox',
                    field: 'isEPF',
                    showOuterLabel: false,
                  },

                ],
              },
            ],
          },
        ],
      },
    },
  },
});

export default AddEmployeeForm;

