import React, { useCallback, useEffect, useState } from 'react';

import {
  FormField, Image, Toast, View,
} from '../../../../../app-components';
import { getErrorString, getString } from '../../../../../Lib/helpers';
import { Action } from '../../../../../app-components/action/Action';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { edit_icon as editIcon } from '../../../../../images';
import vars from '../../../../../theme/vars';
import { post } from '../../../../../AppServices';

const DoctorEdit = (props) => {
  const {
    queue, navigation, eventDispatcher, alreadyAssignedDoctors,
  } = props;
  const {
    doctor, _id, room: { name } = {}, session,
  } = queue;

  const [doctorName, setDoctorName] = useState();

  useEffect(() => {
    setDoctorName(getString([doctor?.firstName, doctor?.lastName]));
  }, [doctor]);

  const updateDoctor = useCallback(async (doctorId, firstName, lastName) => {
    try {
      const doctorDetails = await post({
        data: { _id },
        updates: { doctor: { _id: doctorId, firstName, lastName } },
        addOnFilter: { _id },
        model: MODELS.SESSION_QUEUE,
      });

      Toast.show({
        message: 'Success',
        description: `${firstName} ${lastName} ${'doctor.messages.doctorUpdatedSuccessfullySuffix'.getLabel()} ${name} ${'doctor.messages.doctorUpdatedSuccessfullyPrefix'.getLabel()}`,
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      eventDispatcher.notify('reloadAssistantOPDData');
      setDoctorName(getString([firstName, lastName]));
      navigation.pop();
      return doctorDetails;
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      return err;
    }
  }, [_id, eventDispatcher, name, navigation]);

  return (
    <View style={{
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: vars.colors.grey.color5,
      borderRadius: 4,
      marginTop: 14,
      marginRight: 8,
      paddingLeft: 10,
    }}
    >
      <FormField
        value={doctorName}
        label={'doctor.labels.doctorName'.getLabel()}
        type="text"
        disabled
        style={{
          borderBottomWidth: 0,
        }}
        labelContainerStyle={{ top: 14 }}
      />
      <Action
        navigation={navigation}
        action={{
          type: 'link',
          link: () => ({
            view: ROUTES.editDoctorModal.name,
            modal: true,
            params: {
              doctorName, updateDoctor, session, alreadyAssignedDoctors,
            },
            modalProps: {
              autoHide: true,
              maxHeight: '100%',
              width: 436,
              height: 405,
            },
          }),
        }}
      >
        <Image
          source={editIcon}
          style={{
            paddingTop: 7,
            paddingRight: 10,
          }}
        />
      </Action>
    </View>

  );
};
export default DoctorEdit;
