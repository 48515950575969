import React from 'react';

import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { save } from '../../../../app-components';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { autoSuggestInput } from '../../../../autoSuggestions';

const UploadBulkForm = (props) => {
  const {
    navigation: {
      state: {
        params,
      },
    },
  } = props;
  return (
    <ListActionModal
      {...props}
      title="Upload Bulk Files"
      description="Please Upload the Form Files of an Employee"
      content={(
        <Form
          {...props}
          type="standardShadow"
          closeView={1}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Upload',
              }),
            ],
          }}
          reloadEvent={`reload${MODELS.EMPLOYEE_FORM}`}
          submitMessage="files uploaded successfully."
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={submit({
            model: MODELS.FORM_BULK_UPLOAD,
          })}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h10,
                  color: vars.colors.grey.color2,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        ...autoSuggestInput(
                          {
                            field: 'financialYear',
                            label: 'Select Financial Year',
                            placeholder: 'Select Financial Year',
                            variant: 'filled',
                            suggestionField: 'name',
                            model: MODELS.FINANCIAL_YEAR,
                            query: 'financialYearSuggestions',
                            mandatory: true,
                          },
                        ),
                      },
                      {
                        type: 'file',
                        label: 'Attachments',
                        field: 'uploadedFiles',
                        mandatory: true,
                        multiple: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          UploadBulkUploadForm
        />
      )}
    />
  );
};
export default UploadBulkForm;
