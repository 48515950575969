import React from 'react';
import {
  autoSuggestFilterTheme,
  autoSuggestFilterMobileTheme,
} from '../../../theme/filterTheme';
import { renderRowStyle } from '../../../theme/editorTheme';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@applane/react-core-components';
import { arrowDownIcon, crossIcon } from '../../../images';
import { WithModal } from '@applane/react-with-modal';
import DropdownFilter from './DropdownFilter';
import {
  renderChips,
  renderAvatarChips,
  RenderRowComponent,
} from '../../input-components/autoSuggest/renderRowCard';
import { isMobile, resolveExp } from '../../UtilityFunctions';

let theme = isMobile ? autoSuggestFilterMobileTheme : autoSuggestFilterTheme;

class AutosuggestFilterWithModal extends React.Component {
  setValue = ({ value }) => {
    let {
      field,
      screenState: { dataParams } = {},
      setScreenState,
      asParam,
      asGlobalParam,
      onFilterChange,
      filterField,
      fieldType,
      filterType,
      filterTabs,
      setTabsState,
    } = this.props;
    let { filters } = dataParams || {};
    filters = {
      ...filters,
    };
    if (value === undefined || value === null) {
      delete filters[field];
    } else {
      filters[field] = {
        value,
        filter: {
          filterType: filterType || 'autoSuggest',
          filterField,
          fieldType,
          value,
          asParam,
          asGlobalParam,
        },
      };
    }
    if (onFilterChange) {
      filters = onFilterChange({ filters });
    }
    if (filterTabs && setTabsState) {
      setTabsState({
        filters,
        filtersInfo: {
          [field]: { filterTabs },
        },
      });
    } else {
      setScreenState &&
        setScreenState({
          dataParams: {
            ...dataParams,
            filters,
          },
        });
    }
  };

  reset = () => {
    this.setValue({ value: void 0 });
  };

  getDisplayValue = () => {
    let { label, text, placeholder, valueField, options } = this.props;
    let fieldValue = this.getFieldValue();
    let title = label;
    let displayValue = label || text || placeholder;
    let count = 0;
    if (fieldValue) {
      if (valueField) {
        fieldValue = resolveExp(fieldValue, valueField);
      } else if (options && options.length && options[0] && options[0].value) {
        if (Array.isArray(fieldValue)) {
          let modifiedFieldValue = [];
          for (let value of fieldValue) {
            for (let option of options) {
              if (option.value === value) {
                modifiedFieldValue.push(option.label);
                break;
              }
            }
          }
          fieldValue = modifiedFieldValue;
        } else {
          for (let option of options) {
            if (option.value === fieldValue) {
              fieldValue = option.label;
            }
          }
        }
      }
      if (fieldValue) {
        if (Array.isArray(fieldValue)) {
          if (fieldValue.length) {
            title = fieldValue.join('; ');
            count = fieldValue.length;
          }
        } else {
          title = fieldValue;
          count = 1;
        }
      }
    }
    return {
      title: title || displayValue,
      displayValue,
      count,
    };
  };

  getFieldValue = () => {
    const {
      screenState: { dataParams: { filters = {} } = {} } = {},
      field,
    } = this.props;
    return filters && filters[field]?.value;
  };

  renderRow = ({ props, ...rest }) => {
    let { showAvatar, options } = this.props;
    let { item } = rest;
    if (options && item.label) {
      item = item.label;
    }
    return (
      <RenderRowComponent
        {...rest}
        {...props}
        showAvatar={showAvatar}
        item={item}
      />
    );
  };

  renderModal = ({ frameStyle, hideModal }) => {
    const { screenState, showAvatar, ...restProps } = this.props;
    let { dropdownStyle } = restProps.autoSuggestModalTheme;
    return (
      <TouchableOpacity
        style={[dropdownStyle, isMobile ? {} : frameStyle]}
        activeOpacity={1}
        onPress={() => { }}>
        <DropdownFilter
          hideModal={hideModal}
          setValue={this.setValue}
          value={this.getFieldValue()}
          renderRow={this.renderRow}
          renderChips={props =>
            showAvatar
              ? renderAvatarChips({
                ...props,
                ...theme,
              })
              : renderChips({
                ...props,
                ...theme,
              })
          }
          {...restProps}
        />
      </TouchableOpacity>
    );
  };

  render() {
    const {
      buttonContainerStyle,
      activeButtonContainerStyle,
      valueTextStyle,
      crossImageStyle,
      imageStyle,
      valueContainer,
      countConatiner,
      countTextStyle,
      autoSuggestModalTheme,
      multiSelect,
    } = this.props;
    let { title, displayValue, count } = this.getDisplayValue();
    let { dropdownStyle } = autoSuggestModalTheme;
    return (
      <WithModal
        backdropStyle={{
          backgroundColor: isMobile ? 'rgba(52, 52, 52, 0.1)' : void 0,
        }}
        dropdownStyle={dropdownStyle}
        renderModal={this.renderModal}
        autoHide={!isMobile && !multiSelect}>
        <View
          style={{
            ...buttonContainerStyle,
            ...((count && activeButtonContainerStyle) || {}),
          }}>
          <View style={valueContainer} title={title}>
            <Text style={valueTextStyle}>{displayValue}</Text>
            {count ? (
              <View style={countConatiner}>
                <Text style={countTextStyle}>({count})</Text>
              </View>
            ) : (
              void 0
            )}
          </View>
          {count
            ? crossIcon && (
              <TouchableOpacity onPress={this.reset}>
                <Image source={crossIcon} style={crossImageStyle} />
              </TouchableOpacity>
            )
            : arrowDownIcon && (
              <Image source={arrowDownIcon} style={imageStyle} />
            )}
        </View>
      </WithModal>
    );
  }
}

export default props => {
  let { sugestionField, multiSelect } = props;

  return (
    <AutosuggestFilterWithModal
      {...renderRowStyle}
      {...theme}
      primaryField={sugestionField}
      showButton={multiSelect}
      showHeader={isMobile || multiSelect}
      modalPosition={isMobile ? 'screenBottom' : void 0}
      {...props}
    />
  );
};
