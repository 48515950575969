import React from 'react';

import { Table } from '../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import {
  AttendanceDateLayout,
  FirstHalfLayout,
  SecondHalfLayout,
} from '../../../../../../Wired/Attendance/LeaveRegister/Layouts';
import { RequestsStatusLayots } from '../../../../../../Wired/Attendance/Self/AttendanceLayouts';

const AttendanceRequests = (props) => {
  const { user } = props;

  return (
    <Table
      {...props}
      listVersion={2}
      uri={{
        query: {
          id: 'attendanceRequests',
          sort: { _id: -1 },
          addOnFilter: {
            employee: {
              _id: user?._id,
            },
          },
        },
        model: MODELS.ATTENDANCE_REQUESTS,
      }}
      reloadEvent="UserLOPDrillAttendaceList"
      hoverable={false}
      columns={[
        {
          width: 14,
        },
        AttendanceDateLayout,
        FirstHalfLayout,
        SecondHalfLayout,
        RequestsStatusLayots,
        {
          type: 'moreActions',
          align: 'left',
          width: 80,
          actions: [
            {
              text: 'Cancel Request',
              type: 'link',
              link: ({ item }) => ({
                view: ROUTES.approveRequestConfirmationModal.name,
                params: {
                  item,
                  reloadEvent: 'UserLOPDrillAttendaceList',
                  updates: {
                    status: 'cancelled',
                  },
                  model: MODELS.ATTENDANCE_REQUESTS,
                  title: 'Cancel Attendance Request',
                  message: 'Do you want to Cancel this Attendance Request?',
                  postMessage: 'Attendance Request has been cancelled successfully.',
                },
              }),
            },
          ],
        },
      ]}
    />
  );
};

export default AttendanceRequests;
