import React from 'react';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import { MODELS, modules, ROUTES } from '../../../../Lib/constants';
import permissions from '../../../../Lib/permissions';
import MasterList from '../../../../Wired/Master/MasterList';
import { SupplierList } from '../../../../Queries/supplier';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'pharmacy.labels.supplierName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'pharmacy.labels.address'.getLabel(),
    type: 'text',
    field: 'address',
    numberOfLines: 3,
    width: 400,
  },
  {
    header: 'pharmacy.labels.email'.getLabel(),
    type: 'text',
    field: 'email',
  },
  {
    header: 'pharmacy.labels.contact'.getLabel(),
    type: 'text',
    field: 'mobile',
  },
];
export default (props) => (
  <MasterList
    {...props}
    model={MODELS.PHARMACY_SUPPLIER}
    uri={SupplierList({ filter: { module: modules.pharmacy } })}
    editFormId={ROUTES.editPharmacySupplier.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyPharmacySupplierAndManufacturer
      .permissions.modifyPharmacySupplierAndManufacturer.value}
    header={({ aggregates }) => ({
      title: <HeaderTitleWithCount count={aggregates?._count} label={'pharmacy.title.supplierManagement'.getLabel()} />,
      actions: [
        (actionProps) => (<FtsSearch {...actionProps} action={{ exp: ['name'] }} />),
      ],
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewPharmacySupplier.name,
        params: { item },
      },
    })}
  />
);
