import React from 'react';
import { Text, View } from '../../../app-components';
import {
  NestedAction,
  NestedTable,
} from '../../../app-components/input-components/nestedTable/NestedTable';
import {
  autoSuggestInput, multiAutoSuggestInput, searchInput, multiSearchInput,
} from '../../../autoSuggestions';
import { levelPayOptions, MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';

export const CompensationFormLayouts = {
  name: {
    type: 'text',
    label: 'salaryCompensationTemplate.labels.name'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.templateName'.getLabel(),
    field: 'name',
    allowWhiteSpaces: true,
  },
  code: {
    type: 'text',
    label: 'salaryCompensationTemplate.labels.structure_id'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.structure_id'.getLabel(),
    field: 'code',
    mandatory: true,
  },
  status: {
    type: 'toggleSwitch',
    label: 'Status',
    field: 'isActive',
    container: 'topLabel',
  },
  level: autoSuggestInput({
    label: 'Level Type',
    field: 'level',
    suggestionField: 'label',
    options: levelPayOptions,
    mandatory: true,
  }),
  employee_type: multiAutoSuggestInput({
    field: 'employeeType',
    label: 'Employee Type',
    valueField: 'name',
    suggestionField: 'name',
    keyField: 'name',
    idField: '_id',
    model: MODELS.EMPLOYEETYPE,
    query: 'masterDataList',
    mandatory: true,
  }),
  staffType: autoSuggestInput({
    field: 'staffType',
    suggestionField: 'name',
    keyField: 'name',
    valueField: 'name',
    model: MODELS.STAFFTYPE,
    query: 'masterDataList',
    mandatory: true,
    label: 'Staff Type',
  }),
  description: {
    type: 'textArea',
    label: 'salaryCompensationTemplate.labels.description'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.description'.getLabel(),
    field: 'description',
  },
  Employee: searchInput({
    suggestionField: 'name',
    query: 'resourceSuggestions',
    searchField: 'name',
    field: 'employee_id',
    model: 'Resource',
    placeholder: 'salaryCompensationTemplate.placeholders.employee'.getLabel(),
    secondaryField: 'official_email_id',
    label: 'salaryCompensationTemplate.labels.employee'.getLabel(),
  }),
  FromDate: {
    label: 'salaryCompensationTemplate.labels.from_date'.getLabel(),
    type: 'date',
    field: 'from_date',
    placeholder: 'salaryCompensationTemplate.placeholders.from_date'.getLabel(),
  },
  ToDate: {
    label: 'salaryCompensationTemplate.labels.to_date'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.to_date'.getLabel(),
    type: 'date',
    field: 'to_date',
  },
  CTCAmt: {
    label: 'salaryCompensationTemplate.labels.ctc_amount'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.ctc_amount'.getLabel(),
    field: 'ctc_amount',
    type: 'number',
    disabled: true,
  },
  PackageReviewDate: {
    label: 'salaryCompensationTemplate.labels.package_review_date'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.package_review_date'.getLabel(),
    field: 'package_review_date',
    type: 'date',
  },
  FixedSalary: {
    label: 'salaryCompensationTemplate.labels.fixed_salary'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.fixed_salary'.getLabel(),
    field: 'fixed_salary',
    type: 'number',
  },
  Incentive: {
    label: 'salaryCompensationTemplate.labels.incentive_salary'.getLabel(),
    placeholder: 'salaryCompensationTemplate.placeholders.incentive_salary'.getLabel(),
    field: 'incentive_salary',
    type: 'number',
  },
  MarkSalary: {
    label: 'salaryCompensationTemplate.labels.mark_salary'.getLabel(),
    field: 'mark_salary',
    type: 'checkBox',
  },
  EarningBaseComponent: {
    field: 'attendance_base_components',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      maxHeight: 400,
      fieldVariant: 'filled',
      footer: ({ addRow }) => ({
        leftActions: [{
          render: () => (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderRadius: 5,
              width: 120,
              height: 40,
              borderColor: 'grey',
            }}
            >
              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                Add Row
              </Text>
            </View>
          ),
          onPress: addRow,
        }],
      }),
      listProps: {
        headerRowColumnTextStyle: {
          paddingLeft: 12,
          ...vars.headings.h13,
          color: vars.colors.grey.color3,
          numberOfLines: 1,
        },
        columns: [
          searchInput({
            variant: 'filled',
            suggestionField: 'name',
            query: 'activeSalaryComponent', // 'salaryComponentSuggestions',
            searchField: 'name',
            field: 'salary_component_id',
            model: 'SalaryComponent',
            header: 'salaryCompensationTemplate.headers.salary_component_id'.getLabel(),
            placeholder: 'salaryCompensationTemplate.placeholders.salary_component_id'.getLabel(),
          },
          {
            addOnFilter: ({ data: { _parent: { attendance_base_components } = {} } = {} }) => {
              const supplierIds = attendance_base_components?.filter(
                ({ salary_component_id }) => salary_component_id?._id,
              ).map(({ salary_component_id }) => salary_component_id?._id);
              return {
                _id: { $nin: supplierIds },
                component_type: 'earning_type',
              };
            },
          }),
          multiSearchInput({
            suggestionField: 'name',
            query: 'salaryComponent',
            searchField: 'name',
            field: 'dependentComponents',
            model: 'SalaryComponent',
            placeholder: 'Dependent Salary Component',
            header: 'Dependent Salary Component',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Dependent Percentage',
            field: 'dependentPercentage',
            header: 'Dependent Percentage',
            width: 120,
            variant: 'filled',
            disabled: true,
          },
          {
            header: 'salaryCompensationTemplate.headers.amount'.getLabel(),
            placeholder: 'salaryCompensationTemplate.placeholders.amount'.getLabel(),
            type: 'number',
            field: 'amount',
            variant: 'filled',
            width: 200,
          },
          NestedAction(),
        ],
      },
    },
  },
  PerformanceBaseComponent: {
    field: 'performance_base_components',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow }) => ({
        leftActions: [{
          render: () => (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderRadius: 5,
              width: 120,
              height: 40,
              borderColor: 'grey',
            }}
            >
              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                add Row
              </Text>
            </View>
          ),
          onPress: addRow,
        }],
      }),
      listProps: {
        headerRowColumnTextStyle: {
          paddingLeft: 12,
          ...vars.headings.h13,
          color: vars.colors.grey.color3,
          numberOfLines: 1,
        },
        md: {
          columns: [
            searchInput({
              header: 'salaryCompensationTemplate.headers.salary_component_id'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.salary_component_id'.getLabel(),
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              variant: 'filled',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
            }),
            {
              header: 'salaryCompensationTemplate.headers.amount'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.amount'.getLabel(),
              type: 'number',
              variant: 'filled',
              field: 'amount',
              width: 120,
            },
            NestedAction(),
          ],
        },
        lg: {
          columns: [
            searchInput({
              header: 'salaryCompensationTemplate.headers.salary_component_id'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.salary_component_id'.getLabel(),
              suggestionField: 'name',
              query: 'salaryComponentSuggestions',
              searchField: 'name',
              variant: 'filled',
              field: 'salary_component_id',
              model: 'SalaryComponent',
            }),
            {
              header: 'salaryCompensationTemplate.headers.amount'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.amount'.getLabel(),
              type: 'number',
              variant: 'filled',
              field: 'amount',
              width: 120,
            },
            NestedAction(),
          ],
        },
      },
    },
  },
  DeductionComponent: {
    field: 'deductions_components',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      maxHeight: 400,
      footer: ({ addRow }) => ({
        leftActions: [{
          render: () => (
            <View style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderRadius: 5,
              width: 120,
              height: 40,
              borderColor: 'grey',
            }}
            >
              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                Add Row
              </Text>
            </View>
          ),
          onPress: addRow,
        }],
      }),
      listProps: {
        headerRowColumnTextStyle: {
          paddingLeft: 12,
          ...vars.headings.h13,
          color: vars.colors.grey.color3,
          numberOfLines: 1,
        },
        sm: {
          columns: [
            searchInput({
              header: 'salaryCompensationTemplate.headers.salary_component_id'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.salary_component_id'.getLabel(),
              suggestionField: 'name',
              query: 'activeSalaryComponent',
              searchField: 'name',
              variant: 'filled',
              field: 'salary_component_id',
              model: 'SalaryComponent',
            },
            {
              addOnFilter: ({ data: { _parent: { deductions_components } = {} } = {} }) => {
                const supplierIds = deductions_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                  component_type: 'deduction_type',
                };
              },
            }),
            multiSearchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'dependentComponents',
              model: 'SalaryComponent',
              placeholder: 'Dependent Salary Component',
              header: 'Dependent Salary Component',
              variant: 'filled',
              disabled: true,
            }),
            {
              type: 'number',
              placeholder: 'Dependent Percentage',
              field: 'dependentPercentage',
              header: 'Dependent Percentage',
              width: 120,
              variant: 'filled',
              disabled: true,
            },
            {
              header: 'salaryCompensationTemplate.headers.amount'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.amount'.getLabel(),
              type: 'number',
              variant: 'filled',
              field: 'amount',
              width: 120,
            },
            NestedAction(),
          ],
        },
        lg: {
          columns: [
            searchInput({
              header: 'salaryCompensationTemplate.headers.salary_component_id'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.salary_component_id'.getLabel(),
              suggestionField: 'name',
              query: 'activeSalaryComponent',
              searchField: 'name',
              field: 'salary_component_id',
              variant: 'filled',
              model: 'SalaryComponent',
            },
            {
              addOnFilter: ({ data: { _parent: { deductions_components } = {} } = {} }) => {
                const supplierIds = deductions_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                  component_type: 'deduction_type',
                };
              },
            }),
            multiSearchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'dependentComponents',
              model: 'SalaryComponent',
              placeholder: 'Dependent Salary Component',
              header: 'Dependent Salary Component',
              variant: 'filled',
              disabled: true,
            }),
            {
              type: 'number',
              placeholder: 'Dependent Percentage',
              field: 'dependentPercentage',
              header: 'Dependent Percentage',
              width: 120,
              variant: 'filled',
              disabled: true,
            },
            {
              header: 'salaryCompensationTemplate.headers.amount'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.amount'.getLabel(),
              type: 'number',
              field: 'amount',
              variant: 'filled',
              width: 120,
            },
            {
              header: 'salaryCompensationTemplate.headers.reason'.getLabel(),
              placeholder: 'salaryCompensationTemplate.placeholders.reason'.getLabel(),
              type: 'text',
              variant: 'filled',
              field: 'reason',
              width: 300,
              allowWhiteSpaces: true,
            },
            NestedAction(),
          ],
        },
      },
    },
  },
};

