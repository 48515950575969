import React from 'react';
import { TabNavigator } from '../../../../../../../../app-components/tab/TabNavigator';
import { MODELS } from '../../../../../../../../Lib/constants';
import { countUri } from '../../../../../../../../Queries/countUri';
import ViewTabList from '../ViewDonorTabsList/viewTabList';

const ViewDonorTabs = (props) => {
  const { navigation } = props;
  const data = navigation?.state?.params?.item;

  return (
    <TabNavigator
      tabs={{
        Donor: {
          label: 'Blood Donation History',
          screen: ViewTabList,
          screenProps: {
            data,
            model: MODELS.BLOOD_DONATION,
            id: 'masterDataListForTable',
            addOnFilter: { bloodDonor: { _id: data?._id } },
          },
          countUri: countUri({
            model: MODELS.BLOOD_DONATION,
            id: 'masterDataListForTable',
            filter: { bloodDonor: { _id: data?._id } },
          }),
        },
      }}
      {...props}
    />
  );
};
export default ViewDonorTabs;
