import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import {
  FormField, Image, Text, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { MODELS, patientTypes } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { currencyInput } from '../../../../compositeInputs';
import printLabBill from '../../../../Wired/LabInvoices';
import { getString } from '../../../../Lib/helpers';

const getNewId = () => `new_${uuid()}`;

const NewBill = (props) => {
  const { navigation: { state: { params: { data: patientInfo } } } } = props;

  return (
    <Form
      closeView={1}
      containerStyle={{
        backgroundColor: vars.colors.secondary.color4,
        flex: 1,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 16,
        marginRight: 16,
      }}
      beforeSubmit={({ data, updates }) => {
        const labTestDetails = data?.labDetails?.map((item) => ({
          labType: { _id: item?.labType?._id },
          testType: { _id: item?.testType?._id },
          amount: item?.amount,
        }
        ));
        return {
          updates: {
            ...updates,
            labDetails: labTestDetails,
          },
        };
      }}
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        // const billDate = new Date(submitProps?.data?.billingDate);
        // const invoiceNo = submitProps?.data?.patientId?.uhid
        // + billDate.getFullYear() + billDate.getMonth() + billDate.getDate();
        const newUpdate = {
          ...updates,
          // invoiceNumber: invoiceNo,
          status: 'Active',
        };
        const submitAPI = submit({
          model: MODELS.LAB_BILLING,
          ...submitProps,
          data,
          updates: newUpdate,
        });
        return submitAPI({ data, updates: newUpdate });
      }}
      submitNext={({ data, submitResult }) => printLabBill({ data, submitResult })}
      submitMessage={'billing.messages.invoiceGenerateSuccessfully'.getLabel()}
      defaultValues={() => ({
        patientId: {
          _id: patientInfo[0]?._id,
          age: patientInfo[0]?.age,
          uhid: patientInfo[0]?.uhid,
          gender: patientInfo[0]?.gender,
          lastConsultation: patientInfo[0]?.lastConsultation,
          patientName: patientInfo[0]?.patientName,
          relation: patientInfo[0]?.relation,
          relationName: patientInfo[0]?.relationName,
        },
        labDetails: [{
          _id: getNewId(),
          currency: 'INR',
        }],
        billingDate: new Date(),
        discount: 0,
        billStatus: true,
      })}
      computations={{
        children: {
          labDetails: {
            self: {
              testType: {
                compute: ({ testType: { charge } }) => ({ set: { amount: charge } }),
                onChange: ['testType'],
              },
              labType: {
                compute: ({ testType: { labType } }) => ({ set: { labType: labType[0] } }),
                onChange: ['testType'],
              },
            },
          },

        },
        self: {
          discount: {
            compute: ({ patientType }) => {
              if (patientType === 'Ayushman' || patientType === 'BPL' || patientType === 'Poor' || patientType === 'Staff') {
                return { set: { discount: 100 } };
              }
              return { set: { discount: 0 } };
            },
            onChange: ['patientType', 'labType'],
          },
          amount: {
            compute: ({ labDetails = [], discount }) => {
              let totalAmount = 0;
              labDetails.map(({ amount }) => {
                totalAmount += amount;
                return amount;
              });

              // eslint-disable-next-line no-multi-assign
              totalAmount = totalAmount *= (1 - discount / 100);

              return { set: { billAmount: totalAmount } };
            },
            onChange: ['labDetails.amount', 'discount'],
          },
        },
      }}
      mandatory={{
        labDetails: {
          labType: 1,
          testType: 1,
        },
      }}
      {...props}
    >
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <View style={{ flexDirection: 'row', marginLeft: 24, marginTop: 20 }}>
          <View>
            <Image
              source={barcode}
            />
          </View>
          <View style={{ marginTop: 20, marginLeft: 24 }}>
            <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>
              {'billing.labels.uhid'.getLabel()}
            </Text>
            <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
              {patientInfo[0]?.uhid}
            </Text>
          </View>
        </View>
        <View style={{ width: '80%' }}>
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
            <View style={{ flex: 1, marginLeft: 36 }}>
              <FormField
                type="text"
                label={'billing.labels.name'.getLabel()}
                field="patientId.patientName"
                variant="filled"
                editable={false}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14 }}>
              <FormField
                type="text"
                label={'billing.labels.relation'.getLabel()}
                editable={false}
                render={() => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {getString([patientInfo[0]?.relation, patientInfo[0]?.relationName])}
                  </Text>
                )}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14 }}>
              <FormField
                type="number"
                label={'billing.labels.age'.getLabel()}
                field="patientId.age"
                variant="filled"
                editable={false}
              />
            </View>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
            <View style={{ flex: 1, marginLeft: 36 }}>
              <FormField
                type="text"
                label={'billing.labels.department'.getLabel()}
                field="patientId.lastConsultation.department.code"
                variant="filled"
                editable={false}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14 }}>
              <FormField
                type="text"
                label={'billing.labels.speciality'.getLabel()}
                field="patientId.lastConsultation.speciality.code"
                variant="filled"
                editable={false}
              />
            </View>
            <View style={{ flex: 1, marginLeft: 14 }}>
              <FormField
                type="text"
                label={'billing.labels.doctorName'.getLabel()}
                field="patientId.lastConsultation.sessionQueue.doctor.firstName"
                variant="filled"
                render={() => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {getString([
                      patientInfo[0]?.lastConsultation?.sessionQueue?.doctor?.firstName,
                      patientInfo[0]?.lastConsultation?.sessionQueue?.doctor?.lastName])}
                  </Text>
                )}
                editable={false}
              />
            </View>
          </View>
          <View style={{
            flex: 1, flexDirection: 'row', marginTop: 14, marginLeft: 36,
          }}
          >
            <FormField
              {... {
                field: 'labDetails',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  footer: ({ addRow }) => ({
                    leftActions: [{
                      render: () => (
                        <View style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderWidth: 1,
                          borderRadius: 5,
                          width: 120,
                          height: 40,
                          borderColor: 'grey',
                        }}
                        >
                          <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                            {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                          </Text>
                        </View>
                      ),
                      onPress: addRow,
                    }],
                  }),
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: true,
                    columns: [
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'testType',
                        label: 'billing.labels.testType'.getLabel(),
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.LAB_TEST,
                        query: 'masterDataList',
                        mandatory: true,
                      }),
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'labType',
                        label: `${'billing.labels.labType'.getLabel()}`,
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.LAB_TYPE,
                        query: 'masterDataList',
                        mandatory: true,
                      }),
                      currencyInput({
                        variant: 'filled',
                        amount: {
                          field: 'amount',
                          label: 'Amount',
                          placeholder: 'Enter Amount',
                        },
                        currency: {
                          field: 'currency',
                        },
                      }),
                      NestedAction(),
                    ],
                  },
                },
              }}
            />
          </View>
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
            <View style={{ flex: 1, marginLeft: 36, height: 10 }}>
              <FormField
                type="radioGroup"
                field="patientType"
                variant="filled"
                label="Patient Type"
                container="topLabel"
                options={patientTypes}
              />
            </View>
            <View style={{ marginLeft: 14, width: 309 }}>
              <FormField
                type="number"
                label="Discount (In %)"
                field="discount"
                variant="filled"
              />
            </View>
          </View>
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}>
            <View style={{ flex: 1, marginLeft: 36 }} />
            <View style={{ marginLeft: 14, width: 309 }}>
              <FormField
                type="number"
                editable={false}
                label="Bill Amount"
                field="billAmount"
                variant="filled"
              />
            </View>
          </View>
        </View>
      </View>
    </Form>
  );
};
export default NewBill;
