/* eslint-disable camelcase */
import React from 'react';
import {
  Text, View, List, TabNavigator, OutlineButton,
} from '../../../app-components';
import { Table } from '../../../app-components/table/Table';
import { manazeV6Colors } from '../../../theme/manazeV6Colors';
import { Headline11, Headline4, Headline3 } from '../../../theme/Headlines';
import * as Layouts from './AttendanceLayouts';
import { userLopSheetUri, UserLOPDrillUri } from './AttendaceUri';
import { linkTypeOpenAction, DrillAction } from './AttendanceActions';
import { userLeaveLopSheet } from '../LeaveRegister/Lists';
import { getUser } from '../../../AppServices';

const {
  MonthLayout,
  TotalLayout,
  PayableLayout,
  LOPLayout,
  AttendanceDateLayout,
  YearLayout,
  InTimeLayout,
  OutTimeLayout,
  FirstHalfLayout,
  SecondHalfLayout,
  DurationForAttendanceLayout,
  RequestsStatusLayots,
  FirstHalfLOPLayout,
  SecondHalfLOPLayout,
  FirstHalfLayoutWithType,
  SecondHalfLayoutWithType,
  LeaveType,
} = Layouts;
const {
  neutral_5,
  neutral_3,
  positive_communication_3,
  surface_white,
  negative_communication_3,
} = manazeV6Colors;

export const UserLOPList = (props) => {
  let { user } = props;
  const { isEmployeeDetail } = props;

  if (!user) {
    user = getUser();
  } else {
    user = { employee: user };
  }

  return (
    <Table
      {...props}
      listVersion={2}
      uri={() => userLopSheetUri({ user })}
      reloadEvent="UserLOPList"
      action={({ item }) => DrillAction({
        item: {
          item,
          user,
          isEmployeeDetail,
        },
        type: 'link',
        view: 'attendance-drill',
        params: 'Attendance',
        routeOptions: {
          expanded: true,
        },
      })}
      sm={{
        columns: [MonthLayout, YearLayout],
      }}
      md={{
        columns: [
          {
            width: 14,
          },
          {
            ...MonthLayout,
            sortable: 'month_id',
          },
          {
            ...YearLayout,
            sortable: 'year_id',
          },
          {
            ...TotalLayout,
          },
          {
            ...PayableLayout,
          },
          {
            ...LOPLayout,
          },
          {
            ...Layouts.totalLeavesAccruedInMonth,
          },
        ],
      }}
    />
  );
};

export const UserLOPDrillAttendance = List({
  listVersion: 2,
  hoverable: false,
  uri: ({ navigation }) => {
    const { item, user } = navigation && navigation.getParams('Attendance');

    const { month_last_date: monthLastDate } = item;
    const monthFirstDate = new Date(monthLastDate);
    monthFirstDate.setDate(0);
    const addOnFilter = {
      employee: {
        _id: user && user.employee && user.employee._id,
      },
      attendance_date: {
        $gte: monthFirstDate,
        $lte: monthLastDate,
      },
    };
    const fields = {
      leave_type_id: { _id: 1, name: 1 },
    };
    return UserLOPDrillUri({ addOnFilter, fields });
  },
  reloadEvent: 'UserLOPDrillAttendaceList',
  columns: [
    {
      width: 14,
    },
    {
      ...AttendanceDateLayout,
      sortable: true,
    },
    {
      ...InTimeLayout,
      width: 120,
    },
    {
      ...OutTimeLayout,
      width: 120,
    },
    {
      ...DurationForAttendanceLayout,
      width: 120,
    },
    {
      ...FirstHalfLayoutWithType,
      width: 120,
    },
    {
      ...SecondHalfLayoutWithType,
      width: 120,
    },
    {
      type: 'moreActions',
      align: 'left',
      visible: ({ navigation }) => {
        const { isEmployeeDetail } = navigation && navigation.getParams('Attendance');
        return !isEmployeeDetail;
      },
      actions: [
        linkTypeOpenAction({
          type: 'link',
          text: 'Raise attendance request',
          iconStyle: { height: 24, width: 24 },
          view: 'update-attendance-for-particular-day',
          params: 'attendance',
          modal: true,
          modalProps: {
            width: 550,
            height: 280,
          },
        }),
      ],
    },
  ],
});

export const UserLOPDrillRequests = List({
  listVersion: 2,
  uri: ({ navigation }) => {
    const { item, user } = navigation && navigation.getParams('Attendance');
    const { month_last_date: monthLastDate } = item;
    const monthFirstDate = new Date(monthLastDate);
    monthFirstDate.setDate(0);
    const addOnFilter = {
      employee: {
        _id: user && user.employee && user.employee._id,
      },
      was_manual_update: true,
      attendance_date: {
        $gte: monthFirstDate,
        $lte: monthLastDate,
      },
    };
    return UserLOPDrillUri({ addOnFilter });
  },
  reloadEvent: 'UserLOPDrillRequestsList',
  columns: [
    {
      ...AttendanceDateLayout,
    },
    {
      ...FirstHalfLayout,
      width: 170,
    },
    {
      ...SecondHalfLayout,
      width: 170,
    },
    LeaveType,
    {
      ...RequestsStatusLayots,
      width: 160,
    },
  ],
});

export const AttendanceLOPList = List({
  listVersion: 2,
  hoverable: false,
  uri: ({ navigation }) => {
    const { item, user } = navigation && navigation.getParams('Attendance');
    const { month_last_date: monthLastDate } = item;
    const monthFirstDate = new Date(monthLastDate);
    monthFirstDate.setDate(0);
    const addOnFilter = {
      $or: [
        {
          first_attendance_type_id: {
            code: {
              $in: ['leave', 'absent'],
            },
          },
        },
        {
          second_attendance_type_id: {
            code: {
              $in: ['leave', 'absent'],
            },
          },
        },
      ],
      employee: {
        _id: user && user.employee && user.employee._id,
      },
      attendance_date: {
        $gte: monthFirstDate,
        $lte: monthLastDate,
      },
    };

    return UserLOPDrillUri({ addOnFilter });
  },
  columns: [
    {
      width: 14,
    },
    {
      ...AttendanceDateLayout,
    },
    {
      ...FirstHalfLOPLayout,
      width: 160,
    },
    {
      ...SecondHalfLOPLayout,
      width: 200,
    },
  ],
});
const aggregates = (data) => (
  <View
    style={{
      height: 68,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 6,
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'row',
      color: neutral_5,
      ...Headline11,
    }}
  >
    <View
      style={{
        flex: 4,
        justifyContent: 'space-around',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: surface_white,
        paddingTop: 9,
      }}
    >
      <View style={{ textAlign: 'center' }}>
        <Text>PRESENT</Text>
        <Text style={{ color: neutral_3, marginTop: 8, ...Headline4 }}>
          {(data && data.present_days) || '0'}
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text>OFF</Text>
        <Text style={{ color: neutral_3, marginTop: 8, ...Headline4 }}>
          {(data && data.compensatory_off) || '0'}
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text>EWD</Text>
        <Text style={{ color: neutral_3, marginTop: 8, ...Headline4 }}>
          {(data && data.ewd_payable) || '0'}
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text>HOLIDAYS</Text>
        <Text style={{ color: neutral_3, marginTop: 8, ...Headline4 }}>
          {(data && data.holidays) || '0'}
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text>PAYABLE</Text>
        <Text
          style={{
            color: positive_communication_3,
            marginTop: 8,
            ...Headline3,
          }}
        >
          {(data && data.total_salary_days) || '0'}
        </Text>
      </View>
    </View>
    <View
      style={{
        flex: 3,
        display: 'flex',
        marginLeft: 8,
        borderRadius: 4,
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: surface_white,
        paddingTop: 9,
      }}
    >
      <View style={{ textAlign: 'center' }}>
        <Text>ABSENT</Text>
        <Text style={{ color: neutral_3, marginTop: 8, ...Headline4 }}>
          {(data && data.absent_days) || '0'}
        </Text>
      </View>
      <View style={{ textAlign: 'center' }}>
        <Text>TOTAL LOP</Text>
        <Text
          style={{
            color: negative_communication_3,
            marginTop: 8,
            ...Headline3,
          }}
        >
          {(data && data.leave_days) || data.absent_days || '0'}
        </Text>
      </View>
    </View>
  </View>
);
export class AttendanceLOP extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { navigation } = this.props || {};
    const activeMQ = navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    const {
      state:
      {
        params:
         {
           Attendance: { item: { details: data = {} } = {} } = {},
         } = {},
      } = {},
    } = navigation || {};

    return (
      <View style={{ overflow: 'hidden', display: 'flex', flex: 1 }}>
        {(activeMQ !== 'sm' && aggregates(data)) || null}
        {<AttendanceLOPList {...this.props} />}
      </View>
    );
  }
}

export const userAttendanceDrill = TabNavigator({
  tabs: {
    all: {
      label: 'Attendance Register',
      screen: UserLOPDrillAttendance,
    },
    leaveRegister: {
      label: 'Leave Register',
      screen: userLeaveLopSheet,
      actions: ({ navigation }) => {
        const { isEmployeeDetail } = navigation.getParams('Attendance');
        return [
          {
            render: !isEmployeeDetail ? <OutlineButton label="New leave request" /> : null,
            type: 'link',
            link: {
              view: 'new-leave-request',
              modal: true,
              modalProps: {
                width: 620,
                height: 365,
              },
            },
          },
        ];
      },
    },
    lop: {
      label: 'LOP',
      screen: (props) => <AttendanceLOP {...props} />,
    },
  },
});
