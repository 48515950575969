import { MODELS } from '../Lib/constants';

export const viewOperativeNotesUri = (filter = {}) => ({
  query: {
    model: MODELS.IPD_OPERATIVE_NOTES,
    id: 'operativeNotesDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: 'ipd_operative_notes',
});
