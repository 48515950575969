import { MODELS } from '../Lib/constants';

export const historyUri = (filter = {}) => ({
  query: {
    model: MODELS.HISTORY,
    id: 'historyIPDDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.HISTORY,
});

export const viewHistoryUri = (id) => ({
  query: {
    model: MODELS.HISTORY,
    id: 'historyIPDDataList',
    addOnFilter: { _id: id },
  },
  model: MODELS.HISTORY,
});
