export const EditRoom = ({ navigation }) => ({
  query: {
    model: 'rooms',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'rooms',
      // eslint-disable-next-line no-underscore-dangle
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'rooms',
});

export const roomsListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataList',
    addOnFilter: filter,
  },
  model: 'rooms',
});

