import { isFunction } from 'lodash';
import { MODELS } from '../Lib/constants';

export const PharmacyCentralInventoryItemListUri = ({ filter = {}, queryID = 'masterDataListForTable' } = {}) => ({
  query: {
    id: queryID,
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PHARMACY_ITEM,
});
export const PharmacyDepartmentInventoryItemListUri = ({ queryId, filter = {} } = {}) => ({
  query: {
    id: queryId || 'pharmacyDepartmentInventoryStocksDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
});
export const EditPharmacyItem = ({ navigation }) => ({
  query: {
    model: MODELS.PHARMACY_ITEM,
    id: navigation?.state?.params?.queryID || 'masterDataListForTable',
    relationValue: {
      model: MODELS.PHARMACY_ITEM,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_ITEM,
});

export const PharmacyPurchaseRequestsListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'pharmacyInventoryRequestdataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PHARMACY_PURCHASE,
});
export const PharmacyPurchaseEntityRequestListUri = ({ filter = { type: 'pharmacy' }, queryId } = {}) => ({
  query: {
    id: queryId || 'purchaseRequestPharmacyList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PURCHASE_REQUEST,
});

export const PharmacyPurchaseEntityRequestUri = ({ filter, queryId }) => ({
  query: {
    id: queryId || 'purchaseRequestPharmacyList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.PURCHASE_REQUEST,
});

export const PharmacyIndentRequestsListUri = ({ filter = {}, queryId } = {}) => ({
  query: {
    id: queryId || 'pharmacyInventoryIndentdataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PHARMACY_INDENT,
});

export const PharmacyPatientDispensingListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'pharmacyBillingDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PHARMACY_BILLING,
});
export const PharmacyPatientDispensingUri = ({ navigation }) => ({
  query: {
    model: MODELS.PHARMACY_BILLING,
    id: 'pharmacyBillingDataList',
    relationValue: {
      model: MODELS.PHARMACY_BILLING,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_BILLING,
});

export const PharmacyMSITransferRequestsListUri = ({ queryId, filter = {} } = {}) => ({
  query: {
    id: queryId || 'pharmacyTransferRequestsDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PHARMACY_TRANSFER_REQUESTS,
});
export const PharmacyMSIBatchRequestsListUri = ({ filter = {}, queryId } = {}) => ({
  query: {
    id: queryId || 'pharmacyBatchRequestsDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PHARMACY_BATCH_REQUESTS,
});
export const PharmacyMSILowShelfLifeRequestsListUri = ({ filter = {}, queryId } = {}) => ({
  query: {
    id: queryId || 'pharmacyInventoryBatchLowShelfLifeRequests',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PHARMACY_BATCH,
});
export const EditPharmacyIndentRequestStatus = ({ queryId, navigation, callback }) => ({
  query: {
    model: MODELS.PHARMACY_INDENT,
    id: queryId || 'pharmacyInventoryIndentdataList',
    afterFetch: (data) => {
      if (isFunction(callback)) {
        callback(data);
      }
    },
    relationValue: {
      model: MODELS.PHARMACY_INDENT,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_INDENT,
});

export const EditPharmacyTransferRequestStatus = ({ queryId, navigation }) => ({
  query: {
    model: MODELS.PHARMACY_TRANSFER_REQUESTS,
    id: queryId || 'pharmacyTransferRequestsDataList',
    relationValue: {
      model: MODELS.PHARMACY_TRANSFER_REQUESTS,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_TRANSFER_REQUESTS,
});
export const EditPharmacyBatchRequestStatus = ({ queryId, navigation }) => ({
  query: {
    model: MODELS.PHARMACY_BATCH_REQUESTS,
    id: queryId || 'pharmacyBatchRequestsDataList',
    relationValue: {
      model: MODELS.PHARMACY_BATCH_REQUESTS,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_BATCH_REQUESTS,
});
export const EditPharmacyLowShelfLifeRequestStatus = ({ queryId, navigation }) => ({
  query: {
    model: MODELS.PHARMACY_BATCH,
    id: queryId || 'pharmacyInventoryBatchLowShelfLifeRequests',
    relationValue: {
      model: MODELS.PHARMACY_BATCH,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PHARMACY_BATCH,
});
