import { MODELS } from '../Lib/constants';

export const patientListUri = () => ({
  query: {
    id: 'patientDataList',
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.PATIENTS,
});

export const unknownPatientListUri = () => ({
  query: {
    id: 'unknownPatientDataList',
  },
  aggregate: true,
  model: MODELS.PATIENTS,
});

export const EditPatientUri = ({ navigation }) => ({
  query: {
    model: MODELS.PATIENTS,
    computation: (data) => ([{ ...data[0], isUnknown: null }]),
    id: 'patientDetails',
    relationValue: {
      model: MODELS.PATIENTS,
      _id: navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PATIENTS,
});

export const EditUnknownPatientUri = ({ navigation }) => ({
  query: {
    model: MODELS.PATIENTS,
    id: 'unknownPatientDataList',
    relationValue: {
      model: MODELS.PATIENTS,
      _id: navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PATIENTS,
});

export const registeredIPDListUri = ({ filter } = {}) => ({
  query: {
    id: 'admitIPDDataList',
    addOnFilter: filter,
    sort: { _createdOn: -1 },
  },
  aggregate: true,
  model: MODELS.IPD_ADMISSION,
});

export const editRoomAdmitIPDPatientUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_ADMISSION,
    id: 'EditAdmitIPDPatient',
    relationValue: {
      model: MODELS.IPD_ADMISSION,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.IPD_ADMISSION,
});

export const iPDPatientUri = ({ data }) => ({
  query: {
    model: MODELS.IPD_ADMISSION,
    id: 'EditAdmitIPDPatient',
    relationValue: {
      model: MODELS.IPD_ADMISSION,
      _id: data?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.IPD_ADMISSION,
});

export const editAdmitIPDPatientUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_ADMISSION,
    id: 'EditAdmitIPDPatient',
    relationValue: {
      model: MODELS.IPD_ADMISSION,
      _id: navigation?.state?.params?.patient?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.IPD_ADMISSION,
});

