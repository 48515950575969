import React from 'react';
import { DateFilter, FilterGroup } from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import childTabTheme from './childTabTheme';
import IndentRequestList from './IndentRequests/IndentRequestList';
import OtherRequestsTabs from './OtherRequests';
import RequestList from './RaisedPurchasesRequests/RequestList';

const PharmacyCentralInventoryRequestsTabs = (props) => {
  const {
    purchaseRequestsQueryId,
    indentRequestsQueryId,
    lowShelfLifeQueryId,
    batchUpdateRequestsQueryId,
    transferRequestsQueryId,
    defaultValues,
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...childTabTheme }}
      tabs={{
        RaisedPurchaseRequests: {
          label: 'Raised Purchase Requests',
          screen: RequestList,
          screenProps: {
            queryId: purchaseRequestsQueryId,
            defaultValues,
          },
          actions: [
            (ftsProps) => (
              <FtsSearch
                {...ftsProps}
                action={{ exp: ['purchaseRequestPharmacy.purchaseRequestNumber'] }}
              />
            ),
            <DateFilter
              field="_createdOn"
              key="_createdOn"
              containerStyle={{
                flexDirection: 'row',
                width: 303,
                height: 36,
                borderWidth: 1,
                padding: 9,
                marginRight: 40,
                borderRadius: 4,
                borderStyle: 'solid',
                backgroundColor: vars.colors.white,
                borderColor: vars.colors.grey.color6,
              }}
            />,
          ],
        },
        IndentRequests: {
          label: 'Indent Requests',
          screenProps: {
            queryId: indentRequestsQueryId,
          },
          screen: IndentRequestList,
          actions: [
            (ftsProps) => (
              <FtsSearch
                {...ftsProps}
                action={{ exp: ['indentNumber'] }}
              />
            ),
            <DateFilter
              field="_createdOn"
              key="_createdOn"
              containerStyle={{
                flexDirection: 'row',
                width: 303,
                height: 36,
                borderWidth: 1,
                padding: 9,
                marginRight: 40,
                borderRadius: 4,
                borderStyle: 'solid',
                backgroundColor: vars.colors.white,
                borderColor: vars.colors.grey.color6,
              }}
            />,
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'department',
                  label: 'Department',
                  model: MODELS.DEPARTMENTS,
                  id: 'masterDataList',
                  searchField: 'name',
                  placeholder: 'Select Department',
                  sugestionField: 'label',
                  primaryField: 'name',
                  valueField: 'name',
                },
              ],
            }),
          ],
        },
        Others: {
          label: 'Others',
          screen: OtherRequestsTabs,
          screenProps: {
            lowShelfLifeQueryId,
            batchUpdateRequestsQueryId,
            transferRequestsQueryId,
          },
        },
      }}
    />
  );
};
export default PharmacyCentralInventoryRequestsTabs;
