import React from 'react';

import ListActionModal from '../../../../../Components/ListActionModal';

import { autoSuggestInput } from '../../../../../autoSuggestions';
import { Form } from '../../../../../app-components/form/Form';
import { save, View } from '../../../../../app-components';
import { MODELS } from '../../../../../Lib/constants';
import { EditHouseAllotmentDetails } from '../../uri';
import { submit } from '../../../../../AppServices';

const EditHouseAllotment = (props) => {
  const footer = {
    actions: [
      save,
    ],
  };
  return (
    <ListActionModal
      {...props}
      title="Edit House Allotment Details"
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            {...props}
            onSubmit={(params) => {
              const { data, updates } = params;
              updates.waterCharge = params?.navigation?.state?.params?.item?.waterCharge;
              updates.rentCharge = params?.navigation?.state?.params?.item?.waterCharge;
              const submitAPI = submit({
                model: MODELS.HOUSE_ALLOTMENT,
              });
              const result = submitAPI({ updates, data });
              return result;
            }}
            mandatory={{
              fromDate: 1,
              toDate: 1,
            }}
            uri={EditHouseAllotmentDetails({ props })}
            reloadEvent="houseAllotmentDataList"
            reloadOnChangeEvent={`reload${MODELS.HOUSE_ALLOTMENT}`}
            closeView={1}
            type="standardShadow"
            lg={{
              verticalFormGroups: [
                {
                  formGroups: [
                    {
                      fieldVariant: 'filled',
                      direction: 'row',
                      columnsPerRow: 3,
                      columns: [
                        autoSuggestInput({
                          field: 'allottee',
                          type: 'autoSuggest',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEE,
                          query: 'employeeLists',
                          placeholder: 'Select Employee',
                          searching: true,
                          searchField: 'name',
                          editable: false,
                          label: 'Allottee Name ',
                        }),
                        {
                          type: 'date',
                          field: 'fromDate',
                          label: 'From',
                          mandatory: true,
                        },
                        {
                          type: 'date',
                          field: 'toDate',
                          label: 'To',
                          mandatory: true,
                        },
                        {
                          type: 'text',
                          render: (data) => (data?.navigation?.state?.params?.item?.rentAmount),
                          label: 'Rent Charges',
                          editable: false,
                        },
                        {
                          type: 'text',
                          render: (data) => (data?.navigation?.state?.params?.item?.waterCharge),
                          label: 'Water Charges',
                          editable: false,
                        },
                      ],
                    },
                  ],
                },
              ],
              footer,
            }}
          />
        </View>
      )}
    />
  );
};

export default EditHouseAllotment;
