/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import {
  FormField,
  Toast,
  View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import ListActionModal from '../../../../../Components/ListActionModal';
import { autoSuggestOptionsInput } from '../../../../../autoSuggestions';
import vars from '../../../../../theme/vars';
import {
  inventoryType,
  MODELS,
  Roles,
  RolesOrder,
} from '../../../../../Lib/constants';
import { invoke, submit } from '../../../../../AppServices';
import { getErrorString } from '../../../../../Lib/helpers';
import { LoadingButton } from '../../../../../app-components/buttons/Buttons';

const PurchaseOrderApproveOrReviewModal = (props) => {
  const { navigation: { state: { params } } = {} } = props;
  const { item: { currentApprovalQueue } } = params;
  const [assignedToRole, setAssignedToRole] = useState('');

  const ManageStatusReject = useCallback(async (props) => {
    const { data, navigation, eventDispatcher } = props;
    data._id = params?.item?._id;
    data.uniqueNumber = params?.item?.uniqueNumber;
    data.allAssignees = params?.item?.allAssignees;
    data.type = params?.item?.type;
    try {
      const result = await invoke({
        id: 'rejectPurchaseSupplyOrder',
        paramValue: data,
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Request has been rejected.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
        navigation.pop(2);
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.item._id, params.item.type]);

  return (
    <ListActionModal
      title={
          params?.fromRejectPO
            ? `Reject ${
              params?.item?.type === 'PO' ? 'Purchase Sanction' : 'Supply Order'
            }`
            : `Approve and send ${params?.item?.type === 'PO' ? 'PS' : 'SO'}`
      }
      description={
          params?.fromRejectPO
            ? `This action will reject the ${
              params?.item?.type === 'PO' ? 'Purchase Sanction' : 'Supply Order'
            }.`
            : `This action will approve the ${
              params?.item?.type === 'PO' ? 'Purchase Sanction' : 'Supply Order'
            } and send it to the chosen official.`
      }
      content={(
        <Form
          closeView={2}
          Purchase
          Order
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={(props) => {
            const { data, updates } = props;
            if (params?.fromRejectPO) {
              ManageStatusReject(props);
            } else {
              data.purchaseRequestEntity = params?.item?.purchaseRequestEntity;
              data.purchaseSupplyOrder = { _id: params?.item?._id };

              updates.purchaseRequestEntity = params?.item?.purchaseRequestEntity;
              updates.purchaseSupplyOrder = { _id: params?.item?._id };
              delete data._id;
              delete updates._id;
              if (params?.item?.isContractQueueCreated && params?.item?.type === 'PO' && params?.item?.inventoryType === inventoryType.asset) {
                data.isContractQueueCreated = true;
                updates.isContractQueueCreated = true;
              }
              const submitAPI = submit({
                model: MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE,
                ...props,
                data,
              });
              const result = submitAPI({ data, updates });
              return result;
            }
          }}
          reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
          defaultValues={() => ({
            approvalDaysLimit: params?.item?.currentApprovalQueue?.approvalDaysLimit,
            assignedToRole: params?.fromRejectPO ? params?.item?.currentApprovalQueue?.assignedToRole : null,
            assignedToRoleKey: params?.item?.currentApprovalQueue?.assignedToRoleKey,
            status: params?.item?.currentApprovalQueue?.status,
            _id: params?.item?.currentApprovalQueue?._id,
          })}
          mandatory={{
            remarks: 1,
            assignedToRole: 1,
            approvalDaysLimit: 1,
          }}
          submitMessage={
               params?.fromRejectPO ? '' : `Request has been approve by ${
                 Roles[
                   Object.keys(Roles)[
                      params?.item?.currentApprovalQueue?.assignedToRoleKey - 1
                   ]
                 ]
               } and sent to ${assignedToRole}.`
          }
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              {params?.fromRejectPO ? null : (
                <View style={{ flexDirection: 'row', marginTop: 24 }}>
                  <View style={{ width: params?.fromSendForReview ? 300 : 625, marginRight: 20 }}>
                    <FormField
                      {...autoSuggestOptionsInput({
                        label: 'purchase.labels.sendTo'.getLabel(),
                        variant: 'filled',
                        keyFiled: 'label',
                        field: 'assignedToRole',
                        suggestionField: 'label',
                        valueField: 'label',
                        options: ({ data }) => {
                          const { assignedToRoleKey } = data;
                          setAssignedToRole(data?.assignedToRole);
                          if (currentApprovalQueue?.assignedToRoleKey === 7 && currentApprovalQueue?.isReview) return RolesOrder.slice(1, RolesOrder.length);
                          return RolesOrder.slice(assignedToRoleKey, RolesOrder.length);
                        },
                        mandatory: true,
                      })}
                    />
                  </View>
                </View>
              )}
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ width: 620 }}>
                  <FormField
                    type="textArea"
                    label={'purchase.labels.remark'.getLabel()}
                    variant="filled"
                    field="remarks"
                    mandatory
                    allowWhiteSpaces
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <LoadingButton
                  label={'purchase.button.confirm'.getLabel()}
                  onPress={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default PurchaseOrderApproveOrReviewModal;
