/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import uuid from 'uuid/v4';
import { Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import {
  NestedAction,
  NestedTable,
} from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { searchInput } from '../../../../../autoSuggestions';
import { MODELS } from '../../../../../Lib/constants';
import { getUser, submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const getNewId = () => `new_${uuid()}`;

const RaiseIndent = (props) => {
  const {
    navigation: {
      state: {
        params: {
          itemQueryId,
          defaultValues,
        } = {},
      } = {},
    } = {},
  } = props;

  const user = getUser();
  const { employee: { department = [] } = {} } = user;
  // eslint-disable-next-line no-unused-vars
  const [departmentIds, setDepartmentId] = useState(department);

  return (
    <Form
      type="standard"
      defaultValues={() => ({
        ...defaultValues,
        indentDetails: [{ _id: getNewId() }],
        department: departmentIds[0],
      })}
      beforeSubmit={({ data, updates }) => {
        const itemIndentDetails = data?.indentDetails?.map((item) => ({
          item: { _id: item?.item?._id },
          reasonForPurchase: item?.reasonForPurchase,
          requiredQuantity: item?.requiredQuantity,
        }
        ));
        return {
          updates: {
            ...updates,
            indentDetails: itemIndentDetails,
          },
        };
      }}
      closeView={1}
      computations={{
        children: {
          indentDetails: {
            self: {
              itemAutofill: {
                compute: ({ item }) => ({
                  set: {
                    itemCode: item?.itemCode,
                    category: item?.category,
                    subCategory: item?.subCategory,
                    reorderLevel: item?.reorderLevel,
                    sellingPrice: item?.sellingPrice,
                    description: item?.description,
                    isActive: item?.isActive,
                    avgDepartmentMonthlyConsumption: item?.avgDepartmentMonthlyConsumption,
                    manufacturer: item?.rateContract[0]?.manufacturer?.name,
                    stockAvailable: item?.stockAvailable[0]?.stockAvailable,
                    lastIndentDate: item?.lastIndentDate,
                    lastIndentQuantity: item?.lastIndentQuantity,
                  },
                }),
                onChange: ['item'],
              },
            },
          },
        },
        self: {
          speciality: {
            compute: () => ({
              set: {
                speciality: '',
              },
            }),
            onChange: ['department'],
          },
        },
      }}
      onSubmit={submit({
        model: MODELS.PHARMACY_INDENT,
      })}
      validations={{
        indentDetails: {
          // eslint-disable-next-line consistent-return
          requiredQuantity: ({ data }) => {
            if (data.stockAvailable) {
              if (data.stockAvailable < data.requiredQuantity) {
                return 'Required Quantity can not be more than Stocks available';
              }
            }
          },
        },
      }}
      mandatory={{
        department: 1,
        indentDetails: {
          item: 1,
          requiredQuantity: 1,
        },
      }}
      submitMessage={'inventory.messages.indentRaisedSuccessfully'.getLabel()}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  searchInput({
                    field: 'department',
                    label: 'room.labels.department'.getLabel(),
                    placeholder: 'room.placeholders.department'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.DEPARTMENTS,
                    query: 'masterDataList',
                    searching: true,
                    mandatory: true,
                    visible: () => departmentIds.length > 1,
                  },
                  {
                    addOnFilter: () => ({
                      _id: { $in: departmentIds?.map(({ _id }) => _id) },
                    }),
                  }),
                  searchInput(
                    {
                      field: 'speciality',
                      label: 'employee.labels.speciality'.getLabel(),
                      placeholder: 'employee.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      disabled: ({ data }) => !data?.department,
                      visible: () => itemQueryId,
                    },
                    {
                      addOnFilter: ({ data } = {}) => ({
                        department: { _id: data?.department?._id },
                      }),
                    },
                  ),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.indentDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'indentDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      skipTableHeaderOnNoData: true,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'inventory.buttons.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      listProps: {
                        headerRowContainerStyle: {
                          minHeight: 32,
                          paddingLeft: 12,
                          paddingRight: 4,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 2,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'inventory.labels.itemId'.getLabel(),
                            label: 'inventory.labels.itemId'.getLabel(),
                            variant: 'filled',
                            field: 'itemCode',
                            disabled: true,
                            width: 150,
                          },
                          searchInput({
                            field: 'item',
                            variant: 'filled',
                            header: 'inventory.labels.itemName'.getLabel(),
                            label: 'inventory.labels.itemName'.getLabel(),
                            placeholder: 'inventory.placeholders.selectItemName'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            searchField: 'name',
                            searching: true,
                            model: MODELS.PHARMACY_ITEM,
                            query: itemQueryId || 'masterDataList',
                            mandatory: true,
                            width: 150,
                          },
                          {
                            addOnFilter: ({
                              data:
                                { _parent: { indentDetails = [] } = {} },
                            } = {}) => {
                              const itemIds = indentDetails?.filter(
                                ({ item }) => item?._id,
                              ).map(({ item }) => item?._id);
                              return {
                                _id: { $nin: itemIds },
                              };
                            },
                          }),
                          searchInput({
                            field: 'category',
                            variant: 'filled',
                            header: 'inventory.labels.category'.getLabel(),
                            label: 'inventory.labels.category'.getLabel(),
                            placeholder: 'inventory.placeholders.supplier'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            disabled: true,
                            width: 150,
                          }),
                          {
                            type: 'text',
                            header: 'inventory.labels.unitPrice'.getLabel(),
                            label: 'inventory.labels.unitPrice'.getLabel(),
                            variant: 'filled',
                            field: 'sellingPrice',
                            disabled: true,
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.stockAvailable'.getLabel(),
                            label: 'inventory.labels.stockAvailable'.getLabel(),
                            variant: 'filled',
                            field: 'stockAvailable',
                            disabled: true,
                            width: 150,
                          },
                          {
                            type: 'textArea',
                            header: 'inventory.labels.reasonForPurchase'.getLabel(),
                            label: 'inventory.labels.reasonForPurchase'.getLabel(),
                            variant: 'filled',
                            field: 'reasonForPurchase',
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.requiredQuantity'.getLabel(),
                            label: 'inventory.labels.requiredQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'requiredQuantity',
                            mandatory: true,
                            width: 150,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.avgConsumption'.getLabel(),
                            label: 'inventory.labels.avgConsumption'.getLabel(),
                            variant: 'filled',
                            field: 'avgDepartmentMonthlyConsumption',
                            disabled: true,
                            width: 150,
                          },
                          {
                            type: 'date',
                            header: 'inventory.labels.lastIndentDate'.getLabel(),
                            label: 'inventory.labels.lastIndentDate'.getLabel(),
                            variant: 'filled',
                            field: 'lastIndentDate',
                            disabled: true,
                            width: 150,
                          },
                          {
                            type: 'number',
                            header: 'inventory.labels.lastIndentQuantity'.getLabel(),
                            label: 'inventory.labels.lastIndentQuantity'.getLabel(),
                            variant: 'filled',
                            field: 'lastIndentQuantity',
                            disabled: true,
                            width: 150,
                          },
                          {
                            type: 'text',
                            header: 'inventory.labels.manufacturer'.getLabel(),
                            label: 'inventory.labels.manufacturer'.getLabel(),
                            variant: 'filled',
                            field: 'manufacturer',
                            disabled: true,
                            width: 150,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default RaiseIndent;
