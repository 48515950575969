
import React from 'react';

import MasterForm from '../../../../Wired/Master/MasterForm';
import { EditLeaveType } from '../../../../Queries/leaveType';
import { MODELS } from '../../../../Lib/constants';
import ViewLeaveTypeTabs from './Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'leaveType.labels.leaveName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'code',
    label: 'leaveType.labels.leaveId'.getLabel(),
    mandatory: true,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'leaveType.labels.status'.getLabel(),
    container: 'topLabel',
  },
  {
    type: 'checkBox',
    field: 'isLeaveBalanceReqForApply',
    container: 'rightLabel',
    label: 'Is Leave balance required to apply for this Leave Type',
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'masters.labels.description'.getLabel(),
    allowWhiteSpaces: true,
  },
];

export default MasterForm({
  uri: EditLeaveType,
  model: MODELS.LEAVE_TYPE,
  editable: false,
  submitMessage: 'leaveType.messages.updateMessage'.getLabel(),
  formFields,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewLeaveTypeTabs {...props} />
    ),
  }],
});
