import React from 'react';

import {
  StandardButton,
} from '../../../../../../../app-components';
import {
  MODELS,
  ROUTES,
} from '../../../../../../../Lib/constants';
import { viewMLCSampleListUri } from '../../../../../../../Queries/ipdMlcSample';
import { Table } from '../../../../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="Samples"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      type: 'link',
      link: ({ navigation: { state: { params = {} } } }) => ({
        view: ROUTES.uploadMLCSample.name,
        params: { ...params },
      }),
      render: () => (
        <StandardButton
          label="Upload Sample"
        />
      ),
    },
  ],
});

const tableItems = {
  header,
  md: {
    columns: [
      {
        header: 'Serial Number',
        type: 'number',
        render: (item) => item?.index + 1,
      },
      {
        header: 'Date',
        type: 'date',
        field: 'sampleDate',
        format: 'DD MMM YYYY',
      },
      {
        header: 'Sample Name',
        type: 'text',
        field: 'sampleName',
      },
      {
        header: 'Sample Site',
        type: 'text',
        field: 'sampleSite',
      },
      {
        header: 'Image',
        type: 'file',
        field: 'sampleImage',
      },
    ],
  },
};

const ECTNote = (props) => (
  <Table
    {...props}
    uri={viewMLCSampleListUri}
    {...tableItems}
    reloadEvent={`reload${MODELS.IPD_MLC_SAMPLES}`}
  />
);
export default ECTNote;
