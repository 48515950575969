import React from 'react';
import ReactDOM from 'react-dom';
import './labels/en';
import './date';
import App from './App';

import './index.css';

const MainApp = () => <App />;

ReactDOM.render(<MainApp />, document.getElementById('root'));

