import React from 'react';
import { MODELS, ROUTES } from '../../../Lib/constants';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 15 },
  {
    header: 'Name',
    type: 'text',
    field: 'name',
  },
  {
    header: 'Sanction Seats',
    type: 'text',
    field: 'sanctionSeats',
  },
  {
    header: 'Status',
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'Description',
    type: 'text',
    field: 'description',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.DESIGNATION}
    editFormId={ROUTES.EditEmployeeDesignation.name}
    tableColumns={tableColumns}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.ViewEmployeeDesignation.name,
        params: { item },
      },
    })}
  />
);
