import React, { useCallback, useEffect } from 'react';

import { Table } from '../../../app-components';
import { CommonSalaryComponentsUri } from './uri';
import { Layout } from './layouts';
import { DrillAction } from './actions';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { SalaryComponentToggleFilter } from '../../../app-components/filter/MamcToggleFilters';

export const SalaryComponentList = (props) => {
  const { navigation } = props;
  const setDefaultFilter = useCallback((value) => {
    let dataParams = {};
    dataParams = {
      filters: {
        isActive: { filter: { value }, value },
      },
    };
    return { dataParams };
  }, []);

  useEffect(() => {
    (async () => {
      const { dataParams } = setDefaultFilter(true);
      navigation.setScreenState({ dataParams });
    })();
  }, []);
  const SalaryComponentTable = Table({
    header: {
      leftActions: [(props) => <SalaryComponentToggleFilter {...props} model={MODELS.SALARY_COMPONENTS} id="salaryComponent" />],
    },
    listVersion: 2,
    reloadEvent: 'reloadSalaryComponentsList',
    action: (actionProps) => {
      const { navigation: actionNavigation, item } = actionProps;
      const tab = (actionNavigation && actionNavigation.getParams('tab')) || '';
      return DrillAction({
        type: 'link',
        params: {
          item,
          tab,
        },
        view: ROUTES.viewSalaryComponent.name,
        routeOptions: {
          expanded: true,
        },
      });
    },
    uri: () => CommonSalaryComponentsUri({}, {}),
    sm: { columns: [Layout.name] },
    md: {
      columns: [
        {
          width: 14,
        },
        Layout.component_name,
        Layout.component_type,
        Layout.component_calc_base,
        Layout.pay_to,
        Layout.status,
        Layout.editRecord,
      ],
    },
  });

  return <SalaryComponentTable {...props} />;
};
