import { get } from 'lodash';
import React from 'react';
import FileLink from '../../Components/FileLink';

const AttachmentList = (props) => {
  const value = get(props, 'navigation.state.params.item.attachment', []);

  if (Array.isArray(value)) {
    return value.map((file) => <FileLink file={file} />)
  }

  return null
}

export default AttachmentList;

