import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'House Number',
    mandatory: true,
  },
  {
    type: 'number',
    field: 'rentAmount',
    label: 'Rent Amount',
    mandatory: true,
  },
  {
    type: 'number',
    field: 'waterCharge',
    label: 'Water Charge',
    mandatory: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    allowWhiteSpaces: true,
  },
  {
    type: 'number',
    field: 'noOfRooms',
    label: 'No. of Rooms',
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'Status',
  },
];

export default MasterForm({
  model: MODELS.HOUSE_DETAILS,
  reloadEvent: 'masterDataList',
  formFields,
  submitMessage: 'House Details Added SuccessFully',
  mandatory: {
    name: 1,
    rentAmount: 1,
    isActive: 1,
    noOfRooms: 1,
    waterCharge: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
