import React from 'react';

import { upperFirst } from 'lodash';
import { View } from 'react-native';
import ViewCompanyTabs from './components/tabs';
import { MODELS } from '../../../../../Lib/constants';
import { editCompanyDetails } from '../../../../../Queries/bloodBank';
import { Form } from '../../../../../app-components/form/Form';

const formFields = {
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 4,
          columns: [
            {
              type: 'text',
              field: 'name',
              label: 'companyManagement.label.companyName'.getLabel(),
              mandatory: true,
              modifyValueOnChange: ({ value }) => upperFirst(value),
            },
            {
              type: 'text',
              field: 'email',
              label: 'companyManagement.label.email'.getLabel(),
              mandatory: true,
            },
            {
              type: 'number',
              field: 'contactNo',
              label: 'companyManagement.label.contactNo'.getLabel(),
              mandatory: true,
            },

            {
              type: 'textArea',
              field: 'address',
              label: 'companyManagement.label.address'.getLabel(),
              mandatory: true,
            },

            {
              type: 'textArea',
              field: 'description',
              label: 'companyManagement.label.description'.getLabel(),
              minHeight: 75,
            },
            {
              type: 'status',
              field: 'isActive',
              label: 'companyManagement.label.status'.getLabel(),
            },
          ],
        },
      ],
    },
  ],
};

export default (props) => (
  <View>
    <Form
      uri={editCompanyDetails}
      lg={formFields}
      mandatory={{
        name: 1,
        email: 1,
        contactNo: 1,
      }}
      reloadOnChangeEvent={`reload${MODELS.COMPANY_MANAGEMENT}`}
      editable={false}
      {...props}
    />
    <ViewCompanyTabs {...props} />
  </View>
);
