import React from 'react';
import { Text } from 'react-native';
import { FormHoc } from '../../../../app-components';
import { autoSuggestInput } from '../../../../autoSuggestions';
import FileLink from '../../../../Components/FileLink';
import RelationInput from '../../../../Components/RelationInput';
import { bloodDonationListUri } from '../../../../Queries/bloodBank';
import vars from '../../../../theme/vars';

const ViewBloodDonationForm = FormHoc({
  type: 'standard',
  uri: bloodDonationListUri,
  closeView: 1,
  editable: false,
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        label: 'Donor Details',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 4,
        },
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'bloodDonor.donorId',
                placeholder: 'donorAndDonations.placeholders.donorId'.getLabel(),
                label: 'donorAndDonations.labels.donorId'.getLabel(),
              },
              {
                type: 'text',
                field: 'bloodDonor.name',
                placeholder: 'donorAndDonations.placeholders.donorName'.getLabel(),
                label: 'donorAndDonations.labels.donorName'.getLabel(),
              },
              {
                type: 'custom',
                render: (props) => (
                  <RelationInput
                    {...props}
                    relationFieldName="bloodDonor.relation"
                    relationNameFieldName="bloodDonor.relationName"
                    mandatory
                    relationNameProps={{
                      allowWhiteSpaces: true,
                    }}
                  />
                ),
              },
              {
                type: 'number',
                render: ({ data }) => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {`${data?.bloodDonor?.age || ''
                    } ${
                        data?.bloodDonor?.ageType || ''}`}
                  </Text>
                ),
                label: 'donorAndDonations.labels.age'.getLabel(),
              },
              autoSuggestInput({
                label: 'donorAndDonations.labels.gender'.getLabel(),
                field: 'bloodDonor.gender',
                suggestionField: 'label',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
              }),
              autoSuggestInput({
                label: 'donorAndDonations.labels.bloodGroup'.getLabel(),
                field: 'bloodGroup',
                suggestionField: 'label',
                placeholder: 'donorAndDonations.placeholders.selectGender'.getLabel(),
              }),
              {
                type: 'number',
                field: 'bloodDonor.contactNo',
                label: 'donorAndDonations.labels.mobile'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.mobile'.getLabel(),
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (value?.toString()?.length > 10) {
                    return prevValue;
                  }
                  return value;
                },
              },
              {
                type: 'text',
                field: 'bloodDonor.occupation',
                placeholder: 'donorAndDonations.placeholders.occupation'.getLabel(),
                label: 'donorAndDonations.labels.occupation'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 2,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'bloodDonor.address',
                label: 'patient.labels.address'.getLabel(),
                placeholder: 'patient.placeholders.address'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        columnsPerRow: 3,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'bloodDonor.description',
                label: 'donorAndDonations.labels.additionalInfo'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.additionalInfo'.getLabel(),
              },
              {
                type: 'file',
                field: 'attachment',
                render: (item) => {
                  const value = item?.data?.attachment;
                  if (Array.isArray(value)) {
                    return value.map((file) => <FileLink file={file} />);
                  }
                },
                label: 'donorAndDonations.labels.file'.getLabel(),
                placeholder: 'donorAndDonations.placeholders.file'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'DONATION INFORMATION',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 4,
        },
        columnsPerRow: 4,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                label: 'donorAndDonations.labels.bagNo'.getLabel(),
                variant: 'filled',
                field: 'name',
              },
              {
                type: 'text',
                label: 'donorAndDonations.labels.tubeNo'.getLabel(),
                variant: 'filled',
                field: 'tubeNo',
              },
              {
                type: 'text',
                label: 'donorAndDonations.labels.bagWeight'.getLabel(),
                variant: 'filled',
                field: 'bagWeight',
              },
              {
                type: 'date',
                label: 'donorAndDonations.labels.donationDate'.getLabel(),
                variant: 'filled',
                field: 'date',
              },
            ],
          },
        ],
      },
    ],
  },
});

export default ViewBloodDonationForm;
