import React from 'react';

import { capitalize } from 'lodash';
import {
  Table,
  Text,
} from '../../../../../../app-components';
import { MODELS, ROUTES, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetPurchaseEntityRequestListUri } from '../../../../../../Queries/asset';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.date'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.assetName'.getLabel(),
        type: 'text',
        field: 'item.name',
      },
      {
        header: 'asset.headers.category'.getLabel(),
        type: 'text',
        field: 'item.category.name',
      },
      {
        header: 'asset.headers.subCategory'.getLabel(),
        type: 'text',
        field: 'item.subCategory.name',
      },
      {
        header: 'asset.headers.description'.getLabel(),
        type: 'text',
        field: 'reasonForRepair',
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        field: 'requestStatus',
        render: ({
          item: {
            isRepairRequestApproveByCentral = false, requestStatus = '',
          } = { },
        }) => {
          let purchaseRequestStatus = '';
          let statusColor = '';
          if (isRepairRequestApproveByCentral) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.APPROVE;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === purchaseSupplyOrderStatus.RAISED
            && !isRepairRequestApproveByCentral) {
            purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.RAISED);
            statusColor = vars.colors.emphasis;
          } else if (requestStatus === purchaseSupplyOrderStatus.PO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (requestStatus === purchaseSupplyOrderStatus.PO_APPROVED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_APPROVED;
            statusColor = vars.colors.warning;
          } else if (requestStatus === purchaseSupplyOrderStatus.SO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.SO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (requestStatus === purchaseSupplyOrderStatus.SO_APPROVED) {
            purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.APPROVED);
            statusColor = vars.colors.primary.color2;
          } else {
            purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.REJECTED);
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h8, color: statusColor }}>
              {purchaseRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text
                style={{
                  ...vars.headings.h11,
                  color: vars.colors.secondary.color2,
                }}
              >
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewRepairRequest.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.PURCHASE_REQUEST}`,
  uri: AssetPurchaseEntityRequestListUri({ filter: { type: 'repair' } }),
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewRepairRequest.name,
      params: { item },
    },
  }),
});
