import { ROUTES } from '../../../../../../Lib/constants';
import uploadSample from './MLCSamples/UploadSample';

const emergencyScreens = {
  [ROUTES.uploadMLCSample.name]: {
    screen: uploadSample,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 400,
    },
  },
};
export default emergencyScreens;
