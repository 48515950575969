import React from 'react';
import { View } from '../../../../app-components';
import { MODELS } from '../../../../Lib/constants';
import { EditSupplier } from '../../../../Queries/supplier';
import {
  validateAlphabetsWithSpaceOnly, validateEmail, validateInputLength, validateMobile,
} from '../../../../Lib/helpers';
import ViewSupplierTabs from './ViewSupplier/components/Tabs';
import { submit } from '../../../../AppServices';
import { Form } from '../../../../app-components/form/Form';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'asset.labels.supplierName'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
    mandatory: true,
  },
  {
    type: 'text',
    field: 'email',
    label: 'asset.labels.email'.getLabel(),
    mandatory: true,
  },
  {
    type: 'number',
    field: 'mobile',
    label: 'asset.labels.contact'.getLabel(),
    mandatory: true,
    allowedDecimalDigits: false,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (value?.toString()?.length > 10) {
        return prevValue;
      }
      return value;
    },
  },
  {
    type: 'textArea',
    field: 'address',
    label: 'asset.labels.address'.getLabel(),
    width: 830,
    minHeight: 75,
    mandatory: true,
  },
];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 46),
  description: ({ data }) => validateInputLength(data?.description, 0, 400),
  email: ({ data }) => validateEmail(data?.email),
  address: ({ data }) => validateInputLength(data?.address, 0, 200),
  mobile: ({ data }) => validateMobile(data?.mobile),
};

const EditSupplierForm = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        uri={EditSupplier}
        closeView={1}
        model={MODELS.PHARMACY_SUPPLIER}
        reloadEvent={`reload${MODELS.PHARMACY_SUPPLIER}`}
        onSubmit={submit({
          model: MODELS.PHARMACY_SUPPLIER,
        })}
        validations={validations}
        mandatory={{
          mobile: 1, email: 1, address: 1, name: 1,
        }}
        renderLoading={false}
        uniqueValidationMessage={'asset.messages.supplierAlreadyExists'.getLabel()}
        submitMessage={'asset.messages.supplierUpdatedSuccessfully'.getLabel()}
        formGroups={[
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groups: [
              {
                columns: [...formFields],
              },
            ],
          },
        ]}
        {...props}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewSupplierTabs {...props} />
    </View>
  </View>
);
export default EditSupplierForm;
