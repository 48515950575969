import React from 'react';

import {
  Table, Text,
} from '../../../../../../app-components';
import {
  MODELS, assetInventoryStockRequestStatus, ROUTES, purchaseSupplyOrderStatus,
} from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetIndentRequestsListUri } from '../../../../../../Queries/asset';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.dateRaised'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.indentNumber'.getLabel(),
        type: 'text',
        field: 'indentNumber',
      },
      {
        header: 'asset.headers.stockEntryDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        field: 'requestStatus',
        render: ({ item: { requestStatus } }) => {
          let indentRequestStatus = '';
          let statusColor = '';
          if (requestStatus === assetInventoryStockRequestStatus.approved) {
            indentRequestStatus = assetInventoryStockRequestStatus.approvedByMSI;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === assetInventoryStockRequestStatus.raised) {
            indentRequestStatus = assetInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          } else if (requestStatus === assetInventoryStockRequestStatus.stockProvided) {
            indentRequestStatus = assetInventoryStockRequestStatus.stockProvided;
            statusColor = vars.colors.primary.color2;
          } else {
            indentRequestStatus = assetInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {indentRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text
                style={{
                  ...vars.headings.h11,
                  color: vars.colors.secondary.color2,
                }}
              >
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewDepartmentIndentRecordAsset.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.ASSET_ITEM}`,
  uri: AssetIndentRequestsListUri({
    filter: {
      requestStatus: {
        $in: [purchaseSupplyOrderStatus.STOCK_PROVIDED],
      },
    },
  }),
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewDepartmentIndentRecordAsset.name,
      params: { item },
    },
  }),
});
