import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import { Text, View } from '../../../../../app-components';
import {
  autoSuggestInput, searchInput,
} from '../../../../../autoSuggestions';
import { MODELS, modules } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../../../app-components/form/Form';
import { validateAlphabetsWithSpaceOnly } from '../../../../../Lib/helpers';

const getNewId = () => `new_${uuid()}`;

const AddAsset = (props) => (
  <Form
    {...props}
    type="standardShadow"
    closeView={1}
    onSubmit={(params) => {
      const { data, updates } = params;
      const { data: { rateContract } } = params;
      rateContract.map((item) => {
        const { supplier = {}, manufacturer = {}, rateContractValidity = '' } = item;
        if (supplier?._id || rateContractValidity || manufacturer?._id) {
          if (!manufacturer._id || !rateContractValidity) {
            throw new Error(
              'Either add Supplier, Manufacturer and Rate Contract Validity or leave all empty.',
            );
          }
        }
        return true;
      });
      const submitAPI = submit({
        model: MODELS.ASSET_ITEM,
      });
      const result = submitAPI({ data, updates });
      return result;
    }}
    reloadEvent={`reload${MODELS.ASSET_ITEM}`}
    reloadOnChangeEvent={`reload${MODELS.ASSET_ITEM}`}
    defaultValues={() => ({
      isActive: true,
      rateContract: [{ _id: getNewId() }],
    })}
    computations={{
      children: {
        rateContract: {
          self: {
            rateContractComputations: {
              compute: () => ({ set: { manufacturer: '', rateContractValidity: '' } }),
              onChange: ['supplier'],
            },
          },
        },
      },
      self: {
        subCategory: {
          compute: () => ({ set: { subCategory: '' } }),
          onChange: ['category'],
        },
      },
    }}
    // validations={{
    //   rateContract: {
    //     manufacturer: ({ data }) => {
    //       if (data?.supplier?.code && !data.manufacturer) return 'This Field can not be Empty';
    //       return '';
    //     },
    //     rateContractValidity: ({ data }) => {
    //       if (data?.supplier?.code && !data?.rateContractValidity)
    // return 'This Field can not be Empty';
    //       return '';
    //     },
    //   },
    // }}
    submitMessage={'asset.messages.itemAddedSuccessfully'.getLabel()}
    uniqueValidationMessage={'asset.messages.itemAlreadyExists'.getLabel()}
    mandatory={{
      name: 1,
      category: 1,
      subCategory: 1,
      description: 1,
      isActive: 1,
      reorderLevel: 1,
    }}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          label: 'asset.title.assetDetails'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'asset.labels.assetName'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                },
                searchInput({
                  field: 'category',
                  label: 'asset.labels.category'.getLabel(),
                  placeholder: 'asset.placeholders.category'.getLabel(),
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.PHARMACY_CATEGORY,
                  query: 'masterDataList',
                  mandatory: true,
                }, {
                  addOnFilter: { module: modules.asset },
                }),
                autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'subCategory',
                  label: 'asset.labels.subCategory'.getLabel(),
                  placeholder: 'asset.placeholders.subCategory'.getLabel(),
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.PHARMACY_SUBCATEGORY,
                  query: 'masterDataList',
                  mandatory: true,
                },
                {
                  addOnFilter: ({ data: { category: { _id } } }) => ({
                    module: modules.asset,
                    category: { _id },
                  }),
                }),
                {
                  type: 'number',
                  field: 'reorderLevel',
                  label: 'asset.labels.reorderLevel'.getLabel(),
                  placeholder: 'asset.placeholders.reorderLevel'.getLabel(),
                  mandatory: true,
                },

              ],
            },
            {
              columnsPerRow: 3,
              columns: [
                {
                  type: 'toggleSwitch',
                  field: 'isActive',
                  label: 'asset.labels.status'.getLabel(),
                  container: 'leftLabel',
                  mandatory: true,
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'asset.labels.description'.getLabel(),
                  placeholder: 'asset.placeholders.description'.getLabel(),
                  minHeight: 80,
                  mandatory: true,
                  style: { flex: 2, overflow: 'hidden' },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'asset.title.manufacturerSupplierDetails'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {
                  field: 'rateContract',
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [{
                        render: () => (
                          <View style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 5,
                            width: 120,
                            height: 40,
                            borderColor: 'grey',
                          }}
                          >
                            <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                              {`+ ${'asset.labels.addRow'.getLabel()}`}
                            </Text>
                          </View>
                        ),
                        onPress: addRow,
                      }],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        searchInput({
                          field: 'supplier',
                          variant: 'filled',
                          label: 'asset.labels.supplier'.getLabel(),
                          placeholder: 'asset.placeholders.supplier'.getLabel(),
                          keyField: 'name',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.PHARMACY_SUPPLIER,
                          query: 'masterDataList',
                        },
                        {
                          addOnFilter: ({
                            data:
                              { _parent: { rateContract = [] } = {} },
                          } = {}) => {
                            const supplierIds = rateContract?.filter(
                              ({ supplier }) => supplier?._id,
                            ).map(({ supplier }) => supplier?._id);
                            return {
                              _id: { $nin: supplierIds },
                              module: modules.asset,
                            };
                          },
                        }),
                        searchInput({
                          field: 'manufacturer',
                          variant: 'filled',
                          label: 'asset.labels.manufacturer'.getLabel(),
                          placeholder: 'asset.placeholders.manufacturer'.getLabel(),
                          keyField: 'name',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.PHARMACY_MANUFACTURER,
                          query: 'masterDataList',
                        },
                        {
                          addOnFilter: ({ data: { supplier: { _id } } }) => ({ supplier: { _id } }),
                        }),
                        {
                          type: 'date',
                          field: 'rateContractValidity',
                          variant: 'filled',
                          label: 'asset.labels.rateContractValidity'.getLabel(),
                          placeholder: 'asset.placeholders.rateContractValidity'.getLabel(),
                        },
                        NestedAction(),
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default AddAsset;
