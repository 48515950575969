import { ROUTES } from '../../../../../../Lib/constants';
import ViewTreatment from './PsychologicalTreatment/ViewTreatment';
import LogTreatment from './PsychologicalTreatment/LogTreatment';
import ViewDetail from './ECTNotes/ViewNote';
import LogNote from './ECTNotes/LogNotes';

const psychiatryScreens = {
  [ROUTES.viewECTNotesPsychiatry.name]: {
    screen: ViewDetail,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 400,
    },
  },
  [ROUTES.logECTNotesPsychiatry.name]: {
    screen: LogNote,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 400,
    },
  },
  [ROUTES.logPsychologicalTreatment.name]: {
    screen: LogTreatment,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 400,
    },
  },
  [ROUTES.viewPsychologicalTreatment.name]: {
    screen: ViewTreatment,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 400,
    },
  },
};

export default psychiatryScreens;
