/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import BarcodeReader from 'react-barcode-reader';

class BarCodeReader extends Component {
  constructor(props) {
    super(props);
    this.handleScan = this.handleScan.bind(this);
  }

  handleScan(data) {
    this.props.handleScan(data);
  }

  handleError(err) {
    console.error(err);
  }

  render() {
    return (
      <div>
        <BarcodeReader
          onError={this.handleError}
          onScan={this.handleScan}
        />
        {/* <p>{this.state.result}</p> */}
      </div>
    );
  }
}

export default BarCodeReader;
