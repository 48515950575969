import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExamination = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
          'systematicExamination.isAnyAbnormalChestMotion': {
            compute: (data) => {
              if (data?.systematicExamination?.isAnyAbnormalChestMotion === false) {
                return ({
                  set: {
                    'systematicExamination.anyAbnormalChestMotion': '',
                  },
                });
              }
            },
            onChange: ['systematicExamination.isAnyAbnormalChestMotion'],
          },
          presentIllness: {
            compute: (data) => {
              if (data?.presentIllness === 'Non-Applicable') {
                return ({
                  set: {
                    presentIllnessInjuryName: '',
                    presentIllnessInjuryNameDescription: '',
                    presentIllnessInjuryDate: '',
                    presentIllnessInjuryDateDescription: '',
                    presentIllnessInjuryMode: '',
                    presentIllnessInjuryModeDescription: '',
                    presentIllnessInjurySight: '',
                    presentIllnessInjurySightDescription: '',
                  },
                });
              }
              if (data?.presentIllness === 'Applicable') {
                return ({
                  set: {
                    presentIllnessNonApplicableDescription: '',
                  },
                });
              }
            },
            onChange: ['presentIllness'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  {...autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'historyTakenBy',
                      label: 'History Taken By',
                      placeholder: 'History Taken By',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    // {
                    //   addOnFilter: () => ({
                    //     role: {
                    //       code: 'Supervisor',
                    //     },
                    //   }),
                    // }
                  )}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}
            </View>

            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Chief Complaints
              </Text>

              <FormField
                {...{
                  field: 'cheifComplaint',
                  fieldType: 'nested',
                  addInBottom: true,
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        {
                          type: 'textArea',
                          field: 'complaint',
                          variant: 'filled',
                          placeholder: 'Enter Complaint Here',
                          allowWhiteSpaces: true,
                          editable: true,
                        },
                        NestedAction({
                          containerStyle: {
                            marginTop: 25,
                          },
                        }),
                      ],
                    },
                  },
                }}
              />
            </View>
            {/* History of present Illness */}
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                &nbsp;&nbsp;History of Present Illness
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="presentIllness"
                  variant="filled"
                  container="topLabel"
                  options={['Applicable', 'Non-Applicable']}
                  style={{ marginLeft: 5 }}
                  labelContainerStyle={{ height: 0 }}
                  labelStyle={{ ...vars.headings.h14 }}
                />
                <FormField
                  type="text"
                  field="presentIllnessNonApplicableDescription"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllness } = {} } = disableProps;
                    return (presentIllness === 'Applicable');
                  }}
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 500,
                    minHeight: 30,
                    paddingLeft: 12,
                    fontSize: 14,
                    marginTop: 7,
                    marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />

              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 50,
                  }}
                >
                  i.&nbsp;&nbsp;
                </Text>
                <FormField
                  type="radioGroup"
                  field="presentIllnessInjuryName"
                  container="rightLabel"
                  variant="filled"
                  options={['Injury Name']}
                  disabled={(disableProps) => {
                    const { data: { presentIllness = false } = {} } = disableProps;
                    return !(presentIllness === 'Applicable');
                  }}
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 300,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,

                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  If Yes, is it&nbsp;&nbsp;
                </Text>
                <FormField
                  type="text"
                  field="presentIllnessInjuryNameDescription"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllnessInjuryName = false } = {} } = disableProps;
                    return !(presentIllnessInjuryName);
                  }}
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 1000,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 50,
                  }}
                >
                  ii.&nbsp;&nbsp;
                </Text>
                <FormField
                  type="radioGroup"
                  field="presentIllnessInjuryDate"
                  container="rightLabel"
                  variant="filled"
                  disabled={(disableProps) => {
                    const { data: { presentIllness = false } = {} } = disableProps;
                    return !(presentIllness === 'Applicable');
                  }}
                  options={['Date of Injury']}
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 300,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,

                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  If Yes, is it&nbsp;&nbsp;
                </Text>
                <FormField
                  type="text"
                  field="presentIllnessInjuryDateDescription"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllnessInjuryDate = false } = {} } = disableProps;
                    return !(presentIllnessInjuryDate);
                  }}
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 1000,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 50,
                  }}
                >
                  iii.&nbsp;&nbsp;
                </Text>
                <FormField
                  type="radioGroup"
                  field="presentIllnessInjuryMode"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllness = false } = {} } = disableProps;
                    return !(presentIllness === 'Applicable');
                  }}
                  variant="filled"
                  options={['Mode Of Injury']}
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 300,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  If Yes, is it&nbsp;&nbsp;
                </Text>
                <FormField
                  type="text"
                  field="presentIllnessInjuryModeDescription"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllnessInjuryMode = false } = {} } = disableProps;
                    return !presentIllnessInjuryMode;
                  }}
                  variant="filled"
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 1000,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 50,
                  }}
                >
                  iv.&nbsp;&nbsp;
                </Text>
                <FormField
                  type="radioGroup"
                  field="presentIllnessInjurySight"
                  container="rightLabel"
                  disabled={(disableProps) => {
                    const { data: { presentIllness = {} } = {} } = disableProps;
                    return !(presentIllness === 'Applicable');
                  }}
                  variant="filled"
                  options={['Sight Of Injury']}
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 300,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,

                  }}
                />
                <Text
                  style={{
                    ...vars.headings.h14,
                    marginTop: 15,
                    width: 150,
                  }}
                >
                  If Yes, is it&nbsp;&nbsp;
                </Text>
                <FormField
                  type="text"
                  field="presentIllnessInjurySightDescription"
                  container="rightLabel"
                  variant="filled"
                  disabled={(disableProps) => {
                    const { data: { presentIllnessInjurySight = false } = {} } = disableProps;
                    return !presentIllnessInjurySight;
                  }}
                  editable
                  allowWhiteSpaces
                  inputStyle={{
                    paddingTop: 5,
                    borderWidth: 0,
                    outline: 'none',
                  }}
                  style={{
                    width: 1000,
                    minHeight: 30,
                    paddingLeft: 2,
                    fontSize: 14,
                    marginTop: 7,
                    // marginLeft: 20,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                />
              </View>
            </View>
            {/* HISTORY OF */}
            {/* Previous Surgery */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                HISTORY OF
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                a.&nbsp;&nbsp;Previous Surgery
              </Text>
              <FormField
                type="textArea"
                field="previousSurgery"
                variant="filled"
                placeholder="Enter previous surgery details..."
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '100',
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <PastIllnessRow checkBoxLabel="Polio" fieldLabel="polio" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 100,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    2.
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Family */}
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                c.&nbsp;&nbsp;Family
              </Text>
              <FormField
                type="radioGroup"
                field="familySignificance"
                variant="filled"
                container="topLabel"
                options={['Significant', 'Non-Significant']}
                style={{ marginLeft: 5 }}
                labelContainerStyle={{ height: 0 }}
                labelStyle={{ ...vars.headings.h14 }}
              />
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  d.&nbsp;&nbsp;Personal
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    i.&nbsp;&nbsp;Appetite
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="personalData.appetite"
                    container="rightLabel"
                    editable
                    variant="filled"
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ii.&nbsp;&nbsp;Diet
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="personalData.diet"
                    variant="filled"
                    container="rightLabel"
                    options={['Non-Veg', 'Veg']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iii.&nbsp;&nbsp;Sleep
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.sleep"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Bowel
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bowelAndBladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Bladder
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Addiction
                  </Text>
                  <View
                    style={{
                      width: 100,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="personalData.addictionSmoking"
                      label="Smoking"
                      container="rightLabel"
                      variant="filled"
                      style={{
                        marginTop: 10,
                      }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="personalData.addictionAlcohol"
                        label="Alcohol"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: 131,
                        }}
                      >
                        <FormField
                          type="checkBox"
                          field="personalData.addictionOther"
                          label="Other"
                          container="rightLabel"
                          variant="filled"
                          labelStyle={{ ...vars.headings.h14 }}
                        />
                      </View>
                      <FormField
                        type="text"
                        field="personalData.addictionOtherText"
                        container="rightLabel"
                        variant="filled"
                        editable
                        allowWhiteSpaces
                        disabled={(disableProps) => {
                          const { data: { personalData = {} } = {} } = disableProps;
                          return !personalData.addictionOther;
                        }}
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          // marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Other
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.personalOther"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  e.&nbsp;&nbsp;Immnization
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="immunization.bcg"
                    label="BVG"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.hepb"
                      label="HEBP"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.covid"
                      label="Covid"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.polio"
                      label="Polio"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: 131,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="immunization.immunizationOther"
                        label="Other"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="text"
                      field="immunization.immunizationOtherText"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { immunization = {} } = {} } = disableProps;
                        return !immunization.immunizationOther;
                      }}
                      container="rightLabel"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        // marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Drug Allergies & Obsteric & Menstruation  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Drug Allergies */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Drug Allergies
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iv.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFour"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    v.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFive"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Obsteric & Menstruation */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  g.&nbsp;&nbsp;Obsteric & Menstruation
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;Gravida
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.gravida"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;Para
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.para"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;Lining
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.lining"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iV.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menarche"
                        label="Menarche"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menarcheAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menarche;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        v.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menopause"
                        label="Menopause"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menopauseAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menopause;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Examination  */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Examination
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* GPE */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    GPE
                  </Text>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      marginTop: 15,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        a.&nbsp;&nbsp;Time
                      </Text>
                    </View>
                    <FormField
                      // variant="filled"
                      {...newDateInput({
                        hour: { field: 'gpe.hour', label: 'Hour' },
                        minute: { field: 'gpe.minute', label: 'Minute' },
                        meridiem: { field: 'gpe.meridiem' },
                      })}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        b.&nbsp;&nbsp;Place
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.place"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        c.&nbsp;&nbsp;Person
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.person"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Orientation
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.orientation"
                      variant="filled"
                      container="rightLabel"
                      options={['Conscious', 'Unconscious']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        e.&nbsp;&nbsp;Built
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.built"
                      variant="filled"
                      container="rightLabel"
                      options={['Poor', 'Moderate', 'Well']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        f.&nbsp;&nbsp;Nourishment
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.nourishment"
                      variant="filled"
                      container="rightLabel"
                      options={['Poor', 'Well']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        g.&nbsp;&nbsp;Pulse*
                      </Text>
                    </View>
                    <View>
                      <FormField
                        type="text"
                        field="gpe.pulse"
                        container="rightLabel"
                        variant="filled"
                        allowWhiteSpaces
                        editable
                        mandatory
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 630,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        h.&nbsp;&nbsp;BP
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.bp"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        i.&nbsp;&nbsp;RR
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.rr"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                          width: 150,
                        }}
                      >
                        j.&nbsp;&nbsp;Temperature
                      </Text>
                    </View>
                    <FormField
                      type="text"
                      field="gpe.temprature"
                      container="rightLabel"
                      variant="filled"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 630,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        k.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.pallor"
                        label="Pallor"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        l.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.icterus"
                        label="Icterus"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        m.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.cynosis"
                        label="Cyanosis"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        n.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.clubbing"
                        label="Clubbing"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        o.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.koilonychia"
                        label="Koilonychia"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        p.
                      </Text>
                    </View>
                    <View style={{ width: 150, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.lymphadenopathy"
                        label="Lymphadenopathy"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        q.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="gpe.edema"
                        label="Edema"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If yes, then
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.edemaInput"
                      variant="filled"
                      container="rightLabel"
                      disabled={(disableProps) => {
                        const { data: { gpe = {} } = {} } = disableProps;
                        return !gpe.edema;
                      }}
                      options={['Local', 'General']}
                      optionStyle={{ flexDirection: 'row -reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                </View>

                {/* Systematic Examination */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,
                      fontWeight: 700,
                      marginTop: 15,
                    }}
                  >
                    Systematic Examination
                  </Text>
                  <Text style={{ marginLeft: 20, marginTop: 15 }}>CNS</Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h14,
                        marginTop: 15,
                      }}
                    >
                      a.&nbsp;&nbsp;GCS
                    </Text>
                    <FormField
                      type="number"
                      field="systematicExamination.gcs"
                      container="rightLabel"
                      variant="filled"

                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                    <Text style={{ marginTop: 15, marginLeft: 5 }}>/15</Text>
                  </View>
                  <View
                    style={{
                      width: 100,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 20,
                      }}
                    >
                      <View style={{ width: 150 }}>
                        <Text
                          style={{
                            ...vars.headings.h14,
                            marginTop: 15,
                          }}
                        >
                          i.&nbsp;&nbsp;Loss
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: 20,
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 15,
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            a.
                          </Text>
                          <FormField
                            label="Motor Loss"
                            type="checkBox"
                            field="systematicExamination.motorLossP"
                            variant="filled"
                            labelContainerStyle={{ height: 0 }}
                            labelStyle={{ ...vars.headings.h14 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 15,
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            b.
                          </Text>
                          <FormField
                            label="Motor Loss (A)"
                            type="checkBox"
                            field="systematicExamination.motorLossA"
                            variant="filled"
                            labelContainerStyle={{ height: 0 }}
                            labelStyle={{ ...vars.headings.h14 }}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 20,
                      }}
                    >
                      <View style={{ width: 150 }}>
                        <Text
                          style={{
                            ...vars.headings.h14,
                            marginTop: 15,
                          }}
                        >
                          ii.&nbsp;&nbsp;CVS - Heart Sounds
                        </Text>
                      </View>
                      <View
                        style={{
                          width: 100,
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: 20,
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            a.&nbsp;&nbsp;S1S2
                          </Text>
                          <FormField
                            type="radioGroup"
                            field="systematicExamination.s1s2"
                            variant="filled"
                            container="rightLabel"
                            options={['Normal', 'Abnormal']}
                            optionStyle={{ flexDirection: 'row-reverse' }}
                            labelContainerStyle={{ height: 0 }}
                            labelStyle={{ ...vars.headings.h14 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            b.
                          </Text>
                          <FormField
                            label="Murmurs"
                            type="checkBox"
                            field="systematicExamination.murmursP"
                            variant="filled"
                            labelContainerStyle={{ height: 0 }}
                            labelStyle={{ ...vars.headings.h14 }}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 20,
                      }}
                    >
                      <View style={{ width: 150 }}>
                        <Text
                          style={{
                            ...vars.headings.h14,
                            marginTop: 15,
                          }}
                        >
                          iii.&nbsp;&nbsp;RS - Breath Sounds
                        </Text>
                      </View>
                      <View
                        style={{
                          width: 100,
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: 20,
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            a.
                          </Text>
                          <FormField
                            label="B/L NVBS (P)"
                            type="checkBox"
                            field="systematicExamination.blNvbsP"
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            b.
                          </Text>
                          <FormField
                            label="Bi-Lateral"
                            type="checkBox"
                            field="systematicExamination.biLateral"
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            c.
                          </Text>
                          <FormField
                            label="Equal"
                            type="checkBox"
                            field="systematicExamination.equal"
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            d.
                          </Text>
                          <FormField
                            label="UnEqual"
                            type="checkBox"
                            field="systematicExamination.unEqual"
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            e.
                          </Text>
                          <FormField
                            label="Any Abnormal Chest Motion"
                            type="checkBox"
                            field="systematicExamination.isAnyAbnormalChestMotion"
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0 }}
                          />
                          <FormField
                            label=""
                            type="text"
                            field="systematicExamination.anyAbnormalChestMotion"
                            disabled={(disableProps) => {
                              const { data: { systematicExamination: { isAnyAbnormalChestMotion = false } = {} } = {} } = disableProps;
                              return !(isAnyAbnormalChestMotion);
                            }}
                            variant="filled"
                            labelStyle={{ ...vars.headings.h14 }}
                            labelContainerStyle={{ height: 0, marginLeft: 10 }}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 15,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h14,
                              marginRight: 20,
                            }}
                          >
                            c.&nbsp;&nbsp;Added&nbsp;Sounds
                          </Text>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginLeft: 20,
                              alignItems: 'center',
                              marginTop: 15,
                            }}
                          >
                            <Text
                              style={{
                                ...vars.headings.h14,
                                marginRight: 20,
                              }}
                            >
                              i.
                            </Text>
                            <FormField
                              label="Wheeze"
                              type="checkBox"
                              field="systematicExamination.wheeze"
                              variant="filled"
                              labelStyle={{ ...vars.headings.h14 }}
                              labelContainerStyle={{ height: 0 }}
                            />
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 20,
                              marginTop: 15,
                            }}
                          >
                            <Text
                              style={{
                                ...vars.headings.h14,
                                marginRight: 20,
                              }}
                            >
                              ii.
                            </Text>
                            <FormField
                              label="Rhonchi"
                              type="checkBox"
                              field="systematicExamination.rhonchi"
                              variant="filled"
                              labelStyle={{ ...vars.headings.h14 }}
                              labelContainerStyle={{ height: 0 }}
                            />
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 20,
                            }}
                          >
                            <Text
                              style={{
                                ...vars.headings.h14,
                                marginRight: 20,
                              }}
                            >
                              iii.&nbsp;&nbsp;Crept
                            </Text>
                            <FormField
                              type="radioGroup"
                              field="systematicExamination.crept"
                              variant="filled"
                              container="rightLabel"
                              options={['Fine', 'Coarse']}
                              labelStyle={{ ...vars.headings.h14 }}
                              optionStyle={{ flexDirection: 'row-reverse' }}
                              labelContainerStyle={{ height: 0 }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {/* Local Examination */}
              <View
                style={{
                  width: '100%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h9,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  Local Examination
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <FormField
                    {
                      ...autoSuggestInput({
                        label: 'Axial Skeleton',
                        field: 'axialSkeleton',
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        // query: 'masterDataListForTable',
                        // model: MODELS.COMPANY_MANAGEMENT,
                      })
                      }
                    style={{
                      // width: 00,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    {
                    ...autoSuggestInput({
                      label: 'Limb',
                      field: 'spine',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      options: ['Upper Limb', 'Lower Limb'],
                      // query: 'masterDataListForTable',
                      // model: MODELS.COMPANY_MANAGEMENT,
                    })
                    }
                    style={{
                      // width: 1300,
                      marginLeft: 20,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    {
                    ...autoSuggestInput({
                      label: 'Side',
                      field: 'cervical',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      options: ['Right', 'Left'],

                      // query: 'masterDataListForTable',
                      // model: MODELS.COMPANY_MANAGEMENT,
                    })
                    }
                    style={{
                      // width: 1300,
                      marginLeft: 20,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 1300,
                    }}
                  >
                    i.&nbsp;&nbsp;Inspection
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    a.&nbsp;&nbsp;Attitude
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionAttitude"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    b.&nbsp;&nbsp;Wounds
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionWounds"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    c.&nbsp;&nbsp;Wasting
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionWasting"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    d.&nbsp;&nbsp;Gross Deformity
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionGrossDeformity"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    e.&nbsp;&nbsp;Assymetry
                  </Text>
                  <FormField
                    type="radioGroup"
                    allowWhiteSpaces
                    options={['Yes', 'No']}
                    field="localExamination.inspectionAssymetry"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,

                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    f.&nbsp;&nbsp;Swelling
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionSwelling"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    g.&nbsp;&nbsp;Others
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.inspectionOthers"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                {/* Palpation */}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    b.&nbsp;&nbsp;Palpation
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    a.&nbsp;&nbsp;Bony LandMarks Asymmetry
                  </Text>
                  <FormField
                    type="radioGroup"
                    allowWhiteSpaces
                    options={['Yes', 'No']}
                    field="localExamination.palpationBonyLandmark"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,

                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    b.&nbsp;&nbsp;Bony Tenderness
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.palpationBonyTenderness"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    c.&nbsp;&nbsp;Wounds
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.palpationWounds"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    d.&nbsp;&nbsp;Abnormal Mobility
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.palpationAbnormalMobility"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    e.&nbsp;&nbsp;Crepitus
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.palpationCrepitus"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    f.&nbsp;&nbsp;Loss Of Transmission Movement
                  </Text>
                  <FormField
                    type="radioGroup"
                    allowWhiteSpaces
                    options={['Yes', 'No']}
                    field="localExamination.palpationLossOfTransmissionMovement"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,

                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 220,
                    }}
                  >
                    g.&nbsp;&nbsp;ROM
                  </Text>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 40,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    1.&nbsp;&nbsp;Flexion(degrees)
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.flexionDegree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />

                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 40,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    2.&nbsp;&nbsp;Extension
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.extension"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />

                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 40,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    3.&nbsp;&nbsp;I/R
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.ir"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />

                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 40,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    4.&nbsp;&nbsp;E/R
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.er"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    h.&nbsp;&nbsp;Abduction
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.abduction"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    i.&nbsp;&nbsp;Adduction
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.adduction"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    j.&nbsp;&nbsp;Distal neurological Status
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.distalNeuroLogicalStatus"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 30,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    k.&nbsp;&nbsp;Distal Vascular Status
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.distalVascularStatus"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 400,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 190,
                    }}
                  >
                    iii.&nbsp;&nbsp;Special Tests
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="localExamination.specialTests"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExamination;
