import React from 'react';
import moment from 'moment';

import { viewICUOrdersUri } from '../../../../../../../../Queries/ipdOrders';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { Text } from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import vars from '../../../../../../../../theme/vars';

const ViewICUOrdersForm = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  const { navigation: { state: { params: { dataParams } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="ORDERS"
      description={`Orders of Patient for ${moment(dataParams?.orderDate).format('DD/MM/YYYY')}`}
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_ICU_ORDER}`}
          uri={viewICUOrdersUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    labelStyle: {
                      ...vars.headings.h8,
                      color: vars.colors.grey.color2,
                      textTransform: 'uppercase',
                      paddingLeft: 10,
                    },
                    groupsContainerStyle: { paddingLeft: 12 },
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'date',
                            label: 'DATE',
                            field: 'orderDate',
                            format: 'DD/MM/YYYY',
                            editable: false,
                          },
                          {
                            type: 'date',
                            label: 'TIME',
                            field: 'orderTime',
                            format: 'HH:mm A',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -18 },
                    containerStyle: { paddingLeft: 10 },
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                MEDICATION ORDERS
                              </Text>
                            ),
                          },
                          {
                            render: () => 'a.  Hrly PO/i.v./i.m.',
                            style: {
                              fontSize: 12, color: 'gray',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -32 },
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            label: '1. ',
                            field: 'medicationOrders_hrOne',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '2. ',
                            field: 'medicationOrders_hrTwo',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '3. ',
                            field: 'medicationOrders_hrThree',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '4. ',
                            field: 'medicationOrders_hrFour',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '5. ',
                            field: 'medicationOrders_hrFive',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '6. ',
                            field: 'medicationOrders_hrSix',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '7. ',
                            field: 'medicationOrders_hrSeven',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '8. ',
                            field: 'medicationOrders_hrEight',
                            container: 'leftLabel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: '9. ',
                            field: 'medicationOrders_hrNine',
                            container: 'leftLabel',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -18 },
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                INOTROPES
                              </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { padding: 12, marginTop: -32 },
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            render: () => 'Dopa Mine ',
                            style: {
                              fontSize: 12, color: 'grey',
                            },
                            width: '50%',
                          },
                          {
                            render: () => 'Noradrenaline ',
                            style: {
                              fontSize: 12, color: 'grey',
                            },
                            width: '50%',
                          },
                          {
                            render: () => `${props?.navigation?.state?.params?.dataParams?.dopamineUgPerKgPerMin} ug/kg/min,  ${props?.navigation?.state?.params?.dataParams?.dopamineMlPerHr} ml/hr`,
                            width: '50%',
                          },
                          {
                            render: () => `${props?.navigation?.state?.params?.dataParams?.noradrenalineUgPerKgPerMin} ug/kg/min,  ${props?.navigation?.state?.params?.dataParams?.noradrenalineMlPerHr} ml/hr`,
                            width: '50%',
                          },
                          {
                            render: () => 'Dobutamine ',
                            style: {
                              fontSize: 12, color: 'grey',
                            },
                            width: '50%',
                          },
                          {
                            render: () => 'Nitroglycerine ',
                            style: {
                              fontSize: 12, color: 'grey',
                            },
                            width: '50%',
                          },
                          {
                            render: () => `${props?.navigation?.state?.params?.dataParams?.dobutamineUgPerKgPerMin}  ug/kg/min, ${props?.navigation?.state?.params?.dataParams?.dobutamineMlPerHr} ml/hr`,
                            width: '50%',
                          },
                          {
                            render: () => `${props?.navigation?.state?.params?.dataParams?.nitroglycerineUgPerKgPerMin}  ug/kg/min, ${props?.navigation?.state?.params?.dataParams?.nitroglycerineMlPerHr} ml/hr`,
                            width: '50%',
                          },
                          {
                            type: 'text',
                            label: 'Others',
                            field: 'others',
                            editable: false,
                            style: {
                              fontSize: 12,
                            },
                            labelStyle: {
                              fontSize: 5,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -18 },
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                NEBULIZERS
                              </Text>
                            ),
                          },
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                FLUIDS
                              </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -32 },
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            label: 'Salbutamol (hrly)',
                            field: 'nebulizersDalbutamolHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Crystalloids',
                            field: 'fluidsCrystalloids',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Mucolytic Agent (hrly)',
                            field: 'nebulizersMucolyticAgentHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Colloids',
                            field: 'fluidsColloids',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'nebulizersBidecortHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Blood/Blood Products',
                            field: 'fluidsBloodOrBloodProducts',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Others (hrly)',
                            field: 'nebulizersOthersHr',
                            variant: 'filled',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -18 },
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                RESPIRATORY INSTRUCTIONS
                              </Text>
                            ),
                          },
                          {
                            render: () => (
                              <Text style={{
                                fontSize: 14,
                              }}
                              >
                                NUTRITION
                              </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    groupsContainerStyle: { paddingLeft: 12, marginTop: -32 },
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            label: 'Tracheal Suction (hrly)',
                            field: 'respiratoryInstrumentsTrachealSuctionHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Nil Orally',
                            field: 'nutritionNilOrally',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Chest Physiotherepy (hrly)',
                            field: 'respiratoryInstrumentsChestMucolyticAgentHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Parentral',
                            field: 'nutritionParentral',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Stem Inhalation (hrly)',
                            field: 'respiratoryInstrumentsStemInhalationHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Orally',
                            field: 'nutritionOrally',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Incentive spirometry (hrly)',
                            field: 'respiratoryInstrumentsIncentiveSpirometryHr',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Thromboprophylaxis',
                            field: 'nutritionThromboprophylaxis',
                            variant: 'filled',
                            editable: false,
                          },
                          {
                            type: 'radioGroup',
                            label: 'Option',
                            field: 'nutritionLiquidSaltFreeSemiSolidFull',
                            variant: 'filled',
                            container: 'topLabel',
                            options: ['Liquid', 'salt free', 'semi-solid', 'Full'],
                            optionStyle: { flexDirection: 'row-reverse' },
                            editable: true,
                            disabled: true,
                            width: '50%',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />
  );
};

const ViewOrders = (props) => (<ViewICUOrdersForm {...props} />);
export default ViewOrders;
