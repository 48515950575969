import PharmacyInventoryScreens from './Pharmacy';
import AssetConsumablesScreens from './AssetConsumables';
import BloodBankScreens from './BloodBank';
import Assets from './Assets';
import PurchaseDepartmentScreen from './Purchase';

const InventoryScreens = {
  ...PharmacyInventoryScreens,
  ...BloodBankScreens,
  ...Assets,
  ...PurchaseDepartmentScreen,
  ...AssetConsumablesScreens,
};

export default InventoryScreens;
