import React from 'react';

import { View } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import { validateInputLength, validToAndFromDate } from '../../../Lib/helpers';
import ViewHolidayTabs from './Components/tabs';
import { EditHolidaysCalendar } from '../../../Queries/holidaysCalendar';
import { submit } from '../../../AppServices';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'holidayCalendar.labels.year'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.year'.getLabel(),
    mandatory: true,
  },
  {
    type: 'date',
    field: 'fromDate',
    label: 'holidayCalendar.labels.fromDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.fromDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'date',
    field: 'toDate',
    label: 'holidayCalendar.labels.toDate'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.toDate'.getLabel(),
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'holidayCalendar.labels.status'.getLabel(),
    mandatory: true,
  },
  {
    type: 'file',
    field: 'attachment',
    label: 'holidayCalendar.labels.file'.getLabel(),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'holidayCalendar.labels.description'.getLabel(),
    placeholder: 'holidayCalendar.placeholders.description'.getLabel(),
  },
];
const validations = {
  fromDate: ({ data }) => validToAndFromDate(data),
  toDate: ({ data }) => validToAndFromDate(data),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

const EditHolidaysCalendars = (props) => (
  <View
    style={{
      flex: 1,
      overflow: 'hidden',
    }}
  >
    <View style={{ flex: 2 }}>
      <Form
        uri={EditHolidaysCalendar}
        validations={validations}
        onSubmit={submit({
          model: MODELS.HOLIDAYCALENDAR,
        })}
        closeView={1}
        submitMessage={'holidayCalendar.messages.holidayCalendarUpdatedSuccessfully'.getLabel()}
        mandatory={{
          name: 1,
          fromDate: 1,
          toDate: 1,
        }}
        model={MODELS.HOLIDAYCALENDAR}
        columnsPerRow={4}
        formGroups={[
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groups: [
              {
                columns: [...formFields],
              },
            ],
          },
        ]}
        defaultValues={() => ({
          isActive: true,
        })}
        {...props}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden', marginBottom: 10 }}>
      <ViewHolidayTabs {...props} />
    </View>
  </View>

);

export default EditHolidaysCalendars;

