import React, { useCallback, useState } from 'react';

import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { useTooltip, Tooltip } from '@vx/tooltip';
import useResizeObserver from 'use-resize-observer';
import moment from 'moment';
import { localPoint } from '@vx/event';
import { Text, TouchableOpacity, View } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';
import { ROUTES } from '../../../../../../../../Lib/constants';

const numTrickValues = () => {
  const trick = [5, 10, 20, 30, 40, 50, 60];
  for (let i = 60; i <= 375; i += 15) {
    trick.push(i);
  }
  return trick;
};
const tooltipTimeout = 0;
const defaultMargin = {
  top: 2,
  right: 0,
  bottom: 0,
  left: 10,
};
const x = (d) => d.time;
const yHR = (d) => d.HR;

const MonitoringGraph = (props) => {
  const { data = [], navigation = {} } = props;
  const {
    // showTooltip,
    // hideTooltip,
    // tooltipOpen,
    // tooltipData,
    // tooltipLeft,
    // tooltipTop,
    margin = defaultMargin,
  } = useTooltip();
  const [tooltipData, setTooltipData] = useState();
  const [showTooltip, setShowTooltip] = useState();
  const [insideTooltip, setInsideTooltip] = useState();
  const handleTooltip = useCallback((event, toolTipData) => {
    // eslint-disable-next-line no-shadow
    const { x, y } = localPoint(event);
    setTooltipData({ ...toolTipData, position: { x, y } });
    setShowTooltip(true);
  }, [setTooltipData]);
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const xMax = width - 120;
  const yMax = height - 80;
  const xScale = scaleLinear({
    rangeRound: [0, xMax],
    domain: [0, 375],
  });
  const yScale = scaleLinear({
    rangeRound: [0, yMax],
    domain: [220, 0],
  });
  numTrickValues();
  return (
    <div
      style={{
        width: '95%',
        height: '100%',
        background: '#EFF3FB',
        marginLeft: '15px',
        marginTop: '32px',
      }}
      ref={ref}
    >
      <View
        style={{
          color: '#4F4F4F',
          paddingLeft: 5,
          margin: 5,
          width: '200px',
          marginLeft: 'auto',
        }}
      >
        <text
          x={xScale(x(width + 100))}
          y={yScale(yHR(height))}
          stroke="#828282"
          style={{
            fontSize: 18,
            position: 'absolute',
            color: '#828282',
            lineHeight: -4,
            textAlign: 'left',
            padding: '20px',
          }}
        >
          ⊞ SPO2
          <br />
          ⌼ HR
          <br />
          ⊠ ETCO2
          <br />
          ⍌ SBP
          <br />
          ⍓ DBP
          <br />
          ⍄ CVP
          <br />
          ⍃ TEMP
        </text>
      </View>
      <svg width={width} height={height}>
        <Group top={25} left={65}>
          <AxisLeft scale={yScale} numTicks={22} />
          <AxisBottom
            scale={xScale}
            label="TIME"
            tickValues={numTrickValues()}
            top={yMax}
          />
          {data.map((point, pointIndex) => {
            let xCoordinate = 0;
            let yCoordinate = 0;
            let symbol = '';
            const dataPoints = Object.keys(point);
            return dataPoints.map((eachDataPoint) => {
              if (eachDataPoint === 'spo2') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⊞';
              }
              if (eachDataPoint === 'hr') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⌼';
              }
              if (eachDataPoint === 'etco2') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⊠';
              }
              if (eachDataPoint === 'sbp') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⍌';
              }
              if (eachDataPoint === 'dbp') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⍓';
              }
              if (eachDataPoint === 'cvp') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⍄';
              }
              if (eachDataPoint === 'temp') {
                xCoordinate = point.time || 0;
                yCoordinate = point[eachDataPoint];
                symbol = '⍃';
              }

              return (
                <text
                  key={pointIndex}
                  x={xScale(xCoordinate)}
                  y={yScale(yCoordinate)}
                  stroke="#828282"
                  style={{
                    fontSize: 22,
                  }}
                  onMouseLeave={() => {
                    setShowTooltip(false);
                  }}
                  onMouseEnter={(event) => {
                    handleTooltip(event, point);
                  }}
                >
                  {symbol}
                </text>
              );
            });
          })}
        </Group>
      </svg>

      {tooltipData && (showTooltip || insideTooltip) && (
        <div
          onMouseEnter={() => setInsideTooltip(true)}
          onMouseLeave={() => setInsideTooltip(false)}
          style={{
            position: 'absolute',
            left: tooltipData?.position?.x,
            top: tooltipData?.position?.y,
          }}
        >
          <div style={{
            background: '#ffffff',
            justifyContent: 'flex-start',
            border: 4,
            padding: '5px',
            borderRadius: '5px',
          }}
          >
            <TouchableOpacity
              style={{ backgroundColor: 'white', paddingTop: '5px', alignItems: 'center' }}
              onPress={() => {
                navigation.push({
                  view: ROUTES.editMonitoringDetails.name,
                  params: tooltipData,
                });
              }}
            >
              <Text style={{
                textAlign: 'center',
                padding: '8px',
                width: '50px',
                backgroundColor: vars.colors.secondary.color3,
                borderRadius: 5,
                paddingTop: 5,
                fontSize: 'larger',
                fontWeight: 900,
                color: 'white',
                cursor: 'pointer',
              }}
              >
                Edit
              </Text>
            </TouchableOpacity>
          </div>
        </div>
      )}

    </div>
  );
};

export default MonitoringGraph;
