export const CommonSalaryTemplate = (
  { addOnFilter = {}, query },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      is_master_component: true,
      ...addOnFilter,
    },
  },
  model: 'EmployeeSalaryComponent',
});
