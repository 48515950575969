import { MODELS } from '../Lib/constants';

export const editTemperaturePulseUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART,
    id: 'temperaturePulseChartIPDDataList',
    relationValue: {
      model: MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART,
      _id: navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.IPD_TEMPERATURE_PULSE_TIME_CHART,
});
