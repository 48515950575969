import { MODELS } from '../Lib/constants';

export const viewDailyExaminationNoteUri = (filter) => ({
  query: {
    id: 'dailyExaminationNotesDataListIPD',
    addOnFilter: {
      _id: filter._id,
      speciality: filter?.dataParams?.filters?.specialityFilter,
    },
  },
  model: MODELS.IPD_DAILY_EXAMINATION_NOTES,
});
