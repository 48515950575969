import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator, FormField, Text, TouchableOpacity, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { fetch } from '../../../../AppServices';
import FileLink from '../../../../Components/FileLink';
import ListActionModal from '../../../../Components/ListActionModal';
import { history } from '../../../../images';
import { MODELS, vaccinationStatus } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import printPharmacyBill from '../../../../Wired/PatientInvoices';
import VaccinationStatusRender from '../../../../Wired/VaccinationListOfPatient/VaccinationStatusRender';

const ViewVaccineDetailModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item,
        } = {},
      } = {},
    } = {},
  } = props;
  const [itemDetails, setItemDetails] = useState();
  const [loading, setLoading] = useState(true);
  const fetchItemDetails = useCallback(async () => {
    const fetchedItemDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientVaccinationList',
            addOnFilter: {
              patientVaccineDetails: { item: { _id: item?.item?._id } },
              patient: { _id: item?.patient?._id },
            },
          },
          model: MODELS.VACCINATION,
        },
      },
    });
    let itemDetailsObject = {};
    const details = [];
    if (fetchedItemDetails?.data) {
      const { data } = fetchedItemDetails;
      data.map((vaccinationDetails) => {
        const { patientVaccineDetails } = vaccinationDetails;
        let dose = 0;
        patientVaccineDetails.map((data) => {
          if (data?.item?._id === item?.item?._id) {
            dose = data?.dose;
          }
        });
        const nurseName = `${vaccinationDetails?.session?.nurse?.firstName} ${vaccinationDetails?.session?.nurse?.lastName}`;
        itemDetailsObject = {
          _createdOn: vaccinationDetails?._createdOn,
          dose,
          ODPNumber: vaccinationDetails?.consultation?.ODPNumber,
          nurseName,
          status: vaccinationDetails?.status,
          billing: vaccinationDetails?.billing,
        };
        details.push(itemDetailsObject);
      });
      setItemDetails(details);
    }
    setLoading(false);
    return fetchedItemDetails;
  }, [item]);
  useEffect(() => {
    fetchItemDetails();
  }, [fetchItemDetails]);
  if (loading) return <ActivityIndicator />;
  return (
    <ListActionModal
      icon={history}
      title="Vaccination Logs Item Details"
      description={`List of Vaccination of ${item?.patient?.patientName}`}
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({
            vaccineDoseDetail: itemDetails,
          })}
          {...props}
        >
          <View
            style={{
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 32,
              paddingRight: 22,
            }}
          >
            <View style={{ flexDirection: 'row', marginTop: 24 }}>
              <Text style={{ ...vars.headings.h9 }}>
                VACCINE INFORMATION
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <View style={{ flex: 1 }}>
                <FormField
                  editable={false}
                  type="text"
                  label={'vaccination.labels.vaccineName'.getLabel()}
                  variant="filled"
                  value={item?.item?.name}
                />
              </View>
              <View style={{ flex: 1 }}>
                <FormField
                  editable={false}
                  type="number"
                  label={'vaccination.headers.doseNo'.getLabel()}
                  variant="filled"
                  render={() => {
                    if (item.vaccineDoseTaken) {
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {item.vaccineDoseTaken + 1}
                        </Text>
                      );
                    }
                    return 1;
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <FormField
                  editable={false}
                  type="number"
                  label={'vaccination.headers.dosePending'.getLabel()}
                  variant="filled"
                  render={() => {
                    const { vaccineDoseTaken } = item;
                    const { item: { noOfDoses } = {} } = item;
                    if (vaccineDoseTaken) {
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {noOfDoses - vaccineDoseTaken}
                        </Text>
                      );
                    }
                    return (
                      <Text style={{ ...vars.headings.h9 }}>
                        {noOfDoses}
                      </Text>
                    );
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <FormField
                  editable={false}
                  type="text"
                  label={'vaccination.labels.paymentType'.getLabel()}
                  variant="filled"
                  render={() => {
                    const { item: { isVaccineFree } = {} } = item;
                    if (isVaccineFree) {
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          Free
                        </Text>
                      );
                    }
                    return (
                      <Text style={{ ...vars.headings.h9 }}>
                        Paid
                      </Text>
                    );
                  }}
                />
              </View>
            </View>
            {itemDetails ? (
              <View>
                <View style={{ flexDirection: 'row', marginTop: 24 }}>
                  <Text style={{ ...vars.headings.h9 }}>
                    DOSE DETAILS
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                  <View>
                    <FormField
                      {...{
                        fieldVariant: 'filled',
                        field: 'vaccineDoseDetail',
                        fieldType: 'nested',
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowContainerStyle: {
                              minHeight: 32,
                              backgroundColor: vars.colors.white,
                            },
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              color: vars.colors.grey.color3,
                              numberOfLines: 1,
                              textTransform: 'upperCase',
                            },
                            columns: [
                              {
                                type: 'date',
                                header: 'vaccination.headers.vaccinationDate'.getLabel(),
                                field: '_createdOn',
                                variant: 'filled',
                                format: 'DD MMM YYYY',
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'number',
                                header: 'vaccination.headers.doseNo'.getLabel(),
                                field: 'dose',
                                variant: 'filled',
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'number',
                                header: 'vaccination.headers.opdNo'.getLabel(),
                                field: 'ODPNumber',
                                variant: 'filled',
                                editable: false,
                                width: 100,
                              },
                              {
                                type: 'number',
                                header: 'vaccination.headers.crNo'.getLabel(),
                                field: 'crNo',
                                variant: 'filled',
                                editable: false,
                                width: 100,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.nurseName'.getLabel(),
                                field: 'nurseName',
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.vaccinationStatus'.getLabel(),
                                render: ({ item: { status } = {} }) => {
                                  if (status === vaccinationStatus.vaccinated) {
                                    return (
                                      <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                                        Vaccinated
                                      </Text>
                                    );
                                  }
                                  if (status === vaccinationStatus.nurseAssign) {
                                    return (
                                      <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                                        Nurse Assigned
                                      </Text>
                                    );
                                  }
                                  if (status === vaccinationStatus.notAppear) {
                                    return (
                                      <Text style={{ ...vars.headings.h8, color: vars.colors.error }}>
                                        Not Appeared
                                      </Text>
                                    );
                                  }
                                  if (status === vaccinationStatus.reallign) {
                                    return (
                                      <Text style={{ ...vars.headings.h8, color: vars.colors.warning }}>
                                        Realligned
                                      </Text>
                                    );
                                  }
                                  return (
                                    <Text style={{ ...vars.headings.h8, color: vars.colors.warning }}>
                                      No Status
                                    </Text>
                                  );
                                },
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.bill'.getLabel(),
                                render: ({ item }) => {
                                  const { billing } = item;
                                  return (
                                    <TouchableOpacity onPress={({ submitResult }) => printPharmacyBill({ data: { ...billing }, submitResult })}>
                                      <Text style={{ color: vars.colors.secondary.color2 }}>
                                        Invoice_vcc_128.pdf
                                      </Text>
                                    </TouchableOpacity>
                                  );
                                },
                                field: 'bill',
                                editable: false,
                                width: 150,
                              },
                              {
                                type: 'text',
                                variant: 'filled',
                                header: 'vaccination.headers.prescription'.getLabel(),
                                render: ({ item }) => {
                                  const { billing } = item;
                                  if (billing?.documents) {
                                    return (
                                      <FileLink file={billing.documents} />
                                    );
                                  }
                                  return '--';
                                },
                                field: 'prescription',
                                editable: false,
                                width: 150,
                              },
                            ],
                          },
                        },
                      }}
                    />
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </Form>
      )}
      {...props}
    />
  );
};

export default ViewVaccineDetailModal;
