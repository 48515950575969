/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react';

import moment from 'moment';
import {
  View, Text, Image, save, TouchableOpacity, OutlineButton,
} from '../../../../../../../app-components';
import {
  Form,
} from '../../../../../../../app-components/form/Form';
import {
  Table,
} from '../../../../../../../app-components/table/Table';
import { breadCrumbBack, rupeeIcon1 } from '../../../../../../../images';
import { manazeV6Colors } from '../../../../../../../theme/manazeV6Colors';
import {
  Headline9,
  Headline4,
  Headline13,
  Headline11,
} from '../../../../../../../theme/Headlines';
import vars from '../../../../../../../theme/vars';
import { getConfiguration, getDownloadUrl, submit } from '../../../../../../../AppServices';
import { FormLayouts } from './layouts';
import { taxDeclarationDeductions } from '../../../../../../../Lib/constants';
// import { taxDeclarationDeductions } from '../../../../../../../Lib/constants';

const {
  neutral_4,
  neutral_7,
  primary_5,
  primary_2,
} = manazeV6Colors;

const {
  neutral_1,
  positive_communication_2,
  neutral_3,
  surface_white,
} = manazeV6Colors;

const taxDeclarationCardTheme = {
  titleGap: 6,
  style: {
    padding: 16,
    backgroundColor: vars.colors.white,
    borderRadius: 4,
    marginRight: 10,
    marginBottom: 6,
  },
  hoverStyle: {
    borderColor: neutral_7,
  },
  selectedStyle: {
    backgroundColor: primary_5,
    borderColor: primary_2,
  },
  hoverActionsWrapperStyle: {
    top: 1,
    bottom: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  topHoverActionsWrapperStyle: {
    top: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  bottomHoverActionsWrapperStyle: {
    bottom: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  selectedHoverActionsWrapperStyle: {
    backgroundColor: primary_5,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 6,
  },
  primaryTitleStyle: {
    ...vars.headings.h7,
    color: vars.colors.grey.color1,
  },
  secondaryTitleStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color1,
    marginTop: 10,
  },
  contentItemTextStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color1,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    justifyContent: 'center',
    marginRight: 4,
  },
  contentItemSeparatorStyle: {
    marginLeft: 8,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemSeparatorTextStyle: {
    ...Headline4,
    color: neutral_4,
  },
  leftContainerStyle: { marginTop: 35 },
  leftItemContainerStyle: {},
  rightContainerStyle: { marginTop: 35 },
  rightItemContainerStyle: {},
  rightItemTextStyle: {
    ...Headline11,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  rightItemIconContainerStyle: {
    justifyContent: 'center',
    marginRight: 4,
  },
  selectedAvatarStyle: {
    backgroundColor: surface_white,
  },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
  contentContainerStyle: {
    // paddingTop: 12,
    paddingBottom: 12,
    marginLeft: 6,
    marginRight: 6,
  },
};

const CommonDeclarationDetailsProps = {
  action: {
    type: 'link',
    link: (props) => {
      const { item, handleOnPressCard } = props;
      handleOnPressCard(item);
    },
  },
  skipRenderNoData: true,
};

const isDeductionVisible = (field, deductionDetails) => {
  if (deductionDetails?._id === field) {
    return true;
  }

  return false;
};

const deductionHelperDetails = {
  deduction_under_section_chapterVIA: {
    description: [],
    maxLimit: '',
  },
  other_deductions: {
    description: [
      'Includes Own Medical Insurance Premium (Limit - ₹ 25,000), For Parents (Limit - ₹ 25,000 (if age is <60 yrs) or ₹ 50,000 (if age is >60 yrs)).',
    ],
  },
  income_from_house_property: {
    description: [
      `Maximum limit upto ₹ 30000/- if the house is Self-Occupied and the loan was taken before 1st April 1999
        and upto ₹ 200000/- if the house is Self-Occupied and the loan was taken after 1st April 1999`,
    ],
  },
  lta: {
    // description: [],
    // maxLimit: 100
  },
  previous_employer_income: {
    // description: [],
    // maxLimit: 100
  },
  rent_recipts: {
    description: [
      '1. Only Delhi, Mumbai, Chennai and Kolkata are Metro cities, All other cities are Non-Metro.',
      '2. Bangalore, Gurugram and Noida are Non-Metro cities.',
      '3. If you have joined in the mid of the financial year, please enter the rent for the months after your date of joining.',
      '4. If monthly rent is above 8,333.00 INR, Landlord details & PAN is mandatory.',
    ],
  },
  income_on_higher_education_loan: {
    description: [
      'This section covers only the interest of the education loan, not the principal amount.',
    ],
  },
  donations: {
    description: [
      'The qualifying limit u/s 80 G is 10% of the adjusted gross total income',
    ],
  },
  flexi_component_details: {
    description: [
      '1.Children Education Allowance upto ₹ 100 per month per child up to a maximum of 2 children',
      '2.Leave Travel Allowance covers only the actual travel cost and only two journeys can be performed in a block year.',
      '3.Only the allowances in the compensation structure can be added.',
    ],
  },
};

const computaionSetActualAmount = ({ field }) => ({
  [field]: {
    self: {
      'set actual date as declared value': {
        compute: (data) => {
          if (data && data.declared_amount) {
            return { set: { amount: data.declared_amount, actual_amount: data.declared_amount } };
          }
          return {};
        },
        onChange: ['_id', 'declared_amount'],
      },
    },
  },
});

export const DeductionDetailFooter = (props) => {
  const {
    form_state: { data = {} },
    item,
  } = props;

  const deductionDetails = item;
  let chapter6Adescription = '';
  let Max_limit;
  if (data && deductionDetails._id === 'deduction_under_section_chapterVIA') {
    data
      && data.deduction_under_section_chapterVIA
      && data.deduction_under_section_chapterVIA.length
      && data.deduction_under_section_chapterVIA.forEach((el) => {
        Max_limit = el.saving_heads.max_limit;
        chapter6Adescription += `Includes ${el.saving_heads.name},`;
      });
    if (
      data !== {}
      && chapter6Adescription !== ''
      && deductionHelperDetails.deduction_under_section_chapterVIA.description
        .length === 0
    ) {
      deductionHelperDetails.deduction_under_section_chapterVIA.description.push(
        chapter6Adescription,
      );
      deductionHelperDetails.deduction_under_section_chapterVIA.maxLimit = Max_limit;
    }
  }
  return (
    <DeductionHelperComponent
      {...deductionHelperDetails[deductionDetails._id]}
      actual_amount={deductionDetails.actualAmount}
    />
  );
};

export const getEmployeeSalary = (
  {
    addOnFilter, query, paramValue = {}, model,
  },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
    paramValue,
    sort: {
      _id: -1,
    },
  },
  model,
});

const IncomeTaxDetailForEmployee = ({
  editable,
  uri,
  header,
  footer,
  item,
  data: taxData,
  setValue,
  addDeclaredAmount,
  addActualAmount,
  ...restProps
}) => (
  <Form
    onSubmit={submit({
      model: 'IncomeTax',
    })}
    bodyContainerStyle={{
      marginTop: 2,
      marginLeft: 10,
      backgroundColor: vars.colors.secondary.color4,
    }}
    {...restProps}
    editable
    closeView={0}
    uri={getEmployeeSalary(
      {
        model: 'IncomeTax',
        query: 'incomeTaxDetail',
        addOnFilter: { _id: item?.income_tax_id || '1234' },
      },
      { id: '_find' },
    )}
    header={{
      subTitleTextStyle: {
        ...vars.headings.h6,
        color: vars.colors.grey.color1,
      },
      containerStyle: {
        marginLeft: 10,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 12,
        paddingRight: 12,
        backgroundColor: vars.colors.secondary.color4,
        justifyContent: 'center',
      },
      bottomSeparator: true,
      subTitle: taxDeclarationDeductions[item._id],
      actions: [
        save({ label: 'Save' }),
      ],
    }}
    computations={{
      children: {
        ...computaionSetActualAmount({ field: 'income_from_house_property' }),
        ...computaionSetActualAmount({ field: 'other_deductions' }),
        ...computaionSetActualAmount({
          field: 'deduction_under_section_chapterVIA',
        }),
        ...computaionSetActualAmount({ field: 'lta' }),
        ...computaionSetActualAmount({ field: 'rent_recipts' }),
        ...computaionSetActualAmount({
          field: 'income_on_higher_education_loan',
        }),
        ...computaionSetActualAmount({ field: 'donations' }),
        flexi_component_details: {
          self: {
            'set actual date as declared value': {
              compute: (data) => {
                const result = {
                  jan_actual_amount:
                    data && data.jan_amount ? data.jan_amount : 0,
                  feb_actual_amount:
                    data && data.feb_amount ? data.feb_amount : 0,
                  mar_actual_amount:
                    data && data.mar_amount ? data.mar_amount : 0,
                  apr_actual_amount:
                    data && data.apr_amount ? data.apr_amount : 0,
                  may_actual_amount:
                    data && data.may_amount ? data.may_amount : 0,
                  jun_actual_amount:
                    data && data.jun_amount ? data.jun_amount : 0,
                  jul_actual_amount:
                    data && data.jul_amount ? data.jul_amount : 0,
                  aug_actual_amount:
                    data && data.aug_amount ? data.aug_amount : 0,
                  sep_actual_amount:
                    data && data.sep_amount ? data.sep_amount : 0,
                  oct_actual_amount:
                    data && data.oct_amount ? data.oct_amount : 0,
                  nov_actual_amount:
                    data && data.nov_amount ? data.nov_amount : 0,
                  dec_actual_amount:
                    data && data.dec_amount ? data.dec_amount : 0,
                };

                return {
                  set: result,
                };
              },
              onChange: [
                '_id',
                'jan_amount',
                'feb_amount',
                'mar_amount',
                'apr_amount',
                'may_amount',
                'jun_amount',
                'jul_amount',
                'aug_amount',
                'sep_amount',
                'oct_amount',
                'nov_amount',
                'dec_amount',
              ],
            },
          },
        },
        ...computaionSetActualAmount({ field: 'nps' }),
        ...computaionSetActualAmount({ field: 'previous_employer_income' }),
      },
    }}
    renderFooter={(props) => <DeductionDetailFooter {...props} item={item} />}
    submitMessage="Tax declaration has been Updated successfully"
    fieldVariant="filled"
    columnsPerRow={2}
    reloadEvent="UserTaxDeclarationList"
    formGroups={[
      {
        visible: isDeductionVisible('deduction_under_section_chapterVIA', item),
        columns: [
          FormLayouts.Deduction_under_section_chapterVIA({ addActualAmount, addDeclaredAmount }),
        ],
      },
      {
        visible: isDeductionVisible('other_deductions', item),
        columns: [FormLayouts.OtherDeduction({ addActualAmount, addDeclaredAmount })],
      },
      {
        field: 'income_from_house_property',
        visible: isDeductionVisible('income_from_house_property', item),
        columns: [FormLayouts.IncomeFromHouseProperty({ addActualAmount, addDeclaredAmount })],
      },
      {
        visible: isDeductionVisible('lta', item),
        columns: [FormLayouts.Lta({ addActualAmount, addDeclaredAmount })],
      },
      {
        visible: isDeductionVisible('previous_employer_income', item),
        columns: [FormLayouts.PreviousEmployerIncome],
      },
      {
        visible: isDeductionVisible('rent_recipts', item),
        columns: [FormLayouts.RentRecipts({ addActualAmount, addDeclaredAmount })],
      },
      {
        visible: isDeductionVisible('income_on_higher_education_loan', item),
        columns: [FormLayouts.HigherEducationLoan],
      },
      {
        visible: isDeductionVisible('donations', item),
        columns: [FormLayouts.Donations],
      },
      {
        visible: isDeductionVisible('flexi_component_details', item),
        columns: [FormLayouts.FlexiComponentDetails],
      },
      {
        visible: isDeductionVisible('nps', item),
        columns: [FormLayouts.Nps],
      },
    ]}
  />
);

const TaxDeclarationDetailsView2 = ({
  name,
  handleOnPressBack,
  taxDeclarationDate,
  employee,
  isFreezed,
}) => {
  const configurationData = getConfiguration() || {};
  const { attachment = {} } = configurationData;
  return (
    <View
      style={{
        height: 130,
        width: '100%',
        paddingLeft: 24,
        paddingRight: 12,
        backgroundColor: vars.colors.white,
      }}
    >
      <View
        style={{
          height: 64,
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <TouchableOpacity
          style={{
            height: 12,
            width: 18,
            cursor: 'pointer',
          }}
          onPress={handleOnPressBack}
        >
          <Image
            source={breadCrumbBack}
          />
        </TouchableOpacity>
        <Text
          style={[
            vars.headings.h6,
            {
              color: vars.colors.grey.color1,
              marginLeft: 10,
            },
          ]}
        >
          {`${'TAX Declaration - FY '}${name}`}
        </Text>
        {/* <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <View style={{ paddingRight: 12 }}>
            <OutlineButton
              label=" Download 12B B"
              onPress={() => { window.open(getDownloadUrl(attachment), '_download'); }}
            />
          </View>
        </View> */}
      </View>
      <View
        style={{
          height: 1,
          width: '100%',
          backgroundColor: vars.colors.white,
        }}
      />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginLeft: 20,
        }}
      >
        <View
          style={{
            flex: 0.25,
            justifyContent: 'center',
          }}
        >
          <Text
            style={[
              vars.headings.h13,
              {
                marginBottom: 6,
                color: vars.colors.grey.color3,
              }]}
          >
            Status
          </Text>
          <Text
            style={[
              vars.headings.h8,
              {
                color: vars.colors.grey.color1,
              }]}
          >
            {isFreezed ? 'Freezed' : 'Declared'}
          </Text>
        </View>
        <View
          style={{
            flex: 0.25,
            justifyContent: 'center',
          }}
        >
          <Text
            style={[
              vars.headings.h13,
              {
                marginBottom: 6,
                color: vars.colors.grey.color3,
              }]}
          >
            Tax Declaration Date
          </Text>
          <Text
            style={[
              vars.headings.h8,
              {
                color: vars.colors.grey.color1,
              }]}
          >
            {moment(taxDeclarationDate).format('MM-DD-YYYY')}
          </Text>
        </View>
        <View
          style={{
            flex: 0.25,
            justifyContent: 'center',
          }}
        >
          <Text
            style={[
              vars.headings.h13,
              {
                marginBottom: 6,
                color: vars.colors.grey.color3,
              }]}
          >
            PAN Number
          </Text>
          <Text
            style={[
              vars.headings.h8,
              {
                color: vars.colors.grey.color1,
              }]}
          >
            {employee.panNumber}
          </Text>
        </View>
        <View
          style={{
            flex: 0.25,
            justifyContent: 'center',
          }}
        >
          <Text
            style={[
              vars.headings.h13,
              {
                marginBottom: 6,
                color: vars.colors.grey.color3,
              }]}
          >
            Taxable Income
          </Text>
          <Text
            style={[
              vars.headings.h8,
              {
                color: vars.colors.grey.color1,
              }]}
          >
            42,000.00
          </Text>
        </View>
      </View>
    </View>
  );
};

const TaxDeclarationDetailView1 = ({
  name,
  taxDeclarationDate,
  employee,
  isFreezed,
  handleOnPressBack,
}) => (
  <View
    style={{
      height: 130,
      width: '100%',
      marginLeft: 24,
      marginRight: 12,
    }}
  >
    <View
      style={{
        height: 64,
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <TouchableOpacity
        style={{
          height: 12,
          width: 18,
          marginRight: 12,
          cursor: 'pointer',
        }}
        onPress={handleOnPressBack}
      >
        <Image
          source={breadCrumbBack}
        />
      </TouchableOpacity>
      <Text
        style={[
          vars.headings.h6,
          {
            color: vars.colors.grey.color1,
          },
        ]}
      >
        {`${'TAX Declaration - FY '}${name}`}
      </Text>
    </View>
    <View
      style={{
        height: 1,
        width: '100%',
        backgroundColor: vars.colors.white,
      }}
    />
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          flex: 0.33,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={[
            vars.headings.h13,
            {
              marginBottom: 6,
              color: vars.colors.grey.color3,
            }]}
        >
          Status
        </Text>
        <Text
          style={[
            vars.headings.h8,
            {
              color: vars.colors.grey.color1,
            }]}
        >
          {isFreezed ? 'Freezed' : 'Declared'}
        </Text>
      </View>
      <View
        style={{
          flex: 0.33,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={[
            vars.headings.h13,
            {
              marginBottom: 6,
              color: vars.colors.grey.color3,
            }]}
        >
          Tax Declaration Date
        </Text>
        <Text
          style={[
            vars.headings.h8,
            {
              color: vars.colors.grey.color1,
            }]}
        >
          {moment(taxDeclarationDate).format('MM-DD-YYYY')}
        </Text>
      </View>
      <View
        style={{
          flex: 0.33,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={[
            vars.headings.h13,
            {
              marginBottom: 6,
              color: vars.colors.grey.color3,
            }]}
        >
          PAN Number
        </Text>
        <Text
          style={[
            vars.headings.h8,
            {
              color: vars.colors.grey.color1,
            }]}
        >
          {employee?.panNumber}
        </Text>
      </View>
    </View>
  </View>
);

const getStylesPerStatus = (status) => {
  if (status?.toLowerCase() === 'rejected') {
    return {
      ...vars.headings.h11,
      marginTop: 6,
      color: vars.colors.error,
    };
  } if (status?.toLowerCase() === 'approved') {
    return {
      ...vars.headings.h11,
      marginTop: 6,
      color: vars.colors.primary.color2,
    };
  } if (status?.toLowerCase() === 'requested') {
    return {
      ...vars.headings.h11,
      marginTop: 6,
      color: vars.colors.emphasis,
    };
  } if (status?.toLowerCase() === 'declared' || status?.toLowerCase() === 'pending') {
    return {
      ...vars.headings.h11,
      marginTop: 6,
      color: vars.colors.warning,
    };
  }

  return {};
};

export const EmployeeDeclarationDetails = (props) => {
  const {
    setCurrentActiveView,
    currentActiveView,
    addActualAmount,
    addDeclaredAmount,
    navigation,
    eventDispatcher,
    data,
  } = props;

  const {
    newDeclarations = [],
    finantial_year_id: { name } = {},
    tax_calculation_date: taxDeclarationDate,
    employee_id: employee = {},
    is_freezed: isFreezed,
  } = data || {};

  const [cardDetails, setCardDetails] = useState();

  const handleOnPressCard = useCallback((item) => {
    setCurrentActiveView(2);
    setCardDetails(item);
  }, [setCurrentActiveView]);

  const handleOnPressBack = useCallback(() => {
    setCurrentActiveView(1);
  }, [setCurrentActiveView]);

  const closeAllViews = useCallback(() => {
    setCurrentActiveView(0);
  }, [setCurrentActiveView]);

  const tableStyle = {
    flex: 0.3,
    overflow: 'hidden',
    backgroundColor: vars.colors.white,
    border: '1px solid #F2F2F2',
    borderRadius: 4,
  };
  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      {
        currentActiveView === 2
        && (
          <TaxDeclarationDetailsView2
            name={name}
            handleOnPressBack={handleOnPressBack}
            taxDeclarationDate={taxDeclarationDate}
            employee={employee}
            isFreezed={isFreezed}
          />
        )
      }
      {
        currentActiveView === 1
          && (
          <TaxDeclarationDetailView1
            name={name}
            taxDeclarationDate={taxDeclarationDate}
            employee={employee}
            isFreezed={isFreezed}
            handleOnPressBack={closeAllViews}
          />
          )
      }
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          flexDirection: 'row',
        }}
      >
        <View
          style={currentActiveView === 2 ? tableStyle : {
            flex: 1,
            overflow: 'hidden',
            backgroundColor: vars.colors.secondary.color4,
          }}
        >
          <Table
            {...CommonDeclarationDetailsProps}
            {
              ...(currentActiveView === 2 ? {
                card: {
                  hoverStyle: {
                    backgroundColor: vars.colors.secondary.color4,
                    borderColor: neutral_7,
                  },
                  primaryTitleField: ({ _id }) => {
                    const title = _id.split('_');
                    return title.reduce((total, one) => {
                      total += `${one[0].toUpperCase() + one.slice(1)} `;
                      return total;
                    }, '');
                  },
                  secondaryTitleField: (fieldProps) => (
                    <Text
                      style={getStylesPerStatus(fieldProps.status)}
                    >
                      {fieldProps.status}
                    </Text>
                  ),
                },
                numColumns: 1,

              } : {
                numColumns: 3,
                card: {
                  ...taxDeclarationCardTheme,
                  title: {
                    fullWidth: true,
                    primaryField: ({ _id }) => {
                      if (_id === 'other_deductions') {
                        return 'Deduction Under Section 80D';
                      } if (_id === 'income_on_higher_education_loan') {
                        return 'Deduction Under Section 80E';
                      }
                      const title = _id.split('_');
                      return title.reduce((total, one) => {
                        total += `${one[0].toUpperCase() + one.slice(1)} `;
                        return total;
                      }, '');
                    },
                    secondaryField: (fieldProps) => (fieldProps
                        && fieldProps.supporting_document
                        && fieldProps.supporting_document.length
                      ? `${fieldProps.supporting_document.length} Files Attached`
                      : `${fieldProps.supporting_document}` || '')
                    ,
                  },
                  left: [
                    [
                      {
                        textStyle: {
                          ...Headline9,
                          color: neutral_1,
                        },
                        value: ({ item }) => (item && item.declared_amount ? `₹ ${item.declared_amount}` : '₹ 0'),
                      },
                      {
                        textStyle:
                         {
                           marginTop: 8,
                           ...vars.headings.h14,
                           color: vars.colors.grey.color1,
                         },
                        text: 'Declared',
                      },
                    ],
                  ],
                  right: [
                    {
                      align: 'right',
                      items: [
                        {
                          textStyle: {
                            ...Headline9,
                            color: neutral_1,
                          },
                          value: ({ item }) => (item
                             && item.actualAmount ? `₹ ${item.actualAmount}` : '₹ 0'),
                        },
                        {
                          textStyle: {
                            marginTop: 8,
                            ...vars.headings.h14,
                            color: vars.colors.grey.color1,
                          },
                          text: 'Actual',
                        },
                      ],
                    },
                  ],
                },
              })
            }
            navigation={navigation}
            eventDispatcher={eventDispatcher}
            data={newDeclarations}
            setCurrentActiveView={setCurrentActiveView}
            handleOnPressCard={handleOnPressCard}
            showSelected
          />
        </View>
        {currentActiveView === 2 && (
          <View
            style={{
              flex: 1,
              overflow: 'hidden',
              borderRadius: 4,
            }}
          >
            <IncomeTaxDetailForEmployee
              {...props}
              item={cardDetails}
              deduction_details={cardDetails}
              addActualAmount={addActualAmount}
              addDeclaredAmount={addDeclaredAmount}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const DeductionHelperComponent = ({ description, maxLimit, actual_amount }) => (
  <View style={{ margin: 8 }}>
    <View
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: surface_white,
        borderRadius: 10,
        marginBottom: 8,
        padding: 10,
        justifyContent: 'space-between',
      }}
    >
      <Text style={{ color: neutral_1, ...Headline9 }}>Total</Text>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Image source={rupeeIcon1} style={{ height: 20 }} />
        <Text style={{ color: positive_communication_2, ...Headline4 }}>
          {actual_amount}
        </Text>
      </View>
    </View>
    <View
      style={{
        flexDirection: 'row',
        padding: 16,
        alignItems: 'center',
        backgroundColor: surface_white,
        height: 100,
      }}
    >
      <View style={{ flex: 1, overflow: 'hidden' }}>
        {description && description.length
          ? description.map((desc, index) => (
            <Text
              key={index + 1}
              style={{ ...Headline13, color: neutral_3 }}
            >
              {desc}
            </Text>
          ))
          : undefined}
      </View>
      {maxLimit !== undefined ? (
        <View style={{ marginLeft: 40 }}>
          <Text style={{ ...Headline13, color: neutral_3 }}>
            Maximum Limit of
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Image source={rupeeIcon1} style={{ height: 22 }} />
            <Text style={{ color: neutral_1, ...Headline4 }}>{maxLimit}</Text>
          </View>
        </View>
      ) : (
        undefined
      )}
    </View>
  </View>
);

