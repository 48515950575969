import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import {
  FormField, StandardButton, View, Text,
} from '../../../../../app-components';
import { transferIcon } from '../../../../../images';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import vars from '../../../../../theme/vars';
import { submit } from '../../../../../AppServices';
import { MODELS } from '../../../../../Lib/constants';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';

const getNewId = () => `new_${uuid()}`;

const TransferRequestModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          defaultValues,
          queryId,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <ListActionModal
      icon={transferIcon}
      title={'inventory.title.transferStock'.getLabel()}
      description={'inventory.title.transferStockDescription'.getLabel()}
      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({
            ...defaultValues,
            departmentStocks: [{ _id: getNewId() }],
            type: 'Transfer Request',
          })}
          onSubmit={submit({
            model: MODELS.PHARMACY_TRANSFER_REQUESTS,
          })}
          mandatory={{
            departmentStocks: {
              item: 1,
              transferQuantity: 1,
            },
            fromDepartment: 1,
            toDepartment: 1,
          }}
          computations={{
            children: {
              departmentStocks: {
                self: {
                  itemAutofill: {
                    compute: ({ item }) => ({
                      set: {
                        stockAvailable: item?.stockAvailable,
                      },
                    }),
                    onChange: ['item'],
                  },
                },
              },
            },
            self: {
              fromDepartment: {
                compute: () => ({
                  set: {
                    fromSpeciality: null,
                  },
                }),
                onChange: ['fromDepartment'],
              },
              fromSpeciality: {
                compute: () => ({
                  set: {
                    departmentStocks: [{ _id: getNewId() }],
                    toSpeciality: null,
                  },
                }),
                onChange: ['fromSpeciality'],
              },
              toDepartment: {
                compute: () => ({
                  set: {
                    toSpeciality: null,
                  },
                }),
                onChange: ['toDepartment'],
              },
            },
          }}
          validations={{
            departmentStocks: {
            // eslint-disable-next-line consistent-return
              transferQuantity: ({ data }) => {
                if (data.stockAvailable) {
                  if (data.stockAvailable < data.transferQuantity) {
                    return 'Transfer Quantity can not be more than Stocks available';
                  }
                }
              },
            },
          }}
          submitMessage={'inventory.messages.transferRequest'.getLabel()}
        >
          {({ form_context: formContext, form_state: { data = {} } = {} }) => (
            <View style={{
              paddingBottom: 24, paddingTop: 24, paddingLeft: 22, paddingRight: 22,
            }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1, paddingRight: 40 }}>
                  <FormField
                    {
                      ...autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'fromDepartment',
                        variant: 'filled',
                        label: 'inventory.labels.fromDepartment'.getLabel(),
                        placeholder: 'inventory.labels.fromDepartment'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.DEPARTMENTS,
                        query: 'masterDataList',
                        mandatory: true,
                      })
                    }
                  />
                </View>
                { !!queryId
                && (
                <View style={{ flex: 1, paddingRight: 40 }}>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'fromSpeciality',
                        variant: 'filled',
                        label: 'asset.labels.fromSpeciality'.getLabel(),
                        placeholder: 'asset.placeholders.fromSpeciality'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.WARDS,
                        query: 'masterDataList',
                        mandatory: true,
                        visible: () => !!queryId,
                      },
                      {
                        addOnFilter: ({ data }) => ({
                          department: { _id: data?.fromDepartment?._id },
                        }),
                      },
                    )
                     }
                  />
                </View>
                )}
                <View style={{ flex: 1, paddingRight: 40 }}>
                  <FormField
                    {...autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'toDepartment',
                      variant: 'filled',
                      label: 'inventory.labels.toDepartment'.getLabel(),
                      placeholder: 'inventory.placeholders.toDepartment'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      mandatory: true,
                    })
                }
                  />
                </View>
                { !!queryId
                && (
                <View style={{ flex: 1, paddingRight: 40 }}>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'toSpeciality',
                        variant: 'filled',
                        label: 'asset.labels.toSpeciality'.getLabel(),
                        placeholder: 'asset.placeholders.toSpeciality'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.WARDS,
                        query: 'masterDataList',
                        mandatory: true,
                        visible: () => !!queryId,
                      },
                      {
                        addOnFilter: ({ data }) => ({
                          department: { _id: data?.toDepartment?._id },
                          _id: { $ne: data?.fromSpeciality._id },
                        }),
                      },
                    )
                     }
                  />
                </View>
                )}
              </View>
              <View>
                <FormField
                  {...{
                    field: 'departmentStocks',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'inventory.buttons.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      listProps: {
                        hideColumnHeader: false,
                        rowColumnContainerStyle: {
                          paddingRight: 6,
                        },
                        headerRowContainerStyle: {
                          minHeight: 32,
                          backgroundColor: vars.colors.white,
                        },
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          searchInput({
                            field: 'item',
                            variant: 'filled',
                            header: 'inventory.labels.itemName'.getLabel(),
                            label: 'inventory.labels.itemName'.getLabel(),
                            placeholder: 'inventory.placeholders.selectItemName'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
                            query: queryId || 'pharmacyDepartmentInventoryStocksDataList',
                            mandatory: true,
                          },
                          {
                            // eslint-disable-next-line no-shadow
                            modifyResult: ({ data }) => {
                              const newData = data.map(({
                                item,
                                stockAvailable,
                              }) => ({ ...item, stockAvailable }));
                              return { data: newData };
                            },
                            addOnFilter: ({
                              data:
                                { _parent: { fromDepartment, departmentStocks = [], fromSpeciality } = {} },
                            } = {}) => {
                              const itemIds = departmentStocks?.filter(
                                ({ item }) => item?._id,
                              ).map(({ item }) => item?._id);
                              if (fromSpeciality) {
                                return {
                                  department: { _id: fromDepartment?._id },
                                  item: { _id: { $nin: itemIds } },
                                  module: defaultValues?.module,
                                  speciality: { _id: fromSpeciality?._id },
                                };
                              }
                              return {
                                department: { _id: fromDepartment?._id },
                                item: { _id: { $nin: itemIds } },
                                module: defaultValues?.module,
                              };
                            },
                          }),
                          searchInput({
                            field: 'item.category',
                            variant: 'filled',
                            header: 'inventory.labels.category'.getLabel(),
                            label: 'inventory.labels.category'.getLabel(),
                            placeholder: 'inventory.placeholders.supplier'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_CATEGORY,
                            query: 'masterDataList',
                            disabled: true,
                          }),
                          {
                            type: 'number',
                            field: 'stockAvailable',
                            header: 'inventory.labels.stockAvailable'.getLabel(),
                            variant: 'filled',
                            label: 'inventory.labels.stockAvailable'.getLabel(),
                            width: 190,
                            disabled: true,
                          },
                          {
                            type: 'number',
                            field: 'transferQuantity',
                            header: 'inventory.labels.transferQuantity'.getLabel(),
                            variant: 'filled',
                            label: 'inventory.labels.transferQuantity'.getLabel(),
                            width: 190,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  }
                }
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'inventory.buttons.transfer'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default TransferRequestModal;
