/* eslint-disable max-len */
import React from 'react';

import { isEmpty } from 'lodash';
import {
  cancelButton,
  OutlineButton,
  save,
  Text,
  View,
  Image,
  MediumStandardButton,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import {
  Roles,
  ROUTES,
  purchaseSupplyOrderStatus,
  Status,
  MODELS,
  assetInventoryStockRequestStatus,
} from '../../../Lib/constants';
import PurchaseRequestTab from './PurchaseRequest/purchaseRequestTab';
import ViewPharmacyPurchaseRequest from './PurchaseRequest/PharmacyPurchaseRequest/viewPharmacyPurchaseRequest';
import PurchaseAndSupplyOrderTabs from './PurchaseAndSupplyOrder/purchaseAndSupplyTab';
import ViewPurchaseRequestDetail from './PurchaseAndSupplyOrder/PurchaseOrderRequest/viewPurchaseOrderRequest';
import CreatePurchaseOrder from './PurchaseRequest/PharmacyPurchaseRequest/createPurchaseOrder';
import PurchaseOrderModal from './PurchaseRequest/PharmacyPurchaseRequest/createPurchaseOrderModal';
import PurchaseOrderApproveOrReviewModal from './PurchaseAndSupplyOrder/PurchaseOrderRequest/purchaseOrderRejectOrReviewModal';
import PurchaseOrderApproveModal from './PurchaseAndSupplyOrder/PurchaseOrderRequest/purchaseOrderApproveModal';
import ViewSupplyRequestDetail from './PurchaseAndSupplyOrder/SupplyOrderRequest/viewSupplyOrderRequest';
import CreateAssetPurchaseAndSupplyOrderModal from './PurchaseRequest/AssetPurchaseRequest/createPurchaseAndSupplyOrderModal';
import { daysLimitApprovalIcon } from '../../../images';
import ViewAssetRepairRequest from './PurchaseRequest/AssetPurchaseRequest/viewAssetRepairRequest';
import vars from '../../../theme/vars';
import { reduceDaysLimitApprovalBasedOnRole } from '../../../Lib/helpers';
import CreateAssetPS2OrderModal from './PurchaseRequest/AssetPurchaseRequest/createPS2Modal';
import EditTechnicalModalPurchase from './PurchaseRequest/AssetPurchaseRequest/editTechnicalDetailsModal';
import CreateBOQ from './PurchaseRequest/BOQ';
import CreatePurchaseAndSupplyOrderForAssetOrder from './PurchaseRequest/AssetPurchaseRequest/createPurchaseAndSupplyOrder';
import SendToReviewModal from './PurchaseAndSupplyOrder/PurchaseOrderRequest/sendForReviewModal';
import ViewAssetPurchaseRequest from './PurchaseRequest/AssetPurchaseRequest/viewAssetPurchaseRequest';
import CreateRepairRequestForAsset from './PurchaseRequest/AssetPurchaseRequest/createRepairRequestForAsset';
import CreatePurchaseAndSupplyOrderForAssetRepair from './PurchaseRequest/AssetPurchaseRequest/createRepairRequestForAssetModal';
import AddSupplier from '../Purchase/AddSupplierAndManufacturer/AddSupplier';
import { supplyOrder } from '../../../Wired/Purchase/supplyOrderPDF';

export default {
  [ROUTES.pharmacyAndAssetPurchaseRequest.name]: {
    screen: PurchaseRequestTab,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Purchase Department" />
      ),
      actions: [
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.inventory.views.createAssetBOQ.permissions.createAssetBOQ.value
              }
            >
              <MediumStandardButton label="Create Asset BOQ" />
            </WithPermission>
          ),
          type: 'link',
          link: (props) => ({
            view: ROUTES.createBOQ.name,
            push: true,
            expanded: true,
            params: { ...props?.navigation?.state?.params, type: 'asset' },
          }),
        },
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.inventory.views.createPharmacyBOQ.permissions.createPharmacyBOQ.value
              }
            >
              <MediumStandardButton label="Create Pharmacy BOQ" />
            </WithPermission>
          ),
          type: 'link',
          link: (props) => ({
            view: ROUTES.createBOQ.name,
            push: true,
            expanded: true,
            params: { ...props?.navigation?.state?.params, type: 'pharmacy' },
          }),
        },
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.inventory.views.createConsumableBOQ.permissions.createConsumableBOQ.value
              }
            >
              <MediumStandardButton label="Create Consumable BOQ" />
            </WithPermission>
          ),
          type: 'link',
          link: (props) => ({
            view: ROUTES.createBOQ.name,
            push: true,
            expanded: true,
            params: { ...props?.navigation?.state?.params, type: 'pharmacy', module: 'assetConsumables' },
          }),
        },
      ],
    },
  },
  [ROUTES.createBOQ.name]: {
    screen: CreateBOQ,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.viewPurchaseRequestPharmacy.name]: {
    screen: ViewPharmacyPurchaseRequest,
    title: (props) => {
      const {
        navigation: {
          state: {
            params: {
              item: {
                purchaseRequestPharmacy: {
                  purchaseRequestNumber,
                },
              } = {},
            } = {},
          } = {},
        } = {},
      } = props;

      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <OutlineButton label="Create PS" />
          </WithPermission>
        ),
        type: 'link',
        visible: (props) => {
          const {
            data: {
              status,
            } = {},
          } = props;
          return status === Status?.raised;
        },
        link: (props) => ({
          view: ROUTES.viewPurchaseOrder.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
    ],
  },
  [ROUTES.viewPurchaseRequestAsset.name]: {
    screen: ViewAssetPurchaseRequest,
    title: (props) => {
      const { navigation: { state: { params: { item: { purchaseRequestAsset: { purchaseRequestNumber } } = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <OutlineButton label="Create PS" />
          </WithPermission>
        ),
        type: 'link',
        visible: (props) => {
          const {
            data: {
              status = '',
            } = {},
          } = props;
          return status === Status?.raised;
        },
        link: (props) => ({
          view: ROUTES.createPurchaseAndSupplyOrderForAsset.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
    ],
  },
  [ROUTES.viewRepairRequestAsset.name]: {
    screen: ViewAssetRepairRequest,
    title: (props) => {
      const { navigation: { state: { params: { item: { purchaseRequestAsset: { purchaseRequestNumber } } = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <OutlineButton label="Create PS" />
          </WithPermission>
        ),
        type: 'link',
        visible: (props) => {
          const {
            data: {
              status = '',
            } = {},
          } = props;
          return status === Status?.raised;
        },
        link: (props) => ({
          view: ROUTES.createPurchaseAndSupplyOrderForAssetRepair.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
    ],
  },
  [ROUTES.createPurchaseAndSupplyOrderForAssetRepair.name]: {
    screen: CreateRepairRequestForAsset,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.purchaseOrderAndSupplyOrder.name]: {
    screen: PurchaseAndSupplyOrderTabs,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Purchase Sanction & Supply Orders (Purchase)" />
      ),
    },
  },
  [ROUTES.viewPurchaseOrder.name]: {
    screen: CreatePurchaseOrder,
    screenProps: {
      queryId: 'purchaseRequestAssetConsumablesList',
    },
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.addPharmacySupplierAndManufacturer.name]: {
    screen: AddSupplier,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Add' }),
    ],
  },
  [ROUTES.createPurchaseAndSupplyOrderForAsset.name]: {
    screen: CreatePurchaseAndSupplyOrderForAssetOrder,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.viewSupplyOrder.name]: {
    screen: CreatePurchaseOrder,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <OutlineButton label="Add Supplier and Manufacturer" />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => {
          return {
          view: ROUTES.addPharmacySupplierAndManufacturer.name,
          push: true,
          expanded: true,
          params: { type: props?.data?.type,
            itemId: props?.data?.purchaseRequestPharmacy?.item?._id,
            ...props?.navigation?.state?.params },
        }},
      },
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.viewAssetSupplyOrder.name]: {
    screen: CreatePurchaseAndSupplyOrderForAssetOrder,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <OutlineButton label="Add Supplier and Manufacturer" />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => {
          return {
          view: ROUTES.addPharmacySupplierAndManufacturer.name,
          push: true,
          expanded: true,
          params: {
            type: props?.data?.type,
            itemId: props?.data?.purchaseRequestAsset?.item?._id,
            ...props?.navigation?.state?.params
          },
        }},
      },
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Create' }),
    ],
  },
  [ROUTES.purchaseOrderModal.name]: {
    screen: PurchaseOrderModal,
    modal: true,
    modalProps: { width: 1200, height: 650 },
  },
  [ROUTES.purchaseAndSupplyOrderForAssetRepair.name]: {
    screen: CreatePurchaseAndSupplyOrderForAssetRepair,
    modal: true,
    modalProps: { width: 1200, height: 650 },
  },
  [ROUTES.PS2Modal.name]: {
    screen: CreateAssetPS2OrderModal,
    modal: true,
    modalProps: { width: 1200, height: 650 },
  },
  [ROUTES.assetCreatePurchaseAndSupplyOrderModal.name]: {
    screen: CreateAssetPurchaseAndSupplyOrderModal,
    modal: true,
    modalProps: { width: 1200, height: 650 },
  },
  [ROUTES.viewPurchasesRequest.name]: {
    screen: ViewPurchaseRequestDetail,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.uniqueNumber}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: ({ data = {} }) => {
          if (isEmpty(data)) {
            return <View />;
          }
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
          ) return <View />;

          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }

          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approvePurchaseOrdersAs${assignedRole}`]
                  ?.permissions[`approvePurchaseOrdersAs${assignedRole}`]?.value
              }
            >
              <View style={{ flexDirection: 'row', alignItem: 'center' }}>
                <Image source={daysLimitApprovalIcon} style={{ width: 18, height: 18, marginRight: 7 }} />
                <Text style={{ color: vars.colors.error, paddingTop: 1 }}>
                  {reduceDaysLimitApprovalBasedOnRole(
                    data?.daysLimitForApproval,
                    data?.currentApprovalQueue?._createdOn,
                    data?.status,
                    data?.currentApprovalQueue,
                  )}
                </Text>
              </View>
            </WithPermission>
          );
        },
        visible: ({ data = {} }) => {
          if (isEmpty(data)) {
            return false;
          }
          const {
            assignedToRole,
            isReview,
            status,
          } = data;
          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
          ) return false;

          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }

          return true
        },
        
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED) return <View />;

          if (isEmpty(data)) {
            return null;
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }
          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approvePurchaseOrdersAs${assignedRole}`]
                  ?.permissions[`approvePurchaseOrdersAs${assignedRole}`]?.value
              }
            >
              <OutlineButton label="REJECT" />
            </WithPermission>
          );
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED) return false;

          if (isEmpty(data)) {
            return false;
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }
          return true;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveOrReviewModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params, fromRejectPO: true },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
          ) return <View />;
          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }
          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approvePurchaseOrdersAs${assignedRole}`]
                  ?.permissions[`approvePurchaseOrdersAs${assignedRole}`]?.value
              }
            >
              <OutlineButton label="Send for Review" />
            </WithPermission>
          );
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
          ) return false;
          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }
          return true;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.PSandSoReviewModal.name,
          push: true,
          expanded: true,
          params: {
            ...props?.navigation?.state?.params,
          },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            assignedToRole === Roles?.director || status === assetInventoryStockRequestStatus?.rejected
              || status === assetInventoryStockRequestStatus?.approved) return <View />;

          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approvePurchaseOrdersAs${assignedRole}`]
                  ?.permissions[`approvePurchaseOrdersAs${assignedRole}`]?.value
              }
            >
              <OutlineButton label="Approve and Send" />
            </WithPermission>
          );
        },

        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            isReview,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            assignedToRole === Roles?.director || status === assetInventoryStockRequestStatus?.rejected
              || status === assetInventoryStockRequestStatus?.approved) return false;

          let assignedRole = assignedToRole;
          if (isReview) {
            assignedRole = 'PSI';
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
          return true;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveOrReviewModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params, fromApprovePO: true },
          ...props,
        }),
      },
      {
        render: ({ data = {}, eventDispatcher }) => {
          const {
            assignedToRole,
            status,
            isReview,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (assignedToRole === Roles?.director && status !== Status?.rejected && status !== Status?.approved) {
            let assignedRole = assignedToRole;
            if (isReview) {
              assignedRole = 'PSI';
            }

            eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
            return (
              <WithPermission
                access_key={
                permissions?.inventory?.views[`approvePurchaseOrdersAs${assignedRole}`]
                  ?.permissions[`approvePurchaseOrdersAs${assignedRole}`]?.value
              }
              >
                <OutlineButton label="Approve" />
              </WithPermission>
            );
          }
          return <View />;
        },
        visible: ({ data = {}, eventDispatcher }) => {
          const {
            assignedToRole,
            status,
            isReview,
          } = data;

          if (isEmpty(data)) {
            return false
          }

          if (assignedToRole === Roles?.director && status !== Status?.rejected && status !== Status?.approved) {
            let assignedRole = assignedToRole;
            if (isReview) {
              assignedRole = 'PSI';
            }

            return true
          }
          return false
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
          ...props,
        }),
      },
    ],
  },
  [ROUTES.purchaseOrderApproveOrReviewModal.name]: {
    screen: PurchaseOrderApproveOrReviewModal,
    modal: true,
    modalProps: { width: 725, height: 420 },
  },
  [ROUTES.purchaseOrderApproveModal.name]: {
    screen: PurchaseOrderApproveModal,
    modal: true,
    modalProps: { width: 725, height: 420 },
  },
  [ROUTES.PSandSoReviewModal.name]: {
    screen: SendToReviewModal,
    modal: true,
    modalProps: { width: 725, height: 420 },
  },
  [ROUTES.viewSupplyRequest.name]: {
    screen: ViewSupplyRequestDetail,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.uniqueNumber}
        </Text>
      );
    },
    headerActions: [
      {
        render: ({ data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return <View />;

          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
                  ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
              }
            >
              <View style={{ flexDirection: 'row', alignItem: 'center' }}>
                <Image source={daysLimitApprovalIcon} style={{ width: 18, height: 18, marginRight: 7 }} />
                <Text style={{ color: vars.colors.error, paddingTop: 1 }}>
                  {reduceDaysLimitApprovalBasedOnRole(
                    data?.daysLimitForApproval,
                    data?.currentApprovalQueue?._createdOn,
                    data?.status,
                    data?.currentApprovalQueue,
                  )}
                </Text>
              </View>
            </WithPermission>
          );
        },
        visible: ({ data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return false;

          return true;
        },
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return <View />;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return (
            <WithPermission
              access_key={
              permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
                ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
            }
            >
              <OutlineButton label="REJECT" />
            </WithPermission>
          );
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return false;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return true;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveOrReviewModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params, fromRejectPO: true },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return <View />;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
                  ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
              }
            >
              <OutlineButton label="Send for Review" />
            </WithPermission>
          );
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            status === purchaseSupplyOrderStatus?.REJECTED
            || status === purchaseSupplyOrderStatus?.APPROVED
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return false;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return true;
        },
        
        type: 'link',
        link: (props) => ({
          view: ROUTES.PSandSoReviewModal.name,
          push: true,
          expanded: true,
          params: {
            ...props?.navigation?.state?.params,
          },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          if (
            assignedToRole === Roles?.director
             || status === assetInventoryStockRequestStatus?.rejected
             || status === assetInventoryStockRequestStatus?.approved
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return <View />;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return (
            <WithPermission
              access_key={
                permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
                  ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
              }
            >
              <OutlineButton label="Approve and Send" />
            </WithPermission>
          );
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          if (
            assignedToRole === Roles?.director
             || status === assetInventoryStockRequestStatus?.rejected
             || status === assetInventoryStockRequestStatus?.approved
            || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) return false;

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          return true;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveOrReviewModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params, fromApprovePO: true },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          if (
            assignedToRole === Roles?.director
            && status !== Status?.rejected
            && status !== Status?.approved
            && status !== purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) {
            return (
              <WithPermission
                access_key={
                permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
                  ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
              }
              >
                <OutlineButton label="Approve" />
              </WithPermission>
            );
          }
          return <View />;
        },
        visible: ({ eventDispatcher, data = {} }) => {
          const {
            assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return false;
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          if (
            assignedToRole === Roles?.director
            && status !== Status?.rejected
            && status !== Status?.approved
            && status !== purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) {
            return true;
          }
          return false;
        },
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseOrderApproveModal.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
          ...props,
        }),
      },
      {
        render: ({ eventDispatcher, data = {} }) => {
          const {
            // assignedToRole,
            status,
          } = data;

          if (isEmpty(data)) {
            return <View />;
          }

          eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);

          if (
            // assignedToRole === Roles?.director
            // && status !== Status?.rejected
            // status === Status?.approved
            status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING
          ) {
            return (
               // <WithPermission
              //   access_key={
              //   permissions?.inventory?.views[`approveSupplyOrdersAs${assignedToRole}`]
              //     ?.permissions[`approveSupplyOrdersAs${assignedToRole}`]?.value
              // }
              // >
                <OutlineButton label="Print SO" />
              // </WithPermission>
            );
          }
          return <View />;
        },
        type: 'link',
        link: (props) => {
        supplyOrder({
            supplyOrderId: props?.data?._id,
            itemType: props?.data?.inventoryType,
          }, () => {});
        },
      },
    ],
  },
  [ROUTES.editTechnicalModalPurchase.name]: {
    screen: EditTechnicalModalPurchase,
    modal: true,
    modalProps: {
      width: 700,
      height: 450,
      autoHide: true,
    },
  },
};
