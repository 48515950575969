import React from 'react';
import { FormField, LargeButton, View } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { submit } from '../../../AppServices';
import ListActionModal from '../../../Components/ListActionModal';
import { notificationHolidayIcon } from '../../../images';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';

const EditHoliday = (props) => {
  const {
    navigation: { state: { params: { item } } = {} } = {},
  } = props;
  return (
    <ListActionModal
      icon={notificationHolidayIcon}
      title={'holiday.title.name'.getLabel()}
      description={'holiday.title.description'.getLabel()}
      content={(
        <View style={{ marginTop: 30, flex: 1, overflow: 'hidden' }}>
          <Form
            submitMessage={'holiday.messages.holidayUpdatedSuccessfully'.getLabel()}
            mandatory={{
              name: 1,
              description: 1,
              date: 1,
            }}
            reloadEvent={`reload${MODELS.HOLIDAYCALENDAR}`}
            bodyContainerStyle={{
              backgroundColor: vars.colors.white,
              flex: 1,
              overflow: 'hidden',
            }}
            defaultValues={() => ({
              ...item,
            })}
            onSubmit={submit({
              model: MODELS.HOLIDAYS,
            })}
            closeView={1}
            {...props}
          >
            {({ form_context: formContext }) => (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    marginHorizontal: 42,
                    justifyContent: 'space-around',
                  }}
                >
                  <View>
                    <FormField
                      type="text"
                      label={'holiday.labels.name'.getLabel()}
                      variant="filled"
                      field="name"
                      style={{
                        width: 300,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View>
                    <FormField
                      type="textArea"
                      label={'holiday.labels.description'.getLabel()}
                      variant="filled"
                      field="description"
                      style={{
                        minHeight: 85,
                        width: 312,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginHorizontal: 42,
                    justifyContent: 'space-around',
                    marginTop: 15,
                  }}
                >
                  <View>
                    <FormField
                      type="date"
                      label={'holiday.labels.date'.getLabel()}
                      variant="filled"
                      field="date"
                      style={{
                        width: 300,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                  <View>
                    <FormField
                      type="toggleSwitch"
                      label={'holiday.labels.status'.getLabel()}
                      container="topLabel"
                      variant="filled"
                      field="isActive"
                      style={{
                        width: 312,
                      }}
                    />
                  </View>
                </View>
                <View
                  style={{
                    position: 'absolute',
                    bottom: 15,
                    left: 310,
                    marginTop: 25,
                  }}
                >
                  <LargeButton
                    label={'holiday.buttons.update'.getLabel()}
                    onClick={formContext.handleSubmit}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
              </>
            )}
          </Form>
        </View>
      )}
      {...props}
    />
  );
};
export default EditHoliday;
