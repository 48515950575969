import React from 'react';

import {
  Table,
  Text,
} from '../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetLoanedOutListUri } from '../../../../../../Queries/asset';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.loanOutDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: 'loanedOutDate',
      },
      {
        header: 'asset.headers.noOfItems'.getLabel(),
        type: 'number',
        value: ({ item: { assets = {} } = {} }) => assets?.length,
        width: 300,
      },
      {
        header: 'asset.headers.toDepartment'.getLabel(),
        type: 'text',
        value: ({ item: { loanedOutDept = {} } = {} }) => loanedOutDept?.name,
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text
                style={{
                  ...vars.headings.h11,
                  color: vars.colors.secondary.color2,
                }}
              >
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewLoanOutRecordAsset.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.ASSET_}`,
  uri: AssetLoanedOutListUri,
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewLoanOutRecordAsset.name,
      params: { item },
    },
  }),
});
