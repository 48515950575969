import masters from './Masters';
import importExports from './ImportExcelData';
import EmployeeManagement from './EmployeeManagement';
import OPDScreens from './OPD';
import Billing from './Billing';
import WelcomeScreen from './Welcome';
import Profiles from './Profiles';
import Accounts from './Accounts';
import Inventory from './Inventory';
import Vaccination from './Vaccination';
import IPDScreens from './IPD';
import AuctionScreens from './Auction';
import viewGeneralSurgeryTabs from './IPD/PatientAdmission/Patient/IpdForms/index';
import AddNewOperationNotes from './IPD/PatientAdmission/Patient/IpdForms/GeneralSurgery/OperationOrProcedureNotes/AddNewNote';
import Remarks from './Remarks';

export default {
  ...masters,
  ...importExports,
  ...EmployeeManagement,
  ...Inventory,
  ...OPDScreens,
  ...Billing,
  ...WelcomeScreen,
  ...Profiles,
  ...Accounts,
  ...Vaccination,
  ...IPDScreens,
  ...AuctionScreens,
  ...viewGeneralSurgeryTabs,
  ...AddNewOperationNotes,
  ...Remarks,
};
