import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS, modules } from '../../../../Lib/constants';
import {
  validateAlphabetsWithSpaceOnly, validateEmail, validateInputLength, validateMobile,
} from '../../../../Lib/helpers';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'asset.labels.supplierName'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (validateAlphabetsWithSpaceOnly(value)) {
        return prevValue;
      }
      return value.replace(/ {2,}/g, ' ');
    },
    mandatory: true,
  },
  {
    type: 'text',
    field: 'email',
    label: 'asset.labels.email'.getLabel(),
    mandatory: true,
  },
  {
    type: 'number',
    field: 'mobile',
    label: 'asset.labels.contact'.getLabel(),
    mandatory: true,
    allowedDecimalDigits: false,
    modifyValueOnChange: ({ prevValue, value }) => {
      if (value?.toString()?.length > 10) {
        return prevValue;
      }
      return value;
    },
  },
  {
    type: 'textArea',
    field: 'address',
    label: 'asset.labels.address'.getLabel(),
    width: 830,
    minHeight: 75,
    mandatory: true,
  },

];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 46),
  description: ({ data }) => validateInputLength(data?.description, 0, 400),
  email: ({ data }) => validateEmail(data?.email),
  address: ({ data }) => validateInputLength(data?.address, 0, 200),
  mobile: ({ data }) => validateMobile(data?.mobile),
};

export default MasterForm({
  model: MODELS.PHARMACY_SUPPLIER,
  formFields,
  validations,
  mandatory: {
    mobile: 1, email: 1, address: 1, name: 1,
  },
  uniqueValidationMessage: 'asset.messages.supplierAlreadyExists'.getLabel(),
  submitMessage: 'asset.messages.supplierAddedSuccessfully'.getLabel(),
  defaultValues: () => ({ module: modules.asset }),
});
