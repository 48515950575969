import React from 'react';

import { MODELS, ROUTES } from '../../../../../Lib/constants';
import permissions from '../../../../../Lib/permissions';
import MasterList from '../../../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 14 },
  {
    header: 'donorAndDonations.headers.donorId'.getLabel(),
    type: 'text',
    field: 'donorId',
  },
  {
    header: 'donorAndDonations.headers.donorName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'donorAndDonations.headers.bloodGroup'.getLabel(),
    type: 'text',
    field: 'bloodGroup',
  },
  {
    header: 'donorAndDonations.headers.age'.getLabel(),
    type: 'text',
    value: ({ item }) => `${item?.age || ''} ${item?.ageType || ''}`,
    sortable: 'age',
  },
  {
    header: 'donorAndDonations.headers.gender'.getLabel(),
    type: 'text',
    field: 'gender',
  },
  {
    header: 'donorAndDonations.headers.mobile'.getLabel(),
    type: 'number',
    field: 'contactNo',
    width: 250,
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.BLOOD_DONOR}
    editFormId={ROUTES.editDonor.name}
    access_key={permissions.inventory.views.editDonor.permissions.editDonor.value}
    tableColumns={tableColumns}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewDonor.name,
        params: { item },
      },
    })}
  />
);
