import { upperFirst } from 'lodash';
import React from 'react';

import { Text } from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { getUser } from '../../../../../AppServices';
import {
  MODELS,
  purchaseSupplyOrderStatus,
  Roles,
  RolesIndex,
  ROUTES,
} from '../../../../../Lib/constants';
import { supplyOrderRequest } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';
import {
  isSuperadmin,
  getRoleIndexes,
  isCreateSoPermissionGiven,
  reduceDaysLimitApprovalBasedOnRole,
} from '../../../../../Lib/helpers';

const tabListColumns = [
  { width: 14 },
  {
    header: 'purchase.header.creationDate'.getLabel(),
    type: 'date',
    field: 'SOCreatedOn',
    sortable: 'SOCreatedOn',
  },
  {
    header: 'purchase.header.soNumber'.getLabel(),
    type: 'text',
    field: 'uniqueNumber',
  },
  {
    header: 'purchase.header.department'.getLabel(),
    type: 'text',
    value: ({ item }) => upperFirst(item?.inventoryType),
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    // eslint-disable-next-line consistent-return
    render: ({ item }) => {
      const { currentApprovalQueue } = item;
      const assignedToRole = currentApprovalQueue?.assignedToRoleKey === 7 ? 'HOD' : currentApprovalQueue?.assignedToRole;
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.PENDING) {
        return (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.warning,
            }}
          >
            {`SO in Approval (By ${assignedToRole})`}
          </Text>
        );
      }
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.REJECTED) {
        return <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>SO Rejected</Text>;
      }
      if (
        (currentApprovalQueue?.status === purchaseSupplyOrderStatus?.APPROVED,
        currentApprovalQueue?.assignedToRole === Roles?.director)
      ) {
        return (
          <Text
            style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}
          >
            SO Approved
          </Text>
        );
      }
    },
  },
  {
    header: 'purchase.header.urgent'.getLabel(),
    type: 'text',
    render: ({ item }) => {
      if (item?.urgent) {
        return (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.error,
            }}
          >
            Yes
          </Text>
        );
      }
      return (
        <Text
          style={{
            ...vars.headings.h11,
            color: vars.colors.error,
          }}
        >
          No
        </Text>
      );
    },
  },
  {
    header: 'purchase.header.daysLimitForApproval'.getLabel(),
    type: 'text',
    render: ({ item }) => (
      <Text style={{ ...vars.headings.h9 }}>
        {reduceDaysLimitApprovalBasedOnRole(
          item?.daysLimitForApproval,
          item?.currentApprovalQueue?._createdOn,
          item?.status,
          item?.currentApprovalQueue,
        )}
      </Text>
    ),
  },
  {
    header: 'purchase.header.action'.getLabel(),
    type: 'openActions',
    openActions: [
      {
        render: () => (
          <Text style={{
            ...vars.headings.h11,
            color: vars.colors.secondary.color2,
          }}
          >
            View
          </Text>
        ),
        type: 'link',
        link: ({ item }) => ({
          view: ROUTES.viewSupplyRequest.name,
          params: { item },
        }),
      },
    ],
  },
];

const SupplyOrderRequestList = (props) => {
  const { tableColumns = tabListColumns } = props;
  const userRole = getUser();
  return (
    <Table
      {...props}
      lg={{ columns: tableColumns }}
      uri={() => (isSuperadmin(userRole) || isCreateSoPermissionGiven(userRole)
        ? supplyOrderRequest({
          addOnFilter: { isSOCreated: true, isPOCreated: false },
        })
        : supplyOrderRequest({
          addOnFilter: {
            isSOCreated: true,
            isPOCreated: false,
            $or: [
              {
                allAssignees: {
                  $in: getRoleIndexes(RolesIndex, userRole, 'SO'),
                },
              },
              {
                assignToDepartmentForSendForReview: {
                  _id: { $in: userRole.employee.department.map((dep) => dep?._id) },
                },
              },
            ],
          },
        }))}
      reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
      action={(data) => ({
        type: 'link',
        link: {
          view: ROUTES.viewSupplyRequest.name,
          params: { item: data?.item },
        },
      })}
    />
  );
};
export default SupplyOrderRequestList;
