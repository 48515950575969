import { MODELS } from '../Lib/constants';

export const GetHistoryUri = (filter = {}) => ({
  query: {
    model: MODELS.IPD_HISTORY,
    id: 'history',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.IPD_HISTORY,
});
