import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import { Text, View } from 'react-native';
import {
  autoSuggestInput, searchInput,
} from '../../../../../autoSuggestions';
import { MODELS, modules } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { currencyInput } from '../../../../../compositeInputs';
import { Form } from '../../../../../app-components/form/Form';

const getNewId = () => `new_${uuid()}`;

const AddItem = (props) => {
  const {
    navigation: {
      state: {
        params: {
          defaultValues,
        } = {},
      } = {},
    } = {},
  } = props;
  const [isVaccine, setIsVaccine] = useState(false);

  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      onSubmit={(params) => {
        const { data, updates } = params;
        const { data: { rateContract } } = params;
        rateContract.map((item) => {
          const { supplier = {}, manufacturer = {}, rateContractValidity = '' } = item;
          if (supplier?._id || rateContractValidity || manufacturer?._id) {
            if (!manufacturer._id || !rateContractValidity) {
              throw new Error(
                'Either add Supplier, Manufacturer and Rate Contract Validity or leave all empty.',
              );
            }
          }
          return true;
        });
        const submitAPI = submit({
          model: MODELS.PHARMACY_ITEM,
        });
        const result = submitAPI({ data, updates });
        return result;
      }}
      reloadEvent={`reload${MODELS.PHARMACY_ITEM}`}
      defaultValues={() => ({
        ...defaultValues,
        isActive: true,
        currency: 'INR',
        rateContract: [{ _id: getNewId() }],
      })}
      computations={{
        children: {
          rateContract: {
            self: {
              rateContractComputations: {
                compute: () => ({ set: { manufacturer: '' } }),
                onChange: ['supplier'],
              },
            },
          },
        },
        self: {
          isVaccine: {
            compute: () => {
              setIsVaccine(!isVaccine);
            },
            onChange: ['isVaccine'],
          },
          subCategory: {
            compute: () => ({ set: { subCategory: '' } }),
            onChange: ['category'],
          },
          autoFill1: {
            compute: (data) => {
              if (data?.isVaccineRecommended) {
                return {
                  set: { isVaccineRecommended: false, isVaccineGlobal: !data?.isVaccineGlobal },
                };
              }

              return {};
            },
            onChange: ['isVaccineGlobal'],
          },
          autoFill2: {
            compute: (data) => {
              if (data?.isVaccineGlobal) {
                return {
                  set: {
                    isVaccineGlobal: false,
                    isVaccineRecommended: !data?.isVaccineRecommended,
                  },
                };
              }

              return {};
            },
            onChange: ['isVaccineRecommended'],
          },
        },
      }}
      submitMessage={'inventory.messages.itemAddedSuccessfully'.getLabel()}
      uniqueValidationMessage={'inventory.messages.itemAlreadyExists'.getLabel()}
      mandatory={{
        name: 1,
        category: 1,
        subCategory: 1,
      }}
      lg={{
        validations: {
          sellingPrice: ({ data }) => {
            if (!data?.isVaccineFree && !data?.sellingPrice) {
              return 'inventory.errorMessage.thisFieldCannotBeEmpty'.getLabel();
            }

            return false;
          },
        },
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'inventory.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'name',
                    label: 'inventory.labels.itemName'.getLabel(),
                    allowWhiteSpaces: true,
                    mandatory: true,
                  },
                  searchInput({
                    field: 'category',
                    label: 'inventory.labels.category'.getLabel(),
                    placeholder: 'inventory.placeholders.category'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_CATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: { module: modules.pharmacy },
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'subCategory',
                    label: 'inventory.labels.subCategory'.getLabel(),
                    placeholder: 'inventory.placeholders.subCategory'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUBCATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data } = {}) => ({
                      category: { _id: data?.category?._id },
                    }),
                  }),
                  {
                    type: 'number',
                    field: 'reorderLevel',
                    label: 'inventory.labels.reorderLevel'.getLabel(),
                    placeholder: 'inventory.placeholders.reorderLevel'.getLabel(),
                  },

                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  {
                    ...currencyInput({
                      variant: 'filled',
                      amount: {
                        field: 'sellingPrice',
                        label: 'inventory.labels.sellingPrice'.getLabel(),
                        disabled: ({ data }) => data?.isVaccineFree,
                      },
                      currency: {
                        field: 'currency',
                      },
                    }),
                  },
                  {
                    type: 'toggleSwitch',
                    field: 'isActive',
                    label: 'inventory.labels.status'.getLabel(),
                    container: 'leftLabel',
                    mandatory: true,
                  },
                  {
                    type: 'textArea',
                    field: 'description',
                    label: 'inventory.labels.description'.getLabel(),
                    placeholder: 'inventory.placeholders.description'.getLabel(),
                    minHeight: 80,
                    style: { flex: 2, overflow: 'hidden' },
                  },
                  {
                    type: 'toggleSwitch',
                    field: 'isVaccineFree',
                    label: 'inventory.labels.paymentType'.getLabel(),
                    onValueLabel: 'Free',
                    offValueLabel: 'Paid',
                    container: 'topLabel',
                  },
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'checkBox',
                    label: 'Is Vaccine',
                    field: 'isVaccine',
                    visible: () => {
                      if (defaultValues) return false;
                      return true;
                    },
                  },
                  isVaccine
                    ? {
                      type: 'checkBox',
                      label: 'Global',
                      field: 'isVaccineGlobal',
                    } : null,
                  isVaccine ? {
                    type: 'checkBox',
                    label: 'Recommended',
                    field: 'isVaccineRecommended',
                  } : null,
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.manufacturerSupplierDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'rateContract',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'inventory.labels.addRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: true,
                        columns: [
                          searchInput({
                            field: 'supplier',
                            variant: 'filled',
                            label: 'inventory.labels.supplier'.getLabel(),
                            placeholder: 'inventory.placeholders.supplier'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_SUPPLIER,
                            query: 'masterDataList',
                          },
                          {
                            addOnFilter: ({
                              data:
                            { _parent: { rateContract = [] } = {} },
                            } = {}) => {
                              const supplierIds = rateContract?.filter(
                                ({ supplier }) => supplier?._id,
                              ).map(({ supplier }) => supplier?._id);
                              return {
                                _id: { $nin: supplierIds },
                                module: modules.pharmacy,
                              };
                            },
                          }),
                          searchInput({
                            field: 'manufacturer',
                            variant: 'filled',
                            label: 'inventory.labels.manufacturer'.getLabel(),
                            placeholder: 'inventory.placeholders.manufacturer'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            model: MODELS.PHARMACY_MANUFACTURER,
                            query: 'masterDataList',
                          },
                          {
                            addOnFilter: ({
                              data: {
                                supplier: { _id },
                              },
                            }) => ({ supplier: { _id } }),
                          }),
                          {
                            type: 'date',
                            field: 'rateContractValidity',
                            variant: 'filled',
                            label: 'inventory.labels.rateContractValidity'.getLabel(),
                            placeholder: 'inventory.placeholders.rateContractValidity'.getLabel(),
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default AddItem;
