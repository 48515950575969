import React from 'react';

import MasterForm from '../../../../Wired/Master/MasterForm';
import { EditDepartment } from '../../../../Queries/department';
import { MODELS } from '../../../../Lib/constants';
import DepartmentTabs from './components/Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'department.labels.departmentName'.getLabel(),
    mandatory: true,
    editable: false,
  },
  {
    type: 'text',
    field: 'code',
    label: 'department.labels.departmentId'.getLabel(),
    mandatory: true,
    editable: false,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'department.labels.status'.getLabel(),
    placeholder: 'Select',
    editable: false,
  },
  {
    type: 'text',
    field: 'description',
    label: 'department.labels.description'.getLabel(),
    editable: false,
  },
  {
    type: 'status',
    field: 'trackBeds',
    label: 'department.labels.containNumberOfBeds'.getLabel(),
    editable: false,
  },
  {
    type: 'date',
    format: 'DD MMM YYYY @ hh:mm A',
    field: '_createdOn',
    label: 'department.labels.createdOn'.getLabel(),
    editable: false,
  },
];

export default MasterForm({
  uri: EditDepartment,
  model: MODELS.DEPARTMENTS,
  formFields,
  uniqueValidationMessage: 'department.messages.departmentAlreadyExists'.getLabel(),
  submitMessage: 'department.messages.departmentEditedSuccessfully'.getLabel(),
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <DepartmentTabs {...props} />
    ),
  }],
});
