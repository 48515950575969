export default {
  headers: {
    issueDate: 'Issuing Date',
    issueNumber: 'Issue Number',
    issuer: 'Patient Name/Company Name',
    reason: 'Reason For Issuing Blood',
    unitIssued: 'Unit Issued',
    donationDate: 'Date of Donation/Collection',
    bagNo: 'Bag NO.',
    donationNo: 'Donation No.',
    tubeNo: 'Tube No.',
    donorName: 'Donor Name',
    bloodGroup: 'Blood Group',
    date: 'Date',
    department: 'Department',
    speciality: 'Speciality',
    doctorName: 'Doctor Name',
    patientName: 'Patient Name',
    status: 'Status',
  },
  labels: {
    issueNumber: 'Issue Number',
    dateOfIssue: 'Date Of Issue',
    patientName: 'Patient Name',
    age: 'Age',
    uhid: 'UHID',
    bloodGroup: 'Blood Group',
    reason: 'Reason For Issuing Blood',
    unitIssued: 'Unit Issued',
    companyName: 'Company Name',
  },
};
