import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TextInput } from '@applane/react-text-input';
import { debounce } from 'lodash';
import { TokenInputTheme } from './TokenInputTheme';
import { invoke } from '../../AppServices';
import { Text, Toast } from '../../app-components';
import vars from '../../theme/vars';
import { getErrorString } from '../../Lib/helpers';

const TokenInput = (props) => {
  const { item, eventDispatcher, sessionId } = props;
  const [value, setValue] = useState(item?.token);

  useEffect(() => {
    setValue(item?.token);
  }, [item]);

  const handleBlur = useCallback(async (input) => {
    if (!input) {
      return;
    }
    try {
      const result = await invoke({
        id: 'assignVaccinationToken',
        paramValue: {
          token: parseInt(input, 10),
          vaccinationId: item?._id,
          sessionId,
        },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'incomingPatient.messages.tokenAssignedSuccessfully'.getLabel(),
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        if (result) {
          eventDispatcher.notify('reloadVaccinations');
        }
      }
    } catch (err) {
      setValue(null);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [eventDispatcher, item._id, sessionId]);

  const debouncedOnChange = useMemo(() => debounce(handleBlur, 2000), [handleBlur]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
    debouncedOnChange(e.target.value);
  }, [debouncedOnChange]);

  if (item?.isTokenAssigned !== false) {
    return (
      <Text
        style={[
          vars.headings.h9,
          { color: vars.colors.grey.color1 },
        ]}
      >
        {item?.token}
      </Text>
    );
  }

  return (
    <TextInput
      value={value}
      placeholder="token"
      name="token"
      type="number"
      onChange={handleChange}
      inputProps={{
        type: 'number',
      }}
      {...TokenInputTheme}
    />
  );
};

export default TokenInput;
