import React from 'react';
import { Text } from 'react-native';
import { FormHoc } from '../../../../app-components';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { bloodRequestTypes } from '../../../../Lib/constants';
import { ViewBloodIssueUri } from '../../../../Queries/bloodBank';
import vars from '../../../../theme/vars';

export default FormHoc({
  type: 'standard',
  uri: ViewBloodIssueUri,
  closeView: 1,
  editable: false,

  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'date',
                field: 'issueDate',
                label: 'bloodBankRecords.labels.dateOfIssue'.getLabel(),
              },
              {
                type: 'text',
                field: 'issueId',
                label: 'bloodBankRecords.labels.issueNumber'.getLabel(),
              },
              {
                type: 'text',
                field: 'patient.uhid',
                label: 'bloodBankRecords.labels.uhid'.getLabel(),
                visible: ({ data }) => {
                  if (data?.is_issue_to_company) return false;
                  return true;
                },
              },
              {
                type: 'text',
                label: 'bloodBankRecords.labels.patientName'.getLabel(),
                field: 'patient.patientName',
                visible: ({ data }) => {
                  if (data?.is_issue_to_company) return false;
                  return true;
                },
              },
              {
                type: 'text',
                label: 'bloodBankRecords.labels.companyName'.getLabel(),
                field: 'issue_to_company.name',
                visible: ({ data }) => {
                  if (data?.is_issue_to_company) return true;
                  return false;
                },
              },
              {
                type: 'number',
                render: ({ data }) => (
                  <Text style={{ ...vars.headings.h8 }}>
                    {`${data?.patient?.age || ''} ${data?.patient?.ageType || ''}`}
                  </Text>
                ),
                label: 'bloodBankRecords.labels.age'.getLabel(),
                visible: ({ data }) => {
                  if (data?.is_issue_to_company) return false;
                  return true;
                },
              },
              {
                type: 'textArea',
                field: 'description',
                label: 'bloodBankRecords.labels.reason'.getLabel(),
              },
              {
                type: 'number',
                render: (item) => item?.data?.bag_details?.length,
                label: 'bloodBankRecords.labels.unitIssued'.getLabel(),
              },
              autoSuggestInput({
                label: 'bloodInventory.labels.requestType'.getLabel(),
                field: 'requestType',
                suggestionField: 'label',
                options: bloodRequestTypes,
                visible: ({ data }) => {
                  if (!data?.is_requested) return false;
                  return true;
                },
              }),
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: ' APPROVED BY',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 4,
        },
        columnsPerRow: 4,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'approvedOrRejectedBy.name',
                variant: 'filled',
                label: 'bloodInventory.labels.employeeName'.getLabel(),
              },
              {
                type: 'text',
                field: 'approvedOrRejectedBy.employee_code',
                variant: 'filled',
                label: 'bloodInventory.labels.employeeId'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'Bag Details',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 4,
        },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'bag_details',
                fieldType: 'nested',
                editable: false,
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      {
                        type: 'text',
                        header: 'donorAndDonations.headers.bagNo'.getLabel(),
                        field: 'name',
                        variant: 'filled',
                        width: 200,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'tubeNo',
                        variant: 'filled',
                        header: 'donorAndDonations.headers.tubeNo'.getLabel(),
                        width: 200,
                        editable: false,
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        header: 'bloodInventory.labels.bloodGroup'.getLabel(),
                        field: 'bloodGroup',
                        variant: 'filled',
                        width: 200,
                        editable: false,
                      }),
                      {
                        type: 'text',
                        field: 'category',
                        variant: 'filled',
                        header: 'donorAndDonations.headers.category'.getLabel(),
                        width: 200,
                        editable: false,
                      },
                      {
                        type: 'date',
                        field: 'date',
                        variant: 'filled',
                        header: 'donorAndDonations.headers.donationDate'.getLabel(),
                        width: 200,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'bloodDonor.name',
                        variant: 'filled',
                        header: 'donorAndDonations.headers.donorName'.getLabel(),
                        width: 200,
                        editable: false,
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
});
