import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View } from '@applane/react-core-components';
import FloatingActions from '../action/FloatingActions';
import {
  screenHeaderToolBarTheme,
  screenHeaderToolBarMobileTheme,
  screenFooterToolBarTheme,
} from '../../theme/toolBarTheme';
import FilterBar from '../filter/FilterBar';
import { isMobile } from '../UtilityFunctions';
import ToolBar from '../toolBar/ToolBar';
import { ScreenDataContext } from '../stack/ScreenDataContext';

export class ScreenComponent extends React.Component {
  constructor(props) {
    super(props);
    const { uid, fetchUriEvent } = props;
    this.fetchUriEvent = fetchUriEvent || `${uid || uuid()}_fetchUri`;
  }

  setActiveScreenState = ({ state = {} } = {}) => {
    const { setActiveScreenState, navigation } = this.props;
    if (setActiveScreenState) {
      const screenState = {
        data: state?.data,
        fetchMoreProps: state?.fetchMoreProps,
        aggregates: state?.aggregates,
      };
      setActiveScreenState({
        value: screenState,
        key: navigation.getScreenName(),
      });
    }
  };

  getActiveScreenState = () => {
    const { getActiveScreenState, navigation } = this.props;
    if (getActiveScreenState) {
      return getActiveScreenState(navigation.getScreenName());
    }
  };

  setFormData = (data) => {
    const { setActiveScreenState, navigation } = this.props;
    if (setActiveScreenState) {
      setActiveScreenState({
        value: { data },
        key: navigation.getScreenName(),
      });
    }
  };

  componentDidMount() {
    const { eventDispatcher } = this.props;
    eventDispatcher?.listen &&
      eventDispatcher.listen(
        `${this.fetchUriEvent}_loadData`,
        this.setActiveScreenState,
      );
    eventDispatcher &&
      eventDispatcher.listen(
        `${this.props.navigation.getScreenName()}_loadData`,
        this.setFormData,
      );
  }

  componentWillUnmount() {
    const { eventDispatcher, setActiveScreenState, navigation } = this.props;
    if (setActiveScreenState) {
      setActiveScreenState({
        value: void 0,
        key: navigation.getScreenName(),
      });
    }
    eventDispatcher?.unlisten &&
      eventDispatcher.unlisten(
        `${this.fetchUriEvent}_loadData`,
        this.setActiveScreenState,
      );
    eventDispatcher &&
      eventDispatcher.unlisten(
        `${this.props.navigation.getScreenName()}_loadData`,
        this.setFormData,
      );
  }

  render() {
    let {
      screen: Component,
      floatingActions,
      header,
      footer,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      state,
      setState,
      setTabsState,
      getActiveScreenState,
      setActiveScreenState,
      ...rest
    } = this.props;
    let currentScreenState = this.getActiveScreenState();

    const navigationScreenState =
      (navigation &&
        navigation.getScreenState &&
        navigation.getScreenState()) ||
      {};
    const componentProps = {
      fetchUriEvent: this.fetchUriEvent,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      state,
      setState,
      setTabsState,
    };
    const { filterAction } = navigationScreenState;
    let absoulteTopFilterBar = void 0;
    if (filterAction) {
      if (filterAction.position === 'absolute') {
        absoulteTopFilterBar = (
          <FilterBar
            absolutePosition="top"
            {...componentProps}
            {...filterAction}
          />
        );
      } else {
        header = { ...header };
        header.actions = header.actions ? [...header.actions] : [];
        header.actions.unshift(
          <FilterBar {...componentProps} {...filterAction} />,
        );
      }
    }

    return (
      <View style={{ flex: 1, overflow: 'hidden' }}>
        {absoulteTopFilterBar}
        {header && (
          <ToolBar
            {...(isMobile
              ? screenHeaderToolBarMobileTheme
              : screenHeaderToolBarTheme)}
            {...componentProps}
            {...header}
            {...currentScreenState}
          />
        )}
        <Component
          {...componentProps}
          {...rest}
          {...(screenState && screenState.dataParams ? { dataParams: screenState.dataParams } : {})}
        />
        {footer && (
          <ToolBar
            {...screenFooterToolBarTheme}
            {...componentProps}
            {...footer}
            {...currentScreenState}
          />
        )}
        <FloatingActions
          floatingActions={floatingActions}
          {...componentProps}
          {...rest}
        />
      </View>
    );
  }
}

export default (props) => {
  let { stateRequiredOnTop, ...restProps } = props;
  if (stateRequiredOnTop) {
    return (
      <ScreenDataContext.Consumer>
        {(context) => {
          let { getActiveScreenState, setActiveScreenState } = context;
          return (
            <ScreenComponent
              {...restProps}
              getActiveScreenState={getActiveScreenState}
              setActiveScreenState={setActiveScreenState}
            />
          );
        }}
      </ScreenDataContext.Consumer>
    );
  } else {
    const [state, setState] = useState(void 0);

    const setScreenState = ({ value }) => {
      if (value) {
        setState(value);
      }
    };

    const getScreenState = () => {
      return state;
    };
    return (
      <ScreenComponent
        {...restProps}
        getActiveScreenState={getScreenState}
        setActiveScreenState={setScreenState}
      />
    );
  }
};
