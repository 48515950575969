import React from 'react';

import NotificationCard from './NotificationCard';
import { Table } from '../../app-components/table/Table';
import notficiationTableStyle from './theme';

const List = (props) => {
  const {
    navigation,
    eventDispatcher,
    hideModal,
    data,
  } = props;

  return (
    <Table
      hideModal={hideModal}
      hideColumnHeader
      data={data}
      navigation={navigation}
      eventDispatcher={eventDispatcher}
      {...notficiationTableStyle}
      columns={[
        {
          type: 'custom',
          flex: 1,
          render: (renderProps) => <NotificationCard {...renderProps} />,
        },
      ]}
    />
  );
};

export default List;
