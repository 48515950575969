import React, { useEffect } from 'react';
import BarCode from '../../../../Components/BarCode';

import { Text } from '../../../../app-components';

import { autoSuggestInput } from '../../../../autoSuggestions';
import { EditPatientUri } from '../../../../Queries/patient';
import { ROUTES } from '../../../../Lib/constants';
import ViewPatientTabs from './Components/Tabs';
import vars from '../../../../theme/vars';
import { getString } from '../../../../Lib/helpers';
import { Form } from '../../../../app-components/form/Form';

const ViewPatientForm = (props) => {
  const { navigation } = props;

  return (
    <Form
      {...props}
      uri={EditPatientUri({ navigation })}
      type="standard"
      reloadOnChangeEvent="reloadOPDRegisteredPatients"
      defaultValues={{
        relation: 'S/O',
      }}
      extraFormGroups={[{
        type: 'custom',
        render: ({ data }) => (
          <ViewPatientTabs navigation={navigation} data={data} />
        ),
      }]}
      mandatory={{
        firstName: 1,
        lastName: 1,
        mobile: 1,
        gender: 1,
        age: 1,
        aadharNumber: 1,
      }}
      lg={{
        verticalFormGroups: [
          {
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'patientName',
                        label: 'patient.labels.patientName'.getLabel(),
                        mandatory: true,
                        editable: false,
                      },
                      autoSuggestInput({
                        label: 'patient.labels.gender'.getLabel(),
                        field: 'gender',
                        suggestionField: 'label',
                        placeholder: 'patient.placeholders.selectGender'.getLabel(),
                        options: [
                          { value: 'male', label: 'Male' },
                          { value: 'female', label: 'Female' },
                          { value: 'other', label: 'Other' },
                        ],
                        mandatory: true,
                        editable: false,
                      }),
                      {
                        type: 'number',
                        field: 'age',
                        label: 'patient.labels.age'.getLabel(),
                        mandatory: true,
                        editable: false,
                        render: ({ data }) => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {data?.age}
                            {' '}
                            {data?.ageType}
                          </Text>
                        ),
                      },
                      {
                        type: 'text',
                        label: 'patient.labels.relation'.getLabel(),
                        render: ({ data }) => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {getString([data?.relation, data?.relationName])}
                          </Text>
                        ),
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'aadharNumber',
                        label: 'patient.labels.aadhar'.getLabel(),
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'BPLNumber',
                        label: 'patient.labels.bpl'.getLabel(),
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'mobile',
                        label: 'patient.labels.mobile'.getLabel(),
                        placeholder: 'patient.placeholders.mobile'.getLabel(),
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'address',
                        label: 'patient.labels.address'.getLabel(),
                        placeholder: 'patient.placeholders.address'.getLabel(),
                        editable: false,
                      },
                      // {
                      //   type: 'textArea',
                      //   field: 'remarks',
                      //   label: 'patient.labels.remarks'.getLabel(),
                      //   placeholder: 'patient.placeholders.remarks'.getLabel(),
                      //   editable: false,
                      // },
                    ],
                  },
                  {
                    columnsPerRow: 1,
                    width: 250,
                    columns: [
                      {
                        type: 'custom',
                        render: ({ data }) => <BarCode value={data?.uhid} allowScan={false} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            formGroupsContainerStyle: { backgroundColor: vars.colors.white },
            formGroups: [
              {
                groups: [
                  {
                    columns: [{
                      type: 'custom',
                      render: ({ data }) => (
                        <ViewPatientTabs navigation={navigation} data={data} />
                      ),
                    }],
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

const ViewPatient = (props) => {
  const { navigation } = props;
  const { state: { params } = {} } = navigation;

  useEffect(() => {
    if (navigation?.state?.params?.openAddConsultationForm) {
      navigation.push(
        {
          view: ROUTES.addConsultation.name,
          modal: true,
          params,
          modalProps: {
            autoHide: true,
            width: 696,
            height: 516,
          },
        },
      );
    } else if (navigation?.state?.params?.openAdmitPatientForm) {
      navigation.push(
        {
          view: ROUTES.admitPatientToIPD.name,
          params,
        },
      );
    }
  }, []);

  return (<ViewPatientForm {...props} />);
};

export default ViewPatient;
