import React from 'react';
import { Image, TouchableOpacity } from '../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { editRecord } from '../../../../../images';
import { Table } from '../../../../../app-components/table/Table';

const tabListColumns = [
  {
    header: 'holiday.headers.holiday_type'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'holiday.labels.date'.getLabel(),
    type: 'date',
    field: 'date',
    sortable: 'date',
  },
  {
    header: 'holiday.headers.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'holiday.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'holiday.labels.action'.getLabel(),
    type: 'text',
    render: () => (
      <TouchableOpacity>
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </TouchableOpacity>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        modal: true,
        view: ROUTES.editHoliday.name,
        params: { item },
        modalProps: {
          autoHide: true,
          width: 724,
          height: 437,
        },
      },
    }),
  },
];

const HolidayTabList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      {...restProps}
      uri={{
        query: {
          model: MODELS.HOLIDAYS,
          id: 'masterDataList',
          addOnFilter,
        },
        model: MODELS.HOLIDAYS,
      }}
      columns={tableColumns}
      showActionIcon={false}
      hoverable={false}
    />
  );
};
export default HolidayTabList;
