/* eslint-disable no-void */
import React from 'react';

import { Text, View } from '../../app-components';
import { DataFetch } from '../../app-components/data-fetch/DataFetch';
import vars from '../../theme/vars';

const HeaderTitleWithCount = ({ label, count }) => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <Text
      style={[
        vars.headings.h5,
        {
          color: vars.colors.primary.color1,
        },
      ]}
    >
      {label}
    </Text>
    {count ? (
      <Text
        style={[
          vars.headings.h8,
          {
            color: vars.colors.grey.color3,
            marginLeft: 5,
          },
        ]}
      >
        {`(${count})`}
      </Text>
    ) : (
      void 0
    )}
  </View>
);

export default HeaderTitleWithCount;

export class HeaderTitleWithDataFetchCount extends React.Component {

  resolveCount = (props) => {
    const { loaded, aggregates } = props || {};
    if (loaded) {
      return `${(aggregates && aggregates._count) || 0}`;
    }
    return null;
  };
  render() {
    let {
      tabKey,
      dataFetchProps,
      navigation,
      eventDispatcher,
      reloadEvent,
      countUri,
      label,
      selectionCount
    } = this.props;
    if (!countUri) {
      return <HeaderTitleWithCount label={label} />
    }
    if (countUri && typeof countUri === 'function') {
      countUri = countUri({ navigation });
    }
    if (dataFetchProps && typeof dataFetchProps === 'function') {
      dataFetchProps = dataFetchProps({
        navigation,
      });
    }

    return (
      <DataFetch
        screenName={`HeaderCountTitle-${navigation.state?.routeName}`}
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        reloadEvent={reloadEvent}
        uri={countUri}
        {...dataFetchProps}>
        {result => {
          let Count = this.resolveCount({ ...result });
          if (
            Count === undefined ||
            Count === null
          ) {
            return <HeaderTitleWithCount label={label} />
          }
          if (typeof Count !== 'string' && Count?.toString) {
            Count = Count?.toString();
          }
          return <HeaderTitleWithCount count={selectionCount || Count} label={label} />;
        }}
      </DataFetch>
    );
  }
}
