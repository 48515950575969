/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import {
  View,
  Text,
  Linking,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import Card from '@applane/react-card';
import { Avatar } from '@applane/react-avatar';
import { resolveExp } from '@applane/react-utility-functions';
import { DataCell, HeaderCell } from '@applane/react-table';
import moment from 'moment';
import { Headline6, Headline9 } from '../../theme/Headlines';
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { getDownloadUrl } from '../../AppServices';
import typeCast from './TypeCast';
import { OpenActions } from '../action/OpenActions';
import {
  checkSelectedIcon,
  moreOptionsIcon,
  unCheckboxIcon,
} from '../../images';
import { RowSelector, HeaderRowSelector } from '../table/selector/Selector';
import ToggleSwitchInput from '../../Components/Form/Switch';
import {
  textCellTheme,
  cardCellTheme,
  fileCellTheme,
  numberCellTheme,
  textHeaderCellTheme,
  numberHeaderCellTheme,
  numberEditableHeaderCellTheme,
  customHeaderCellTheme,
  linkCellTheme,
} from '../../theme/tableTheme';
import StatusCell from '../../Components/StatusCell';
import vars from '../../theme/vars';
import ConsultationStatusCell from '../../Components/ConsultationStatus';
import { get } from 'lodash';

const { primary_2, primary_5, surface_white } = manazeV6Colors;

export const AvatarRender = (props) => {
  const { column, item, selected } = props;
  let {
    field,
    avatarField,
    size = 32,
    avatarIcon,
    value,
    resolveIcon,
    avatarTextStyle,
  } = column;
  if (avatarField === undefined) {
    avatarField = field;
  }
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && avatarField) {
    value = resolveExp(item, avatarField);
  }
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  if (avatarIcon) {
    if (typeof avatarIcon === 'function') {
      avatarIcon = avatarIcon(props);
    } else {
      avatarIcon = resolveExp(item, avatarIcon);
    }
  }
  if (avatarIcon) {
    if (resolveIcon) {
      avatarIcon = resolveIcon(avatarIcon);
    }
  }
  avatarTextStyle = {
    ...Headline9,
    color: primary_2,
    ...avatarTextStyle,
  };
  return (
    <Avatar
      size={size}
      icon={avatarIcon}
      value={value || ''}
      backgroundColor={selected ? surface_white : primary_5}
      textStyle={avatarTextStyle}
    />
  );
};

export const AvatarTextRender = (props) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <AvatarRender {...props} />
    <View style={{ paddingLeft: 12, flex: 1, overflow: 'hidden' }}>
      <DataCell {...textCellTheme} {...props} />
    </View>
  </View>
);

export const CardRender = (props) => {
  const { column = {}, ...restProps } = props;
  const { header, footer, ...restColumn } = column;
  return (
    <Card
      typeCast={typeCast}
      {...cardCellTheme}
      {...restColumn}
      {...restProps}
    />
  );
};

export const FileRender = (props) => {
  const { column = {}, item, ...restProps } = props;
  const { field } = column;
  const value = resolveExp(item, field);
  const { name, key } = value || {};
  const downloadUrl = getDownloadUrl({ name, key, openInNewTab: true });
  const { linkTextStyle } = fileCellTheme;

  return (
    <TouchableOpacity
      style={{ cursor: 'pointer' }}
      onPress={() => Linking.openURL(downloadUrl)}
      onClick={(e) => e && e.stopPropagation && e.stopPropagation()}
    >
      <Text style={linkTextStyle}>{name || key || ''}</Text>
    </TouchableOpacity>
  );
};

export const checkBoxRender = (props) => {
  const { column = {}, item } = props;
  const { field } = column;
  const value = resolveExp(item, field);
  return (
    <Image
      source={value ? checkSelectedIcon : unCheckboxIcon}
      resizeMode="contain"
      style={{ height: 20, width: 20 }}
    />
  );
};

export const TextRender = (props) => DataCell({ ...linkCellTheme, typeCast, ...props });

export const LinkRender = (props) => DataCell({
  ...textCellTheme,
  typeCast,
  ...props,
  rowColumnTextStyle: {
    ...props?.rowColumnTextStyle,
    color: vars.colors.secondary.color2,
    textDecoration: 'underline',
  },
});

export const StatusRender = (props) => <StatusCell value={get(props?.item, props?.column?.field)} />;

export const ConsultationStatusRender = (props) => (
  <ConsultationStatusCell
    value={props?.item[props?.column?.field]}
  />
);

export const NumberRender = (props) => DataCell({ ...numberCellTheme, typeCast, ...props });

export const TextAreaRender = (props) => DataCell({
  ...textCellTheme, typeCast, html: true, ...props,
});

export const OpenActionsRender = (props) => {
  const { column, ...restProps } = props;
  return OpenActions({
    ...column,
    ...restProps,
    renderAction: (action) => <TextRender {...restProps} column={action} />,
  });
};

export const MoreActionsRender = (props) => {
  let {
    column: { icon, iconStyle },
  } = props || {};
  iconStyle = iconStyle || { padding: 6, objectFit: 'contain' };
  return <Image source={icon || moreOptionsIcon} style={iconStyle} />;
};

export const CustomRender = (props) => {
  const { column: { render } = {} } = props;
  return render ? render(props) : null;
};

export const RowSelectionRender = (props) => {
  const { item, column } = props;
  const { field } = column || {};

  return (
    <RowSelector
      data={item}
      field={field}
      {...props}
      selectedIcon={checkSelectedIcon}
      unCheckboxIcon={unCheckboxIcon}
    />
  );
};

export const HeaderRowSelectionRender = (props) => {
  const { column: { field } = {} } = props || {};
  return (
    <HeaderRowSelector
      {...props}
      field={field}
      selectedIcon={checkSelectedIcon}
      unCheckboxIcon={unCheckboxIcon}
    />
  );
};

export const HeaderTextRender = (props) => HeaderCell({ ...textHeaderCellTheme, ...props });

export const HeaderNumberRender = (props) => HeaderCell({
  ...((props.editable && numberEditableHeaderCellTheme)
      || numberHeaderCellTheme),
  ...props,
});

export const HeaderCustomRender = (props) => HeaderCell({ ...customHeaderCellTheme, ...props });
export const SwitchRender = (props) => {
  const { item } = props;
  return <ToggleSwitchInput data={item} {...textCellTheme} {...props} />;
};

export const DateRangeRender = (props) => {
  const {
    column: { field, format = 'DD MMM YY', ...restColumn },
    ...restProps
  } = props;
  const { item } = restProps;
  let value = void 0;
  if (field) {
    const { from, to } = field;
    const fromDate = resolveExp(item, from);
    const toDate = resolveExp(item, to);
    if (fromDate || toDate) {
      value = `${
        fromDate
          ? moment(fromDate).format(format)
          : restColumn.defaultText || ''
      } - ${
        toDate ? moment(toDate).format(format) : restColumn.defaultText || ''
      } `;
    }
  }
  return DataCell({
    ...textCellTheme,
    ...restProps,
    column: { value, ...restColumn },
  });
};

export const CurrencyRender = (props) => {
  const {
    column: { field, ...restColumn },
    ...restProps
  } = props;
  let value = void 0;
  if (field) {
    const { amount, currency } = field;
    const { item } = restProps;
    const amountValue = resolveExp(item, amount);
    const currencyValue = resolveExp(item, currency);
    value = typeCast.number({ value: amountValue, ...restColumn });
    if (currencyValue) {
      value = `${value || 0} ${currencyValue}`;
    }
  }
  return DataCell({
    ...numberCellTheme,
    ...restProps,
    column: { value, ...restColumn },
  });
};
export const RecursiveRender = (props) => {
  const {
    _level,
    column: { recursiveField },
    item,
    indentationWidth = 20,
    expanded,
    toggleExpanded,
  } = props;
  const expandable = item
    && recursiveField
    && item[recursiveField]
    && item[recursiveField].length;
  const rowComponent = DataCell({
    ...textCellTheme,
    ...props,
  });
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View style={{ width: (_level || 0) * indentationWidth }} />
      <View style={{ width: 18 }}>
        {expandable ? (
          <TouchableOpacity
            onPress={toggleExpanded}
            style={{ cursor: 'pointer' }}
          >
            <Text style={{ ...Headline6, color: primary_2 }}>
              {expanded ? '-' : '+'}
            </Text>
          </TouchableOpacity>
        ) : (
          void 0
        )}
      </View>
      <View style={{ flex: 1, overflow: 'hidden' }}>{rowComponent}</View>
    </View>
  );
};
