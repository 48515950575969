/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useCallback, useState } from 'react';
import ListActionModal from '../../../../../../Components/ListActionModal';
import {
  ActivityIndicator, FormField, LargeButton, Toast, View,
} from '../../../../../../app-components';
import { Form } from '../../../../../../app-components/form/Form';
import { autoSuggestInput } from '../../../../../../autoSuggestions';
import { issueToCompanyIcon } from '../../../../../../images';
import { MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { getUser, invoke, submit } from '../../../../../../AppServices';
import { getErrorString } from '../../../../../../Lib/helpers';

const IssueToCompanyModal = (props) => {
  const user = getUser();
  const [data, setData] = useState([]);
  const {
    navigation: { state: { params: { item: { navigation: { state: { params: { item: { category = '' } } } } } } } = {} } = {},
  } = props;

  const getDataForNestedTable = useCallback(async () => {
    try {
      const result = await invoke({
        id: '_find',
        paramValue: {
          query: {
            id: 'bloodGroupListForTable',
            model: MODELS.BLOOD_BANK,
            addOnFilter: {
              _id: {
                $in:
                  props?.navigation?.state?.params?.item?.state?.selectedIds,
              },
            },
          },
          model: MODELS.BLOOD_BANK,
        },
      });
      setData(result?.result);
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [props.navigation.state.params.item.state.selectedIds]);

  useEffect(() => {
    getDataForNestedTable();
  }, [getDataForNestedTable]);

  if (!data?.length) {
    return <ActivityIndicator />;
  }
  return (
    <ListActionModal
      icon={issueToCompanyIcon}
      title="Issue to company"
      description={`Select the company to which ${category} has to be issued. This action will change the selected bag status to ‘Issued to company’`}
      content={(
        <View style={{ marginTop: 25, flex: 1, overflow: 'hidden' }}>
          <Form
            submitMessage={'bloodInventory.messages.issueBloodMessage'.getLabel()}
            mandatory={{
              issue_to_company: 1,
            }}
            reloadEvent={`reload${MODELS.BLOOD_BANK}`}
            bodyContainerStyle={{
              backgroundColor: vars.colors.white,
              flex: 1,
              overflow: 'hidden',
            }}
            defaultValues={() => ({
              is_issue_to_company: true,
              is_approved: true,
              bag_details: data,
              category: data[0]?.category,
              approvedOrRejectedBy: { _id: user?.employee?._id },
            })}
            onSubmit={submit({
              model: MODELS.BLOOD_REQUEST,
            })}
            closeView={1}
            {...props}
          >
            {({ form_context: formContext }) => (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  marginHorizontal: 42,
                  justifyContent: 'space-around',
                }}
              >
                <View>
                  <FormField
                    {
                    ...autoSuggestInput({
                      label: 'Select Company',
                      field: 'issue_to_company',
                      variant: 'filled',
                      keyFiled: 'name',
                      suggestionField: 'name',
                      valueField: 'name',
                      query: 'masterDataListForTable',
                      model: MODELS.COMPANY_MANAGEMENT,
                    })
                    }
                  />
                </View>
                <View
                  style={{
                    position: 'absolute',
                    bottom: 15,
                  }}
                >
                  <LargeButton
                    label="Confirm"
                    onClick={formContext.handleSubmit}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
              </View>
            )}
          </Form>
        </View>
      )}
      {...props}
    />
  );
};
export default IssueToCompanyModal;
