export const EditRole = ({ navigation }) => ({
  query: {
    model: 'roles',
    id: 'masterDataListForTable',
    relationValue: {
      model: 'roles',
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: 'roles',
});
