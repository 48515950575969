import React, { Component } from 'react';
import { ToolBar as ReactToolBar } from '@applane/react-toolbar-v1';
import { Action } from '../action/Action';
import { getResolvedMQProps } from '../../BreakPoints';

export class ToolBar extends Component {
  render() {
    const {
      navigation,
      screenState,
      setTabsState,
      data,
      aggregates,
      fetchUriEvent,
      actionProps,
      setScreenState,
    } = this.props;

    const activeMQ = navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    let {
      leftAction,
      leftActions,
      actions,
      selectableActions,
      selectableLeftActions,
      selectableLeftAction,
      ...restProps
    } = getResolvedMQProps(this.props, { activeMQ });

    let extraProps = { leftAction, leftActions, actions };
    if (screenState && setScreenState) {
      const selectionCount = screenState
        && screenState.selectedIds
        && screenState.selectedIds.length;
      if (selectionCount) {
        leftActions = selectableLeftActions || leftActions;
        actions = selectableActions || actions;
        leftAction = selectableLeftAction || selectableLeftAction;
      }
      extraProps = {
        ...extraProps,
        selectionCount,
        state: screenState,
        setState: setScreenState,
        leftActions,
        leftAction,
        actions,
        actionProps: {
          data,
          fetchUriEvent,
          setTabsState,
          aggregates,
          ...actionProps,
        },
      };
    }
    return <ReactToolBar Action={Action} {...restProps} {...extraProps} />;
  }
}

export default ToolBar;
