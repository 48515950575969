/* eslint-disable no-shadow */
import React from 'react';

import {
  save,
  StandardButton,
  FormHoc,
  cancelButton,
  close,
  FilterGroup,
  View,
} from '../../../app-components';

import { submit } from '../../../AppServices';
import { SavingHeadList, TaxSlabsList } from './lists';
import { CommonTaxDetailsUri } from './uri';
import { FormLayout, Layout } from './layouts';
import { MODELS, ROUTES } from '../../../Lib/constants';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../images';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import { Form } from '../../../app-components/form/Form';
import { countUri } from '../../../Queries/countUri';
import ActivityLogsTabList from '../../../Wired/ActivityLogList';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';

export const TaxDetailsTabNavigator = (props) => (
  <TabNavigator
    {...props}
    actions={[]}
    tabs={{
      saving_head: {
        label: 'organisationTaxDetails.labels.saving_head'.getLabel(),
        screen: SavingHeadList,
        actions: [
          // {
          //   render: (props) => (
          //     <WithPermission
          //       access_key={permissions.mdm.views.addSalary.permissions.addSalary.value}
          //     >
          //       <StandardButton label="New Saving Head" {...props} />
          //     </WithPermission>
          //   ),
          //   type: 'link',
          //   link: () => ({
          //     view: [ROUTES.addSavingHead.name],
          //     modal: true,
          //     modalProps: {
          //       width: 800,
          //       height: 400,
          //     },
          //   }),
          // },
          <FtsSearch
            action={{
              exp: ['name'],
            }}
            key="fts"
          />,
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'name',
                label: 'Sections',
                model: MODELS.TaxDeclarationSections,
                id: 'taxDeclarationSectionSuggestions',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
                multiSelect: true,
              },
            ],
          }),
        ],
      },
      tax_slabs: {
        label: 'organisationTaxDetails.labels.tax_slabs'.getLabel(),
        screen: TaxSlabsList,
        actions: [
          {
            render: (props) => (
              <WithPermission
                access_key={permissions.mdm.views.addNewTaxSlab.permissions.addNewTaxSlab.value}
              >
                <StandardButton label="New Tax Slab" {...props} />
              </WithPermission>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: [ROUTES.addTaxSlab.name],
              item,
              modal: true,
              modalProps: {
                width: 800,
                height: 400,
              },
            }),
          },
        ],
      },
    }}
  />
);

export const SavingHeadDrill = FormHoc({
  onSubmit: submit({
    model: MODELS.SAVING_HEAD,
  }),
  closeView: 1,
  mandatory: {
    section: 1, name: 1, amount: 1, max_limit: 1, is_salary_paid_base: 1,
  },
  header: {
    title: 'organisationTaxDetails.title.edit_saving_head'.getLabel(),
    actions: [close],
    containerStyle: {
      paddingTop: 30,
      paddingLeft: 26,
      paddingRight: 26,
    },
  },
  footer: {
    actions: [cancelButton, save({ label: 'organisationTaxDetails.buttons.update'.getLabel() })],
  },
  uri: ({ navigation }) => CommonTaxDetailsUri(
    {
      query: 'savingHead',
      model: MODELS.SAVING_HEAD,
      addOnFilter: { _id: navigation?.state?.params?._id },
    },
    {},
  ),
  submitMessage: 'organisationTaxDetails.messages.update'.getLabel(),
  fieldVariant: 'filled',
  reloadEvent: 'SavingHeadList',
  formGroups: [
    {
      columnsPerRow: 2,
      groups: [
        {
          columns: [
            FormLayout.name,
            FormLayout.section,
            FormLayout.amount,
            FormLayout.max_limit,
            FormLayout.is_salary_paid_base,
            FormLayout.benefit_percent,
          ],
        },
      ],
    },
  ],
});

// edit tax drill
export const TaxSlabsDrill = FormHoc({
  onSubmit: submit({
    model: MODELS.TAX_SLABS,
  }),
  closeView: 1,
  mandatory: {
    finantial_year_id: 1, tax_percentage: 1, to_amount: 1, from_amount: 1, is_for_senior_citizen: 1,
  },
  header: {
    title: 'organisationTaxDetails.title.edit_tax_slab'.getLabel(),
    actions: [close],
    containerStyle: {
      paddingTop: 30,
      paddingLeft: 26,
      paddingRight: 26,
    },
  },
  footer: {
    actions: [cancelButton, save({ label: 'Update' })],
  },
  uri: ({ navigation }) => CommonTaxDetailsUri(
    {
      query: 'TaxSlabDetails',
      model: MODELS.TAX_SLABS,
      addOnFilter: { _id: navigation?.state?.params?._id },
    },
    {},
  ),
  submitMessage: 'organisationTaxDetails.messages.tax_slab_updated'.getLabel(),
  fieldVariant: 'filled',
  reloadEvent: 'TaxSlabsList',
  formGroups: [
    {
      columnsPerRow: 2,
      groups: [
        {
          columns: [
            Layout.financial_year,
            Layout.from_amount,
            Layout.tax_percentage,
            Layout.to_amount,
            Layout.is_for_senior_citizen,
          ],
        },
      ],
    },
  ],
});
const ViewActivityLogs = (props) => {
  const { navigation: { state: { params: { item = {} } = {} } = {} } = {} } = props;

  return (
    <TabNavigator
      {...props}
      tabs={{
        activityLog: {
          label: 'Activity Log',
          screen: ActivityLogsTabList,
          screenProps: {
            data: item,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: item?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: item?._id },
          }),
        },
      }}
    />
  );
};
export const ViewTaxSlabs = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Form
        {...props}
        closeView={1}
        editable={false}
        uri={({ navigation }) => CommonTaxDetailsUri(
          {
            query: 'TaxSlabDetails',
            model: MODELS.TAX_SLABS,
            addOnFilter: { _id: navigation?.state?.params?.item?._id },
          },
          {},
        )}
        submitMessage={'organisationTaxDetails.messages.tax_slab_updated'.getLabel()}
        fieldVariant="filled"
        reloadEvent="TaxSlabsList"
        formGroups={[
          {
            columnsPerRow: 2,
            groups: [
              {
                columns: [
                  Layout.financial_year,
                  Layout.from_amount,
                  Layout.tax_percentage,
                  Layout.to_amount,
                  Layout.is_for_senior_citizen,
                ],
              },
            ],
          },
        ]}
      />
    </View>
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <ViewActivityLogs {...props} />
    </View>
  </View>
);
export const AddNewSavingHead = FormHoc({
  onSubmit: submit({
    model: MODELS.SAVING_HEAD,
  }),
  mandatory: {
    section: 1, name: 1, amount: 1, max_limit: 1, is_salary_paid_base: 1,
  },
  defaultValues: () => ({
    is_salary_paid_base: false,
  }),
  closeView: 1,
  submitMessage: 'organisationTaxDetails.messages.add_saving_head'.getLabel(),
  fieldVariant: 'filled',
  reloadEvent: 'SavingHeadList',
  header: {
    title: 'organisationTaxDetails.title.add_saving_head'.getLabel(),
    actions: [close],
    containerStyle: {
      paddingTop: 30,
      paddingLeft: 26,
      paddingRight: 26,
    },
  },
  footer: {
    actions: [cancelButton, save],
  },
  formGroups: [
    {
      columnsPerRow: 2,
      groups: [
        {
          columns: [
            FormLayout.name,
            FormLayout.section,
            FormLayout.amount,
            FormLayout.max_limit,
            FormLayout.is_salary_paid_base,
            FormLayout.benefit_percent,
          ],
        },
      ],
    },
  ],
});
export const AddNewTaxSlab = FormHoc({
  onSubmit: submit({
    model: MODELS.TAX_SLABS,
  }),
  closeView: 1,
  mandatory: {
    finantial_year_id: 1, tax_percentage: 1, to_amount: 1, from_amount: 1, is_for_senior_citizen: 1,
  },
  defaultValues: () => ({
    is_for_senior_citizen: false,
  }),
  submitMessage: 'organisationTaxDetails.messages.add_tax_slab'.getLabel(),
  fieldVariant: 'filled',
  reloadEvent: 'TaxSlabsList',
  header: {
    title: 'organisationTaxDetails.title.add_tax_slab'.getLabel(),
    actions: [close],
    containerStyle: {
      paddingTop: 30,
      paddingLeft: 26,
      paddingRight: 26,
    },
  },
  footer: {
    actions: [cancelButton, save],
  },
  formGroups: [
    {
      columnsPerRow: 2,
      groups: [
        {
          columns: [
            Layout.financial_year,
            Layout.from_amount,
            Layout.tax_percentage,
            Layout.to_amount,
            Layout.is_for_senior_citizen,
          ],
        },
      ],
    },
  ],
});

