import React from 'react';
import { ToggleFilter } from '../../../../app-components';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import MasterList from '../../../../Wired/Master/MasterList';
import permissions from '../../../../Lib/permissions';

const tableColumns = [
  { width: 20 },
  {
    header: 'lab.labels.test'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'lab.labels.testID'.getLabel(),
    type: 'text',
    field: 'code',
  },
  {
    header: 'lab.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
  {
    header: 'lab.labels.labType'.getLabel(),
    type: 'text',
    value: ({ item: { labType } }) => labType?.map((item) => item.name).toString(),
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.LAB_TEST}
    editFormId={ROUTES.editTest.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyTestManagement.permissions.modifyTestManagement.value}
    header={{
      leftActions: [
        (actionProps) => (
          <ToggleFilter
            {...actionProps}
            model={MODELS.LAB_TEST}
            id="masterDataListForTable"
          />
        ),
      ],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewTest.name,
        params: { item },
      },
    })}
  />
);
