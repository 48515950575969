import React from 'react';

import { save, Text, View } from '../../../../app-components';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import vars from '../../../../theme/vars';
import { autoSuggestInput } from '../../../../autoSuggestions';

const ViewGradePay = (props) => (
  <Form
    {...props}
    reloadEvent={`reload${MODELS.GRADE_PAY_LEVEL}`}
    uri={() => ({
      query: {
        id: 'gradePay',
        addOnFilter: {
          _id: props?.navigation?.state?.params?.addOnFilter?._id,
        },
      },
      model: MODELS.GRADE_PAY_LEVEL,
    })}
    type="standard"
    lg={{
      verticalFormGroups: [
        {

          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      label: 'Name',
                      field: 'name',
                      variant: 'filled',
                      editable: false,
                    },
                    {
                      type: 'number',
                      label: 'Grade Pay',
                      field: 'gradePay',
                      editable: false,
                      variant: 'filled',
                    },

                  ],
                },

              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);

export default ViewGradePay;
