import React from 'react';

import { Text } from '../../../../app-components';
import {
  autoSuggestInput,
  multiAutoSuggestInput,
  searchInput,
} from '../../../../autoSuggestions';
import { getString, textColorForRequestStatus, timeConversion } from '../../../../Lib/helpers';
import { ViewIssueBloodUpdationRequest } from '../../../../Queries/bloodBank';
import vars from '../../../../theme/vars';
import FileLink from '../../../../Components/FileLink';
import { MODELS } from '../../../../Lib/constants';
import { Form } from '../../../../app-components/form/Form';

const ViewBloodUpdationRequest = (props) => (
  <Form
    {...props}
    type="standard"
    uri={ViewIssueBloodUpdationRequest}
    reloadEvent={`reload${MODELS.BLOOD_ISSUE_UPDATION_REQUEST}`}
    editable={false}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          label: 'Patient Details',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 4,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  label: 'bloodBankUpdationRequest.labels.dateOfRequest'.getLabel(),
                  type: 'date',
                  field: 'issueDate',
                },
                searchInput({
                  label: 'bloodBankUpdationRequest.labels.uhid'.getLabel(),
                  field: 'patient.uhid',
                }),
                {
                  label: 'bloodBankUpdationRequest.labels.status'.getLabel(),
                  type: 'text',
                  render: ({ data }) => textColorForRequestStatus(data),
                },
                searchInput({
                  label: 'bloodBankUpdationRequest.labels.patientName'.getLabel(),
                  field: 'patient.patientName',
                }),
                {
                  type: 'text',
                  label: 'patient.labels.relation'.getLabel(),
                  render: ({ data }) => (
                    <Text style={vars.headings.h8}>
                      {getString([
                        data?.patient?.relation,
                        data?.patient?.relationName,
                      ])}
                    </Text>
                  ),
                  editable: false,
                },
                {
                  type: 'number',
                  render: ({ data }) => (
                    <Text style={vars.headings.h8}>
                      {`${data?.patient?.age ?? ' '} ${data?.patient?.ageType ?? ' '}`}
                    </Text>
                  ),
                  label: 'bloodBankUpdationRequest.labels.age'.getLabel(),
                },
                autoSuggestInput({
                  label: 'bloodBankUpdationRequest.labels.gender'.getLabel(),
                  field: 'patient.gender',
                }),
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'bloodBankUpdationRequest.labels.reason'.getLabel(),
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          columnsPerRow: 4,
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'department.name',
                  label: 'bloodBankUpdationRequest.labels.department'.getLabel(),
                }),
                multiAutoSuggestInput({
                  field: 'speciality.name',
                  label: 'bloodBankUpdationRequest.labels.speciality'.getLabel(),
                }),
                searchInput({
                  label: 'bloodBankUpdationRequest.labels.doctorName'.getLabel(),
                  field: 'doctorName.firstName',
                }),
                {
                  type: 'file',
                  field: 'attachment',
                  label: 'bloodBankUpdationRequest.labels.attachments'.getLabel(),
                  container: 'topLabel',
                  render: (item) => {
                    const value = item?.data?.attachment;
                    if (Array.isArray(value)) {
                      return value.map((file, index) => <FileLink file={file} key={index} />);
                    }
                    return '--';
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          columnsPerRow: 4,
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  field: 'issueDate',
                  label: 'bloodBankUpdationRequest.labels.dateOfIssue'.getLabel(),
                },
                {
                  type: 'date',
                  field: 'newDateOfIssue',
                  label: 'bloodBankUpdationRequest.labels.newIssueDate'.getLabel(),
                },
                {
                  type: 'text',
                  render: ({ data } = {}) => (
                    <Text style={{ ...vars.headings.h8 }}>
                      {timeConversion(data?.time)}
                    </Text>
                  ),
                  label: 'bloodBankUpdationRequest.labels.issueTime'.getLabel(),
                },
                {
                  type: 'text',
                  render: ({ data } = {}) => (
                    <Text style={{ ...vars.headings.h8 }}>
                      {timeConversion(data?.newTimeOfIssue)}
                    </Text>
                  ),
                  label: 'bloodBankUpdationRequest.labels.newIssueTime'.getLabel(),
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          columnsPerRow: 2,
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  render: ({ data } = {}) => (
                    <Text style={vars.headings.h8}>
                      {getString([
                        `${data?.requestedBy?.name} (${data?.requestedBy?.employee_code})`,
                      ])}
                    </Text>
                  ),
                  variant: 'filled',
                  label: 'bloodBankUpdationRequest.labels.requestedBy'.getLabel(),
                  editable: false,
                },
                {
                  type: 'text',
                  field: 'reasonForDateChange',
                  variant: 'filled',
                  label: 'bloodBankUpdationRequest.labels.reasonForUpdation'.getLabel(),
                  editable: false,
                  style: { flex: 3 },
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default ViewBloodUpdationRequest;
