import React from 'react';

import {
  AssetDepartmentInventoryToggleFilter, Text, Toast,
} from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import { getUser } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { AssetDepartmentInventoryItemListUri } from '../../../../Queries/asset';
import vars from '../../../../theme/vars';

const header = {
  leftActions: [(props) => {
    const user = getUser();
    const { employee: { department } = {} } = user;
    return (
      <AssetDepartmentInventoryToggleFilter
        model={MODELS.ASSET_DEPARTMENT_STOCKS}
        id="assetDepartmentInventoryStocksDataList"
        addOnFilter={{ department: { _id: { $in: department?.map(({ _id }) => _id) } } }}
        {...props}
      />
    );
  }],

};
const tableItems = {
  lg: {
    header,
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'asset.headers.assetName'.getLabel(),
        type: 'text',
        field: 'item.name',
        sortable: 'name',
      },
      {
        header: 'asset.headers.category'.getLabel(),
        type: 'text',
        value: ({ item: { item = {} } = {} }) => item?.category?.name,
      },
      {
        header: 'asset.headers.subCategory'.getLabel(),
        type: 'text',
        value: ({ item: { item = {} } = {} }) => item?.subCategory?.name,
      },
      {
        header: 'asset.headers.stockAvailable'.getLabel(),
        type: 'text',
        field: 'stockAvailable',
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { stockStatus } = {} }) => {
          let statusColor = vars.colors.warning;
          if (stockStatus === 'In-Stocks') {
            statusColor = vars.colors.primary.color2;
          } else if (stockStatus === 'Out-of-Stock') {
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h8, color: statusColor }}>
              {stockStatus}
            </Text>
          );
        },
      },
    ],
  },
};
const InventoryList = (props) => {
  const user = getUser();
  const { employee: { department } = {} } = user;
  if (department && department.length && department[0]?._id) {
    return (
      <Table
        reloadEvent={`reload${MODELS.ASSET_DEPARTMENT_STOCKS}`}
      // eslint-disable-next-line max-len
        uri={AssetDepartmentInventoryItemListUri({ filter: { department: { _id: { $in: department?.map(({ _id }) => _id) } } } })}
        {...tableItems}
        action={({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.assetViewDepartmentInventory.name,
            params: { item },
          },
        })}
        {...props}
      />
    );
  }
  Toast.show({
    message: 'Error',
    description: 'asset.messages.loginUserError'.getLabel(),
    type: 'error',
    position: 'top',
    direction: 'right',
    duration: 3000,
  });
  return null;
};

export default InventoryList;
