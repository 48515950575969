import React from 'react';

import { DateFilter } from '../../../../app-components';
import { MODELS } from '../../../../Lib/constants';
import childtabTheme from '../../../../theme/childTabTheme';
import EmployeeScheduleList from '../../../../Wired/EmployeeScheduleList/employeeScheduleList';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import vars from '../../../../theme/vars';

const Schedule = (props) => {
  const { navigation: { state: { params: { item } } } } = props;

  return (
    <TabNavigator
      theme={{ ...childtabTheme }}
      tabs={{
        Schedule: {
          label: 'Schedule',
          actions: [<DateFilter
            field="fromDate"
            key="date"
            containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
          />],
          screen: EmployeeScheduleList,
          screenProps: {
            model: MODELS.EMPLOYEE_SCHEDULE,
            id: 'schedules',
            data: item,
            addOnFilter: {
              employee: { _id: item?._id },
            },
          },
        },
      }}
      {...props}
    />
  );
};
export default Schedule;
