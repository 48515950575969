import React from 'react';
import { View, Text } from '@applane/react-core-components';
import { Avatar } from '@applane/react-avatar';
import { resolveExp } from '@applane/react-utility-functions';
import { Radio } from '@applane/react-radio-button-v1';
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { Headline11, Headline9, Headline14 } from '../../theme/Headlines';
import { radioTheme } from '../../theme/radioTheme';
import typeCast from './TypeCast';
import vars from '../../theme/vars';

const { headings, colors } = vars;

const {
  neutral_1,
  neutral_4,
  neutral_2,
  surface_white,
  primary_2,
} = manazeV6Colors;

const WithLabel = (Component) => {
  class Label extends React.Component {
    render() {
      let {
        label,
        skipLabel,
        labelContainerStyle,
        labelTextStyle,
        ...restProps
      } = this.props;
      let renderComponent = <Component {...restProps} />;
      if (skipLabel || label === false) {
        return renderComponent;
      }
      labelContainerStyle = {
        height: 14,
        marginBottom: 8,
        ...labelContainerStyle,
      };

      labelTextStyle = {
        ...Headline11,
        // color: neutral_4,
        numberOfLines: 1,
        ...labelTextStyle,
        ...headings.h13,
        color: colors.grey.color3,
      };
      renderComponent = (
        <>
          <View style={labelContainerStyle}>
            <Text style={labelTextStyle}>{label || ''}</Text>
          </View>
          {renderComponent}
        </>
      );
      return renderComponent;
    }
  }
  return Label;
};

const AvatarRender = (props) => {
  let {
    size = 32,
    avatarIcon,
    item,
    value,
    resolveIcon,
    avatarTextStyle,
  } = props;
  avatarTextStyle = {
    ...Headline9,
    color: primary_2,
    ...avatarTextStyle,
  };
  return (
    <Avatar
      size={size}
      icon={avatarIcon ? resolveExp(item, avatarIcon) : void 0}
      value={value}
      resolveIcon={resolveIcon}
      textStyle={avatarTextStyle}
    />
  );
};

let RenderComponent = (props) => {
  let {
    value = '--',
    render,
    defaultValue = '',
    color,
    numberOfLines,
    prefix,
    html,
    suffix,
    prefixStyle,
    suffixStyle,
    valueContainerStyle,
    valueTextStyle,
    type,
  } = props;
  valueContainerStyle = {
    minHeight: 14,
    ...valueContainerStyle,
  };
  valueTextStyle = {
    // ...Headline9,
    // color: neutral_2,
    // ...valueTextStyle,
    ...headings.h8,
    color: colors.grey.color1,
  };
  let valueComponent = void 0;
  if (render) {
    valueComponent = render(props);
  } else if (value !== undefined && value !== null) {
    if (React.isValidElement(value)) {
      valueComponent = value;
    } else {
      if (color) {
        if (typeof color === 'function') {
          color = color({ value });
        }
        if (color !== undefined) {
          valueTextStyle.color = color;
        }
      }
      if (numberOfLines) {
        valueTextStyle.numberOfLines = numberOfLines;
      }
      if (type && typeCast && typeCast[type]) {
        value = typeCast[type](props);
      }
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          value = value.join(';');
        } else if (value && typeof value === 'object') {
          value = JSON.stringify(value);
        } else if (typeof value !== 'string') {
          value = value.toString ? value.toString() : JSON.stringify(value);
        }
        let prefixComponent = void 0;
        let suffixComponent = void 0;
        if (prefix) {
          if (prefixStyle) {
            prefixComponent = <Text style={prefixStyle}>{prefix}</Text>;
          } else {
            value = prefix + value;
          }
        }
        if (suffix) {
          if (suffixStyle) {
            suffixComponent = <Text style={suffixStyle}>{suffix}</Text>;
          } else {
            value += suffix;
          }
        }

        if (html) {
          valueComponent = (
            <Text
              style={valueTextStyle}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          );
        } else {
          valueComponent = <Text style={valueTextStyle}>{value}</Text>;
        }
        if (prefixComponent || suffixComponent) {
          valueComponent = (
            <View style={{ flexDirection: 'row', flex: 1, overflow: 'hidden' }}>
              {prefixComponent}
              {valueComponent}
              {suffixComponent}
            </View>
          );
        }
      }
    }
  }
  if (!valueComponent && defaultValue) {
    valueComponent = <Text style={valueTextStyle}>{defaultValue}</Text>;
  }
  return <View style={valueContainerStyle}>{valueComponent}</View>;
};

RenderComponent = WithLabel(RenderComponent);

export const TextRender = RenderComponent;

export const TextAreaRender = (props) => <RenderComponent {...props} html />;

export const AutoSuggestAvatarRender = (props) => {
  let {
    value, valueField, textStyle, ...restProps
  } = props;
  textStyle = {
    ...Headline11,
    color: neutral_1,
    paddingLeft: 8,
    paddingRight: 8,
    ...textStyle,
  };
  if (value && !Array.isArray(value)) {
    value = [value];
  }
  const valueComponent = (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {value
        ? value.map((item) => {
          if (!item) {
            return null;
          }
          let displayValue = valueField ? resolveExp(item, valueField) : item;
          if (displayValue && typeof displayValue === 'object') {
            displayValue = JSON.stringify(displayValue);
          }
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <AvatarRender {...props} value={displayValue} item={item} />
              <Text style={textStyle}>{displayValue}</Text>
            </View>
          );
        })
        : void 0}
    </View>
  );
  return <RenderComponent {...restProps} value={valueComponent} />;
};

export const MultiAutoSuggestRender = (props) => {
  let {
    value,
    valueField,
    showAvatar,
    chipsContainerStyle,
    chipsTextStyle,
    ...restProps
  } = props;
  chipsContainerStyle = {
    borderWidth: 1,
    borderColor: '#E1E1E1',
    marginRight: 8,
    borderRadius: 24,
    backgroundColor: '#ffffff',
    ...chipsContainerStyle,
  };
  chipsTextStyle = {
    ...Headline11,
    color: neutral_1,
    paddingTop: 9,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 9,
    ...chipsTextStyle,
  };
  if (value && !Array.isArray(value)) {
    value = [value];
  }
  const valueComponent = (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {value
        ? value.map((item) => {
          if (!item) {
            return null;
          }
          let displayValue = valueField ? resolveExp(item, valueField) : item;
          if (displayValue && typeof displayValue === 'object') {
            displayValue = JSON.stringify(displayValue);
          }
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                ...chipsContainerStyle,
              }}
            >
              {showAvatar && (
              <AvatarRender {...props} value={displayValue} item={item} />
              )}
              <Text style={chipsTextStyle}>{displayValue}</Text>
            </View>
          );
        })
        : void 0}
    </View>
  );
  return <RenderComponent {...restProps} value={valueComponent} />;
};

export const MultiAutoSuggestAvatarRender = (props) => <MultiAutoSuggestRender {...props} showAvatar />;

export const RadioRender = (props) => <Radio {...radioTheme} {...props} />;

export const FilledAutoSuggestAvatarRender = (props) => {
  const { label, value, valueField } = props;
  let displayValue = value;
  if (value && valueField) {
    if (Array.isArray(value)) {
      displayValue = value.map((item) => (item && item[valueField]) || '');
      displayValue = displayValue.join(';');
    } else if (typeof value === 'object') {
      displayValue = value[valueField];
    }
  }
  return (
    <View
      style={{
        backgroundColor: surface_white,
        height: 48,
        borderRadius: 4,
        flexDirection: 'row',
        padding: 12,
      }}
    >
      <AvatarRender
        size={24}
        {...props}
        value={displayValue}
        item={value}
        avatarTextStyle={{ ...Headline14 }}
      />
      <View style={{ paddingLeft: 12 }}>
        <View style={{ height: 14 }}>
          <Text style={{ ...Headline11, color: neutral_2 }}>{displayValue}</Text>
        </View>
        <View style={{ paddingTop: 2 }}>
          <Text style={{ ...Headline14, color: neutral_4 }}>{label}</Text>
        </View>
      </View>
    </View>
  );
};
