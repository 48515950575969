import React from 'react';
import { FilterGroup } from '../../../../../app-components';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { filterActive, filterInactive } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import tabTheme from '../../../../../theme/childTabTheme';
import GeneratedSalaryList from '../../../../../Wired/GenerateSalary';
import { EmployeePackage } from '../../../../EmployeeManagement/EditEmployee/Salary/list';
import EmployeeForms from './TaxDeclarations/EmployeeForm';

import { UserTaxDeclarationList } from './TaxDeclarations/payroll/list';

const SalaryTabNavigation = (props) => {
  const {
    employee,
    canAddSalaryStructure,
  } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        GeneratedSalary: {
          label: 'Generated Salary',
          screen: GeneratedSalaryList,
          screenProps: {
            addOnFilter: {
              employee: { _id: employee?._id },
            },
          },
          actions: [
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'month_id',
                  label: 'Month',
                  model: MODELS.MONTHS,
                  id: 'MonthTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Months',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: '_id',
                },
                {
                  type: 'autoSuggest',
                  field: 'year_id',
                  label: 'Year',
                  model: MODELS.years,
                  id: 'YearTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Year',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: '_id',
                },
              ],
            }),
          ],
        },
        CompensationStructure: {
          label: 'Compensation Structure',
          screen: EmployeePackage,
          screenProps: {
            addOnFilter: {
              employee_id: { _id: employee?._id },
            },
            canAddSalaryStructure,
          },
        },
        TaxDeclarations: {
          label: 'Tax Declarations',
          screen: UserTaxDeclarationList,
          screenProps: {
            addOnFilter: {
              employee_id: { _id: employee?._id },
            },
            addDeclaredAmount: true,
            addActualAmount: false,
          },
          actions: [
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'finantial_year_id',
                  label: 'Financial Year',
                  model: MODELS.FINANCIAL_YEAR,
                  id: 'financialyears',
                  searchField: 'name',
                  placeholder: 'Year',
                  sugestionField: 'financial_year',
                  primaryField: 'financial_year',
                  valueField: 'financial_year',
                },
              ],
            }),
          ],
        },
        EmployeeForm: {
          label: 'Employee Form',
          screen: EmployeeForms,
          screenProps: {
            addOnFilter: {
              employee: { _id: employee?._id },
            },
            canAddSalaryStructure,
          },
        },
      }}
    />
  );
};

export default SalaryTabNavigation;
