import React from 'react';

import ActivityLogsTabList from '../../../../../../../Wired/ActivityLogList';
import {
  OutlineButton,
} from '../../../../../../../app-components';
import { countUri } from '../../../../../../../Queries/countUri';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import SubCategoryList from '../../../../SubCategory/SubCategoryList';
import { TabNavigator } from '../../../../../../../app-components/tab/TabNavigator';

const ViewAssetCategoryTabs = (props) => {
  const { navigation, showAddButton = true } = props;
  const { state: { params: { item: data = {} } = {} } = {} } = navigation;
  if (!data?._id) {
    return null;
  }

  return (
    <TabNavigator
      {...props}
      tabs={{
        SubCategory: {
          label: 'Sub-Category',
          screen: SubCategoryList,
          screenProps: {
            data,
            model: MODELS.PHARMACY_SUBCATEGORY,
            id: 'masterDataListForTable',
            addOnFilter: {
              category: { _id: data?._id },
            },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_SUBCATEGORY,
            id: 'masterDataListForTable',
            filter: {
              category: { _id: data?._id },
            },
          }),
          actions: [
            {
              type: 'link',
              render: () => (showAddButton ? (
                <OutlineButton
                  label={'asset.buttons.createSubCategory'.getLabel()}
                />
              ) : null),
              link: () => {
                const {
                  navigation: { state: { params: { item } = {} } = {} } = {},
                } = props;
                return {
                  view: ROUTES.addAssetSubCategory.name,
                  modal: true,
                  params: {
                    category: {
                      _id: item?._id,
                    },
                  },
                  modalProps: {
                    autoHide: true,
                    width: 404,
                    height: 496,
                  },
                };
              },
            },
          ],
        },
        ActivityLogs: {
          label: 'Activity Logs',
          screen: ActivityLogsTabList,
          screenProps: {
            data,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: data?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: data?._id },
          }),
        },
      }}
    />
  );
};
export default ViewAssetCategoryTabs;
