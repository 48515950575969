/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { ActivityIndicator, Text, Toast } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { getIsLowShelfLife } from '../../../../../Lib/helpers';
import { fetch, submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { currencyInput } from '../../../../../compositeInputs';

const AddStock = (props) => {
  const { navigation, eventDispatcher } = props;
  const {
    navigation: {
      state: {
        params: {
          item,
          purchaseRequestEntityId,
          defaultValues = {},
        },
      },
    },
  } = props;

  const [supplier, setSupplier] = useState();
  const [loading, setLoading] = useState(true);
  const [manufacturer, setManufacturer] = useState();

  const fetchSupplierAndManufacturerDetails = useCallback(async () => {
    const fetchedSupplierAndManufacturerDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'purchaseSupplyOrderDetails',
            addOnFilter: {
              purchaseRequestEntity: { _id: purchaseRequestEntityId },
              type: 'PO',
            },
          },
          model: MODELS.PURCHASE_SUPPLY_ORDER,
          limit: 1,
        },
      },
    });
    setSupplier(fetchedSupplierAndManufacturerDetails?.data[0]?.supplier);
    setManufacturer(fetchedSupplierAndManufacturerDetails?.data[0]?.manufacturer);
    setLoading(false);
    return fetchedSupplierAndManufacturerDetails;
  }, [purchaseRequestEntityId]);

  useEffect(() => {
    fetchSupplierAndManufacturerDetails();
  }, [fetchSupplierAndManufacturerDetails]);

  const getLowShelfLife = useCallback(async ({ data, updates }) => {
    const getLowShelfLifeStatus = getIsLowShelfLife(data.manufacturingDate, data.expiryDate);
    if (getLowShelfLifeStatus) {
      navigation.push({
        view: ROUTES.lowShelfLifeModal.name,
        modal: true,
        params: { data },
        modalProps: {
          autoHide: true,
          height: 253,
        },
      });
    } else {
      const submitAPI = submit({
        model: MODELS.PHARMACY_BATCH,
        ...props,
        data,
      });
      await submitAPI({ data, updates });
      Toast.show({
        message: 'Success',
        description: 'inventory.messages.batchAddedSuccessfully'.getLabel(),
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      eventDispatcher.notify(`reload${MODELS.PURCHASE_REQUEST}`);
      navigation.pop(2);
      return {};
    }
  }, [eventDispatcher, navigation, props]);

  return (
    !loading ? (
      <Form
        {...props}
        type="standardShadow"
        onSubmit={getLowShelfLife}
        reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
        defaultValues={() => ({
          purchaseRequestEntity: { _id: purchaseRequestEntityId },
          item,
          supplier,
          manufacturer,
          currency: 'INR',
          ...defaultValues,
        })}
        mandatory={{
          item: 1,
          batchNumber: 1,
          // supplier: 1,
          // manufacturer: 1,
          manufacturingDate: 1,
          expiryDate: 1,
          stockEntryDate: 1,
          supplyReceivedOn: 1,
          quantityReceived: 1,
          billAmount: 1,
          billNumber: 1,
        }}
        validations={{
          expiryDate: ({ data }) => {
            if (data && data?.expiryDate) {
              const days = moment(data?.expiryDate).diff(new Date(), 'days');
              if (days < 0) return 'Expiry date should be greater than current date';
            }
            return '';
          },
          manufacturingDate: ({ data }) => {
            if (data && data?.manufacturingDate) {
              const days = moment(data?.manufacturingDate).diff(new Date(), 'days');
              if (days >= 0) return 'Manufacturing date should be less than current date';
            }
            return '';
          },
          stockEntryDate: ({ data }) => {
            if (data && data?.manufacturingDate && data?.stockEntryDate) {
              const days = moment(data?.manufacturingDate).diff(data?.stockEntryDate, 'days');
              if (days >= 0) return 'Stock Entry date should be greater than Manufacturing date';
            } else if (data && data?.stockEntryDate) {
              const days = new Date(data?.stockEntryDate) > new Date();
              if (days) return 'Stock Entry date can not be greater than current date';
            }
            return '';
          },
          supplyReceivedOn: ({ data }) => {
            if (data && data?.supplyReceivedOn && data?.stockEntryDate) {
              const days = new Date(data?.supplyReceivedOn) > new Date(data?.stockEntryDate);
              if (days) return 'Stock Received On date can not  be greater than Stock Entry date';
            }
            return '';
          },
        }}
        lg={{
          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              label: 'inventory.title.itemDetails'.getLabel(),
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    searchInput({
                      field: 'item',
                      label: 'inventory.labels.itemName'.getLabel(),
                      placeholder: 'inventory.placeholders.selectItemName'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.PHARMACY_ITEM,
                      query: 'masterDataList',
                      mandatory: true,
                      disabled: true,
                    }),
                    searchInput({
                      field: 'item.category',
                      label: 'inventory.labels.category'.getLabel(),
                      placeholder: 'inventory.placeholders.category'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.PHARMACY_CATEGORY,
                      query: 'masterDataList',
                      disabled: true,
                    }),
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'item.subCategory',
                      label: 'inventory.labels.subCategory'.getLabel(),
                      placeholder: 'inventory.placeholders.subCategory'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.PHARMACY_SUBCATEGORY,
                      query: 'masterDataList',
                      disabled: true,
                    }),
                    {
                      type: 'number',
                      field: 'item.reorderLevel',
                      label: 'inventory.labels.reorderLevel'.getLabel(),
                      placeholder: 'inventory.placeholders.reorderLevel'.getLabel(),
                      disabled: true,
                    },
                  ],
                },
                {
                  columnsPerRow: 3,
                  columns: [
                    {
                      ...currencyInput({
                        variant: 'filled',
                        amount: {
                          field: 'item.sellingPrice',
                          label: 'inventory.labels.sellingPrice'.getLabel(),
                        },
                        currency: {
                          field: 'item.currency',
                        },
                        disabled: true,
                      }),
                    },
                    {
                      type: 'toggleSwitch',
                      field: 'item.isActive',
                      label: 'inventory.labels.status'.getLabel(),
                      container: 'leftLabel',
                    },
                    {
                      type: 'textArea',
                      field: 'item.description',
                      label: 'inventory.labels.description'.getLabel(),
                      placeholder: 'inventory.placeholders.description'.getLabel(),
                      minHeight: 80,
                      disabled: true,
                      style: { flex: 2, overflow: 'hidden' },
                    },
                  ],
                },
              ],
            },
            {
              fieldVariant: 'filled',
              label: 'inventory.title.batchInformation'.getLabel(),
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 4,
                  columns: [
                    {
                      type: 'text',
                      field: 'batchNumber',
                      variant: 'filled',
                      label: 'inventory.labels.batchNumber'.getLabel(),
                      placeholder: 'inventory.placeholders.batchNumber'.getLabel(),
                      mandatory: true,
                    },
                    searchInput({
                      field: 'supplier',
                      variant: 'filled',
                      label: 'inventory.labels.supplier'.getLabel(),
                      placeholder: 'inventory.placeholders.supplier'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.PHARMACY_SUPPLIER,
                      query: 'masterDataList',
                      mandatory: true,
                      disabled: true,
                    }),
                    searchInput(
                      {
                        field: 'manufacturer',
                        variant: 'filled',
                        label: 'inventory.labels.manufacturer'.getLabel(),
                        placeholder: 'inventory.placeholders.manufacturer'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.PHARMACY_MANUFACTURER,
                        query: 'masterDataList',
                        mandatory: true,
                        disabled: true,
                      },
                      {
                        addOnFilter: ({
                          data: {
                            supplier: { _id },
                          },
                        }) => ({ supplier: { _id } }),
                      },
                    ),
                    {
                      type: 'date',
                      field: 'manufacturingDate',
                      variant: 'filled',
                      label: 'inventory.labels.manufacturingDate'.getLabel(),
                      placeholder: 'inventory.placeholders.manufacturingDate'.getLabel(),
                      mandatory: true,
                    },

                    {
                      type: 'date',
                      field: 'expiryDate',
                      variant: 'filled',
                      label: 'inventory.labels.expiryDate'.getLabel(),
                      placeholder: 'inventory.placeholders.expiryDate'.getLabel(),
                      mandatory: true,

                    },
                    {
                      type: 'date',
                      field: 'stockEntryDate',
                      variant: 'filled',
                      label: 'inventory.labels.stockEntryDate'.getLabel(),
                      placeholder: 'inventory.placeholders.stockEntryDate'.getLabel(),
                      mandatory: true,

                    },
                    {
                      type: 'date',
                      field: 'supplyReceivedOn',
                      variant: 'filled',
                      label: 'inventory.labels.supplyReceivedOn'.getLabel(),
                      placeholder: 'inventory.placeholders.supplyReceivedOn'.getLabel(),
                      mandatory: true,
                    },
                    {
                      type: 'text',
                      variant: 'filled',
                      label: 'inventory.labels.remainingSelfLife'.getLabel(),
                      placeholder: 'inventory.placeholders.remainingSelfLife'.getLabel(),
                      render: ({ data }) => {
                        if (data?.expiryDate) {
                          const days = moment(data?.expiryDate).diff(new Date(), 'days');
                          return (
                            <Text style={{ ...vars.headings.h9 }}>
                              {days}
                              {' days'}
                            </Text>
                          );
                        }
                      },
                      editable: false,
                    },
                    {
                      ...currencyInput({
                        variant: 'filled',
                        amount: {
                          field: 'purchasedPrice',
                          label: 'inventory.labels.purchasedPrice'.getLabel(),
                        },
                        currency: {
                          field: 'currency',
                        },
                      }),
                    },
                    {
                      type: 'number',
                      field: 'item.stockAvailable.stockAvailable',
                      variant: 'filled',
                      label: 'inventory.labels.stockAvailable'.getLabel(),
                      disabled: true,
                    },
                    {
                      type: 'number',
                      field: 'quantityReceived',
                      variant: 'filled',
                      label: 'inventory.labels.quantityReceived'.getLabel(),
                      mandatory: true,
                    },
                    {
                      type: 'text',
                      field: 'billNumber',
                      variant: 'filled',
                      label: 'inventory.labels.billNumber'.getLabel(),
                      mandatory: true,
                    },
                    {
                      type: 'number',
                      field: 'billAmount',
                      variant: 'filled',
                      label: 'inventory.labels.billAmount'.getLabel(),
                      mandatory: true,
                    },
                    {
                      type: 'file',
                      field: 'bill',
                      variant: 'filled',
                      label: 'inventory.labels.billUpload'.getLabel(),
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    ) : <ActivityIndicator />
  );
};
export default AddStock;
