import React from 'react';
import { MODELS, ROUTES } from '../../Lib/constants';
import ViewAccountTabs from './Tabs';
import DownloadSalarySheetModal from '../../Wired/DownloadSalarySheetModal';
import GenerateAllSalaryModal from '../../Wired/DownloadAllSalarySheetModal';
import RejectRequestSavingHead from './RejectRequestSavingHead';
import ApproveRequestConfirmationModal from './ApproveRequestConfirmationModal';
import RejectRequestConfirmationModal from './RejectRequestConfirmationModal';
import FilesAndBillsScreens from './FilesAndBills';
import EmployeeForm from './EmployeeForm';
import UploadBulkForm from './EmployeeForm/UploadBulkForm';
import HeaderTitleWithCount from '../../Components/HeaderTitileWithCount';
import PaymentDoneModal from './Tabs/ChildTabs/Salary/paymentDone';
import GenerateArrearsModal from './Tabs/ChildTabs/Salary/generateArrears';
import AttendanceFlowScreens from './AttendanceFlow/index';
import SalaryTabNavigation from './Salary';
import RequestsTabNavigation from './Requests';
import Payment from './Payment';
import { FilterGroup, OutlineButton, StandardButton } from '../../app-components';
import { filterActive, filterInactive } from '../../images';
import { searchFilter } from '../../autoSuggestions';
import GenerateSalaryActionModal from './Tabs/ChildTabs/Salary/generateSalaryAction';

export default {
  [ROUTES.accountsInfo.name]: { screen: ViewAccountTabs, expanded: true },
  [ROUTES.salarySheetModal.name]: {
    screen: DownloadSalarySheetModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 400,
      height: 560,
    },
  },
  [ROUTES.allSalarySheetModal.name]: {
    screen: GenerateAllSalaryModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 400,
      height: 500,
    },
  },
  [ROUTES.rejectRequestSavingHead.name]: {
    screen: RejectRequestSavingHead,
    modal: true,
    modalProps: {
      width: 400,
      height: 400,
    },
  },
  [ROUTES.approveRequestConfirmationModal.name]: {
    screen: ApproveRequestConfirmationModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 300,
    },
  },
  [ROUTES.employeeForm.name]: {
    screen: EmployeeForm,
    expanded: true,

  },

  [ROUTES.formBulkUpload.name]: {
    screen: UploadBulkForm,
    expanded: true,
    modal: true,
    modalProps: {
      width: 600,
    },
  },
  [ROUTES.rejectRequestConfirmationModal.name]: {
    screen: RejectRequestConfirmationModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 400,
    },
  },
  [ROUTES.salaryInfo.name]: {
    screen: SalaryTabNavigation,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Salary" />
      ),
    },
  },

  [ROUTES.paymentDone.name]: {
    screen: PaymentDoneModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 600,
      // height: 'aut',
    },
  },
  [ROUTES.generateSalaryAction.name]: {
    screen: GenerateSalaryActionModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 400,
      height: 'auto',
    },
  },
  [ROUTES.generateArrears.name]: {
    screen: GenerateArrearsModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 600,
      // height: 'aut',
    },
  },
  [ROUTES.AttendanceRequests.name]: {
    screen: RequestsTabNavigation,
    expanded: true,
  },
  [ROUTES.Payment.name]: {
    screen: Payment,
    expanded: true,
    headerActions: [
      FilterGroup({
        icon: filterInactive,
        activeIcon: filterActive,
        filters: [
          {
            type: 'autoSuggest',
            field: 'month',
            label: 'Month',
            model: MODELS.MONTHS,
            id: 'MonthTypeSuggestions',
            filterField: 'key',
            fieldType: 'text',
            // searchField: 'month',
            // placeholder: 'Months',
            // suggestionField: 'key',
            primaryField: 'name',
            idField: 'name',
          },
          {
            type: 'autoSuggest',
            field: 'year',
            label: 'Year',
            model: MODELS.years,
            id: 'YearTypeSuggestions',
            searchField: 'name',
            placeholder: 'Year',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
            keyField: 'name',
            filterField: 'name',
            fieldType: 'text',
          },
          searchFilter({
            field: 'employee',
            model: MODELS.EMPLOYEE,
            id: 'employeeLists',
            searchField: 'name',
            label: 'Employee',
            suggestionField: 'fullName',
            multiSelect: true,
          },
          {
            modifyResult: ({ data }) => {
              const clonedData = data.map((item) => ({
                ...item,
                fullName: `${item?.firstName} ${item?.lastName}`,
              }));

              return { data: clonedData };
            },
          }),
          {
            type: 'autoSuggest',
            field: 'employee.employeeType',
            label: 'Employee Type',
            model: MODELS.EMPLOYEETYPE,
            id: 'masterDataList',
            searchField: 'name',
            placeholder: 'Select Employee Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
          },
          {
            type: 'autoSuggest',
            field: 'employee.staffType',
            label: 'Staff Type',
            model: MODELS.STAFFTYPE,
            id: 'masterDataList',
            searchField: 'name',
            placeholder: 'Select Staff Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
          },
        ],
      }),
    ],
  },
  ...FilesAndBillsScreens,
  ...AttendanceFlowScreens,
};
