import React from 'react';
import { DateFilter } from '../../../../../app-components';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import vars from '../../../../../theme/vars';
import childTabTheme from '../CentralInventory/childTabTheme';
import IndentRequestList from './RaisedIndentList/IndentList';

const PharmacyDepartmentInventoryRequestsTabs = (props) => {
  const { indentRequestsQueryId } = props;
  return (
    <TabNavigator
      {...props}
      theme={childTabTheme}
      actions={[
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={
        {
          flexDirection: 'row',
          width: 303,
          height: 36,
          borderWidth: 1,
          padding: 9,
          marginTop: 7,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }
      }
        />,
      ]}
      tabs={{
        IndentRequests: {
          label: 'Indent Requests',
          screen: IndentRequestList,
          screenProps: {
            queryId: indentRequestsQueryId,
          },
        },
      }}
    />
  );
};
export default PharmacyDepartmentInventoryRequestsTabs;
