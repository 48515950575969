import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const AddHistoryIPDForm = (props) => (

  <ListActionModal
    title="History"
    description="Add History of the Patient."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Save',
            }),
          ],
        }}

        closeView={1}
        reloadEvent={`reload${MODELS.HISTORY}`}
        defaultValues={() => ({
          date: new Date(),
        })}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            admissionId: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.HISTORY,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}

        lg={{
          formGroups: [
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
                paddingTop: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      type: 'date',
                      editable: true,
                      label: 'Date',
                      field: 'date',
                      style: {
                        width: 200,
                      },
                    },
                    {
                      type: 'textArea',
                      allowWhiteSpaces: true,
                      editable: true,
                      label: 'Description',
                      field: 'description',
                      width: 500,
                      minHeight: 95,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default AddHistoryIPDForm;
