import {MODE} from './Config';
export const DASHBOARD_ANALYTICS = 'analytics';
export const CLINICS_BOARD = 'clinics';
export const PATIENT_BOARD = 'patients';
export const MASTER_DATA = 'master-data';
export const CONTACT_SUPPORT = 'contact-support';
export const MANAGE_INVITE_LINKS = 'manage-invite-links';
export const SETTING = 'clinic-detail';
export const MY_PATIENT = 'my-patient';
export const IMPORT = 'importList';
export const DOCTOR_PATIENT_QUEUE = 'doctor-patient-queue';
export const SCHEDULE = 'schedule';
export const PATIENT_WAITING_ROOM = 'patient-waiting-room';
export const DOCTOR_SEARCH = 'doctor-search';

export const USER_TYPES = {
  PATIENT: 'Patient',
  DOCTOR: 'Doctor',
  LIVE_SUPER_ADMIN: 'SuperAdmin',
  CLINICADMIN: 'ClinicAdmin',
};

export const getUserViewsMap = ({user}) => {
  let {resource} = user || {};
  if (user && user.userType === 'SuperAdmin') {
    resource = {
      analytics: true,
      clinics: true,
      patient: true,
      master_data: true,
      contact_support: true,
      import: true,
      // 'doctor-patient-queue': true,
    };
  }
  if (user && user.userType === 'ClinicAdmin') {
    resource = {
      analytics: true,
      manage_invite_links: true,
      patient: true,
      // contact_support: true,
      // manage_invite_links: true,
      'clinic-detail': true,
    };
  }
  if (user && user.userType === 'Doctor') {
    resource = {
      // contact_support: true,
      'doctor-patient-queue': true,
      // patient: true,
      analytics: true,
      my_patient: true,
      schedule: true,
    };
  }
  if (user && user.userType === 'Patient') {
    resource = {
      patient: true,
      patient_waiting_room: true,
      'doctor-search': true,
    };
  }
  if (user && user.userType === 'FrontDesk') {
    resource = {
      manage_invite_links: true,
    };
  }
  let views = {
    [DASHBOARD_ANALYTICS]: resource.analytics,
    [CLINICS_BOARD]: resource.clinics,
    [PATIENT_BOARD]: resource.patient,
    [MASTER_DATA]: resource.master_data,
    [CONTACT_SUPPORT]: resource.contact_support,
    [MANAGE_INVITE_LINKS]: resource.manage_invite_links,
    [SETTING]: resource['clinic-detail'],
    [MY_PATIENT]: resource.my_patient,
    [DOCTOR_PATIENT_QUEUE]: resource['doctor-patient-queue'],
    [DOCTOR_PATIENT_QUEUE]: resource['doctor-patient-queue'],
    [SCHEDULE]: resource.schedule,
    [IMPORT]: resource.import,
    [PATIENT_WAITING_ROOM]: resource.patient_waiting_room,
    [DOCTOR_SEARCH]: resource['doctor-search'],
  };
  return views;
};

export const tabAccess = ({tab}) => ({user}) => {
  let {userType} = user || {};
  if (
    tab === 'classRoomLiveIncharge' ||
    tab === 'add-class-room-live-incharge'
  ) {
    return (
      userType === USER_TYPES.LIVE_SUPER_ADMIN ||
      userType === USER_TYPES.RESOURCE
    );
  } else {
    return userType === USER_TYPES.SUPER_ADMIN;
  }
};

export const getUserWiseDefaultView = ({user = {}}) => {
  let {userType, resource} = user;
  let type = (user && user.userType) || '';

  const userWiseViews = {
    SuperAdmin: 'clinics',
    ClinicAdmin: 'analytics',
    Doctor: 'analytics',
    FrontDesk: 'manage-invite-links',
    Patient: 'doctor-search',
  };

  return userWiseViews[type];
};
