import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';

const formFields = [
  {
    label: 'Operation Name',
    type: 'text',
    field: 'name',
    allowWhiteSpaces: true,
    mandatory: true,
    inputProps: {
      maxLength: 100,
    },
  },
  {
    label: 'Operation Code',
    type: 'text',
    field: 'code',
    allowWhiteSpaces: true,
    mandatory: true,
    inputProps: {
      maxLength: 100,
    },
  },
  {
    label: 'Cost',
    type: 'number',
    field: 'cost',
    mandatory: true,
    inputProps: {
      maxLength: 7,
    },
  },
  {
    label: 'Description',
    type: 'text',
    field: 'description',
    allowWhiteSpaces: true,
    inputProps: {
      maxLength: 150,
    },
  },
  {
    type: 'radioGroup',
    container: 'leftLabel',
    field: 'operationDegree',
    label: 'Degree',
    options: ['Major', 'Minor'],
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'Status',
    container: 'leftLabel',
    align: 'center',
  },
];

export default MasterForm({
  model: MODELS.OPERATIONS,
  formFields,
  uniqueValidationMessage: 'Operation is Already exist',
  submitMessage: ' Operation is added Successfully',
  mandatory: {
    name: 1,
    cost: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});

