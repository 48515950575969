import React, { useCallback, useEffect } from 'react';

import { View, Text } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import vars from '../../../../theme/vars';
import ConsultationActions from './Components/ConsultationActions';
import { MODELS } from '../../../../Lib/constants';
import VaccinationStatusRender from '../../../../Wired/VaccinationListOfPatient/VaccinationStatusRender';
import { gpsStore } from '../../../../AppServices';

const tableColumns = [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
    width: 200,
    render: ({ item }) => (
      <View style={{}}>
        <Text
          style={{
            ...vars.headings.h2,
          }}
        >
          {item?.token}
        </Text>
      </View>
    ),
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
    width: 250,
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
    width: 250,
  },
  {
    header: 'Status',
    type: 'text',
    render: VaccinationStatusRender,
    width: 250,
  },
  // apply link here of view
  {
    header: 'Actions',
    type: 'custom',
    render: (props) => (
      <ConsultationActions
        {...props}
      />
    ),
    width: 250,
  },
];

const VaccinationPatients = (props) => {
  const { addOnFilter, eventDispatcher } = props;

  const vaccinationAssigned = useCallback(() => {
    eventDispatcher.notify('vaccinationAssigned');
  }, [eventDispatcher]);

  const subscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Vaccination-Assigned',
      callback: vaccinationAssigned,
    });
  }, [vaccinationAssigned]);

  const unSubscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Vaccination-Assigned',
      callback: vaccinationAssigned,
    });
  }, [vaccinationAssigned]);

  useEffect(() => {
    subscribeToVaccinationAssigned();

    return () => {
      unSubscribeToVaccinationAssigned();
    };
  }, []);

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Table
          uri={() => ({
            query: {
              id: 'patientVaccinationList',
              addOnFilter,
              sort: {
                token: -1,
              },
            },
            model: MODELS.VACCINATION,
          })}
          columns={tableColumns}
          showActionIcon={false}
          reloadEvent="vaccinationAssigned"
          {...props}
        />
      </View>
    </View>
  );
};

export default VaccinationPatients;
