import { validateInputLength } from '../../../Lib/helpers';
import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Category Name',
    mandatory: true,
    placeholder: 'Category',
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'Description',
    allowWhiteSpaces: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'employeeType.labels.status'.getLabel(),
    container: 'leftLabel',
  },
];

export default MasterForm({
  model: MODELS.EMPLOYEE_CATEGORY,
  defaultValues: () => ({
    isActive: true,
  }),
  submitMessage: 'Category Added Successfully',
  formFields,
  mandatory: {
    name: 1,
  },
  validations: {
    name: ({ data }) => validateInputLength(data?.name, 2, 20),
  },
});
