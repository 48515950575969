import React from 'react';
import {
  FormField, LargeButton, OutlineButton, View,
} from '../../../app-components';
import { ROUTES } from '../../../Lib/constants';

const SearchExistingPatientOnUHIDForm = (props) => {
  const { navigation } = props;
  const { state: { params: { routes: { viewRoute } } = {} } = {} } = navigation;
  const {
    form_context: { handleSubmit },
  } = props;

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View>
          <FormField
            label={'patient.labels.enterUHID'.getLabel()}
            field="UHID"
            placeholder={'patient.placeholders.enterUHID'.getLabel()}
            type="text"
            variant="filled"
            onSubmitEditing={handleSubmit}
            mandatory
            upperCase
            autoFocus
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <View>
          <OutlineButton
            label={'patient.labels.register'.getLabel()}
            width={120}
            borderRadius={4}
            onClick={() => navigation.push({ view: ROUTES.addPatient.name, params: { viewRoute } })}
          />
        </View>
        <View style={{ marginLeft: 22 }}>
          <LargeButton
            label={'patient.labels.searchPatient'.getLabel()}
            width={160}
            borderRadius={4}
            onClick={handleSubmit}
          />
        </View>
      </View>
    </View>
  );
};

export default SearchExistingPatientOnUHIDForm;
