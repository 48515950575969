import React from 'react';

import {
  Text,
  StandardButton,
  TouchableOpacity,
  Image,
} from '../../../../../../../app-components';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { editIpdOrderListUri } from '../../../../../../../Queries/ipdOrders';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { getPermission } from '../../../../../../../AppServices';
import permissions from '../../../../../../../Lib/permissions';
import { editRecord } from '../../../../../../../images';

const header = (item) => {
  const { dataParams } = item;
  return ({
    title: () => (
      <HeaderTitleWithCount
        label="Notes"
        count={item?.aggregates?._count}
      />
    ),
    actions: [
      {
        type: 'link',
        link: ({ navigation: { state: { params = {} } } }) => ({
          view: ROUTES.LogOrder.name,
          params: { ...params, dataParams },
          modal: true,
          modalProps: {
            autoHide: true,
            maxHeight: '100%',
            width: 1196,
            height: 547,
          },
        }),

        render: () => (
          <StandardButton
            label="Log Orders"
          />
        ),
      },
    ],
  });
};

const tableItems = {
  lg: {
    columns: [
      {
        header: 'Opeartion Date',
        type: 'date',
        format: 'DD MM YYYY',
        field: 'dateOfOperation',
      },
      {
        header: 'Name of Operation',
        type: 'text',
        field: 'operation.name',
      },
      {
        header: 'Surgeon Name',
        type: 'text',
        render: ({ item }) => item.surgeonName.map((data) => (
          <Text key={data?._id} style={{ fontSize: 14, lineHeight: 16 }}>
            {data?.name}
          </Text>
        )),
      },
      {
        header: 'Action',
        render: () => <Text>View</Text>,
        type: 'link',
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.viewOrderDetail.name,
              params: { item },
            };
          },
        },
      },
      {
        header: 'Edit'.getLabel(),
        type: 'text',
        visible: () => getPermission(
          permissions.departments.views.ipdEditForms
            .permissions.ipdEditForms.value,
        ),
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: (data) => ({
          type: 'link',
          // visible: () => getPermission(
          //   permissions.departments.views.ipdEditForms
          //     .permissions.ipdEditForms.value,
          // ),
          link: {
            modal: true,
            view: ROUTES.EditOrder.name,
            params: {
              addOnFilter: {
                _id: data.item._id,
              },
              dataParams: data.dataParams,
              patient: data?.item?.admissionID,
              item: data.item,
            },

          },
        }),
      },
    ],
  },
};

const Orders = (props) => (
  <Table
    {...props}
    header={header(props)}
    uri={editIpdOrderListUri(props)}
    {...tableItems}
    reloadEvent={`reload${MODELS.IPD_ORDERS}`}
    reloadOnChangeEvent={`reload${MODELS.IPD_ORDERS}`}
  />
);
export default Orders;

