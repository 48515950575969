import React from 'react';
import {
  save, Text, Toast, View,
} from '../../../app-components';

import { Form } from '../../../app-components/form/Form';
import { autoSuggestInput, multiAutoSuggestInput, searchInput } from '../../../autoSuggestions';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { invoke } from '../../../AppServices';
import { doseTime, frequency, MODELS } from '../../../Lib/constants';
import { getErrorString } from '../../../Lib/helpers';
import vars from '../../../theme/vars';

const footer = {
  containerStyle: {
    height: 70,
    paddingTop: 5,
  },
  actions: [
    save,
  ],
};

const DocumentationDetails = (props) => {
  const { item = {}, isClinicalView = false } = props || {};
  const { consultation: { _id } = {} } = item || {};

  const uri = {
    query: {
      id: 'getConsultationPrescriptions',
      relationValue: {
        model: MODELS.CONSULTATION,
        _id,
        relation: '_id',
      },
      limit: 1,
    },
    model: MODELS.CONSULTATION,
  };
  return (
    <Form
      type="standardShadow"
      uri={uri}
      {...props}
      containerStyle={{
        paddingTop: isClinicalView ? 0 : 10,
        // paddingBottom: 20,
        paddingLeft: isClinicalView ? 0 : 12,
        paddingRight: isClinicalView ? 0 : 12,
        // display:props?.selected='patient_profile'
        backgroundColor: '#fff',
      }}
      onSubmit={async ({ data }) => {
        const newData = { ...data, ...item };
        try {
          const result = await invoke({
            id: 'addDetailsOnVideoConsultation',
            paramValue: {
              data: newData,
            },
          });
          if (result) {
            Toast.show({
              message: 'Success',
              description: 'Details Added successfully',
              type: 'success',
              position: 'top',
              direction: 'right',
            });
          }
        } catch (err) {
          Toast.show({
            message: 'Error',
            description: `${getErrorString(err)}`,
            type: 'error',
            position: 'top',
            direction: 'right',
          });
        }
      }}
      formGroups={[
        {
          fieldVariant: 'filled',
          direction: 'row',
          groups: [
            {
              columnsPerRow: 1,
              columns: [
                multiAutoSuggestInput({
                  field: 'diagnosis',
                  label: 'Diagnosis',
                  placeholder: 'Diagnosis',
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.DISEASE,
                  query: 'masterDataList',
                  mandatory: true,
                }),
                {
                  type: 'textArea',
                  label: 'Prescription',
                  field: 'prescription',
                  placeholder: 'Prescription',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'Items',
          editable: true,
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 7,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {
                  field: 'pharmacyDetails',
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    header: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        searchInput({
                          type: 'autoSuggest',
                          field: 'item',
                          label: `${'billing.labels.itemName'.getLabel()}`,
                          variant: 'filled',
                          keyFiled: 'name',
                          suggestionField: 'name',
                          valueField: 'name',
                          model: MODELS.PHARMACY_ITEM,
                          query: 'masterDataList',
                          width: 200,
                        },
                        {
                          addOnFilter: ({
                            data: {
                              _parent: {
                                pharmacyDetails = [],
                              } = {},
                            },
                          } = {}) => {
                            const itemIds = pharmacyDetails?.filter(
                              ({ item }) => item?._id,
                            ).map(({ item }) => item?._id);

                            return {
                              _id: { $nin: itemIds },
                            };
                          },
                        }),
                        searchInput({
                          type: 'autoSuggest',
                          field: 'item',
                          label: `${'billing.labels.itemId'.getLabel()}`,
                          variant: 'filled',
                          keyFiled: 'itemCode',
                          suggestionField: 'itemCode',
                          valueField: 'itemCode',
                          model: MODELS.PHARMACY_ITEM,
                          query: 'masterDataList',
                          mandatory: true,
                          disabled: true,
                          width: 200,
                        }),
                        {
                          type: 'number',
                          label: `${'billing.labels.quantity'.getLabel()}`,
                          field: 'quantity',
                          variant: 'filled',
                          mandatory: true,
                          width: 100,
                        },
                        autoSuggestInput({
                          label: `${'billing.labels.frequency'.getLabel()}`,
                          field: 'frequency',
                          suggestionField: 'label',
                          placeholder: 'Select frequency',
                          options: frequency,
                          mandatory: true,
                          variant: 'filled',
                          width: 200,
                        }),
                        {
                          type: 'text',
                          label: `${'billing.labels.doses'.getLabel()}`,
                          field: 'doses',
                          variant: 'filled',
                          mandatory: true,
                          width: 100,
                        },
                        autoSuggestInput({
                          label: `${'billing.labels.doseTime'.getLabel()}`,
                          field: 'doseTime',
                          suggestionField: 'label',
                          placeholder: 'select dose time',
                          options: doseTime,
                          mandatory: true,
                          variant: 'filled',
                          width: 150,
                        }),
                        {
                          type: 'text',
                          label: 'Comments',
                          field: 'comments',
                          variant: 'filled',
                          width: 200,
                          allowWhiteSpace: true,
                        },
                        NestedAction(),
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ]}
      footer={footer}
    />
  );
};

export default DocumentationDetails;
