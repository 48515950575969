import React from 'react';

import ListActionModal from '../../../Components/ListActionModal';
import { Form } from '../../../app-components/form/Form';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import {
  save,
} from '../../../app-components';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { submit } from '../../../AppServices';

const AddRemark = (props) => (
  <ListActionModal
    title="REMARK"
    description="Add Remark of the Employee."
    content={(
      <Form
        {...props}
        closeView={1}

        footer={{
          actions: [
            save,
          ],
        }}

        reloadEvent={`reload${MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK}`}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}

        onSubmit={(params) => {
          const { data, updates } = params;
          updates.month = updates?.month?.key;
          updates.year = updates?.year?.name;
          const submitAPI = submit({
            model: MODELS.EMPLOYEE_MONTHLY_SALARY_REMARK,
          });
          const result = submitAPI({ updates, data });
          return result;
        }}

        mandatory={{
          employee: 1,
          month: 1,
          year: 1,
          remark: 1,
        }}

        {...props}

        lg={{
          formGroups: [
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    searchInput({
                      suggestionField: 'name',
                      query: 'getEmployeeDetails',
                      searchField: 'name',
                      field: 'employee',
                      model: MODELS.EMPLOYEE,
                      placeholder: 'Select Employee',
                      header: 'Select Employee',
                      mandatory: true,
                    }),
                  ],
                },
              ],
            },
            {
              columnsPerRow: 2,
              fieldVariant: 'filled',
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 2,
                  columns: [

                    autoSuggestInput(
                      {
                        field: 'month',
                        label: 'Select Month',
                        placeholder: 'Select Month',
                        suggestionField: 'name',
                        model: MODELS.MONTHS,
                        query: 'MonthTypeSuggestions',
                        mandatory: true,
                      },
                    ),
                    autoSuggestInput(
                      {
                        field: 'year',
                        label: 'Select Year',
                        placeholder: 'Select Year',
                        suggestionField: 'name',
                        model: MODELS.YEARS,
                        query: 'YearTypeSuggestions',
                        mandatory: true,
                      },
                    ),

                  ],
                },
              ],
            },
            {
              columnsPerRow: 1,
              fieldVariant: 'filled',
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      type: 'textArea',
                      field: 'remark',
                      label: 'Remark',
                      mandatory: true,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);

export default AddRemark;
