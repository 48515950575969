import React from 'react';
import {
  FormField, OutlineButton, StandardButton, Text, View,
} from '../../../../../../../app-components';
import { batchIcon } from '../../../../../../../images';
import ListActionModal from '../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../app-components/form/Form';
import vars from '../../../../../../../theme/vars';
import { submit } from '../../../../../../../AppServices';
import { assetServiceTypes, MODELS } from '../../../../../../../Lib/constants';
import { currencyInput } from '../../../../../../../compositeInputs';
// import { currency } from '../../../../../../../Config';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { NestedAction, NestedTable } from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { EditAsset } from '../../../../../../../Queries/asset';

const EditAssetItem = (props) => {
  const { navigation } = props;
  const {
    navigation: {
      state: {
        params: { item },
      },
    },
  } = props;
  return (
    <ListActionModal
      icon={batchIcon}
      title={'asset.title.itemDetails'.getLabel()}
      description={'asset.title.editItem'.getLabel()}
      content={(
        <Form
          closeView={1}
          uri={EditAsset}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          reloadEvent={`reload${MODELS.ASSET}`}
          // eslint-disable-next-line no-unused-vars
          onSubmit={submit({
            model: MODELS.ASSET,
          })}
          submitMessage={'asset.messages.itemUpdatedSuccessfully'.getLabel()}
          mandatory={{
            purchasedPrice: 1,
            currency: 1,
            supplyReceivedOn: 1,
            isAMC: 1,
            serviceItemDetails: {
              preventiveDate: 1,
              serviceDate: 1,
            },
          }}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View style={{
              paddingBottom: 12, paddingLeft: 94, paddingRight: 94,
            }}
            >
              <View style={{ flex: 1, flexDirection: 'row', paddingTop: 5 }}>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="text"
                    label={'asset.labels.serialNo'.getLabel()}
                    variant="filled"
                    field="serialNumber"
                    disabled
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    {
                        ...currencyInput({
                          variant: 'filled',
                          amount: {
                            field: 'purchasedPrice',
                            label: 'asset.labels.purchasePrice'.getLabel(),
                          },
                          currency: {
                            field: 'currency',
                          },
                        })
                      }
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    type="date"
                    label={'asset.labels.supplyReceivedOn'.getLabel()}
                    variant="filled"
                    field="supplyReceivedOn"
                  />
                </View>

              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    {... autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'supervisor',
                      label: 'Supervisor',
                      placeholder: 'Supervisor',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    {
                      addOnFilter: () => ({
                        role: {
                          code: 'Supervisor',
                        },
                      }),
                    })}
                  />
                </View>
                <View style={{ flex: 1, paddingRight: 16 }}>
                  <FormField
                    {... autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'inCharge',
                      label: 'InCharge',
                      placeholder: 'InCharge',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                      disabled: ({ data }) => (!data?.supervisor),
                    },
                    {
                      addOnFilter: ({ data: { supervisor: { _id } } }) => ({
                        role: {
                          code: 'Incharge',
                        },
                        supervisor: { _id },
                      }),
                    })}
                  />
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 0.5, paddingRight: 16 }}>
                  <FormField
                    type="radioGroup"
                    label={'asset.labels.service'.getLabel()}
                    container="topLabel"
                    options={assetServiceTypes}
                    field="isAMC"
                    variant="filled"
                  />
                </View>
              </View>
              <View
                style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}
              >
                <FormField
                  {...{
                    field: 'serviceItemDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.secondary.color2 }}>
                                {`+ ${'asset.buttons.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      listProps: {
                        hideColumnHeader: true,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          backgroundColor: vars.colors.white,
                        },
                        headerRowColumnTextStyle: {
                          // paddingLeft: 12,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'date',
                            label: 'asset.headers.preventiveDate'.getLabel(),
                            header: 'asset.headers.preventiveDate'.getLabel(),
                            variant: 'filled',
                            field: 'preventiveDate',
                          },
                          {
                            type: 'date',
                            label: 'asset.headers.serviceDate'.getLabel(),
                            header: 'asset.headers.serviceDate'.getLabel(),
                            variant: 'filled',
                            field: 'serviceDate',
                          },
                          {
                            type: 'number',
                            label: 'asset.headers.repairPrice'.getLabel(),
                            placeholder: 'asset.headers.repairPrice'.getLabel(),
                            variant: 'filled',
                            field: 'repairPrice',
                            width: 200,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  }
                }
                />
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 16 }}>
                <View style={{ flex: 0.5, paddingRight: 16 }}>
                  <FormField
                    type="file"
                    label={'asset.labels.attachDocuments'.getLabel()}
                    variant="filled"
                    field="documents"
                  />
                </View>
              </View>
              <View
                style={{
                  alignSelf: 'center',
                  marginTop: 25,
                  flexDirection: 'row',
                }}
              >
                <View style={{ marginRight: 24 }}>
                  <OutlineButton
                    label={'asset.buttons.cancel'.getLabel()}
                    onClick={() => {
                      navigation.pop();
                    }}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
                <StandardButton
                  label={'asset.buttons.save'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={166}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default EditAssetItem;
