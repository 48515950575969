import React from 'react';

import {
  Table,
  Text,
} from '../../../../../../app-components';
import {
  MODELS, assetInventoryStockRequestStatus, ROUTES, purchaseSupplyOrderStatus,
} from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetMSITransferRequestsListUri } from '../../../../../../Queries/asset';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.date'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.noOfItems'.getLabel(),
        type: 'text',
        value: ({ item }) => item?.departmentStocks?.length,
      },
      {
        header: 'asset.headers.fromDepartment'.getLabel(),
        type: 'text',
        field: 'fromDepartment.name',
      },
      {
        header: 'asset.headers.toDepartment'.getLabel(),
        type: 'text',
        field: 'toDepartment.name',
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { requestStatus } }) => {
          let otherRequestStatus = '';
          let statusColor = '';
          if (requestStatus === assetInventoryStockRequestStatus.approved) {
            otherRequestStatus = assetInventoryStockRequestStatus.Approved;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === assetInventoryStockRequestStatus.raised) {
            otherRequestStatus = assetInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          } else {
            otherRequestStatus = assetInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {otherRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewTransferRequestAsset.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.ASSET_ITEM}`,
  uri: AssetMSITransferRequestsListUri({
    filter: {
      requestStatus: {
        $in:
       [purchaseSupplyOrderStatus.APPROVED],
      },
    },
  }),
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewTransferRequestAsset.name,
      params: { item },
    },
  }),
});
