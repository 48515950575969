import React from 'react';

import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';

const viewExGratiaSociety = (props) => (
  <Form
    {...props}

    type="standard"
    editable={false}
    uri={
        () => ({
          model: MODELS.EX_GRATIA_SOCIETY_SALARY,
          query: {
            id: 'exGratiaAndSocietySalary',
            addOnFilter: props?.navigation?.state?.params?.addOnFilter,
          },
        })
    }
    reloadEvent={`reload${MODELS.EX_GRATIA_SOCIETY_SALARY}`}
    lg={{
      formGroups: [
        {
          columnsPerRow: 3,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 10,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'employee.name',
                  label: 'Employee',
                  variant: 'filled',
                },
                {
                  type: 'text',
                  field: 'month.name',
                  label: 'Month',
                  variant: 'filled',
                },
                {
                  type: 'text',
                  field: 'year.name',
                  label: 'Year',
                  variant: 'filled',
                },
                {
                  type: 'number',
                  field: 'no_of_present_days',
                  label: 'No of Present Days',
                  variant: 'filled',
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default viewExGratiaSociety;
