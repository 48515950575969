import React from 'react';

import { viewVitalSignSheetUri } from '../../../../../../../../Queries/vitalSign';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import {
  vitalSignBloodSugar,
  vitalSignCS,
  vitalSignG,
  vitalSignOutput,
  vitalSignTnput,
} from '../../../fieldConfiguration';

const IpdViewVitals = (props) => {
  const { navigation: { state: { params } } } = props;
  const { addOnFilter: filter } = params;
  const { dataParams } = params;
  return (
    <ListActionModal
      {...props}
      title="Vitals Signs"
      description="Logged Vitals of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          type="standardShadow"
          uri={viewVitalSignSheetUri(filter)}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'date',
                        label: 'Date',
                        field: '',
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignBP',
                        label: 'BP',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignTemperature',
                        label: 'Temperature',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignPulse',
                        label: 'Pulse',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignResp',
                        label: 'Resp.',
                        mandatory: true,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignG',
                        label: 'G',
                        visible: vitalSignG[dataParams?.filters?.specialityFilter],
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignCS',
                        label: 'CS',
                        visible: vitalSignCS[dataParams?.filters?.specialityFilter],
                        editable: false,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'number',
                        field: 'vitalSignInput',
                        label: 'Input',
                        mandatory: true,
                        visible:
                              vitalSignTnput[
                                dataParams?.filters?.specialityFilter
                              ],
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'vitalSignOutput',
                        label: 'Output',
                        visible: vitalSignOutput[dataParams?.filters?.specialityFilter],
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'bloodSugar',
                        label: 'Blood Sugar',
                        visible: vitalSignBloodSugar[dataParams?.filters?.specialityFilter],
                        editable: false,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'nurseNote',
                        label: 'Nurse Note',
                        placeHolder: 'Type Here....',
                        variant: 'filled',
                        editable: false,
                        minHeight: 90,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />

  );
};

const ViewVitals = (props) => (<IpdViewVitals {...props} />);
export default ViewVitals;
