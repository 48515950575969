import React, { useEffect, useState } from 'react';

import { View, Text } from '../../../../app-components';
import ChronicPatient from '../ChronicPatient';
import vars from '../../../../theme/vars';
import Summary from '../Summary';
import Doctor from '../Doctor';

const selectedStyles = {
  height: 30,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 34,
  paddingRight: 34,
  cursor: 'pointer',
  backgroundColor: '#4070C9',
  color: '#FFFFFF',
  borderWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
};

const unSelectedStyles = {
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 34,
  paddingRight: 34,
  borderWidth: 0,
  borderColor: vars.colors.primary.color3,
  cursor: 'pointer',
  backgroundColor: '#F0FFF4',
  color: '#828282',
};

export default (props) => {
  const { dataParams = {} } = props;
  const [selectedTab, setSelectedTab] = useState('summary');

  const RenderTab = ({ selectTab }) => {
    if (selectTab === 'summary') {
      return <Summary filters={dataParams} />;
    }
    if (selectTab === 'doctor') {
      return <Doctor filters={dataParams} />;
    }
    return <ChronicPatient filters={dataParams} />;
  };
  useEffect(() => {
    const { eventDispatcher } = props || {};
    eventDispatcher.notify('selectedTabFilter', selectedTab);
  }, [selectedTab]);

  return (
    <View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginTop: '1rem',
          marginLeft: '3rem',
        }}
      >
        <View
          style={selectedTab === 'summary' ? selectedStyles : unSelectedStyles}
          onPress={() => setSelectedTab('summary')}
        >
          <Text>Summary</Text>
        </View>
        <View
          style={selectedTab === 'patients' ? selectedStyles : unSelectedStyles}
          onPress={() => setSelectedTab('patients')}
        >
          <Text>Chronic Patients</Text>
        </View>
        <View
          style={selectedTab === 'doctor' ? selectedStyles : unSelectedStyles}
          onPress={() => setSelectedTab('doctor')}
        >
          <Text>Doctor</Text>
        </View>
      </View>
      <RenderTab selectTab={selectedTab} />
    </View>
  );
};
