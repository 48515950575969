import { Text } from '@applane/react-core-components';
import React from 'react';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const tabListColumns = [
  {
    width: 14,
  },
  {
    header: 'asset.headers.serialNo'.getLabel(),
    type: 'text',
    field: 'serialNumber.',
    width: 100,
  },
  {
    header: 'asset.headers.value'.getLabel(),
    type: 'text',
    field: 'purchasedPrice',
    width: 100,
  },
  {
    header: 'asset.headers.stockEntryDate'.getLabel(),
    type: 'date',
    field: 'batchNumber.stockEntryDate',
    width: 120,
  },
  {
    header: 'asset.headers.manufacturerName'.getLabel(),
    type: 'text',
    field: 'batchNumber.manufacturer.name',
    width: 150,
  },
  {
    header: 'asset.headers.lastRepairDate'.getLabel(),
    type: 'date',
    field: 'lastRepairDate',
    width: 120,
  },
  {
    header: 'asset.headers.lastLoanOutDate'.getLabel(),
    type: 'date',
    field: 'loanedOutDate',
    width: 150,
  },
  {
    header: 'asset.headers.lastLoanOutDept'.getLabel(),
    type: 'text',
    field: 'department.name',
    width: 150,
  },
  {
    header: 'asset.headers.loanOutDays'.getLabel(),
    type: 'number',
    field: 'loanedOutDays',
    width: 120,
  },
  {
    header: 'asset.headers.speciality'.getLabel(),
    type: 'text',
    field: 'speciality.name',
    width: 100,
  },
  {
    header: 'asset.headers.stockStatus'.getLabel(),
    width: 150,
    type: 'text',
    render: ({ item: { assetStatus } = {} }) => {
      let stocksStatus = assetStatus.toString();
      let statusColor = vars.colors.warning;
      if (stocksStatus === 'Issued') {
        stocksStatus = 'In-Storage';
        statusColor = vars.colors.primary.color2;
      }
      return (
        <Text style={{ ...vars.headings.h9, color: statusColor }}>
          {stocksStatus}
        </Text>
      );
    },
  },
];

const ItemWiseStockList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;

  return (
    <Table
      {...props}
      uri={() => ({
        query: {
          id,
          addOnFilter: { ...addOnFilter, assetStatus: { $ne: 'inAuction' } },
        },
        model,
      })}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent={`reload${MODELS.ASSET}`}
      {...restProps}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.assetItemDetail.name,
          params: { item },
        },
      })}
    />
  );
};
export default ItemWiseStockList;
