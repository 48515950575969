import React, { useCallback, useEffect, useState } from 'react';

import {
  LargeButton, Toast, View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { invoke } from '../../../../../AppServices';
import ListActionModal from '../../../../../Components/ListActionModal';
import { history } from '../../../../../images';
import { getErrorString } from '../../../../../Lib/helpers';
import vars from '../../../../../theme/vars';

const GenerateSalaryActionModal = (props) => {
  const {
    navigation,
    navigation: { state: { params } },
  } = props;
  const {
    monthlyAttendanceId,
    employee: {
      staffType, employeeType, department, speciality,
    },
    month_id,
    year_id,
  } = params;

  const [paymentDone, isPaymentDone] = useState(false);
  const handleSalaryPaymentDoneRequest = useCallback(async () => {
    const result = await invoke({
      id: 'isSalaryPaymentDone',
      paramValue: {
        filter: {
          month: {
            _id: month_id?._id,
          },
          year: {
            _id: year_id?._id,
          },
          // department: {
          //   _id: {
          //     $in: department?.map((item) => item._id),
          //   },
          // },
        },
        validations: {
          staffType: {
            _id: staffType?._id,
          },
          employeeType: {
            _id: employeeType?._id,
          },
          // speciality: speciality?.map((item) => item._id),
        },
      },
    });
    isPaymentDone(result.result);
  }, [department, employeeType, month_id, speciality, staffType, year_id]);

  useEffect(() => {
    handleSalaryPaymentDoneRequest();
  });

  const handleOnSubmitGenerateSalary = useCallback(async () => {
    try {
      const result = await invoke({
        id: 'generateAttendanceBasedSalary',
        paramValue: { selectedIds: [monthlyAttendanceId] },
      });

      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Salary has been generated successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        navigation.pop();
        // eventDispatcher.notify(`reload${MODELS.MONTHLY_ATTENDANCE}`);
        // setScreenState({
        //   selectedIds: [],
        // });
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [navigation, params.employee]);

  return (
    <ListActionModal
      icon={history}
      title="Generate Salary"
      description={`This action will generate Salary of ${params?.employee?.firstName} ${paymentDone ? 'as arrear.' : '.'}`}
      content={(
        <Form
          onSubmit={handleOnSubmitGenerateSalary}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;
              return (
                <View
                  style={{
                    flex: 1,
                    // marginTop: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LargeButton
                    label="Generate"
                    onClick={handleSubmit}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default GenerateSalaryActionModal;
