import vars from '../../../theme/vars';

export const BLOOD_REQUEST_STATUSES = {
  REQUESTED: 'requested',
  APPROVED: 'provided',
  REJECTED: 'rejected',
  SAMPLE_REQUESTED: 'sample_requested',
  SAMPLE_SENT: 'sample_sent',
  SAMPLE_RECEIVED: 'sample_received',
};

export const BLOOD_REQUEST_STATUSES_LABELS_AND_COLORS = {
  [BLOOD_REQUEST_STATUSES.REQUESTED]: { value: 'Requested', color: vars.colors.warning },
  [BLOOD_REQUEST_STATUSES.APPROVED]: { value: 'Provided', color: vars.colors.primary.color2 },
  [BLOOD_REQUEST_STATUSES.REJECTED]: { value: 'Rejected', color: vars.colors.error },
  [BLOOD_REQUEST_STATUSES.SAMPLE_REQUESTED]: { value: 'Sample Requested', color: vars.colors.primary.color2 },
  [BLOOD_REQUEST_STATUSES.SAMPLE_SENT]: { value: 'Sample Sent', color: vars.colors.primary.color2 },
  [BLOOD_REQUEST_STATUSES.SAMPLE_REQUESTED]: { value: 'Sample Received', color: vars.colors.primary.color2 },
};
