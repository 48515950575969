export const CommonTaxDetailsUri = (
  {
    model, addOnFilter = {}, query,
  },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
  },
  model,
});
