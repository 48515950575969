import React, { Component } from 'react';
import {
  renderAvatarChips,
  renderChips,
  RenderRowComponent,
} from '../../input-components/autoSuggest/renderRowCard';
import DropdownFilter from './DropdownFilter';
import { renderRowStyle } from '../../../theme/editorTheme';
import { isMobile } from '../../UtilityFunctions';
import {
  autoSuggestFilterTheme,
  autoSuggestFilterMobileTheme,
} from '../../../theme/filterTheme';

const theme = isMobile ? autoSuggestFilterMobileTheme : autoSuggestFilterTheme;

export class AutoSuggest extends Component {
  setValue = ({ value }) => {
    let {
      field,
      data: filters,
      asParam,
      asGlobalParam,
      onFilterChange,
      filterType,
      filterField,
      fieldType,
      onChangeValue,
      filterTabs,
      setTabsState,
      filterValueField,
    } = this.props;
    filters = {
      ...filters,
    };
    if (value === undefined || value === null) {
      delete filters[field];
    } else {
      filters[field] = {
        value,
        filter: {
          filterType: filterType || 'autoSuggest',
          filterField,
          fieldType,
          value,
          asParam,
          asGlobalParam,
          filterValueField,
        },
      };
    }
    if (onFilterChange) {
      filters = onFilterChange({ filters });
    }
    onChangeValue && onChangeValue(filters);
    if (filterTabs && setTabsState) {
      setTabsState({
        filtersInfo: {
          [field]: { filterTabs },
        },
      });
    }
  };

  getFieldValue = () => {
    const { data: filters, field } = this.props;
    return filters && filters[field]?.value;
  };

  renderRow = ({ props, ...rest }) => {
    const { showAvatar, options } = this.props;
    let { item } = rest;
    if (options && item.label) {
      item = item.label;
    }
    return (
      <RenderRowComponent
        {...rest}
        {...props}
        showAvatar={showAvatar}
        item={item}
      />
    );
  };

  render() {
    const { screenState, showAvatar, ...restProps } = this.props;
    return (
      <DropdownFilter
        // renderChips={(props) =>
        //   showAvatar
        //     ? renderAvatarChips({
        //         ...props,
        //         ...theme,
        //       })
        //     : renderChips({
        //         ...props,
        //         ...theme,
        //       })
        // }
        {...restProps}
        setValue={this.setValue}
        value={this.getFieldValue()}
        renderRow={this.renderRow}
        usePropsValue
      />
    );
  }
}

export default (props) => {
  let filterType = 'autoSuggest';
  const { options, sugestionField } = props;
  if (options) {
    filterType = 'autoSuggestOptions';
  }
  return (
    <AutoSuggest
      filterType={filterType}
      primaryField={sugestionField}
      {...props}
      {...renderRowStyle}
      autoSuggestModalTheme={theme.autoSuggestModalTheme}
    />
  );
};
