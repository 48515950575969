import React from 'react';
import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import FtsSearch from '../../../../../Components/TableFilters/FtsSearch';
import {
  MODELS,
} from '../../../../../Lib/constants';
import PatientVaccinationLogList from '../vaccinationLogList';
import PatientDueVaccineList from '../dueVaccine';
import { countUri } from '../../../../../Queries/countUri';

const PatientVaccinationHistoryTabs = (props) => {
  const { navigation: { state: { params: { item: { patient: { _id } } } } } } = props;
  const { navigation: { state: { params: { item: { patientVaccineDetails = [] } } } } } = props;
  const dueVaccineFilter = { _id: patientVaccineDetails.map(({ item }) => item?._id) };

  return (
    <TabNavigator
      tabs={{
        DueVaccines: {
          label: 'Due Vaccines',
          screen: PatientDueVaccineList,
          screenProps: {
            model: MODELS.PATIENT_VACCINATION_HISTORY,
            id: 'patientVaccinationHistory',
            addOnFilter: {
              item: {
                _id: { $in: dueVaccineFilter?._id },
              },
              patient: { _id },
            },
          },
          countUri: countUri({
            model: MODELS.PATIENT_VACCINATION_HISTORY,
            id: 'patientVaccinationHistory',
            filter: {
              item: {
                _id: { $in: dueVaccineFilter?._id },
              },
              patient: { _id },
            },
          }),
          actions: [
            {
              render: (actionProps) => (
                <FtsSearch
                  {...actionProps}
                  action={{
                    exp: ['item.name'],
                  }}
                />
              ),
            },
          ],
        },
        VaccinationLog: {
          label: 'Vaccination Log',
          screen: PatientVaccinationLogList,
          screenProps: {
            model: MODELS.PATIENT_VACCINATION_HISTORY,
            id: 'patientVaccinationHistory',
            addOnFilter: { patient: { _id } },
          },
          countUri: countUri({
            model: MODELS.PATIENT_VACCINATION_HISTORY,
            id: 'patientVaccinationHistory',
            filter: { patient: { _id } },
          }),
          actions: [
            {
              render: (actionProps) => (
                <FtsSearch
                  {...actionProps}
                  action={{
                    exp: ['item.name'],
                  }}
                />
              ),
            },
          ],
        },
      }}
      {...props}
    />
  );
};
export default PatientVaccinationHistoryTabs;
