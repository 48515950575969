import React from 'react';
import { Image } from '../../../app-components';
import { editRecord } from '../../../images';
import { ROUTES } from '../../../Lib/constants';

export const Layouts = {
  name: {
    header: 'NAME',
    type: 'text',
    field: 'name',
  },
  code: {
    header: 'Structure Id',
    type: 'text',
    field: 'code',
  },
  level: {
    header: 'Level',
    type: 'text',
    field: 'level',
  },
  employee_type: {
    type: 'text',
    field: 'employeeType.name',
    header: 'Employee Type',
    placeholder: 'Employee Type',
  },
  ctc_amount: {
    header: 'salaryCompensationTemplate.labels.ctc_amount'.getLabel(),
    type: 'number',
    field: 'ctc_amount',
  },
  status: {
    type: 'status',
    header: 'status',
    field: 'isActive',
  },
  action: {
    header: 'organisationTaxDetails.headers.action'.getLabel(),
    type: 'text',
    render: () => (
      <Image
        style={{
          height: 24,
          width: 24,
        }}
        source={editRecord}
      />
    ),
    action: () => ({
      type: 'link',
      link: () => ({
        view: ROUTES.editSalaryCompensationStructure.name,
        routeOptions: {
          expanded: true,
        },
      }),
    }),
  },
  designation: {
    header: 'DESIGNATION',
    type: 'text',
    field: '',
  },
  joinings: {
    header: 'JOINING',
    type: 'date',
    format: 'DD MMM, YYYY',
    field: '',
  },
  reportingTo: {
    header: 'REPORTING TO',
    type: 'text',
    field: '',
  },
  org: {
    header: 'ORGANISATION',
    type: 'text',
    field: '',
  },
};
