import { MODELS } from '../Lib/constants';

export const appointmentsListUri = () => ({
  query: {
    id: 'doctorAppointments',
    addOnFilter: {
      status: { $ne: 'cancelled' },
    },
    sort: { _id: -1 },
  },
  aggregate: true,
  model: MODELS.APPOINTMENTS,
});
