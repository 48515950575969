export const EditSettingDetail = ({ user, navigation }) => {
  return {
    query: {
      id: 'settingLists',
      relationValue: {
        model: 'Settings',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Settings',
  };
};

export const countUri = ({ id, model, filter }) => {
  return {
    query: {
      id,
      addOnFilter: filter,
    },
    fetchCount: true,
    model,
  };
};
export const listUri = ({ id, model, filter, limit }) => {
  return {
    query: {
      id,
      addOnFilter: filter,
      limit,
    },
    model,
  };
};
export const aPPP = () => {
  return {
    query: {
      id: 'patientAppointments',
      addOnFilter: {},
    },
    model: 'Appointments',
  };
};

export const EditSymptom = ({ navigation }) => {
  let { state: { params: { item: { _id } = {} } = {} } = {} } = navigation;
  return {
    query: {
      id: 'symptomLists',
      relationValue: {
        model: 'Symptoms',
        _id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Symptoms',
  };
};

export const EditDrug = ({ navigation }) => {
  return {
    query: {
      id: 'drugLists',
      relationValue: {
        model: 'Drugs',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Drugs',
  };
};
export const EditDoctor = ({ navigation }) => {
  return {
    query: {
      id: 'doctorLists',
      relationValue: {
        model: 'Doctor',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Doctor',
  };
};

export const specialityDetail = ({ navigation }) => {
  return {
    query: {
      id: 'specialityLists',
      relationValue: {
        model: 'Specialities',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Specialities',
  };
};

export const EditProcedure = ({ navigation }) => {
  return {
    query: {
      id: 'procedureLists',
      relationValue: {
        model: 'Procedures',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Procedures',
  };
};

export const EditAllergy = ({ navigation }) => {
  return {
    query: {
      id: 'allergyLists',
      relationValue: {
        model: 'Allergies',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Allergies',
  };
};

export const EditOrganisationSetting = ({ navigation }) => {
  return {
    query: {
      id: 'organizationSettingList',
      relationValue: {
        model: 'OrganizationSetting',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'OrganizationSetting',
  };
};

export const EditOrganizationQuerry = ({ navigation }) => {
  return {
    query: {
      id: 'organizationLists',
      relationValue: {
        model: 'Organizations',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Organizations',
  };
};

export const EditClinicAdmin = ({ navigation }) => {
  return {
    query: {
      id: 'userLists',
      relationValue: {
        model: 'User',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'User',
  };
};

export const EditAppointmentAdmin = ({ navigation }) => {
  return {
    query: {
      id: 'appointmentLists',
      relationValue: {
        model: 'Appointments',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Appointments',
  };
};

export const importData = ({}) => {
  return {
    query: {
      id: 'importHistoryLogList',
      // addOnFilter: filter,
    },
    model: 'ImportHistoryLogs',
  };
};

export const importDetail = ({ user, navigation }) => {
  return {
    query: {
      id: 'importHistoryLogDetails',
      addOnFilter: { _id: navigation.state.params.import._id },
    },
    model: 'ImportHistoryLogs',
  };
};

export const EditDosage = ({ navigation }) => {
  return {
    query: {
      id: 'dosageLists',
      relationValue: {
        model: 'Dosages',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Dosages',
  };
};

export const EditDiagnosis = ({ navigation }) => {
  return {
    query: {
      id: 'diagnoseLists',
      relationValue: {
        model: 'Diagnosis',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Diagnosis',
  };
};

export const EditPatientProfile = ({ navigation }) => {
  return {
    query: {
      id: 'patientLists',
      relationValue: {
        model: 'Patients',
        _id: navigation.state.params.items.patient._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Patients',
  };
};

export const EditDoctorProfile = ({ navigation }) => {
  return {
    query: {
      id: 'doctorLists',
      relationValue: {
        model: 'Doctor',
        _id: navigation.state.params.items.doctor._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'Doctor',
  };
};

export const EditUserProfile = ({ navigation }) => {
  return {
    query: {
      id: 'userLists',
      relationValue: {
        model: 'User',
        _id: navigation.state.params.items._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'User',
  };
};
