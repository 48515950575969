import React from 'react';
import { View } from '../../../../../app-components';
import { assetCategory, MODELS } from '../../../../../Lib/constants';
import { EditCategory } from '../../../../../Queries/category';
import ViewAssetCategoryTabs from './components/Tabs';
import { searchInput } from '../../../../../autoSuggestions';
import { Form } from '../../../../../app-components/form/Form';

const formFields = [
  {
    ...searchInput({
      field: 'name',
      label: 'asset.labels.category'.getLabel(),
      suggestionField: 'label',
      options: assetCategory,
      searching: true,
      mandatory: true,
    }),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'asset.labels.description'.getLabel(),
    minHeight: 100,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'asset.labels.status'.getLabel(),
    container: 'topLabel',
  },
];

const ViewCategoryForm = (props) => {
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{ flex: 1 }}>
        <Form
          uri={EditCategory}
          model={MODELS.PHARMACY_CATEGORY}
          editable={false}
          renderLoading={false}
          columnsPerRow={3}
          formGroups={[
            {
              columnsPerRow: 3,
              fieldVariant: 'filled',
              groups: [
                {
                  columns: [...formFields],
                },
              ],
            },
          ]}
          reloadOnChangeEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
          defaultValues={() => ({
            isActive: true,
          })}
          {...props}
        />
      </View>
      <View style={{ flex: 4, overflow: 'hidden' }}>
        <ViewAssetCategoryTabs {...props} showAddButton={false} />
      </View>
    </View>
  );
};
export default ViewCategoryForm;
