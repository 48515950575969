/* eslint-disable no-shadow */
import React from 'react';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import { bloodCategories, bloodGroup, MODELS } from '../../../../Lib/constants';
import BloodCategoryList from './BloodCategoryList';
import BloodGroupList from './BloodGroupList';
import { tabTheme } from './tabTheme';

const BloodBankTabs = (props) => {
  const { navigation } = props;
  const data = navigation?.state?.params?.item;
  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      tabs={{
        BloodCategory: {
          label: 'Blood Category',
          screen: BloodCategoryList,
          screenProps: {
            data,
            model: MODELS.BLOOD_CATEGORY,
            id: 'bloodCategoryAggregatedList',
            hoverable: true,
            addOnFilter: { category: { $in: bloodCategories.map((item) => item.label) } },
          },
        },
        BloodGroups: {
          label: 'Blood Group',
          screen: BloodGroupList,
          screenProps: {
            data,
            model: MODELS.BLOOD_CATEGORY,
            id: 'bloodCategoryList',
            hoverable: true,
            addOnFilter: { category: { $in: bloodGroup.map((item) => item.label) } },
          },
        },
      }}
      {...props}
    />
  );
};
export default BloodBankTabs;
