import { LeaveregisterDrill } from './Lists';
import { NewLeaveRequest } from './Actions';

export default {
  'leave-drill': {
    screen: LeaveregisterDrill,
    title: ({ navigation }) => {
      const { month_id: month } = (navigation && navigation.getParams('Attendance')) || {};
      return month?.name;
    },
  },
  'new-leave-request': { screen: NewLeaveRequest },
};
