import React from 'react';

import MasterList from '../../../Wired/Master/MasterList';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { Toast, ToggleFilter } from '../../../app-components';
import { getErrorString } from '../../../Lib/helpers';
import { downloadData, getUser } from '../../../AppServices';
import permissions from '../../../Lib/permissions';

const tableColumns = [
  {
    width: 20,
  },
  {
    header: 'holidayCalendar.labels.year'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',

  },
  {
    header: 'holidayCalendar.labels.NoOfHolidays'.getLabel(),
    type: 'number',
    field: 'numberOfHolidays',
    value: ({ item }) => item?.holidays_count?._count,
  },
  {
    header: 'holidayCalendar.labels.fromDate'.getLabel(),
    type: 'date',
    field: 'fromDate',
  },
  {
    header: 'holidayCalendar.labels.toDate'.getLabel(),
    type: 'date',
    field: 'toDate',
  },
  {
    header: 'holidayCalendar.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'holidayCalendar.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },

];

const tableColumnsAfterEditAction = [
  {
    type: 'moreActions',
    width: 50,
    actions: [
      {
        text: 'Export Holidays',
        type: 'download',
        file: 'HolidayCalendar',
        visible: () => {
          const user = getUser();
          const { role: { permissions } } = user;
          if (permissions?.mdm_holiday_management_export_records) return true;
          return false;
        },
        columns: [
          {
            field: 'name',
            header: 'Holiday Type',
          },
          {
            field: 'description',
            header: 'Description',
          },
          {
            field: {
              field: 'date',
              parserFunction: 'formatDateForExcel',
            },
            header: 'Date',
          },
          {
            field: {
              field: 'isActive',
              parserFunction: 'formatBooleanValueForExcel',
            },
            header: 'speciality.labels.status'.getLabel(),
          },
        ],
        confirmModalProps: { autoHide: true, minWidth: 436 },
        confirm: {
          title: 'Export Records!',
          hideCross: false,
          message: 'This action will export all the holidays in this year',
          actions: ({ action }) => [
            { text: 'Cancel' },
            {
              text: 'yes',
              columns: action.columns,
              type: 'onPress',
              allPageSelected: true,
              onPress: async (props) => {
                try {
                  const { navigation, item } = props;
                  const result = await downloadData({
                    service: {
                      id: 'holidayExcelInvoke',
                      paramValue: { item },
                    },
                  });
                  if (result) {
                    Toast.show({
                      message: 'Success',
                      description: '',
                      type: 'success',
                      position: 'top',
                      direction: 'right',
                      duration: 3000,
                    });
                    navigation.pop();
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              },
            },
          ],
        },
      },
    ],
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.HOLIDAYCALENDAR}
    editFormId={ROUTES.editHolidaysCalendar.name}
    tableColumns={tableColumns}
    tableColumnsAfterEditAction={tableColumnsAfterEditAction}
    access_key={permissions.mdm.views.modifyHoliday.permissions.modifyHoliday.value}
    header={{
      leftActions: [(props) => <ToggleFilter {...props} model={MODELS.HOLIDAYCALENDAR} id="masterDataListForTable" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewHolidaysCalendar.name,
        params: { item },
      },
    })}
  />
);
