import React from 'react';

import {
  Text,
  TouchableOpacity,
} from '../../../../../../app-components';
import { Table } from '../../../../../../app-components/table/Table';
import { PharmacyPurchaseEntityRequestListUri } from '../../../../../../Queries/pharmacy';
import { MODELS, purchaseSupplyOrderStatus, ROUTES } from '../../../../../../Lib/constants';
import WithPermission from '../../../../../../Components/WithPermission';
import permissions from '../../../../../../Lib/permissions';
import vars from '../../../../../../theme/vars';

const tableItems = ({ queryId }) => ({
  lg: {
    columns: [
      { width: 14 },
      {
        header: 'inventory.headers.itemName'.getLabel(),
        type: 'text',
        field: 'purchaseRequestPharmacy.item.name',
      },
      {
        header: 'inventory.headers.dateRaised'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
        sortable: '_createdOn',
      },
      {
        header: 'inventory.headers.purchaseRequestNumber'.getLabel(),
        type: 'text',
        field: 'purchaseRequestPharmacy.purchaseRequestNumber',
      },
      {
        header: 'inventory.headers.status'.getLabel(),
        type: 'text',
        field: 'purchaseRequestPharmacy.requestStatus',
        render: ({ item: { status } }) => {
          let purchaseRequestStatus = '';
          let statusColor = '';
          if (status === purchaseSupplyOrderStatus.RAISED) {
            purchaseRequestStatus = 'Raised';
            statusColor = vars.colors.emphasis;
          } else if (status === purchaseSupplyOrderStatus.STOCK_PROVIDED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_PROVIDED;
            statusColor = vars.colors.primary.color2;
          } else if (status === purchaseSupplyOrderStatus.PO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PO_APPROVED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_APPROVED;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.SO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.SO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PAYMENT_PENDING;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PARTIALLY_RECEIVED;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.LOW_SHELF_LIFE_REQUEST_RAISED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.LOW_SHELF_LIFE_REQUEST_RAISED;
            statusColor = vars.colors.warning;
          } else {
            purchaseRequestStatus = purchaseSupplyOrderStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {purchaseRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.viewPurchaseRequest.permissions.viewPurchaseRequest.value
            }
          >
            <TouchableOpacity>
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            </TouchableOpacity>
          </WithPermission>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.viewPurchaseRequest.name,
            params: { item, queryId },
          },
        }),
      },
    ],
  },
});

export default (props) => {
  const { queryId, defaultValues } = props;

  return (
    <Table
      {...props}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      uri={() => PharmacyPurchaseEntityRequestListUri({ queryId })}
      {...tableItems({ queryId })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPurchaseRequest.name,
          params: { item, defaultValues, queryId },
        },
      })}
    />
  );
};
