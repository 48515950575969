import React from 'react';

import { viewVitalSignSheetUri } from '../../../../../../../../../Queries/vitalSign';
import { Form } from '../../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../../Lib/constants';
import { Text } from '../../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../../Components/ListActionModal';
import vars from '../../../../../../../../../theme/vars';

const IpdViewInputOutputVitals = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Blood Sugar"
      description="Daily Blood Sugar of Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          type="standardShadow"
          uri={viewVitalSignSheetUri(filter)}
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Input</Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'vitalSignTime',
                            label: 'Date',
                            editable: false,
                            format: 'DD MM YYYY',
                          },
                          {
                            type: 'text',
                            field: 'nurseName',
                            label: 'Nurse Name',
                            allowWhiteSpaces: true,
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Intravenous Infusion </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'text',
                            field: 'volumeStarted',
                            label: 'Volume Started (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'volumeRemaining',
                            label: 'Volume Remaining (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'volumeInfused',
                            label: 'Volume Infused (ml)',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Oral </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'text',
                        field: 'oralVolume',
                        label: 'Volume (ml)',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'oralType',
                        label: 'Type',
                        editable: false,
                      },
                    ],

                  },

                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Output </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'text',
                            field: 'outputurine',
                            label: 'Urine (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'outputVomit',
                            label: 'Vomit',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'outputDrainage',
                            label: 'Drainage (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'oralAspirate',
                            label: 'Aspirate (ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'oralOthers',
                            label: 'Others (ml)',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />

  );
};

const ViewInputOutputVitals = (props) => (<IpdViewInputOutputVitals {...props} />);
export default ViewInputOutputVitals;
