import React from 'react';

import { Table } from '../../../../../app-components/table/Table';
import { AssetItemMonthlyRemarksUri } from '../../../../../Queries/asset';
import { MODELS } from '../../../../../Lib/constants';

const tabListColumns = [
  { width: 14 },
  {
    type: 'date',
    header: 'auction.headers.date'.getLabel(),
    field: 'remarksDate',
    sortable: 'remarksDate',
  },
  {
    type: 'text',
    header: 'auction.headers.month'.getLabel(),
    field: 'month',
  },
  {
    type: 'date',
    header: 'auction.headers.year'.getLabel(),
    field: 'remarksDate',
    format: 'YYYY',
  },
  {
    type: 'text',
    header: 'auction.headers.remarks'.getLabel(),
    field: 'monthlyRemarks',
    width: 500,
  },
];

const ItemMonthlyRemarksList = (props) => {
  const { tableColumns = tabListColumns, item: { item: { _id } = {} } } = props;

  return (
    <Table
      uri={() => AssetItemMonthlyRemarksUri({ filter: { item: { _id } } })}
      reloadEvent={`reload${MODELS.ASSET_MONTHLY_REMARKS}`}
      lg={{ columns: tableColumns }}
      {...props}
    />
  );
};
export default ItemMonthlyRemarksList;
