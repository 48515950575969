/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React from 'react';
import {
  cancelButton, OutlineButton,
  clearSelection,
  FilterGroup,
  save, selectAll,
  showSelectedCount, StandardButton,
  Toast,
  Text,
} from '../../../app-components';
import { LoadingButton } from '../../../app-components/buttons/Buttons';
import {
  endingRateContractDateFilterOptions,
  MODELS, pharmacyInventoryStockRequestStatus, purchaseSupplyOrderStatus, ROUTES,
} from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { getUser, invoke } from '../../../AppServices';
import { getErrorString } from '../../../Lib/helpers';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import WithPermission from '../../../Components/WithPermission';
import { filterActive, filterInactive } from '../../../images';

import AddItem from './CentralInventory/Items/AddItem';
import EditItem from './CentralInventory/Items/EditItem';
import InventoryItemList from './CentralInventory/Items/ItemList';
import DepartmentInventoryItemList from './DepartmentInventory/InventoryList';
import AddStock from './CentralInventory/Stocks/AddStock';
import RaisePurchaseRequest from './CentralInventory/PurchaseRequest';
import TransferRequestModal from './CentralInventory/Stocks/TransferStock';
import ViewIndentRequest from './Requests/CentralInventory/IndentRequests/ViewIndentRequest';

import PharmacyCentralRequests from './Requests/CentralInventory';
import PharmacyDepartmentRequests from './Requests/DepartmentInventory';
import ViewPurchaseRequest from './Requests/CentralInventory/RaisedPurchasesRequests/ViewPurchasesRequest';
import ViewTransferRequests from './Requests/CentralInventory/OtherRequests/ViewTransferRequestList';

import MSIRequestsTabs from './MSI Requests/RequestTabs';
import MSIViewIndentRequest from './MSI Requests/components/ViewIndentRequest';
import MSIViewTransferRequest from './MSI Requests/components/ViewTransferRequest';
import MSIViewBatchUpdateRequests from './MSI Requests/components/ViewBatchUpdateRequest';
import MSIViewLowShelfLifeRequest from './MSI Requests/components/ViewLowShelfLifeRequest';

import PharmacyCentralRecordsTabs from './Records/CentralRecords';
import PharmacyDepartmentRecordsTabs from './Records/DepartmentRecords';
import ViewIndentRecord from './Records/CentralRecords/DepartmentRecords/IndentRequests/ViewIndentRequest';
import ViewTransferRequestRecord from './Records/CentralRecords/DepartmentRecords/TransferRequests/ViewTransferRequest';
import OPDPatientDispensingRecords from './Records/CentralRecords/PatientDispensing/OPD/ViewPatientDispensing';
import IPDPatientDispensingRecords from './Records/CentralRecords/PatientDispensing/IPD/ViewPatientDispensing';
import ViewCentralInventoryPurchaseRecords from './Records/CentralRecords/CentralPurchaseRecords/ViewCentralPurchaseRecords';
import ViewBatchUpdateRequests from './Requests/CentralInventory/OtherRequests/ViewBatchUpdateRequest';
import ViewLowShelfLifeRequest from './Requests/CentralInventory/OtherRequests/ViewLowShelfLifeRequest';
import LowShelfLifeModal from './CentralInventory/Stocks/LowShelfLifeModal';
import permissions from '../../../Lib/permissions';
import BulkUploadPharmacyModal from '../../../Wired/PharmacyBulkUpload';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

export default {
  [ROUTES.addInventoryItem.name]: {
    screen: AddItem,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.lowShelfLifeModal.name]: {
    screen: LowShelfLifeModal,
  },
  [ROUTES.editInventoryItem.name]: {
    screen: EditItem,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.pharmacyCentralInventory.name]: {
    screen: InventoryItemList,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
      selectableActions: (selectedActionProps) => [
        showSelectedCount(selectedActionProps),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={permissions.inventory.views.exportCentralPharmacyItem
                .permissions.exportCentralPharmacyItem.value}
            >
              <OutlineButton label={'inventory.buttons.exportToExcel'.getLabel()} />
            </WithPermission>
          ),

          file: 'CentralInventoryManagement',
          type: 'download',
          columns: [
            {
              field: 'name',
              header: 'pharmacy.labels.name'.getLabel(),
            },
            {
              field: 'category.name',
              header: 'pharmacy.labels.category'.getLabel(),
            },
            {
              field: 'subCategory.name',
              header: 'pharmacy.labels.subCategory'.getLabel(),
            },
            {
              field: 'description',
              header: 'pharmacy.labels.description'.getLabel(),
            },
            {
              field: 'sellingPrice',
              header: 'Selling Price',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.PHARMACY_ITEM,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (props) => {
              const { screenState: { selectedIds } } = props;
              return `This action will export ${selectedIds?.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'inventory.title.centralInventory'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'category',
              label: 'Category',
              model: MODELS.PHARMACY_CATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Category',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'subCategory',
              label: 'Sub-Category',
              model: MODELS.PHARMACY_SUBCATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Sub-Category',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'rateContract.manufacturer',
              label: 'Manufacturers',
              model: MODELS.PHARMACY_MANUFACTURER,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Manufacturer',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'rateContract.supplier',
              label: 'Suppliers',
              model: MODELS.PHARMACY_SUPPLIER,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Supplier',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggestOptions',
              field: 'rateContract.rateContractValidity',
              label: 'Ending Rate Contract Date',
              options: endingRateContractDateFilterOptions,
              multiSelect: false,
            },
          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <StandardButton label="Bulk Upload" />
        ),
        type: 'link',
        visible: () => {
          const user = getUser();
          if (user?.username === 'superadmin') {
            return true;
          }
          return false;
        },
        link: () => ({
          view: ROUTES.bulkPharmacyUploadModal.name,
          modal: true,
          params: {
            fileName: 'Pharmacy_Template.xlsx',
          },
          modalProps: {
            autoHide: true,
            width: 404,
            height: 550,
          },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.transferPharmacyStocks
              .permissions.transferPharmacyStocks.value}
          >
            <StandardButton label={'inventory.buttons.transferStock'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.transferRequest.name,
          push: true,
          modal: true,
          modalProps: {
            autoHide: true,
            width: 917,
            height: 507,
          },
          params: { selectedIds: props?.state?.selectedIds },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views
              .addPharmacyItem.permissions.addPharmacyItem.value}
          >
            <StandardButton label={'inventory.buttons.addItem'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addInventoryItem.name,
          push: true,
        }),
      },
    ],
  },
  [ROUTES.pharmacyDepartmentInventory.name]: {
    screen: DepartmentInventoryItemList,
    expanded: true,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
    },
    selectableActions: (selectedActionProps) => [
      showSelectedCount(selectedActionProps),
      clearSelection,
      selectAll,
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.exportDepartmentPharmacyItem
              .permissions.exportDepartmentPharmacyItem.value}
          >
            <OutlineButton label={'inventory.buttons.exportToExcel'.getLabel()} />
          </WithPermission>
        ),

        file: 'DepartmentInventoryManagement',
        type: 'download',
        columns: [
          {
            field: 'item.name',
            header: 'Item Name',
          },
          {
            field: 'item.category.name',
            header: 'pharmacy.labels.category'.getLabel(),
          },
          {
            field: 'department.name',
            header: 'pharmacy.labels.department'.getLabel(),
          },
          {
            field: 'stockAvailable',
            header: 'Stock Available',
          },
          {
            field: 'item.subCategory.name',
            header: 'Sub-Category',
          },
          {
            header: 'Reorder Level',
            field: 'item.reorderLevel',
          },
          {
            field: 'item.description',
            header: 'pharmacy.labels.description'.getLabel(),
          },
          {
            field: 'item.sellingPrice',
            header: 'Selling Price',
          },
        ],
        confirmModalProps: { autoHide: true, minWidth: 436 },
        confirm: {
          title: 'Export Records!',
          hideCross: false,
          message: (props) => {
            const { screenState: { selectedIds } } = props;
            return `This action will export ${selectedIds.length} selected records in the excel format.`;
          },
          actions: ({ action }) => [
            { text: 'Cancel' },
            {
              text: 'Export All',
              ...action,
              allPageSelected: true,
            },
            {
              text: 'Yes',
              ...action,
            },
          ],
        },
      },

    ],
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'inventory.title.departmentInventory'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'item.category',
              label: 'Category',
              model: MODELS.PHARMACY_CATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Category',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'item.subCategory',
              label: 'Sub-Category',
              model: MODELS.PHARMACY_SUBCATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Sub-Category',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'item.rateContract',
              label: 'Manufacturers',
              model: MODELS.PHARMACY_MANUFACTURER,
              id: 'masterDataList',
              searchField: 'manufacturer.name',
              placeholder: 'Select Manufacturer',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Departments',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Department',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggestOptions',
              field: 'rateContract.rateContractValidity',
              label: 'Ending Rate Contract Date',
              options: endingRateContractDateFilterOptions,
              multiSelect: false,
            },
          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.raiseIndentRequestPharmacy
              .permissions.raiseIndentRequestPharmacy.value}
          >
            <StandardButton label={'inventory.buttons.raiseIndent'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.raiseIndent.name,
          push: true,
        }),
      },
    ],
  },
  [ROUTES.purchaseRequest.name]: {
    screen: RaisePurchaseRequest,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.transferRequest.name]: {
    screen: TransferRequestModal,
  },
  [ROUTES.bulkPharmacyUploadModal.name]: { screen: BulkUploadPharmacyModal },

  [ROUTES.pharmacyCentralRequests.name]: {
    screen: PharmacyCentralRequests,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'inventory.title.requests'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.pharmacyDepartmentRequests.name]: {
    screen: PharmacyDepartmentRequests,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'inventory.title.requests'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewIndentRequest.name]: {
    screen: ViewIndentRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => {
          const { data = {}, eventDispatcher, navigation } = props;
          if (data?.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
            return (
              <StandardButton
                label={'inventory.buttons.provideStock'.getLabel()}
                onPress={async () => {
                  try {
                    if (data?.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
                      eventDispatcher.notify('appLoader', true);
                      const stockProvideResult = await invoke({
                        id: 'manageDepartmentStocksFromCentralInventoryStocks',
                        paramValue: data,
                      });
                      if (stockProvideResult) {
                        navigation.pop();
                        Toast.show({
                          message: 'Success',
                          description: 'inventory.messages.stockProvidedSuccessfully'.getLabel(),
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                        eventDispatcher.notify([`reload${[MODELS.PURCHASE_REQUEST]}`, `reload${[MODELS.PHARMACY_INDENT]}`]);
                      }
                      eventDispatcher.notify('appLoader', false);
                    } else if (data?.requestStatus === pharmacyInventoryStockRequestStatus.stockProvided) {
                      throw new Error('inventory.messages.stockAlreadyProvided'.getLabel());
                    } else {
                      throw new Error('inventory.messages.indentStatusApproval'.getLabel());
                    }
                  } catch (e) {
                    eventDispatcher.notify('appLoader', false);
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
                {...props}
              />
            );
          }
        },
      },
    ],
  },
  [ROUTES.pharmacyMSIIndentRequests.name]: {
    screen: MSIRequestsTabs,
  },
  [ROUTES.msiViewIndentRequest.name]: {
    screen: MSIViewIndentRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    headerActions: [{
      type: 'save',
      role: 'edit',
      id: 'submit',
      render: (props) => {
        const { data = {} } = props;
        if (data?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
          return (
            <StandardButton
              label={'inventory.buttons.submit'.getLabel()}
              {...props}
            />
          );
        }
      },
    },
    ],
  },
  // [ROUTES.msiViewLowShelfLifeRequest.name]: {
  //   screen: MSIViewLowShelfLifeRequest,
  //   expanded: true,
  //   headerActions: [
  //     {
  //       render: (props) => {
  //         const { navigation: { state: { params: { item } } } } = props;
  //         if (item?.isLowShelfLifeApproved || item?.isLowShelfLifeRejected) {
  //           return null;
  //         }
  //         return (
  //           <LoadingButton
  //             label={'inventory.buttons.reject'.getLabel()}
  //             color={vars.colors.error}
  //             hoverColor={vars.colors.error}
  //             onPress={async () => {
  //               try {
  //                 const { navigation, eventDispatcher } = props;
  //                 // eslint-disable-next-line no-shadow
  //                 const { navigation: { state: { params: { item } } } } = props;
  //                 if (item.isLowShelfLifeRejected) {
  //                   throw new Error('inventory.messages.lowShelfLifeRequestAlreadyRejected'.getLabel());
  //                 } else if (item.isLowShelfLifeApproved) {
  //                   throw new Error('inventory.messages.lowShelfLifeRequestAlreadyApproved'.getLabel());
  //                 }
  //                 const result = await invoke({
  //                   id: 'handleLowShelfLifeRequestStatus',
  //                   paramValue: {
  //                     ...item,
  //                     isLowShelfLifeRejected: true,
  //                   },
  //                 });
  //                 if (result) {
  //                   eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH}`);
  //                   navigation.pop();
  //                   Toast.show({
  //                     message: 'Success',
  //                     description: 'inventory.messages.lowShelfLifeRequestReject'.getLabel(),
  //                     type: 'success',
  //                     position: 'top',
  //                     direction: 'right',
  //                     duration: 3000,
  //                   });
  //                 }
  //               } catch (e) {
  //                 Toast.show({
  //                   message: 'Error',
  //                   description: `${getErrorString(e)}`,
  //                   type: 'error',
  //                   position: 'top',
  //                   direction: 'right',
  //                 });
  //               }
  //             }}
  //           />
  //         );
  //       },
  //     },
  //     {
  //       render: (props) => {
  //         const { navigation: { state: { params: { item } } } } = props;
  //         if (item?.isLowShelfLifeApproved || item?.isLowShelfLifeRejected) {
  //           return null;
  //         }
  //         return (
  //           <LoadingButton
  //             label={'inventory.buttons.approve'.getLabel()}
  //             color={vars.colors.primary.color2}
  //             hoverColor={vars.colors.primary.color2}
  //             onPress={async () => {
  //               try {
  //                 const { navigation, eventDispatcher } = props;
  //                 // eslint-disable-next-line no-shadow
  //                 const { navigation: { state: { params: { item } } } } = props;
  //                 if (item.isLowShelfLifeApproved) {
  //                   throw new Error('inventory.messages.lowShelfLifeRequestAlreadyApproved'.getLabel());
  //                 } else if (item.isLowShelfLifeRejected) {
  //                   throw new Error('inventory.messages.lowShelfLifeRequestAlreadyRejected'.getLabel());
  //                 }
  //                 const result = await invoke({
  //                   id: 'handleLowShelfLifeRequestStatus',
  //                   paramValue: {
  //                     ...item,
  //                     isLowShelfLifeApproved: true,
  //                   },
  //                 });
  //                 if (result) {
  //                   eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH}`);
  //                   navigation.pop();
  //                   Toast.show({
  //                     message: 'Success',
  //                     description: 'inventory.messages.lowShelfLifeRequestApprove'.getLabel(),
  //                     type: 'success',
  //                     position: 'top',
  //                     direction: 'right',
  //                     duration: 3000,
  //                   });
  //                 }
  //               } catch (e) {
  //                 Toast.show({
  //                   message: 'Error',
  //                   description: `${getErrorString(e)}`,
  //                   type: 'error',
  //                   position: 'top',
  //                   direction: 'right',
  //                 });
  //               }
  //             }}
  //           />
  //         );
  //       },
  //     },
  //   ],
  // },
  [ROUTES.msiviewTransferRequest.name]: {
    screen: MSIViewTransferRequest,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { navigation: { state: { params: { item } } } } = props;
          if (item?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.reject'.getLabel()}
                color={vars.colors.error}
                hoverColor={vars.colors.error}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (item.requestStatus === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.transferRequestAlreadyRejected'.getLabel());
                    } else if (item.requestStatus
                       === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.transferRequestAlreadyApproved'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleTransferRequestStatusChange',
                      paramValue: {
                        ...item,
                        requestStatus: pharmacyInventoryStockRequestStatus.rejected,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_TRANSFER_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.transferRequestReject'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
      {
        render: (props) => {
          const { navigation: { state: { params: { item } } } } = props;
          if (item?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.approve'.getLabel()}
                color={vars.colors.primary.color2}
                hoverColor={vars.colors.primary.color2}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (item.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.transferRequestAlreadyApproved'.getLabel());
                    } else if (item.requestStatus
                      === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.transferRequestAlreadyRejected'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleTransferRequestStatusChange',
                      paramValue: {
                        ...item,
                        requestStatus: pharmacyInventoryStockRequestStatus.approved,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_TRANSFER_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.transferRequestApprove'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
    ],
  },
  // [ROUTES.msiViewBatchUpdateRequest.name]: {
  //   screen: MSIViewBatchUpdateRequests,
  //   expanded: true,
  //   headerActions: [
  //     {
  //       render: (props) => {
  //         const { navigation: { state: { params: { item } } } } = props;
  //         if (item?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
  //           return (
  //             <LoadingButton
  //               label={'inventory.buttons.reject'.getLabel()}
  //               color={vars.colors.error}
  //               hoverColor={vars.colors.error}
  //               onPress={async () => {
  //                 try {
  //                   const { navigation, eventDispatcher } = props;
  //                   if (item.requestStatus === pharmacyInventoryStockRequestStatus.rejected) {
  //                     throw new Error('inventory.messages.batchUpdateRequestAlreadyRejected'.getLabel());
  //                   } else if (item.requestStatus
  //                     === pharmacyInventoryStockRequestStatus.approved) {
  //                     throw new Error('inventory.messages.batchUpdateRequestAlreadyApproved'.getLabel());
  //                   }
  //                   const result = await invoke({
  //                     id: 'handleBatchRequestStatusChange',
  //                     paramValue: {
  //                       ...item,
  //                       requestStatus: pharmacyInventoryStockRequestStatus.rejected,
  //                     },
  //                   });
  //                   if (result) {
  //                     eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH_REQUESTS}`);
  //                     navigation.pop();
  //                     Toast.show({
  //                       message: 'Success',
  //                       description: 'inventory.messages.batchUpdateRequestReject'.getLabel(),
  //                       type: 'success',
  //                       position: 'top',
  //                       direction: 'right',
  //                       duration: 3000,
  //                     });
  //                   }
  //                 } catch (e) {
  //                   Toast.show({
  //                     message: 'Error',
  //                     description: `${getErrorString(e)}`,
  //                     type: 'error',
  //                     position: 'top',
  //                     direction: 'right',
  //                   });
  //                 }
  //               }}
  //             />
  //           );
  //         }
  //       },
  //     },
  //     {
  //       render: (props) => {
  //         const { navigation: { state: { params: { item } } } } = props;
  //         if (item?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
  //           return (
  //             <LoadingButton
  //               label={'inventory.buttons.approve'.getLabel()}
  //               color={vars.colors.primary.color2}
  //               hoverColor={vars.colors.primary.color2}
  //               onPress={async () => {
  //                 try {
  //                   const { navigation, eventDispatcher } = props;
  //                   if (item.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
  //                     throw new Error('inventory.messages.batchUpdateRequestAlreadyApproved'.getLabel());
  //                   } else if (item.requestStatus
  //                      === pharmacyInventoryStockRequestStatus.rejected) {
  //                     throw new Error('inventory.messages.batchUpdateRequestAlreadyRejected'.getLabel());
  //                   }
  //                   const result = await invoke({
  //                     id: 'handleBatchRequestStatusChange',
  //                     paramValue: {
  //                       ...item,
  //                       requestStatus: pharmacyInventoryStockRequestStatus.approved,
  //                     },
  //                   });
  //                   if (result) {
  //                     eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH_REQUESTS}`);
  //                     navigation.pop();
  //                     Toast.show({
  //                       message: 'Success',
  //                       description: 'inventory.messages.batchUpdateRequestApprove'.getLabel(),
  //                       type: 'success',
  //                       position: 'top',
  //                       direction: 'right',
  //                       duration: 3000,
  //                     });
  //                   }
  //                 } catch (e) {
  //                   Toast.show({
  //                     message: 'Error',
  //                     description: `${getErrorString(e)}`,
  //                     type: 'error',
  //                     position: 'top',
  //                     direction: 'right',
  //                   });
  //                 }
  //               }}
  //             />
  //           );
  //         }
  //       },
  //     },
  //   ],
  // },
  [ROUTES.viewLowShelfLifeRequest.name]: {
    screen: ViewLowShelfLifeRequest,
    expanded: true,
  },
  [ROUTES.viewTransferRequest.name]: {
    screen: ViewTransferRequests,
    expanded: true,
  },
  [ROUTES.viewBatchUpdateRequest.name]: {
    screen: ViewBatchUpdateRequests,
    expanded: true,
  },
  [ROUTES.pharmacyDepartmentRecords.name]: {
    screen: PharmacyDepartmentRecordsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'inventory.title.departmentRecords'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.pharmacyCentralRecords.name]: {
    screen: PharmacyCentralRecordsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'inventory.title.centralRecords'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewIndentRecord.name]: {
    screen: ViewIndentRecord,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewTransferRequestRecord.name]: {
    screen: ViewTransferRequestRecord,
    expanded: true,
  },
  [ROUTES.viewOPDPatientDispensingRecords.name]: {
    screen: OPDPatientDispensingRecords,
    expanded: true,
  },
  [ROUTES.viewIPDPatientDispensingRecords.name]: {
    screen: IPDPatientDispensingRecords,
    expanded: true,
  },
  [ROUTES.viewCentralInventoryPurchaseRecords.name]: {
    screen: ViewCentralInventoryPurchaseRecords,
    title: (props) => {
      const {
        navigation: {
          state: {
            params: {
              item: {
                purchaseRequestPharmacy: { purchaseRequestNumber },
              } = {},
            } = {},
          } = {},
        } = {},
      } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
  },
};
