import React from 'react';

import { save, Toast } from '../../../../../../../../app-components';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { dateFormatX } from '../../../../../../constants';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import { viewVitalSignSheetUri } from '../../../../../../../../Queries/vitalSign';

const IpdEditVitalsPediatrics = (props) => {
  const {
    navigation: { state: { params: { data: paramsData } = {} } = {} } = {},
  } = props;
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;

  return (
    <ListActionModal
      {...props}
      title="Vital Signs"
      description="Edit today's Vitals of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          uri={viewVitalSignSheetUri(filter)}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [save],
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.vitalSignTime = dateFormatX(
              data?.hour,
              data?.minute,
              data?.format,
            );
            delete updates.hour;
            delete updates.minute;
            delete updates.format;
            const newUpdate = {
              ...updates,
              ...paramsData,
            };

            const submitAPI = submit({
              model: MODELS.IPD_VITALS_SIGN_SHEET,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Vital has been Edited successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          mandatory={{
            hour: 1,
            format: 1,
            minute: 1,
            vitalSignTemperature: 1,
            color: 1,
            vitalSignPulse: 1,
            vitalSignHr: 1,
            vitalSignO2: 1,
            vitalSignFIO2: 1,
            oxygenSaturation: 1,
            bloodSugar: 1,
            urine: 1,
            stool: 1,
            abdominalGirth: 1,
            rtAspirate: 1,
            bloodCollection: 1,
            vitalSignResp: 1,
            vitalSignBP: 1,
          }}
          defaultValues={{
            format: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
          }}
          type="standardShadow"
          lg={{
            validations: {
              vitalSignBP: ({ data }) => validateInputLength(data?.vitalSignBP, 1, 4),
              vitalSignTemperature: ({ data }) => validateInputLength(data?.vitalSignTemperature, 1, 4),
              vitalSignPulse: ({ data }) => validateInputLength(data?.vitalSignPulse, 1, 4),
              color: ({ data }) => validateInputLength(data?.color, 1, 20),
              vitalSignHr: ({ data }) => validateInputLength(data?.vitalSignHr, 1, 4),
              vitalSignO2: ({ data }) => validateInputLength(data?.vitalSignO2, 1, 4),
              vitalSignFIO2: ({ data }) => validateInputLength(data?.vitalSignFIO2, 1, 4),
              oxygenSaturation: ({ data }) => validateInputLength(data?.oxygenSaturation, 1, 4),
              vitalSignResp: ({ data }) => validateInputLength(data?.vitalSignResp, 1, 4),
              nurseNote: ({ data }) => validateInputLength(data?.nurseNote, 0, 200),
              bloodSugar: ({ data }) => validateInputLength(data?.bloodSugar, 1, 4),
              urine: ({ data }) => validateInputLength(data?.urine, 1, 20),
              stool: ({ data }) => validateInputLength(data?.stool, 1, 20),
              abdominalGirth: ({ data }) => validateInputLength(data?.abdominalGirth, 1, 20),
              rtAspirate: ({ data }) => validateInputLength(data?.rtAspirate, 1, 4),
              bloodCollection: ({ data }) => validateInputLength(data?.bloodCollection, 1, 20),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          newDateInput({
                            hour: { field: 'hour', label: 'Hour' },
                            minute: { field: 'minute', label: 'Minute' },
                            meridiem: { field: 'format' },
                          }),
                          {
                            type: 'number',
                            field: 'vitalSignBP',
                            label: 'BP',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'crt',
                            label: 'CRT',
                            editable: true,
                          },
                          {
                            type: 'radioGroup',
                            field: 'activity',
                            container: 'topLabel',
                            label: 'Activity',
                            options: ['Dull', 'Active'],
                          },
                          {
                            type: 'text',
                            field: 'color',
                            label: 'Color',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignTemperature',
                            label: 'TEMPERATURE',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignPulse',
                            label: 'PULSE',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignHr',
                            label: 'HR',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignResp',
                            label: 'RESP.',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignO2',
                            label: 'O2 Flow Rate',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignFIO2',
                            label: 'FIO2',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'oxygenSaturation',
                            label: 'Oxygen Saturation',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'bloodSugar',
                            label: 'blood Sugar',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'urine',
                            label: 'Urine',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'stool',
                            label: 'Stool',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'abdominalGirth',
                            label: 'Abdominal Girth',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'radioGroup',
                            field: 'patency',
                            container: 'topLabel',
                            label: 'Patency',
                            options: ['Yes', 'No'],
                          },
                          {
                            type: 'number',
                            field: 'rtAspirate',
                            label: 'R.T Aspirate',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'bloodCollection',
                            label: 'blood Collection',
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'textArea',
                            field: 'nurseNote',
                            label: 'Nurse Note',
                            placeHolder: 'Type Here....',
                            variant: 'filled',
                            allowWhiteSpaces: true,
                            editable: true,
                            minHeight: 90,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const EditVitalsPediatrics = (props) => <IpdEditVitalsPediatrics {...props} />;
export default EditVitalsPediatrics;
