import React from 'react';

import {
  Table,
  Text,
} from '../../../../../../app-components';
import { MODELS, ROUTES, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { AssetPurchaseEntityRequestUri } from '../../../../../../Queries/asset';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.date'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.assetName'.getLabel(),
        type: 'text',
        field: 'purchaseRequestAsset.item.name',
      },
      {
        header: 'asset.headers.category'.getLabel(),
        type: 'text',
        field: 'purchaseRequestAsset.item.category.name',
      },
      {
        header: 'asset.headers.subCategory'.getLabel(),
        type: 'text',
        field: 'purchaseRequestAsset.item.subCategory.name',
      },
      {
        header: 'asset.headers.description'.getLabel(),
        type: 'text',
        field: 'purchaseRequestAsset.reasonForRepair',
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { status } }) => {
          let purchaseRequestStatus = '';
          let statusColor = '';
          if (status === purchaseSupplyOrderStatus.RAISED) {
            purchaseRequestStatus = 'Raised';
            statusColor = vars.colors.emphasis;
          } else if (status === purchaseSupplyOrderStatus.STOCK_PROVIDED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_PROVIDED;
            statusColor = vars.colors.primary.color2;
          } else if (status === purchaseSupplyOrderStatus.PO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PO_APPROVED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PO_APPROVED;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.SO_IN_APPROVAL) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.SO_IN_APPROVAL;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PAYMENT_PENDING;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.PARTIALLY_RECEIVED;
            statusColor = vars.colors.warning;
          } else if (status === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING) {
            purchaseRequestStatus = purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING;
            statusColor = vars.colors.warning;
          } else {
            purchaseRequestStatus = purchaseSupplyOrderStatus.REJECTED;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {purchaseRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewDepartmentRepairRequest.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.PURCHASE_REQUEST}`,
  uri: AssetPurchaseEntityRequestUri({ filter: { type: 'asset', subType: 'repair' } }),
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewDepartmentRepairRequest.name,
      params: { item },
    },
  }),
});
