import React from 'react';
import { size } from 'lodash';
import { Text } from '../../../../../../../app-components';
import { searchInput } from '../../../../../../../autoSuggestions';
import vars from '../../../../../../../theme/vars';

export const formFields = [
  {
    type: 'date',
    label: 'inventory.labels.dateOfDispensing'.getLabel(),
    format: 'DD MMM YYYY',
    field: 'patientDispenses._createdOn',
  },
  {
    type: 'text',
    label: 'inventory.labels.patientUHID'.getLabel(),
    field: 'patientDispenses.patientId.uhid',
  },
  {
    type: 'text',
    label: 'inventory.labels.patientName'.getLabel(),
    field: 'patientDispenses.patientId.patientName',
  },
  {
    type: 'text',
    label: 'inventory.labels.noOfItems'.getLabel(),
    render: ({
      navigation: {
        state: {
          params:
      { item = {} } = {},
        } = {},
      } = {},
    }) => (
      <Text style={{ ...vars.headings.h8 }}>
        {size(item.pharmacyDetails)}
      </Text>
    ),
  },
  {
    type: 'text',
    label: 'inventory.labels.dispensedBy'.getLabel(),
    field: 'patientDispenses.dispensedBy.name',
  },

];
export const nestedTableFields = [
  {
    type: 'text',
    header: 'inventory.labels.itemId'.getLabel(),
    label: 'inventory.labels.itemId'.getLabel(),
    variant: 'filled',
    field: 'item.itemCode',
    editable: false,
    width: 80,
  },
  {
    type: 'text',
    header: 'inventory.labels.itemName'.getLabel(),
    label: 'inventory.labels.itemName'.getLabel(),
    variant: 'filled',
    field: 'item.name',
    editable: false,
    width: 120,
  },
  searchInput({
    field: 'item.category',
    variant: 'filled',
    header: 'inventory.labels.category'.getLabel(),
    label: 'inventory.labels.category'.getLabel(),
    placeholder: 'inventory.placeholders.supplier'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    editable: false,
    width: 120,
  }),
  {
    type: 'text',
    header: 'inventory.labels.manufacturer'.getLabel(),
    label: 'inventory.labels.manufacturer'.getLabel(),
    variant: 'filled',
    field: 'item.rateContract.manufacturer[0].name',
    editable: false,
    width: 120,
    render: (({
      item: { item: { rateContract = [] } = {} } = {},
    }) => <Text style={{ ...vars.headings.h9, color: '#4F4F4F' }}>{rateContract[0]?.manufacturer?.name}</Text>),
  },
  {
    type: 'number',
    header: 'inventory.labels.quantityDispensed'.getLabel(),
    label: 'inventory.labels.quantityDispensed'.getLabel(),
    variant: 'filled',
    field: 'quantity',
    editable: false,
    width: 180,
  },
  {
    type: 'number',
    header: 'inventory.labels.unitPrice'.getLabel(),
    label: 'inventory.labels.unitPrice'.getLabel(),
    variant: 'filled',
    field: 'item.sellingPrice',
    editable: false,
    width: 120,
  },
  {
    type: 'number',
    header: 'inventory.labels.updatedStock'.getLabel(),
    label: 'inventory.labels.updatedStock'.getLabel(),
    variant: 'filled',
    field: 'updatedStock',
    editable: false,
    width: 120,
  },
  {
    type: 'number',
    header: 'inventory.labels.totalPrice'.getLabel(),
    label: 'inventory.labels.totalPrice'.getLabel(),
    variant: 'filled',
    field: 'totalPrice',
    editable: false,
    width: 200,
  },
];

