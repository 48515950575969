import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from 'react-native';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import { Form } from '../../../../../app-components/form/Form';
import { EditCategory } from '../../../../../Queries/category';
import { View } from '../../../../../app-components';
import ViewPurchaseCategoryTabs from './components/Tabs';

const ViewCategoryForm = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 2 }}>
      <Form
        {...props}
        uri={EditCategory}
        editable={false}
        type="standard"
        closeView={1}
        reloadEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
        reloadOnChangeEvent={`reload${MODELS.PHARMACY_CATEGORY}`}
        lg={{
          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'text',
                      field: 'name',
                      label: 'purchase.labels.category'.getLabel(),
                      mandatory: true,
                    },
                    {
                      type: 'textArea',
                      field: 'description',
                      label: 'purchase.labels.description'.getLabel(),
                      minHeight: 100,
                    },
                    {
                      type: 'text',
                      render: ({ data: { isContractual } }) => (<Text style={{ ...vars.headings.h8 }}>{isContractual ? 'Yes' : 'No'}</Text>),
                      label: 'purchase.labels.contractual'.getLabel(),
                    },
                    {
                      type: 'status',
                      field: 'isActive',
                      label: 'purchase.labels.status'.getLabel(),
                      container: 'topLabel',
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    </View>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <ViewPurchaseCategoryTabs {...props} />
    </View>
  </View>
);

export default ViewCategoryForm;
