import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { MiscellaneousUri } from '../../../../../../../Queries/miscellaneous';
import {
  Text, StandardButton, TouchableOpacity, Image,
} from '../../../../../../../app-components';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';
import { getPermission } from '../../../../../../../AppServices';
import permissions from '../../../../../../../Lib/permissions';
import { editRecord } from '../../../../../../../images';

const tabListColumns = () => ([
  {
    header: 'SERIAL NUMBER',
    type: 'text',
    containerStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    value: (data) => data.index + 1,
    width: 100,
  },
  {
    header: 'SERVICE',
    type: 'text',
    field: 'service',
    width: '20%',
  },
  {
    header: 'UNIT',
    type: 'number',
    field: 'unit',
    width: '20%',
  },
  {
    header: 'RATE',
    type: 'number',
    field: 'rate',
    width: '20%',
  },
  {
    header: 'AMOUNT',
    type: 'number',
    field: 'amount',
    width: '20%',
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>View</Text>
      ),
      type: 'link',
      link: ({ item }) => ({
        view: ROUTES.viewService.name,
        params: { item },
      }),
    },
    ],
  },
  {
    header: 'Edit'.getLabel(),
    type: 'text',
    visible: () => getPermission(
      permissions.departments.views.ipdEditForms
        .permissions.ipdEditForms.value,
    ),
    render: () => (
      <TouchableOpacity>
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </TouchableOpacity>
    ),
    action: (data) => ({
      type: 'link',
      // visible: () => getPermission(
      //   permissions.departments.views.ipdEditForms
      //     .permissions.ipdEditForms.value,
      // ),
      link: {
        modal: true,
        view: ROUTES.editService.name,
        params: {
          item: data.item,
        },

      },
    }),
  },
]);

const Miscellaneous = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;

  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="Misc."
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Add Service"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.addService.name,
              params: { ...params },
              push: true,
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.MISCELLANEOUS_ACCOUNT}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={() => MiscellaneousUri({ admissionId: { _id } })}
    />
  );
};

export default Miscellaneous;
