import React from 'react';

import {
  FormField,
  LargeButton,
  Text,
  View,
} from '../../../../../app-components';
import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import { technicalIcon } from '../../../../../images';
import vars from '../../../../../theme/vars';

const EditTechnicalModal = (props) => {
  const { navigation } = props;
  const { state: { params } = {} } = navigation;
  const { item, serialNo } = params;
  const { technicalDetails } = item;

  return (
    <ListActionModal
      icon={technicalIcon}
      {...props}
      title={'asset.title.technicalSpecification'.getLabel()}
      description="Add a title and details for technical specification "
      content={(
        <Form
          onSubmit={(submitProps) => {
            const { data } = submitProps;
            const setValue = navigation.getParam('setValue');
            const oldData = navigation.getParam('item') || [];
            const updatedTechnicalDetails = [];
            oldData.technicalDetails.map((need, index) => {
              if (index === serialNo - 1) updatedTechnicalDetails.push({ ...data });
              else updatedTechnicalDetails.push({ ...need });
              return {};
            });
            setValue({ data: oldData, value: updatedTechnicalDetails, field: 'technicalDetails' });
            navigation.pop();
          }}
          mandatory={{
            technicalTitle: 1,
            technicalListDetails: {
              description: 1,
            },
          }}
          defaultValues={{ ...technicalDetails[serialNo - 1] }}
          closeView={1}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
        >
          {
          ({ form_context: formContext = {} }) => {
            const { handleSubmit } = formContext;

            return (
              <View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FormField
                    type="text"
                    label={'asset.labels.title'.getLabel()}
                    field="technicalTitle"
                    variant="filled"
                    mandatory
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 20,
                    marginLeft: 10,
                  }}
                >
                  <Text style={{ ...vars.headings.h8, color: vars.colors.grey.color2 }}>
                    {'asset.title.listDetails'.getLabel()}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 20,
                  }}
                >
                  <FormField
                    {...{
                      field: 'technicalListDetails',
                      fieldType: 'nested',
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        skipTableHeaderOnNoData: true,
                        header: ({ addRow }) => ({
                          leftActions: [{
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                              >
                                <Text
                                  style={{
                                    ...vars.headings.h9,
                                    color: vars.colors.secondary.color2,
                                  }}
                                >
                                  {`+ ${'asset.buttons.addNewRow'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: addRow,
                          }],
                        }),
                        listProps: {
                          hideColumnHeader: true,
                          headerRowContainerStyle: {
                            minHeight: 32,
                            backgroundColor: vars.colors.white,
                          },
                          headerRowColumnTextStyle: {
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                            numberOfLines: 1,
                            textTransform: 'upperCase',
                          },
                          columns: [
                            {
                              type: 'textArea',
                              label: 'asset.labels.description'.getLabel(),
                              header: 'asset.headers.description'.getLabel(),
                              variant: 'filled',
                              field: 'description',
                              mandatory: true,
                            },
                            NestedAction(),
                          ],
                        },
                      },
                    }
                    }
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LargeButton
                    label="Save"
                    onClick={handleSubmit}
                    width={109}
                    height={40}
                    borderRadius={4}
                  />
                </View>
              </View>
            );
          }
        }
        </Form>
      )}
    />
  );
};

export default EditTechnicalModal;
