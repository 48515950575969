import { TabNavigator } from '../../../../../../app-components';
import { MODELS, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import { countUri } from '../../../../../../Queries/countUri';
import TransferRequestList from './TransferRequestList';
import tabTheme from '../../../Themes/nestedTabTheme';
import Repair from './Repair';

const OtherRecordsTabs = TabNavigator({
  theme: { ...tabTheme },
  tabs: {
    Repair: {
      label: 'Repair',
      screen: Repair,
      countUri: countUri({
        model: MODELS.ASSET_PURCHASE,
        id: 'assetInventoryRequestDataList',
        filter: {
          type: 'repair',
          requestStatus: {
            $in:
            [purchaseSupplyOrderStatus.PAYMENT_DONE],
          },
        },
      }),
    },
    transfer: {
      label: 'Transfer',
      screen: TransferRequestList,
      countUri: countUri({
        model: MODELS.ASSET_TRANSFER_REQUESTS,
        id: 'assetTransferRequestsDataList',
        filter: {
          requestStatus: {
            $in:
           [purchaseSupplyOrderStatus.APPROVED],
          },
        },
      }),
    },
  },
});
export default OtherRecordsTabs;
