import { size } from 'lodash';
import React from 'react';

import {
  Text, TouchableOpacity, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import FileLink from '../../../../Components/FileLink';
import { ROUTES } from '../../../../Lib/constants';
import { getString } from '../../../../Lib/helpers';
import { consultationDetailUri } from '../../../../Queries/consultations';
import vars from '../../../../theme/vars';

import ViewConsultedPatientHistoryTabs from './components/tabs';

const ViewConsultedPatientHistory = (props) => {
  const { navigation: { state: { params: { item = {} } = {} } = {} } = {} } = props;
  const { navigation } = props;

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Form
          {...props}
          type="standard"
          editable={false}
          uri={consultationDetailUri(item?._id)}
          lg={{
            formGroups: [
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'date',
                        field: '_createdOn',
                        label: 'consultation.labels.consultationDate'.getLabel(),
                      },
                      {
                        type: 'text',
                        field: 'patient.uhid',
                        label: 'consultation.labels.uhid'.getLabel(),
                      },
                      {
                        type: 'text',
                        field: 'patient.patientName',
                        label: 'consultation.labels.patientName'.getLabel(),
                      },
                      {
                        type: 'consultationStatus',
                        field: 'status',
                        label: 'consultation.labels.status'.getLabel(),
                      },
                      {
                        type: 'text',
                        label: 'consultation.labels.assistantOnDuty'.getLabel(),
                        render: () => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {getString([item?.assignedBy?.firstName,
                               item?.assignedBy?.lastName]) || '--'}
                          </Text>
                        ),
                      },
                      {
                        type: 'number',
                        field: 'sessionQueue.room.name',
                        label: 'consultation.labels.roomNo'.getLabel(),
                      },
                      {
                        type: 'number',
                        field: 'ODPNumber',
                        label: 'consultation.labels.opdNumber'.getLabel(),
                      },
                      {
                        type: 'text',
                        label: 'consultation.labels.doctorName'.getLabel(),
                        render: () => (
                          <Text style={{ ...vars.headings.h8 }}>
                            {getString([item?.sessionQueue?.doctor?.firstName,
                               item?.sessionQueue?.doctor?.lastName]) || '--'}
                          </Text>
                        ),
                      },
                      {
                        type: 'text',
                        field: 'speciality.name',
                        label: 'consultation.labels.speciality'.getLabel(),
                      },
                      {
                        type: 'file',
                        field: 'prescription',
                        render: ({ data }) => {
                          const { prescription } = data;
                          if (prescription) {
                            return (
                              <FileLink style={{ ...vars.headings.h9 }} file={prescription} />
                            );
                          }
                          return <View />;
                        },
                        label: 'consultation.labels.uploadedPrescription'.getLabel(),
                      },
                      {
                        type: 'text',
                        label: 'consultation.labels.prescription'.getLabel(),
                        render: ({ data }) => (size(data?.pharmacyDetails) ? (
                          <TouchableOpacity
                            onPress={() => {
                              navigation.push({
                                view: ROUTES.opdIncomingPatientsViewDiagnosis.name,
                                params: {
                                  consultationId: item?._id,
                                },
                              });
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Text style={{ color: vars.colors.secondary.color2 }}>
                              View prescription
                            </Text>
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity>
                            <Text style={{ color: vars.colors.grey.color3 }}>
                              View prescription
                            </Text>
                          </TouchableOpacity>
                        )),
                      },
                      {
                        type: 'text',
                        field: 'remarks',
                        label: 'consultation.labels.remarks'.getLabel(),
                      },
                      {
                        type: 'text',
                        field: 'diagnosis',
                        label: 'consultation.labels.diagnose'.getLabel(),
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
        {/* <ViewConsultedPatientHistoryForm {...props} /> */}
      </View>
      <View style={{ flex: 2, overflow: 'hidden' }}>
        <ViewConsultedPatientHistoryTabs {...props} />
      </View>
    </View>
  );
};

export default ViewConsultedPatientHistory;
