import React, { useEffect, useState } from 'react';

import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { DateFilter } from '../../../app-components';
import { ROUTES } from '../../../Lib/constants';
import TrackReport from './TrackReport';

const CustomDateFilter = (props) => {
  useEffect(() => {
    const { navigation } = props || {};
    const today = new Date();
    const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 1);

    const dataParams = {
      filters: {
        customFilter: {
          filter: {
            value: {
              filterOf: 'Day',
              from: new Date(yesterday.setHours(0, 0, 0, 0)),
              to: today,
            },
          },
          value: {
            filterOf: 'Day',
            from: new Date(yesterday.setHours(0, 0, 0, 0)),
            to: today,
          },
        },
      },
    };
    navigation.setScreenState({
      dataParams,
    });
  }, []);

  return (
    <DateFilter
      {...props}
      field="customFilter"
      key="customFilter"
      dateSelectorData={[
        { label: 'Today', value: 'Day' },
        { label: 'Week', value: 'Week' },
        { label: 'Month', value: 'Month' },
        { label: 'Year', value: 'Year' },
      ]}
    />
  );
};

const CustomMonthFilter = (props) => {
  useEffect(() => {
    const { navigation } = props || {};
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const dataParams = {
      filters: {
        customFilter: {
          filter: {
            value: {
              filterOf: 'Month',
              from: yesterday,
              to: today,
            },
          },
          value: {
            filterOf: 'Month',
            from: yesterday,
            to: today,
          },
        },
      },
    };

    navigation.setScreenState({
      dataParams,
    });
  }, []);

  return (
    <DateFilter
      {...props}
      field="customFilter"
      key="customFilter"
      dateSelectorData={[
        { label: 'Month', value: 'Month' },
      ]}
    />
  );
};

const HeaderDateFilter = (props) => {
  const [selectedTab, setSelectedTab] = useState('summary');
  useEffect(() => {
    const { eventDispatcher } = props;
    function updateSelectedTab(value) {
      setSelectedTab(value);
    }
    eventDispatcher.listen('selectedTabFilter', updateSelectedTab);
    return () => eventDispatcher && eventDispatcher.unlisten('selectedTabFilter', updateSelectedTab);
  }, [props]);

  if (selectedTab === 'patients') {
    return <CustomMonthFilter {...props} />;
  }
  return <CustomDateFilter {...props} />;
};

const DatesFilter = (props) => <HeaderDateFilter {...props} />;

export default {
  [ROUTES.opdReports.name]: {
    screen: TrackReport,
    headerActions: [
      {
        render: () => '',
      },
    ],
    header: {
      title: (
        <HeaderTitleWithCount label="Reports" />
      ),
      actions: [DatesFilter,
      ],
    },
  },
};
