import React from 'react';

import { cancelButton, save } from '../../../../../app-components';
import { validatePanCard } from '../../../../../Lib/helpers';
import { MODELS } from '../../../../../Lib/constants';
import { submit } from '../../../../../AppServices';
import { EditEmployee as EditEmployeeQuery } from '../../../../../Queries/employee';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { Form } from '../../../../../app-components/form/Form';

const EditEmployee = (props) => {
  const { visible = true, fromView = false } = props;
  const { navigation: { state: { params: { item: { epfNumber = '', pranNumber = '' } } } } } = props;

  return (
    <Form
      type="standardShadow"
      uri={EditEmployeeQuery}
      closeView={1}
      onSubmit={submit({
        model: MODELS.EMPLOYEE,
      })}
      reloadEvent={`reload${MODELS.EMPLOYEE}`}
      reloadOnChangeEvent={`reload${MODELS.EMPLOYEE}`}
      submitMessage={'employee.messages.updateMessage'.getLabel()}
      uniqueValidationMessage={'employee.messages.uniqueError'.getLabel()}
      mandatory={{
        panNumber: 1,
        ecrNumber: 1,
        ecrPageNumber: 1,
        dcrNumber: 1,
      }}
      computations={{
        self: {
          epfNumber: {
            compute: (data) => {
              if (!data?.isEPF) return { set: { epfNumber: null } };
              return { set: { epfNumber } };
            },
            onChange: ['isEPF'],
          },
          pranNumber: {
            compute: (data) => {
              if (!data?.isNPS) return { set: { pranNumber: null } };
              return { set: { pranNumber } };
            },
            onChange: ['isNPS'],

          },
        },
      }}
      lg={{
        validations: {
          panNumber: ({ data }) => validatePanCard(data?.panNumber),
        },
        header: visible ? {
          actions: [cancelButton, save({ label: 'employee.buttons.update'.getLabel() })],
        } : null,
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'accountNumber',
                    label: 'employee.labels.account'.getLabel(),
                  },
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'bank',
                    label: 'employee.labels.bank'.getLabel(),
                    placeholder: 'employee.placeholders.bank'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.BANKS,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data }) => {
                      const bankId = data?.bank?._id;
                      if (bankId) {
                        return {
                          _id: { $ne: bankId },
                        };
                      }
                      return {};
                    },
                  }),
                  {
                    type: 'text',
                    field: 'ifscCode',
                    label: 'employee.labels.ifsc'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'panNumber',
                    label: 'employee.labels.panNumber'.getLabel(),
                    placeholder: 'employee.placeholders.enterPAN'.getLabel(),
                    mandatory: true,
                    upperCase: true,
                    inputProps: {
                      maxLength: 10,
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    type: 'number',
                    field: 'aadharNumber',
                    label: 'employee.labels.aadhar'.getLabel(),
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 12) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'number',
                    field: 'dcrNumber',
                    label: 'DCR Number',
                    allowedDecimalDigits: false,
                  },
                  {
                    type: 'number',
                    field: 'ecrNumber',
                    label: 'employee.labels.ecrNumber'.getLabel(),
                    allowedDecimalDigits: false,
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'ecrPageNumber',
                    label: 'employee.labels.ecrPageNumber'.getLabel(),
                    allowedDecimalDigits: false,
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    field: 'dcrNumber',
                    label: 'DCR Number',
                    allowedDecimalDigits: false,
                    mandatory: true,
                  },
                  fromView ? {
                    type: 'number',
                    field: 'pranNumber',
                    label: 'NPS Number',
                    allowedDecimalDigits: false,
                    disabled: ({ data }) => !data?.isNPS,
                  } : null,

                ],
              },
              {
                columns: [
                  fromView ? null : {
                    type: 'number',
                    field: 'pranNumber',
                    label: 'NPS Number',
                    allowedDecimalDigits: false,
                    disabled: ({ data }) => !data?.isNPS,
                  },
                  fromView ? null : {
                    type: 'checkBox',
                    field: 'isNPS',
                    showOuterLabel: false,
                  },
                  {
                    type: 'number',
                    field: 'epfNumber',
                    label: 'EPF Number',
                    disabled: ({ data }) => !data?.isEPF,
                  },
                  fromView ? null : {
                    type: 'checkBox',
                    field: 'isEPF',
                    showOuterLabel: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default EditEmployee;
