import { upperFirst } from 'lodash';
import React from 'react';

import { Text, View } from '../../../../app-components';
import { Action } from '../../../../app-components/action/Action';
import { Table } from '../../../../app-components/table/Table';
import {
  purchaseSupplyOrderStatus,
  Roles,
  ROUTES,
} from '../../../../Lib/constants';
import { ContingentVoucherListUri } from '../../../../Queries/accounts';
import vars from '../../../../theme/vars';

const tabListColumns = [
  { type: 'selection' },
  {
    header: 'Creation Date',
    type: 'date',
    field: '_createdOn',
  },
  {
    header: 'Subject',
    type: 'text',
    field: 'subject',
  },
  {
    header: 'Bill Number',
    type: 'text',
    field: 'billNumber',
  },
  {
    header: 'Name of Firm',
    type: 'text',
    field: 'nameOfFirm',
  },
  {
    header: 'Amount',
    type: 'text',
    field: 'amount',
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    // eslint-disable-next-line consistent-return
    render: ({ item }) => {
      const { status, assignedToRole } = item;
      if (assignedToRole[assignedToRole.length - 1].status === 'PENDING') {
        return (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.warning,
            }}
          >
            {`In Approval (By ${assignedToRole[assignedToRole.length - 1].role})`}
          </Text>
        );
      }
      if (status === 'Rejected') {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>
            {`Rejected (By ${assignedToRole[assignedToRole.length - 1].role})`}
          </Text>
        );
      }
      if (
        (assignedToRole[assignedToRole.length - 1].status === purchaseSupplyOrderStatus?.APPROVED,
        assignedToRole[assignedToRole.length - 1].role === Roles?.director)
      ) {
        return (
          <Text
            style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}
          >
            {`${assignedToRole[assignedToRole.length - 1].role} Approved`}
          </Text>
        );
      }
    },
  },
  {
    header: 'Bill',
    type: 'file',
    field: 'attachment',
    // width: 250,
  },
  {

    width: 70,
  },
  {
    header: 'Actions',
    type: 'custom',
    render: ({ item }) => (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            ...vars.headings.h11,
            color: vars.colors.secondary.color2,
          }}
        >
          View
        </Text>
      </View>
    ),
  },
];

const contingentVoucherRequestList = (props) => (
  <Table
    lg={{ columns: tabListColumns }}
    reloadEvent="contingent_voucher_list"
    uri={ContingentVoucherListUri}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewContingentVoucherRequest.name,
        params: { item },
      },
    })}
    {...props}
  />
);
export default contingentVoucherRequestList;
