import React from 'react';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { GetAnesthesiaReportUri } from '../../../../../../../Queries/anesthesiaReport';
import { Table } from '../../../../../../../app-components/table/Table';
import { StandardButton, Text } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';

const header = (item) => {
  const { dataParams } = item;
  return ({
    title: () => (
      <HeaderTitleWithCount
        label="Notes"
        count={item?.aggregates?._count}
      />
    ),
    actions: [
      {
        type: 'link',
        render: () => (
          <StandardButton
            label="Add New Notes"
          />
        ),
        link: ({ navigation: { state: { params = {} } } }) => ({
          view: ROUTES.logAnaesthesia.name,
          params: { ...params, dataParams },
          push: true,
        }),
      },
    ],
  });
};

const tabListColumns = {
  md: {
    columns: [
      {
        type: 'date',
        header: 'DATE',
        value: ({ item }) => item?.operationProcedure?.operationDate,

        format: 'MMM DD YYYY',
      },
      {
        type: 'text',
        value: ({ item }) => item?.operationProcedure?.operationName?.name,
        header: 'NAME OF OPERATIONs',
      },
      {
        header: 'SURGEON NAME',
        type: 'text',
        render: ({ item }) => item?.operationProcedure?.surgeonName.map((data) => (
          <Text key={data?._id} style={{ fontSize: 14, lineHeight: 16 }}>
            {data?.name}
          </Text>
        )),
      },
      {
        header: 'ANESTHESIOLOGIST NAME',
        type: 'text',
        value: ({ item }) => item?.operationProcedure?.anesthesiologist?.name,
      },
      {
        header: 'ANESTHESIA',
        type: 'text',
        value: ({ item }) => item?.operationProcedure?.anesthesia,
      },
      {
        header: 'TYPE',
        type: 'text',
        value: ({ item }) => item?.operationProcedure?.anesthesiaType,
      },
      {
        header: 'patient.headers.action'.getLabel(),
        type: 'link',
        value: (props) => {
          if (props?.item?.npoSince) { return 'View'; }
          return 'Log';
        },
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            if (props?.item?.npoSince) {
              return {
                view: ROUTES.viewAnaesthesia.name,
                params: { ...props },
                push: true,
              };
            }
            return {
              view: ROUTES.logAnaesthesia.name,
              params: { item },
            };
          },
        },
      },
    ],
  },
};

const AnesthesiaReport = (props) => {
  const { navigation: { state: { params: { patient } } } } = props;
  return (
    <Table
      {...props}
      header={header(props)}
      reloadEvent={`reload${MODELS.ANESTHESIA_REPORT}`}
      model={MODELS.ANESTHESIA_REPORT}
      uri={() => GetAnesthesiaReportUri({
        admissionId: { _id: patient._id },
        speciality: props.dataParams?.filters?.specialityFilter,

      })}
      {...tabListColumns}
      // action={{
      //   type: 'link',
      //   link: (info) => ({
      //     view: ROUTES.viewAnaesthesia.name,
      //     params: {
      //       addOnFilter: {
      //         _id: info.item._id,
      //       },
      //     },
      //   }),
      // }}
    />
  );
};
export default AnesthesiaReport;
