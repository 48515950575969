import React from 'react';

import {
  SalaryComponentList,
} from './lists';
import { AddnewSalaryComponent, EditComponent, ViewComponent } from './actions';
import {
  StandardButton, cancelButton, save, FilterGroup, Text,
} from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../images';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import vars from '../../../theme/vars';
import ApplySalaryComponent from '../../../Wired/ApplySalaryComponentModal';
import PranPercentage from './PranPercentage';

export default {
  [ROUTES.salaryComponentList.name]: {
    screen: SalaryComponentList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label="Salary Components"
        />
      ),
      actions: [
        (props) => <FtsSearch {...props} action={{ exp: ['name', 'description'] }} />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'isActive',
              label: 'Active/In-Active',
              placeholder: 'Active/In-Active',
              sugestionField: 'label',
              primaryField: 'label',
              valueField: 'value',
              options: [
                { label: 'Active', value: true },
                { label: 'InActive', value: { $eq: false } },
              ],
            },
            {
              type: 'autoSuggest',
              field: 'component_type',
              label: 'Type',
              placeholder: 'Component Type',
              sugestionField: 'label',
              primaryField: 'label',
              valueField: 'value',
              options: [
                { label: 'Earning', value: 'earning_type' },
                { label: 'Deductions', value: 'deduction_type' },
              ],
            },
            {
              type: 'autoSuggest',
              field: 'payment_type_id',
              label: 'Payment Type',
              placeholder: 'Select Payment Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              model: MODELS.PAYMENT_TYPES,
              id: 'paymentTypeSuggestions',
            },
            {
              type: 'autoSuggest',
              field: 'component_calculation_base_id',
              label: 'Calculation Base',
              placeholder: 'Select Payment Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              model: MODELS.COMPONENT_CALCULATION_BASE,
              id: 'componentBaseCalTypeSuggestions',
            },
            {
              type: 'autoSuggest',
              field: 'pay_to_id',
              label: 'Pay To',
              placeholder: 'Select Payment Type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              model: MODELS.PAY_TO,
              id: 'payToSuggestions',
            },
            {
              type: 'autoSuggest',
              field: 'is_taxable',
              label: 'Taxation',
              placeholder: 'Taxation',
              sugestionField: 'label',
              primaryField: 'label',
              valueField: 'value',
              options: [
                { label: 'Taxable', value: true },
                { label: 'Not Taxable', value: { $ne: true } },
              ],
            },
          ],
        }),
      ],
    },
    headerActions: [{
      render: (
        <WithPermission
          access_key={permissions.mdm.views.addSalaryComponent.permissions.addSalaryComponent.value}
        >
          <StandardButton label="Add New Salary Component" />
        </WithPermission>
      ),
      type: 'link',
      link: {
        view: [ROUTES.addSalaryComponent.name],
        routeOptions: {
          expanded: true,
        },
      },
    }],
  },
  [ROUTES.addSalaryComponent.name]: {
    screen: AddnewSalaryComponent,
    headerActions: [
      cancelButton, save,
    ],
  },
  [ROUTES.editSalaryComponent.name]: {
    screen: EditComponent,
    headerActions: [
      cancelButton, save({ label: 'Update' }),
    ],
  },
  [ROUTES.applySalaryComponent.name]: {
    screen: ApplySalaryComponent,
    modal: true,
    modalProps: {
      width: 400,
      height: 500,
    },
  },
  [ROUTES.viewSalaryComponent.name]: {
    screen: ViewComponent,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      {
        render: (
          <StandardButton label="Add PRAN Percentage" />
        ),
        visible: (vprop) => vprop?.data?.code === 'NPS',
        type: 'link',
        link: (props) => ({
          view: [ROUTES.pranPercentage.name],
          params: { ...props?.data },
          routeOptions: {
            expanded: true,
          },
        }),
      },
      {
        render: (
          <WithPermission
            access_key={permissions.mdm.views.modifySalaryComponent
              .permissions.modifySalaryComponent.value}
          >
            <StandardButton label="Apply Dependency Change" />
          </WithPermission>
        ),
        visible: (vprop) => !!vprop?.data?.dependentPercentage,
        type: 'link',
        link: (props) => ({
          view: [ROUTES.applySalaryComponent.name],
          params: { ...props?.data },
          routeOptions: {
            expanded: true,
          },
        }),
      },
      cancelButton,
      {
        render: (
          <WithPermission
            access_key={permissions.mdm.views.modifySalaryComponent
              .permissions.modifySalaryComponent.value}
          >
            <StandardButton label="Edit" />
          </WithPermission>
        ),
        type: 'link',
        link: ({ navigation }) => ({
          view: [ROUTES.editSalaryComponent.name],
          params: { ...navigation?.state?.params },
          routeOptions: {
            expanded: true,
          },
        }),
      },
    ],
  },
  [ROUTES.pranPercentage.name]: {
    screen: PranPercentage,
    expanded: true,
    modal: true,
    modalProps: {
      height: 'auto',
      width: 800,
    },

  },

};
