import { cancelButton, save } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';
import ConfirmationDetail from './confirmationModal';
import {
  EmployeeCompensationDetail,
  NewEmployeeCompensationDetail,
  ViewEmployeeCompensationDetail,
} from './actions';

export default {
  [ROUTES.editEmployeeSalaryCompensationStructure.name]: {
    screen: EmployeeCompensationDetail,
    expanded: true,
    headerActions: [
      cancelButton,
      save({ label: 'Update' }),
    ],
  },
  [ROUTES.viewEmployeeSalaryCompensationStructure.name]: {
    screen: ViewEmployeeCompensationDetail,
    expanded: true,
    // headerActions: [
    //   cancelButton,
    //   save({ label: 'Update' }),
    // ],
  },
  [ROUTES.addEmployeeSalaryCompensationStructure.name]: {
    screen: NewEmployeeCompensationDetail,
    expanded: true,
    headerActions: [
      cancelButton,
      save,
    ],
  },
  [ROUTES.CompensationStructureConfirmationView.name]: {
    screen: ConfirmationDetail,
    modal: true,
    modalProps: {
      width: 400,
      height: 300,
    },
  },
};
