import React from 'react';
import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { View, Text } from '../../../app-components';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'purchaseDepartmentTC.labels.purchaseDepartmentTCName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
  },
  {
    type: 'text',
    field: 'description',
    label: 'purchaseDepartmentTC.labels.description'.getLabel(),
    allowWhiteSpaces: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'purchaseDepartmentTC.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'left',
  },
];

export default MasterForm({
  model: MODELS.PURCHASE_DEPARTMENT_TC,
  formFields,
  otherFormGroups: [
    {
      fieldVariant: 'filled',
      label: 'List of Terms & Conditions',
      groups: [
        {
          columnsPerRow: 1,
          columns: [
            {
              field: 'conditions',
              fieldType: 'nested',
              Component: NestedTable,
              componentProps: {
                maxHeight: 400,
                footer: ({ addRow }) => ({
                  leftActions: [
                    {
                      render: () => (
                        <View
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderRadius: 5,
                            width: 120,
                            height: 40,
                            borderColor: vars.colors.secondary.color2,
                          }}
                        >
                          <Text
                            style={{
                              ...vars.headings.h9,
                              color: vars.colors.secondary.color2,
                            }}
                          >
                            {` ${'bloodInventory.labels.addNewItem'.getLabel()}`}
                          </Text>
                        </View>
                      ),
                      onPress: addRow,
                    },
                  ],
                }),
                skipTableHeaderOnNoData: true,
                listProps: {
                  hideColumnHeader: false,
                  headerRowColumnTextStyle: {
                    paddingLeft: 12,
                    ...vars.headings.h13,
                    color: vars.colors.grey.color3,
                    numberOfLines: 1,
                  },
                  columns: [
                    {
                      type: 'text',
                      field: 'name',
                      variant: 'filled',
                      placeholder: 'purchaseDepartmentTC.labels.name'.getLabel(),
                      header: 'purchaseDepartmentTC.labels.name'.getLabel(),
                      mandatory: true,
                      allowWhiteSpaces: true,
                    },
                    {
                      type: 'text',
                      field: 'description',
                      variant: 'filled',
                      placeholder: 'purchaseDepartmentTC.labels.description'.getLabel(),
                      header: 'purchaseDepartmentTC.labels.description'.getLabel(),
                      mandatory: true,
                      allowWhiteSpaces: true,
                    },
                    NestedAction(),
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  ],
  submitMessage: 'purchaseDepartmentTC.messages.purchaseDepartmentTCAddedSuccessfully'.getLabel(),
  mandatory: {
    name: 1,
  },
  defaultValues: () => ({
    isActive: false,
  }),
});
