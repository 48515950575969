import React from 'react';

import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';

import ConsultationLogs from '../../../../../../Wired/ConsultationLogTable';
import { MODELS } from '../../../../../../Lib/constants';
import PatientDetails from '../GeneralSurgery/PatientDetails';
import IpdForm from '../GeneralSurgery/IPDForm';
import ViewConsentForm from '../GeneralSurgery/ConsentForm';
import Investigation from '../GeneralSurgery/Investigation';
import Orders from './Orders';
import Outcome from '../GeneralSurgery/Outcome';
import References from '../GeneralSurgery/References';
import AddDailyExaminationNote from './DailyExaminationNotes';
import MonitoringChart from './MonitoringChart';
import InputOutputChart from './InputOutputChart';

const ViewICUTabs = (props) => {
  const {
    navigation: { state: { params = {} } = {} },
  } = props;

  return (
    <TabNavigator
      {...props}
      tabs={{
        PatientDetails: {
          label: 'Patient Details',
          screen: PatientDetails,
          screenProps: props,
        },
        ConsultationLogs: {
          label: 'Consultation Log',
          screen: ConsultationLogs,
          screenProps: {
            props,
            model: MODELS.CONSULTATION,
            id: 'consultationDataList',
            addOnFilter: { patient: { _id: params?.patient?.patient?._id } },
          },
        },
        IPDForm: {
          label: 'IPD Form',
          screen: IpdForm,
          screenProps: props,
        },
        ConsentForm: {
          label: 'Consent Form',
          screen: ViewConsentForm,
          screenProps: props,
        },
        DailyExaminationNotes: {
          label: 'Daily Examination Notes',
          screen: AddDailyExaminationNote,
          screenProps: props,
        },
        Investigation: {
          label: 'Investigation',
          screen: Investigation,
          screenProps: props,
        },
        MonitoringChart: {
          label: 'Monitoring Chart',
          screen: MonitoringChart,
          screenProps: props,
          expanded: true,
        },
        InputOutputChart: {
          label: 'Input/Output Chart',
          screen: InputOutputChart,
          screenProps: props,
        },
        Orders: {
          label: 'Orders',
          screen: Orders,
          screenProps: props,
        },
        Outcome: {
          label: 'Outcome',
          screen: Outcome,
          screenProps: props,
        },
        References: {
          label: 'References',
          screen: References,
          screenProps: props,
        },
      }}
    />
  );
};
export default ViewICUTabs;
