import React from 'react';
import {
  clearSelection, selectAll, showSelectedCount, StandardButton,
} from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { getString } from '../../../Lib/helpers';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'Employee',
    type: 'text',
    field: 'employee_id.name',
    sortable: 'employee_id.name',
  },
  {
    header: 'Employee ID',
    type: 'text',
    value: ({ item }) => item?.employee_id?.employeeId,
  },
  {
    header: 'Speciality',
    type: 'text',
    value: ({ item }) => (Array.isArray(item?.employee_id?.speciality) && getString(item?.employee_id?.speciality?.map((speciality) => speciality.name), ', ')),

  },
  {
    header: 'room.labels.department'.getLabel(),
    type: 'text',
    field: 'department',
    value: ({ item }) => (Array.isArray(item?.employee_id?.department) && getString(item?.employee_id?.department?.map((department) => department.name), ', ')),

  },
  {
    header: 'StaffType',
    type: 'text',
    value: ({ item }) => item?.employee_id?.staffType?.name,
  },
  {
    header: 'Last Increment Date',
    type: 'date',
    field: 'employee_id.lastIncrementDate',
    format: 'DD/MM/YYYY',
  },
  // {
  //   header: 'To Date',
  //   type: 'date',
  //   field: 'to_date',
  //   format: 'DD/MM/YYYY',
  // },
];

export default (props) => (
  <MasterList
    {...props}
    showActionIcon={false}
    model={MODELS.EMPLOYEE_SALARY_COMPONENT}
    reloadOnChangeEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}
    reloadEvent={`reload${MODELS.EMPLOYEE_SALARY_COMPONENT}`}
    uri={() => ({
      query: {
        id: 'employeePackageReview',
      },
      model: MODELS.EMPLOYEE_SALARY_COMPONENT,
    })}
    tableColumns={tableColumns}
    const
    header={({ aggregates }) => ({
      selectableActions: () => [
        showSelectedCount(props, aggregates),

        clearSelection,
        selectAll,
        {
          render: () => (
            <StandardButton label="Review Package" />
          ),
          type: 'link',
          link: () => ({
            view: ROUTES.reviewedPackageForm.name,
            params: {
              selectedIds: props?.state?.selectedIds,
              oldProps: props,
            },
            push: true,
            expanded: true,
          }),
        },
      ],
    })}
  />
);
