import React, { useCallback, useState } from 'react';

import {
  FlatList, FormField, Text, View, TouchableOpacity,
} from '../../../../app-components';
import Headings from '../Components/Headings';
import vars from '../../../../theme/vars';
import theme from '../theme';

const PermissionsViewsForm = (props) => {
  const {
    setValue,
    selectAllModuleFormValue,
    views,
    data: {
      permissions = {},
    } = {},
  } = props;

  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [clearAllCheckbox, setClearAllCheckbox] = useState(false);

  const handleSelectAllCheckboxSelection = useCallback(() => {
    setSelectAllCheckbox(!selectAllCheckbox);
    for (let permission = 0; permission < views.length; permission += 1) {
      const element = views[permission];
      if (!permissions[`${element.permissions[0].value}`]) {
        setTimeout(() => {
          setValue({ field: `permissions.${element.permissions[0].value}`, value: element.permissions[0].value });
        }, 100);
        setTimeout(() => {
          setValue({ field: `permissions.${element.permissions[0].value}`, value: true });
        }, 100);
      }
    }
  }, [permissions, selectAllCheckbox, setValue, views]);

  const handleClearAllCheckboxSelection = useCallback(() => {
    setClearAllCheckbox(!clearAllCheckbox);
    for (let permission = 0; permission < views.length; permission += 1) {
      const element = views[permission];
      if (permissions[`${element.permissions[0].value}`]) {
        setTimeout(() => {
          setValue({ field: `permissions.${element.permissions[0].value}`, value: element.permissions[0].value });
        }, 100);
        setTimeout(() => {
          setValue({ field: `permissions.${element.permissions[0].value}`, value: false });
        }, 100);
      }
    }
  }, [clearAllCheckbox, permissions, setValue, views]);

  const handleOnPressPermissionInput = useCallback((value) => {
    if (value) {
      setTimeout(() => {
        setValue({ field: `permissions.${selectAllModuleFormValue}`, value: true });
      }, 100);
    }
  }, [selectAllModuleFormValue, setValue]);

  return (
    <View
      style={theme.completeWidth}
    >
      <View style={{
        flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: 7,
      }}
      >
        <TouchableOpacity
          style={{
            cursor: 'pointer',
          }}
          onPress={handleSelectAllCheckboxSelection}
        >
          <Text style={{ marginLeft: 7, ...vars.headings.h9, color: vars.colors.secondary.color2 }}>
            Select all
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            cursor: 'pointer',
          }}
          onPress={handleClearAllCheckboxSelection}
        >
          <Text
            style={{
              marginLeft: 14,
              ...vars.headings.h9,
              color: vars.colors.secondary.color2,
            }}
          >
            Clear all
          </Text>
        </TouchableOpacity>
      </View>
      <Headings {...props} />
      <View>
        <FlatList
          data={views}
          style={{ height: '45vh' }}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <View key={index}>
              <View
                style={theme.center}
                key={index}
              >
                <View
                  style={theme.width200}
                >
                  <Text
                    style={[
                      vars.headings.h9,
                      { color: vars.colors.grey.color1 },
                    ]}
                  >
                    {item.label}
                  </Text>
                </View>
                {
                  item.permissions.map((permission, uniqueIndex) => (
                    <View
                      key={uniqueIndex}
                      style={theme.switchInputWrapper}
                    >
                      {permission.label && (
                        <FormField
                          field={`permissions.${permission.value}`}
                          type="toggleSwitch"
                          showOuterLabel={false}
                          placeholder={permission.label}
                          variant="filled"
                          mandatory
                          onChangeValue={
                            (value) => handleOnPressPermissionInput(value, index, permission.all)
                          }
                        />
                      )}
                    </View>
                  ))
                }
              </View>
              <View
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: vars.colors.secondary.color4,
                }}
              />
            </View>
          )}
          keyExtractor={(_, index) => index.toString()}
          numColumns={2}
        />
      </View>
    </View>
  );
};

export default PermissionsViewsForm;
