import React from 'react';
import { upperFirst } from 'lodash';

import { FormHoc, Text, View } from '../../../../../../app-components';
import { MODELS } from '../../../../../../Lib/constants';
import { AssetPurchaseEntityRequestUri } from '../../../../../../Queries/asset';
import vars from '../../../../../../theme/vars';

const ViewPurchaseRequest = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item: {
            purchaseRequestAsset,
          },
        } = {},
      } = {},
    } = {},
  } = props;

  const ViewPurchaseRequestForm = FormHoc({
    type: 'standard',
    closeView: 1,
    reloadEvent: `reload${MODELS.PURCHASE_REQUEST}`,
    reloadOnChangeEvent: `reload${MODELS.PURCHASE_REQUEST}`,
    uri: AssetPurchaseEntityRequestUri({ filter: { type: 'asset', subType: 'purchase', purchaseRequestAsset: { _id: purchaseRequestAsset._id } } }),
    editable: false,
    lg: {
      formGroups: [
        {
          columnsPerRow: 3,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'asset.labels.dateRaised'.getLabel(),
                  format: 'DD MMM YYYY',
                  field: '_createdOn',
                },
                {
                  type: 'text',
                  label: 'asset.labels.purchaseRequestNumber'.getLabel(),
                  field: 'purchaseRequestAsset.purchaseRequestNumber',
                },
                {
                  type: 'text',
                  label: 'asset.labels.status'.getLabel(),
                  render: ({ data }) => {
                    let statusColor = '';
                    const status = data?.purchaseRequestAsset?.requestStatus;
                    if (status === 'raised') { statusColor = vars.colors.emphasis; } else if (status === 'Order  Received') { statusColor = vars.colors.primary.color2; } else if (status === 'PO Rejected' || status === 'SO Rejected') { statusColor = vars.colors.error; } else { statusColor = vars.colors.warning; }
                    return (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                        <View style={{
                          height: 8,
                          width: 8,
                          borderRadius: 8,
                          backgroundColor: statusColor,
                          marginTop: 3,
                          marginLeft: 7,
                        }}
                        />
                      </View>
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          label: 'asset.title.purchaseRequestDetails'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 3,
              columns: [
                {
                  type: 'number',
                  label: 'asset.labels.requiredQuantity'.getLabel(),
                  field: 'purchaseRequestAsset.requiredQuantity',
                },
                {
                  type: 'text',
                  label: 'asset.labels.service'.getLabel(),
                  field: 'purchaseRequestAsset.isAMC',
                },
                {
                  type: 'textArea',
                  label: 'asset.labels.reasonForPurchase'.getLabel(),
                  field: 'purchaseRequestAsset.reasonForPurchase',
                },
              ],
            },
            {
              columnsPerRow: 3,
              columns: [
                {
                  type: 'text',
                  field: 'purchaseRequestAsset.item.name',
                  label: 'asset.labels.assetName'.getLabel(),
                },
                {
                  type: 'text',
                  field: 'purchaseRequestAsset.item.category.name',
                  label: 'asset.labels.category'.getLabel(),
                },
                {
                  type: 'text',
                  field: 'purchaseRequestAsset.item.subCategory.name',
                  label: 'asset.labels.subCategory'.getLabel(),
                },
                {
                  type: 'number',
                  field: 'purchaseRequestAsset.unitPrice',
                  label: 'asset.labels.unitPrice'.getLabel(),
                },
                {
                  type: 'number',
                  field: 'purchaseRequestAsset.item.stockAvailable.stockAvailable',
                  label: 'asset.labels.stockAvailable'.getLabel(),
                },
                {
                  type: 'text',
                  field: 'purchaseRequestAsset.manufacturer.name',
                  label: 'asset.labels.manufacturer'.getLabel(),
                }, {
                  type: 'text',
                  field: 'purchaseRequestAsset.supplier.name',
                  label: 'asset.labels.supplier'.getLabel(),
                },
              ],
            },
          ],
        },
        {
          label: 'asset.labels.progressChart'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'progressChart',
                  field: '_id',
                  id: 'purchaseRequestAssetList',
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return <ViewPurchaseRequestForm {...props} />;
};

export default ViewPurchaseRequest;

