import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { EditEmployeeCategory } from '../../../Queries/employeeCategory';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'Category Name'.getLabel(),
    mandatory: true,
    placeholder: 'masters.labels.name'.getLabel(),
    allowWhiteSpaces: true,
    editable: false,
  },
  {
    type: 'text',
    field: 'description',
    label: 'Description',
    placeholder: 'masters.labels.description'.getLabel(),
    editable: false,
    allowWhiteSpaces: true,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'Status',
    editable: false,
    container: 'topLabel',
  },
];

export default MasterForm({
  uri: EditEmployeeCategory,
  model: MODELS.EMPLOYEE_CATEGORY,
  formFields,
});
