import React from 'react';
import { upperFirst } from 'lodash';

import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { ViewPurchaseRequestListAsset } from '../../../../../Queries/purchaseRequestPharmacy';
import { purchaseSupplyOrderStatus } from '../../../../../Lib/constants';
import { FormField, Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { tabTheme } from '../../../../../theme/tabBarTheme';
import vars from '../../../../../theme/vars';

const ViewAssetPurchaseRequest = (props) => (
  <Form
    type="standard"
    uri={ViewPurchaseRequestListAsset}
    closeView={1}
    editable={false}
    lg={{
      tabProps: { ...tabTheme },
      tabs: {
        BasicDetails: {
          label: 'Basic Details',
          formGroups: [
            {
              columnsPerRow: 4,
              fieldVariant: 'filled',
              label: 'PURCHASE REQUEST DETAILS',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'date',
                      label: 'purchase.labels.dateRaised'.getLabel(),
                      field: '_createdOn',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.requestNumber'.getLabel(),
                      field: 'purchaseRequestAsset.purchaseRequestNumber',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.department'.getLabel(),
                      render: ({ data }) => (
                        <Text
                          style={{ ...vars.headings.h8 }}
                        >
                          {upperFirst(data?.type)}
                        </Text>
                      ),
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.status'.getLabel(),
                      render: ({ data }) => {
                        let statusColor = '';
                        const status = data?.purchaseRequestAsset?.requestStatus;
                        if (status === purchaseSupplyOrderStatus?.RAISED) {
                          statusColor = vars.colors.emphasis;
                        } else if (status === purchaseSupplyOrderStatus?.ORDER_RECEIVED) {
                          statusColor = vars.colors.primary.color2;
                        } else if (
                          status === purchaseSupplyOrderStatus?.PO_REJECTED
                          || status === purchaseSupplyOrderStatus?.SO_REJECTED
                        ) {
                          statusColor = vars.colors.error;
                        } else { statusColor = vars.colors.warning; }

                        return (
                          <View style={{ flexDirection: 'row' }}>
                            <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                            <View style={{
                              height: 8,
                              width: 8,
                              borderRadius: 8,
                              backgroundColor: statusColor,
                              marginTop: 4.5,
                              marginLeft: 7,
                            }}
                            />
                          </View>
                        );
                      },
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.assetName'.getLabel(),
                      field: 'purchaseRequestAsset.item.name',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.category'.getLabel(),
                      field: 'purchaseRequestAsset.item.category.name',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.reasonForPurchase'.getLabel(),
                      field: 'purchaseRequestAsset.reasonForPurchase',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.description'.getLabel(),
                      field: 'purchaseRequestAsset.item.description',
                    },
                    {
                      type: 'text',
                      field: 'purchaseRequestAsset.requiredQuantity',
                      label: 'purchase.labels.requiredQuantity'.getLabel(),
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.unitPrice'.getLabel(),
                      field: 'purchaseRequestAsset.unitPrice',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.totalPrice'.getLabel(),
                      render: ({ data }) => (
                        <Text style={{ ...vars.headings.h8 }}>
                          {data?.purchaseRequestAsset?.requiredQuantity
                        * data?.purchaseRequestAsset?.unitPrice}
                        </Text>
                      ),
                    },
                    {
                      type: 'date',
                      label: 'purchase.labels.lastPurchaseDate'.getLabel(),
                      field: 'purchaseRequestAsset.lastPurchaseDate',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.lastPurchaseQuantity'.getLabel(),
                      field: 'purchaseRequestAsset.lastPurchaseQuantity',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.stockAvailable'.getLabel(),
                      field: 'purchaseRequestAsset.item.stockAvailable.stockAvailable',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.manufacturer'.getLabel(),
                      field: 'purchaseRequestAsset.manufacturer.name',
                    },
                    {
                      type: 'text',
                      label: 'purchase.labels.supplier'.getLabel(),
                      field: 'purchaseRequestAsset.supplier.name',
                    },
                  ],
                },
              ],
            },
            {
              label: 'inventory.labels.statusTracking'.getLabel(),
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 12,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    {
                      type: 'progressChart',
                      field: '_id',
                      id: 'purchaseRequestAssetList',
                    },
                  ],
                },
              ],
            },
          ],
        },
        TechnicalDetails: {
          label: 'Technical Specification',
          formGroups: [
            {
              fieldVariant: 'filled',
              label: 'asset.title.technicalDetails'.getLabel(),
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
              },
              groupsContainerStyle: { paddingLeft: 0 },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      render: (nestedFieldProps) => (
                        <FormField
                          {...nestedFieldProps}
                          {...{
                            field: 'purchaseRequestAsset.technicalDetails',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              skipTableHeaderOnNoData: true,
                              listProps: {
                                hideColumnHeader: true,
                                columnsPerRow: 1,
                                columns: [
                                  {
                                    type: 'custom',
                                    render: (nestedTableProps) => {
                                      const { item } = nestedTableProps;
                                      const {
                                        technicalListDetails,
                                        technicalTitle,
                                        serialNo,
                                      } = item;

                                      const technicalDetailsList = [];
                                      if (technicalListDetails) {
                                        technicalListDetails.forEach((element) => {
                                          technicalDetailsList.push({
                                            description: element?.description,
                                          });
                                        });
                                      }
                                      if (item) {
                                        return (
                                          <View
                                            style={{
                                              flex: 1,
                                              marginTop: 20,
                                            }}
                                          >
                                            <View
                                              style={{
                                                flexDirection: 'row',
                                                flex: 1,
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <Text style={{
                                                ...vars.headings.h9,
                                                color: vars.colors.secondary.color2,
                                                paddingLeft: 5,
                                              }}
                                              >
                                                {serialNo}
                                                {'. '}
                                                {upperFirst(technicalTitle)}
                                              </Text>
                                            </View>
                                            {technicalDetailsList.map((need) => (
                                              <View
                                                style={{
                                                  flexBasis: '50%',
                                                  flexDirection: 'row',
                                                  marginTop: 10,
                                                }}
                                                key="description"
                                              >
                                                <View style={{ marginLeft: 10 }}>
                                                  <View
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    }}
                                                  >
                                                    <Text
                                                      style={{
                                                        height: 5,
                                                        width: 5,
                                                        borderRadius: 2.5,
                                                        backgroundColor: vars.colors.grey.color3,
                                                      }}
                                                      numberOfLines={4}
                                                    />
                                                    <Text
                                                      style={{
                                                        ...vars.headings.h9,
                                                        textOverFlow: 'scroll',
                                                        color: vars.colors.grey.color3,
                                                        marginLeft: 4,
                                                      }}
                                                      numberOfLines={4}
                                                    >
                                                      {` ${need?.description}`}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                            ))}
                                          </View>
                                        );
                                      }
                                      return <Text />;
                                    },
                                  },
                                ],
                              },
                            },
                          }}
                        />
                      ),

                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    }}
    {...props}
  />
);

export default ViewAssetPurchaseRequest;
