import React from 'react';
import { getPermission } from '../../AppServices';
import { View } from '../../app-components';

const WithPermission = (props) => {
  const { access_key } = props;
  const isPermitted = getPermission(access_key);

  if (isPermitted || !access_key) {
    return props.children;
  }
  return null;
};

export default WithPermission;
