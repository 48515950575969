import { MODELS } from '../Lib/constants';

export const employeeScheduleUri = ({ navigation }) => ({
  query: {
    model: MODELS.EMPLOYEE_SCHEDULE,
    id: 'scheduleDetail',
    _id: navigation?.state?.params?.item?._id,
    relationValue: {
      model: MODELS.EMPLOYEE_SCHEDULE,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    relation: '_id',
    limit: 1,
  },
  model: MODELS.EMPLOYEE_SCHEDULE,
});
