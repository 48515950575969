export const EditState = ({ navigation }) => {
  return {
    query: {
      model: 'states',
      id: 'masterDataList',
      relationValue: {
        model: 'states',
        _id: navigation.state.params.item._id,
        relation: '_id',
      },
      limit: 1,
    },
    model: 'states',
  };
};
