import React from 'react';

import { size } from 'lodash';
import {
  PharmacyInventoryRequestToggleFilter,
  Text,
} from '../../../../../app-components';
import {
  Table,
} from '../../../../../app-components/table/Table';
import { PharmacyIndentRequestsListUri } from '../../../../../Queries/pharmacy';
import { MODELS, pharmacyInventoryStockRequestStatus, ROUTES } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const tableItems = {
  lg: {
    columns: [
      { width: 14 },
      {
        header: 'inventory.headers.responseDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_updatedOn',
      },
      {
        header: 'inventory.headers.dateRaised'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
        sortable: '_createdOn',
      },
      {
        header: 'inventory.headers.indentNumber'.getLabel(),
        type: 'text',
        field: 'indentNumber',
      },
      {
        header: 'inventory.headers.noOfItems'.getLabel(),
        type: 'number',
        value: ({ item: { indentDetails = [] } = {} }) => size(indentDetails),
      },
      {
        header: 'inventory.headers.department'.getLabel(),
        type: 'text',
        value: ({ item: { department = {} } = {} }) => department?.name,
      },
      {
        header: 'inventory.headers.status'.getLabel(),
        type: 'text',
        field: 'requestStatus',
        render: ({ item: { requestStatus } }) => {
          let indentRequestStatus = '';
          let statusColor = '';
          if (requestStatus === pharmacyInventoryStockRequestStatus.approved) {
            indentRequestStatus = pharmacyInventoryStockRequestStatus.approvedByMSI;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            indentRequestStatus = pharmacyInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          } else if (requestStatus === pharmacyInventoryStockRequestStatus.stockProvided) {
            indentRequestStatus = pharmacyInventoryStockRequestStatus.stockProvided;
            statusColor = vars.colors.primary.color2;
          } else {
            indentRequestStatus = pharmacyInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {indentRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item, queryId }) => ({
              view: ROUTES.msiViewIndentRequest.name,
              params: { item, queryId },
            }),
          },
        ],
      },
    ],
  },
};

export default (props) => {
  const {
    queryId,
  } = props;

  return (
    <Table
      {...props}
      reloadEvent={`reload${MODELS.PHARMACY_INDENT}`}
      uri={() => PharmacyIndentRequestsListUri({ queryId })}
      {...tableItems}
      header={{
        // eslint-disable-next-line no-shadow
        leftActions: [(props) => (
          <PharmacyInventoryRequestToggleFilter
            {...props}
            model={MODELS.PHARMACY_INDENT}
            id={queryId || 'pharmacyInventoryIndentdataList'}
          />
        )],
      }}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.msiViewIndentRequest.name,
          params: { item, queryId },
        },
      })}
    />
  );
};
