/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { upperFirst } from 'lodash';

import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { ViewPurchaseRequestListAsset } from '../../../../../Queries/purchaseRequestPharmacy';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { Action } from '../../../../../app-components/action/Action';
import { StandardButton, Text } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const CreateRepairRequestForAsset = (props) => {
  const { navigation } = props;
  const {
    state: {
      params: {
        purchaseSupplyOrderId = '',
        fromSupplyOrder = false,
      },
    },
  } = navigation;

  const [isCodeSelect, setIsCodeSelect] = useState(false);

  return (
    <Form
      type="standard"
      editable={false}
      uri={ViewPurchaseRequestListAsset}
      onSubmit={(submitProps) => {
        const { data } = submitProps;
        data.fromSupplyOrder = fromSupplyOrder;
        data.purchaseSupplyOrderId = purchaseSupplyOrderId;

        navigation.push({
          view: ROUTES.purchaseAndSupplyOrderForAssetRepair.name,
          params: data,
        });
      }}
      mandatory={{
        daysLimitForApproval: 1,
        code: 1,
      }}
      computations={{
        self: {
          autoFill: {
            compute: (data) => {
              const { code } = data || {};
              if (code?.conditions?.length) {
                setIsCodeSelect(true);
                const newConditions = code?.conditions?.map((el, index) => ({
                  ...el,
                  code: data?.code?.code,
                  sNo: index + 1,
                  selected: false,
                }));
                return {
                  set: {
                    conditions: newConditions,
                  },
                };
              }

              setIsCodeSelect(false);
              return {
                set: {
                  conditions: [],
                },
              };
            },
            onChange: ['code', 'checkBoxGroupInput'],
          },
        },
        children: {
          conditions: {
            self: {
              conditions: {
                compute: (data) => {
                  const {
                    _parent: { conditions = [] },
                  } = data;
                  conditions[data?.sNo - 1].selected = !conditions[
                  data?.sNo - 1
                  ].selected;
                  const updatedConditions = conditions;
                  return {
                    set: {
                      conditions: updatedConditions,
                    },
                  };
                },
                onChange: ['checkBoxGroupInput'],
              },
            },
          },
        },
      }}
      closeView={0}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'purchase.labels.purchaseRequestNumber'.getLabel(),
                    field: 'purchaseRequestAsset.purchaseRequestNumber',
                    editable: false,
                  },
                  {
                    type: 'date',
                    label: 'purchase.labels.date'.getLabel(),
                    value: new Date(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.department'.getLabel(),
                    render: ({ data }) => (
                      <Text
                        style={{ ...vars.headings.h8 }}
                      >
                        {upperFirst(data?.type)}
                      </Text>
                    ),
                  },
                  {
                    type: 'checkBox',
                    label: 'purchase.labels.urgent'.getLabel(),
                    container: 'topLabel',
                    options: ['urgent'],
                    field: 'urgent',
                    editable: true,
                  },
                  {
                    type: 'number',
                    skipSelector: true,
                    label: 'purchase.labels.daysLimitForApproval'.getLabel(),
                    field: 'daysLimitForApproval',
                    editable: true,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Item Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            columnsPerRow: 4,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'purchase.labels.itemName'.getLabel(),
                    field: 'purchaseRequestAsset.item.name',
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.approximateCost'.getLabel(),
                    field: 'purchaseRequestAsset.approximateCost',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.reasonForRepair'.getLabel(),
                    field: 'purchaseRequestAsset.reasonForRepair',
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: ' ',
                    value: '',
                    editable: false,
                  },
                  {
                    type: 'link',
                    render: (renderProps) => {
                      const { data, setValue } = renderProps;
                      return (
                        <Action
                          navigation={navigation}
                          action={{
                            type: 'link',
                            link: () => ({
                              view: ROUTES.editPurchaseDepartmentTC.name,
                              params: {
                                item: { _id: data?.code?._id },
                                setValue,
                                data,
                              },
                            }),
                          }}
                        >
                          {data?.code ? (
                            <StandardButton label="Add T&C" width={100} />
                          ) : null}
                        </Action>
                      );
                    },
                  },
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'code',
                    label: 'purchase.labels.typeTAndC'.getLabel(),
                    variant: 'filled',
                    keyFiled: 'name',
                    suggestionField: 'name',
                    valueField: 'name',
                    model: MODELS.PURCHASE_DEPARTMENT_TC,
                    query: 'masterDataListForTable',
                    editable: true,
                    mandatory: true,
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            label: isCodeSelect ? 'purchase.labels.termsAndConditions'.getLabel() : '',
            groups: [
              {

                columns: [
                  {
                    field: 'conditions',
                    fieldType: 'nested',
                    columnsPerRow: 3,
                    editable: true,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          {
                            type: 'checkBoxGroup',
                            field: 'checkBoxGroupInput',
                            header: ' ',
                            options: [{ value: '1' }],
                            width: 30,
                          },
                          {
                            type: 'text',
                            field: 'sNo',
                            variant: 'filled',
                            header: 'S No.',
                            editable: false,
                            width: 100,
                          },
                          {
                            type: 'text',
                            field: 'name',
                            variant: 'filled',
                            header: 'purchase.header.tAndCName'.getLabel(),
                            width: 300,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'description',
                            variant: 'filled',
                            header: 'purchase.header.description'.getLabel(),
                            editable: false,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default CreateRepairRequestForAsset;
