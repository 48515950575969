import React from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { assetStatus, MODELS, rateContractStatus, supervisorAndInChargeAssetStatus } from '../../../../Lib/constants';
import { Form } from '../../../../app-components/form/Form';
import { Text, View } from '../../../../app-components';
import { AssetDetails } from '../../../../Queries/asset';
import FileLink from '../../../../Components/FileLink';
import vars from '../../../../theme/vars';

const ViewInChargeAssetDetail = (props) => {
  const { item } = props;

  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      closeView={1}
      editable={false}
      uri={AssetDetails({ filter: { _id: item?._id } })}
      reloadOnChangeEvent={`reload${MODELS.ASSET}`}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.serialNo'.getLabel(),
                    field: 'serialNumber',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.selectItemName'.getLabel(),
                    field: 'item.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'item.category.name',
                  },
                  {
                    type: 'text',
                    label: 'Status',
                    render: ({ data }) => {
                      let color = '';
                      let status = data?.assetStatus;
                      if (data?.assetStatus === assetStatus[0]) {
                        color = vars.colors.primary.color2;
                      }
                      if (
                        data?.assetStatus === assetStatus[4]
                        || data?.assetStatus === assetStatus[5]
                      ) {
                        color = vars.colors.error;
                      }
                      if (data?.assetStatus === assetStatus[3]) {
                        color = vars.colors.warning;
                      }
                      if (data?.assetStatus === supervisorAndInChargeAssetStatus.inAuction) {
                        status = 'In-Auction';
                        color = vars.colors.warning;
                      }
                      return (
                        <Text style={{ ...vars.headings.h8, color }}>
                          {status}
                        </Text>
                      );
                    },

                  },
                  {
                    type: 'text',
                    label: 'asset.labels.repairReturnDate'.getLabel(),
                    field: '',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.cost'.getLabel(),
                    field: 'purchasedPrice',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.service'.getLabel(),
                    field: 'isAMC',
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'FINANCE DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 7,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.manufacturer'.getLabel(),
                    field: 'batchNumber.manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.supplier'.getLabel(),
                    field: 'batchNumber.supplier.name',
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.contractEndDate'.getLabel(),
                    render: ({
                      data: {
                        item: {
                          rateContract = [],
                        } = {},
                        batchNumber: {
                          supplier: batchSupplier = {},
                        } = {},
                      } = {},
                    }) => {
                      const result = rateContract.find(({
                        supplier = {},
                      }) => supplier?._id === batchSupplier?._id);

                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text
                            style={{ ...vars.headings.h8 }}
                          >
                            {moment(result?.rateContractValidity).format('DD MMM YY')}
                          </Text>
                        </View>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'Contract Status',
                    render: ({
                      data: {
                        item: {
                          rateContract = [],
                        } = {},
                        batchNumber: {
                          supplier: batchSupplier = {},
                        } = {},
                      } = {},
                    }) => {
                      const result = rateContract.find(({
                        supplier = {},
                      }) => supplier?._id === batchSupplier?._id);

                      let statusColor = '';
                      let status = '';
                      if (moment(result?.rateContractValidity) <= moment()) {
                        statusColor = vars.colors.error;
                        status = rateContractStatus.expired;
                      } else {
                        statusColor = vars.colors.primary.color2;
                        status = rateContractStatus.onGoing;
                      }

                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8 }}>{status}</Text>
                          <View style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: statusColor,
                            marginTop: 4.5,
                            marginLeft: 7,
                          }}
                          />
                        </View>
                      );
                    },
                    variant: 'filled',
                    editable: false,
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.supplyReceivedOn'.getLabel(),
                    field: 'supplyReceivedOn',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.lastRepairDate'.getLabel(),
                    field: 'lastRepairDate',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.supervisor'.getLabel(),
                    field: 'supervisor.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.inCharge'.getLabel(),
                    field: 'inCharge.name',
                  },
                  {
                    type: 'file',
                    field: 'documents',
                    label: 'asset.labels.attachDocuments'.getLabel(),
                    container: 'topLabel',
                    render: () => {
                      const value = item?.documents;
                      if (Array.isArray(value)) {
                        return value.map((file, index) => <FileLink key={index} file={file} />);
                      }
                      return <View />;
                    },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'SERVICES',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 7,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'serviceItemDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: false,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          backgroundColor: 'white',
                          numberOfLines: 1,
                          marginBottom: 10,
                        },
                        headerRowContainerStyle: { backgroundColor: 'white', marginLeft: 10 },
                        columns: [
                          {
                            type: 'date',
                            header: 'asset.headers.preventiveDate'.getLabel(),
                            field: 'preventiveDate',
                          },
                          {
                            type: 'date',
                            header: 'asset.headers.serviceDate'.getLabel(),
                            field: 'serviceDate',
                          },

                          {
                            type: 'text',
                            header: 'asset.headers.visitStatus'.getLabel(),
                            // field: 'serviceStatus',
                            render: ({ item: paramItem }) => {
                              if (paramItem?.serviceStatus) {
                                return (
                                  <Text style={{ ...vars.headings.h9 }}>
                                    {upperFirst(paramItem?.serviceStatus)}
                                  </Text>
                                );
                              }
                              return (
                                <Text style={{ ...vars.headings.h9 }}>
                                  {moment(paramItem?.serviceDate) > new Date() ? 'Upcoming' : 'Pending'}
                                </Text>
                              );
                            },
                          },
                          {
                            type: 'number',
                            header: 'asset.headers.repairCharge'.getLabel(),
                            field: 'repairPrice',
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewInChargeAssetDetail;
