import { MODELS } from '../../../Lib/constants';

const UserLOPDrillAttendanceFields = {
  _id: 1,
  attendance_date: 1,
  in_time: 1,
  progress_hours: 1,
  out_time: 1,
  second_attendance_type_id: { _id: 1, name: 1 },
  first_attendance_type_id: { _id: 1, name: 1 },
  approved_status: 1,
  time_in_office: 1,
  break_time: 1,
  remarks: 1,
  punch_status_id: 1,
  is_short_leave: 1,
  is_approved: 1,
  consider_short_leave: 1,
  was_manual_update: 1,
  punch_data: 1,
  status: 1,
  call_from: 1,
  employee: {
    _id: 1,
  },
};

export const userLeaveLopSheetUri = (rest) => ({
  query: {
    ...rest,
    sort: {
      _id: -1,
    },
  },
  model: 'MonthlyAttendance',
});

export const UserLOPDrillUri = ({ addOnFilter }) => ({
  query: {
    id: 'attendances',
    fields: {
      ...UserLOPDrillAttendanceFields,
    },
    sort: {
      _id: -1,
    },
    addOnFilter,
  },
  model: MODELS.ATTENDANCE,
});
