import { afterFetchForm } from '../../../app-components/data-fetch/DataFetchFunctions';

export const dateFormatX = (hour, min, format) => {
  let updatedHour = 0;
  if (format === 'PM') {
    updatedHour = hour + 12;
  } else updatedHour = hour;
  const temp = `${updatedHour}:${min}`;
  const currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  const result = new Date(`${currentDate} ${temp}`);
  return result;
};

export const afterUriFetch = ({ dataAfterfetch, insertData }) => {
  const { state } = afterFetchForm(dataAfterfetch);
  const { data = {} } = state;

  const newData = {
    ...data,
    ...insertData,
  };
  const result = {
    ...dataAfterfetch,
    state: { ...state, data: newData },
  };
  return result;
};
