/* eslint-disable consistent-return */
import React from 'react';

import vars from '../../../../../theme/vars';
import { FormHoc } from '../../../../../app-components';
import { submit } from '../../../../../AppServices';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import {
  MODELS, bloodStoringTypes, bloodBankBagCapacityTypes, bloodBankStorageTypes,
} from '../../../../../Lib/constants';
import { validateMultipleFileTypesAllowed } from '../../../../../Lib/helpers';

const styles = {
  autoSuggestContainer: {
    backgroundColor: '#F2F2F2',
  },
};

const AddBloodDonation = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item: { bloodGroup = '', _id },
        },
      } = {},
    } = {},
  } = props;
  const AddBloodDonationForm = FormHoc({
    type: 'standard',
    closeView: 1,
    mandatory: {
      date: 1,
      tubeNo: 1,
      bagWeight: 1,
      bloodType: 1,
      name: 1,
    },
    submitMessage: 'donorAndDonations.message.addDonation'.getLabel(),
    uniqueValidationMessage: 'donorAndDonations.message.bagNoExist'.getLabel(),
    onSubmit: submit({
      model: MODELS.BLOOD_DONATION,
    }),
    defaultValues: () => ({
      bloodDonor: { _id },
      bloodGroup,
    }),
    reloadEvent: `reload${MODELS.BLOOD_DONATION}`,
    lg: {
      validations: {
        typeOfBloodBag: ({ data }) => {
          if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE) {
            if (!data?.typeOfBloodBag) {
              return 'donorAndDonations.message.bloodBag'.getLabel();
            }
          }
        },
        attachment: ({ data }) => validateMultipleFileTypesAllowed(data?.attachment, ['pdf']),
        fppWeight: ({ data }) => {
          if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE && !data?.fppWeight
            && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
          ) {
            return 'donorAndDonations.message.fpp'.getLabel();
          }
        },
        prbcWeight: ({ data }) => {
          if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE && !data?.prbcWeight
            && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
          ) {
            return 'donorAndDonations.message.prbc'.getLabel();
          }
        },
        rdpPlateletsWeight: ({ data }) => {
          if (
            data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
            && !data?.rdpPlateletsWeight
            && data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
          ) {
            return 'donorAndDonations.message.platelets'.getLabel();
          }
        },
        sdpPlateletsWeight: ({ data }) => {
          if (
            data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
            && !data?.sdpPlateletsWeight
            && data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
          ) {
            return 'donorAndDonations.message.platelets'.getLabel();
          }
        },
        wholeBloodExpiryDate: ({ data }) => {
          if (
            data?.bloodType === 'Save as Whole Blood'
            && !data?.wholeBloodExpiryDate
          ) {
            return 'This field cannot be empty';
          }
        },
        fppExpiryDate: ({ data }) => {
          if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
            && !data?.fppExpiryDate
            && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)

          ) {
            return 'This field cannot be empty';
          }
        },
        prbcExpiryDate: ({ data }) => {
          if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
             && !data?.prbcExpiryDate
            && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
          ) {
            return 'This field cannot be empty';
          }
        },
        rdpPlateletsExpiryDate: ({ data }) => {
          if (
            data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
            && !data?.rdpPlateletsExpiryDate
            && data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
          ) {
            return 'This field cannot be empty';
          }
        },
        sdpPlateletsExpiryDate: ({ data }) => {
          if (
            data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
            && !data?.sdpPlateletsExpiryDate
            && data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
          ) {
            return 'This field cannot be empty';
          }
        },
      },
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'donorAndDonations.labels.donationDate'.getLabel(),
                  variant: 'filled',
                  field: 'date',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'donorAndDonations.labels.bagNo'.getLabel(),
                  variant: 'filled',
                  field: 'name',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'donorAndDonations.labels.tubeNo'.getLabel(),
                  variant: 'filled',
                  field: 'tubeNo',
                  mandatory: true,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'donorAndDonations.labels.bloodGroup'.getLabel(),
                  variant: 'filled',
                  field: 'bloodGroup',
                  value: bloodGroup,
                  mandatory: true,
                  disabled: true,
                  buttonContainerStyle: styles.autoSuggestContainer,
                },

                {
                  type: 'number',
                  label: 'donorAndDonations.labels.bagWeight'.getLabel(),
                  variant: 'filled',
                  field: 'bagWeight',
                  mandatory: true,
                },
                {
                  type: 'text',
                  label: 'donorAndDonations.labels.remarks'.getLabel(),
                  variant: 'filled',
                  field: 'description',
                  allowWhiteSpaces: true,
                },
                {
                  type: 'file',
                  field: 'attachment',
                  multiple: true,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  container: 'topLabel',
                  field: 'bagCapacityType',
                  label: 'Bag Capacity',
                  type: 'radioGroup',
                  options: bloodBankBagCapacityTypes,
                },
                {
                  container: 'topLabel',
                  field: 'bloodType',
                  label: 'Blood storing type',
                  type: 'radioGroup',
                  options: bloodStoringTypes,
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'date',
                  label: 'donorAndDonations.labels.expiryDate'.getLabel(),
                  placeholder: 'donorAndDonations.placeholders.enterExpiryDate'.getLabel(),
                  field: 'wholeBloodExpiryDate',
                  mandatory: true,
                  variant: 'filled',
                  visible: ({ data }) => {
                    if (data?.bloodType === 'Save as Whole Blood') {
                      return true;
                    }
                    return false;
                  },
                },
                autoSuggestInput({
                  field: 'typeOfBloodBag',
                  label: 'Centrifuge Category',
                  mandatory: true,
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  visible: ({ data }) => {
                    if (data?.bloodType === bloodBankStorageTypes.CENTRIFUGE) {
                      return true;
                    }
                    return false;
                  },
                  options: [
                    {
                      name: 'SDP Platelets',
                      value: 'SDP Platelets',
                    },
                    {
                      name: 'Double Bag',
                      value: 'Double Bag',
                    },
                    {
                      name: 'Triple Bag',
                      value: 'Triple Bag',
                    },
                    {
                      name: 'Quadruple Bag',
                      value: 'Quadruple Bag',
                    },
                  ],
                }),
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  editable: false,
                  label: 'CATEGORY',
                  value: '',
                  width: 150,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  label: 'WEIGHT OF BAG (GM)',
                  value: '',
                  editable: false,
                  width: 250,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  label: 'EXPIRY DATE',
                  value: '',
                  editable: false,
                  width: 250,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  label: 'UNIT',
                  value: '',
                  style: { paddingLeft: 40 },
                  width: 380,
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  editable: false,
                  label: 'FFP',
                  width: 150,
                  value: '',
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'number',
                  placeholder: 'donorAndDonations.placeholders.typeHere'.getLabel(),
                  variant: 'filled',
                  field: 'fppWeight',
                  width: 250,
                  mandatory: true,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'date',
                  field: 'fppExpiryDate',
                  label: 'donorAndDonations.labels.expiryDate'.getLabel(),
                  mandatory: true,
                  width: 250,
                  placeholder: 'donorAndDonations.placeholders.enterExpiryDate'.getLabel(),
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  value: '1',
                  style: { paddingLeft: 40 },
                  mandatory: true,
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'PRBC',
                  width: 150,
                  value: '',
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'number',
                  placeholder: 'donorAndDonations.placeholders.typeHere'.getLabel(),
                  variant: 'filled',
                  field: 'prbcWeight',
                  width: 250,
                  mandatory: true,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },

                {
                  type: 'date',
                  field: 'prbcExpiryDate',
                  mandatory: true,
                  width: 250,
                  label: 'donorAndDonations.labels.expiryDate'.getLabel(),
                  placeholder: 'donorAndDonations.placeholders.enterExpiryDate'.getLabel(),
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  value: '1',
                  style: { paddingLeft: 40 },
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                      && !(data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS)
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'RDP Platelets',
                  value: '',
                  width: 150,
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      (data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
                        || data?.typeOfBloodBag === bloodBankStorageTypes.QUADRUPLE_BAG)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'number',
                  placeholder: 'donorAndDonations.placeholders.typeHere'.getLabel(),
                  variant: 'filled',
                  field: 'rdpPlateletsWeight',
                  width: 250,
                  mandatory: true,
                  visible: ({ data }) => {
                    if (
                      (data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
                      || data?.typeOfBloodBag === bloodBankStorageTypes.QUADRUPLE_BAG)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },

                {
                  type: 'date',
                  field: 'rdpPlateletsExpiryDate',
                  variant: 'filled',
                  label: 'donorAndDonations.labels.expiryDate'.getLabel(),
                  width: 250,
                  mandatory: true,
                  placeholder: 'donorAndDonations.placeholders.enterExpiryDate'.getLabel(),
                  visible: ({ data }) => {
                    if (
                      (data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
                        || data?.typeOfBloodBag === bloodBankStorageTypes.QUADRUPLE_BAG)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  value: '1',
                  style: { paddingLeft: 40 },
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      (data?.typeOfBloodBag === bloodBankStorageTypes.TRIPLE_BAG
                        || data?.typeOfBloodBag === bloodBankStorageTypes.QUADRUPLE_BAG)
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columnsPerRow: 4,
              columns: [
                {
                  type: 'text',
                  label: 'SDP Platelets',
                  value: '',
                  width: 150,
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'number',
                  placeholder: 'donorAndDonations.placeholders.typeHere'.getLabel(),
                  variant: 'filled',
                  field: 'sdpPlateletsWeight',
                  width: 250,
                  mandatory: true,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },

                {
                  type: 'date',
                  field: 'sdpPlateletsExpiryDate',
                  variant: 'filled',
                  label: 'donorAndDonations.labels.expiryDate'.getLabel(),
                  width: 250,
                  mandatory: true,
                  placeholder: 'donorAndDonations.placeholders.enterExpiryDate'.getLabel(),
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  type: 'text',
                  value: '1',
                  style: { paddingLeft: 40 },
                  editable: false,
                  visible: ({ data }) => {
                    if (
                      data?.typeOfBloodBag === bloodBankStorageTypes.SDP_PLATELETS
                      && data?.bloodType === bloodBankStorageTypes.CENTRIFUGE
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  });
  return <AddBloodDonationForm {...props} />;
};
export default AddBloodDonation;
