import React, { useCallback } from 'react';

import {
  View,
  Text,
  Image,
  Toast,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import ExistingPatientUHIDSearchForm from './Components/searchExistingPatientByUHID';
import ListActionModal from '../../../../Components/ListActionModal';

import { barcode, greenTick, personIcon } from '../../../../images';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import { fetch } from '../../../../AppServices';

const AddPatientModalContent = (props) => {
  const { navigation: { state: { params: { activeSession } } } } = props;
  const { navigation } = props;

  const handleSearchPatientByUHID = useCallback(
    async (data) => {
      const { data: { UHID: uhid } = {} } = data;
      if (!uhid) {
        Toast.show({
          message: 'Error',
          description: 'patient.messages.enterUHIDToSearch'.getLabel(),
          type: 'error',
          position: 'top',
          direction: 'right',
        });
      } else {
        const patientInfo = await fetch({
          uri: {
            props: {
              query: {
                id: 'patientDataList',
                addOnFilter: {
                  uhid,
                },
              },
              model: MODELS.PATIENTS,
            },
          },
        });

        if (patientInfo?.data.length > 0) {
          if (patientInfo?.data[0]?.lastConsultation?._id) {
            navigation.push({
              view: ROUTES.vaccineDetailForPatientModal.name,
              params: { patientDetails: patientInfo?.data[0], activeSession },
            });
          } else {
            Toast.show({
              message: 'Error',
              description: 'Patient has not consulted with doctor yet.',
              type: 'error',
              position: 'top',
              direction: 'right',
            });
          }
        } else {
          Toast.show({
            message: 'Error',
            description: 'patient.messages.incorrectUHID'.getLabel(),
            type: 'error',
            position: 'top',
            direction: 'right',
          });
        }
      }
    },
    [activeSession, navigation],
  );

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          display: 'block',
          textAlign: 'center',
        }}
      >
        <Image source={barcode} />
      </View>
      <View
        style={{
          justifyContent: 'center',
          marginTop: 20,
          flexDirection: 'row',
        }}
      >
        <Text
          style={{
            padding: '6px 12px 6px 12px',
            ...vars.headings.h13,
            color: vars.colors.secondary.color2,
          }}
        >
          {'patient.labels.scanner'.getLabel()}
        </Text>
        <Image source={greenTick} />
      </View>
      <Form
        defaultValues={() => ({
          relation: 'S/O',
        })}
        onSubmit={handleSearchPatientByUHID}
        bodyContainerStyle={{
          backgroundColor: vars.colors.white,
          flex: 1,
          justifyContent: 'space-between',
          marginTop: 20,
          marginBottom: 30,
        }}
        {...props}
      >
        <ExistingPatientUHIDSearchForm
          {...props}
        />
      </Form>
    </View>
  );
};

const AddPatientModal = (props) => (
  <ListActionModal
    icon={personIcon}
    title="Add Patient"
    content={<AddPatientModalContent {...props} />}
    {...props}
  />
);

export default AddPatientModal;
