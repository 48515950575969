import React from 'react';
import {
  cancelButton, save, OutlineButton, StandardButton,
} from '../../../app-components';
import { invoke } from '../../../AppServices';

import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { ROUTES } from '../../../Lib/constants';
import NewContingentForm from './ContingentVoucherForm';
import CotingentVoucherPreview from './ContingentVoucherPreview';
import CotingentVoucherApproval from './CotingentVoucherApproval';
import CotingentVoucherRejected from './RejectModal';
import SendToReviewModal from './ReviewModal';
import FilesAndBillsTabs from './Tabs';
import ViewCotingentVoucherRequest from './ViewCotingentVoucherRequest';

export default {
  [ROUTES.filesAndBills.name]: {
    screen: FilesAndBillsTabs,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Files and Bills" />
      ),
    },
  },
  [ROUTES.newContingentForm.name]: {
    screen: NewContingentForm,
    expanded: true,
    headerActions: [
      cancelButton,
      save({ label: 'Create and Preview' }),
    ],
  },
  [ROUTES.cotingentVoucherPreview.name]: {
    screen: CotingentVoucherPreview,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 1194,
      height: 516,
    },
  },
  [ROUTES.cotingentVoucherApproval.name]: {
    screen: CotingentVoucherApproval,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 620,
      height: 397,
    },
  },
  [ROUTES.cotingentVoucherRejected.name]: {
    screen: CotingentVoucherRejected,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 620,
      height: 397,
    },
  },
  [ROUTES.SendToReviewModal.name]: {
    screen: SendToReviewModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 620,
      height: 397,
    },
  },
  [ROUTES.viewContingentVoucherRequest.name]: {
    screen: ViewCotingentVoucherRequest,
    expanded: true,
    title: 'BILLS AND CONTINGENT DETAILS',
    headerActions: [
      {
        render: (props) => {
          const { data } = props;
          if (data?.status === 'Rejected' || data?.status === 'Payment Pending') { return ''; }
          return (
            <OutlineButton
              onClick={() => props.navigation.push({
                view: ROUTES.cotingentVoucherRejected.name,
                params: { data: props?.data },
              })}
              label="REJECT"
            />
          );
        }
        ,
      },
      {
        render: (props) => {
          const { data } = props;
          if (data?.status === 'Rejected' || data?.status === 'Payment Pending') { return ''; }
          return (
            <StandardButton
              onClick={() => props.navigation.push({
                view: ROUTES.SendToReviewModal.name,
                params: { data: props?.data },
              })}
              label="Send for Review"
            />
          );
        },
      },
      {
        render: (props) => {
          const { data } = props;
          const temp = data?.assignedToRole ? data?.assignedToRole[data.assignedToRole.length - 1].role === 'DIRECTOR' : '';
          if (data?.status === 'Rejected' || data?.status === 'Payment Pending') { return ''; }
          if (temp && !data?.isReview) {
            return (
              <StandardButton
                onClick={() => props.navigation.push({
                  view: ROUTES.cotingentVoucherApproval.name,
                  params: { data, approve: 'approve' },
                })}
                label="Approve"
              />
            );
          }
          return (
            <StandardButton
              onClick={() => props.navigation.push({
                view: ROUTES.cotingentVoucherApproval.name,
                params: { data },
              })}
              label="Send for Approval"
            />
          );
        },
      },
    ],
  },
};
