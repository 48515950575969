/* eslint-disable react/jsx-key */
import React from 'react';
import {
  DateFilter,
  FilterGroup,
} from '../../../../../../app-components';
import FtsSearch from '../../../../../../Components/TableFilters/FtsSearch';
import { countUri } from '../../../../../../Queries/countUri';
import { bloodGroup, MODELS } from '../../../../../../Lib/constants';
import BloodRecordsDonationList from '../../DonationList';
import BloodRecordsIssuingList from '../../IssuingList';
import { filterActive, filterInactive } from '../../../../../../images';
import { TabNavigator } from '../../../../../../app-components/tab/TabNavigator';
import { tabTheme } from '../../../BloodBankInventory/tabTheme';

const BloodInventoryRecordsTabs = (props) => (
  <TabNavigator
    theme={{ ...tabTheme }}
    tabs={{
      Issuing: {
        label: 'Issuing',
        screen: BloodRecordsIssuingList,
        actions: [
          <FtsSearch
            action={{ exp: ['issueId', 'issueDate', 'uhid.patientName'] }}
          />,
          <DateFilter field="issueDate" key="issueDate" />,
        ],
        screenProps: {
          model: MODELS.BLOOD_BANK,
          id: 'bloodRequestApprovedDetail',
          addOnFilter: { is_approve: true },
        },
        countUri: countUri({
          model: MODELS.BLOOD_REQUEST,
          id: 'bloodRequestApprovedDetail',
          filter: { issueId: { $exists: true } },
        }),
      },
      Donations: {
        label: 'Donations',
        screen: BloodRecordsDonationList,
        actions: [
          <FtsSearch
            action={{
              exp: ['donationNo', 'bloodDonor.name'],
            }}
          />,
          <DateFilter field="date" key="date" />,
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggestOptions',
                options: bloodGroup,
                field: 'bloodGroup',
                label: 'Blood Group',
                multiSelect: true,
              },
            ],
          }),
        ],
        countUri: countUri({
          model: MODELS.BLOOD_DONATION,
          id: 'masterDataListForTable',
        }),
      },
    }}
    {...props}
  />
);
export default BloodInventoryRecordsTabs;
