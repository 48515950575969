import React from 'react';
import moment from 'moment';
import vars from '../../../../theme/vars';
import { Text, Table } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';

const tableItems = {
  lg: {
    columns: [
      {
        header: 'vaccination.headers.vaccineName'.getLabel(),
        type: 'text',
        field: 'item.name',
      },
      {
        header: 'vaccination.headers.vaccinationStatus'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { vaccineDoseTaken } = item;
          const { item: { noOfDoses } = {} } = item;
          if (vaccineDoseTaken === noOfDoses) {
            return (
              <Text style={{ ...vars.headings.h9, color: vars.colors.primary.color2 }}>
                Completed
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h9, color: vars.colors.warning }}>
              Ongoing
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.doseNo'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { vaccineDoseTaken } = item;
          const { item: { noOfDoses } = {} } = item;
          if (vaccineDoseTaken === noOfDoses) {
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {vaccineDoseTaken}
              </Text>
            );
          }
          if (vaccineDoseTaken) {
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {vaccineDoseTaken + 1}
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h9 }}>
              {1}
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.dosePending'.getLabel(),
        type: 'number',
        render: ({ item }) => {
          const { vaccineDoseTaken } = item;
          const { item: { noOfDoses } = {} } = item;
          if (vaccineDoseTaken) {
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {noOfDoses - vaccineDoseTaken}
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h9 }}>
              {noOfDoses}
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.lastVaccinationDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: 'lastVaccination._createdOn',
      },
      {
        header: 'vaccination.headers.doseDuseDate'.getLabel(),
        type: 'date',
        render: ({ item }) => {
          const { item: { vaccinationReOccurringDetails, isReoccurringIntervalEqual, vaccinationType }, vaccineDoseTaken, lastVaccination } = item;
          if (!vaccineDoseTaken) {
            return '--';
          }
          if (vaccinationType === 'reCurring' && isReoccurringIntervalEqual) {
            const { interval, intervalType } = vaccinationReOccurringDetails[0];
            const dueDate = moment(lastVaccination?._createdOn).add(interval, intervalType).format('DD-MMM-YYYY');
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {dueDate}
              </Text>
            );
          }
          if (vaccinationType === 'reCurring') {
            if (vaccineDoseTaken <= vaccinationReOccurringDetails.length - 1) {
              const { interval, intervalType } = vaccinationReOccurringDetails[vaccineDoseTaken];
              const dueDate = moment(lastVaccination?._createdOn).add(interval, intervalType).format('DD-MMM-YYYY');
              return (
                <Text style={{ ...vars.headings.h9 }}>
                  {dueDate}
                </Text>
              );
            }
            return '--';
          }
          return '--';
        },
      },
      {
        header: 'vaccination.headers.action'.getLabel(),
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <Text style={{ color: vars.colors.secondary.color2 }}>
                View
              </Text>
            ),
            type: 'link',
            link: ({ item }) => ({
              view: ROUTES.viewVaccineDetailList.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

const VaccinationHistoryLogs = (props) => {
  const {
    addOnFilter, model, id, navigation = {},
  } = props;

  const {
    state: { params = {} } = {},
  } = navigation;

  const VaccinationHistoryLogsTable = Table({
    uri: () => ({
      query: {
        id: id || params?.id,
        addOnFilter: addOnFilter || params?.addOnFilter,
        sort: { _createdOn: -1 },
      },
      model: model || params?.model,
    }),
    hoverable: false,
    ...tableItems,
    ...props,
  });
  return <VaccinationHistoryLogsTable {...props} />;
};

export default VaccinationHistoryLogs;

