import React from 'react';
import moment from 'moment';

import {
  ActivityIndicator,
  Image,
  TouchableOpacity,
} from '../../app-components';
import MasterList from '../Master/MasterList';
import { ROUTES } from '../../Lib/constants';
import { editRecord } from '../../images';
import { timeConversion } from '../../Lib/helpers';
import permissions from '../../Lib/permissions';
import WithPermission from '../../Components/WithPermission';

const tabListColumns = [
  {
    header: 'employeeSchedule.header.dateRange'.getLabel(),
    type: 'text',
    value: ({ item }) =>
      moment(item?.fromDate).format('DD MMM YYYY') +
      ' - ' +
      moment(item?.endDate).format('DD MMM YYYY'),
  },
  {
    header: 'employeeSchedule.header.shiftType'.getLabel(),
    type: 'text',
    width: 300,
    value: ({ item: { ShiftType = {} } }) =>
      ShiftType?.map(
        (shift) =>
          ` ${shift?.name}(${timeConversion(
            shift?.startTime,
          )} to ${timeConversion(shift?.endTime)})`,
      ).toString(),
  },
  {
    header: 'employeeSchedule.header.weekdaysSchedule'.getLabel(),
    type: 'text',
    value: ({ item }) => {
      const days = [];
      if (item?.monday) {
        days.push('Mon');
      }
      if (item?.tuesday) {
        days.push('Tue');
      }
      if (item?.wednesday) {
        days.push('Wed');
      }
      if (item?.thursday) {
        days.push('Thu');
      }
      if (item?.friday) {
        days.push('Fri');
      }
      if (item?.saturday) {
        days.push('Sat');
      }
      if (item?.sunday) {
        days.push('Sun');
      }
      return days?.map((day) => ' ' + day).toString();
    },
  },
  {
    header: 'employeeSchedule.header.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'employeeSchedule.labels.actions'.getLabel(),
    type: 'text',
    render: () => (
      <WithPermission
        access_key={
          permissions.mdm.views.modifySchedule.permissions.modifySchedule.value
        }>
        <TouchableOpacity>
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        </TouchableOpacity>
      </WithPermission>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editEmployeeSchedule.name,
        params: { item },
      },
    }),
  },
];

const EmployeeScheduleTabList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  if (!data?._id) {
    return <ActivityIndicator />;
  }

  return (
    <MasterList
      {...restProps}
      hoverable={false}
      uri={{
        query: {
          id,
          addOnFilter,
        },
        model,
      }}
      tableColumns={tableColumns}
      showActionIcon={false}
    />
  );
};
export default EmployeeScheduleTabList;
