import React from 'react';
import {
  OutlineButton,
} from '../../../../../app-components';
import {
  TabNavigator,
} from '../../../../../app-components/tab/TabNavigator';
import ActivityLogsTabList from '../../../../../Wired/ActivityLogList';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { countUri } from '../../../../../Queries/countUri';
import HolidayTabList from '../HolidayTabList';
import WithPermission from '../../../../../Components/WithPermission';
import permissions from '../../../../../Lib/permissions';

const ViewHolidayTabs = (props) => {
  const { showAddButton = true, navigation } = props;
  const data = navigation?.state?.params?.item;

  return (
    <TabNavigator
      {...props}
      tabs={{
        Holiday: {
          label: 'holidayCalendar.title.name'.getLabel(),
          screen: HolidayTabList,
          screenProps: {
            data,
            model: MODELS.HOLIDAYS,
            id: 'masterDataListForTable',
            addOnFilter: { holidayCalendar: { _id: data?._id } },
          },
          countUri: countUri({
            model: MODELS.HOLIDAYS,
            id: 'masterDataListForTable',
            filter: { holidayCalendar: { _id: data?._id } },
          }),
          actions: [
            {
              type: 'link',
              render: () => (showAddButton ? (
                <WithPermission
                  access_key={permissions.mdm.views.modifyHoliday.permissions.modifyHoliday.value}
                >
                  <OutlineButton label="Create Holidays" />
                </WithPermission>
              ) : (
                null
              )),
              link: (actionProps) => {
                const {
                  navigation: { state: { params: { item } = {} } = {} } = {},
                } = actionProps;
                return {
                  view: ROUTES.addHoliday.name,
                  modal: true,
                  params: {
                    holidayCalendar: {
                      _id: item?._id,
                    },
                  },
                  modalProps: {
                    autoHide: true,
                    width: 724,
                    height: 437,
                  },
                };
              },
            },
          ],
        },
        ActivityLogs: {
          label: 'holidayCalendar.title.activity'.getLabel(),
          screen: ActivityLogsTabList,
          screenProps: {
            hoverable: false,
            data,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: data?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: data?._id },
          }),
        },
      }}
    />
  );
};
export default ViewHolidayTabs;
