/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
  GradientButton,
  Toast,
  Image,
} from '../../../../app-components';
import DateRange from './DateRange';
import {
  h12_Regular,
  h14_Medium,
  h14_Regular,
  h15_Regular,
} from '../../../../theme/fonts';
import { invoke, fetch } from '../../../../AppServices';
import { colors } from '../../../../theme/colors';
import { doctorProfileContainerShadow } from '../../../../theme/shadows';
import { autoSuggestInput } from '../../../../autoSuggestions';
import { MODELS } from '../../../../Lib/constants';
import { AutoSuggestInput } from '../../../../app-components/input-components/Editors';
import { backCrossIcon } from '../../../../images';
import vars from '../../../../theme/vars';

const {
  themeContainer, themeTextColor, themeColor,
} = colors;

const Booking = (props) => {
  const { navigation, eventDispatcher } = props;

  const {
    state: {
      params: {
        item,
        setValue,
      } = {},
    } = {},
  } = navigation;

  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [switchToNextWeek, setSwitchToNextWeek] = useState(0);
  const [selectdDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [onPress, setOnPress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const checkSelectedDate = (time, selectedTime) => {
    const today = new Date(selectedTime);
    if (
      time.getDay() === today.getDay()
      && time.getDate() === today.getDate()
      && time.getMonth() === today.getMonth()
      && today.getFullYear() === time.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const checkToday = (time) => {
    const today = new Date();
    if (
      time.getDay() === today.getDay()
      && time.getDate() === today.getDate()
      && time.getMonth() === today.getMonth()
      && today.getFullYear() === time.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    eventDispatcher.listen('ScreenPop', () => {
      setCount(count + 1);
    });
    return () => {
      eventDispatcher.unlisten('ScreenPop');
    };
  }, []);

  const availableSlot = (date) => {
    const today = new Date();
    const slotGiven = new Date(date);
    const year = slotGiven.getFullYear() - today.getFullYear();
    const month = slotGiven.getMonth() - today.getMonth();
    const day = slotGiven.getDate() - today.getDate();
    const hrs = slotGiven.getHours() - today.getHours();
    const min = slotGiven.getMinutes() - today.getMinutes();
    if (
      year > 0
      || (year === 0 && month > 0)
      || (month === 0 && day > 0)
      || (day === 0 && hrs > 0)
      || (hrs === 0 && min > 0)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        if (!selectedDoctor?._id) {
          Toast.show({
            message: 'Error',
            type: 'error',
            position: 'top',
            direction: 'right',
            description: 'Please select a doctor.',
          });

          return {};
        }

        const query = {
          doctor: selectedDoctor?._id,
          period: {
            from: moment(new Date(selectdDate)).startOf('day').toDate(),
            to: moment(new Date(selectdDate)).endOf('day').toDate(),
          },
          status: 'Available',
        };

        let result = null;
        result = await invoke({
          paramValue: { query },
          id: 'getDoctorSlots',
        });

        const { data = [] } = result || {};
        if (Array.isArray(data) && data.length) {
          const timeSlot = [];
          if (checkToday(selectdDate)) {
            for (let i = 0; i < data.length; i += 1) {
              if (availableSlot(data[i].start_time)) {
                timeSlot.push(data[i]);
              }
            }
            setTimeSlots(timeSlot);
            setSelectedTimeSlotIndex(0);
            setSelectedTimeSlot(timeSlot[0]);
          } else {
            setTimeSlots(data);
            setSelectedTimeSlotIndex(0);
            setSelectedTimeSlot(data[0]);
          }
        } else if (checkToday(selectdDate)) {
          const result = await fetch({
            uri: {
              props: {
                query: {
                  id: 'nextAvailableSlot',
                  addOnFilter: {
                    doctor: { _id: selectedDoctor?._id },
                  },
                  limit: 1,
                  paramValue: {
                    period: {
                      from: moment().startOf('day').toDate(),
                    },
                  },
                },
                model: 'DoctorSlots',
              },
            },
          });

          const { data = {} } = result;

          if (
            selectdDate
              && data?.date
              && !checkSelectedDate(new Date(selectdDate), new Date(data?.date))
          ) {
            const weekDiffrence = moment(data?.date).isoWeek() - moment().isoWeek();
            if (weekDiffrence > 0) {
              setSwitchToNextWeek(weekDiffrence);
            }
            setSelectedDate(moment(data?.date).toDate());
          } else {
            setTimeSlots([]);
            setSelectedTimeSlotIndex(null);
            setSelectedTimeSlot(null);
          }
        } else {
          setTimeSlots([]);
          setSelectedTimeSlotIndex(null);
          setSelectedTimeSlot(null);
        }

        return {};
      } catch (e) {
        return e;
      } finally {
        setLoading(false);
      }
    })();
  }, [selectdDate, item, count, selectedDoctor]);

  return (
    <View
      style={{
        padding: 10,
        borderColor: 'rgba(56, 56, 56, 0.37)',
        flex: 1,
        justifyContent: 'space-between',
        overflow: 'hidden',
      }}
    >
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: themeContainer,
          padding: 10,
          paddingBottom: 0,
          borderRadius: 10,
          marginBottom: 10,
          ...doctorProfileContainerShadow,
        }}
      >
        <View
          style={{ alignItems: 'flex-end', paddingRight: 20 }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.pop();
            }}
            style={{ cursor: 'pointer' }}
          >
            <Image
              source={backCrossIcon}
              style={{
                width: 16,
                height: 16,
              }}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <View
            style={{
              width: 250,
              height: 80,
            }}
          >
            <Text
              style={{
                ...vars.headings.h9,
                marginBottom: 10,
              }}
            >
              Select doctor
            </Text>
            <AutoSuggestInput
              value={selectedDoctor}
              {... autoSuggestInput({
                type: 'autoSuggest',
                field: 'doctor',
                label: 'Doctor',
                placeholder: 'Doctor',
                keyField: 'name',
                valueField: 'name',
                suggestionField: 'name',
                model: MODELS.EMPLOYEE,
                query: 'employeeLists',
                variant: 'filled',
              },
              {
                addOnFilter: () => ({
                  role: {
                    code: 'DOCTOR',
                  },
                }),
              })

            }
              onItemSelect={(data) => {
                setSelectedDoctor(data?.item);
              }}
            />
          </View>
        </View>
        <View>
          <Text
            style={{ ...h15_Regular, color: themeTextColor, fontWeight: 600 }}
          >
            Book an Appointment
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 15,
            paddingBottom: 15,
            paddingRight: 24,
          }}
        >
          <Text style={{ ...h14_Regular, color: themeTextColor, opacity: 0.87 }}>
            Select Appointment Date
          </Text>
        </View>
        <DateRange
          setSelectedDate={setSelectedDate}
          checkToday={checkToday}
          key={switchToNextWeek}
          selectdDate={selectdDate}
          checkSelectedDate={checkSelectedDate}
          switchToNextWeek={switchToNextWeek}
        />
        <View
          style={{
            borderWidth: 1,
            borderColor: 'rgb(151,151,151, 0.2)',
            marginTop: 10,
            marginRight: 16,
          }}
        />
        {!loading ? (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 15,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  ...h14_Regular,
                  fontWeight: 600,
                  color: themeTextColor,
                }}
              >
                Showing Available Slots for selected day
              </Text>
            </View>
            <ScrollView
              style={{
                flex: 1,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {timeSlots.length ? (
                  timeSlots.map((slot, index) => (
                    <TouchableOpacity
                      key={index}
                      style={{
                        backgroundColor:
                          selectedTimeSlotIndex !== index ? '#fff' : themeColor,
                        width: 90,
                        justifyContent: 'center',
                        alignItems: 'center',
                        border:
                          selectedTimeSlotIndex !== index
                            ? '1px solid rgba(56, 56, 56, 0.11)'
                            : '',
                        height: 35,
                        borderRadius: 20,
                        margin: 10,
                        cursor: 'pointer',
                      }}
                      onPress={() => {
                        setOnPress(false);
                        setSelectedTimeSlotIndex(index);
                        setSelectedTimeSlot(slot);
                      }}
                    >
                      <Text
                        style={{
                          color:
                            selectedTimeSlotIndex !== index
                              ? themeColor
                              : themeTextColor,
                          fontWeight: 600,
                          ...h14_Medium,
                        }}
                      >
                        {moment(slot?.start_time).format('hh:mm A')}
                      </Text>
                    </TouchableOpacity>
                  ))
                ) : (
                  <Text
                    style={{
                      ...h14_Regular,
                      color: 'rgb(46,110,205, 0.87)',
                    }}
                  >
                    No Slot available for the selected day.
                  </Text>
                )}
              </View>
            </ScrollView>
          </>
        ) : (
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ActivityIndicator size="small" />
          </View>
        )}
      </View>
      <TouchableOpacity
        style={{
          cursor: 'pointer',
        }}
        onPress={() => {
          setTimeout(() => {
            setValue({ field: 'doctor', value: { _id: selectedDoctor?._id } });
          }, 100);

          setTimeout(() => {
            setValue({ field: 'slot', value: { _id: selectedTimeSlot?._id, start_time: selectedTimeSlot?.start_time } });
          }, 100);

          navigation.pop();
        }}
      >
        <GradientButton
          label="Confirm Appointment"
          buttonStyle={{ flex: 1 }}
        />
      </TouchableOpacity>
      {onPress
      && !availableSlot(selectedTimeSlot?.start_time)
      && selectedTimeSlot != null ? (
        <Text style={{ ...h12_Regular, color: 'red' }}>
          Selected slot has passed, Please select another slot.
        </Text>
        ) : null}
      {onPress && selectedTimeSlot === null ? (
        <Text style={{ ...h12_Regular, color: 'red' }}>
          Please select a slot.
        </Text>
      ) : null}
    </View>
  );
};

export default Booking;
