import React from 'react';

import { NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
// import { editIpdOrderUri } from '../../../../../../../../Queries/ipdOrders';
import { Form } from '../../../../../../../../app-components/form/Form';
import { Text } from '../../../../../../../../app-components';
import vars from '../../../../../../../../theme/vars';
import { MODELS } from '../../../../../../../../Lib/constants';
import { viewProgressReportUri } from '../../../../../../../../Queries/progressReport';

const ViewProgressDetail = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          uri={viewProgressReportUri(filter)}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'doctorOnRound',
                            label: 'Doctor On Round',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => 'Complaints',
                      },
                      {
                        type: 'text',
                        field: 'complaintOne',
                        editable: false,
                        variant: 'filled',
                      },
                      {
                        type: 'text',
                        field: 'complintTwo',
                        variant: 'filled',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'complaintThree',
                        variant: 'filled',
                        editable: false,
                      },
                      {
                        type: 'text',
                        field: 'complaintFour',
                        variant: 'filled',
                        editable: false,
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'fever',
                            label: 'Fever',
                            editable: false,
                          },
                          {
                            type: 'text',
                            label: 'Oral Acceptance',
                            field: 'oralAcceptance',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'bladder',
                            label: 'Bladder',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'bowel',
                            label: 'Bowel',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'gcs',
                            label: 'GCS',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'mentalStatus',
                            label: 'Mental Status',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            type: 'text',
                            field: 'isSensoryDeficit',
                            label: 'Sensory Deficit',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'sensoryDeficit',
                            editable: false,
                            style: { flex: 3 },
                            visible: (item) => item.data.isSensoryDeficit,
                          },
                          {
                            type: 'text',
                            field: 'isMotorDeficit',
                            label: 'Motor Deficit',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'motorDeficit',
                            editable: false,
                            style: { flex: 3 },
                            visible: (item) => item.data.isMotorDeficit,
                          },
                          {
                            type: 'text',
                            field: 'isDressingSoakage',
                            label: 'Dressing Soakage',
                            editable: false,
                          },
                          {
                            type: 'text',
                            visible: (item) => item.data.isDressingSoakage,
                            field: 'dressingSoakage',
                            editable: false,
                            style: { flex: 3 },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'text',
                            field: 'drainOutput',
                            label: 'Drain Output',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'intake',
                            label: 'InTake(ml)',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'output(ml)',
                            label: 'Output',
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowColumnTextStyle: {
                                  ...vars.headings.h10,
                                  color: vars.colors.grey.color3,
                                  numberOfLines: 1,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: false,
                                    width: 250,
                                    header: 'Drug',
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    mandatory: true,
                                    editable: false,
                                    width: 150,
                                    header: 'Route',
                                  },
                                  {
                                    type: 'text',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    mandatory: true,
                                    editable: false,
                                    width: 100,
                                    header: 'Dose',
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: false,
                                    mandatory: true,
                                    width: 200,
                                    header: 'Frequency',
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    editable: false,
                                    width: 312,
                                    header: 'Description',
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          {
                            type: 'text',
                            editable: false,
                            field: 'transferTo',
                            label: 'Transfer To',
                            variant: 'filled',
                          },
                          {
                            type: 'text',
                            editable: false,
                            field: 'assignTO',
                            label: 'Assign To',
                            variant: 'filled',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />
  );
};

const ViewProgressReport = (props) => (<ViewProgressDetail {...props} />);
export default ViewProgressReport;

