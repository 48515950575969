import React from 'react';
import { FilterGroup } from '../../app-components';
import { TableActionButton } from '../../app-components/buttons/Buttons';

import { TabNavigator } from '../../app-components/tab/TabNavigator';
import WithPermission from '../../Components/WithPermission';
import { filterActive, filterInactive } from '../../images';
import { MODELS, ROUTES } from '../../Lib/constants';
import permissions from '../../Lib/permissions';
import { EmployeePackage } from '../../screens/EmployeeManagement/EditEmployee/Salary/list';
import tabTheme from '../../theme/childTabTheme';
import vars from '../../theme/vars';
import GeneratedSalaryList from '../GenerateSalary';
import { UserTaxDeclarationListAll } from './TaxDeclarations/payroll/list';

const SalaryTabNavigation = (props) => {
  const { employee, canAddSalaryStructure } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...tabTheme }}
      tabs={{
        GeneratedSalary: {
          label: 'Generated Salary',
          screen: GeneratedSalaryList,
          screenProps: {
            addOnFilter: {
              employee: { _id: employee?._id },
            },
          },
          actions: [
            {
              render: (renderProps) => (
                <WithPermission
                  access_key={
                    permissions.departments.views.generateSalarySheet.permissions
                      .generateSalarySheet.value
                  }
                >
                  <TableActionButton
                    label="Generate Salary Statement"
                    width={230}
                    color={vars.colors.secondary.color2}
                    hoverColor={vars.colors.secondary.color2}
                    labelStyle={{
                      ...vars.headings.h7,
                      color: vars.colors.white,
                      numberOfLines: 1,
                    }}
                    {...renderProps}
                  />
                </WithPermission>
              ),
              type: 'link',
              link: () => ({
                view: ROUTES.salaryStatementModal.name,
                params: { employee_id: employee?._id },
                modal: true,
              }),
            },
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'month_id',
                  label: 'Month',
                  model: MODELS.MONTHS,
                  id: 'MonthTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Months',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: '_id',
                },
                {
                  type: 'autoSuggest',
                  field: 'year_id',
                  label: 'Year',
                  model: MODELS.years,
                  id: 'YearTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Year',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: '_id',
                },
              ],
            }),
          ],
        },
        CompensationStructure: {
          label: 'Compensation Structure',
          screen: EmployeePackage,
          screenProps: {
            addOnFilter: {
              employee_id: { _id: employee?._id },
            },
            canAddSalaryStructure,
          },
        },
        TaxDeclarations: {
          label: 'Tax Declarations',
          screen: UserTaxDeclarationListAll,
          screenProps: {
            addOnFilter: {
              employee_id: { _id: employee?._id },
            },
            addDeclaredAmount: true,
            addActualAmount: false,
          },
          actions: [
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'finantial_year_id',
                  label: 'Financial Year',
                  model: MODELS.FINANCIAL_YEAR,
                  id: 'financialyears',
                  searchField: 'name',
                  placeholder: 'Year',
                  sugestionField: 'financial_year',
                  primaryField: 'financial_year',
                  valueField: 'financial_year',
                },
              ],
            }),
          ],
        },
      }}
    />
  );
};

export default SalaryTabNavigation;
