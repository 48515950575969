import React from 'react';

import PersonalDetails from '../../EditEmployee/Profile/PersonalDetails';
import RegistrationDetails from '../../EditEmployee/Profile/RegistrationDetails';
import Statutory from '../../EditEmployee/Profile/Statutory';
import childtabTheme from '../../../../theme/childTabTheme';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';

const ProfileTab = (props) => (
  <TabNavigator
    theme={{ ...childtabTheme }}
    tabs={{
      PersonalDetails: {
        label: 'Personal Details',
        screen: PersonalDetails,
        screenProps: {
          editable: false,
          type: 'standard',
          visible: false,
          fromView: true,
        },
      },
      RegistrationDetails: {
        label: 'Registration Details',
        screen: RegistrationDetails,
        screenProps: {
          editable: false,
          type: 'standard',
          visible: false,
          fromView: true,
        },
      },
      Statutory: {
        label: 'Statutory',
        screen: Statutory,
        screenProps: {
          editable: false,
          fromView: true,
          type: 'standard',
          visible: false,
        },
      },
    }}
    {...props}
  />
);

export default ProfileTab;
