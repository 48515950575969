import React from 'react';

import HouseAllotmentTable from '../../HouseAllotment/HouseAllotmentTable';
import { TabNavigator } from '../../../../../app-components';
import { countUri } from '../../../../../Queries/countUri';
import ElectricityBillTabList from '../../ElectricityBill';
import { MODELS } from '../../../../../Lib/constants';

const ViewHouseTabs = (props) => {
  const { data } = props;
  if (!data?._id) {
    return null;
  }

  const HouseTabNavigations = TabNavigator({
    tabs: {
      HouseAllotment: {
        label: 'House Allotment',
        screen: HouseAllotmentTable,
        countUri: countUri({
          model: MODELS.HOUSE_ALLOTMENT,
          id: 'houseAllotmentDataList',
          filter: { houseID: { _id: data?._id } },
        }),
        reloadEvent: 'houseAllotmentDataList',
      },
      ElectricityBills: {
        label: 'Electricity Bills',
        screen: ElectricityBillTabList,
        countUri: countUri({
          model: MODELS.ELECTRICITY_BILL,
          id: 'electricityBillDataList',
          filter: { houseID: { _id: data?._id } },
        }),
        reloadEvent: 'electricityBillDataList',
      },
    },
  });

  return (
    <HouseTabNavigations
      {...props}
    />
  );
};
export default ViewHouseTabs;
