import React, { useCallback, useState } from 'react';

import {
  Form, Text, TouchableOpacity, View,
} from '../../../../../../../app-components';
import { Form as TagForm } from '../../../../../../../app-components/form/Form';
import { Table as TagTable } from '../../../../../../../app-components/table/Table';
import { getConfiguration, getDownloadUrl, submit } from '../../../../../../../AppServices';
import vars from '../../../../../../../theme/vars';
import { EmployeeDeclarationDetails } from './CommonTaxDeclarations';
import { Layouts } from './layouts';
import { getEmployeeSalary } from './uri';

const IncomeTaxNonEditableDetailHoc = ({
  editable, uri, header, footer,
}) => Form({
  onSubmit: submit({
    model: 'IncomeTax',
  }),
  closeView: 1,
  uri,
  header,
  editable,
  footer,
  submitMessage: 'Update Successfully',
  fieldVariant: 'filled',
  columnsPerRow: 2,
  reloadEvent: 'UserTaxDeclarationList',
  formGroups: [
    {
      type: 'custom',
      render: EmployeeDeclarationDetails,
    },
  ],
});

export const IncomeTaxDetail = IncomeTaxNonEditableDetailHoc({
  uri: (props) => {
    const { navigation } = props;

    const item = (navigation && navigation.getParams('item')) || {};

    return {
      id: 'incomeTaxCardTypeList',
      query: {
        id: 'incomeTaxDetailDeductionCard',
        addOnFilter: { _id: item && item._id },
        item,
      },
      props: {
        params: { item },
      },
      model: 'IncomeTax',
    };
  },
});

export const UserTaxDeclarationList = (props) => {
  const { addOnFilter, addDeclaredAmount, addActualAmount } = props;

  const [taxDeclarationDetail, setTaxDeclarationDetail] = useState({});
  const [currentActiveView, setCurrentActiveView] = useState(0);

  const handleOnPressRow = useCallback((item) => {
    setCurrentActiveView(1);
    setTaxDeclarationDetail(item);
  }, []);

  const configurationData = getConfiguration();
  const { attachmentForm16 = {} } = configurationData || {};

  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
        flexDirection: 'row',
      }}
    >
      { currentActiveView !== 2 && (
        <View
          style={{
            flex: currentActiveView === 1 ? 0.3 : 1,
            overflow: 'hidden',
          }}
        >
          <TagTable
            action={({ item }) => ({
              type: 'link',
              link: () => {
                handleOnPressRow(item);
              },
            })}
            uri={() => getEmployeeSalary(
              {
                model: 'IncomeTax',
                addOnFilter,
                query: 'selfIncometax',
              },
              { id: '_find' },
            )}
            listVersion={2}
            reloadEvent="UserTaxDeclarationList"
            lg={{
              columns: [
                {
                  width: 14,
                },
                Layouts.FinancialYear,
                Layouts.TaxCalculationDate,
                Layouts.NetTaxableIncome,
                Layouts.TotalTaxAmt,
                Layouts.TaxPaid,
                Layouts.PendingTax,
                {
                  ...Layouts.IsFreeze,
                  align: 'center',
                  width: 150,
                },
                {
                  type: 'moreActions',
                  actions: [
                    {

                      render: () => (
                        <TouchableOpacity
                          onPress={() => { window.open(getDownloadUrl(attachmentForm16), '_download'); }}
                        >
                          <Text
                            style={{ ...vars.headings.h8, color: vars.colors.secondary.color3 }}
                          >
                            Form 16
                          </Text>
                        </TouchableOpacity>
                      ),
                    },
                    {
                      text: 'Tax Sheet',
                      type: 'download',
                      service: () => ({
                        id: 'taxSheetCalculation',
                        paramValue: {
                          callFor: 'Tax Sheet',
                          model: 'IncomeTax',
                        },
                      }),
                    },
                  ],
                },
              ],
            }}
          />
        </View>
      )}
      { currentActiveView !== 0 && (
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <TagForm
            key={taxDeclarationDetail?._id}
            isSelected={(selectedItem) => selectedItem?.item?._id === taxDeclarationDetail?._id}
            onSubmit={submit({
              model: 'IncomeTax',
            })}
            closeView={1}
            uri={() => ({
              id: 'incomeTaxCardTypeList',
              query: {
                id: 'incomeTaxDetailDeductionCard',
                addOnFilter: { _id: taxDeclarationDetail && taxDeclarationDetail._id },
                taxDeclarationDetail,
              },
              props: {
                params: { item: taxDeclarationDetail },
              },
              model: 'IncomeTax',
            })}
            editable={false}
            submitMessage="Tax Declarations has been updated successfully"
            fieldVariant="filled"
            columnsPerRow={2}
            reloadEvent="UserTaxDeclarationList"
            formGroups={[
              {
                type: 'custom',
                render: (customViewProps) => (
                  <EmployeeDeclarationDetails
                    {...customViewProps}
                    currentActiveView={currentActiveView}
                    setCurrentActiveView={setCurrentActiveView}
                    addActualAmount={addActualAmount}
                    addDeclaredAmount={addDeclaredAmount}
                  />
                ),
              },
            ]}
            {...props}
          />
        </View>
      )}
    </View>

  );
};
