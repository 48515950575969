import React, { useCallback, useEffect, useState } from 'react';
import { Text } from 'react-native';
import { View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { fetch } from '../../../../../AppServices';
import { MODELS } from '../../../../../Lib/constants';
import { EditSupplier } from '../../../../../Queries/supplier';
import vars from '../../../../../theme/vars';
import ViewSupplierTabs from './components/Tabs';

const ViewSupplier = (props) => {
  const { navigation: { state: { params: { item: { _id } } } } } = props;
  const [item, setItem] = useState([]);

  const fetchItems = useCallback(async () => {
    const fetchedQueueDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'masterDataList',
            addOnFilter: { rateContract: { supplier: { _id }, rateContractValidity: { $gte: new Date() } } },
            fields: { name: 1 },
          },
          model: MODELS.PHARMACY_ITEM,
        },
      },
    });
    setItem(fetchedQueueDetails?.data);
    return fetchedQueueDetails;
  }, [_id]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return (
    <Form
      closeView={1}
      editable={false}
      uri={EditSupplier}
      renderLoading={false}
      reloadOnChangeEvent={`reload${MODELS.PHARMACY_SUPPLIER}`}
      model={MODELS.PHARMACY_SUPPLIER}
      lg={{
        formGroups: [
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            label: 'PURCHASE REQUEST DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'name',
                    label: 'asset.labels.supplierName'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'email',
                    label: 'asset.labels.email'.getLabel(),
                    editable: false,
                  },

                  {
                    type: 'number',
                    field: 'mobile',
                    editable: false,
                    label: 'asset.labels.contact'.getLabel(),
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 10) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'textArea',
                    field: 'address',
                    label: 'asset.labels.address'.getLabel(),
                    width: 830,
                    minHeight: 75,
                    editable: false,
                  },

                ],
              },
              {
                fieldVariant: 'filled',
                label: 'inventory.title.itemDetails'.getLabel(),
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 4,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'custom',
                        render: () => (
                          <View style={{ flexDirection: 'row' }}>
                            {item.map(({ name }) => (
                              <View
                                style={{
                                  padding: 10, margin: 5, borderRadius: 50, backgroundColor: vars.colors.white,
                                }}
                                key="name"
                              >
                                <Text style={{ ...vars.headings.h9 }}>
                                  {name}
                                </Text>
                              </View>
                            ))}
                          </View>
                        ),
                        header: 'Item',
                        label: 'Item',
                        variant: 'filled',
                        field: 'name',
                        editable: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },

        ],
      }}
      {...props}
    />
  );
};

export default (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1 }}>
      <ViewSupplier {...props} />
    </View>
    <View style={{ flex: 4, overflow: 'hidden' }}>
      <ViewSupplierTabs {...props} showAddButton={false} />
    </View>
  </View>
);
