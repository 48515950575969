import React, { useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  Image,
} from '../../app-components';

import { TextInput } from '../../app-components/input-components/Editors';
import { LargeButton } from '../../app-components/buttons/Buttons';
import { FormField } from '../../app-components/form/field';
import { Form } from '../../app-components/form/Form';
import { notificationError } from '../../images';
import vars from '../../theme/vars';
import loginStyle from './theme';
import { ROUTES } from '../../Lib/constants';
import { getConfiguration } from '../../AppServices';
import { LANDING_VIEW } from '../constansts';

export default ({ mamcAuthenticate, images }) => {
  const { scrollViewStyle } = loginStyle;
  const LoginForm = ({ navigation }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = ({ data }) => mamcAuthenticate(data).then((res) => {
      if (res.enforceChangePassword) {
        navigation.replace(ROUTES.ResetPassword.name);
      } else if (!res.error) {
        navigation.replace(LANDING_VIEW);
      } else {
        throw res && res.error;
      }
    });

    return (
      <ScrollView style={scrollViewStyle}>
        <Form
          scrollable={false}
          mandatory={{
            username: 'Employee code is mandatory',
            password: 'Password is mandatory',
          }}
          required={{
            username: 1,
            password: 1,
          }}
          onSubmit={handleSubmit}
          containerStyle={{
            flex: 1,
          }}
          bodyContainerStyle={{
            margin: 0,
            flex: 1,
          }}
          onSubmitError={setErrorMessage}
        >
          <LoginFormNew
            errorMessage={errorMessage}
            images={images}
          />
        </Form>
      </ScrollView>
    );
  };
  return LoginForm;
};

const LoginFormNew = ({ form_context: formContext, errorMessage, images }) => {
  const { handleSubmit } = formContext;
  const { bgImage, hospitalLiveLogo } = images;
  const {
    containerStyle,
    loginBoxStyle,
    loginLogoStyle,
    loginLogoContainer,
    errorStyle,
  } = loginStyle;

  const configurations = getConfiguration();

  return (
    <View
      style={{
        ...containerStyle,
        backgroundImage: `url(${bgImage})`,
        flex: 1,
        backgroundSize: 'cover',
      }}
    >
      <View style={{ loginBoxStyle }}>
        <View
          style={{
            ...loginBoxStyle,
            flex: 1,
          }}
        >
          <View style={loginLogoContainer}>
            <Image
              source={hospitalLiveLogo}
              style={{ ...loginLogoStyle }}
            />
          </View>
          <View style={{ marginTop: 42 }}>
            <FormField
              field="username"
              label="Employee Code"
              placeholder="Enter Employee Code"
              variant="filled"
              onSubmitEditing={handleSubmit}
              mandatory
            >
              <TextInput autoFocus />
            </FormField>
          </View>
          <View style={{ marginTop: 24 }}>
            <FormField
              field="password"
              label="Password"
              placeholder="Enter Password"
              variant="filled"
              mandatory
              onSubmitEditing={handleSubmit}
            >
              <TextInput inputProps={{ type: 'password' }} />
            </FormField>
          </View>
          <View style={{ marginTop: 42, display: 'flex', alignItems: 'center' }}>
            <LargeButton
              label={'login.buttons.login'.getLabel()}
              onClick={handleSubmit}
              width={109}
              height={40}
              borderRadius={4}
            />
          </View>
          {errorMessage ? (
            <View style={{
              flexDirection: 'row',
              marginTop: 27,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Image
                source={notificationError}
                style={{ height: 18, width: 18 }}
              />
              <Text style={{ ...errorStyle }}>{errorMessage}</Text>
            </View>
          ) : null}
          <Text style={{
            marginTop: 27,
            ...vars.headings.h13,
            textAlign: 'center',
            color: vars.colors.grey.color7,
            mixBlendMode: 'normal',
            opacity: 0.87,

          }}
          >
            Forgot Password? Please contact your administration on extension number
            {' '}
            {configurations?.adminContactNumber}
          </Text>
        </View>
      </View>
    </View>
  );
};
