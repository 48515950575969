import React, { useCallback, useEffect, useState } from 'react';
import { MODELS } from '../../../../../Lib/constants';
import { fetch } from '../../../../../AppServices';
import tabTheme from '../../../../../theme/childTabTheme';
import { TabNavigator } from '../../../../../app-components';
import PersonalDetails from './PersonalDetails';
import RegistrationDetails from './RegistrationDetails';
import Statutory from './Statutory';

const Profile = (props) => {
  const { navigation: { state: { params: { items: { employee } } } } } = props;
  const [profileInfo, setProfileInfo] = useState({});

  const fetchProfileInfo = useCallback(async () => {
    try {
      const fetchedProfileInfo = await fetch({
        uri: {
          props: {
            query: {
              id: 'getEmployeeDetails',
              addOnFilter: {
                _id: employee._id,
              },
            },
            model: MODELS.EMPLOYEE,
            limit: 1,
          },
        },
      });
      setProfileInfo(...fetchedProfileInfo?.data);
      return fetchedProfileInfo;
    } catch (err) {
      return err;
    }
  }, []);

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const ProfileTabNavigation = TabNavigator({
    theme: { ...tabTheme },
    tabs: {
      PersonalDetails: {
        label: 'Personal Details',
        screen: PersonalDetails,
        screenProps: {
          profileInfo,
        },
      },
      RegistrationDetails: {
        label: 'Registration Details',
        screen: RegistrationDetails,
        screenProps: {
          profileInfo,
        },
      },
      Statutory: {
        label: 'Statutory',
        screen: Statutory,
        screenProps: {
          profileInfo,
        },
      },
    },
  });
  return <ProfileTabNavigation {...props} />;
};
export default Profile;
