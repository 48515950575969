import { ROUTES } from '../../Lib/constants';
import ImportAppBoard from './AddImport';
import ImportDetail from './ImportDetail';
import ImportList from './ImportList';

export default {
  import: {
    screen: ImportAppBoard,
    reloadEvent: 'reloadImport',
  },
  addImport: {
    screen: ImportAppBoard,
    expanded: true,
  },
  importDetail: { screen: ImportDetail },
  [ROUTES.importList.name]: { screen: ImportList },
};
