import React from 'react';
import { ToggleFilter } from '../../../../app-components';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import MasterList from '../../../../Wired/Master/MasterList';
import permissions from '../../../../Lib/permissions';

const tableColumns = [
  { width: 14 },
  {
    header: 'testComponent.header.testComponentName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'testComponent.title.testLinked'.getLabel(),
    type: 'link',
    value: ({ item: { test_count: testCount } = {} }) => testCount?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewTestLinked.name,
          modal: true,
          params: {
            item,
            description: 'employeeType.title.description'.getLabel(),
            filter: {
              testComponentType: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'testComponent.header.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
  {
    header: 'testComponent.header.unit'.getLabel(),
    type: 'text',
    sortable: 'unit',
    field: 'unit',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.TEST_COMPONENT_TYPE}
    editFormId={ROUTES.editComponent.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyTestComponent.permissions.modifyTestComponent.value}
    header={{
      actions: [

      ],
      leftActions: [
        (actionProps) => (
          <ToggleFilter
            {...actionProps}
            model={MODELS.TEST_COMPONENT_TYPE}
            id="masterDataListForTable"
          />
        ),
      ],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewComponent.name,
        params: { item },
      },
    })}
  />
);
