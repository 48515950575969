import { cancelButton, save } from '../../../app-components';
import { ROUTES } from '../../../Lib/constants';
import EditNurseModal from './SessionTabs/Components/EditNurseModal';
import EndSessionModal from './SessionTabs/Components/EndSessionModal';
import AddNewPatientModal from './SessionTabs/AddPatientModal';
import GenerateBill from './SessionTabs/generateVaccineBill';
import VaccineDetailModalForPatient from './SessionTabs/VaccineDetailModalForPatient';
import StartSessionForm from './StartSession';
import ViewActiveSession from './ViewActiveSession';
import PatientMeasurmentForVaccine from '../../../Wired/PatientMeasurmentForVaccine';

export default {
  [ROUTES.vaccinationTokenGeneration.name]: {
    screen: StartSessionForm,
    expanded: true,
  },
  [ROUTES.viewActiveVaccinationSession.name]: {
    screen: ViewActiveSession,
    expanded: true,
  },
  [ROUTES.editNurseModal.name]: {
    screen: EditNurseModal,
    expanded: true,
  },
  [ROUTES.endVaccinationSessionModal.name]: {
    screen: EndSessionModal,
  },
  [ROUTES.addPatientModal.name]: {
    screen: AddNewPatientModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 400,
      height: 450,
    },
  },
  [ROUTES.vaccineDetailForPatientModal.name]: {
    screen: VaccineDetailModalForPatient,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 950,
      height: 700,
    },
  },
  [ROUTES.generateVaccineBill.name]: {
    screen: GenerateBill,
    expanded: true,
    headerActions: [
      cancelButton({ label: 'Cancel' }),
      save({ label: 'Generate' }),
    ],
  },
  [ROUTES.addPatientMeasurment.name]: {
    screen: PatientMeasurmentForVaccine,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 430,
      height: 450,
    },
  },
};
