/* eslint-disable max-len */
import { upperFirst } from 'lodash';
import React from 'react';
import { Text, View } from 'react-native';
import { Form } from '../../../../../app-components/form/Form';
import { ViewPurchaseRequestListAsset } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';

const ViewAssetRepairRequest = (props) => (
  <Form
    type="standard"
    uri={ViewPurchaseRequestListAsset}
    closeView={1}
    editable={false}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          label: 'Repair Request Details',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 10,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'asset.labels.dateRaised'.getLabel(),
                  format: 'DD MMM YYYY',
                  field: '_createdOn',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'purchase.labels.requestNumber'.getLabel(),
                  field: 'purchaseRequestAsset.purchaseRequestNumber',
                },
                {
                  type: 'text',
                  label: 'purchase.labels.department'.getLabel(),
                  render: ({ data }) => (<Text style={{ ...vars.headings.h8 }}>{upperFirst(data?.type)}</Text>),
                },
                {
                  label: 'asset.labels.status'.getLabel(),
                  type: 'text',
                  render: ({ data }) => {
                    let statusColor = '';
                    const status = data?.status;
                    if (status === 'raised') { statusColor = vars.colors.emphasis; } else if (status === 'Order  Received') { statusColor = vars.colors.primary.color2; } else if (status === 'PS Rejected' || status === 'SO Rejected') { statusColor = vars.colors.error; } else { statusColor = vars.colors.warning; }
                    return (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                        <View style={{
                          height: 8, width: 8, borderRadius: 8, backgroundColor: statusColor, marginTop: 4.5, marginLeft: 7,
                        }}
                        />
                      </View>
                    );
                  },
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'asset.labels.assetName'.getLabel(),
                  field: 'purchaseRequestAsset.item.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'asset.labels.category'.getLabel(),
                  field: 'purchaseRequestAsset.item.category.name',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'asset.labels.subCategory'.getLabel(),
                  field: 'purchaseRequestAsset.item.subCategory.name',
                  editable: false,
                },
                {
                  type: 'number',
                  label: 'asset.labels.approximateCost'.getLabel(),
                  field: 'purchaseRequestAsset.approximateCost',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'asset.labels.reasonForRepair'.getLabel(),
                  field: 'purchaseRequestAsset.reasonForRepair',
                  editable: false,
                },
                {
                  type: 'text',
                  label: 'asset.labels.description'.getLabel(),
                  field: 'purchaseRequestAsset.item.description',
                  editable: false,
                },
              ],
            },
          ],
        },
        {
          label: 'inventory.labels.statusTracking'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'progressChart',
                  field: '_id',
                  id: 'purchaseRequestAssetList',
                },
              ],
            },
          ],
        },
      ],
    }}
    {...props}
  />
);

export default ViewAssetRepairRequest;
