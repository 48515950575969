import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';

const ViewDetailsForm = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      title="OT List"
      description="Logged OT list for the patients."
      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      // {
                      //   type: 'number',
                      //   label: 'Serial Number',
                      //   render: () => item?.serialNumber,
                      // },
                      {
                        type: 'text',
                        render: () => item?.patientUHID?.uhid,
                        label: 'Patient UHID',
                      },
                      {
                        type: 'text',
                        render: () => item?.name,
                        label: 'Patient',
                      },
                      {
                        type: 'number',
                        render: () => item?.otNumber,
                        label: 'OT Number',
                      },
                      {
                        type: 'text',
                        render: () => item?.operationType,
                        label: 'Type Of Operation',
                      },
                      {
                        type: 'text',
                        render: () => item?.surgeonName?.name,
                        label: 'Surgeon',
                      },
                      {
                        type: 'text',
                        label: 'PAC Status',
                        render: () => item?.pacStatus,
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        label: 'Reason for Rejected',
                        render: () => item?.reasonOfRejection,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};
export default ViewDetailsForm;
