import React, { useState, useCallback, useMemo } from 'react';

import { isFunction } from 'lodash';
import { View, Text } from '../../app-components';
import { bgs } from '../../theme/colors';
import vars from '../../theme/vars';
import { getPermission } from '../../AppServices';

const Menu = ({ options = [], onMenuOptionSelect, selectedSubMenuOption }) => {
  const componentColors = {
    mainBackground: '#EFF3FB',
    normal: '#4070C9',
    selected: '#192F59',
    heading: '#333333',
    content: '#828282',
  };

  // const options = [
  //   { id: 0, label: 'User Management' },
  //   { id: 1, label: 'Role Management' },
  //   { id: 2, label: 'Main Feature 2' },
  //   { id: 3, label: 'Main Feature 3' },
  //   { id: 4, label: 'Main Feature 4' },
  // ];

  // const subOptions = [
  //   { id: 0, heading: 'Sub-feature 1', content: 'Description of the module.' },
  //   { id: 1, heading: 'Sub-feature 2', content: 'Description of the module.' },
  //   { id: 2, heading: 'Sub-feature 3', content: 'Description of the module.' },
  //   { id: 3, heading: 'Sub-feature 4', content: 'Description of the module.' },
  //   { id: 4, heading: 'Sub-feature 5', content: 'Description of the module.' },
  // ];
  const defaultSelectedOption = useMemo(() => {
    if (options && options.length) {
      // outerLoop:
      for (let index = 0; index < options.length; index++) {
        const option = options[index];
        if (option?.subOptions && option.subOptions.length) {
          for (let subOptionIndex = 0; subOptionIndex < option.subOptions.length; subOptionIndex++) {
            const subOption = option.subOptions[subOptionIndex];
            if (subOption.id === selectedSubMenuOption) {
              return {
                id: option.id,
                subOptions: option.subOptions,
              };
              // break outerLoop;
            }
          }
        }
      }
      const defaultOption = options.filter(({ access_key}) => getPermission(access_key));
      return {
        id: defaultOption[0]?.id,
        subOptions: defaultOption[0]?.subOptions,
      };
    }

    return {};
  }, []);

  const [selectedOption, selectOption] = useState(defaultSelectedOption);

  const onOptionClick = useCallback((id, subOptions) => () => {
    selectOption({ id, subOptions });
  }, []);

  const handleMenuOptionSelect = useCallback((item) => () => {
    if (isFunction(onMenuOptionSelect)) {
      onMenuOptionSelect(item);
    }
  }, [onMenuOptionSelect]);

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{
        backgroundColor: componentColors.mainBackground,
        borderRadius: 4,
        paddingLeft: 24,
        paddingTop: 10,
        paddingRight: 24,
        paddingBottom: 10,
      }}
      >
        {options.map(({
          id, label, subOptions, access_key,
        }) => (getPermission(access_key)
          && (
            <View
              key={id}
              style={{ position: 'relative', flexDirection: 'row', alignItems: 'center' }}
            >
              <Text
                style={{
                  ...vars.headings.h13,
                  fontWeight: selectedOption?.id === id ? 'bold' : 'normal',
                  color: selectedOption?.id === id
                    ? vars.colors.secondary.color1 : vars.colors.secondary.color2,
                  lineHeight: 36,
                }}
                onClick={onOptionClick(id, subOptions)}
              >
                {label}
              </Text>
              <View style={{
                position: 'absolute',
                borderWidth: 12,
                borderColor: bgs.transparent,
                borderLeftColor: selectedOption?.id === id
                  ? componentColors.mainBackground : bgs.transparent,
                right: -48,
              }}
              />
            </View>
          )
        ))}
      </View>
      <View style={{
        flexDirection: 'row',
        borderRadius: 4,
        paddingLeft: 24,
        paddingTop: 20,
        paddingRight: 24,
        paddingBottom: 16,
        width: 420,
        flexWrap: 'wrap',
        display: 'block',
      }}
      >
        <View style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        >
          {selectedOption?.subOptions?.map(({
            id, label, description, access_key,
          }) => (getPermission(access_key)
          && (
            <View
              key={id}
              style={{
                width: 140,
                paddingLeft: 16,
                paddingTop: 8,
                paddingRight: 4,
                paddingBottom: 8,
                borderRadius: 4,
                backgroundColor: selectedSubMenuOption === id && vars.colors.secondary.color4,
              }}
              onClick={handleMenuOptionSelect(
                { id: selectOption?.id, option: { id, label, description } },
              )}
            >
              <Text style={{
                ...vars.headings.h8,
                color: vars.colors.grey.color1,
              }}
              >
                {label}
              </Text>
              <Text style={{
                ...vars.headings.h15,
                color: vars.colors.grey.color3,
              }}
              >
                {description}
              </Text>
            </View>
          )
          ))}
        </View>
      </View>
    </View>
  );
};

export default Menu;
