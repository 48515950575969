/* eslint-disable no-shadow */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';

import { NestedAction, NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { ActivityIndicator, Text, View } from '../../../../../app-components';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import { assetServiceTypes, MODELS } from '../../../../../Lib/constants';
import { fetch, submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { currencyInput } from '../../../../../compositeInputs';
import { Form } from '../../../../../app-components/form/Form';

const AddStock = (props) => {
  const {
    navigation: {
      state: {
        params: {
          purchaseRequestEntityId,
          purchaseRequestAssetId,
        },
      },
    },
  } = props;

  const [supplier, setSupplier] = useState();
  const [manufacturer, setManufacturer] = useState();
  const [rateContract, setRateContract] = useState('');
  const [item, setItem] = useState();

  const fetchRateContractCorrespontingTOSupplierAndManufacturer = useCallback(
    async (SupplierAndManufacturerDetails) => {
      const {
        purchaseRequestEntity: {
          purchaseRequestAsset: {
            item: { _id },
          },
        },
      } = SupplierAndManufacturerDetails?.data[0];

      const rateContractCorrespondingToSupplierAndManufacture = await fetch({
        uri: {
          props: {
            query: {
              id: 'masterDataList',
              addOnFilter: {
                _id,
              },
            },
            model: MODELS.ASSET_ITEM,
            limit: 1,
          },
        },
      });

      setItem(rateContractCorrespondingToSupplierAndManufacture?.data[0]);
      setRateContract(rateContractCorrespondingToSupplierAndManufacture?.data[0]?.rateContract);

      return rateContractCorrespondingToSupplierAndManufacture;
    }, [],
  );

  const fetchSupplierAndManufacturerDetails = useCallback(async () => {
    const fetchedSupplierAndManufacturerDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'purchaseSupplyOrderDetails',
            addOnFilter: {
              purchaseRequestEntity: { _id: purchaseRequestEntityId },
              type: 'PO',
            },
          },
          model: MODELS.PURCHASE_SUPPLY_ORDER,
          limit: 1,
        },
      },
    });
    setSupplier(fetchedSupplierAndManufacturerDetails?.data[0]?.supplier);
    setManufacturer(fetchedSupplierAndManufacturerDetails?.data[0]?.manufacturer);
    fetchRateContractCorrespontingTOSupplierAndManufacturer(fetchedSupplierAndManufacturerDetails);

    return fetchedSupplierAndManufacturerDetails;
  }, [fetchRateContractCorrespontingTOSupplierAndManufacturer, purchaseRequestEntityId]);

  useEffect(() => {
    fetchSupplierAndManufacturerDetails();
  }, [fetchSupplierAndManufacturerDetails]);

  if (!manufacturer || !supplier || !item) return <ActivityIndicator />;

  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      onSubmit={submit({
        model: MODELS.ASSET_BATCH,
      })}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}
      defaultValues={() => ({
        purchaseRequestEntity: { _id: purchaseRequestEntityId },
        purchaseRequestAssetId: { _id: purchaseRequestAssetId },
        item,
        supplier,
        manufacturer,
        currency: 'INR',
        stockEntryDate: new Date(),
      })}
      computations={{
        self: {
          itemAutofill: {
            compute: ({ item: selectedItem }) => ({
              set: {
                'item.category': selectedItem?.category,
                'item.subCategory': selectedItem?.subCategory,
                'item.reorderLevel': selectedItem?.reorderLevel,
                'item.description': selectedItem?.description,
                'item.isActive': selectedItem?.isActive,
              },
            }),
            onChange: ['item'],
          },
        },
      }}
      submitMessage={'asset.messages.batchAddedSuccessfully'.getLabel()}
      mandatory={{
        supplyReceivedOn: 1,
        stockEntryDate: 1,
        quantityReceived: 1,
        purchasedPrice: 1,
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'asset.title.assetDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  searchInput({
                    field: 'item',
                    label: 'asset.labels.assetName'.getLabel(),
                    placeholder: 'asset.placeholders.selectItemName'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.ASSET_ITEM,
                    query: 'masterDataList',
                    mandatory: true,
                    disabled: true,
                  }),
                  searchInput({
                    field: 'item.category',
                    label: 'asset.labels.category'.getLabel(),
                    placeholder: 'asset.placeholders.category'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_CATEGORY,
                    query: 'masterDataList',
                    disabled: true,
                  }),
                  autoSuggestInput({
                    type: 'autoSuggest',
                    field: 'item.subCategory',
                    label: 'asset.labels.subCategory'.getLabel(),
                    placeholder: 'asset.placeholders.subCategory'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUBCATEGORY,
                    query: 'masterDataList',
                    disabled: true,
                  }),
                  {
                    type: 'number',
                    field: 'item.reorderLevel',
                    label: 'asset.labels.reorderLevel'.getLabel(),
                    placeholder: 'asset.placeholders.reorderLevel'.getLabel(),
                    disabled: true,
                  },
                ],
              },
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'number',
                    field: 'quantityReceived',
                    variant: 'filled',
                    label: 'asset.labels.quantityReceived'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'toggleSwitch',
                    field: 'item.isActive',
                    label: 'asset.labels.status'.getLabel(),
                    container: 'leftLabel',
                    disabled: true,
                  },
                  {
                    type: 'textArea',
                    field: 'item.description',
                    label: 'asset.labels.description'.getLabel(),
                    placeholder: 'asset.placeholders.description'.getLabel(),
                    minHeight: 80,
                    disabled: true,
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.manufacturerSupplierDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 3,
                columns: [
                  searchInput({
                    field: 'supplier',
                    variant: 'filled',
                    label: 'asset.labels.supplier'.getLabel(),
                    placeholder: 'asset.placeholders.supplier'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUPPLIER,
                    query: 'masterDataList',
                    disabled: true,
                  }),
                  searchInput(
                    {
                      field: 'manufacturer',
                      variant: 'filled',
                      label: 'asset.labels.manufacturer'.getLabel(),
                      placeholder: 'asset.placeholders.manufacturer'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.PHARMACY_MANUFACTURER,
                      query: 'masterDataList',
                      disabled: true,
                    },
                    {
                      addOnFilter: ({
                        data: {
                          supplier: { _id },
                        },
                      }) => ({ supplier: { _id } }),
                    },
                  ),
                  {
                    type: 'date',
                    value: rateContract[0]?.rateContractValidity,
                    filed: 'rateContractValidity',
                    variant: 'filled',
                    label: 'asset.labels.rateContractValidity'.getLabel(),
                    placeholder: 'asset.placeholders.rateContractValidity'.getLabel(),
                    disabled: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.assetStockDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'date',
                    field: 'stockEntryDate',
                    variant: 'filled',
                    label: 'asset.labels.stockEntryDate'.getLabel(),
                    placeholder: 'asset.placeholders.stockEntryDate'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'date',
                    field: 'supplyReceivedOn',
                    variant: 'filled',
                    label: 'asset.labels.supplyReceivedOn'.getLabel(),
                    placeholder: 'asset.placeholders.supplyReceivedOn'.getLabel(),
                    mandatory: true,
                  },
                  {
                    ...currencyInput({
                      variant: 'filled',
                      amount: {
                        field: 'purchasedPrice',
                        label: 'asset.labels.purchasedPrice'.getLabel(),
                        mandatory: true,
                      },
                      currency: {
                        field: 'currency',
                      },
                    }),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'asset.title.service'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    type: 'radioGroup',
                    field: 'isAMC',
                    variant: 'filled',
                    options: assetServiceTypes,
                  },
                ],
              },
              {
                columns: [
                  {
                    field: 'serviceItemDates',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: ({ addRow }) => ({
                        leftActions: [{
                          render: () => (
                            <View style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                            >
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                {`+ ${'asset.labels.addRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        }],
                      }),
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: true,
                        columns: [
                          {
                            type: 'date',
                            field: 'preventiveDate',
                            variant: 'filled',
                            label: 'asset.labels.preventiveDate'.getLabel(),
                            placeholder: 'asset.placeholders.preventiveDate'.getLabel(),
                            width: 300,
                          },
                          {
                            type: 'date',
                            field: 'serviceDate',
                            variant: 'filled',
                            label: 'asset.labels.serviceDate'.getLabel(),
                            placeholder: 'asset.placeholders.serviceDate'.getLabel(),
                            width: 300,
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'file',
                    field: 'documents',
                    variant: 'filled',
                    label: 'asset.labels.attachDocuments'.getLabel(),
                    container: 'topLabel',
                    multiple: true,
                    mandatory: true,
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default AddStock;
