import React from 'react';
import { upperFirst } from 'lodash';
import {
  FormField, StandardButton, View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import ListActionModal from '../../../../../Components/ListActionModal';
import { autoSuggestOptionsInput } from '../../../../../autoSuggestions';
import { technicalIcon } from '../../../../../images';
import vars from '../../../../../theme/vars';
import { MODELS, RolesOrder } from '../../../../../Lib/constants';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../AppServices';

function getSelectedItem(conditions) {
  const newConditions = [];
  conditions.forEach((item) => {
    if (item.selected) {
      newConditions.push({
        _id: item?._id,
        name: item?.name,
        description: item?.description,
        code: item?.code,
      });
    }
  });
  return newConditions;
}
const CreatePurchaseAndSupplyOrderForAssetRepair = (props) => {
  const { navigation: { state: { params } } = {} } = props;

  return (
    <ListActionModal
      icon={technicalIcon}
      title={params?.fromSupplyOrder ? 'purchase.title.supplyOrder'.getLabel() : 'purchase.title.purchaseOrder'.getLabel()}
      content={(
        <Form
          closeView={params?.fromSupplyOrder ? 2 : 3}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={({ data, navigation, updates }) => {
            const { conditions = [] } = data;
            if (!conditions?.length) {
              navigation.pop();
              throw new Error(
                'purchase.errorMessage.selectConditionAndConditionType'.getLabel(),
              );
            }
            const submitAPI = submit({
              model: MODELS.PURCHASE_SUPPLY_ORDER,
              data,
            });
            const result = submitAPI({ data, updates });
            return result;
          }}
          submitMessage={
            params?.fromSupplyOrder
              ? 'purchase.message.soCreatedSuccessfully'.getLabel()
              : 'purchase.message.createPurchaseOrder'.getLabel()
          }
          reloadEvent={[`reload${MODELS.PURCHASE_REQUEST}`, `reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`]}
          defaultValues={() => ({
            type: params?.fromSupplyOrder ? 'SO' : 'PO',
            isContractual: params?.isContractual,
            daysLimitForApproval: params?.daysLimitForApproval,
            inventoryType: 'asset',
            isPOCreated: !params?.fromSupplyOrder,
            isSOCreated: !!params?.fromSupplyOrder,
            status: 'created',
            urgent: !!params?.urgent,
            conditions_type: params?.code ? { _id: params?.code?._id } : null,
            conditions: params?.conditions
              ? getSelectedItem(params?.conditions)
              : null,
            purchaseRequestEntity: {
              _id: params?._id,
              purchaseRequestAsset: params?.purchaseRequestAsset,
              conditions: params?.conditions,
            },
            POCreatedOn: params?.fromSupplyOrder ? '' : new Date(),
            SOCreatedOn: params?.fromSupplyOrder ? new Date() : '',
            supplier: params?.supplier,
            manufacturer: params?.manufacturer,
            purchaseSupplyOrder: params?.fromSupplyOrder
              ? { _id: params?.purchaseSupplyOrderId }
              : null,
          })}
          mandatory={{
            assignedToRole: 1,
            lastRemarks: 1,
          }}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ width: 300, marginRight: 20 }}>
                  <FormField
                    {...autoSuggestOptionsInput({
                      label: 'purchase.labels.sendForApprovalTo'.getLabel(),
                      variant: 'filled',
                      keyFiled: 'label',
                      field: 'assignedToRole',
                      suggestionField: 'label',
                      options: RolesOrder,
                      valueField: 'label',
                      mandatory: true,
                    })}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="textArea"
                    label={'purchase.labels.remarks'.getLabel()}
                    variant="filled"
                    field="lastRemarks"
                    allowWhiteSpaces
                    mandatory
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 24 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'purchase.labels.poNumber'.getLabel()}
                    variant="filled"
                    field="purchaseRequestEntity.purchaseRequestAsset.purchaseRequestNumber"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="date"
                    label={'purchase.labels.date'.getLabel()}
                    variant="filled"
                    value={new Date()}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'purchase.labels.department'.getLabel()}
                    variant="filled"
                    value={upperFirst(params?.type)}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    editable={false}
                    type="text"
                    label={'purchase.labels.itemName'.getLabel()}
                    variant="filled"
                    field="purchaseRequestEntity.purchaseRequestAsset.item.name"
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="number"
                    label={'asset.labels.approximateCost'.getLabel()}
                    field="purchaseRequestEntity.purchaseRequestAsset.approximateCost"
                    editable={false}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 25 }}>
                <View>
                  <FormField
                    {...{
                      field: 'conditions',
                      fieldType: 'nested',
                      editable: false,
                      Component: NestedTable,
                      componentProps: {
                        maxHeight: 400,
                        skipTableHeaderOnNoData: false,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 32,
                            backgroundColor: vars.colors.white,
                          },
                          headerRowColumnTextStyle: {
                            paddingLeft: 12,
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                            numberOfLines: 1,
                            textTransform: 'upperCase',
                          },
                          columns: [
                            {
                              type: 'text',
                              field: 'name',
                              variant: 'filled',
                              header: 'purchase.header.tAndCName'.getLabel(),
                              width: 350,
                              editable: false,
                            },
                            {
                              type: 'text',
                              field: 'description',
                              variant: 'filled',
                              header: 'purchase.header.description'.getLabel(),
                              width: 350,
                              editable: false,
                            },
                          ],
                        },
                      },
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={params?.fromSupplyOrder ? 'purchase.button.createAndPrintSO'.getLabel() : 'purchase.button.createAndPrintPO'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default CreatePurchaseAndSupplyOrderForAssetRepair;
