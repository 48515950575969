import React from 'react';
import ConsultationStatusCell from '../../../Components/ConsultationStatus';
import { CurrencyInput } from '../../input-components/CompositeEditors';

import {
  TextInput,
  TextAreaInput,
  NumberInput,
  DateInput,
  TimeInput,
  ProgressSliderInput,
  DownloadFile,
  FileInput,
  CheckBox,
  AutoSuggestInput,
  AutoSuggestAvatarInput,
  MultiAutoSuggestInput,
  MultiAutoSuggestAvatarInput,
  SearchInput,
  SearchAvatarInput,
  MultiSearchInput,
  MultiSearchAvatarInput,
  GooglePlaceInput,
  ToggleInput,
  ToggleSwitchInput,
  RadioGroupInput,
  RadioInput,
  MultiTextInput,
  RichTextInput,
  ChipsToggleInput,
  statusInput,
  UploadImage,
  consultationStatusInput,
  CheckBoxGroup,
  progressChart,
  statusChart
} from '../../input-components/Editors';
import {
  TextRender as FormTextRender,
  TextAreaRender as FormTextAreaRender,
  FilledAutoSuggestAvatarRender,
  AutoSuggestAvatarRender,
  MultiAutoSuggestRender,
  MultiAutoSuggestAvatarRender,
  RadioRender,
} from '../../render-components/FormRenders';

const inputTypes = {
  text: TextInput,
  password: (props) => (
    <TextInput {...props} inputProps={{ ...props?.inputProps, type: 'password' }} />
  ),
  multiText: MultiTextInput,
  textArea: TextAreaInput,
  number: NumberInput,
  date: DateInput,
  time: TimeInput,
  progressSlider: ProgressSliderInput,
  downloadFile: DownloadFile,
  file: FileInput,
  checkBox: CheckBox,
  checkBoxGroup: CheckBoxGroup,
  autoSuggest: AutoSuggestInput,
  autoSuggestAvatar: AutoSuggestAvatarInput,
  search: SearchInput,
  searchAvatar: SearchAvatarInput,
  multiSearch: MultiSearchInput,
  multiSearchAvatar: MultiSearchAvatarInput,
  multiAutoSuggest: MultiAutoSuggestInput,
  multiAutoSuggestAvatar: MultiAutoSuggestAvatarInput,
  googlePlace: GooglePlaceInput,
  toggle: ToggleInput,
  toggleSwitch: ToggleSwitchInput,
  radioGroup: RadioGroupInput,
  radio: RadioInput,
  richText: RichTextInput,
  chipsToggle: ChipsToggleInput,
  uploadImage: UploadImage,
  currency: CurrencyInput,
  statusChart,
};

const renderTypes = {
  text: FormTextRender,
  multiText: FormTextRender,
  textArea: FormTextAreaRender,
  number: FormTextRender,
  date: FormTextRender,
  time: FormTextRender,
  file: FormTextRender,
  html: FormTextAreaRender,
  richText: FormTextAreaRender,
  checkBox: CheckBox,
  switch: FormTextRender,
  toggle: FormTextRender,
  toggleSwitch: FormTextRender,
  autoSuggest: FormTextRender,
  autoSuggestAvatar: AutoSuggestAvatarRender,
  search: FormTextRender,
  searchAvatar: AutoSuggestAvatarRender,
  multiSearch: MultiAutoSuggestRender,
  multiSearchAvatar: MultiAutoSuggestAvatarRender,
  multiAutoSuggest: MultiAutoSuggestRender,
  multiAutoSuggestAvatar: MultiAutoSuggestAvatarRender,
  googlePlace: FormTextRender,
  radio: RadioRender,
  progressSlider: FormTextRender,
  filledAutoSuggestAvatar: FilledAutoSuggestAvatarRender,
  status: statusInput,
  uploadImage: UploadImage,
  chipsToggle: ChipsToggleInput,
  consultationStatus: consultationStatusInput,
  progressChart,
  statusChart,
};

export { inputTypes, renderTypes };
