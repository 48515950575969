import { Image, TouchableOpacity } from '@applane/react-core-components';
import React from 'react';

import {
  searchInput,
  autoSuggestInput,
  multiSearchInput,
  multiSearchAvatarInput,
} from '../../../autoSuggestions';
import { editRecord } from '../../../images';
import { ROUTES } from '../../../Lib/constants';

export const Layout = {
  component_name: {
    header: ' COMPONENT NAME',
    type: 'text',
    field: 'name',
  },
  component_calc_base: {
    type: 'text',
    header: 'CALCULATION BASE',
    field: 'component_calculation_base_id.name',
  },
  payment_type: {
    type: 'text',
    header: 'PAYMENT TYPE',
    field: 'payment_type_id.name',
  },
  pay_to: {
    type: 'text',
    header: 'PAY TO',
    field: 'pay_to_id.name',
  },
  status: {
    type: 'status',
    header: 'Status',
    field: 'isActive',
  },
  editRecord: {
    header: 'Actions',
    type: 'custom',
    render: () => (
      <TouchableOpacity>
        <Image
          style={{
            height: 24,
            width: 24,
          }}
          source={editRecord}
        />
      </TouchableOpacity>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editSalaryComponent.name,
        params: { item },
        routeOptions: {
          expanded: true,
        },
      },
    }),
  },
  component_type: {
    type: 'text',
    field: 'component_type',
    header: 'Component Type',
    value: ({ item }) => (item.component_type === 'earning_type' ? 'Earnings' : 'Deductions'),
  },
  taxation_status: {
    type: 'text',
    field: 'is_taxable',
    header: 'Taxation Status',
  },
  component_dependance: {
    type: 'text',
    field: 'component_dependance',
    header: 'Component Dependance',
  },
};

export const FormLayouts = {
  component_type: autoSuggestInput({
    field: 'component_type',
    label: 'Component Type',
    placeholder: 'Component Type',
    valueField: 'label',
    suggestionField: 'label',
    keyField: 'value',
    options: [
      { label: 'Earning', value: 'earning_type' },
      { label: 'Deductions', value: 'deduction_type' },
    ],
    mandatory: true,
  }),
  select_component: searchInput({
    field: 'component_type_id',
    query: 'componentTypeSuggestions',
    model: 'Components',
    label: 'Component Type',
    placeholder: 'Component Type',
    searchField: 'name',
    suggestionField: 'name',
  }),
  name: {
    type: 'text',
    label: 'Name In Payslip',
    placeholder: 'Name',
    field: 'name',
    allowWhiteSpaces: true,
    allowConsecutiveSpaces: true,
    mandatory: true,
  },
  payment_type: searchInput({
    field: 'payment_type_id',
    query: 'paymentTypeSuggestions',
    model: 'PaymentType',
    label: 'Payment Type',
    placeholder: 'Payment Type',
    searchField: 'name',
    suggestionField: 'name',
    mandatory: true,
  }),
  calculation_base: searchInput({
    field: 'component_calculation_base_id',
    query: 'componentBaseCalTypeSuggestions',
    model: 'ComponentBaseCal',
    label: 'Calculation Base',
    placeholder: 'Calculation Base',
    searchField: 'name',
    suggestionField: 'name',
    mandatory: true,
  }),
  payment_acc: searchInput({
    field: 'payable_account_id',
    query: 'accountSuggestions',
    model: 'Account',
    label: 'Payable Account',
    placeholder: 'Payable Account',
    searchField: 'name',
    suggestionField: 'name',
  }),
  deductable_account_id: searchInput({
    field: 'deductable_account_id',
    query: 'accountSuggestions',
    model: 'Account',
    label: 'Deductable Account',
    placeholder: 'Deductable Account',
    searchField: 'name',
    suggestionField: 'name',
  }),
  not_show_in_salary_slip: {
    label: "Don't Show In Salary Sheet",
    container: 'leftLabel',
    placeholder: "Don't Show In Salary Sheet",
    field: 'not_show_in_salary_slip',
    type: 'checkBox',
  },
  include_in_others: {
    label: 'Include in Others',
    container: 'leftLabel',
    placeholder: 'Include in Others',
    field: 'include_in_others',
    type: 'checkBox',
  },
  manual_recovery: {
    label: 'Manual Recovery',
    container: 'leftLabel',
    placeholder: 'Manual Recovery',
    field: 'manual_recovery',
    type: 'checkBox',
  },
  not_show_in_salary_statement: {
    label: "Don't Show In Salary Statement",
    container: 'leftLabel',
    placeholder: "Don't Show In Salary Statement",
    field: 'not_show_in_salary_statement',
    type: 'checkBox',
  },
  not_show_for_payable: {
    placeholder: 'Not Show for Payable',
    label: 'Not Show for Payable',
    container: 'leftLabel',
    field: 'not_show_for_payable',
    type: 'checkBox',
  },
  is_upper_round: {
    placeholder: 'Is Upper Round',
    container: 'leftLabel',
    label: 'Is Upper Round',
    field: 'is_upper_round',
    type: 'checkBox',
  },
  is_taxable: {
    label: 'Is Taxable',
    field: 'is_taxable',
    container: 'leftLabel',
    placeholder: 'Is Taxable',
    type: 'checkBox',
  },
  is_fix_amount: {
    label: 'Is Fix Amount (Required for at least Tax Component. Deduct it only once with fixed amount)',
    placeholder: 'Is Fix Amount',
    field: 'is_fix_amount',
    container: 'leftLabel',
    type: 'checkBox',
  },
  sequence_for_salary_sheet: {
    label: 'Sequence For Salary Sheet',
    placeholder: 'Sequence For Salary Sheet',
    field: 'sequence_for_salary_sheet',
    type: 'number',
  },
  payment_cycle_id: searchInput({
    field: 'payment_cycle_id',
    query: 'paymentCycleTypeSuggestions',
    model: 'PaymentCycles',
    label: 'Payment Cycle',
    placeholder: 'Payment Cycle',
    searchField: 'name',
    suggestionField: 'name',
  }),
  pay_to_id: searchInput({
    field: 'pay_to_id',
    query: 'payToSuggestions',
    model: 'PayTo',
    label: 'Pay To',
    placeholder: 'Pay To',
    searchField: 'name',
    suggestionField: 'name',
    mandatory: true,
  }),
  isActive: {
    label: 'Status',
    placeholder: 'Status',
    field: 'isActive',
    type: 'toggleSwitch',
  },
  is_flexi_component: {
    container: 'leftLabel',
    label: 'Is Flexi Component',
    placeholder: 'Is Flexi Component',
    field: 'is_flexi_component',
    type: 'checkBox',
  },
  pay_after_month: {
    label: 'Payment After Month',
    placeholder: 'Payment After Month',
    field: 'pay_after_month',
    type: 'number',
  },
  target: {
    field: 'target',
    label: 'Target',
    placeholder: 'Target',
    type: 'text',
  },
  rate: {
    field: 'rate',
    label: 'Rate',
    placeholder: 'Rate',
    type: 'text',
  },
  percentage_of_components: {
    label: 'Percentage Of Components',
    placeholder: 'Percentage Of Components',
    field: 'percentage_of_components',
    type: 'text',
  },
  ignore_in_exception_report: {
    label: 'Ignore In Exception Report',
    placeholder: 'Ignore In Exception Report',
    field: 'ignore_in_exception_report',
    type: 'text',
  },
  base_amount: {
    label: 'Base Amount',
    placeholder: 'Base Amount',
    field: 'base_amount',
    type: 'text',
  },
  business_unit_id: {
    label: 'Business Unit',
    placeholder: 'Business Unit',
    field: 'business_unit_id',
    type: 'text',
  },
  not_show_for_gross: {
    label: 'Not Show For Gross',
    placeholder: 'Not Show For Gross',
    field: 'not_show_for_gross',
    type: 'text',
  },
  org_id: searchInput({
    field: 'org_id',
    query: 'orgSuggestions',
    model: 'Organization',
    label: 'Organization',
    placeholder: 'Organization',
    searchField: 'name',
    suggestionField: 'name',
  }),
  max_limit: {
    label: 'Max Limit',
    placeholder: 'Max Limit',
    field: 'max_limit',
    type: 'number',
  },
  ctc_calculation_type: {
    type: 'radioGroup',
    field: 'ctc_calculation_type',
    container: 'topLabel',
    label: 'Accrued Type',
    options: [
      { value: 'fix_amount', label: 'Fix Amount' },
      { value: 'percentage', label: 'Percentage' },
    ],
  },
  ctc_percentage: {
    type: 'number',
    field: 'ctc_percentage',
    label: 'Percentage',
    placeholder: 'Percentage',
  },
  dependency_field: multiSearchAvatarInput({
    field: 'dependency_field',
    query: 'salaryComponent',
    model: 'SalaryComponent',
    placeholder: 'Dependency Field',
    searchField: 'name',
    suggestionField: 'name',
    mandatory: true,
    chipsPosition: 'bottom',
  }),
  include_in_gross: multiSearchInput({
    query: 'salaryComponent',
    model: 'SalaryComponent',
    field: 'include_in_gross_id',
    placeholder: 'Include in gross',
    valueField: 'name',
    searchField: 'name',
    suggestionField: 'name',
  }),
  include_in_id: multiSearchInput({
    query: 'salaryComponent',
    model: 'SalaryComponent',
    field: 'include_in_id',
    placeholder: 'Include in id',
    valueField: 'name',
    searchField: 'name',
    suggestionField: 'name',
  }),
};
