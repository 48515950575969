import React from 'react';

import { FormHoc } from '../../../app-components';
import { submit } from '../../../AppServices';
import { autoSuggestInput, multiAutoSuggestInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';
import { validFromData, validToData } from '../../../Lib/helpers';
import vars from '../../../theme/vars';

const AddEmployeSchedule = (props) => {
  const { navigation: { state: { params: { item } } = {} } = {} } = props;
  const AddEmployeScheduleForm = FormHoc({
    type: 'standardShadow',
    closeView: 1,
    mandatory: {
      ShiftType: 1,
      fromDate: 1,
      endDate: 1,
      dateEffective: 1,
    },
    computations: {
      self: {
        schedule: {
          // eslint-disable-next-line consistent-return
          compute: (data) => {
            if (data?.dateEffective === 'Only Weekend') {
              return {
                set: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: true,
                  sunday: true,
                },
              };
            }
            if (data?.dateEffective === 'All Days') {
              return {
                set: {
                  monday: true,
                  tuesday: true,
                  wednesday: true,
                  thursday: true,
                  friday: true,
                  saturday: true,
                  sunday: true,
                },
              };
            }
            if (data?.dateEffective === 'Weekdays Schedule') {
              return {
                set: {
                  monday: true,
                  tuesday: true,
                  wednesday: true,
                  thursday: true,
                  friday: true,
                  saturday: false,
                  sunday: false,
                },
              };
            }
          },
          onChange: ['dateEffective'],
        },
      },
    },
    submitMessage: 'updateEmployeeSchedule.message.scheduleUpdated'.getLabel(),
    onSubmit: submit({
      model: MODELS.EMPLOYEE_SCHEDULE,
    }),
    defaultValues: () => ({
      employee: item,
      isActive: false,
    }),
    reloadEvent: `reload${MODELS.EMPLOYEE}`,
    validations: {
      fromDate: ({ data }) => validFromData({
        toDate: data?.endDate,
        fromDate: data?.fromDate,
      }),
      endDate: ({ data }) => validToData({
        toDate: data?.endDate,
        fromDate: data?.fromDate,
      }),
    },

    formGroups: [
      {
        columnsPerRow: 3,
        fieldVariant: 'filled',
        label: 'updateEmployeeSchedule.description.detailsForTheSchedule'.getLabel(),
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              multiAutoSuggestInput({
                field: 'ShiftType',
                label: 'updateEmployeeSchedule.labels.shiftType'.getLabel(),
                mandatory: true,
                keyField: 'name',
                valueField: 'name',
                suggestionField: 'name',
                idField: '_id',
                model: MODELS.SHIFT,
                query: 'masterDataList',
              }),
              {
                type: 'toggleSwitch',
                field: 'isActive',
                label: 'holidayCalendar.labels.status'.getLabel(),
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columnsPerRow: 3,
            columns: [
              {
                type: 'date',
                label: 'updateEmployeeSchedule.labels.effectiveFrom'.getLabel(),
                variant: 'filled',
                field: 'fromDate',
                mandatory: true,
              },

              {
                type: 'date',
                label: 'updateEmployeeSchedule.labels.effectiveTo'.getLabel(),
                variant: 'filled',
                field: 'endDate',
                mandatory: true,
              },
              autoSuggestInput({
                type: 'autoSuggest',
                label: 'updateEmployeeSchedule.labels.effectiveDays'.getLabel(),
                field: 'dateEffective',
                mandatory: true,
                keyField: 'name',
                valueField: 'name',
                suggestionField: 'name',
                options: [
                  {
                    name: 'updateEmployeeSchedule.options.onlyWeekend'.getLabel(),
                    value: 'onlyWeekend',
                  },
                  {
                    name: 'updateEmployeeSchedule.options.allDays'.getLabel(),
                    value: 'alldays',
                  },
                  {
                    name: 'updateEmployeeSchedule.options.weekdaysSchedule'.getLabel(),
                    value: 'weekdayschedule',
                  },
                ],
                model: MODELS.EMPLOYEE_SCHEDULE,
                query: 'scheduleDetail',
              }),
              {
                type: 'checkBox',
                label: 'MONDAY',
                container: 'topLabel',
                options: ['MONDAY'],
                field: 'monday',
              },
              {
                type: 'checkBox',
                label: 'TUESDAY',
                container: 'topLabel',
                options: ['tuesday'],
                field: 'tuesday',
              },
              {
                type: 'checkBox',
                label: 'WEDNESDAY',
                container: 'topLabel',
                options: ['wednesday'],
                field: 'wednesday',
              },
              {
                type: 'checkBox',
                label: 'THURSDAY',
                container: 'topLabel',
                options: ['thursday'],
                field: 'thursday',
              },
              {
                type: 'checkBox',
                label: 'FRIDAY',
                container: 'topLabel',
                options: ['friday'],
                field: 'friday',
              },
              {
                type: 'checkBox',
                label: 'SATURDAY',
                container: 'topLabel',
                options: ['saturday'],
                field: 'saturday',
              },
              {
                type: 'checkBox',
                label: 'SUNDAY',
                container: 'topLabel',
                options: ['sunday'],
                field: 'sunday',
              },
            ],
          },
        ],
      },
    ],
  });
  return <AddEmployeScheduleForm {...props} />;
};

export default AddEmployeSchedule;
