import React from 'react';

import {
  View, FormField, LargeButton, OutlineButton,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';

import ListActionModal from '../../../../../Components/ListActionModal';

import { searchInput } from '../../../../../autoSuggestions';
import { notificationError } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const EditNurseContent = (props) => {
  const { navigation } = props;

  const { nurseName, updateNurse } = navigation?.state?.params;
  return (
    <View style={{ flex: 1, paddingLeft: 40, paddingRight: 40 }}>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={({ data }) => {
          updateNurse(
             data?.nurse?._id,
             data?.nurse?.firstName,
             data?.nurse?.lastName,
          );
        }}
      >
        {({ form_context: formContext }) => (
          <>
            {nurseName ? (
              <FormField
                value={nurseName}
                type="text"
                variant="filled"
                label={'vaccination.labels.nurse'.getLabel()}
                editable={false}
              />
            ) : null}
            <View style={{ marginTop: 16 }}>
              <FormField
                {
                ...searchInput({
                  label: 'vaccination.labels.nurse'.getLabel(),
                  field: 'nurse',
                  variant: 'filled',
                  suggestionField: 'fullName',
                  placeholder: 'vaccination.placeholders.nurse'.getLabel(),
                  model: MODELS.EMPLOYEE,
                  query: 'employeeLists',

                }, {
                  modifyResult: ({ data }) => {
                    const newData = data.map((item) => ({
                      ...item,
                      fullName: `${item.firstName} ${item.lastName}`,
                    }));
                    return { data: newData };
                  },
                  addOnFilter: {
                    role: { code: 'NURSE' },
                  },
                })
                }
              />
            </View>
            <View style={{ flexDirection: 'row', marginTop: 38, justifyContent: 'space-around' }}>
              <View>
                <OutlineButton
                  label="Cancel"
                  width={109}
                  onClick={() => {
                    navigation.pop();
                  }}
                />
              </View>
              <View>
                <LargeButton
                  label="Confirm"
                  width={109}
                  onClick={formContext?.handleSubmit}
                />
              </View>

            </View>
          </>
        )}
      </Form>
    </View>
  );
};

const EditNurseModal = (props) => (
  <ListActionModal
    icon={notificationError}
    title={'vaccination.title.editNurse'.getLabel()}
    description={'vaccination.title.descriptionNurseEdit'.getLabel()}
    content={<EditNurseContent {...props} />}
    {...props}
  />
);

export default EditNurseModal;
