import React, { useCallback, useEffect } from 'react';

import ListOfPatient from '../../../../Wired/ListOfPatients';
import { MODELS } from '../../../../Lib/constants';
import { Image, Text, View } from '../../../../app-components';
import vars from '../../../../theme/vars';
import { confirmation, noStatus } from '../../../../images';
import VaccinationStatusRender from '../../../../Wired/VaccinationListOfPatient/VaccinationStatusRender';
import { gpsStore } from '../../../../AppServices';

const tabListColumns = [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
    width: 150,
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'vaccination.headers.vaccinationStatus'.getLabel(),
    type: 'text',
    render: VaccinationStatusRender,
    field: 'status',
  },
  {
    header: 'vaccination.headers.paymentStatus'.getLabel(),
    type: 'text',
    render: ({ item: { isPaid } = {} } = {}) => {
      if (!isPaid) {
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Image
              source={confirmation}
              style={{
                width: 20,
                height: 20,
              }}
            />
            <Text
              style={[
                vars.headings.h9,
                { marginLeft: 10, color: vars.colors.grey.color1 },
              ]}
            >
              Paid
            </Text>
          </View>
        );
      }
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            source={noStatus}
            style={{
              width: 20,
              height: 20,
            }}
          />
          <Text
            style={[
              vars.headings.h9,
              { marginLeft: 10, color: vars.colors.grey.color1 },
            ]}
          >
            Free
          </Text>
        </View>
      );
    },
  },
];

const ListOfPatients = (props) => {
  const { navigation, sessionInfo, eventDispatcher } = props;
  const { session } = sessionInfo;
  const { _id } = session;

  const nurseListReload = useCallback(() => {
    eventDispatcher.notify('nurseListReload');
  }, [eventDispatcher]);

  const subscribeToTokenAssigned = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Token-Assigned',
      callback: nurseListReload,
    });
  }, [nurseListReload]);

  const unSubscribeToTokenAssigned = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Token-Assigned',
      callback: nurseListReload,
    });
  }, [nurseListReload]);

  useEffect(() => {
    subscribeToTokenAssigned();

    return () => {
      unSubscribeToTokenAssigned();
    };
  }, []);

  if (!_id) {
    return <View />;
  }

  return (
    <ListOfPatient
      tableColumns={tabListColumns}
      model={MODELS.VACCINATION}
      id="patientVaccinationList"
      subscribeToUpdates
      {...props}
      addOnFilter={{
        session: {
          _id,
        },
      }}
      reloadEvent="nurseListReload"
    />
  );
};
export default ListOfPatients;
