import React from 'react';
import { upperFirst } from 'lodash';
import { MODELS, modules, ROUTES } from '../../../../Lib/constants';
import MasterList from '../../../../Wired/Master/MasterList';
import FTS from '../../../../Components/TableFilters/FtsSearch';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import permissions from '../../../../Lib/permissions';
import { CategoryList } from '../../../../Queries/category';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'asset.labels.category'.getLabel(),
    type: 'text',
    field: 'name',
    value: ({ item }) => upperFirst(item?.name),
    sortable: 'name',
  },
  {
    header: 'asset.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
    numberOfLines: 2,
    width: 300,
  },
  {
    header: 'asset.labels.subCategory'.getLabel(),
    type: 'link',
    align: 'center',
    value: ({ item }) => item?.subCategory_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item = {} } = props;
        return {
          view: ROUTES.subCategoryListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              category: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'asset.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.PHARMACY_CATEGORY}
    uri={() => CategoryList({ filter: { module: modules.asset } })}
    editFormId={ROUTES.editAssetCategory.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyAssetCategory.permissions.modifyAssetCategory.value}
    header={({ aggregates }) => ({
      title: (
        <HeaderTitleWithCount
          count={aggregates?._count}
          label={'asset.title.categoryManagement'.getLabel()}
        />
      ),
      actions: [(actionProps) => <FTS {...actionProps} action={{ exp: ['name'] }} />],
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewAssetCategory.name,
        params: { item },
      },
    })}
  />
);
