import React from 'react';

import { ActivityIndicator } from '../../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants';
import { Table } from '../../../../../../../../app-components/table/Table';

const tabListColumns = [
  {
    header: 'donorAndDonations.headers.donationDate'.getLabel(),
    type: 'date',
    field: 'date',
    sortable: 'name',
  },
  {
    header: 'donorAndDonations.headers.unitDonated'.getLabel(),
    type: 'number',
    value: '1',
    width: 200,
  },
  {
    header: 'donorAndDonations.headers.weightDonated'.getLabel(),
    type: 'text',
    value: ({ item }) => `${item?.bagWeight}gm`,
    width: 200,
  },
  {
    header: 'donorAndDonations.headers.bagNo'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'donorAndDonations.headers.tubeNo'.getLabel(),
    type: 'text',
    field: 'tubeNo',
  },
  {
    header: 'Attachments',
    type: 'link',
    // eslint-disable-next-line consistent-return
    value: ({ item }) => {
      if (item?.attachment) {
        return item?.attachment?.length;
      }
    },
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewBloodDonorModal.name,
          modal: true,
          params: {
            item,
            filter: {
              donorId: { _id: item?._id },
            },
          },
          ...props,
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
];

const ViewTabList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;
  if (!data?._id) {
    return <ActivityIndicator />;
  }
  return (
    <Table
      {...restProps}
      uri={{
        query: {
          id,
          addOnFilter,
        },
        model,
      }}
      reloadEvent={`reload${MODELS.BLOOD_DONATION}`}
      columns={tableColumns}
      showActionIcon={false}
      hoverable={false}
    />
  );
};
export default ViewTabList;
