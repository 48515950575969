import React from 'react';
import { ROUTES } from '../../Lib/constants';
import { View, Image, Text } from '../../app-components';
import vars from '../../theme/vars';
import { welcomeImage } from '../../images';
import { organizationLabels } from '../../labels/en/logoOrganisationLabels';

const Welcome = () => (
  <View style={{
    flex: 1, ...vars.headings.h11,
  }}
  >
    <View style={{
      flex: 1,
      position: 'absolute',
      top: 80,
      left: 80,
      maxWidth: 624,
      zIndex: 0.5,
    }}
    >
      <Text style={{
        ...vars.headings.h7,
        color: vars.colors.secondary.color1,
        fontSize: 36,
        lineHeight: 42.3,
        marginBottom: 16,
      }}
      >
        Welcome !
      </Text>
      <Text style={{
        ...vars.headings.h6,
        color: vars.colors.secondary.color2,
      }}
      >
        A Management tool for the Healthcare Members of
        {' '}
        {organizationLabels.organizationName}
        {' '}
        to help them with the daily tasks of the variety of departments.
      </Text>
    </View>
    <View style={{
      flex: 1,
      position: 'absolute',
      bottom: 0,
      right: 0,
    }}
    >

      <Image
        source={welcomeImage}
        style={{
          height: 475,
        }}
      />
    </View>
  </View>
);

export default {
  [ROUTES.Welcome.name]: { screen: Welcome },
};
