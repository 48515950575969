import { userAttendanceDrill } from './AttendanceLists';
import { UpdateUserAttendance, AttendanceFormWithDateHoc } from './AttendanceActions';

export default {
  'attendance-drill': {
    screen: userAttendanceDrill,
    title: ({ navigation }) => {
      const { item } = (navigation && navigation.getParams('Attendance')) || {};
      const { month_id: month } = item;
      return month?.name;
    },
  },
  'update-attendance': {
    screen: UpdateUserAttendance,
    modal: true,
  },
  'update-attendance-for-particular-day': {
    screen: AttendanceFormWithDateHoc,
    modal: true,
  },
};
