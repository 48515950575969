import React from 'react';
import moment from 'moment';
import MasterList from '../Master/MasterList';
import {
  ActivityIndicator,
} from '../../app-components';
import { getShiftType, getWeekDaysSchedule } from '../../Lib/helpers';

const tabListColumns = [
  {
    header: 'employeeSchedule.header.dateRange'.getLabel(),
    type: 'text',
    value: ({ item }) => `${moment(item?.fromDate).format('DD MMM YYYY')
    } - ${
      moment(item?.endDate).format('DD MMM YYYY')}`,
  },
  {
    header: 'employeeSchedule.header.shiftType'.getLabel(),
    type: 'text',
    width: 300,
    numberOfLines: 5,
    value: ({ item: { ShiftType = {} } }) => getShiftType(ShiftType),
  },
  {
    header: 'employeeSchedule.header.weekdaysSchedule'.getLabel(),
    type: 'text',
    value: ({ item }) => getWeekDaysSchedule(item),
  },
  {
    header: 'employeeSchedule.header.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

const EmployeeScheduleList = ((props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <MasterList
      {...restProps}
      hoverable={false}
      uri={{
        query: {
          id,
          addOnFilter,
        },
        model,
      }}
      tableColumns={tableColumns}
      showActionIcon={false}
    />
  );
});
export default EmployeeScheduleList;

