import React from 'react';
import { FormField, StandardButton, View } from '../../../../app-components';
import { notificationTickWithBG } from '../../../../images';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import vars from '../../../../theme/vars';
import { submit } from '../../../../AppServices';
import { MODELS, modules } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';

const AddAssetSubCategoryModal = (props) => {
  const {
    navigation: {
      state: {
        params: { category },
      },
    },
  } = props;
  return (
    <ListActionModal
      icon={notificationTickWithBG}
      title={'asset.labels.subCategory'.getLabel()}
      description={'asset.title.addSubCategoryDescription'.getLabel()}
      content={(
        <Form
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          mandatory={{
            name: 1,
          }}
          onSubmit={submit({
            model: MODELS.PHARMACY_SUBCATEGORY,
          })}
          reloadEvent={`reload${MODELS.PHARMACY_SUBCATEGORY}`}
          submitMessage={'asset.messages.subCategoryAddedSuccessfully'.getLabel()}
          defaultValues={() => ({
            category,
            module: modules.asset,
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 24,
                paddingTop: 24,
                paddingLeft: 22,
                paddingRight: 22,
              }}
            >
              <FormField
                type="text"
                label={'asset.labels.subCategoryName'.getLabel()}
                variant="filled"
                field="name"
                allowWhiteSpaces
                modifyValueOnChange={({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                }}
                style={{
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                mandatory
              />
              <FormField
                type="textArea"
                label={'asset.labels.description'.getLabel()}
                variant="filled"
                field="description"
                minHeight={80}
                style={{
                  marginTop: 16,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <View style={{ marginTop: 16 }}>
                <FormField
                  type="toggleSwitch"
                  label={'asset.labels.status'.getLabel()}
                  container="topLabel"
                  variant="filled"
                  field="isActive"
                />
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'asset.buttons.create'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default AddAssetSubCategoryModal;
