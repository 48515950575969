import React from 'react';
import ListActionModal from '../../../Components/ListActionModal';
import { Form } from '../../../app-components/form/Form';
import { resetPassword } from '../../../images';
import {
  FormField, OutlineButton, View,
} from '../../../app-components';
import { TextInput } from '../../../app-components/input-components/Editors';
import { validateConfirmPassword, validatePassword } from '../../../Lib/helpers';
import { mamcChangePassword } from '../../../AppServices';

const ChangePasswordModal = (props) => {
  const { navigation } = props;

  const item = navigation.getParam('item');
  const handleSubmit = ({ data }) => {
    mamcChangePassword(data).then((res) => {
      if (res?.message === 'Password changed successfully') {
        navigation.pop();
      }
    });
  };

  return (
    <ListActionModal
      icon={resetPassword}
      title="Reset Password"
      description="Change password for selected user"
      content={(
        <Form
          {...props}
          type="standardShadow"
          onSubmit={handleSubmit}
          defaultValues={() => ({
            username: item?.employeeId,
            employeeId: item?._id,
          })}
          validations={{
            newPassword: ({ data }) => validatePassword(data.newPassword),
            confirmPassword: ({ data }) => validateConfirmPassword(data),
          }}
          mandatory={{
            newPassword: 1,
            confirmPassword: 1,
          }}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                flex: 1,
                marginTop: 26,
                alignItems: 'center',
              }}
            >
              <View style={{ width: 312 }}>
                <FormField
                  label="New Password"
                  field="newPassword"
                  placeholder="New Password"
                  variant="filled"
                  mandatory
                >
                  <TextInput inputProps={{ type: 'password' }} />
                </FormField>
              </View>
              <View style={{ width: 312, marginTop: 10 }}>
                <FormField
                  label="Confirm Password"
                  field="confirmPassword"
                  placeholder="Confirm Password"
                  variant="filled"
                  mandatory
                >
                  <TextInput inputProps={{ type: 'password' }} />
                </FormField>
              </View>
              <View style={{ marginTop: 30, alignItems: 'center' }}>
                <OutlineButton
                  label="Save"
                  onClick={formContext.handleSubmit}
                  width={150}
                />
              </View>
            </View>
          )}
        </Form>
        )}
      {...props}
    />
  );
};

export default ChangePasswordModal;
