import React, {
  useCallback,
} from 'react';

import {
  Toast,
  View,
} from '../../../../../app-components';
import { OutlineButtonNotAppread, TableActionButton } from '../../../../../app-components/buttons/Buttons';
import { invoke } from '../../../../../AppServices';
import { ROUTES } from '../../../../../Lib/constants';
import { getErrorString } from '../../../../../Lib/helpers';

const Actions = (props) => {
  const { navigation, eventDispatcher } = props;
  const {
    item = {},
    patientDetails,
  } = props;

  const {
    _id,
  } = item;

  const handleOnPressViewDetails = useCallback(() => {
    navigation.push({ view: ROUTES.viewPatientDetail.name, params: { item } });
  }, [item, navigation]);

  const handleNotAppearedForVaccination = useCallback(async () => {
    try {
      const result = await invoke({
        id: 'patientNotAppearedForVaccination',
        paramValue: { ...item },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Patient has been marked as not appeared.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify('vaccinationAssigned');
        navigation.pop();
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [item, navigation]);

  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      <View style={{ marginRight: 10 }}>
        {/* {status !== 'vaccinated' && (
        <OutlineButtonNotAppread
          label="Not Appeared"
          onPress={handleNotAppearedForVaccination}
        />
        )} */}
      </View>
      {_id !== patientDetails?._id && (
        <TableActionButton
          label={'incomingPatient.labels.viewDetails'.getLabel()}
          onPress={handleOnPressViewDetails}
        />
      )}
    </View>
  );
};

export default Actions;
