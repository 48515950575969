import React from 'react';

import {
  TabNavigator,
} from '../../../app-components/tab/TabNavigator';
import DiscardedPharmacyBillingRecords from './discardedPharmacyBillingRecords';
import PharmacyBillingRecords from './pharmacyBillingRecords';
import { tabTheme } from '../../../theme/tabBarTheme';
import { countUri } from '../../../Queries/countUri';
import { MODELS } from '../../../Lib/constants';
import { getPermission } from '../../../AppServices';
import permissions from '../../../Lib/permissions';
import CanceledBillingHistory from './CancelBllHistory';

const PharmacyBillingHistory = (props) => (
  <TabNavigator
    {...props}
    theme={{ ...tabTheme }}
    tabs={{

      ActiveBills: {
        label: 'Active Bills',
        screen: PharmacyBillingRecords,
        countUri: countUri({
          model: MODELS.PHARMACY_BILLING,
          id: 'pharmacyBillingDataList',
          filter: { status: 'Active' },
          reloadEvent: 'pharmacyBillingDataList',

        }),
      },

      CanceledBills: {
        label: 'Canceled Bills',
        visible: () => getPermission(
          permissions?.billing?.views?.viewCanceledBill?.permissions?.viewCanceledBill?.value,
        ),
        screen: CanceledBillingHistory,
        countUri: countUri({
          model: MODELS.PHARMACY_BILLING,
          id: 'pharmacyBillingDataList',
          reloadEvent: 'pharmacyBillingDataList',
          filter: { status: 'Pending from MS' },
        }),
      },

      DiscardedBills: {
        label: 'Discarded Bills',
        screen: DiscardedPharmacyBillingRecords,
        countUri: countUri({
          model: MODELS.PHARMACY_BILLING,
          id: 'pharmacyBillingDataList',
          reloadEvent: 'pharmacyBillingDataList',
          filter: { status: 'discarded' },
        }),
      },
    }}
  />
);

export default PharmacyBillingHistory;

