import React from 'react';
import {
  FilterGroup, OutlineButton, Toast,
} from '../../../../../app-components';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { getPermission, getUser, invoke } from '../../../../../AppServices';
import { searchFilter } from '../../../../../autoSuggestions';
import WithPermission from '../../../../../Components/WithPermission';
import { filterActive, filterInactive } from '../../../../../images';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import permissions from '../../../../../Lib/permissions';
import tabTheme from '../../../../../theme/childTabTheme';
import MonthlyAttendance from './MonthlyAttendance';
import Approved from './Approved';
import ReviewPending from './ReviewPending';
import SentForReview from './SentForReview';
import Hold from './Hold';
import { getErrorString } from '../../../../../Lib/helpers';

const EmployeeAttendanceChildTabNavigation = (props) => {
  const tabs = {};
  if (getPermission(permissions.departments.views.attendanceApprove
    .permissions.attendanceApprove.value)) {
    tabs.monthlyAttendance = {
      label: 'Monthly Attendance',
      screen: MonthlyAttendance,
      screenProps: {
        header: {
          selectableActions: [
            {
              // eslint-disable-next-line no-shadow
              render: (props) => (
                <WithPermission
                  access_key={
                            permissions.departments.views.attendanceApprove
                              .permissions.attendanceApprove.value
                            }
                >
                  <OutlineButton
                    {...props}
                    label="Approve"
                    onPress={async () => {
                      try {
                        const result = await invoke({
                          id: 'attendanceFlow',
                          paramValue: {
                            action: 'approved',
                            ids: props?.state?.selectedIds,
                          },
                        });
                        if (result) {
                          Toast.show({
                            message: 'Success',
                            type: 'success',
                            position: 'top',
                            direction: 'right',
                            duration: 3000,
                          });
                          props.eventDispatcher.notify(`reload${MODELS.MONTHLY_ATTENDANCE}`);
                          props.setScreenState({
                            selectedIds: [],
                          });
                        }
                      } catch (e) {
                        Toast.show({
                          // message: 'Error',
                          description: `${getErrorString(e)}`,
                          type: 'error',
                          position: 'top',
                          direction: 'right',
                        });
                      }
                    }}
                  />
                </WithPermission>

              ),
            },
          ],
        },
      },
    };
  }
  tabs.approved = {
    label: 'Approved By HOD',
    screen: Approved,
    screenProps: {
      header: {
        selectableActions: getPermission(permissions.departments.views.attendanceHold
          .permissions.attendanceHold.value) ? [
            {
            // eslint-disable-next-line no-shadow
              render: (props) => (
                <WithPermission
                  access_key={
                         permissions.departments.views.attendanceHold
                           .permissions.attendanceHold.value
                       }
                >
                  <OutlineButton
                    {...props}
                    label="Hold"
                    onPress={async () => {
                      const result = await invoke({
                        id: 'attendanceFlow',
                        paramValue: {
                          action: 'hold',
                          ids: props?.state?.selectedIds,
                        },
                      });
                      if (result) {
                        Toast.show({
                          message: 'Success',
                          // description: successMessage,
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                        props.eventDispatcher.notify(`reload${MODELS.MONTHLY_ATTENDANCE}`);
                        props.setScreenState({
                          selectedIds: [],
                        });
                      }
                    }}
                  />
                </WithPermission>

              ),
            },
          ] : [],
      },

    },
  };

  tabs.hold = {
    label: 'Hold',
    screen: Hold,
  };

  tabs.reviewPending = {
    label: 'Review Pending',
    screen: ReviewPending,
    screenProps: {
      header: {
        selectableActions: [
          {
            // eslint-disable-next-line no-shadow
            render: (props) => (
              <WithPermission
                access_key={
                  permissions.departments.views.attendanceReview
                    .permissions.attendanceReview.value
                  }
              >
                <OutlineButton
                  {...props}
                  label={getUser().role[0].code === 'HOD' ? 'Send For Approval' : 'Approve'}
                  onPress={async () => {
                    const result = await invoke({
                      id: 'attendanceFlow',
                      paramValue: {
                        action: getUser().role[0].code === 'HOD' ? 'inReview' : 'approved',
                        ids: props?.state?.selectedIds,
                      },
                    });
                    if (result) {
                      Toast.show({
                        message: 'Success',
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                      props.eventDispatcher.notify(`reload${MODELS.MONTHLY_ATTENDANCE}`);
                      props.setScreenState({
                        selectedIds: [],
                      });
                    }
                  }}
                />
              </WithPermission>

            ),
          },
        ],
      },

    },
  };

  tabs.sentForReview = {
    label: 'Sent For Review',
    screen: SentForReview,
  };

  return (
    <TabNavigator
      theme={{ ...tabTheme }}
      actions={[
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'month_id',
              label: 'Month',
              model: MODELS.MONTHS,
              id: 'MonthTypeSuggestions',
              searchField: 'name',
              placeholder: 'Months',
              suggestionField: 'name',
              primaryField: 'name',
              valueField: '_id',
            },
            {
              type: 'autoSuggest',
              field: 'year_id',
              label: 'Years',
              model: MODELS.YEARS,
              id: 'YearTypeSuggestions',
              searchField: 'name',
              placeholder: 'Years',
              suggestionField: 'name',
              primaryField: 'name',
              valueField: '_id',
            },
            {
              type: 'autoSuggest',
              field: 'finantial_year_id',
              label: 'Financial Year',
              model: MODELS.FINANCIAL_YEAR,
              id: 'financialyears',
              searchField: 'name',
              placeholder: 'Year',
              sugestionField: 'financial_year',
              primaryField: 'financial_year',
              valueField: 'financial_year',
            },
            searchFilter({
              field: 'employee.department',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              label: 'Department',
              suggestionField: 'name',
              multiSelect: true,
              filterTabs: ['pending', 'approved', 'rejected'],
            }),
            {
              type: 'autoSuggest',
              field: 'speciality',
              label: 'Speciality',
              model: MODELS.WARDS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select specialities',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            searchFilter({
              field: 'employee_id',
              model: MODELS.EMPLOYEE,
              id: 'employeeLists',
              searchField: 'firstName',
              label: 'Employee',
              suggestionField: 'fullName',
              multiSelect: true,
              filterTabs: ['pending', 'approved', 'rejected'],
            },
            {
              modifyResult: ({ data }) => {
                const clonedData = data.map((item) => ({
                  ...item,
                  fullName: `${item?.firstName} ${item?.lastName}`,
                }));
                return { data: clonedData };
              },
            }),
          ],
        }),
      ]}
      tabs={tabs}
      {...props}
    />
  );
};

export default EmployeeAttendanceChildTabNavigation;
