import React from 'react';

import { viewVitalSignSheetUri } from '../../../../../../../../Queries/vitalSign';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';

const IpdViewVitalsPediatrics = (props) => {
  const { navigation: { state: { params: { addOnFilter: filter } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Vitals Signs"
      description="Logged Vitals of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
          type="standardShadow"
          uri={viewVitalSignSheetUri(filter)}
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            label: 'TIME',
                            type: 'date',
                            format: 'HH:mm A',
                            field: 'vitalSignTime',
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignBP',
                            label: 'BP',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'crt',
                            label: 'CRT',
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'activity',
                            container: 'topLabel',
                            editable: false,
                            label: 'Activity',
                          },
                          {
                            type: 'text',
                            field: 'color',
                            label: 'Color',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignTemperature',
                            label: 'TEMPERATURE',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignPulse',
                            label: 'PULSE',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignHr',
                            label: 'HR',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignResp',
                            label: 'RESP.',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignO2',
                            label: 'O2 Flow Rate',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'vitalSignFIO2',
                            label: 'FIO2',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'oxygenSaturation',
                            label: 'Oxygen Saturation',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'bloodSugar',
                            label: 'blood Sugar',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'urine',
                            label: 'Urine',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'stool',
                            label: 'Stool',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'abdominalGirth',
                            label: 'Abdominal Girth',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'patency',
                            container: 'topLabel',
                            editable: false,
                            label: 'Patency',
                          },
                          {
                            type: 'number',
                            field: 'rtAspirate',
                            label: 'R.T Aspirate',
                            mandatory: true,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'bloodCollection',
                            label: 'blood Collection',
                            mandatory: true,
                            editable: false,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'textArea',
                            field: 'nurseNote',
                            label: 'Nurse Note',
                            placeHolder: 'Type Here....',
                            variant: 'filled',
                            editable: false,
                            height: 92,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const ViewVitalsPediatrics = (props) => (<IpdViewVitalsPediatrics {...props} />);
export default ViewVitalsPediatrics;
