import React from 'react';

import { TabNavigator } from '../../../../../../app-components';
import { countUri } from '../../../../../../Queries/countUri';
import { MODELS } from '../../../../../../Lib/constants';
import { testTypeListUri } from '../../../../../../Queries/test';
import TestComponentTabList from '../../../../../../Wired/TestComponentTabList';
import ActivityLogsTabList from '../../../../../../Wired/ActivityLogList';

const ViewTestTabs = (props) => {
  const { data } = props;
  if (!data?._id) {
    return null;
  }
  const ComponentTabNavigations = TabNavigator({
    tabs: {
      TestLinked: {
        label: 'testComponent.label.testLinked'.getLabel(),
        screen: TestComponentTabList,
        uri: testTypeListUri,
        screenProps: {
          data,
          model: MODELS.LAB_TEST,
          id: 'masterDataListForTable',
          addOnFilter: { testComponentType: { _id: data?._id } },
        },
        countUri: countUri({
          model: MODELS.LAB_TEST,
          id: 'masterDataListForTable',
          filter: { testComponentType: { _id: data?._id } },
        }),
      },
      ActivityLogs: {
        label: 'Activity Logs',
        screen: ActivityLogsTabList,
        screenProps: {
          data,
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivity',
          addOnFilter: { id: data?._id },
          hoverable: false,
        },
        countUri: countUri({
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivityCount',
          filter: { id: data?._id },
        }),
      },
    },
  });
  return <ComponentTabNavigations {...props} />;
};
export default ViewTestTabs;
