import React from 'react';
import moment from 'moment';
import {
  Table, Text,
} from '../../app-components';
import vars from '../../theme/vars';
import { ROUTES } from '../../Lib/constants';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'vaccination.headers.vaccineName'.getLabel(),
        type: 'text',
        field: 'item.name',
      },
      {
        header: 'vaccination.headers.vaccinationDate'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { lastVaccination } = item;
          if (lastVaccination?._createdOn) {
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {moment(lastVaccination?._createdOn).format('DD-MMM-YYYY')}
              </Text>
            );
          }
          return '--';
        },
      },
      {
        header: 'vaccination.headers.doseDuseDate'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        render: ({ item } = {}) => {
          const {
            item: {
              vaccinationReOccurringDetails, isReoccurringIntervalEqual, vaccinationType, noOfDoses,
            }, vaccineDoseTaken, lastVaccination,
          } = item;
          if (!vaccineDoseTaken) {
            return '--';
          }
          if (vaccinationType === 'reCurring' && isReoccurringIntervalEqual) {
            const { interval, intervalType } = vaccinationReOccurringDetails[0];
            const dueDate = moment(lastVaccination?._createdOn).add(interval, intervalType).format('DD-MMM-YYYY');
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {dueDate}
              </Text>
            );
          }
          if (vaccinationType === 'reCurring') {
            if (vaccineDoseTaken && vaccineDoseTaken < noOfDoses) {
              const { interval, intervalType } = vaccinationReOccurringDetails[vaccineDoseTaken];
              const dueDate = moment(lastVaccination?._createdOn).add(interval, intervalType).format('DD-MMM-YYYY');
              return (
                <Text style={{ ...vars.headings.h9 }}>
                  {dueDate}
                </Text>
              );
            }
            return '--';
          }
          return '--';
        },
      },
      {
        header: 'vaccination.headers.noOfDose'.getLabel(),
        type: 'number',
        field: 'item.noOfDoses',
      },
      {
        header: 'vaccination.headers.dosePending'.getLabel(),
        type: 'number',
        render: ({ item }) => {
          const { vaccineDoseTaken } = item;
          const { item: { noOfDoses } = {} } = item;
          if (vaccineDoseTaken) {
            return (
              <Text style={{ ...vars.headings.h9 }}>
                {noOfDoses - vaccineDoseTaken}
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h9 }}>
              {noOfDoses}
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.status'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { vaccineDoseTaken } = item;
          const { item: { noOfDoses } = {} } = item;
          if (vaccineDoseTaken) {
            if (noOfDoses - vaccineDoseTaken > 0) {
              return (
                <Text style={{ ...vars.headings.h9, color: vars.colors.warning }}>
                  Pending
                </Text>
              );
            }
            if (noOfDoses === vaccineDoseTaken) {
              return (
                <Text style={{ ...vars.headings.h9, color: vars.colors.primary.color1 }}>
                  Provided
                </Text>
              );
            }
          }
          return (
            <Text style={{ ...vars.headings.h9, color: vars.colors.warning }}>
              Pending
            </Text>
          );
        },
      },
    ],
  },
};

const VaccinationLogs = (props) => {
  const {
    addOnFilter, model, id,
  } = props;
  const { navigation } = props;
  /*
    We get params in navigation when we open screen as a modal
  */
  const {
    state: { params = {} } = {},
  } = navigation;
  const VaccinationLogsTable = Table({
    uri: () => ({
      query: {
        id: id || params?.id,
        addOnFilter: addOnFilter || params?.addOnFilter,
        sort: { _createdOn: -1 },
      },
      model: model || params?.model,
    }),
    hoverable: true,
    ...tableItems,
    navigation,
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewVaccinationLogPatientManagement.name,
        params: { item },
      },
    }),
  });

  return <VaccinationLogsTable />;
};

export default VaccinationLogs;

