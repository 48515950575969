import React from 'react';
import MasterList from '../Master/MasterList';
import { ActivityIndicator } from '../../app-components';

const tabListColumns = [
  {
    header: 'Employee ID',
    type: 'text',
    field: 'employeeId',
  },
  {
    header: 'UserName',
    type: 'text',
    field: 'firstName',
  },
  {
    header: 'Location',
    type: 'text',
    field: 'addressLine1',
  },
  {
    header: 'Status',
    type: 'status',
    field: 'isActive',
  },
];

const EmployeeTabList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;

  if (!data?._id) {
    return <ActivityIndicator />;
  }

  return (
    <MasterList
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      tableColumns={tableColumns}
      showActionIcon={false}
    />
  );
};
export default EmployeeTabList;
