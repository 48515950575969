import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../Lib/constants';
import { NestedTable } from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { formFields, nestedTableFields } from '../components/ViewPatientDispensingColumns';
import vars from '../../../../../../../theme/vars';

const ViewIPDPatientDispensing = (props) => {
  const { navigation: { state: { params: { item: indentRequest } = {} } = {} } = {} } = props;

  return (
    <Form
      type="standard"
      closeView={1}
      reloadEvent={`reload${MODELS.PHARMACY_INDENT}`}
      editable={false}
      defaultValues={() => ({ indentRequest })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  ...formFields,
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.indentDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'indentRequest.indentDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          paddingLeft: 12,
                          paddingRight: 4,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 2,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          ...nestedTableFields,
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewIPDPatientDispensing;
