import React from 'react';
import { View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { MODELS } from '../../../../../Lib/constants';
import { viewBloodCategoryAggregatedList } from '../../../../../Queries/bloodBank';
import BloodGroupTabs from './tabs';

const formFields = {
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 4,
          columns: [
            {
              type: 'text',
              field: 'category',
              label: 'bloodInventory.labels.bloodGroupName'.getLabel(),
            },
            {
              type: 'number',
              field: 'unitInStock',
              label: 'bloodInventory.labels.unitInStocks'.getLabel(),
            },
            {
              type: 'status',
              field: 'status',
              label: 'bloodInventory.labels.status'.getLabel(),
            },
          ],
        },
      ],
    },
  ],
};

export default (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1 }}>
      <Form
        lg={formFields}
        editable={false}
        columnsPerRow={4}
        reloadOnChangeEvent={`reload${MODELS.BLOOD_BANK}`}
        uri={() => viewBloodCategoryAggregatedList(props)}
        {...props}
      />
    </View>
    <View style={{ flex: 4, overflow: 'hidden' }}>
      <BloodGroupTabs {...props} />
    </View>
  </View>
);
