import React from 'react';

import { VaccineScheduleToggleFilter } from '../../../app-components/filter/MamcToggleFilters';
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 14 },
  {
    header: 'vaccination.headers.vaccineName'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    header: 'vaccination.headers.vaccineType'.getLabel(),
    type: 'text',
    value: ({ item }) => (item?.isVaccineGlobal ? 'GLOBAL' : 'Recommended'),
  },
  {
    header: 'vaccination.headers.reVaccinationType'.getLabel(),
    type: 'text',
    value: ({ item: { vaccinationType } }) => (vaccinationType === 'reCurring' ? 'Recurrring' : 'Once in  lifetime'),
  },
  {
    header: 'vaccination.headers.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'vaccination.headers.stockAvailable'.getLabel(),
    type: 'text',
    value: ({ item }) => (item?.stockAvailable[0].stockAvailable ? item?.stockAvailable[0].stockAvailable : '0'),
  },
  {
    header: 'vaccination.headers.vaccineStatus'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.PHARMACY_ITEM}
    filter={{ isVaccine: true }}
    editFormId={ROUTES.addVaccineSchedule.name}
    reloadEvent={`reload${MODELS.PHARMACY_ITEM}`}
    access_key={permissions.mdm.views.addVaccineSchedule.permissions.addVaccineSchedule.value}
    tableColumns={tableColumns}
    header={{
      leftActions: [(actionProps) => (
        <VaccineScheduleToggleFilter
          {...actionProps}
          model={MODELS.PHARMACY_ITEM}
          id="masterDataListForTable"
        />
      )],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewVaccineSchedule.name,
        params: { item },
      },
    })}
  />
);

