/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect } from 'react';

import { Text, View } from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { gpsStore } from '../../../../../AppServices';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import VaccinationStatusRender from '../../../../../Wired/VaccinationListOfPatient/VaccinationStatusRender';

const tableColumns = [
  {
    width: 20,
  },
  {
    header: 'vaccination.headers.token'.getLabel(),
    type: 'number',
    field: 'token',
    width: 120,
    render: ({ item }) => (
      <View style={{}}>
        <Text
          style={{
            ...vars.headings.h2,
          }}
        >
          {item?.token}
        </Text>
      </View>
    ),
  },
  {
    header: 'vaccination.headers.uhid'.getLabel(),
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'vaccination.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'vaccination.headers.status'.getLabel(),
    type: 'custom',
    render: VaccinationStatusRender,
    width: 200,
  },
  {
    width: 50,
  },
];
const VaccinationPatientsListTabs = (props) => {
  const { activeSession, eventDispatcher } = props;
  const { _id } = activeSession;

  const vaccinationStatusUpdate = useCallback(() => {
    eventDispatcher.notify('vaccinationStatusUpdate');
  }, [eventDispatcher]);

  const subscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Vaccination-Assigned',
      callback: vaccinationStatusUpdate,
    });
  }, [vaccinationStatusUpdate]);

  const unSubscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Vaccination-Assigned',
      callback: vaccinationStatusUpdate,
    });
  }, [vaccinationStatusUpdate]);
  const subscribeToStatusChange = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Status-Change',
      callback: vaccinationStatusUpdate,
    });
  }, [vaccinationStatusUpdate]);

  const unSubscribeToStatusChange = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Status-Change',
      callback: vaccinationStatusUpdate,
    });
  }, [vaccinationStatusUpdate]);

  useEffect(() => {
    subscribeToVaccinationAssigned();
    subscribeToStatusChange();
    return () => {
      unSubscribeToVaccinationAssigned();
      unSubscribeToStatusChange();
    };
  }, []);

  return (
    <Table
      uri={() => ({
        query: {
          id: 'patientVaccinationList',
          addOnFilter: {
            session: { _id },
            status: { $in: ['nurse-assigned', 'not-appeared', 'vaccinated'] },
          },
        },
        model: MODELS.VACCINATION,
      })}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent="vaccinationStatusUpdate"
      {...props}
    />
  );
};

export default VaccinationPatientsListTabs;
