/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { upperFirst } from 'lodash';
import {
  Text, View,
} from '../../../../app-components';
import { Headline9, Headline6 } from '../../../../theme/Headlines';
import { searchInput, autoSuggestInput } from '../../../../autoSuggestions';
import {
  NestedTable,
} from '../../../../app-components/input-components/nestedTable/NestedTable';
import { manazeV6Colors } from '../../../../theme/manazeV6Colors';
import { MODELS } from '../../../../Lib/constants';
// eslint-disable-next-line import/no-cycle
import { UpdateSavingHeadActions } from './CommonTaxDeclarations';
import vars from '../../../../theme/vars';
import FileLink from '../../../../Components/FileLink';
import permissions from '../../../../Lib/permissions';
import { getPermission } from '../../../../AppServices';

const {
  neutral_2,
  positive_communication_2,
  negative_communication_2,
} = manazeV6Colors;

export const LinkTypeAction = ({ view, params }) => ({
  type: 'link',
  link: {
    view,
    params,
  },
});

export const Layouts = {
  Month: {
    header: 'Month',
    field: 'month_id.name',
    type: 'text',
  },
  Year: {
    header: 'Year',
    field: 'year_id.name',
    type: 'text',
  },
  Payable: {
    header: 'Payable',
    field: 'payable_amount',
    value: ({ item }) => (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0,
    type: 'number',
  },
  PayableFromComponentDetail: {
    header: 'Payable',
    field: 'component_details.payable_amount',
    value: ({ item }) => item
      && item.component_details
      && item.component_details.payable_amount
      && item.component_details.payable_amount.toFixed(2),
    type: 'number',
  },
  InvoiceDate: {
    header: 'Date',
    field: 'invoiceDate',
    type: 'date',
    format: 'DD MMM YY',
  },
  GrossAmt: {
    header: 'Add on (INR)',
    textStyle: { color: positive_communication_2, ...Headline6 },
    value: ({ item }) => {
      const amount = (item && item.gross_amount && item.gross_amount.toFixed(2)) || 0;
      return `₹ ${amount}`;
    },
    type: 'text',
  },
  GrossAmtFromComponentDetail: {
    header: 'Gross',
    field: 'component_details.gross_amount',
    value: ({ item }) => item
      && item.component_details
      && item.component_details.gross_amount
      && item.component_details.gross_amount.toFixed(2),
    type: 'number',
  },
  Deduction: {
    header: 'Deductions (INR)',
    textStyle: { color: negative_communication_2, ...Headline6 },
    value: ({ item }) => {
      const grossAmount = (item && item.gross_amount && item.gross_amount.toFixed(2)) || 0;
      const payableAmount = (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0;
      return `₹ ${grossAmount - payableAmount}`;
    },
    type: 'text',
  },
  PayableAmt: {
    header: 'Payable (INR)',
    textStyle: { color: neutral_2, ...Headline6 },
    value: ({ item }) => {
      const amount = (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0;
      return `₹ ${amount}`;
    },
    type: 'text',
  },
  SalaryComponent: {
    header: 'Component',
    field: 'component_details.salary_component_id.name',
    type: 'text',
  },
  VariableAmt: {
    header: 'Amount',
    field: 'variable',
    value: ({ item }) => item && item.variable && item.variable.toFixed(2),
    type: 'number',
  },
  FromDate: {
    type: 'date',
    field: 'from_date',
    header: 'From Date',
    format: 'DD MMM YY',
  },
  ToDate: {
    header: 'To Date',
    field: 'to_date',
    type: 'date',
    format: 'DD MMM YY',
  },
  CTCAmt: {
    header: 'CTC(Per Month)',
    field: 'ctc_amount',
    value: ({ item }) => item && item.ctc_amount && item.ctc_amount.toFixed(2),
    type: 'number',
  },
  FinancialYear: {
    header: 'Financial Year',
    type: 'text',
    value: ({ item }) => `Declaration for FY ${item.finantial_year_id.name}`,
    width: 150,
  },
  FinancialYearNo: {
    header: 'Financial Year',
    type: 'text',
    value: ({ item }) => `${item.finantial_year_id.name}`,
    width: 150,
  },
  employee: {
    header: 'Employee',
    type: 'text',
    value: ({ item }) => `${`${item?.employee_id?.firstName} ${item?.employee_id?.lastName}`}`,
    width: 150,
  },
  employeeCode: {
    header: 'Employee ID',
    type: 'text',
    value: ({ item }) => `${item?.employee_id?.employeeId}`,
    width: 150,
  },
  TaxCalculationDate: {
    type: 'date',
    field: 'tax_calculation_date',
    header: 'DECLARED ON',
    format: 'DD MMM YY',
    width: 150,
  },
  NetTaxableIncome: {
    type: 'text',
    field: 'net_taxable_income',
    value: ({ item }) => item && item.net_taxable_income && item.net_taxable_income.toFixed(2),
    header: 'TAXABLE INCOME',
    width: 150,
  },
  Exempted: {
    type: 'text',
    field: '',
    header: 'EXEMPTED',
  },
  TotalTaxAmt: {
    type: 'text',
    field: 'total_tax_amount',
    value: ({ item }) => item && item.total_tax_amount && item.total_tax_amount.toFixed(2),
    header: 'TOTAL TAX',
    width: 150,
  },
  TaxPaid: {
    type: 'text',
    field: 'tax_already_paid',
    header: 'TAX PAID',
    width: 150,
  },
  PendingTax: {
    type: 'text',
    header: 'PENDING TAX',
    value: ({ item }) => {
      const totalTax = (item && item.total_tax_amount) || 0;
      const taxPaid = (item && item.tax_already_paid) || 0;
      return (totalTax - taxPaid).toFixed(2);
    },
    width: 150,
  },
  PanNumber: {
    header: ' PAN number',
    type: 'text',
    field: 'employee_id.panNumber',
    width: 150,
  },
  IsFreeze: {
    type: 'text',
    field: 'is_freezed',
    value: ({ item }) => (item && item.is_freezed ? 'Yes' : 'No'),
    header: 'IS FREEZED',
  },
  EmployeeStatus: {
    type: 'text',
    field: 'employee_status',
    header: 'STATUS',
  },
  Delivery: {
    type: 'text',
    field: 'delivery_number',
    header: 'Delivery',
  },
  Potential: {
    type: 'number',
    field: 'resourcePotential',
    header: 'Potential',
    value: ({ item }) => (item && item.resourcePotential && item.resourcePotential.toFixed(2))
      || 0,
  },
  AllocatedHrs: {
    type: 'number',
    field: 'allocationHours',
    header: 'Allocated Hours',
    value: ({ item }) => (item && item.allocationHours && item.allocationHours.toFixed(2)) || 0,
  },
  DeliveredHrs: {
    type: 'number',
    field: 'deliveredHours',
    header: 'Delivered Hours',
    value: ({ item }) => (item && item.deliveredHours && item.deliveredHours.toFixed(2)) || 0,
  },
  ProjectIncentive: {
    type: 'number',
    field: 'projectIncentive',
    header: 'Amount',
    value: ({ item }) => (item && item.projectIncentive && item.projectIncentive.toFixed(2)) || 0,
  },
  AllocationHrsForFixed: {
    field: 'allocationHours',
    type: 'number',
    header: 'Hours',
    value: ({ item }) => (item && item.allocationHours && item.allocationHours.toFixed(2)) || 0,
  },
  EfficiencyWithFormula: {
    field: 'efficiencyContributionValue',
    header: 'Efficiency',
    type: 'custom',
    render: ({ item }) => {
      const { totalVariableHours, totalEffHrs, efficiencyContributionValue } = item || {};
      const efficiency = efficiencyContributionValue > 1000
        ? `${(efficiencyContributionValue / 1000).toFixed(0)}k`
        : efficiencyContributionValue;
      return (
        <View
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                paddingBottom: 5,
              }}
            >
              {(totalVariableHours && totalVariableHours.toFixed(2)) || 0}
            </Text>
            <Text style={{ paddingTop: 5 }}>
              {(totalEffHrs && totalEffHrs.toFixed(2)) || 0}
            </Text>
          </View>
          <Text style={{ ...Headline9 }}>{`${' '} = ${efficiency || 0} %`}</Text>
        </View>
      );
    },
  },
  Project: {
    type: 'text',
    field: 'project.project',
    header: 'Project',
  },
  Hours: {
    type: 'number',
    field: 'hours',
    header: 'Hours',
    value: ({ item }) => (item && item.hours && item.hours.toFixed(2)) || 0,
  },
  CSAT: {
    type: 'number',
    header: 'CSAT',
    field: 'CSAT',
    value: ({ item }) => (item && item.CSAT && item.CSAT.toFixed(2)) || 0,
  },
  Amount: {
    type: 'number',
    header: 'Amount',
    field: 'incentive',
    value: ({ item }) => (item && item.incentive && item.incentive.toFixed(2)) || 0,
  },
  Efficiency: {
    type: 'number',
    header: 'Efficiency',
    field: 'efficiency',
    value: ({ item }) => (item && item.efficiency && item.efficiency.toFixed(2)) || 0,
  },
};

const StatusPerSavingHead = ({ data }) => {
  const { status } = data;

  const getStylesPerStatus = useMemo(() => {
    if (status === 'rejected') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.error,
      };
    } if (status === 'approved') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.primary.color2,
      };
    } if (status === 'requested') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.emphasis,
      };
    } if (status === 'declared' || status === 'pending') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.warning,
      };
    }

    return {};
  }, [status]);
  return (
    <Text
      style={getStylesPerStatus}
    >
      {upperFirst(status)}
    </Text>
  );
};

export const FormLayouts = {
  Deduction_under_section_chapterVIA: () => ({
    field: 'deduction_under_section_chapterVIA',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            disabled: true,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="deduction_under_section_chapterVIA" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  }),
  OtherDeduction: () => ({
    field: 'other_deductions',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: '',
            options: [{ value: '1' }],
            style: { marginTop: 10 },
            width: 30,
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: 'true',
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            disabled: true,
            width: 120,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="other_deductions" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  }),
  IncomeFromHouseProperty: () => ({
    field: 'income_from_house_property',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            disabled: true,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="income_from_house_property" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  }),
  Lta: () => ({
    field: 'lta',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            disabled: true,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="lta" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  }),
  PreviousEmployerIncome: {
    field: 'previous_employer_income',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Income',
            field: 'income',
            header: 'Income',
            variant: 'filled',
            width: 150,
          },
          {
            type: 'number',
            placeholder: 'TDS Deducted',
            field: 'tds_deducted',
            header: 'TDS Deducted',
            variant: 'filled',
            width: 150,
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="previous_employer_income" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  },
  RentRecipts: () => ({
    field: 'rent_recipts',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'MonthTypeSuggestions',
            searchField: 'name',
            field: 'month',
            model: 'Month',
            placeholder: 'Month',
            header: 'Month',
            variant: 'filled',
            disabled: true,
            width: 180,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            disabled: true,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="rent_recipts" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  }),
  HigherEducationLoan: {
    field: 'income_on_higher_education_loan',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            disabled: true,
            variant: 'filled',
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
            variant: 'filled',
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="income_on_higher_education_loan" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  },
  Donations: {
    field: 'donations',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            disabled: true,
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            disabled: true,
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="donations" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  },
  FlexiComponentDetails: {
    field: 'flexi_component_details',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        lg: {
          columnsPerRow: 5,
          columns: [
            autoSuggestInput(
              {
                suggestionField: 'name',
                model: MODELS.SALARY_COMPONENTS,
                query: 'salaryComponent',
                searchField: 'name',
                field: 'salary_component_id',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                valueField: 'name',
                primaryField: 'name',
                width: 150,
                variant: 'filled',
                disabled: true,
              },
            ),
            {
              type: 'number',
              placeholder: 'January',
              field: 'jan_amount',
              header: 'January',
              variant: 'filled',
              disabled: true,
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'January',
              field: 'jan_actual_amount',
              header: 'January Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'February',
              field: 'feb_amount',
              header: 'February',
              variant: 'filled',
              disabled: true,
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'February',
              field: 'feb_actual_amount',
              header: 'February Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'March',
              field: 'mar_amount',
              header: 'March',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'March',
              field: 'mar_actual_amount',
              header: 'March Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'April',
              field: 'apr_amount',
              header: 'April',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'April',
              field: 'apr_actual_amount',
              header: 'April Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'May',
              field: 'may_amount',
              header: 'May',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'May',
              field: 'may_actual_amount',
              header: 'May Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'June',
              field: 'jun_amount',
              header: 'June',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'June',
              field: 'jun_actual_amount',
              header: 'June Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'July',
              field: 'jul_amount',
              header: 'July',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'July',
              field: 'jul_actual_amount',
              header: 'July Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'August',
              field: 'aug_amount',
              header: 'August',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'August',
              field: 'aug_actual_amount',
              header: 'August Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'September',
              field: 'sep_amount',
              header: 'September',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'September',
              field: 'sep_actual_amount',
              header: 'September Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'October',
              field: 'oct_amount',
              header: 'October',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'October',
              field: 'oct_actual_amount',
              header: 'October Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'November',
              field: 'nov_amount',
              header: 'November',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'November',
              field: 'nov_actual_amount',
              header: 'November Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              type: 'number',
              placeholder: 'December',
              field: 'dec_amount',
              header: 'December',
              variant: 'filled',
              width: 140,
              disabled: true,
            },
            {
              type: 'number',
              placeholder: 'December',
              field: 'dec_actual_amount',
              header: 'December Actual Amount',
              variant: 'filled',
              width: 140,
            },
            {
              render: ({ data }) => {
                const value = data?.supporting_document;
                if (Array.isArray(value)) {
                  return value.map((file, index) => <FileLink file={file} key={index} />);
                }
                return <View>--</View>;
              },
              header: 'Supporting Document',
              type: 'custom',
              placeholder: 'Document',
              label: 'Supporting Document',
              variant: 'filled',
              multiple: true,
              width: 180,
            },
            {
              header: 'Status',
              type: 'custom',
              width: 120,
              render: (props) => <StatusPerSavingHead {...props} />,
            },
            {
              header: 'Actions',
              type: 'actions',
              width: 120,
              render: (props) => <UpdateSavingHeadActions {...props} field="flexi_component_details" />,
              visible: () => getPermission(permissions.departments.views.incomeTaxActions
                .permissions.incomeTaxActions.value),
            },
          ],
        },
      },
    },
  },
  Nps: {
    field: 'nps',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            disabled: true,
            width: 220,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'declared_amount',
            width: 120,
            disabled: true,
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
          },
          {
            render: ({ data }) => {
              const value = data?.supporting_document;
              if (Array.isArray(value)) {
                return value.map((file, index) => <FileLink file={file} key={index} />);
              }
              return <View>--</View>;
            },
            header: 'Supporting Document',
            type: 'custom',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
            width: 180,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Actions',
            type: 'actions',
            width: 120,
            render: (props) => <UpdateSavingHeadActions {...props} field="nps" />,
            visible: () => getPermission(permissions.departments.views.incomeTaxActions
              .permissions.incomeTaxActions.value),
          },
        ],
      },
    },
  },
};
