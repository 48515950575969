import FormHoc from '../../../app-components/form/Form';
import { submit } from '../../../AppServices';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';

const AccountFormHOC = ({
  submitMessage = 'account.messages.updateMessage'.getLabel(),
  editable = true,
  uri,
}) => FormHoc({
  onSubmit: submit({
    model: 'Account',
  }),
  closeView: 1,
  editable,
  uri,
  reloadEvent: 'Accounts',
  submitMessage,
  fieldVariant: 'filled',
  formGroups: [
    {
      groups: [
        {
          columnsPerRow: 2,
          columns: [
            {
              type: 'text',
              field: 'name',
              placeholder: 'account.placeholders.name'.getLabel(),
              label: 'account.labels.name'.getLabel(),
              allowWhiteSpaces: true,
            },
            {
              label: 'account.labels.description'.getLabel(),
              type: 'textArea',
              field: 'description',
              placeholder: 'account.placeholders.description'.getLabel(),
            },
            searchInput({
              label: 'account.labels.parent'.getLabel(),
              placeholder: 'account.placeholders.parent'.getLabel(),
              field: 'parent_account_id',
              query: 'accountSuggestions',
              model: 'Account',
              searchField: 'name',
              suggestionField: 'name',
            }),
            autoSuggestInput({
              label: 'account.labels.type'.getLabel(),
              field: 'account_group_type',
              options: [{ label: 'Revenue', value: 'revenue' }, { label: 'Asset', value: 'assert' }, { label: 'Liability', value: 'liability' }, { label: 'Expense', value: 'expense' }],
              placeholder: 'account.placeholders.type'.getLabel(),
              valueField: 'value',
              suggestionField: 'label',
            }),
          ],
        },
      ],
    },
  ],
});

const AccountDetailQuery = ({ navigation }) => {
  const addOnFilter = { _id: navigation.state.params._id };

  return {
    query: {
      id: 'accountDetail',
      limit: 1,
      addOnFilter,
    },
    model: MODELS.ACCOUNT,
  };
};

export const AccountNonEditDetail = AccountFormHOC({
  uri: AccountDetailQuery,
  editable: false,
});

export const AccountDetail = AccountFormHOC({
  uri: AccountDetailQuery,
});

export default AccountFormHOC;
