import React from 'react';
import {
  View, Image, Text,
} from '../../../../app-components';
import { Action } from '../../../../app-components/action/Action';
import { emergencyIcon, admitPatient } from '../../../../images';
import { ROUTES } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const PatientAdmission = (props) => (
  <View
    style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <View style={{ flexDirection: 'row' }}>
      <Action
        action={{
          type: 'link',
          push: true,
          link: () => ({
            view: ROUTES.emergencyIPDModal.name,
            params: { ...props },
          }),
        }}
        {...props}
      >
        <Image
          source={emergencyIcon}
        />
      </Action>
      <View style={{ marginLeft: 44 }}>
        <Action
          action={{
            type: 'link',
            push: true,
            link: () => ({
              view: ROUTES.existingPatientModal.name,
              modal: true,
              params: {
                ...props,
                routes: {
                  listRoute: ROUTES.patientAdmissionListIPD.name,
                  viewRoute: ROUTES.viewPatientIPD.name,
                },
              },
              modalProps: {
                autoHide: true,
                maxHeight: '100%',
                width: 373,
                height: 535,
              },
            }),
          }}
          {...props}
        >
          <Image
            source={admitPatient}
          />
        </Action>
      </View>
    </View>
    <View style={{
      width: 165,
      height: 46,
      marginTop: 50,
    }}
    >
      <Action
        action={{
          type: 'link',
          push: true,
          link: () => ({
            view: ROUTES.patientAdmissionListIPD.name,
          }),
        }}
        {...props}
      >
        <Text style={{
          ...vars.headings.h8,
          color: vars.colors.secondary.color2,
          textDecorationLine: 'underline',
        }}
        >
          {'patient.labels.viewRegisteredPatients'.getLabel()}
        </Text>
      </Action>
    </View>
  </View>
);
export default PatientAdmission;
