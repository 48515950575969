/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React from 'react';
import { size } from 'lodash';
import {
  cancelButton, OutlineButton,
  clearSelection,
  FilterGroup,
  save, selectAll,
  showSelectedCount, StandardButton,
  Toast,
  Text,
} from '../../../app-components';
import { LoadingButton } from '../../../app-components/buttons/Buttons';
import {
  endingRateContractDateFilterOptions,
  inventoryModules,
  MODELS, pharmacyInventoryStockRequestStatus, purchaseSupplyOrderStatus, ROUTES,
} from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { getUser, invoke } from '../../../AppServices';
import { getErrorString } from '../../../Lib/helpers';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import WithPermission from '../../../Components/WithPermission';
import { filterActive, filterInactive } from '../../../images';

import AddItem from '../Pharmacy/CentralInventory/Items/AddItem';
import EditItem from '../Pharmacy/CentralInventory/Items/EditItem';
import ViewItem from '../Pharmacy/CentralInventory/Items/ViewItem';
import InventoryItemList from '../Pharmacy/CentralInventory/Items/ItemList';
import DepartmentInventoryItemList from '../Pharmacy/DepartmentInventory/InventoryList';
import ViewDepartmentInventoryItem from '../Pharmacy/DepartmentInventory/ViewInventoryItem';
import EditBatch from '../Pharmacy/CentralInventory/Batches/EditBatch';
import AddStock from '../Pharmacy/CentralInventory/Stocks/AddStock';
import RaiseIndent from '../Pharmacy/CentralInventory/RaiseIndent';
import RaisePurchaseRequest from '../Pharmacy/CentralInventory/PurchaseRequest';
import TransferRequestModal from '../Pharmacy/CentralInventory/Stocks/TransferStock';

import PharmacyCentralRequests from '../Pharmacy/Requests/CentralInventory';
import PharmacyDepartmentRequests from '../Pharmacy/Requests/DepartmentInventory';
import ViewPurchaseRequest from '../Pharmacy/Requests/CentralInventory/RaisedPurchasesRequests/ViewPurchasesRequest';
import ViewTransferRequests from '../Pharmacy/Requests/CentralInventory/OtherRequests/ViewTransferRequestList';

import MSIRequestsTabs from '../Pharmacy/MSI Requests/RequestTabs';
import MSIViewIndentRequest from '../Pharmacy/MSI Requests/components/ViewIndentRequest';
import MSIViewTransferRequest from '../Pharmacy/MSI Requests/components/ViewTransferRequest';
import MSIViewBatchUpdateRequests from '../Pharmacy/MSI Requests/components/ViewBatchUpdateRequest';
import MSIViewLowShelfLifeRequest from '../Pharmacy/MSI Requests/components/ViewLowShelfLifeRequest';

import PharmacyCentralRecordsTabs from '../Pharmacy/Records/CentralRecords';
import PharmacyDepartmentRecordsTabs from '../Pharmacy/Records/DepartmentRecords';
import ViewIndentRecord from '../Pharmacy/Records/CentralRecords/DepartmentRecords/IndentRequests/ViewIndentRequest';
import ViewTransferRequestRecord from '../Pharmacy/Records/CentralRecords/DepartmentRecords/TransferRequests/ViewTransferRequest';
import OPDPatientDispensingRecords from '../Pharmacy/Records/CentralRecords/PatientDispensing/OPD/ViewPatientDispensing';
import IPDPatientDispensingRecords from '../Pharmacy/Records/CentralRecords/PatientDispensing/IPD/ViewPatientDispensing';
import ViewCentralInventoryPurchaseRecords from '../Pharmacy/Records/CentralRecords/CentralPurchaseRecords/ViewCentralPurchaseRecords';
import ViewBatchUpdateRequests from '../Pharmacy/Requests/CentralInventory/OtherRequests/ViewBatchUpdateRequest';
import ViewLowShelfLifeRequest from '../Pharmacy/Requests/CentralInventory/OtherRequests/ViewLowShelfLifeRequest';
import LowShelfLifeModal from '../Pharmacy/CentralInventory/Stocks/LowShelfLifeModal';
import { Action } from '../../../app-components/action/Action';
import permissions from '../../../Lib/permissions';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

export default {
  [ROUTES.addInventoryItem.name]: {
    screen: AddItem,
    screenProps: {
      defaultValues: {
        module: inventoryModules.assetConsumables,
      },
    },
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.addInventoryStocks.name]: {
    screen: AddStock,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.lowShelfLifeModal.name]: {
    screen: LowShelfLifeModal,
  },
  [ROUTES.editInventoryItem.name]: {
    screen: EditItem,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewInventoryItem.name]: {
    screen: ViewItem,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.raisePurchaseRequestPharmacy
              .permissions.raisePurchaseRequestPharmacy.value}
          >
            <OutlineButton label={'inventory.buttons.raisePurchaseRequest'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => {
          const { navigation: { state: { params: { item } } = {} } = {} } = props;
          return {
            view: ROUTES.purchaseRequest.name,
            params: {
              item: {
                item: {
                  ...item,
                  lastPurchaseQuantity: item?.lastPurchaseQuantity
                    ? (
                      item?.lastPurchaseQuantity[size(
                        item.lastPurchaseQuantity,
                      ) - 1].requiredQuantity
                    ) : 0,
                  lastPurchaseDate: item?.lastPurchaseDate
                    ? (item?.lastPurchaseDate[size(item.lastPurchaseDate) - 1]._createdOn) : null,
                },
              },
              defaultValues: {
                module: item?.module,
              },
            },
            push: true,
            expanded: true,
          };
        },
      },
      {
        // will handle later permissions
        // eslint-disable-next-line no-unused-vars
        render: ({ navigation: { state: { params: { queryID } } } }) => (
          <WithPermission
            access_key={permissions.inventory.views
              .editPharmacyItem.permissions.editPharmacyItem.value}
          >
            <StandardButton label={'inventory.buttons.edit'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => {
          const { navigation: { state: { params: { queryID } } } } = props;
          return {
            view: ROUTES.editInventoryItem.name,
            push: true,
            expanded: true,
            ...props,
            params: { ...props?.navigation?.state?.params, queryID },
          };
        },
      },
    ],
  },
  [ROUTES.assetConsumablesCentralInventory.name]: {
    screen: InventoryItemList,
    screenProps: {
      hidePatientDispensing: true,
      queryID: 'assetConsumablesItemList',
      raisePurchaseRequestDefaultValues: {
        module: inventoryModules.assetConsumables,
      },
      editItemPermissionId: permissions.inventory.views
        .editAssetConsumablesItem.permissions.editAssetConsumablesItem.value,
      raisePurchaseRequestPermissionId: permissions.inventory
        .views.raisePurchaseRequestAssetConsumables
        .permissions.raisePurchaseRequestAssetConsumables.value,
      defaultValues: {
        module: inventoryModules.assetConsumables,
      },
      fetchUriEvent: 'fetchListAggregates',
      selectableActions: (selectedActionProps) => [
        showSelectedCount(selectedActionProps),
        clearSelection,
        selectAll,
        {
          render: () => (
            <WithPermission
              access_key={permissions.inventory.views.exportCentralAssetConsumablesItem
                .permissions.exportCentralAssetConsumablesItem.value}
            >
              <OutlineButton label={'inventory.buttons.exportToExcel'.getLabel()} />
            </WithPermission>
          ),

          file: 'CentralInventoryManagement',
          type: 'download',
          download: () => ({
            uri: {
              model: MODELS.PHARMACY_ITEM,
              query: {
                id: 'masterDataListForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            message: (props) => {
              const { screenState: { selectedIds } } = props;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
                allPageSelected: true,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
      ],
    },
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'inventory.title.centralInventory'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'category',
              label: 'Category',
              model: MODELS.PHARMACY_CATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Category',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'subCategory',
              label: 'Sub-Category',
              model: MODELS.PHARMACY_SUBCATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Sub-Category',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'rateContract.manufacturer',
              label: 'Manufacturers',
              model: MODELS.PHARMACY_MANUFACTURER,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Manufacturer',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'rateContract.supplier',
              label: 'Suppliers',
              model: MODELS.PHARMACY_SUPPLIER,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Supplier',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggestOptions',
              field: 'rateContract.rateContractValidity',
              label: 'Ending Rate Contract Date',
              options: endingRateContractDateFilterOptions,
              multiSelect: false,
            },
          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <StandardButton label="Bulk Upload" />
        ),
        type: 'link',
        visible: () => {
          const user = getUser();
          if (user?.username === 'superadmin') {
            return true;
          }
          return false;
        },
        link: () => ({
          view: ROUTES.bulkConsumablesUploadModal.name,
          modal: true,
          params: {
            fileName: 'Consumables_Template.xlsx',
          },
          modalProps: {
            autoHide: true,
            width: 404,
            height: 550,
          },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.transferAssetConsumablesStocks
              .permissions.transferAssetConsumablesStocks.value}
          >
            <StandardButton label={'inventory.buttons.transferStock'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.transferRequest.name,
          push: true,
          modal: true,
          modalProps: {
            autoHide: true,
            width: 917,
            height: 507,
          },
          params: {
            raisePurchaseRequestDefaultValues: {
              module: inventoryModules.assetConsumables,
            },
            editItemPermissionId: permissions.inventory.views
              .editAssetConsumablesItem.permissions.editAssetConsumablesItem.value,
            transferRequestPermissionId: permissions.inventory
              .views.transferAssetConsumablesStocks
              .permissions.transferAssetConsumablesStocks.value,
            defaultValues: {
              module: inventoryModules.assetConsumables,
            },
            queryId: 'assetConsumablesDepartmentInventoryStocksDataList',
            selectedIds: props?.state?.selectedIds,
          },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views
              .addAssetConsumablesItem.permissions.addAssetConsumablesItem.value}
          >
            <StandardButton label={'inventory.buttons.addItem'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addInventoryItem.name,
          push: true,
          params: {
            raisePurchaseRequestDefaultValues: {
              module: inventoryModules.assetConsumables,
            },
            editItemPermissionId: permissions.inventory.views
              .editAssetConsumablesItem.permissions.editAssetConsumablesItem.value,
            raisePurchaseRequestPermissionId: permissions.inventory
              .views.raisePurchaseRequestAssetConsumables
              .permissions.raisePurchaseRequestAssetConsumables.value,
            defaultValues: {
              module: inventoryModules.assetConsumables,
            },
          },
        }),
      },
    ],
  },
  [ROUTES.assetConsumablesDepartmentInventory.name]: {
    screen: DepartmentInventoryItemList,
    expanded: true,
    screenProps: {
      hidePatientDispensing: true,
      fetchUriEvent: 'fetchListAggregates',
      queryId: 'assetConsumablesDepartmentInventoryStocksDataList',
    },
    selectableActions: (selectedActionProps) => [
      showSelectedCount(selectedActionProps),
      clearSelection,
      selectAll,
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.exportDepartmentAssetConsumablesItem
              .permissions.exportDepartmentAssetConsumablesItem.value}
          >
            <OutlineButton label={'inventory.buttons.exportToExcel'.getLabel()} />
          </WithPermission>
        ),

        file: 'DepartmentInventoryManagement',
        type: 'download',
        confirmModalProps: { autoHide: true, minWidth: 436 },
        confirm: {
          title: 'Export Records!',
          hideCross: false,
          message: (props) => {
            const { screenState: { selectedIds } } = props;
            return `This action will export ${selectedIds.length} selected records in the excel format.`;
          },
          actions: ({ action }) => [
            { text: 'Cancel' },
            {
              text: 'Export All',
              ...action,
              allPageSelected: true,
            },
            {
              text: 'Yes',
              ...action,
            },
          ],
        },
      },

    ],
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'inventory.title.departmentInventory'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'item.category',
              label: 'Category',
              model: MODELS.PHARMACY_CATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Category',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'item.subCategory',
              label: 'Sub-Category',
              model: MODELS.PHARMACY_SUBCATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Sub-Category',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'item.rateContract',
              label: 'Manufacturers',
              model: MODELS.PHARMACY_MANUFACTURER,
              id: 'masterDataList',
              searchField: 'manufacturer.name',
              placeholder: 'Select Manufacturer',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Departments',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Department',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggestOptions',
              field: 'rateContract.rateContractValidity',
              label: 'Ending Rate Contract Date',
              options: endingRateContractDateFilterOptions,
              multiSelect: false,
            },
          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.raiseIndentRequestAssetConsumables
              .permissions.raiseIndentRequestAssetConsumables.value}
          >
            <StandardButton label={'inventory.buttons.raiseIndent'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.raiseIndent.name,
          push: true,
          params: {
            itemQueryId: 'assetConsumablesItemList',
            defaultValues: {
              module: inventoryModules.assetConsumables,
            },
          },
        }),
      },
    ],
  },
  [ROUTES.pharmacyViewDepartmentInventory.name]: {
    screen: ViewDepartmentInventoryItem,
    title: (props) => {
      const { navigation: { state: { params: { item: { item } = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.raiseIndent.name]: {
    screen: RaiseIndent,
    // screenProps: {
    //   defaultValues: {
    //     module: inventoryModules.assetConsumables,
    //   },
    // },
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.purchaseRequest.name]: {
    screen: RaisePurchaseRequest,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.transferRequest.name]: {
    screen: TransferRequestModal,
    screenProps: {
      defaultValues: {
        module: inventoryModules.assetConsumables,
      },
    },
  },
  [ROUTES.assetConsumablesCentralRequests.name]: {
    screen: PharmacyCentralRequests,
    screenProps: {
      purchaseRequestsQueryId: 'purchaseRequestAssetConsumablesList',
      indentRequestsQueryId: 'assetConsumablesInventoryIndentdataList',
      lowShelfLifeQueryId: 'assetConsumablesInventoryBatchLowShelfLifeRequests',
      batchUpdateRequestsQueryId: 'assetConsumablesBatchRequestsDataList',
      transferRequestsQueryId: 'assetConsumablesTransferRequestsDataList',
      defaultValues: {
        module: inventoryModules.assetConsumables,
      },
    },
    header: {
      title: () => (
        <HeaderTitleWithCount
          label="Requests (Asset Consumables)"
        />
      ),
    },
  },
  [ROUTES.assetConsumablesDepartmentRequests.name]: {
    screen: PharmacyDepartmentRequests,
    screenProps: {
      indentRequestsQueryId: 'assetConsumablesInventoryIndentdataList',
    },
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'inventory.title.requests'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewPurchaseRequest.name]: {
    screen: ViewPurchaseRequest,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { navigation, data = {} } = props;
          const {
            navigation: {
              state: {
                params: {
                  defaultValues,
                } = {},
              } = {},
            } = {},
          } = props;

          if (
            data?.purchaseRequestPharmacy?.requestStatus
            === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING
          ) {
            return (
              <Action
                navigation={navigation}
                action={{
                  type: 'link',
                  link: () => ({
                    view: ROUTES.addInventoryStocks.name,
                    params: {
                      item: data?.purchaseRequestPharmacy?.item,
                      purchaseRequestEntityId: data?._id,
                      quantityReceived: data?.purchaseRequestPharmacy?.requiredQuantity,
                      defaultValues,
                    },
                  }),
                }}
              >
                <StandardButton
                  label={'inventory.buttons.addStock'.getLabel()}
                />
              </Action>
            );
          }
          if (
            data?.purchaseRequestPharmacy?.requestStatus
             === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED
          ) {
            return (
              <Action
                navigation={navigation}
                action={{
                  type: 'link',
                  link: () => ({
                    view: ROUTES.addInventoryStocks.name,
                    params: {
                      item: data?.purchaseRequestPharmacy?.item,
                      purchaseRequestEntityId: data?._id,
                      quantityReceived: data?.purchaseRequestPharmacy?.requiredQuantity,
                      defaultValues,
                    },
                  }),
                }}
              >
                <StandardButton
                  label={'inventory.buttons.provideRemainingStock'.getLabel()}
                />
              </Action>
            );
          }
        },
      },
    ],
  },
  [ROUTES.editBatch.name]: {
    screen: EditBatch,
  },
  [ROUTES.assetConsumablesMSIIndentRequests.name]: {
    screen: MSIRequestsTabs,
    screenProps: {
      purchaseRequestsQueryId: 'purchaseRequestAssetConsumablesList',
      indentRequestsQueryId: 'assetConsumablesInventoryIndentdataList',
      lowShelfLifeQueryId: 'assetConsumablesInventoryBatchLowShelfLifeRequests',
      batchUpdateRequestsQueryId: 'assetConsumablesBatchRequestsDataList',
      transferRequestsQueryId: 'assetConsumablesTransferRequestsDataList',
    },
  },
  [ROUTES.msiViewIndentRequestAssetConsumables.name]: {
    screen: MSIViewIndentRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    headerActions: [{
      type: 'save',
      role: 'edit',
      id: 'submit',
      render: (props) => {
        const { navigation: { state: { params: { item } } } } = props;
        if (item?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
          return (
            <StandardButton
              label={'inventory.buttons.submit'.getLabel()}
              {...props}
            />
          );
        }
      },
    },
    ],
  },
  [ROUTES.msiViewLowShelfLifeRequest.name]: {
    screen: MSIViewLowShelfLifeRequest,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { data } = props;

          if (data?.isLowShelfLifeApproved || data?.isLowShelfLifeRejected) {
            return null;
          }

          return (
            <LoadingButton
              label={'inventory.buttons.reject'.getLabel()}
              color={vars.colors.error}
              hoverColor={vars.colors.error}
              onPress={async () => {
                try {
                  const { navigation, eventDispatcher } = props;
                  // eslint-disable-next-line no-shadow

                  if (data.isLowShelfLifeRejected) {
                    throw new Error('inventory.messages.lowShelfLifeRequestAlreadyRejected'.getLabel());
                  } else if (data.isLowShelfLifeApproved) {
                    throw new Error('inventory.messages.lowShelfLifeRequestAlreadyApproved'.getLabel());
                  }

                  const result = await invoke({
                    id: 'handleLowShelfLifeRequestStatus',
                    paramValue: {
                      ...data,
                      isLowShelfLifeRejected: true,
                    },
                  });

                  if (result) {
                    eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH}`);
                    navigation.pop();
                    Toast.show({
                      message: 'Success',
                      description: 'inventory.messages.lowShelfLifeRequestReject'.getLabel(),
                      type: 'success',
                      position: 'top',
                      direction: 'right',
                      duration: 3000,
                    });
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              }}
            />
          );
        },
      },
      {
        render: (props) => {
          const { data } = props;
          if (data?.isLowShelfLifeApproved || data?.isLowShelfLifeRejected) {
            return null;
          }
          return (
            <LoadingButton
              label={'inventory.buttons.approve'.getLabel()}
              color={vars.colors.primary.color2}
              hoverColor={vars.colors.primary.color2}
              onPress={async () => {
                try {
                  const { navigation, eventDispatcher } = props;
                  // eslint-disable-next-line no-shadow
                  if (data.isLowShelfLifeApproved) {
                    throw new Error('inventory.messages.lowShelfLifeRequestAlreadyApproved'.getLabel());
                  } else if (data.isLowShelfLifeRejected) {
                    throw new Error('inventory.messages.lowShelfLifeRequestAlreadyRejected'.getLabel());
                  }
                  const result = await invoke({
                    id: 'handleLowShelfLifeRequestStatus',
                    paramValue: {
                      ...data,
                      isLowShelfLifeApproved: true,
                    },
                  });
                  if (result) {
                    eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH}`);
                    navigation.pop();
                    Toast.show({
                      message: 'Success',
                      description: 'inventory.messages.lowShelfLifeRequestApprove'.getLabel(),
                      type: 'success',
                      position: 'top',
                      direction: 'right',
                      duration: 3000,
                    });
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              }}
            />
          );
        },
      },
    ],
  },
  [ROUTES.msiviewTransferRequest.name]: {
    screen: MSIViewTransferRequest,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { data = {} } = props;
          if (data?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.reject'.getLabel()}
                color={vars.colors.error}
                hoverColor={vars.colors.error}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (data.requestStatus === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.transferRequestAlreadyRejected'.getLabel());
                    } else if (data.requestStatus
                       === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.transferRequestAlreadyApproved'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleTransferRequestStatusChange',
                      paramValue: {
                        ...data,
                        requestStatus: pharmacyInventoryStockRequestStatus.rejected,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_TRANSFER_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.transferRequestReject'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
      {
        render: (props) => {
          const { data } = props;
          if (data?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.approve'.getLabel()}
                color={vars.colors.primary.color2}
                hoverColor={vars.colors.primary.color2}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (data.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.transferRequestAlreadyApproved'.getLabel());
                    } else if (data.requestStatus
                      === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.transferRequestAlreadyRejected'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleTransferRequestStatusChange',
                      paramValue: {
                        ...data,
                        requestStatus: pharmacyInventoryStockRequestStatus.approved,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_TRANSFER_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.transferRequestApprove'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
    ],
  },
  [ROUTES.msiViewBatchUpdateRequest.name]: {
    screen: MSIViewBatchUpdateRequests,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { data } = props;
          if (data?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.reject'.getLabel()}
                color={vars.colors.error}
                hoverColor={vars.colors.error}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (data.requestStatus === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.batchUpdateRequestAlreadyRejected'.getLabel());
                    } else if (data.requestStatus
                      === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.batchUpdateRequestAlreadyApproved'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleBatchRequestStatusChange',
                      paramValue: {
                        ...data,
                        requestStatus: pharmacyInventoryStockRequestStatus.rejected,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.batchUpdateRequestReject'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
      {
        render: (props) => {
          const { data } = props;
          if (data?.requestStatus === pharmacyInventoryStockRequestStatus.raised) {
            return (
              <LoadingButton
                label={'inventory.buttons.approve'.getLabel()}
                color={vars.colors.primary.color2}
                hoverColor={vars.colors.primary.color2}
                onPress={async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    if (data.requestStatus === pharmacyInventoryStockRequestStatus.approved) {
                      throw new Error('inventory.messages.batchUpdateRequestAlreadyApproved'.getLabel());
                    } else if (data.requestStatus
                       === pharmacyInventoryStockRequestStatus.rejected) {
                      throw new Error('inventory.messages.batchUpdateRequestAlreadyRejected'.getLabel());
                    }
                    const result = await invoke({
                      id: 'handleBatchRequestStatusChange',
                      paramValue: {
                        ...data,
                        requestStatus: pharmacyInventoryStockRequestStatus.approved,
                      },
                    });
                    if (result) {
                      eventDispatcher.notify(`reload${MODELS.PHARMACY_BATCH_REQUESTS}`);
                      navigation.pop();
                      Toast.show({
                        message: 'Success',
                        description: 'inventory.messages.batchUpdateRequestApprove'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
              />
            );
          }
        },
      },
    ],
  },
  [ROUTES.viewLowShelfLifeRequest.name]: {
    screen: ViewLowShelfLifeRequest,
    expanded: true,
  },
  [ROUTES.viewTransferRequest.name]: {
    screen: ViewTransferRequests,
    expanded: true,
  },
  [ROUTES.viewBatchUpdateRequest.name]: {
    screen: ViewBatchUpdateRequests,
    expanded: true,
  },
  [ROUTES.assetConsumablesDepartmentRecords.name]: {
    screen: PharmacyDepartmentRecordsTabs,
    screenProps: {
      indentRequestsQueryId: 'assetConsumablesInventoryIndentdataList',
      hidePatientDispensing: true,
    },
    header: {
      title: () => (
        <HeaderTitleWithCount
          label="Dept. Records (Asset Consumables)"
        />
      ),
    },
  },
  [ROUTES.assetConsumablesCentralRecords.name]: {
    screen: PharmacyCentralRecordsTabs,
    screenProps: {
      purchaseRequestsQueryId: 'purchaseRequestAssetConsumablesList',
      indentRequestsQueryId: 'assetConsumablesInventoryIndentdataList',
      lowShelfLifeQueryId: 'assetConsumablesInventoryBatchLowShelfLifeRequests',
      batchUpdateRequestsQueryId: 'assetConsumablesBatchRequestsDataList',
      transferRequestsQueryId: 'assetConsumablesTransferRequestsDataList',
      hidePatientDispensing: true,
    },
    header: {
      title: () => (
        <HeaderTitleWithCount
          label="Central Records (Asset Consumables)"
        />
      ),
    },
  },
  [ROUTES.viewIndentRecord.name]: {
    screen: ViewIndentRecord,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewTransferRequestRecord.name]: {
    screen: ViewTransferRequestRecord,
    expanded: true,
  },
  [ROUTES.viewOPDPatientDispensingRecords.name]: {
    screen: OPDPatientDispensingRecords,
    expanded: true,
  },
  [ROUTES.viewIPDPatientDispensingRecords.name]: {
    screen: IPDPatientDispensingRecords,
    expanded: true,
  },
  [ROUTES.viewCentralInventoryPurchaseRecords.name]: {
    screen: ViewCentralInventoryPurchaseRecords,
    title: (props) => {
      const {
        navigation: {
          state: {
            params: {
              item: {
                purchaseRequestPharmacy: { purchaseRequestNumber } = {},
              } = {},
            } = {},
          } = {},
        } = {},
      } = props;

      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
  },
};
