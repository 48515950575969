import { initUser, mamcAuthenticate, mamcResetPassword } from '../AppServices';

import {
  loginLogo,
  helpIcon,
  hospitalLiveLogo,
  login_background_web,
  resetPassword,
} from '../images';

import LoginHoc from './Login';
import AuthenticatorHoc from './Authenticator';
import ResetPasswordHOC from './ResetPassword';
import { LANDING_VIEW } from './constansts';
import { ROUTES } from '../Lib/constants';

const getAuthTemplate = () => {
  const Login = LoginHoc({
    mamcAuthenticate,
    images: {
      bgImage: login_background_web, helpIcon, loginLogo, hospitalLiveLogo,
    },
  });
  const ResetPassword = ResetPasswordHOC({
    mamcResetPassword,
    images: {
      bgImage: login_background_web,
      helpIcon,
      loginLogo,
      hospitalLiveLogo,
      resetPassword,
    },
  });

  const getPath = () => {
    let path = decodeURIComponent(window.location.pathname);
    if (window.localStorage.token) {
      path = '/home';
    }
    if (path && path.trim() === '/') {
      return LANDING_VIEW;
    }
    const landingPath = path.split('/')[1];
    if (landingPath === ROUTES.viewOTListScreen.name) {
      return ROUTES.patientAdmissionListIPD.name;
    }
    return landingPath || LANDING_VIEW;
  };
  const Authenticator = AuthenticatorHoc({
    initUser,
    landingView: getPath,
    loginView: 'login',
  });

  return {
    Login,
    Authenticator,
    ResetPassword,
  };
};

const authTemplates = getAuthTemplate();

export default authTemplates;
