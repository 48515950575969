import React, { useState } from 'react';

import {
  Image, ScrollView, TouchableOpacity, View,
} from '../../app-components';
import { download } from '../../app-components/action/DownloadAction';
import { Table } from '../../app-components/table/Table';
import { downloadSalarySlip } from '../../images';
import { MODELS } from '../../Lib/constants';
import { getString } from '../../Lib/helpers';
import SalaryDetails from './SalaryDetail';

const tabListColumns = [
  { width: 14 },
  {
    header: 'Month',
    type: 'text',
    value: ({ item }) => getString([item.month_id.name]),
  },
  {
    header: 'Year',
    type: 'text',
    value: ({ item }) => item.year_id.name,
  },
  {
    header: 'Deduction',
    type: 'text',
    field: 'deduction',
    value: ({ item }) => (item?.gross_amount - item?.payable_amount).toFixed(2),
  },
  {
    header: 'Gross Salary',
    type: 'text',
    field: 'gross_amount',
    value: ({ item }) => item?.gross_amount?.toFixed(2),
  },
  {
    header: 'Payable Salary',
    type: 'text',
    field: 'payable_amount',
    value: ({ item }) => item?.payable_amount?.toFixed(2),
  },
  // {
  //   header: 'Actions',
  //   action: download({
  //     file: 'SalarySlip',
  //     service: ({ data: { _id } = {} }) => ({
  //       id: 'generateSalarySlipData',
  //       paramValue: {
  //         orientation: 'landscape',
  //         monthId: _id,
  //       },
  //     }),
  //     download: () => ({
  //       id: 'generateSalarySlipData',
  //       paramValue: {
  //         orientation: 'landscape',
  //       },
  //     }),
  //   }),
  //   render: () => (
  //     <TouchableOpacity
  //       style={{
  //         marginLeft: 10,
  //       }}
  //     >
  //       <Image
  //         source={downloadSalarySlip}
  //         style={{
  //           width: 18,
  //           height: 18,
  //         }}
  //       />
  //     </TouchableOpacity>
  //   )

  //   ,
  // },
];

const GeneratedSalaryList = (props) => {
  const {
    tableColumns = tabListColumns,
    addOnFilter = {},
  } = props;

  const [salaryDetails, setSalaryDetails] = useState();

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Table
          showSelected
          isSelected={({ item }) => item?._id === salaryDetails?._id}
          // action={({ item }) => ({
          //   type: 'link',
          //   link: () => {
          //     setSalaryDetails(item);
          //   },
          // })}
          uri={() => ({
            query: {
              id: 'selfMonthlyAttendances',
              addOnFilter: {
                invoiceDate: {
                  $exists: true,
                },
                ...addOnFilter,
              },
            },
            model: MODELS.MONTHLY_ATTENDANCE,
          })}
          columns={tableColumns}
          showActionIcon={false}
          reloadEvent="reloadConsultedPatients"
          {...props}
        />
      </View>
      {salaryDetails && (
        <ScrollView
          style={{
            width: '50%',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <SalaryDetails
            salaryDetails={salaryDetails}
            setSalaryDetails={setSalaryDetails}
            {...props}
          />
        </ScrollView>
      )}
    </View>
  );
};
export default GeneratedSalaryList;
