import React from 'react';

import { Form } from '../../../../../../../../../app-components/form/Form';
import { newDateInput } from '../../../../../../../../../compositeInputs';
import { save, Toast } from '../../../../../../../../../app-components';
import { MODELS } from '../../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../../AppServices';
import { dateFormatX } from '../../../../../../../constants';
import ListActionModal from '../../../../../../../../../Components/ListActionModal';

const IpdAddBloodSugar = (props) => {
  const {
    navigation: { state: { params: { data: paramsData } = {} } = {} } = {},
  } = props;
  const { navigation: { state: { params: { value } } } } = props;
  return (
    <ListActionModal
      {...props}
      title="Blood Sugar Chart"
      description="Log today's Blood Sugar of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [save],
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            updates.vitalSignTime = dateFormatX(
              data?.hour,
              data?.minute,
              data?.meridiem,
            );
            delete updates.hour;
            delete updates.minute;
            delete updates.meridiem;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: {
                _id: submitProps?.navigation?.state?.params?.patient?._id,
              },
              speciality: submitProps?.navigation?.state
                ?.params?.dataParams?.filters?.specialityFilter,
              vitalType: value,
            };
            const submitAPI = submit({
              model: MODELS.IPD_VITALS_SIGN_SHEET,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          newDateInput({
                            hour: { field: 'hour', label: 'Hour' },
                            minute: { field: 'minute', label: 'Minute' },
                            meridiem: { field: 'meridiem' },
                          }),
                          {
                            type: 'text',
                            field: 'nurseName',
                            label: 'Nurse Name',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'rbs',
                            label: 'RBS',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'doseOfInsulin',
                            label: 'Dose Of Insulin (ml)',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'adviceBy',
                            label: 'Advice By',
                            allowWhiteSpaces: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const AddBloodSugar = (props) => <IpdAddBloodSugar {...props} />;
export default AddBloodSugar;
