import React from 'react';

import {
  OutlineButton, TabNavigator, Text, View,
} from '../../../../app-components';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import ConsultedPatientsListTabs from './Components/ConsultedPatientsList';
import PatientsList from '../../../../Wired/ListOfPatients';
import TokenInput from '../../../../Wired/ListOfPatients/TokenInput';
import { countUri } from '../../../../Queries/countUri';
import vars from '../../../../theme/vars';

export default TabNavigator({
  tabs: {
    ConsultedPatientsList: {
      label: 'Consulted Patients',
      screen: ConsultedPatientsListTabs,
    },
    ListOfPatients: {
      label: 'List Of Patients',
      screen: (props) => {
        const { navigation, activeSession } = props;

        const { speciality, _id, department } = (activeSession?.activeQueues[0]
           && activeSession?.activeQueues[0]?.session) || {};

        if (!navigation?._parent() || !_id) {
          return <View />;
        }

        return (
          <PatientsList
            sessionId={_id}
            addOnFilter={{
              speciality,
              department,
              $or: [
                {
                  session: {
                    _id,
                  },
                }, {
                  status: 'new',
                },
              ],
            }}
            model={MODELS.CONSULTATION}
            subscribeToUpdates
            speciality={speciality}
            id="consultationDataList"
            {...props}
          />
        );
      },
      dataFetchProps: ({ navigation }) => {
        const { getScreenState } = navigation || {};
        const { dataParams } = (getScreenState && getScreenState()) || {};
        return {
          dataParams,
          reloadEvent: 'reloadConsultations',
        };
      },
      countUri: ({ navigation }) => {
        if (navigation?._parent()?.getScreenState()?.activeQueues) {
          const {
            _id,
            speciality,
            department,
          } = navigation?._parent()?.getScreenState()?.activeQueues
          && navigation?._parent()?.getScreenState()?.activeQueues[0]?.session;
          return countUri({
            model: MODELS.CONSULTATION,
            id: 'consultationDataList',
            filter: {
              speciality,
              department,
              $or: [
                {
                  session: {
                    _id,
                  },
                }, {
                  status: 'new',
                },
              ],
            },
          });
        }
        return false;
      },
      actions: [
        {
          render: (props) => {
            const { navigation } = props;
            return (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TokenInput
                  {...props}
                  sessionId={navigation?._parent()?.getScreenState()?.activeQueues[0]?.session}
                />
              </View>
            );
          },
        },
      ],
    },
  },
  actions: [
    {
      render: ({ navigation }) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>Speciality:</Text>
          <Text style={{
            paddingLeft: 12, ...vars.headings.h8, color: vars.colors.grey.color1,
          }}
          >
            {navigation?._parent()?.getScreenState()?.activeQueues
          && navigation?._parent()?.getScreenState()?.activeQueues[0]?.session?.speciality?.name}
          </Text>
        </View>
      ),
    },
    {
      type: 'link',
      link: ({ navigation }) => ({
        view: ROUTES.endSessionModal.name,
        params: navigation?._parent()?.getScreenState(),
        push: true,
        modal: true,
        modalProps: {
          autoHide: true,
          width: 436,
          height: 279,
        },
      }),
      render: () => (
        <OutlineButton
          label="End Session"
        />
      ),
    }],
});

