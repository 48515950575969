import React from 'react';

import { Table } from '../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../Lib/constants';

const tabListColumns = {
  lg: {
    columns: [
      {
        header: 'Employee',
        type: 'text',
        field: 'employee.name',
        sortable: 'employee',
        width: '25%',
      },
      {
        header: 'Month',
        type: 'text',
        field: 'month.name',
        width: '25%',
      },
      {
        header: 'Year',
        type: 'text',
        field: 'year.name',
        sortable: 'year',
        width: '25%',
      },
      {
        header: 'Present Days',
        type: 'text',
        field: 'no_of_present_days',
        width: '25%',
      },
    ],
  },
};

const ExGratiaSocietyList = (props) => (
  <Table
    {...props}
    uri={({
      query: {
        model: MODELS.EX_GRATIA_SOCIETY_SALARY,
        id: 'exGratiaAndSocietySalary',
      },
      aggregate: true,
      model: MODELS.EX_GRATIA_SOCIETY_SALARY,
    })}
    {...tabListColumns}
    reloadEvent={`reload${MODELS.EX_GRATIA_SOCIETY_SALARY}`}
    type="link"
    action={{
      type: 'link',
      link: (data) => ({
        view: ROUTES.ViewExGratiaSalary.name,
        params: {
          addOnFilter: {
            _id: data.item._id,
          },
        },
      }),
    }}
  />
);

export default ExGratiaSocietyList;
