import React from 'react';
import numWords from 'num-words';

import { Text, View } from '@applane/react-core-components';
import { Form } from '../../../../app-components/form/Form';
import { LargeButton } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { contingentVoucherRequest } from '../../../../Queries/accounts';

const ViewCotingentVoucherRequest = (props) => {
  const { navigation: { state: { params: { item } } } } = props;

  return (
    <Form
      type="standardShadow"
      uri={contingentVoucherRequest}
      {...props}
      defaultValues={{
        formData: item.formData,
        soNumber: item.uniqueNumber,
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            editable: false,
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'supplyOrder.uniqueNumber',
                    label: 'SO Number',
                  },
                  {
                    type: 'text',
                    field: 'head.name',
                    label: 'Head',
                  },
                  {
                    type: 'text',
                    field: 'accountant.name',
                    label: 'Accountant',
                  },
                  {
                    type: 'text',
                    field: 'subject',
                    label: 'Subject',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    render: () => (
                      <Text style={{ fontStyle: 'italic', fontSize: 14 }}>
                        The bill placed below has been received for
                        payment/adjustment duly verified/inspected by the
                        competent authority and entered in the concerned stock
                        register. The purchase/expenditure has been made after
                        obtaining the approval of the director/Competent
                        authority.
                      </Text>
                    ),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            direction: 'row',
            editable: false,
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'nameOfFirm',
                    label: 'Name Of Firm',
                  },
                  {
                    type: 'text',
                    field: 'billNumber',
                    label: 'Bill Number',
                  },
                  {
                    type: 'date',
                    field: 'billDate',
                    format: 'DD/MM/YYYY',
                    label: 'Date',
                  },
                  {
                    type: 'text',
                    field: 'amount',
                    label: 'Amount',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    render: () => (
                      <Text style={{ fontStyle: 'italic', fontSize: 14 }}>
                        Kindly accord approval for the payment/adjustment of Rs.
                        {' '}
                        {item?.amount}
                        {' '}
                        (
                        {numWords(item?.amount)}
                        {' '}
                        only)
                      </Text>
                    ),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            direction: 'row',
            editable: false,
            label: () => <Text style={vars.headings.h8}>Remarks of Official</Text>,
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    field: 'assignedToRole',
                    fieldType: 'nested',
                    type: 'standardShadow',
                    editable: false,
                    Component: NestedTable,
                    componentProps: {
                      // maxHeight: 400,
                      listProps: {
                        columns: [
                          {
                            type: 'text',
                            variant: 'filled',
                            header: 'Official',
                            field: 'role',
                          },
                          {
                            type: 'text',
                            variant: 'filled',
                            header: 'Remarks',
                            field: 'remarks',
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'statusChart',
                    field: 'assignedToRole',
                    id: 'contingentVoucherDataList',
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewCotingentVoucherRequest;

