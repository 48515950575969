import React, { useState } from 'react';
import { Button } from '@applane/react-button';
import { isFunction } from 'lodash';
import {
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  isMobile,
} from '../index';
import {
  standardButtonStyle,
  standardButtonMediumStyle,
  standardButtonMobileStyle,
  compactButtonStyle,
  largeButtonStyle,
  TableActionButtonStyle,
  outlineButtonStyle,
  outlineButtonMobileStyle,
  negativeStandardButtonStyle,
  negativeStandardButtonMobileStyle,
  negativeCompactButtonStyle,
  positiveStandardButtonStyle,
  positiveStandardButtonMobileStyle,
  positiveCompactButtonStyle,
  standardLinkButtonStyle, notAppreadButtonStyle,
} from '../../theme/buttonsTheme';
import { gradiantButtonShadow } from '../../theme/shadows';
import { h14_AvenirNext, h16_Regular } from '../../theme/fonts';

export const ButtonLoader = () => (
  <ActivityIndicator size={18} color="#ffffff" width={1} position="relative" />
);

export const StandardButton = (props) => (
  <Button
    {...(isMobile ? standardButtonMobileStyle : standardButtonStyle)}
    {...props}
  />
);

export const MediumStandardButton = (props) => (

  <Button
    {...(isMobile ? standardButtonMobileStyle : standardButtonMediumStyle)}
    {...props}
  />
);

export const CompactButton = (props) => (
  <Button {...compactButtonStyle} {...props} />
);

export const LargeButton = (props) => (
  <Button {...largeButtonStyle} {...props} />
);
export const LoadingButton = (props) => {
  const [loading, setLoading] = useState();

  const customOnPress = async () => {
    setLoading(true);
    const { onPress } = props;
    if (isFunction(onPress)) {
      await onPress();
    }
    setLoading(false);
  };
  return (
    <Button
      {...largeButtonStyle}
      {...props}
      loading={loading}
      loadingLabel={' '}
      loaderComponent={<ActivityIndicator size="small" color="white" />}
      onPress={customOnPress}
    />
  );
};

export const TableActionButton = (props) => (
  <Button
    {...TableActionButtonStyle}
    {...props}
    loadingLabel={' '}
    loaderComponent={<ActivityIndicator size="small" color="white" />}
  />
);

export const OutlineButton = (props) => (
  <Button
    {...(isMobile ? outlineButtonMobileStyle : outlineButtonStyle)}
    {...props}
  />
);
export const OutlineButtonNotAppread = (props) => (
  <Button
    {...(isMobile ? outlineButtonMobileStyle : notAppreadButtonStyle)}
    {...props}
  />
);

export const NegativeStandardButton = (props) => (
  <Button
    {...(isMobile
      ? negativeStandardButtonMobileStyle
      : negativeStandardButtonStyle)}
    {...props}
  />
);

export const NegativeCompactButton = (props) => (
  <Button {...negativeCompactButtonStyle} {...props} />
);

export const PositiveStandardButton = (props) => (
  <Button
    {...(isMobile
      ? positiveStandardButtonMobileStyle
      : positiveStandardButtonStyle)}
    {...props}
  />
);

export const PositiveCompactButton = (props) => (
  <Button {...positiveCompactButtonStyle} {...props} />
);

export const RowButton = (props) => (
  <StandardButton borderRadius={4} {...props} />
);

export const ActionButton = (props) => {
  const { theme = {}, label, submitting = false } = props;
  const {
    containerStyle,
    labelStyle,
    indicatorSize = 'small',
    indicatorColor = 'white',
  } = theme;

  return (
    <TouchableOpacity style={containerStyle}>
      {submitting ? (
        <ActivityIndicator size={indicatorSize} color={indicatorColor} />
      ) : (
        <Text style={labelStyle}>{label}</Text>
      )}
    </TouchableOpacity>
  );
};
export const GradientButton = (props) => (
  <TouchableOpacity
    style={{
      minWidth: 125,
      minHeight: 42,
      backgroundImage: 'linear-gradient(to right, #55cfea , #5c98f0)',
      borderRadius: 8,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 3,
      paddingBottom: 3,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      ...gradiantButtonShadow,
    }}
    onPress={props.onPress}
  >
    <Text style={{ color: 'white', ...h14_AvenirNext, textAlign: 'center' }}>
      {props.label}
    </Text>
    {props?.image ? (
      <Image
        source={props?.image}
        style={props?.imageStyle ? props?.imageStyle : {}}
      />
    ) : (
        void 0
      )}
  </TouchableOpacity>
);

export const StartVideoCallButton = (props) => (
  <TouchableOpacity
    style={{
      minWidth: 172,
      minHeight: 42,
      backgroundImage: 'linear-gradient(to right, #55cfea , #5c98f0)',
      borderRadius: 8,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 3,
      paddingBottom: 3,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      ...gradiantButtonShadow,
    }}
  >
    <Text style={{ color: 'white', ...h16_Regular, textAlign: 'center' }}>
      {props.label}
    </Text>
    {props?.image ? (
      <Image
        source={props?.image}
        style={props?.imageStyle ? props?.imageStyle : {}}
      />
    ) : (
        void 0
      )}
  </TouchableOpacity>
);

export const StandardLink = (props) => <StandardButton {...standardLinkButtonStyle} {...props} link />;

export const buttonMappings = {
  standard: StandardButton,
  outline: OutlineButton,
  text: StandardLink,
  link: StandardLink,
};
