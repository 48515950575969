import React from 'react';
import moment from 'moment';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { organizationLabels } from '../../../../../../../../labels/en/logoOrganisationLabels';

function timeFormat(hour, min, format) {
  const temp = `${hour}:${min} ${format}`;
  return temp;
}

const DeathCardForm = (props) => {
  const today = moment();
  return (
    <ListActionModal
      title="Death Card"
      description="Fill details for the death of the patient."
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          defaultValues={() => ({
            _createdOn: today,
            hospitalName: organizationLabels.organizationName,
            deathDate: today,
            meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
          })}
          submitMessage={'asset.messages.itemUpdatedSuccessfully'.getLabel()}
          reloadEvent={`reload${MODELS.IPD_SUMMARY}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.deathTime = timeFormat(
              data?.hour,
              data?.minute,
              data?.meridiem,
            );
            delete updates.hour;
            delete updates.minute;
            delete updates.meridiem;
            const newUpdate = {
              ...updates,
              admissionId: {
                _id: submitProps?.navigation?.state?.params?.patient?._id,
              },
              summary: submitProps?.navigation?.state?.params?.summary,
            };
            const submitAPI = submit({
              model: MODELS.IPD_SUMMARY,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
          }}
          mandatory={{
            _id: 1,
            wardNumber: 1,
            deathDate: 1,
            deathAge: 1,
            onsetDeathCause: 1,
            deathCauseInterval: 1,
            morbidCondition: 1,
            onsetMorbidCondition: 1,
            morbidConditionInterval: 1,
            otherDeathCause: 1,
            onsetOtherDeathCause: 1,
            otherConditionInterval: 1,
            deathManner: 1,
            injuryDetail: 1,
            certifyingAttendantName: 1,
            deathCertificate: 1,
          }}
          computations={{
            self: {
              isGenderSelected: {
                compute: (data) => {
                  if (!data?.isGenderSelected === 'Female') {
                    return {
                      set: {
                        isDelivery: null,
                        pregnancyCauseDeath: null,
                      },
                    };
                  }
                  return {};
                },
                onChange: ['isGenderSelected'],
              },
            },
          }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                editable: true,
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'hospitalName',
                        label: 'NAME OF THE HOSPITAL',
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'wardNumber',
                        label: 'Ward Number',
                        mandatory: true,
                      },
                      {
                        type: 'date',
                        field: 'deathDate',
                        label: 'Date Of Death',
                      },
                      newDateInput({
                        hour: { field: 'hour', label: 'End Hour' },
                        minute: { field: 'minute', label: 'End Minute' },
                        meridiem: { field: 'meridiem', label: 'Meridiem' },
                      }),
                      {
                        type: 'text',
                        field: 'patient?.patientName',
                        label: 'Name Of Deceased',
                        editable: false,
                      },
                      {
                        type: 'radioGroup',
                        label: 'GENDER',
                        field: 'isGenderSelected',
                        container: 'topLabel',
                        options: ['Male', 'Female'],
                      },
                      {
                        type: 'number',
                        field: 'deathAge',
                        label: 'Age at the time Death',
                        mandatory: true,
                      },
                    ],
                  },
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'deathCause',
                        label: 'Cause Of Death',
                        allowWhiteSpace: true,
                        minHeight: 60,
                      },
                      {
                        type: 'textArea',
                        field: 'onsetDeathCause',
                        label: 'Onset and death approx for the cause of death',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'deathCauseInterval',
                        label: 'Interval between cause of death',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'morbidCondition',
                        label: 'Morbid Conditions, If any',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'onsetMorbidCondition',
                        label: 'Onset and death approx for the Morbid Conditions',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'morbidConditionInterval',
                        label: 'Interval between the morbid conditions',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'otherDeathCause',
                        label: 'Other significant Conditions',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'onsetOtherDeathCause',
                        label: 'Onset and Death approx for the other Conditions',
                        allowWhiteSpace: true,
                        minHeight: 60,
                        mandatory: true,
                      },
                      {
                        type: 'textArea',
                        field: 'otherConditionInterval',
                        label: 'Interval between the other conditions',
                        allowWhiteSpace: true,
                        mandatory: true,
                        minHeight: 60,
                      },
                    ],
                  },
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        type: 'radioGroup',
                        container: 'topLabel',
                        label: 'Manner Of Death',
                        field: 'deathManner',
                        mandatory: true,
                        style: {
                          flexDirection: 'column',
                          flex: 1,
                          overflow: 'hidden',
                          flexFlow: 'row',
                        },
                        options: [
                          'Natural',
                          'Accident',
                          'Suicide',
                          'Homicide',
                          'Pending investigation',
                        ],
                      },
                      {
                        type: 'textArea',
                        label: 'How did the injury occur, If any',
                        field: 'injuryDetail',
                        allowWhiteSpace: true,
                        width: '47%',
                        mandatory: true,
                        minHeight: 80,
                      },
                      {
                        type: 'radioGroup',
                        container: 'topLabel',
                        field: 'pregnancyCauseDeath',
                        label: 'WAS PREGNANCY THE DEATH ASSOCIATED WITH',
                        style: { flex: 2, overflow: 'hidden' },
                        options: ['Yes', 'No'],
                        visible: ({ data }) => {
                          if (data?.isGenderSelected === 'Female') {
                            return true;
                          }
                          return false;
                        },
                      },
                      {
                        type: 'radioGroup',
                        container: 'topLabel',
                        field: 'isDelivery',
                        label: 'WAS THERE A DELIVERY',
                        style: {
                          flex: 2,
                          overflow: 'hidden',
                          alignItems: 'Left',
                          paddingLeft: 100,
                        },
                        options: ['Yes', 'No'],
                        visible: ({ data }) => {
                          if (data?.isGenderSelected === 'Female') {
                            return true;
                          }
                          return false;
                        },
                        width: '47%',
                      },
                      {
                        type: 'text',
                        field: 'certifyingAttendantName',
                        label: 'Name of the Medical attendant certifying the cause of death',
                        allowWhiteSpace: true,
                        mandatory: true,
                      },
                      {
                        type: 'file',
                        label: 'deathCertificate',
                        field: 'deathCertificate',
                        mandatory: true,
                        width: '47%',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
      {...props}
    />
  );
};
export default DeathCardForm;
