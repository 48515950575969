import React from 'react';

import { cancelButton, save, Text } from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import { ROUTES } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import GenerateLabTestBill from './GenerateLabTestBill';
import LabBillingHistory from './LabBillingHistory';
import ConfirmationModal from './LabBillingHistory/ActiveBillHistory/ConfirmationModal';
import ViewBillHistory from './LabBillingHistory/ViewBillhistory';
import NewBill from './NewBill';

export default {
  [ROUTES.labBill.name]: { screen: GenerateLabTestBill, expanded: true },
  [ROUTES.newLabBill.name]: {
    screen: NewBill,
    title: (props) => {
      const { navigation: { state: { params: { data } } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {data[0]?.uhid}
        </Text>
      );
    },
    expanded: true,
    headerActions: [cancelButton, save({ label: 'Generate' })],
  },
  [ROUTES.labBillHistory.name]: {
    screen: LabBillingHistory,
    expanded: true,
    header: {
      title: <HeaderTitleWithCount
        label="Billing History"
      />,
    },

  },
  [ROUTES.viewLabBillHistory.name]: {
    screen: ViewBillHistory, expanded: true, modal: true, modalProps: { width: 900 },
  },
  [ROUTES.confirmLabBillHistory.name]: {
    screen: ConfirmationModal, expanded: true, modal: true,
  },
};
