import React from 'react';

import MasterList from '../../../Wired/Master/MasterList';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { ToggleFilter } from '../../../app-components';
import permissions from '../../../Lib/permissions';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'department.labels.departmentName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
    width: 150,
  },
  {
    header: 'department.labels.departmentId'.getLabel(),
    type: 'text',
    field: 'code',
    sortable: 'code',
  },
  {
    header: 'department.labels.roomsAllocated'.getLabel(),
    type: 'link',
    field: 'rooms',
    align: 'center',
    value: (props) => props?.item?.rooms_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewRoomsListModal.name,
          modal: true,
          params: {
            item,
            filter: { department: { _id: item._id } },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'department.labels.employees'.getLabel(),
    type: 'link',
    field: 'users_count',
    align: 'center',
    value: (props) => props?.item?.employee_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewUserListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              department: { _id: item?._id },
            },
            description: 'department.title.description'.getLabel(),
            ...props,
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'department.labels.specialities'.getLabel(),
    type: 'link',
    field: 'specialities',
    align: 'center',
    value: (props) => props?.item?.wards_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewWardsListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              department: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'department.labels.status'.getLabel(),
    field: 'isActive',
    type: 'status',
    sortable: 'isActive',
  },
];

const tableColumnsAfterEditAction = [
  {
    header: 'department.labels.description'.getLabel(),
    type: 'text',
    position: 'center',
    width: 250,
    field: 'description',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.DEPARTMENTS}
    editFormId={ROUTES.editDepartment.name}
    tableColumns={tableColumns}
    tableColumnsAfterEditAction={tableColumnsAfterEditAction}
    access_key={permissions.mdm.views.modifyDepartment.permissions.modifyDepartment.value}
    header={{
      leftActions: [(actionProps) => <ToggleFilter {...actionProps} model={MODELS.DEPARTMENTS} id="masterDataListForTable" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewDepartment.name,
        params: { item },
      },
    })}
  />
);
