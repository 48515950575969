import React, { Component } from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from '@applane/react-core-components';
import { Button } from '@applane/react-button';
import { WithModal } from '@applane/react-with-modal';
import {
  filterGroupTheme,
  filterGroupMobileTheme,
} from '../../../theme/filterGroupTheme';
import { OutlineButton, StandardButton } from '../../buttons/Buttons';
import { filterGroupTypes } from '../FilterTypes';
import { getRenderComponent, isMobile } from '../../UtilityFunctions';
import { isFilterVisible } from '../Utility';
import { dropDownShadow } from '../../../theme/shadows';

const getCount = ({ filters, appliedFilters = {}, dataParams }) => {
  let count = 0;
  for (const filter of filters) {
    if (filter) {
      const { field, type } = filter || {};
      if (field) {
        const appliedFilterValue = appliedFilters[field] && appliedFilters[field].value;
        if (appliedFilterValue !== undefined && appliedFilterValue !== null) {
          count += 1;
        }
      }
      if (type === 'fts' && dataParams?.ftsFilter?.value) {
        count += 1;
      }
    }
  }
  return count;
};

const getAppliedFilters = ({ screenState }) => {
  const { dataParams: { filters } = {} } = screenState;
  return filters || {};
};

class FilterPopUp extends Component {
  constructor(props) {
    super(props);
    const { screenState: { dataParams } = {} } = props;
    this.state = { filtersData: { ...dataParams } };
    this.tabFilterInfo = {};
  }

  onChangeValue = (filtersDataValue) => {
    const { filtersData } = this.state;
    const { type } = this.getSelectedFilter();
    if (type === 'fts') {
      this.setState({
        filtersData: { ...filtersData, ftsFilter: filtersDataValue },
      });
    } else {
      this.setState({
        filtersData: { ...filtersData, filters: filtersDataValue },
      });
    }
  };

  getSelectedFilter = () => {
    const { filters } = this.props;
    const { selectedFilterIndex = 0 } = this.state;
    return filters[selectedFilterIndex] || {};
  };

  resetFilterData = ({ filtersData, filterInfo } = {}) => {
    const { field, type, filterTabs } = filterInfo;
    if (filterTabs) {
      this.tabFilterInfo[field] = { filterTabs };
    }
    if (type === 'fts') {
      delete filtersData.ftsFilter;
    } else if (field) {
      let { filters } = filtersData;
      filters = {
        ...filters,
      };
      delete filters[field];
      filtersData.filters = filters;
    }
  };

  resetSingleFilter = (filterInfo) => {
    let { filtersData } = this.state;
    filtersData = { ...filtersData };
    this.resetFilterData({ filtersData, filterInfo });
    this.setState({ filtersData });
  };

  resetFilter = () => {
    let { filtersData = {} } = this.state;
    filtersData = { ...filtersData };
    const { filters = [] } = this.props;
    filters.forEach((item) => {
      this.resetFilterData({ filtersData, filterInfo: item });
    });
    this.setState({ filtersData }, () => {
      this.applyFilter();
    });
  };

  applyFilter = () => {
    const { setScreenState, hideModal, setTabsState } = this.props;
    const { filtersData } = this.state;
    setScreenState
      && setScreenState({
        dataParams: filtersData,
      });
    if (Object.keys(this.tabFilterInfo).length && setTabsState) {
      setTimeout((_) => {
        setTabsState({
          skipSelectedTab: true,
          filters: filtersData?.filters,
          filtersInfo: this.tabFilterInfo,
        });
        hideModal && hideModal();
      }, 200);
    } else {
      hideModal && hideModal();
    }
  };

  setTabsState = ({ filtersInfo } = {}) => {
    this.tabFilterInfo = { ...this.tabFilterInfo, ...filtersInfo };
  };

  renderFiltersLabel = () => {
    const {
      filters = [],
      labelValueContainerStyle,
      selectedLabelValueContainerStyle,
      labelValueTextStyle,
      selectedLabelValueTextStyle,
      labelValueCountContainerStyle,
      labelValueCountTextStyle,
      labelValueDotStyle,
      labelValueCrossIcon,
    } = this.props;
    if (!filters || !filters.length) {
      return null;
    }
    const { selectedFilterIndex = 0, filtersData = {} } = this.state;
    return (
      <ScrollView style={{ flex: 1 }}>
        {filters.map((item, index) => {
          const isActive = selectedFilterIndex === index;
          const { field, label, type } = item || {};
          let count = 0;
          let showDot = false;
          if (
            type === 'fts'
            && filtersData
            && filtersData.ftsFilter
            && filtersData.ftsFilter.value
          ) {
            showDot = true;
          } else {
            const filterValue = filtersData
              && filtersData.filters
              && filtersData.filters[field]
              && filtersData.filters[field].value;
            if (filterValue !== undefined && filterValue !== null) {
              if (Array.isArray(filterValue)) {
                count = filterValue.length;
              } else {
                showDot = true;
              }
            }
          }
          return (
            <TouchableOpacity
              style={{
                ...labelValueContainerStyle,
                ...((isActive && selectedLabelValueContainerStyle) || {}),
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onPress={() => {
                this.setState({
                  selectedFilterIndex: index,
                });
              }}
            >
              <Text
                style={{
                  ...labelValueTextStyle,
                  ...((isActive && selectedLabelValueTextStyle) || {}),
                }}
              >
                {label || field}
              </Text>
              {showDot ? (
                <View style={labelValueDotStyle} />
              ) : count > 0 ? (
                <View style={labelValueCountContainerStyle}>
                  <Text style={labelValueCountTextStyle}>{count}</Text>
                </View>
              ) : (
                void 0
              )}
              {(showDot || count > 0) && (
                <TouchableOpacity
                  style={{ cursor: 'pointer' }}
                  onPress={() => this.resetSingleFilter(item)}
                >
                  <Image
                    source={labelValueCrossIcon}
                    style={{
                      marginLeft: 8,
                      marginRight: 8,
                      height: 12,
                      width: 12,
                    }}
                  />
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );
  };

  render() {
    const {
      hideModal,
      filters,
      title = 'Filters',
      headerContainerStyle,
      labelContainerStyle,
      filterContainerStyle,
      footerContainerStyle,
      titleStyle,
      crossIcon,
      crossIconStyle = {},
      applyText = 'Apply',
      resetText = 'Reset',
      buttonStyle,
      ...restProps
    } = this.props;
    const { screenState } = this.props;
    const { dataParams } = screenState || {};
    if (!filters || !filters.length) {
      return null;
    }
    const { selectedFilterIndex = 0, filtersData = {} } = this.state;
    const selectedFilter = this.getSelectedFilter();
    let { type, filter, ...restFilterProps } = selectedFilter || {};
    if (!filter && type) {
      filter = filterGroupTypes[type];
    }
    if (!filter) {
      filter = (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Text style={{ color: 'red' }}>Filter Editor Not Found</Text>
        </View>
      );
    }
    const appliedFilters = getAppliedFilters(this.props);
    const count = getCount({ filters, appliedFilters, dataParams });
    return (
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <View style={headerContainerStyle}>
          <Text style={titleStyle}>{title}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
              style={{ cursor: 'pointer' }}
              onPress={hideModal}
            >
              <Image source={crossIcon} style={crossIconStyle} />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            overflow: 'hidden',
          }}
        >
          <View style={labelContainerStyle}>
            <View style={{ flex: 1, overflow: 'hidden' }}>
              {this.renderFiltersLabel()}
            </View>
          </View>
          <View style={filterContainerStyle}>
            <View
              style={{
                flex: 1,
                overflow: 'hidden',
              }}
            >
              {getRenderComponent(filter, {
                withoutModal: true,
                key: selectedFilterIndex,
                ...restFilterProps,
                ...restProps,
                data:
                  type === 'fts' ? filtersData.ftsFilter : filtersData.filters,
                onChangeValue: this.onChangeValue,
                setTabsState: this.setTabsState,
              })}
            </View>
          </View>
        </View>
        <View style={footerContainerStyle}>
          <Button
            {...buttonStyle.reset}
            label={resetText}
            onPress={this.resetFilter}
          />
          <Button
            {...buttonStyle.apply}
            label={applyText}
            onPress={this.applyFilter}
          />
        </View>
        {/* <OutlineButton
            disabled={!count}
            label={resetText}
            onPress={() => {
              this.resetFilter();
            }}
          />
          <View style={{ marginLeft: 12 }}>
            <StandardButton label={applyText} onPress={this.applyFilter} />
          </View>
        </View> */}
      </View>
    );
  }
}

class FilterGroup extends Component {
  state = {};

  renderModal = ({ frameStyle, hideModal }) => {
    const { dropdownStyle } = this.props;
    this.setState({ isOpened: true });
    return (
      <View style={{ ...frameStyle, ...dropdownStyle, overflow: 'hidden' }}>
        <FilterPopUp
          {...this.props}
          hideModal={(props) => {
            this.setState({ isOpened: false });
            hideModal && hideModal(props);
          }}
        />
      </View>
    );
  };

  render() {
    let {
      icon,
      activeIcon,
      appliedIcon,
      filters,
      title = 'Filters',
      screenState,
      modalPosition,
      containerStyle,
      hoverContainerStyle,
      activeContainerStyle,
      dropdownStyle,
      backdropStyle,
      badgeCountContainerStyle,
      badgeCountTextStyle,
      imageStyle,
      visible = true,
    } = this.props;
    const { dataParams } = screenState || {};
    if (!filters || !filters.length) {
      return null;
    }
    if (typeof visible === 'function') {
      visible = visible(this.props);
    }
    if (!visible) {
      return null;
    }
    const { isHover, isOpened } = this.state;
    const appliedFilters = getAppliedFilters(this.props);
    const count = getCount({ filters, appliedFilters, dataParams }) || 0;

    if (isOpened) {
      containerStyle = {
        ...containerStyle,
        ...activeContainerStyle,
      };
      icon = activeIcon || icon;
    } else if (isHover) {
      containerStyle = {
        ...containerStyle,
        ...hoverContainerStyle,
      };
    }
    if (count > 0) {
      icon = appliedIcon || icon;
    }
    return (
      <WithModal
        position={modalPosition}
        style={containerStyle}
        backdropStyle={backdropStyle}
        dropdownStyle={dropdownStyle}
        renderModal={this.renderModal}
        pushModal
      >
        <View
          title={title}
          style={{ cursor: 'pointer' }}
          onMouseOver={() => {
            this.setState({ isHover: true });
          }}
          onMouseOut={() => {
            this.setState({ isHover: false });
          }}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Image resizeMode="contain" source={icon} style={imageStyle} />
          {count > 0 ? (
            <View style={badgeCountContainerStyle}>
              <Text style={badgeCountTextStyle}>{count}</Text>
            </View>
          ) : (
            void 0
          )}
        </View>
      </WithModal>
    );
  }
}

export const FilterGroupComponent = (props) => {
  if (!props.filters || !props.filters.length) {
    return null;
  }
  return (
    <FilterGroup
      {...(isMobile ? filterGroupMobileTheme : filterGroupTheme)}
      {...props}
      // dropdownStyle={{
      //   width: 300,
      //   height: 100,
      //   ...dropDownShadow,
      //   backgroundColor: 'red',
      //   borderRadius: 8,
      //   overflow: 'hidden',
      // }}
    />
  );
};

export default (props) => {
  if (!props.filters || !props.filters.length) {
    return null;
  }
  return {
    visible: isFilterVisible,
    render: <FilterGroupComponent {...props} />,
  };
};
