import React from 'react';
import moment from 'moment';

import {
  Table,
  StandardButton,
  View,
  Text,
  DateFilter,
  FilterGroup,
  selectAll,
  clearSelection,
  showSelectedCount,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FTS from '../../../Components/TableFilters/FtsSearch';
import { patientListUri } from '../../../Queries/patient';
import { genders, MODELS, ROUTES } from '../../../Lib/constants';
import { getString } from '../../../Lib/helpers';
import vars from '../../../theme/vars';
import { filterActive, filterInactive } from '../../../images';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';

const header = ({ aggregates }) => ({
  title: <HeaderTitleWithCount count={aggregates?._count} label={'patient.title.registeredPatients'.getLabel()} />,
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
    {
      render: () => (
        <WithPermission access_key={permissions.mdm.views.patientManagement.permissions.export.value}><StandardButton label={'patient.buttons.exportToExcel'.getLabel()} /></WithPermission>
      ),
      file: 'PatientsManagementList',
      type: 'download',
      columns: [
        {
          field: 'patientName',
          header: 'Patient Name',
        },
        {
          field: 'gender',
          header: 'Gender',
        },
        {
          field: 'age',
          header: 'Age',
        },
        {
          field: 'relation',
          header: 'Relation Type',
        },
        {
          field: 'relationName',
          header: 'Relation Name',
        },
        // {
        //   field: 'aadharNumber',
        //   header: 'Aadhar Number',
        // },
        // {
        //   field: 'BPLNumber',
        //   header: 'BPL Number',
        // },
        // {
        //   field: 'mobile',
        //   header: 'Mobile',
        // },
        // {
        //   field: 'address',
        //   header: 'Address',
        // },
        // {
        //   field: 'remarks',
        //   header: 'Remarks',
        // },
        {
          field: {
            field: 'lastConsultation[0].date',
            parserFunction: 'formatDateForExcel',
          },
          header: 'Last Consultation Date',
        },
      ],
      download: () => ({
        uri: {
          model: MODELS.PATIENTS,
          query: {
            id: 'patientDataList',

          },
        },
      }),
      confirmModalProps: { autoHide: true, minWidth: 436 },
      confirm: {
        title: 'Export Records!',
        hideCross: false,
        message: (messageProps) => {
          const { screenState: { selectedIds } } = messageProps;
          return `This action will export ${selectedIds.length} selected records in the excel format.`;
        },
        actions: ({ action }) => [
          { text: 'Cancel' },
          {
            text: 'Export All',
            ...action,
            allPageSelected: true,
          },
          {
            text: 'Yes',
            ...action,
          },
        ],
      },
    }]),
  actions: [
    (props) => <FTS {...props} action={{ exp: ['patientName', 'uhid', 'relationName'] }} />,
    <DateFilter field="lastConsultationDate" key="lastConsultationDate" />,
    FilterGroup({
      icon: filterInactive,
      activeIcon: filterActive,
      filters: [
        {
          type: 'autoSuggestOptions',
          options: genders,
          field: 'gender',
          label: 'Gender',
          multiSelect: false,
        },
        {
          type: 'autoSuggestOptions',
          options: ['S/O', 'D/O', 'W/O'],
          field: 'relation',
          label: 'Relation',
          multiSelect: false,
        },
      ],
    }),
  ],
});

const tableItems = {
  lg: {
    header,
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'UHID',
        type: 'text',
        field: 'uhid',
      },
      {
        header: 'Patient Name',
        type: 'text',
        sortable: 'patientName',
        field: 'patientName',
      },
      {
        header: 'Relation Name',
        type: 'text',
        value: ({ item }) => getString([item?.relation, item?.relationName]),
      },
      {
        header: 'Gender',
        type: 'text',
        value: ({ item }) => item?.gender.charAt(0).toUpperCase() + item?.gender.slice(1),
      },
      {
        header: 'Age',
        type: 'text',
        field: 'age',
      },
      {
        header: 'Last Consultation Date',
        type: 'date',
        sortable: 'lastConsultationDate',
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            return {
              view: ROUTES.doctorConsultationList.name,
              modal: true,
              params: {
                item,
                model: MODELS.CONSULTATION,
                id: 'consultationDataList',
                patientName: item?.patientName,
                addOnFilter: {
                  patient: {
                    _id: item?._id,
                  },
                },
              },
            };
          },
        },
        render: ({ item }) => (
          <View>
            <Text style={{ ...vars.headings.h9, color: vars.colors.secondary.color2, textDecorationLine: 'underline' }}>
              {item?.lastConsultationDate ? moment(item?.lastConsultationDate).format('DD MMM YYYY') : 'N/A'}
            </Text>
          </View>
        ),
      },
    ],
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.ViewPatientMDM.name,
        params: { _id: item?._id, item },
      },
    }),
  },
};

export default Table({ uri: patientListUri, ...tableItems });
