import React from 'react';

import { DateFilter, TabNavigator } from '../../../../app-components';
import vars from '../../../../theme/vars';
import IndentRequestList from './components/IndentRequestList';
import TransferRequestList from './components/TransferRequestList';
import tabTheme from '../Themes/tabTheme';

const MSIRequestsTabs = TabNavigator({
  theme: tabTheme,
  actions: [
    <DateFilter
      field="_createdOn"
      key="_createdOn"
      containerStyle={
        {
          flexDirection: 'row',
          width: 303,
          height: 30,
          borderWidth: 1,
          padding: 9,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }
      }
    />,
  ],
  tabs: {
    IndentRequests: {
      label: 'Indent Requests',
      screen: IndentRequestList,
    },
    transfer: {
      label: 'Transfer Requests',
      screen: TransferRequestList,
    },
  },
});
export default MSIRequestsTabs;
