import { MODELS } from '../Lib/constants';

export const ipdOrderListUri = () => ({
  query: {
    id: 'IPDOrderList',
  },
  aggregate: true,
  model: MODELS.IPD_ORDERS,
});

export const editIpdOrderListUri = (props) => ({
  query: {
    model: MODELS.IPD_ORDERS,
    id: 'EditIPDOrderPatient',
    addOnFilter: {
      admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
      speciality: props.dataParams?.filters?.specialityFilter,

    },
  },
  aggregate: true,
  model: MODELS.IPD_ORDERS,
});

export const editIpdOrderUri = ({ navigation }) => {
  const { state: { params: { item = {} } } } = navigation;
  return ({
    query: {
      model: MODELS.IPD_ORDERS,
      id: 'IPDOrderList',
      addOnFilter: { _id: item._id },
    },
    model: MODELS.IPD_ORDERS,
  });
};

export const getOrderICUDataUri = (filter = {}) => ({
  query: {
    model: MODELS.IPD_ICU_ORDER,
    id: 'ipdOrderICUDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: 'ipd_icu_orders',
});

export const viewICUOrdersUri = (filter) => ({
  query: {
    id: 'ipdOrderICUDataList',
    addOnFilter: {
      _id: filter._id,
      speciality: filter?.dataParams?.filters?.specialityFilter,
    },
  },
  model: MODELS.IPD_ICU_ORDER,
});
