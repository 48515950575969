/* eslint-disable react/jsx-key */
import React from 'react';
import { TabNavigator } from '../../../../../../../app-components/tab/TabNavigator';
import { MODELS } from '../../../../../../../Lib/constants';
import { countUri } from '../../../../../../../Queries/countUri';
import ActivityLogsTabList from '../../../../../../../Wired/ActivityLogList';
import PatientWiseDispensingList from '../patientWiseDispensingList';

const ViewItemTabs = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
          hidePatientDispensing,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <TabNavigator
      tabs={{
        patientWiseDispensing: {
          visible: !hidePatientDispensing,
          label: 'Patient Wise Dispensing',
          screen: PatientWiseDispensingList,
          screenProps: {
            model: MODELS.PHARMACY_BILLING,
            id: 'pharmacyBillingDataList',
            addOnFilter: { pharmacyDetails: { item: { _id: item.item._id } } },
          },
          countUri: countUri({
            model: MODELS.PHARMACY_BILLING,
            id: 'pharmacyBillingDataList',
            filter: { pharmacyDetails: { item: { _id: item.item._id } } },
          }),
        },
        activityLog: {
          label: 'Activity Log',
          screen: ActivityLogsTabList,
          screenProps: {
            data: item,
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivity',
            addOnFilter: { id: item?._id },
          },
          countUri: countUri({
            model: MODELS.APP_HISTORY_CHANGES,
            id: 'getHistoryActivityCount',
            filter: { id: item?._id },
          }),
        },
      }}
      {...props}
    />
  );
};
export default ViewItemTabs;
