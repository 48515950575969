import React from 'react';
import {
  Text,
} from '../../../app-components';

import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { getString } from '../../../Lib/helpers';

const PatientDetails = (props) => {
  const { navigation = {}, item = {}, isClinicalView = false } = props || {};
  const { patient_id: { _id } = {} } = item || {};

  const uri = {
    query: {
      id: 'patientDataList',
      relationValue: {
        model: MODELS.PATIENTS,
        _id,
        relation: '_id',
      },
      limit: 1,
    },
    model: MODELS.PATIENTS,
  };
  return (
    <Form
      type="standardShadow"
      uri={uri}
      {...props}
      mandatory={{ name: 1 }}
      containerStyle={{
        paddingTop: isClinicalView ? 0 : 10,
        paddingLeft: isClinicalView ? 0 : 12,
        paddingRight: isClinicalView ? 0 : 12,
        backgroundColor: '#fff',
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'patient.labels.patientName'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'patient.labels.gender'.getLabel(),
                    field: 'gender',
                    suggestionField: 'label',
                    placeholder: 'patient.placeholders.selectGender'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'age',
                    label: 'patient.labels.age'.getLabel(),
                    mandatory: true,
                    editable: false,
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {data?.age}
                        {' '}
                        {data?.ageType}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'patient.labels.relation'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getString([data?.relation, data?.relationName])}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'aadharNumber',
                    label: 'patient.labels.aadhar'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'BPLNumber',
                    label: 'patient.labels.bpl'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'mobile',
                    label: 'patient.labels.mobile'.getLabel(),
                    placeholder: 'patient.placeholders.mobile'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'address',
                    label: 'patient.labels.address'.getLabel(),
                    placeholder: 'patient.placeholders.address'.getLabel(),
                    editable: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default PatientDetails;
