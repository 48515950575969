import React, { useCallback, useEffect, useState } from 'react';

import {
  FormField, Image, Toast, View,
} from '../../../../../app-components';
import { getErrorString, getString } from '../../../../../Lib/helpers';
import { Action } from '../../../../../app-components/action/Action';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { edit_icon as editIcon } from '../../../../../images';
import vars from '../../../../../theme/vars';
import { post } from '../../../../../AppServices';

const NurseEdit = (props) => {
  const { navigation, eventDispatcher, activeSession } = props;
  const { nurse, _id } = activeSession;
  const [nurseName, setNurseName] = useState();

  useEffect(() => {
    setNurseName(nurse?.name);
  }, [nurse]);

  const updateNurse = useCallback(async (nurseId, firstName, lastName) => {
    try {
      const nurseDetails = await post({
        data: { _id },
        updates: { nurse: { _id: nurseId } },
        addOnFilter: { _id },
        model: MODELS.VACCINATION_SESSION,
      });

      Toast.show({
        message: 'Success',
        description: `${firstName} ${lastName} ${'vaccination.messages.nurseUpdatedSuccessfullySuffix'.getLabel()}`,
        type: 'success',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      eventDispatcher.notify('reloadAssistantVaccinationData');
      setNurseName(getString([firstName, lastName]));
      navigation.pop();
      return nurseDetails;
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        duration: 3000,
      });
      return err;
    }
  }, [_id, eventDispatcher]);

  return (
    <View style={{
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: vars.colors.grey.color5,
      borderRadius: 4,
      marginTop: 14,
      marginRight: 8,
      paddingLeft: 10,
    }}
    >
      <FormField
        value={nurseName}
        label={'vaccination.labels.nurse'.getLabel()}
        type="text"
        disabled
        style={{
          borderBottomWidth: 0,
        }}
        labelContainerStyle={{ top: 14 }}
      />
      <Action
        navigation={navigation}
        action={{
          type: 'link',
          link: () => ({
            view: ROUTES.editNurseModal.name,
            modal: true,
            params: { nurseName, updateNurse },
            modalProps: {
              autoHide: true,
              maxHeight: '100%',
              width: 436,
              height: 405,
            },
          }),
        }}
      >
        <Image
          source={editIcon}
          style={{
            paddingTop: 7,
            paddingRight: 10,
          }}
        />
      </Action>
    </View>

  );
};
export default NurseEdit;
