import React from 'react';

import { NestedAction, NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { View, Text, save } from '../../../../../../../../app-components';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const DischargeCardForm = (props) => {
  const today = new Date();
  const { navigation: { state: { params: { patient } } } } = props;

  return (
    <ListActionModal
      title="Discharge Card"
      description="Fill details for the discharge based on the patient's request."
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          defaultValues={() => ({
            _createdOn: today,
            dischargedDate: today,
            admissionId: patient._id,
          })}
          editable={false}
          reloadEvent={`reload${MODELS.IPD_SUMMARY}`}
          submitMessage={'asset.messages.itemUpdatedSuccessfully'.getLabel()}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const newUpdate = {
              ...updates,
              admissionId: {
                _id:
                patient._id,
              },
              summary: submitProps?.navigation?.state?.params?.summary,
            };
            const submitAPI = submit({
              model: MODELS.IPD_SUMMARY,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
          }}
          mandatory={{
            _id: 1,
            caseSummary: 1,
            dischargedDate: 1,
            diagnosis: 1,
            adviceOnDischarge: {
              drug: 1,
              route: 1,
              dose: 1,
              frequency: 1,
            },
          }}
          validations={{
            diagnosis: ({ data }) => validateInputLength(data?.diagnosis, 1, 200),
            surgeryPerformed: ({ data }) => validateInputLength(data?.surgeryPerformed, 0, 250),
            operativeFindings: ({ data }) => validateInputLength(data?.operativeFindings, 0, 150),
            caseSummary: ({ data }) => validateInputLength(data?.caseSummary, 1, 250),
          }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 4,
                fieldVariant: 'filled',
                editable: false,
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        value: patient?.department?.name,
                        label: 'DEPARTMENT',
                      },
                      {
                        type: 'number',
                        value: patient?.patient?.lastConsultation?.ODPNumber,
                        label: 'OPD NUMBER',
                      },
                      {
                        type: 'text',
                        value: patient?.patientName,
                        label: 'NAME OF THE PATIENT',
                      },
                      {
                        type: 'text',
                        value: patient?.patient?.uhid,
                        label: 'UHID',
                      },
                      {
                        type: 'date',
                        field: '_createdOn',
                        label: 'DATE OF ADMISSION',
                      },
                      {
                        type: 'date',
                        field: 'dischargedDate',
                        label: 'DATE OF DISCHARGE',
                        editable: true,
                      },
                      {
                        type: 'number',
                        value: 'N/A',
                        label: 'HEAD OF THE UNIT',
                        mandatory: true,
                      },
                      {
                        type: 'number',
                        value: patient?.CRNumber,
                        label: 'CR NUMBER',
                      },
                      {
                        type: 'number',
                        value: patient?.patient?.age,
                        label: 'AGE',
                        width: '25%',
                      },
                      {
                        type: 'text',
                        value: patient?.patient?.gender,
                        label: 'GENDER',
                        width: '25%',
                      },
                      {
                        type: 'textArea',
                        field: 'diagnosis',
                        label: 'Diagnosis',
                        allowWhiteSpace: true,
                        placeholder: 'This is description box.',
                        minHeight: 80,
                        width: '48%',
                        mandatory: true,
                        editable: true,
                      },
                    ],
                  },
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'operationName',
                        label: 'Operation',
                      },
                      {
                        type: 'number',
                        field: 'mrp',
                        label: 'MRP (Rs.)',
                        editable: true,
                      },
                      {
                        type: 'date',
                        field: '_createdOn',
                        label: 'DATE OF SURGERY',
                      },
                    ],
                  },
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'surgeryPerformed',
                        label: 'Surgery Performance',
                        placeholder: 'This is description box.',
                        allowWhiteSpace: true,
                        mandatory: false,
                        editable: true,
                        minHeight: 60,
                      },
                      {
                        type: 'textArea',
                        field: 'operativeFindings',
                        label: 'Operative Findings',
                        placeholder: 'This is description box.',
                        allowWhiteSpace: true,
                        editable: true,
                        minHeight: 60,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'Treatment & advice on discharge',
                editable: true,
                labelStyle: {
                  ...vars.headings.h9,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 7,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        field: 'adviceOnDischarge',
                        fieldType: 'nested',
                        Component: NestedTable,
                        componentProps: {
                          footer: ({ addRow }) => ({
                            leftActions: [{
                              render: () => (
                                <View style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderWidth: 1,
                                  borderRadius: 3,
                                  width: 140,
                                  height: 40,
                                  borderColor: 'blue',
                                }}
                                >
                                  <Text style={{
                                    ...vars.headings.h9,
                                    color: vars.colors.secondary.color2,
                                  }}
                                  >
                                    + Add New Item
                                  </Text>
                                </View>
                              ),
                              onPress: addRow,
                            }],
                          }),
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowColumnTextStyle: {
                              ...vars.headings.h13,
                              color: vars.colors.secondary.color2,
                              backgroundColor: 'white',
                              numberOfLines: 1,
                              marginBottom: 7,
                            },
                            headerRowContainerStyle: { backgroundColor: 'white', marginRight: 15 },
                            columns: [
                              {
                                type: 'text',
                                field: 'drug',
                                variant: 'filled',
                                width: 'auto',
                                label: 'Drug',
                                mandatory: true,
                              },
                              {
                                type: 'text',
                                field: 'route',
                                variant: 'filled',
                                mandatory: true,
                                width: 'auto',
                                label: 'Route',
                              },
                              {
                                type: 'number',
                                field: 'dose',
                                variant: 'filled',
                                width: 'auto',
                                mandatory: true,
                                label: 'Dose (mg)',
                              },
                              {
                                type: 'number',
                                field: 'frequency',
                                label: 'Frequency',
                                mandatory: true,
                                variant: 'filled',
                                width: 'auto',
                              },
                              {
                                type: 'textArea',
                                field: 'specialInstructions',
                                label: 'Special Instructions',
                                placeholder: 'This is description box..',
                                variant: 'filled',
                                width: 400,
                                minHeight: 80,
                              },
                              NestedAction(),
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'date',
                        field: 'reviewDate',
                        label: 'Date of surgery',
                        width: '22%',
                        editable: true,
                      },
                      {
                        type: 'textArea',
                        field: 'caseSummary',
                        label: 'Case Summary',
                        placeholder: 'This is description box.',
                        allowWhiteSpace: true,
                        editable: true,
                        mandatory: true,
                        minHeight: 60,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};
export default DischargeCardForm;
