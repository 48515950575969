import React from 'react';

import {
  cancelButton,
  clearSelection,
  close,
  FilterGroup,
  OutlineButton,
  save,
  selectAll,
  showSelectedCount,
  StandardButton,
  Text,
} from '../../app-components';
import {
  epfFilter,
  MODELS,
  npsFilter,
  ROUTES,
} from '../../Lib/constants';
import EmployeeList from './EmployeeList';
import WithPermission from '../../Components/WithPermission';
import permissions from '../../Lib/permissions';
import AddEmployee from './AddEmployee';
import FtsSearch from '../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../images';
import { HeaderTitleWithDataFetchCount } from '../../Components/HeaderTitileWithCount';
import EditEmployeeTabs from './EditEmployee/Tabs';
import ViewEmployeeTabs from './ViewEmployee/Tabs';
import ImportSalaryComponents from '../../Wired/ImportSalaryComponents';
import employeeSalaryScreens from './EditEmployee/Salary';
import GenerateSalaryModal from '../../Wired/GenerateSalaryModal';
import ActivityLogsList from '../../Wired/ActivityLogList';
import SalaryStructureConfirmationModal from './Components/SalaryStructureConfirmationModal';
import vars from '../../theme/vars';
import ChangePasswordModal from './Components/ChangePasswordModal';
import { countUri } from '../../Queries/countUri';
import GenerateSalaryStatementModal from '../../Wired/DownloadSalaryStatementModal';
import GenerateIncomeTaxModal from '../../Wired/GenerateIncomeTax';
import PromotionModal from '../../Wired/Promotion';

const headerActionsForAddForm = [cancelButton, save];

export default {
  [ROUTES.addEmployee.name]: {
    screen: AddEmployee,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.generateSalary.name]: {
    screen: GenerateSalaryModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 'auto',
    },
  },
  [ROUTES.generateIncomeTax.name]: {
    screen: GenerateIncomeTaxModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 500,
    },
  },
  [ROUTES.promotionModal.name]: {
    screen: PromotionModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 'auto',
    },
  },
  [ROUTES.EmployeeList.name]: {
    screen: EmployeeList,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
      selectableActions: (props) => ([
        showSelectedCount(props),
        selectAll,
        clearSelection,
        {
          render: () => (
            <OutlineButton label={'employee.title.exportToExcel'.getLabel()} />
          ),
          file: 'EmployeeManagement',
          type: 'download',
          columns: [
            {
              field: 'firstName',
              header: 'Employee FirstName',
            },
            {
              field: 'lastName',
              header: 'Employee LastName',
            },
            {
              field: 'employeeId',
              header: 'Employee ID',
            },
            {
              field: 'department.name',
              header: 'Department',
            },
            {
              field: 'role.name',
              header: 'Role',
            },
            {
              field: 'employeeType.name',
              header: 'Employee Type',
            },
            {
              field: 'staffType.name',
              header: 'Staff Type',
            },
            {
              field: {
                field: 'isActive',
                parserFunction: 'formatBooleanValueForExcel',
              },
              header: 'Status',
            },
          ],
          download: () => ({
            uri: {
              model: MODELS.EMPLOYEE,
              query: {
                id: 'employeeListsForTable',
              },
            },
          }),
          confirmModalProps: { autoHide: true, minWidth: 436 },
          confirm: {
            title: 'Export Records!',
            hideCross: false,
            // eslint-disable-next-line no-shadow
            message: (props) => {
              const {
                screenState: { selectedIds },
              } = props;
              return `This action will export ${selectedIds.length} selected records in the excel format.`;
            },
            actions: ({ action }) => [
              { text: 'Cancel' },
              {
                text: 'Export All',
                ...action,
              },
              {
                text: 'Yes',
                ...action,
              },
            ],
          },
        },
        // {
        //   // eslint-disable-next-line no-shadow
        //   render: (props) => (
        //     <WithPermission
        //       access_key={
        //         permissions.departments.views.generateEmployeeSalary
        //           .permissions.generateEmployeeSalary.value
        //       }
        //     >
        //       <OutlineButton {...props} label="Generate Salary" />
        //     </WithPermission>

        //   ),
        //   type: 'link',
        //   link: ({ state }) => ({
        //     view: ROUTES.generateSalary.name,
        //     // modal: true,
        //     // modalProps: {
        //     //   width: 400,
        //     //   height: 400,
        //     // },
        //     params: { selectedIds: state.selectedIds },
        //   }),
        // },
        {
          // eslint-disable-next-line no-shadow
          render: (props) => (
            <WithPermission
              access_key={
                permissions.departments.views.generateEmployeeSalary
                  .permissions.generateEmployeeSalary.value
              }
            >
              <OutlineButton {...props} label="Generate Income Tax" />
            </WithPermission>

          ),
          type: 'link',
          link: ({ state }) => ({
            view: ROUTES.generateIncomeTax.name,
            // modal: true,
            // modalProps: {
            //   width: 400,
            //   height: 400,
            // },
            params: { selectedIds: state.selectedIds },
          }),
        },
        {
          // eslint-disable-next-line no-shadow
          render: (props) => (
            <WithPermission
              access_key={
                permissions.departments.views.generateEmployeeSalary
                  .permissions.generateEmployeeSalary.value
              }
            >
              <OutlineButton {...props} label="Promote" />
            </WithPermission>

          ),
          type: 'link',
          link: ({ state }) => ({
            view: ROUTES.promotionModal.name,
            // modal: true,
            // modalProps: {
            //   width: 400,
            //   height: 400,
            // },
            params: { selectedIds: state.selectedIds },
          }),
        },
      ]),
    },
    header: {
      title: ({ selectionCount, ...props }) => (
        <HeaderTitleWithDataFetchCount
          {...props}
          countUri={countUri({
            model: MODELS.EMPLOYEE,
            id: 'getEmployeeDetails',
          })}
          selectionCount={selectionCount}
          label={'employee.title.employeeManagement'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['firstName', 'lastName', 'employeeId'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Department',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select departments',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
              searching: true,
              multiSelect: true,
            },
            {
              type: 'autoSuggest',
              field: 'employeeType',
              label: 'Employee Type',
              model: MODELS.EMPLOYEETYPE,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select employee type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'staffType',
              label: 'Staff Type',
              model: MODELS.STAFFTYPE,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select staff type',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
            // {
            //   type: 'regex',
            //   field: 'firstName',
            //   label: 'First Name',
            // },
            {
              type: 'autoSuggestOptions',
              options: epfFilter,
              field: 'isEPF',
              label: 'Filter by EPF',
              multiSelect: false,
            },
            {
              type: 'autoSuggestOptions',
              options: npsFilter,
              field: 'isNPS',
              label: 'Filter by NPS',
              multiSelect: false,
            },

          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.mdm.views.importEmployee.permissions.importEmployee.value
            }
          >
            <OutlineButton label={'employee.buttons.bulkUpload'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.bulkUploadModal.name,
          modal: true,
          params: {
            fileName: 'Employee_Template.xlsx',
          },
          modalProps: {
            autoHide: true,
            width: 404,
            height: 550,
          },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.mdm.views.addEmployee.permissions.addEmployee.value
            }
          >
            <StandardButton label={'employee.buttons.addEmployee'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addEmployee.name,
          push: true,
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.departments.views.importEmployeeSalaryComponents
                .permissions.importEmployeeSalaryComponents.value
            }
          >
            <StandardButton
              label="Import Salary Components"
            />
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.importSalaryComponents.name,
          push: true,
          params: {
            fileName: 'importSalaryComponentsTemplate.xlsx',
          },
        }),
      },
    ],
  },
  [ROUTES.importSalaryComponents.name]: {
    screen: ImportSalaryComponents,
    modal: true,
    modalProps: {
      width: 400,
      height: 800,
    },
  },
  [ROUTES.editEmployee.name]: {
    screen: EditEmployeeTabs,
    expanded: true,
  },
  [ROUTES.viewEmployee.name]: {
    screen: ViewEmployeeTabs,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.firstName}
          {' '}
          {item?.lastName}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
            permissions.mdm.views.modifyEmployee.permissions.modifyEmployee.value
          }
          >
            <OutlineButton label="View Activity Logs" />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.employeeActivityLogs.name,
          push: true,
          ...props,
          params: {
            data: {
              ...props?.navigation?.state?.params.item,
              editable: true,
            },
            addOnFilter: { id: props?.navigation?.state?.params.item?._id },
            header: {
              title: `Showing activity logs for ${props?.navigation?.state?.params.item?.firstName} ${props?.navigation?.state?.params.item?.lastName}`,
              actions: [
                close,
              ],
            },
          },
        }),
      },
      cancelButton,
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.mdm.views.modifyEmployee.permissions.modifyEmployee.value
            }
          >
            <StandardButton label={'employee.buttons.editEmployee'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editEmployee.name,
          push: true,
          params: { item: { ...props?.navigation?.state?.params.item, editable: true } },
        }),
      },
    ],
  },
  [ROUTES.salaryStatementModal.name]: {
    screen: GenerateSalaryStatementModal,
    expanded: true,
    modal: true,
    modalProps: {
      width: 400,
      height: 650,
    },
  },
  [ROUTES.employeeActivityLogs.name]: {
    screen: ActivityLogsList,
    screenProps: {
      model: MODELS.APP_HISTORY_CHANGES,
      id: 'getHistoryActivity',
    },
    modal: true,
    modalProps: {
      width: 1000,
      height: 600,
      autoHide: true,
    },
  },
  [ROUTES.salaryStructureConfirmation.name]: {
    screen: SalaryStructureConfirmationModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 300,
    },
  },
  [ROUTES.changePassword.name]: {
    screen: ChangePasswordModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 400,
    },
  },
  ...employeeSalaryScreens,
};
