import React from 'react';

import {
  Image,
  Table,
  TouchableOpacity,
  ToggleFilter,
} from '../../app-components';
import { employeeListUri } from '../../Queries/employee';
import { editRecord } from '../../images';
import { MODELS, ROUTES } from '../../Lib/constants';
import { getString } from '../../Lib/helpers';
import WithPermission from '../../Components/WithPermission';
import permissions from '../../Lib/permissions';

const tableItems = {
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'Employee Name',
        type: 'text',
        sortable: 'firstName',
        value: ({ item }) => getString([item?.firstName, item?.lastName]),
      },
      {
        header: 'EmployeeId',
        type: 'text',
        field: 'employeeId',
        sortable: true,
      },
      {
        header: 'Department',
        type: 'text',
        value: ({ item }) => (Array.isArray(item?.department) && getString(item?.department?.map((department) => department.name), ', ')),
      },
      // {
      //   header: 'Designation',
      //   type: 'text',
      //   field: 'designation',
      // },
      {
        header: 'Role',
        type: 'text',
        value: ({ item }) => (Array.isArray(item?.role) && getString(item?.role?.map((role) => role.name), ', ')),
      },
      {
        header: 'Employee Type',
        type: 'text',
        sortable: 'employeeType',
        value: ({ item }) => item?.employeeType?.name || '',
      },
      {
        header: 'Status',
        type: 'status',
        field: 'isActive',
      },
      {
        header: 'Staff type',
        type: 'text',
        value: ({ item }) => item?.staffType?.name || '',
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <WithPermission
            access_key={
              permissions.mdm.views.modifyEmployee.permissions.modifyEmployee.value
            }
          >
            <TouchableOpacity>
              <Image
                style={{
                  height: 24,
                  width: 24,
                }}
                source={editRecord}
              />
            </TouchableOpacity>
          </WithPermission>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.editEmployee.name,
            params: { item: { ...item, editable: true } },
          },
        }),
      },
      {
        type: 'moreActions',
        width: 15,
        actions: [
          {
            type: 'link',
            text: 'Reset Password',
            link: ({ item }) => ({
              view: ROUTES.changePassword.name,
              params: { item },
            }),
          },
        ],
      },
    ],
  },
};

export default Table({
  header: {
    leftActions: [(props) => (
      <ToggleFilter
        {...props}
        model={MODELS.EMPLOYEE}
        id="employeeListsForTable"
      />
    )],
  },
  reloadEvent: `reload${MODELS.EMPLOYEE}`,
  uri: employeeListUri,
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewEmployee.name,
      params: { item: { ...item, editable: false } },
    },
  }),
});
