import React from 'react';

import {
  StandardButton,
  Text,
} from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants';

const tableItemsBloodSugar = [
  {
    header: 'S.No.'.getLabel(),
    type: 'number',
    render: (item) => <Text>{item.index + 1}</Text>,
  },
  {
    header: 'Time'.getLabel(),
    type: 'date',
    format: 'HH:mm A',
    field: '_createdOn',
  },
  {
    header: 'Nurse Name',
    type: 'text',
    field: 'nurseName',
  },
  {
    header: 'RBS',
    type: 'text',
    field: 'rbs',
  },
  {
    header: 'Dose of Insulin',
    type: 'text',
    field: 'doseOfInsulin',
  },
  {
    header: 'Advice By',
    type: 'text',
    field: 'adviceBy',
  },
];

// const BloodSugarDetails = () => (
//   <Table
//     lg={{ columns: tableItems }}
//     reloadEvent={`reload${MODELS.VITALS_BLOOD_SUGAR}`}
//     uri={(item) => ({
//       query: {
//         id: 'IPDVitalsList',
//         addOnFilter: {
//           admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
//         },
//       },
//       model: MODELS.VITALS_BLOOD_SUGAR,
//     })}
//     header={() => ({
//       actions: [
//         {
//           type: 'link',
//           link: ({ navigation: { state: { params = {} } } }) => ({
//             view: ROUTES.addBloodSugar.name,
//             params: { ...params },
//             push: true,
//             modal: true,
//             modalProps: {
//               autoHide: true,
//               maxHeight: '100%',
//               width: 1196,
//               height: 547,
//             },
//           }),
//           render: () => (
//             <StandardButton
//               label="Log Blood Sugar"
//             />
//           ),
//         },
//       ],
//     })}
//     action={{
//       type: 'link',
//       link: (data) => ({
//         view: ROUTES.ViewBloodSugarVitals.name,
//         modal: true,
//         params: {
//           addOnFilter: {
//             _id: data.item._id,
//           },
//         },
//         modalProps: {
//           autoHide: true,
//           maxHeight: '100%',
//           width: 1196,
//           height: 547,
//         },

//       }),
//     }}
//   />
// );
export default tableItemsBloodSugar;

