// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

export const MODELS = {
  DEPARTMENTS: 'departments',
  CITY: 'cities',
  ROOM: 'rooms',
  ROLE: 'roles',
  STATE: 'states',
  ZIPCODE: 'zipCodes',
  EMPLOYEETYPE: 'employeeType',
  WARDS: 'specialities',
  BUYERS: 'auction_buyers',
  USER: 'User',
  CONFIGURATION: 'configurations',
  PATIENTS: 'patients',
  APPOINTMENTS: 'appointments',
  APP_HISTORY_CHANGES: 'app_history_changes',
  DESIGNATION: 'designations',
  EMPLOYEE: 'employees',
  STAFFTYPE: 'staff_type',
  CONSULTATION: 'consultations',
  SESSION: 'sessions',
  SESSION_QUEUE: 'session_queues',
  HOLIDAYCALENDAR: 'holidays_calendar',
  HOLIDAYS: 'holidays',
  LAB_BILLING: 'lab_billing',
  EMPLOYEE_SCHEDULE: 'schedules',
  TAX_DECLARATION_SECTIONS: 'tax_declaration_sections',
  SAVING_HEAD: 'saving_heads',
  TAX_SLABS: 'tax_slabs',
  PHARMACY_CATEGORY: 'category',
  FINANCIAL_YEAR: 'financial_years',
  YEARS: 'years',
  LAB_TEST: 'lab_tests',
  LAB_TYPE: 'lab_types',
  PHARMACY_SUBCATEGORY: 'sub_category',
  PHARMACY_SUPPLIER: 'suppliers',
  PHARMACY_MANUFACTURER: 'manufacturers',
  PHARMACY_ITEM: 'pharmacy_items',
  PHARMACY_BATCH: 'pharmacy_batches',
  PHARMACY_STOCK: 'pharmacy_stocks',
  PHARMACY_INDENT: 'pharmacy_indents',
  PHARMACY_PURCHASE: 'pharmacy_purchases',
  PHARMACY_DEPARTMENT_STOCKS: 'pharmacy_department_stocks',
  PHARMACY_TRANSFER_REQUESTS: 'pharmacy_transfer_requests',
  PHARMACY_BATCH_REQUESTS: 'pharmacy_batch_update_requests',
  MONTHS: 'months',
  years: 'years',
  LEAVE_TYPE: 'leaveTypes',
  ATTENDANCE_TYPES: 'attendance_types',
  LEAVE_TYPES: 'leaveTypes',
  ATTENDANCE: 'attendance',
  LEAVE_POLICY_TYPE: 'leavePolicyTypes',
  COMPANY_MANAGEMENT: 'company',
  BLOOD_DONOR: 'blood_donor',
  BLOOD_DONATION: 'blood_donation',
  BLOOD_BANK: 'blood_donation_by_category',
  BLOOD_CATEGORY: 'blood_bank_inventory',
  BLOOD_REQUEST: 'blood_request',
  BLOOD_ISSUE_UPDATION_REQUEST: 'blood_issue_updation_request',
  TEST_COMPONENT_TYPE: 'test_component',
  REQUESTS: 'Request',
  SHIFT: 'shifts',
  EMPLOYEE_SALARY_COMPONENT: 'EmployeeSalaryComponent',
  MONTHLY_ATTENDANCE: 'MonthlyAttendance',
  PURCHASE_DEPARTMENT_TC: 'purchase_department_TC',
  PURCHASE_REQUEST: 'purchase_request_entity',
  PURCHASE_SUPPLY_ORDER: 'purchase_supply_order',
  PURCHASE_AND_SUPPLY_ORDER_QUEUE: 'purchase_supply_order_approval_queue',
  PHARMACY_BILLING: 'pharmacy_billing',
  ACCOUNT: 'Account',
  PHARMACY_FREQUENTLY_ITEM_SUGGESTION: 'pharmacy_frequently_suggested_items',
  ASSET_ITEM: 'asset_items',
  ASSET_PURCHASE: 'asset_purchases',
  ASSET_STOCK: 'asset_stocks',
  ASSET_BATCH: 'asset_batches',
  ASSET_MONTHLY_REMARKS: 'asset_monthly_remarks',
  ASSET_TRANSFER_REQUESTS: 'asset_transfer_requests',
  ASSET_INDENT: 'asset_indents',
  ASSET: 'asset',
  ASSET_DEPARTMENT_STOCKS: 'asset_department_stocks',
  BANKS: 'banks',
  PAYMENT: 'Payment',
  PAYMENT_TYPES: 'PaymentType',
  COMPONENT_CALCULATION_BASE: 'ComponentBaseCal',
  PAY_TO: 'PayTo',
  ASSETS_REPAIR: 'asset_repairs',
  ASSETS_PURCHASE: 'asset_purchases',
  ASSET_LOANED_OUT_HISTORY: 'asset_loaned_out_history',
  SALARY_COMPONENTS: 'SalaryComponent',
  TaxDeclarationSections: 'tax_declaration_sections',
  VACCINATION_SESSION: 'vaccination_session',
  VACCINATION: 'vaccination',
  BOQ_MODEL: 'boq_data',
  ATTENDANCE_REQUESTS: 'attendance_requests',
  PATIENT_VACCINATION_HISTORY: 'patient_vaccination_history',
  IPD_ADMISSION: 'ipd_admissions',
  AUCTION_ITEMS: 'auction_items',
  AUCTION_BUYER: 'auction_buyers',
  CONTINGENT_VOUCHER: 'contingent_vouchers',
  ROOM_CATEGORY: 'room_categories',
  MISCELLANEOUS_ACCOUNT: 'miscellaneous_amounts',
  IPD_PROGRESS_REPORT: 'ipd_patient_progress_report',
  IPD_TREATMENT_PROGRESS_REPORT: 'ipd_patient_progress_treatment',
  IPD_REFERENCE: 'ipd_references',
  IPD_ORDERS: 'ipd_patient_orders',
  OPERATION_PROCEDURE_NOTES: 'operation_procedure_notes',
  ANESTHESIA_REPORT: 'anesthesia_report',
  IPD_OUTCOME: 'ipd_outcomes',
  IPD_VITALS_SIGN_SHEET: 'ipd_patient_vital_sign_sheet',
  IPD_TEMPERATURE_PULSE_TIME_CHART: 'temperature_pulse_time_charts',
  IPD_HISTORY: 'ipd_patient_history',
  OPERATIONS: 'operations',
  IPD_PSYCHOLOGICAL_TREATMENT: 'ipd_pschological_treatments',
  IPD_MLC_SAMPLES: 'ipd_mlc_samples',
  IPD_ECT_NOTES: 'ipd_ect_notes',
  IPD_SUMMARY: 'ipd_summaries',
  IPD_BIRTH_FORM: 'ipd_birth_forms',
  IPD_OT_LIST: 'ipd_OTLists',
  IPD_OPERATIVE_NOTES: 'ipd_operative_notes',
  PRE_ANESTHETIC_CHECKUP: 'pre_anesthetic_checkup',
  BABY_MOTHER_INFORMATION: 'baby_and_mother_information',
  IPD_EYE_INVESTIGATIONS: 'ipd_eye_investigations',
  IPD_ENT_INVESTIGATIONS: 'ipd_ent_investigations',
  IPD_DAILY_EXAMINATION_NOTES: 'ipd_daily_examination_notes',
  PRE_OPERATIVE_CHECKLIST: 'pre_operative_checklist',
  MONITORING_CHART: 'monitoring_chart_icu',
  INPUT_OUTPUT_CHART: 'input_output_chart',
  IPD_ICU_ORDER: 'ipd_icu_orders',
  Monitoring_Chart_Anesthesia: 'monitoring_chart',
  OPD_SUMMARY: 'opd_summary',
  EMPLOYEE_MONTHLY_SALARY_REMARK: 'employee_monthly_salary_remark',
  HOUSE_DETAILS: 'house',
  HOUSE_ALLOTMENT: 'house_allotment',
  ELECTRICITY_BILL: 'electricity_bill',
  EMPLOYEE_FORM: 'employee_form',
  FORM_BULK_UPLOAD: 'form_bulk_upload',
  PAYMENT_DONE: 'monthly_attendance_payment_done',
  BED: 'bed',
  EMPLOYEE_CATEGORY: 'employee_category',
  GRADE_PAY_LEVEL: 'gradePayLevel',
  ACP_PAY_MATRIX: 'acpPayMatrix',
  PAY_MATRIX: 'payMatrix',
  ACP_PAY_SCHEDULE: 'acpPaySchedule',
  PRAN_PERCENTAGE: 'pran_percentage',
  EX_GRATIA_SOCIETY_SALARY: 'ex_gratia_society_salary',
  DISEASE: 'disease',
};

export const ROUTES = {
  Welcome: {
    name: 'home',
    title: 'home',
  },
  addUser: {
    name: '(MDM) Add User',
    title: 'Add User',
  },
  UsersList: {
    name: '(MDM) View Users',
    title: 'View Users',
  },
  EmployeeTypesList: {
    name: '(MDM) View Employee Types',
    title: 'View Employee Types',
  },
  EmployeeDesignation: {
    name: '(MDM)  Employee Designation',
    title: ' Employee Designation',
  },
  AddEmployeeDesignation: {
    name: '(MDM) Add Employee Designation',
    title: 'Add Employee Designation',
  },
  EditEmployeeDesignation: {
    name: '(MDM) Edt Employee Designation',
    title: 'Edt Employee Designation',
  },
  ViewEmployeeDesignation: {
    name: '(MDM) View Employee Designation',
    title: 'View Employee Designation',
  },
  EmployeeCategory: {
    name: '(MDM)  Employee Category',
    title: ' Employee Category',
  },
  ViewEmployeeCategory: {
    name: '(MDM) View Employee Category',
    title: 'View Employee Category',
  },
  AddEmployeeCategory: {
    name: '(MDM) Add Employee Category',
    title: 'Add Employee Category',
  },
  EditEmployeeCategory: {
    name: '(MDM) Edit Employee Category',
    title: 'Edit Employee Category',
  },
  changePassword: {
    name: '(MDM) Change Password',
    title: 'Change Password',
  },
  RolesList: {
    name: '(MDM) View Roles',
    title: 'View Roles',
  },
  DepartmentsList: {
    name: '(MDM) View Departments',
    title: 'View Departments',
  },
  PatientListOPD: {
    name: 'opd View Patients',
    title: 'View Patients',
  },
  viewDepartment: {
    name: '(MDM) View Department',
    title: 'View Department',
  },
  RoomsList: {
    name: '(MDM) View Rooms',
    title: 'View Rooms',
  },
  WardsList: {
    name: '(MDM) View Specialities',
    title: 'View Specialities',
  },
  addDepartment: {
    name: '(MDM) Add Department',
    title: 'Add Department',
  },
  editDepartment: {
    name: '(MDM) Edit Department',
    title: 'Edit Department',
  },
  addRole: {
    name: '(MDM) Add Role',
    title: 'Add Role',
  },
  editRole: {
    name: '(MDM) Edit Role',
    title: 'Edit Role',
  },
  addZipCode: {
    name: '(MDM) Add Zip Code',
    title: 'Add Zip Code',
  },
  editZipCode: {
    name: '(MDM) Edit Zip Code',
    title: 'Edit Zip Code',
  },
  viewUserListModal: {
    name: '(MDM) View User List Modal',
    title: 'View User List Modal',
  },
  viewRoomsListModal: {
    name: '(MDM) View Rooms List Modal',
    title: 'View Rooms List Modal',
  },
  viewWardsListModal: {
    name: '(MDM) View Specialities List Modal',
    title: 'View Specialities List Modal',
  },
  bulkUploadModal: {
    name: '(MDM) Bulk Upload Modal',
    title: 'Bulk Upload Modal',
  },
  bulkAssetUploadModal: {
    name: '(MDM) Bulk Upload Asset Modal',
    title: 'Bulk Upload Asset Modal',
  },
  bulkConsumablesUploadModal: {
    name: '(MDM) Bulk Upload Asset Modal',
    title: 'Bulk Upload Asset Modal',
  },
  bulkPharmacyUploadModal: {
    name: '(MDM) Bulk Upload Pharmacy Modal',
    title: 'Bulk Upload Pharmacy Modal',
  },
  uploadZipCode: {
    name: '(MDM) Upload Zip Code',
    title: 'Upload Zip Code',
  },
  addRoom: {
    name: '(MDM) Add Room',
    title: 'Add Room',
  },
  editRoom: {
    name: '(MDM) Edit Room',
    title: 'Edit Room',
  },
  addWard: {
    name: '(MDM) Add Speciality',
    title: 'Add Speciality',
  },
  editWard: {
    name: '(MDM) Edit Speciality',
    title: 'Edit Speciality',
  },
  viewRoom: {
    name: '(MDM) View Room',
    title: 'View Room',
  },
  addBed: {
    name: 'Add Bed',
    title: 'Add Bed',
  },
  editBed: {
    name: 'Edit Bed',
    title: 'Edit Bed',
  },
  viewBed: {
    name: 'View Bed',
    title: 'View Bed',
  },
  bedList: {
    name: 'Bed List',
    title: 'Bed List',
  },
  roomCategories: {
    name: '(MDM) Room category',
    title: 'Room Category',
  },
  dischargeCardSummary: {
    name: '(IPD) Fill Discharge Card Summary',
    title: 'Discharge Card',
  },
  addOperativeCheckList: {
    name: 'Add Operative CheckList',
    title: 'Add Operative CheckList',
  },
  viewOperativeCheckList: {
    name: 'View Operative CheckList',
    title: 'View Operative CheckList',
  },
  deathCardSummary: {
    name: '(IPD) Fill Death Card Summary',
    title: 'Death Card',
  },
  dischargeCardGeneralMedicine: {
    name: '(IPD) General Medicine Fill Discharge Card',
    title: 'Discharge Card',
  },
  deathCardGeneralMedicine: {
    name: '(IPD) General Medicine Fill Death Card',
    title: 'Death Card',
  },
  dischargeCard: {
    name: '(IPD) Fill Discharge Card',
    title: 'Discharge Card',
  },
  deathCard: {
    name: '(IPD) Fill Death Card',
    title: 'Death Card',
  },
  sendForApprovalOTList: {
    name: '(IPD) Send For Approval',
    title: 'Send For Approval',
  },
  addPatientToOTList: {
    name: '(IPD) Add Patient ',
    title: 'Add Patient',
  },
  viewOTListScreen: {
    name: '(IPD) View OT List Screen',
    title: 'View OT List Screen',
  },
  createNewOTList: {
    name: '(IPD) Create New List',
    title: 'Create New List',
  },
  viewOTList: {
    name: '(IPD) View OT List Details',
    title: 'View OT List Details',
  },
  logReferencesGeneralMedicine: {
    name: '(IPD) General Medicine Log References',
    title: 'Log Reference',
  },
  takeReferencesGeneralMedicine: {
    name: '(IPD) General Medicine Take References',
    title: 'Take Reference',
  },
  viewReferenceGeneralMedicine: {
    name: '(IPD) General Medicine View Logged Reference',
    title: 'View Logged Reference',
  },
  logReferences: {
    name: '(IPD) Log References',
    title: 'Log Reference',
  },
  takeReferences: {
    name: '(IPD) Take References',
    title: 'Take Reference',
  },
  viewReference: {
    name: '(IPD) View Logged Reference',
    title: 'View Logged Reference',
  },
  addRoomCategory: {
    name: 'Add Room Category',
    title: 'Add Room Category',
  },
  addProcedureNoteGeneralMedicineIPD: {
    name: '(IPD) General Medicine Operation Procedure Notes Modal',
    title: 'Add New Procedure Notes',
  },
  addProcedureNoteIPD: {
    name: '(IPD) Operation Procedure Notes Modal',
    title: 'Add New Procedure Notes',
  },
  editProcedureNoteIPD: {
    name: 'Edit Operation Procedure Notes Modal',
    title: 'Edit New Procedure Notes',
  },
  editRoomCategory: {
    name: 'Edit Room Category',
    title: 'Edit Room Category',
  },
  viewWard: {
    name: '(MDM) View Speciality',
    title: 'View Speciality',
  },
  addService: {
    name: '(IPD) Add Service',
    title: 'Add Service',
  },
  editService: {
    name: '(IPD) Edit Service',
    title: 'Edit Service',
  },
  viewService: {
    name: '(IPD) View Service',
    title: 'View Service',
  },
  uploadMLCSample: {
    name: '(IPD) Upload MLC Sample',
    title: 'Upload MLC Sample',
  },
  fillBirthForm: {
    name: '(IPD) Fill Birth Form',
    title: 'Fill Birth Form',
  },
  viewBirthForm: {
    name: '(IPD) View Birth Form',
    title: 'View Birth Form',
  },
  editBirthForm: {
    name: '(IPD) Edit Birth Form',
    title: 'Edit Birth Form',
  },
  logOperativeNotes: {
    name: '(IPD) Log Operative Notes',
    title: 'Log Operative Notes',
  },
  viewOperativeNotes: {
    name: '(IPD) View Operative Notes',
    title: 'View Operative Notes',
  },
  ResetPassword: {
    name: 'Reset Password',
  },
  viewRole: {
    name: '(MDM) View Role',
    title: 'View Role',
  },
  addEmployeeType: {
    name: '(MDM) Add Employee Type',
    title: 'Add Employee Type',
  },
  salaryStructureConfirmation: {
    name: '(MDM) Salary Structure Confirmation',
    title: 'Salary Structure Confirmation',
  },
  viewEmployeeType: {
    name: '(MDM) View Employee Type',
    title: 'View Employee Type',
  },
  editEmployeeType: {
    name: '(MDM) Edit Employee Type',
    title: 'Edit Employee Type',
  },
  mdm: {
    name: '(MDM)',
  },
  ipd: {
    name: 'IPD',
  },
  manageWards: {
    name: '(MDM) Manage Specialities',
    title: 'Manage Specialities',
  },
  analytics: {
    name: 'Analytics',
  },
  userManagementAll: {
    name: '(MDM) User Management',
    title: 'User Management',
  },
  employeeManagementAll: {
    name: '(MDM) Employee Management',
    title: 'Employee Management',
  },
  rolesManagementAll: {
    name: '(MDM) Roles Management',
    title: 'Roles Management',
  },
  addAssetServiceReturnDateModal: {
    name: 'Record return date',
    title: 'Record return date',
  },
  departmentManagementAll: {
    name: '(MDM) Department Management',
    title: 'Department Management',
  },
  appointmentsList: {
    name: 'departments Appointments',
    title: 'Appointments',
  },
  videoAppointment: {
    name: 'departments Video Appointment',
    title: 'Video Appointments',
  },
  exportModal: {
    name: '(MDM) Export Modal',
    title: 'Export Modal',
  },
  EmployeeList: {
    name: '(MDM) View Employees',
    title: 'View Employees',
  },
  addEmployee: {
    name: '(MDM) Add Employee',
    title: 'Add Employee',
  },
  generateSalary: {
    name: '(MDM) Generate Salary',
    title: 'Generate Salary',
  },
  generateIncomeTax: {
    name: '(MDM) Generate Income Tax',
    title: 'Generate Income Tax',
  },
  promotionModal: {
    name: '(MDM) Promote Employee',
    title: 'Promote Employee',
  },
  editEmployee: {
    name: '(MDM) Edit Employee',
    title: 'Edit Employee',
  },
  importSalaryComponents: {
    name: '(MDM) Import Salary Components',
    title: 'Import Salary Components',
  },
  viewEmployee: {
    name: '(MDM) View Employee',
    title: 'View Employee',
  },
  employeeActivityLogs: {
    name: '(MDM) View Employee Activity Log',
    title: 'View Employee Activity Logs',
  },
  addPatient: {
    name: '(MDM) Add Patient',
    title: 'Add Patient',
  },
  addAppointment: {
    name: '(MDM) Add Appointment',
    title: 'Add Appointment',
  },
  viewPatientOPD: {
    name: 'departments View Patient',
    title: 'View Patient',
  },
  editPatient: {
    name: '(MDM) Edit Patient',
    title: 'Edit Patient',
  },
  PatientRegistrations: {
    name: 'departments Patient Registrations',
    title: 'Patient Registrations',
  },
  existingPatientModal: {
    name: 'departments Existing Patient Modal',
    title: 'Existing Patient Modal',
  },
  patientTokenGeneration: {
    name: 'departments Patient Token Generation',
    title: 'Patient Token Generation',
  },
  viewActiveSession: {
    name: 'departments OPD View Active session',
    title: 'View Active session',
  },
  addConsultation: {
    name: 'departments Add Consultation',
    title: 'Add Consultation',
  },
  opd: {
    name: 'opd',
  },
  vaccination: {
    name: 'vaccination',
  },
  departments: {
    name: 'departments',
  },
  opdAll: {
    name: 'opd All',
  },
  opdIncomingPatients: {
    name: 'departments Incoming Patients',
    title: 'Incoming Patients',
  },
  opdIncomingPatientsAddDiagnosis: {
    name: 'departments Incoming Patients Add Diagnosis',
    title: 'Incoming Patients Add Diagnosis',
  },
  opdIncomingPatientsViewDiagnosis: {
    name: 'departments Incoming Patients View Diagnosis',
    title: 'Incoming Patients View Diagnosis',
  },
  opdIncomingPatientDetails: {
    name: 'departments Incoming Patient Details',
    title: 'Incoming Patient Details',
  },
  opdConsultedPatientList: {
    name: 'departments Consulted Patient List',
    title: 'Consulted Patient List',
  },
  opdLiveConsultation: {
    name: 'departments Live Consultation',
    title: 'Live Consultation',
  },
  opdReports: {
    name: 'departments OPD Reports',
    title: 'OPD Reports',
  },
  viewOpdConsultedPatientHistory: {
    name: 'Patient Consultation Details',
    title: 'Consulted Patient Details',
  },
  doctorConsultationList: {
    name: 'departments Consultation List',
    title: 'Consultation List',
  },
  doctorVaccinationList: {
    name: 'departments Vaccination List',
    title: 'Vaccination List',
  },
  editDoctorModal: {
    name: 'departments Edit Doctor Modal',
    title: 'Edit Doctor Modal',
  },
  viewActiveQueue: {
    name: 'departments View Active Queue',
    title: 'View Active Queue',
  },
  endSessionModal: {
    name: 'departments End Session Modal',
    title: 'End Session Modal',
  },
  newLabBill: {
    name: 'New Lab Bill',
    title: 'new Lab Bill',
  },
  newPharmacyBill: {
    name: 'New Pharmacy Bill',
    title: 'new Pharmacy Bill',
  },
  viewPharmacyBill: {
    name: 'View Pharmacy Billing Records',
    title: 'View Pharmacy Billing Records',
  },
  patientListMDM: {
    name: '(MDM) View Patients',
    title: 'View Patients',
  },
  ViewPatientMDM: {
    name: '(MDM) View Patient',
    title: 'View Patient',
  },
  viewVaccinationLogPatientManagement: {
    name: '(MDM) View Patient Vaccination Log ',
    title: 'View Patient Vaccination Log',
  },
  labManagementAll: {
    name: '(MDM) Lab Management',
    title: 'lab_management_view_access',
  },
  bloodGroupManagementAll: {
    name: '(Inventory) Blood Group Management All',
    title: 'blood_group_management_all',
  },
  companyManagementList: {
    name: '(Inventory) View Company',
    title: 'View Company',
  },
  addCompany: {
    name: '(Inventory) Add Company',
    title: 'Add Company',
  },
  viewCompany: {
    name: '(Inventory) View Company Details',
    title: 'View Company',
  },
  editCompany: {
    name: '(Inventory) Edit Company Details',
    title: 'Edit Company',
  },
  viewDonorList: {
    name: '(Inventory) View Donor Details',
    title: 'View Donor',
  },
  bloodBankInventoryList: {
    name: '(Inventory) Blood Bank Inventory',
    title: 'Blood Bank (Inventory Management)',
  },
  bloodBankRecords: {
    name: '(Inventory) Records',
    title: 'Records',
  },
  bloodBankRequest: {
    name: '(Inventory) Requests',
    title: 'Requests',
  },
  bloodBankMSRequest: {
    name: '(Inventory) MS Requests',
    title: 'MS Requests',
  },
  raiseBloodRequest: {
    name: '(Inventory) Raise Blood Request',
    title: 'Raise Blood Request',
  },
  issueBloodRequestMSModal: {
    name: '(Inventory) Issue Blood Request MS',
    title: 'Issue Blood Request MS',
  },
  issueToCompanyModal: {
    name: '(Inventory) Issue To Company',
    title: 'Issue To Company',
  },
  addDonor: {
    name: '(Inventory) Add Donor',
    title: 'Add Donor',
  },
  viewDonor: {
    name: '(Inventory) View Donor',
    title: 'View Donor',
  },
  addBloodDonation: {
    name: '(Inventory) Add Blood Donation',
    title: 'Add Donation',
  },
  editDonor: {
    name: '(Inventory) Edit Donor',
    title: 'Edit Donor',
  },
  salaryAll: {
    name: '(MDM) Salary All',
    title: 'Salary All',
  },
  salaryComponentList: {
    name: '(MDM) Salary Components',
    title: 'Salary Components',
  },
  packageReview: {
    name: 'Review Package',
    title: 'Review Package',
  },
  reviewedPackageForm: {
    name: 'Review Package Form',
    title: 'Review Package Form',
  },
  acpReviewPackageForm: {
    name: 'ACP Review Package Form',
    title: 'ACP Review Package Form',
  },
  salaryCompensationList: {
    name: '(MDM) Salary Structure Template List',
    title: 'Salary Structure Template List',
  },
  addSalaryCompensationStructure: {
    name: '(MDM) Add Salary Structure Template',
    title: 'Add Salary Structure Template',
  },
  editSalaryCompensationStructure: {
    name: '(MDM) Edit Salary Structure Template',
    title: 'Edit Salary Structure Template',
  },
  viewSalaryCompensationStructure: {
    name: '(MDM) View Salary Structure Template',
    title: 'View Salary Structure Template',
  },
  salarySetUp: {
    name: '(MDM) Salary Set Up',
    title: 'Salary Set Up',
  },
  addSavingHead: {
    name: '(MDM) Add Saving Head',
    title: 'Add Saving Head',
  },
  editSavingHead: {
    name: '(MDM) Edit Saving Head',
    title: 'Edit Saving Head',
  },
  basicPayMatrixes: {
    name: '(MDM) Pay Matrixes',
    title: 'Pay Matrixes',
  },
  addPayMatrixes: {
    name: '(MDM) Add Pay Matrixes',
    title: 'Add Pay Matrixes',
  },
  viewPayMatrixes: {
    name: '(MDM) View Pay Matrixes',
    title: 'View Pay Matrixes',
  },
  editPayMatrixes: {
    name: '(MDM) Edit Pay Matrixes',
    title: 'Edit Pay Matrixes',
  },
  addPaySchedule: {
    name: '(MDM) Add Pay Schedule',
    title: 'Add Pay Schedule',
  },
  viewPaySchedule: {
    name: '(MDM) View Pay Schedule',
    title: 'View Pay Schedule',
  },
  editPaySchedule: {
    name: '(MDM) Edit Pay Schedule',
    title: 'Edit Pay Schedule',
  },
  addGradePayLevel: {
    name: '(MDM) Add Grade Pay Level',
    title: 'Add Grade Pay Level',
  },
  viewGradePayLevel: {
    name: '(MDM) View Grade Pay Level',
    title: 'View Grade Pay Level',
  },
  editGradePayLevel: {
    name: '(MDM) Edit Grade Pay Level',
    title: 'Edit Grade Pay Level',
  },
  addAcpMatrixes: {
    name: '(MDM) Add Acp Matrixes',
    title: 'Add Acp Matrixes',
  },
  viewAcpMatrixes: {
    name: '(MDM) View Acp Matrixes',
    title: 'View Acp Matrixes',
  },
  editAcpMatrixes: {
    name: '(MDM) Edit Acp Matrixes',
    title: 'Edit Acp Matrixes',
  },

  houseList: {
    name: '(MDM) View House List',
    title: 'View House List',
  },
  addHouseDetails: {
    name: '(MDM) Add House Details',
    title: 'Add House Details',
  },
  viewHouseDetails: {
    name: '(MDM) View House Details',
    title: 'View House Details',
  },
  allotHouse: {
    name: '(MDM) Allot House View',
    title: 'Allot House View',
  },
  addElectricityBill: {
    name: '(MDM) Add Electricity Bill House',
    title: 'Add Electricity Bill House',
  },
  editElectricityBill: {
    name: '(MDM) Edit Electricity Bill',
    title: 'Edit Electricity Bill',
  },
  editHouseAllotment: {
    name: '(MDM) Edit House Allotment',
    title: 'Edit House Allotment',
  },
  editHouseDetails: {
    name: '(MDM) Edit House Details',
    title: 'Edit House Details',
  },
  addTaxSlab: {
    name: '(MDM) Add Tax Slab',
    title: 'Add Tax Slab',
  },
  editTaxSlab: {
    name: '(MDM) Edit Tax Slab',
    title: 'Edit Tax Slab',
  },
  viewTaxSlab: {
    name: '(MDM) View Tax Slab',
    title: 'View Tax Slab',
  },
  addSalaryComponent: {
    name: '(MDM) Add Salary Components',
    title: 'Add Salary Component',
  },
  editSalaryComponent: {
    name: '(MDM) Edit Salary Components',
    title: 'Edit Salary Component',
  },
  viewSalaryComponent: {
    name: '(MDM) View Salary Components',
    title: 'View Salary Component',
  },
  accountMaster: {
    name: '(MDM) Account',
    title: 'Account',
  },
  accountMasterList: {
    name: '(MDM) Account List',
    title: 'Account List',
  },
  addAccountMaster: {
    name: '(MDM) Add Account',
    title: 'Add Account',
  },
  editAccountMaster: {
    name: '(MDM) Edit Account',
    title: 'Edit Account',
  },
  viewAccountMaster: {
    name: '(MDM) View Account',
    title: 'View Account',
  },
  LabType: {
    name: '(MDM) View Lab Type',
    title: 'View Lab Type',
  },
  TestType: {
    name: '(MDM) View Test Type',
    title: 'View Test Type',
  },
  addTest: {
    name: '(MDM) Add Test',
    title: 'Add Test Type',
  },
  confirmTestModal: {
    name: 'Confirm Test Modal',
    title: 'Confirm Test Modal',
  },
  editTest: {
    name: '(MDM) Edit Test',
    title: 'Edit Test Type',
  },
  viewTest: {
    name: '(MDM) View Test',
    title: 'View Test Type',
  },
  addLab: {
    name: '(MDM) Add Lab',
    title: 'Add Lab Type',
  },
  editLab: {
    name: '(MDM) Edit Lab',
    title: 'Edit Lab Type',
  },
  viewLab: {
    name: '(MDM) View Lab',
    title: 'View Lab Type',
  },
  TestComponentType: {
    name: '(MDM) Component List',
    title: 'Component Type',
  },
  addComponent: {
    name: '(MDM) Add Component',
    title: 'ADD COMPONENT',
  },
  viewComponent: {
    name: '(MDM) View Component',
    title: 'COMPONENT Details',
  },
  editComponent: {
    name: '(MDM) Edit Component',
    title: 'COMPONENT',
  },
  viewTestLinked: {
    name: '(MDM) View Test Linked Component',
    title: 'View Test Linked',
  },
  viewEmployeeListModal: {
    name: 'view employee list modal',
    title: 'View Employee List Modal',
  },
  viewBloodDonorModal: {
    name: 'view blood donor attachment Modal',
    title: 'view blood donor attachment Modal',
  },
  importList: {
    name: 'Import List',
  },
  editConfiguration: {
    name: '(MDM) config',
    title: 'Config',
  },
  accounts: {
    name: 'departments Accounts',
    title: 'Accounts',
  },
  accountsInfo: {
    name: 'departments Accounts Info',
    title: 'Accounts',
  },
  salaryInfo: {
    name: 'Employee Salary Info',
    title: 'Salary',
  },
  paymentDone: {
    name: 'Payment Done',
    title: 'Payment Done',
  },
  generateSalaryAction: {
    name: 'Generate Salary',
    title: 'Generate Salary',
  },
  generateArrears: {
    name: 'Generate Arrears',
    title: 'Generate Arrears',
  },
  salarySheetModal: {
    name: 'departments Download Salary Sheet',
    title: 'Download Salary Sheet',
  },
  allSalarySheetModal: {
    name: 'departments Download All Salary Sheet',
    title: 'Download All Salary Sheet',
  },
  rejectRequestSavingHead: {
    name: 'Reject Request Saving Head',
    title: 'Reject Request Saving Head',
  },
  approveRequestConfirmationModal: {
    name: 'Approve Request Confirmation modal',
    title: 'Approve Request Confirmation modal',
  },
  rejectRequestConfirmationModal: {
    name: 'Reject Request Confirmation modal',
    title: 'Reject Request Confirmation modal',
  },
  employeeScheduleList: {
    name: '(MDM) Employee Schedule List',
    title: 'Employee Schedule',
  },
  viewEmployeeSchedule: {
    name: '(MDM) View Employee Schedule',
    title: 'View Employee Schedule',
  },
  editEmployeeSchedule: {
    name: '(MDM) Edit Employee Schedule',
    title: 'Edit Employee Schedule',
  },
  addEmployeeSchedule: {
    name: '(MDM) Add Employee Schedule',
    title: 'Add Employee Schedule',
  },
  holidayListCalendar: {
    name: '(MDM) Holiday List Calendar',
    title: 'Holiday List Calendar',
  },
  addHolidayCalendar: {
    name: '(MDM) Add Holiday Calendar',
    title: 'Add Holiday Calendar',
  },
  viewHolidaysCalendar: {
    name: 'View Holiday Calendar',
    title: 'View Holiday Calendar',
  },
  editHolidaysCalendar: {
    name: '(MDM) Edit Holiday Calendar',
    title: 'Edit Holiday Calendar',
  },
  addHoliday: {
    name: 'Add Holiday',
    title: 'Add Holiday',
  },
  editHoliday: {
    name: 'Edit Holiday',
    title: 'Edit Holiday',
  },
  billing: {
    name: 'Billing',
    title: 'Billing',
  },
  auction: {
    name: 'Auction',
    title: 'Auction',
  },
  inventoryManagementAuction: {
    name: 'Auction Inventory Management Auction',
    title: 'Auction Inventory Management',
  },
  buyerManagementAuction: {
    name: 'Auction Buyer Management Auction',
    title: 'Buyer Inventory Management',
  },
  addBuyer: {
    name: 'Auction Add Buyer',
    title: 'Add Buyer',
  },
  viewAuctionItem: {
    name: 'ITEM DETAILS',
    title: 'ITEM DETAILS',
  },
  editBuyer: {
    name: 'Auction Edit Buyer',
    title: 'Edit Buyer',
  },
  editAuctionItemDetails: {
    name: 'Edit Detail',
    title: 'Edit Detail',
  },
  viewBuyer: {
    name: 'Auction View Buyer',
    title: 'View Buyer',
  },
  assignBuyer: {
    name: 'Auction Assign Buyer',
    title: 'Assign Buyer',
  },
  assignBuyerToItem: {
    name: 'Auction Assign Buyer Item',
    title: 'Assign Buyer Item',
  },
  setPrice: {
    name: 'Auction Set Price',
    title: 'Set Price',
  },
  inventory: {
    name: '(Inventory)',
    title: 'Inventory',
  },
  shiftManagementAll: {
    name: '(MDM) Shift Management',
    title: 'Shift Management',
  },
  shiftList: {
    name: '(MDM) Shift List',
    title: 'Shift List',
  },
  addShift: {
    name: 'Add Shift',
    title: 'Add Shift',
  },
  editShift: {
    name: 'Edit Shift',
    title: 'Edit Shift',
  },
  viewShift: {
    name: 'View Shift',
    title: 'View Shift',
  },
  purchaseDepartmentAll: {
    name: '(MDM) Purchase Department',
    title: 'Purchase Department',
  },
  purchaseDepartmentTCList: {
    name: '(MDM) Terms and Conditions Management',
    title: 'Terms and Conditions Management',
  },
  purchaseCategory: {
    name: '(MDM) Purchase Category',
    title: 'Category',
  },
  addPurchaseDepartmentTC: {
    name: 'Add T&C Type',
    title: 'Add T&C Type',
  },
  editPurchaseDepartmentTC: {
    name: 'Edit T&C Type',
    title: 'Edit T&C Type',
  },
  addTechnicalModal: {
    name: 'Add Technical Specification Type',
    title: 'Add Technical Specification Type',
  },
  editTechnicalModal: {
    name: 'Edit Technical Specification Type',
    title: 'Edit Technical Specification Type',
  },
  editTechnicalModalPurchase: {
    name: 'Edit Technical Specification Purchase',
    title: 'Edit Technical Specification Purchase',
  },
  viewPurchaseDepartmentTC: {
    name: 'View T&C Type',
    title: 'View T&C Type',
  },
  PS2Modal: {
    name: 'Ps2 Modal',
    title: 'Ps2 Modal',
  },
  PSandSoReviewModal: {
    name: 'Review modal',
    title: 'Review modal',
  },
  viewLeavePolicyActivityLog: {
    name: 'View Leave Policy Activity Log',
    title: 'View Leave Policy Activity Log',
  },
  profile: {
    name: 'My Profile',
    title: 'My Profile',
  },
  labBill: {
    name: 'Billing Lab Bill',
    title: 'Lab Bill',
  },
  labBillHistory: {
    name: ' Lab Bill History',
    title: 'Lab Bill History',
  },
  viewLabBillHistory: {
    name: ' View Lab Bill History',
    title: 'View Lab Bill History',
  },
  confirmLabBillHistory: {
    name: ' Confirm Lab Bill History',
    title: 'Confirm Lab Bill History',
  },
  confirmPharmacyLabBillHistory: {
    name: ' Confirm Pharmacy Lab Bill History',
    title: 'Confirm Pharmacy Lab Bill History',
  },
  pharmacyBill: {
    name: 'Billing Pharmacy Bill',
    title: 'Pharmacy Bill',
  },
  pharmacyBillingRecords: {
    name: 'Billing Pharmacy Billing Records',
    title: 'Pharmacy Billing Records',
  },
  assetManagementAll: {
    name: '(MDM) Asset Management',
    title: 'assets_management_view_access',
  },
  assetCategory: {
    name: '(MDM) Asset Category',
    title: 'Category',
  },
  addAssetCategory: {
    name: 'Add Asset Category',
    title: 'Add Asset Category',
  },
  editAssetCategory: {
    name: 'Edit Asset Category',
    title: 'Edit Asset Category',
  },
  viewAssetCategory: {
    name: 'View Asset Category',
    title: 'View Asset Category',
  },
  addAssetSubCategory: {
    name: 'Add Asset Sub Category',
    title: 'Add Asset Sub Category',
  },
  editAssetSubCategory: {
    name: 'Edit Asset Sub Category',
    title: 'Edit Asset Sub Category',
  },
  viewAssetSubCategory: {
    name: 'View Asset Sub Category',
    title: 'View Asset Sub Category',
  },
  viewAssetSupplier: {
    name: 'View Asset Supplier',
    title: 'View Asset Supplier',
  },
  addAssetSupplier: {
    name: 'Add Asset Supplier',
    title: 'Add Asset Supplier',
  },
  editAssetSupplier: {
    name: 'Edit Asset Supplier',
    title: 'Edit Asset Supplier',
  },
  viewAssetManufacturer: {
    name: 'View Asset Manufacturer',
    title: 'View Asset Manufacturer',
  },
  addAssetManufacturer: {
    name: 'Add Asset Manufacturer',
    title: 'Add Asset Manufacturer',
  },
  editAssetManufacturer: {
    name: 'Edit Asset Manufacturer',
    title: 'Edit Asset Manufacturer',
  },
  // subCategoryListModal: {
  //   name: 'Sub Category List Modal',
  //   title: 'Sub Category List Modal',
  // },
  assetSupplierAndManufacturer: {
    name: '(MDM) Asset Supplier & Manufacturer',
    title: 'Supplier & Manufacturer',
  },
  pharmacyManagementAll: {
    name: '(MDM) Pharmacy Management',
    title: 'pharmacy_management_view_access',
  },
  pharmacyCategory: {
    name: '(MDM) Pharmacy Category',
    title: 'Category',
  },
  addPharmacyCategory: {
    name: 'Add Category',
    title: 'Add Category',
  },
  editPharmacyCategory: {
    name: 'Edit Category',
    title: 'Edit Category',
  },
  viewPharmacyCategory: {
    name: 'View Category',
    title: 'View Category',
  },
  addPharmacySubCategory: {
    name: 'Add Sub Category',
    title: 'Add Sub Category',
  },
  editPharmacySubCategory: {
    name: 'Edit Sub Category',
    title: 'Edit Sub Category',
  },
  viewPharmacySubCategory: {
    name: 'View Sub Category',
    title: 'View Sub Category',
  },
  subCategoryListModal: {
    name: 'Sub Category List Modal',
    title: 'Sub Category List Modal',
  },
  pharmacySupplierAndManufacturer: {
    name: '(MDM) Pharmacy Supplier & Manufacturer',
    title: 'Supplier & Manufacturer',
  },
  viewPharmacySupplier: {
    name: 'View Supplier',
    title: 'View Supplier',
  },
  addPharmacySupplier: {
    name: 'Add Supplier',
    title: 'Add Supplier',
  },
  editPharmacySupplier: {
    name: 'Edit Supplier',
    title: 'Edit Supplier',
  },
  viewPharmacyManufacturer: {
    name: 'View Manufacturer',
    title: 'View Manufacturer',
  },
  addPharmacyManufacturer: {
    name: 'Add Manufacturer',
    title: 'Add Manufacturer',
  },
  editPharmacyManufacturer: {
    name: 'Edit Manufacturer',
    title: 'Edit Manufacturer',
  },
  pharmacyCentralInventory: {
    name: '(Inventory) Pharmacy Central Inventory',
    title: 'Central Inventory',
  },
  assetConsumablesCentralInventory: {
    name: '(Inventory) Asset Consumables Central Inventory',
    title: 'Central Inventory',
  },
  pharmacyDepartmentInventory: {
    name: '(Inventory) Pharmacy Department Inventory',
    title: 'Department Inventory',
  },
  assetConsumablesDepartmentInventory: {
    name: '(Inventory) Asset Consumables Department Inventory',
    title: 'Department Inventory',
  },
  pharmacyViewDepartmentInventory: {
    name: 'View Department Inventory Item',
    title: 'View Department Item',
  },
  pharmacyCentralRecords: {
    name: '(Inventory) Pharmacy Central Records',
    title: 'Pharmacy Central Records',
  },
  assetConsumablesCentralRecords: {
    name: '(Inventory) Asset Consumables Central Records',
    title: 'Asset Consumables Central Records',
  },
  pharmacyDepartmentRecords: {
    name: '(Inventory) Pharmacy Department Records',
    title: 'Pharmacy Department Records',
  },
  assetConsumablesDepartmentRecords: {
    name: '(Inventory) asset Consumables Department Records',
    title: 'Asset Consumables Department Records',
  },
  pharmacyCentralRequests: {
    name: '(Inventory) Pharmacy Central Requests',
    title: 'Pharmacy Central Requests',
  },
  assetConsumablesCentralRequests: {
    name: '(Inventory) Asset Consumables Central Requests',
    title: 'Pharmacy Central Requests',
  },
  pharmacyDepartmentRequests: {
    name: '(Inventory) Pharmacy Department Requests',
    title: 'Pharmacy Department Requests',
  },
  assetConsumablesDepartmentRequests: {
    name: '(Inventory) Asset Consumables Department Requests',
    title: 'Asset Consumables Department Requests',
  },
  pharmacyMSIIndentRequests: {
    name: '(Inventory) MS Indent Requests',
    title: ' MS Indent Requests',
  },
  assetConsumablesMSIIndentRequests: {
    name: '(Inventory) Asset Consumables Indent Requests',
    title: ' MS Indent Requests',
  },
  addInventoryItem: {
    name: 'Add Inventory item',
    title: 'Add Inventory Item',
  },
  addInventoryStocks: {
    name: 'Add Inventory stocks',
    title: 'Add Inventory Stocks',
  },
  lowShelfLifeModal: {
    name: 'Low Shelf Life Modal',
    title: 'Low Shelf Life Modal',
  },
  editInventoryItem: {
    name: 'Edit Inventory item',
    title: 'Edit Inventory Item',
  },
  viewInventoryItem: {
    name: 'View Inventory item',
    title: 'View Inventory Item',
  },
  pharmacyAndAssetPurchaseRequest: {
    name: '(Inventory) Purchase Request',
    title: 'Purchase Request',
  },
  viewPurchaseRequestPharmacy: {
    name: 'Pharmacy Purchase Request Detail',
    title: 'Pharmacy Purchase Request Detail',
  },
  viewPurchaseRequestAsset: {
    name: 'Asset Purchase Request Detail',
    title: 'Asset Purchase Request Detail',
  },
  viewRepairRequestAsset: {
    name: 'Asset Repair Request Detail',
    title: 'Asset Repair Request Detail',
  },
  pharmacyAndAssetPurchaseOrder: {
    name: '(Inventory) Purchase Order',
    title: 'Purchase Sanction',
  },
  purchaseOrderAndSupplyOrder: {
    name: '(Inventory) Purchase And Supply Order',
    title: 'Purchase And Supply Order',
  },
  purchaseOrderModal: {
    name: '(Inventory) Purchase Sanction Modal',
    title: 'Purchase Sanction Modal',
  },
  purchaseAndSupplyOrderForAssetRepair: {
    name: '(Inventory) Purchase Sanction Modal For Asset Repair',
    title: 'Purchase Sanction Modal',
  },
  assetCreatePurchaseAndSupplyOrderModal: {
    name: '(Inventory) Asset Purchase And Supply Order Modal',
    title: 'Asset Purchase And Supply Order Modal',
  },
  viewPurchaseOrder: {
    name: 'Create Purchase Sanction',
    title: 'Create Purchase  Sanction',
  },
  addPharmacySupplierAndManufacturer: {
    name: 'Add Pharmacy Supplier',
    title: 'Add Pharmacy Supplier',
  },
  createPurchaseAndSupplyOrderForAsset: {
    name: 'Create Purchase And Supply Order For Asset',
    title: 'Create Purchase And Supply Order For Asset',
  },
  createPurchaseAndSupplyOrderForAssetRepair: {
    name: 'Create Purchase And Supply Order For Asset Repair',
    title: 'Create Purchase And Supply Order For Asset',
  },
  createBOQ: {
    name: 'Create BOQ',
    title: 'Create BOQ',
  },
  viewSupplyOrder: {
    name: 'View Pharmacy Supply Order',
    title: 'View Pharmacy Supply  Order',
  },
  viewAssetSupplyOrder: {
    name: 'View Asset Supply Order',
    title: 'View Asset Supply  Order',
  },
  viewPurchaseRequest: {
    name: 'View Purchase  Request',
    title: 'View Purchase Request',
  },
  viewSupplyRequest: {
    name: 'View Supply  Request',
    title: 'View Supply Request',
  },
  purchaseOrderApproveOrReviewModal: {
    name: '(Inventory) Purchase Order Approve-Review Modal',
    title: 'Purchase Sanction Modal',
  },
  purchaseOrderApproveModal: {
    name: '(Inventory) Purchase Order Approve Modal',
    title: 'Purchase Order Approve Modal',
  },
  leaveTypeList: {
    name: '(MDM) Leave Type List',
    title: '(MDM) Leave Type List',
  },
  addLeaveType: {
    name: 'Add Leave Type',
    title: 'Add Leave Type',
  },
  editLeaveType: {
    name: 'Edit Leave Type',
    title: 'Edit Leave Type',
  },
  viewLeaveType: {
    name: 'View Leave Type',
    title: 'View Leave Type',
  },
  leavePolicyTypeList: {
    name: '(MDM) Leave Policy Type',
    title: 'Leave Policy',
  },
  addLeavePolicyType: {
    name: 'Add Leave Policy Type',
    title: 'Add Leave Policy',
  },
  editLeavePolicyType: {
    name: 'Edit Leave Policy Type',
    title: 'Edit Leave Policy',
  },
  viewLeavePolicyType: {
    name: 'View Leave Policy Type',
    title: 'View Leave Policy',
  },
  raiseIndent: {
    name: 'Raise Indent Form',
    title: 'Raise Item Indent',
  },
  purchaseRequest: {
    name: 'Raise Purchase Request',
    title: 'Raise Purchase Request',
  },
  transferRequest: {
    name: 'Stock Transfer Request',
    title: 'Transfer Request',
  },
  viewPurchasesRequest: {
    name: 'View Purchase Request',
    title: 'View Purchase Request',
  },
  editBatch: {
    name: 'Edit Batch',
    title: 'Edit Batch',
  },
  editBloodCategoryListModal: {
    name: '(Inventory) Edit Category List Modal',
    title: 'Category Listing Modal',
  },
  viewBloodCategory: {
    name: '(Inventory) View Blood Group Category',
    title: 'View Blood Group Category',
  },
  viewBloodGroup: {
    name: '(Inventory) View Blood Group ',
    title: 'View Blood Group ',
  },
  issueBloodGroup: {
    name: '(Inventory) Issue Blood',
    title: 'Issue Blood',
  },
  viewBloodDonation: {
    name: '(Inventory) View Blood Donation ',
    title: 'View Blood Donation ',
  },
  viewBloodIssuing: {
    name: '(Inventory) View Blood Issuing ',
    title: 'View Blood Issuing ',
  },
  viewBloodRequest: {
    name: '(Inventory) View Blood Request ',
    title: 'View Blood Request',
  },
  viewIssueBloodUpdationRequest: {
    name: '(Inventory) View Issue Blood Updation Request',
    title: 'View Issue Blood Updation Request',
  },
  addEmployeeSalaryCompensationStructure: {
    name: 'Add Salary Compensation Structure',
    title: 'Add New Salary Compensation Structure',
  },
  CompensationStructureConfirmationView: {
    name: 'Add Salary Compensation Structure Confirmation',
    title: 'Add New Salary Compensation Structure Confirmation',
  },
  applySalaryComponent: {
    name: 'Apply Salary Component',
    title: 'Apply Salary Component',
  },
  editEmployeeSalaryCompensationStructure: {
    name: 'Edit Salary Compensation Structure',
    title: 'Edit Employee Salary Compensation Structure',
  },
  viewEmployeeSalaryCompensationStructure: {
    name: 'View Salary Compensation Structure',
    title: 'View Employee Salary Compensation Structure',
  },
  viewIndentRequest: {
    name: 'View Indent Request',
    title: 'View Indent Request',
  },
  msiViewIndentRequest: {
    name: 'MS View Indent Request',
    title: 'View Indent Request',
  },
  msiViewIndentRequestAssetConsumables: {
    name: 'MS View Indent Request Asset Consumables',
    title: 'View Indent Request',
  },
  msiviewTransferRequest: {
    name: 'MS View Transfer Request',
    title: 'View Transfer Request',
  },
  msiViewBatchUpdateRequest: {
    name: 'MS View Batch Update Request',
    title: 'View Batch Update Request',
  },
  msiViewLowShelfLifeRequest: {
    name: 'MS View Low Shelf Life Request',
    title: 'View Low Shelf Life Request',
  },
  viewLowShelfLifeRequest: {
    name: 'View Low Shelf Life Request',
    title: 'View Low Shelf Life Request',
  },
  viewTransferRequest: {
    name: 'View Transfer Request',
    title: 'View Transfer Request',
  },
  viewBatchUpdateRequest: {
    name: 'View Batch Update Request',
    title: 'View Batch Update Request',
  },
  viewIndentRecord: {
    name: 'View Indent Record',
    title: 'Indents',
  },
  viewTransferRequestRecord: {
    name: 'View Transfer Request Record',
    title: 'View Transfer Request Record',
  },
  viewOPDPatientDispensingRecords: {
    name: 'View OPD Patient Dispensing Records',
    title: 'View OPD Patient Dispensing Records',
  },
  viewIPDPatientDispensingRecords: {
    name: 'View IPD Patient Dispensing Records',
    title: 'View IPD Patient Dispensing Records',
  },
  logEYEInvestigation: {
    name: '(IPD) Add Eye/General Medicine Investigation',
    title: 'Add Eye/General Medicine Investigation',
  },
  logENTInvestigation: {
    name: '(IPD) Add ENT Investigation',
    title: 'Add ENT Investigation',
  },
  viewCentralInventoryPurchaseRecords: {
    name: 'View Central Inventory Purchase Records',
    title: 'View Central Inventory Purchase Records',
  },
  assetsCentralInventory: {
    name: '(Inventory) Assets Central Inventory',
    title: 'Central Inventory',
  },
  assetsDepartmentInventory: {
    name: '(Inventory) Assets Department Inventory',
    title: 'Department Inventory',
  },
  editMonthlyRemarks: {
    name: 'Edit ',
    title: 'Edit Monthly remarks',
  },
  assetsCentralRequests: {
    name: '(Inventory) Assets Central Requests',
    title: 'Assets Central Requests',
  },
  assetsDepartmentRequests: {
    name: '(Inventory) Assets Department Requests',
    title: 'Assets Department Requests',
  },
  assetsMSIIndentRequests: {
    name: '(Inventory) Asset MS Indent Requests',
    title: 'Asset MS Indent Requests',
  },
  assetsIncargeRecordList: {
    name: '(Inventory) Asset Incharge record (Asset store)',
    title: 'Incharge record (Asset store)',
  },
  viewAssetsInChargeRecord: {
    name: 'InCharge Records',
    title: 'InCharge Records',
  },
  viewAssetsSupervisorRecord: {
    name: 'Supervisor Records',
    title: 'Supervisor Records',
  },
  addAssetReturnDateModal: {
    name: 'Asset return date',
    title: 'Asset return date',
  },
  addAssetItemRemarks: {
    name: 'Asset Item Remarks',
    title: 'Asset Item Remarks',
  },
  assetsSupervisorRecordList: {
    name: '(Inventory) Asset Supervisor record (Asset store)',
    title: 'Supervisor record (Asset store)',
  },
  editAssetDetail: {
    name: 'Edit asset details',
    title: 'Edit',
  },
  assetsDepartmentRecords: {
    name: '(Inventory) Assets Department Records',
    title: 'Assets Department Records',
  },
  assetsCentralRecords: {
    name: '(Inventory) Assets Central Records',
    title: 'Assets Central Records',
  },
  addInventoryAsset: {
    name: 'Add Inventory Asset',
    title: 'Add Inventory Asset',
  },
  editInventoryAsset: {
    name: 'Edit Inventory Asset',
    title: 'Edit Inventory Asset',
  },
  editInventoryAssetItem: {
    name: 'Edit Inventory Asset Item',
    title: 'Edit Inventory Asset Item',
  },
  repairRequest: {
    name: 'Repair Request',
    title: 'Repair Request',
  },
  assetItemDetail: {
    name: 'Asset Item Detail',
    title: 'Asset Item Detail',
  },
  centralAssetItemDetail: {
    name: 'Asset Central Item Detail',
    title: 'Asset Central Item Detail',
  },
  viewInventoryAsset: {
    name: 'View Inventory Asset',
    title: 'View Inventory Asset',
  },
  purchaseRequestAsset: {
    name: 'Raise Purchase Request Asset',
    title: 'Raise Purchase Request Asset',
  },
  transferRequestAsset: {
    name: 'Stock Transfer Request Asset',
    title: 'Transfer Request Asset',
  },
  loanOutAssets: {
    name: 'Loan Out Assets',
    title: 'Loan Out Assets',
  },
  raiseIndentAsset: {
    name: 'Raise Indent Asset',
    value: 'Raise Indent Asset',
  },
  viewIndentRequestAsset: {
    name: 'View Indent Request Asset',
    title: 'View Indent Request Asset',
  },
  viewDepartmentIndentRequest: {
    name: 'View Department Indent Request Asset',
    title: 'View Department Indent Request Asset',
  },
  viewRepairRequest: {
    name: 'View Repair Request',
    title: 'View Repair Request',
  },
  viewDepartmentRepairRequest: {
    name: 'View Department Repair Request',
    title: 'View Department Repair Request',
  },
  viewTransferRequestAsset: {
    name: 'View Transfer Request Asset',
    title: 'View Transfer Request Asset',
  },
  msiViewIndentRequestAsset: {
    name: 'MS View Indent Request Asset',
    title: 'View Indent Request Asset',
  },
  msiViewRepairRequest: {
    name: 'MS View Repair Request',
    title: 'View Repair Request',
  },
  viewDepartmentIndentRecordAsset: {
    name: 'View Department Indent Record Asset',
    title: 'Department Indents Asset',
  },
  viewCentralInventoryPurchaseRecordsAsset: {
    name: 'View Central Inventory Purchase Records Asset',
    title: 'View Central Inventory Purchase Records Asset',
  },
  viewRepairRecords: {
    name: 'View Repair Records',
    title: 'View Repair Records',
  },
  viewTransferRecordsAsset: {
    name: 'View Transfer Records Asset',
    title: 'View Transfer Records Asset',
  },
  viewCentralIndentRecordAsset: {
    name: 'View Central Indent Record Asset',
    title: 'Central Indents Asset',
  },
  viewCentralIndentRecordAssetItem: {
    name: 'View Central Indent Record Asset Item',
    title: 'Central Indents Asset Item',
  },
  viewLoanOutRecordAsset: {
    name: 'View Loan Out Record Asset',
    title: 'Loan Out Asset',
  },
  addInventoryAssetStocks: {
    name: 'Add Inventory Asset stocks',
    title: 'Add Inventory Asset Stocks',
  },
  assetViewDepartmentInventory: {
    name: 'View Asset Department Inventory Item',
    title: 'View Asset Department Item',
  },
  msiViewAssetTransferRequest: {
    name: 'MS View Asset Transfer Request',
    title: 'View Asset Transfer Request',
  },
  viewCentralPurchaseRequestAsset: {
    name: 'Asset Central Purchase Request Detail',
    title: 'Asset Central Purchase Request Detail',
  },
  sendToAuction: {
    name: 'Asset Send To Auction',
    title: 'Asset Send To Auction',
  },
  vaccinationTokenGeneration: {
    name: 'departments Vaccination Patient Token Generation',
    title: 'Vaccination Patient Token Generation',
  },
  viewActiveVaccinationSession: {
    name: 'departments View Active Vaccination session',
    title: 'View Active Vaccination session',
  },
  viewIncomingPatientNursePortal: {
    name: 'departments View Incoming Patient',
    title: 'Nurse Portal',
  },
  viewPatientDetail: {
    name: 'departments View Patient Detail',
    title: 'Patient Detail',
  },
  patientVaccineDetailConfirmationModal: {
    name: 'departments Vaccine Detail Confirmation Modal',
    title: 'Vaccine Detail Confirmation Modal',
  },
  patientVaccineDetailSingleDoseModal: {
    name: 'departments Vaccine Detail Single Dose',
    title: 'Vaccine Detail Single Dose',
  },
  patientVaccineDetailRepeatedDoseModal: {
    name: 'departments Vaccine Detail Repeated Dose',
    title: 'Vaccine Detail Repeated Dose',
  },
  viewPatientVaccinationHistory: {
    name: 'departments View Patient Vaccination History',
    title: 'View Patient Vaccination History',
  },
  viewPatientVaccinationHistoryDetails: {
    name: 'departments View Patient Vaccination History Details',
    title: 'View Patient Vaccination History Details',
  },
  editNurseModal: {
    name: 'departments Edit Nurse Modal',
    title: 'Edit Nurse Modal',
  },
  endVaccinationSessionModal: {
    name: 'departments End Vaccination Session Modal',
    title: 'End Vaccination Session Modal',
  },
  addPatientModal: {
    name: 'departments Add Patient Modal',
    title: 'Add Patient Modal',
  },
  vaccineDetailForPatientModal: {
    name: 'departments Vaccine Detail For Patient Modal',
    title: 'Vaccine Detail Modal',
  },
  generateVaccineBill: {
    name: 'departments New Bill',
    title: 'Vaccination New Bill',
  },
  viewVaccineList: {
    name: 'departments View Vaccine ',
    title: 'View Vaccine',
  },
  viewVaccineDetailList: {
    name: 'departments View Vaccine Detail ',
    title: 'View Vaccine Detail',
  },
  viewVaccinationHistoryItemDetails: {
    name: 'departments View Vaccine History ',
    title: 'View Vaccine History',
  },
  vaccineMasterAll: {
    name: '(MDM) Vaccine Scheduling All',
    title: 'Scheduling',
  },
  vaccineScheduleList: {
    name: '(MDM) Vaccine Scheduling List',
    title: 'Scheduling',
  },
  viewVaccineSchedule: {
    name: '(MDM) View Vaccine Schedule',
    title: 'View Vaccine Schedule',
  },
  addVaccineSchedule: {
    name: '(MDM) Add Vaccine Schedule',
    title: 'Add Vaccine Schedule',
  },
  addPatientMeasurment: {
    name: 'Patient Measurment For Vaccine',
    title: 'Patient Measurment For Vaccine',
  },
  patientAdmissionIPD: {
    name: 'departments Patient Admissions',
    title: 'Patient Admission',
  },
  patientAdmissionListIPD: {
    name: 'departments View Patients',
    title: 'View Patients (IPD)',
  },
  emergencyIPDModal: {
    name: 'departments Patient Emergency (IPD)',
    title: 'Patient Emergency',
  },
  viewUnknownPatientIPD: {
    name: 'departments View Unknown Patient (IPD)',
    title: 'View Unknown Patient',
  },
  unknownPatientAdd: {
    name: 'departments Add Patient (IPD)',
    title: 'Add Patient (IPD)',
  },
  unknownPatientEdit: {
    name: 'departments Edit Patient (IPD)',
    title: 'Edit Patient (IPD)',
  },
  patientBelongingsList: {
    name: 'departments Patient Belongings (IPD)',
    title: 'Patient Belongings ',
  },
  admitPatientToIPD: {
    name: 'departments Admit Patient (IPD)',
    title: 'Admit Patient ',
  },
  viewPatientTabs: {
    name: 'departments Admitted Patient (IPD)',
    title: 'Admitted Patient (IPD)',
  },
  viewGeneralSurgeryTabs: {
    name: 'View General Surgery Tabs (IPD)',
    title: 'View General Surgery Tabs (IPD)',
  },
  addPurchaseCategory: {
    name: 'Add Purchase Category',
    title: 'Add Purchase Category',
  },
  editPurchaseCategory: {
    name: 'Edit Purchase Category',
    title: 'Edit Purchase Category',
  },
  viewPurchaseCategory: {
    name: 'View Purchase Category',
    title: 'View Purchase Category',
  },
  addPurchaseSubCategory: {
    name: 'Add Purchase Sub Category',
    title: 'Add Purchase Sub Category',
  },
  editPurchaseSubCategory: {
    name: 'Edit Purchase Sub Category',
    title: 'Edit Purchase Sub Category',
  },
  viewPurchaseSubCategory: {
    name: 'View Purchase Sub Category',
    title: 'View Purchase Sub Category',
  },
  filesAndBills: {
    name: 'departments Files and Bills',
    title: 'Files and Bills',
  },
  newContingentForm: {
    name: 'departments New Contingent Voucher',
    title: 'New Contingent Voucher',
  },
  cotingentVoucherPreview: {
    name: 'departments Contingent Voucher Preview',
    title: 'Contingent Voucher Preview',
  },
  cotingentVoucherApproval: {
    name: 'departments Contingent Voucher Approval',
    title: 'Contingent Voucher Approval',
  },
  cotingentVoucherRejected: {
    name: 'departments Contingent Voucher Rejected',
    title: 'Contingent Voucher Rejected',
  },
  viewContingentVoucherRequest: {
    name: 'View Contingent Voucher Request',
    title: 'View Contingent Voucher Request',
  },
  SendToReviewModal: {
    name: 'Review Contingent Voucher Request',
    title: 'Review Contingent Voucher Request',
  },
  viewPatientIPD: {
    name: 'departments View Known Patient (IPD)',
    title: 'View Known Patient',
  },
  nurseViewIPD: {
    name: 'Nurse View (IPD)',
    title: 'Registered Patient',
  },
  nurseViewPatientIPD: {
    name: 'Nurse view of Patient (IPD)',
    title: 'Nurse view of Patient',
  },
  roomAllot: {
    name: 'Bed Allotment Form',
    title: 'Bed Allotment Form',
  },
  consentForm: {
    name: 'consent Form',
    title: 'consent Form',
  },
  admittedPatient: {
    name: 'Admitted Patients',
    title: 'Admitted Patients',
  },
  AddProgress: {
    name: 'Add Progress',
    title: 'Add Progress',
  },
  EditProgress: {
    name: 'Edit Progress',
    title: 'Edit Progress',
  },
  addProgressNeurology: {
    name: 'Add Progress Neurology',
    title: 'Add Progress Neurology',
  },
  viewProgressNeurology: {
    name: 'View Progress Neurology',
    title: 'View Progress Neurology',
  },
  addProgressGeneralMedicine: {
    name: 'General Medicine Add Progress',
    title: 'Add Progress',
  },
  viewOrderDetail: {
    name: 'view Order Detail',
    title: 'view Order Detail',
  },
  LogOrder: {
    name: 'Log Order Detail',
    title: 'Log Order Detail',
  },
  EditOrder: {
    name: 'Edit Order Detail',
    title: 'Edit Order Detail',
  },
  viewNotesGeneralMedicineIpd: {
    name: 'view General Medicine Notes Ipd',
    title: 'view Notes Ipd',
  },
  viewNotesIpd: {
    name: 'view Notes Ipd',
    title: 'view Notes Ipd',
  },
  viewProgressReportGeneralMedicine: {
    name: 'view Progress Report General Medicine IPD',
    title: 'view Progress Report IPD',
  },
  ViewProgressReport: {
    name: 'view Progress Report Ipd',
    title: 'view Progress Report Ipd',
  },
  addHistory: {
    name: 'Add History',
    title: 'Add History',
  },
  viewHistory: {
    name: ' View History',
    title: 'View History',
  },
  logAnaesthesiaGeneralMedicine: {
    name: 'Log Anaesthesia General Medicine',
    title: 'log Anaesthesia',
  },
  logAnaesthesia: {
    name: 'log Anaesthesia',
    title: 'log Anaesthesia',
  },
  viewAnaesthesia: {
    name: 'View Anaesthesia',
    title: 'View Anaesthesia',
  },
  LogDailyVitals: {
    name: 'Add Vital Sheet Ipd',
    title: 'Add Vital Sheet Ipd',
  },
  editDailyVitals: {
    name: 'Edit Vital Sheet Ipd',
    title: 'Edit Vital Sheet Ipd',
  },
  ViewDailyVitals: {
    name: 'View Vital Sheet Ipd',
    title: 'View Vital Sheet Ipd',
  },
  logPsychologicalTreatment: {
    name: 'Log Psychological Treatment IPD',
    title: 'Log Psychological Treatment',
  },
  addDailyExaminationNotesGeneralMedicine: {
    name: 'Log Daily Examination/Notes IPD',
    title: 'Log Daily Examination/Notes',
  },
  viewDailyExaminationNotesGeneralMedicine: {
    name: 'View Daily Examination/Notes IPD',
    title: 'View Daily Examination/Notes',
  },
  logOrderIDU: {
    name: 'Log Order ICU IPD',
    title: 'log order ICU',
  },
  viewOrderIDU: {
    name: 'View Order ICU IPD',
    title: 'view order ICU',
  },
  viewPsychologicalTreatment: {
    name: 'View Psychological Treatment IPD',
    title: 'View Psychological Treatment',
  },
  editTemperaturePulseTimeChartDetails: {
    name: 'Edit Temperature Pulse Time Chart Detail (IPD)',
    title: 'Edit Temperature Pulse Time Chart Detail',
  },
  editMonitoringDetails: {
    name: 'Edit Monitoring Detail (IPD)',
    title: 'Edit Monitoring Detail',
  },
  redirectToAnesthesiaAfterOperationNotesModal: {
    name: 'Redirect To Anesthesia After Operation Notes Modal (IPD)',
    title: 'Redirect To Anesthesia After Operation Notes Modal',
  },
  operationMaster: {
    name: 'View Operation',
    title: 'View Operation',
  },
  editOperationMaster: {
    name: 'Edit Operation',
    title: 'Edit Operation',
  },
  addOperationMaster: {
    name: 'Add Operation',
    title: 'Add Operation',
  },
  addProgressReportGeneralMedicine: {
    name: 'Add Progress general medicine',
    title: 'Add Progress general medicine',
  },

  logInputOutput: {
    name: 'Log Input Output',
    title: 'Log Input Output',
  },
  ViewInputOutputVitals: {
    name: 'View Input Output',
    title: 'View Input Output',
  },
  addBloodSugar: {
    name: 'Add Blood Sugar',
    title: 'Add Blood Sugar',
  },
  ViewBloodSugarVitals: {
    name: 'View Blood Sugar',
    title: 'View Blood Sugar',
  },
  ViewVitalsGeneralMedicine: {
    name: 'View Vitals',
    title: 'View Vitals',
  },
  addVitalsGeneralMedicine: {
    name: 'Add Vitals',
    title: 'Add Vitals',
  },
  logECTNotesPsychiatry: {
    name: 'Log ECT Notes Psychiatry IPD',
    title: 'Log ECT Notes',
  },
  viewECTNotesPsychiatry: {
    name: 'view ECT Notes Psychiatry IPD',
    title: 'view ECT Notes',
  },
  AddProgressPediatrics: {
    name: 'Add Progress Pediatrics',
    title: 'Add Progress Pediatrics',
  },
  ViewProgressReportPediatric: {
    name: 'View Progress Pediatrics',
    title: 'View Progress Pediatrics',
  },
  AddVitalsPediatrics: {
    name: 'Add Vitals Pediatrics',
    title: 'Add Vitals Pediatrics',
  },
  editVitalsPediatrics: {
    name: 'Edit Vitals Pediatrics',
    title: 'Edit Vitals Pediatrics',
  },
  ViewVitalsPediatrics: {
    name: 'View Vitals Pediatrics',
    title: 'View Vitals Pediatrics',
  },
  logMonitoringChart: {
    name: 'Log Monitoring Chart',
    title: 'Log Monitoring Chart',
  },
  viewMonitoringChart: {
    name: 'View Monitoring Chart',
    title: 'View Monitoring Chart',
  },
  monitoringChart: {
    name: ' Monitoring Chart',
    title: ' Monitoring Chart',
  },
  logInputOutputChart: {
    name: 'Log Input Output Chart',
    title: 'Log Input Output Chart',
  },
  viewInputOutputChart: {
    name: 'View Input Output Chart',
    title: 'View Input Output Chart',
  },
  inputOutputChart: {
    name: ' Input Output Chart',
    title: ' Input Output Chart',
  },
  salaryStatementModal: {
    name: 'departments Download Salary Statement',
    title: 'Download Salary Statement',
  },
  employeeForm: {
    name: 'Employee Form',
    title: 'Employee Form',
  },
  formBulkUpload: {
    name: 'Employee Form Bulk Upoad',
    title: 'Employee Form Bulk Upoad',
  },
  EmployeeRemarkList: {
    name: 'Employee Remarks',
    title: 'Employee Remarks',
  },
  AddRemark: {
    name: 'Add Remarks',
    title: 'Add Remarks',
  },
  EditRemark: {
    name: 'Edit Remarks',
    title: 'Edit Remarks',
  },
  EmployeeAttendance: {
    name: 'Employee Attendance',
    title: 'Employee Attendance',
  },
  AttendanceRequests: {
    name: 'Employee Requests',
    title: 'Employee Requests',
  },
  Payment: {
    name: 'Payment',
    title: 'Payment',
  },
  EmployeeAttendanceTabs: {
    name: 'Employee Attendance Tabs',
    title: 'Employee Attendance Tabs',
  },
  addMedicineFromTreatment: {
    name: 'Add Medicine From Treatment',
    title: 'Add Medicine From Treatment',
  },
  ipdConsultation: {
    name: 'Consultation IPD',
    title: 'Consultation IPD',
  },
  pranPercentage: {
    name: 'PRAN Percentage',
    title: 'PRAN Percentage',
  },
  ExGratiaSalary: {
    name: 'Ex-Gratica And Society Salary',
    title: 'Ex-Gratica And Society Salary',
  },
  AddExGratiaSalary: {
    name: 'Add Ex-Gratia And Society Salary',
    title: 'Add Ex-Gratia And Society Salary',
  },
  ViewExGratiaSalary: {
    name: 'View Ex-Gratica And Society Salary',
    title: 'View Ex-Gratica And Society Salary',
  },
  disease: {
    name: '(MDM) Disease',
    title: 'Disease',
  },
  diseaseList: {
    name: '(MDM) View Disease List',
    title: 'View Disease List',
  },
  addDisease: {
    name: '(MDM) Add Disease',
    title: 'Add Disease',
  },
  editDisease: {
    name: '(MDM) Edit Disease',
    title: 'Edit Disease',
  },
  viewDisease: {
    name: '(MDM) View Disease',
    title: 'View Disease',
  },
};
export const states = [
  {
    value: 'Andaman and Nicobar Islands',
    label: 'Andaman and Nicobar Islands',
  },
  {
    value: 'Andhra Pradesh',
    label: 'Andhra Pradesh',
  },
  {
    value: 'Arunachal Pradesh',
    label: 'Arunachal Pradesh',
  },
  {
    value: 'Assam',
    label: 'Assam',
  },
  {
    value: 'Bihar',
    label: 'Bihar',
  },
  {
    value: 'Chandigarh',
    label: 'Chandigarh',
  },
  {
    value: 'Chhattisgarh',
    label: 'Chhattisgarh',
  },
  {
    value: 'Dadra and Nagar Haveli',
    label: 'Dadra and Nagar Haveli',
  },
  {
    value: 'Daman and Diu',
    label: 'Daman and Diu',
  },
  {
    value: 'Delhi',
    label: 'Delhi',
  },
  {
    value: 'Goa',
    label: 'Goa',
  },
  {
    value: 'Gujarat',
    label: 'Gujarat',
  },
  {
    value: 'Haryana',
    label: 'Haryana',
  },
  {
    value: 'Himachal Pradesh',
    label: 'Himachal Pradesh',
  },
  {
    value: 'Jammu and Kashmir',
    label: 'Jammu and Kashmir',
  },
  {
    value: 'Jharkhand',
    label: 'Jharkhand',
  },
  {
    value: 'Karnataka',
    label: 'Karnataka',
  },
  {
    value: 'Kerala',
    label: 'Kerala',
  },
  {
    value: 'Lakshadweep',
    label: 'Lakshadweep',
  },
  {
    value: 'Madhya Pradesh',
    label: 'Madhya Pradesh',
  },
  {
    value: 'Maharashtra',
    label: 'Maharashtra',
  },
  {
    value: 'Manipur',
    label: 'Manipur',
  },
  {
    value: 'Meghalaya',
    label: 'Meghalaya',
  },
  {
    value: 'Mizoram',
    label: 'Mizoram',
  },
  {
    value: 'Nagaland',
    label: 'Nagaland',
  },
  {
    value: 'Odisha',
    label: 'Odisha',
  },
  {
    value: 'Puducherry',
    label: 'Puducherry',
  },
  {
    value: 'Punjab',
    label: 'Punjab',
  },
  {
    value: 'Rajasthan',
    label: 'Rajasthan',
  },
  {
    value: 'Sikkim',
    label: 'Sikkim',
  },
  {
    value: 'Tamil Nadu',
    label: 'Tamil Nadu',
  },
  {
    value: 'Telangana',
    label: 'Telangana',
  },
  {
    value: 'Tripura',
    label: 'Tripura',
  },
  {
    value: 'Uttar Pradesh',
    label: 'Uttar Pradesh',
  },
  {
    value: 'Uttarakhand',
    label: 'Uttarakhand',
  },
  {
    value: 'West Bengal',
    label: 'West Bengal',
  },
];

export const relations = [
  {
    label: 'S/O',
    value: 'S/O',
  },
  {
    label: 'D/O',
    value: 'D/O',
  },
  {
    label: 'W/O',
    value: 'W/O',
  },
];

export const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export const bloodRequestTypes = [
  { value: 'emergency', label: 'Emergency' },
  { value: 'lifeSaving', label: 'Life-saving' },
  { value: 'routine', label: 'Routine' },
];

export const frequency = [
  { value: 'once', label: 'Once' },
  { value: 'twice', label: 'Twice' },
  { value: 'thrice', label: 'Thrice' },
];

export const doseTime = [
  { value: 'before-meal', label: 'Before Meal' },
  { value: 'after-meal', label: 'After Meal' },
];

export const doses = [
  { value: 'once', label: 'Once' },
  { value: 'twice', label: 'Twice' },
];

export const gendersAll = [
  { value: 'all', label: 'All' },
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export const bloodGroup = [
  { value: 'A+', label: 'A+' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B-', label: 'B-' },
  { value: 'O+', label: 'O+' },
  { value: 'O-', label: 'O-' },
  { value: 'AB+', label: 'AB+' },
  { value: 'AB-', label: 'AB-' },
  { value: 'BOMBAY GROUP', label: 'BOMBAY GROUP' },
];

export const maritalStatus = [
  { value: 'single', label: 'Single' },
  { value: 'married', label: 'Married' },
  { value: 'widowed', label: 'Widowed' },
  { value: 'divorced', label: 'Divorced' },
  { value: 'separated', label: 'Separated' },
];
export const patientTypes = ['Ayushman', 'BPL', 'Others', 'Staff', 'Poor'];
export const assetServiceTypes = ['AMC', 'CMC', 'NONE'];
export const assetStatus = ['In-Storage', 'In-Service', 'Issued', 'Loaned-Out', 'In-Repair', 'Out of Order', 'inAuction'];
export const inChargeAssetStatus = ['In-Storage', 'In-Service', 'Issued/Loaned-Out', 'In-Repair', 'Out of Order'];
export const bloodStoringTypes = ['Save as Whole Blood', 'Centrifuge'];
export const bloodBankBagCapacityTypes = ['350 ml', '450 ml'];

export const taxDeclarationDeductions = {
  deduction_under_section_chapterVIA: 'DEDUCTION UNDER SECTION CHAPTER VIA',
  other_deductions: 'OTHER DEDUCTION',
  income_from_house_property: 'INCOME FROM HOUSE PROPERTY',
  lta: 'LTA',
  previous_employer_income: 'PREVIOUS EMPLOYER INCOME',
  rent_recipts: 'RENT RECEIPTS',
  income_on_higher_education_loan: 'INCOME ON HIGHER EDUCATION LOAN',
  donations: 'DONATIONS',
  flexi_component_details: 'Flexi Component Details',
  nps: 'NPS',
};
export const category = [
  { value: 'injectables', label: 'Injectables' },
  { value: 'surgical Items', label: 'Surgical Items' },
  { value: 'tablets', label: 'Tablets' },
  { value: 'syrups', label: 'Syrups' },
  { value: 'eye Drops', label: 'Eye Drops' },
];
export const assetCategory = [
  { value: 'furniture&Fixtures', label: 'Furniture & Fixtures' },
  { value: 'medicalEquipments', label: 'Medical Equipments' },
];

export const assetServiceStatus = [
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },

];
export const serviceStatus = { upcoming: 'upcoming', pending: 'pending', completed: 'completed' };

export const leaveCreditType = [
  { value: 'daily', label: 'Daily' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'half_yearly', label: 'Half Yearly' },
  { value: 'yearly', label: 'Yearly' },
];

export const bloodCategoriesMap = {
  FFP: 'FFP',
  PRBC: 'PRBC',
  RDP_PLATELETS: 'RDP Platelets',
  SDP_PLATELETS: 'SDP Platelets',
};

export const bloodBankStorageTypes = {
  TRIPLE_BAG: 'Triple Bag',
  CENTRIFUGE: 'Centrifuge',
  SDP_PLATELETS: 'SDP Platelets',
  QUADRUPLE_BAG: 'Quadruple Bag',
};

export const bloodCategories = Object.keys(bloodCategoriesMap).map((item) => ({
  label: bloodCategoriesMap[item], value: item,
}));

export const bloodBankTestStatus = [
  { value: 'tested', label: 'Tested' },
  { value: 'untested', label: 'Untested' },
];

export const bloodBankBagStatus = [
  { value: 'active', label: 'Active' },
  { value: 'discard', label: 'Discard' },
];

export const bloodBankTestResult = [
  { value: 'hiv', label: 'HIV' },
  { value: 'hcv', label: 'HCV' },
  { value: 'hv bs ag', label: 'HV Bs Ag' },
  { value: 'malaria', label: 'Malaria' },
  { value: 'vdrl-syphilis', label: 'VDRL-Syphilis' },
  { value: 'sterility', label: 'sterility' },
];

export const unit = [
  { name: 'lit', value: 'Lit' },
  {
    name: 'ml',
    value: 'ml',
  },
  {
    name: 'kg',
    value: 'kg',
  },
];
export const leaveAccuralOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'half_yearly', label: 'Half Yearly' },
];

export const opdConsultationStatus = [
  { value: 'not-appeared', label: 'Not Appeared' },
  { value: 'completed', label: 'Consulted' },
];

export const bloodStockStatus = {
  all: { value: null, label: 'All' },
  inStock: {
    value: 'In-Stock',
    label: 'In Stock',
  },
  lowOfStock: {
    value: 'Low on Stock',
    label: 'Low On Stock',
  },
  outOfStock: {
    value: 'Out of Stock',
    label: 'Out of Stock',
  },
};
export const pharmacyRequestsStatus = [
  { value: 'raised', label: 'Raised' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];
export const expiryStatusOptions = {
  active: 'Active',
  critical: 'Critical',
  optimal: 'Optimal',
  expired: 'Expired',
  nearToExpiry: 'Near to Expiry',
};

export const assetStatuses = {
  IN_REPAIR: 'In-Repair',
  IN_STORAGE: 'In-Storage',
  IN_SERVICE: 'In-Service',
  IN_ISSUED_LOANED_OUT: 'Issued/Loaned-Out',
  LOANED_OUT: 'Loaned-Out',
  OUT_OF_ORDER: 'Out of Order',
  ISSUED: 'Issued',
};

export const pharmacyInventoryStockRequestStatus = {
  raised: 'raised',
  rejected: 'rejected',
  approved: 'approved',
  stockProvided: 'Stock-Provided',
  approvedByMSI: 'Approved By MS',
  pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved',
};
export const assetRequestsStatus = [
  { value: 'raised', label: 'Raised' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];
export const assetInventoryStockRequestStatus = {
  raised: 'raised',
  rejected: 'rejected',
  approved: 'approved',
  stockProvided: 'Stock-Provided',
  approvedByMSI: 'Approved By MS',
  pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved',
};
export const RolesOrder = [
  { value: 'PSI', label: 'PSI' },
  { value: 'DMS', label: 'DMS' },
  { value: 'FA', label: 'FA' },
  { value: 'MS', label: 'MS' },
  { value: 'DA', label: 'DA' },
  { value: 'DIRECTOR', label: 'DIRECTOR' },
];
export const Roles = {
  psi: 'PSI',
  dms: 'DMS',
  fa: 'FA',
  msi: 'MS',
  da: 'DA',
  director: 'DIRECTOR',
  hod: 'HOD',
};
export const RolesIndex = {
  PSI: 1, // - Purchase superintendent
  DMS: 2, // - Deputy Medical Superintendent
  FA: 3, // - Financial advisor
  MS: 4, // - Medical Superintendent
  DA: 5, // - Deputy Administrator
  DIRECTOR: 6, //
  HOD: 7,
};
export const Status = {
  approved: 'approved',
  rejected: 'rejected',
  pending: 'pending',
  raised: 'raised',
};
export const supplyOrderStatus = [
  { value: 'created', label: 'SO in Approval' },
  { value: 'Stock Entry Pending', label: 'SO Approved/ Stock Entry Pending' },
  { value: 'rejected', label: 'SO Rejected' },
  { value: 'Payment Pending', label: 'Payment Pending' },
];

export const purchaseOrderStatus = [
  { value: 'created', label: 'PS in Approval' },
  { value: 'rejected', label: 'PS Rejected' },
  { value: 'approved', label: 'PS Approved' },
];

export const pharmacyAndAssetStocksStatus = [
  { value: 'raised', label: 'Raised' },
  { value: 'PS in Approval', label: 'PS in Approval' },
  { value: 'PS Rejected', label: 'PS Rejected' },
  { value: 'PS Approved', label: 'PS Approved' },
  { value: 'SO in Approval', label: 'SO in Approval' },
  { value: 'SO Rejected', label: 'SO Rejected' },
  { value: 'Stock Entry Pending', label: 'SO Approved/ Stock Entry Pending' },
  { value: 'Payment Pending', label: 'Payment Pending' },
];

export const assetSubType = [
  { value: 'purchase', label: 'Purchase' },
  { value: 'repair', label: 'Repair' },
];

export const modules = {
  pharmacy: 'pharmacyManagement',
  asset: 'assetManagement',
  purchase: 'purchaseManagement',
};

export const purchaseSupplyOrderStatus = {
  APPROVED: 'approved',
  APPROVE: 'Approved',
  REJECTED: 'rejected',
  RAISED: 'raised',
  PENDING: 'pending',
  CREATED: 'created',
  PO_IN_APPROVAL: 'PS in Approval',
  PO_APPROVED: 'PS Approved',
  PO_REJECTED: 'PS Rejected',
  SO_IN_APPROVAL: 'SO in Approval',
  SO_APPROVED: 'SO Approved',
  SO_REJECTED: 'SO Rejected',
  STOCK_PROVIDED: 'Stock-Provided',
  PAYMENT_DONE: 'Payment Done',
  STOCK_ENTRY_PENDING: 'Stock Entry Pending',
  PARTIALLY_RECEIVED: 'Partially Received',
  PAYMENT_PENDING: 'Payment Pending',
  COMPLETED: 'completed',
  ORDER_RECEIVED: 'Order Received',
  LOW_SHELF_LIFE_REQUEST_RAISED: 'Low shelf life request raised',
  REQUEST_APPROVE_CENTRAL: 'Request approve by central',
};

export const inventoryType = {
  pharmacy: 'pharmacy',
  asset: 'asset',
  subTypePurchase: 'purchase',
  subTypeRepair: 'repair',
};

export const inventoryTypeFilter = [{ value: 'asset', label: 'Asset' }, { value: 'pharmacy', label: 'Pharmacy' }];
export const urgentFilter = [{ value: true, label: 'Yes' }, { value: { $ne: true }, label: 'No' }];
export const endingRateContractDateFilterOptions = [
  { label: 'Less than 15 Days', value: { $gte: moment(), $lte: moment().add(14, 'days').toISOString() } },
  { label: 'Between 15 - 30 Days', value: { $gte: moment().add(15, 'days').toISOString(), $lte: moment().add(30, 'days').toISOString() } },
  { label: 'Between 30 - 60 Days', value: { $gte: moment().add(30, 'days').toISOString(), $lte: moment().add(60, 'days').toISOString() } },
  { label: 'Greater than 60 Days', value: { $gte: moment().add(61, 'days').toISOString() } },
];

export const levelPayOptions = [
  { label: 'L1', value: 'L1' },
  { label: 'L2', value: 'L2' },
  { label: 'L3', value: 'L3' },
  { label: 'L4', value: 'L4' },
  { label: 'L5', value: 'L5' },
  { label: 'L6', value: 'L6' },
  { label: 'L7', value: 'L7' },
  { label: 'L8', value: 'L8' },
  { label: 'L9', value: 'L9' },
  { label: 'L10', value: 'L10' },
  { label: 'L11', value: 'L11' },
  { label: 'L12', value: 'L12' },
  { label: 'L13', value: 'L13' },
  { label: 'L14', value: 'L14' },
  { label: 'L15', value: 'L15' },
  { label: 'L16', value: 'L16' },
  { label: 'L17', value: 'L17' },
  { label: 'L18', value: 'L18' },
  { label: 'L19', value: 'L19' },
  { label: 'L20', value: 'L20' },
  { label: 'L21', value: 'L21' },
  { label: 'L22', value: 'L22' },
  { label: 'L23', value: 'L23' },
  { label: 'L24', value: 'L24' },
  { label: 'L25', value: 'L25' },
  { label: 'L26', value: 'L26' },
  { label: 'L27', value: 'L27' },
  { label: 'L28', value: 'L28' },
  { label: 'L29', value: 'L29' },
  { label: 'L30', value: 'L30' },
  { label: 'L31', value: 'L31' },
  { label: 'L32', value: 'L32' },
  { label: 'L33', value: 'L33' },
  { label: 'L34', value: 'L34' },
  { label: 'L35', value: 'L35' },
  { label: 'L36', value: 'L36' },
  { label: 'L37', value: 'L37' },
  { label: 'L38', value: 'L38' },
  { label: 'L39', value: 'L39' },
  { label: 'L40', value: 'L40' },
];

export const epfFilter = [
  { label: 'EPF Employees', value: true },
  { label: 'Non EPF Employees', value: { $ne: true } },
];
export const npsFilter = [
  { label: 'NPS Employees', value: true },
  { label: 'Non NPS Employees', value: { $ne: true } },
];

export const vaccineItemStockStatus = {
  inStock: 'In-Stock',
  lowOfStock: 'Low-on-Stock',
  outOfStock: 'Out-of-Stock',
};
export const vaccinationStatus = {
  notAppear: 'not-appeared',
  vaccinated: 'vaccinated',
  nurseAssign: 'nurse-assigned',
  reallign: 're-allign',
  noStatus: 'No status',
  provided: 'Provided',
  pending: 'Pending',
};
export const revaccinateGapOptions = [
  { value: 'onceInLifeTime', label: 'Once in lifetime' },
  { value: 'reCurring', label: 'Recurring' },
];

export const vaccinationType = {
  reCurring: 'reCurring',
  onceInLifeTime: 'onceInLifeTime',
};

export const inventoryModules = {
  pharmacy: 'pharmacy',
  purchase: 'purchase',
  asset: 'asset',
  assetConsumables: 'assetConsumables',
};

export const supervisorAndInChargeAssetStatus = {
  inStorage: 'In-Storage',
  inService: 'In-Service',
  issued: 'Issued',
  loanedOut: 'Loaned-Out',
  inRepair: 'In-Repair',
  outOfOrder: 'Out of Order',
  inAuction: 'inAuction',
};

export const rateContractStatus = {
  onGoing: 'Ongoing',
  expired: 'Expired',
};

export const consultationTypes = [
  { value: 'inHospital', label: 'In Hospital' },
  { value: 'videoAppointment', label: 'Video Appointment' },
];

export const auctionItemStatus = {
  inStock: 'inStock',
  soldOut: 'soldOut',
};

export const issueBloodUpdationRequestStatus = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const bagStatusBloodBank = {
  ACTIVE: 'Active',
  DISCARD: 'Discard',
  REQUESTED: 'Requested',
};

export const entryLevels = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
  { value: 29, label: 29 },
  { value: 30, label: 30 },
  { value: 31, label: 31 },
  { value: 32, label: 32 },
  { value: 33, label: 33 },
  { value: 34, label: 34 },
  { value: 35, label: 35 },
  { value: 36, label: 36 },
  { value: 37, label: 37 },
  { value: 38, label: 38 },
  { value: 39, label: 39 },
  { value: 40, label: 40 },
];

export const hardCodedData = {
  EX_GRATIA_CODE: 'Ex-Gratia',
  SOCIETY_SALARY_CODE: 'Society Salary',
};
