/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React from 'react';
import { Toast, View } from '../../../../app-components';

import { Form } from '../../../../app-components/form/Form';
import { getUser, submit } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import TokenGenerationForm from './Components/TokenGenerationForm';

const StartSessionForm = (props) => {
  const { navigation } = props;

  const user = getUser();
  const { username } = user;
  if (username === 'superadmin') {
    Toast.show({
      message: 'Error',
      description: 'vaccination.messages.loginUserError'.getLabel(),
      type: 'error',
      position: 'top',
      direction: 'right',
    });
    return <View />;
  }

  if (!navigation?.state?.params.isSessionInActive) {
    navigation.replace(ROUTES.viewActiveVaccinationSession.name);
  }

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Form
        bodyContainerStyle={{
          flex: 1,
          justifyContent: 'space-between',
          marginTop: 20,
          marginBottom: 30,
        }}
        {...props}
        onSubmit={(props) => {
          const { data, updates } = props;

          const submitAPI = submit({
            model: MODELS.VACCINATION_SESSION,
            data,
          });
          const result = submitAPI({ data, updates });
          return result;
        }}
        submitNext={(submitProps) => {
          const { data } = submitProps;
          navigation.replace(
            {
              view: ROUTES.viewActiveVaccinationSession.name,
              params: data,
            },
          );
        }}
        mandatory={{
          assistant: 1,
          nurse: 1,
        }}
        {...props}
      >
        <TokenGenerationForm />
      </Form>
    </View>
  );
};

export default StartSessionForm;

