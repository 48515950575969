import IncomingPatientScreens from './IncomingPatients';
import PatientTokenGenerationScreens from './PatientTokenGeneration';
import PatientManagementScreens from './PatientManagement';
import ConsultedPatientHistory from './ConsultedPatientHistory';
import LiveConsultations from './LiveConsultations';
import Reports from './Reports';
import AppointmentList from './VideoCalling/appointmentsList';
import VideoAppointment from './VideoCalling';
import { ROUTES } from '../../Lib/constants';

const OPDScreens = {
  ...IncomingPatientScreens,
  ...PatientTokenGenerationScreens,
  ...PatientManagementScreens,
  ...ConsultedPatientHistory,
  ...LiveConsultations,
  ...Reports,
  [ROUTES.appointmentsList.name]: {
    screen: AppointmentList,
    expanded: true,
  },
  [ROUTES.videoAppointment.name]: {
    screen: VideoAppointment,
    expanded: true,
    modal: true,
    modalProps: {
      // width: 1000,
      // height: 1200,
      position: 'fullScreen',
    },
  },
};

export default OPDScreens;
