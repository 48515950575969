import React, { useCallback, useEffect, useState } from 'react';

import { isArray } from 'lodash';
import { AutoSuggestInput } from '../../../../../app-components/input-components/Editors';
import ipdGeneralMedicineScreen from './GeneralMedicine/generalMedicineScreen';
import generalSurgeryScreens from './GeneralSurgery/generalSurgeryScreens';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import gynecologyScreens from './Gynaecology/gynecologyScreens';
import pediatricScreen from './PediatricsNICU/pediatricScreen';
import psychiatryScreens from './Psychiatry/psychiatryScreens';
import emergencyScreens from './Emergency/emergencyScreens';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import { Text } from '../../../../../app-components';
import vars from '../../../../../theme/vars';
import eyeScreens from './Eye/eyeScreens';
import viewTabs from './Tabs';
import entScreens from './ENT/entScreens';
import icuScreens from './ICU/icuScreens';
import neurologyScreens from './Neurology/neurologyScreen';
import { fetch } from '../../../../../AppServices';

const CustomSelect = (props) => {
  const {
    eventDispatcher,
    navigation: {
      state: {
        params: {
          patient: {
            _id,
            assignedSpecialities: defaultSpeciality,
          },
        },
      },
    },
  } = props;
  const [value, setValue] = useState({
    code: defaultSpeciality?.[0]?.code, _id: defaultSpeciality?.[0]?._id,
  });
  const [assignedSpecialitiesArray, setAssignedSpecialitiesArray] = useState(defaultSpeciality);

  const fetchAssignedSpecialities = useCallback(async () => {
    const fetchedChartData = await fetch({
      uri: {
        props: {
          query: {
            id: 'admitIPDDataList',
            addOnFilter: {
              _id,
            },
          },
          model: MODELS.IPD_ADMISSION,
        },
      },
    });
    const data = fetchedChartData?.data[0]?.assignedSpecialities || defaultSpeciality;
    setAssignedSpecialitiesArray(data);
    return fetchedChartData;
  }, [_id]);

  useEffect(() => {
    props.setScreenState
      && props.setScreenState({
        dataParams: {
          filters: {
            specialityFilter: value?.code,
            specialityId: { _id: value?._id },
          },
        },
      });
    fetchAssignedSpecialities();
    eventDispatcher.listen(`reload${MODELS.IPD_PROGRESS_REPORT}`, fetchAssignedSpecialities);
    return () => eventDispatcher.unlisten(`reload${MODELS.IPD_PROGRESS_REPORT}`);
  }, [value]);

  const onChangeValue = (val) => {
    setValue(val);
  };
  return (
    <AutoSuggestInput
      {...props}
      value={value?.code}
      {...autoSuggestInput({
        options: isArray(assignedSpecialitiesArray)
          ? assignedSpecialitiesArray?.map((data) => ({ ...data, value: { code: data?.code, _id: data?._id } }))
          : [assignedSpecialitiesArray]?.map((data) => ({ ...data, value: { code: data?.code, _id: data?._id } })),
        field: 'labType',
        label: 'Speciality',
        variant: 'filled',
        keyFiled: 'name',
        suggestionField: 'name',
        valueField: 'name',
        dropdownStyle: {
          minHeight: '40%',
          borderWidth: 1,
          padding: 9,
          marginTop: 7,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.grey.color8,
          borderColor: vars.colors.grey.color6,
        },
        onChangeValue,
        // model: MODELS.LAB_TYPE,
        // query: 'masterDataList',
        mandatory: true,
      })}
    />
  );
};

export default {
  [ROUTES.viewPatientTabs.name]: {
    screen: viewTabs,
    expanded: true,
    title: (props) => {
      const {
        navigation: {
          state: { params: { patient: { patient } = {} } = {} } = {},
        } = {},
      } = props;
      return <Text style={{ ...vars.headings.h8 }}>{patient?.uhid}</Text>;
    },
    headerActions: [
      {
        render: (props) => {
          const {
            navigation: {
              state: { params: { patient: { patient } = {} } = {} } = {},
            } = {},
          } = props;
          return (
            <Text
              style={{
                color: vars.colors.grey.color3,
                ...vars.headings.h8,
              }}
            >
              {patient?.uhid}
              {'  '}
              |
              {'  '}
              {patient?.patientName}
              {'  '}
              |
              {'  '}
              {patient?.gender}
              {'  '}
              |
              {' '}
              {patient?.age}
              {'  '}
            </Text>
          );
        },
        type: 'Text',
      },
      {
        render: (props) => <CustomSelect {...props} />,
      },
    ],
  },

  ...emergencyScreens,
  ...psychiatryScreens,
  ...eyeScreens,
  ...entScreens,
  ...gynecologyScreens,
  ...ipdGeneralMedicineScreen,
  ...pediatricScreen,
  ...generalSurgeryScreens,
  ...icuScreens,
  ...neurologyScreens,
};
