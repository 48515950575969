import React from 'react';

import { View } from '@applane/react-core-components';

import { ScreenContextProvider } from '../../app-components/screen/ScreenContextProvider';
import { NotificationContextProviderHoc } from '../../AppNotification';
import { getUser } from '../../AppServices';
import Header from './Header';

const AppContainer = (props) => {
  const { children, ...restProps } = props;
  const user = getUser();

  let wrappedComponent = (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <ScreenContextProvider>
        <Header {...restProps} user={user} />
        <View
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {children}
        </View>
      </ScreenContextProvider>
    </View>
  );

  if (user) {
    wrappedComponent = (
      <NotificationContextProviderHoc
        navigation={restProps.navigation}
        eventDispatcher={restProps.eventDispatcher}
      >
        {wrappedComponent}
      </NotificationContextProviderHoc>
    );
  }
  return wrappedComponent;
};

export default AppContainer;
