import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { currencyInput } from '../../../../../../../../compositeInputs';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const AddServiceIPDForm = (props) => (

  <ListActionModal
    title="Service"
    description="Add service details for the record."

    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Save',
            }),
          ],
        }}

        closeView={1}
        reloadEvent={`reload${MODELS.MISCELLANEOUS_ACCOUNT}`}
        defaultValues={() => ({
          currency: 'INR',
        })}
        mandatory={{
          service: 1,
          unit: 1,
          rate: 1,
          amount: 1,
        }}
        computations={{
          self: {
            rate: {
              compute: (data) => ({ set: { amount: data?.unit * data?.rate } }),
              onChange: ['rate', 'unit'],
            },
          },
        }}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            admissionId: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.MISCELLANEOUS_ACCOUNT,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}

        lg={{
          formGroups: [
            {
              columnsPerRow: 2,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
                paddingTop: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columnsPerRow: 1,
                  columns: [
                    {
                      type: 'text',
                      editable: true,
                      label: 'Service',
                      field: 'service',
                      mandatory: true,
                      allowWhiteSpaces: true,
                    },
                  ],
                },
                {
                  columnsPerRow: 2,
                  columns: [
                    {
                      type: 'number',
                      field: 'unit',
                      label: 'Unit',
                      mandatory: true,
                      editable: true,

                    },
                    {
                      ...currencyInput({
                        mandatory: true,
                        variant: 'filled',
                        amount: {
                          type: 'number',
                          field: 'rate',
                          label: 'Rate',
                        },
                        currency: {
                          field: 'currency',
                        },
                      }),
                    },
                    {
                      type: 'number',
                      field: 'amount',
                      label: 'Amount',
                      mandatory: true,
                      editable: false,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default AddServiceIPDForm;
