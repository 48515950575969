import React from 'react';
import numWords from 'num-words';

import { Text, View } from '@applane/react-core-components';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import { LargeButton } from '../../../../app-components';
import { ROUTES } from '../../../../Lib/constants';
import { contingentVoucher } from '../../../../images';
import vars from '../../../../theme/vars';

const CotingentVoucherPreview = (props) => {
  const { navigation: { state: { params: { item } } } } = props;

  return (
    <ListActionModal
      icon={contingentVoucher}
      title="Contingent Voucher"
      content={(
        <Form
          type="standardShadow"
          {...props}
          defaultValues={{
            formData: item.formData,
            soNumber: item.uniqueNumber,
          }}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                direction: 'row',
                editable: false,
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'soNumber',
                        label: 'SO Number',
                      },
                      {
                        type: 'text',
                        render: () => <Text style={vars.headings.h8}>{item?.formData?.head?.name}</Text>,
                        label: 'Head',
                      },
                      {
                        type: 'text',
                        render: () => <Text style={vars.headings.h8}>{item?.formData?.accountant?.name}</Text>,
                        label: 'Accountant',
                      },
                      {
                        type: 'text',
                        field: 'formData.subject',
                        label: 'Subject',
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <Text style={{ fontStyle: 'italic', fontSize: 14 }}>
                            The bill placed below has been received for
                            payment/adjustment duly verified/inspected by the
                            competent authority and entered in the concerned stock
                            register. The purchase/expenditure has been made after
                            obtaining the approval of the director/Competent
                            authority.
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                editable: false,
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'text',
                        field: 'formData.nameOfTheFirm',
                        label: 'Name Of Firm',
                      },
                      {
                        type: 'text',
                        field: 'formData.billNumber',
                        label: 'Bill Number',
                      },
                      {
                        type: 'date',
                        format: 'DD/MM/YYYY',
                        field: 'formData.date',
                        label: 'Date',
                      },
                      {
                        type: 'text',
                        field: 'formData.amount',
                        label: 'Amount',
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <Text style={{ fontStyle: 'italic', fontSize: 14 }}>
                            Kindly accord approval for the payment/adjustment of Rs.
                            {' '}
                            {item?.formData?.amount}
                            {' '}
                            (
                            {numWords(item?.formData?.amount)}
                            {' '}
                            only)
                          </Text>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                direction: 'row',
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                          }}
                          >
                            <LargeButton
                              label="Send for Approval"
                              onClick={({ data }) => props.navigation.push({
                                view: ROUTES.cotingentVoucherApproval.name,
                                params: { item: { formData: data, ...item } },
                              })}
                              width={230}
                              // height={40}
                              borderRadius={4}
                            />
                            <LargeButton
                              label="Send and Print"
                                // onClick={formContext.handleSubmit}
                              width={230}
                              // height={40}
                              borderRadius={4}
                            />
                          </View>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default CotingentVoucherPreview;
