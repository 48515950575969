import React from 'react';

import { FilterGroup, StandardButton } from '../../app-components';
import { filterActive, filterInactive } from '../../images';
import EmployeeRemarkList from './EmployeeRemarkList';
import { searchFilter } from '../../autoSuggestions';
import { MODELS, ROUTES } from '../../Lib/constants';
import EditRemark from './EditRemark';
import AddRemark from './AddRemark';

export default {
  [ROUTES.EmployeeRemarkList.name]:
  {
    screen: EmployeeRemarkList,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <StandardButton label="Add Remark" />
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.AddRemark.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
      FilterGroup({
        icon: filterInactive,
        activeIcon: filterActive,
        filters: [
          {
            type: 'autoSuggest',
            field: 'month',
            label: 'Month',
            model: MODELS.MONTHS,
            id: 'MonthTypeSuggestions',
            filterField: 'key',
            fieldType: 'text',
            // searchField: 'month',
            // placeholder: 'Months',
            // suggestionField: 'key',
            primaryField: 'name',
            idField: 'name',
          },
          {
            type: 'autoSuggest',
            field: 'year',
            label: 'Year',
            model: MODELS.years,
            id: 'YearTypeSuggestions',
            searchField: 'name',
            placeholder: 'Year',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
            keyField: 'name',
            filterField: 'name',
            fieldType: 'text',
          },
          searchFilter({
            field: 'employee',
            model: MODELS.EMPLOYEE,
            id: 'employeeLists',
            searchField: 'name',
            label: 'Employee',
            suggestionField: 'fullName',
            multiSelect: true,
          },
          {
            modifyResult: ({ data }) => {
              const clonedData = data.map((item) => ({
                ...item,
                fullName: `${item?.firstName} ${item?.lastName}`,
              }));

              return { data: clonedData };
            },
          }),
          {
            type: 'autoSuggest',
            field: 'employee.employeeType',
            label: 'Employee Type',
            model: MODELS.EMPLOYEETYPE,
            id: 'masterDataList',
            searchField: 'name',
            placeholder: 'Select Employee Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
          },
          {
            type: 'autoSuggest',
            field: 'employee.staffType',
            label: 'Staff Type',
            model: MODELS.STAFFTYPE,
            id: 'masterDataList',
            searchField: 'name',
            placeholder: 'Select Staff Type',
            suggestionField: 'name',
            primaryField: 'name',
            valueField: 'name',
          },
        ],
      }),
    ],
  },

  [ROUTES.AddRemark.name]:
   {
     screen: AddRemark,
     expanded: true,
     modal: true,
     modalProps: {
       width: 600,
     },
   },

  [ROUTES.EditRemark.name]:
   {
     screen: EditRemark,
     expanded: true,
     modal: true,
     modalProps: {
       width: 600,
     },
   },
};
