import React from 'react';
import { FormField, StandardButton, View } from '../../../../app-components';
import { notificationTickWithBG } from '../../../../images';
import ListActionModal from '../../../../Components/ListActionModal';
import { Form } from '../../../../app-components/form/Form';
import vars from '../../../../theme/vars';
import { submit } from '../../../../AppServices';
import { MODELS } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';

const EditManufacturerModal = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  return (
    <ListActionModal
      icon={notificationTickWithBG}
      title={'pharmacy.labels.manufacturer'.getLabel()}
      description={'pharmacy.title.editManufacturerDescription'.getLabel()}
      content={(
        <Form
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          reloadEvent={`reload ${MODELS.PHARMACY_MANUFACTURER}`}
          mandatory={{
            name: 1,
          }}
          onSubmit={submit({
            model: MODELS.PHARMACY_MANUFACTURER,
          })}
          submitMessage={'pharmacy.messages.manufacturerUpdatedSuccessfully'.getLabel()}
          defaultValues={() => ({
            ...item,
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View style={{
              paddingBottom: 24, paddingTop: 24, paddingLeft: 22, paddingRight: 22,
            }}
            >
              <FormField
                type="text"
                label={'pharmacy.labels.manufacturerName'.getLabel()}
                variant="filled"
                field="name"
                allowWhiteSpaces
                modifyValueOnChange={({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                }}
                style={{
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                mandatory
              />
              <FormField
                type="textArea"
                label={'pharmacy.labels.address'.getLabel()}
                variant="filled"
                field="address"
                minHeight={80}
                style={{
                  marginTop: 16,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'pharmacy.buttons.update'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default EditManufacturerModal;
