/* eslint-disable max-len */
import React from 'react';

import { size } from 'lodash';
import {
  Image,
  PharmacyCentralInventoryToggleFilter,
  Text,
  TouchableOpacity,
} from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { PharmacyCentralInventoryItemListUri } from '../../../../../Queries/pharmacy';
import { editRecord } from '../../../../../images';
import { MODELS, ROUTES } from '../../../../../Lib/constants';
import WithPermission from '../../../../../Components/WithPermission';
import permissions from '../../../../../Lib/permissions';
import vars from '../../../../../theme/vars';
import { getPermission } from '../../../../../AppServices';

const header = ({ queryID = 'masterDataListForTable' }) => ({
  leftActions: [
    (props) => (
      <PharmacyCentralInventoryToggleFilter
        {...props}
        model={MODELS.PHARMACY_ITEM}
        id={queryID}
      />
    ),
  ],
});

const tableItems = ({
  queryID,
  editItemPermissionId,
  raisePurchaseRequestPermissionId,
  raisePurchaseRequestDefaultValues,
}) => ({
  lg: {
    header: header({ queryID }),
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'Item ID',
        type: 'text',
        field: 'itemCode',
      },
      {
        header: 'Item Name',
        type: 'text',
        field: 'name',
        sortable: 'name',
      },
      {
        header: 'Category',
        type: 'text',
        value: ({ item }) => item?.category?.name,
      },
      {
        header: 'Sub- category',
        type: 'text',
        value: ({ item }) => item?.subCategory?.name,
      },
      {
        header: 'description',
        type: 'text',
        field: 'description',
      },
      {
        header: 'stock available',
        type: 'text',
        value: ({ item: { stockAvailable } }) => stockAvailable?.map((item) => item?.stockAvailable).toString(),
      },
      {
        header: 'Status',
        type: 'text',
        render: ({ item: { stockStatus } = {} }) => {
          const stocksStatus = stockStatus
            ?.map((item) => item?.stockStatus)
            .toString();
          let statusColor = vars.colors.warning;
          if (stocksStatus === 'In-Stocks') {
            statusColor = vars.colors.primary.color2;
          } else if (stocksStatus === 'Out-of-Stock') {
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {stocksStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <WithPermission
            access_key={editItemPermissionId || permissions.inventory.views.editPharmacyItem.permissions.editPharmacyItem.value}
          >
            <TouchableOpacity>
              <Image
                style={{
                  height: 24,
                  width: 24,
                }}
                source={editRecord}
              />
            </TouchableOpacity>
          </WithPermission>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.editInventoryItem.name,
            params: { item, queryID },
          },
        }),
      },
      {
        type: 'moreActions',
        width: 15,
        actions: [
          {
            type: 'link',
            text: 'inventory.buttons.raisePurchaseRequest'.getLabel(),
            visible: () => getPermission(
              raisePurchaseRequestPermissionId || permissions.inventory.views.raisePurchaseRequestPharmacy
                .permissions.raisePurchaseRequestPharmacy.value,
            ),
            link: ({ item }) => ({
              view: ROUTES.purchaseRequest.name,
              params: {
                defaultValues: raisePurchaseRequestDefaultValues,
                item: {
                  item: {
                    _id: item._id,
                    category: item.category,
                    subCategory: item.subCategory,
                    reorderLevel: item.reorderLevel,
                    sellingPrice: item.sellingPrice,
                    rateContract: item.rateContract,
                    name: item?.name,
                    code: item?.itemCode,
                    itemCode: item?.itemCode,
                    stockAvailable: item?.stockAvailable,
                    avgMonthlyConsumption: item?.avgMonthlyConsumption,
                    lastPurchaseQuantity: item?.lastPurchaseQuantity
                      ? item?.lastPurchaseQuantity[
                        size(item.lastPurchaseQuantity) - 1
                      ].requiredQuantity
                      : 0,
                    lastPurchaseDate: item?.lastPurchaseDate
                      ? item?.lastPurchaseDate[size(item.lastPurchaseDate) - 1]
                        ._createdOn
                      : null,
                  },
                },
              },
            }),
          },
        ],
      },
    ],
  },
});

export default (props) => {
  const {
    queryID = 'masterDataListForTable',
    editItemPermissionId,
    raisePurchaseRequestPermissionId,
    raisePurchaseRequestDefaultValues,
    defaultValues,
    hidePatientDispensing,
  } = props;

  return (
    <Table
      {...props}
      reloadEvent={`reload${MODELS.PHARMACY_ITEM}`}
      uri={() => PharmacyCentralInventoryItemListUri({ queryID })}
      {...tableItems({
        queryID,
        editItemPermissionId,
        raisePurchaseRequestPermissionId,
        raisePurchaseRequestDefaultValues,
      })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewInventoryItem.name,
          params: {
            item,
            queryID,
            defaultValues,
            hidePatientDispensing,
          },
        },
      })}
    />
  );
};
