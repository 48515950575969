const decodeAction = ({ path, action }) => {
  if (!action) {
    return path;
  }
  if (typeof action === 'string') {
    path += `:${action}`;
    return path;
  } if (action.view) {
    path += `:${action.view}`;
    return decodeAction({ path, action: action.action });
  }
};

export const getPathName = () => {
  const { pathname, hash, search } = typeof window !== undefined ? window.location : { pathname: '/' };
  return { pathName: pathname };
};

export const setPathName = ({ pathName, replace, remove }) => {
  const { pathname: currentPathName, hash, search } = typeof window !== undefined ? window.location : { pathname: '/' };

  const { history } = window;
  if (history && currentPathName !== pathName) {
    if (remove) {
      let removeCount = 1;
      if (typeof remove === 'number') {
        removeCount = 1;
      }
      history.go(removeCount);
    } else if (replace) {
      history.replaceState({}, '', pathName);
    } else {
      history.pushState({}, '', pathName);
    }
  }
};

export const setLocationView = ({ view, action }) => {
  let path = '/';
  if (view && typeof view === 'string') {
    path += view;
  }
  path = decodeAction({ path, action });

  const { pathname: currentPathname, search: currentSearch } = window.location;

  const { history } = window;
  if (history && currentPathname !== path) {
    history.pushState({}, '', path);
  }
};
export const getLocationView = ({ pathName }) => {
  const slashIndex = pathName.indexOf('/');

  if (slashIndex >= 0) {
    pathName = pathName.substring(slashIndex + 1);
  }
  if (!pathName) {
    return void 0;
  }
  const actions = pathName.split(':');
  if (actions && actions.length) {
    const view = {
      view: actions[0],
    };

    if (actions.length > 1) {
      let lastViewAction = view;
      for (let index = 1; index < actions.length; index++) {
        lastViewAction.action = {
          view: actions[index],
        };
        lastViewAction = lastViewAction.action;
      }
    }
    return view;
  }
};

export const getDefaultLocationView = (view) => {
  let { pathname, hash, search } = typeof window !== undefined ? window.location : { pathname: '/' };

  if (pathname === '/login' || pathname === '/otp') {
    pathname = '/';
  }
  const locationView = getLocationView({ pathName: pathname }) || view;
  return locationView;
};
