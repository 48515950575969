import React, { useState } from 'react';

import { Form } from '../../../../../../../app-components/form/Form';
import { save, StandardButton } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { submit } from '../../../../../../../AppServices';
import vars from '../../../../../../../theme/vars';

const outcomeTypes = ['Discharge', 'Discharged on patient request', 'Left against medical advice', 'Absconded', 'Referred to Higher Center', 'Death'];

const Outcome = (props) => {
  const { dataParams } = props;
  const { navigation } = props;
  const { state: { params: { patient = {} } } } = navigation;
  const [isDone, setIsDone] = useState(false);
  const [isDischarge, SetIsDischarge] = useState(false);
  const [isDeath, SetIsDeath] = useState(false);
  const [isVisible, SetIsVisible] = useState(false);
  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      closeView={1}
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        const newUpdate = {
          ...updates,
          admissionId:
          {
            _id: submitProps?.navigation?.state?.params?.patient?._id,
          },
        };
        const submitAPI = submit({
          model: MODELS.IPD_OUTCOME,
          data,
          updates: newUpdate,
        });
        await submitAPI({ data, updates: newUpdate });
      }}
      computations={{
        self: {
          outcome: {
            compute: (data) => {
              if (data?.outcome === 'Left against medical advice' || data?.outcome === 'Absconded' || data?.outcome === 'Referred to Higher Center') {
                return (
                  setIsDone(true),
                  SetIsDischarge(false),
                  SetIsDeath(false),
                  SetIsVisible(true)
                );
              }
              if (data?.outcome === 'Discharge' || data?.outcome === 'Discharged on patient request') {
                return (
                  setIsDone(false),
                  SetIsDischarge(true),
                  SetIsVisible(false),
                  SetIsDeath(false)
                );
              }
              if (data?.outcome === 'Death') {
                return (
                  SetIsVisible(false),
                  setIsDone(false),
                  SetIsDischarge(false),
                  SetIsDeath(true)
                );
              }
              return {
                isDone,
                isDischarge,
                isDeath,
              };
            },
            onChange: ['outcome'],
          },
        },
      }}
      lg={{
        header: isVisible ? {
          actions: [save()],
        } : null,
        formGroups: [
          {
            columnsPerRow: 1,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'radioGroup',
                    field: 'outcome',
                    label: 'OUTCOME',
                    container: 'topLabel',
                    options: outcomeTypes,
                    editable: true,
                  },
                  {
                    type: 'textArea',
                    field: 'lamaNote',
                    container: 'bottomLabel',
                    allowWhiteSpaces: true,
                    minHeight: 80,
                    label: 'Description',
                    editable: true,
                    visible: () => isDone,
                  },
                  {
                    type: 'custom',
                    render: () => (
                      <StandardButton
                        label="Fill Discharge Card"
                        onClick={() => navigation.push({
                          view: ROUTES.dischargeCard.name,
                          params: { patient, outcome: 'Discharge', dataParams },

                        })}
                      />
                    ),
                    width: 200,
                    visible: () => isDischarge,
                  },
                  {
                    render: () => (
                      <StandardButton
                        label="Fill Death Card"
                        onClick={() => navigation.push({
                          view: ROUTES.deathCard.name,
                          params: { patient, outcome: 'Death', dataParams },
                        })}
                      />
                    ),
                    width: 200,
                    visible: () => isDeath,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default Outcome;
