/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React from 'react';
import { Text } from 'react-native';
import moment from 'moment';
import { Table } from '../../../../app-components/table/Table';
import { MODELS, vaccinationStatus, vaccinationType } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const tabListColumns = [
  { width: 14 },
  {
    header: 'vaccination.headers.lastVaccinationDate'.getLabel(),
    type: 'date',
    field: 'lastVaccination._createdOn',
  },
  {
    header: 'vaccination.headers.vaccineName'.getLabel(),
    type: 'text',
    field: 'item.name',
  },
  {
    header: 'vaccination.headers.daysBeforeReVaccination'.getLabel(),
    type: 'text',
    render: ({ item: { item, vaccineDoseTaken = 0 } } = {}) => {
      const { vaccinationReOccurringDetails = [] } = item;
      if (!vaccineDoseTaken) {
        return '--';
      }
      let daysBeforeRevaccination = '';
      if (item?.vaccinationType === vaccinationType.reCurring && item?.isReoccurringIntervalEqual) {
        const { intervalType, interval } = vaccinationReOccurringDetails[0];
        daysBeforeRevaccination = `${interval || '0'} ${intervalType}`;
      } else if (item?.vaccinationType === vaccinationType.reCurring) {
        const { interval, intervalType } = vaccinationReOccurringDetails[vaccineDoseTaken - 1];
        daysBeforeRevaccination = `${interval || '0'} ${intervalType}`;
      } else if (item?.vaccinationType === vaccinationType.onceInLifeTime) {
        daysBeforeRevaccination = 'Once in Lifetime';
      }
      return (
        <Text>
          {daysBeforeRevaccination}
        </Text>
      );
    },
  },
  {
    header: 'vaccination.headers.doseNo'.getLabel(),
    type: 'text',
    render: ({ item: { item, vaccineDoseTaken = 0, vaccinationSoFar = [] } } = {}) => {
      if (!vaccineDoseTaken) return 1;
      const vaccinationSoFarLength = vaccinationSoFar?.length;
      if (vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase() || item?.noOfDoses === vaccineDoseTaken) {
        if (item?.noOfDoses >= vaccineDoseTaken) return vaccineDoseTaken;
      } else if (item?.noOfDoses > vaccineDoseTaken) return vaccineDoseTaken + 1;
    },
  },
  {
    header: 'vaccination.headers.doseDuseDate'.getLabel(),
    type: 'text',
    render: ({
      item: {
        item, vaccineDoseTaken = 0, lastVaccination,
      },
    }) => {
      let doseDueDate = '';
      if (item?.vaccinationType === vaccinationType.onceInLifeTime || !vaccineDoseTaken) {
        doseDueDate = new Date();
        return (
          <Text>
            {moment().format('DD MMM YYYY')}
          </Text>
        );
      }
      if (item?.isReoccurringIntervalEqual) {
        const intervalType = item?.vaccinationReOccurringDetails[0]?.intervalType;
        const doseDueDateInterval = item?.vaccinationReOccurringDetails[0]?.interval || 0;
        doseDueDate = moment(lastVaccination?._createdOn)?.add(doseDueDateInterval, intervalType);
        doseDueDate = moment(doseDueDate).format('DD MMM YYYY');
        return (
          <Text>
            {doseDueDate}
          </Text>
        );
      }
      const intervalType = item?.vaccinationReOccurringDetails[vaccineDoseTaken - 1]?.intervalType;
      const doseDueDateInterval = item?.vaccinationReOccurringDetails[vaccineDoseTaken - 1]?.interval || 0;
      doseDueDate = moment(lastVaccination?._createdOn)?.add(doseDueDateInterval, intervalType);
      doseDueDate = moment(doseDueDate).format('DD MMM YYYY');
      return (
        <Text>
          {doseDueDate}
        </Text>
      );
    },
  },
  {
    header: 'vaccination.headers.doseStatus'.getLabel(),
    type: 'text',
    render: ({ item } = {}) => {
      const { vaccinationSoFar = [] } = item;
      const vaccinationSoFarLength = vaccinationSoFar?.length;
      let status = '';
      let color = '';
      if (vaccinationSoFar[vaccinationSoFarLength - 1]?.status === vaccinationStatus?.provided.toLowerCase()) {
        status = 'Provided';
        color = vars.colors.primary.color2;
      } else {
        status = 'Pending';
        color = vars.colors.warning;
      }
      return (
        <Text style={{ color }}>
          {status}
        </Text>
      );
    },
  },
];

const PatientVaccinationLogList = (props) => {
  const {
    data,
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    ...restProps
  } = props;

  return (
    <Table
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      reloadEvent={`reload${MODELS.PATIENT_VACCINATION_HISTORY}`}
      columns={tableColumns}
      showActionIcon={false}
    />
  );
};
export default PatientVaccinationLogList;
