
import React from 'react';

import {
  Text,
  View,
  StandardButton,
  TouchableOpacity,
  Image,
} from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants'; import {
  breadCrumbBack,
  breadcrumbSeperator,
} from '../../../../../../../../images';

const tableItemHeading = [
  {
    heading: 'Time',
    paddingLeft: 15,
  },
  {
    heading: 'InTake',
    subHeading: [
      { name: 'Infusion(ml)', width: 450 },
      'Total',
      'RT/Oral (ml)',
      'Total Intake',
    ],
    subSubHeading: [
      '1', '2', '3',
    ],
  },
  {
    heading: 'Output',
    subHeading: [
      { name: 'Urine(ml)', width: 300 },
      { name: 'Drainage(ml)', width: 450 },
      'Total',
      'RT Aspiration',
      'Stool(ml)',
      'Total Output',
    ],
    subSubHeading: [
      'hrly',
      'Total (ml)',
      '1', '2', '3',
    ],
  },
  {
    heading: 'balance',
  },
];

const tableHeadingWidth = [
  150, 900, 1350];

const heading = (item, widthHeader) => (
  <View style={{
    width: widthHeader,
    display: 'flex',
    flexFlow: 'wrap',
    flexDirection: 'row',
    backgroundColor: '#EFF3FB',
  }}
  >
    <Text
      style={{
        width: widthHeader,
        display: 'flex',
        flexFlow: 'wrap',
        marginBottom: 15,
        marginTop: 15,
        // fontSize: 15,
        paddingLeft: item.paddingLeft || 0,
      }}
    >
      {item.heading}
    </Text>
    { item.subHeading ? item.subHeading.map((data, index) => (
      <Text
        key={index}
        style={{
          width: data.width || 150,
          display: 'flex',
          lineHeight: 4,
          marginBottom: 12,
          flexFlow: 'wrap',
          paddingBottom: 10,
        }}
      >
        {data.name ? data.name : data}
      </Text>
    )) : (
      <Text
        style={{
          width: 150,
          display: 'flex',
          flexFlow: 'wrap',
          lineHeight: 4,
          marginBottom: 12,
        }}
      >
        {'  '}
      </Text>
    )}
    { item.subSubHeading ? item.subSubHeading.map((data, index) => (
      <Text
        key={index}
        style={{
          width: 150,
          display: 'flex',
          lineHeight: 4,
          marginBottom: 12,
          flexFlow: 'wrap',
          paddingBottom: 10,
        }}
      >
        {data}
      </Text>
    )) : (
      <Text
        style={{
          width: 150,
          display: 'flex',
          flexFlow: 'wrap',
          lineHeight: 4,
          marginBottom: 12,
        }}
      >
        {'  '}
      </Text>
    )}
  </View>
);

const tableItems = {
  lg: {
    columns: [
      {
        type: 'date',
        format: 'HH:mm A',
        width: 150,
        field: 'inputOutputTime',
      },
      {
        type: 'text',
        width: 150,
        field: 'intakeOne',

      },
      {
        type: 'text',
        width: 150,
        field: 'intakeTwo',
      },
      {
        type: 'text',
        width: 150,
        field: 'intakeThree',
      },
      {
        type: 'text',
        width: 150,
        field: 'totalIntakeOneToThree',
      },
      {
        type: 'text',
        width: 150,
        field: 'rtOral',
      },
      {
        type: 'text',
        width: 150,
        field: 'totalIntake',
      },
      {
        type: 'text',
        width: 150,
        field: 'urineHrly',
      },
      {
        type: 'text',
        width: 150,
        field: 'totalUrineOutput',
      },
      {
        type: 'text',
        width: 150,
        field: 'drainageOne',
      },
      {
        type: 'text',
        width: 150,
        field: 'drainageTwo',
      },
      {
        type: 'text',
        width: 150,
        field: 'drainageThree',
      },
      {
        type: 'text',
        width: 150,
        field: 'totalDrainageOneToThree',
      },
      {
        type: 'text',
        width: 150,
        field: 'rtAspiration',
      },
      {
        type: 'text',
        width: 150,
        field: 'stool',
      },
      {
        type: 'text',
        width: 150,
        field: 'totalOutPut',
      },
      {
        type: 'text',
        width: 150,
        field: 'balance',
      },

    ],
  },
};

const ViewIPDInputOutputChart = (props) => {
  const { navigation: { state: { params } } } = props;
  const { navigation } = props;
  return (
    <Table
      {...props}
      scrollable
      renderHeaderRow={
        tableItemHeading.map((item, index) => heading(item, tableHeadingWidth[index]))
}
      headerRowContainerStyle={{
        height: 100,
        marginLeft: 25,
        scrollX: true,
      }}
      header={() => ({
        leftAction: {
          render: () => (
            <View
              style={{
                height: 64,
                flexDirection: 'row',
                margin: -23,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.replace({
                    view: ROUTES.inputOutputChart.name,
                    params: {
                      patient: params?.patient,
                    },
                  });
                }}
              >
                <Image
                  source={breadCrumbBack}
                  style={{
                    margin: '16px',
                  }}
                />
              </TouchableOpacity>
              <Text
                style={
                  {
                    margin: '12px 0px 0px 3px',
                    textAlign: 'center',
                    color: '#307343',
                  }
                }
              >
                Monitoring Chart
                <Image
                  source={breadcrumbSeperator}
                  style={{ margin: 10 }}
                />
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  padding: '13px 0px 0px 0px',
                  fontSize: '18px',
                  color: '#333333',
                }}
              >
                {params.selectedDate}
              </Text>
            </View>
          ),
        },
        actions: [

          {
            type: 'link',
            style: {
              justifyContent: 'space-between',
            },
            render: () => (
              <StandardButton
                label="Log"
              />
            ),
            link: () => ({
              view: ROUTES.logInputOutputChart.name,
              params: { ...params },
              push: true,
              modal: true,
              modalProps: {
                autoHide: true,
                maxHeight: '100%',
                height: 547,
              },
            }),
          },
        ],
      })}
      uri={() => ({
        query: {
          id: 'inputOutputChartList',
          addOnFilter: {
            admissionID: { _id: params?.item?.admissionID?._id },
            speciality: params?.dataParams?.filters?.specialityFilter,
            inputOutput: {
              $eq: new Date(
                new Date(
                  new Date(
                    new Date(
                      new Date(params?.selectedDate).setUTCHours(0),
                    ).setUTCMinutes(0),
                  ).setUTCSeconds(0),
                ).setUTCMilliseconds(0),
              ),
            },
          },
        },
        model: MODELS.INPUT_OUTPUT_CHART,
      })}
      {...tableItems}
      reloadEvent={`reload${MODELS.INPUT_OUTPUT_CHART}`}
      reloadOnChangeEvent={`reload${MODELS.INPUT_OUTPUT_CHART}`}
    />
  );
};

const ViewInputOutputChart = (props) => (<ViewIPDInputOutputChart {...props} />);
export default ViewInputOutputChart;

