import React from 'react';

import { Text } from '../../../../../../../app-components';

const Investigation = () => (
  <Text style={{
    textAlign: 'center',
    justifyContent: 'center',
    padding: '180px',
  }}
  >
    Cooming soon....
  </Text>
);
export default Investigation;
