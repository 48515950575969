import React from 'react';
import { OutlineButton, TabNavigator } from '../../../../../../app-components';
import { countUri } from '../../../../../../Queries/countUri';
import { MODELS, ROUTES } from '../../../../../../Lib/constants';
import BedList from '../../../Bed/BedList';

const ViewRoomTabs = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  // const { navigation, showAddButton = false } = props;
  const showAddButton = item?.roomCategories?.name !== 'Private Room';
  if (!item?._id) {
    return null;
  }
  const RoomsTabNavigations = TabNavigator({
    tabs: {
      SubCategory: {
        label: 'Beds',
        screen: BedList,
        screenProps: {
          item,
          model: MODELS.BED,
          id: 'masterDataList',
          addOnFilter: {
            room: { _id: item?._id },
          },
        },
        countUri: countUri({
          model: MODELS.BED,
          id: 'masterDataList',
          filter: {
            room: { _id: item?._id },
          },
        }),
        actions: [
          {
            type: 'link',
            render: () => (showAddButton ? <OutlineButton label={'Add Bed'.getLabel()} /> : null),
            link: () => ({
              view: ROUTES.addBed.name,
              modal: true,
              params: {
                room: {
                  _id: item?._id,
                },
              },
              modalProps: {
                autoHide: true,
                width: 404,
                height: 496,
              },
            }),
          },
        ],
      },
      // ActivityLogs: {
      //   label: 'Activity Logs',
      //   screen: ActivityLogsTabList,
      //   screenProps: {
      //     item,
      //     model: MODELS.BED,
      //     id: 'bedLists',
      //     addOnFilter: { id: item?._id },
      //   },
      //   countUri: countUri({
      //     model: MODELS.BED,
      //     id: 'getHistoryActivityCount',
      //     filter: { id: item?._id },
      //   }),
      // },
    },
  });
  return <RoomsTabNavigations {...props} />;
};
export default ViewRoomTabs;
