import React, { useEffect } from 'react';
import moment from 'moment';

import { Text, View } from '../../../../app-components';
import { autoSuggestInput, searchInput } from '../../../../autoSuggestions';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';
import { Form } from '../../../../app-components/form/Form';
import { EditUnknownPatientUri } from '../../../../Queries/patient';
import { UploadImage } from '../../../../app-components/input-components/Editors';

const ViewUnknownPatientForm = (props) => {
  const { navigation } = props;
  const { state: { params } = {} } = navigation;
  useEffect(() => {
    if (params?.openAdmitForm) {
      navigation.push(
        {
          view: ROUTES.admitPatientToIPD.name,
          params,
        },
      );
    }
  }, []);

  return (
    <Form
      type="standard"
      editable={false}
      uri={EditUnknownPatientUri}
      {...props}
      reloadEvent="reloadOPDRegisteredPatients"
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 1,
                width: 300,
                columns: [
                  {
                    type: 'uploadImage',
                    nonEditable: true,
                    field: 'patientImage',
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'date',
                    field: 'date',
                    label: 'patient.labels.date'.getLabel(),
                    render: ({ data } = {}) => (
                      <Text style={{ ...vars.headings.h9 }}>
                        {moment(data?.date).format('DD/MM/YYYY')}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'time',
                    field: 'time',
                    label: 'Time',
                  },
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'patient.labels.patientName'.getLabel(),
                    allowWhiteSpaces: true,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (validateAlphabetsWithSpaceOnly(value)) {
                        return prevValue;
                      }
                      return value.replace(/ {2,}/g, ' ');
                    },
                  },
                  autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'patient.labels.department'.getLabel(),
                      placeholder: 'patient.placeholders.selectDepartment'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                      editable: false,
                    },
                    {
                      addOnFilter: {
                        code: 'IPD',
                      },
                    },
                  ),
                  searchInput(
                    {
                      field: 'speciality',
                      label: 'patient.labels.speciality'.getLabel(),
                      placeholder: 'patient.placeholders.selectWard'.getLabel(),
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                      searchField: 'name',
                      searching: true,
                    },
                    {
                      addOnFilter: { department: { code: 'IPD' } },
                    },
                  ),
                  {
                    type: 'textArea',
                    field: 'uniqueMark',
                    label: 'patient.labels.uniqueMark'.getLabel(),
                    placeholder: 'patient.placeholders.uniqueMark'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'remarks',
                    label: 'patient.labels.remarks'.getLabel(),
                    placeholder: 'patient.placeholders.remarks'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            label: 'patient.title.belongingsDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'patient.labels.belongingsCareBy'.getLabel(),
                    render: ({ data }) => {
                      const { belongingsCareBy } = data;
                      if (belongingsCareBy) {
                        return (
                          <Text style={{ ...vars.headings.h9 }}>
                            {belongingsCareBy?.firstName}
                            {' '}
                            {belongingsCareBy?.lastName}
                          </Text>
                        );
                      }
                      return '--';
                    },
                  },
                  {
                    type: 'textArea',
                    field: 'additionalComments',
                    variant: 'filled',
                    label: 'patient.labels.additionalComments'.getLabel(),
                    placeholder: 'patient.placeholders.additionalComments'.getLabel(),
                    style: { flex: 2 },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    type: 'custom',
                    render: ({ data: { belongingsDetails } = [] }) => {
                      if (belongingsDetails) {
                        return (
                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                              flexWrap: 'wrap',
                            }}
                          >
                            {belongingsDetails.map((need, index) => (
                              <View
                                style={{
                                  flexBasis: '50%',
                                  flexDirection: 'row',
                                  marginTop: 10,
                                }}
                                key="description"
                              >
                                <View>
                                  <UploadImage
                                    data={need}
                                    field="itemImage"
                                    editable={false}
                                    imageWidth={100}
                                    imageHeight={130}
                                  />
                                </View>
                                <View style={{ marginLeft: 10 }}>
                                  <View>
                                    <Text style={{ ...vars.headings.h9 }}>
                                      {index + 1}
                                      {'.'}
                                      {need?.itemName}
                                    </Text>
                                  </View>
                                  <View style={{ marginTop: 20 }}>
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.grey.color3,
                                      }}
                                    >
                                      {need?.description}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        );
                      } return <View />;
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewUnknownPatientForm;
