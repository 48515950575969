import React from 'react';
import {
  View, Image, Text,
} from '../../../../app-components';
import { Action } from '../../../../app-components/action/Action';
import { addPatient, existingPatient } from '../../../../images';
import { ROUTES } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const PatientRegistartions = (props) => (
  <View
    style={{
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <View style={{ flexDirection: 'row' }}>
      <Action
        action={{
          type: 'link',
          push: true,
          link: () => ({
            view: ROUTES.existingPatientModal.name,
            modal: true,
            params: {
              ...props,
              routes: {
                listRoute: ROUTES.PatientListOPD.name,
                viewRoute: ROUTES.viewPatientOPD.name,
              },
            },
            modalProps: {
              autoHide: true,
              maxHeight: '100%',
              width: 373,
              height: 535,
            },
          }),
        }}
        {...props}
      >
        <Image
          source={existingPatient}
        />
      </Action>
      <View style={{ marginLeft: 44 }}>
        <Action
          action={{
            type: 'link',
            push: true,
            link: () => ({
              view: ROUTES.addPatient.name,
              params: props,
            }),
          }}
          {...props}
        >
          <Image
            source={addPatient}
          />
        </Action>
      </View>
    </View>
    <View style={{
      width: 165,
      height: 46,
      marginTop: 50,
    }}
    >
      <Action
        action={{
          type: 'link',
          push: true,
          link: () => ({
            view: ROUTES.PatientListOPD.name,
          }),
        }}
        {...props}
      >
        <Text style={{
          ...vars.headings.h8,
          color: vars.colors.secondary.color2,
          textDecorationLine: 'underline',
        }}
        >
          {'patient.labels.viewRegisteredPatients'.getLabel()}
        </Text>
      </Action>
    </View>
  </View>
);
export default PatientRegistartions;
