import React, { useCallback, useEffect, useState } from 'react';

import {
  ActivityIndicator,
  Table,
} from '../../../../../app-components';
import { fetch } from '../../../../../AppServices';
import { MODELS } from '../../../../../Lib/constants';

const tableItems = {
  lg: {
    columns: [
      {
        header: 'vaccination.labels.serialNo'.getLabel(),
        type: 'text',
        field: 'item.serialNo',
      },
      {
        header: 'vaccination.labels.vaccineName'.getLabel(),
        type: 'text',
        field: 'item.name',
      },
      {
        header: 'vaccination.labels.doseNo'.getLabel(),
        type: 'text',
        field: 'dose',
      },
    ],
  },
};

const VaccineLogs = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;

  const [serialNo, setSerialNo] = useState(1);
  const [patientDetails, setPatientDetails] = useState();
  const [loading, setLoading] = useState(true);

  const fetchPatientDetails = useCallback(async () => {
    const fetchedPatientDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientVaccinationList',
            addOnFilter: {
              _id: item?._id,
            },
          },
          model: MODELS.VACCINATION,
        },
      },
    });
    setPatientDetails(fetchedPatientDetails?.data[0]);
    setLoading(false);
    return fetchedPatientDetails;
  }, [item]);

  useEffect(() => {
    fetchPatientDetails();
  }, [item]);

  if (serialNo <= patientDetails?.patientVaccineDetails.length) {
    patientDetails.patientVaccineDetails.map(({ item }, index) => item.serialNo = index + 1);
  }
  if (loading) return <ActivityIndicator />;
  const VaccineLogsTable = Table({
    data: [...patientDetails?.patientVaccineDetails],
    ...tableItems,
    hoverable: false,
  });

  return <VaccineLogsTable />;
};

export default VaccineLogs;

