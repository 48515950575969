import React from 'react';
import {
  View, Text, Image, TouchableOpacity,
} from '../../app-components';
import { backCrossIcon } from '../../images';
// import { backCrossIcon } from '../../images';
import vars from '../../theme/vars';

export default (props) => {
  const {
    content,
    icon,
    title,
    description,
    navigation,
    popCount = 1,
    renderDescription: RenderDescription,
  } = props;

  const {
    state: {
      params: {
        isModal = true,
      } = {},
    } = {},
  } = navigation;

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      {isModal ? (
        <View
          style={{ alignItems: 'flex-end', paddingRight: 20, paddingTop: 20 }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.pop(popCount);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Image
              source={backCrossIcon}
              style={{
                width: 16,
                height: 16,
              }}
            />
          </TouchableOpacity>
        </View>
      )
        : null}
      <View style={{ alignItems: 'center', flex: 1, overflow: 'hidden' }}>
        {isModal ? (
          <View style={{ marginTop: 4, flexDirection: 'row' }}>
            <Image
              source={icon}
              style={{
                objectFit: 'contains',
                height: 40,
              }}
            />
          </View>
        )
          : null}
        {isModal ? (
          <View
            style={{
              marginLeft: 46,
              marginRight: 46,
            }}
          >
            <View
              style={{
                marginTop: 16,
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  ...vars.headings.h5,
                  color: vars.neutrals.text1,
                  textAlign: 'center',
                }}
              >
                {title}
              </Text>
            </View>
            {(description || RenderDescription) ? (
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 8,
                }}
              >
                {RenderDescription ? (
                  <RenderDescription />
                ) : (
                  <Text
                    style={{
                      ...vars.headings.h11,
                      color: vars.neutrals.placeholder,
                      textAlign: 'center',
                    }}
                  >
                    {description}
                  </Text>
                )}
              </View>
            ) : <View style={{ marginTop: -10 }} />}
          </View>
        )
          : null}
        <View
          style={{
            flex: 1,
            marginTop: 24,
            paddingLeft: 22,
            paddingRight: 24,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {content}
        </View>
      </View>
      {/* <View style={{ flex: 1, overflow: 'hidden' }}> */}
      {/* </View> */}
    </View>
  );
};
