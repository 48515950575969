/* eslint-disable camelcase */
import React from 'react';
import {
  cancelButton,
  FilterGroup,
  OutlineButton,
  save,
  StandardButton,
  Text,
  Toast,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import {
  bloodGroup, genders, issueBloodUpdationRequestStatus, MODELS, ROUTES,
} from '../../../Lib/constants';
import CompanyManagementList from './ManageCompany/CompanyList';
import AddCompany from './ManageCompany/AddCompany';
import ViewCompany from './ManageCompany/ViewCompany/index';
import EditCompanyDetails from './ManageCompany/EditCompany';
import AddBloodDonation from './DonorAndDonation/Donation/AddBloodDonation';
import DonorList from './DonorAndDonation/Donor/DonorList';
import AddDonor from './DonorAndDonation/Donor/AddDonor';
import ViewDonor from './DonorAndDonation/Donor/ViewDonor';
import EditDonor from './DonorAndDonation/Donor/EditDonor';
import BloodBankTabs from './BloodBankInventory/BloodBankTabs';
import ViewBloodDonorDocumentListModal from '../../../Wired/BloodDonorDocumentList';
import RaiseBloodRequest from './BloodBankInventory/RaiseBloodRequest';
import ViewBloodGroup from './BloodBankInventory/ViewBloodGroup';
import ViewBloodCategory from './BloodBankInventory/ViewBloodCategory';
import IssueBlood from './BloodBankInventory/ViewBloodCategory/components/IssueBlood';
import BloodBagListModal from './BloodBankInventory/ViewBloodCategory/components/BloodBagListModal';
import IssueToCompanyModal from './BloodBankInventory/ViewBloodCategory/components/IssueToCompanyModal';
import ViewBloodDonation from './Records/ViewBloodDonation';
import BloodBankRecordsTabs from './Records/components/tabs';
import BloodRequestList from './Requests/RequestList';
import ViewBloodRequest from './Requests/ViewRequest';
import ViewBloodIssue from './Records/ViewBloodIssue';
import { getErrorString } from '../../../Lib/helpers';
import { invoke } from '../../../AppServices';
import { filterActive, filterInactive } from '../../../images';
import vars from '../../../theme/vars';
import IssueBloodRequestMSModal from './BloodBankInventory/ViewBloodCategory/components/IssueBloodMSRequestModal';
import BloodUpdationRequestList from './MSRequests/MsRequestList';
import ViewBloodUpdationRequest from './MSRequests/ViewUpdationRequest';
import { rejectBloodRequest, requestSampleForBloodRequest } from './helpers';
import { BLOOD_REQUEST_STATUSES } from './constants';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];

const getHeaderActionToRoute = (params) => {
  const { route, label, access_key } = params;

  return {
    render: () => (
      <WithPermission access_key={access_key}>
        <StandardButton label={label} />
      </WithPermission>
    ),
    type: 'link',
    link: (props) => ({
      view: route,
      push: true,
      expanded: true,
      params: { ...props?.navigation?.state?.params },
    }),
  };
};

export default {
  [ROUTES.companyManagementList.name]: {
    screen: CompanyManagementList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label="Company Management"
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['name', 'email', 'contactNo'] }}
          />
        ),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Company',
        route: ROUTES.addCompany.name,
        access_key:
          permissions.inventory.views.addCompany.permissions.addCompany.value,
      }),
    ],
  },
  [ROUTES.addCompany.name]: {
    screen: AddCompany,
    expanded: true,
    headerActions: headerActionsForAddForm,
    title: () => (
      <Text style={{ ...vars.headings.h8 }}>
        ADD COMPANY
      </Text>
    ),
  },
  [ROUTES.viewCompany.name]: {
    screen: ViewCompany,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    expanded: true,
    headerActions: [
      getHeaderActionToRoute({
        label: 'Edit',
        route: ROUTES.editCompany.name,
        access_key:
          permissions.inventory.views.editCompany.permissions.editCompany.value,
      }),
    ],
  },
  [ROUTES.editCompany.name]: {
    screen: EditCompanyDetails,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.viewDonorList.name]: {
    screen: DonorList,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount count={selectionCount} label="Donor Management" />
      ),
      actions: [
        (props) => (
          <FtsSearch {...props} action={{ exp: ['name', 'donorId'] }} />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggestOptions',
              options: bloodGroup,
              field: 'bloodGroup',
              label: 'Blood Group',
              multiSelect: true,
            },
            {
              type: 'autoSuggestOptions',
              options: genders,
              field: 'gender',
              label: 'Gender',
              multiSelect: true,
            },
          ],
        }),
      ],
    },
    headerActions: [
      getHeaderActionToRoute({
        label: 'Add Donor',
        route: ROUTES.addDonor.name,
        access_key:
          permissions.inventory.views.addDonor.permissions.addDonor.value,
      }),
    ],
  },
  [ROUTES.addDonor.name]: {
    screen: AddDonor,
    expanded: true,
    headerActions: headerActionsForAddForm,
    title: () => (
      <Text style={{ ...vars.headings.h8 }}>
        ADD DONOR
      </Text>
    ),

  },
  [ROUTES.viewDonor.name]: {
    screen: ViewDonor,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.donorId}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.editDonor.permissions.editDonor.value
            }
          >
            <OutlineButton label="Edit" />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editDonor.name,
          push: true,
          expanded: true,
          params: { ...props?.navigation?.state?.params },
        }),
      },
      getHeaderActionToRoute({
        label: 'Add Donation',
        route: ROUTES.addBloodDonation.name,
        access_key:
          permissions.inventory.views.addDonation.permissions.addDonation.value,
      }),
    ],
  },
  [ROUTES.addBloodDonation.name]: {
    screen: AddBloodDonation,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.editDonor.name]: {
    screen: EditDonor,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.bloodBankInventoryList.name]: {
    screen: BloodBankTabs,
    expanded: true,
    header: {
      title: () => (
        <HeaderTitleWithCount label="Blood Bank (Inventory Management)" />
      ),
      actions: [
        {
          render: () => (
            <WithPermission
              access_key={
                permissions.inventory.views.raiseBloodRequest.permissions.raiseBloodRequest.value
              }
            >
              <StandardButton label="Raise Blood Request" />
            </WithPermission>
          ),
          type: 'link',
          link: () => ({
            view: ROUTES.raiseBloodRequest.name,
            push: true,
            expanded: true,
          }),
        },

      ],
    },
  },
  [ROUTES.raiseBloodRequest.name]: {
    screen: RaiseBloodRequest,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewBloodDonorModal.name]: {
    screen: ViewBloodDonorDocumentListModal,
    expanded: true,
  },
  [ROUTES.bloodBankRecords.name]: {
    screen: BloodBankRecordsTabs,
    expanded: true,
    header: {
      title: () => <HeaderTitleWithCount label="Records (Blood Bank)" />,
    },
  },
  [ROUTES.bloodBankRequest.name]: {
    screen: BloodRequestList,
    expanded: true,
  },
  [ROUTES.bloodBankMSRequest.name]: {
    screen: BloodUpdationRequestList,
    expanded: true,
  },
  [ROUTES.editBloodCategoryListModal.name]: {
    screen: BloodBagListModal,
  },
  [ROUTES.viewBloodCategory.name]: {
    screen: ViewBloodCategory,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.category}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.issueToCompanyModal.name]: {
    screen: IssueToCompanyModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 350,
    },
  },
  [ROUTES.issueBloodRequestMSModal.name]: {
    screen: IssueBloodRequestMSModal,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 429,
      height: 388,
    },
  },
  [ROUTES.viewBloodGroup.name]: {
    screen: ViewBloodGroup,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.category}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.issueBloodGroup.name]: {
    screen: IssueBlood,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewBloodIssuing.name]: {
    screen: ViewBloodIssue,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.issueId}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewBloodDonation.name]: {
    screen: ViewBloodDonation,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.bloodDonor?.donorId}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewBloodRequest.name]: {
    screen: ViewBloodRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return `Blood request for  ${item?.patient?.patientName}`;
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.inventory.views.approveRejectBloodRequests
              .permissions.approveRejectBloodRequests.value}
          >
            <OutlineButton label="Reject" />
          </WithPermission>
        ),
        type: 'invoke',
        visible: ({ data }) => data?.status !== BLOOD_REQUEST_STATUSES.APPROVED
        && data?.status !== BLOOD_REQUEST_STATUSES.REJECTED,
        invoke: rejectBloodRequest,
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.approveRejectBloodRequests
                .permissions.approveRejectBloodRequests.value
            }
          >
            <OutlineButton label="Approve & Issue" />
          </WithPermission>
        ),
        type: 'save',
        visible: ({ data }) => data?.status !== BLOOD_REQUEST_STATUSES.APPROVED
        && data?.status !== BLOOD_REQUEST_STATUSES.REJECTED,
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.approveRejectBloodRequests
                .permissions.approveRejectBloodRequests.value
            }
          >
            <OutlineButton label="Request Sample" />
          </WithPermission>
        ),
        type: 'invoke',
        visible: ({ data }) => data?.status === BLOOD_REQUEST_STATUSES.REQUESTED,
        invoke: requestSampleForBloodRequest,
      },
    ],
  },
  [ROUTES.viewIssueBloodUpdationRequest.name]: {
    screen: ViewBloodUpdationRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.patient?.patientName}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.viewBloodBankMSRequests
                .permissions.viewBloodBankMSRequests.value
            }
          >
            <OutlineButton label="Reject" />
          </WithPermission>
        ),
        type: 'invoke',
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (item?.status === issueBloodUpdationRequestStatus?.PENDING) return true;
          return false;
        },
        invoke: async (props) => {
          try {
            const { navigation, eventDispatcher } = props;
            const {
              navigation: {
                state: {
                  params: { item = {} },
                },
              },
            } = props;
            const result = await invoke({
              id: 'handleBloodInventoryOnBloodIssueToPatientByMS',
              paramValue: {
                data: {
                  _id: item?._id,
                  status: issueBloodUpdationRequestStatus?.REJECTED,
                },
              },
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: 'bloodInventory.messages.rejectRequest'.getLabel(),
                type: 'success',
              });
              eventDispatcher.notify(`reload${MODELS.BLOOD_ISSUE_UPDATION_REQUEST}`);
              navigation.pop();
            }
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: `${getErrorString(e)}`,
              type: 'error',
            });
          }
        },
      },
      {
        render: ({ eventDispatcher }) => {
          eventDispatcher.notify(`reload${MODELS.BLOOD_ISSUE_UPDATION_REQUEST}`);
          return (
            <WithPermission
              access_key={
              permissions.inventory.views.viewBloodBankMSRequests
                .permissions.viewBloodBankMSRequests.value
            }
            >
              <OutlineButton label="Approve & Issue" />
            </WithPermission>
          );
        },
        type: 'invoke',
        visible: ({ navigation }) => {
          const {
            state: {
              params: { item = {} },
            },
          } = navigation;
          if (item?.status === issueBloodUpdationRequestStatus?.PENDING) return true;
          return false;
        },
        invoke: async (props) => {
          try {
            const { navigation, eventDispatcher } = props;
            const {
              navigation: {
                state: {
                  params: { item = {} },
                },
              },
            } = props;
            const result = await invoke({
              id: 'handleBloodInventoryOnBloodIssueToPatientByMS',
              paramValue: {
                data: {
                  _id: item?._id,
                  status: issueBloodUpdationRequestStatus?.APPROVED,
                },
              },
            });
            if (result) {
              Toast.show({
                message: 'Success',
                description: 'bloodInventory.messages.approveAndIssue'.getLabel(),
                type: 'success',
              });
              eventDispatcher.notify(`reload${MODELS.BLOOD_ISSUE_UPDATION_REQUEST}`);
              navigation.pop();
            }
          } catch (e) {
            Toast.show({
              message: 'Error',
              description: `${getErrorString(e)}`,
              type: 'error',
            });
          }
        },
      },
    ],
  },
};
