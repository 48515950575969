import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { ActivityIndicator, Text, View } from '../../../../../../../app-components';
import { Form } from '../../../../../../../app-components/form/Form';
import { NestedTable } from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { fetch } from '../../../../../../../AppServices';
import ListActionModal from '../../../../../../../Components/ListActionModal';
import { history } from '../../../../../../../images';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const AssetItemDetails = (props) => {
  const { navigation } = props;
  const item = navigation.getParam('item');
  const [assetItems, setAssetItem] = useState();
  const [loading, setLoading] = useState(true);

  const fetchAssetDetails = useCallback(async () => {
    const fetchedAssetDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'assetInventoryDataList',
            addOnFilter: {
              indent: {
                _id: item?._id,
              },
            },
          },
          model: MODELS.ASSET,
        },
      },
    });
    setAssetItem(fetchedAssetDetails?.data);
    setLoading(false);
    return fetchedAssetDetails;
  }, [item]);

  useEffect(() => {
    fetchAssetDetails();
  }, []);

  if (loading) return <ActivityIndicator />;

  return (
    <ListActionModal
      icon={history}
      title={'asset.title.itemDetails'.getLabel()}
      description="Details of the Asset Items."
      content={(
        <Form
          defaultValues={{ assetItems }}
          formGroups={[
            {
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 4,
              },
              groupsContainerStyle: { paddingLeft: 0 },
              groups: [
                {
                  columns: [
                    {
                      field: 'assetItems',
                      fieldType: 'nested',
                      editable: false,
                      Component: NestedTable,
                      componentProps: {
                        skipTableHeaderOnNoData: true,
                        listProps: {
                          hideColumnHeader: false,
                          headerRowContainerStyle: {
                            minHeight: 32,
                            paddingLeft: 12,
                            paddingRight: 4,
                          },
                          headerRowColumnTextStyle: {
                            paddingLeft: 2,
                            ...vars.headings.h13,
                            color: vars.colors.grey.color3,
                            numberOfLines: 1,
                            textTransform: 'upperCase',
                          },
                          columns: [
                            {
                              type: 'text',
                              label: 'asset.labels.serialNo'.getLabel(),
                              header: 'asset.labels.serialNo'.getLabel(),
                              field: 'serialNumber',
                              variant: 'filled',
                              width: 180,
                            },
                            {
                              type: 'date',
                              format: 'DD.MM.YYYY',
                              variant: 'filled',
                              render: ({ item: { serviceItemDetails } = {} }) => (
                                <View style={{ flexDirection: 'column' }}>
                                  {serviceItemDetails?.map(({ preventiveDate }, key) => (
                                    <View style={{ padding: 5 }} key={key}>
                                      <Text style={{ ...vars.headings.h9 }}>
                                        {moment(preventiveDate).format('DD MMM YYYY')}
                                      </Text>
                                    </View>
                                  ))}
                                </View>
                              ),
                              header: 'asset.labels.preventiveDate'.getLabel(),
                            },
                            {
                              type: 'date',
                              format: 'DD.MM.YYYY',
                              variant: 'filled',
                              render: ({ item: { serviceItemDetails } = {} }) => (
                                <View style={{ flexDirection: 'column' }}>
                                  {serviceItemDetails?.map(({ serviceDate }, key) => (
                                    <View style={{ padding: 5 }} key={key}>
                                      <Text style={{ ...vars.headings.h9 }}>
                                        {moment(serviceDate).format('DD MMM YYYY')}
                                      </Text>
                                    </View>
                                  ))}
                                </View>
                              ),
                              header: 'asset.labels.serviceDate'.getLabel(),
                            },
                            {
                              type: 'number',
                              label: 'asset.labels.value'.getLabel(),
                              header: 'asset.labels.value'.getLabel(),
                              field: 'purchasedPrice',
                              variant: 'filled',
                              width: 180,
                            },
                            {
                              type: 'text',
                              label: 'asset.labels.manufacturer'.getLabel(),
                              header: 'asset.labels.manufacturer'.getLabel(),
                              field: 'batchNumber.manufacturer.name',
                              variant: 'filled',
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ]}
        />
        )}
      {...props}
    />
  );
};

export default AssetItemDetails;
