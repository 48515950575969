import { MODELS } from '../Lib/constants';

export const progressReportUri = () => ({
  query: {
    model: MODELS.IPD_PROGRESS_REPORT,
    id: 'IPDProgressList',
  },
  model: MODELS.IPD_PROGRESS_REPORT,
});

export const logReportUri = ({ navigation }) => ({
  query: {
    model: MODELS.IPD_PROGRESS_REPORT,
    id: 'EditIPDProgressPatient',
    relationValue: {
      model: MODELS.IPD_PROGRESS_REPORT,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
  },
  model: MODELS.IPD_PROGRESS_REPORT,
});

export const viewProgressReportUri = (filter) => ({
  query: {
    id: 'EditIPDProgressPatient',
    addOnFilter: {
      _id: filter._id,
      speciality: filter?.dataParams?.filters?.specialityFilter,
    },
  },
  model: MODELS.IPD_PROGRESS_REPORT,
});

// export const viewCategoriesListUri = ({ navigation }) => ({
//   query: {
//     model: MODELS.ROOM_CATEGORY,
//     id: 'masterDataListForTable',
//     relationValue: {
//       model: MODELS.ROOM_CATEGORY,
//       _id: navigation.state.params.item._id,
//       relation: '_id',
//     },
//     limit: 1,
//   },
//   model: MODELS.ROOM_CATEGORY,
// });
