import { ROUTES } from '../../../../../../Lib/constants';

import logDailyExaminationNote from './DailyExaminationNotes/LogDailyExamination';
import viewDailyExaminationNote from './DailyExaminationNotes/ViewDailyExamination';
import InputOutputChart from './InputOutputChart';
import LogInputOutputChart from './InputOutputChart/LogInputOutputChart';
import ViewInputOutputChart from './InputOutputChart/ViewInputOutputChart';
import MonitoringChart from './MonitoringChart';
import LogMonitoringChart from './MonitoringChart/LogMonitoringChart';
import ViewMonitoringChartModel from './MonitoringChart/ViewMonitoringChart';
import viewOrderIDU from './Orders/ViewOrders';
import logOrderIDU from './Orders/LogOrders';

export const icuScreens = {
  [ROUTES.addDailyExaminationNotesGeneralMedicine.name]: {
    screen: logDailyExaminationNote,
    modal: true,
    modalProps: {
      autoHide: true,
      width: '930',
      height: 'auto',
    },
  },
  [ROUTES.viewDailyExaminationNotesGeneralMedicine.name]: {
    screen: viewDailyExaminationNote,
    modal: true,
    modalProps: {
      autoHide: true,
      width: '930',
      height: 'auto',
    },
  },
  [ROUTES.logMonitoringChart.name]: {
    screen: LogMonitoringChart,
  },
  [ROUTES.logOrderIDU.name]: {
    screen: logOrderIDU,
    modal: true,
    modalProps: {
      autoHide: true,
      width: '930',
      height: 'auto',
    },
  },
  [ROUTES.viewMonitoringChart.name]: {
    screen: ViewMonitoringChartModel,
    expanded: true,
  },
  [ROUTES.monitoringChart.name]: {
    screen: MonitoringChart,
    expanded: true,
  },
  [ROUTES.logInputOutputChart.name]: {
    screen: LogInputOutputChart,
    modal: true,
    modalProps: {
      autoHide: true,
      width: '930',
      height: 'auto',
    },
  },
  [ROUTES.viewInputOutputChart.name]: {
    screen: ViewInputOutputChart,
    expanded: true,
  },
  [ROUTES.inputOutputChart.name]: {
    screen: InputOutputChart,
    expanded: true,
  },
  [ROUTES.viewOrderIDU.name]: {
    screen: viewOrderIDU,
    modal: true,
    modalProps: {
      width: 970,
      height: 'auto',
    },
  },
};

export default icuScreens;
