import React, { useCallback, useEffect, useState } from 'react';

import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { EditEmployeeDesignation } from '../../../Queries/employeeDesignation';
import { FormHoc } from '../../../app-components';
import { MODELS } from '../../../Lib/constants';
import { fetch } from '../../../AppServices';
import vars from '../../../theme/vars';

const ViewDesignation = (props) => {
  const [allocatedSanctionSeatData, setAllocatedSanctionSeatData] = useState([]);

  const fetchAllocatedData = useCallback(async () => {
    const allocatedSeats = await fetch({
      uri: {
        props: {
          query: {
            id: 'sanctionSeatData',
            addOnFilter: {
              held_position: { _id: props?.navigation?.state?.params?.item?._id },
            },
          },
          model: MODELS.EMPLOYEE,
        },
      },
    });
    setAllocatedSanctionSeatData(allocatedSeats?.data);
  }, []);

  useEffect(() => {
    fetchAllocatedData();
  }, []);

  const ViewDesignationForm = FormHoc({
    type: 'standard',
    closeView: 1,
    uri: EditEmployeeDesignation,
    reloadEvent: `reload${MODELS.DESIGNATION}`,
    lg: {
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'Category Name',
                  editable: false,
                },
                {
                  type: 'number',
                  field: 'sanctionSeats',
                  label: 'Sanction Seats',
                  editable: false,
                },
                {
                  type: 'text',
                  field: 'description',
                  label: 'Description',
                  editable: false,
                },
                {
                  type: 'status',
                  field: 'isActive',
                  label: 'Status',
                  editable: false,
                  container: 'topLabel',
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'Sanction Seats',
          labelStyle: {
            ...vars.headings.h10,
            color: vars.colors.grey.color2,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {

                  field: 'sanctionSeatsCategory',
                  editable: false,
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    skipTableHeaderOnNoData: false,
                    columnsPerRow: 4,
                    listProps: {
                      hideColumnHeader: false,
                      headerRowColumnTextStyle: {
                        ...vars.headings.h10,
                        color: vars.colors.grey.color3,
                        numberOfLines: 1,
                      },
                      columns: [
                        {
                          type: 'text',
                          field: 'category.name',
                          header: 'Category',
                        },
                        {
                          type: 'number',
                          field: 'sanction_seats_category',
                          header: 'Sanction Seats',
                          width: 350,
                        },
                        {
                          type: 'number',
                          // field: 'allocated_seats_category',
                          value: ({ item }) => {
                            const sanctionCategoryData = allocatedSanctionSeatData?.filter((value) => value?._children?.[0]?.category?._id === item?.category?._id);
                            if (sanctionCategoryData?.length) return sanctionCategoryData?.[0]?.count;
                            return '0';
                          },
                          header: 'Allocated Seats',
                          width: 350,
                        },
                        {
                          type: 'number',
                          value: ({ item }) => {
                            const sanctionCategoryData = allocatedSanctionSeatData?.filter((value) => (value?._children?.[0]?.category?._id === item?.category?._id));
                            if (sanctionCategoryData.length) {
                              const vacantSeats = item?.sanction_seats_category - sanctionCategoryData?.[0]?.count;
                              return vacantSeats === 0 ? '0' : vacantSeats;
                            }
                            return item?.sanction_seats_category;
                          },
                          header: 'Vacant Seats',
                          width: 350,
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  });

  return <ViewDesignationForm {...props} />;
};

export default ViewDesignation;
