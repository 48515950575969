import React from 'react';
import EmployeeTabs from './components/index';
import { View } from '../../../../app-components';

const ViewEmployeeSchedule = (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 3, overflow: 'hidden' }}>
      <EmployeeTabs showAddButton={false} {...props} />
    </View>
  </View>
);
export default ViewEmployeeSchedule;
