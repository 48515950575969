import React from 'react';

import {
  cancelButton, save, StandardButton,
} from '../../../app-components';
import { ROUTES } from '../../../Lib/constants';

import AddElectricityBill from './ElectricityBill/AddElectricityBill';
import WithPermission from '../../../Components/WithPermission';
import EditBillingDetails from './ElectricityBill/EditScreen';
import EditHouseAllotment from './HouseAllotment/EditHouseAllotment';
import permissions from '../../../Lib/permissions';
import EditHouseList from './EditHouse';
import AllotHouse from './HouseAllotment';
import ViewDetails from './ViewDetails';
import HouseList from './HouseList';
import AddHouse from './AddHouse';

const headerActionsForAddForm = [cancelButton, save];

export default {
  [ROUTES.houseList.name]: {
    screen: HouseList,
    headerActions: [{
      render: (
        <WithPermission
          access_key={permissions.mdm.views.manageHouseView.permissions.manageHouseView.value}
        >
          <StandardButton label="Add House" />
        </WithPermission>
      ),
      type: 'link',
      link: {
        view: [ROUTES.addHouseDetails.name],
        routeOptions: {
          expanded: true,
        },
      },
    }],
    expanded: true,
  },
  [ROUTES.addHouseDetails.name]: {
    screen: AddHouse,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewHouseDetails.name]: {
    screen: ViewDetails,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <WithPermission
            access_key={permissions.mdm.views.allotHouseView.permissions.allotHouseView.value}
          >
            <StandardButton label="Allot House" />
          </WithPermission>
        ),
        type: 'link',
        link: (linkProps) => ({
          view: ROUTES.allotHouse.name,
          push: true,
          params: {
            item: linkProps?.navigation?.state?.params?.item,
          },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={permissions.mdm.views
              .addElectricityBillOfHouse.permissions.addElectricityBillOfHouse.value}
          >
            <StandardButton label="Add Electricity Bill" />
          </WithPermission>
        ),
        type: 'link',
        link: (linkProps) => ({
          view: ROUTES.addElectricityBill.name,
          push: true,
          params: {
            item: linkProps?.navigation?.state?.params?.item,
          },
        }),
      },
    ],
  },
  [ROUTES.allotHouse.name]: {
    screen: AllotHouse,
    modal: true,
    modalProps: {
      width: 750,
      height: 350,
    },
  },
  [ROUTES.addElectricityBill.name]: {
    screen: AddElectricityBill,
    modal: true,
    modalProps: {
      width: 750,
      height: 350,
    },
  },
  [ROUTES.editElectricityBill.name]: {
    screen: EditBillingDetails,
    modal: true,
    modalProps: {
      width: 750,
      height: 350,
    },
  },

  [ROUTES.editHouseAllotment.name]: {
    screen: EditHouseAllotment,
    modal: true,
    modalProps: {
      width: 750,
      height: 350,
    },
  },
  [ROUTES.editHouseDetails.name]: {
    screen: EditHouseList,
    modal: true,
    modalProps: {
      width: 650,
      height: 400,
    },
  },
};
