import MasterForm from '../../../Wired/Master/MasterForm';
import { autoSuggestInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';
import {
  validateInputLength,
  validateDecimalInputLength,
} from '../../../Lib/helpers';

const validations = {
  code: ({ data }) => validateInputLength(data?.code, 2, 20),
  name: ({ data }) => validateInputLength(data?.name, 2, 26),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
  charge: ({ data }) => validateDecimalInputLength(data?.charge, 2),
};

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'speciality.labels.wardName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
    allowConsecutiveSpaces: true,
  },
  {
    type: 'text',
    field: 'code',
    label: 'speciality.labels.wardId'.getLabel(),
    mandatory: true,
  },
  autoSuggestInput({
    mandatory: true,
    field: 'department',
    label: 'room.labels.department'.getLabel(),
    placeholder: 'room.placeholders.department'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.DEPARTMENTS,
    query: 'masterDataList',
    searching: true,
  }),
  {
    type: 'number',
    field: 'charge',
    label: `${'speciality.labels.charge'.getLabel()}(INR)`,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'speciality.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'center',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'speciality.labels.description'.getLabel(),
    minHeight: 80,
    allowWhiteSpaces: true,
  },
];

export default MasterForm({
  model: MODELS.WARDS,
  formFields,
  validations,
  submitMessage: 'speciality.messages.wardAddedSuccessfully'.getLabel(),
  uniqueValidationMessage: 'speciality.messages.wardAlreadyExists'.getLabel(),
  mandatory: {
    name: 1,
    code: 1,
    department: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
