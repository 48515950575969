import React from 'react';

import { Text } from 'react-native';

import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { GetReferencesUri } from '../../../../../../../Queries/reference';
import { Table } from '../../../../../../../app-components/table/Table';
import { StandardButton } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const tabListColumns = () => ([
  {
    header: 'DATE',
    type: 'date',
    field: 'referenceDate',
    format: 'DD/MM/YYYY',
  },
  {
    header: 'DEPARTMENT',
    type: 'text',
    field: 'department.name',
  },
  {
    header: 'ADVICE FROM DEPARTMENT',
    type: 'text',
    field: 'advice',
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>View</Text>
      ),
      type: 'link',
      link: ({ item }) => ({
        view: ROUTES.viewReference.name,
        params: { item },
      }),
    },
    ],
  },
]);

const References = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;
  const { dataParams } = props;
  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="References"
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Take Reference"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.takeReferences.name,
              params: { ...params, dataParams },
              push: true,
            }),
          },
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Log Reference"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.logReferences.name,
              params: { ...params },
              push: true,
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.IPD_REFERENCE}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={() => GetReferencesUri({ admissionID: { _id } })}
    />
  );
};
export default References;
