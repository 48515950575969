import React from 'react';

import MasterList from '../Master/MasterList';
import { ActivityIndicator } from '../../app-components';
import { getString } from '../../Lib/helpers';

const tabListColumns = [
  {
    width: 14,
  },
  {
    header: 'Date',
    type: 'date',
    format: 'DD MMM YYYY',
    field: '_createdOn',
  },
  {
    header: 'Time',
    type: 'date',
    format: 'hh:mm:ss A',
    field: '_createdOn',
  },
  {
    header: 'Employee',
    type: 'text',
    field: '_createdBy',
    value: ({ item }) => getString([
        item?._createdBy?.employee?.firstName,
        item?._createdBy?.employee?.lastName,
    ]) || item?._createdBy?.username,
  },
  {
    header: 'Activity',
    type: 'text',
    field: 'op',
    value: ({ item }) => (item?.op === 'insert' ? 'Created' : 'Updated'),
  },
];

const ActivityLogsList = (props) => {
  const {
    id,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;

  let {
    data,
    addOnFilter,
    header,
  } = props;

  if (!data) {
    data = navigation.getParam('data');
  }

  if (!addOnFilter) {
    addOnFilter = navigation.getParam('addOnFilter');
  }

  if (!header) {
    header = navigation.getParam('header');
  }

  if (!data?._id) {
    return <ActivityIndicator />;
  }

  delete restProps.data;

  return (
    <MasterList
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        limit: 10,
        model,
      })}
      tableColumns={tableColumns}
      showActionIcon={false}
      header={header}
      {...restProps}
      navigation={navigation}
    />
  );
};

export default ActivityLogsList;
