import SchoolFormHoc from './ImportForm';
import { cancelButton } from '../../app-components/action/CloseAction';
import { save } from '../../app-components/action/SaveAction';

const submitMessage = 'Import added successfuly';
const label = 'Add Import';
const header = {
  title: label,
  actions: [save(), cancelButton()],
};
export default SchoolFormHoc({ submitMessage, label, header });
