/* eslint-disable no-nested-ternary */
import React from 'react';
import { upperFirst } from 'lodash';

import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { ViewPurchaseOrder } from '../../../../../Queries/purchaseRequestPharmacy';
import { reduceDaysLimitApprovalBasedOnRole } from '../../../../../Lib/helpers';
import { Text, View } from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import vars from '../../../../../theme/vars';
import {
  assetInventoryStockRequestStatus,
  purchaseSupplyOrderStatus,
  inventoryType,
  MODELS,
  inventoryModules,
} from '../../../../../Lib/constants';

const ViewPurchaseRequestDetail = (props) => {
  const {
    navigation: {
      state: {
        params: { item },
      },
    },
  } = props;

  return (
    <Form
      type="standard"
      uri={ViewPurchaseOrder}
      closeView={1}
      reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'purchase.labels.poNumber'.getLabel(),
                    field: 'uniqueNumber',
                  },
                  {
                    type: 'date',
                    label: 'purchase.labels.date'.getLabel(),
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.department'.getLabel(),
                    render: ({ data }) => (
                      <Text
                        style={{ ...vars.headings.h8 }}
                      >
                        { upperFirst(data?.inventoryType)}
                      </Text>
                    ),
                  },
                  item?.inventoryType === 'asset' ? {
                    type: 'text',
                    label: 'Type',
                    render: ({ data: { purchaseRequestEntity = {} } = {} }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {
                      purchaseRequestEntity?.subType === inventoryType.subTypeRepair
                        ? upperFirst(inventoryType.subTypeRepair)
                        : upperFirst(inventoryType.subTypePurchase)
                      }
                      </Text>
                    ),
                  } : null,
                  {
                    type: 'text',
                    label: 'purchase.labels.urgent'.getLabel(),
                    render: ({ data }) => {
                      if (data?.urgent) {
                        return (
                          <Text
                            style={{ ...vars.headings.h8, color: vars.colors.error }}
                          >
                            Yes
                          </Text>
                        );
                      }
                      return <Text style={{ ...vars.headings.h8 }}>No</Text>;
                    },
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.daysLimitForApproval'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {reduceDaysLimitApprovalBasedOnRole(
                          data?.daysLimitForApproval,
                          data?.currentApprovalQueue?._createdOn,
                          data?.status,
                          data?.currentApprovalQueue,
                        )}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.purchaseRequestNumber'.getLabel(),
                    field:
                    item?.inventoryType === inventoryType?.pharmacy
                      ? 'purchaseRequestEntity.purchaseRequestPharmacy.purchaseRequestNumber'
                      : 'purchaseRequestEntity.purchaseRequestAsset.purchaseRequestNumber',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.status'.getLabel(),
                    render: ({ data }) => {
                      let statusColor = '';
                      const status = data?.status;
                      if (status === purchaseSupplyOrderStatus?.CREATED) {
                        statusColor = vars.colors.warning;
                      } else if (status === purchaseSupplyOrderStatus?.APPROVED) {
                        statusColor = vars.colors.primary.color2;
                      } else if (status === purchaseSupplyOrderStatus?.REJECTED) {
                        statusColor = vars.colors.error;
                      }

                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                          <View style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: statusColor,
                            marginTop: 4.5,
                            marginLeft: 7,
                          }}
                          />
                        </View>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'Remarks',
                    field: 'remarks',
                  },
                  item?.status === assetInventoryStockRequestStatus?.rejected ? {
                    type: 'text',
                    label: 'Rejection Reason',
                    field: 'currentApprovalQueue.remarks',
                  } : null,
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'ITEM DETAILS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            columnsPerRow: 5,
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field:
                    item?.inventoryType === inventoryType?.pharmacy
                      ? 'purchaseRequestEntity.purchaseRequestPharmacy.item.name'
                      : 'purchaseRequestEntity.purchaseRequestAsset.item.name',
                    label: 'Item name',
                  },
                  {
                    type: 'text',
                    field: 'supplier.name',
                    label: 'purchase.labels.supplier'.getLabel(),
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.manufacturer'.getLabel(),
                    field: 'manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.purchaseQuantity'.getLabel(),
                    field:
                    item?.inventoryType === inventoryType?.pharmacy
                      ? 'purchaseRequestEntity.purchaseRequestPharmacy.requiredQuantity'
                      : 'purchaseRequestEntity.purchaseRequestAsset.requiredQuantity',
                  },
                  {
                    type: 'text',
                    label: 'purchase.labels.unitPrice'.getLabel(),
                    field:
                    item?.inventoryType === inventoryType?.pharmacy
                      ? 'purchaseRequestEntity.purchaseRequestPharmacy.item.sellingPrice'
                      : 'purchaseRequestEntity.purchaseRequestAsset.unitPrice',
                  },
           item?.purchaseRequestEntity?.subType === 'repair' ? {
             type: 'number',
             label: 'purchase.labels.approximationCost'.getLabel(),
             field: 'purchaseRequestEntity.purchaseRequestAsset.approximateCost',
           } : {
             type: 'text',
             label: 'purchase.labels.totalPrice'.getLabel(),
             render: ({ data }) => {
               if (item?.inventoryType === inventoryType?.pharmacy) {
                 const {
                   purchaseRequestEntity: {
                     purchaseRequestPharmacy: {
                       item: { sellingPrice } = {},
                       requiredQuantity,
                     } = {},
                   } = {},
                 } = data;
                 return (
                   <Text style={{ ...vars.headings.h8 }}>
                     {sellingPrice * requiredQuantity}
                   </Text>
                 );
               }
               const {
                 purchaseRequestEntity: {
                   purchaseRequestAsset: {
                     unitPrice,
                     requiredQuantity,
                   } = {},
                 } = {},
               } = data;
               return (
                 <Text style={{ ...vars.headings.h8 }}>
                   { unitPrice * requiredQuantity}
                 </Text>
               );
             },
           },
           {
             type: 'date',
             label: 'purchase.labels.lastPurchaseDate'.getLabel(),
             field: item?.inventoryType === inventoryType?.pharmacy
               ? 'purchaseRequestEntity.purchaseRequestPharmacy.lastPurchaseDate'
               : 'purchaseRequestEntity.purchaseRequestAsset.lastPurchaseDate',
           },
           {
             type: 'text',
             label: 'purchase.labels.lastPurchaseQuantity'.getLabel(),
             field: item?.inventoryType === inventoryType?.pharmacy
               ? 'purchaseRequestEntity.purchaseRequestPharmacy.lastPurchaseQuantity'
               : 'purchaseRequestEntity.purchaseRequestAsset.lastPurchaseQuantity',
           },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'REMARKS',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    field: 'purchaseRequestEntity.queues',
                    fieldType: 'nested',
                    editable: true,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'purchase.header.official'.getLabel(),
                            variant: 'filled',
                            field: 'assignedToRole',
                            editable: false,
                            width: 350,
                          },
                          {
                            type: 'text',
                            header: 'purchase.header.remarks'.getLabel(),
                            field: 'remarks',
                            editable: false,
                            width: 350,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'purchase.labels.termsAndConditions'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    field: 'conditions',
                    fieldType: 'nested',
                    editable: true,
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                        },
                        columns: [
                          {
                            type: 'text',
                            field: 'name',
                            variant: 'filled',
                            header: 'purchase.header.tAndCName'.getLabel(),
                            width: 350,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'description',
                            variant: 'filled',
                            header: 'purchase.header.description'.getLabel(),
                            width: 350,
                            editable: false,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: 'purchaseRequestEntity._id',
                    id: item?.inventoryType === 'asset' ? 'purchaseRequestAssetList'
                      : item?.purchaseRequestEntity?.module === inventoryModules?.assetConsumables
                        ? 'purchaseRequestAssetConsumablesList'
                        : 'purchaseRequestPharmacyList',
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPurchaseRequestDetail;
