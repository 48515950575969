import React from 'react';

import {
  Text, save, Toast,
} from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const IpdLogMonitoringChart = (props) => {
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Monitoring Chart"
      description="Log vitals of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.MONITORING_CHART}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          defaultValues={{
            monitoringDate: new Date(),
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            updates.monitoringDate = new Date(
              new Date(
                new Date(
                  new Date(
                    new Date(updates?.monitoringDate).setUTCHours(0),
                  ).setUTCMinutes(0),
                ).setUTCSeconds(0),
              ).setUTCMilliseconds(0),
            );
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
              speciality: submitProps?.navigation?.state
                .params?.dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.MONITORING_CHART,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Chart has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            validations: {
              pr: ({ data }) => validateInputLength(data?.pr, 0, 5),
              rhythm: ({ data }) => validateInputLength(data?.rhythm, 0, 5),
              bpsd: ({ data }) => validateInputLength(data?.bpsd, 0, 5),
              rr: ({ data }) => validateInputLength(data?.rr, 0, 5),
              spo2: ({ data }) => validateInputLength(data?.spo2, 0, 5),
              temp: ({ data }) => validateInputLength(data?.temp, 0, 5),
              cvp: ({ data }) => validateInputLength(data?.cvp, 0, 5),
              ibp: ({ data }) => validateInputLength(data?.ibp, 0, 5),
              mode: ({ data }) => validateInputLength(data?.mode, 0, 5),
              tbpi: ({ data }) => validateInputLength(data?.tbpi, 0, 5),
              rate: ({ data }) => validateInputLength(data?.rate, 0, 5),
              fio2: ({ data }) => validateInputLength(data?.fio2, 0, 5),
              ppeak: ({ data }) => validateInputLength(data?.ppeak, 0, 5),
              pplat: ({ data }) => validateInputLength(data?.pplat, 0, 5),
              peep: ({ data }) => validateInputLength(data?.peep, 0, 5),
              psupp: ({ data }) => validateInputLength(data?.psupp, 0, 5),
              dopa: ({ data }) => validateInputLength(data?.dopa, 0, 5),
              norad: ({ data }) => validateInputLength(data?.norad, 0, 5),
              ntg: ({ data }) => validateInputLength(data?.ntg, 0, 5),
              midaZolam: ({ data }) => validateInputLength(data?.midaZolam, 0, 5),
              fentAnyl: ({ data }) => validateInputLength(data?.fentAnyl, 0, 5),
              insulin: ({ data }) => validateInputLength(data?.insulin, 0, 5),
              nebulizer: ({ data }) => validateInputLength(data?.nebulizer, 0, 5),
              eventOne: ({ data }) => validateInputLength(data?.eventOne, 0, 5),
              eventTwo: ({ data }) => validateInputLength(data?.eventTwo, 0, 5),
              eventThree: ({ data }) => validateInputLength(data?.eventThree, 0, 5),
              suctionPositioning: ({ data }) => validateInputLength(data?.suctionPositioning, 0, 5),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'date',
                            field: 'monitoringDate',
                            label: 'Date',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>PULSE </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'text',
                            field: 'pr',
                            label: 'PR',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'rhythm',
                            label: 'Rhythm',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'bpsd',
                            label: 'BP / SD',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'rr',
                            label: 'RR',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'spo2',
                            label: 'Spo2',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'temp',
                            label: 'Temp',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'cvp',
                            label: 'Cvp',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'ibp',
                            label: 'IBP',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Respiratory Data </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'text',
                            field: 'mode',
                            label: 'Mode',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'tbpi',
                            label: 'TB / PI',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'rate',
                            label: 'Rate',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'fio2',
                            label: 'FIO2',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'ppeak',
                            label: 'Ppeak',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'pplat',
                            label: 'Pplat',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'peep',
                            label: 'Peep',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'psupp',
                            label: 'Psupp',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Drug Infusion </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'text',
                            field: 'dopa',
                            label: 'Dopa',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'norad',
                            label: 'Norad',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'ntg',
                            label: 'Ntg',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'midaZolam',
                            label: 'MidaZolam',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'fentAnyl',
                            label: 'Fent Anyl',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'insulin',
                            label: 'Insulin',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'nebulizer',
                            label: 'Nebulizer',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Other Event</Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 8,
                        columns: [
                          {
                            type: 'text',
                            field: 'eventOne',
                            label: '1',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'eventTwo',
                            label: '2',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'eventThree',
                            label: '3',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'suctionPositioning',
                            label: 'Suction Positioning Chest PT Spirometry',
                            editable: true,
                            with: { width: 200 },
                          },

                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const LogMonitoringChart = (props) => (<IpdLogMonitoringChart {...props} />);
export default LogMonitoringChart;
