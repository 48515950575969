import { ROUTES } from '../../../../../../Lib/constants';

import EditTemperaturePulseTimeView from './TemperatureOrPulseTimeChart/EditChart';
import ViewOperationNotesIPDForm from './OperationOrProcedureNotes/ViewNote';
import AddNewOperationNotes from './OperationOrProcedureNotes/AddNewNote';
import ViewService from './MiscellaneousAccount/ViewService';
import AddService from './MiscellaneousAccount/AddService';
import TakeReferences from './References/TakeReferences';
import LogReference from './References/LogReferences';
import DischargeCard from './Outcome/DischargedCard';
import ViewDetails from './References/ViewDetail';
import AddProgress from './ProgressReport/AddProgress';
import ViewDetail from './Orders/ViewDetail';
import DeathCard from './Outcome/DeathCard';
import LogOrder from './Orders/LogOrder';
import ViewProgressReport from './ProgressReport/ViewReport';
import AddHistoryIPDForm from './History/AddHistory';
import ViewHistoryIPDForm from './History/ViewHistory';
import NewAnesthesiaIPDForm from './AnesthesiaReport/AddAnesthesiaReport';
import AddVitals from './VitalSignsSheet/AddVitals';
import ViewVitals from './VitalSignsSheet/ViewVitals';
import AnesthesiaModal from './OperationOrProcedureNotes/AnesthesiaModal';
import ViewAnesthesiaIPDForm from './AnesthesiaReport/ViewAnesthesiaReport';
import EditMonitoringModal from './AnesthesiaReport/EditMonitoringChart';
import EditOperationNotesIPDForm from './OperationOrProcedureNotes/EditNote';
import EditProgress from './ProgressReport/EditProgressReport';
import EditVitals from './VitalSignsSheet/EditVitalSheet';
import EditOrder from './Orders/EditOrder';
import EditServiceIPDForm from './MiscellaneousAccount/EditService';
import AddMedicine from './TreatmentChart/AddMediine';

const generalSurgeryScreens = {

  [ROUTES.addService.name]: {
    screen: AddService,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
    },
  },
  [ROUTES.editService.name]: {
    screen: EditServiceIPDForm,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
    },
  },
  [ROUTES.viewService.name]: {
    screen: ViewService,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 600,
      height: 300,
    },
  },
  [ROUTES.logReferences.name]: {
    screen: LogReference,
    modal: true,
    modalProps: {
      autoHide: true,
      height: 'auto',
      width: 'auto',
    },
  },
  [ROUTES.takeReferences.name]: {
    screen: TakeReferences,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 'auto',
      height: 'auto',
    },
  },
  [ROUTES.viewReference.name]: {
    screen: ViewDetails,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 550,
      height: 'auto',
    },
  },
  [ROUTES.viewOrderDetail.name]: {
    screen: ViewDetail,
    expanded: true,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 1196,
      height: 547,
    },
  },
  [ROUTES.LogOrder.name]: {
    screen: LogOrder,
    expanded: true,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 500,
      height: 300,
    },
  },
  [ROUTES.EditOrder.name]: {
    screen: EditOrder,
    expanded: true,
    modal: true,
    modalProps: {
      autoHide: true,
      // maxHeight: '100%',
      // width: 500,
      // height: 300,
    },
  },
  [ROUTES.addProcedureNoteIPD.name]: {
    screen: AddNewOperationNotes,
    modal: true,
    modalProps: {
      autoHide: true,
      height: 'auto',
      width: 'auto',
    },
  },
  [ROUTES.editProcedureNoteIPD.name]: {
    screen: EditOperationNotesIPDForm,
    modal: true,
    modalProps: {
      autoHide: true,
      height: '700',
      width: '900',
    },
  },
  [ROUTES.viewNotesIpd.name]: {
    screen: ViewOperationNotesIPDForm,
    modal: true,
    modalProps: {
      autoHide: true,
      height: 'auto',
      width: 1300,
    },
  },
  [ROUTES.dischargeCard.name]: {
    screen: DischargeCard,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.deathCard.name]: {
    screen: DeathCard,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      height: 'auto',
      width: 1396,
    },
  },
  [ROUTES.AddProgress.name]: {
    screen: AddProgress,
    expanded: true,
  },
  [ROUTES.EditProgress.name]: {
    screen: EditProgress,
    expanded: true,
  },
  [ROUTES.ViewProgressReport.name]: {
    screen: ViewProgressReport,
    expanded: true,
  },

  [ROUTES.addHistory.name]: {
    screen: AddHistoryIPDForm,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
    },
  },

  [ROUTES.viewHistory.name]: {
    screen: ViewHistoryIPDForm,
    modal: true,
    modalProps: {
      autoHide: true,
      maxHeight: '100%',
      width: 600,
      height: 300,
    },
  },
  [ROUTES.logAnaesthesia.name]: {
    screen: NewAnesthesiaIPDForm,
    expanded: true,
    title: 'New Anesthesia Report',
  },
  [ROUTES.viewAnaesthesia.name]: {
    screen: ViewAnesthesiaIPDForm,
    expanded: true,
    title: 'New Anesthesia Report',
  },
  [ROUTES.LogDailyVitals.name]: {
    screen: AddVitals,
    expanded: true,
  },
  [ROUTES.editDailyVitals.name]: {
    screen: EditVitals,
    expanded: true,
  },
  [ROUTES.ViewDailyVitals.name]: {
    screen: ViewVitals,
    expanded: true,
  },
  [ROUTES.editTemperaturePulseTimeChartDetails.name]: {
    screen: EditTemperaturePulseTimeView,
    modal: true,
    modalProps: {
      width: 700,
      height: 400,
    },
  },
  [ROUTES.redirectToAnesthesiaAfterOperationNotesModal.name]: {
    screen: AnesthesiaModal,
    modal: true,
    modalProps: {
      width: 440,
      height: 260,
    },
  },
  [ROUTES.editMonitoringDetails.name]: {
    screen: EditMonitoringModal,
    modal: true,
    modalProps: {
      width: 440,
      height: 260,
    },
  },
  [ROUTES.addMedicineFromTreatment.name]: {
    screen: AddMedicine,
    modal: true,
    modalProps: {
      // width: 440,
      // height: 260,
    },
  },
};

export default generalSurgeryScreens;
