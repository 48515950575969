import React from 'react';

import {
  save, Toast,
} from '../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { validateInputLength } from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';

const IpdAddOperativeCheckList = (props) => {
  const { navigation: { state: { params: { dataParams } } } } = props;
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Pre-Operative CheckList"
      description="Log pre-operative checklist details for the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.PRE_OPERATIVE_CHECKLIST}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          mandatory={{
            bloodsugar: 1,
            bloodPressure: 1,
            atiCoagulants: 1,
            hb: 1,
          }}
          computations={{
            self: {
              eyeRight: {
                compute: (data) => {
                  if (!data?.isEyeRight) {
                    return ({
                      set: {
                        eyeRight: '',
                      },
                    });
                  }
                },
                onChange: ['isEyeRight'],
              },
              eyeLeft: {
                compute: (data) => {
                  if (!data?.isEyeLeft) {
                    return ({
                      set: {
                        eyeLeft: '',
                      },
                    });
                  }
                },
                onChange: ['isEyeLeft'],
              },
              visionRight: {
                compute: (data) => {
                  if (!data?.isVisionRight) {
                    return ({
                      set: {
                        visionRight: '',
                      },
                    });
                  }
                },
                onChange: ['isVisionRight'],
              },
              visionLeft: {
                compute: (data) => {
                  if (!data?.isVisionLeft) {
                    return ({
                      set: {
                        visionLeft: '',
                      },
                    });
                  }
                },
                onChange: ['isVisionLeft'],
              },
              iopRight: {
                compute: (data) => {
                  if (!data?.isIOPRight) {
                    return ({
                      set: {
                        iopRight: '',
                      },
                    });
                  }
                },
                onChange: ['isIOPRight'],
              },
              iopLeft: {
                compute: (data) => {
                  if (!data?.isIOPLeft) {
                    return ({
                      set: {
                        iopLeft: '',
                      },
                    });
                  }
                },
                onChange: ['isIOPLeft'],
              },
              roplasRight: {
                compute: (data) => {
                  if (!data?.isRoplasRight) {
                    return ({
                      set: {
                        roplasRight: '',
                      },
                    });
                  }
                },
                onChange: ['isRoplasRight'],
              },
              roplasLeft: {
                compute: (data) => {
                  if (!data?.isRoplasLeft) {
                    return ({
                      set: {
                        roplasLeft: '',
                      },
                    });
                  }
                },
                onChange: ['isRoplasLeft'],
              },
              tripleTest: {
                compute: (data) => {
                  if (data?.tripleTest === 'Negative') {
                    return ({
                      set: {
                        tripleTestPositiveDescription: '',
                      },
                    });
                  }
                },
                onChange: ['tripleTest'],
              },
            },
          }}

          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
              speciality: dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.PRE_OPERATIVE_CHECKLIST,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}

          type="standardShadow"
          lg={{
            validations: {
              systematicDisease: ({ data }) => validateInputLength(data?.systematicDisease, 0, 20),
              eyeRight: ({ data }) => validateInputLength(data?.eyeRight, 0, 20),
              eyeLeft: ({ data }) => validateInputLength(data?.eyeLeft, 0, 20),
              visionRight: ({ data }) => validateInputLength(data?.visionRight, 0, 20),
              visionLeft: ({ data }) => validateInputLength(data?.visionLeft, 0, 20),
              iopRight: ({ data }) => validateInputLength(data?.iopRight, 0, 20),
              iopLeft: ({ data }) => validateInputLength(data?.iopLeft, 0, 20),
              biometryK1: ({ data }) => validateInputLength(data?.biometryK1, 0, 50),
              biometryK2: ({ data }) => validateInputLength(data?.biometryK2, 0, 50),
              bloodsugar: ({ data }) => validateInputLength(data?.bloodsugar, 1, 20),
              bloodPressure: ({ data }) => validateInputLength(data?.bloodPressure, 1, 20),
              atiCoagulants: ({ data }) => validateInputLength(data?.atiCoagulants, 1, 20),
              hb: ({ data }) => validateInputLength(data?.hb, 1, 20),
              tripleTestPositiveDescription: ({ data }) => validateInputLength(data?.tripleTestPositiveDescription, 0, 100),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'text',
                            field: 'systematicDisease',
                            label: 'Name of Systematic Disease',
                            editable: true,
                            mandatory: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'checkList',
                            label: 'Check List',
                            editable: true,
                          },
                          {
                            type: 'date',
                            field: 'dateOfSurgery',
                            label: 'Date of Surgery',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [
                      {
                        columnsPerRow: 5,
                        style: { width: '55%' },
                        columns: [
                          {
                            render: () => '1. Eye',
                            style: { marginTop: 10, width: 120 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isEyeRight',
                            editable: true,
                            container: 'leftLabel',
                            label: 'Right',
                            variant: 'filled',
                            style: { marginLeft: 20 },
                          },
                          {
                            type: 'text',
                            field: 'eyeRight',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isEyeRight,
                          },
                          {
                            type: 'checkBox',
                            field: 'isEyeLeft',
                            container: 'leftLabel',
                            label: 'Left',
                            editable: true,
                            variant: 'filled',
                            style: { marginLeft: 20 },
                          },
                          {
                            type: 'text',
                            field: 'eyeLeft',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isEyeLeft,
                          },
                        ],
                      },
                      {
                        columnsPerRow: 1,
                        style: { marginLeft: '3%', width: '43%' },
                        columns: [
                          {
                            render: () => '9. Biometry',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { paddingTop: 0 },
                    groups: [
                      {
                        columnsPerRow: 5,
                        style: { width: '55%' },
                        columns: [
                          {
                            render: () => '2. Vision',
                            style: { marginTop: 10, width: 120 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isVisionRight',
                            editable: true,
                            container: 'leftLabel',
                            label: 'Right',
                            variant: 'filled',
                            style: { marginLeft: 20 },
                          },
                          {
                            type: 'text',
                            field: 'visionRight',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isVisionRight,
                          },
                          {
                            type: 'checkBox',
                            field: 'isVisionLeft',
                            container: 'leftLabel',
                            label: 'Left',
                            editable: true,
                            style: { marginLeft: 20 },
                            variant: 'filled',
                          },
                          {
                            type: 'text',
                            field: 'visionLeft',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isVisionLeft,
                          },
                          {
                            render: () => '3. IIOP(mmHg)',
                            style: { marginTop: 10, width: 120 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isIOPRight',
                            style: { marginLeft: 20 },
                            editable: true,
                            container: 'leftLabel',
                            label: 'Right',
                            variant: 'filled',
                          },
                          {
                            type: 'text',
                            field: 'iopRight',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isIOPRight,
                          },
                          {
                            type: 'checkBox',
                            field: 'isIOPLeft',
                            style: { marginLeft: 20 },
                            container: 'leftLabel',
                            label: 'Left',
                            editable: true,
                            variant: 'filled',
                          },
                          {
                            type: 'text',
                            field: 'iopLeft',
                            variant: 'filled',
                            editable: true,
                            disabled: (disableProps) => !disableProps?.data?.isIOPLeft,
                          },
                        ],
                      },
                      {
                        columnsPerRow: 2,
                        style: { marginLeft: 40, width: '45%' },
                        columns: [
                          {
                            render: () => 'i. K1 @',
                            style: { width: 100, marginTop: 10 },
                          },
                          {
                            type: 'text',
                            field: 'biometryK1',
                            variant: 'filled',
                            editable: true,
                            style: { flex: 2 },
                          },
                          {
                            render: () => 'i. K2 @',
                            style: { width: 100, marginTop: 10 },
                          },
                          {
                            type: 'text',
                            field: 'biometryK2',
                            variant: 'filled',
                            style: { flex: 2 },
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { paddingTop: 0 },
                    groups: [
                      {
                        columnsPerRow: 3,
                        style: { width: '55%' },
                        columns: [
                          {
                            render: () => '4. ROPLAS',
                            style: { marginTop: 10, width: 120 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isRoplasRight',
                            editable: true,
                            container: 'leftLabel',
                            label: 'Right',
                            variant: 'filled',
                            style: { marginLeft: '3%' },
                          },
                          {
                            type: 'checkBox',
                            field: 'isRoplasLeft',
                            container: 'leftLabel',
                            label: 'Left',
                            editable: true,
                            variant: 'filled',
                            style: { marginLeft: '31%' },
                          },
                          {
                            type: 'radioGroup',
                            field: 'roplasRight',
                            options: ['Positive', 'Negative'],
                            variant: 'filled',
                            editable: true,
                            style: { marginLeft: '17%' },
                            disabled: (disableProps) => !disableProps?.data?.isRoplasRight,
                          },
                          {
                            type: 'radioGroup',
                            field: 'roplasLeft',
                            options: ['Positive', 'Negative'],
                            variant: 'filled',
                            editable: true,
                            style: { marginLeft: '14%' },
                            disabled: (disableProps) => !disableProps?.data?.isRoplasLeft,
                          },
                        ],
                      },
                      {
                        columnsPerRow: 2,
                        style: { marginLeft: 40, width: '45%' },
                        columns: [
                          {
                            render: () => 'iii. AXL',
                            style: { width: 100, marginTop: 10 },
                          },
                          {
                            type: 'text',
                            field: 'biometryAXL',
                            style: { flex: 2 },
                            variant: 'filled',
                            editable: true,
                          },
                          {
                            render: () => 'iv. IOL',
                            style: { width: 100, marginTop: 10 },
                          },
                          {
                            type: 'text',
                            field: 'biometryIOL',
                            style: { flex: 2 },
                            variant: 'filled',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { paddingTop: 0 },
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            render: () => '5. Xylocame Senstivity',
                            style: { marginTop: 10, width: 150 },
                          },
                          {
                            type: 'radioGroup',
                            field: 'xylocameSensitivity',
                            editable: true,
                            options: ['Sensitive', 'Negative'],
                            variant: 'filled',
                          },
                        ],
                      },
                      {
                        columnsPerRow: 1,
                        style: { marginLeft: '10%', width: '43%' },
                        columns: [
                          {
                            render: () => '10. Less Tests',
                            style: { marginTop: 10 },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [
                      {
                        columnsPerRow: 2,
                        style: { padding: 0, width: '55%' },
                        columns: [
                          {
                            render: () => '6. Pupil Dolation',
                            style: { marginTop: 10, width: 150 },
                          },
                          {
                            type: 'radioGroup',
                            field: 'pupilDolation',
                            editable: true,
                            options: ['Poor', 'Moderate', 'Good'],
                            variant: 'filled',
                          },
                        ],
                      },
                      {
                        columnsPerRow: 3,
                        style: { marginLeft: 40, width: '45%' },
                        columns: [
                          {
                            render: () => 'Parameter',
                          },
                          {
                            render: () => 'Value',
                          },
                          {
                            render: () => 'Range',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [
                      {
                        columnsPerRow: 5,
                        style: { width: '55%' },
                        columns: [
                          {
                            render: () => '7. SL Examination',
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationSelf',
                            editable: true,
                            container: 'leftLabel',
                            label: 'Self',
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationHardNSI',
                            editable: true,
                            container: 'leftLabel',
                            label: 'Hard-NS-I',
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationII',
                            editable: true,
                            container: 'leftLabel',
                            label: 'II',
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationIII',
                            editable: true,
                            container: 'leftLabel',
                            label: 'III',
                          },
                        ],
                      },
                      {
                        columnsPerRow: 3,
                        style: { marginLeft: 40, width: '45%' },
                        columns: [
                          {
                            render: () => 'Blood Sugar',
                            style: { width: '18%' },
                          },
                          {
                            type: 'text',
                            field: 'bloodsugar',
                            variant: 'filled',
                          },
                          {
                            render: () => 'FBS<140mg%RBS<180mg%',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [
                      {
                        columnsPerRow: 3,
                        style: { width: '55%' },
                        columns: [
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationIV',
                            editable: true,
                            container: 'leftLabel',
                            label: 'IV',
                            style: { marginLeft: 150 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationPSC',
                            editable: true,
                            container: 'leftLabel',
                            label: 'PSC',
                            style: { marginLeft: 70 },
                          },
                          {
                            type: 'checkBox',
                            field: 'isSlExaminationBrown',
                            editable: true,
                            container: 'leftLabel',
                            style: { marginLeft: 60 },
                            label: 'Brown',
                          },
                          {
                            render: () => '8. Consent',
                            style: { marginTop: 10, width: 150 },
                          },
                          {
                            type: 'radioGroup',
                            options: ['Yes', 'No'],
                            field: 'consent',
                          },
                        ],
                      },
                      {
                        columnsPerRow: 3,
                        style: { marginLeft: 40, width: '45%' },
                        columns: [
                          {
                            render: () => 'Blood Pressure',
                            style: { width: '18%' },
                          },
                          {
                            type: 'text',
                            field: 'bloodPressure',
                            style: { width: '40%' },
                            variant: 'filled',
                          },
                          {
                            render: () => '<140/90mgHg',
                            style: { width: '20%' },
                          },
                          {
                            render: () => 'ATI-Coagulants',
                            style: { width: '18%' },
                          },
                          {
                            type: 'text',
                            field: 'atiCoagulants',
                            style: { width: '40%' },
                            variant: 'filled',
                          },
                          {
                            render: () => 'PT<18sec',
                            style: { width: '20%' },
                          },
                          {
                            render: () => 'HB',
                            style: { width: '18%' },
                          },
                          {
                            type: 'text',
                            field: 'hb',
                            variant: 'filled',
                            style: { width: '40%' },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [

                      {
                        columnsPerRow: 4,
                        style: { marginLeft: '57%', width: '45%' },
                        columns: [
                          {
                            render: () => '11. Triple Tests',
                            style: { width: '18%', marginTop: 10 },
                          },
                          {
                            type: 'radioGroup',
                            field: 'tripleTest',
                            style: { width: '36%' },
                            options: ['Negative', 'Positive'],
                            variant: 'filled',
                          },
                          {
                            type: 'text',
                            field: 'tripleTestPositiveDescription',
                            style: { width: '20%' },
                            variant: 'filled',
                            disabled: (disableProps) => !(disableProps?.data?.tripleTest === 'Positive'),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    style: { padding: 0 },
                    groups: [

                      {
                        columnsPerRow: 1,
                        style: { width: '100%' },
                        columns: [
                          {
                            render: () => 'Comments',
                          },
                          {
                            type: 'textArea',
                            field: 'comments',
                            variant: 'filled',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />

  );
};

const AddOperativeCheckList = (props) => (<IpdAddOperativeCheckList {...props} />);
export default AddOperativeCheckList;
