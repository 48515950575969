import React from 'react';
import { Table } from '../../../../../app-components/table/Table';

const tabListColumns = [
  { width: 20 },
  {
    header: 'pharmacy.labels.item'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
];

const ItemList = (props) => {
  const {
    data, id = '', model, tableColumns = tabListColumns, addOnFilter = {}, ...restProps
  } = props;
  return (
    <Table
      {...restProps}
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      lg={{ columns: tableColumns }}
      hoverable={false}
    />
  );
};
export default ItemList;
