import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import vars from '../../../../../../../../theme/vars';

const ViewOperativeNote = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Operative Notes"
      description="Logged Operative notes of the patient."
      content={(
        <Form
          {...props}
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          lg={{
            formGroups: [
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        render: () => item?.typeofAnesthesia,
                        label: 'Type Of Anesthesia',
                      },
                      {
                        type: 'text',
                        render: () => item?.anesthesia,
                        label: 'Anesthesia',
                      },
                      {
                        type: 'text',
                        render: () => item?.eye,
                        label: 'Eye',
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        render: () => item?.procedure,
                        label: 'Prodecure',
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 3,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'text',
                        label: 'Assistanta',
                        render: () => item?.assistants?.map((e) => e.name).join(', '),

                      },
                      {
                        type: 'text',
                        label: 'Surgeons',
                        render: () => item?.surgeons?.map((e) => e.name).join(', '),
                      },
                    ],
                  },
                ],
              },
              {
                columnsPerRow: 1,
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                editable: false,
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columns: [
                      {
                        type: 'textArea',
                        render: () => item?.remarks,
                        label: 'Remarks/Specific Notes',
                      },
                      {
                        render: () => 'POST-OPERATIVE NOTES',
                      },
                      {
                        render: () => 'i. Normal dies',
                      },
                      {
                        render: () => 'ii. No restriction on any normal physical activity. ',
                      },
                      {
                        render: () => 'iii. Analgesis',
                      },
                      {
                        render: () => '1. Tab brufen 2 tab 4 hrlt PC',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => '2. Tab paracetamol tab 4 hrlt PC',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => '3. Tab voveran 1 bd PC',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => 'iv. Antibiotics',
                      },
                      {
                        render: () => '1. Inj Gentamycin 80 mg IM 8 hrly',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => '2. Inj Amplicillin 500 mg IM 6 hrly',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => '3. Tab Ciprofloxacillin 5000 mg BD',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => '4. Tab septan 2 BD',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => 'v. Tab Diaxepam 1 tab after lunch and after dinner',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 0, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => 'vi. Tab diamox 1 to 8 hrly',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 0, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => 'vii. Continue antihypertensive/antibiatic treatment',
                        style: {
                          flex: 1, fontSize: 13, marginLeft: 0, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                        },
                      },
                      {
                        render: () => 'viii. Monitor TPR',
                      },
                      {
                        render: () => 'ix. Inform sos in case of pain, vomiting, fevera',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
    />
  );
};
export default ViewOperativeNote;
