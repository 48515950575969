import React, { useCallback } from 'react';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { Form } from '../../../../app-components/form/Form';
import { EditBuyer } from '../../../../Queries/auction';
import { View } from '../../../../app-components';
import FileLink from '../../../../Components/FileLink';
import Chips from '../../../../Components/Chips';

const ViewBuyerForm = (props) => {
  const { navigation } = props;
  const handleItemOnPress = useCallback((item) => {
    const newItem = {
      _id: item?._id,
      item: {
        _id: item?.itemId,
        item: item?.item,
      },
    };
    navigation.push({ view: ROUTES.viewAuctionItem.name, params: { item: newItem } });
  }, []);

  return (
    <Form
      {...props}
      type="standardShadow"
      uri={EditBuyer}
      editable={false}
      closeView={1}
      reloadEvent={`reload${MODELS.AUCTION_BUYER}`}
      reloadOnChangeEvent={`reload${MODELS.AUCTION_BUYER}`}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'name',
                    label: 'buyer.labels.buyerName'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'email',
                    label: 'buyer.labels.email'.getLabel(),
                  },
                  {
                    type: 'number',
                    field: 'contactNo',
                    label: 'buyer.labels.contactNumber'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'textArea',
                    field: 'address',
                    label: 'buyer.labels.address'.getLabel(),
                    numberOfLines: 2,
                    minHeight: 80,
                    style: { flex: 1, overflow: 'hidden' },
                  },
                  {
                    type: 'file',
                    field: 'documents',
                    label: 'asset.labels.attachDocuments'.getLabel(),
                    container: 'topLabel',
                    render: ({ data }) => {
                      const value = data?.documents;
                      if (Array.isArray(value)) {
                        return value.map((file, index) => <FileLink key={index} file={file} />);
                      }
                      return <View />;
                    },
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 1,
            columns: [
              {
                type: 'custom',
                field: 'broughtItems',
                render: ({ data: { broughtItems = [] } }) => {
                  const modifiedField = broughtItems.map((item) => ({ ...item?.item, itemId: item?.item?._id, _id: item?._id }));
                  return (
                    <Chips
                      data={modifiedField || []}
                      field="serialNumber"
                      label="BOUGHT ITEM DETAILS"
                      onPress={handleItemOnPress}
                      cursor="pointer"
                      pressable
                    />
                  );
                },
                editable: false,
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewBuyerForm;
