import { size } from 'lodash';
import React from 'react';

import {
  Text,
  TouchableOpacity,
} from '../../app-components';
import { Table } from '../../app-components/table/Table';
import FileLink from '../../Components/FileLink';
import { ROUTES } from '../../Lib/constants';
import { getString } from '../../Lib/helpers';
import vars from '../../theme/vars';

const tableItems = (navigation) => ({
  lg: {
    columns: [
      {
        header: 'consultation.labels.date'.getLabel(),
        type: 'date',
        field: '_createdOn',
      },
      {
        header: 'consultation.labels.time'.getLabel(),
        type: 'date',
        field: '_createdOn',
        format: 'hh:mm A',
      },
      {
        header: 'consultation.labels.speciality'.getLabel(),
        type: 'text',
        value: ({ item }) => item?.speciality?.name,
      },
      {
        header: 'consultation.labels.opdNo'.getLabel(),
        type: 'text',
        field: 'ODPNumber',
      },
      {
        header: 'consultation.labels.doctorName'.getLabel(),
        type: 'text',
        value: ({
          item:
           { sessionQueue: { doctor } = {} },
        }) => getString([doctor?.firstName, doctor?.lastName]),
      },
      {
        header: 'consultation.labels.labReports'.getLabel(),
        type: 'text',
        field: 'labReports',
      },
      {
        header: 'consultation.labels.prescription'.getLabel(),
        type: 'custom',
        field: 'prescription',
        render: ({ item }) => <FileLink file={item?.prescription} />,
      },
      {
        type: 'link',
        render: ({ item }) => (size(item?.pharmacyDetails) ? (
          <TouchableOpacity
            onPress={() => {
              navigation.push({
                view: ROUTES.opdIncomingPatientsViewDiagnosis.name,
                params: {
                  consultationId: item?._id,
                },
              });
            }}
            style={{ cursor: 'pointer', marginLeft: 10 }}
          >
            <Text style={{ color: vars.colors.secondary.color2 }}>
              View prescription
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={{ marginLeft: 10 }}
          >
            <Text style={{ color: vars.colors.grey.color3 }}>
              View prescription
            </Text>
          </TouchableOpacity>
        )),
      },
    ],
  },
});

const ConsultationLogs = (props) => {
  const {
    addOnFilter, model, data, id, navigation = {}, ...restProps
  } = props;
  /*
    We get params in navigation when we open screen as a modal
  */
  const {
    state: { params = {} } = {},
  } = navigation;

  return (
    <Table
      {...restProps}
      reloadEvent="reloadConsultations"
      uri={() => ({
        query: {
          id: id || params?.id,
          addOnFilter: addOnFilter || params?.addOnFilter,
          sort: { _createdOn: -1 },
        },
        model: model || params?.model,
      })}
      hoverable={false}
      {...tableItems(navigation)}
    />
  );
};

export default ConsultationLogs;

