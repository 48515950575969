import React from 'react';

import { save, Toast, Text } from '../../../../../../../../../app-components';
import ListActionModal from '../../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../../AppServices';
import { dateFormatX } from '../../../../../../../constants';
import vars from '../../../../../../../../../theme/vars';
import { newDateInput } from '../../../../../../../../../compositeInputs';

const IpdLogInputOutput = (props) => {
  const {
    navigation: { state: { params: { data: paramsData } = {} } = {} } = {},
  } = props;
  return (
    <ListActionModal
      {...props}
      title="Log Input Output Chart"
      description="Log today's Input Output of the Patient"
      content={(
        <Form
          {...props}
          closeView={1}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [save],
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            updates.vitalSignTime = dateFormatX(
              data?.hour,
              data?.minute,
              data?.meridiem,
            );
            delete updates.hour;
            delete updates.minute;
            delete updates.meridiem;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: {
                _id: submitProps?.navigation?.state?.params?.patient?._id,
              },
            };
            const submitAPI = submit({
              model: MODELS.IPD_VITALS_SIGN_SHEET,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>Input</Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          newDateInput({
                            hour: { field: 'hour', label: 'Hour' },
                            minute: { field: 'minute', label: 'Minute' },
                            meridiem: { field: 'meridiem' },
                          }),
                          {
                            type: 'text',
                            field: 'nurseName',
                            label: 'Nurse Name',
                            allowWhiteSpaces: true,
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>Intravenous Infusion </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'number',
                            field: 'volumeStarted',
                            label: 'Volume Started (ml)',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'volumeRemaining',
                            label: 'Volume Remaining (ml)',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'volumeInfused',
                            label: 'Volume Infused (ml)',
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>Oral </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    columnsPerRow: 3,
                    columns: [
                      {
                        type: 'number',
                        field: 'oralVolume',
                        label: 'Volume (ml)',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'oralType',
                        label: 'Type',
                        allowWhiteSpaces: true,
                        mandatory: true,
                        editable: true,
                      },
                    ],
                  },

                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => <Text>Output </Text>,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'number',
                            field: 'outputurine',
                            label: 'Urine (ml)',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'outputVomit',
                            label: 'Vomit',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'outputDrainage',
                            label: 'Drainage (ml)',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'oralAspirate',
                            label: 'Aspirate (ml)',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'oralOthers',
                            label: 'Others (ml)',
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
      )}
    />
  );
};

const LogInputOutput = (props) => <IpdLogInputOutput {...props} />;
export default LogInputOutput;
