import React from 'react';

import {
  cancelButton,
  save,
} from '../../../../../app-components';
import { autoSuggestInput, searchInput } from '../../../../../autoSuggestions';
import {
  validateEmail,
  validateInputLength,
  validateMobile,
  validatePinCode,
  validFutureDate,
} from '../../../../../Lib/helpers';
import {
  bloodGroup, genders, maritalStatus, MODELS, states,
} from '../../../../../Lib/constants';
import { EditEmployee as EditEmployeeQuery } from '../../../../../Queries/employee';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';
import { Form } from '../../../../../app-components/form/Form';

const EditEmployee = (props) => {
  const { visible = true } = props;

  return (
    <Form
      type="standardShadow"
      uri={EditEmployeeQuery}
      closeView={1}
      onSubmit={submit({
        model: MODELS.EMPLOYEE,
        lowerCaseFields: ['email'],
      })}
      reloadEvent={`reload${MODELS.EMPLOYEE}`}
      reloadOnChangeEvent={`reload${MODELS.EMPLOYEE}`}
      submitMessage={'employee.messages.updateMessage'.getLabel()}
      uniqueValidationMessage={'employee.messages.uniqueError'.getLabel()}
      mandatory={{
        firstName: 1,
        lastName: 1,
        gender: 1,
        fatherName: 1,
        dob: 1,
        bloodGroup: 1,
        mobile: 1,
        addressLine1: 1,
        city: 1,
        state: 1,
        pinCode: 1,
      }}
      lg={{
        validations: {
          firstName: ({ data }) => validateInputLength(data?.firstName, 2, 26),
          lastName: ({ data }) => validateInputLength(data?.lastName, 2, 26),
          mobile: ({ data }) => validateMobile(data?.mobile),
          addressLine1: ({ data }) => validateInputLength(data?.addressLine1, 0, 100),
          addressLine2: ({ data }) => validateInputLength(data?.addressLine2, 0, 100),
          email: ({ data }) => validateEmail(data?.email),
          pinCode: ({ data }) => validatePinCode(data?.pinCode),
          dob: ({ data }) => validFutureDate(data?.dob, 'Date Of Birth Should be less than current Date'),
        },
        header: visible ? {
          actions: [cancelButton, save({ label: 'employee.buttons.update'.getLabel() })],
        } : null,
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'employee.labels.personalDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'firstName',
                    label: 'employee.labels.firstName'.getLabel(),
                    placeholder: 'employee.placeholders.enterName'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'lastName',
                    label: 'employee.labels.lastName'.getLabel(),
                    placeholder: 'employee.placeholders.enterName'.getLabel(),
                    mandatory: true,
                  },
                  {
                    type: 'text',
                    field: 'fatherName',
                    label: 'Father Name',
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                  autoSuggestInput({
                    label: 'employee.labels.gender'.getLabel(),
                    field: 'gender',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectGender'.getLabel(),
                    options: genders,
                    mandatory: true,
                  }),
                  {
                    type: 'date',
                    field: 'dob',
                    label: 'employee.labels.dob'.getLabel(),
                    placeholder: 'employee.placeholders.selectDOB'.getLabel(),
                    mandatory: true,
                  },
                  autoSuggestInput({
                    label: 'employee.labels.bloodGroup'.getLabel(),
                    field: 'bloodGroup',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectbloodGroup'.getLabel(),
                    options: bloodGroup,
                    mandatory: true,
                  }),
                  autoSuggestInput({
                    label: 'employee.labels.maritalStatus'.getLabel(),
                    field: 'maritalStatus',
                    suggestionField: 'label',
                    placeholder: 'employee.placeholders.selectmaritalStatus'.getLabel(),
                    options: maritalStatus,
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Contact Information',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'number',
                    field: 'mobile',
                    label: 'employee.labels.mobileNo'.getLabel(),
                    placeholder: 'employee.placeholders.enterMobile'.getLabel(),
                    mandatory: true,
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 10) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'text',
                    field: 'email',
                    label: 'employee.labels.email'.getLabel(),
                    placeholder: 'employee.placeholders.enterEmail'.getLabel(),
                  },
                ],
              },
              {
                columnsPerRow: 2,
                columns: [
                  {
                    type: 'text',
                    field: 'addressLine1',
                    label: 'employee.labels.addressLine1'.getLabel(),
                    placeholder: 'employee.placeholders.addressLine1'.getLabel(),
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'addressLine2',
                    label: 'employee.labels.addressLine2'.getLabel(),
                    placeholder: 'employee.placeholders.addressLine2'.getLabel(),
                    allowWhiteSpaces: true,
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'city',
                    label: 'employee.labels.city'.getLabel(),
                    placeholder: 'employee.placeholders.selectCity'.getLabel(),
                    mandatory: true,
                  },
                  searchInput({
                    type: 'autoSuggest',
                    label: 'employee.labels.state'.getLabel(),
                    placeholder: 'employee.placeholders.selectState'.getLabel(),
                    mandatory: true,
                    valueField: 'value',
                    options: states,
                    suggestionField: 'label',
                    field: 'state',
                  }),
                  {
                    type: 'number',
                    field: 'pinCode',
                    label: 'employee.labels.pinCode'.getLabel(),
                    placeholder: 'employee.placeholders.enterPincode'.getLabel(),
                    mandatory: true,
                  },
                ],
              },
            ],
          },
        ],
      }}

      {...props}
    />
  );
};

export default EditEmployee;
