import React from 'react';
import { Table } from '../../../../../../../../app-components/table/Table';

const tabListColumns = [
  {
    width: 14,
  },
  {
    header: 'asset.headers.department'.getLabel(),
    type: 'text',
    field: 'department.name',
  },
  {
    header: 'asset.headers.stockAvailable'.getLabel(),
    type: 'text',
    field: 'stockAvailable',
  },
  {
    header: 'asset.headers.stockStatus'.getLabel(),
    type: 'text',
    field: 'stockStatus',
  },
];

const DepartmentWiseStockList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;

  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
        },
        model,
      })}
      columns={tableColumns}
      showActionIcon={false}
      {...restProps}
    />
  );
};
export default DepartmentWiseStockList;
