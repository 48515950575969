import React from 'react';
import { Text, Toast, View } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import { getUser } from '../../../../AppServices';
import {
  assetStatuses,
  MODELS,
  ROUTES,
  supervisorAndInChargeAssetStatus,
} from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import { SupervisorAssetList } from '../../../../Queries/asset';
import { AssetInventoryInChargeToggleFilter } from '../../../../app-components/filter/MamcToggleFilters';

const header = ({ filter }) => ({
  leftActions: [(props) => (
    <AssetInventoryInChargeToggleFilter
      {...props}
      model={MODELS.ASSET}
      id="assetInventoryDataList"
      filter={filter}
    />
  )],
});

const tabListColumns = [
  { width: 14 },
  {
    header: 'asset.headers.serialNo'.getLabel(),
    type: 'text',
    field: 'serialNumber',
    sortable: 'serialNumber',
  },
  {
    header: 'asset.headers.assetName'.getLabel(),
    type: 'text',
    field: 'item.name',
  },
  {
    header: 'asset.headers.category'.getLabel(),
    type: 'text',
    field: 'item.category.name',
  },
  {
    header: 'asset.headers.department'.getLabel(),
    type: 'text',
    field: 'department.name',
  },
  {
    header: 'asset.headers.speciality'.getLabel(),
    type: 'text',
    field: 'speciality.name',
  },
  {
    header: 'asset.headers.status'.getLabel(),
    type: 'text',
    render: ({ item: { assetStatus } }) => {
      let color = '';
      let status = assetStatus;
      if (assetStatus === supervisorAndInChargeAssetStatus.inStorage) {
        color = vars.colors.primary.color2;
      } else if (assetStatus === 'inAuction') {
        status = 'In-Auction';
        color = vars.colors.warning;
      }
      if (assetStatus
        === (supervisorAndInChargeAssetStatus.inRepair
          || supervisorAndInChargeAssetStatus.outOfOrder)
      ) {
        color = vars.colors.error;
      }
      if (assetStatus === supervisorAndInChargeAssetStatus.loanedOut) {
        color = vars.colors.warning;
      }
      return (
        <Text style={{ ...vars.headings.h9, color }}>
          {status}
        </Text>
      );
    },
  },
  {
    header: 'asset.headers.action'.getLabel(),
    type: 'openActions',
    openActions: [
      {
        render: ({ item, navigation }) => {
          const handleOnPressRecordReturnDate = () => {
            navigation.push({
              view: ROUTES.addAssetServiceReturnDateModal.name,
              params: { item, fromItemList: true },
            });
          };

          return item?.assetStatus === assetStatuses.IN_SERVICE ? (
            <View
              onPress={handleOnPressRecordReturnDate}
              style={{ borderBottomWidth: 1, borderBottomColor: vars.colors.secondary.color2 }}
            >
              <Text style={{ color: vars.colors.secondary.color2 }}>Record Return Date</Text>
            </View>
          )
            : (
              <View>
                <Text style={{ color: vars.colors.grey.color3 }}>Record Return Date</Text>
              </View>
            );
        },
      },
    ],
  },
];

const AssetItemSupervisorList = (props) => {
  const { tableColumns = tabListColumns } = props;
  const user = getUser();
  if (user?.username !== 'superadmin') {
    const { role = [], employee: { _id } } = user;
    const isSupervisor = role?.some(({ code }) => code === 'Supervisor');
    if (isSupervisor) {
      return (
        <Table
          lg={{ columns: tableColumns }}
          uri={SupervisorAssetList({ filter: { supervisor: { _id } } })}
          reloadEvent={`reload${MODELS.ASSET}`}
          header={(headerProps) => header({ ...headerProps, filter: { supervisor: { _id } } })}
          action={({ item }) => ({
            type: 'link',
            link: {
              view: ROUTES.viewAssetsSupervisorRecord.name,
              params: { item },
            },
          })}
          {...props}
        />
      );
    }
  }

  Toast.show({
    message: 'Error',
    description: 'Login user is not an Supervisor',
    type: 'error',
    position: 'top',
    direction: 'right',
    duration: 3000,
  });
  return null;
};
export default AssetItemSupervisorList;
