import {
  Toast,
} from '../../../app-components';
import { invoke } from '../../../AppServices';
import { MODELS } from '../../../Lib/constants';
import { getErrorString } from '../../../Lib/helpers';
import { BLOOD_REQUEST_STATUSES } from './constants';

export const rejectBloodRequest = async (props) => {
  try {
    const { navigation, eventDispatcher } = props;
    const bloodRequest = navigation.getParam('item');

    const result = await invoke({
      id: 'manageBloodRequest',
      paramValue: {
        bloodRequestId: bloodRequest._id,
        status: BLOOD_REQUEST_STATUSES.REJECTED,
      },
    });
    if (result) {
      Toast.show({
        message: 'Success',
        description: 'bloodInventory.messages.rejectRequest'.getLabel(),
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_REQUEST}`);
      navigation.pop();
    }
  } catch (e) {
    Toast.show({
      message: 'Error',
      description: `${getErrorString(e)}`,
      type: 'error',
    });
  }
};

export const approveBloodRequest = async (props) => {
  try {
    const { navigation, eventDispatcher, data } = props;

    const result = await invoke({
      id: 'manageBloodRequest',
      paramValue: {
        bloodRequestId: data._id,
        status: BLOOD_REQUEST_STATUSES.APPROVED,
        items: data.raise_blood_request,
      },
    });
    if (result) {
      Toast.show({
        message: 'Success',
        description: 'bloodInventory.messages.approveAndIssue'.getLabel(),
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_REQUEST}`);
      navigation.pop();
    }
  } catch (e) {
    Toast.show({
      message: 'Error',
      description: `${getErrorString(e)}`,
      type: 'error',
    });
  }
};

export const requestSampleForBloodRequest = async (props) => {
  try {
    const { navigation, eventDispatcher } = props;
    const bloodRequest = navigation.getParam('item');

    const result = await invoke({
      id: 'manageBloodRequest',
      paramValue: {
        bloodRequestId: bloodRequest._id,
        status: BLOOD_REQUEST_STATUSES.SAMPLE_REQUESTED,
      },
    });
    if (result) {
      Toast.show({
        message: 'Success',
        description: 'bloodInventory.messages.requestSample'.getLabel(),
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_REQUEST}`);
      navigation.pop();
    }
  } catch (e) {
    Toast.show({
      message: 'Error',
      description: `${getErrorString(e)}`,
      type: 'error',
    });
  }
};

export const handleSampleSentForBloodRequest = async (props) => {
  try {
    const { navigation, eventDispatcher } = props;
    const bloodRequest = props?.item;

    const result = await invoke({
      id: 'manageBloodRequest',
      paramValue: {
        bloodRequestId: bloodRequest._id,
        status: BLOOD_REQUEST_STATUSES.SAMPLE_SENT,
      },
    });
    if (result) {
      Toast.show({
        message: 'Success',
        description: 'bloodInventory.messages.requestSample'.getLabel(),
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_REQUEST}`);
      navigation.pop();
    }
  } catch (e) {
    Toast.show({
      message: 'Error',
      description: `${getErrorString(e)}`,
      type: 'error',
    });
  }
};

export const handleSampleReceivedForBloodRequest = async (props) => {
  try {
    const { navigation, eventDispatcher } = props;
    const bloodRequest = props?.item;

    const result = await invoke({
      id: 'manageBloodRequest',
      paramValue: {
        bloodRequestId: bloodRequest._id,
        status: BLOOD_REQUEST_STATUSES.SAMPLE_RECEIVED,
      },
    });
    if (result) {
      Toast.show({
        message: 'Success',
        description: 'bloodInventory.messages.requestSample'.getLabel(),
      });
      eventDispatcher.notify(`reload${MODELS.BLOOD_REQUEST}`);
      navigation.pop();
    }
  } catch (e) {
    Toast.show({
      message: 'Error',
      description: `${getErrorString(e)}`,
      type: 'error',
    });
  }
};
