import React from 'react';

import { consultationListUri } from '../../../Queries/consultations';
import { Table } from '../../../app-components/table/Table';

const tableColumns = {
  lg: {
    columns: [
      {
        header: 'consultation.headers.consultationDate'.getLabel(),
        type: 'date',
        field: '_createdOn',
        width: 100,
      },
      {
        header: 'consultation.headers.doctorName'.getLabel(),
        field: 'consultedByName',
      },
      {
        header: 'consultation.headers.speciality'.getLabel(),
        type: 'text',
        field: 'speciality.name',
      },
      {
        header: 'consultation.headers.consultationStatus'.getLabel(),
        type: 'consultationStatus',
        field: 'status',
      },
    ],
  },
};

const PatientConsultationHistory = (props) => {
  const { item = {} } = props;
  return (
    <Table
      {...props}
      uri={consultationListUri({ filter: { patient: { _id: item?.patient_id?._id } } })}
      {...tableColumns}
    />
  );
};
export default PatientConsultationHistory;
