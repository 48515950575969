import React from 'react';

import { capitalize } from 'lodash';
import { AssetPurchaseEntityRequestListUri } from '../../../../../../Queries/asset';
import { Form } from '../../../../../../app-components/form/Form';
import { Text } from '../../../../../../app-components';
import vars from '../../../../../../theme/vars';
import { purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';

const ViewRepairRequests = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      uri={AssetPurchaseEntityRequestListUri({
        filter: {
          type: 'repair',
          _id: item?._id,
        },
      })}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    field: 'item.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'item.category.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.subCategory'.getLabel(),
                    field: 'item.subCategory.name',
                    editable: false,
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.approximateCost'.getLabel(),
                    field: 'approximateCost',
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.reasonForRepair'.getLabel(),
                    field: 'reasonForRepair',
                    editable: false,
                  },
                  {
                    label: 'asset.labels.status'.getLabel(),
                    type: 'text',
                    field: 'requestStatus',
                    render: ({
                      data: {
                        isRepairRequestApproveByCentral = false, requestStatus = '',
                      } = {},
                    }) => {
                      let purchaseRequestStatus = '';
                      let statusColor = '';
                      if (isRepairRequestApproveByCentral) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.APPROVE;
                        statusColor = vars.colors.primary.color2;
                      } else if (requestStatus === purchaseSupplyOrderStatus.RAISED
                        && !isRepairRequestApproveByCentral) {
                        purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.RAISED);
                        statusColor = vars.colors.emphasis;
                      } else if (requestStatus === purchaseSupplyOrderStatus.PO_IN_APPROVAL) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PO_IN_APPROVAL;
                        statusColor = vars.colors.warning;
                      } else if (requestStatus === purchaseSupplyOrderStatus.PO_APPROVED) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.PO_APPROVED;
                        statusColor = vars.colors.warning;
                      } else if (requestStatus === purchaseSupplyOrderStatus.SO_IN_APPROVAL) {
                        purchaseRequestStatus = purchaseSupplyOrderStatus.SO_IN_APPROVAL;
                        statusColor = vars.colors.warning;
                      } else if (requestStatus === purchaseSupplyOrderStatus.SO_APPROVED) {
                        purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.APPROVED);
                        statusColor = vars.colors.primary.color2;
                      } else {
                        purchaseRequestStatus = capitalize(purchaseSupplyOrderStatus.REJECTED);
                        statusColor = vars.colors.error;
                      }
                      return (
                        <Text style={{ ...vars.headings.h8, color: statusColor }}>
                          {purchaseRequestStatus}
                        </Text>
                      );
                    },
                    editable: false,
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: 'purchaseRequestEntity._id',
                    id: 'purchaseRequestAssetList',
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewRepairRequests;
