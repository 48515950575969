import { isArray, upperFirst } from 'lodash';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
// eslint-disable-next-line import/no-cycle
import { fetch } from '../../AppServices';
import { approvedIcon, pendingIcon,  redCloseIcon } from '../../images';
import {
  inventoryType, MODELS, purchaseSupplyOrderStatus, Roles,
} from '../../Lib/constants';
import vars from '../../theme/vars';

import './progressBar.scss';

const StepItem = ({ position, status = '' }) => (
  <div className="step-element" style={{ left: position }}>
    <div className={`step-circle ${status}`}>
      <div className="inner-circle" />
    </div>
  </div>

);
const TitleItem = ({ position, title }) => (
  <div className="title-element" style={{ left: position }}>
    <div className="title-item">{title}</div>
  </div>
);

const ContentItem = ({ position, content = [] }) => (
  content?.length ? (
    <div className="content-element" style={{ left: position }}>
      <div className="content-item">
        <div className="title">Status :</div>
        <div className="content-items-container">
        {content.map((item) => (
          <div
          key={item.id}
          className="item"
          style={{
            paddingTop: 6, display: 'flex', flex: 1, flexDirection: 'row', paddingLeft: 6, paddingBottom: 4,
          }}
          >
            <img src={item.icon} alt="" height="20" />
            <div style={{ paddingLeft: 4, ...vars.headings.h13, color: vars.colors.grey.color2 }}>
              {item.text}
              <div style={{ ...vars.headings.h16, color: vars.colors.grey.color4 }}>
                {item?.statusChangedOnDate ? moment(item.statusChangedOnDate).format('DD/MM/YY hh:mm A') : ''}
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  ) : null
);

const Progress = ({ value, max }) => {
  const width = useMemo(() => `${(value / max) * 100}%`, [max, value]);
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-value" style={{ width }} />
    </div>
  );
};

const Steps = ({ stepsList, current }) => {
  const getPosition = useCallback((index) => (`${(index / (stepsList.length - 1)) * 100}%`), [stepsList]);

  const getStatus = useCallback((index) => {
    if (index === current) return 'current';
    if (index < current) return 'completed';
    return '';
  }, [current]);

  return (
    <div className="steps-container">
      <div className="titles-container">
        {stepsList.map((item, index) => (
          <TitleItem position={getPosition(index)} key={item?.id} title={item?.title} />
        ))}
      </div>
      <div className="progress-container">
        <Progress value={current} max={(stepsList.length - 1)} />
        {stepsList.map((item, index) => (
          <StepItem
            key={item?.id}
            status={getStatus(index)}
            position={getPosition(index)}
          />
        ))}
      </div>
      <div className="content-container">
        {stepsList.map((item, index) => (
          <ContentItem position={getPosition(index)} key={item?.id} content={item?.content} />
        ))}
      </div>
    </div>
  );
};

export const Theme = (props) => {
  const { value = '', id = '' } = props;
  
  const [requestStatus, setRequestStatus] = useState('');
  const [steps, setSteps] = useState([]);
  const [ps2Queues, setPS2Queues] = useState([]);
  const [requestCreatedTime, setRequestCreatedTime] = useState();
  const [stockEnteredTime, setStockEnteredTime] = useState();
  const [subType, setSubType] = useState();
  const [isPS2VisibleForAsset, setPS2VisibleForAsset] = useState(false);

  const fetchQueueDetails = useCallback(async () => {
    const fetchedQueueDetails = await fetch({
      uri: {
        props: {
          query: {
            id,
            addOnFilter: {
              _id: isArray(value) ? value[0] : value,
            },
          },
          model: MODELS.PURCHASE_REQUEST,
          limit: 1,
        },
      },
    });
    setRequestCreatedTime(fetchedQueueDetails?.data[0]?._createdOn);
    setStockEnteredTime(fetchedQueueDetails?.data[0]?._updatedOn);
    setSteps(fetchedQueueDetails?.data[0]?.queues);
    setPS2Queues(fetchedQueueDetails?.data[0]?.queues);
    setRequestStatus(fetchedQueueDetails?.data[0]?.status);
    setSubType(fetchedQueueDetails?.data[0]?.subType);
    setPS2VisibleForAsset(fetchedQueueDetails?.data[0]?.isContractual === false);
    return fetchedQueueDetails;
  }, [id, value]);

  useEffect(() => {
    fetchQueueDetails();
  }, [fetchQueueDetails]);

  const POStepsData = useMemo(() => steps?.map((item) => item)
    ?.filter(({
      purchaseSupplyOrder,
      isContractQueueCreated = false,
    }) => (
      purchaseSupplyOrder.type === 'PO'
    && !isContractQueueCreated
    )), [steps]);

  const PO2StepsData = useMemo(() => ps2Queues
    ?.map((item) => item)
    ?.filter(({
      purchaseSupplyOrder,
      isContractQueueCreated = false,
    }) => (
      purchaseSupplyOrder?.type === 'PO'
      && !purchaseSupplyOrder?.isContractual
      && isContractQueueCreated
      && purchaseSupplyOrder?.inventoryType === inventoryType?.asset
    )), [ps2Queues]);

  const SOStepsData = useMemo(() => steps
    ?.map((item) => item)
    ?.filter(({ purchaseSupplyOrder }) => purchaseSupplyOrder.type === 'SO'), [steps]);

  const POStepsContent = useMemo(() => POStepsData?.map((item, index) => ({
    id: index + 1,
    text: item.assignedToRole === Roles.hod ? `${item?.reviewByDepartment?.name + ' ' +  item?.assignedToRole}` : item.assignedToRole,
    statusChangedOnDate: item?._createdOn,
    icon: item?.status === purchaseSupplyOrderStatus.PENDING ? pendingIcon :  item?.status === purchaseSupplyOrderStatus.REJECTED ? redCloseIcon :approvedIcon,
  })), [POStepsData]);

  const PO2StepsContent1 = useMemo(() => PO2StepsData?.map((item, index) => ({
    id: index + 1,
    text: item.assignedToRole,
    statusChangedOnDate: item._createdOn,
    icon: item?.status === purchaseSupplyOrderStatus.PENDING ? pendingIcon : item?.status === purchaseSupplyOrderStatus.REJECTED? redCloseIcon :approvedIcon,
  })), [PO2StepsData]);

  const SOStepsContent = useMemo(() => SOStepsData?.map((item, index) => ({
    id: index + 1,
    text: item.assignedToRole === Roles.hod ? `${item?.reviewByDepartment?.name + ' ' +  item?.assignedToRole}` : item.assignedToRole,
    statusChangedOnDate: item._createdOn,
    icon: item?.status === purchaseSupplyOrderStatus.PENDING ? pendingIcon : approvedIcon,
  })), [SOStepsData]);

  let currentValue;

  if (requestStatus === purchaseSupplyOrderStatus.RAISED) {
    currentValue = 0;
  } else if (
    requestStatus === purchaseSupplyOrderStatus.PO_IN_APPROVAL
    || requestStatus === purchaseSupplyOrderStatus.PO_APPROVED
    || requestStatus === purchaseSupplyOrderStatus.PO_REJECTED
  ) {
    currentValue = 1;
  } else if (
    requestStatus === purchaseSupplyOrderStatus.SO_IN_APPROVAL
    || requestStatus === purchaseSupplyOrderStatus.SO_APPROVED
    || requestStatus === purchaseSupplyOrderStatus.SO_REJECTED
  ) {
    currentValue = 2;
  } else if (
    requestStatus === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING
    || requestStatus === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED
  ) {
    currentValue = 3;
  } else if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
    currentValue = 4;
  }

  let paymentStepsContent = [];

  if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
    paymentStepsContent = [{
      _id: 1,
      text: purchaseSupplyOrderStatus.PAYMENT_PENDING,
      icon: pendingIcon,
    }];
  } else if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_DONE) {
    paymentStepsContent = [{
      _id: 1,
      text: purchaseSupplyOrderStatus.PAYMENT_DONE,
      icon: approvedIcon,
    }];
  }

  let stockEntryStepsContent = [];
  if (requestStatus === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING|| requestStatus === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED) {
    stockEntryStepsContent = [{
      _id: 1,
      statusChangedOnDate: '',
      icon: pendingIcon,
      text: requestStatus,
    }];
  } else if (
    requestStatus === purchaseSupplyOrderStatus.PAYMENT_PENDING
    || requestStatus === purchaseSupplyOrderStatus.PAYMENT_DONE
  ) {
    stockEntryStepsContent = [{
      _id: 1,
      statusChangedOnDate: stockEnteredTime,
      icon: approvedIcon,
      text: 'Stock Provided',
    }];
  }

  return (
    < >
      {isPS2VisibleForAsset ? (
        <Steps
          stepsList={[
            {
              id: 1,
              title: 'PURCHASE REQUEST',
              content: [{
                id: 1,
                text: upperFirst(purchaseSupplyOrderStatus.RAISED),
                statusChangedOnDate: requestCreatedTime,
                icon: approvedIcon,
              }],
            },
            { id: 2, title: 'PURCHASE SANCTION (1)', content: POStepsContent },
            { id: 3, title: 'PURCHASE SANCTION (2)', content: PO2StepsContent1 },
            { id: 4, title: 'SUPPLY ORDER', content: SOStepsContent },
            {
              id: 5,
              title: subType === 'repair' ? 'REPAIR' : 'STOCK ENTRY',
              content: stockEntryStepsContent,
            },
            {
              id: 6,
              title: 'PAYMENT',
              content: paymentStepsContent,
            },
          ]}
          current={currentValue}
        />
      ) : (
        <Steps
          stepsList={[
            {
              id: 1,
              title: 'PURCHASE REQUEST',
              content: [{
                id: 1,
                text: upperFirst(purchaseSupplyOrderStatus.RAISED),
                statusChangedOnDate: requestCreatedTime,
                icon: approvedIcon,
              }],
            },
            { id: 2, title: 'PURCHASE SANCTION', content: POStepsContent },
            { id: 3, title: 'SUPPLY ORDER', content: SOStepsContent },
            {
              id: 4,
              title: subType === 'repair' ? 'REPAIR' : 'STOCK ENTRY',
              content: stockEntryStepsContent,
            },
            {
              id: 5,
              title: 'PAYMENT',
              content: paymentStepsContent,
            },
          ]}
          current={currentValue}
        />
      )}
    </>
  );
};
