import { cancelButton, save } from '../../../app-components';

import MasterForm from '../../../Wired/Master/MasterForm';
import { EditCity } from '../../../Queries/city';
import { MODELS } from '../../../Lib/constants';

const header = {
  title: 'Edit City',
  actions: [cancelButton(), save()],
};

export default MasterForm({
  uri: EditCity,
  header,
  model: MODELS.CITY,
});
