import React from 'react';

import {
  FormField, LargeButton, View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { submit } from '../../../../../AppServices';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import ListActionModal from '../../../../../Components/ListActionModal';
import { history } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const PaymentDoneModal = (props) => (
  <ListActionModal
    icon={history}
    title="Payment Done"
    // description="This action will download salary sheet for selected month and year"
    content={(
      <Form
        onSubmit={submit({
          model: MODELS.PAYMENT_DONE,
        })}
        submitMessage="Success"
        {...props}
        mandatory={{
          month: 1,
          year: 1,
          // department: 1,
          //   speciality: 1,
          //   employeeType: 1,
          staffType: 1,
        }}
        containerStyle={{
          flex: 1,
          backgroundColor: vars.colors.white,
        }}
        bodyContainerStyle={{
          margin: 20,
          flex: 1,
          backgroundColor: vars.colors.white,
        }}
        defaultValues={() => ({
          month: props?.navigation?.state?.params?.month_id?.value,
        })}
        closeView={1}
      >
        {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <View>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'month',
                        label: 'Select Month',
                        placeholder: 'Select Month',
                        variant: 'filled',
                        suggestionField: 'name',
                        model: MODELS.MONTHS,
                        query: 'MonthTypeSuggestions',
                        mandatory: true,
                      },
                    )}
                  />
                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'year',
                          label: 'Select Year',
                          placeholder: 'Select Year',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.YEARS,
                          query: 'YearTypeSuggestions',
                          mandatory: true,
                        },
                      )}
                    />
                  </View>
                  {/* <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'department',
                          label: 'Department',
                          placeholder: 'Select department',
                          keyField: 'name',
                          variant: 'filled',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.DEPARTMENTS,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'speciality',
                          label: 'patient.labels.speciality'.getLabel(),
                          placeholder: 'patient.placeholders.selectWard'.getLabel(),
                          keyField: 'name',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.WARDS,
                          query: 'masterDataList',
                          variant: 'filled',
                        },
                      )}
                    />
                  </View> */}
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'staffType',
                          label: 'Select Staff Type',
                          placeholder: 'Select Staff Type',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.STAFFTYPE,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'employeeType',
                          label: 'Select Employee Type',
                          placeholder: 'Select Employee Type',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEETYPE,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>

                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Done"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </View>
              );
            }
          }
      </Form>
        )}
    {...props}
  />
);

export default PaymentDoneModal;
