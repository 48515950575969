import React from 'react';
import { Table } from '../../../../app-components/table/Table';
import { ROUTES } from '../../../../Lib/constants';
import { bloodRequestApprovedList } from '../../../../Queries/bloodBank';

const tabListColumns = [
  { width: 14 },
  {
    header: 'bloodBankRecords.headers.issueDate'.getLabel(),
    type: 'date',
    field: 'issueDate',
    sortable: 'date',
  },
  {
    header: 'bloodBankRecords.headers.issueNumber'.getLabel(),
    type: 'text',
    field: 'issueId',
    sortable: 'issueNumber',
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.issuer'.getLabel(),
    type: 'text',
    value: ({ item }) => {
      if (item?.is_issue_to_company) {
        return item?.issue_to_company?.name;
      }

      return item?.patient?.patientName;
    },
  },
  {
    header: 'bloodBankRecords.headers.reason'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'bloodBankRecords.headers.unitIssued'.getLabel(),
    type: 'number',
    value: ({ item }) => item?.bag_details?.length,
  },
];

const BloodRecordsIssuingList = (props) => {
  const { tableColumns = tabListColumns } = props;
  return (
    <Table
      lg={{ columns: tableColumns }}
      uri={bloodRequestApprovedList({ filter: { issueId: { $exists: true } } })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewBloodIssuing.name,
          params: { item },
        },
      })}
      {...props}
    />
  );
};
export default BloodRecordsIssuingList;
