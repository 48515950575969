import React from 'react';

import { ActivityIndicator, Toast } from '../../../app-components';
import { TableActionButton } from '../../../app-components/buttons/Buttons';
import { getErrorString } from '../../../Lib/helpers';
import IncomingPatients from './VaccinationPatients';
import { invoke } from '../../../AppServices';
import vars from '../../../theme/vars';
import ListOfPatients from './ListOfPatients';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';

const SessionNavigation = (props) => {
  const { sessionInfo, eventDispatcher } = props;

  return (
    <TabNavigator
      tabs={{
        VaccinationPatients: {
          label: 'Vaccinated Patients',
          screen: () => (sessionInfo?.session?._id
            ? (
              <IncomingPatients
                {...props}
                addOnFilter={{ status: { $in: ['nurse-assigned', 'not-appeared', 'vaccinated', 'provided'] }, session: { _id: sessionInfo?.session?._id } }}
              />
            ) : <ActivityIndicator />),
          actions: [
            {
              render: () => (
                <TableActionButton
                  label="Call In Patient"
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  width={150}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                  onPress={async () => {
                    try {
                      const result = await invoke({
                        id: 'callInPatientVaccination',
                      });

                      eventDispatcher.notify('vaccinationAssigned');
                      if (result.status === 'nurse-assigned') {
                        Toast.show({
                          message: 'Success',
                          description: 'Patient Called successfully',
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                      }
                    } catch (e) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(e)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  }}
                />
              ),
            },
          ],
        },
        ListOfPatients: {
          label: 'List Of Patients',
          screen: () => <ListOfPatients sessionInfo={sessionInfo} {...props} addOnFilter={{}} />,
        },
      }}
    />
  );
};
export default SessionNavigation;
