import React, { useCallback, useState } from 'react';
import { Form } from '../../app-components/form/Form';
import { invoke } from '../../AppServices';
import { getErrorString, getEstimatedDepreciationValue, getMonthOfOperation } from '../../Lib/helpers';
import { save, Text, Toast } from '../../app-components';
import ListActionModal from '../../Components/ListActionModal';
import { MODELS } from '../../Lib/constants';
import vars from '../../theme/vars';
import { setPrice } from '../../images';

const SendToAuction = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {}, eventDispatcher } = props;
  const [currentValue, setCurrentValue] = useState(0);
  const handleSendToAuction = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'sendItemToAuction',
        paramValue: { data, currentValue },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Asset has been sent to auction successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify(`reload${MODELS.ASSET}`);
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [currentValue, eventDispatcher]);

  return (
    <ListActionModal
      icon={setPrice}
      title="Send To Auction"
      description="Send item to auction"
      content={(
        <Form
          {...props}
          footer={{
            actions: [
              save({ label: 'Send To Auction' }),
            ],
          }}
          closeView={1}
          reloadEvent={`reload${MODELS.AUCTION_ITEMS}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({ ...item })}
          onSubmit={handleSendToAuction}
          mandatory={{
            rate: 1,
            reason: 1,
          }}
          validations={{
            rate: ({ data }) => {
              if (data?.rate > 100) {
                return 'Rate can not be more than 100%';
              }
            },
          }}
          {...props}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      {
                        type: 'number',
                        field: 'batchNumber.purchasedPrice',
                        placeholder: 'auction.labels.purchasedPrice'.getLabel(),
                        label: 'auction.labels.purchasedPrice'.getLabel(),
                        editable: false,
                      },
                      {
                        type: 'number',
                        label: 'auction.labels.estimatedDepreciation'.getLabel(),
                        editable: false,
                        render: ({ data }) => {
                          const rate = (data?.rate);
                          const Principle = data?.batchNumber?.purchasedPrice;
                          const months = getMonthOfOperation(data?._createdOn);
                          return (
                            <Text
                              style={{
                                ...vars.headings.h9,
                              }}
                            >
                              {Math.round(getEstimatedDepreciationValue(rate, Principle, months))}
                            </Text>
                          );
                        },
                      },
                      {
                        type: 'number',
                        field: 'currentValue',
                        label: 'auction.labels.currentValue'.getLabel(),
                        editable: false,
                        render: ({ data }) => {
                          const rate = (data?.rate);
                          const Principle = data?.batchNumber?.purchasedPrice;
                          const months = getMonthOfOperation(data?._createdOn);
                          setCurrentValue(Math.round(Principle - getEstimatedDepreciationValue(rate, Principle, months)));
                          return (
                            <Text
                              style={{
                                ...vars.headings.h9,
                              }}
                            >
                              {Math.round(Principle - getEstimatedDepreciationValue(rate, Principle, months))}
                            </Text>
                          );
                        },
                      },
                      {
                        type: 'number',
                        field: 'rate',
                        placeholder: 'auction.labels.rate'.getLabel(),
                        label: 'auction.labels.rate'.getLabel(),
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        type: 'textArea',
                        field: 'reason',
                        placeholder: 'auction.labels.reason'.getLabel(),
                        label: 'auction.labels.reason'.getLabel(),
                        mandatory: true,
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default SendToAuction;
