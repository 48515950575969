import { MODELS } from '../Lib/constants';

export const GetAnesthesiaReportUri = (filter = {}) => ({
  query: {
    model: MODELS.ANESTHESIA_REPORT,
    id: 'anesthesiaReportIPDDataList',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.ANESTHESIA_REPORT,
});
