import React from 'react';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import MasterList from '../../../../Wired/Master/MasterList';
import permissions from '../../../../Lib/permissions';
import { ToggleFilter } from '../../../../app-components';

const tableColumns = [
  { width: 20 },
  {
    header: 'lab.labels.lab'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'lab.labels.labID'.getLabel(),
    type: 'text',
    field: 'code',
  },
  {
    header: 'lab.labels.room'.getLabel(),
    type: 'link',
    value: ({ item }) => item?.rooms?.length,
    action: {
      type: 'link',
      link: (props) => {
        const { item = {} } = props;
        const { rooms = [] } = item;

        return {
          view: ROUTES.viewRoomsListModal.name,
          modal: true,
          params: {
            item,
            filter: { _id: { $in: rooms.map((room) => room?._id) } },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'lab.labels.employee'.getLabel(),
    type: 'link',
    value: ({ item }) => item?.employee_count?._count,
    action: {
      type: 'link',
      link: (props) => {
        const { item } = props;
        return {
          view: ROUTES.viewEmployeeListModal.name,
          modal: true,
          params: {
            item,
            filter: {
              labType: { _id: item?._id },
            },
          },
          modalProps: {
            autoHide: true,
            width: 454,
            height: 522,
          },
        };
      },
    },
  },
  {
    header: 'lab.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
    sortable: 'isActive',
  },
  {
    header: 'lab.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
    width: 250,
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.LAB_TYPE}
    editFormId={ROUTES.editLab.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyLab.permissions.modifyLab.value}
    header={{
      leftActions: [(props) => <ToggleFilter {...props} model={MODELS.LAB_TYPE} id="masterDataListForTable" />],
    }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewLab.name,
        params: { item },
      },
    })}
  />
);
