/* eslint-disable no-nested-ternary */
import React from 'react';
import { upperFirst } from 'lodash';

import { Text } from '../../../../../app-components';
import { Table } from '../../../../../app-components/table/Table';
import { getUser } from '../../../../../AppServices';
import WithPermission from '../../../../../Components/WithPermission';
import {
  MODELS,
  purchaseSupplyOrderStatus,
  Roles,
  ROUTES,
  RolesIndex,
  inventoryType,
} from '../../../../../Lib/constants';
import permissions from '../../../../../Lib/permissions';
import { purchaseOrderRequest } from '../../../../../Queries/purchaseRequestPharmacy';
import vars from '../../../../../theme/vars';
import {
  isSuperadmin, getRoleIndexes, isCreateSoPermissionGiven, reduceDaysLimitApprovalBasedOnRole,
} from '../../../../../Lib/helpers';

const tabListColumns = [
  { width: 14 },
  {
    header: 'purchase.header.creationDate'.getLabel(),
    type: 'date',
    field: 'POCreatedOn',
    sortable: 'POCreatedOn',
  },
  {
    header: 'purchase.header.poNumber'.getLabel(),
    type: 'text',
    field: 'uniqueNumber',
  },
  {
    header: 'purchase.header.department'.getLabel(),
    type: 'text',
    value: ({ item }) => upperFirst(item?.inventoryType),
  },
  {
    header: 'purchase.header.status'.getLabel(),
    type: 'text',
    // eslint-disable-next-line consistent-return
    render: ({ item }) => {
      const { currentApprovalQueue } = item;
      const assignedToRole = currentApprovalQueue?.assignedToRoleKey === 7 ? 'HOD' : currentApprovalQueue?.assignedToRole;
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.PENDING) {
        return (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.warning,
            }}
          >
            {`PS in Approval (By ${assignedToRole})`}
          </Text>
        );
      }
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus.REJECTED) {
        return <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>PS Rejected</Text>;
      }
      if (currentApprovalQueue?.status === purchaseSupplyOrderStatus?.APPROVED) {
        return (
          <Text style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}>
            PS Approved
          </Text>
        );
      }
    },
  },
  {
    header: 'purchase.header.urgent'.getLabel(),
    type: 'text',
    render: ({ item }) => {
      if (item?.urgent) {
        return (
          <Text style={{
            color: vars.colors.error,
            ...vars.headings.h9,
          }}
          >
            Yes
          </Text>
        );
      }
      return (
        <Text
          style={{
            ...vars.headings.h9,
          }}
        >
          No
        </Text>
      );
    },
  },
  {
    header: 'purchase.header.daysLimitForApproval'.getLabel(),
    type: 'text',
    render: ({ item }) => (
      <Text style={{ ...vars.headings.h9 }}>
        {reduceDaysLimitApprovalBasedOnRole(
          item?.daysLimitForApproval,
          item?.currentApprovalQueue?._createdOn,
          item?.status,
          item?.currentApprovalQueue,
        )}
      </Text>
    ),
  },
  {
    header: 'purchase.header.action'.getLabel(),
    type: 'openActions',
    openActions: [
      {
        render: () => (
          <Text
            style={{
              ...vars.headings.h11,
              color: vars.colors.secondary.color2,
            }}
          >
            View
          </Text>
        ),
        type: 'link',
        link: ({ item }) => ({
          view: ROUTES.viewPurchasesRequest.name,
          params: { item },
        }),
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createSupplyOrder.permissions.createSupplyOrder.value
            }
          >
            <Text
              style={{
                ...vars.headings.h11,
                color: vars.colors.secondary.color2,
              }}
            >
              Create SO
            </Text>
          </WithPermission>
        ),
        type: 'link',
        visible: ({ item }) => {
          const {
            assignedToRole,
            isSOCreated = false,
            currentApprovalQueue: { status },
            isContractQueueCreated = false,
            purchaseRequestEntity: { isContractual = true },
          } = item;
          if (
            assignedToRole === Roles?.director
            && !isSOCreated
            && status === purchaseSupplyOrderStatus?.APPROVED
          ) {
            if (item?.inventoryType === inventoryType.pharmacy) return true;
            if (isContractual) {
              return true;
            }
            if (!isContractual && isContractQueueCreated) return true;
            return false;
          }
          return false;
        },
        link: ({ item }) => {
          const {
            purchaseRequestEntity: {
              purchaseRequestPharmacy: {
                item: {
                  module,
                } = {},
              } = {},
            } = {},
          } = item;
          let queryId = '';
          if (module) {
            queryId = 'purchaseRequestAssetConsumablesList';
          }
          return {
            view:
            item?.inventoryType === inventoryType?.pharmacy
              ? ROUTES.viewSupplyOrder.name
              : item?.purchaseRequestEntity?.subType === 'repair'
                ? ROUTES.createPurchaseAndSupplyOrderForAssetRepair.name
                : ROUTES.viewAssetSupplyOrder.name,
            params: {
              queryId,
              item: { _id: item?.purchaseRequestEntity?._id, purchaseRequestEntity: item?.purchaseRequestEntity },
              fromSupplyOrder: true,
              purchaseSupplyOrderId: item?._id,
              manufacturer: item?.manufacturer,
              supplier: item?.supplier,
              isMandatory: true,
              mandatoryFields: {
                supplier: 1,
                manufacturer: 1,
              },
            },
          };
        },
      },
      {
        render: () => (
          <WithPermission
            access_key={
              permissions.inventory.views.createPurchaseOrder.permissions.createPurchaseOrder.value
            }
          >
            <Text
              style={{
                ...vars.headings.h11,
                color: vars.colors.secondary.color2,
              }}
            >
              Create PS 2
            </Text>
          </WithPermission>
        ),
        type: 'link',
        visible: ({ item }) => {
          const {
            assignedToRole,
            isSOCreated = false,
            isContractQueueCreated = false,
            purchaseRequestEntity: { isContractual = true },
            currentApprovalQueue: { status },
          } = item;
          if (
            assignedToRole === Roles?.director
            && !isSOCreated
            && status === purchaseSupplyOrderStatus?.APPROVED
            && !isContractual
            && !isContractQueueCreated
          ) return true;
          return false;
        },
        link: ({ item }) => ({
          view: ROUTES.viewAssetSupplyOrder.name,
          params: {
            item: { _id: item?.purchaseRequestEntity?._id, purchaseRequestEntity: item?.purchaseRequestEntity },
            purchaseSupplyOrderId: item?._id,
            manufacturer: item?.manufacturer,
            supplier: item?.supplier,
            isContractual: false,
            isContractQueueCreated: item?.isContractQueueCreated,
            fromPS2: true,
            isMandatory: true,
            mandatoryFields: {
              supplier: 1,
              manufacturer: 1,
            },
          },
        }),
      },
    ],
  },
];

const PurchaseOrderRequestList = (props) => {
  const { tableColumns = tabListColumns } = props;
  const userRole = getUser();

  return (
    <Table
      lg={{ columns: tableColumns }}
      uri={() => (isSuperadmin(userRole) || isCreateSoPermissionGiven(userRole)
        ? purchaseOrderRequest({ addOnFilter: { isPOCreated: true } })
        : purchaseOrderRequest({
          addOnFilter: {
            isPOCreated: true,
            $or: [
              {
                allAssignees: {
                  $in: getRoleIndexes(RolesIndex, userRole, 'PS'),
                },
              },
              {
                assignToDepartmentForSendForReview: {
                  _id: { $in: userRole?.employee?.department.map((dep) => dep?._id) },
                },
              },
            ],
          },
        }))}
      reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewPurchasesRequest.name,
          params: { item },
        },
      })}
      {...props}
    />
  );
};
export default PurchaseOrderRequestList;
