import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MODELS } from '../../../Lib/constants';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';
import { Form } from '../../../app-components/form/Form';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../Lib/helpers';

const AddDisease = (props) => (
  <Form
    {...props}
    type="standard"
    closeView={1}
    onSubmit={submit({
      model: MODELS.DISEASE,
    })}
    reloadEvent={`reload${MODELS.DISEASE}`}
    defaultValues={() => ({
      isActive: true,
    })}
    uniqueValidationMessage={'disease.message.diseaseAlreadyExists'.getLabel()}
    submitMessage={'disease.message.diseaseAddedSuccessfully'.getLabel()}
    mandatory={{
      name: 1,
    }}
    validations={{
      name: ({ data }) => validateInputLength(data?.name, 2, 26),
      description: ({ data }) => validateInputLength(data?.description, 0, 200),
    }}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 12,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'text',
                  field: 'name',
                  label: 'disease.labels.diseaseName'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                },
                {
                  type: 'textArea',
                  field: 'description',
                  label: 'disease.labels.description'.getLabel(),
                  minHeight: 100,
                },
                {
                  type: 'toggleSwitch',
                  field: 'isActive',
                  label: 'disease.labels.status'.getLabel(),
                  container: 'topLabel',
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default AddDisease;
