/* eslint-disable camelcase */
import { manazeV6Colors } from './manazeV6Colors';
import {
  Headline9,
  Headline3,
  Headline11,
  Headline4,
  Headline10,
} from './Headlines';
import { standardDropDownTheme } from './dropDownTheme';
import vars from './vars';

const {
  neutral_1,
  neutral_2,
  neutral_8,
  neutral_3,
  surface_white,
  primary_2,
  primary_5,
} = manazeV6Colors;

export const moreActionStyle = {
  style: { justifyContent: 'center', padding: 6 },
  textStyle: {
    marginLeft: 12,
  },
  dropdownStyle: {
    width: 200,
    height: 'auto',
    maxHeight: 300,
    backgroundColor: surface_white,
    borderRadius: 4,
  },
  ...standardDropDownTheme,
};

export const toolBarTheme = {
  containerStyle: {
    height: 50,
    paddingLeft: 34,
    paddingRight: 4,
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderBottomWidth: 0.5,
    borderBottomColor: neutral_8,
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  titleTextStyle: {
    paddingLeft: 13,
    color: neutral_1,
    ...Headline9,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 8,
    paddingRight: 8,
    ...Headline11,
    color: neutral_3,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
      marginLeft: 4,
      flexDirection: 'row',
      alignItems: 'center',
    },
    activeContainerStyle: {
      borderRadius: 4,
      backgroundColor: primary_5,
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
    textStyle: { ...Headline11, color: neutral_2 },
  },
  moreActionStyle: {
    ...moreActionStyle,
  },
};

export const screenHeaderToolBarTheme = {
  ...toolBarTheme,
};
export const screenHeaderToolBarMobileTheme = {
  ...screenHeaderToolBarTheme,
  containerStyle: {
    height: 44,
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: 'center',
    // backgroundColor: surface_white,
  },
};
export const screenFooterToolBarTheme = {
  ...screenHeaderToolBarTheme,
};

export const stackHeaderTheme = {
  ...toolBarTheme,
};

export const stackHeaderMobileTheme = {
  containerStyle: {
    padding: 12,
    paddingBottom: 6,
    backgroundColor: surface_white,
  },
  itemsStyle: {
    flexDirection: 'row',
  },
  titleContainerStyle: {
    padding: 4,
  },
  titleTextStyle: {
    ...Headline4,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    ...Headline10,
    color: neutral_2,
  },
  actionContainerStyle: { flexDirection: 'row' },
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      padding: 4,
    },
    iconStyle: { height: 20, width: 20, objectFit: 'contain' },
    textStyle: { ...Headline11, color: neutral_2 },
  },
};

export const stackFooterTheme = {
  ...toolBarTheme,
};

export const stackModalHeaderTheme = {
  ...toolBarTheme,
  containerStyle: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: surface_white,
  },
  separatorStyle: {
    height: 2,
    backgroundColor: neutral_8,
  },
  titleTextStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline3,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline11,
    color: neutral_3,
  },
  actionStyle: {
    containerStyle: {
      paddingLeft: 12,
      paddingRight: 12,
      flexDirection: 'row',
      alignItems: 'center',
    },
    activeContainerStyle: {
      borderRadius: 4,
      backgroundColor: primary_5,
      marginLeft: 4,
    },
    iconStyle: { height: 24, width: 24, objectFit: 'contain' },
  },
};

export const stackModalHeaderMobileTheme = {
  ...stackHeaderMobileTheme,
};

export const stackModalFooterTheme = {
  ...stackModalHeaderTheme,
};

export const nestedTableHeaderTheme = {
  ...toolBarTheme,
  titleTextStyle: {
    ...vars.headings.h8,
    color: vars.colors.grey.color2,
    textTransform: 'uppercase',
  },
  subTitleTextStyle: {
    paddingTop: 3,
    ...Headline11,
    color: neutral_3,
  },
  containerStyle: {},
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 8,
    },
    iconStyle: {
      height: 20,
      width: 20,
      resizeMode: 'contain',
    },
    textStyle: { ...Headline11, color: primary_2 },
  },
};

export const nestedTableHeaderMobileTheme = {
  ...nestedTableHeaderTheme,
};

export const nestedTableFooterTheme = {
  ...toolBarTheme,
  containerStyle: {},
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 8,
    },
    iconStyle: {
      height: 20,
      width: 20,
      resizeMode: 'contain',
    },
    textStyle: { ...Headline11, color: primary_2 },
  },
};

export const nestedTableFooterMobileTheme = {
  ...nestedTableFooterTheme,
};

export const nestedTableModalHeaderTheme = {
  ...toolBarTheme,
  containerStyle: {
    height: 40,
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: neutral_8,
  },

  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 6,
    },
    textStyle: { ...Headline9, color: primary_2 },
  },
};
export const appHeaderToolBarTheme = {
  ...toolBarTheme,
  containerStyle: {
    height: 40,
    paddingLeft: 4,
    paddingRight: 4,
    justifyContent: 'center',
  },
};
