import React from 'react';

import AddPatient from './PatientRegistration/AddPatient';
import AddAppointment from './CreateAppointment';
import ViewPatient from './ViewPatient';
import EditPatient from './EditPatient';
import PatientList from './PatientList';
import { ROUTES } from '../../../Lib/constants';
import PatientRegistrations from './PatientRegistration';
import {
  cancelButton, save, StandardButton, Text,
} from '../../../app-components';
import AddConsultation from './PatientRegistration/AddConsultation';
import ConsultationReceipt from '../../../Wired/ConsutationReceipt/Receipt';
import ExistingPatientModal from '../../../Wired/ExsitingPatientModal/ExistingPatient';
import vars from '../../../theme/vars';

export default {
  [ROUTES.addPatient.name]: {
    screen: AddPatient,
    expanded: true,
    headerActions: [cancelButton, save({ label: 'patient.labels.saveAndPrintCard'.getLabel() })],
  },
  [ROUTES.addAppointment.name]: {
    screen: AddAppointment,
    modal: true,
    modalProps: {
      width: 800,
      height: 700,
    },
  },
  [ROUTES.viewPatientOPD.name]: {
    screen: ViewPatient,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { patient } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {patient?.uhid}
        </Text>
      );
    },
    headerActions: [
      cancelButton,
      {
        render: () => (
          <StandardButton label={'patient.buttons.edit'.getLabel()} />
        ),
        type: 'link',
        link: ({ navigation }) => ({
          view: ROUTES.editPatient.name,
          push: true,
          expanded: true,
          params: { _id: navigation?.state?.params?._id },
        }),
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.addConsultation'.getLabel()} />
        ),
        type: 'link',
        link: (props) => {
          const { navigation } = props;

          return {
            view: ROUTES.addConsultation.name,
            modal: true,
            params: { patient: navigation?.state?.params?.patient },
            modalProps: {
              autoHide: true,
              width: 696,
              height: 516,
            },
          };
        },
      },
    ],
  },
  [ROUTES.editPatient.name]: {
    screen: EditPatient,
    expanded: true,
    headerActions: [cancelButton, save({ label: 'patient.buttons.update'.getLabel() })],
  },
  [ROUTES.PatientRegistrations.name]: { screen: PatientRegistrations },
  [ROUTES.existingPatientModal.name]: { screen: ExistingPatientModal },
  [ROUTES.PatientListOPD.name]: {
    screen: PatientList,
    expanded: true,
    headerActions: [
      {
        type: 'link',
        link: {
          view: ROUTES.existingPatientModal.name,
          push: true,
          modal: true,
          params: {
            routes: {
              listRoute: ROUTES.PatientListOPD.name,
              viewRoute: ROUTES.viewPatientOPD.name,
            },
          },
          modalProps: {
            autoHide: true,
            maxHeight: '100%',
            width: 373,
            height: 535,
          },
        },
        render: () => (
          <StandardButton label={'patient.buttons.scan'.getLabel()} />
        ),
      },
      {
        render: () => (
          <StandardButton label={'patient.buttons.register'.getLabel()} />
        ),
        type: 'link',
        link: {
          view: ROUTES.addPatient.name,
          push: true,
          expanded: true,
        },
      },
    ],
  },
  [ROUTES.addConsultation.name]: { screen: AddConsultation, modal: true, expanded: true },
  consultationReceipt: { screen: ConsultationReceipt },
};
