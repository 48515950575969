export default {
  labels: {
    category: 'Category',
    title: 'Title',
    isActive: 'Active / Inactive',
    subCategory: 'Sub Category',
    reorderLevel: 'Reorder Level',
    description: 'Description',
    supplier: 'Supplier',
    manufacturer: 'Manufacturer',
    status: 'Status',
    rateContractValidity: 'Rate Contract Validity',
    addRow: 'Add Row',
    batchNumber: 'Batch Number',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    stockEntryDate: 'Stock Entry Date',
    remainingSelfLife: 'Remaining Shelf Life',
    supplyReceivedOn: 'Supply Received On',
    purchasedPrice: 'Purchased Price',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    lastPurchaseDate: 'Last Purchase Date',
    lastPurchaseQuantity: 'Last Purchase Quantity',
    lastIndentDate: 'Last Indent Date',
    lastIndentQuantity: 'Last Indent Quantity',
    avgConsumption: 'Avg. Monthly Consumption',
    requiredQuantity: 'Required Quantity',
    reasonForPurchase: 'Reason',
    unitPrice: 'Unit Price',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    purchaseRequestNumber: 'Purchase Request Number',
    dateRaised: 'Date Raised',
    progressChart: 'Progress Chart',
    statusChart: 'Status Chart',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    purchasePrice: 'Purchase Price',
    indentNumber: 'Indent Number',
    department: 'Department',
    responseDate: 'Response Date',
    providedQuantity: 'Provided Quantity',
    reasonForRejection: 'Reason For Rejection',
    speciality: 'Speciality',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    updatedQuantity: 'Updated Quantity',
    stockAdditionDate: 'Stock Addition Date',
    stockRequested: 'Stock Requested',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'Dispensed By',
    noOfItems: 'No of Items',
    quantityDispensed: 'Quantity Dispensed',
    updatedStock: 'Updated Stock',
    totalPrice: 'Total Price',
    assetName: 'Asset Name',
    quantityAssignedDep: 'Quantity Assigned To Dep.',
    quantityInStocks: 'Quantity In Stocks',
    serialNo: 'Serial No.',
    value: 'Value of Asset',
    days: 'No. of Days',
    lastLoanOutDate: 'Last Loaned-Out Dated',
    lastLoanOutQuantity: 'Last Loaned-Out Quantity',
    manufacturerName: 'Manufacturer Name',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    service: 'Service',
    attachDocuments: 'Attachments',
    preventiveDate: 'Preventive Visit Date',
    serviceDate: 'Service Visit Date',
    repairCharge: 'Repair Charge',
    fromSpeciality: 'From Speciality',
    toSpeciality: 'To Speciality',
    repairPrice: 'Repair Price',
    approximateCost: 'Approximate Cost',
    reasonForRepair: 'Reason For Repair',
    loanOutDays: 'Loan Out Days',
    loanOutQuantity: 'Loan Out Quantity',
    loanOutDate: 'Loan Out Date',
    inService: 'In-Service',
    contractStatus: 'Contract Status',
    loanOutDept: ' Loan Out/Issue Department',
    repairReturnDate: 'Repair Return Date',
    selectItemName: 'Asset Name',
    cost: 'Cost',
    contractEndDate: 'Contract End Date',
    lastRepairDate: 'Last Repair Date',
    supervisor: 'Supervisor',
    inCharge: 'Incharge',
    loanedOutDept: 'Loaned Out Dept.',
    recordMonth: 'Record Month',
    recordYear: 'Record Year',
    remarks: 'Remarks',
    date: 'Date',
    itemCodePrefix: 'Item Prefix',
  },
  placeholders: {
    itemName: 'Item Name',
    category: 'Category',
    subCategory: 'Sub Category',
    reorderLevel: 'Reorder Level',
    sellingPrice: 'Selling Price',
    description: 'Description',
    supplier: 'Supplier',
    manufacturer: 'Manufacturer',
    rateContractValidity: 'Rate Contract Validity',
    batchNumber: 'Batch Number',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    stockEntryDate: 'Stock Entry Date',
    remainingSelfLife: 'Remaining Shelf Life',
    supplyReceivedOn: 'Supply Received On',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    selectItemName: 'Asset Name',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    serialNo: 'Serial No.',
    days: 'No. of Days',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    requiredQuantity: 'Required Quantity',
    preventiveDate: 'Preventive Visit Date',
    serviceDate: 'Service Visit Date',
    repairCharge: 'Repair Charge',
    fromSpeciality: 'From Speciality',
    toSpeciality: 'To Speciality',
    serviceStatus: 'Service Status',
    repairPrice: 'Repair Price',
    repairReturnDate: 'Repair Return Date',
    selectLoanedOutDept: 'Select loaned out dept.',
    itemCodePrefix: 'Item Prefix',
  },
  title: {
    centralInventory: 'Central Inventory',
    departmentInventory: 'Department Inventory',
    itemDetails: 'Item Details',
    assetDetails: 'Asset Details',
    transferDetails: 'Transfer Details',
    loanOutDetails: 'Loan Out Details',
    manufacturerSupplierDetails: 'Manufacturer & Supplier Details',
    batchInformation: 'Batch Information',
    indentDetails: 'Indent Details',
    purchaseRequestDetails: 'Purchase Request Details',
    transferStock: 'Transfer Stock',
    transferStockDescription: 'Transfer stocks to departments',
    requests: 'Requests (Asset)',
    assetCentralRequests: 'Central Requests (Assets)',
    assetDeptRequests: 'Dept. Requests (Assets)',
    assetStockDetails: 'Asset Stock Details',
    batchDescription: 'Details of the batch to edit. Edited details will be sent for approval to the Medical Superintendent.',
    departmentRecords: 'Dept. Records (Asset)',
    centralRecords: 'Central Records (Asset)',
    categoryManagement: 'Category Management',
    addSubCategoryDescription: 'Create Sub Category of the Category.',
    editSubCategoryDescription: 'Edit Sub Category of the Category.',
    supplierManagement: 'Suppliers and Manufacturers Management',
    addManufacturerDescription: 'Create Manufacturer of the Supplier.',
    editManufacturerDescription: 'Edit Manufacturer of the Supplier.',
    service: 'Services',
    editItem: 'Edit Item Detail Of the asset',
    generalDetails: 'General Details',
    financeDetails: 'Finance Details',
    technicalDetails: 'Technical Specification-Neonatal Open Care System',
    technicalSpecification: 'Technical Specification',
    listDetails: 'List Of Details',
  },
  buttons: {
    save: 'Save',
    addItem: 'Add Item',
    add: ' Add ',
    addAsset: 'Add Asset',
    addStock: 'Add Stock',
    exportToExcel: 'Export To Excel',
    raisePurchaseRequest: 'Raise Purchase Request',
    transferStock: 'Transfer Stock',
    raiseIndent: 'Raise Indent',
    transfer: 'Transfer',
    sendForApproval: 'Send For Approval',
    cancel: 'Cancel',
    edit: 'Edit',
    provideStock: 'Provide Stock',
    submit: 'Submit',
    reject: 'Reject',
    approve: 'Approve',
    addNewRow: 'Add New Row',
    loanOut: 'Loan Out',
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    createSubCategory: 'Create Sub-Category',
    create: 'Create',
    update: 'Update',
    addSupplier: 'Add Supplier',
    editSupplier: 'Edit Supplier',
    addManufacturer: 'Add Manufacturer',
    editManufacturer: 'Edit Manufacturer',
    repairDone: 'Asset Repaired',
    raiseRepair: 'Raise Repair Request',
    confirm: 'Confirm',
  },
  messages: {
    dataSaveSuccessfully: 'Data has been saved successfully',
    itemAddedSuccessfully: 'Item has been added successfully.',
    itemUpdatedSuccessfully: 'Item has been updated successfully.',
    itemAlreadyExists: 'Item Already Exists',
    batchAddedSuccessfully: 'Batch has been added successfully.',
    indentRaisedSuccessfully: 'Indent has been raised successfully.',
    indentUpdateSuccessfully: 'Indent Request has been updated successfully.',
    transferStockSuccessfully: 'Stock has been transfer successfully.',
    purchaseRequestSuccessfully: 'Purchase request has been raised successfully',
    requestRaisedSuccessfully: 'Request has been raised successfully.',
    sendForApproval: 'Approval has been send successfully',
    stockProvidedSuccessfully: 'Stock has been provided to the department successfully.',
    stockAlreadyProvided: 'Stock has been already provided to the department',
    indentStatusApproval: 'Status for this request not approved by MS ',
    transferRequest: 'Transfer Request has been raised successfully.',
    transferRequestReject: 'Transfer Request has been rejected successfully.',
    transferRequestApprove: 'Transfer Request has been approved successfully',
    transferRequestAlreadyApproved: 'Transfer Request has been already approved',
    transferRequestAlreadyRejected: 'Transfer Request has been already rejected',
    loginUserError: 'Login user is not an employee.',
    batchUpdateRequestReject: 'Batch Update Request has been rejected successfully.',
    batchUpdateRequestApprove: 'Batch Update Request has been approved successfully',
    categoryAddedSuccessfully: 'Category has been Added successfully.',
    categoryUpdatedSuccessfully: 'Category has been Updated successfully.',
    categoryAlreadyExists: 'Category Already Exists',
    subCategoryAddedSuccessfully: 'Sub-Category has been Added successfully.',
    subCategoryUpdatedSuccessfully:
      'Sub-Category has been Updated successfully.',
    subCategoryAlreadyExists: 'Sub-Category Already Exists',
    supplierAddedSuccessfully: 'Supplier has been Added successfully.',
    supplierUpdatedSuccessfully: 'Supplier has been Updated successfully.',
    supplierAlreadyExists: 'Supplier Already Exists',
    manufacturerAddedSuccessfully: 'Manufacturer has been Added successfully.',
    manufacturerUpdatedSuccessfully:
      'Manufacturer has been Updated successfully.',
    manufacturerAlreadyExists: 'Manufacturer Already Exists',
    monthlyRemarksUpdate: 'Monthly remarks has been update successfully.',
    monthlyRemarksAdded: 'Monthly remarks has been added successfully.',
  },
  headers: {
    itemId: 'Item Id',
    itemName: 'Item Name',
    assetName: 'Asset Name',
    category: 'Category',
    subCategory: 'Sub Category',
    manufacturer: 'Manufacturer',
    description: 'Description',
    batchNo: 'Batch No.',
    supplyReceivedOn: 'Supply Received On',
    stockEntryDate: 'Stock Entry Date',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    remainingSelfLife: 'Remaining Shelf Life',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    quantityInStocks: 'Quantity In Stocks',
    purchasedPrice: 'Purchase Price',
    status: 'Status',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    dateRaised: 'Date Raised',
    purchaseRequestNumber: 'Purchase Request Number',
    department: 'Department',
    noOfItems: 'No of Items',
    responseDate: 'Response Date',
    indentNumber: 'Indent Number',
    active: 'Active/Inactive',
    speciality: 'Speciality',
    avgConsumption: 'Avg. Monthly Consumption',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    date: 'Date',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    batchNumber: 'Batch Number',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    stockAdditionDate: 'Stock Addition Date',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'DispensedBy',
    value: 'Value Of Asset',
    lastLoanOutDate: 'Last Loan Out/Issue Date',
    lastLoanOutDept: 'Last Loan Out/Issue Dept.',
    lastRepairDate: 'Last Repair Date',
    lastLoanOutQuantity: 'Last Loaned-Out/Issued Quantity',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    manufacturerName: 'Manufacturer Name',
    serialNo: 'Serial No.',
    preventiveDate: 'Preventive Visit Date',
    serviceDate: 'Service Visit Date',
    loanOutDays: 'Loaned Out Days',
    repairPrice: 'Repair Price',
    visitStatus: 'Visit Status',
    stockIssued: 'Stock Issued Date',
    item: 'Item',
    repairCharge: 'Repair Charge',
    dateOfEvent: 'DATE OF EVENT',
    returnDate: 'RETURN DATE',
    toSpeciality: 'To Speciality',
    noOfDays: 'No. of Days',
    month: 'Month',
    year: 'Year',
    remarks: 'Remarks',
    action: 'Action',
    reasonForRepair: 'REASON For Repair',
    loanOutDate: 'Loan Out Date',
  },
};
