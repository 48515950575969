import React from 'react';

import { viewOperativeNotesUri } from '../../../../../../../Queries/operativeNotes';
import { StandardButton, Text } from '../../../../../../../app-components';
import { Table } from '../../../../../../../app-components/table/Table';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import vars from '../../../../../../../theme/vars';

const tabListColumns = () => ([
  {
    header: 'Date',
    type: 'date',
    field: 'currentLogDate',
    format: 'DD MMM YYYY',
  },
  {
    header: 'Procedure',
    type: 'text',
    field: 'procedure',
  },
  {
    header: 'Eye',
    type: 'text',
    field: 'eye',
  },
  {
    header: 'Surgeon Name',
    type: 'text',
    value: (item) => item?.item?.surgeons?.map((e) => e.name).join(', '),
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>View Pre-operative Checklist</Text>
      ),
    },
    ],
  },
]);

const OperativeNotes = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;

  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="Notes"
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Log Operative Notes"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.logOperativeNotes.name,
              params: { ...params },
              push: true,
            }),
          },
        ],
      })}
      reloadEvent={`reload${MODELS.IPD_OPERATIVE_NOTES}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={() => viewOperativeNotesUri({ admissionId: { _id } })}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewOperativeNotes.name,
          params: { item },
        },
      })}
    />
  );
};
export default OperativeNotes;
