import React from 'react';
import {
  FormField, LargeButton, View,
} from '../../../../../app-components';

const SearchExistingPatientOnUHIDForm = (props) => {
  const {
    form_context: { handleSubmit },
  } = props;

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <View>
          <FormField
            label={'patient.labels.enterUHID'.getLabel()}
            field="UHID"
            placeholder={'patient.placeholders.enterUHID'.getLabel()}
            type="text"
            variant="filled"
            mandatory
            upperCase
            autoFocus
            onSubmitEditing={handleSubmit}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <View style={{ marginLeft: 22 }}>
          <LargeButton
            label="Proceed"
            width={160}
            borderRadius={4}
            onClick={handleSubmit}
          />
        </View>
      </View>
    </View>
  );
};

export default SearchExistingPatientOnUHIDForm;
