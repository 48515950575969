import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../../app-components';
import { FormField } from '../../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExaminationNose = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
        scroll: 'auto',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      // {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            // overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  {...autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'historyTakenBy',
                      label: 'History Taken By',
                      placeholder: 'History Taken By',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    // {
                    //   addOnFilter: () => ({
                    //     role: {
                    //       code: 'Supervisor',
                    //     },
                    //   }),
                    // }
                  )}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}
            </View>

            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    display: 'block',
                    fontWeight: 700,
                    marginTop: 15,
                    marginLeft: 20,
                    marginBottom: 10,
                  }}
                >
                  c.&nbsp;&nbsp;Complaints
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    marginLeft: 30,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="hearingLoss"
                    label="Hearing Loss"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginLeft: 30,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="earDischarge"
                    label="Ear Discharge"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginLeft: 30,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="earPin"
                    label="Ear Pin"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  marginLeft: 20,
                  marginTop: 45,
                }}
              >
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="tinnitus"
                    label="Tinnitus"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="cuddiness"
                    label="Cuddiness"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="earDeformity"
                    label="Ear Deformity"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                </View>
              </View>
            </View>

            {/* HISTORY OF */}
            {/* Previous Surgery */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  History Of
                </Text>
                <Text style={{ marginLeft: 20, marginTop: 15 }}>i.Hearing Loss</Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      width: 150,
                      marginTop: 15,
                    }}
                  >
                    a.&nbsp;&nbsp;Onset
                  </Text>
                  <FormField
                    type="text"
                    field="hearingLossOnset"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    b.&nbsp;&nbsp;Duration
                  </Text>
                  <FormField
                    type="text"
                    field="HearingLossDuration"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    c.&nbsp;&nbsp;Associated Complaint
                  </Text>
                  <FormField
                    type="number"
                    field="HearingLossAssociateComplaint"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <Text style={{ marginLeft: 20, marginTop: 15 }}>ii.&nbsp;&nbsp;Ear Discharge</Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    a.&nbsp;&nbsp;Color
                  </Text>
                  <FormField
                    type="text"
                    field="earDischargeColor"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    b.&nbsp;&nbsp;Character
                  </Text>
                  <FormField
                    type="text"
                    field="earDischargecharacter"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    c.&nbsp;&nbsp;Amount
                  </Text>
                  <FormField
                    type="number"
                    field="earDischargeAmount"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    d.&nbsp;&nbsp;Preceding Event
                  </Text>
                  <FormField
                    type="text"
                    field="earDischargePrecedingEvent"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <Text style={{ marginLeft: 40, marginTop: 15 }}>iii Ear Pain</Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 60,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    a.&nbsp;&nbsp;Onset
                  </Text>
                  <FormField
                    type="text"
                    field="earPainOnset"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 80,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    b.&nbsp;&nbsp;
                  </Text>
                  <FormField
                    type="number"
                    field="earPain"
                    container="rightLabel"
                    variant="filled"

                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Headache
                  </Text>
                  <FormField
                    type="number"
                    field="headAche"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Vomiting
                  </Text>
                  <FormField
                    type="text"
                    field="vomiting"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vii.&nbsp;&nbsp;Post Aural Swelling
                  </Text>
                  <FormField
                    type="number"
                    field="postAuralSwelling"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    viii.&nbsp;&nbsp;Facial Palry
                  </Text>
                  <FormField
                    type="number"
                    field="facialPalry"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ix.&nbsp;&nbsp;neck Swelling
                  </Text>
                  <FormField
                    type="text"
                    field="neckSwelling"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Altered Sensorium
                  </Text>
                  <FormField
                    type="text"
                    field="alteredSensorium"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    xi.&nbsp;&nbsp;Convulsion
                  </Text>
                  <FormField
                    type="text"
                    field="convulsions"
                    container="rightLabel"
                    variant="filled"
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      // width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 100,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Family */}
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                c.&nbsp;&nbsp;Family
              </Text>
              <FormField
                type="radioGroup"
                field="familySignificance"
                variant="filled"
                container="topLabel"
                options={['Significant', 'Non-Significant']}
                style={{ marginLeft: 5 }}
                labelContainerStyle={{ height: 0 }}
                labelStyle={{ ...vars.headings.h14 }}
              />
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  d.&nbsp;&nbsp;Personal
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    i.&nbsp;&nbsp;Appetite
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="personalData.appetite"
                    container="rightLabel"
                    editable
                    variant="filled"
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ii.&nbsp;&nbsp;Diet
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="personalData.diet"
                    variant="filled"
                    container="rightLabel"
                    options={['Non-Veg', 'Veg']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iii.&nbsp;&nbsp;Sleep
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.sleep"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Bowel & Bladder
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bowelAndBladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Addiction
                  </Text>
                  <View
                    style={{
                      width: 100,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="personalData.addictionSmoking"
                      label="Smoking"
                      container="rightLabel"
                      variant="filled"
                      style={{
                        marginTop: 10,
                      }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="personalData.addictionAlcohol"
                        label="Alcohol"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: 131,
                        }}
                      >
                        <FormField
                          type="checkBox"
                          field="personalData.addictionOther"
                          label="Other"
                          container="rightLabel"
                          variant="filled"
                          labelStyle={{ ...vars.headings.h14 }}
                        />
                      </View>
                      <FormField
                        type="text"
                        field="personalData.addictionOtherText"
                        container="rightLabel"
                        variant="filled"
                        editable
                        allowWhiteSpaces
                        disabled={(disableProps) => {
                          const { data: { personalData = {} } = {} } = disableProps;
                          return !personalData.addictionOther;
                        }}
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          // marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Other
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.personalOther"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  e.&nbsp;&nbsp;Immnization
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <FormField
                    type="checkBox"
                    field="immunization.bcg"
                    label="BCG"
                    container="rightLabel"
                    variant="filled"
                    labelStyle={{ ...vars.headings.h14 }}
                  />
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.hepb"
                      label="HEBP"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.covid"
                      label="Covid"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="immunization.polio"
                      label="Polio"
                      container="rightLabel"
                      variant="filled"
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: 131,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="immunization.immunizationOther"
                        label="Other"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="text"
                      field="immunization.immunizationOtherText"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { immunization = {} } = {} } = disableProps;
                        return !immunization.immunizationOther;
                      }}
                      container="rightLabel"
                      allowWhiteSpaces
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        // marginTop: 7,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Drug Allergies & Obsteric & Menstruation  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Drug Allergies */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Drug Allergies
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iv.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFour"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    v.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFive"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Obsteric & Menstruation */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  g.&nbsp;&nbsp;Obsteric & Menstruation
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;Gravida
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.gravida"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;Para
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.para"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 150 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;Lining
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Menstrual Cycle
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="obstetricAndMenstruaion.lining"
                      variant="filled"
                      container="rightLabel"
                      options={['Regular', 'Irregular']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        iV.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menarche"
                        label="Menarche"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menarcheAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menarche;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 20 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        v.
                      </Text>
                    </View>
                    <View style={{ width: 130, marginTop: 15 }}>
                      <FormField
                        type="checkBox"
                        field="obstetricAndMenstruaion.menopause"
                        label="Menopause"
                        container="rightLabel"
                        variant="filled"
                        style={{
                          marginTop: 10,
                        }}
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        If Yes, Then
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        Age
                      </Text>
                    </View>
                    <FormField
                      type="number"
                      field="obstetricAndMenstruaion.menopauseAge"
                      container="rightLabel"
                      variant="filled"
                      disabled={(disableProps) => {
                        const { data: { obstetricAndMenstruaion = {} } = {} } = disableProps;
                        return !obstetricAndMenstruaion.menopause;
                      }}
                      editable
                      inputStyle={{
                        paddingTop: 5,
                        borderWidth: 0,
                        outline: 'none',
                      }}
                      style={{
                        // width: 600,
                        minHeight: 30,
                        paddingLeft: 2,
                        fontSize: 14,
                        marginTop: 15,
                        // marginLeft: 20,
                        borderWidth: 1,
                        borderColor: vars.colors.grey.color5,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Examination  */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Examination
              </Text>
              {/* Local Examination */}
              <View
                style={{
                  width: '100%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    S.No&nbsp;&nbsp;&nbsp;&nbsp;Category
                  </Text>
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 600,
                      marginLeft: 100,
                    }}
                  >
                    &nbsp;&nbsp;LeftEar
                  </Text>
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 600,
                      marginLeft: 80,
                    }}
                  >
                    &nbsp;&nbsp;RightEar
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    1.&nbsp;&nbsp;Preauricular Region
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.preauricularLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.preauricularRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    2.&nbsp;&nbsp;Postauricular Region
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.postauricularLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.postauricularRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    3.&nbsp;&nbsp;Pinna
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.pinnaLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.pinnaRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    4.&nbsp;&nbsp;ExternalEar Canal
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.externalEarCanalLeft"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.externalEarCanalRight"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    5.&nbsp;&nbsp;Tympanic embrane
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.tympanicEmbraneLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.tympanicEmbraneRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                    }}
                  >
                    6.&nbsp;&nbsp;Tuning Fork Test
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      width: 290,
                      marginTop: 15,
                      marginLeft: 60,
                    }}
                  >
                    i.&nbsp;&nbsp;Rinne
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.rinne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 290,
                      marginLeft: 60,
                    }}
                  >
                    ii.&nbsp;&nbsp;Weber
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.weber"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginLeft: 60,
                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    iii.&nbsp;&nbsp;ABC
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.abc"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    7.&nbsp;&nbsp;Nystagmus
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.nystagmusLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.nystagmusRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    8.&nbsp;&nbsp;Facial Nerve Examination
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.facialNerveExaminationLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.facialNerveExaminationRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                      marginLeft: 60,

                    }}
                  >
                    9.&nbsp;&nbsp;i.&nbsp;&nbsp; Roumbers Test
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.roumbersTest"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginLeft: 60,
                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    ii.&nbsp;&nbsp;Sharpened Roumbers Test
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.SharpenedRoumbersTest"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginLeft: 60,
                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    iii.&nbsp;&nbsp;Unterberger Test
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.unterbergerTest"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 1300,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,

                      marginTop: 15,
                      width: 290,
                    }}
                  >
                    10.&nbsp;&nbsp;Fistula test
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.fistulaTestLeftEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      borderWidth: 1,
                      marginLeft: 100,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="ear.fistulaTestRightEar"
                    container="rightLabel"
                    variant="filled"
                    editable
                    style={{
                      width: 600,
                      fontSize: 14,
                      marginLeft: 80,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
                {/* Provisional Diagnosis */}
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: 10,
                    marginLeft: 20,
                    paddingTop: 0,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,

                      display: 'block',
                    }}
                  >
                    Provisional Diagnosis
                  </Text>
                  <FormField
                    type="textArea"
                    field="provisionalDiagnosis"
                    variant="filled"
                    style={{
                      height: 100,
                      paddingLeft: 2,
                      paddingTop: 2,
                      marginTop: 16,
                      marginLeft: 15,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    inputStyle={{
                      outline: 'none',
                      border: 0,
                      padding: '13 12 9',
                      backgroundColor: 'transparent',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'left',
                      minHeight: 95,
                    }}
                  />
                </View>
                {/* Final Diagnosis */}
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: 10,
                    marginLeft: 20,
                    paddingTop: 0,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h12,

                      display: 'block',
                    }}
                  >
                    Final Diagnosis
                  </Text>
                  <FormField
                    type="textArea"
                    field="finalDiagnosis"
                    variant="filled"
                    style={{
                      height: 100,
                      paddingLeft: 2,
                      paddingTop: 2,
                      marginTop: 16,
                      marginLeft: 15,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                    inputStyle={{
                      outline: 'none',
                      border: 0,
                      padding: '13 12 9',
                      backgroundColor: 'transparent',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'left',
                      minHeight: 95,
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExaminationNose;
