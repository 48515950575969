/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import {
  FormField, Toast, View,
} from '../../../app-components';
import { LoadingButton } from '../../../app-components/buttons/Buttons';
import { Form } from '../../../app-components/form/Form';
import { invoke } from '../../../AppServices';
import { autoSuggestInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';
import LiveConsultationPatientList from './LiveConsultationsPatientList';

const LiveConsultationForm = (props) => {
  const [specialityData, setSpeciality] = useState({});
  const [upcoming, setUpcoming] = useState([]);
  const [LastPatient, setLastPatient] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPatientData = useCallback(async ({ data = {} }) => {
    const { speciality } = data;
    const result = await invoke({
      id: 'fetchPatientDataRecords',
      paramValue: { data: { speciality } },
    }).catch((err) => {
      Toast.show({
        message: `${err}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        description: 'No Session Found.',
      });
    });
    if (result) {
      setLastPatient(result?.result);
      setLoading(false);
      setSpeciality(speciality);
      setUpcoming(result?.upcoming);
    }
  }, []);

  if (specialityData?._id) {
    return (
      <LiveConsultationPatientList
        {...props}
        LastPatient={LastPatient}
        speciality={specialityData}
        fetchPatientData={fetchPatientData}
        upcoming={upcoming}
        loading={loading}
      />
    );
  }
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Form
        bodyContainerStyle={{
          flex: 1,
          justifyContent: 'space-between',
          marginTop: 20,
          marginBottom: 30,
        }}
        {...props}
        onSubmit={fetchPatientData}
        mandatory={{
          speciality: 1,
        }}
        {...props}
      >
        {
  ({ form_state: formState, form_context: formContext }) => {
    const { handleSubmit } = formContext;
    return (
      <View style={{ alignItems: 'center', marginTop: 50, justifyContent: 'space-evenly' }}>
        <FormField
          {
          ...autoSuggestInput({
            field: 'speciality',
            type: 'autoSuggest',
            variant: 'filled',
            keyFiled: 'name',
            suggestionField: 'name',
            valueField: 'name',
            model: MODELS.WARDS,
            query: 'masterDataList',
            placeholder: 'Search Speciality',
            searching: true,
            searchField: 'name',
            mandatory: true,
          })
          }
        />
        {!formState?.submitting && (
          <View
            style={{
              flex: 0,
              width: 152,
              height: 152,
              marginTop: 40,
            }}
          >
            <LoadingButton
              label="View Session"
              onPress={handleSubmit}
            />
          </View>
        )}
      </View>
    );
  }
          }
      </Form>
    </View>
  );
};

export default LiveConsultationForm;
