import React from 'react';

import { DateFilter, TabNavigator } from '../../../../../app-components';
import AssetIndentRequestList from './DepartmentRecords/IndentRequests/IndentRequestList';
import AssetPurchaseRequestList from './CentralPurchaseRecords/CentralRecordsList';
import LoanOutList from './LoanOutRecords/LoanOutList';
import OtherRecordsTabs from './OtherRequests';
import tabTheme from '../../Themes/tabTheme';
import vars from '../../../../../theme/vars';

const AssetCentralRecordsTabs = TabNavigator({
  theme: { ...tabTheme },
  tabs: {
    PurchaseRequest: {
      label: 'Purchase Requests',
      screen: AssetPurchaseRequestList,
      actions: [
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginTop: 7,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
        />,
      ],
    },
    IndentRequests: {
      label: 'Indent Requests',
      screen: AssetIndentRequestList,
      actions: [
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginTop: 7,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
        />,
      ],
    },
    Other: {
      label: 'Others',
      screen: OtherRecordsTabs,
      actions: [
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginTop: 7,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
        />,
      ],
    },
    LoanOut: {
      label: 'Loan Out',
      screen: LoanOutList,
      actions: [
        <DateFilter
          field="_createdOn"
          key="_createdOn"
          containerStyle={
            {
              flexDirection: 'row',
              width: 303,
              height: 36,
              borderWidth: 1,
              padding: 9,
              marginTop: 7,
              marginRight: 40,
              borderRadius: 4,
              borderStyle: 'solid',
              backgroundColor: vars.colors.white,
              borderColor: vars.colors.grey.color6,
            }
          }
        />,
      ],
    },
  },
});
export default AssetCentralRecordsTabs;
