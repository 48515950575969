import { upperFirst } from 'lodash';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
// eslint-disable-next-line import/no-cycle
import { fetch } from '../../AppServices';
import {
  approvedIcon, pendingIcon, redCloseIcon,
} from '../../images';
import {
  MODELS, purchaseSupplyOrderStatus,
} from '../../Lib/constants';
import vars from '../../theme/vars';

import './progressBar.scss';

const StepItem = ({ position, status = '' }) => (
  <div className="step-element" style={{ left: position }}>
    <div className={`step-circle ${status}`}>
      <div className="inner-circle" />
    </div>
  </div>

);
const TitleItem = ({ position, title }) => (
  <div className="title-element" style={{ left: position }}>
    <div className="title-item">{title}</div>
  </div>
);

const ContentItem = ({ position, content = [] }) => (
  content?.length ? (
    <div className="content-element" style={{ left: position }}>
      <div className="content-item">
        <div className="title">Status :</div>
        <div className="content-items-container">
          {content.map((item) => (
            <div
              key={item.id}
              className="item"
              style={{
                paddingTop: 6, display: 'flex', flex: 1, flexDirection: 'row', paddingLeft: 6, paddingBottom: 4,
              }}
            >
              <img src={item.icon} alt="" height="20" />
              <div style={{ paddingLeft: 4, ...vars.headings.h13, color: vars.colors.grey.color2 }}>
                {item.text}
                <div style={{ ...vars.headings.h16, color: vars.colors.grey.color4 }}>
                  {item?.statusChangedOnDate ? moment(item.statusChangedOnDate).format('DD/MM/YY hh:mm A') : ''}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
);

const Progress = ({ value, max }) => {
  const width = useMemo(() => `${(value / max) * 100}%`, [max, value]);
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-value" style={{ width }} />
    </div>
  );
};

const Steps = ({ stepsList, current }) => {
  const getPosition = useCallback((index) => (`${(index / (stepsList.length - 1)) * 100}%`), [stepsList]);

  const getStatus = useCallback((index) => {
    if (index === current) return 'current';
    if (index < current) return 'completed';
    return '';
  }, [current]);

  return (
    <div className="steps-container">
      <div className="titles-container">
        {stepsList.map((item, index) => (
          <TitleItem position={getPosition(index)} key={item?.id} title={item?.title} />
        ))}
      </div>
      <div className="progress-container">
        <Progress value={current} max={(stepsList.length - 1)} />
        {stepsList.map((item, index) => (
          <StepItem
            key={item?.id}
            status={getStatus(index)}
            position={getPosition(index)}
          />
        ))}
      </div>
      <div className="content-container">
        {stepsList.map((item, index) => (
          <ContentItem position={getPosition(index)} key={item?.id} content={item?.content} />
        ))}
      </div>
    </div>
  );
};

export const StatusTheme = (props) => {
  const { value = '', id = '', navigation } = props;
  const { state: { params: { item } } } = navigation;

  const [requestStatus, setRequestStatus] = useState('');
  const [ps2Queues, setPS2Queues] = useState([]);
  const [requestCreatedTime, setRequestCreatedTime] = useState();

  const fetchQueueDetails = useCallback(async () => {
    const fetchedQueueDetails = await fetch({
      uri: {
        props: {
          query: {
            id,
            addOnFilter: {
              _id: item && item._id,
            },
          },
          model: MODELS.CONTINGENT_VOUCHER,
          limit: 1,
        },
      },
    });

    setRequestCreatedTime(fetchedQueueDetails?.data[0]?._createdOn);
    setPS2Queues(fetchedQueueDetails?.data[0]?.assignedToRole);
    setRequestStatus(fetchedQueueDetails?.data[0]?.status);
    return fetchedQueueDetails;
  }, [id, value]);

  useEffect(() => {
    fetchQueueDetails();
  }, [fetchQueueDetails]);

  const contingentVoucherData = useMemo(() => ps2Queues
    ?.map((item) => item), [ps2Queues]);

  const contingentVoucherContent = useMemo(() => contingentVoucherData?.map((item, index) => ({
    id: index + 1,
    text: item.role,
    statusChangedOnDate: item._createdOn,
    icon: item?.status === 'PENDING' ? pendingIcon
      : item?.status === 'Rejected' ? redCloseIcon : approvedIcon,
  })), [contingentVoucherData]);

  let currentValue;
  if (
    requestStatus === purchaseSupplyOrderStatus.SO_IN_APPROVAL
    || requestStatus === purchaseSupplyOrderStatus.SO_APPROVED
    || requestStatus === purchaseSupplyOrderStatus.SO_REJECTED
  ) {
    currentValue = 0;
  } else if (
    requestStatus === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED
  ) {
    currentValue = 1;
  } else if (
    requestStatus === 'pending'
    || requestStatus === 'approved'
    || requestStatus === 'Rejected'
  ) {
    currentValue = 2;
  } else if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
    currentValue = 3;
  }

  let paymentStepsContent = [];

  if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_PENDING) {
    paymentStepsContent = [{
      _id: 1,
      text: purchaseSupplyOrderStatus.PAYMENT_PENDING,
      icon: pendingIcon,
    }];
  } else if (requestStatus === purchaseSupplyOrderStatus.PAYMENT_DONE) {
    paymentStepsContent = [{
      _id: 1,
      text: purchaseSupplyOrderStatus.PAYMENT_DONE,
      icon: approvedIcon,
    }];
  }

  return (
    <Steps
      stepsList={[
        {
          id: 1,
          title: 'SUPPLY ORDER',
          content: [{
            id: 1,
            text: upperFirst(purchaseSupplyOrderStatus.RAISED),
            statusChangedOnDate: requestCreatedTime,
            icon: approvedIcon,
          }],
        },
        {
          id: 1,
          title: 'STOCK ENTRY',
          content: [{
            id: 1,
            text: upperFirst('Stock Provided'),
            statusChangedOnDate: requestCreatedTime,
            icon: approvedIcon,
          }],
        },
        { id: 3, title: 'CONTINGENT VOUCHER', content: contingentVoucherContent },
        { id: 4, title: 'PAYMENT', content: paymentStepsContent },
      ]}
      current={currentValue}
    />
  );
};
