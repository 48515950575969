import React from 'react';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import ViewAttendanceTabs from './Attendance/index';
import ViewScheduleTabs from './Schedule';
import ViewProfileTabs from './Profile';
import ViewSalaryTabs from './Salary';
import tabTheme from './tabTheme';
import { getUser } from '../../../../AppServices';

const MyProfileTabNavigation = (props) => {
  const user = getUser();

  return (
    <TabNavigator
      {...props}
      theme={tabTheme}
      tabs={{
        Profile: {
          label: 'Profile',
          screen: ViewProfileTabs,
        },
        Attendance: {
          label: 'Attendance',
          screen: ViewAttendanceTabs,
          screenProps: {
            user: user?.employee,
            canAddSalaryStructure: false,
          },
        },
        Schedule: {
          label: 'Schedule',
          screen: ViewScheduleTabs,
        },
        Salary: {
          label: 'Salary',
          screen: ViewSalaryTabs,
          screenProps: {
            employee: user?.employee,
            canAddSalaryStructure: false,
          },
        },
      }}
    />
  );
};

export default MyProfileTabNavigation;
