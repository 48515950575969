/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import { isFunction } from 'lodash';

import { gpsStore } from '../../AppServices';
import TokenInput from './TokenInput';
import VaccinationStatusRender from './VaccinationStatusRender';
import { Image, Text, View } from '../../app-components';
import { confirmation, noStatus } from '../../images';
import vars from '../../theme/vars';
import { ROUTES } from '../../Lib/constants';
import { Table } from '../../app-components/table/Table';

const tabListColumns = ({ sessionId }) => [
  {
    width: 20,
  },
  {
    header: 'vaccination.headers.token'.getLabel(),
    type: 'number',
    width: 240,
    render: (props) => <TokenInput {...props} sessionId={sessionId} />,
  },
  {
    header: 'Actions',
    type: 'openActions',
    openActions: [
      {
        render: () => (
          <Text style={{ color: vars.colors.secondary.color2, marginLeft: 12 }}>Add Measurement</Text>
        ),
        type: 'link',
        visible: ({ item: { patientHeight } }) => (!patientHeight),
        link: ({ item }) => ({
          view: ROUTES.addPatientMeasurment.name,
          params: { item },
        }),
      },
      {
        render: ({
          item: {
            patientWeight = 0,
            patientHeight = 0,
            patientHeadCircumfrence = 0,
          },
        }) => (
          <Text
            style={{ ...vars.headings.h9 }}
          >
            {`${patientHeight} cm, ${patientWeight} kg, ${patientHeadCircumfrence} in`}
          </Text>
        ),
        visible: ({ item: { patientHeight } }) => (!!patientHeight),
      },
    ],
  },
  {
    header: 'vaccination.headers.uhid'.getLabel(),
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'vaccination.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'vaccination.headers.vaccinationStatus'.getLabel(),
    type: 'text',
    render: VaccinationStatusRender,
    field: 'status',
  },
  {
    header: 'vaccination.headers.paymentStatus'.getLabel(),
    type: 'text',
    render: ({ item: { isPaid } = {} } = {}) => {
      if (!isPaid) {
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Image
              source={confirmation}
              style={{
                width: 20,
                height: 20,
              }}
            />
            <Text
              style={[
                vars.headings.h9,
                { marginLeft: 10, color: vars.colors.grey.color1 },
              ]}
            >
              Paid
            </Text>
          </View>
        );
      }
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            source={noStatus}
            style={{
              width: 20,
              height: 20,
            }}
          />
          <Text
            style={[
              vars.headings.h9,
              { marginLeft: 10, color: vars.colors.grey.color1 },
            ]}
          >
            Free
          </Text>
        </View>
      );
    },
  },
];

const VaccinationListOfPatients = (props) => {
  const {
    id,
    model,
    tableColumns = tabListColumns,
    addOnFilter,
    eventDispatcher,
    // subscribeToUpdates,
    sessionId,
  } = props;

  const reloadVaccinations = useCallback(() => {
    eventDispatcher.notify('reloadVaccinations');
  }, [eventDispatcher]);

  const subscribeToNewVaccinations = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'new-Vaccination',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  const unSubscribeToNewVaccinations = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'new-Vaccination',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  const subscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Vaccination-Assigned',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  const unSubscribeToVaccinationAssigned = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Vaccination-Assigned',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  const subscribeToStatusChange = useCallback(() => {
    gpsStore.subscribeEvent({
      event: 'Status-Change',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  const unSubscribeToStatusChange = useCallback(() => {
    gpsStore.unsubscribeEvent({
      event: 'Status-Change',
      callback: reloadVaccinations,
    });
  }, [reloadVaccinations]);

  useEffect(() => {
    subscribeToNewVaccinations();
    subscribeToStatusChange();
    subscribeToVaccinationAssigned();

    return () => {
      unSubscribeToNewVaccinations();
      unSubscribeToStatusChange();
      unSubscribeToVaccinationAssigned();
    };
  }, []);

  return (
    <Table
      {...props}
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: {
            statusSortValue: 1,
            token: 1,
            _createdOn: -1,
          },
        },
        model,
      })}
      lg={{ columns: isFunction(tableColumns) ? tableColumns({ sessionId }) : tableColumns }}
      showActionIcon={false}
      reloadEvent="reloadVaccinations"
    />
  );
};
export default VaccinationListOfPatients;
