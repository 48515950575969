import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator, Text, TouchableOpacity,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { fetch } from '../../../../AppServices';
import { MODELS, vaccinationStatus } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import printPharmacyBill from '../../../../Wired/PatientInvoices';
import FileLink from '../../../../Components/FileLink';

const ViewPatientVaccinationLogs = (props) => {
  const { navigation } = props;
  const { state: { params: { item: { vaccinationSoFar } } = {} } = {} } = navigation;
  const { length } = vaccinationSoFar;
  const [patientDetails, setPatientDetails] = useState();
  const [loading, setLoading] = useState(true);
  const fetchPatientDetails = useCallback(async () => {
    const fetchedPatientDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'patientVaccinationList',
            addOnFilter: {
              _id: vaccinationSoFar[length - 1]?.vaccination?._id,
            },
          },
          model: MODELS.VACCINATION,
        },
      },
    });
    setPatientDetails(fetchedPatientDetails?.data[0]);
    setLoading(false);
    return fetchedPatientDetails;
  }, [vaccinationSoFar]);
  useEffect(() => {
    fetchPatientDetails();
  }, [vaccinationSoFar]);
  if (loading) return <ActivityIndicator />;
  return (
    <Form
      {...props}
      type="standard"
      closeView={1}
      defaultValues={{ ...patientDetails }}
      editable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'vaccination.labels.vaccinationDate'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: '_createdOn',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.status'.getLabel(),
                    render: () => {
                      const { status } = patientDetails;
                      if (status === vaccinationStatus.vaccinated) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                            Vaccinated
                          </Text>
                        );
                      }
                      if (status === vaccinationStatus.nurseAssign) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                            Nurse Assigned
                          </Text>
                        );
                      }
                      if (status === vaccinationStatus.notAppear) {
                        return (
                          <Text style={{ ...vars.headings.h8, color: vars.colors.error }}>
                            Not Appeared
                          </Text>
                        );
                      }
                      return (
                        <Text style={{ ...vars.headings.h8 }}>
                          No Status
                        </Text>
                      );
                    },
                    field: 'status',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.bill'.getLabel(),
                    render: ({ data }) => {
                      const { billing, patient } = data;
                      return (
                        <TouchableOpacity onPress={({ submitResult }) => printPharmacyBill({ data: { ...billing }, submitResult })}>
                          <Text style={{ color: vars.colors.secondary.color2 }}>
                            {`Invoice_${patient?.patientName}.pdf`}
                          </Text>
                        </TouchableOpacity>
                      );
                    },
                  },
                  {
                    type: 'file',
                    label: 'vaccination.labels.prescription'.getLabel(),
                    render: () => {
                      const { billing } = patientDetails;
                      if (billing?.documents) {
                        return (
                          <FileLink file={billing.documents} />
                        );
                      }
                    },
                  },
                  {
                    type: 'number',
                    label: 'vaccination.labels.latestOPD'.getLabel(),
                    field: 'consultation.ODPNumber',
                  },
                ],
              },
            ],
          },
          {
            label: 'vaccination.title.patientDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    label: 'vaccination.labels.patientName'.getLabel(),
                    field: 'patient.patientName',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.uhid'.getLabel(),
                    field: 'patient.uhid',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.gender'.getLabel(),
                    field: 'patient.gender',
                  },
                  {
                    type: 'number',
                    label: 'vaccination.labels.age'.getLabel(),
                    render: () => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {patientDetails.patient.age}
                        {' '}
                        {patientDetails.patient.ageType}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.bloodGroup'.getLabel(),
                    field: 'patient.name',
                  },
                  {
                    type: 'number',
                    label: 'vaccination.labels.mobile'.getLabel(),
                    field: 'patient.mobile',
                  },
                ],
              },
            ],
          },
          {
            label: 'vaccination.labels.vaccineDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 3,
                columns: [
                  {
                    type: 'text',
                    label: 'vaccination.labels.nurse'.getLabel(),
                    render: () => {
                      const { session: { nurse: { firstName, lastName } = {} } = {} } = patientDetails;
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {firstName}
                          {' '}
                          {lastName}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.assistant'.getLabel(),
                    render: () => {
                      const { session: { assistant: { firstName, lastName } = {} } = {} } = patientDetails;
                      return (
                        <Text style={{ ...vars.headings.h9 }}>
                          {firstName}
                          {' '}
                          {lastName}
                        </Text>
                      );
                    },
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.department'.getLabel(),
                    field: 'consultation.department.name',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.speciality'.getLabel(),
                    field: 'consultation.speciality.name',
                  },
                  {
                    type: 'text',
                    label: 'vaccination.labels.doctorName'.getLabel(),
                    field: 'consultation.consultedByName',
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewPatientVaccinationLogs;

