import MasterForm from '../../../Wired/Master/MasterForm';
import { MODELS } from '../../../Lib/constants';
import { EditShift } from '../../../Queries/shift';
import { validateShift } from '../../../Lib/helpers';

const validations = {
  startTime: ({ data }) => validateShift(data),
  endTime: ({ data }) => validateShift(data),
};

const formFields = [
  {
    type: 'text',
    field: 'code',
    label: 'shift.labels.shiftId'.getLabel(),
    mandatory: true,
  },
  {
    type: 'text',
    field: 'name',
    label: 'shift.labels.shiftName'.getLabel(),
    mandatory: true,
    allowWhiteSpaces: true,
  },
  {
    type: 'time',
    field: 'startTime',
    label: 'shift.labels.startTime'.getLabel(),
    container: 'leftLabel',
    mandatory: true,
  },
  {
    type: 'time',
    field: 'endTime',
    label: 'shift.labels.endTime'.getLabel(),
    container: 'leftLabel',
    mandatory: true,
  },
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'shift.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'left',
  },
];

export default MasterForm({
  uri: EditShift,
  model: MODELS.SHIFT,
  formFields,
  validations,
  submitMessage: 'shift.messages.shiftUpdatedSuccessfully'.getLabel(),
  mandatory: {
    code: 1,
    name: 1,
    startTime: 1,
    endTime: 1,
  },
  defaultValues: () => ({
    isActive: true,
  }),
});
