import React from 'react';
import { upperFirst } from 'lodash';

import { MODELS, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { Form } from '../../../../../../app-components/form/Form';
import { Text, View } from '../../../../../../app-components';

const ViewPurchaseRequest = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  const {
    navigation: {
      state: {
        params: {
          item: {
            batchNumber,
          } = {},
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <Form
      type="standard"
      closeView={1}
      reloadEvent={`reload${MODELS.ASSET_BATCH}`}
      editable={false}
      defaultValues={() => ({ item })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'asset.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'item._createdOn',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.purchaseRequestNumber'.getLabel(),
                    field: 'item.purchaseRequestAsset.purchaseRequestNumber',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.status'.getLabel(),
                    render: ({ data }) => {
                      let statusColor = '';
                      const status = data?.item?.purchaseRequestAsset?.requestStatus;
                      if (status === purchaseSupplyOrderStatus.RAISED) {
                        statusColor = vars.colors.emphasis;
                      } else if (status === purchaseSupplyOrderStatus.ORDER_RECEIVED) {
                        statusColor = vars.colors.primary.color2;
                      } else if (
                        status === purchaseSupplyOrderStatus.PO_REJECTED
                        || status === purchaseSupplyOrderStatus.SO_REJECTED
                      ) {
                        statusColor = vars.colors.error;
                      } else { statusColor = vars.colors.warning; }
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                          <View style={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: statusColor,
                            marginTop: 3,
                            marginLeft: 7,
                          }}
                          />
                        </View>
                      );
                    },
                  },
                  {
                    type: 'date',
                    format: 'DD MMM YYYY',
                    label: 'asset.labels.stockEntryDate'.getLabel(),
                    field: 'item.purchaseRequestAsset.batchNumber.stockEntryDate',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.service'.getLabel(),
                    field: 'item.purchaseRequestAsset.isAMC',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.description'.getLabel(),
                    field: 'item.purchaseRequestAsset.reasonForPurchase',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    field: 'item.purchaseRequestAsset.item.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.category'.getLabel(),
                    field: 'item.purchaseRequestAsset.item.category.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.subCategory'.getLabel(),
                    field: 'item.purchaseRequestAsset.item.subCategory.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.purchasedPrice'.getLabel(),
                    field: 'item.purchaseRequestAsset.batchNumber.purchasedPrice',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.manufacturer'.getLabel(),
                    field: 'item.purchaseRequestAsset.manufacturer.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.supplier'.getLabel(),
                    field: 'item.purchaseRequestAsset.supplier.name',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    type: 'custom',
                    render: () => (
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {batchNumber.map(({ asset }) => asset.map(({ serialNumber }, index) => (
                          <View
                            style={{
                              padding: 10,
                              margin: 5,
                              borderRadius: 50,
                              backgroundColor: vars.colors.white,
                            }}
                            key={index}
                          >
                            <Text style={{ ...vars.headings.h9 }}>
                              {serialNumber}
                            </Text>
                          </View>
                        )))}
                      </View>
                    ),
                    header: 'asset.labels.serialNo'.getLabel(),
                    label: 'asset.labels.serialNo'.getLabel(),
                    variant: 'filled',
                    field: 'serialNumber',
                    editable: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPurchaseRequest;
