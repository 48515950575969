import vars from '../../../theme/vars';

const { colors, headings } = vars;

const navigationBarStyle = {
  tabsWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 80,
    alignItems: 'space-between',
    overflow: 'inherit',
  },
  modalTextStyle: {
    color: 'white',
    paddingTop: 12,
  },
  tabWrapper: {
    justifyContent: 'space-between',
    height: 80,
  },
  tabTouchableContainerStyle: {
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: 25,
    marginRight: 25,
    marginTop: 18,
    justifyContent: 'space-around',
  },
  activeTint: {
    height: 3,
    width: '50%',
    alignSelf: 'center',
    backgroundColor: colors.primary.color2,
  },
  wrapperForBorderRadius: {
    // backgroundColor: colors.primary.color5,
  },
  containerStyle: {
    backgroundColor: colors.white,
    paddingLeft: 14,
    paddingRight: 30,
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(154, 154, 154, 0.143521)',
    borderRadius: ' 0px 0px 8px 8px',
    marginBottom: 2,
  },
  tabText: {
    ...headings.h13,
    marginTop: 8,
  },
  userInfoWrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: colors.secondary.color2,
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  usernameText: {
    ...headings.h9,
    textTransform: 'uppercase',
    color: colors.secondary.color2,
  },
  breadcrumbViewWrapper: {
    flexDirection: 'row',
    height: 60,
    backgroundColor: colors.primary.color5,
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 30,
    justifyContent: 'space-between',
  },
};

export default navigationBarStyle;
