/* eslint-disable no-unused-expressions */
import React from 'react';

import ConsultationStatus from '../../../../../Wired/ConsultedPatients/Components/ConsultationStatus';
import ConsultedPatientLists from '../../../../../Wired/ConsultedPatients';
import { TabNavigator, Text, View } from '../../../../../app-components';
import { countUri } from '../../../../../Queries/countUri';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';
import DoctorEdit from './EditDoctor';
import tabTheme from './tabTheme';

const tabListColumns = [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
    width: 120,
    render: ({ item }) => (
      <View style={{}}>
        <Text
          style={{
            ...vars.headings.h2,
          }}
        >
          {item?.token}
        </Text>
      </View>
    ),
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'Status',
    type: 'custom',
    render: ConsultationStatus,
    width: 200,
  },
  {
    width: 50,
  },
];

const getTabs = (props) => {
  const { activeSession = {} } = props;
  const tabs = {};

  const alreadyAssignedDoctors = activeSession?.activeQueues?.map((
    { doctor },
  ) => doctor?._id);

  activeSession.activeQueues && activeSession.activeQueues.forEach((queue) => {
    const { room = {} } = queue;
    const { name, _id } = room || {};

    tabs[queue._id] = {
      label: name,
      screen: ConsultedPatientLists,
      screenProps: {
        key: queue._id,
        tableColumns: tabListColumns,
        model: MODELS.CONSULTATION,
        id: 'consultationDataList',
        roomId: _id,
        addOnFilter: {
          sessionQueue: { _id: queue._id },
        },
      },
      actions: [
        {
          render: (actionProps) => (
            <DoctorEdit
              {...actionProps}
              queue={queue}
              alreadyAssignedDoctors={alreadyAssignedDoctors}
            />
          ),
        },
      ],
      countUri: countUri({
        model: MODELS.CONSULTATION,
        id: 'consultationDataList',
        filter: {
          sessionQueue: { _id: queue._id },
        },
      }),
    };
  });
  return tabs;
};

const ConsultedPatientsListTabs = TabNavigator({
  theme: { ...tabTheme },
  tabsFunc: getTabs,
});

export default ConsultedPatientsListTabs;
