import React from 'react';

import { Text } from '../../../../../app-components';
import { organizationLabels } from '../../../../../labels/en/logoOrganisationLabels';
import vars from '../../../../../theme/vars';

const Consent = (paramsData = {}) => (
  <Text style={{
    lineHeight: '26px',
    paddingLeft: 12,
    labelStyle: {
      ...vars.colors.grey.color2,
      fontSize: '500px',
    },
  }}
  >
    a. I hereby authorized the designated
    staff of
    {' '}
    {organizationLabels.ipdConsentOrganizationName}
    {' '}
    to admit, investigate and treat my ward/myself.
    <br />
    b. I haveby give an undertaking that I will
    insist on treatment/ operation/ procedure by a particular doctor/ nurse/ technician.
    <br />
    c. I also give consent to perform the mentioned operation/
    procedure
    {' '}
    <b style={{
      textDecoration: paramsData.operationName || paramsData.operationProcedure ? 'underline' : null,
      margin: '4px',
      textUnderlineOffset: '3px',
    }}
    >
      {paramsData.operationName ? paramsData.operationName.name
        : paramsData.operationProcedure ? paramsData.operationProcedure.operationName.name : '........'}
    </b>
    of anesthesia and also to alternative
    or additive procedure as deemed necessary bt treating doctor(s).
    <br />
    d. The nature & purpose of the treatment modality has been explained
    to me along with the risk and complication involved in a language,
    I fully understand.
    Also, no guarantee has been given to me regarding the success of the treatment.
    <br />
    e. If any unforeseen complication(s) arise(s) during the course of
    treatment. I authorize the doctor to act in the best interest of the mine/ patient.
    <br />
    f. I consent to disposal, by the authorized of
    {' '}
    {organizationLabels.ipdConsentOrganizationName}
    {' '}
    , of any
    tissues/ part/ fluids, which may be removed from my patient`s
    body during the course of treatment.
    <br />
    g. I also consent for autopsy examination, if deemed essential by the
    hospital authorities.
    <br />
    h. Certified that I have read/have been read over the above in a
    language I understand and after having understood its implications. I hereby give my
    consent for the above under no compulsions, what so ever.
  </Text>
);
export default Consent;

