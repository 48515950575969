import React from 'react';
import {
  View,
  Fts,
} from '../../app-components';
import { userView } from '../../images';
import ListActionModal from '../../Components/ListActionModal';
import UserCardList from '../UserCardList';

const ViewEmployeeListModal = (props) => {
  const { navigation: { state: { params: { item, filter } } } } = props;

  return (
    <ListActionModal
      icon={userView}
      title={item?.code}
      description="List of Employee in the lab."
      content={(
        <>
          <View style={{ alignItems: 'center', marginBottom: 36 }}>
            <View>
              <Fts />
            </View>
          </View>
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <UserCardList filter={filter} {...props} />
          </View>
        </>
      )}
      {...props}
    />
  );
};
export default ViewEmployeeListModal;
