/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import {
  ActivityIndicator,
  FormField, Image, Text, Toast, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { fetch, getUser, submit } from '../../../../AppServices';
import { NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import printPharmacyBill from '../../../../Wired/PatientInvoices';
import { getString, sortByKey } from '../../../../Lib/helpers';
import { MODELS } from '../../../../Lib/constants';

const GenerateBill = (props) => {
  const { navigation: { state: { params: { data: patientInfo, pharmacyDetails, freeVaccineDetails } } } } = props;
  const itemIds = pharmacyDetails?.filter(({ name }) => name?._id).map(({ name }) => name?._id);
  const [pharmacyDetailsNew, setDetailsSuggestion] = useState();
  const [loading, setLoading] = useState(true);

  const user = getUser();
  const { employee: { department = [], firstName, lastName } = {} } = user;
  // eslint-disable-next-line no-unused-vars
  const [departmentId, setDepartmentId] = useState(department?.map((dep) => dep?._id));

  const fetchPharmacyDetailsSuggestionDetails = useCallback(async () => {
    const fetchedItemSuggestionDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'pharmacyDepartmentInventoryStocksDataList',
            addOnFilter: {
              item: {
                _id: { $in: itemIds },
                department: { _id: { $in: departmentId } },
              },
            },
          },
          model: MODELS.PHARMACY_DEPARTMENT_STOCKS,
        },
      },
    });

    const clonedFetchedDepartmentStocks = sortByKey(fetchedItemSuggestionDetails.data, 'stockAvailable');

    const clonedStocks = [];
    clonedFetchedDepartmentStocks.forEach((item) => {
      const found = clonedStocks.find((stock) => stock.item._id === item.item._id);

      if (!found) {
        clonedStocks.push({
          ...item,
        });
      }
    });

    setDetailsSuggestion(clonedStocks);
    setLoading(false);
    return fetchedItemSuggestionDetails;
  }, [departmentId, itemIds]);

  useEffect(() => {
    fetchPharmacyDetailsSuggestionDetails();
  }, [fetchPharmacyDetailsSuggestionDetails]);

  if (!department[0]?._id) {
    Toast.show({
      message: 'Error',
      description: 'vaccination.messages.loginUserError'.getLabel(),
      type: 'error',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    return null;
  }

  if (loading) return <ActivityIndicator />;

  return (
    <Form
      containerStyle={{
        backgroundColor: vars.colors.secondary.color4,
        flex: 1,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 16,
        marginRight: 16,
      }}
      closeView={1}
      beforeSubmit={({ data, updates }) => {
        let itemPharmacyDetails = data?.pharmacyDetails?.map((item) => ({
          item: { _id: item?.item?._id },
          quantity: item?.quantity,
          totalPrice: item?.totalPrice,
          department: { _id: item?.department?._id },
        }
        ));
        itemPharmacyDetails = [...itemPharmacyDetails, ...freeVaccineDetails];
        return {
          updates: {
            ...updates,
            pharmacyDetails: itemPharmacyDetails,

          },
        };
      }}
      onSubmit={submit({
        model: MODELS.PHARMACY_BILLING,
      })}
      submitNext={({ data, submitResult }) => printPharmacyBill({ data, submitResult })}
      submitMessage={'vaccination.messages.invoiceGenerateSuccessfully'.getLabel()}
      defaultValues={() => ({
        billingDate: new Date(),
        department: departmentId,
        pharmacyDetails: pharmacyDetailsNew,
        consultationId: { _id: patientInfo[0]?.lastConsultation?._id },
        patientId: {
          _id: patientInfo[0]?._id,
          age: patientInfo[0]?.age,
          uhid: patientInfo[0]?.uhid,
          gender: patientInfo[0]?.gender,
          mobile: patientInfo[0]?.mobile,
          lastConsultation: patientInfo[0]?.lastConsultation,
          patientName: patientInfo[0]?.patientName,
          relation: patientInfo[0]?.relation,
          relationName: patientInfo[0]?.relationName,
          lastConsultationDate: patientInfo[0]?.lastConsultationDate ? moment(patientInfo[0]?.lastConsultationDate).format('DD MMM YYYY') : 'N/A',
        },
        discount: 0,
        dispensedBy: firstName + lastName,
      })}
      computations={{
        children: {
          pharmacyDetails: {
            self: {
              priceSet: {
                compute: ({ item, quantity }) => ({
                  set: {
                    totalPrice: item?.sellingPrice * quantity,
                    billAmount: item?.sellingPrice * quantity,
                  },
                }),
                onChange: ['quantity'],
              },
            },
          },
        },
        self: {
          billAmount: {
            compute: ({ pharmacyDetails = [], discount }) => {
              let totalAmount = 0;
              let countLength = 0;
              pharmacyDetails.map(({ totalPrice }) => {
                totalAmount += totalPrice;
                countLength += 1;
                return (totalPrice, countLength);
              });
              // eslint-disable-next-line no-multi-assign
              totalAmount = totalAmount *= (1 - (discount || 0) / 100);
              return {
                set: { billAmount: totalAmount <= 0 ? 0 : totalAmount, totalItems: countLength },
              };
            },
            onChange: ['pharmacyDetails.totalPrice', 'discount', 'pharmacyDetails.quantity'],
          },
        },
      }}
      validations={{
        pharmacyDetails: {
          quantity: ({ data }) => {
            if (data.quantity) {
              if (data?.quantity > data?.name?.stockAvailable[0].stockAvailable) {
                return 'Requested units can not be more than units available';
              }
            }
            if (data?.quantity <= 0) {
              return 'Unit Requested can not be less than or equal to 0';
            }
            if (!data?.quantity) return 'This field cannot be empty';
          },
        },
      }}
      {...props}
    >
      {() => (
        <>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flexDirection: 'column', flex: 0.2 }}>
              <View style={{ flexDirection: 'row', marginLeft: 24, marginTop: 20 }}>
                <View>
                  <Image
                    source={barcode}
                  />
                </View>
                <View style={{ marginTop: 20, marginLeft: 24 }}>
                  <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>
                    {'vaccination.labels.uhid'.getLabel()}
                  </Text>
                  <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                    {patientInfo[0]?.uhid}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'column', flex: 0.8 }}>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.name'.getLabel()}
                    field="patientId.patientName"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.relation'.getLabel()}
                    editable={false}
                    render={() => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getString([patientInfo[0]?.relation, patientInfo[0]?.relationName])}
                      </Text>
                    )}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.gender'.getLabel()}
                    field="patientId.gender"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.age'.getLabel()}
                    field="patientId.age"
                    variant="filled"
                    editable={false}
                  />
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36 }}>
                  <FormField
                    type="file"
                    label={'vaccination.labels.documents'.getLabel()}
                    container="topLabel"
                    field="documents"
                    variant="filled"
                  />
                </View>

                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.mobile'.getLabel()}
                    field="patientId.mobile"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.latestOPD'.getLabel()}
                    field="patientId.lastConsultation.ODPNumber"
                    variant="filled"
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1, marginLeft: 14 }}>
                  <FormField
                    type="text"
                    label={'vaccination.labels.lastConsultation'.getLabel()}
                    field="patientId.lastConsultationDate"
                    variant="filled"
                    editable={false}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <View style={{
              flexDirection: 'row', flex: 1, marginTop: 14, marginLeft: 36,
            }}
            >
              <FormField
                {... {
                  field: 'pharmacyDetails',
                  fieldType: 'nested',
                  label: 'PRESCRIBED MEDICINES',
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    listProps: {
                      columns: [
                        {
                          type: 'text',
                          field: 'item.itemCode',
                          label: `${'vaccination.labels.itemId'.getLabel()}`,
                          variant: 'filled',
                          disabled: () => true,
                        },
                        {
                          type: 'text',
                          field: 'item.name',
                          label: `${'vaccination.labels.itemName'.getLabel()}`,
                          variant: 'filled',
                          disabled: () => true,
                        },
                        {
                          type: 'text',
                          field: 'item.category.name',
                          label: 'vaccination.labels.category'.getLabel(),
                          variant: 'filled',
                          disabled: () => true,
                        },
                        {
                          type: 'text',
                          field: 'item.rateContract.manufacturer.name',
                          label: `${'vaccination.labels.manufacturer'.getLabel()}`,
                          variant: 'filled',
                          disabled: () => true,
                        },
                        {
                          type: 'number',
                          label: `${'vaccination.labels.quantity'.getLabel()}`,
                          field: 'quantity',
                          variant: 'filled',
                          mandatory: true,
                        },
                        {
                          type: 'number',
                          label: `${'vaccination.labels.stockAvailable'.getLabel()}`,
                          field: 'stockAvailable',
                          variant: 'filled',
                          disabled: true,
                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.unitPrice'.getLabel()}`,
                          field: 'item.sellingPrice',
                          variant: 'filled',
                          disabled: true,

                        },
                        {
                          type: 'number',
                          label: `${'billing.labels.price'.getLabel()}`,
                          field: 'totalPrice',
                          variant: 'filled',
                          disabled: true,
                        },
                      ],
                    },
                  },
                }}
              />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View style={{ flexDirection: 'column', flex: 0.7, marginTop: 14 }}>
                <View style={{ flex: 1, marginLeft: 36, height: 10 }}>
                  <FormField
                    type="text"
                    editable={false}
                    field="totalItems"
                    variant="filled"
                    label={'vaccination.labels.totalNoOfItem'.getLabel()}
                    container="topLabel"
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'column', flex: 0.3, marginTop: 10 }}>
                <View style={{ flex: 1, marginLeft: 36 }} />
                <View style={{ marginLeft: 14, width: 309 }}>
                  <FormField
                    type="number"
                    label={'vaccination.labels.discount'.getLabel()}
                    field="discount"
                    variant="filled"
                  />
                </View>
                <View style={{ marginTop: 20, marginLeft: 14, width: 309 }}>
                  <FormField
                    type="number"
                    editable={false}
                    label={'vaccination.labels.billAmount'.getLabel()}
                    field="billAmount"
                    variant="filled"
                  />
                </View>
              </View>
            </View>
          </View>
        </>
      )}
    </Form>
  );
};
export default GenerateBill;
