import React, { useEffect, useState } from 'react';

import { View, Text } from '../../../../../../../app-components';
// import ChronicPatient from '../ChronicPatient';
import vars from '../../../../../../../theme/vars';
import Ear from './CaseHistoryAndExaminationNose';
import CaseHistoryAndExaminationNose from './CaseHistoryAndExaminationEar';

const selectedStyles = {
  height: 30,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 34,
  paddingRight: 34,
  cursor: 'pointer',
  backgroundColor: '#4070C9',
  color: '#FFFFFF',
  borderWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
};

const unSelectedStyles = {
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 34,
  paddingRight: 34,
  borderWidth: 0,
  borderColor: vars.colors.primary.color3,
  cursor: 'pointer',
  backgroundColor: '#F0FFF4',
  color: '#828282',
};

const CaseHistoryAndExamination = (props) => {
  const { dataParams = {} } = props;
  const [selectedTab, setSelectedTab] = useState('ear');

  const RenderTab = ({ selectTab }) => {
    if (selectTab === 'ear') {
      return <CaseHistoryAndExaminationNose filters={dataParams} {...props} />;
    }
    return <Ear filters={dataParams} {...props} />;
  };
  useEffect(() => {
    const { eventDispatcher } = props || {};
    eventDispatcher.notify('selectedTabFilter', selectedTab);
  }, [selectedTab]);

  return (
    <View>
      <View
        style={{
          // flex: 1,
          flexDirection: 'row',
          marginTop: '1rem',
          marginLeft: '3rem',
        }}
      >
        <View
          style={selectedTab === 'ear' ? selectedStyles : unSelectedStyles}
          onPress={() => setSelectedTab('ear')}
        >
          <Text>Ear</Text>
        </View>
        <View
          style={selectedTab === 'nose' ? selectedStyles : unSelectedStyles}
          onPress={() => setSelectedTab('nose')}
        >
          <Text>Nose And Throat</Text>
        </View>
      </View>
      <RenderTab selectTab={selectedTab} />
    </View>
  );
};

export default CaseHistoryAndExamination;
