/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FormField, Image, View,
} from '../../../../app-components';
import { autoSuggestInput } from '../../../../autoSuggestions';
import vars from '../../../../theme/vars';
import {
  expandIcon,
} from '../../../../images';
import { getString } from '../../../../Lib/helpers';

const styles = {
  autoSuggestContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: vars.colors.grey.color6,
  },
  autoSuggestWrapper: {
    width: 300,
    height: 44,
    marginLeft: 20,
  },
};

const Selector = () => (
  <View
    style={{
      width: 26,
      justifyContent: 'center',
    }}
  >
    <Image
      source={expandIcon}
      style={{
        width: 11,
        height: 6,
      }}
    />
  </View>
);

const SessionInfo = (props) => {
  const { setScreenState, sessionQueueInfo } = props;

  useEffect(() => {
    if (!isEmpty(sessionQueueInfo)) {
      setScreenState({ sessionQueue: sessionQueueInfo });
    }
  }, [sessionQueueInfo, setScreenState]);

  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          width: 300,
          height: 44,
        }}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'incomingPatient.labels.doctor'.getLabel(),
            value: getString([
              sessionQueueInfo?.doctor?.firstName,
               sessionQueueInfo?.doctor?.lastName,
            ]),
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
          }
        />
      </View>
      <View
        style={styles.autoSuggestWrapper}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'incomingPatient.labels.speciality'.getLabel(),
            value: sessionQueueInfo?.session?.speciality?.name,
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
        }
        />
      </View>
      <View
        style={styles.autoSuggestWrapper}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'incomingPatient.labels.department'.getLabel(),
            value: sessionQueueInfo?.department?.name,
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            buttonContainerStyle: styles.autoSuggestContainer,
            renderSelector: Selector,
          })
        }
        />
      </View>
      <View
        style={styles.autoSuggestWrapper}
      >
        <FormField
          {
          ...autoSuggestInput({
            label: 'incomingPatient.labels.room'.getLabel(),
            value: sessionQueueInfo?.room?.name,
            variant: 'filled',
            disabled: true,
            suggestionField: 'name',
            editable: false,
          })
        }
        />
      </View>
    </View>
  );
};

export default SessionInfo;
