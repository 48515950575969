import React from 'react';
import { Table } from '../../../../app-components/table/Table';
import { ROUTES } from '../../../../Lib/constants';
import { DonationRecordsList } from '../../../../Queries/bloodBank';

const tabListColumns = [
  { width: 14 },
  {
    header: 'bloodBankRecords.headers.donationDate'.getLabel(),
    type: 'date',
    field: 'date',
    sortable: 'date',
  },
  {
    header: 'bloodBankRecords.headers.bagNo'.getLabel(),
    type: 'text',
    field: 'name',
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.tubeNo'.getLabel(),
    type: 'text',
    field: 'tubeNo',
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.donorName'.getLabel(),
    type: 'text',
    field: 'bloodDonor.name',
  },
  {
    header: 'bloodBankRecords.headers.bloodGroup'.getLabel(),
    type: 'text',
    field: 'bloodGroup',
  },
];

const BloodRecordsDonationList = (props) => {
  const { tableColumns = tabListColumns } = props;
  return (
    <Table
      lg={{ columns: tableColumns }}
      uri={DonationRecordsList}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewBloodDonation.name,
          params: { item },
        },
      })}
      {...props}
    />
  );
};
export default BloodRecordsDonationList;
