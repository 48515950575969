import React from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import { Text, View } from '../../../../../../app-components';
import { MODELS, purchaseSupplyOrderStatus } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { NestedTable } from '../../../../../../app-components/input-components/nestedTable/NestedTable';
import { searchInput } from '../../../../../../autoSuggestions';
import { Form } from '../../../../../../app-components/form/Form';
import { EditAssetIndentRequestStatus } from '../../../../../../Queries/asset';

const ViewIndentRecord = (props) => (
  <Form
    {...props}
    type="standard"
    closeView={1}
    reloadEvent={`reload${MODELS.ASSET_BATCH}`}
    uri={EditAssetIndentRequestStatus}
    editable={false}
    lg={{
      formGroups: [
        {
          columnsPerRow: 4,
          fieldVariant: 'filled',
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                {
                  type: 'date',
                  label: 'asset.labels.dateRaised'.getLabel(),
                  format: 'DD MMM YYYY',
                  field: '_createdOn',
                },
                {
                  type: 'date',
                  label: 'asset.labels.responseDate'.getLabel(),
                  format: 'DD MMM YYYY',
                  field: '_updatedOn',
                },
                {
                  type: 'text',
                  label: 'asset.labels.indentNumber'.getLabel(),
                  field: 'indentNumber',
                },
                {
                  type: 'text',
                  label: 'asset.labels.status'.getLabel(),
                  render: ({ data }) => {
                    let statusColor = '';
                    const status = data?.requestStatus;
                    if (status === purchaseSupplyOrderStatus.STOCK_PROVIDED) {
                      statusColor = vars.colors.primary.color2;
                    } else {
                      statusColor = vars.colors.warning;
                    }
                    return (
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ ...vars.headings.h8 }}>{upperFirst(status)}</Text>
                        <View style={{
                          height: 8,
                          width: 8,
                          borderRadius: 8,
                          backgroundColor: statusColor,
                          marginTop: 3,
                          marginLeft: 7,
                        }}
                        />
                      </View>
                    );
                  },
                },
              ],
            },
          ],
        },
        {
          fieldVariant: 'filled',
          label: 'asset.title.indentDetails'.getLabel(),
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 4,
          },
          groupsContainerStyle: { paddingLeft: 0 },
          groups: [
            {
              columns: [
                {
                  field: 'indentDetails',
                  fieldType: 'nested',
                  Component: NestedTable,
                  componentProps: {
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: false,
                      headerRowContainerStyle: {
                        minHeight: 32,
                        paddingLeft: 12,
                        paddingRight: 4,
                      },
                      headerRowColumnTextStyle: {
                        paddingLeft: 2,
                        ...vars.headings.h13,
                        color: vars.colors.grey.color3,
                        numberOfLines: 1,
                        textTransform: 'upperCase',
                      },
                      columns: [
                        {
                          type: 'text',
                          header: 'asset.labels.assetName'.getLabel(),
                          label: 'asset.labels.assetName'.getLabel(),
                          variant: 'filled',
                          field: 'item.name',
                          editable: false,
                          width: 150,
                        },
                        searchInput({
                          field: 'item.category',
                          variant: 'filled',
                          header: 'asset.labels.category'.getLabel(),
                          label: 'asset.labels.category'.getLabel(),
                          keyField: 'category.name',
                          valueField: 'name',
                          editable: false,
                          width: 120,
                        }),
                        searchInput({
                          field: 'item.subCategory',
                          variant: 'filled',
                          header: 'asset.labels.subCategory'.getLabel(),
                          label: 'asset.labels.subCategory'.getLabel(),
                          keyField: 'subCategory.name',
                          valueField: 'name',
                          editable: false,
                          width: 120,
                        }),
                        {
                          header: 'asset.headers.stockIssued'.getLabel(),
                          type: 'date',
                          format: 'DD MMM YYYY',
                          render: ({
                            navigation:
                               {
                                 state:
                                 {
                                   params:
                                   {
                                     item: {
                                       _updatedOn,
                                     } = {},
                                   }
                                   = {},
                                 } = {},
                               } = {},
                          }) => (
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color2 }}>
                                {moment(_updatedOn).format('DD MMM YYYY')}
                              </Text>
                            </View>
                          ),
                          width: 150,
                        },
                        {
                          type: 'number',
                          header: 'asset.labels.stockRequired'.getLabel(),
                          label: 'asset.labels.stockRequired'.getLabel(),
                          variant: 'filled',
                          field: 'requiredQuantity',
                          editable: false,
                          width: 150,
                        },
                        {
                          type: 'number',
                          header: 'asset.labels.stockProvided'.getLabel(),
                          label: 'asset.labels.stockProvided'.getLabel(),
                          variant: 'filled',
                          field: 'providedQuantity',
                          editable: false,
                          width: 150,
                        },
                        {
                          type: 'text',
                          header: 'asset.labels.stockAvailable'.getLabel(),
                          label: 'asset.labels.stockAvailable'.getLabel(),
                          variant: 'filled',
                          value: ({ item }) => item.item.stockAvailable[0].stockAvailable,
                          editable: false,
                          width: 150,
                        },
                        {
                          type: 'text',
                          header: 'asset.labels.manufacturer'.getLabel(),
                          label: 'asset.labels.manufacturer'.getLabel(),
                          variant: 'filled',
                          render: ({ item: { item: { rateContract } } }) => (
                            <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color2 }}>
                              {rateContract[0]?.manufacturer?.name}
                            </Text>
                          ),
                          editable: false,
                          width: 150,
                        },
                        {
                          type: 'text',
                          header: 'asset.labels.supplier'.getLabel(),
                          label: 'asset.labels.supplier'.getLabel(),
                          variant: 'filled',
                          render: ({ item: { item: { rateContract } } }) => (
                            <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color2 }}>
                              {rateContract[0]?.supplier?.name}
                            </Text>
                          ),
                          editable: false,
                          width: 150,
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default ViewIndentRecord;
