import React from 'react';

import {
  validateInputLength,
  validateMultipleFileTypesAllowed,
} from '../../../../../../../../Lib/helpers';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import ListActionModal from '../../../../../../../../Components/ListActionModal';

const UploadMLCSampleScreen = (props) => (
  <ListActionModal
    {...props}
    title="MLC Sample"
    description="Log MLC Sample of the patient."
    content={(
      <Form
        {...props}

        closeView={1}
        type="standardShadow"
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Save',
            }),
          ],
        }}
        reloadEvent={`reload${MODELS.IPD_MLC_SAMPLES}`}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            ipdPatient: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.IPD_MLC_SAMPLES,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        mandatory={{
          sampleDate: 1,
          sampleName: 1,
          sampleSite: 1,
        }}
        validations={{
          sampleName: ({ data }) => validateInputLength(data?.sampleName, 1, 50),
          sampleSite: ({ data }) => validateInputLength(data?.sampleSite, 1, 50),
          sampleImage: ({ data }) => validateMultipleFileTypesAllowed(data?.sampleImage, ['jpeg', 'png']),
        }}
        defaultValues={() => ({
          sampleDate: new Date(),
        })}
        submitMessage="MLC Sample had been saved successfully."
        lg={{
          formGroups: [
            {
              fieldVariant: 'filled',
              direction: 'row',
              groups: [
                {
                  columnsPerRow: 2,
                  columns: [
                    {
                      type: 'date',
                      field: 'sampleDate',
                      label: 'Date',
                      formte: 'DD/MMM/YYYY',
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'sampleName',
                      label: 'Sample Name',
                      allowWhiteSpace: true,
                      editable: true,
                    },
                    {
                      type: 'text',
                      field: 'sampleSite',
                      label: 'Sample Site',
                      allowWhiteSpace: true,
                      editable: true,
                    },
                    {
                      type: 'file',
                      field: 'sampleImage',
                      label: 'UPLOAD SAMPLE IMAGE',
                      editable: true,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
      )}
  />
);
export default UploadMLCSampleScreen;
