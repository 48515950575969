import { MODELS } from '../Lib/constants';

export const categoryRoomsList = () => ({
  query: {
    model: MODELS.ROOM_CATEGORY,
    id: 'roomCategoryList',
  },
  model: MODELS.ROOM_CATEGORY,
});
export const editRoomCategory = ({ navigation }) => ({
  query: {
    model: MODELS.ROOM_CATEGORY,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.ROOM_CATEGORY,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
  },

  model: MODELS.ROOM_CATEGORY,
});

export const roomsCategoriesListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'masterDataList',
    addOnFilter: filter,
  },
  model: MODELS.ROOM_CATEGORY,
});

export const viewCategoriesListUri = ({ navigation }) => ({
  query: {
    model: MODELS.ROOM_CATEGORY,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.ROOM_CATEGORY,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ROOM_CATEGORY,
});
