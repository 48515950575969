const UserLOPDrillAttendanceFields = {
  _id: 1,
  attendance_date: 1,
  in_time: 1,
  progress_hours: 1,
  out_time: 1,
  second_attendance_type_id: { _id: 1, name: 1, code: 1 },
  first_attendance_type_id: { _id: 1, name: 1, code: 1 },
  approved_status: 1,
  time_in_office: 1,
  break_time: 1,
  remarks: 1,
  punch_status_id: 1,
  is_short_leave: 1,
  is_approved: 1,
  consider_short_leave: 1,
  was_manual_update: 1,
  punch_data: 1,
  status: 1,
  call_from: 1,
  leave_type_id: { _id: 1, name: 1, code: 1 },
  employee: {
    _id: 1,
  },
};

export const userLopSheetUri = ({ user }) => ({
  model: 'MonthlyAttendance',
  query: {
    id: 'lopSheetmonthlyAttendanceForSelfQuery',
    sort: {
      _id: -1,
    },
    addOnFilter: {
      employee: {
        _id: user?.employee?._id || '1234',
      },
    },
  },
});

export const UserLOPDrillUri = ({ addOnFilter, fields = {} }) => ({
  query: {
    id: 'attendances',
    fields: {
      ...UserLOPDrillAttendanceFields,
      ...fields,
    },
    sort: {
      _id: -1,
    },
    addOnFilter,
  },
  model: 'attendance',
});
