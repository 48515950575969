import React, {
  useCallback,
  // useState,
} from 'react';

import {
  // ActivityIndicator,
  // Toast,
  View,
} from '../../../../../app-components';
import { TableActionButton } from '../../../../../app-components/buttons/Buttons';
// import { MODELS } from '../../../../../Lib/constants';
// import { post } from '../../../../../AppServices';
// import vars from '../../../../../theme/vars';

const Actions = (props) => {
  const {
    item = {},
    // eventDispatcher,
    showPatientDetails,
    // isPatientDetailsVisible,
    patientDetails,
  } = props;

  // const [loading, setLoading] = useState(false);

  const {
    // status,
    _id,
  } = item;

  // const updateConsultationStatus = useCallback(async (update) => {
  //   setLoading(true);
  //   const fetchedConsultationDetails = await post({
  //     data: { _id },
  //     updates: { status: update },
  //     model: MODELS.CONSULTATION,
  //   });
  //   Toast.show({
  //     message: 'Success',
  //     description: 'incomingPatient.messages.consultationUpdatedSuccessfully'.getLabel(),
  //     type: 'success',
  //     position: 'top',
  //     direction: 'right',
  //     duration: 3000,
  //   });
  //   setLoading(false);
  //   eventDispatcher.notify('reloadConsultedPatients');

  //   return fetchedConsultationDetails;
  // }, [_id, eventDispatcher]);

  // const handleOnPressConsulted = useCallback(() => {
  //   updateConsultationStatus('completed');
  // }, [updateConsultationStatus]);

  // const handleOnPressNotAppeared = useCallback(() => {
  //   updateConsultationStatus('not-appeared');
  // }, [updateConsultationStatus]);

  const handleOnPressViewDetails = useCallback(() => {
    showPatientDetails(item);
  }, [item, showPatientDetails]);

  // if (loading) return <ActivityIndicator />;

  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      {_id !== patientDetails?._id && (
        <TableActionButton
          label={'incomingPatient.labels.viewDetails'.getLabel()}
          onPress={handleOnPressViewDetails}
        />
      )}
      {/* {status === 'assigned' && (
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: 10,
            }}
          />
          <TableActionButton
            label="Consulted"
            color={vars.colors.primary.color2}
            hoverColor={vars.colors.primary.color2}
            onPress={handleOnPressConsulted}
            labelStyle={{
              ...vars.headings.h7,
              color: vars.colors.white,
              numberOfLines: 1,
            }}
          />
          <View
            style={{
              width: 10,
            }}
          />
          <TableActionButton
            label="Not Appeared"
            color={vars.colors.secondary.color4}
            borderWidth={1}
            borderColor={vars.colors.error}
            onPress={handleOnPressNotAppeared}
            width="auto"
            labelStyle={{
              ...vars.headings.h7,
              color: vars.colors.error,
              numberOfLines: 1,
            }}
          />
        </View>
      )} */}
    </View>
  );
};

export default Actions;
