import React from 'react';

import {
  List, TabNavigator, OutlineButton,
} from '../../../app-components';
import * as Layouts from './Layouts';
import { userLeaveLopSheetUri, UserLOPDrillUri } from './Uri';

const {
  AvailableLayout,
  ConsumedLayout,
  BalanceLayout,
  AttendanceDateLayout,
  FirstHalfLayout,
  SecondHalfLayout,
  StatusLayout,
  leaveNameLayout,
  OpeningLayout,
} = Layouts;

export const userLeaveLopSheet = List({
  hoverable: false,
  listVersion: 2,
  uri: ({ navigation }) => {
    const { user, item: { month_id: { _id: monthId } } = {} } = navigation.getParams('Attendance');

    return userLeaveLopSheetUri({
      addOnFilter: {
        employee: {
          _id: user?.employee?._id || '1234',
        },
        month_id: { _id: monthId },
        // $or: [
        // { leaveDetails: { leave_balance: { $gt: 0.5 } } },
        // { leaveDetails: { opening_leave_balance: { $gt: 0.5 } } },
        // ],
      },
      id: 'leaveRegistermonthlyAttendanceForSelfQuery',
    });
  },
  reloadEvent: 'userLeaveLopSheet',
  sm: {
    columns: [leaveNameLayout],
  },
  md: {
    columns: [
      {
        width: 14,
      },
      leaveNameLayout,
      {
        ...OpeningLayout,
        width: 200,
      },
      {
        ...AvailableLayout,
        width: 200,
      },
      {
        ...ConsumedLayout,
        width: 200,
      },
      {
        ...BalanceLayout,
        width: 200,
      },
    ],
  },
  lg: {
    columns: [
      {
        width: 14,
      },
      leaveNameLayout,
      {
        ...OpeningLayout,
        width: 200,
      },
      {
        ...AvailableLayout,
        width: 200,
      },
      {
        ...ConsumedLayout,
        width: 200,
      },
      {
        ...BalanceLayout,
        width: 200,
      },
    ],
  },
});

export const LeaveregisterDrillList = List({
  listVersion: 2,
  reloadEvent: 'LeaveregisterDrillList',
  uri: ({ user, navigation }) => {
    const { month_last_date: monthLastDate } = navigation?.getParams('Attendance') || {};
    const monthFirstDate = new Date(monthLastDate);
    monthFirstDate.setDate(0);
    const addOnFilter = {
      employee: {
        _id: user && user.employee && user.employee._id,
      },
      attendance_date: {
        $gte: monthFirstDate,
        $lte: monthLastDate,
      },
      $or: [
        {
          second_attendance_type_id: { code: 'leave' },
        },
        {
          first_attendance_type_id: { code: 'leave' },
        },
      ],
    };
    return UserLOPDrillUri({ addOnFilter });
  },
  columns: [
    {
      width: 14,
    },
    AttendanceDateLayout,
    {
      ...FirstHalfLayout,
      width: 120,
    },
    {
      ...SecondHalfLayout,
      width: 120,
    },
    {
      ...StatusLayout,
      width: 120,
    },
  ],
});

export const LeaveregisterDrill = TabNavigator({
  actions: [
    {
      render: <OutlineButton label="New leave request" />,
      type: 'link',
      link: {
        view: 'new-leave-request',
        modal: true,
        modalProps: {
          width: 620,
          height: 365,
        },
      },
    },
  ],
  tabs: {
    self: {
      label: 'Leave Requests',
      screen: LeaveregisterDrillList,
    },
  },
});
