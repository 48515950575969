import React, { useState } from 'react';

import { save, StandardButton } from '../../../../../../../app-components';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { submit } from '../../../../../../../AppServices';
import vars from '../../../../../../../theme/vars';

const summaryTypes = ['Discharge', 'Death', 'Lama', 'Abscond'];

const Summary = (props) => {
  const { navigation } = props;
  const { state: { params: { patient = {} } } } = navigation;
  const [isDone, setIsDone] = useState(false);
  const [isDischarge, SetIsDischarge] = useState(false);
  const [isDeath, SetIsDeath] = useState(false);
  const [isVisible, SetIsVisible] = useState(false);
  return (
    <Form
      type="standardShadow"
      bodyContainerStyle={{ paddingLeft: 14, paddingRight: 14 }}
      closeView={1}
      onSubmit={async (submitProps) => {
        const { updates, data } = submitProps;
        const newUpdate = {
          ...updates,
          admissionId:
          {
            _id:
            submitProps?.navigation?.state?.params?.patient?._id,
          },
        };
        const submitAPI = submit({
          model: MODELS.IPD_SUMMARY,
          data,
          updates: newUpdate,
        });
        await submitAPI({ data, updates: newUpdate });
      }}
      dataSaveSuccessfully
      submitMessage={'asset.messages.dataSaveSuccessfully'.getLabel()}
      computations={{
        self: {
          summary: {
            compute: (data) => {
              if (data?.summary === 'Lama' || data?.summary === 'Abscond') {
                return (
                  setIsDone(true),
                  SetIsDischarge(false),
                  SetIsDeath(false),
                  SetIsVisible(true)
                );
              }
              if (data?.summary === 'Discharge') {
                return (
                  setIsDone(false),
                  SetIsDischarge(true),
                  SetIsVisible(false),
                  SetIsDeath(false)
                );
              }
              if (data?.summary === 'Death') {
                return (
                  SetIsVisible(false),
                  setIsDone(false),
                  SetIsDischarge(false),
                  SetIsDeath(true)
                );
              }
              return {
                isDone,
                isDischarge,
                isDeath,
              };
            },
            onChange: ['summary'],
          },
        },
      }}
      lg={{
        header: isVisible ? {
          actions: [save()],
        } : null,
        formGroups: [
          {
            columnsPerRow: 1,
            fieldVariant: 'filled',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 10,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'radioGroup',
                    field: 'summary',
                    label: 'SUMMARY',
                    container: 'topLabel',
                    options: summaryTypes,
                    editable: true,
                  },
                  {
                    type: 'textArea',
                    field: 'lamaNote',
                    container: 'bottomLabel',
                    allowWhiteSpaces: true,
                    minHeight: 80,
                    label: 'LAMA NOTES',
                    editable: true,
                    visible: () => isDone,
                  },
                  {
                    type: 'custom',
                    render: () => (
                      <StandardButton
                        label="Fill Discharge Card"
                        onClick={() => navigation.push({
                          view: ROUTES.dischargeCardSummary.name,
                          params: { patient, summary: 'Discharge' },

                        })}
                      />
                    ),
                    width: 200,
                    visible: () => isDischarge,
                  },
                  {
                    render: () => (
                      <StandardButton
                        label="Fill Death Card"
                        onClick={() => navigation.push({
                          view: ROUTES.deathCardSummary.name,
                          params: { patient, summary: 'Death' },
                        })}
                      />
                    ),
                    width: 200,
                    visible: () => isDeath,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};
export default Summary;
