import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SelectDate } from '@applane/react-date-input';

import {
  StandardButton,
  View,
  Text,
  TouchableOpacity,
  Image,
} from '../../../../../../../app-components';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import { Table } from '../../../../../../../app-components/table/Table';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import {
  entScreen, progressEnt, progressGc, remark, sleep, vitals,
} from '../../fieldConfiguration';
import { getPermission } from '../../../../../../../AppServices';
import permissions from '../../../../../../../Lib/permissions';
import { editRecord } from '../../../../../../../images';

const ProgressReport = (props) => {
  const [selectedDate, setSelectedDate] = useState();
  const { eventDispatcher } = props || {};
  const { dataParams } = props;
  const tableItems = {
    lg: {
      columns: [
        {
          header: 'S.No.'.getLabel(),
          type: 'number',
          render: (item) => <Text>{item.index + 1}</Text>,
        },
        {
          header: 'Time'.getLabel(),
          type: 'date',
          format: 'HH:mm A',
          field: '_createdOn',
          visible: entScreen[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'GC',
          type: 'text',
          field: 'progressGc',
          visible: progressGc[dataParams?.filters?.specialityFilter],

        },
        {
          header: 'P',
          type: 'text',
          field: 'vitalsP',
          visible: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,

        },
        {
          header: 'RR',
          type: 'text',
          field: 'vitalsRR',
          visible: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
        },
        {
          header: 'Temperature',
          type: 'text',
          field: 'vitalsTemperature',
          visible: vitals[dataParams?.filters?.specialityFilter] ? 1 : 0,
        },
        {
          header: 'Progress',
          type: 'text',
          field: 'progressEnt',
          visible: progressEnt[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'Progression',
          type: 'text',
          field: 'progression',
          visible: sleep[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'Sleep',
          type: 'text',
          field: 'sleep',
          visible: sleep[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'Appetite',
          type: 'text',
          field: 'appetite',
          visible: sleep[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'Remark',
          type: 'text',
          field: 'remark',
          visible: remark[dataParams?.filters?.specialityFilter],
        },
        {
          header: 'Edit'.getLabel(),
          type: 'text',
          visible: () => getPermission(
            permissions.departments.views.ipdEditForms
              .permissions.ipdEditForms.value,
          ),
          render: () => (
            <TouchableOpacity>
              <Image
                style={{
                  height: 24,
                  width: 24,
                }}
                source={editRecord}
              />
            </TouchableOpacity>
          ),
          action: (data) => ({
            type: 'link',
            // visible: () => getPermission(
            //   permissions.departments.views.ipdEditForms
            //     .permissions.ipdEditForms.value,
            // ),
            link: {
              modal: true,
              view: ROUTES.EditProgress.name,
              params: {
                addOnFilter: {
                  _id: data.item._id,
                  dataParams,
                },
                dataParams,
              },

            },
          }),
        },
      ],
    },
  };

  useEffect(() => {
    eventDispatcher.notify(`reload${MODELS.IPD_PROGRESS_REPORT}`);
  }, [selectedDate, eventDispatcher]);
  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      editable={false}
      flex={false}
      scrollable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(item?.navigation?.state?.params?.patient?._createdOn).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate)
                            .isSame(currentDate);
                          const dischargedDate = moment(item?.navigation?.state?.params?.patient?.dateOfDischargeOrDeath).format('YYYY-MM-DD');
                          const dischargeDate = item?.navigation?.state?.params?.patient?.dateOfDischargeOrDeath ? moment(dischargedDate)
                            .isSame(currentDate) : false;
                          const highlightedDate = moment(currentDate).isAfter(moment(admissionDate))
                           && moment(currentDate).isBefore(moment(dischargedDate));
                          if (dischargeDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'red', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Discharge
                                </Text>
                              </View>
                            );
                          }
                          if (admittedDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'green', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View style={{ backgroundColor: '#EFF3FB', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View style={{ backgroundColor: '#4070C9', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          return (
                            <View>
                              {date}
                            </View>
                          );
                        }}
                      />
                    ),
                  },
                  {
                    render: () => {
                      const tableProps = {
                        ...props,
                        ...tableItems,
                      };
                      return (
                        <Table
                          {...tableProps}
                          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
                          uri={(item) => ({
                            query: {
                              id: 'IPDProgressList',
                              addOnFilter: {
                                admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
                                // treatment: { addMedicineFromTreatment: false },
                                speciality: props.dataParams?.filters?.specialityFilter,
                                progressDate: {
                                  $eq: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(0),
                                        ).setUTCMinutes(0),
                                      ).setUTCSeconds(0),
                                    ).setUTCMilliseconds(0),
                                  ),
                                },
                              },
                            },
                            model: MODELS.IPD_PROGRESS_REPORT,
                          })}
                          header={() => ({
                            title: () => (
                              <HeaderTitleWithCount
                                label={`Progress Report:  ${moment(selectedDate).format('DD MMM YYYY')}`}
                              />
                            ),
                            actions: [
                              {
                                type: 'link',
                                link: ({ navigation: { state: { params = {} } } }) => ({
                                  view: !(props.dataParams.filters.specialityFilter === 'General Medicine')
                                    ? ROUTES.AddProgress.name
                                    : ROUTES.addProgressReportGeneralMedicine.name,
                                  params: { ...params, dataParams },
                                  push: true,
                                  modal: true,
                                  modalProps: {
                                    autoHide: true,
                                    maxHeight: '100%',
                                    width: 1196,
                                    height: 547,
                                  },
                                }),
                                render: () => (
                                  <StandardButton
                                    label="Log Daily Progress"
                                  />
                                ),
                              },
                            ],
                          })}
                          action={{
                            type: 'link',
                            link: (data) => ({
                              view: !(props.dataParams.filters.specialityFilter === 'General Medicine')
                                ? ROUTES.ViewProgressReport.name
                                : ROUTES.viewProgressReportGeneralMedicine.name,
                              modal: true,
                              params: {
                                addOnFilter: {
                                  _id: data.item._id,
                                  dataParams,
                                },
                              },
                              modalProps: {
                                autoHide: true,
                                maxHeight: '100%',
                                width: 1196,
                                height: 547,
                              },

                            }),
                          }}
                        />
                      );
                    },
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default ProgressReport;

