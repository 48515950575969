import React from 'react';

import { Image, Text, TouchableOpacity } from '../../../../../../../../app-components';
import { Table } from '../../../../../../../../app-components/table/Table';
import WithPermission from '../../../../../../../../Components/WithPermission';
import { editRecord } from '../../../../../../../../images';
import { MODELS, ROUTES } from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';

const tabListColumns = [
  {
    type: 'selection',
  },
  {
    header: 'asset.headers.serialNo'.getLabel(),
    type: 'text',
    field: 'serialNumber.',
    width: 100,

  },
  {
    header: 'asset.headers.value'.getLabel(),
    type: 'text',
    field: 'purchasedPrice',
    width: 100,
  },
  {
    header: 'asset.headers.stockEntryDate'.getLabel(),
    type: 'date',
    field: 'batchNumber.stockEntryDate',
    width: 150,
  },
  {
    header: 'asset.headers.manufacturerName'.getLabel(),
    type: 'text',
    field: 'batchNumber.manufacturer.name',
    width: 150,
  },
  {
    header: 'asset.headers.lastRepairDate'.getLabel(),
    type: 'date',
    field: 'lastRepairDate',
    width: 150,
  },
  {
    header: 'asset.headers.lastLoanOutDate'.getLabel(),
    type: 'date',
    field: 'loanedOutDate',
    width: 150,
  },
  {
    header: 'asset.headers.lastLoanOutDept'.getLabel(),
    type: 'text',
    field: 'department.name',
    width: 150,
  },
  {
    header: 'asset.headers.speciality'.getLabel(),
    type: 'text',
    field: 'speciality.name',
    width: 100,
  },
  {
    header: 'asset.headers.stockStatus'.getLabel(),
    type: 'text',
    width: 120,
    render: ({ item: { assetStatus, isAMC } = {} }) => {
      let stocksStatus = assetStatus.toString();
      let statusColor = vars.colors.warning;
      if (stocksStatus === 'In-Storage') {
        statusColor = vars.colors.primary.color2;
      } else if (stocksStatus === 'In-Service') {
        stocksStatus = `In-Service (${isAMC})`;
      } else if (stocksStatus === 'inAuction') {
        stocksStatus = 'In-Auction';
      }
      return (
        <Text style={{ ...vars.headings.h11, color: statusColor }}>
          {stocksStatus}
        </Text>
      );
    },
    field: 'assetStatus',
  },
  {
    header: 'Actions',
    type: 'text',
    render: () => (
      <WithPermission>
        <TouchableOpacity>
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        </TouchableOpacity>
      </WithPermission>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editInventoryAssetItem.name,
        params: { item },
      },
    }),
  },
];

const ItemWiseStockList = (props) => {
  const {
    data, id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;

  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter: { ...addOnFilter, assetStatus: { $ne: 'inAuction' } },
        },
        model,
      })}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent={`reload${MODELS.ASSET}`}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.centralAssetItemDetail.name,
          params: { item },
        },
      })}
      {...restProps}
    />
  );
};
export default ItemWiseStockList;
