import React from 'react';

import { FilterGroup, Image, Table } from '../../../app-components';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import { editRecord, filterActive, filterInactive } from '../../../images';
import { ROUTES } from '../../../Lib/constants';

const smCard = {
  primaryTitleField: 'name',
  defaultItemText: 'N/A',
  contentSeparator: true,
  contentItems: [
    [
      {
        type: 'text',
        header: 'account.headers.parent'.getLabel(),
        field: 'parent_account_id.name',
      },
      {
        type: 'autoSuggestOptions',
        header: 'account.headers.type'.getLabel(),
        options: [
          { label: 'Revenue', value: 'revenue' },
          { label: 'Asset', value: 'assert' },
          { label: 'Liability', value: 'liability' },
          { label: 'Expense', value: 'expense' },
        ],
        valueField: 'value',
        suggestionField: 'label',
        field: 'account_group_type',
      },
      {
        type: 'text',
        header: 'account.headers.action'.getLabel(),
        render: () => (
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        ),
        action: () => ({
          type: 'link',
          link: () => ({
            view: [ROUTES.editAccountMaster.name],
            routeOptions: {
              expanded: true,
            },
          }),
        }),
      },
    ],
  ],
};

const AccountColumns = {
  lg: {
    columns: [
      {
        type: 'text',
        header: 'account.headers.name'.getLabel(),
        field: 'name',
      },
      {
        type: 'text',
        header: 'account.headers.parent'.getLabel(),
        field: 'parent_account_id.name',
      },
      {
        type: 'autoSuggestOptions',
        header: 'account.headers.type'.getLabel(),
        options: [
          { label: 'Revenue', value: 'revenue' },
          { label: 'Asset', value: 'assert' },
          { label: 'Liability', value: 'liability' },
          { label: 'Expense', value: 'expense' },
        ],
        valueField: 'value',
        suggestionField: 'label',
        field: 'account_group_type',
      },
      {
        type: 'text',
        header: 'account.headers.action'.getLabel(),
        render: () => (
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        ),
        action: () => ({
          type: 'link',
          link: ({ item }) => ({
            view: [ROUTES.editAccountMaster.name],
            routeOptions: {
              expanded: true,
            },
            params: {
              ...item,
            },
          }),
        }),
      },
    ],
  },
  md: {
    columns: [
      {
        type: 'text',
        header: 'account.headers.name'.getLabel(),
        field: 'name',
      },
      {
        type: 'text',
        header: 'account.headers.parent'.getLabel(),
        field: 'parent_account_id.name',
      },
      {
        type: 'autoSuggestOptions',
        header: 'account.headers.type'.getLabel(),
        options: [
          { label: 'Revenue', value: 'revenue' },
          { label: 'Asset', value: 'assert' },
          { label: 'Liability', value: 'liability' },
          { label: 'Expense', value: 'expense' },
        ],
        valueField: 'value',
        suggestionField: 'label',
        field: 'account_group_type',
      },
      {
        type: 'text',
        header: 'Actions',
        render: () => (
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        ),
        action: () => ({
          type: 'link',
          link: ({ item }) => ({
            view: [ROUTES.editAccountMaster.name],
            routeOptions: {
              expanded: true,
            },
            params: {
              ...item,
            },
          }),
        }),
      },
    ],
  },

  sm: {
    card: {
      ...smCard,
    },
  },
};

export const AccountsListQuery = () => ({
  query: {
    id: 'accounts',
  },
  model: 'Account',
});

export const AccountsCount = () => ({
  query: {
    id: 'accounts',
  },
  model: 'Account',
  fetchCount: true,
});

export const AccountList = Table({
  header: {
    title: 'Accounts',
    actions: [
      (props) => <FtsSearch {...props} action={{ exp: ['name', 'description'] }} />,
      FilterGroup({
        icon: filterInactive,
        activeIcon: filterActive,
        filters: [
          {
            type: 'autoSuggest',
            field: 'account_group_type',
            label: 'Type',
            placeholder: 'Select Type',
            sugestionField: 'label',
            primaryField: 'label',
            valueField: 'value',
            options: [{ label: 'Revenue', value: 'revenue' }, { label: 'Asset', value: 'assert' }, { label: 'Liability', value: 'liability' }, { label: 'Expense', value: 'expense' }],
          },
        ],
      }),
    ],
  },
  action: (props) => {
    const { item } = props;
    return {
      type: 'link',
      link: {
        view: [ROUTES.viewAccountMaster.name],
        params: {
          ...item,
        },
      },
    };
  },
  uri: AccountsListQuery,
  reloadEvent: 'Accounts',
  listVersion: 2,
  ...AccountColumns,
});

