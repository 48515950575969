import React from 'react';

import { Table } from '../../../../../../../../app-components/table/Table';
import { GetOTListUri } from '../../../../../../../../Queries/otList';
import {
  StandardButton,
  Text,
  View,
  TouchableOpacity,
  Image,
} from '../../../../../../../../app-components';
import {
  breadCrumbBack,
  breadcrumbSeperator,
} from '../../../../../../../../images';
import {
  MODELS,
  ROUTES,
} from '../../../../../../../../Lib/constants';
import vars from '../../../../../../../../theme/vars';

const tabListColumns = () => ([
  {
    header: 'Patient UHID',
    type: 'text',
    field: 'patientUHID.uhid',
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patientName',
  },
  {
    header: 'OT Number',
    type: 'number',
    field: 'otNumber',
  },
  {
    header: 'Type Of Operation',
    type: 'text',
    field: 'operationType',
  },
  {
    header: 'Name Of Surgeon',
    type: 'text',
    field: 'surgeonName.name',
  },
  {
    header: 'ACTIONS',
    type: 'openActions',
    openActions: [{
      render: () => (
        <Text style={{ color: vars.colors.secondary.color2 }}>Remove</Text>
      ),
      type: 'link',
    },
    ],
  },
]);

const NewOtList = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;

  return (
    <Table
      header={() => ({
        leftAction: {
          render: () => (
            <View
              style={{
                height: 64,
                flexDirection: 'row',
                margin: -23,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.replace({
                    view: ROUTES.viewOTListScreen.name,
                  });
                }}
              >
                <Image
                  source={breadCrumbBack}
                  style={{
                    margin: '16px',
                  }}
                />
              </TouchableOpacity>
              <Text
                style={
                  {
                    margin: '12px 0px 0px 3px',
                    textAlign: 'center',
                    color: '#307343',
                  }
                }
              >
                OT List
                <Image
                  source={breadcrumbSeperator}
                  style={{ margin: 10 }}
                />
              </Text>
              <Text
                style={{
                  textAlign: 'center',
                  padding: '13px 0px 0px 0px',
                  fontSize: '18px',
                  color: '#333333',
                }}
              >
                New OT List
              </Text>
            </View>
          ),
        },
        actions: [

          {
            type: 'link',
            style: {
              justifyContent: 'space-between',
            },
            render: () => (
              <StandardButton
                label="Add Patient"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.addPatientToOTList.name,
              params: { ...params },
              push: true,
            }),
          },
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Send for Approval"
              />
            ),
            confirmModalProps: { autoHide: true, minWidth: 436 },
            confirm: {
              title: 'Approval Request!',
              hideCross: false,
              message: () => 'Are sure, wanted to send approval request.',
            },
          },
        ],
      })}
      reloadEvent={`reload${MODELS.IPD_OT_LIST}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={GetOTListUri}
    />
  );
};
export default NewOtList;
