import React from 'react';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import IndentRequestList from './IndentRequests/IndentRequestList';
import RequestList from './RaisedPurchasesRequests/RequestList';
import { DateFilter } from '../../../../../app-components';
import OtherRequestsTabs from './OtherRequests';
import vars from '../../../../../theme/vars';
import tabTheme from '../childTabTheme';

const AssetCentralInventoryRequestsTabs = (props) => (
  <TabNavigator
    {...props}
    theme={tabTheme}
    actions={[
      <DateFilter
        field="_createdOn"
        key="_createdOn"
        containerStyle={{
          flexDirection: 'row',
          width: 303,
          height: 30,
          borderWidth: 1,
          padding: 9,
          marginTop: 7,
          marginRight: 40,
          borderRadius: 4,
          borderStyle: 'solid',
          backgroundColor: vars.colors.white,
          borderColor: vars.colors.grey.color6,
        }}
      />,
    ]}
    tabs={{
      RaisedPurchaseRequests: {
        label: 'Raised Purchase Requests',
        screen: RequestList,
      },
      IndentRequests: {
        label: 'Indent Requests',
        screen: IndentRequestList,
      },
      Others: {
        label: 'Others',
        screen: OtherRequestsTabs,
      },
    }}
  />
);
export default AssetCentralInventoryRequestsTabs;
