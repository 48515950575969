import { autoSuggestInput } from './autoSuggestions';
import { manazeV6Colors } from './theme/manazeV6Colors';
import vars from './theme/vars';

const { primary_2: primiary2 } = manazeV6Colors;

export const currencyInput = (props) => {
  const { amount, currency, ...restProps } = props;
  return {
    type: 'currency',
    fieldType: 'composite',
    fields: [
      {
        flex: 1,
        type: 'number',
        ...amount,
      },
      autoSuggestInput({
        flex: 0.5,
        minWidth: 85,
        maxWidth: 150,
        options: ['INR'],
        placeholder: '',
        skipRemoveValue: true,
        textColor: primiary2,
        ...currency,
      }),
    ],
    ...restProps,
  };
};

export const ageInput = (props) => {
  const { age, ageType, ...restProps } = props;
  return {
    type: 'currency',
    fieldType: 'composite',
    fields: [
      {
        flex: 1,
        type: 'number',
        ...age,
      },
      autoSuggestInput({
        flex: 0.5,
        minWidth: 85,
        maxWidth: 150,
        options: ['Years', 'Months', 'Days'],
        placeholder: '',
        skipRemoveValue: true,
        textColor: vars.colors.grey.color1,
        dropdownStyle: {
          width: 125,
          height: 270,
          backgroundColor: vars.colors.white,
        },
        ...ageType,
      }),
    ],
    ...restProps,
  };
};

export const dateInput = {
  type: 'currency',
  fieldType: 'composite',
  fields: [
    {
      flex: 1,
      type: 'number',
      field: 'hour',
      label: 'Hour',
      mandatory: true,
      allowedDecimalDigits: false,
      modifyValueOnChange: ({ prevValue, value }) => {
        if (value > 11) {
          return prevValue;
        }
        return value;
      },
    },
    {
      flex: 1,
      type: 'number',
      label: 'Minute',
      field: 'minute',
      mandatory: true,
      allowedDecimalDigits: false,
      modifyValueOnChange: ({ prevValue, value }) => {
        if (value > 59) {
          return prevValue;
        }
        return value;
      },
    },
    autoSuggestInput({
      flex: 0.5,
      minWidth: 85,
      maxWidth: 100,
      options: ['AM', 'PM'],
      placeholder: '',
      skipRemoveValue: true,
      textColor: vars.colors.grey.color1,
      dropdownStyle: {
        width: 125,
        height: 270,
        backgroundColor: vars.colors.white,
      },
      field: 'meridiem',
    }),
  ],
};

export const newDateInput = (props) => {
  const {
    hour,
    minute,
    meridiem,
  } = props;

  return ({
    variant: 'filled',
    type: 'currency',
    fieldType: 'composite',
    fields: [
      {
        flex: 1,
        type: 'number',
        ...hour,
        allowedDecimalDigits: false,
        modifyValueOnChange: ({ prevValue, value }) => {
          if (value > 11) {
            return prevValue;
          }
          return value;
        },
      },
      {
        flex: 1,
        type: 'number',
        ...minute,
        allowedDecimalDigits: false,
        modifyValueOnChange: ({ prevValue, value }) => {
          if (value > 59) {
            return prevValue;
          }
          return value;
        },
      },
      autoSuggestInput({
        flex: 0.5,
        minWidth: 85,
        maxWidth: 100,
        options: ['AM', 'PM'],
        // skipRemoveValue: true,
        textColor: vars.colors.grey.color1,
        dropdownStyle: {
          width: 100,
          height: 80,
          backgroundColor: vars.colors.white,
        },
        ...meridiem,
      }),
    ],
  });
};

