import { ROUTES } from '../../../../../../Lib/constants';
import AddProgress from './ProgressReport/AddProgress';
import ViewProgressReport from './ProgressReport/ViewReport';

const neurologyScreens = {

  [ROUTES.addProgressNeurology.name]: {
    screen: AddProgress,
    expanded: true,
  },
  [ROUTES.viewProgressNeurology.name]: {
    screen: ViewProgressReport,
    expanded: true,
  },
};

export default neurologyScreens;
