/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { upperFirst } from 'lodash';

import {
  Text,
} from '../../../../../../../app-components';
import { Headline6 } from '../../../../../../../theme/Headlines';
import { searchInput, autoSuggestInput } from '../../../../../../../autoSuggestions';
import {
  NestedTable,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { manazeV6Colors } from '../../../../../../../theme/manazeV6Colors';
import {
  greenFileIcon,
} from '../../../../../../../images';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const {
  neutral_2,
  positive_communication_2,
  negative_communication_2,
} = manazeV6Colors;

export const LinkTypeAction = ({ view, params }) => ({
  type: 'link',
  link: {
    view,
    params,
  },
});

const StatusPerSavingHead = ({ data }) => {
  const { status } = data;

  const getStylesPerStatus = useMemo(() => {
    if (status === 'rejected') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.error,
      };
    } if (status === 'approved') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.primary.color2,
      };
    } if (status === 'requested') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.emphasis,
      };
    } if (status === 'declared' || status === 'pending') {
      return {
        ...vars.headings.h11,
        marginTop: 6,
        color: vars.colors.warning,
      };
    }

    return {};
  }, [status]);
  return (
    <Text
      style={getStylesPerStatus}
    >
      {upperFirst(status)}
    </Text>
  );
};

export const Layouts = {
  Month: {
    header: 'Month',
    field: 'month_id.name',
    type: 'text',
  },
  Year: {
    header: 'Year',
    field: 'year_id.name',
    type: 'text',
  },
  Payable: {
    header: 'Payable',
    field: 'payable_amount',
    value: ({ item }) => (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0,
    type: 'number',
  },
  PayableFromComponentDetail: {
    header: 'Payable',
    field: 'component_details.payable_amount',
    value: ({ item }) => item
      && item.component_details
      && item.component_details.payable_amount
      && item.component_details.payable_amount.toFixed(2),
    type: 'number',
  },
  InvoiceDate: {
    header: 'Date',
    field: 'invoiceDate',
    type: 'date',
    format: 'DD MMM YY',
  },
  GrossAmt: {
    header: 'Add on (INR)',
    textStyle: { color: positive_communication_2, ...Headline6 },
    value: ({ item }) => {
      const amount = (item && item.gross_amount && item.gross_amount.toFixed(2)) || 0;
      return `₹ ${amount}`;
    },
    type: 'text',
  },
  GrossAmtFromComponentDetail: {
    header: 'Gross',
    field: 'component_details.gross_amount',
    value: ({ item }) => item
      && item.component_details
      && item.component_details.gross_amount
      && item.component_details.gross_amount.toFixed(2),
    type: 'number',
  },
  Deduction: {
    header: 'Deductions (INR)',
    textStyle: { color: negative_communication_2, ...Headline6 },
    value: ({ item }) => {
      const grossAmount = (item && item.gross_amount && item.gross_amount.toFixed(2)) || 0;
      const payableAmount = (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0;
      return `₹ ${grossAmount - payableAmount}`;
    },
    type: 'text',
  },
  PayableAmt: {
    header: 'Payable (INR)',
    textStyle: { color: neutral_2, ...Headline6 },
    value: ({ item }) => {
      const amount = (item && item.payable_amount && item.payable_amount.toFixed(2)) || 0;
      return `₹ ${amount}`;
    },
    type: 'text',
  },
  SalaryComponent: {
    header: 'Component',
    field: 'component_details.salary_component_id.name',
    type: 'text',
  },
  VariableAmt: {
    header: 'Amount',
    field: 'variable',
    value: ({ item }) => item && item.variable && item.variable.toFixed(2),
    type: 'number',
  },
  FromDate: {
    type: 'date',
    field: 'from_date',
    header: 'From Date',
    format: 'DD MMM YY',
  },
  ToDate: {
    header: 'To Date',
    field: 'to_date',
    type: 'date',
    format: 'DD MMM YY',
  },
  CTCAmt: {
    header: 'CTC(Per Month)',
    field: 'ctc_amount',
    value: ({ item }) => item && item.ctc_amount && item.ctc_amount.toFixed(2),
    type: 'number',
  },
  FinancialYear: {
    header: 'Financial Year',
    type: 'text',
    value: ({ item }) => `Declaration for FY ${item.finantial_year_id.name}`,
    width: 230,
  },
  FinancialYearNo: {
    header: 'Financial Year',
    type: 'text',
    value: ({ item }) => `${item.finantial_year_id.name}`,
    width: 150,
  },
  employee: {
    header: 'Employee',
    type: 'text',
    value: ({ item }) => `${`${item?.employee_id?.firstName} ${item?.employee_id?.lastName}`}`,
    width: 150,
  },
  TaxCalculationDate: {
    type: 'date',
    align: 'right',
    field: 'tax_calculation_date',
    header: 'DECLARED ON',
    format: 'DD MMM YY',
    minWidth: 140,
  },
  NetTaxableIncome: {
    type: 'text',
    align: 'right',
    field: 'net_taxable_income',
    value: ({ item }) => item && item.net_taxable_income && item.net_taxable_income.toFixed(2),
    header: 'TAXABLE INCOME',
    minWidth: 150,
  },
  Exempted: {
    type: 'text',
    align: 'right',
    field: '',
    header: 'EXEMPTED',
  },
  TotalTaxAmt: {
    type: 'text',
    align: 'right',
    field: 'total_tax_amount',
    value: ({ item }) => item && item.total_tax_amount && item.total_tax_amount.toFixed(2),
    header: 'TOTAL TAX',
    minWidth: 150,
  },
  TaxPaid: {
    type: 'text',
    align: 'right',
    field: 'tax_already_paid',
    header: 'TAX PAID',
    minWidth: 150,
  },
  PendingTax: {
    type: 'text',
    align: 'right',
    header: 'PENDING TAX',
    value: ({ item }) => {
      const totalTax = (item && item.total_tax_amount) || 0;
      const taxPaid = (item && item.tax_already_paid) || 0;
      return (totalTax - taxPaid).toFixed(2);
    },
    minWidth: 150,
  },
  IsFreeze: {
    type: 'text',
    field: 'is_freezed',
    value: ({ item }) => (item && item.is_freezed ? 'Yes' : 'No'),
    header: 'IS FREEZED',
  },
  EmployeeStatus: {
    type: 'text',
    field: 'employee_status',
    align: 'right',
    header: 'STATUS',
  },
};

export const FormLayouts = {
  SalaryComponent: {
    type: 'text',
    header: 'SALARY COMPONENTS',
    field: 'salary_component_id.name',
  },
  GrossAmt: {
    header: 'GROSS',
    type: 'number',
    field: 'gross_amount',
    align: 'right',
  },
  Payable: {
    header: 'PAYABLE',
    type: 'number',
    field: 'payable_amount',
    align: 'right',
  },
  Employee: searchInput({
    // icon:greenFlagIcon,
    suggestionField: 'name',
    query: 'resourceSuggestions',
    searchField: 'name',
    field: 'employee_id',
    model: 'Resource',
    placeholder: 'Employee',
    label: 'Employee',
  }),
  FinancialYear: searchInput({
    suggestionField: 'name',
    query: 'financialYearSuggestions',
    searchField: 'name',
    field: 'finantial_year_id',
    model: 'FinancialYearDBs',
    placeholder: 'Financial Year',
    label: 'Financial Year',
    // icon:greenFlagIcon,
  }),
  TDSMonth: searchInput({
    suggestionField: 'name',
    query: 'MonthTypeSuggestions',
    searchField: 'name',
    field: 'tds_month_id',
    model: 'Month',
    placeholder: 'TDS Month',
    label: 'TDS Month',
    // icon:greenFlagIcon,
  }),
  TDSYear: searchInput({
    suggestionField: 'name',
    query: 'YearTypeSuggestions',
    searchField: 'name',
    field: 'tds_year_id',
    model: 'Year',
    placeholder: 'TDS Year',
    label: 'TDS Year',
    // icon:greenFlagIcon,
  }),
  Document: {
    type: 'file',
    field: 'document',
    icon: greenFileIcon,
    placeholder: 'Document',
    label: 'Upload File',
  },
  Deduction_under_section_chapterVIA: () => ({
    field: 'deduction_under_section_chapterVIA',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  }),
  OtherDeduction: () => ({
    field: 'other_deductions',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  }),
  IncomeFromHouseProperty: () => ({
    field: 'income_from_house_property',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  }),
  Lta: () => ({
    field: 'lta',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  }),
  PreviousEmployerIncome: {
    field: 'previous_employer_income',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'number',
            placeholder: 'Income',
            field: 'income',
            header: 'Income',
            width: 150,
            variant: 'filled',
          },
          {
            type: 'number',
            placeholder: 'TDS Deducted',
            field: 'tds_deducted',
            header: 'TDS Deducted',
            width: 150,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  },
  RentRecipts: () => ({
    field: 'rent_recipts',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'MonthTypeSuggestions',
            searchField: 'name',
            field: 'month',
            model: 'Month',
            placeholder: 'Month',
            header: 'Month',
            variant: 'filled',
            disabled: true,
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  }),
  HigherEducationLoan: {
    field: 'income_on_higher_education_loan',
    fieldType: 'nested',
    Component: NestedTable,
    addInBottom: true,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
            variant: 'filled',
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
            variant: 'filled',
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  },
  Donations: {
    field: 'donations',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'Amount',
            width: 120,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  },
  FlexiComponentDetails: {
    field: 'flexi_component_details',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        lg: {
          columnsPerRow: 5,
          columns: [
            autoSuggestInput(
              {
                suggestionField: 'name',
                model: MODELS.SALARY_COMPONENTS,
                query: 'salaryComponent',
                searchField: 'name',
                field: 'salary_component_id',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                valueField: 'name',
                primaryField: 'name',
                disabled: true,
                width: 200,
                variant: 'filled',
              },
            ),
            {
              type: 'number',
              placeholder: 'January',
              field: 'jan_amount',
              header: 'January',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'February',
              field: 'feb_amount',
              header: 'February',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'March',
              field: 'mar_amount',
              header: 'March',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'April',
              field: 'apr_amount',
              header: 'April',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'May',
              field: 'may_amount',
              header: 'May',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'June',
              field: 'jun_amount',
              header: 'June',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'July',
              field: 'jul_amount',
              header: 'July',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'August',
              field: 'aug_amount',
              header: 'August',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'September',
              field: 'sep_amount',
              header: 'September',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'October',
              field: 'oct_amount',
              header: 'October',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'November',
              field: 'nov_amount',
              header: 'November',
              variant: 'filled',
            },
            {
              type: 'number',
              placeholder: 'December',
              field: 'dec_amount',
              header: 'December',
              variant: 'filled',
            },
            {
              header: 'Status',
              type: 'custom',
              width: 120,
              render: (props) => <StatusPerSavingHead {...props} />,
            },
            {
              header: 'Supporting Document',
              type: 'file',
              field: 'supporting_document',
              placeholder: 'Document',
              label: 'Supporting Document',
              variant: 'filled',
              width: 240,
              multiple: true,
            },
          ],
        },
      },
    },
  },
  Nps: {
    field: 'nps',
    fieldType: 'nested',
    addInBottom: true,
    Component: NestedTable,
    componentProps: {
      skipTableHeaderOnNoData: true,
      listProps: {
        columns: [
          {
            type: 'checkBoxGroup',
            field: 'checkBoxGroupInput',
            header: ' ',
            options: [{ value: '1' }],
            width: 30,
            style: { marginTop: 10 },
          },
          searchInput({
            suggestionField: 'name',
            query: 'savingHeadSuggestions',
            searchField: 'name',
            field: 'saving_heads',
            model: MODELS.SAVING_HEAD,
            placeholder: 'Saving Head',
            header: 'Saving Head',
          }),
          {
            type: 'number',
            placeholder: 'Amount',
            field: 'declared_amount',
            header: 'amount',
            width: 120,
          },
          {
            type: 'number',
            placeholder: 'Actual Amount',
            field: 'amount',
            header: 'Actual Amount',
            width: 150,
          },
          {
            header: 'Status',
            type: 'custom',
            width: 120,
            render: (props) => <StatusPerSavingHead {...props} />,
          },
          {
            header: 'Supporting Document',
            type: 'file',
            field: 'supporting_document',
            placeholder: 'Document',
            label: 'Supporting Document',
            variant: 'filled',
            multiple: true,
          },
        ],
      },
    },
  },
};
