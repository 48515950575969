import React from 'react';

import { View, Text } from '../../app-components';
import vars from '../../theme/vars';
import theme from './theme';

const StatusCell = (props = {}) => {
  const {
    value, label, containerStyles,
  } = props;
  return (
    <View style={[theme.container]}>
      {label && (
      <Text style={[
        vars.headings.h16,
        { color: vars.colors.grey.color3 },
      ]}
      >
        {label}
      </Text>
      )}
      <View style={[
        {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        },
        containerStyles,
      ]}
      >
        <Text style={theme.text}>{value ? 'Active' : 'In-active'}</Text>
        <View style={value ? theme.dotIndicatorActive : theme.dotIndicatorInActive} />
      </View>
    </View>
  );
};

export default StatusCell;
