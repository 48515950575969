/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import {
  cancelButton, clearSelection, FilterGroup,
  LargeButton, OutlineButton, save, Toast,
  selectAll, showSelectedCount, StandardButton, View, Text,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import WithPermission from '../../../Components/WithPermission';
import { getErrorString } from '../../../Lib/helpers';
import {
  MODELS, assetInventoryStockRequestStatus, ROUTES, purchaseSupplyOrderStatus, assetStatus,
} from '../../../Lib/constants';
import { filterActive, filterInactive } from '../../../images';
import { getUser, invoke } from '../../../AppServices';
import vars from '../../../theme/vars';

import loanOut from './CentralInventory/LoanOutAssets';
import RaiseIndent from './CentralInventory/RaiseIndent';
import AddAsset from './CentralInventory/Asset/AddAsset';
import AddStock from './CentralInventory/Stocks/AddStock';
import EditAsset from './CentralInventory/Asset/EditAsset';
import ViewAsset from './CentralInventory/Asset/ViewAsset';
import AssetList from './CentralInventory/Asset/AssetList';
import RaisePurchaseRequestAsset from './CentralInventory/PurchaseRequestAsset';
import TransferRequestModalAsset from './CentralInventory/Stocks/TransferStock';

import ViewRepair from './Requests/CentralInventory/OtherRequests/ViewRepair';
import ViewIndentRequest from './Requests/CentralInventory/IndentRequests/ViewIndentRequest';
import ViewTransferRequests from './Requests/CentralInventory/OtherRequests/ViewTransferRequestList';
import ViewPurchaseRequest from './Requests/CentralInventory/RaisedPurchasesRequests/ViewPurchasesRequest';

import MSIRequestsTabs from './MSI Requests/RequestTabs';
import MSIViewIndentRequest from './MSI Requests/components/ViewIndentRequest';
import MSIViewRepairRequests from './MSI Requests/components/ViewRepairRequest';
import MSIViewTransferRequest from './MSI Requests/components/ViewTransferRequest';

import AssetCentralRecordsTabs from './Records/CentralRecords';
import AssetDepartmentRecordsTabs from './Records/DepartmentRecords';
import ViewLoanOutRecord from './Records/CentralRecords/LoanOutRecords/ViewLoanOutRecords';
import ViewDepartmentIndentRecord from './Records/DepartmentRecords/components/ViewIndentRecords';
import ViewCentralIndentRecord from './Records/CentralRecords/DepartmentRecords/IndentRequests/ViewIndentRequest';
import ViewCentralInventoryPurchaseRecords from './Records/CentralRecords/CentralPurchaseRecords/ViewCentralPurchaseRecords';

import InventoryList from './DepartmentInventory/InventoryList';
import ViewDepartmentInventoryItem from './DepartmentInventory/ViewInventoryItem';
import { Action } from '../../../app-components/action/Action';
import EditAssetItem from './CentralInventory/Asset/ViewAsset/components/EditAssetItem';
import AssetCentralInventoryRequestsTabs from './Requests/CentralInventory';
import AssetDepartmentInventoryRequestsTabs from './Requests/DepartmentInventory';
import RaiseRepairRequest from './DepartmentInventory/RaiseRepairRequest/index';
import permissions from '../../../Lib/permissions';
import ViewTransferRecords from './Records/CentralRecords/OtherRequests/ViewTransferRecordsList';
import ViewRepairRecords from './Records/CentralRecords/OtherRequests/ViewRepair';
import ViewDepartmentIndentRequest from './Requests/DepartmentInventory/RaisedIndentList/ViewIndentRequest';
import AssetItemDetails from './Records/CentralRecords/DepartmentRecords/IndentRequests/AssetItemRecordDetails';
import ViewDepartmentRepairRequests from './Requests/DepartmentInventory/RaiseRepairList/ViewDepartmentRepair';
import AssetItemInchargeList from './InchargeRecords/assetItemList';
import ViewInChargeTabs from './InchargeRecords/ViewInchargeRecords/tabs/index';
import AssetItemSupervisorList from './SupervisorRecords/assetItemList';
import ViewSupervisorTab from './SupervisorRecords/ViewInchargeRecords/tabs/index';
import AddAssetItemRemarks from './InchargeRecords/AssetMonthlyRemarks/addMonthlyRemarks';
import editAssetDetail from '../../../Wired/AssetDetails/EditItem/index.js';
import AddTechnicalModal from './CentralInventory/PurchaseRequestAsset/addTechnicalModal';
import EditTechnicalModal from './CentralInventory/PurchaseRequestAsset/editTechnicalModel';
import EditMonthlyRemarks from './InchargeRecords/AssetMonthlyRemarks/editMonthlyRemarks';
import AddAssetReturnDateModal from '../../../Wired/AssetDetails/RecordReturnDate/index';
import { LoadingButton } from '../../../app-components/buttons/Buttons';
import SendToAuction from '../../../Wired/SendToAuctionModal';
import ViewCentralAssetItemDetail from './CentralInventory/Asset/ViewAsset/components/CentralAssetItemDetail/tabs';
import { ScreenDataConsumer } from '../../../app-components/stack/ScreenDataContext';
import BulkUploadAssetModal from '../../../Wired/AssetBulkUpload';
import BulkUploadConsumablesModal from '../../../Wired/CosumablesBulkUpload';

const headerActionsForAddForm = [cancelButton, save];
const headerActionsForEditForm = [cancelButton, save({ label: 'Update' })];
const getHeaderActionToRoute = (params) => {
  const { route, label, access_key } = params;

  return {
    render: () => (
      <WithPermission access_key={access_key}>
        <StandardButton label={label} />
      </WithPermission>
    ),
    type: 'link',
    link: (props) => ({
      view: route,
      push: true,
      expanded: true,
      params: { ...props?.navigation?.state?.params },
      ...props,
    }),
  };
};

export default {
  [ROUTES.addInventoryAsset.name]: {
    screen: AddAsset,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.bulkAssetUploadModal.name]: { screen: BulkUploadAssetModal },
  [ROUTES.bulkConsumablesUploadModal.name]: { screen: BulkUploadConsumablesModal },

  [ROUTES.editInventoryAsset.name]: {
    screen: EditAsset,
    expanded: true,
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.editInventoryAssetItem.name]: {
    screen: EditAssetItem,
    modal: true,
    modalProps: {
      autoHide: true,
      width: 1178,
      height: 683,
    },
    headerActions: headerActionsForEditForm,
  },
  [ROUTES.assetItemDetail.name]: {
    screen: ViewCentralAssetItemDetail,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.repairItem.permissions.repairItem.value}
                  >
                    <StandardButton
                      label={'asset.buttons.raiseRepair'.getLabel()}
                    />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.repairRequest.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
        }),
      },
    ],
  },
  [ROUTES.centralAssetItemDetail.name]: {
    screen: ViewCentralAssetItemDetail,
    expanded: true,
    headerActions: [
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.sendToAuction.permissions.sendToAuction.value}
                  >
                    <StandardButton
                      label="Send To Auction"
                    />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.sendToAuction.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          modal: true,
        }),
      },
    ],
  },
  [ROUTES.repairRequest.name]: {
    screen: RaiseRepairRequest,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.viewInventoryAsset.name]: {
    screen: ViewAsset,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    headerActions: [
      {
        render: () => (
          <WithPermission access_key={permissions.inventory.views.raisePurchaseRequestAsset.permissions.raisePurchaseRequestAsset.value}>
            <StandardButton label={'asset.buttons.raisePurchaseRequest'.getLabel()} />
          </WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.purchaseRequestAsset.name,
          params: { item: { ...props?.navigation?.state?.params } },
          push: true,
          expanded: true,
        }),
      },
      {
        render: () => (
          <WithPermission access_key={permissions.inventory.views.transferAssetStocks.permissions.transferAssetStocks.value}><StandardButton label={'asset.buttons.transferStock'.getLabel()} /></WithPermission>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.transferRequestAsset.name,
          push: true,
          params: { item: { ...props?.navigation?.state?.params } },
          ...props,
        }),
      },
      {
        render: () => <WithPermission access_key={permissions.inventory.views.loanOutAssetStocks.permissions.loanOutAssetStocks.value}><StandardButton label={'asset.buttons.loanOut'.getLabel()} /></WithPermission>,
        type: 'link',
        link: (props) => ({
          view: ROUTES.loanOutAssets.name,
          params: { item: { ...props?.navigation?.state?.params } },
          push: true,
          expanded: true,
        }),
      },
      getHeaderActionToRoute({
        route: ROUTES.editInventoryAsset.name,
        label: 'asset.buttons.edit'.getLabel(),
        access_key: permissions.inventory.views.editInventoryAsset.permissions.editInventoryAsset.value,
      }),
    ],
  },
  [ROUTES.assetsDepartmentInventory.name]: {
    screen: InventoryList,
    expanded: true,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
    },
    selectableActions: (selectedActionProps) => [
      showSelectedCount(selectedActionProps),
      clearSelection,
      selectAll,
      {
        render: () => (
          <WithPermission access_key={
            permissions.inventory.views.exportToExcel.permissions.exportToExcel.value
          }
          >
            <OutlineButton label={'asset.buttons.exportToExcel'.getLabel()} />
          </WithPermission>
        ),

        file: 'DepartmentInventoryManagement',
        type: 'download',
        columns: [
          {
            field: 'item.name',
            header: 'Item Name',
          },
          {
            field: 'item.category.name',
            header: 'asset.labels.category'.getLabel(),
          },
          {
            field: 'department.name',
            header: 'asset.labels.department'.getLabel(),
          },
          {
            field: 'speciality.name',
            header: 'asset.labels.speciality'.getLabel(),
          },
          {
            field: 'stockAvailable',
            header: 'Stock Available',
          },
          {
            field: 'item.subCategory.name',
            header: 'Sub-Category',
          },
          {
            header: 'Reorder Level',
            field: 'item.reorderLevel',
          },
          {
            field: 'item.description',
            header: 'asset.labels.description'.getLabel(),
          },
          {
            field: 'item.sellingPrice',
            header: 'Selling Price',
          },
        ],
        confirmModalProps: { autoHide: true, minWidth: 436 },
        confirm: {
          title: 'Export Records!',
          hideCross: false,
          message: (props) => {
            const { screenState: { selectedIds } } = props;
            return `This action will export ${selectedIds?.length} selected records in the excel format.`;
          },
          actions: ({ action }) => [
            { text: 'Cancel' },
            {
              text: 'Export All',
              ...action,
              allPageSelected: true,
            },
            {
              text: 'Yes',
              ...action,
            },
          ],
        },
      },

    ],
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'asset.title.departmentInventory'.getLabel()}
        />
      ),
      actions: [
        (props) => (
          <FtsSearch
            {...props}
            action={{ exp: ['item.name', 'item.category.name', 'item.subCategory.name'] }}
          />
        ),
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'item.category',
              label: 'Category',
              model: MODELS.PHARMACY_CATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Category',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
            },
            {
              type: 'autoSuggest',
              field: 'item.subCategory',
              label: 'Sub-Category',
              model: MODELS.PHARMACY_SUBCATEGORY,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Sub-Category',
              sugestionField: 'name',
              primaryField: 'name',
              valueField: 'name',
            },
          ],
        }),
      ],
    },
    headerActions: [
      {
        render: () => (
          <WithPermission access_key={permissions.inventory.views.raiseIndentRequestAsset.permissions.raiseIndentRequestAsset.value}>
            {' '}
            <StandardButton label={'asset.buttons.raiseIndent'.getLabel()} />
            {' '}
          </WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.raiseIndentAsset.name,
          push: true,
        }),
      },
    ],
  },
  [ROUTES.raiseIndentAsset.name]: {
    screen: RaiseIndent,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.assetsCentralInventory.name]: {
    screen: AssetList,
    expanded: true,
    screenProps: {
      fetchUriEvent: 'fetchListAggregates',
      header: {
        title: ({ selectionCount }) => (
          <HeaderTitleWithCount
            count={selectionCount}
            label={'asset.title.centralInventory'.getLabel()}
          />
        ),
        selectableActions: (selectedActionProps) => [
          showSelectedCount(selectedActionProps),
          clearSelection,
          selectAll,
          {
            render: () => (
              <WithPermission
                access_key={
                permissions.inventory.views.exportToExcel.permissions.exportToExcel.value
              }
              >
                <OutlineButton label={'asset.buttons.exportToExcel'.getLabel()} />
              </WithPermission>
            ),

            file: 'CentralInventoryManagement',
            type: 'download',
            columns: [
              {
                field: 'name',
                header: 'asset.labels.name'.getLabel(),
              },
              {
                field: 'category.name',
                header: 'asset.labels.category'.getLabel(),
              },
              {
                field: 'subCategory.name',
                header: 'asset.labels.subCategory'.getLabel(),
              },
              {
                field: 'description',
                header: 'asset.labels.description'.getLabel(),
              },
              {
                field: 'sellingPrice',
                header: 'Selling Price',
              },
            ],
            download: () => ({
              uri: {
                model: MODELS.ASSET_ITEM,
                query: {
                  id: 'masterDataListForTable',
                },
              },
            }),
            confirmModalProps: { autoHide: true, minWidth: 436 },
            confirm: {
              title: 'Export Records!',
              hideCross: false,
              message: (props) => {
                const { screenState: { selectedIds } = {} } = props;
                return `This action will export ${selectedIds.length} selected records in the excel format.`;
              },
              actions: ({ action }) => [
                { text: 'Cancel' },
                {
                  text: 'Export All',
                  ...action,
                  download: () => ({
                    allPageSelected: true,
                    uri: {
                      model: MODELS.ASSET_ITEM,
                      query: {
                        id: 'masterDataListForTable',
                      },
                    },
                  }),
                },
                {
                  text: 'Yes',
                  ...action,
                },
              ],
            },
          },
        ],
        actions: [
          (props) => (
            <FtsSearch
              {...props}
              action={{ exp: ['name', 'category.name', 'subCategory.name'] }}
            />
          ),
          FilterGroup({
            icon: filterInactive,
            activeIcon: filterActive,
            filters: [
              {
                type: 'autoSuggest',
                field: 'category',
                label: 'Category',
                model: MODELS.PHARMACY_CATEGORY,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select Category',
                sugestionField: 'label',
                primaryField: 'name',
                keyField: 'key',
              },
              {
                type: 'autoSuggest',
                field: 'subCategory',
                label: 'Sub-Category',
                model: MODELS.PHARMACY_SUBCATEGORY,
                id: 'masterDataList',
                searchField: 'name',
                placeholder: 'Select Sub-Category',
                sugestionField: 'name',
                primaryField: 'name',
                valueField: 'name',
              },
            ],
          }),
        ],
      },
    },
    headerActions: [
      {
        render: () => (
          <StandardButton label="Bulk Upload" />
        ),
        type: 'link',
        visible: () => {
          const user = getUser();
          if (user?.username === 'superadmin') {
            return true;
          }
          return false;
        },
        link: () => ({
          view: ROUTES.bulkAssetUploadModal.name,
          modal: true,
          params: {
            fileName: 'Asset_Template.xlsx',
          },
          modalProps: {
            autoHide: true,
            width: 404,
            height: 550,
          },
        }),
      },
      {
        render: () => (
          <WithPermission access_key={permissions.inventory.views.addAssetItem.permissions.addAssetItem.value}><StandardButton label={'asset.buttons.addAsset'.getLabel()} /></WithPermission>
        ),
        type: 'link',
        link: () => ({
          view: ROUTES.addInventoryAsset.name,
          push: true,
        }),
      },
    ],
  },
  [ROUTES.purchaseRequestAsset.name]: {
    screen: RaisePurchaseRequestAsset,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.transferRequestAsset.name]: {
    screen: TransferRequestModalAsset,
    expanded: true,
    headerActions: [
      save({ label: 'asset.buttons.transferStock'.getLabel() }),
    ],
  },
  [ROUTES.loanOutAssets.name]: {
    screen: loanOut,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.assetsCentralRequests.name]: {
    expanded: true,
    screen: AssetCentralInventoryRequestsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'asset.title.assetCentralRequests'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.assetsDepartmentRequests.name]: {
    expanded: true,
    screen: AssetDepartmentInventoryRequestsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'asset.title.assetDeptRequests'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewCentralPurchaseRequestAsset.name]: {
    screen: ViewPurchaseRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item: { purchaseRequestAsset: { purchaseRequestNumber } = {} } = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => {
          const { navigation } = props;
          const { data } = props;

          if (data?.purchaseRequestAsset?.requestStatus === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING) {
            return (
              <Action
                navigation={navigation}
                action={{
                  type: 'link',
                  link: () => ({
                    view: ROUTES.addInventoryAssetStocks.name,
                    params: {
                      item: data?.purchaseRequestAsset?.item,
                      purchaseRequestEntityId: data?._id,
                      quantityReceived: data?.purchaseRequestAsset?.requiredQuantity,
                      purchaseRequestAssetId: data?.purchaseRequestAsset?._id,
                    },
                  }),
                }}
              >
                <WithPermission access_key={permissions.inventory.views.addAssetItem.permissions.addAssetItem.value}>
                  <StandardButton
                    label={'inventory.buttons.addStock'.getLabel()}
                  />
                </WithPermission>
              </Action>
            );
          }
          if (data?.purchaseRequestAsset?.requestStatus === purchaseSupplyOrderStatus.PARTIALLY_RECEIVED) {
            return (
              <Action
                navigation={navigation}
                action={{
                  type: 'link',
                  link: () => ({
                    view: ROUTES.addInventoryAssetStocks.name,
                    params: {
                      item: data?.purchaseRequestAsset?.item,
                      purchaseRequestEntityId: data?._id,
                      quantityReceived: data?.purchaseRequestAsset?.requiredQuantity,
                      purchaseRequestAssetId: data?.purchaseRequestAsset?._id,
                    },
                  }),
                }}
              >
                <WithPermission access_key={permissions.inventory.views.addAssetItem.permissions.addAssetItem.value}>
                  <StandardButton
                    label={'inventory.buttons.provideRemainingStock'.getLabel()}
                  />
                </WithPermission>
              </Action>
            );
          }
          return <View />;
        },
      },
    ],
  },
  [ROUTES.viewIndentRequestAsset.name]: {
    screen: ViewIndentRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => {
          const { navigation, eventDispatcher } = props;
          const { data } = props;
          if (data?.requestStatus === assetInventoryStockRequestStatus.approved) {
            return (
              <LoadingButton
                label={'asset.buttons.provideStock'.getLabel()}
                onPress={async () => {
                  try {
                    const result = await invoke({
                      id: 'handleAssetDepartmentStocksFromCentralInventoryStocks',
                      paramValue: data,
                    });
                    if (result) {
                      Toast.show({
                        message: 'Success',
                        description: 'asset.messages.stockProvidedSuccessfully'.getLabel(),
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                      eventDispatcher.notify(`reload${MODELS.ASSET_INDENT}`);
                      navigation.pop();
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                }}
                {...props}
              />
            );
          }
        },
      },
    ],
  },
  [ROUTES.viewRepairRequest.name]: {
    screen: ViewRepair,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.purchaseRequestNumber}
        </Text>
      );
    },
    headerActions: [
      {
        render: (props) => {
          const { navigation } = props;
          const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
          if (item?.requestStatus === purchaseSupplyOrderStatus.STOCK_ENTRY_PENDING) {
            return (
              <Action
                {...props}
                navigation={navigation}
                action={{
                  type: 'invoke',
                  invoke: async (props) => {
                    try {
                      const { navigation, eventDispatcher } = props;
                      const { state: { params: { item } = {} } } = navigation;
                      const result = await invoke({
                        id: 'changeStatusOnRepairDone',
                        paramValue: item,
                      });
                      if (result) {
                        Toast.show({
                          message: 'Success',
                          description: 'Asset has been repaired successfully.',
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                        navigation.pop();
                        eventDispatcher.notify(`reload${MODELS.PURCHASE_REQUEST}`);
                      }
                    } catch (error) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(error)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  },
                }}
              >
                <WithPermission access_key={permissions.inventory.views.repairItem.permissions.repairItem.value}>
                  <StandardButton
                    label={'asset.buttons.repairDone'.getLabel()}
                  />
                </WithPermission>
              </Action>
            );
          }

          return <View />;
        },
      },
      {
        render: (props) => {
          const { navigation } = props;
          const { state: { params: { item } = {} } = {} } = navigation;
          if (item.requestStatus === purchaseSupplyOrderStatus.RAISED && !item?.isRepairRequestApproveByCentral) {
            return (
              <Action
                {...props}
                navigation={navigation}
                action={{
                  type: 'invoke',
                  invoke: async (item) => {
                    try {
                      const { eventDispatcher } = item;
                      const { state: { params: { item: { _id, status, subType } } } } = navigation;
                      const UpdateRepairStatus = await invoke({
                        id: 'handleRepairRequestRaiseByDepartment',
                        paramValue: {
                          requestStatus: 'approve', _id, status, subType,
                        },
                      });
                      if (UpdateRepairStatus) {
                        Toast.show({
                          message: 'Success',
                          description: 'Request has been approved successfully.',
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                      }
                      navigation.pop();
                      eventDispatcher.notify(`reload${MODELS.PURCHASE_REQUEST}`);
                    } catch (error) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(error)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  },
                }}
              >
                <WithPermission access_key={permissions.inventory.views.repairItem.permissions.repairItem.value}>
                  <StandardButton
                    label="Approve"
                  />
                </WithPermission>
              </Action>
            );
          }
        },
      },
      {
        render: (props) => {
          const { navigation } = props;
          const { state: { params: { item } = {} } = {} } = navigation;
          if (item.requestStatus === purchaseSupplyOrderStatus.RAISED && !item?.isRepairRequestApproveByCentral) {
            return (
              <Action
                {...props}
                navigation={navigation}
                action={{
                  type: 'invoke',
                  invoke: async (item) => {
                    try {
                      const { eventDispatcher } = item;
                      const { state: { params: { item: { _id, status, subType } } } } = navigation;
                      const UpdateRepairStatus = await invoke({
                        id: 'handleRepairRequestRaiseByDepartment',
                        paramValue: {
                          requestStatus: 'rejected', _id, status, subType,
                        },
                      });
                      if (UpdateRepairStatus) {
                        Toast.show({
                          message: 'Success',
                          description: 'Request has been rejected',
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                        navigation.pop();
                        eventDispatcher.notify(`reload${MODELS.PURCHASE_REQUEST}`);
                      }
                    } catch (error) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(error)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  },
                }}
              >
                <WithPermission access_key={permissions.inventory.views.repairItem.permissions.repairItem.value}>
                  <StandardButton
                    label="Reject"
                    color={vars.colors.error}
                  />
                </WithPermission>
              </Action>
            );
          }
        },
      },
    ],
  },
  [ROUTES.viewDepartmentRepairRequest.name]: {
    screen: ViewDepartmentRepairRequests,
    title: (props) => {
      const { navigation: { state: { params: { item: { purchaseRequestAsset: { purchaseRequestNumber } = {} } = { } } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewTransferRequestAsset.name]: {
    screen: ViewTransferRequests,
    expanded: true,
  },
  [ROUTES.assetsMSIIndentRequests.name]: {
    expanded: true,
    screen: MSIRequestsTabs,
  },
  [ROUTES.msiViewIndentRequestAsset.name]: {
    screen: MSIViewIndentRequest,
    expanded: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    headerActions: [
      {
        type: 'save',
        role: 'edit',
        id: 'submit',
        render: (props) => {
          const { data } = props;
          if (data?.requestStatus === assetInventoryStockRequestStatus.raised) {
            return (
              <StandardButton
                label={'inventory.buttons.submit'.getLabel()}
                {...props}
              />
            );
          }
        },
      },
    ],
  },
  [ROUTES.msiViewRepairRequest.name]: {
    screen: MSIViewRepairRequests,
    expanded: true,
    headerActions: [
      {
        render: (props) => (
          <WithPermission access_key={permissions.inventory.views.approveRejectMSIRepairRequest.permissions.approveRejectMSIRepairRequest.value}>
            <LargeButton
              label={'asset.buttons.reject'.getLabel()}
              color={vars.colors.error}
              hoverColor={vars.colors.error}
              onPress={async () => {
                try {
                  const { navigation } = props;
                  const { navigation: { state: { params: { item } } } } = props;
                  if (item.requestStatus === assetInventoryStockRequestStatus.rejected) {
                    throw new Error('asset.messages.transferRequestAlreadyRejected'.getLabel());
                  } else if (item.requestStatus === assetInventoryStockRequestStatus.approved) {
                    throw new Error('asset.messages.transferRequestAlreadyApproved'.getLabel());
                  }
                  const result = await invoke({
                    id: 'handleAssetRepairRequestStatusChange',
                    paramValue: {
                      ...item,
                      requestStatus: assetInventoryStockRequestStatus.rejected,
                    },
                  });
                  if (result) {
                    navigation.pop();
                    Toast.show({
                      message: 'Success',
                      description: 'asset.messages.transferRequestReject'.getLabel(),
                      type: 'success',
                      position: 'top',
                      direction: 'right',
                      duration: 3000,
                    });
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              }}
            />
          </WithPermission>
        ),
      },
      {
        render: (props) => (
          <WithPermission access_key={permissions.inventory.views.approveRejectMSIRepairRequest.permissions.approveRejectMSIRepairRequest.value}>
            <LargeButton
              label={'asset.buttons.approve'.getLabel()}
              color={vars.colors.primary.color2}
              hoverColor={vars.colors.primary.color2}
              onPress={async () => {
                try {
                  const { navigation } = props;
                  const { navigation: { state: { params: { item } } } } = props;
                  if (item.requestStatus === assetInventoryStockRequestStatus.approved) {
                    throw new Error('asset.messages.batchUpdateRequestAlreadyApproved'.getLabel());
                  } else if (item.requestStatus === assetInventoryStockRequestStatus.rejected) {
                    throw new Error('asset.messages.batchUpdateRequestAlreadyRejected'.getLabel());
                  }
                  const result = await invoke({
                    id: 'handleBatchRequestStatusChange',
                    paramValue: {
                      ...item,
                      requestStatus: assetInventoryStockRequestStatus.approved,
                    },
                  });
                  if (result) {
                    navigation.pop();
                    Toast.show({
                      message: 'Success',
                      description: 'asset.messages.batchUpdateRequestApprove'.getLabel(),
                      type: 'success',
                      position: 'top',
                      direction: 'right',
                      duration: 3000,
                    });
                  }
                } catch (e) {
                  Toast.show({
                    message: 'Error',
                    description: `${getErrorString(e)}`,
                    type: 'error',
                    position: 'top',
                    direction: 'right',
                  });
                }
              }}
            />
          </WithPermission>
        ),
      },
    ],
  },
  [ROUTES.assetsDepartmentRecords.name]: {
    screen: AssetDepartmentRecordsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'asset.title.departmentRecords'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewDepartmentIndentRecordAsset.name]: {
    screen: ViewDepartmentIndentRecord,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.assetsCentralRecords.name]: {
    screen: AssetCentralRecordsTabs,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label={'asset.title.centralRecords'.getLabel()}
        />
      ),
    },
  },
  [ROUTES.viewCentralInventoryPurchaseRecordsAsset.name]: {
    screen: ViewCentralInventoryPurchaseRecords,
    // title: (props) => {
    //   const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
    //   return (
    //     <Text style={{ ...vars.headings.h8 }}>
    //       {item?.purchaseRequestNumber}
    //     </Text>
    //   );
    // },
    expanded: true,
  },
  [ROUTES.viewCentralIndentRecordAsset.name]: {
    screen: ViewCentralIndentRecord,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewLoanOutRecordAsset.name]: {
    screen: ViewLoanOutRecord,
    expanded: true,
  },
  [ROUTES.addInventoryAssetStocks.name]: {
    screen: AddStock,
    expanded: true,
    headerActions: headerActionsForAddForm,
  },
  [ROUTES.assetViewDepartmentInventory.name]: {
    screen: ViewDepartmentInventoryItem,
    title: (props) => {
      const { navigation: { state: { params: { item: { item } = {} } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.name}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.msiViewAssetTransferRequest.name]: {
    screen: MSIViewTransferRequest,
    expanded: true,
    headerActions: [
      {
        render: (props) => {
          const { navigation } = props;
          const { data } = props;
          if (data?.requestStatus === assetInventoryStockRequestStatus.raised) {
            return (
              <WithPermission access_key={permissions.inventory.views.approveRejectMSIRequest.permissions.approveRejectMSIRequest.value}>
                <LoadingButton
                  label={'asset.buttons.reject'.getLabel()}
                  color={vars.colors.error}
                  hoverColor={vars.colors.error}
                  onPress={async () => {
                    try {
                      if (data.requestStatus === assetInventoryStockRequestStatus.rejected) {
                        throw new Error('asset.messages.transferRequestAlreadyRejected'.getLabel());
                      } else if (data.requestStatus === assetInventoryStockRequestStatus.approved) {
                        throw new Error('asset.messages.transferRequestAlreadyApproved'.getLabel());
                      }
                      const result = await invoke({
                        id: 'handleAssetTransferRequestStatusChange',
                        paramValue: {
                          ...data,
                          requestStatus: assetInventoryStockRequestStatus.rejected,
                        },
                      });
                      if (result) {
                        navigation.pop();
                        Toast.show({
                          message: 'Success',
                          description: 'asset.messages.transferRequestReject'.getLabel(),
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                      }
                    } catch (e) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(e)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  }}
                />
              </WithPermission>
            );
          }
        },
      },
      {
        render: (props) => {
          const { navigation } = props;
          const { data } = props;
          if (data?.requestStatus === assetInventoryStockRequestStatus.raised) {
            return (
              <WithPermission access_key={permissions.inventory.views.approveRejectMSIRequest.permissions.approveRejectMSIRequest.value}>
                <LoadingButton
                  label={'asset.buttons.approve'.getLabel()}
                  color={vars.colors.primary.color2}
                  hoverColor={vars.colors.primary.color2}
                  onPress={async () => {
                    try {
                    // const { navigation } = props;
                    // const { navigation: { state: { params: { data } } } } = props;
                      if (data.requestStatus === assetInventoryStockRequestStatus.approved) {
                        throw new Error('asset.messages.transferRequestAlreadyApproved'.getLabel());
                      } else if (data.requestStatus === assetInventoryStockRequestStatus.rejected) {
                        throw new Error('asset.messages.transferRequestAlreadyRejected'.getLabel());
                      }
                      const result = await invoke({
                        id: 'handleAssetTransferRequestStatusChange',
                        paramValue: {
                          ...data,
                          requestStatus: assetInventoryStockRequestStatus.approved,
                        },
                      });
                      if (result) {
                        navigation.pop();
                        Toast.show({
                          message: 'Success',
                          description: 'asset.messages.transferRequestApprove'.getLabel(),
                          type: 'success',
                          position: 'top',
                          direction: 'right',
                          duration: 3000,
                        });
                      }
                    } catch (e) {
                      Toast.show({
                        message: 'Error',
                        description: `${getErrorString(e)}`,
                        type: 'error',
                        position: 'top',
                        direction: 'right',
                      });
                    }
                  }}
                />
              </WithPermission>
            );
          }
        },
      },
    ],
  },
  [ROUTES.viewTransferRecordsAsset.name]: {
    screen: ViewTransferRecords,
    expanded: true,
  },
  [ROUTES.viewRepairRecords.name]: {
    screen: ViewRepairRecords,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.purchaseRequestNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewDepartmentIndentRequest.name]: {
    screen: ViewDepartmentIndentRequest,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.viewCentralIndentRecordAssetItem.name]: {
    screen: AssetItemDetails,
    modal: true,
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {item?.indentNumber}
        </Text>
      );
    },
    modalProps: {
      autoHide: true,
      width: 1178,
      height: 683,
    },
  },
  [ROUTES.assetsIncargeRecordList.name]: {
    screen: AssetItemInchargeList,
    header: {
      title: () => {
        const user = getUser();
        const { employee } = user;
        return (
          <View style={{ flexDirection: 'row' }}>
            <HeaderTitleWithCount
              label="Incharge Record (Asset Store)"
            />
            <View style={{ flexDirection: 'row', marginLeft: 15 }}>
              <Text style={{ ...vars.headings.h8, paddingTop: 3, color: vars.colors.grey.color3 }}>
                SUPERVISOR:
              </Text>
              <Text style={{
                ...vars.headings.h8, paddingTop: 3, paddingLeft: 8, color: vars.colors.primary.color1,
              }}
              >
                {employee?.supervisor?.name}
              </Text>
            </View>
          </View>
        );
      },
    },
    expanded: true,
  },
  [ROUTES.viewAssetsInChargeRecord.name]: {
    screen: ViewInChargeTabs,
    headerActions: [
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.sendToAuction.permissions.sendToAuction.value}
                  >
                    <StandardButton
                      label="Send To Auction"
                    />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.sendToAuction.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          modal: true,
        }),
      },
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.modifyInChargeRecords.permissions.modifyInChargeRecords.value}
                  >
                    <StandardButton label="Edit Item Details" />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editAssetDetail.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          expanded: true,
        }),
      },
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.modifyInChargeRecords.permissions.modifyInChargeRecords.value}
                  >
                    <StandardButton label="Edit Item Details" />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.addAssetItemRemarks.name,
          push: true,
          params: { ...props?.navigation?.state?.params },
          ...props,
        }),
      },

    ],
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      const { serialNumber } = item;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          { serialNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.addAssetItemRemarks.name]: {
    screen: AddAssetItemRemarks,
    modal: true,
    modalProps: {
      width: 700,
      height: 420,
    },
    expanded: true,
  },
  [ROUTES.assetsSupervisorRecordList.name]: {
    screen: AssetItemSupervisorList,
    header: {
      title: () => (
        <HeaderTitleWithCount
          label="Supervisor Record (Asset Store)"
        />
      ),
    },
    expanded: true,
  },
  [ROUTES.viewAssetsSupervisorRecord.name]: {
    screen: ViewSupervisorTab,
    headerActions: [
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.modifySupervisorRecords.permissions.modifySupervisorRecords.value}
                  >
                    <StandardButton label="Edit Item Details" />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.editAssetDetail.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          expanded: true,
        }),
      },
      {
        render: () => (
          <ScreenDataConsumer>
            {(props) => {
              const data = props?.getActiveScreenState()?.data;
              if (data?.assetStatus === assetStatus[0] || data?.assetStatus === assetStatus[2]) {
                return (
                  <WithPermission
                    access_key={permissions.inventory.views.sendToAuction.permissions.sendToAuction.value}
                  >
                    <StandardButton
                      label="Send To Auction"
                    />
                  </WithPermission>
                );
              }
            }}
          </ScreenDataConsumer>
        ),
        type: 'link',
        link: (props) => ({
          view: ROUTES.sendToAuction.name,
          params: { ...props?.navigation?.state?.params },
          push: true,
          modal: true,
        }),
      },
    ],
    title: (props) => {
      const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
      const { serialNumber } = item;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          { serialNumber}
        </Text>
      );
    },
    expanded: true,
  },
  [ROUTES.editAssetDetail.name]: {
    screen: editAssetDetail,
    headerActions: headerActionsForEditForm,
    expanded: true,
  },
  [ROUTES.addTechnicalModal.name]: {
    screen: AddTechnicalModal,
    modal: true,
    modalProps: {
      width: 700,
      height: 450,
      autoHide: true,
    },
  },
  [ROUTES.editTechnicalModal.name]: {
    screen: EditTechnicalModal,
    modal: true,
    modalProps: {
      width: 700,
      height: 450,
      autoHide: true,
    },
  },
  [ROUTES.editMonthlyRemarks.name]: {
    screen: EditMonthlyRemarks,
    modal: true,
    modalProps: {
      width: 620,
      height: 420,
      autoHide: true,
    },
  },
  [ROUTES.addAssetServiceReturnDateModal.name]: {
    screen: AddAssetReturnDateModal,
    modal: true,
    modalProps: {
      width: 400,
      height: 400,
      autoHide: true,
    },
  },
  [ROUTES.sendToAuction.name]: {
    screen: SendToAuction,
    modal: true,
    modalProps: {
      width: 600,
      height: 470,
      autoHide: true,
    },
  },
};
