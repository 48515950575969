import moment from 'moment';
import React, { useCallback } from 'react';
import uuid from 'uuid';
import {
  View,
  Text,
  save,
  TouchableOpacity,
  StandardButton,
  OutlineButton,
} from '../../../../../../../app-components';
import { afterFetchForm } from '../../../../../../../app-components/data-fetch/DataFetchFunctions';
import { FormField } from '../../../../../../../app-components/form/field/FormField';
import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedAction,
  NestedTable,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit, fetch } from '../../../../../../../AppServices';

import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { font_20_Bold } from '../../../../../../../theme/Headlines';
import { manazeV6Colors } from '../../../../../../../theme/manazeV6Colors';
import vars from '../../../../../../../theme/vars';
import { afterUriFetch } from '../../../../../constants';

const TreatmentChart = (props) => (
  <Form
    {...props}
    scrollable
    type="standardShadow"
    uri={(uriProps) => ({
      query: {
        id: 'pastTreatmentChartData',
        addOnFilter: {
          ipdAdmission: { _id: uriProps?.navigation?.state?.params?._id },
        },
        speciality: props?.dataParams?.filters?.specialityId,
      },
      model: 'treatment_chart',
    })}
    onSubmit={submit({
      model: 'treatment_chart',
    })}
    afterSubmit={({ submitResult: { result } = {}, data }) => ({
      data: {
        ...data,
        _id: result?._id || data?._id,
      },
      updates: {},
    })}
    submitMessage="Data saved successfully."
    // header={{
    //   actions: [save({})],
    // }}
    // In case submodal are used
      // Start
    beforeSubmit={({ data }) => ({
      data,
      updates: data,
    })}
    deepDiff={false}
      // Finish
    saveOnChange
    defaultValues={(deafultProps) => ({
      ipdAdmission: {
        _id: deafultProps?.navigation?.state?.params?.patient?._id,
      },
      speciality: props?.dataParams?.filters?.specialityId,
    })}
    computations={{
      children: {
        dosage: {
          self: {
            morningMeridiem: {
              compute: () => ({
                set: {
                  morningMeridiem: 'AM',
                  afternoonMeridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
                  eveningMeridiem: 'PM',
                  bedMeridiem: 'PM',
                  date: new Date(),
                },
              }),
              onChange: ['_id'],
            },
          },
        },

      },
    }}
  >
    {({ form_state: formState, form_context: formContext }) => {
      const { handleSubmit } = formContext;
      return (
        <View>
          <View>
            <FormField
              {...{
                field: 'dosage',
                fieldType: 'nested',
                addInBottom: false,
                Component: NestedTable,
                componentProps: {
                  // data: [{ _id: 'new_123456789' }],
                  maxHeight: 400,
                  header: ({ addRow }) => ({
                    leftActions: [
                      {
                        render: () => (
                          <View
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: 'grey',
                            }}
                          >
                            <Text
                              style={{
                                ...vars.headings.h9,
                                color: vars.colors.grey.color3,
                              }}
                            >
                              Add New Drug
                            </Text>
                          </View>
                        ),
                        onPress: addRow,
                      },
                      {
                        render: () => (
                          // <WithPermission access_key={permissions.inventory.views.raisePurchaseRequestAsset.permissions.raisePurchaseRequestAsset.value}>
                          <OutlineButton label="Add Medicine" />
                          // </WithPermission>
                        ),
                        type: 'link',
                        link: () => ({
                          view: ROUTES.addMedicineFromTreatment.name,
                          params: { ...props?.navigation?.state?.params },
                          push: true,
                          expanded: true,
                        }),
                      },
                    ],
                  }),
                  skipTableHeaderOnNoData: false,
                  listProps: {
                    // data: new Array(4).fill(),
                    //   hideColumnHeader: true,
                    columns: [
                      {
                        type: 'date',
                        field: 'date',
                        header: 'Date',
                        variant: 'filled',
                        width: 200,
                        editable: false,
                      },
                      {
                        type: 'number',
                        field: 'day',
                        header: 'Day',
                        variant: 'filled',
                        width: 200,
                        editable: false,
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'drug',
                        header: 'Drug Name',
                        variant: 'filled',
                        keyFiled: 'drug',
                        suggestionField: 'drug',
                        valueField: 'drug',
                        model: MODELS.IPD_TREATMENT_PROGRESS_REPORT,
                        query: 'treatmentProgressReportData',
                        mandatory: true,
                        width: 200,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      },
                      {
                        addOnFilter: ({ data } = {}) => ({
                          admissionID: { _id: props?.navigation?.state?.params?.patient?._id },
                          _createdOn: {
                            $gt: new Date(
                              new Date(
                                new Date(
                                  new Date(
                                    new Date().setUTCHours(0),
                                  ).setUTCMinutes(0),
                                ).setUTCSeconds(0),
                              ).setUTCMilliseconds(0),
                            ),
                          },
                        }),
                      }),
                      {
                        header: 'Morning',
                        ...newDateInput({
                          hour: { field: 'morningHour', label: 'Hour' },
                          minute: { field: 'morningMinute', label: 'Minute' },
                          meridiem: { field: 'morningMeridiem' },
                        }),
                        width: 300,
                        variant: 'filled',
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'morningNurse',
                        header: 'Nurse Name',
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        mandatory: true,
                        width: 200,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }),
                      {
                        header: 'Afternoon',
                        ...newDateInput({
                          hour: { field: 'afternoonHour', label: 'Hour' },
                          minute: { field: 'afternoonMinute', label: 'Minute' },
                          meridiem: { field: 'afternoonMeridiem' },
                        }),
                        variant: 'filled',
                        width: 300,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'afternoonNurse',
                        header: 'Nurse Name',
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        mandatory: true,
                        width: 200,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }),
                      {
                        header: 'Evening',
                        ...newDateInput({
                          hour: { field: 'eveningHour', label: 'Hour' },
                          minute: { field: 'eveningMinute', label: 'Minute' },
                          meridiem: { field: 'eveningMeridiem' },
                        }),
                        variant: 'filled',
                        width: 300,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'eveningNurse',
                        header: 'Nurse Name',
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        mandatory: true,
                        width: 200,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }),
                      {
                        header: 'Bed',
                        ...newDateInput({
                          hour: { field: 'bedHour', label: 'Hour' },
                          minute: { field: 'bedMinute', label: 'Minute' },
                          meridiem: { field: 'bedMeridiem' },
                        }),
                        variant: 'filled',
                        width: 300,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'bedNurse',
                        header: 'Nurse Name',
                        variant: 'filled',
                        keyFiled: 'name',
                        suggestionField: 'name',
                        valueField: 'name',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                        mandatory: true,
                        width: 200,
                        editable: (editableProps) => {
                          if (
                            moment(editableProps?.item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return false;
                          }
                          return true;
                        },
                      }),
                      {
                        type: 'action',
                        action: ({ removeRow }) => ({
                          onPress: removeRow,
                        }),
                        render: ({ removeRow, item }) => {
                          if (
                            moment(item?.date).diff(
                              moment(new Date()),
                              'days',
                            ) < -1
                          ) {
                            return null;
                          }
                          return (
                            <Text
                              style={{
                                ...font_20_Bold,
                                margin: 8,
                                marginLeft: 12,
                                marginRight: 12,
                                color: 'red',
                              }}
                            >
                              X
                            </Text>
                          );
                        },
                      },
                    ],
                  },
                },
              }}
            />
          </View>
        </View>
      );
    }}
  </Form>
);
export default TreatmentChart;
