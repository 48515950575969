import React from 'react';
import { upperFirst } from 'lodash';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';
import { multiSearchInput } from '../../../../autoSuggestions';
import { EditTest } from '../../../../Queries/test';
import { validateInputLength } from '../../../../Lib/helpers';
import { currencyInput } from '../../../../compositeInputs';
import CheckBoxComponent from './Components/checkbox';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'lab.labels.testName'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ value }) => upperFirst(value),
  },
  {
    type: 'text',
    field: 'code',
    label: 'lab.labels.testID'.getLabel(),
    editable: false,
    mandatory: true,
  },
  multiSearchInput({
    field: 'labType',
    label: 'lab.labels.labType'.getLabel(),
    placeholder: 'lab.placeholders.selectLab'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    idField: '_id',
    suggestionField: 'name',
    model: MODELS.LAB_TYPE,
    query: 'masterDataList',
    searching: true,
    mandatory: true,
  }),
  {
    type: 'toggleSwitch',
    field: 'isActive',
    label: 'lab.labels.status'.getLabel(),
    container: 'leftLabel',
    align: 'center',
  },
  {
    ...currencyInput({
      variant: 'filled',
      amount: {
        field: 'charge',
        label: 'Charge',
        placeholder: 'Enter Amount',
      },
      currency: {
        field: 'currency',
      },
    }),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'lab.labels.description'.getLabel(),
    minHeight: 38,
  },
  {
    type: 'custom',
    showOuterLabel: false,
    field: 'manualTest',
    label: 'lab.labels.manualTest'.getLabel(),
    container: 'leftLabel',
    render: (renderProps) => (
      <CheckBoxComponent
        navigation={renderProps?.navigation}
        setValue={renderProps?.setValue}
        value={renderProps?.data?.manualTest}
      />
    ),
  },
  multiSearchInput({
    field: 'testComponentType',
    label: 'lab.labels.componentTest'.getLabel(),
    placeholder: 'lab.placeholders.componentTest'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.TEST_COMPONENT_TYPE,
    query: 'masterDataList',
    searching: true,
    mandatory: true,
  }),
];
const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 46),
  code: ({ data }) => validateInputLength(data?.code, 2, 20),
  description: ({ data }) => validateInputLength(data?.description, 0, 200),
};

export default MasterForm({
  uri: EditTest,
  model: MODELS.LAB_TEST,
  formFields,
  validations,
  submitMessage: 'lab.messages.testUpdatedSuccessfully'.getLabel(),
  uniqueValidationMessage: 'lab.messages.testAlreadyExists'.getLabel(),
  mandatory: {
    code: 1,
    labType: 1,
  },
});
