import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SelectDate } from '@applane/react-date-input';

import {
  StandardButton,
  View,
  Text,
  TouchableOpacity,
  Image,
} from '../../../../../../../app-components';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';
import { selectCalendarStyle } from '../../../../../../../theme/DateBoxtheme';
import { Table } from '../../../../../../../app-components/table/Table';
import { Form } from '../../../../../../../app-components/form/Form';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import { getPermission } from '../../../../../../../AppServices';
import permissions from '../../../../../../../Lib/permissions';
import { editRecord } from '../../../../../../../images';

const VitalSignsSheet = (props) => {
  const { dataParams } = props;

  const [selectedDate, setSelectedDate] = useState();
  const { eventDispatcher } = props || {};
  useEffect(() => {
    eventDispatcher.notify(`reload${MODELS.IPD_VITALS_SIGN_SHEET}`);
  }, [selectedDate, eventDispatcher]);
  const tableItems = {
    lg: {
      columns: [
        {
          header: 'S.No.'.getLabel(),
          type: 'number',
          render: (item) => (
            <Text style={{ lineHeight: 16, fontSize: 14 }}>
              {' '}
              {item.index + 1}
            </Text>
          ),
        },
        {
          header: 'TIME'.getLabel(),
          type: 'date',
          format: 'HH:mm A',
          field: 'vitalSignTime',
        },
        {
          header: 'BP',
          type: 'text',
          field: 'vitalSignBP',
        },
        {
          header: 'TEMPERATURE',
          type: 'text',
          field: 'vitalSignTemperature',
        },
        {
          header: 'PULSE',
          type: 'text',
          field: 'vitalSignPulse',
        },
        {
          header: 'RESP.',
          type: 'text',
          field: 'vitalSignResp',
        },
        {
          header: 'NURSE NOTES',
          type: 'text',
          field: 'nurseNote',
        },
        {
          header: 'Edit'.getLabel(),
          type: 'text',
          visible: () => getPermission(
            permissions.departments.views.ipdEditForms
              .permissions.ipdEditForms.value,
          ),
          render: () => (
            <TouchableOpacity>
              <Image
                style={{
                  height: 24,
                  width: 24,
                }}
                source={editRecord}
              />
            </TouchableOpacity>
          ),
          action: (data) => ({
            type: 'link',
            // visible: () => getPermission(
            //   permissions.departments.views.ipdEditForms
            //     .permissions.ipdEditForms.value,
            // ),
            link: {
              modal: true,
              view: (props.dataParams.filters.specialityFilter === 'Pediatrics NICU'
              || props.dataParams.filters.specialityFilter === 'Pediatrics PICU'
              || props.dataParams.filters.specialityFilter === 'Pediatrics Ward (PICU)')
                ? ROUTES.editVitalsPediatrics.name : ROUTES.editDailyVitals.name,
              params: {
                addOnFilter: {
                  _id: data.item._id,
                  // dataParams,
                },
                dataParams,
              },

            },
          }),
        },
      ],
    },
  };
  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      editable={false}
      flex={false}
      scrollable={false}
      lg={{
        formGroups: [
          {
            columnsPerRow: 2,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12, marginLeft: 66 },
            groups: [
              {
                columns: [
                  {
                    render: (item) => (
                      <SelectDate
                        theme={selectCalendarStyle}
                        renderDate={({
                          date, year, month, selected,
                        }) => {
                          let currentDate = new Date(year, month, date);
                          currentDate = moment(currentDate).format('YYYY-MM-DD');
                          if (selected) {
                            setSelectedDate(currentDate);
                          }
                          const admissionDate = moment(item?.navigation?.state?.params?.patient?._createdOn).format('YYYY-MM-DD');
                          const admittedDate = moment(admissionDate)
                            .isSame(currentDate);
                          const dischargedDate = moment(item?.navigation?.state?.params?.patient?.dateOfDischargeOrDeath).format('YYYY-MM-DD');
                          const dischargeDate = item?.navigation?.state?.params?.patient?.dateOfDischargeOrDeath ? moment(dischargedDate)
                            .isSame(currentDate) : false;
                          const highlightedDate = moment(currentDate).isAfter(moment(admissionDate))
                             && moment(currentDate).isBefore(moment(dischargedDate));
                          if (dischargeDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'red', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Discharge
                                </Text>
                              </View>
                            );
                          }

                          if (admittedDate) {
                            return (
                              <View style={{ margin: 'auto' }}>
                                <Text style={{
                                  margin: 'auto', backgroundColor: '#EFF3FB', marginBottom: -15, padding: 12,
                                }}
                                >
                                  {date}
                                </Text>
                                {' '}
                                <br />
                                <Text style={{
                                  margin: 'auto', fontSize: 10, color: 'green', backgroundColor: '#EFF3FB', marginTop: -10,
                                }}
                                >
                                  Admission
                                </Text>
                              </View>
                            );
                          }
                          if (highlightedDate) {
                            return (
                              <View style={{ backgroundColor: '#EFF3FB', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          if (selected) {
                            return (
                              <View style={{ backgroundColor: '#4070C9', padding: 13 }}>
                                {date}
                              </View>
                            );
                          }
                          return (
                            <View>
                              {date}
                            </View>
                          );
                        }}
                      />
                    ),
                  },
                  {
                    render: () => {
                      const tableProps = {
                        ...props,
                        ...{ selectedDate },
                        ...tableItems,
                      };
                      return (
                        <Table
                          {...tableProps}
                          reloadEvent={`reload${MODELS.IPD_VITALS_SIGN_SHEET}`}
                          header={() => {
                            const { navigation: { state: { params: { patient: { _id } } } } } = props;
                            // const { selectedDate = {} } = props;
                            return ({
                              title: () => (
                                <HeaderTitleWithCount
                                  label={`Vital Signs:  ${moment(selectedDate).format('DD MMM YYYY')}`}
                                />
                              ),
                              actions: [
                                {
                                  type: 'link',
                                  link: ({ navigation: { state: { params = {} } } }) => ({
                                    view: (props.dataParams.filters.specialityFilter === 'Pediatrics NICU'
                                    || props.dataParams.filters.specialityFilter === 'Pediatrics PICU'
                                    || props.dataParams.filters.specialityFilter === 'Pediatrics Ward (PICU)')
                                      ? ROUTES.AddVitalsPediatrics.name : ROUTES.LogDailyVitals.name,

                                    params: { ...params, dataParams },
                                    push: true,
                                    modal: true,
                                    modalProps: {
                                      autoHide: true,
                                      maxHeight: '100%',
                                      width: 1000,
                                      height: 'auto',
                                    },
                                  }),
                                  render: () => (
                                    <StandardButton
                                      label="Log Daily Vitals"
                                    />
                                  ),
                                },
                              ],
                            });
                          }}
                          uri={(item) => ({
                            query: {
                              id: 'EditIPDVitalSheet',
                              addOnFilter: {
                                admissionID: { _id: item?.navigation?.state?.params?.patient?._id },
                                speciality: props.dataParams?.filters?.specialityFilter,
                                _createdOn: {
                                  $gt: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(0),
                                        ).setUTCMinutes(0),
                                      ).setUTCSeconds(0),
                                    ).setUTCMilliseconds(0),
                                  ),
                                  $lt: new Date(
                                    new Date(
                                      new Date(
                                        new Date(
                                          new Date(selectedDate).setUTCHours(23),
                                        ).setUTCMinutes(59),
                                      ).setUTCSeconds(59),
                                    ).setUTCMilliseconds(0),
                                  ),
                                },
                              },
                            },
                            model: MODELS.IPD_VITALS_SIGN_SHEET,
                          })}
                          action={{
                            type: 'link',
                            link: (data) => ({
                              view: props.dataParams.filters.specialityFilter === 'Pediatrics NICU'
                               || props.dataParams.filters.specialityFilter === 'Pediatrics PICU'
                              || props.dataParams.filters.specialityFilter === 'Pediatrics Ward (PICU)'
                                ? ROUTES.ViewVitalsPediatrics.name : ROUTES.ViewDailyVitals.name,
                              modal: true,
                              params: {
                                addOnFilter: {
                                  _id: data.item._id,
                                },
                                dataParams: props.dataParams,
                              },
                              modalProps: {
                                autoHide: true,
                                maxHeight: '100%',
                                width: 1000,
                                height: 'auto',
                              },

                            }),
                          }}
                        />
                      );
                    },
                    style: { flex: 2, overflow: 'hidden' },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default VitalSignsSheet;

