/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  cloneDeep, isEmpty, uniq, uniqueId,
} from 'lodash';
import {
  View, Text, Toast,
} from '../../../../app-components';
import { salaryHeartIcon } from '../../../../images';
import {
  EmployeePackage,
} from './list';
import { getResource } from './uri';
import {
  CompensationFormLayouts,
} from './layouts';
import { EmployeeCompensationHoc } from './commonHoc';
import { multiSearchInput, searchInput } from '../../../../autoSuggestions';
import {
  NestedAction,
  NestedTable,
} from '../../../../app-components/input-components/nestedTable/NestedTable';
import tabTheme from '../../../../theme/childTabTheme';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';
import { TableActionButton } from '../../../../app-components/buttons/Buttons';
import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import { invoke } from '../../../../AppServices';
import { getErrorString } from '../../../../Lib/helpers';
import { getMonthFirstLastDate } from '../../../../app-components/filter/date/DateUtility';

const calculateDependantComponentsAmount = (components, allComponents, returnPercentage) => {
  let amount = 0;
  for (let c = 0; c < components?.dependentComponents?.length; c += 1) {
    if (components.dependentComponents[c]?.dependentComponents) {
      const cAmount = calculateDependantComponentsAmount(
        components.dependentComponents[c],
        allComponents,
        true,
      );
      amount += cAmount;
    } else {
      const component = allComponents?.find(
        (com) => com?.salary_component_id?._id?.toString()
          === components?.dependentComponents[c]?._id?.toString(),
      );

      if (component) {
        amount += component.amount;
      }
    }
  }

  if (returnPercentage) {
    return ((amount / 100)
    * (components?.dependentPercentage || 0));
  }
  return Math.round(amount);
};

const handleNPAAmount = (components) => {
  const bp = components.find(((com) => com?.salary_component_id?.code === 'BP'));
  const npa = components.find(((com) => com?.salary_component_id?.code === 'NPA'));
  if (!bp || !bp.amount) return 0;

  if (bp.amount <= 187125) return Math.round((bp.amount / 100) * npa?.dependentPercentage || 0) || 0;

  if (bp.amount > 187125 && bp.amount < 224550) return Math.round(224550 - bp.amount);

  return 0;
};

const handleEPFAmount = (components, employee = false) => {
  const bp = components.find(((com) => com?.salary_component_id?.code === 'BP'));
  const da = components.find(((com) => com?.salary_component_id?.code === 'DA'));
  const epfEmployer = components.find(((com) => com?.salary_component_id?.code === 'EPF'));
  const epfEmployee = components.find(((com) => com?.salary_component_id?.code === 'EPF Deductable'));

  const epf = employee ? epfEmployee : epfEmployer;

  const daAndBp = da?.amount || 0 + bp?.amount || 0;

  if (!daAndBp) return 0;

  if (daAndBp > 15000) return 1800;

  return Math.round((daAndBp / 100) * (epf?.dependentPercentage || 0));
};

const calculateComponentsAmount = (
  primaryComponents, calculatedComponents = {}, componentNames, attendance_base_components = [],
) => {
  const components = [...primaryComponents, ...attendance_base_components];
  for (let i = 0; i < components?.length; i += 1) {
    if (components[i]?.salary_component_id?.dependentComponents) {
      let isAllDependentCalculated = true;
      let amount = 0;
      for (let j = 0; j < components[i]?.salary_component_id?.dependentComponents.length; j += 1) {
        const componentName = components[i]?.salary_component_id?.dependentComponents[j].name;
        if (componentNames.indexOf(componentName) >= 0
        && Object.keys(calculatedComponents).indexOf(componentName) < 0) {
          isAllDependentCalculated = false;
          break;
        }
        amount += calculatedComponents[componentName] || 0;
      }
      if (isAllDependentCalculated) {
        if (primaryComponents[i]) {
          primaryComponents[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          components[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          if (components[i]?.salary_component_id?.code === 'NPA') {
            primaryComponents[i].amount = handleNPAAmount(components);
            components[i].amount = handleNPAAmount(components);
          }
          if (components[i]?.salary_component_id?.code === 'EPF Deductable') {
            primaryComponents[i].amount = handleEPFAmount(components, true);
            components[i].amount = handleEPFAmount(components, true);
          }
          if (components[i]?.salary_component_id?.code === 'EPF') {
            primaryComponents[i].amount = handleEPFAmount(components);
            components[i].amount = handleEPFAmount(components);
          }
        } else {
          components[i].amount = Math.round(((amount / 100))
          * (components[i]?.salary_component_id?.dependentPercentage || 0));
          if (components[i]?.salary_component_id?.code === 'NPA') {
            components[i].amount = handleNPAAmount(components);
          }
          if (components[i]?.salary_component_id?.code === 'EPF Deductable') {
            components[i].amount = handleEPFAmount(components, true);
          }
          if (components[i]?.salary_component_id?.code === 'EPF') {
            components[i].amount = handleEPFAmount(components);
          }
        }
        calculatedComponents[components[i]?.salary_component_id?.name] = components[i].amount;
      }
    } else {
      calculatedComponents[components[i]?.salary_component_id?.name] = components[i].amount;
    }
  }
  if (!primaryComponents?.length || Object.keys(calculatedComponents).length === componentNames.length) {
    return primaryComponents;
  }
  return calculateComponentsAmount(
    primaryComponents, calculatedComponents, componentNames, attendance_base_components,
  );
};

export const EmployeePackageStructure = (props) => {
  const { navigation, addOnFilter, canAddSalaryStructure } = props;
  const { item } = navigation.getParams() || {};
  return (
    <TabNavigator
      {...props}
      theme={tabTheme}
      actions={[
        {
          type: 'link',
          link: () => ({
            view: ROUTES.addEmployeeSalaryCompensationStructure.name,
            params: { item },
          }),
          render: () => {
            if (item?.editable) {
              return (
                <WithPermission
                  access_key={
                    permissions.departments.views.addEditCompensationStructure
                      .permissions.addEditCompensationStructure.value
                  }
                >
                  <TableActionButton
                    label="Add Compensation Structure"
                    width={230}
                    color={vars.colors.secondary.color2}
                    hoverColor={vars.colors.secondary.color2}
                    labelStyle={{
                      ...vars.headings.h7,
                      color: vars.colors.white,
                      numberOfLines: 1,
                    }}
                    {...props}
                  />
                </WithPermission>
              );
            }
            return <View />;
          }
          ,
        },
      ]}
      tabs={{
        salary: {
          label: 'Compensation',
          screen: EmployeePackage,
          screenProps: {
            addOnFilter,
            canAddSalaryStructure,
          },
        },
      }}
    />
  );
};

const commonComputationForEmployeePackage = {
  self: {
    'Select Template for data': {
      compute: (data) => {
        const {
          parent = {},
          attendance_base_components: attendanceBaseComponents,
          deductions_components: dependentComponents,
          fix_base_components: fixBaseComponents,
        } = data;

        const {
          attendance_base_components = attendanceBaseComponents || [],
          ctc_amount = undefined,
          deductions_components = dependentComponents || [],
          fix_base_components = fixBaseComponents || [],
        } = parent || {};

        return {
          set: {
            ctc_amount,
            attendance_base_components,
            deductions_components,
            fix_base_components,
          },
        };
      },
      onChange: ['parent'],
    },
    'Select level for data': {
      compute: () => ({
        set: {
          // attendance_base_components: [],
          // deductions_components: [],
          // fix_base_components: [],
          parent: '',
        },
      }),
      onChange: ['level'],
    },
    artificialTotalAmount: {
      compute: (data) => {
        const response = {};
        response.artificialTotalAmount = ((data
          && data.attendance_base_components
          && data.attendance_base_components.reduce(
            (accum, doc) => accum + doc.amount,
            0,
          ))
          || 0)
          + ((data
            && data.deductions_components
            && data.deductions_components.reduce((accum, doc) => {
              let total = 0;
              total = doc.amount;
              return accum - total;
            }, 0))
            || 0);
        response.ctc_amount = response.artificialTotalAmount;
        return { set: { ...response } };
      },
      onChange: [
        'attendance_base_components',
        'performance_base_components',
        'deductions_components',
        'fix_base_components',
        'attendance_base_components.amount',
        'performance_base_components.amount',
        'performance_base_components.link_to_project',
        'deductions_components.amount',
        'deductions_components.salary_component_id. _id',
        'fix_base_components.amount',
        '_set_ctc',
      ],
    },
    'set attendance component amount': {
      compute: (data) => {
        const { attendance_base_components } = data;

        // let clonedAttendanceComponent = cloneDeep(attendance_base_components);
        return {
          set: {
            attendance_base_components: calculateComponentsAmount(
              cloneDeep(attendance_base_components).filter((com) => com.salary_component_id),
              {},
              uniq(attendance_base_components.filter((com) => com.salary_component_id)?.map((com) => com?.salary_component_id.name)),
            ),
          },
        };
      },
      onChange: ['attendance_base_components.amount'],
    },
    'set deduction component amount': {
      compute: (data) => {
        const { deductions_components = [], attendance_base_components } = data;

        return {
          set: {
            deductions_components: calculateComponentsAmount(
              cloneDeep(deductions_components).filter((com) => com.salary_component_id),
              {},
              uniq([...deductions_components, ...attendance_base_components].filter(
                (com) => com.salary_component_id,
              )?.map((com) => com?.salary_component_id.name)),
              attendance_base_components.filter(
                (com) => com.salary_component_id,
              ),
            ),
          },
        };
      },
      onChange: ['deductions_components.amount', 'attendance_base_components.amount'],
    },
    fixed_salary: {
      compute: (data) => {
        const response = {};
        response.fixed_salary = ((data
          && data.attendance_base_components
          && data.attendance_base_components.reduce(
            (accum, doc) => accum + doc.amount,
            0,
          ))
          || 0)
          + ((data.deductions_components
            && data.deductions_components.reduce((accum, doc) => {
              let total = 0;
              // Esi employer,gratuity, pf employer ,health insurance
              if (
                doc
                && doc.salary_component_id
                && (doc.salary_component_id._id.toString()
                  === '53ae739a79df44bac9b0b9f1'
                  || doc.salary_component_id._id.toString()
                  === '53ae73a779df44bac9b0ba0a'
                  || doc.salary_component_id._id.toString()
                  === '53ae738f79df44bac9b0b9da'
                  || doc.salary_component_id._id.toString()
                  === '53ae739d79df44bac9b0b9f7')
              ) {
                total = doc.amount || 0;
              }
              return accum + total;
            }, 0))
            || 0);
        return { set: { ...response } };
      },
      onChange: [
        '_id',
        'attendance_base_components',
        'attendance_base_components.amount',
        'deductions_components',
        'deductions_components.amount',
        'deductions_components.salary_component_id._id',
      ],
    },
    package_review_date: {
      compute: (data) => {
        const response = {};
        if (data && data.to_date) {
          response.package_review_date = moment(data.to_date).subtract(1, 'months');
          return { set: { ...response } };
        }
        return {};
      },
      onChange: ['to_date'],
    },
  },
  children: {
    attendance_base_components: {
      self: {
        'set amount on select dependent components': {
          compute: (data) => {
            const amount = calculateDependantComponentsAmount(
              {
                ...data,
                dependentComponents: data
                  .salary_component_id?.dependentComponents,
                dependentPercentage: data
                  .salary_component_id?.dependentPercentage,
              }, data?._parent?.attendance_base_components,
            );

            return {
              set: {
                amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                dependentComponents: data.salary_component_id?.dependentComponents,
                dependentPercentage: data.salary_component_id?.dependentPercentage,
              },
            };
          },
          onChange: ['salary_component_id'],
        },
        'set from and to date ': {
          compute: (data) => {
            const { _parent } = data;
            return {
              set: {
                from_date: _parent.from_date,
                to_date: _parent.to_date,
              },
            };
          },
          onChange: ['_id', '_parent.from_date', '_parent.to_date'],
        },
        'set amount on percentage change': {
          compute: (data) => {
            let mainJson = {};
            let amount = 0;
            let deductableAmt = 0;
            const {
              _parent: { attendance_base_components = [] },
              ctc_percentage: dataMainPercentage = 0,
              salary_component_id: { _id, dependency_field = [] },
            } = data;

            if (
              attendance_base_components
              && attendance_base_components.length
            ) {
              mainJson = attendance_base_components.reduce((json, one) => {
                const id = one && one.salary_component_id && one.salary_component_id._id;
                if (id) json[id] = one;
                return json;
              }, {});
            }
            if (dependency_field) {
              for (const dependancyId of dependency_field) {
                if (dependancyId._id.toString() === '11111111111111') {
                  amount += (data._parent && data._parent.ctc_amount) || 0;
                } else {
                  amount
                    += (mainJson
                      && mainJson[dependancyId._id]
                      && mainJson[dependancyId._id].amount)
                    || 0;
                }
              }
              deductableAmt = (amount * dataMainPercentage) / 100 || 0;
              amount = deductableAmt;
            }
            mainJson[_id].amount = amount;
            return {
              set: {
                amount: Math.round(amount),
              },
            };
          },
          onChange: ['ctc_percentage'],
        },
      },
    },
    deductions_components: {
      self: {
        'set from and to date': {
          compute: (data) => {
            const { _parent } = data;
            return {
              set: {
                from_date: _parent.from_date,
                to_date: _parent.to_date,
              },
            };
          },
          onChange: ['_id', '_parent.from_date', '_parent.to_date'],
        },
        'set amount on select dependent components': {
          compute: (data) => {
            const amount = calculateDependantComponentsAmount(
              {
                ...data,
                dependentComponents: data
                  .salary_component_id?.dependentComponents,
                dependentPercentage: data
                  .salary_component_id?.dependentPercentage,
              }, [
                ...data?._parent?.attendance_base_components,
                ...data?._parent?.deductions_components],
            );

            return {
              set: {
                amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                dependentComponents: data.salary_component_id?.dependentComponents,
                dependentPercentage: data.salary_component_id?.dependentPercentage,
              },
            };
          },
          onChange: ['salary_component_id'],
        },
      },
    },
  },
};

export const EmployeeCompensationDetailCopy = EmployeeCompensationHoc({
  onSubmit: ({ data, updates, navigation }) => {
    let mainData = {};
    const submitMessage = 'Compensation Structure has been updated successfully';
    mainData = { ...data, ...updates };
    mainData
      && mainData.artificialTotalAmount;
    navigation.push({
      view: ROUTES.CompensationStructureConfirmationView.name,
      params: {
        mainData,
        submitMessage,
        description: 'Are you sure you want to edit this compensation structure?',
      },
    });
  },
  mandatory: {
    from_date: 1,
    to_date: 1,
    level: 1,
    package_review_date: 1,
    attendance_base_components: { salary_component_id: 1 },
  },
  uri: ({ navigation }) => {
    const { _id } = (navigation && navigation.getParams('item')) || {};
    return getResource(
      {
        query: 'employeeSalaryComponentDetail',
        model: MODELS.EMPLOYEE_SALARY_COMPONENT,
        addOnFilter: { _id: _id || '1234' },
      },
      { id: '_find' },
    );
  },
  editable: true,
  reloadEvent: 'UserCompensationList',
  computations: commonComputationForEmployeePackage,
  basicDetails: {
    label: 'BASIC DETAILS',
    fieldVariant: 'filled',
    icon: salaryHeartIcon,
    groups: [
      {
        columnsPerRow: 3,
        columns: [
          {
            ...CompensationFormLayouts.FromDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.ToDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.LevelPayEmployee,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.PackageReviewDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            label: 'Total Amount Calculated',
            type: 'number',
            field: 'artificialTotalAmount',
            editable: false,
          },
        ],
      },
    ],
  },
  earningsNestedField: [
    {
      field: 'attendance_base_components',
      fieldType: 'nested',
      Component: NestedTable,
      addInBottom: true,
      componentProps: {
        maxHeight: 400,
        footer: ({ addRow }) => ({
          leftActions: [{
            render: () => (
              <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderRadius: 5,
                width: 120,
                height: 40,
                borderColor: 'grey',
              }}
              >
                <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                  {`+ ${'asset.labels.addRow'.getLabel()}`}
                </Text>
              </View>
            ),
            onPress: addRow,
          }],
        }),
        listProps: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'activeSalaryComponent',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
              variant: 'filled',
            },
            {
              addOnFilter: ({ data: { _parent: { attendance_base_components } = {} } = {} }) => {
                const supplierIds = attendance_base_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                };
              },
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              width: 120,
              variant: 'filled',
              disabled: ({ data }) => data?.salary_component_id?.dependentComponents,
            },
            multiSearchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'dependentComponents',
              model: 'SalaryComponent',
              placeholder: 'Dependent Salary Component',
              header: 'Dependent Salary Component',
              variant: 'filled',
              disabled: true,
            }),
            {
              type: 'number',
              placeholder: 'Dependent Percentage',
              field: 'dependentPercentage',
              header: 'Dependent Percentage',
              width: 120,
              variant: 'filled',
              disabled: true,
            },
            {
              type: 'date',
              placeholder: 'From Date',
              field: 'from_date',
              header: 'From Date',
              variant: 'filled',
            },
            {
              type: 'date',
              placeholder: 'To Date',
              field: 'to_date',
              header: 'To Date',
              variant: 'filled',
            },
            NestedAction(),
          ],
        },
      },
    },
  ],
});

export const EmployeeCompensationDetail = EmployeeCompensationHoc({
  onSubmit: ({ data, updates, navigation }) => {
    let mainData = {};
    const submitMessage = 'Compensation Structure has been updated successfully';
    mainData = { ...data, ...updates };
    mainData
      && mainData.artificialTotalAmount;
    navigation.push({
      view: ROUTES.CompensationStructureConfirmationView.name,
      params: {
        mainData,
        submitMessage,
        description: 'Are you sure you want to edit this compensation structure?',
      },
    });
  },
  mandatory: {
    from_date: 1,
    to_date: 1,
    level: 1,
    package_review_date: 1,
    attendance_base_components: { salary_component_id: 1 },
  },
  uri: ({ navigation }) => {
    const { _id } = (navigation && navigation.getParams('item')) || {};
    return getResource(
      {
        query: 'employeeSalaryComponentDetail',
        model: MODELS.EMPLOYEE_SALARY_COMPONENT,
        addOnFilter: { _id: _id || '1234' },
      },
      { id: '_find' },
    );
  },
  editable: true,
  reloadEvent: 'UserCompensationList',
  // computations: commonComputationForEmployeePackage,
  basicDetails: {
    label: 'BASIC DETAILS',
    fieldVariant: 'filled',
    icon: salaryHeartIcon,
    groups: [
      {
        columnsPerRow: 3,
        columns: [
          {
            ...CompensationFormLayouts.FromDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.ToDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.LevelPayEmployee,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.PackageReviewDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            label: 'Total Amount Calculated',
            type: 'number',
            field: 'artificialTotalAmount',
            editable: false,
          },
        ],
      },
    ],
  },
  earningsNestedField: [
    {
      field: 'attendance_base_components',
      fieldType: 'nested',
      Component: NestedTable,
      addInBottom: true,
      componentProps: {
        maxHeight: 400,
        footer: ({ addRow }) => ({
          leftActions: [{
            render: () => (
              <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderRadius: 5,
                width: 120,
                height: 40,
                borderColor: 'grey',
              }}
              >
                <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                  {`+ ${'asset.labels.addRow'.getLabel()}`}
                </Text>
              </View>
            ),
            onPress: addRow,
          }],
        }),
        listProps: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'activeSalaryComponent',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
              variant: 'filled',
            },
            {
              addOnFilter: ({ data: { _parent: { attendance_base_components } = {} } = {} }) => {
                const supplierIds = attendance_base_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                  component_type: 'earning_type',
                };
              },
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              // width: 120,
              variant: 'filled',
              disabled: ({ data }) => data?.salary_component_id?.code === 'BP',
            },
            // multiSearchInput({
            //   suggestionField: 'name',
            //   query: 'salaryComponent',
            //   searchField: 'name',
            //   field: 'dependentComponents',
            //   model: 'SalaryComponent',
            //   placeholder: 'Dependent Salary Component',
            //   header: 'Dependent Salary Component',
            //   variant: 'filled',
            //   // disabled: true,
            // }),
            // {
            //   type: 'number',
            //   placeholder: 'Dependent Percentage',
            //   field: 'dependentPercentage',
            //   header: 'Dependent Percentage',
            //   width: 120,
            //   variant: 'filled',
            //   // disabled: true,
            // },
            {
              type: 'date',
              placeholder: 'From Date',
              field: 'from_date',
              header: 'From Date',
              variant: 'filled',
            },
            {
              type: 'date',
              placeholder: 'To Date',
              field: 'to_date',
              header: 'To Date',
              variant: 'filled',
            },
            NestedAction(),
          ],
        },
      },
    },
  ],
  deductionsNestedField: [
    {
      field: 'deductions_components',
      fieldType: 'nested',
      Component: NestedTable,
      addInBottom: true,
      componentProps: {
        maxHeight: 400,
        footer: ({ addRow }) => ({
          leftActions: [{
            render: () => (
              <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderRadius: 5,
                width: 120,
                height: 40,
                borderColor: 'grey',
              }}
              >
                <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                  {`+ ${'asset.labels.addRow'.getLabel()}`}
                </Text>
              </View>
            ),
            onPress: addRow,
          }],
        }),
        listProps: {
          lg: {
            columns: [
              searchInput({
                suggestionField: 'name',
                query: 'activeSalaryComponent',
                searchField: 'name',
                field: 'salary_component_id',
                model: 'SalaryComponent',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                variant: 'filled',
              },
              {
                addOnFilter: ({ data: { _parent: { deductions_components } = {} } = {} }) => {
                  const supplierIds = deductions_components?.filter(
                    ({ salary_component_id }) => salary_component_id?._id,
                  ).map(({ salary_component_id }) => salary_component_id?._id);
                  return {
                    _id: { $nin: supplierIds },
                    component_type: 'deduction_type',
                  };
                },
              }),
              {
                type: 'number',
                placeholder: 'Amount',
                field: 'amount',
                header: 'Amount',
                width: 120,
                variant: 'filled',
                // disabled: ({ data }) => data?.salary_component_id?.dependentComponents,
              },
              // multiSearchInput({
              //   suggestionField: 'name',
              //   query: 'salaryComponent',
              //   searchField: 'name',
              //   field: 'dependentComponents',
              //   model: 'SalaryComponent',
              //   placeholder: 'Dependent Salary Component',
              //   header: 'Dependent Salary Component',
              //   variant: 'filled',
              //   disabled: true,
              // }),
              // {
              //   type: 'number',
              //   placeholder: 'Dependent Percentage',
              //   field: 'dependentPercentage',
              //   header: 'Dependent Percentage',
              //   width: 120,
              //   variant: 'filled',
              //   disabled: true,
              // },
              {
                field: 'from_date',
                type: 'date',
                placeholder: 'From Date',
                header: 'From Date',
                variant: 'filled',
              },
              {
                field: 'to_date',
                type: 'date',
                placeholder: 'To Date',
                header: 'To Date',
                variant: 'filled',
              },
              // {
              //   type: 'text',
              //   header: 'Reason',
              //   placeholder: 'Reason',
              //   field: 'reason',
              //   variant: 'filled',
              //   allowWhiteSpaces: true,
              // },
              NestedAction(),
            ],
          },
        },
      },
    },
  ],
});

export const ViewEmployeeCompensationDetail = EmployeeCompensationHoc({
  editable: false,
  onSubmit: ({ data, updates, navigation }) => {
    let mainData = {};
    const submitMessage = 'Compensation Structure has been updated successfully';
    mainData = { ...data, ...updates };
    mainData
      && mainData.artificialTotalAmount;
    navigation.push({
      view: ROUTES.CompensationStructureConfirmationView.name,
      params: {
        mainData,
        submitMessage,
        description: 'Are you sure you want to edit this compensation structure?',
      },
    });
  },
  mandatory: {
    from_date: 1,
    to_date: 1,
    level: 1,
    package_review_date: 1,
    attendance_base_components: { salary_component_id: 1, amount: 1 },
  },
  uri: ({ navigation }) => {
    const { _id } = (navigation && navigation.getParams('item')) || {};
    return getResource(
      {
        query: 'employeeSalaryComponentDetail',
        model: MODELS.EMPLOYEE_SALARY_COMPONENT,
        addOnFilter: { _id: _id || '1234' },
      },
      { id: '_find' },
    );
  },
  reloadEvent: 'UserCompensationList',
  computations: commonComputationForEmployeePackage,
  basicDetails: {
    label: 'BASIC DETAILS',
    labelStyle: {
      color: 'red',
    },
    fieldVariant: 'filled',
    icon: salaryHeartIcon,
    groups: [
      {
        columnsPerRow: 3,
        columns: [
          {
            label: 'Total Amount Calculated',
            type: 'number',
            field: 'artificialTotalAmount',
            editable: false,
          },
          {
            ...CompensationFormLayouts.FromDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.ToDate,
            mandatory: true,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.LevelPayEmployee,
            variant: 'filled',
          },
          {
            ...CompensationFormLayouts.PackageReviewDate,
            mandatory: true,
            variant: 'filled',
          },
        ],
      },
    ],
  },
  deductionsNestedField: [
    {
      field: 'deductions_components',
      fieldType: 'nested',
      Component: NestedTable,
      addInBottom: true,
      componentProps: {
        maxHeight: 400,
        listProps: {
          sm: {
            columns: [
              searchInput({
                suggestionField: 'name',
                query: 'salaryComponentSuggestions',
                searchField: 'name',
                field: 'salary_component_id',
                model: 'SalaryComponent',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                variant: 'filled',
                mandatory: true,
              }),
              {
                type: 'number',
                placeholder: 'Amount',
                field: 'amount',
                header: 'Amount',
                width: 120,
                variant: 'filled',
                mandatory: true,
              },
              multiSearchInput({
                suggestionField: 'name',
                query: 'salaryComponent',
                searchField: 'name',
                field: 'dependentComponents',
                model: 'SalaryComponent',
                placeholder: 'Dependent Salary Component',
                header: 'Dependent Salary Component',
                variant: 'filled',
                disabled: true,
              }),
              {
                type: 'number',
                placeholder: 'Dependent Percentage',
                field: 'dependentPercentage',
                header: 'Dependent Percentage',
                width: 120,
                variant: 'filled',
                disabled: true,
              },
              {
                field: 'from_date',
                type: 'date',
                placeholder: 'From Date',
                header: 'From Date',
                variant: 'filled',
              },
              {
                field: 'to_date',
                type: 'date',
                placeholder: 'To Date',
                header: 'To Date',
                variant: 'filled',
              },
            ],
          },
          lg: {
            columns: [
              searchInput({
                suggestionField: 'name',
                query: 'salaryComponentSuggestions',
                searchField: 'name',
                field: 'salary_component_id',
                model: 'SalaryComponent',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                variant: 'filled',
              },
              {
                addOnFilter: ({ data: { _parent: { deductions_components } = {} } = {} }) => {
                  const supplierIds = deductions_components?.filter(
                    ({ salary_component_id }) => salary_component_id?._id,
                  ).map(({ salary_component_id }) => salary_component_id?._id);
                  return {
                    _id: { $nin: supplierIds },
                    component_type: 'deduction_type',
                  };
                },
              }),
              {
                type: 'number',
                placeholder: 'Amount',
                field: 'amount',
                header: 'Amount',
                width: 120,
                variant: 'filled',
              },
              multiSearchInput({
                suggestionField: 'name',
                query: 'salaryComponent',
                searchField: 'name',
                field: 'dependentComponents',
                model: 'SalaryComponent',
                placeholder: 'Dependent Salary Component',
                header: 'Dependent Salary Component',
                variant: 'filled',
                disabled: true,
              }),
              {
                type: 'number',
                placeholder: 'Dependent Percentage',
                field: 'dependentPercentage',
                header: 'Dependent Percentage',
                width: 120,
                variant: 'filled',
                disabled: true,
              },
              {
                field: 'from_date',
                type: 'date',
                placeholder: 'From Date',
                header: 'From Date',
                variant: 'filled',
              },
              {
                field: 'to_date',
                type: 'date',
                placeholder: 'To Date',
                header: 'To Date',
                variant: 'filled',
              },
              {
                type: 'text',
                header: 'Reason',
                placeholder: 'Reason',
                field: 'reason',
                variant: 'filled',
                allowWhiteSpaces: true,
              },
              // NestedAction(),
            ],
          },
        },
      },
    },
  ],
  earningsNestedField: [
    {
      field: 'attendance_base_components',
      fieldType: 'nested',
      Component: NestedTable,
      addInBottom: true,
      componentProps: {
        maxHeight: 400,
        listProps: {
          columns: [
            searchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'salary_component_id',
              model: 'SalaryComponent',
              placeholder: 'Salary Component',
              header: 'Salary Component',
              variant: 'filled',
            },
            {
              addOnFilter: ({ data: { _parent: { attendance_base_components } = {} } = {} }) => {
                const supplierIds = attendance_base_components?.filter(
                  ({ salary_component_id }) => salary_component_id?._id,
                ).map(({ salary_component_id }) => salary_component_id?._id);
                return {
                  _id: { $nin: supplierIds },
                  component_type: 'earning_type',
                };
              },
            }),
            {
              type: 'number',
              placeholder: 'Amount',
              field: 'amount',
              header: 'Amount',
              width: 120,
              variant: 'filled',
            },
            multiSearchInput({
              suggestionField: 'name',
              query: 'salaryComponent',
              searchField: 'name',
              field: 'dependentComponents',
              model: 'SalaryComponent',
              placeholder: 'Dependent Salary Component',
              header: 'Dependent Salary Component',
              variant: 'filled',
              disabled: true,
            }),
            {
              type: 'number',
              placeholder: 'Dependent Percentage',
              field: 'dependentPercentage',
              header: 'Dependent Percentage',
              width: 120,
              variant: 'filled',
              disabled: true,
            },
            {
              type: 'date',
              placeholder: 'From Date',
              field: 'from_date',
              header: 'From Date',
              variant: 'filled',
            },
            {
              type: 'date',
              placeholder: 'To Date',
              field: 'to_date',
              header: 'To Date',
              variant: 'filled',
            },
          ],
        },
      },
    },
  ],
});

export const NewEmployeeCompensationDetail = (props) => {
  const { navigation } = props;

  const {
    gradePayLevel, entryLevel, isAcp, joiningDate, nextIncrementDate, lastIncrementDate,
  } = (navigation && navigation.getParams('item')) || {};
  const [loading, setLoading] = useState(true);

  const [basicPay, setBasicPay] = useState(0);

  const getBasicPay = useCallback(async () => {
    try {
      const fetchedBasicPay = await invoke({
        id: 'getBasicPay',
        paramValue: { gradePayLevel, entryLevel, isAcp },
      });

      if (!isEmpty(fetchedBasicPay)) {
        setBasicPay(fetchedBasicPay.basicPay);
      } else {
        setBasicPay(0);
      }
      setLoading(false);
      return fetchedBasicPay;
    } catch (err) {
      setLoading(false);
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      return err;
    }
  }, []);

  useEffect(() => {
    getBasicPay();
  }, []);

  const EmployeeCompensation = EmployeeCompensationHoc({
    onSubmit: ({ data, updates, navigation }) => {
      let mainData = {};
      const submitMessage = 'Compensation Structure has been added successfully';
      mainData = { ...data, ...updates };
      mainData
      && mainData.artificialTotalAmount;
      navigation.push({
        view: ROUTES.CompensationStructureConfirmationView.name,
        params: {
          mainData,
          submitMessage,
          description: 'Are you sure you want to add a compensation structure in this employee?',
        },
      });
    },
    defaultValues: ({ navigation }) => {
      const { _id, name } = (navigation && navigation.getParams('item')) || {};
      const res = {
        employee_id: {
          _id,
          name,
        },
        from_date: new Date(),
        mark_salary: false,
      };
      return {
        ...res,
      };
    },
    mandatory: {
      from_date: 1,
      to_date: 1,
      level: 1,
      package_review_date: 1,
      attendance_base_components: { salary_component_id: 1 },
    },
    computations: {
      self: {
        'Set from & to date': {
          compute: (data) => {
            const fromDate = lastIncrementDate || joiningDate;
            const toDate = nextIncrementDate;
            return {
              set: {
                from_date: fromDate,
                to_date: new Date(moment(toDate).add(-1, 'days')),
              },
            };
          },
          onChange: ['_id'],
        },
        'Select Template for data': {
          compute: (data) => {
            const {
              parent = {},
              attendance_base_components: attendanceBaseComponents,
              deductions_components: dependentComponents,
              fix_base_components: fixBaseComponents,
            } = data;

            let {
              attendance_base_components = attendanceBaseComponents || [],
              deductions_components = dependentComponents || [],
            } = parent || {};

            const fromDate = lastIncrementDate || joiningDate;
            const toDate = nextIncrementDate;

            const {
              ctc_amount = undefined,
              fix_base_components = fixBaseComponents || [],
            } = parent || {};

            attendance_base_components = attendance_base_components.map((com) => {
              if (com?.salary_component_id?.code === 'BP') {
                com.amount = basicPay;
              }
              com.from_date = fromDate;
              com.to_date = new Date(moment(toDate).add(-1, 'days'));
              return com;
            });

            deductions_components = deductions_components.map((com) => {
              if (com?.salary_component_id?.code === 'BP') {
                com.amount = basicPay;
              }
              com.from_date = fromDate;
              com.to_date = new Date(moment(toDate).add(-1, 'days'));
              return com;
            });

            return {
              set: {
                ctc_amount,
                attendance_base_components: calculateComponentsAmount(
                  cloneDeep(attendance_base_components).filter((com) => com.salary_component_id),
                  {},
                  uniq(attendance_base_components.filter((com) => com.salary_component_id)?.map((com) => com?.salary_component_id.name)),
                ),
                deductions_components: calculateComponentsAmount(
                  cloneDeep(deductions_components).filter((com) => com.salary_component_id),
                  {},
                  uniq([...deductions_components, ...attendance_base_components].filter(
                    (com) => com.salary_component_id,
                  )?.map((com) => com?.salary_component_id.name)),
                  attendance_base_components.filter(
                    (com) => com.salary_component_id,
                  ),
                ),
                fix_base_components,
              },
            };
          },
          onChange: ['parent'],
        },
        'Select level for data': {
          compute: () => ({
            set: {
              // attendance_base_components: [],
              // deductions_components: [],
              // fix_base_components: [],
              parent: '',
            },
          }),
          onChange: ['level'],
        },
        artificialTotalAmount: {
          compute: (data) => {
            const response = {};
            response.artificialTotalAmount = ((data
              && data.attendance_base_components
              && data.attendance_base_components.reduce(
                (accum, doc) => accum + doc.amount,
                0,
              ))
              || 0)
              + ((data
                && data.deductions_components
                && data.deductions_components.reduce((accum, doc) => {
                  let total = 0;
                  total = doc.amount;
                  return accum - total;
                }, 0))
                || 0);
            response.ctc_amount = response.artificialTotalAmount;
            return { set: { ...response } };
          },
          onChange: [
            'attendance_base_components',
            'performance_base_components',
            'deductions_components',
            'fix_base_components',
            'attendance_base_components.amount',
            'performance_base_components.amount',
            'performance_base_components.link_to_project',
            'deductions_components.amount',
            'deductions_components.salary_component_id. _id',
            'fix_base_components.amount',
            '_set_ctc',
          ],
        },
        'set attendance component amount': {
          compute: (data) => {
            const { attendance_base_components } = data;

            // let clonedAttendanceComponent = cloneDeep(attendance_base_components);
            return {
              set: {
                attendance_base_components: calculateComponentsAmount(
                  cloneDeep(attendance_base_components).filter((com) => com.salary_component_id),
                  {},
                  uniq(attendance_base_components.filter((com) => com.salary_component_id)?.map((com) => com?.salary_component_id.name)),
                ),
              },
            };
          },
          onChange: ['attendance_base_components.amount'],
        },
        'set deduction component amount': {
          compute: (data) => {
            const { deductions_components = [], attendance_base_components } = data;

            return {
              set: {
                deductions_components: calculateComponentsAmount(
                  cloneDeep(deductions_components).filter((com) => com.salary_component_id),
                  {},
                  uniq([...deductions_components, ...attendance_base_components].filter(
                    (com) => com.salary_component_id,
                  )?.map((com) => com?.salary_component_id.name)),
                  attendance_base_components.filter(
                    (com) => com.salary_component_id,
                  ),
                ),
              },
            };
          },
          onChange: ['deductions_components.amount', 'attendance_base_components.amount'],
        },
        fixed_salary: {
          compute: (data) => {
            const response = {};
            response.fixed_salary = ((data
              && data.attendance_base_components
              && data.attendance_base_components.reduce(
                (accum, doc) => accum + doc.amount,
                0,
              ))
              || 0)
              + ((data.deductions_components
                && data.deductions_components.reduce((accum, doc) => {
                  let total = 0;
                  // Esi employer,gratuity, pf employer ,health insurance
                  if (
                    doc
                    && doc.salary_component_id
                    && (doc.salary_component_id._id.toString()
                      === '53ae739a79df44bac9b0b9f1'
                      || doc.salary_component_id._id.toString()
                      === '53ae73a779df44bac9b0ba0a'
                      || doc.salary_component_id._id.toString()
                      === '53ae738f79df44bac9b0b9da'
                      || doc.salary_component_id._id.toString()
                      === '53ae739d79df44bac9b0b9f7')
                  ) {
                    total = doc.amount || 0;
                  }
                  return accum + total;
                }, 0))
                || 0);
            return { set: { ...response } };
          },
          onChange: [
            '_id',
            'attendance_base_components',
            'attendance_base_components.amount',
            'deductions_components',
            'deductions_components.amount',
            'deductions_components.salary_component_id._id',
          ],
        },
        package_review_date: {
          compute: (data) => {
            const response = {};
            if (data && data.to_date) {
              // response.package_review_date = moment(data.to_date).subtract(1, 'months');
              response.package_review_date = moment(nextIncrementDate).subtract(1, 'days');
              return { set: { ...response } };
            }
            return {};
          },
          onChange: ['to_date'],
        },
      },
      children: {
        attendance_base_components: {
          self: {
            'set amount on select dependent components': {
              compute: (data) => {
                const amount = calculateDependantComponentsAmount(
                  {
                    ...data,
                    dependentComponents: data
                      .salary_component_id?.dependentComponents,
                    dependentPercentage: data
                      .salary_component_id?.dependentPercentage,
                  }, data?._parent?.attendance_base_components,
                );

                return {
                  set: {
                    amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                    dependentComponents: data.salary_component_id?.dependentComponents,
                    dependentPercentage: data.salary_component_id?.dependentPercentage,
                  },
                };
              },
              onChange: ['salary_component_id'],
            },
            'set from and to date ': {
              compute: (data) => {
                const { _parent } = data;
                return {
                  set: {
                    from_date: _parent.from_date,
                    to_date: _parent.to_date,
                  },
                };
              },
              onChange: ['_id', '_parent.from_date', '_parent.to_date'],
            },
            'set amount on percentage change': {
              compute: (data) => {
                let mainJson = {};
                let amount = 0;
                let deductableAmt = 0;
                const {
                  _parent: { attendance_base_components = [] },
                  ctc_percentage: dataMainPercentage = 0,
                  salary_component_id: { _id, dependency_field = [] },
                } = data;

                if (
                  attendance_base_components
                  && attendance_base_components.length
                ) {
                  mainJson = attendance_base_components.reduce((json, one) => {
                    const id = one && one.salary_component_id && one.salary_component_id._id;
                    if (id) json[id] = one;
                    return json;
                  }, {});
                }
                if (dependency_field) {
                  for (const dependancyId of dependency_field) {
                    if (dependancyId._id.toString() === '11111111111111') {
                      amount += (data._parent && data._parent.ctc_amount) || 0;
                    } else {
                      amount
                        += (mainJson
                          && mainJson[dependancyId._id]
                          && mainJson[dependancyId._id].amount)
                        || 0;
                    }
                  }
                  deductableAmt = (amount * dataMainPercentage) / 100 || 0;
                  amount = deductableAmt;
                }
                mainJson[_id].amount = amount;
                return {
                  set: {
                    amount: Math.round(amount),
                  },
                };
              },
              onChange: ['ctc_percentage'],
            },
            'set basic pay on select of basic pay component': {
              compute: (data) => {
                if (data?.salary_component_id?.code === 'BP') {
                  return {
                    set: {
                      amount: Math.round(basicPay),
                    },
                  };
                }
              },
              onChange: ['salary_component_id'],
            },
          },
        },
        deductions_components: {
          self: {
            'set from and to date': {
              compute: (data) => {
                const { _parent } = data;
                return {
                  set: {
                    from_date: _parent.from_date,
                    to_date: _parent.to_date,
                  },
                };
              },
              onChange: ['_id', '_parent.from_date', '_parent.to_date'],
            },
            'set amount on select dependent components': {
              compute: (data) => {
                const amount = calculateDependantComponentsAmount(
                  {
                    ...data,
                    dependentComponents: data
                      .salary_component_id?.dependentComponents,
                    dependentPercentage: data
                      .salary_component_id?.dependentPercentage,
                  }, [
                    ...data?._parent?.attendance_base_components,
                    ...data?._parent?.deductions_components],
                );

                return {
                  set: {
                    amount: Math.round(((amount / 100) * (data.salary_component_id?.dependentPercentage || 0))),
                    dependentComponents: data.salary_component_id?.dependentComponents,
                    dependentPercentage: data.salary_component_id?.dependentPercentage,
                  },
                };
              },
              onChange: ['salary_component_id'],
            },
          },
        },
      },
    },
    editable: true,
    basicDetails: {
      label: 'BASIC DETAILS',
      icon: salaryHeartIcon,
      groups: [
        {
          columnsPerRow: 3,
          columns: [
            {
              ...CompensationFormLayouts.salaryTemplate,
            },
            {
              ...CompensationFormLayouts.FromDate,
              mandatory: true,
            },
            {
              ...CompensationFormLayouts.ToDate,
              mandatory: true,
            },
            {
              ...CompensationFormLayouts.LevelPayEmployee,
            },
            {
              ...CompensationFormLayouts.PackageReviewDate,
              mandatory: true,
            },
            {
              label: 'Total Amount Calculated',
              type: 'number',
              field: 'artificialTotalAmount',
              editable: false,
            },
          ],
        },
      ],
    },
    earningsNestedField: [
      {
        field: 'attendance_base_components',
        fieldType: 'nested',
        Component: NestedTable,
        addInBottom: true,
        componentProps: {
          maxHeight: 400,
          footer: ({ addRow }) => ({
            leftActions: [{
              render: () => (
                <View style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderRadius: 5,
                  width: 120,
                  height: 40,
                  borderColor: 'grey',
                }}
                >
                  <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                    {`+ ${'asset.labels.addRow'.getLabel()}`}
                  </Text>
                </View>
              ),
              onPress: addRow,
            }],
          }),
          listProps: {
            columns: [
              searchInput({
                suggestionField: 'name',
                query: 'activeSalaryComponent',
                searchField: 'name',
                field: 'salary_component_id',
                model: 'SalaryComponent',
                placeholder: 'Salary Component',
                header: 'Salary Component',
                variant: 'filled',
              },
              {
                addOnFilter: ({ data: { _parent: { attendance_base_components } = {} } = {} }) => {
                  const supplierIds = attendance_base_components?.filter(
                    ({ salary_component_id }) => salary_component_id?._id,
                  ).map(({ salary_component_id }) => salary_component_id?._id);
                  return {
                    _id: { $nin: supplierIds },
                    component_type: 'earning_type',
                  };
                },
              }),
              {
                type: 'number',
                placeholder: 'Amount',
                field: 'amount',
                header: 'Amount',
                width: 120,
                variant: 'filled',
                disabled: ({ data }) => data?.salary_component_id?.dependentComponents || data?.salary_component_id?.code === 'BP',
              },
              multiSearchInput({
                suggestionField: 'name',
                query: 'salaryComponent',
                searchField: 'name',
                field: 'dependentComponents',
                model: 'SalaryComponent',
                placeholder: 'Dependent Salary Component',
                header: 'Dependent Salary Component',
                variant: 'filled',
                disabled: true,
              }),
              {
                type: 'number',
                placeholder: 'Dependent Percentage',
                field: 'dependentPercentage',
                header: 'Dependent Percentage',
                width: 120,
                variant: 'filled',
                disabled: true,
              },
              {
                type: 'date',
                placeholder: 'From Date',
                field: 'from_date',
                header: 'From Date',
                variant: 'filled',
              },
              {
                type: 'date',
                placeholder: 'To Date',
                field: 'to_date',
                header: 'To Date',
                variant: 'filled',
              },
              NestedAction(),
            ],
          },
        },
      },
    ],
    reloadEvent: 'UserCompensationList',
  });

  return <EmployeeCompensation {...props} />;
};
