import React, { useState } from 'react';
import { upperFirst } from 'lodash';
import { View } from '../../app-components';
import { categoryIcon } from '../../images';
import SubCategoryCardList from '../SubCategoryCardList';
import FTS from '../../Components/TableFilters/FtsSearch';
import ListActionModal from '../../Components/ListActionModal';

const ViewSubCategoryListModal = (props) => {
  const {
    navigation: {
      getScreenState,
      state: { params: { item, filter, description } = {} } = {},
    },
  } = props;

  const [screenState, setScreenState] = useState(getScreenState());

  return (
    <ListActionModal
      icon={categoryIcon}
      title={upperFirst(item?.code)}
      description={description}
      content={(
        <>
          <View style={{ alignItems: 'center', marginBottom: 8 }}>
            <View>
              <FTS {...props} screenState={screenState} setScreenState={setScreenState} action={{ exp: ['name'] }} />
            </View>
          </View>
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <SubCategoryCardList filter={filter} {...props} dataParams={screenState.dataParams} />
          </View>
        </>
      )}
      {...props}
    />
  );
};
export default ViewSubCategoryListModal;
