import React from 'react';

import { TabNavigator } from '../../../../../app-components/tab/TabNavigator';
import { UserLOPList } from '../../../../../Wired/Attendance/Self/AttendanceLists';
import LeaveRequests from './LeaveRequests ';
import { FilterGroup } from '../../../../../app-components';
import { filterActive, filterInactive } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import { TableActionButton } from '../../../../../app-components/buttons/Buttons';
import vars from '../../../../../theme/vars';
import AttendanceRequest from './AtttendanceRequests';
import childtabTheme from '../../../../../theme/childTabTheme';
import LeaveDetails from './LeaveDetails';

const AttendanceTabs = (props) => {
  const { user } = props;

  return (
    <TabNavigator
      {...props}
      theme={{ ...childtabTheme }}
      tabs={{
        attendance: {
          label: 'Months',
          screen: UserLOPList,
          actions: [
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'month_id',
                  label: 'Month',
                  model: MODELS.MONTHS,
                  id: 'MonthTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Months',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
                {
                  type: 'autoSuggest',
                  field: 'year_id',
                  label: 'Year',
                  model: MODELS.years,
                  id: 'YearTypeSuggestions',
                  searchField: 'name',
                  placeholder: 'Year',
                  suggestionField: 'name',
                  primaryField: 'name',
                  valueField: 'name',
                },
              ],
            }),
          ],
          screenProps: {
            user,
          },
        },
        attendanceRequests: {
          label: 'Attendance Requests',
          screen: AttendanceRequest,
          actions: [
            {
              render: (
                <TableActionButton
                  label="New attendance request"
                  width={200}
                  height={20}
                  borderColor={vars.colors.secondary.color2}
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                />
              ),
              type: 'link',
              link: {
                view: 'update-attendance-for-particular-day',
                modal: true,
                modalProps: {
                  width: 550,
                  height: 280,
                },
              },
            },
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'status',
                  label: 'Status',
                  searchField: 'name',
                  placeholder: 'Status',
                  suggestionField: 'label',
                  primaryField: 'label',
                  valueField: 'value',
                  options: [
                    { label: 'Approved', value: 'approve' },
                    { label: 'Pending', value: 'unapproved' },
                    { label: 'Cancelled', value: 'cancelled' },
                    { label: 'Rejected', value: 'reject' },
                  ],
                },
              ],
            }),
          ],
          screenProps: {
            user,
            isEmployeeDetail: true,
          },
        },
        leaveRequests: {
          label: 'Leave Requests',
          screen: LeaveRequests,
          actions: [
            {
              render: (
                <TableActionButton
                  label="New leave request"
                  width={160}
                  height={20}
                  borderColor={vars.colors.secondary.color2}
                  color={vars.colors.secondary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                />
              ),
              type: 'link',
              link: {
                view: 'new-leave-request',
                modal: true,
                screenProps: {
                  user,
                  isEmployeeDetail: true,
                },
                modalProps: {
                  width: 620,
                  height: 335,
                },
              },
            },
            FilterGroup({
              icon: filterInactive,
              activeIcon: filterActive,
              filters: [
                {
                  type: 'autoSuggest',
                  field: 'status',
                  label: 'Status',
                  searchField: 'name',
                  placeholder: 'Status',
                  suggestionField: 'label',
                  primaryField: 'label',
                  valueField: 'value',
                  options: [
                    { label: 'Approved', value: 'approve' },
                    { label: 'Pending', value: 'unapproved' },
                    { label: 'Cancelled', value: 'cancelled' },
                    { label: 'Rejected', value: 'reject' },
                  ],
                },
              ],
            }),
          ],
          screenProps: {
            user,
            isEmployeeDetail: true,
          },
        },
        // leaveDetails: {
        //   label: 'Leave Register',
        //   screen: LeaveDetails,
        //   actions: [
        //     FilterGroup({
        //       icon: filterInactive,
        //       activeIcon: filterActive,
        //       filters: [
        //         {
        //           type: 'autoSuggest',
        //           field: 'status',
        //           label: 'Status',
        //           searchField: 'name',
        //           placeholder: 'Status',
        //           suggestionField: 'label',
        //           primaryField: 'label',
        //           valueField: 'value',
        //           options: [
        //             { label: 'Approved', value: 'approve' },
        //             { label: 'Pending', value: 'unapproved' },
        //             { label: 'Cancelled', value: 'cancelled' },
        //             { label: 'Rejected', value: 'reject' },
        //           ],
        //         },
        //       ],
        //     }),
        //   ],
        //   screenProps: {
        //     user,
        //     isEmployeeDetail: true,
        //   },
        // },
      }}
    />
  );
};
export default AttendanceTabs;
