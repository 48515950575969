import React from 'react';

import VaccinationLogTable from '../VaccinationLogTable';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import FtsSearch from '../../Components/TableFilters/FtsSearch';
import { View } from '../../app-components';

const VaccinationLogsTableModal = (props) => {
  const {
    navigation: {
      state: {
        params: {
          patientName, addOnFilter, model, id,
        } = {},
      } = {},
    } = {},
  } = props;

  return (
    <ListActionModal
      icon={history}
      title="Vaccination History"
      description={`List of Vaccination of ${patientName}`}
      content={(
        <VaccinationLogTable
          addOnFilter={addOnFilter}
          model={model}
          id={id}
          {...props}
        />
        )}
      {...props}
    />
  );
};

export default VaccinationLogsTableModal;
