import React from 'react';

import {
  Table,
} from '../../app-components';
import { wardListUri } from '../../Queries/speciality';

const card = {
  lg: {
    card: {
      type: 'title',
      title: { primaryField: 'name' },
    },
  },
};

export default (props) => {
  const { filter } = props;

  const WardCardList = Table({
    uri: () => wardListUri({ filter }),
    ...card,

  });
  return <WardCardList {...props} />;
};

