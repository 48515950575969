import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { View, FormField, Image } from '../../../../../app-components';
import { Action } from '../../../../../app-components/action/Action';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { startSessionButton } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import { fetch } from '../../../../../AppServices';
import RenderRooms from './RenderRooms';

const TokenGenerationForm = ({
  form_state: formState, form_context: formContext, department,
}) => {
  const { setValue, handleSubmit } = formContext || {};

  const [rooms, setRooms] = useState([]);

  const { specialtyName, id } = useMemo(() => {
    if (formState?.data?.speciality) {
      const { name, _id } = formState?.data?.speciality ?? {};
      return { specialtyName: name, id: _id };
    }
    return {};
  }, [formState.data.speciality]);

  const fetchRooms = useCallback(async () => {
    const fetchedRoomData = await fetch({
      uri: {
        props: {
          query: {
            id: 'masterDataList',
            addOnFilter: {
              speciality: { _id: id },
            },
          },
          model: MODELS.ROOM,
        },
      },
    });
    return fetchedRoomData;
  }, [id]);

  useEffect(() => {
    if (specialtyName) {
      fetchRooms().then(({ data }) => setRooms(data || []));
    }
  }, [specialtyName]);

  return (
    <View style={{ alignItems: 'center', marginTop: 50, justifyContent: 'space-evenly' }}>
      <FormField
        {
        ...autoSuggestInput({
          field: 'speciality',
          type: 'autoSuggest',
          variant: 'filled',
          keyFiled: 'name',
          suggestionField: 'name',
          valueField: 'name',
          model: MODELS.WARDS,
          query: 'masterDataList',
          placeholder: 'Search Speciality',
          searching: true,
          searchField: 'name',
        },
        {
          addOnFilter: { department: { _id: department?._id } },
        })
        }
      />
      {specialtyName && (
        <RenderRooms rooms={rooms} setValue={setValue} />
      )}
      {!formState?.submitting && (
        <View
          style={{
            flex: 0,
            width: 152,
            height: 152,
            marginTop: 40,
          }}
        >
          <Action
            action={{
              type: 'link',
              push: true,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
              onClick={handleSubmit}
            >
              <Image source={startSessionButton} />
            </View>
          </Action>
        </View>
      )}
    </View>
  );
};
export default TokenGenerationForm;
