import React, {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import {
  View,
} from '../../../../../../app-components';

import { Table } from '../../../../../../app-components/table/Table';
import { getUser, invoke } from '../../../../../../AppServices';
import { MODELS } from '../../../../../../Lib/constants';
import { getString } from '../../../../../../Lib/helpers';

const tabListColumns = [
  {
    type: 'selection',
  },
  {
    header: 'Month',
    type: 'text',
    field: 'month_id',
    value: ({ item }) => item.month_id.name,
  },
  {
    header: 'Year',
    type: 'text',
    field: 'year_id',
    value: ({ item }) => item.year_id.name,
  },
  // {
  //   header: 'account.headers.ecrNo'.getLabel(),
  //   type: 'number',
  //   field: 'employee.ecrNumber',
  // },
  // {
  //   header: 'account.headers.ecrPageNo'.getLabel(),
  //   type: 'number',
  //   field: 'employee.ecrPageNumber',
  // },
  {
    header: 'account.headers.employeeName'.getLabel(),
    type: 'text',
    value: ({ item }) => getString([item.employee.firstName, item.employee.lastName]),
  },
  {
    header: 'account.headers.employeeId'.getLabel(),
    type: 'text',
    field: 'employee.employeeId',
  },
  {
    header: 'account.headers.department'.getLabel(),
    type: 'text',
    field: 'employee.department.name',
    value: ({ item: { employee: { department = [] } = {} } = {} }) => department?.map(({ name }) => name).join(', '),
  },
  {
    header: 'Total Days',
    type: 'text',
    field: 'total_days_id_month',
  },

  {
    header: 'Leaves',
    type: 'text',
    field: 'employee.leaves',
    value: (valueProps) => {
      const { item: { details = [] } = {} } = valueProps;
      const leaves = details[0]?.leave_days || 0;
      return leaves;
    },
  },
  {
    header: 'Absent',
    type: 'text',
    field: 'employee.absent',
    value: (valueProps) => {
      const { item: { details = [] } = {} } = valueProps;
      const leaves = details[0]?.absent_days || 0;
      return leaves;
    },
  },
  {
    header: 'Payble',
    type: 'text',
    field: 'employee.payable',
    value: (valueProps) => {
      const { item: { details = [] } = {} } = valueProps;
      const leaves = details[0]?.total_salary_days || 0;
      return leaves;
    },
  },
];

const SalaryRecordList = (props) => {
  const { navigation } = props;
  const [salaryDetails, setSalaryDetails] = useState();
  const currentMonth = new Date().toLocaleString('default', { month: 'long' }).toString();
  const currentYear = new Date().getFullYear().toString();

  const fetchMonthAndYearId = useCallback(async () => {
    const result = await invoke({
      id: 'getMonthAndYearId',
      paramValue: { currentMonth, currentYear },
    });
    return result;
  }, [currentMonth, currentYear]);

  const setDefaultFilter = useCallback((monthAndYearId = {}) => {
    let dataParams = {};
    dataParams = {
      filters: {
        month_id: { filter: { value: monthAndYearId?.monthId }, value: monthAndYearId?.monthId },
        year_id: { filter: { value: monthAndYearId?.yearId }, value: monthAndYearId?.yearId },
      },
    };
    return { dataParams };
  }, []);

  useEffect(() => {
    (async () => {
      const monthAndYearId = await fetchMonthAndYearId();
      const { dataParams } = setDefaultFilter(monthAndYearId);
      navigation.setScreenState({ dataParams });
    })();
  }, []);

  const tableColumns = useMemo(() => (
    [
      ...tabListColumns,
    ]),
  []);
  const filter = {};
  const user = getUser().role[0].code;
  if (user === 'HOD') {
    filter.salaryStatus = 'hold';
  } else if (user === 'DIRECTOR' || user === 'MS') {
    filter.salaryStatus = 'inReview';
    filter.holdRole = { _id: getUser()._id };
  } else {
    filter.salaryStatus = '';
    filter.invoiceDate = {
      $exists: false,
    };
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Table
          {...props}
          reloadEvent={`reload${MODELS.MONTHLY_ATTENDANCE}`}

          showSelected
          isSelected={({ item }) => item?._id === salaryDetails?._id}
          action={({ item }) => ({
            type: 'link',
            link: () => {
              setSalaryDetails(item);
            },
          })}
          uri={() => ({
            query: {
              id: 'selfMonthlyAttendances',
              addOnFilter: filter,
            },
            model: MODELS.MONTHLY_ATTENDANCE,
          })}
          columns={tableColumns}
          showActionIcon={false}
        />
      </View>
    </View>
  );
};
export default SalaryRecordList;
