import React from 'react';

import { MODELS, ROUTES } from '../../../../Lib/constants';
import { Table } from '../../../../app-components/table/Table';
import WithPermission from '../../../../Components/WithPermission';
import { editRecord } from '../../../../images';
import { Image, TouchableOpacity } from '../../../../app-components';
import permissions from '../../../../Lib/permissions';
import { BuyerListUri } from '../../../../Queries/auction';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'buyer.labels.buyerName'.getLabel(),
    type: 'text',
    field: 'name',
    width: 200,
  },
  {
    header: 'buyer.labels.address'.getLabel(),
    type: 'text',
    field: 'address',
    numberOfLines: 2,
    width: 350,
  },
  {
    header: 'buyer.labels.email'.getLabel(),
    type: 'text',
    field: 'email',
  },
  {
    header: 'buyer.labels.contactNumber'.getLabel(),
    type: 'number',
    field: 'contactNo',
  },
  {
    header: 'Actions',
    type: 'text',
    align: 'center',
    render: () => (
      <WithPermission
        access_key={
          permissions.auction.views
            .modifyAuctionBuyers.permissions.modifyAuctionBuyers.value
        }
      >
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              height: 24,
              width: 24,
            }}
            source={editRecord}
          />
        </TouchableOpacity>
      </WithPermission>
    ),
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.editBuyer.name,
        params: { item },
      },
    }),
  },
];

const BuyerList = (props) => (
  <Table
    {...props}
    header={{
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label={'buyer.title.buyerManagement'.getLabel()}
        />
      ),
      actions: [
        () => (
          <FtsSearch
            {...props}
            action={{ exp: ['name', 'email'] }}
          />
        ),
      ],
    }}
    reloadEvent={`reload${MODELS.AUCTION_BUYER}`}
    model={MODELS.AUCTION_BUYER}
    uri={() => BuyerListUri()}
    lg={{ columns: tableColumns }}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewBuyer.name,
        params: { item },
      },
    })}
    {...props}
  />
);

export default BuyerList;
