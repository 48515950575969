import React, { useCallback, useEffect } from 'react';
import { Toast, View } from '../../../app-components';
import { Action } from '../../../app-components/action/Action';
import { TableActionButton } from '../../../app-components/buttons/Buttons';
import { Table } from '../../../app-components/table/Table';
import { getUser, gpsStore, invoke } from '../../../AppServices';
import { ROUTES } from '../../../Lib/constants';
import { getErrorString } from '../../../Lib/helpers';
import { appointmentsListUri } from '../../../Queries/appointment';

const tableItems = {
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'Doctor Name',
        type: 'text',
        field: 'doctor_id.name',
        width: 200,
      },
      {
        header: 'Patient Name',
        type: 'text',
        field: 'patient_id.patientName',
        width: 200,
      },
      {
        header: 'Start time',
        type: 'date',
        format: 'DD MM YYYY @ hh:mm A',
        field: 'time_slot.start_time',
        width: 200,
      },
      {
        header: 'End time',
        type: 'date',
        format: 'DD MM YYYY @ hh:mm A',
        field: 'time_slot.end_time',
        width: 200,
      },
      {
        header: '',
        type: 'custom',
        width: 200,
        render: (props) => {
          const { item } = props;
          return (
            <Action
              action={{
                type: 'link',
                link: ({ item }) => ({
                  view: ROUTES.videoAppointment.name,
                  params: { item },
                }),
              }}
              {...props}
            >
              {item?.status === 'pending' ? (
                <TableActionButton
                  label="Join"
                />
) : <View />}
            </Action>
          );
        },
      },
      {
        header: '',
        type: 'custom',
        render: (props) => {
          const { item } = props;
          return (
            <Action
              action={{
                type: 'invoke',
                invoke: async () => {
                  try {
                    const { navigation, eventDispatcher } = props;
                    const result = await invoke({
                      id: 'setAppointmentStatus',
                      paramValue: { data: { ...item, status: 'completed' } },
                    });

                    if (result) {
                      Toast.show({
                        message: 'Success',
                        description: 'Patient marked as consulted',
                        type: 'success',
                        position: 'top',
                        direction: 'right',
                        duration: 3000,
                      });
                      eventDispatcher.notify('reloadDoctorAppointments');
                      navigation.pop();
                    }
                  } catch (e) {
                    Toast.show({
                      message: 'Error',
                      description: `${getErrorString(e)}`,
                      type: 'error',
                      position: 'top',
                      direction: 'right',
                    });
                  }
                },
              }}
              {...props}
            >
              {item?.status === 'pending' ? (
                <TableActionButton
                  label="Mark Completed"
                  width={200}
                />
) : <View />}
            </Action>
          );
        },
      },
    ],
  },
};
const AppointmentPatientList = (props) => {
  const { eventDispatcher } = props;
  const user = getUser() || {};

  const reloadDoctorAppointments = useCallback(() => {
    eventDispatcher.notify('reloadDoctorAppointments');
  }, [eventDispatcher]);

  const subscribeToAppointmentsUpdates = useCallback((doctorId) => {
    gpsStore.subscribeEvent({
      event: `Video_Appointment_${doctorId}_Added`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  const subscribeToAppointmentsReschedule = useCallback((doctorId) => {
    gpsStore.subscribeEvent({
      event: `Video_Appointment_${doctorId}_Reschedule`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  const subscribeToAppointmentCancelled = useCallback((doctorId) => {
    gpsStore.subscribeEvent({
      event: `Video_Appointment_Cancelled_${doctorId}`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  const unSubscribeToAppointmentsUpdates = useCallback((doctorId) => {
    gpsStore.unsubscribeEvent({
      event: `Video_Appointment_${doctorId}_Added`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  const unSubscribeToAppointmentCancelled = useCallback((doctorId) => {
    gpsStore.unsubscribeEvent({
      event: `Video_Appointment_Cancelled_${doctorId}`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  const unSubscribeToAppointmentsReschedule = useCallback((doctorId) => {
    gpsStore.unsubscribeEvent({
      event: `Video_Appointment_${doctorId}_Reschedule`,
      callback: reloadDoctorAppointments,
    });
  }, [reloadDoctorAppointments]);

  useEffect(() => {
    if (user?.employee?._id) {
      subscribeToAppointmentsUpdates(user?.employee?._id);
      subscribeToAppointmentCancelled(user?.employee?._id);
      subscribeToAppointmentsReschedule(user?.employee?._id);
    }
    return () => {
      unSubscribeToAppointmentsUpdates(user?.employee?._id);
      unSubscribeToAppointmentCancelled(user?.employee?._id);
      unSubscribeToAppointmentsReschedule(user?.employee?._id);
    };
  }, [user.employee._id]);

  return (
    <Table
      {...props}
      uri={appointmentsListUri}
      {...tableItems}
      reloadEvent="reloadDoctorAppointments"
    />
  );
};
export default AppointmentPatientList;
