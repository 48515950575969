import React from 'react';

const Anchor = (props) => {
  const { label } = props;

  return (
    <a {...props}>
      {label}
    </a>
  );
};

export default Anchor;
