import React from 'react';

import {
  cancelButton, FilterGroup, save, Text,
} from '../../../app-components';
import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../Components/TableFilters/FtsSearch';
import { filterActive, filterInactive } from '../../../images';
import { MODELS, ROUTES } from '../../../Lib/constants';
import GeneratePharmacyTestBill from './GeneratePharmacyTestBill';
import vars from '../../../theme/vars';
import NewBill from './NewBill';
import PharmacyBillingHistory from './tabNavigator';
import ViewPharmacyBill from './viewPharmacyBill';
import ConfirmationModal from './ConfirmationModal';

export default {
  [ROUTES.pharmacyBill.name]: {
    screen: GeneratePharmacyTestBill,
    expanded: true,
  },
  [ROUTES.pharmacyBillingRecords.name]: {
    screen: PharmacyBillingHistory,
    header: {
      title: ({ selectionCount }) => (
        <HeaderTitleWithCount
          count={selectionCount}
          label="Pharmacy Billing Records"
        />
      ),
      actions: [
        <FtsSearch
          action={{ exp: ['patientId.patientName', 'patientId.uhid', 'consultationId.ODPNumber', 'dispensedBy.name'] }}
          key="vaccination"
          placeholder="Search by Patient Name, UHID, OPD Number, billed by."
        />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggest',
              field: 'department',
              label: 'Department',
              model: MODELS.DEPARTMENTS,
              id: 'masterDataList',
              searchField: 'name',
              placeholder: 'Select Department',
              sugestionField: 'label',
              primaryField: 'name',
              valueField: 'name',
              addOnFilter: { $or: [{ name: 'OPD' }, { name: 'IPD' }] },
            },

          ],
        }),
      ],
    },
    expanded: true,
  },
  [ROUTES.newPharmacyBill.name]: {
    screen: NewBill,
    title: (props) => {
      const { navigation: { state: { params: { data } } = {} } = {} } = props;
      return (
        <Text style={{ ...vars.headings.h8 }}>
          {data[0]?.uhid}
        </Text>
      );
    },
    expanded: true,
    headerActions: [cancelButton, save({ label: 'Generate' })],
  },
  [ROUTES.viewPharmacyBill.name]: {
    screen: ViewPharmacyBill,
    expanded: true,
  },
  [ROUTES.confirmPharmacyLabBillHistory.name]: {
    screen: ConfirmationModal, expanded: true, modal: true,
  },
};
