import React from 'react';

import { getOrderICUDataUri } from '../../../../../../../Queries/ipdOrders';
import { Table } from '../../../../../../../app-components/table/Table';
import { StandardButton } from '../../../../../../../app-components';
import { MODELS, ROUTES } from '../../../../../../../Lib/constants';
import HeaderTitleWithCount from '../../../../../../../Components/HeaderTitileWithCount';

const tabListColumns = () => ([
  {
    header: 'Date',
    type: 'date',
    field: 'orderDate',
    format: 'DD/MM/YYYY',
    width: '15%',
  },
  {
    header: 'Time',
    type: 'date',
    format: 'HH:mm A',
    field: 'orderTime',
    width: '15%',
  },
  {
    header: 'Order',
    type: 'text',
    field: 'orders',
  },
]);

const OrderICUForm = (props) => {
  const {
    data,
    model,
    tableColumns = tabListColumns,
    navigation,
    ...restProps
  } = props;
  const { navigation: { state: { params: { patient: { _id } } } } } = props;
  return (
    <Table
      header={({ aggregates }) => ({
        title:
  <HeaderTitleWithCount
    label="Order"
    count={aggregates?._count}
  />,
        actions: [
          {
            type: 'link',
            render: () => (
              <StandardButton
                label="Log Orders"
              />
            ),
            link: ({ navigation: { state: { params = {} } } }) => ({
              view: ROUTES.logOrderIDU.name,
              params: { ...params },
              push: true,
            }),
          },
        ],
      })}
      action={{
        type: 'link',
        link: (dataItem) => ({
          view: ROUTES.viewOrderIDU.name,
          modal: true,
          params: {
            addOnFilter: {
              _id: dataItem?.item?._id,
            },
            dataParams: dataItem?.item,
          },
        }),
      }}
      reloadEvent={`reload${MODELS.IPD_ICU_ORDER}`}
      lg={{
        columns: tableColumns(),
      }}
      {...restProps}
      navigation={navigation}
      uri={() => getOrderICUDataUri({ admissionId: { _id } })}
    />
  );
};
export default OrderICUForm;
