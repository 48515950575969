import React from 'react';

import WithPermission from '../../../../Components/WithPermission';
import permissions from '../../../../Lib/permissions';

import { Table } from '../../../../app-components/table/Table';
import { ROUTES } from '../../../../Lib/constants';
import { Image } from '../../../../app-components';
import { editRecord } from '../../../../images';
import { ViewHouseAllotmentURI } from '../uri';

const tableItems = {
  lg: {
    columns: [
      {
        header: 'Allottee',
        type: 'text',
        field: 'allottee.name',
      },
      {
        header: 'From Date',
        field: 'fromDate',
        type: 'date',
        format: 'DD MMM YYYY',
      },
      {
        header: 'To Date',
        field: 'toDate',
        type: 'date',
        format: 'DD MMM YYYY',
      },
      {
        header: 'Actions',
        type: 'openActions',
        openActions: [
          {
            render: () => (
              <WithPermission
                access_key={permissions.mdm.views.allotHouseView.permissions.allotHouseView.value}
              >
                <Image
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  source={editRecord}
                />
              </WithPermission>
            ),
            type: 'link',
            link: (props) => ({
              view: ROUTES.editHouseAllotment.name,
              params: { _id: props?.item?._id, item: props?.navigation?.state?.params?.item },
            }),
          },
        ],
      },
    ],
  },
};

const HouseAllotmentTable = (props) => {
  const {
    navigation: {
      state: {
        params: {
          item = {},
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <Table
      uri={ViewHouseAllotmentURI(item._id)}
      {...tableItems}
      {...props}
      reloadEvent="houseAllotmentDataList"
    />
  );
};
export default HouseAllotmentTable;
