
import React from 'react';

import { DateFilter, Text } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import FtsSearch from '../../../../Components/TableFilters/FtsSearch';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { raiseBloodRequestList } from '../../../../Queries/bloodBank';
import vars from '../../../../theme/vars';
import { BLOOD_REQUEST_STATUSES_LABELS_AND_COLORS } from '../constants';

const tabListColumns = [
  { width: 14 },
  {
    header: 'bloodBankRecords.headers.date'.getLabel(),
    type: 'date',
    field: 'date',
    sortable: 'date',
  },
  {
    header: 'bloodBankRecords.headers.department'.getLabel(),
    type: 'text',
    field: 'department.name',
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.speciality'.getLabel(),
    type: 'text',
    value: ({ item: { speciality = [] } = {} }) => speciality?.map((item) => item?.name).toString(),
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.doctorName'.getLabel(),
    type: 'text',
    field: 'doctorName.firstName',
    width: 200,
  },
  {
    header: 'bloodBankRecords.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patient.patientName',
  },
  {
    header: 'bloodBankRecords.headers.status'.getLabel(),
    type: 'text',
    render: ({ item }) => {
      const { color, value } = BLOOD_REQUEST_STATUSES_LABELS_AND_COLORS[item?.status] || {};
      return (
        <Text style={{ ...vars.headings.h11, color }}>
          {value || item?.status}
        </Text>
      );
    },
  },
];

const BloodRequestList = (props) => {
  const {
    data, id, model, addOnFilter, tableColumns = tabListColumns, ...restProps
  } = props;

  return (
    <Table
      uri={() => raiseBloodRequestList({ filter: { is_requested: true } })}
      model={MODELS.BLOOD_REQUEST}
      columns={tableColumns}
      showActionIcon={false}
      reloadEvent={`reload${MODELS.BLOOD_REQUEST}`}
      header={{
        title: () => <HeaderTitleWithCount label="Requests " />,
        actions: [
          () => (
            <FtsSearch
              {...props}
              action={{
                exp: [
                  'patient.patientName',
                  'doctorName.firstName',
                  'department.name',
                  'status',
                ],
              }}
            />
          ),
          <DateFilter field="date" key="date" />,
        ],
      }}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewBloodRequest.name,
          params: { item },
        },
      })}
      {...restProps}
    />
  );
};
export default BloodRequestList;
