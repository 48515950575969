import React from 'react';

/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { post, submit } from '../../../AppServices';
import {
  Form, Toast,
} from '../../../app-components';

import { FormLayouts } from './layouts';
import { invoke } from '../../../app-components/action/InvokeAction';
import { CommonSalaryComponentsUri } from './uri';
import {
  validateInputLength,
} from '../../../Lib/helpers';
import ViewSalaryComponentTabs from './Tabs';
import vars from '../../../theme/vars';
import { multiSearchInput } from '../../../autoSuggestions';
import { MODELS } from '../../../Lib/constants';

export const linkTypeOpenAction = ({
  text = '',
  view,
  type,
  params = '',
  modal,
  modalProps = {},
  icon,
  iconStyle = {},
}) => ({
  type,
  text,
  icon,
  iconStyle,
  link: ({ item }) => ({
    view,
    params: {
      [params]: item,
      item,
    },
    modal,
    modalProps,
  }),
});

export const InvokeTypeConfirmOpenAction = ({
  text,
  title,
  label = '',
  message,
  postMessage,
  reloadEvent,
  model,
  updates,
  data = {},
}) => invoke({
  type: 'invoke',
  label,
  text,
  confirm: {
    title,
    message,
    confirmText: 'Yes',
    cancelText: 'No',
  },
  postMessage,
  reloadEvent,
  invoke: ({ item }) => {
    if (!item) {
      item = data;
    }
    return post({
      data: item,
      updates,
      model,
    });
  },
});

export const LinkTypeActionsForPost = ({
  icon,
  iconStyle = {},
  text,
  updates = {},
  model = '',
  postMessage = '',
}) => {
  if (!Object.keys(updates).length && !model) {
    return {
      text,
      icon,
      iconStyle,
    };
  }
  return {
    text,
    icon,
    iconStyle,
    action: ({ item }) => post({
      data: item,
      updates,
      model,
    }).then(() => {
      Toast.show({ message: postMessage });
    }),
  };
};

export const DrillAction = ({
  params = {}, type, view, routeOptions = {},
}) => ({
  type,
  link: {
    view,
    params,
    routeOptions,
  },
});

const commonFormGroups = [
  {
    columnsPerRow: 2,
    groups: [
      {
        columns: [
          {
            ...FormLayouts.name,
          },
          {
            ...FormLayouts.component_type,
          },
          {
            ...FormLayouts.select_component,
          },
          FormLayouts.payment_type,
          FormLayouts.calculation_base,
          {
            ...FormLayouts.pay_to_id,
          },
          {
            ...FormLayouts.isActive,
          },
          multiSearchInput({
            suggestionField: 'name',
            query: 'salaryComponent',
            searchField: 'name',
            field: 'dependentComponents',
            model: 'SalaryComponent',
            placeholder: 'Dependent Salary Component',
            header: 'Dependent Salary Component',
            variant: 'filled',
          },
          {
            addOnFilter: ({ data }) => {
              const dependentComponents = data?.dependentComponents;
              if (dependentComponents) {
                return {
                  _id: { $nin: dependentComponents?.map((eachComponent) => eachComponent?._id) },
                };
              }
              return {};
            },
          }),
          {
            type: 'number',
            placeholder: 'Dependent Percentage',
            field: 'dependentPercentage',
            header: 'Dependent Percentage',
            variant: 'filled',
            disabled: ({ data }) => !data?.dependentComponents,
          },
          FormLayouts.max_limit,
          multiSearchInput({
            field: 'staffType',
            label: 'employee.labels.staffType'.getLabel(),
            placeholder: 'employee.placeholders.selectStaffType'.getLabel(),
            suggestionField: 'name',
            keyField: 'name',
            valueField: 'name',
            model: MODELS.STAFFTYPE,
            query: 'masterDataList',
          },
          {
            addOnFilter: ({ data }) => {
              const staffType = data?.staffType;
              if (staffType) {
                return {
                  _id: { $nin: staffType?.map((eachType) => eachType?._id) },
                };
              }
              return {};
            },
          }),
        ],
      },
    ],
  },
  {
    columnsPerRow: 2,
    expandable: true,
    label: 'Other Details',
    columns: [
      FormLayouts.not_show_in_salary_slip,
      FormLayouts.not_show_in_salary_statement,
      FormLayouts.is_upper_round,
      FormLayouts.include_in_others,
      FormLayouts.manual_recovery,
      FormLayouts.sequence_for_salary_sheet,
      FormLayouts.is_taxable,
      FormLayouts.is_flexi_component,
      FormLayouts.is_fix_amount,
    ],
  },
];

const validations = {
  name: ({ data }) => validateInputLength(data?.name, 2, 32),
};

export const AddnewSalaryComponent = Form({
  onSubmit: ({ data, updates }) => {
    let mainData = {};
    const componentType = {};
    switch (updates && updates.component_type) {
      case 'earning_type':
        componentType.type = 'e';
        componentType.status = 'a';
        break;
      case 'deduction_type':
        componentType.type = 'd';
        componentType.status = 'a';
        break;
    }
    mainData = { ...data, ...updates, ...componentType };
    return post({
      data: {},
      updates: { ...mainData },
      model: 'SalaryComponent',
    });
  },
  mandatory: {
    name: 1,
    component_type: 1,
    component_calculation_base_id: 1,
    pay_to_id: 1,
    payment_type_id: 1,
  },
  validations,
  closeView: 1,
  submitMessage: 'Salary Component has been added successfully.',
  fieldVariant: 'filled',
  reloadEvent: 'reloadSalaryComponentsList',
  defaultValues: () => {
    const Defaults = { isActive: true };
    Defaults.component_type = 'earning_type';
    return Defaults;
  },
  formGroups: commonFormGroups,
});

export const EditComponent = Form({
  onSubmit: submit({
    model: 'SalaryComponent',
  }),
  closeView: 1,
  submitMessage: 'Salary Component has been Edited Successfully',
  fieldVariant: 'filled',
  reloadEvent: 'reloadSalaryComponentsList',
  mandatory: {
    name: 1,
    component_type: 1,
    component_calculation_base_id: 1,
    pay_to_id: 1,
    payment_type_id: 1,
  },
  uri: ({ navigation }) => {
    const { _id } = navigation && navigation.getParams('item');
    return CommonSalaryComponentsUri(
      {
        query: 'salaryComponentDetails',
        addOnFilter: { _id },
      },
      {},
    );
  },
  defaultValues: ({ navigation }) => {
    const tab = navigation && navigation.getParams('tab');
    const Defaults = {};
    Defaults.component_type = tab;
    return Defaults;
  },
  formGroups: commonFormGroups,
});

export const ViewComponent = Form({
  onSubmit: submit({
    model: 'SalaryComponent',
  }),
  editable: false,
  closeView: 1,
  submitMessage: 'Component Added Successfully',
  fieldVariant: 'filled',
  reloadEvent: 'reloadSalaryComponentsList',
  reloadOnChangeEvent: 'reloadSalaryComponentsList',
  uri: ({ navigation }) => {
    const { _id } = navigation && navigation.getParams('item');
    return CommonSalaryComponentsUri(
      {
        query: 'salaryComponentDetails',
        addOnFilter: { _id },
      },
      {},
    );
  },
  defaultValues: ({ navigation }) => {
    const tab = navigation && navigation.getParams('tab');
    const Defaults = {};
    Defaults.component_type = tab;
    return Defaults;
  },
  verticalFormGroups: [
    {
      formGroups: [
        {
          columnsPerRow: 2,
          groups: [
            {
              columns: [
                {
                  ...FormLayouts.name,
                },
                {
                  ...FormLayouts.component_type,
                },
                FormLayouts.payment_type,
                FormLayouts.calculation_base,
                {
                  ...FormLayouts.pay_to_id,
                },
                {
                  ...FormLayouts.isActive,
                  type: 'status',
                },
                multiSearchInput({
                  suggestionField: 'name',
                  query: 'salaryComponent',
                  searchField: 'name',
                  field: 'dependentComponents',
                  model: 'SalaryComponent',
                  placeholder: 'Dependent Salary Component',
                  label: 'Dependent Salary Component',
                  variant: 'filled',
                }),
                {
                  type: 'number',
                  placeholder: 'Dependent Percentage',
                  field: 'dependentPercentage',
                  label: 'Dependent Percentage',
                  variant: 'filled',
                  disabled: ({ data }) => !data?.dependentComponents,
                },
                multiSearchInput({
                  field: 'staffType',
                  label: 'employee.labels.staffType'.getLabel(),
                  suggestionField: 'name',
                  keyField: 'name',
                  valueField: 'name',
                  model: MODELS.STAFFTYPE,
                  query: 'masterDataList',
                  variant: 'filled',
                }),
              ],
            },
          ],
        },
        {
          columnsPerRow: 2,
          expandable: true,
          label: 'Other Details',
          columns: [
            FormLayouts.not_show_in_salary_slip,
            FormLayouts.not_show_in_salary_statement,
            FormLayouts.is_upper_round,
            FormLayouts.is_taxable,
            FormLayouts.sequence_for_salary_sheet,
            FormLayouts.is_flexi_component,
          ],
        },
      ],
    },
    {
      formGroupsContainerStyle: { backgroundColor: vars.colors.white },
      formGroups: [{
        type: 'custom',
        render: (props) => (
          <ViewSalaryComponentTabs {...props} />
        ),
      }],
    },
  ],

});
