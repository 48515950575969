export const CommonSalaryComponentsUri = (
  { addOnFilter = {}, query = 'salaryComponent' },
  { id = '_find' },
) => ({
  id,
  query: {
    id: query,
    addOnFilter: {
      ...addOnFilter,
    },
    sort: { _createdOn: 1 },

  },
  model: 'SalaryComponent',
});

export const CommonCountUri = ({ filter }) => ({
  query: {
    id: 'salaryComponent',
    addOnFilter: { ...filter },
  },
  model: 'SalaryComponent',
  fetchCount: true,
});
