import React from 'react';

import {
  TouchableOpacity,
  Text,
} from '../../../../../app-components';
import { CanceledBillingHistoryUri, DiscardBillingHistoryUri } from '../../../../../Queries/billing';
import { Table } from '../../../../../app-components/table/Table';
import { ROUTES } from '../../../../../Lib/constants';
import { InvokeTypeConfirmOpenAction } from '../../../../../Wired/Attendance/Requests/Actions';
import vars from '../../../../../theme/vars';

const tableItems = {
  lg: {
    columns: [
      { width: 14 },
      {
        header: 'Uhid',
        type: 'text',
        field: 'patientId.uhid',
      },
      {
        header: 'patient Name',
        type: 'text',
        field: 'patientId.patientName',
      },
      {
        header: 'Patient type',
        type: 'text',
        sortable: 'patientType',
        field: 'patientType',
      },
      {
        header: 'Billing Amount',
        type: 'text',
        field: 'billAmount',
      },
      {
        header: 'Billing Date',
        type: 'date',
        format: 'DD MM YYYY',
        field: 'billingDate',
      },

      {
        header: 'Status',
        type: 'text',
        render: ({ item: { status } = {} }) => {
          const stocksStatus = status;
          let statusColor = vars.colors.warning;
          if (stocksStatus === 'Active') {
            statusColor = vars.colors.primary.color2;
          } else if (stocksStatus === 'Pending from MS') {
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {status}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Text style={{ textDecoration: 'underline', fontSize: 15, color: '#4070C9' }}>View</Text>
          </TouchableOpacity>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.viewLabBillHistory.name,
            params: { item },
          },
        }),
      },
      {
        type: 'moreActions',
        width: 120,
        align: 'left',
        header: 'MoreActions',
        actions: [
          {
            ...InvokeTypeConfirmOpenAction({
              text: 'Approval',
              title: 'Approval Window',
              message: 'Do you want to Approve this Request?',
              postMessage: ' Approved Successfully',
              reloadEvent: 'billingDataList',
              updates: {
                billStatus: false,
                status: 'Discard',
              },
              model: 'lab_billing',
            }),
          },
        ],
      },
    ],
  },
};

const CanceledBillingHistory = (props) => (
  <Table
    uri={CanceledBillingHistoryUri}
    reloadEvent="billingDataList"

    {...tableItems}
    {...props}
  />
);

export default CanceledBillingHistory;
