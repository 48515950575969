import React from 'react';
import ViewAttendanceTabs from './ChildTabs/AttendanceRequests';
import ViewLeaveRequestsTabs from './ChildTabs/LeaveRequests';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';

import tabTheme from './tabTheme';

const RequestsTabNavigation = (props) => (
  <TabNavigator
    theme={{ ...tabTheme }}
    tabs={{
      LeaveRequests: {
        label: 'Leave Requests',
        screen: ViewLeaveRequestsTabs,
      },
      AttendanceRequests: {
        label: 'Attendance Requests',
        screen: ViewAttendanceTabs,
      },
    }}
    {...props}
  />
);
export default RequestsTabNavigation;
