import React from 'react';
import { TabNavigator } from '../../../../../../app-components';
import { countUri } from '../../../../../../Queries/countUri';
import { MODELS } from '../../../../../../Lib/constants';
import ActivityLogsTabList from '../../../../../../Wired/ActivityLogList';

const ViewShiftTabs = (props) => {
  const { data } = props;
  if (!data?._id) {
    return null;
  }
  const ShiftsTabNavigations = TabNavigator({
    tabs: {
      ActivityLogs: {
        label: 'Activity Logs',
        screen: ActivityLogsTabList,
        screenProps: {
          data,
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivity',
          addOnFilter: { id: data?._id },
        },
        countUri: countUri({
          model: MODELS.APP_HISTORY_CHANGES,
          id: 'getHistoryActivityCount',
          filter: { id: data?._id },
        }),
      },
    },
  });
  return <ShiftsTabNavigations {...props} />;
};
export default ViewShiftTabs;
