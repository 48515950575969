import React, { useState } from 'react';
import uuid from 'uuid/v4';
import { View, Text } from '../../../../../../../app-components';
import { FormField } from '../../../../../../../app-components/form/field';

import { Form } from '../../../../../../../app-components/form/Form';
import {
  NestedTable,
  NestedAction,
} from '../../../../../../../app-components/input-components/nestedTable/NestedTable';
import { submit } from '../../../../../../../AppServices';
import { autoSuggestInput } from '../../../../../../../autoSuggestions';
import { newDateInput } from '../../../../../../../compositeInputs';
import { MODELS } from '../../../../../../../Lib/constants';
import vars from '../../../../../../../theme/vars';

const PastIllnessRow = ({ checkBoxLabel, fieldLabel }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...vars.headings.h14,
    }}
  >
    {/* <View style={{
      // width: '50%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

    }}
    > */}
    <View
      style={{
        width: '6%',
      }}
    >
      <FormField
        type="checkBox"
        field={`pastIllness.${fieldLabel}Name`}
        label={checkBoxLabel}
        container="rightLabel"
        editable
        labelStyle={{
          ...vars.headings.h14,
        }}
      />
    </View>
    <Text
      style={{
        fontSize: 12,
        color: vars.colors.grey.color3,
        width: '6%',
        ...vars.headings.h14,
      }}
    >
      If Yes, is it
    </Text>
    <FormField
      type="radioGroup"
      container="rightLabel"
      options={['Controlled', 'Uncontrolled', 'No Treatment']}
      field={`pastIllness.${fieldLabel}Type`}
      editable
      disabled={(disableProps) => {
        const { data: { pastIllness = {} } = {} } = disableProps;
        return !pastIllness[`${fieldLabel}Name`];
      }}
      width="15%"
      style={{
        // width: 400,
      }}
    />
    <Text style={{
      fontSize: 12, color: vars.colors.grey.color3, width: '10%', paddingLeft: '1%',
    }}
    >
      Mention Treatment
    </Text>
    {/* </View> */}

    <View style={{ width: '55%' }}>
      <FormField
        type="textArea"
        field={`pastIllness.${fieldLabel}Treatment`}
        container="rightLabel"
        variant="filled"
        editable
        disabled={(disableProps) => {
          const { data: { pastIllness = {} } = {} } = disableProps;
          if (pastIllness[`${fieldLabel}Type`] === 'Controlled' || pastIllness[`${fieldLabel}Type`] === 'Uncontrolled') {
            return false;
          }
          return true;
        }}
        style={{
        // width: 850,
          marginBottom: 5,
          fontSize: 14,
          borderWidth: 1,
          borderColor: vars.colors.grey.color5,
          borderRadius: 5,
        }}
      />
    </View>
  </View>
);

const CaseHistoryAndExamination = (props) => {
  const [relativeVisibility, setRelativeVisibility] = useState(false);
  return (
    <Form
      // scrollable
      uri={(uriProps) => ({
        query: {
          id: 'caseHistoryData',
          addOnFilter: {
            admissionID: { _id: uriProps?.navigation?.state?.params?.patient?._id },
            speciality: props?.dataParams?.filters?.specialityFilter,
          },
        },
        model: 'case_history_and_examination',
      })}
      onSubmit={submit({
        model: 'case_history_and_examination',
      })}
      defaultValues={(deafultProps) => ({
        cheifComplaint: new Array(4)
          .fill()
          .map(() => ({ _id: `new_${uuid()}` })),
        gpe: {
          meridiem: (new Date().getHours() % 12 || 12) >= 12 ? 'PM' : 'AM',
        },
        admissionID: { _id: deafultProps?.navigation?.state?.params?.patient?._id },
        speciality: props?.dataParams?.filters?.specialityFilter,
      })}
      bodyContainerStyle={{
        margin: 0,
        marginRight: '2%',
      }}
      // In case submodal are used
      // Start
      beforeSubmit={({ data }) => ({
        data,
        updates: data,
      })}
      deepDiff={false}
      // Finish
      saveOnChange
      {...props}
      afterSubmit={({ submitResult: { result } = {}, data }) => ({
        data: {
          ...data,
          _id: result._id,
        },
      })}
      // mandatory={{
      //   narratedBy: 1,
      //   'gpe.pulse': 1,
      // }}
      computations={{
        self: {
          'pastIllness.htnName': {
            compute: (data) => {
              if (data?.pastIllness?.htnName === false) {
                return ({
                  set: {
                    'pastIllness.htnType': '',
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnName'],
          },
          'pastIllness.dmName': {
            compute: (data) => {
              if (data?.pastIllness?.dmName === false) {
                return ({
                  set: {
                    'pastIllness.dmType': '',
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmName'],
          },
          'pastIllness.tbName': {
            compute: (data) => {
              if (data?.pastIllness?.tbName === false) {
                return ({
                  set: {
                    'pastIllness.tbType': '',
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbName'],
          },
          'pastIllness.thyroidName': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidName === false) {
                return ({
                  set: {
                    'pastIllness.thyroidType': '',
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidName'],
          },
          'pastIllness.steroidName': {
            compute: (data) => {
              if (data?.pastIllness?.steroidName === false) {
                return ({
                  set: {
                    'pastIllness.steroidType': '',
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidName'],
          },
          'pastIllness.covidName': {
            compute: (data) => {
              if (data?.pastIllness?.covidName === false) {
                return ({
                  set: {
                    'pastIllness.covidType': '',
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidName'],
          },
          'pastIllness.copdName': {
            compute: (data) => {
              if (data?.pastIllness?.copdName === false) {
                return ({
                  set: {
                    'pastIllness.copdType': '',
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdName'],
          },
          'pastIllness.asthmaName': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaName === false) {
                return ({
                  set: {
                    'pastIllness.asthmaType': '',
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaName'],
          },
          'pastIllness.otherName': {
            compute: (data) => {
              if (data?.pastIllness?.otherName === false) {
                return ({
                  set: {
                    'pastIllness.otherTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.otherName'],
          },
          'pastIllness.htn': {
            compute: (data) => {
              if (data?.pastIllness?.htnType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.htnTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.htnType'],
          },
          'pastIllness.dm': {
            compute: (data) => {
              if (data?.pastIllness?.dmType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.dmTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.dmType'],
          },
          'pastIllness.tb': {
            compute: (data) => {
              if (data?.pastIllness?.tbType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.tbTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.tbType'],
          },
          'pastIllness.thyroid': {
            compute: (data) => {
              if (data?.pastIllness?.thyroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.thyroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.thyroidType'],
          },
          'pastIllness.steroid': {
            compute: (data) => {
              if (data?.pastIllness?.steroidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.steroidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.steroidType'],
          },
          'pastIllness.covid': {
            compute: (data) => {
              if (data?.pastIllness?.covidType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.covidTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.covidType'],
          },
          'pastIllness.copd': {
            compute: (data) => {
              if (data?.pastIllness?.copdType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.copdTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.copdType'],
          },
          'pastIllness.asthma': {
            compute: (data) => {
              if (data?.pastIllness?.asthmaType === 'No Treatment') {
                return ({
                  set: {
                    'pastIllness.asthmaTreatment': '',
                  },
                });
              }
            },
            onChange: ['pastIllness.asthmaType'],
          },
          narratedBy: {
            compute: (data) => {
              if (data?.narratedBy === 'Relative') {
                setRelativeVisibility(true);
              } else {
                setRelativeVisibility(false);
              }
            },
            onChange: ['narratedBy'],
          },
          'personalData.addictionOtherText': {
            compute: (data) => {
              if (data?.personalData?.addictionOther === false) {
                return ({
                  set: {
                    'personalData.addictionOtherText': '',
                  },
                });
              }
            },
            onChange: ['personalData.addictionOther'],
          },
          'immunization.immunizationOtherText': {
            compute: (data) => {
              if (data?.immunization?.immunizationOther === false) {
                return ({
                  set: {
                    'immunization.immunizationOtherText': '',
                  },
                });
              }
            },
            onChange: ['immunization.immunizationOther'],
          },
          'gpe.edemaInput': {
            compute: (data) => {
              if (data?.gpe?.edema === false) {
                return ({
                  set: {
                    'gpe.edemaInput': '',
                  },
                });
              }
            },
            onChange: ['gpe.edema'],
          },
          'localExamination.dreText': {
            compute: (data) => {
              if (data?.localExamination?.dre === false) {
                return ({
                  set: {
                    'localExamination.dreText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.dre'],
          },
          'localExamination.proctoscopyText': {
            compute: (data) => {
              if (data?.localExamination?.proctoscopy === false) {
                return ({
                  set: {
                    'localExamination.proctoscopyText': '',
                  },
                });
              }
            },
            onChange: ['localExamination.proctoscopy'],
          },
          developmentHistory: {
            compute: (data) => {
              if (data?.developmentHistory === 'No') {
                return ({
                  set: {
                    dq: '',
                    motorMileStone: '',
                    languageMilestone: '',
                    stoneMilestone: '',
                  },
                });
              }
            },
            onChange: ['developmentHistory'],
          },
          dietaryHistory: {
            compute: (data) => {
              if (data?.dietaryHistory !== 'Normal Diet') {
                return ({
                  set: {
                    proteinIntake: '',
                    proteinRequired: '',
                    energyIntake: '',
                    energyrequired: '',
                  },
                });
              }
            },
            onChange: ['dietaryHistory'],
          },
        },
      }}
    >
      {({ form_state: formState, form_context: formContext }) => {
        const { handleSubmit } = formContext;
        return (
          <View style={{
            marginBottom: 10,
            overflow: 'hidden',
          }}
          >
            {/* historyTakenBy, narratedBy, relation */}
            <View
              style={{
                marginTop: 42,
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}
            >
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  {...autoSuggestInput(
                    {
                      type: 'autoSuggest',
                      field: 'historyTakenBy',
                      label: 'History Taken By',
                      placeholder: 'History Taken By',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                      variant: 'filled',
                    },
                    // {
                    //   addOnFilter: () => ({
                    //     role: {
                    //       code: 'Supervisor',
                    //     },
                    //   }),
                    // }
                  )}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <FormField
                  type="radioGroup"
                  field="narratedBy"
                  variant="filled"
                  label="Narrated By"
                  container="topLabel"
                  options={['patient', 'Relative']}
                  mandatory
                />
              </View>
              {relativeVisibility ? (
                <View
                  style={{
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="relativeName"
                    variant="filled"
                    label="Relative Name"
                  />
                </View>
              ) : (
                <View />
              )}

            </View>

            {/* Chief Complaint */}
            {/* TODO: Indexing */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Chief Complaints
              </Text>

              <FormField
                {...{
                  field: 'cheifComplaint',
                  fieldType: 'nested',
                  addInBottom: true,
                  Component: NestedTable,
                  componentProps: {
                    maxHeight: 400,
                    footer: ({ addRow }) => ({
                      leftActions: [
                        {
                          render: () => (
                            <View
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                            >
                              <Text
                                style={{
                                  ...vars.headings.h9,
                                  color: vars.colors.grey.color3,
                                }}
                              >
                                {`+ ${'billing.labels.addNewRow'.getLabel()}`}
                              </Text>
                            </View>
                          ),
                          onPress: addRow,
                        },
                      ],
                    }),
                    skipTableHeaderOnNoData: true,
                    listProps: {
                      hideColumnHeader: true,
                      columns: [
                        {
                          type: 'textArea',
                          field: 'complaint',
                          variant: 'filled',
                          placeholder: 'Enter Complaint Here',
                          allowWhiteSpaces: true,
                          editable: true,
                        },
                        NestedAction({
                          containerStyle: {
                            marginTop: 25,
                          },
                        }),
                      ],
                    },
                  },
                }}
              />
            </View>

            {/* HISTORY OF */}
            {/* Previous Surgery */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                HISTORY OF
              </Text>
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                a.&nbsp;&nbsp;Present Illness
              </Text>
              <FormField
                type="textArea"
                field="presentIllness"
                variant="filled"
                placeholder="Enter present illness details..."
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                  // minHeight: '100',
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Past Illness */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                b.&nbsp;&nbsp;Past Illness
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 15,
                }}
              >
                <PastIllnessRow checkBoxLabel="HTN" fieldLabel="htn" />
                <PastIllnessRow checkBoxLabel="DM" fieldLabel="dm" />
                <PastIllnessRow checkBoxLabel="TB" fieldLabel="tb" />
                <PastIllnessRow checkBoxLabel="Thyroid" fieldLabel="thyroid" />
                <PastIllnessRow checkBoxLabel="Steroid" fieldLabel="steroid" />
                <PastIllnessRow checkBoxLabel="Covid" fieldLabel="covid" />
                <PastIllnessRow checkBoxLabel="COPD" fieldLabel="copd" />
                <PastIllnessRow checkBoxLabel="Asthma" fieldLabel="asthma" />
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      width: 100,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="pastIllness.other"
                      label="Other"
                      container="rightLabel"
                      variant="filled"
                      editable
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      color: vars.colors.grey.color3,
                      width: 150,
                      ...vars.headings.h14,
                    }}
                  >
                    If Yes, mention illness
                  </Text>
                  <FormField
                    type="textArea"
                    field="pastIllness.otherTreatment"
                    container="rightLabel"
                    disabled={(disableProps) => {
                      const { data: { pastIllness = {} } = {} } = disableProps;
                      return !pastIllness.other;
                    }}
                    editable
                    variant="filled"
                    style={{
                      width: 850,
                      // minHeight: 30,
                      marginBottom: 5,
                      fontSize: 14,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Family */}
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  fontWeight: 700,
                  marginTop: 15,
                }}
              >
                c.&nbsp;&nbsp;Family &  Socioeconomic
              </Text>
              <FormField
                type="radioGroup"
                field="familySignificance"
                variant="filled"
                container="topLabel"
                options={['Significant', 'Non-Significant']}
                style={{ marginLeft: 5 }}
                labelContainerStyle={{ height: 0 }}
                labelStyle={{ ...vars.headings.h14 }}
              />
            </View>
            <View
              style={{
                marginTop: 10,
                marginLeft: 20,
              }}
            >
              <FormField
                type="textArea"
                field="famiySocioEconomicDescription"
                variant="filled"
                style={{ marginLeft: 5 }}
                labelContainerStyle={{ height: 0 }}
                labelStyle={{ ...vars.headings.h14 }}
              />
            </View>

            {/* Personal & Immnization  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Persoanl */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    marginTop: 15,
                    fontWeight: 700,
                  }}
                >
                  d.&nbsp;&nbsp;Personal
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    i.&nbsp;&nbsp;Appetite
                  </Text>
                  <FormField
                    type="text"
                    allowWhiteSpaces
                    field="personalData.appetite"
                    container="rightLabel"
                    editable
                    variant="filled"
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    ii.&nbsp;&nbsp;Diet
                  </Text>
                  <FormField
                    type="radioGroup"
                    field="personalData.diet"
                    variant="filled"
                    container="rightLabel"
                    options={['Non-Veg', 'Veg']}
                    optionStyle={{ flexDirection: 'row-reverse' }}
                    labelContainerStyle={{ height: 0 }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iii.&nbsp;&nbsp;Sleep
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.sleep"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    iv.&nbsp;&nbsp;Bowel & Bladder
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.bowelAndBladder"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

              </View>

              {/* Immnization */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    v.&nbsp;&nbsp;Addiction
                  </Text>
                  <View
                    style={{
                      width: 100,
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <FormField
                      type="checkBox"
                      field="personalData.addictionSmoking"
                      label="Smoking"
                      container="rightLabel"
                      variant="filled"
                      style={{
                        marginTop: 10,
                      }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <FormField
                        type="checkBox"
                        field="personalData.addictionAlcohol"
                        label="Alcohol"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View
                        style={{
                          width: 131,
                        }}
                      >
                        <FormField
                          type="checkBox"
                          field="personalData.addictionOther"
                          label="Other"
                          container="rightLabel"
                          variant="filled"
                          labelStyle={{ ...vars.headings.h14 }}
                        />
                      </View>
                      <FormField
                        type="text"
                        field="personalData.addictionOtherText"
                        container="rightLabel"
                        variant="filled"
                        editable
                        allowWhiteSpaces
                        disabled={(disableProps) => {
                          const { data: { personalData = {} } = {} } = disableProps;
                          return !personalData.addictionOther;
                        }}
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          // marginTop: 7,
                          // marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 150,
                    }}
                  >
                    vi.&nbsp;&nbsp;Other
                  </Text>
                  <FormField
                    type="text"
                    field="personalData.personalOther"
                    container="rightLabel"
                    variant="filled"
                    allowWhiteSpaces
                    editable
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 600,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>
            </View>

            {/* Birth History */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,

                  display: 'block',
                }}
              >
                e. &nbsp; Birth History
              </Text>
              <FormField
                type="textArea"
                field="birthHistory"
                variant="filled"
                style={{
                  height: 100,
                  paddingLeft: 2,
                  paddingTop: 2,
                  marginTop: 16,
                  marginLeft: 15,
                  fontSize: 14,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
                inputStyle={{
                  outline: 'none',
                  border: 0,
                  padding: '13 12 9',
                  backgroundColor: 'transparent',
                  fontStyle: 'normal',
                  fontSize: 14,
                  textAlign: 'left',
                  minHeight: 95,
                }}
              />
            </View>

            {/* Drug Allergies & Obsteric & Menstruation  */}
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Drug Allergies */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Drug Allergies
                </Text>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    i.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesOne"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    ii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesTwo"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iii.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesThree"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    iv.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFour"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...vars.headings.h14,
                      marginTop: 15,
                      width: 50,
                    }}
                  >
                    v.
                  </Text>
                  <FormField
                    type="text"
                    field="drugAllergies.drugAllergiesFive"
                    container="rightLabel"
                    variant="filled"
                    editable
                    allowWhiteSpaces
                    inputStyle={{
                      paddingTop: 5,
                      borderWidth: 0,
                      outline: 'none',
                    }}
                    style={{
                      width: 700,
                      minHeight: 30,
                      paddingLeft: 2,
                      fontSize: 14,
                      marginTop: 7,
                      // marginLeft: 20,
                      borderWidth: 1,
                      borderColor: vars.colors.grey.color5,
                      borderRadius: 5,
                    }}
                  />
                </View>
              </View>

              {/* Obsteric & Menstruation */}
              <View
                style={{
                  width: '50%',
                  marginTop: 10,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,
                    fontWeight: 700,
                    marginTop: 15,
                  }}
                >
                  f.&nbsp;&nbsp;Immunization
                </Text>
                <View
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                  >
                    <View style={{ marginTop: 10 }}>
                      <FormField
                        type="checkBox"
                        field="immunization.bcg"
                        label="BCG"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="radioGroup"
                      field="immunization.bcgDescription"
                      variant="filled"
                      container="topLabel"
                      options={['Complete', 'Partial', 'Unimmunized']}
                      style={{ marginLeft: 10 }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ marginTop: 10 }}>

                      <FormField
                        type="checkBox"
                        field="immunization.hepb"
                        label="HEPB"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="radioGroup"
                      field="immunization.hepbDescription"
                      variant="filled"
                      container="topLabel"
                      options={['Complete', 'Partial', 'Unimmunized']}
                      style={{ marginLeft: 10 }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ marginTop: 10 }}>

                      <FormField
                        type="checkBox"
                        field="immunization.covid"
                        label="Covid"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="radioGroup"
                      field="immunization.covidDescription"
                      variant="filled"
                      container="topLabel"
                      options={['Complete', 'Partial', 'Unimmunized']}
                      style={{ marginLeft: 10 }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    {/* <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        // marginTop: 15,
                      }}
                    >
                      iv.
                    </Text> */}
                    <View style={{ marginTop: 10 }}>

                      <FormField
                        type="checkBox"
                        field="immunization.polio"
                        label="Polio"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                        style={{ marginLeft: 10, marginTop: 40 }}
                      />
                    </View>
                    <FormField
                      type="radioGroup"
                      field="immunization.polioDescription"
                      variant="filled"
                      container="topLabel"
                      options={['Complete', 'Partial', 'Unimmunized']}
                      style={{ marginLeft: 10 }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ marginTop: 10 }}>

                      <FormField
                        type="checkBox"
                        field="immunization.other"
                        label="Other"
                        container="rightLabel"
                        variant="filled"
                        labelStyle={{ ...vars.headings.h14 }}
                      />
                    </View>
                    <FormField
                      type="radioGroup"
                      field="immunization.otherDescription"
                      variant="filled"
                      container="topLabel"
                      options={['Complete', 'Partial', 'Unimmunized']}
                      style={{ marginLeft: 10}}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Development History */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                }}
              >
                h. &nbsp; Development History
              </Text>
              <FormField
                type="radioGroup"
                field="developmentHistory"
                variant="filled"
                container="rightLabel"
                options={['Yes', 'No']}
                optionStyle={{ flexDirection: 'row-reverse' }}
                labelContainerStyle={{ height: 0 }}
                labelStyle={{ ...vars.headings.h14 }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; If Delayed Then
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  width: 50,
                  marginLeft: 20,
                }}
              >
               &nbsp; DQ
              </Text>
              <FormField
                type="text"
                field="dq"
                container="rightLabel"
                disabled={(disableProps) => {
                  const { data: { developmentHistory = {} } = {} } = disableProps;
                  return !(developmentHistory === 'Yes');
                }}
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Motor Milestone
              </Text>
              <FormField
                type="text"
                field="motorMileStone"
                disabled={(disableProps) => {
                  const { data: { developmentHistory = {} } = {} } = disableProps;
                  return !(developmentHistory === 'Yes');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Language MileStone
              </Text>
              <FormField
                type="text"
                field="languageMilestone"
                disabled={(disableProps) => {
                  const { data: { developmentHistory = {} } = {} } = disableProps;
                  return !(developmentHistory === 'Yes');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Social Milestone
              </Text>
              <FormField
                type="text"
                field="stoneMilestone"
                container="rightLabel"
                disabled={(disableProps) => {
                  const { data: { developmentHistory = {} } = {} } = disableProps;
                  return !(developmentHistory === 'Yes');
                }}
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text
                style={{
                  ...vars.headings.h12,
                  display: 'block',
                }}
              >
                i. &nbsp; Dietary History
              </Text>
              <FormField
                {... autoSuggestInput({
                  label: 'Dietary History',
                  field: 'dietaryHistory',
                  suggestionField: 'label',
                  options: ['Exclusive BreastFeeding', 'Mix Feeding', 'Top Feeding', 'B/F Complementary Feeding', 'Normal Diet'],
                  editable: true,
                  style: {
                    width: 300,
                    marginTop: 16,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  },
                })}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Protein Intake
              </Text>
              <FormField
                type="text"
                field="proteinIntake"
                disabled={(disableProps) => {
                  const { data: { dietaryHistory = {} } = {} } = disableProps;
                  return !(dietaryHistory === 'Normal Diet');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Protein Required
              </Text>
              <FormField
                type="text"
                field="proteinRequired"
                disabled={(disableProps) => {
                  const { data: { dietaryHistory = {} } = {} } = disableProps;
                  return !(dietaryHistory === 'Normal Diet');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Energy Intake
              </Text>
              <FormField
                type="text"
                field="energyIntake"
                disabled={(disableProps) => {
                  const { data: { dietaryHistory = {} } = {} } = disableProps;
                  return !(dietaryHistory === 'Normal Diet');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
              <Text
                style={{
                  marginTop: 10,
                  width: 150,
                  marginLeft: 20,
                }}
              >
               &nbsp; Energy Required
              </Text>
              <FormField
                type="text"
                field="energyrequired"
                disabled={(disableProps) => {
                  const { data: { dietaryHistory = {} } = {} } = disableProps;
                  return !(dietaryHistory === 'Normal Diet');
                }}
                container="rightLabel"
                variant="filled"
                editable
                inputStyle={{
                  paddingTop: 5,
                  borderWidth: 0,
                  outline: 'none',
                }}
                style={{
                  width: 200,
                  minHeight: 30,
                  paddingLeft: 2,
                  fontSize: 14,
                  marginTop: 15,
                  marginLeft: 20,
                  borderWidth: 1,
                  borderColor: vars.colors.grey.color5,
                  borderRadius: 5,
                }}
              />
            </View>
            {/* Examination  */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                marginTop: 10,
                marginLeft: 20,
                paddingTop: 0,
              }}
            >
              <Text style={{ ...vars.headings.h8, display: 'block' }}>
                Examination
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* GPE */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        a.&nbsp;&nbsp;Pallor
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.pallor"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        b.&nbsp;&nbsp;Icterus
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.icterus"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        c.&nbsp;&nbsp;Clubbing
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.clubbing"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Cynaosis
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.cynaosis"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        e.&nbsp;&nbsp;Lymph Node
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.lymphNode"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        f.&nbsp;&nbsp;Edema
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.edema"
                      variant="filled"
                      container="rightLabel"
                      options={['Yes', 'No']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          ...vars.headings.h14,
                          marginTop: 15,
                        }}
                      >
                        h.&nbsp;&nbsp;Tanner Staging
                      </Text>
                    </View>
                    <FormField
                      type="radioGroup"
                      field="gpe.tannerStaging"
                      variant="filled"
                      container="rightLabel"
                      options={['I', 'II', 'III', 'IV', 'V']}
                      optionStyle={{ flexDirection: 'row-reverse' }}
                      labelContainerStyle={{ height: 0 }}
                      labelStyle={{ ...vars.headings.h14 }}
                    />
                  </View>

                  {/* {Vitals} */}
                  <View
                    style={{
                      width: '100%',
                      marginTop: 10,
                      // marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      Vitals
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        a.&nbsp;&nbsp;HR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.hr"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        b.&nbsp;&nbsp;BP
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.bp"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        c.&nbsp;&nbsp;RR
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.rr"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        d.&nbsp;&nbsp;Temperature
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.temperature"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        e.&nbsp;&nbsp;SPO2
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.spo2"
                        container="rightLabel"
                        variant="filled"
                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        f.&nbsp;&nbsp;Hydration
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.hydration"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>

                {/* Systematic Examination */}
                <View
                  style={{
                    width: '50%',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      marginTop: 10,
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      Systematic Examination
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;CNS
                      </Text>
                      <FormField
                        type="number"
                        field="systematicExamination.cns"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;CVS
                      </Text>
                      <FormField
                        type="number"
                        field="systematicExamination.cvs"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;GI
                      </Text>
                      <FormField
                        type="number"
                        field="systematicExamination.gi"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        iv.&nbsp;&nbsp;Respiratory
                      </Text>
                      <FormField
                        type="number"
                        field="systematicExamination.respiratory"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        v.&nbsp;&nbsp;Others
                      </Text>
                      <FormField
                        type="number"
                        field="systematicExamination.others"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                          width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>

                  </View>
                  <View
                    style={{
                      width: '50%',
                      marginTop: 10,
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        ...vars.headings.h12,
                        fontWeight: 700,
                        marginTop: 15,
                      }}
                    >
                      j. Anthropometry
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        i.&nbsp;&nbsp;Weight
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.weight"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        ii.&nbsp;&nbsp;WeightForLength/Height
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.weightForLengthOrHeight"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        iii.&nbsp;&nbsp;Height
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.height"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        iv.&nbsp;&nbsp;Head Circumference
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.headCircumference"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        v.&nbsp;&nbsp;Mid Upper Arm Circumference
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.midUpperArmCircumference"
                        container="rightLabel"
                        variant="filled"
                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        vi.&nbsp;&nbsp;Body Max Index
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.bodyMaxIndex"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 20,
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          ...vars.headings.h14,
                          width: 290,
                          marginTop: 15,
                        }}
                      >
                        vii.&nbsp;&nbsp;Chest Circumference
                      </Text>
                      <FormField
                        type="number"
                        field="gpe.chestCircuference"
                        container="rightLabel"
                        variant="filled"

                        editable
                        inputStyle={{
                          paddingTop: 5,
                          borderWidth: 0,
                          outline: 'none',
                        }}
                        style={{
                        // width: 600,
                          minHeight: 30,
                          paddingLeft: 2,
                          fontSize: 14,
                          marginTop: 15,
                          marginLeft: 20,
                          borderWidth: 1,
                          borderColor: vars.colors.grey.color5,
                          borderRadius: 5,
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>

              {/* Provisional Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Provisional Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="provisionalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
              {/* Fianl Diagnosis */}
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: 10,
                  marginLeft: 20,
                  paddingTop: 0,
                }}
              >
                <Text
                  style={{
                    ...vars.headings.h12,

                    display: 'block',
                  }}
                >
                  Final Diagnosis
                </Text>
                <FormField
                  type="textArea"
                  field="finalDiagnosis"
                  variant="filled"
                  style={{
                    height: 100,
                    paddingLeft: 2,
                    paddingTop: 2,
                    marginTop: 16,
                    marginLeft: 15,
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: vars.colors.grey.color5,
                    borderRadius: 5,
                  }}
                  inputStyle={{
                    outline: 'none',
                    border: 0,
                    padding: '13 12 9',
                    backgroundColor: 'transparent',
                    fontStyle: 'normal',
                    fontSize: 14,
                    textAlign: 'left',
                    minHeight: 95,
                  }}
                />
              </View>
            </View>
          </View>
        );
      }}
    </Form>
  );
};

export default CaseHistoryAndExamination;
