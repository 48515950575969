import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  Image,
} from '@applane/react-core-components';
import { breadCrumbBack } from '../../images';
import { ROUTES } from '../../Lib/constants';

const routes = {};

Object.keys(ROUTES).map((route) => {
  const clonedRoute = ROUTES[route];
  routes[clonedRoute.name] = clonedRoute.title;
  return route;
});

const stringUpperCaseMethod = (string) => {
  if (!string || typeof string !== 'string') {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const getTitle = ({ navigation, title, style = {} }) => {
  if (typeof title === 'function') {
    const returnTitle = title({ navigation });
    if (returnTitle && typeof returnTitle === 'string') {
      return (
        <Text numberOfLines={1} style={style}>
          {stringUpperCaseMethod(returnTitle)}
        </Text>
      );
    }
    return returnTitle;
  }

  return (
    <Text numberOfLines={1} style={style}>
      {
        title && title.includes('svg') || title.includes('png')
          ? <Image source={title} />
          : stringUpperCaseMethod(title)
      }
    </Text>
  );
};

export class Breadcrumb extends React.Component {
  state = {};

  handelRoutePress = ({ index, item }) => {
    const { navigation, state } = this.props;
    const { views } = state || {};
    const count = views.length - 1 - index;
    if (count) navigation.pop(count);
  };

  handelBackPress = () => {
    const { navigation } = this.props;
    navigation.pop(1);
  };

  onMouseEnter = ({ index }) => {
    this.setState({ mouseOver: index });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: void 0 });
  };

  render() {
    const {
      state,
      separator = '>',
      maxWidth,
      containerStyle,
      activeTextStyle,
      hoverTextStyle,
      textStyle,
      viewRowStyle,
      iconStyle,
    } = this.props;
    const { mouseOver } = this.state;
    const { views } = state || {};
    if (maxWidth) {
      containerStyle = {
        ...containerStyle,
        maxWidth,
      };
    }

    if (views && views.length === 1) {
      return <View style={containerStyle} />;
    }
    return (
      <View style={containerStyle}>
        <TouchableOpacity
          onPress={this.handelBackPress}
          style={{
            cursor: 'pointer',
          }}
        >
          <Image
            source={breadCrumbBack}
            style={{
              marginRight: 10,
            }}
          />
        </TouchableOpacity>
        {views
          && views.map((item, index) => {
            let { routeName, view: { title, icon, activeIcon } = {}, navigation } = item || {};
            routeName = routes[routeName] || routeName;
            if (title !== false) {
              title = title || routeName;
            }

            const active = index === views.length - 1;
            let titleStyle = textStyle;
            if (active) {
              icon = activeIcon || icon;
              titleStyle = activeTextStyle || titleStyle;
            }
            if (!title && !icon) {
              return null;
            }
            return (
              <View style={viewRowStyle} key={index}>
                <TouchableOpacity
                  style={viewRowStyle}
                  onMouseLeave={() => {
                    !active && this.onMouseLeave({ index });
                  }}
                  onMouseEnter={() => {
                    !active && this.onMouseEnter({ index });
                  }}
                  onPress={() => {
                    !active && this.handelRoutePress({ item, index });
                    this.onMouseLeave({ index });
                  }}
                >
                  {icon ? <Image source={icon} style={iconStyle} /> : void 0}
                  {icon && title ? <View style={{ width: 8 }} /> : void 0}
                  {title
                    ? getTitle
                      && getTitle({
                        title,
                        style: {
                          ...titleStyle,
                          ...(mouseOver === index ? hoverTextStyle : {}),
                        },
                        navigation,
                      })
                    : void 0}
                </TouchableOpacity>
                {index !== views.length - 1
                  && getTitle
                  && getTitle({
                    title: separator,
                    style: { ...textStyle, paddingLeft: 8, paddingRight: 8 },
                  })}
              </View>
            );
          })}
      </View>
    );
  }
}

export default Breadcrumb;
