/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import { get } from 'lodash';
import isString from 'lodash/isString';
import moment from 'moment';

import { StandardButton, Text, View } from '../app-components';
import { getUser } from '../AppServices';
import { inventoryModules, issueBloodUpdationRequestStatus, purchaseSupplyOrderStatus, ROUTES } from './constants';
import permissions from './permissions';
import vars from '../theme/vars';
import { handleSampleReceivedForBloodRequest, handleSampleSentForBloodRequest } from '../screens/Inventory/BloodBank/helpers';
import { BLOOD_REQUEST_STATUSES } from '../screens/Inventory/BloodBank/constants';

export const regexps = {
  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/,
  zipCode: /^[1-9][0-9]{5}$/,
  mobile: /^[1-9][0-9]{9}$/,
  panCard: /[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  numberRegex: (decimalPlaces = 0) => new RegExp(
    `^[0-9]+${decimalPlaces ? `(\\.[0-9]{0,${decimalPlaces}})?` : ''}$`,
  ),
  slashRegex: (decimalPlaces = 0) => new RegExp(
    `^[0-9]+${decimalPlaces ? `(\\/[0-9]{0,${decimalPlaces}})?` : ''}$`,
  ),
  alphabetsWithSpaces: /^[a-zA-Z ]*$/,
};

const passwordValidationMessage = 'Password must contain 8-30 characters. At least 1 letter, 1 numeric digit and 1 special character.';
export const validatePassword = (
  password,
  message = passwordValidationMessage,
) => {
  if (!password) {
    return;
  }
  const value = regexps.password.test(password);

  if (!value) {
    return message;
  }
};

export const validateConfirmPassword = (data, message = 'Invalid password') => {
  if (!data.newPassword) {
    return;
  }
  const value = regexps.password.test(data.newPassword);
  if (!value) {
    return message;
  }
  if (
    data.newPassword
    && data.confirmPassword
    && data.newPassword !== data.confirmPassword
  ) {
    return 'Passwords do not match. please re-enter';
  }
};

export const validateEmail = (email, message = 'Invalid Email') => {
  if (!email) {
    return;
  }
  const value = regexps.email.test(email);

  if (!value) {
    return message;
  }
};

export const validatePinCode = (zipCode, message = 'Invalid Pin code') => {
  if (!zipCode) {
    return;
  }
  const value = regexps.zipCode.test(zipCode);

  if (!value) {
    return message;
  }
};

export const validatePanCard = (panNumber, message = 'Invalid PanCard') => {
  if (!panNumber) {
    return;
  }
  const value = regexps.panCard.test(panNumber);

  if (!value) {
    return message;
  }
};

export const validateMobile = (mobile, message = 'Invalid Mobile Number') => {
  if (!mobile) {
    return;
  }
  const value = regexps.mobile.test(mobile);

  if (!value) {
    return message;
  }
};

export const validateInputLength = (
  data,
  minValue,
  maxValue,
  message = `Length should be in between ${minValue} to ${maxValue}`,
) => {
  if (!data) {
    return;
  }

  if (!isString(data)) {
    data = data.toString();
  }

  if (data?.length >= minValue && data?.length <= maxValue) {
    return;
  }

  return message;
};

export const validateFileTypesAllowed = (
  fileName,
  fileType = [],
  message,
) => {
  if (!fileName) {
    return;
  }

  const splitedName = fileName.split('.');

  const filetype = splitedName[splitedName.length - 1];
  let allowed = false;

  fileType.forEach((type) => {
    if (type === filetype) {
      allowed = true;
    }
  });

  if (!allowed) {
    return message || `only ${fileType.join(',')} is allowed. Please choose right file.`;
  }
};

export const validateMultipleFileTypesAllowed = (
  fileName,
  fileType = [],
  message,
) => {
  let allFilesOk = true;
  if (!fileName) {
    return;
  }
  if (Array.isArray(fileName)){
    fileName.forEach((file) => {
      const splitedName = file?.name?.split('.');
  
      const filetype = splitedName[splitedName.length - 1];
  
      let allowed = false;
  
      fileType.forEach((type) => {
        if (type === filetype) {
          allowed = true;
        }
      });
      allFilesOk = allowed;
    });
  } else {
    const splitedName = fileName?.name?.split('.');
  
    const filetype = splitedName[splitedName.length - 1];

    let allowed = false;

    fileType.forEach((type) => {
      if (type === filetype) {
        allowed = true;
      }
    });
    allFilesOk = allowed;
  }
  if (!allFilesOk) {
    return message || `only ${fileType.join(',')} is allowed. Please choose right file.`;
  }
  return;
};

export const validateDecimalInputLength = (
  data,
  decimalPoints,
  message = `You can input the only numbers up to ${decimalPoints} decimal places`,
) => {
  if (!data) {
    return;
  }

  if (regexps.numberRegex(decimalPoints).test(data)) {
    return;
  }

  return message;
};

export const validateSlashInputLength = (
  data,
  decimalPoints,
  message,
) => {
  if (!data) {
    return;
  }

  if (regexps.slashRegex(decimalPoints).test(data)) {
    return;
  }

  return message;
};

export const getString = (data, operator) => data?.filter((item) => item).join(operator || ' ');

export const sortByKey = (array, key) => array.sort((a, b) => {
  const x = a[key];
  const y = b[key];
  return y < x ? -1 : y > x ? 1 : 0;
});


export const removeAllSpacesOnChange = ({ value }) => {
  if (value && typeof value === 'string') {
    value = value.split(' ').join('');
  }
  return value;
};

export const trimLeftValueOnChange = ({ value }) => {
  if (value && typeof value === 'string') {
    value = value.trimLeft();
  }
  return value;
};

export const trimValueOnBlur = ({ value }) => {
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  return value;
};

export const formatNumberMaxTwoDecimalOnChange = ({ prevValue, value }) => {
  if (value) {
    if (typeof value === 'string') {
      value = Number(value);
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(value)) {
      const strValue = value.toString();
      const splitValue = strValue.split('.');
      if (splitValue.length > 1) {
        if (splitValue[1].length > 2) {
          value = prevValue;
        }
      }
    }
  }
  return value;
};

export const getErrorString = (e) => {
  let errorResponse = e.response && e.response.data && e.response.data.response;
  if (errorResponse) {
    if (errorResponse && errorResponse.error && errorResponse.error.code) {
      errorResponse = errorResponse.error.code;
    } else {
      errorResponse = errorResponse?.error?.message;
    }
  } else {
    errorResponse = e.message;
  }
  return errorResponse;
};

export const validateAlphabetsWithSpaceOnly = (
  value,
  message = 'Special Characters are not allowed.',
) => {
  if (!value) {
    return;
  }

  if (regexps.alphabetsWithSpaces.test(value)) {
    return;
  }

  return message;
};

export const getModulesPermissions = () => {
  const permissionsKeys = Object.keys(permissions);
  const modules = {};
  for (let index = 0; index < permissionsKeys.length; index += 1) {
    const module = { ...permissions[permissionsKeys[index]] };
    const views = Object.values(module.views);
    const viewsArray = [];
    for (let viewsIndex = 0; viewsIndex < views.length; viewsIndex += 1) {
      const view = { ...views[viewsIndex] };
      view.permissions = Object.values(views[viewsIndex].permissions);
      viewsArray.push(view);
    }
    module.views = viewsArray;
    modules[permissionsKeys[index]] = module;
  }
  return modules;
};

export const getMyValue = (data, keyPath) => get(data, keyPath, '---');

export const validateShift = (data, message = '') => {
  const { startTime, endTime } = data;
  if (startTime === endTime) {
    message = 'Shift Time Should be greater than Zero Minutes.';
  }
  // if (startTime > endTime) {
  //   message = 'Shift End Time Should be Greater than startTime';
  // }
  return message;
};

export const validFromData = (data) => {
  const date = moment(data?.fromDate).format('DD');
  const month = moment(data?.fromDate).format('MM');
  const year = moment(data?.fromDate).format('YYYY');
  if (data?.toDate) {
    const todate = moment(data?.toDate).format('DD');
    const tomonth = moment(data?.toDate).format('MM');
    const toyear = moment(data?.toDate).format('YYYY');
    if (toyear < year || tomonth < month || todate < date) {
      if (month < tomonth || year < toyear) {
        return;
      }
      return 'to date should be greater than fromDate ';
    }
  }
};

export const validToData = (data) => {
  const date = moment(data?.toDate).format('DD');
  const month = moment(data?.toDate).format('MM');
  const year = moment(data?.toDate).format('YYYY');
  if (moment(data?.fromDate)) {
    const fromdate = moment(data?.fromDate).format('DD');
    const frommonth = moment(data?.fromDate).format('MM');
    const fromyear = moment(data?.fromDate).format('YYYY');
    if ((fromyear > year || frommonth > month, fromdate > date)) {
      if (month > frommonth || year > fromyear) {
        return;
      }
      return 'Effective to  should be greater than Effective from ';
    }
  }
};

export const timeConversion = (time) => {
  let hr = '';
  let min = '';
  // eslint-disable-next-line no-param-reassign
  time *= 1;
  let displayValue = '';
  if (time) {
    hr = Math.floor(time);
    min = (time - hr) * 60;
    min = Math.round(min);
  }
  if (time === 0) {
    hr = 0;
    min = 0;
  }
  if (!min) {
    min = `${min.toString()}0`;
  }
  if (hr <= 12) {
    if (hr === 12) {
      displayValue = `${hr}:${min} PM`;
    }
    if (hr < 10) {
      if (min < 10) {
        displayValue = `0${hr}:0${min} AM`;
      } else {
        displayValue = `0${hr}:${min} AM`;
      }
    } else {
      displayValue = `${hr}:${min} AM`;
    }
  }
  if (hr > 12) {
    hr %= 12;
    if (hr === 0) {
      displayValue = `0${hr}:${min} AM`;
    } else if (hr > 0 && hr < 10) {
      displayValue = `0${hr}:${min} PM`;
    } else {
      displayValue = `${hr}:${min} PM`;
    }
  }
  return displayValue;
};

export const validToAndFromDate = (data, message = '') => {
  if (!data) return message;

  if (data?.fromDate && data?.toDate) {
    const from = new Date(data?.fromDate);
    const to = new Date(data?.toDate);
    const value = from > to;
    // eslint-disable-next-line eqeqeq
    if (value == 1) {
      message = 'To Date Should be greater than From Date';
    }
    return message;
  }
};

export const validFutureDate = (data, message = '') => {
  if (!data) return false;

  if (data) {
    const from = new Date(data);
    const to = new Date();
    const value = from > to;
    if (value == 1) {
      return message || ' Date must be less than Current Date';
    }
  }
};

export const calculateRemainingSelfLife = (expiryDate) => {
  if (!expiryDate) return;
  const TodayDate = moment(new Date());
  const expDate = moment(new Date(expiryDate));
  return expDate.diff(TodayDate, 'days');
};

export const calculateTotalShelfLife = (manufacturingDate, expiryDate) => {
  const manufactureDate = moment(new Date(manufacturingDate));
  const expDate = moment(new Date(expiryDate));
  return expDate.diff(manufactureDate, 'days');
};

export const getIsLowShelfLife = (manufacturingDate, expiryDate) => {
  const totalLife = calculateTotalShelfLife(manufacturingDate, expiryDate);
  const remainingLife = calculateRemainingSelfLife(expiryDate);
  const percentageValue = (remainingLife / totalLife) * 100;
  return percentageValue <= 70;
};

export const getExpirationStatus = (date) => {
  const today = moment();
  if (today > moment(date)) {
    return 'Expired';
  }
  return 'Active';
};

export const getRemainingSelfValue = (date) => {
  const today = moment();
  const remainingDays = moment(date).diff(today, 'days');
  if (remainingDays > 0) {
    return remainingDays;
  }
  return '0';
};

export const getDaysLimitForApprovalForPO = (item) => {
  const today = moment();
  const daysLimitForApproval = item?.daysLimitForApproval
    - today.diff(item?._createdOn, 'days');

  return daysLimitForApproval <= 0
    ? '0 day left for approval'
    : `${daysLimitForApproval} days left for approval`;
};

export const getDaysLimitForApprovalForSO = (item) => {
  const today = moment();
  const daysLimitForApproval = item?.daysLimitForApproval
    - today.diff(item?._createdOn, 'days');

  return daysLimitForApproval <= 0
    ? '0 day left for approval'
    : `${daysLimitForApproval} days left for approval`;
};

export const getShiftType = (ShiftType) => ShiftType?.map(
    (shift) => ` ${shift?.name}(${timeConversion(shift?.startTime)} to ${timeConversion(
        shift?.endTime,
      )})`,
  ).toString();

export const getWeekDaysSchedule = (item) => {
  const days = [];
  if (item?.monday) {
    days.push('Mon');
  }
  if (item?.tuesday) {
    days.push('Tue');
  }
  if (item?.wednesday) {
    days.push('Wed');
  }
  if (item?.thursday) {
    days.push('Thu');
  }
  if (item?.friday) {
    days.push('Fri');
  }
  if (item?.saturday) {
    days.push('Sat');
  }
  if (item?.sunday) {
    days.push('Sun');
  }
  return days?.map((day) => ` ${ day}`).toString();
};

export const isSuperadmin = (user) => {
  if (user?.role?.code?.toLowerCase() === 'superadmin') {
    return true;
  }

  const superadmin = Array.isArray(user?.role) && user?.role.filter((role) => role?.code?.toLowerCase() === 'superadmin');
  if (superadmin && superadmin.length) {
    return true;
  }

  return false;
};

export const isCreateSoPermissionGiven = (user = {})=>
{
  const {employee : {role} = {}} = user;
  for(let i=0;i<=role?.length;i++)
  {
    const {permissions} = role[i] ||  {};
    if(permissions?.inventory_purchase_create_supply_order)
    return true
  }

}

export const getRoleIndexes = (rolesIndexes, user = {},type) => {
  const indexes = []
  const {employee: {role} = {}} = user;
  for(let i=0;i<role?.length;i++)
  {
    const {permissions,code} = role[i]
    if(type==='PS')
    {
    
        if(permissions?.inventory_purchase_approve_purchase_orders_as_psi)
        {
          indexes.push(1)
        }
     
        if(permissions?.inventory_purchase_approve_purchase_orders_as_dms)
        {
          indexes.push(2)
        }
      
        if(permissions?.inventory_purchase_approve_purchase_orders_as_fa)
        {
          indexes.push(3)
        }
     
        if(permissions?.inventory_purchase_approve_purchase_orders_as_msi)
        {
          indexes.push(4)
        }
   
        if(permissions?.inventory_purchase_approve_purchase_orders_as_da)
        {
          indexes.push(5)
        }
     
        if(permissions?.inventory_purchase_approve_purchase_orders_as_director)
        {
          indexes.push(6)
        }
    }
    else
    {
    
        if(permissions?.inventory_purchase_approve_supply_orders_as_psi)
        {
          indexes.push(1)
        }
     
        if(permissions?.inventory_purchase_approve_supply_orders_as_dms)
        {
          indexes.push(2)
        }
     
        if(permissions?.inventory_purchase_approve_supply_orders_as_fa)
        {
          indexes.push(3)
        }
      
        if(permissions?.inventory_purchase_approve_Supply_orders_as_msi)
        {
          indexes.push(4)
        }
     
        if(permissions?.inventory_purchase_approve_supply_orders_as_da)
        {
          indexes.push(5)
        }
     
        if(permissions?.inventory_purchase_approve_supply_orders_as_director)
        {
          indexes.push(6)
        }
    }
    
  }
  // const indexes = Array.isArray(user?.role) && user?.role.map((role) => rolesIndexes[role?.code]);
  return indexes || [];
};

export const reduceDaysLimitApprovalBasedOnRole = (
  daysLimitForApproval,
  createdOn, 
  status, 
  {isReview=false, approvalDaysLimit=0}={}
  )=> {
  
  if(status === purchaseSupplyOrderStatus?.APPROVED || status === purchaseSupplyOrderStatus?.STOCK_ENTRY_PENDING) {
    return '--'
  }
  else if(isReview){
  const days = moment(new Date()).diff(createdOn,'days')
  if(approvalDaysLimit-days>=0)
  {
    return (`${approvalDaysLimit - days} days left for approval`)
  }
    return '0 days left for approval'
  }
  else if(daysLimitForApproval) {
    const days = moment(new Date()).diff(createdOn, 'days');
    const daysLimitForApprovalPerRole = Math.floor(daysLimitForApproval / 6); 
    
    if (daysLimitForApprovalPerRole - days >= 0) {
      return (`${daysLimitForApprovalPerRole - days} days left for approval`)
    } else {
      return '0 days left for approval'
    }
  } else {
    return '--'
  }
}

export const getRedirectionViewDetails = (type, item) => {
  switch (type) {
    case 'central_asset_inventory_stock_status': return {
      view: ROUTES.viewInventoryAsset.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_asset_inventory_stock_status': return {
      view: ROUTES.assetViewDepartmentInventory.name,
      params: {
        item:item?.data,
        navigation: true,
      },
    };
    case 'department_leave_request': return {
      view: ROUTES.accountsInfo.name,
      action: {
        view: 'LeaveRequests',
        action: {
          view: 'pending',
        },
      },
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_attendance_request': return {
      view: ROUTES.accountsInfo.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'AttendanceRequests',
        action: {
          view: 'pending',
        },
      },
    };
    case 'department_tax_declared_request': return {
      view: ROUTES.accountsInfo.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'TaxDeclaration',
        action: {
          view: 'taxDeclaration',
        },
      },
    };
    case 'department_tax_declaration_requested_document_uploaded': return {
      view: ROUTES.accountsInfo.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'TaxDeclaration',
        action: {
          view: 'taxDeclaration',
        },
      },
    };
    case 'employee_tax_freezed': return {
      view: ROUTES.profile.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'Salary',
        action: {
          view: 'TaxDeclarations',
        },
      },
    };
    case 'employee_tax_declaration_document_request': return {
      view: ROUTES.profile.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'Salary',
        action: {
          view: 'TaxDeclarations',
        },
      },
    };
    case 'employee_tax_declaration_approved': return {
      view: ROUTES.profile.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'Salary',
        action: {
          view: 'TaxDeclarations',
        },
      },
    };
    case 'employee_tax_declaration_rejected': return {
      view: ROUTES.profile.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        view: 'Salary',
        action: {
          view: 'TaxDeclarations',
        },
      },
    };
    case 'employee_leave_request': return {
      view: ROUTES.profile.name,
      params: {
        items: { employee: item?.data?.user },
        navigation: true,
      },
      action: {
        view: 'Attendance',
        action: {
          view: 'leaveRequests',
        },
      },
    };
    case 'employee_attendance_request': return {
      view: ROUTES.profile.name,
      params: {
        items: { employee: item?.data?.user },
        navigation: true,
      },
      action: {
        view: 'Attendance',
        action: {
          view: 'attendanceRequests',
        },
      },
    };
    case 'asset_inventory_msi_raise_indent_status': return {
      view: ROUTES.msiViewIndentRequestAsset.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_asset_inventory_raise_indent_status': return {
      view: ROUTES.viewDepartmentIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'central_asset_inventory_raise_indent_status': return {
      view: ROUTES.viewIndentRequestAsset.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'asset_msi_transfer_stocks_request_departments': return {
      view: ROUTES.msiViewAssetTransferRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'asset_central_transfer_stocks_request_departments': return {
      view: ROUTES.viewTransferRequestAsset.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'asset_department_transfer_stocks_request_departments': return {
      view: ROUTES.viewTransferRecordsAsset.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'central_inventory_stock_status': return {
      view: ROUTES.viewInventoryItem.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'central_inventory_stock_status_asset_consumables': return {
      view: ROUTES.viewInventoryItem.name,
      params: {
        item: item?.data,
        navigation: true,
        hidePatientDispensing: true,
        queryID: 'assetConsumablesItemList',
        raisePurchaseRequestDefaultValues: {
          module: inventoryModules.assetConsumables,
        },
        editItemPermissionId: permissions.inventory.views
          .editAssetConsumablesItem.permissions.editAssetConsumablesItem.value,
        raisePurchaseRequestPermissionId: permissions.inventory
          .views.raisePurchaseRequestAssetConsumables
          .permissions.raisePurchaseRequestAssetConsumables.value,
        defaultValues: {
          module: inventoryModules.assetConsumables,
        },
      },
    };
    case 'central_loanOut_update_requests': return {
      view: ROUTES.centralAssetItemDetail.name,
      params: {
        item: item?.data,
        navigation: true,
        isModal:false
      },
      routeOptions: {
        expanded: true,
      },
    };
    case 'central_service_pending_update': return {
      view: ROUTES.centralAssetItemDetail.name,
      params: {
        item: item?.data,
        navigation: true,
        isModal:false
      },
      routeOptions: {
        expanded: true,
      },
    };
    case 'department_inventory_raise_indent_status': return {
      view: ROUTES.viewIndentRecord.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_inventory_raise_indent_status_asset_consumables': return {
      view: ROUTES.viewIndentRecord.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId: 'assetConsumablesInventoryIndentdataList'
      },
    };
    case 'msi_inventory_raise_indent_status': return {
      view: ROUTES.msiViewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'msi_inventory_raise_indent_status_asset_consumables': return {
      view: ROUTES.msiViewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId: 'assetConsumablesInventoryIndentdataList'
      },
    };
    case 'department_loanOut_update_requests': return {
      view: ROUTES.assetItemDetail.name,
      routeOptions: {
        expanded: true,
      },
      params: {
        item: item?.data,
        navigation: true,
        isModal:false
      },
    };
    case 'department_inventory_indent_request_status': return {
      view: ROUTES.msiViewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_inventory_indent_stock_provided': return {
      view: ROUTES.msiViewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'central_inventory_indent_request_status': return {
      view: ROUTES.viewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'central_inventory_indent_request_status_asset_consumables': return {
      view: ROUTES.viewIndentRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId:'assetConsumablesInventoryIndentdataList',
      },
    };
    case 'pharmacy_batch_expiry_status': return {
      view: ROUTES.viewInventoryItem.name,
      params: {
        item: item?.data?.item,
        navigation: true,
      },
    };
    case 'batch_expiry_status_asset_consumables': return {
      view: ROUTES.viewInventoryItem.name,
      params: {
        item: item?.data?.item,
        navigation: true,
        hidePatientDispensing: true,
        queryID: 'assetConsumablesItemList',
        raisePurchaseRequestDefaultValues: {
          module: inventoryModules.assetConsumables,
        },
        editItemPermissionId: permissions.inventory.views
          .editAssetConsumablesItem.permissions.editAssetConsumablesItem.value,
        raisePurchaseRequestPermissionId: permissions.inventory
          .views.raisePurchaseRequestAssetConsumables
          .permissions.raisePurchaseRequestAssetConsumables.value,
        defaultValues: {
          module: inventoryModules.assetConsumables,
        },
      },
    };
    case 'msi_inventory_low_shelf_life_request': return {
      view: ROUTES.msiViewLowShelfLifeRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'msi_inventory_low_shelf_life_request_asset_consumables': return {
      view: ROUTES.msiViewLowShelfLifeRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId:'assetConsumablesInventoryBatchLowShelfLifeRequests'
      },
    };
    case 'msi_transfer_stocks_request_raised_between_departments': return {
      view: ROUTES.msiviewTransferRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'msi_transfer_stocks_request_raised_between_departments_asset_consumables': return {
      view: ROUTES.msiviewTransferRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId: 'assetConsumablesTransferRequestsDataList'
      },
    };
    case 'transfer_stocks_request_raised_between_departments': return {
      view: ROUTES.viewTransferRequestRecord.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'transfer_stocks_request_raised_between_departments_asset_consumables': return {
      view: ROUTES.viewTransferRequestRecord.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId: 'assetConsumablesTransferRequestsDataList'
      },
    };
    case 'transfer_stocks_between_departments': return {
      view: ROUTES.pharmacyDepartmentInventory.name,
      params: {
        navigation: true,
      },
    };
    case 'transfer_stocks_between_departments_asset_consumables': return {
     view: ROUTES.assetConsumablesDepartmentInventory.name,
      params: {
        navigation: true,
      },
    };
    case 'pharmacy_batch_update_requests': return {
      view: ROUTES.viewBatchUpdateRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'batch_update_requests_asset_consumables': return {
      view: ROUTES.viewBatchUpdateRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId:'assetConsumablesBatchRequestsDataList'
      },
    };
    case 'batch_update_requests_raised': return {
      view: ROUTES.msiViewBatchUpdateRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'batch_update_requests_raised_asset_consumables': return {
      view: ROUTES.msiViewBatchUpdateRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId:'assetConsumablesBatchRequestsDataList'
      },
    };
    case 'assets_consumables_low_shelf_life_requests': return {
      view: ROUTES.viewLowShelfLifeRequest.name,
      params: {
        item: item?.data,
        navigation: true,
        queryId: 'assetConsumablesInventoryBatchLowShelfLifeRequests'
      },
    };
    case 'low_shelf_life_requests': return {
      view: ROUTES.viewLowShelfLifeRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'asset_purchase_request_status': return {
      view: ROUTES.viewCentralPurchaseRequestAsset.name,
      params: {
        item: {
          purchaseRequestAsset: item?.data,
          _id: item?.data?.purchaseRequestEntity,
        },
        navigation: true,
      },
    };
    case 'purchase_request_status': return {
      view: ROUTES.viewPurchaseRequestAsset.name,
      params: {
        item: {
          purchaseRequestAsset: item?.data,
          _id: item?.data?.purchaseRequestEntity,
        },
        navigation: true,
      },
    }; 
    case 'department_inventory_stock_status': return {
      view: ROUTES.pharmacyDepartmentInventory.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'department_inventory_stock_status_asset_consumables': return {
      view: ROUTES.assetConsumablesDepartmentInventory.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'raised_blood_request_notification': return {
      view: ROUTES.viewBloodRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        render: (props) =>   {
          const {item} = props;
          if(item.status !== BLOOD_REQUEST_STATUSES.SAMPLE_REQUESTED) return <View/>;

          return (
          <StandardButton 
            width={140}
            label= "Sample Sent"
            onPress={() => {
              handleSampleSentForBloodRequest({
              ...props, bloodRequest: props?.item
              });
              props.afterClick(props);
            }}
            style-lg= {{
              padding: 6,
              height: 20,
              justifyContent: 'center',
              marginTop: 6,
              marginBottom: 6,
              borderRadius: 10
            }}
        />
        )}
      }
    };
    case 'blood_bank_blood_request_sample_sent': return {
      view: ROUTES.viewBloodRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
      action: {
        render: (props) =>   {
          const {item} = props;
          if(item.status !== BLOOD_REQUEST_STATUSES.SAMPLE_SENT) return <View/>;

          return (
          <StandardButton 
            width={140}
            label= "Sample Received"
            onPress={() => {
              handleSampleReceivedForBloodRequest({
              ...props, bloodRequest: props?.item
              });
              props.afterClick(props);
            }}
            style-lg= {{
              padding: 6,
              height: 20,
              justifyContent: 'center',
              marginTop: 6,
              marginBottom: 6,
              borderRadius: 10
            }}
        />
        )}
      }
    };
    case 'notify_blood_bag_expiry_status': return {
      view: ROUTES.viewBloodCategory.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'blood_inventory_stock_status': return {
      view: ROUTES.bloodBankInventoryList.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'PO_SO_creation_status_pharmacy': return {
      view: ROUTES.viewPurchaseRequest.name,
      params: {
        navigation: true,
        item:item?.data
      },
    };
    case 'PO_SO_Creation_status_asset_consumables': return {
      view: ROUTES.viewPurchaseRequest.name,
      params: {
        navigation: true,
        item:item?.data,
        queryId:'purchaseRequestAssetConsumablesList'
      },
    };
    case 'PO_SO_Creation_status_asset': return {
      view: ROUTES.viewCentralPurchaseRequestAsset.name,
      params: {
        navigation: true,
        item: item?.data
      },
    };
    case 'PO_SO_approval_role_order': return {
      view: item?.data?.type === 'PO' ? ROUTES.viewPurchasesRequest.name : ROUTES.viewSupplyRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'PO_SO_approval_notification': return {
      view: item?.data?.type === 'PO' ? ROUTES.viewPurchasesRequest.name : ROUTES.viewSupplyRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'po_so_rejection_role_order': return {
      view: item?.data?.type === 'PO' ? ROUTES.viewPurchasesRequest.name : ROUTES.viewSupplyRequest.name,
      params: {
        item: item?.data,
        navigation: true,
      },
    };
    case 'purchase_request_status_pharmacy': return {
      view: ROUTES.viewPurchaseRequestPharmacy.name,
      params: {
        item: {
          purchaseRequestPharmacy: item?.data,
          _id: item?.data?.purchaseRequestEntity,
        },
        navigation: true,
      },
    };
    case 'purchase_request_status_asset': return {
      view: ROUTES.viewPurchaseRequestAsset.name,
      params: {
        item: {
          purchaseRequestAsset: item?.data,
          _id: item?.data?.purchaseRequestEntity,
        },
        navigation: true,
      },
    };
    case 'purchase_request_status_pharmacy_asset_consumables': return {
      view: ROUTES.viewPurchaseRequestPharmacy.name,
      params: {
        item: {
          purchaseRequestPharmacy: item?.data,
          _id: item?.data?.purchaseRequestEntity,
        },
        queryId:'purchaseRequestAssetConsumablesList',
        navigation: true,
      },
    };
    case 'asset_monthly_remarks_remainder': return {
      view: ROUTES.assetsIncargeRecordList.name,
      params: {
        navigation: true
      },
    };
    case 'asset_in_auction': return {
      view: ROUTES.viewAuctionItem.name,
      params: {
        navigation: true,
        item:item?.data
      },
    };
    default: return {
    };
  }
};

export const getMonthOfOperation = (soldOnDate=new Date(),_createdOn=new Date())=> {
  return moment(_createdOn).diff(soldOnDate,'months')
}

export const getEstimatedDepreciationValue = (rate=0,Principle=0,months=0)=> {
  return Principle - (Principle * ((1 - (rate/100)) ** (months / 12)))
}
 
export const  getCurrencyIntoWords=(price)=> {
  var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
    dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
    tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
    handle_tens = function(dgt, prevDgt) {
      return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
    },
    handle_utlc = function(dgt, nxtDgt, denom) {
      return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (price += "", isNaN(parseInt(price))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
      case 0:
        words.push(handle_utlc(digit, nxtDigit, ""));
        break;
      case 1:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 2:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
        break;
      case 3:
        words.push(handle_utlc(digit, nxtDigit, "Thousand"));
        break;
      case 4:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 5:
        words.push(handle_utlc(digit, nxtDigit, "Lakh"));
        break;
      case 6:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 7:
        words.push(handle_utlc(digit, nxtDigit, "Crore"));
        break;
      case 8:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 9:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
    }
    str = words.reverse().join("")
  } else str = "";
  return str
}

export const textColorForRequestStatus=(item)=>{
  if (item?.status === issueBloodUpdationRequestStatus.APPROVED) {
    return (
      <Text style={{ ...vars.headings.h11, color: vars.colors.primary.color2 }}>
        {item?.status}
      </Text>
    );
  }
  if (item?.status === issueBloodUpdationRequestStatus.PENDING) {
    return (
      <Text style={{ ...vars.headings.h11, color: vars.colors.warning }}>
        {item?.status}
      </Text>
    );
  }
  return (
    <Text style={{ ...vars.headings.h11, color: vars.colors.error }}>
      {item?.status}
    </Text>
  );
}