import React from 'react';
import {
  View,
} from '../../app-components';
import { issueToCompanyIcon } from '../../images';
import ListActionModal from '../../Components/ListActionModal';
import BloodDonorCardList from '../BloodDonorCardList';

const ViewBloodDonorDocumentListModal = (props) => {
  const { navigation: { state: { params: { item, filter } } } } = props;
  return (
    <ListActionModal
      icon={issueToCompanyIcon}
      title={`Bag Number: ${item?.name}`}
      description="List of Files attached for the donation."
      content={(
        <>
          <View style={{ alignItems: 'center', marginBottom: 36 }} />
          <View style={{ flex: 1, overflow: 'hidden' }}>
            <BloodDonorCardList filter={filter} {...props} />
          </View>
        </>
      )}
      {...props}
    />
  );
};
export default ViewBloodDonorDocumentListModal;

