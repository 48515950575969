
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import { DiseaseList } from '../../../Queries/disease';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { width: 14 },
  {
    header: 'disease.labels.diseaseName'.getLabel(),
    type: 'text',
    field: 'name',
  },
  {
    type: 'textArea',
    field: 'description',
    header: 'disease.labels.description'.getLabel(),
  },
];

export default MasterList({
  model: MODELS.DISEASE,
  uri: () => DiseaseList(),
  editFormId: ROUTES.editDisease.name,
  reloadEvent: `reload${MODELS.DISEASE}`,
  access_key: permissions.mdm.views.addDisease.permissions.addDisease.value,
  tableColumns,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewDisease.name,
      params: { item },
    },
  }),
});
