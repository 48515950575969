import React from 'react';
import moment from 'moment';
import { uniqueId } from 'lodash';

import { FormHoc, Text, View } from '../../../../app-components';
import {
  NestedAction, NestedTable,
} from '../../../../app-components/input-components/nestedTable/NestedTable';
import {
  autoSuggestInput, multiAutoSuggestInput, searchInput,
} from '../../../../autoSuggestions';
import RelationInput from '../../../../Components/RelationInput';
import {
  bloodCategories, bloodCategoriesMap, bloodGroup, bloodRequestTypes,
  genders, MODELS,
} from '../../../../Lib/constants';
import { getUser, submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { validateAlphabetsWithSpaceOnly } from '../../../../Lib/helpers';
import { ageInput } from '../../../../compositeInputs';
import { BLOOD_REQUEST_STATUSES } from '../constants';

const getNewId = () => `new_${uniqueId()}`;

const RaiseBloodRequest = (props) => {
  const user = getUser();
  const today = moment();

  const BloodRequestForm = FormHoc({
    type: 'standard',
    closeView: 1,
    computations: {
      children: {
        raise_blood_request: {
          self: {
            bloodGroup: {
              compute: (data) => {
                if (data?.category && data?.bloodGroup) {
                  return {
                    set: {
                      unitInStocks: data?.category?.unitInStock,
                    },
                  };
                }
                if (data?.bloodGroup) {
                  return {
                    set: {
                      unitInStocks: data?.bloodGroup?.unitInStock,
                    },
                  };
                }

                return {};
              },
              onChange: ['bloodGroup', 'category', 'raise_blood_request'],
            },
            category: {
              compute: () => ({
                set: {
                  category: null,
                },
              }),
              onChange: ['bloodGroup'],
            },

          },
        },
      },
      self: {
        speciality: {
          compute: (data) => {
            if (!data?.speciality?.length) {
              return {
                set: {
                  doctorName: null,
                },
              };
            }
            return {
              set: {
                doctorName: null,
              },
            };
          },
          onChange: ['speciality'],
        },
        department: {
          compute: () => ({
            set: {
              speciality: null,
            },
          }),
          onChange: ['department'],
        },
      },
    },
    submitMessage: 'bloodInventory.messages.bloodRequest'.getLabel(),
    onSubmit: submit({
      model: MODELS.BLOOD_REQUEST,
    }),
    defaultValues: () => ({
      'patient.relation': 'S/O',
      raise_blood_request: [{ _id: getNewId() }],
      is_approved: false,
      is_requested: true,
      status: BLOOD_REQUEST_STATUSES.REQUESTED,
      date: today,
      'patient.ageType': 'Years',
      requestedBy: { _id: user?.employee?._id },
    }),
    reloadEvent: `reload${MODELS.BLOOD_BANK}`,
    mandatory: {
      department: 'Department is mandatory',
      speciality: 'Speciality is mandatory',
      doctorName: 'Doctor name is mandatory',
    },
    validations: {
      patient: ({ data }) => {
        if (!data?.patient?.patientName) return 'This field cannot be empty';
        return false;
      },
      raise_blood_request: {
        bloodGroup: ({ data }) => {
          if (!data?.bloodGroup) {
            return 'This field cannot be empty';
          }
          return false;
        },
        unitRequested: ({ data }) => {
          if (data.unitInStocks) {
            if (data.unitInStocks < data.unitRequested) {
              return 'Requested units can not be more than units available';
            }
          }
          if (data?.unitRequested <= 0) {
            return 'Unit Requested can not be less than or equal to 0';
          }
          if (!data?.unitRequested) return 'This field cannot be empty';
          return false;
        },
      },
    },
    formGroups: [
      {
        label: 'PATIENT DETAILS',
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              searchInput({
                label: 'bloodInventory.labels.uhid'.getLabel(),
                field: 'patient',
                keyField: 'uhid',
                valueField: 'uhid',
                suggestionField: 'uhid',
                model: MODELS.PATIENTS,
                query: 'patientDetails',
                placeholder: 'bloodInventory.placeholders.uhid'.getLabel(),
              }),
              {
                type: 'text',
                label: 'bloodInventory.labels.patientName'.getLabel(),
                field: 'patient.patientName',
                placeholder: 'bloodInventory.placeholders.patientName'.getLabel(),
                mandatory: true,
                allowWhiteSpaces: true,
                modifyValueOnChange: ({ prevValue, value }) => {
                  if (validateAlphabetsWithSpaceOnly(value)) {
                    return prevValue;
                  }
                  return value.replace(/ {2,}/g, ' ');
                },
                disabled: () => true,
              },
              {
                type: 'custom',
                render: () => (
                  <RelationInput
                    {...props}
                    mandatory
                    relationFieldName="patient.relation"
                    relationNameFieldName="patient.relationName"
                    relationNameProps={{
                      allowWhiteSpaces: true,
                      disabled: () => true,
                    }}
                    relationProps={{
                      disabled: () => true,
                    }}
                  />
                ),
              },
              ageInput({
                age: {
                  type: 'number',
                  field: 'patient.age',
                  label: 'bloodInventory.labels.age'.getLabel(),
                  mandatory: true,
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value > 999) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                disabled: () => true,
                ageType: {
                  field: 'patient.ageType',
                },
              }),
              autoSuggestInput({
                label: 'bloodInventory.labels.gender'.getLabel(),
                field: 'patient.gender',
                suggestionField: 'label',
                placeholder: 'bloodInventory.placeholders.selectGender'.getLabel(),
                options: genders,
                disabled: () => true,
                mandatory: true,
              }),
              {
                type: 'textArea',
                field: 'description',
                label: 'bloodInventory.labels.reason'.getLabel(),
                placeholder: 'bloodInventory.placeholders.reason'.getLabel(),
                allowWhiteSpaces: true,
              },
              autoSuggestInput({
                label: 'bloodInventory.labels.requestType'.getLabel(),
                field: 'requestType',
                suggestionField: 'label',
                placeholder: 'bloodInventory.placeholders.requestType'.getLabel(),
                options: bloodRequestTypes,
                mandatory: true,
              }),
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'CURRENT ADMISSION DETAILS',
        columnsPerRow: 3,
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              autoSuggestInput({
                type: 'autoSuggest',
                field: 'department',
                label: 'employee.labels.department'.getLabel(),
                placeholder: 'employee.placeholders.selectDepartment'.getLabel(),
                mandatory: true,
                keyField: 'name',
                valueField: 'name',
                suggestionField: 'name',
                model: MODELS.DEPARTMENTS,
                query: 'masterDataList',
              }),
              multiAutoSuggestInput(
                {
                  field: 'speciality',
                  label: 'employee.labels.speciality'.getLabel(),
                  placeholder: 'employee.placeholders.selectWard'.getLabel(),
                  keyField: 'name',
                  valueField: 'name',
                  suggestionField: 'name',
                  model: MODELS.WARDS,
                  query: 'masterDataList',
                },
                {
                  addOnFilter: ({ data } = {}) => (!data?.department ? {} : {
                    department: { _id: data?.department?._id },
                  }),
                },
              ),
              searchInput({
                label: 'bloodInventory.labels.doctorName'.getLabel(),
                field: 'doctorName',
                keyField: 'firstName',
                valueField: 'firstName',
                suggestionField: 'firstName',
                model: MODELS.EMPLOYEE,
                query: 'employeeListsForTable',
                placeholder: 'bloodInventory.placeholders.doctorName'.getLabel(),
                mandatory: true,
                searching: true,
                searchField: 'firstName',
              },
              {
                addOnFilter: ({ data = {} }) => (!data?.speciality?.length
                  ? {}
                  : {
                    speciality: {
                      _id: {
                        $in: data.speciality.map(
                          (specialityId) => specialityId._id,
                        ),
                      },
                    },
                  }),
              }),
              {
                type: 'file',
                field: 'attachment',
                label: 'bloodInventory.labels.file'.getLabel(),
                container: 'topLabel',
                placeholder: 'bloodInventory.placeholders.file'.getLabel(),
                allowWhiteSpaces: true,
                multiple: true,
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'ISSUE DETAILS',
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'raise_blood_request',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  footer: ({ addRow }) => ({
                    leftActions: [
                      {
                        render: () => (
                          <View
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 120,
                              height: 40,
                              borderColor: vars.colors.secondary.color2,
                            }}
                          >
                            <Text
                              style={{
                                ...vars.headings.h9,
                                color: vars.colors.secondary.color2,
                              }}
                            >
                              {` ${'bloodInventory.labels.addNewItem'.getLabel()}`}
                            </Text>
                          </View>
                        ),
                        onPress: addRow,
                      },
                    ],
                  }),
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      paddingLeft: 12,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      autoSuggestInput(
                        {
                          type: 'autoSuggest',
                          variant: 'filled',
                          header: 'bloodInventory.labels.bloodGroup'.getLabel(),
                          field: 'bloodGroup',
                          suggestionField: 'category',
                          placeholder: 'bloodInventory.placeholders.bloodGroup'.getLabel(),
                          model: MODELS.BLOOD_CATEGORY,
                          query: 'bloodTypeCategoryCount',
                          mandatory: true,
                          width: 150,
                        },
                        {
                          addOnFilter: () => ({
                            category: {
                              $in: bloodGroup.map((group) => group.value),
                            },
                          }),
                        },
                      ),
                      autoSuggestInput(
                        {
                          type: 'autoSuggest',
                          header: 'bloodInventory.labels.category'.getLabel(),
                          field: 'category',
                          variant: 'filled',
                          suggestionField: 'category',
                          placeholder: 'bloodInventory.placeholders.category'.getLabel(),
                          model: MODELS.BLOOD_CATEGORY,
                          query: 'bloodTypeCategoryCount',
                          width: 150,
                          disabled: ({ data }) => !data?.bloodGroup?.category,
                        },
                        {
                          addOnFilter: ({ data } = {}) => ({
                            category: {
                              $in: bloodCategories.map((item) => item.value),
                            },
                            bloodGroup: data?.bloodGroup?.category,
                          }),
                        },
                      ),
                      {
                        type: 'number',
                        field: 'plateletsCount',
                        variant: 'filled',
                        placeholder: 'bloodInventory.placeholders.plateletsCount'.getLabel(),
                        header: 'bloodInventory.labels.plateletsCount'.getLabel(),
                        width: 150,
                        disabled: ({ data }) => data?.category?.category === bloodCategoriesMap.FFP
                        || data?.category?.category === bloodCategoriesMap.PRBC,
                      },
                      {
                        type: 'number',
                        field: 'unitInStocks',
                        variant: 'filled',
                        placeholder: 'bloodInventory.placeholders.unitInStocks'.getLabel(),
                        header: 'bloodInventory.labels.unitInStocks'.getLabel(),
                        width: 350,
                        disabled: true,
                      },
                      {
                        type: 'number',
                        field: 'unitRequested',
                        variant: 'filled',
                        placeholder: 'bloodInventory.placeholders.unitRequested'.getLabel(),
                        header: 'bloodInventory.labels.unitRequested'.getLabel(),
                        width: 350,
                        mandatory: true,
                        disabled: ({ data }) => !data?.bloodGroup || data?.unitInStocks === 0,
                      },
                      NestedAction(),
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  });
  return <BloodRequestForm {...props} />;
};

export default RaiseBloodRequest;
