export default {
  labels: {
    category: 'Category',
    title: 'Title',
    isActive: 'Active / Inactive',
    description: 'Description',
    supplier: 'Supplier',
    manufacturer: 'Manufacturer',
    status: 'Status',
    expiryDate: 'Expiry Date',
    stockEntryDate: 'Stock Entry Date',
    purchasedPrice: 'Purchased Price',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    lastPurchaseDate: 'Last Purchase Date',
    lastPurchaseQuantity: 'Last Purchase Quantity',
    lastIndentDate: 'Last Indent Date',
    lastIndentQuantity: 'Last Indent Quantity',
    avgConsumption: 'Avg. Monthly Consumption',
    requiredQuantity: 'Required Quantity',
    reasonForPurchase: 'Reason',
    unitPrice: 'Unit Price',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    purchaseRequestNumber: 'Purchase Request Number',
    dateRaised: 'Date Raised',
    progressChart: 'Progress Chart',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    purchasePrice: 'Purchase Price',
    indentNumber: 'Indent Number',
    department: 'Department',
    responseDate: 'Response Date',
    providedQuantity: 'Provided Quantity',
    reasonForRejection: 'Reason For Rejection',
    speciality: 'Speciality',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    updatedQuantity: 'Updated Quantity',
    stockAdditionDate: 'Stock Addition Date',
    stockRequested: 'Stock Requested',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'Dispensed By',
    noOfItems: 'No of Items',
    quantityDispensed: 'Quantity Dispensed',
    updatedStock: 'Updated Stock',
    totalPrice: 'Total Price',
    assetName: 'Asset Name',
    quantityAssignedDep: 'Quantity Assigned To Dep.',
    quantityInStocks: 'Quantity In Stocks',
    serialNo: 'Serial No.',
    value: 'Value of Asset',
    days: 'No. of Days',
    lastLoanOutDate: 'Last Loaned-Out Dated',
    lastLoanOutQuantity: 'Last Loaned-Out Quantity',
    manufacturerName: 'Manufacturer Name',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    service: 'Service',
    attachDocuments: 'Attachments',
    preventiveDate: 'Preventive Visit Date',
    serviceDate: 'Service Visit Date',
    repairCharge: 'Repair Charge',
    fromSpeciality: 'From Speciality',
    toSpeciality: 'To Speciality',
    repairPrice: 'Repair Price',
    approximateCost: 'Approximate Cost',
    reasonForRepair: 'Reason For Repair',
    loanOutDays: 'Loan Out Days',
    loanOutQuantity: 'Loan Out Quantity',
    loanOutDate: 'Loan Out Date',
    inService: 'In-Service',
    contractStatus: 'Contract Status',
    loanOutDept: ' Loan Out/Issue Department',
    repairReturnDate: 'Repair Return Date',
    selectItemName: 'Asset Name',
    cost: 'Cost',
    contractEndDate: 'Contract End Date',
    lastRepairDate: 'Last Repair Date',
    supervisor: 'Supervisor',
    inCharge: 'Incharge',
    loanedOutDept: 'Loaned Out Dept.',
    recordMonth: 'Record Month',
    recordYear: 'Record Year',
    remarks: 'Remarks',
    date: 'Date',
    itemCodePrefix: 'Item Prefix',
    name: 'Name',
    phone: 'Phone',
    reason: 'Reason',
    currentValue: 'Current Value',
    estimatedDepreciation: 'Estimated Depreciation',
    soldAtPrice: 'Sold at Price',
    soldOnDate: 'Sold on Date',
    yearOpOperation: 'Years of Operation',
    rate: 'Depreciation Rate',

  },
  headers: {
    itemId: 'Item Id',
    itemName: 'Item Name',
    assetName: 'Asset Name',
    category: 'Category',
    subCategory: 'Sub Category',
    manufacturer: 'Manufacturer',
    description: 'Description',
    batchNo: 'Batch No.',
    supplyReceivedOn: 'Supply Received On',
    stockEntryDate: 'Stock Entry Date',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    remainingSelfLife: 'Remaining Shelf Life',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    quantityInStocks: 'Quantity In Stocks',
    purchasedPrice: 'Purchase Price',
    status: 'Status',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    dateRaised: 'Date Raised',
    purchaseRequestNumber: 'Purchase Request Number',
    department: 'Department',
    noOfItems: 'No of Items',
    responseDate: 'Response Date',
    indentNumber: 'Indent Number',
    active: 'Active/Inactive',
    speciality: 'Speciality',
    avgConsumption: 'Avg. Monthly Consumption',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    date: 'Date',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    batchNumber: 'Batch Number',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    stockAdditionDate: 'Stock Addition Date',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'DispensedBy',
    value: 'Value Of Asset',
    lastLoanOutDate: 'Last Loan Out/Issue Date',
    lastLoanOutDept: 'Last Loan Out/Issue Dept.',
    lastRepairDate: 'Last Repair Date',
    lastLoanOutQuantity: 'Last Loaned-Out/Issued Quantity',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    manufacturerName: 'Manufacturer Name',
    serialNo: 'Serial No.',
    preventiveDate: 'Preventive Visit Date',
    serviceDate: 'Service Visit Date',
    loanOutDays: 'Loaned Out Days',
    repairPrice: 'Repair Price',
    visitStatus: 'Visit Status',
    stockIssued: 'Stock Issued Date',
    item: 'Item',
    repairCharge: 'Repair Charge',
    dateOfEvent: 'DATE OF EVENT',
    returnDate: 'RETURN DATE',
    toSpeciality: 'To Speciality',
    noOfDays: 'No. of Days',
    month: 'Month',
    year: 'Year',
    remarks: 'Remarks',
    action: 'Action',
    reasonForRepair: 'REASON For Repair',
    loanOutDate: 'Loan Out Date',
  },
  messages: {
    updatedMessage: 'Item has been updated Successfully.',
  },
};
