import React from 'react';

import { View, ScrollView, FormField } from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { submit } from '../../../../AppServices';
import { MODELS } from '../../../../Lib/constants';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';
import theme from '../theme';
import Views from './Views';

const AddRole = (props) => (
  <ScrollView style={theme.scrollViewStyle}>
    <Form
      onSubmit={submit({
        model: MODELS.ROLE,
      })}
      submitMessage={'role.messages.roleAddedSuccessfully'.getLabel()}
      reloadEvent="reloadroles"
      uniqueValidationMessage={'role.messages.roleAlreadyExists'.getLabel()}
      mandatory={{
        name: 1,
      }}
      defaultValues={() => ({
        isActive: true,
      })}
      closeView={1}
      validations={{
        name: ({ data }) => validateAlphabetsWithSpaceOnly(data?.name),
        description: ({ data }) => validateInputLength(data?.description, 2, 200),
      }}
      {...props}
    >
      <AddRoleInfoForm {...props} />
    </Form>
  </ScrollView>
);

const AddRoleInfoForm = ({ form_context: formContext = {}, form_state: formState = {} }) => {
  const { setValue } = formContext;
  const { data } = formState;

  return (
    <ScrollView style={theme.containerStyle}>
      <View style={theme.infoFormWrapper}>
        <View style={theme.roleInputWrapper}>
          <FormField
            field="name"
            label="Role"
            type="text"
            placeholder="Role"
            variant="filled"
            allowWhiteSpaces
            allowConsecutiveSpaces
            mandatory
          />
        </View>
        <View style={theme.statusWrapper}>
          <FormField
            field="isActive"
            type="toggleSwitch"
            label="Status"
            container="leftLabel"
            align="center"
            placeholder="Status"
            variant="filled"
            defaultValue={() => ({
              isActive: true,
            })}
            mandatory
          />
        </View>
        <View style={theme.descriptionWrapper}>
          <FormField
            field="description"
            type="textArea"
            label="Description"
            placeholder="Description"
            variant="filled"
            minHeight={80}
            maxHeight={80}
          />
        </View>
      </View>
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Views setValue={setValue} formContext={formContext} data={data} />
      </View>
    </ScrollView>
  );
};

export default AddRole;
