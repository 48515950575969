import React from 'react';

import { Table } from '../../../../../app-components/table/Table';
import { MODELS } from '../../../../../Lib/constants';

const getLeaveDuration = (value) => {
  if (value === 'full_day') {
    return 'Full Day';
  } if (value === 'half_day') {
    return 'Half Day';
  } if (value === 'short_leave') {
    return 'Short Leave';
  }

  return '';
};

const LeaveRequests = (props) => {
  const { user } = props;

  return (
    <Table
      {...props}
      hoverable={false}
      uri={{
        query: {
          id: 'leaveRequests',
          sort: {
            _id: -1,
          },
          addOnFilter: {
            user: {
              _id: user?._id,
            },
          },
        },
        model: MODELS.REQUESTS,

      }}
      reloadEvent="UserLOPDrillRequestsList"
      columns={[
        {
          width: 14,
        },
        {
          type: 'date',
          field: 'request_date',
          header: 'Request Date',
        },
        {
          type: 'date',
          field: 'from_date',
          header: 'From Date',
        },
        {
          type: 'date',
          field: 'to_date',
          header: 'To date',
        },
        {
          type: 'text',
          field: 'duration_of_leave',
          header: 'Duration',
          value: ({ item }) => getLeaveDuration(item.duration_of_leave),
        },
        {
          header: 'Leave Type',
          type: 'text',
          field: 'leave_type_id.name',
        },
        {
          header: 'Status',
          type: 'text',
          value: ({ item }) => (item && item.is_approved && 'Approved') || 'Pending',
        },
      ]}
    />
  );
};

export default LeaveRequests;
