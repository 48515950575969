/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { uniqueId } from 'lodash';

import {
  View, Image, Text, Breadcrumb, Storage,
} from '../../../app-components';
import Menu from '../../../Components/Menu';
import Notifications from './Actions/Notifications';
import UserInfo from './Actions/UserInfo';
import Logo from './Actions/Logo';
import theme from './theme';
import { breadcrumbStyle } from '../../../theme/breadcrumbTheme';

import {
  analyticsIcon,
  mdmIcon,
  analyticsSelected,
  mdmSelected,
  breadcrumbSeperator,
  IPDSelected,
  IPDUnSelected,
  inventoryIcon,
  billingIcon,
  auctionIcon,
  selectedInventoryIcon,
  selectedBillingIcon,
  selectedAuctionIcon,
} from '../../../images';
import ToolTip from '../../../Components/ToolTip';
import { ROUTES } from '../../../Lib/constants';
import {
  getPermission, gpsStore, refreshUserToken,
} from '../../../AppServices';

import ScreenHeaderActions from './ScreenActions';
import permissions from '../../../Lib/permissions';

// eslint-disable-next-line max-len
// const menuPermissions = (menu) => Object.values(menu.permissions).map((permission) => permission.value);

const tabsDef = [
  {
    label: 'Departments',
    access_key: permissions.departments.views.viewDepartments.permissions.viewDepartments.value,
    icon: IPDUnSelected,
    selectedIcon: IPDSelected,
    route: ROUTES.departments.name,
    menuOptions: [
      {
        id: 'OPD',
        label: 'OPD',
        route: ROUTES.opd.name,
        access_key: [
          permissions.departments.views.opdPatientRegistration.permissions.opdPatientRegistration.value,
          permissions.departments.views.opdIncomingPatients.permissions.opdIncomingPatients.value,
          permissions.departments.views.opdPatientTokenGeneration.permissions.opdPatientTokenGeneration.value,
          permissions.departments.views.opdConsultedPatientHistory.permissions.opdConsultedPatientHistory.value,
          permissions.departments.views.liveConsultation.permissions.liveConsultation.value,
        ],
        subOptions: [
          {
            id: ROUTES.PatientRegistrations.name,
            label: 'Patient Registration',
            description:
              'Patient are registered and their consultation records maintained.',
            access_key: permissions.departments.views.opdPatientRegistration.permissions.opdPatientRegistration.value,
          },
          {
            id: ROUTES.viewActiveSession.name,
            label: 'Token Generation',
            description:
              'For Assistant, To start and control tokens and the consultation sessions of patients.',
            access_key: permissions.departments.views.opdPatientTokenGeneration.permissions.opdPatientTokenGeneration.value,
          },
          {
            id: ROUTES.opdIncomingPatients.name,
            label: 'Incoming Patients',
            description:
              'For Doctors, To enter consultation sessions of patients.',
            access_key: permissions.departments.views.opdIncomingPatients.permissions.opdIncomingPatients.value,
          },
          {
            id: ROUTES.opdConsultedPatientList.name,
            label: 'Consulted Patient History',
            description: 'For Doctors, To manage consulted patients’ records.',
            access_key: permissions.departments.views.opdConsultedPatientHistory.permissions.opdConsultedPatientHistory.value,
          },
          {
            id: ROUTES.opdLiveConsultation.name,
            label: 'Live Consultation',
            description: 'To view patients those are in queue.',
            access_key: permissions.departments.views.liveConsultation.permissions.liveConsultation.value,
          },
          {
            id: ROUTES.opdReports.name,
            label: 'Reports',
            description: 'To track and download variety of reports date-wise.',
            access_key: permissions.departments.views.opdReports.permissions.opdReports.value,
          },
          {
            id: ROUTES.appointmentsList.name,
            label: 'Video Appointments',
            description: 'For Doctors, To manage Video Appointments.',
            access_key: permissions.departments.views.opdConsultedPatientHistory.permissions.opdConsultedPatientHistory.value,
          },
        ],
      },
      {
        id: 'Accounts',
        label: 'Accounts',
        route: ROUTES.accounts.name,
        access_key: [
          permissions.departments.views.viewAccounts.permissions.viewAccounts.value,
        ],
        subOptions: [
          {
            id: ROUTES.accountsInfo.name,
            label: 'Accounts',
            description: 'Accounts Details',
            access_key: permissions.departments.views.accounts.permissions.accounts.value,
          },
          {
            id: ROUTES.filesAndBills.name,
            label: 'Files and Bills',
            description: 'Files and Bills Details',
            access_key: permissions.departments.views.filesAndBills.permissions.filesAndBills.value,
          },
          {
            id: ROUTES.EmployeeRemarkList.name,
            label: 'Employee Remarks',
            description: 'Employee Salary Remarks Details',
            access_key: permissions.departments.views.EmployeeRemarks.permissions.EmployeeRemarks.value,
          },
          {
            id: ROUTES.salaryInfo.name,
            label: 'Salary',
            description: 'Salary Generation after attendance approval.',
            access_key: permissions.departments.views.SalaryFlow.permissions.SalaryFlow.value,
          },
          {
            access_key: permissions.departments.views.Attendance.permissions.viewAttendance.value,
            id: ROUTES.EmployeeAttendance.name,
            label: 'Attendance',
            description: 'Attendance approval and hold.',
          },
          {
            access_key: permissions.departments.views.Requests.permissions.viewRequests.value,
            id: ROUTES.AttendanceRequests.name,
            label: 'Requests',
            description: 'Leave and Attendance Requests.',
          },
          {
            // access_key: permissions.departments.views.Requests.permissions.viewRequests.value,
            id: ROUTES.Payment.name,
            label: 'Payments',
            description: 'Payment Details.',
          },
        ],
      },
      {
        id: 'Vaccination',
        label: 'Vaccination',
        route: ROUTES.vaccination.name,
        access_key: [
          permissions.departments.views.vaccinationTokenGeneration.permissions.vaccinationTokenGeneration.value,
          permissions.departments.views.vaccinationIncomingPatients.permissions.vaccinationIncomingPatients.value,
          permissions.departments.views.vaccinationHistory.permissions.vaccinationHistory.value,
        ],
        subOptions: [
          {
            id: ROUTES.viewActiveVaccinationSession.name,
            label: 'Token Generation',
            description:
              'For Assistant, To start and control tokens and the consultation sessions of patients.',
            access_key: permissions.departments.views.vaccinationTokenGeneration.permissions.vaccinationTokenGeneration.value,
          },
          {
            id: ROUTES.viewIncomingPatientNursePortal.name,
            label: 'Incoming Patients',
            description:
              'Manage and create record of vaccinations of patients.',
            access_key: permissions.departments.views.vaccinationIncomingPatients.permissions.vaccinationIncomingPatients.value,

          },
          {
            id: ROUTES.viewPatientVaccinationHistory.name,
            label: 'Vaccination History',
            description:
              'Patients who came in for sample collection and their reports.',
            access_key: permissions.departments.views.vaccinationHistory.permissions.vaccinationHistory.value,
          },
        ],
      },
      {
        id: 'IPD',
        label: 'IPD',
        route: ROUTES.ipd.name,
        access_key: [
          permissions.departments.views.ipdPatientAdmissions.permissions.ipdPatientAdmissions.value,
        ],
        subOptions: [
          {
            id: ROUTES.patientAdmissionIPD.name,
            label: 'Patient Admission',
            description:
              'Patient are registered and their consultation records maintained.',
            access_key: permissions.departments.views.ipdPatientAdmissions.permissions.ipdPatientAdmissions.value,
          },
          {
            id: ROUTES.ipdConsultation.name,
            label: 'Consulted Patient History',
            description:
              'For Doctors, To manage consulted patients’ records.',
            access_key: permissions.departments.views.ipdConsultedPatientHistory.permissions.ipdConsultedPatientHistory.value,

          },
          {
            id: ROUTES.admittedPatient.name,
            label: 'Patients Management',
            description:
              'For Nurse, To maintain assessments and doctors for all patients.',
            access_key: permissions.departments.views.ipdPatientManagement.permissions.ipdPatientManagement.value,
          },
          {
            id: ROUTES.nurseViewIPD.name,
            label: 'Nurse View',
            description:
              'For Nurse, To allocate rooms',
            access_key: permissions.departments.views.ipdNurseView.permissions.ipdNurseView.value,
          },
        ],
      },
    ],
  },
  // {
  //   label: 'Analytics',
  //   icon: analyticsIcon,
  //   selectedIcon: analyticsSelected,
  //   route: ROUTES.analytics.name,
  // },
  {
    label: 'MDM',
    icon: mdmIcon,
    access_key: permissions.mdm.views.viewMDM.permissions.viewMDM.value,
    selectedIcon: mdmSelected,
    route: ROUTES.mdm.name,
    menuOptions: [
      {
        id: 'Employee Management',
        label: 'Employee Management',
        route: ROUTES.employeeManagementAll.name,
        access_key: [permissions.mdm.views.viewEmployee.permissions.viewEmployee.value,
          permissions.mdm.views.viewEmployeeType.permissions.viewEmployeeType.value,
        ],
        subOptions: [
          {
            id: ROUTES.EmployeeList.name,
            label: 'View Employees ',
            description: 'See list of employees.',
            access_key:
              permissions.mdm.views.viewEmployee.permissions.viewEmployee.value,
          },
          {
            id: ROUTES.EmployeeTypesList.name,
            label: 'View Employee Types',
            description: 'See list of employee types.',
            access_key:
              permissions.mdm.views.viewEmployeeType.permissions.viewEmployeeType.value,
          },
          {
            id: ROUTES.EmployeeDesignation.name,
            label: 'View Employee Designations',
            description: 'See list of employee Designations.',
            access_key:
            permissions.mdm.views.employeeDesignation.permissions.employeeDesignation.value,
          },
          {
            id: ROUTES.EmployeeCategory.name,
            label: 'View Employee Categories',
            description: 'See list of employee Categories.',
            access_key:
              permissions.mdm.views.employeeCategory.permissions.employeeCategory.value,
          },
          {
            id: ROUTES.ExGratiaSalary.name,
            label: 'Ex-Gratia and Society Salary',
            description: 'Log Ex-Gratia and Society Salary Details',
            access_key: permissions.mdm.views.exGratia.permissions.exGratia.value,
          },
        ],
      },
      {
        id: 'Role Management',
        label: 'Role Management',
        route: ROUTES.rolesManagementAll.name,
        access_key: [permissions.mdm.views.viewRole.permissions.viewRole.value],
        subOptions: [
          {
            id: ROUTES.RolesList.name,
            label: 'View Roles',
            description: 'See list of roles.',
            access_key:
              permissions.mdm.views.viewRole.permissions.viewRole.value,
          },
        ],
      },
      {
        id: 'Department Management',
        label: 'Department Management',
        route: ROUTES.departmentManagementAll.name,
        access_key: [
          permissions.mdm.views.viewDepartment.permissions.viewDepartment.value,
          permissions.mdm.views.viewRoom.permissions.viewRoom.value,
          permissions.mdm.views.viewSpeciality.permissions.viewSpeciality.value,
        ],
        subOptions: [
          {
            id: ROUTES.DepartmentsList.name,
            label: 'View Departments',
            description: 'See list of department.',
            access_key:
              permissions.mdm.views.viewDepartment.permissions.viewDepartment.value,
          },
          {
            id: ROUTES.RoomsList.name,
            label: 'View Rooms',
            description: 'See list of rooms.',
            access_key:
              permissions.mdm.views.viewRoom.permissions.viewRoom.value,
          },
          {
            id: ROUTES.WardsList.name,
            label: 'View Specialities',
            description: 'See list of specialities.',
            access_key:
              permissions.mdm.views.viewSpeciality.permissions.viewSpeciality.value,
          },
          {
            id: ROUTES.roomCategories.name,
            label: 'Ward Categories',
            description: 'See list of wards.',
            access_key:
              permissions.mdm.views.viewRoom.permissions.viewRoom.value,
          },
        ],
      },
      {
        id: 'Operation Management',
        label: 'Operation Management',
        route: ROUTES.operationMaster.name,
        access_key: [permissions.mdm.views.viewEmployee.permissions.viewEmployee.value,
          permissions.mdm.views.viewOperation.permissions.viewOperation.value,
        ],
        subOptions: [
          {
            id: ROUTES.operationMaster.name,
            label: 'View Operation',
            description: 'See list of Operation.',
            access_key:
            permissions.mdm.views.viewOperation.permissions.viewOperation.value,
          },
        ],
      },
      {
        id: 'Patient Management',
        label: 'Patient Management',
        route: ROUTES.patientListMDM.name,
        access_key: [permissions.mdm.views.viewPatient.permissions.viewPatient.value],
        subOptions: [
          {
            id: ROUTES.patientListMDM.name,
            label: 'View Patients',
            description: 'See list of patients.',
            access_key:
              permissions.mdm.views.viewPatient.permissions.viewPatient.value,
          },
        ],
      },
      {
        id: 'Shift Management',
        label: 'Shift Management',
        route: ROUTES.shiftManagementAll.name,
        access_key: [
          permissions.mdm.views.viewShift.permissions.viewShift.value,
          permissions.mdm.views.viewSchedule.permissions.viewSchedule.value,
        ],
        subOptions: [
          {
            id: ROUTES.shiftList.name,
            label: 'Shift Type',
            description: 'Variety of Shifts are created and maintained.',
            access_key:
              permissions.mdm.views.viewShift.permissions.viewShift.value,
          },
          {
            id: ROUTES.employeeScheduleList.name,
            label: 'Employee Schedule',
            description: 'Variety of Schedules are created and maintained.',
            access_key:
              permissions.mdm.views.viewSchedule.permissions.viewSchedule.value,
          },
        ],
      },
      {
        id: 'Holiday Management',
        label: 'Holiday Management',
        route: ROUTES.holidayListCalendar.name,
        access_key: [
          permissions.mdm.views.viewHoliday.permissions.viewHoliday.value,
        ],
        subOptions: [
          {
            id: ROUTES.holidayListCalendar.name,
            label: 'View holidays',
            description: 'See list of holidays.',
            access_key: permissions.mdm.views.viewHoliday.permissions.viewHoliday.value,
          },
        ],
      },
      {
        id: 'Leave Management',
        label: 'Leave Management',
        route: ROUTES.leaveTypeList.name,
        access_key: [
          permissions.mdm.views.viewLeaveType.permissions.viewLeaveType.value,
          permissions.mdm.views.viewLeavePolicyType.permissions.viewLeavePolicyType.value,
        ],
        subOptions: [
          {
            id: ROUTES.leaveTypeList.name,
            label: 'Leave Types',
            description:
              'Variety of Leaves are created and maintained for Employees.',
            access_key: permissions.mdm.views.viewLeaveType.permissions.viewLeaveType.value,
          },
          {
            id: ROUTES.leavePolicyTypeList.name,
            label: 'Leave Policy Types',
            description:
              'Variety of Leaves Policy are created and maintained for Employees.',
            access_key:
              permissions.mdm.views.viewLeavePolicyType.permissions.viewLeavePolicyType.value,
          },
        ],
      },
      {
        id: 'Salary',
        label: 'Salary Management',
        route: ROUTES.salaryAll.name,
        access_key: [
          permissions.mdm.views.viewSalary.permissions.viewSalary.value,
          permissions.mdm.views.viewSalaryComponent.permissions.viewSalaryComponent.value,
          permissions.mdm.views.viewSalaryStructureTemplate
            .permissions.viewSalaryStructureTemplate.value,
        ],
        subOptions: [
          {
            id: ROUTES.salarySetUp.name,
            label: 'Set Up',
            description:
              'Salary Setup sections of tax slabs, saving heads are created and defined here.',
            access_key: permissions.mdm.views.viewSalary.permissions.viewSalary.value,
          },
          {
            id: ROUTES.salaryComponentList.name,
            label: 'Components',
            description: 'Salary components are created and defined here.',
            access_key: permissions.mdm.views.viewSalaryComponent
              .permissions.viewSalaryComponent.value,
          },
          {
            id: ROUTES.salaryCompensationList.name,
            label: 'Salary Structure Template',
            description:
              'CTC along with the components of each type is created and defined here.',
            access_key: permissions.mdm.views.viewSalaryStructureTemplate
              .permissions.viewSalaryStructureTemplate.value,
          },
          {
            id: ROUTES.houseList.name,
            label: 'Houses',
            description: 'Houses Detail.',
            access_key: [
              permissions.mdm.views.viewHouseList.permissions.viewHouseList.value,
              permissions.mdm.views.manageHouseView.permissions.manageHouseView.value,
              permissions.mdm.views.allotHouseView.permissions.allotHouseView.value,
              permissions.mdm.views.addElectricityBillOfHouse.permissions.addElectricityBillOfHouse.value,
            ],
          },
          {
            id: ROUTES.packageReview.name,
            label: 'Review Package',
            description:
              'Details of Salary Package Review.',
            // access_key: permissions.mdm.views.viewSalary.permissions.viewSalary.value,
          },
          {
            id: ROUTES.basicPayMatrixes.name,
            label: 'Pay Matrixes ',
            description:
              'Pay Matrixes.',
            access_key: permissions.mdm.views.payMatrix.permissions.payMatrix.value,
          },
        ],
      },
      {
        id: 'Lab Management',
        label: 'Lab Management',
        route: ROUTES.labManagementAll.name,
        access_key: [
          permissions.mdm.views.viewLab.permissions.viewLab.value,
          permissions.mdm.views.viewTestManagement.permissions.viewTestManagement.value,
          permissions.mdm.views.viewTestComponent.permissions.viewTestComponent
            .value,
        ],
        subOptions: [
          {
            id: ROUTES.LabType.name,
            label: 'Lab Type',
            description:
              'Variety of Labs are created and maintained for ‘Lab type’.',
            access_key:
              permissions.mdm.views.viewLab.permissions.viewLab.value,
          },
          {
            id: ROUTES.TestType.name,
            label: 'Test Type',
            description:
              'Variety of Labs are created and maintained for ‘Test type’.',
            access_key:
              permissions.mdm.views.viewTestManagement.permissions.viewTestManagement.value,
          },
          {
            id: ROUTES.TestComponentType.name,
            label: 'Test Component',
            description:
              'Variety of Tests are created and maintained for ‘Test type’.',
            access_key:
              permissions.mdm.views.viewTestComponent.permissions.viewTestComponent
                .value,
          },
        ],
      },
      /* Accounts are only used internally no need to show on UI */
      // {
      //   id: 'Account',
      //   label: 'Account',
      //   route: ROUTES.accountMaster.name,
      //   subOptions: [
      //     {
      //       id: ROUTES.accountMasterList.name,
      //       label: 'Accounts',
      //       description: 'Salary Account List Show ',
      //     },
      //   ],
      // },
      {
        id: 'Pharmacy Management',
        label: 'Pharmacy Management',
        route: ROUTES.pharmacyManagementAll.name,
        access_key: [
          permissions.mdm.views.viewPharmacyCategory.permissions.viewPharmacyCategory.value,
          permissions.mdm.views.viewPharmacySupplierAndManufacturer.permissions
            .viewPharmacySupplierAndManufacturer.value,
        ],
        subOptions: [
          {
            id: ROUTES.pharmacyCategory.name,
            label: 'Category',
            description:
              'Variety of Categories and sub categories are created and maintained.',
            access_key:
              permissions.mdm.views.viewPharmacyCategory.permissions.viewPharmacyCategory.value,
          },
          {
            id: ROUTES.pharmacySupplierAndManufacturer.name,
            label: 'Supplier & Manufacturer',
            description: 'Manage the List of Suppliers and Manufacturers.',
            access_key:
              permissions.mdm.views.viewPharmacySupplierAndManufacturer.permissions
                .viewPharmacySupplierAndManufacturer.value,
          },
        ],
      },
      {
        id: 'Asset',
        label: 'Asset Management',
        route: ROUTES.assetManagementAll.name,
        access_key: [
          permissions.mdm.views.viewAssetCategory.permissions.viewAssetCategory.value,
          permissions.mdm.views.viewAssetSupplierAndManufacturer.permissions
            .viewAssetSupplierAndManufacturer.value,
        ],
        subOptions: [
          {
            id: ROUTES.assetCategory.name,
            label: 'Category',
            description:
              'Variety of Categories and sub categories are created and maintained.',
            access_key:
            permissions.mdm.views.viewAssetCategory.permissions.viewAssetCategory.value,
          },
          {
            id: ROUTES.assetSupplierAndManufacturer.name,
            label: 'Supplier & Manufacturer',
            description: 'Manage the List of Suppliers and Manufacturers.',
            access_key:
            permissions.mdm.views.viewAssetSupplierAndManufacturer.permissions
              .viewAssetSupplierAndManufacturer.value,
          },
        ],
      },
      {
        id: 'Purchase Dept.',
        label: 'Purchase Dept.',
        route: ROUTES.purchaseDepartmentAll.name,
        access_key: [
          permissions.mdm.views.viewPurchaseTAndC
            .permissions.viewPurchaseTAndC.value,
        ],
        subOptions: [
          {
            id: ROUTES.purchaseDepartmentTCList.name,
            label: 'Terms & Conditions',
            description:
              'Variety of terms and conditions are created and maintained.',
            access_key:
              permissions.mdm.views.viewPurchaseTAndC
                .permissions.viewPurchaseTAndC.value,
          },
          {
            id: ROUTES.purchaseCategory.name,
            label: 'Category',
            description:
              'Variety of Categories and sub categories are created and maintained.',
            access_key:
            permissions.mdm.views.viewPurchaseCategory.permissions.viewPurchaseCategory.value,
          },
        ],
      },
      {
        id: 'Vaccine',
        label: 'Vaccine',
        route: ROUTES.vaccineMasterAll.name,
        access_key: [
          permissions.mdm.views.viewVaccine
            .permissions.viewVaccine.value,
        ],
        subOptions: [
          {
            id: ROUTES.vaccineScheduleList.name,
            label: 'Scheduling',
            description:
              'Schedules for all the vaccines are created and maintained.',
            access_key:
              permissions.mdm.views.viewVaccine
                .permissions.viewVaccine.value,
          },
        ],
      },
      {
        id: 'config',
        label: 'Configurations',
        route: ROUTES.vaccineMasterAll.name,
        access_key: [
          permissions.mdm.views.editHospitalDetails
            .permissions.editHospitalDetails.value,
        ],
        subOptions: [
          {
            id: ROUTES.editConfiguration.name,
            label: 'Basic hospital details',
            description:
              'Configuration (Basic hospital details that will show in records in downloaded documents).',
            access_key:
              permissions.mdm.views.editHospitalDetails
                .permissions.editHospitalDetails.value,
          },
        ],
      },
      {
        id: 'Disease',
        label: 'Disease',
        route: ROUTES.disease.name,
        access_key: [
          permissions.mdm.views.viewDisease
            .permissions.viewDisease.value,
        ],
        subOptions: [
          {
            id: ROUTES.diseaseList.name,
            label: 'Disease',
            description:
              'Variety of Disease are created and maintained.',
            access_key:
              permissions.mdm.views.viewDisease
                .permissions.viewDisease.value,
          },
        ],
      },
    ],
  },
  {
    label: 'Inventory',
    access_key: permissions.inventory.views.inventory.permissions.inventory.value,
    icon: inventoryIcon,
    selectedIcon: selectedInventoryIcon,
    route: ROUTES.inventory.name,
    menuOptions: [
      {
        id: 'Pharmacy',
        label: 'Pharmacy',
        route: ROUTES.inventory.name,
        access_key: [
          permissions.inventory.views.viewPharmacyCentralInventory.permissions.viewPharmacyCentralInventory.value,
          permissions.inventory.views.viewPharmacyDepartmentInventory.permissions.viewPharmacyDepartmentInventory.value,
          permissions.inventory.views.viewPharmacyCentralRequests.permissions.viewPharmacyCentralRequests.value,
          permissions.inventory.views.viewPharmacyDepartmentRequests.permissions.viewPharmacyDepartmentRequests.value,
          permissions.inventory.views.viewPharmacyCentralRecords.permissions.viewPharmacyCentralRecords.value,
          permissions.inventory.views.viewPharmacyDepartmentRecords.permissions.viewPharmacyDepartmentRecords.value,
          permissions.inventory.views.viewPharmacyMSIIndentRequests.permissions.viewPharmacyMSIIndentRequests.value,
        ],
        subOptions: [
          {
            id: ROUTES.pharmacyCentralInventory.name,
            label: 'Central Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewPharmacyCentralInventory.permissions.viewPharmacyCentralInventory.value,
          },
          {
            id: ROUTES.pharmacyDepartmentInventory.name,
            label: 'Department Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewPharmacyDepartmentInventory.permissions.viewPharmacyDepartmentInventory.value,
          },
          {
            id: ROUTES.pharmacyCentralRequests.name,
            label: 'Central Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewPharmacyCentralRequests.permissions.viewPharmacyCentralRequests.value,
          },
          {
            id: ROUTES.pharmacyDepartmentRequests.name,
            label: 'Department Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewPharmacyDepartmentRequests.permissions.viewPharmacyDepartmentRequests.value,
          },
          {
            id: ROUTES.pharmacyCentralRecords.name,
            label: 'Central Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewPharmacyCentralRecords.permissions.viewPharmacyCentralRecords.value,
          },
          {
            id: ROUTES.pharmacyDepartmentRecords.name,
            label: 'Department Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewPharmacyDepartmentRecords.permissions.viewPharmacyDepartmentRecords.value,
          },
          {
            id: ROUTES.pharmacyMSIIndentRequests.name,
            label: 'MS Indent Requests',
            description: 'For, Medical Superintendent to approve indent requests.',
            access_key: permissions.inventory.views.viewPharmacyMSIIndentRequests.permissions.viewPharmacyMSIIndentRequests.value,
          },
        ],
      },
      {
        id: 'Purchases',
        label: 'Purchases',
        route: ROUTES.inventory.name,
        access_key: [
          permissions.inventory.views.viewPurchaseSanction.permissions.viewPurchaseSanction.value,
          permissions.inventory.views.viewPurchaseAndSupplyOrders.permissions.viewPurchaseAndSupplyOrders.value,
        ],
        subOptions: [
          {
            id: ROUTES.pharmacyAndAssetPurchaseRequest.name,
            label: 'Requests',
            description:
              'To approve and manage the List of indent requests from asset and pharmacy inventory.',
            access_key: permissions.inventory.views.viewPurchaseSanction.permissions.viewPurchaseSanction.value,
          },
          {
            id: ROUTES.purchaseOrderAndSupplyOrder.name,
            label: 'Purchase Sanction & Supply Orders',
            description:
              'For, Medical Superintendent to approve indent requests.',
            access_key: permissions.inventory.views.viewPurchaseAndSupplyOrders.permissions.viewPurchaseAndSupplyOrders.value,
          },
        ],
      },
      {
        id: 'BloodBank',
        label: 'Blood Bank',
        route: ROUTES.inventory.name,
        access_key: [
          permissions.inventory.views.viewBloodBankCentralInventory.permissions.viewBloodBankCentralInventory.value,
          permissions.inventory.views.viewCompanies.permissions.viewCompanies.value,
          permissions.inventory.views.viewDonors.permissions.viewDonors.value,
          permissions.inventory.views.viewBloodBankRecords.permissions.viewBloodBankRecords.value,
          permissions.inventory.views.viewBloodBankRequests.permissions.viewBloodBankRequests.value,
        ],
        subOptions: [
          {
            id: ROUTES.bloodBankInventoryList.name,
            label: 'Blood Bank Inventory',
            description:
              'To Manage and create the List of Items in the Inventory',
            access_key:
              permissions.inventory.views.viewBloodBankCentralInventory.permissions.viewBloodBankCentralInventory.value,
          },
          {
            id: ROUTES.companyManagementList.name,
            label: 'Manage Company',
            description: 'To create and maintain list of companies.',
            access_key:
              permissions.inventory.views.viewCompanies.permissions.viewCompanies.value,
          },
          {
            id: ROUTES.viewDonorList.name,
            label: 'Donors & Donations',
            description:
              'To Manage the list of donors and the record of their donations.',
            access_key:
              permissions.inventory.views.viewDonors.permissions.viewDonors.value,
          },
          {
            id: ROUTES.bloodBankRecords.name,
            label: 'Records',
            description:
              'Record for indents and invoices of the List of Items in the inventory.',
            access_key:
              permissions.inventory.views.viewBloodBankRecords.permissions.viewBloodBankRecords.value,
          },
          {
            id: ROUTES.bloodBankRequest.name,
            label: 'Requests',
            description: 'To maintain approved and pending issue requests.',
            access_key:
              permissions.inventory.views.viewBloodBankRequests.permissions.viewBloodBankRequests.value,
          },
          {
            id: ROUTES.bloodBankMSRequest.name,
            label: 'MS Requests',
            description: 'For, Medical Superintendent to approve time and date change requests.',
            access_key:
              permissions.inventory.views.viewBloodBankMSRequests.permissions.viewBloodBankMSRequests.value,
          },
        ],
      },
      {
        id: 'Assets',
        label: 'Store Assets',
        route: ROUTES.inventory.name,
        access_key: [
          permissions.inventory.views.viewAssetCentralInventory.permissions.viewAssetCentralInventory.value,
          permissions.inventory.views.viewAssetDepartmentInventory.permissions.viewAssetDepartmentInventory.value,
          permissions.inventory.views.viewAssetCentralRequests.permissions.viewAssetCentralRequests.value,
          permissions.inventory.views.viewAssetDepartmentRequests.permissions.viewAssetDepartmentRequests.value,
          permissions.inventory.views.viewAssetCentralRecords.permissions.viewAssetCentralRecords.value,
          permissions.inventory.views.viewAssetDepartmentRecords.permissions.viewAssetDepartmentRecords.value,
          permissions.inventory.views.viewAssetMSIIndentRequests.permissions.viewAssetMSIIndentRequests.value,
          permissions.inventory.views.viewInChargeAssetList.permissions.viewInChargeAssetList.value,
        ],

        subOptions: [
          {
            id: ROUTES.assetsCentralInventory.name,
            label: 'Central Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetCentralInventory.permissions.viewAssetCentralInventory.value,
          },
          {
            id: ROUTES.assetsDepartmentInventory.name,
            label: 'Department Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetDepartmentInventory.permissions.viewAssetDepartmentInventory.value,
          },
          {
            id: ROUTES.assetsCentralRequests.name,
            label: 'Central Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewAssetCentralRequests.permissions.viewAssetCentralRequests.value,
          },
          {
            id: ROUTES.assetsDepartmentRequests.name,
            label: 'Department Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewAssetDepartmentRequests.permissions.viewAssetDepartmentRequests.value,
          },
          {
            id: ROUTES.assetsCentralRecords.name,
            label: 'Central Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetCentralRecords.permissions.viewAssetCentralRecords.value,
          },
          {
            id: ROUTES.assetsDepartmentRecords.name,
            label: 'Department Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetDepartmentRecords.permissions.viewAssetDepartmentRecords.value,
          },
          {
            id: ROUTES.assetsMSIIndentRequests.name,
            label: 'MS Indent Requests',
            description: 'For, Medical Superintendent to approve indent requests.',
            access_key: permissions.inventory.views.viewAssetMSIIndentRequests.permissions.viewAssetMSIIndentRequests.value,
          },
          {
            id: ROUTES.assetsIncargeRecordList.name,
            label: 'Incharge Records',
            description: 'Record of items under different incharge.',
            access_key: permissions.inventory.views.viewInChargeAssetList.permissions.viewInChargeAssetList.value,
          },
          {
            id: ROUTES.assetsSupervisorRecordList.name,
            label: 'Supervisor Records',
            description: 'Record of items under different supervisors',
            access_key: permissions.inventory.views.viewSupervisorRecords.permissions.viewSupervisorRecords.value,
          },
        ],
      },
      {
        id: 'assetConsumables',
        label: 'Store Consumables',
        route: ROUTES.inventory.name,
        access_key: [
          permissions.inventory.views.viewAssetConsumablesCentralInventory.permissions.viewAssetConsumablesCentralInventory.value,
          permissions.inventory.views.viewAssetConsumebalesDepartmentInventory.permissions.viewAssetConsumebalesDepartmentInventory.value,
          permissions.inventory.views.viewAssetConsumablesCentralRequests.permissions.viewAssetConsumablesCentralRequests.value,
          permissions.inventory.views.viewAssetConsumablesDepartmentRequests.permissions.viewAssetConsumablesDepartmentRequests.value,
          permissions.inventory.views.viewAssetConsumablesCentralRecords.permissions.viewAssetConsumablesCentralRecords.value,
          permissions.inventory.views.viewAssetConsumablesDepartmentRecords.permissions.viewAssetConsumablesDepartmentRecords.value,
          permissions.inventory.views.viewAssetConsumablesMSIIndentRequests.permissions.viewAssetConsumablesMSIIndentRequests.value,
        ],
        subOptions: [
          {
            id: ROUTES.assetConsumablesCentralInventory.name,
            label: 'Central Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetConsumablesCentralInventory.permissions.viewAssetConsumablesCentralInventory.value,
          },
          {
            id: ROUTES.assetConsumablesDepartmentInventory.name,
            label: 'Department Inventory',
            description: 'To manage and create the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetConsumebalesDepartmentInventory.permissions.viewAssetConsumebalesDepartmentInventory.value,
          },
          {
            id: ROUTES.assetConsumablesCentralRequests.name,
            label: 'Central Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewAssetConsumablesCentralRequests.permissions.viewAssetConsumablesCentralRequests.value,
          },
          {
            id: ROUTES.assetConsumablesDepartmentRequests.name,
            label: 'Department Requests',
            description: 'To Maintain approved and pending indent requests.',
            access_key: permissions.inventory.views.viewAssetConsumablesDepartmentRequests.permissions.viewAssetConsumablesDepartmentRequests.value,
          },
          {
            id: ROUTES.assetConsumablesCentralRecords.name,
            label: 'Central Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetConsumablesCentralRecords.permissions.viewAssetConsumablesCentralRecords.value,
          },
          {
            id: ROUTES.assetConsumablesDepartmentRecords.name,
            label: 'Department Records',
            description: 'Record for indents and invoices of the List of Items in the inventory.',
            access_key: permissions.inventory.views.viewAssetConsumablesDepartmentRecords.permissions.viewAssetConsumablesDepartmentRecords.value,
          },
          {
            id: ROUTES.assetConsumablesMSIIndentRequests.name,
            label: 'MS Indent Requests',
            description: 'For, Medical Superintendent to approve indent requests.',
            access_key: permissions.inventory.views.viewAssetConsumablesMSIIndentRequests.permissions.viewAssetConsumablesMSIIndentRequests.value,
          },
        ],
      },
    ],
  },
  {
    label: 'Billing',
    access_key: permissions.billing.views.viewBilling.permissions.viewBilling.value,
    icon: billingIcon,
    selectedIcon: selectedBillingIcon,
    route: ROUTES.billing.name,
    menuOptions: [
      {
        id: 'Lab',
        label: 'Lab',
        route: ROUTES.billing.name,
        access_key: [permissions.billing.views.generateLabBill.permissions.generateLabBill.value],
        subOptions: [
          {
            id: ROUTES.labBill.name,
            label: 'Billing',
            description: 'Lab test bills are booked and generated.',
            access_key:
            permissions.billing.views.generateLabBill.permissions.generateLabBill.value,
          },
          {
            id: ROUTES.labBillHistory.name,
            label: 'Billing History',
            description: 'Lab  bills History.',
            access_key:
            permissions.billing.views.generateLabBill.permissions.generateLabBill.value,
          },
        ],
      },
      {
        id: 'Pharmacy',
        label: 'Pharmacy',
        route: ROUTES.billing.name,
        access_key: [permissions.billing.views.generatePharmacyBill.permissions.generatePharmacyBill.value],
        subOptions: [
          {
            id: ROUTES.pharmacyBill.name,
            label: 'Patient Handling',
            description:
              'Invoices for the medicines are booked and generated.',
            access_key:
            permissions.billing.views.generatePharmacyBill.permissions.generatePharmacyBill.value,
          },
          {
            id: ROUTES.pharmacyBillingRecords.name,
            label: 'Billing Records',
            description:
              'Records of invoices generated.',
            access_key:
            permissions.billing.views.pharmacyBillingRecords.permissions.pharmacyBillingRecords.value,
          },
        ],
      },
    ],
  },
  {
    label: 'Auction',
    icon: auctionIcon,
    access_key: permissions.auction.views.viewAuction.permissions.viewAuction.value,
    selectedIcon: selectedAuctionIcon,
    route: ROUTES.auction.name,
    menuOptions: [
      {
        id: 'Auction',
        label: 'Auction',
        route: ROUTES.auction.name,
        access_key: [
          permissions.auction.views.viewInventory.permissions.viewInventory.value,
          permissions.auction.views.viewAuctionBuyers.permissions.viewAuctionBuyers.value,
        ],
        subOptions: [
          {
            id: ROUTES.inventoryManagementAuction.name,
            label: 'Inventory Management',
            description:
              'To manage and create the List of Items in the inventory.',
            access_key:
            permissions.auction.views.viewInventory.permissions.viewInventory.value,
          },
          {
            id: ROUTES.buyerManagementAuction.name,
            label: 'Buyer Management',
            description:
              'To manage and add new buyers of the auctioned items.',
            access_key:
            permissions.auction.views.viewAuctionBuyers.permissions.viewAuctionBuyers.value,
          },
        ],
      },
    ],

  },
];

const connectToSockets = () => {
  const socketUid = uniqueId();

  gpsStore.emitDataOnConnect({
    uid: socketUid,
    event: 'subscribeGroup',
    data: {
      sessionId: socketUid,
      source: 'web',
    },
    callback: () => {
      console.log('Real time sync enabled ->>>');
    },
  });
};

const Header = (props) => {
  const { user, state, state: { views } = {} } = props;
  const currentViewInfo = views[views.length - 1];
  const shouldBreadcrumbHeaderVisible = state?.views?.length > 1
    || currentViewInfo?.view?.headerActions?.length > 0;
  const [selectedTab, setSelectedTab] = useState('MDM');

  useEffect(() => {
    const interval = setInterval(() => {
      Storage.getItem('tokenExpiryTime').then((tokenExpiryTime) => {
        const currentDate = new Date();
        const tokenExpiryDate = new Date(parseInt(tokenExpiryTime, 10));
        const diffTimeInMinutes = (tokenExpiryDate - currentDate) / (1000 * 60);

        if (diffTimeInMinutes < 5 && diffTimeInMinutes > 0) {
          refreshUserToken();
        }
      });
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, []);

  const onTabChange = useCallback(
    (parentRoute, childRoute) => {
      if (parentRoute) {
        props.navigation.replace(childRoute);
        setSelectedTab(parentRoute);
      }
    },
    [props],
  );

  useEffect(() => {
    if (props?.navigation?.state?.routeName) {
      setSelectedTab(props?.navigation?.state?.routeName?.split(' ')[0]);
    }
  }, []);

  useEffect(() => {
    connectToSockets();
  });

  if (!user || user.enforceChangePassword) {
    return <View />;
  }
  return (
    <View style={theme.wrapperForBorderRadius}>
      <View style={theme.containerStyle}>
        <Logo {...props} />
        <View style={theme.tabsWrapper}>
          {tabsDef.map(
            (tab, index) => getPermission(tab.access_key) && (
              <RenderTab
                {...tab}
                key={index}
                selectedSubMenuOption={props?.navigation?.state?.routeName}
                onTabChange={onTabChange}
                selectedTab={selectedTab}
              />
            ),
          )}
        </View>
        <Notifications {...props} />
        <UserInfo {...props} />
      </View>
      {shouldBreadcrumbHeaderVisible && (
        <View style={theme.breadcrumbViewWrapper}>
          <Breadcrumb
            separator={breadcrumbSeperator}
            {...breadcrumbStyle}
            {...props}
          />
          <View>
            <ScreenHeaderActions {...props} />
          </View>
        </View>
      )}
    </View>
  );
};

const RenderTab = (props) => {
  const {
    label,
    icon,
    route,
    selectedIcon,
    onTabChange,
    selectedTab,
    selectedSubMenuOption,
    menuOptions = [],
  } = props;

  const handleOptionSelect = useCallback(
    ({ option }) => {
      onTabChange(route, option?.id);
    },
    [onTabChange, route],
  );

  return (
    <View style={theme.tabWrapper}>
      <ToolTip
        noPadding
        containerStyle={theme.tabTouchableContainerStyle}
        component={(
          <Menu
            selectedSubMenuOption={selectedSubMenuOption}
            options={menuOptions}
            onMenuOptionSelect={handleOptionSelect}
          />
        )}
      >
        <Image
          source={selectedTab === route ? selectedIcon : icon}
          style={theme.imageIconStyle}
        />
        <Text style={theme.tabText}>{label}</Text>
      </ToolTip>
      {selectedTab === route && <View style={theme.activeTint} />}
    </View>
  );
};

export default Header;
