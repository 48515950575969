import React from 'react';

import {
  showSelectedCount,
  clearSelection,
  selectAll,
} from '../../../../../app-components';
import { registeredIPDListUri } from '../../../../../Queries/patient';
import { Table } from '../../../../../app-components/table/Table';
import { ROUTES } from '../../../../../Lib/constants';

const header = ({ aggregates }) => ({
  selectableActions: (props) => ([
    showSelectedCount(props, aggregates),
    clearSelection,
    selectAll,
  ]),
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'patient.headers.uhid'.getLabel(),
        type: 'text',
        field: 'patient.uhid',
      },
      {
        header: 'patient.headers.patientName'.getLabel(),
        type: 'text',
        sortable: 'patientName',
        field: 'patientName',
      },
      {
        header: 'Speciality',
        type: 'text',
        field: 'speciality.name',
      },
      {
        header: 'Room No',
        type: 'text',
        field: 'rooms.code',
      },
      {
        header: 'Nurse Name',
        type: 'text',
        field: '',
      },
      {
        header: 'Doctor Name',
        type: 'text',
        field: '',
      },
      {
        header: 'Final Diagnosis',
        type: 'text',
        field: 'department.speciality',
      },
      {
        header: 'patient.headers.action'.getLabel(),
        type: 'link',

        sortable: 'rooms.code',
        value: (props) => {
          if (props?.item?.rooms) { return 'View'; }
          return 'Allot Room';
        },
        action: {
          type: 'link',
          link: (props) => {
            const { item } = props;
            if (props?.item?.rooms) {
              return {
                view: ROUTES.nurseViewPatientIPD.name,
                params: { patient: item },
              };
            }
            return {
              view: ROUTES.roomAllot.name,
              params: { item },
            };
          },
        },
      },
    ],
    action: ({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.nurseViewPatientIPD.name,
        params: { _id: item?._id, patient: item },
      },
    }),
  },
};

const PatientList = (props) => (
  <Table
    uri={registeredIPDListUri}
    // uri={registeredPatientIPDUri}
    {...tableItems}
    {...props}
    reloadEvent="reloadIPDRegisteredPatients"
  />
);
export default PatientList;

