import React from 'react';
import ListActionModal from '../../../../Components/ListActionModal';
import { vaccineIcon } from '../../../../images';
import VaccineTable from './components/vaccineTable';

const VaccineTableModal = (props) => (
  <ListActionModal
    icon={vaccineIcon}
    title="Vaccine Details"
    description="List of Vaccine taken in past."
    content={(
      <VaccineTable
        {...props}
      />
        )}
    {...props}
  />
);

export default VaccineTableModal;
