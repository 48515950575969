import MasterForm from '../../../Wired/Master/MasterForm';
import { cancelButton, save } from '../../../app-components';
import { EditZipCode } from '../../../Queries/zipCode';
import { MODELS } from '../../../Lib/constants';

const formFields = [
  {
    type: 'text',
    field: 'zipCode',
    label: 'ZipCode',
    placeholder: 'zipCode.placeholders.zipCode'.getLabel(),
  },
  {
    type: 'text',
    field: 'state',
    label: 'State',
    placeholder: 'zipCode.placeholders.state'.getLabel(),
  },
  {
    type: 'text',
    field: 'region',
    label: 'Region',
    placeholder: 'zipCode.placeholders.region'.getLabel(),

  },
  {
    type: 'text',
    field: 'city',
    label: 'City',
    placeholder: 'zipCode.placeholders.city'.getLabel(),

  },
  {
    type: 'text',
    field: 'district',
    label: 'District',
    placeholder: 'zipCode.placeholders.district'.getLabel(),
  },
];

const header = {
  title: 'Edit ZipCode',
  actions: [cancelButton(), save()],
};

export default MasterForm({
  uri: EditZipCode,
  header,
  model: MODELS.ZIPCODE,
  formFields,
});
