import React from 'react';

import { autoSuggestInput } from '../../../autoSuggestions';
import { Form } from '../../../app-components/form/Form';
import { MODELS } from '../../../Lib/constants';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';

const addExGratiaSociety = (props) => (
  <Form
    {...props}

    type="standard"
    closeView={1}
    // editable
    onSubmit={submit({
      model: MODELS.EX_GRATIA_SOCIETY_SALARY,
    })}
    submitMessage="Salary Added Successfully"
    reloadEvent={`reload${MODELS.EX_GRATIA_SOCIETY_SALARY}`}
    mandatory={{
      no_of_present_days: 1,
      employee: 1,
      month: 1,
      year: 1,
    }}
    lg={{
      formGroups: [
        {
          columnsPerRow: 3,
          fieldVariant: 'filled',
          labelStyle: {
            ...vars.headings.h8,
            color: vars.colors.grey.color2,
            textTransform: 'uppercase',
            paddingLeft: 10,
          },
          groupsContainerStyle: { paddingLeft: 12 },
          groups: [
            {
              columns: [
                autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'employee',
                  label: 'Employee',
                  variant: 'filled',
                  keyFiled: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  model: MODELS.EMPLOYEE,
                  query: 'employeeLists',
                  mandatory: true,
                },
                {
                  addOnFilter: () => ({
                    staffType: { code: { $in: ['Ex-Gratia', 'Society Salary'] } },
                  }),
                }),
                autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'month',
                  label: 'Month',
                  variant: 'filled',
                  keyFiled: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  model: MODELS.MONTHS,
                  query: 'MonthTypeSuggestions',
                  mandatory: true,
                }),
                autoSuggestInput({
                  type: 'autoSuggest',
                  field: 'year',
                  label: 'Year',
                  variant: 'filled',
                  keyFiled: 'name',
                  suggestionField: 'name',
                  valueField: 'name',
                  model: MODELS.YEARS,
                  query: 'YearTypeSuggestions',
                  mandatory: true,
                }),
                {
                  type: 'number',
                  field: 'no_of_present_days',
                  label: 'No of Present Days',
                  variant: 'filled',
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);

export default addExGratiaSociety;
