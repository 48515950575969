import React from 'react';
import {
  DateFilter, FilterGroup,
} from '../../../../app-components';
import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import { filterActive, filterInactive } from '../../../../images';
import FTS from '../../../../Components/TableFilters/FtsSearch';
// import UnknownPatientList from '../Patient/UnknownPatientList';
import RegisteredPatientList from './RegisteredPatientList';
import { genders, MODELS } from '../../../../Lib/constants';
import { countUri } from '../../../../Queries/countUri';

const NurseViewPatientTabs = (props) => {
  const { dataParams } = props;

  return (
    <TabNavigator
      {...props}
      actions={[
        (actionProps) => <FTS {...actionProps} action={{ exp: ['patientName', 'uhid', 'relationName'] }} />,
        <DateFilter field="_createdOn" key="dateFilter" />,
        FilterGroup({
          icon: filterInactive,
          activeIcon: filterActive,
          filters: [
            {
              type: 'autoSuggestOptions',
              options: genders,
              field: 'patient.gender',
              label: 'Gender',
              multiSelect: false,
            },
            {
              type: 'autoSuggestOptions',
              options: ['S/O', 'D/O', 'W/O'],
              field: 'patient.relation',
              label: 'Relation',
              multiSelect: false,
            },
          ],
        }),
      ]}
      tabs={{
        registeredPatient: {
          label: 'Registered Patient',
          screen: RegisteredPatientList,
          screenProps: {
            searchData: dataParams,
            dataParams,
          },
          countUri: countUri({
            model: MODELS.IPD_ADMISSION,
            id: 'admitIPDDataList',
          }),
        },
        // unknownPatient: {
        //   label: 'Unknown Patient',
        //   screen: UnknownPatientList,
        //   countUri: countUri({
        //     model: MODELS.PATIENTS,
        //     id: 'unknownPatientDataList',
        //   }),
        // },
      }}
    />
  );
};
export default NurseViewPatientTabs;
