import React from 'react';

import { multiSearchInput } from '../../../../../autoSuggestions';
import MasterForm from '../../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../../Lib/constants';
import { EditLab } from '../../../../../Queries/lab';
import ViewLabTabs from './components/Tabs';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'lab.labels.labName'.getLabel(),
    mandatory: true,
  },
  {
    type: 'text',
    field: 'code',
    label: 'lab.labels.labID'.getLabel(),
    mandatory: true,
  },
  multiSearchInput({
    field: 'rooms',
    label: 'lab.labels.rooms'.getLabel(),
    placeholder: 'lab.placeholders.selectRooms'.getLabel(),
    keyField: 'name',
    valueField: 'name',
    suggestionField: 'name',
    model: MODELS.ROOM,
    query: 'masterDataList',
    searchField: 'name',
    searching: true,
    mandatory: true,
  }),
  {
    type: 'status',
    field: 'isActive',
    label: 'lab.labels.status'.getLabel(),
    container: 'topLabel',
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'lab.labels.description'.getLabel(),
    minHeight: 38,
  },
  {
    type: 'date',
    format: 'DD MMM YYYY @ hh:mm A',
    field: '_createdOn',
    label: 'lab.labels.createdOn'.getLabel(),
    editable: false,
  },
];

export default MasterForm({
  uri: EditLab,
  formFields,
  submitMessage: 'lab.messages.labUpdatedSuccessfully'.getLabel(),
  mandatory: {
    name: 1,
    code: 1,
  },
  reloadOnChangeEvent: `reload${MODELS.LAB_TYPE}`,
  editable: false,
  extraFormGroups: [{
    type: 'custom',
    render: (props) => (
      <ViewLabTabs {...props} />
    ),
  }],
});

