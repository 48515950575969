import React, {
  useCallback, useEffect, useState,
} from 'react';
import { TextInput } from '@applane/react-text-input';
import { TokenInputTheme } from './TokenInputTheme';
import { invoke } from '../../AppServices';
import { OutlineButton, Toast, View } from '../../app-components';
import { getErrorString } from '../../Lib/helpers';
import { MODELS } from '../../Lib/constants';
import BarCodeReader from '../../Components/BarcodeReader';

const TokenInput = (props) => {
  const {
    item,
    sessionId,
    navigation, eventDispatcher,
  } = props;

  const [value, setValue] = useState();

  useEffect(() => {
    setValue(item?.token);
  }, [item]);

  const handleButtonClick = useCallback(async () => {
    if (!value) {
      return;
    }
    try {
      const result = await invoke({
        id: 'assignToken',
        paramValue: {
          uhid: value,
          sessionId,
        },
      });
      if (result) {
        setValue();
        Toast.show({
          message: 'Success',
          description: 'Token assigned successfully',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });

        eventDispatcher.notify(`reload${MODELS.CONSULTATION}`);
        navigation.pop();
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [eventDispatcher, navigation, sessionId, value]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleScan = useCallback(async (value) => {
    if (!value) {
      return;
    }
    try {
      const result = await invoke({
        id: 'assignToken',
        paramValue: {
          uhid: value,
          sessionId,
        },
      });
      if (result) {
        eventDispatcher.notify(`reload${MODELS.CONSULTATION}`);
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [eventDispatcher, sessionId]);

  return (
    <>

      <View style={{
        flexDirection: 'row', justifyContent: 'space-between', minWidth: 300, alignItems: 'center',
      }}
      >
        <TextInput
          value={value}
          placeholder="UHID"
          name="UHID"
          type="number"
          onChange={handleChange}
          inputProps={{
            type: 'number',
          }}
          {...TokenInputTheme}
          style={{
            ...TokenInputTheme.style,
            height: 40,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
          }}
          inputStyle={{
            ...TokenInputTheme.inputStyle,
            fontSize: 14,

          }}
        />
        <OutlineButton
          label="Assign Token"
          onClick={handleButtonClick}
        />
      </View>

      <BarCodeReader handleScan={handleScan} />
    </>
  );
};

export default TokenInput;
