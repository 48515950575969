import React from 'react';

import { Text, View } from '../../../../app-components';
import vars from '../../../../theme/vars';
import theme from '../theme';

const Headings = ({ headings = [], subLabel }) => (
  <View
    style={{
      ...theme.center,
      marginTop: 14,
    }}
  >
    <View
      style={theme.width200}
    >
      <Text
        style={
            [vars.headings.h16, { color: vars.colors.grey.color3, paddingBottom: 5 }]
          }
      >
        {subLabel}
      </Text>
    </View>
    { headings.map((heading, index) => (
      <View
        key={index}
        style={theme.switchInputWrapper}
      >
        <Text
          style={
             [vars.headings.h16, { color: vars.colors.grey.color3 }]
            }
        >
          {heading.label}
        </Text>
      </View>
    ))}
  </View>
);

export default Headings;
