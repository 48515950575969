/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { upperFirst } from 'lodash';
import moment from 'moment';

import {
  TouchableOpacity,
  FormField,
  Image,
  Text,
  View,
} from '../../../../../app-components';
import {
  MODELS,
  assetServiceTypes,
  modules,
  ROUTES,
} from '../../../../../Lib/constants';
import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput } from '../../../../../autoSuggestions';
import { Form } from '../../../../../app-components/form/Form';
import { edit_icon as editIcon } from '../../../../../images';
import { submit } from '../../../../../AppServices';
import vars from '../../../../../theme/vars';

const RaisePurchaseRequestAsset = (props) => {
  const { navigation } = props;
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  const [serialNo, setSerialNo] = useState(1);
  const { rateContract = [] } = item?.item || {};
  const supplierIds = rateContract.filter(({ rateContractValidity }) => moment().diff(rateContractValidity, 'days') < 0);
  const supplierExist = supplierIds.length !== 0;

  return (
    <Form
      {...props}
      type="standardShadow"
      mandatory={supplierExist ? {
        requiredQuantity: 1,
        reasonForPurchase: 1,
        unitPrice: 1,
        category: 1,
        subCategory: 1,
        isAMC: 1,
        manufacturer: 1,
        supplier: 1,
      } : {
        requiredQuantity: 1,
        reasonForPurchase: 1,
        unitPrice: 1,
        category: 1,
        subCategory: 1,
        isAMC: 1,
      }}
      closeView={1}
      defaultValues={() => item}
      onSubmit={submit({
        model: MODELS.ASSET_PURCHASE,
      })}
      reloadEvent={`reload${MODELS.PURCHASE_REQUEST}`}

      computations={{
        self: {
          supplier: {
            compute: () => ({
              set: { manufacturer: null },
            }),
            onChange: ['supplier'],
          },
          subCategory: {
            compute: () => ({ set: { subCategory: '' } }),
            onChange: ['category'],
          },
        },
      }}
      submitMessage={'asset.messages.purchaseRequestSuccessfully'.getLabel()}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            label: 'asset.title.purchaseRequestDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 6,
              marginTop: 4,
              marginBottom: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    field: 'item.name',
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'item.category.name',
                    label: 'asset.labels.category'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'item.subCategory.name',
                    label: 'asset.labels.subCategory'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.stockAvailable'.getLabel(),
                    field: 'item.stockAvailable.stockAvailable',
                    editable: false,
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.unitPrice'.getLabel(),
                    field: 'unitPrice',
                    mandatory: true,
                  },
                  {
                    type: 'number',
                    label: 'asset.labels.requiredQuantity'.getLabel(),
                    field: 'requiredQuantity',
                    mandatory: true,
                  },
                  {
                    type: 'textArea',
                    label: 'asset.labels.reasonForPurchase'.getLabel(),
                    field: 'reasonForPurchase',
                    mandatory: true,
                  },
                  {
                    type: 'radioGroup',
                    field: 'isAMC',
                    variant: 'filled',
                    label: 'asset.labels.service'.getLabel(),
                    container: 'topLabel',
                    options: assetServiceTypes,
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    field: 'supplier',
                    variant: 'filled',
                    label: 'asset.labels.supplier'.getLabel(),
                    placeholder: 'asset.placeholders.supplier'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUPPLIER,
                    query: 'masterDataList',
                    mandatory: supplierExist,
                    visible: ({ data } = {}) => data?.category?.isContractual,
                  },
                  {
                    addOnFilter: ({ data: { item: { rateContract } } }) => ({
                      _id: {
                        $in:
                      rateContract.map(({ supplier, rateContractValidity }) => {
                        if (moment().diff(rateContractValidity, 'days') < 0) {
                          return supplier._id;
                        }
                        return {};
                      }),
                      },
                    }),
                  }),
                  autoSuggestInput({
                    field: 'manufacturer',
                    variant: 'filled',
                    label: 'asset.labels.manufacturer'.getLabel(),
                    placeholder: 'asset.placeholders.manufacturer'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_MANUFACTURER,
                    query: 'masterDataList',
                    mandatory: supplierExist,
                    visible: ({ data } = {}) => data?.category?.isContractual,
                  },
                  {
                    addOnFilter: ({ data }) => ({
                      _id: {
                        $in:
                      data?.item?.rateContract.map(({ manufacturer }) => manufacturer._id),
                      },
                      supplier: {
                        _id: data.supplier._id,
                      },
                    }),
                  }),
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'Purchase Request Asset Details',
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
            },
            groupsContainerStyle: { paddingLeft: 7 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  autoSuggestInput({
                    field: 'category',
                    variant: 'filled',
                    label: 'Purchase request category',
                    placeholder: 'asset.placeholders.category'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_CATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: () => ({
                      module: modules.purchase,
                    }),
                  }),
                  autoSuggestInput({
                    field: 'subCategory',
                    variant: 'filled',
                    label: 'Purchase request sub category',
                    placeholder: 'asset.placeholders.subCategory'.getLabel(),
                    keyField: 'name',
                    valueField: 'name',
                    suggestionField: 'name',
                    model: MODELS.PHARMACY_SUBCATEGORY,
                    query: 'masterDataList',
                    mandatory: true,
                  },
                  {
                    addOnFilter: ({ data: { category } }) => ({
                      category: {
                        _id: category._id,
                      },
                    }),
                  }),
                ],
              },
            ],
          },

          {
            fieldVariant: 'filled',
            // label: 'asset.title.technicalDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columnsPerRow: 1,
                columns: [
                  {
                    render: (nestedFieldProps) => (
                      <FormField
                        {...nestedFieldProps}
                        {...{
                          title: 'asset.title.technicalDetails'.getLabel(),
                          field: 'technicalDetails',
                          fieldType: 'nested',
                          Component: NestedTable,
                          header: () => ({
                            actions: [{
                              render: () => {
                                const { data, setValue } = nestedFieldProps;
                                const { technicalDetails = [] } = data;

                                setSerialNo(technicalDetails?.length);

                                if (serialNo <= technicalDetails?.length) {
                                  technicalDetails.map((name, index) => {
                                    name.serialNo = index + 1;
                                    return {};
                                  });
                                }
                                return (
                                  <TouchableOpacity
                                    onPress={() => {
                                      navigation.push({
                                        view: ROUTES.addTechnicalModal.name,
                                        params: {
                                          data,
                                          setValue,
                                        },
                                      });
                                    }}
                                  >
                                    <View style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      width: 100,
                                      height: 35,
                                      borderColor: 'grey',
                                      backgroundColor: vars.colors.secondary.color2,
                                    }}
                                    >
                                      <Text
                                        style={{
                                          ...vars.headings.h7,
                                          color: vars.colors.white,
                                        }}
                                      >
                                        {`${'asset.buttons.add'.getLabel()}`}
                                      </Text>
                                    </View>
                                  </TouchableOpacity>
                                );
                              },
                            }],
                          }),
                          componentProps: {
                            maxHeight: 400,
                            skipTableHeaderOnNoData: true,
                            listProps: {
                              hideColumnHeader: true,
                              columnsPerRow: 1,
                              columns: [
                                {
                                  type: 'custom',
                                  render: (nestedTableProps) => {
                                    const { data } = nestedTableProps;
                                    const { technicalListDetails, technicalTitle, serialNo } = data;

                                    const technicalDetailsList = [];

                                    if (technicalListDetails) {
                                      technicalListDetails.forEach((element) => {
                                        technicalDetailsList.push({
                                          description: element?.description,
                                        });
                                      });
                                    }

                                    if (data) {
                                      return (
                                        <View
                                          style={{
                                            backgroundColor: vars.colors.white,
                                            flex: 1,
                                            width: 1200,
                                            marginTop: 20,
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: 'row',
                                              flex: 1,
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <Text style={{
                                              ...vars.headings.h9,
                                              color: vars.colors.secondary.color2,
                                              paddingLeft: 5,
                                            }}
                                            >
                                              {serialNo}
                                              {'. '}
                                              {upperFirst(technicalTitle)}
                                            </Text>
                                            <TouchableOpacity
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onPress={() => {
                                                const { setValue } = nestedFieldProps;
                                                navigation.push({
                                                  view: ROUTES.editTechnicalModal.name,
                                                  params: {
                                                    item: data?._parent,
                                                    setValue,
                                                    serialNo,
                                                  },
                                                });
                                              }}
                                            >
                                              <Image source={editIcon} />
                                            </TouchableOpacity>
                                          </View>
                                          {technicalDetailsList.map((need) => (
                                            <View
                                              style={{
                                                backgroundColor: vars.colors.white,
                                                flexBasis: '50%',
                                                flexDirection: 'row',
                                                marginTop: 10,
                                              }}
                                              key="description"
                                            >
                                              <View style={{ marginLeft: 10 }}>
                                                <View
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <Text
                                                    style={{
                                                      height: 5,
                                                      width: 5,
                                                      borderRadius: 2.5,
                                                      backgroundColor: vars.colors.grey.color3,
                                                    }}
                                                    numberOfLines={4}
                                                  />
                                                  <Text
                                                    style={{
                                                      ...vars.headings.h9,
                                                      textOverFlow: 'scroll',
                                                      color: vars.colors.grey.color3,
                                                      marginLeft: 4,
                                                    }}
                                                    numberOfLines={4}
                                                  >
                                                    {` ${need?.description}`}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                          ))}
                                        </View>
                                      );
                                    }
                                    return {};
                                  },
                                },
                              ],
                            },
                          },
                        }}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default RaisePurchaseRequestAsset;

