import { upperFirst } from 'lodash';
import MasterForm from '../../../../Wired/Master/MasterForm';
import { MODELS } from '../../../../Lib/constants';

const formFields = [
  {
    type: 'text',
    field: 'name',
    label: 'pharmacy.labels.subCategoryName'.getLabel(),
    allowWhiteSpaces: true,
    modifyValueOnChange: ({ value }) => upperFirst(value),
  },
  {
    type: 'textArea',
    field: 'description',
    label: 'pharmacy.labels.description'.getLabel(),
    minHeight: 100,
  },
  {
    type: 'status',
    field: 'isActive',
    label: 'pharmacy.labels.status'.getLabel(),
    container: 'topLabel',
  },
];

export default MasterForm({
  formFields,
  reloadOnChangeEvent: `reload${MODELS.PHARMACY_CATEGORY}`,
  editable: false,
  defaultValues: (props) => props?.navigation?.state?.params?.item,
});
