import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ActivityIndicator, Text, View } from '../../../../app-components';
import {
  searchInput,
} from '../../../../autoSuggestions';
import { MODELS } from '../../../../Lib/constants';
import { submit } from '../../../../AppServices';
import vars from '../../../../theme/vars';
import { NestedAction, NestedTable } from '../../../../app-components/input-components/nestedTable/NestedTable';
import { Form } from '../../../../app-components/form/Form';
import { getCurrencyIntoWords, validateMobile } from '../../../../Lib/helpers';

const CreateBOQ = (props) => {
  const { navigation: { state: { params } = {} } = {} } = props;
  const { type, module } = params;
  const [serialNo, setSerialNo] = useState(1);
  let query = 'masterDataList';
  let model = '';
  if (type === 'asset') {
    model = MODELS.ASSET_ITEM;
  } else if (type === 'pharmacy') {
    if (module) {
      model = MODELS.PHARMACY_ITEM;
      query = 'assetConsumablesItemList';
    }
    model = MODELS.PHARMACY_ITEM;
  }

  if (!model) return <ActivityIndicator />;
  return (
    <Form
      {...props}
      type="standardShadow"
      closeView={1}
      onSubmit={submit({
        model: MODELS.BOQ_MODEL,
      })}
      computations={{
        children: {
          itemDetails: {
            self: {
              itemDetailsComputations: {
                compute: () => ({
                  set: {
                    units: 'Numbers',
                    currencyConversion: 'Full Conversion',
                    quotedCurrency: 'INR',
                  },
                }),
                onChange: ['itemNamePharmacy', 'itemNameAsset'],
              },
            },
          },
        },
        self: {
          totalAmountWithOutTax: {
            compute: ({ itemDetails = [] }) => {
              let totalAmount = 0;
              itemDetails.map(({
                unitPrice = 0,
                requiredQuantity = 0,
              }) => {
                totalAmount += (unitPrice * requiredQuantity);
                return (unitPrice * requiredQuantity);
              });
              return {
                set: {
                  totalAmountWithOutTax: totalAmount,
                },
              };
            },
            onChange: [
              'itemDetails.requiredQuantity',
              'itemDetails.unitPrice',
            ],
          },
          totalAmountWithTax: {
            compute: ({ itemDetails = [], totalAmountWithOutTax }) => {
              let totalAmountWithTaxes = 0;
              itemDetails.map(({
                exciseAmount = 0,
                gstAmount = 0,
                freightAmount = 0,
                otherTax = 0,
                thirdPartyInspectionCharges = 0,
                otherDuties = 0,
                lessCenvatCredit = 0,
              }) => {
                totalAmountWithTaxes -= (
                  exciseAmount
                + gstAmount
                + freightAmount
                + otherTax
                + thirdPartyInspectionCharges
                + otherDuties
                + lessCenvatCredit);
                return (totalAmountWithTaxes);
              });
              totalAmountWithTaxes += totalAmountWithOutTax;
              return {
                set: {
                  totalAmountWithTax: totalAmountWithTaxes,
                },
              };
            },
            onChange: [
              'itemDetails.gstAmount',
              'itemDetails.exciseAmount',
              'itemDetails.freightAmount',
              'itemDetails.otherTax',
              'itemDetails.thirdPartyInspectionCharges',
              'itemDetails.otherDuties',
              'itemDetails.lessCenvatCredit',
              'totalAmountWithOutTax',
            ],
          },
        },
      }}
      validations={{
        contactNumber: ({ data }) => validateMobile(data?.contactNumber),
      }}
      submitMessage={'purchase.message.BOQAddedSuccessfully'.getLabel()}
      mandatory={{
        authority: 1,
        workName: 1,
        contactNumber: 1,
        bidderCompany: 1,
      }}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            label: 'purchase.title.BOQ'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    field: 'authority',
                    label: 'purchase.labels.authority'.getLabel(),
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'text',
                    field: 'workName',
                    label: 'purchase.labels.workName'.getLabel(),
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                  {
                    type: 'number',
                    field: 'contactNumber',
                    label: 'purchase.labels.contactNumber'.getLabel(),
                    mandatory: true,
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value?.toString()?.length > 10) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  {
                    type: 'text',
                    field: 'bidderCompany',
                    label: 'purchase.labels.bidderCompany'.getLabel(),
                    mandatory: true,
                    allowWhiteSpaces: true,
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'purchase.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    field: 'itemDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      footer: (props) => {
                        const { data: { itemDetails = [] } } = props;
                        setSerialNo(itemDetails?.length);
                        if (serialNo <= itemDetails?.length) {
                          itemDetails.map((name, index) => name.serialNo = index + 1);
                        }
                        return {
                          leftActions: [{
                            render: () => (
                              <View style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 120,
                                height: 40,
                                borderColor: 'grey',
                              }}
                              >
                                <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                                  {`+ ${'inventory.labels.addRow'.getLabel()}`}
                                </Text>
                              </View>
                            ),
                            onPress: props?.addRow,
                          }],
                        };
                      },
                      skipTableHeaderOnNoData: false,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          minHeight: 32,
                          backgroundColor: vars.colors.white,
                        },
                        headerRowColumnTextStyle: {
                          paddingLeft: 12,
                          ...vars.headings.h13,
                          color: vars.colors.grey.color3,
                          numberOfLines: 1,
                          textTransform: 'upperCase',
                        },
                        columns: [
                          {
                            type: 'text',
                            header: 'vaccination.headers.serialNo'.getLabel(),
                            field: 'serialNo',
                            variant: 'filled',
                            editable: false,
                            width: 100,
                          },
                          searchInput({
                            field: 'itemNamePharmacy',
                            variant: 'filled',
                            header: 'purchase.labels.itemName'.getLabel(),
                            placeholder: 'purchase.placeholders.itemName'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            visible: () => model === MODELS.PHARMACY_ITEM,
                            model,
                            query,
                            width: 150,

                          },
                          {
                            addOnFilter: ({
                              data: {
                                _parent: {
                                  itemDetails = [],
                                } = {},
                              },
                            } = {}) => {
                              const itemIds = itemDetails?.filter(
                                ({ itemNamePharmacy }) => itemNamePharmacy?._id,
                              ).map(({ itemNamePharmacy }) => itemNamePharmacy?._id);
                              if (module) {
                                return {
                                  _id: { $nin: itemIds },
                                  module: 'assetConsumables',
                                };
                              }
                              return {
                                _id: { $nin: itemIds },
                              };
                            },
                          }),
                          searchInput({
                            field: 'itemNameAsset',
                            variant: 'filled',
                            header: 'purchase.labels.itemName'.getLabel(),
                            placeholder: 'purchase.placeholders.itemName'.getLabel(),
                            keyField: 'name',
                            valueField: 'name',
                            suggestionField: 'name',
                            visible: () => model === MODELS.ASSET_ITEM,
                            model,
                            query: 'masterDataList',
                            width: 150,
                          },
                          {
                            addOnFilter: ({
                              data:
                                      { _parent: { itemDetails = [] } = {} },
                            } = {}) => {
                              const itemIds = itemDetails?.filter(
                                ({ itemNameAsset }) => itemNameAsset?._id,
                              ).map(({ itemNameAsset }) => itemNameAsset?._id);
                              return {
                                _id: { $nin: itemIds },
                              };
                            },
                          }),
                          {
                            type: 'number',
                            field: 'requiredQuantity',
                            variant: 'filled',
                            header: 'purchase.labels.requiredQuantity'.getLabel(),
                            placeholder: 'purchase.placeholders.requiredQuantity'.getLabel(),
                            mandatory: true,
                            width: 150,
                          },
                          {
                            type: 'text',
                            field: 'units',
                            variant: 'filled',
                            header: 'purchase.labels.units'.getLabel(),
                            placeholder: 'purchase.placeholders.units'.getLabel(),
                            editable: false,
                            width: 150,
                          },
                          {
                            type: 'number',
                            field: 'unitPrice',
                            variant: 'filled',
                            header: 'purchase.labels.unitPrice'.getLabel(),
                            placeholder: 'purchase.placeholders.unitPrice'.getLabel(),
                            width: 150,
                            mandatory: false,
                          },
                          {
                            type: 'number',
                            field: 'estimatedPrice',
                            variant: 'filled',
                            header: 'purchase.labels.estimatedPrice'.getLabel(),
                            placeholder: 'purchase.placeholders.estimatedPrice'.getLabel(),
                            width: 150,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'currencyConversion',
                            variant: 'filled',
                            header: 'purchase.labels.currencyConversion'.getLabel(),
                            width: 250,
                            editable: false,
                          },
                          {
                            type: 'text',
                            field: 'quotedCurrency',
                            variant: 'filled',
                            width: 250,
                            header: 'purchase.labels.quotedCurrency'.getLabel(),
                            editable: false,
                          },
                          {
                            type: 'number',
                            field: 'basicRateBidder',
                            variant: 'filled',
                            width: 350,
                            placeholder: 'purchase.placeholders.basicRateBidder'.getLabel(),
                            header: 'purchase.labels.basicRateBidder'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'exciseAmount',
                            variant: 'filled',
                            width: 200,
                            placeholder: 'purchase.placeholders.exciseAmount'.getLabel(),
                            header: 'purchase.labels.exciseAmount'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'gstAmount',
                            variant: 'filled',
                            width: 200,
                            placeholder: 'purchase.placeholders.gstAmount'.getLabel(),
                            header: 'purchase.labels.gstAmount'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'freightAmount',
                            variant: 'filled',
                            width: 250,
                            placeholder: 'purchase.placeholders.freightCharges'.getLabel(),
                            header: 'purchase.labels.freightCharges'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'otherTax',
                            variant: 'filled',
                            width: 200,
                            placeholder: 'purchase.placeholders.otherTax'.getLabel(),
                            header: 'purchase.labels.otherTax'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'otherDuties',
                            variant: 'filled',
                            width: 200,
                            placeholder: 'purchase.placeholders.otherDuties'.getLabel(),
                            header: 'purchase.labels.otherDuties'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'thirdPartyInspectionCharges',
                            variant: 'filled',
                            width: 350,
                            placeholder: 'purchase.placeholders.thirdPartyInspectionCharges'.getLabel(),
                            header: 'purchase.labels.thirdPartyInspectionCharges'.getLabel(),
                          },
                          {
                            type: 'number',
                            field: 'lessCenvatCredit',
                            variant: 'filled',
                            width: 350,
                            placeholder: 'purchase.placeholders.lessCenvatCredit'.getLabel(),
                            header: 'purchase.labels.lessCenvatCredit'.getLabel(),
                          },
                          NestedAction(),
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            columnsPerRow: 3,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'number',
                    field: 'totalAmountWithOutTax',
                    label: 'purchase.labels.totalAmountWithOutTax'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'totalAmountWithTax',
                    label: 'purchase.labels.totalAmountWithTax'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'text',
                    // field: 'totalAmountInWords',
                    label: 'purchase.labels.totalAmountInWords'.getLabel(),
                    editable: false,
                    render: ({ data }) => {
                      if (data?.totalAmountWithOutTax) {
                        return (
                          <Text style={{ ...vars.headings.h9 }}>
                            {getCurrencyIntoWords(data?.totalAmountWithTax)}
                          </Text>
                        );
                      }
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default CreateBOQ;
