import React from 'react';

import {
  View, FormField, LargeButton, OutlineButton,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';

import ListActionModal from '../../../../../Components/ListActionModal';

import { searchInput } from '../../../../../autoSuggestions';
import { notificationError } from '../../../../../images';
import { MODELS } from '../../../../../Lib/constants';
import vars from '../../../../../theme/vars';

const EditDoctorContent = (props) => {
  const { navigation } = props;

  const {
    doctorName,
    updateDoctor,
    session: { speciality } = {},
    alreadyAssignedDoctors,
  } = navigation?.state?.params;
  return (
    <View style={{ flex: 1, paddingLeft: 40, paddingRight: 40 }}>
      <Form
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        mandatory={{
          doctorName: 1,
        }}
        onSubmit={({ data }) => {
          updateDoctor(
             data?.doctorName?._id,
             data?.doctorName?.firstName,
             data?.doctorName?.lastName,
          );
        }}
      >
        {({ form_context: formContext }) => (
          <>
            {doctorName ? (
              <FormField
                value={doctorName}
                type="text"
                variant="filled"
                label={'doctor.labels.doctorName'.getLabel()}
                editable={false}
              />
            ) : null}
            <View style={{ marginTop: 16 }}>
              <FormField
                {
                ...searchInput({
                  label: 'doctor.labels.newDoctor'.getLabel(),
                  field: 'doctorName',
                  variant: 'filled',
                  suggestionField: 'fullName',
                  placeholder: 'patientToken.placeholders.searchDoctor'.getLabel(),
                  model: MODELS.EMPLOYEE,
                  mandatory: true,
                  query: 'employeeLists',

                }, {
                  modifyResult: ({ data }) => {
                    const newData = data.map((item) => ({
                      ...item,
                      fullName: `${item.firstName} ${item.lastName}`,
                    }));
                    return { data: newData };
                  },
                  addOnFilter: {
                    speciality: { _id: speciality?._id },
                    role: { code: 'DOCTOR' },
                    _id: { $nin: alreadyAssignedDoctors },
                  },
                })
                }
              />
            </View>
            <View style={{ flexDirection: 'row', marginTop: 38, justifyContent: 'space-around' }}>
              <View>
                <OutlineButton
                  label="Cancel"
                  width={109}
                  onClick={() => {
                    navigation.pop();
                  }}
                />
              </View>
              <View>
                <LargeButton
                  label="Confirm"
                  width={109}
                  onClick={formContext?.handleSubmit}
                />
              </View>

            </View>
          </>
        )}
      </Form>
    </View>
  );
};

const EditDoctorModal = (props) => (
  <ListActionModal
    icon={notificationError}
    title={'doctor.title.existingPatient'.getLabel()}
    description={'doctor.title.description'.getLabel()}
    content={<EditDoctorContent {...props} />}
    {...props}
  />
);

export default EditDoctorModal;
