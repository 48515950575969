import React from 'react';

import {
  Image,
  StandardButton, TouchableOpacity,
} from '../../../../app-components';
import {
  MODELS,
  ROUTES,
} from '../../../../Lib/constants';
import HeaderTitleWithCount from '../../../../Components/HeaderTitileWithCount';
import { Table } from '../../../../app-components/table/Table';
import { editRecord } from '../../../../images';
import { getPermission } from '../../../../AppServices';
import permissions from '../../../../Lib/permissions';

const header = (item) => ({
  title: () => (
    <HeaderTitleWithCount
      label="ACP Pay Matrix"
      count={item?.aggregates?._count}
    />
  ),
  actions: [
    {
      type: 'link',
      visible: () => getPermission(
        permissions.mdm.views.editPayMatrix.permissions.editPayMatrix.value,
      ),
      link: ({ navigation: { state: { params = {} } } }) => ({
        view: ROUTES.addAcpMatrixes.name,
        params: { ...params },
      }),
      render: () => (
        <StandardButton
          label="Add ACP Pay Matrix"
        />
      ),
    },
  ],
});

const tableItems = {
  header,
  lg: {
    columns: [
      {
        header: 'Grade Pay Level',
        type: 'text',
        field: 'gradePayLevel.name',
        width: 150,
      },
      {
        header: '1',
        type: 'text',
        value: ({ item }) => item?.levels?.[0]?.amount || '--',
        width: 100,
      },
      {
        header: '2',
        type: 'text',
        value: ({ item }) => item?.levels?.[1]?.amount || '--',
        width: 100,
      },
      {
        header: '3',
        type: 'text',
        value: ({ item }) => item?.levels?.[2]?.amount || '--',
        width: 100,
      },
      {
        header: '4',
        type: 'text',
        value: ({ item }) => item?.levels?.[3]?.amount || '--',
        width: 100,
      },
      {
        header: '5',
        type: 'text',
        value: ({ item }) => item?.levels?.[4]?.amount || '--',
        width: 100,
      },
      {
        header: '6',
        type: 'text',
        value: ({ item }) => item?.levels?.[5]?.amount || '--',
        width: 100,
      },
      {
        header: '7',
        type: 'text',
        value: ({ item }) => item?.levels?.[6]?.amount || '--',
        width: 100,
      },
      {
        header: '8',
        type: 'text',
        value: ({ item }) => item?.levels?.[7]?.amount || '--',
        width: 100,
      },
      {
        header: '9',
        type: 'text',
        value: ({ item }) => item?.levels?.[8]?.amount || '--',
        width: 100,
      },
      {
        header: '10',
        type: 'text',
        value: ({ item }) => item?.levels?.[9]?.amount || '--',
        width: 100,
      },
      {
        header: '11',
        type: 'text',
        value: ({ item }) => item?.levels?.[10]?.amount || '--',
        width: 100,
      },
      {
        header: '12',
        type: 'text',
        value: ({ item }) => item?.levels?.[11]?.amount || '--',
        width: 100,
      },
      {
        header: '13',
        type: 'text',
        value: ({ item }) => item?.levels?.[12]?.amount || '--',
        width: 100,
      },
      {
        header: '14',
        type: 'text',
        value: ({ item }) => item?.levels?.[13]?.amount || '--',
        width: 100,
      },
      {
        header: '15',
        type: 'text',
        value: ({ item }) => item?.levels?.[14]?.amount || '--',
        width: 100,
      },
      {
        header: '16',
        type: 'text',
        value: ({ item }) => item?.levels?.[15]?.amount || '--',
        width: 100,
      },
      {
        header: '17',
        type: 'text',
        value: ({ item }) => item?.levels?.[16]?.amount || '--',
        width: 100,
      },
      {
        header: '18',
        type: 'text',
        value: ({ item }) => item?.levels?.[17]?.amount || '--',
        width: 100,
      },
      {
        header: '19',
        type: 'text',
        value: ({ item }) => item?.levels?.[18]?.amount || '--',
        width: 100,
      },
      {
        header: '20',
        type: 'text',
        value: ({ item }) => item?.levels?.[19]?.amount || '--',
        width: 100,
      },
      {
        header: '21',
        type: 'text',
        value: ({ item }) => item?.levels?.[20]?.amount || '--',
        width: 100,
      },
      {
        header: '22',
        type: 'text',
        value: ({ item }) => item?.levels?.[21]?.amount || '--',
        width: 100,
      },
      {
        header: '23',
        type: 'text',
        value: ({ item }) => item?.levels?.[22]?.amount || '--',
        width: 100,
      },
      {
        header: '24',
        type: 'text',
        value: ({ item }) => item?.levels?.[23]?.amount || '--',
        width: 100,
      },
      {
        header: '25',
        type: 'text',
        value: ({ item }) => item?.levels?.[24]?.amount || '--',
        width: 100,
      },
      {
        header: '26',
        type: 'text',
        value: ({ item }) => item?.levels?.[25]?.amount || '--',
        width: 100,
      },
      {
        header: '27',
        type: 'text',
        value: ({ item }) => item?.levels?.[26]?.amount || '--',
        width: 100,
      },
      {
        header: '28',
        type: 'text',
        value: ({ item }) => item?.levels?.[27]?.amount || '--',
        width: 100,
      },
      {
        header: '29',
        type: 'text',
        value: ({ item }) => item?.levels?.[28]?.amount || '--',
        width: 100,
      },
      {
        header: '30',
        type: 'text',
        value: ({ item }) => item?.levels?.[29]?.amount || '--',
        width: 100,
      },
      {
        header: '31',
        type: 'text',
        value: ({ item }) => item?.levels?.[30]?.amount || '--',
        width: 100,
      },
      {
        header: '32',
        type: 'text',
        value: ({ item }) => item?.levels?.[31]?.amount || '--',
        width: 100,
      },
      {
        header: '33',
        type: 'text',
        value: ({ item }) => item?.levels?.[32]?.amount || '--',
        width: 100,
      },
      {
        header: '34',
        type: 'text',
        value: ({ item }) => item?.levels?.[33]?.amount || '--',
        width: 100,
      },
      {
        header: '35',
        type: 'text',
        value: ({ item }) => item?.levels?.[34]?.amount || '--',
        width: 100,
      },
      {
        header: '36',
        type: 'text',
        value: ({ item }) => item?.levels?.[35]?.amount || '--',
        width: 100,
      },
      {
        header: '37',
        type: 'text',
        value: ({ item }) => item?.levels?.[36]?.amount || '--',
        width: 100,
      },
      {
        header: '38',
        type: 'text',
        value: ({ item }) => item?.levels?.[37]?.amount || '--',
        width: 100,
      },
      {
        header: '39',
        type: 'text',
        value: ({ item }) => item?.levels?.[38]?.amount || '--',
        width: 100,
      },
      {
        header: '40',
        type: 'text',
        value: ({ item }) => item?.levels?.[39]?.amount || '--',
        width: 100,
      },
      {
        header: 'Edit'.getLabel(),
        type: 'text',
        visible: () => getPermission(
          permissions.mdm.views.editPayMatrix.permissions.editPayMatrix.value,
        ),
        render: () => (
          <TouchableOpacity>
            <Image
              style={{
                height: 24,
                width: 24,
              }}
              source={editRecord}
            />
          </TouchableOpacity>
        ),
        action: (data) => ({
          type: 'link',
          // visible: () => getPermission(
          //   permissions.departments.views.ipdEditForms
          //     .permissions.ipdEditForms.value,
          // ),
          link: {
            view: ROUTES.editAcpMatrixes.name,
            params: {
              addOnFilter: {
                _id: data.item._id,
              },
            },

          },
        }),
      },
    ],
  },
};

const AcpMatrix = (props) => (
  <Table
    {...props}
    uri={({
      query: {
        model: MODELS.ACP_PAY_MATRIX,
        id: 'gradePay',
      },
      aggregate: true,
      model: MODELS.ACP_PAY_MATRIX,
    })}
    {...tableItems}
    reloadEvent={`reload${MODELS.ACP_PAY_MATRIX}`}
    type="link"
    action={{
      type: 'link',
      link: (data) => ({
        view: ROUTES.viewAcpMatrixes.name,
        params: {
          addOnFilter: {
            _id: data.item._id,
          },
        },
      }),
    }}
  />
);

export default AcpMatrix;
