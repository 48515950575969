import VaccinationTokenGenerationScreens from './VaccinationTokenGeneration';
import IncomingPatientNursePortal from './IncomingPatientNursePortal/index';
import patientVaccinationHistory from './PatientVaccinationHistory/index';

const VaccinationScreens = {
  ...VaccinationTokenGenerationScreens,
  ...IncomingPatientNursePortal,
  ...patientVaccinationHistory,
};

export default VaccinationScreens;
