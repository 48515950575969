import React, { useCallback, useEffect, useState } from 'react';

import { View, Text, ScrollView } from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import vars from '../../../../theme/vars';
import ConsultationActions from './Components/ConsultationActions';
import ConsultationStatus from './Components/ConsultationStatus';
import IncomingPatientDetails from '../IncomingPatientsDetails';
import { getUser, gpsStore } from '../../../../AppServices';
import { MODELS } from '../../../../Lib/constants';

const tabListColumns = ({ showPatientDetails, isPatientDetailsVisible, patientDetails }) => [
  {
    width: 20,
  },
  {
    header: 'Token',
    type: 'number',
    field: 'token',
    width: 100,
    render: ({ item }) => (
      <View style={{}}>
        <Text
          style={{
            ...vars.headings.h2,
          }}
        >
          {item?.token}
        </Text>
      </View>
    ),
  },
  {
    header: 'UHID',
    type: 'text',
    field: 'patient.uhid',
    width: 112,
  },
  {
    header: 'Patient Name',
    type: 'text',
    field: 'patient.patientName',
    width: 250,
  },
  {
    header: 'Status',
    type: 'custom',
    render: (props) => (
      <ConsultationStatus
        {...props}
        patientDetails={patientDetails}
      />
    ),
    width: 250,
  },
  {
    header: 'Actions',
    type: 'custom',
    render: (props) => (
      <ConsultationActions
        showPatientDetails={showPatientDetails}
        isPatientDetailsVisible={isPatientDetailsVisible}
        patientDetails={patientDetails}
        {...props}
      />
    ),
    width: 250,
  },
  {
    width: 100,
  },
];

const ConsultedPatients = (props) => {
  const { tableColumns = tabListColumns, addOnFilter, eventDispatcher } = props;

  const user = getUser() || {};

  const [isPatientDetailsVisible, setPatientDetailsVisible] = useState(false);
  const [patientDetails, setPatientDetails] = useState();

  const reloadConsultations = useCallback(() => {
    eventDispatcher.notify('reloadConsultedPatients');
  }, [eventDispatcher]);

  const subscribeToConsultationsUpdates = useCallback((doctorId) => {
    gpsStore.subscribeEvent({
      event: `consultedPatient-${doctorId}-new`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const unSubscribeToConsultationsUpdates = useCallback((doctorId) => {
    gpsStore.unsubscribeEvent({
      event: `consultedPatient-${doctorId}-new`,
      callback: reloadConsultations,
    });
  }, [reloadConsultations]);

  const showPatientDetails = useCallback((data) => {
    setPatientDetails(data);
    setPatientDetailsVisible(true);
  }, []);

  useEffect(() => {
    if (user?.employee?._id) {
      subscribeToConsultationsUpdates(user?.employee?._id);
    }

    return () => unSubscribeToConsultationsUpdates();
  }, [user?.employee]);

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <Table
          showSelected
          isSelected={(selectedItem) => selectedItem?.item?._id === patientDetails?._id}
          uri={() => ({
            query: {
              id: 'consultationDataList',
              addOnFilter,
              sort: {
                token: -1,
              },
            },
            model: MODELS.CONSULTATION,
          })}
          columns={tableColumns({ showPatientDetails, isPatientDetailsVisible, patientDetails })}
          showActionIcon={false}
          reloadEvent="reloadConsultedPatients"
          {...props}
        />
      </View>
      {isPatientDetailsVisible && (
        <ScrollView
          style={{
            width: '50%',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <IncomingPatientDetails
            patientId={patientDetails?.patient?._id}
            consultationId={patientDetails?._id}
            remarks={patientDetails?.remarks}
            diagnosis={patientDetails?.diagnosis}
            prescription={patientDetails?.prescription}
            token={patientDetails?.token}
            setPatientDetailsVisible={setPatientDetailsVisible}
            status={patientDetails?.status}
            setPatientDetails={setPatientDetails}
            {...props}
          />
        </ScrollView>
      )}
    </View>
  );
};
export default ConsultedPatients;
