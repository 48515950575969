import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';

const TakeReferencesIPDForm = (props) => (
  <ListActionModal
    title="Take Reference"
    description="Request reference from other department for the case."
    content={(
      <Form
        {...props}
        footer={{
          containerStyle: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          actions: [
            save({
              label: 'Save',
            }),
          ],
        }}
        closeView={1}
        reloadEvent={`reload${MODELS.IPD_REFERENCE}`}
        bodyContainerStyle={{ backgroundColor: vars.colors.white }}
        onSubmit={async (submitProps) => {
          const { updates, data } = submitProps;
          const newUpdate = {
            ...updates,
            admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
          };
          const submitAPI = submit({
            model: MODELS.IPD_REFERENCE,
            ...submitProps,
            data,
            updates: newUpdate,
          });
          await submitAPI({ data, updates: newUpdate });
        }}
        defaultValues={() => ({
          department: { name: 'IPD' },
          referenceDate: new Date(),
        })}
        mandatory={{
          _id: 1,
        }}

        lg={{
          formGroups: [
            {
              columnsPerRow: 3,
              fieldVariant: 'filled',
              labelStyle: {
                ...vars.headings.h8,
                color: vars.colors.grey.color2,
                textTransform: 'uppercase',
                paddingLeft: 10,
              },
              groupsContainerStyle: { paddingLeft: 12 },
              groups: [
                {
                  columns: [
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'department',
                      label: 'DEPARTMENT',
                      suggestionField: 'name',
                      model: MODELS.DEPARTMENTS,
                      query: 'masterDataList',
                    }),
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'speciality',
                      label: 'Speciality',
                      keyField: 'name',
                      valueField: 'name',
                      suggestionField: 'name',
                      model: MODELS.WARDS,
                      query: 'masterDataList',
                    }),
                    autoSuggestInput({
                      type: 'autoSuggest',
                      field: 'doctorName',
                      label: 'DOCTOR',
                      suggestionField: 'name',
                      keyField: 'name',
                      valueField: 'name',
                      model: MODELS.EMPLOYEE,
                      query: 'employeeLists',
                    },
                    {
                      addOnFilter: ({ data }) => {
                        const specialityId = data?.speciality?._id;
                        if (specialityId) {
                          return {
                            speciality: { _id: specialityId },
                            // department: { _id: data?.department._id },
                          };
                        }
                        return {};
                      },
                    }),
                  ],
                },
              ],
            },
          ],
        }}
      />
    )}
    {...props}
  />
);
export default TakeReferencesIPDForm;
