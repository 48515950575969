import { uniqueId } from 'lodash';
import { MODELS } from '../Lib/constants';

export const consultationLogUri = ({ filters = {} } = {}) => ({
  query: {
    id: 'patientDataList',
    addOnFilter: filters,
  },
  model: 'patients',
});

export const EditPatientUri = ({ navigation }) => ({
  query: {
    model: MODELS.PATIENTS,
    id: 'patientDataList',
    relationValue: {
      model: MODELS.PATIENTS,
      _id: navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.PATIENTS,
});

export const consultationListUri = () => ({
  query: {
    id: 'consultationDataList',
    sort: { _id: -1 },
  },
  aggregate: true,
  model: MODELS.CONSULTATION,
});

export const consultationDetailUri = (_id) => ({
  query: {
    id: 'consultationDataList',
    relationValue: {
      model: MODELS.CONSULTATION,
      _id,
      relation: '_id',
    },
    limit: 1,
  },
  aggregate: true,
  model: MODELS.CONSULTATION,
});

export const editConsultationUri = ({ consultationId }) => ({
  query: {
    model: MODELS.CONSULTATION,
    id: 'getConsultationPrescriptions',
    computation: (data) => {
      const clonedData = data?.length ? data[0] : {};

      clonedData.pharmacyDetails = clonedData.pharmacyDetails
        && clonedData.pharmacyDetails.length
         && clonedData.pharmacyDetails.map((item) => ({
           ...item,
           itemName: item?.item,
           frequency: item?.frequency,
           doses: item?.doses,
           doseTime: item?.doseTime,
         }));

      if (clonedData.pharmacyDetails?.length) {
        return [clonedData];
      }
      return [
        {
          ...clonedData,
          pharmacyDetails: [{
            _id: `new _${uniqueId()}`,
          }],
        },
      ];
    },
    relationValue: {
      model: MODELS.PATIENTS,
      _id: consultationId,
      relation: '_id',
    },
    limit: 1,
  },
  aggregate: true,
  model: MODELS.CONSULTATION,
});

export const consultationIPDListUri = () => ({
  query: {
    id: 'admitIPDDataList',
    sort: { _id: -1 },
  },
  aggregate: true,
  model: MODELS.IPD_ADMISSION,
});
