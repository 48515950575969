import React, { useCallback } from 'react';

import {
  FormField, LargeButton, Toast, View,
} from '../../app-components';
import { Form } from '../../app-components/form/Form';
import { downloadData } from '../../AppServices';
import { autoSuggestInput } from '../../autoSuggestions';
import ListActionModal from '../../Components/ListActionModal';
import { history } from '../../images';
import { MODELS } from '../../Lib/constants';
import vars from '../../theme/vars';

const GenerateAllSalaryModal = (props) => {
  const {
    navigation,
  } = props;

  const handleOnSubmitGenerateSalary = useCallback(async ({ data }) => {
    await downloadData({
      service: {
        id: 'yearlySalarySheetExcelInvoke',
        paramValue: { ...data },
      },
    });

    Toast.show({
      message: 'Success',
      description: 'Salary Sheet has been generated successfully',
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });

    navigation.pop();
  }, [navigation]);

  return (
    <ListActionModal
      icon={history}
      title="Generate Salary Sheet"
      description="This action will download salary sheet for selected month and year"
      content={(
        <Form
          onSubmit={handleOnSubmitGenerateSalary}
          mandatory={{
            financial_year_id: 1,
            // year_id: 1,
          }}
          containerStyle={{
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          bodyContainerStyle={{
            margin: 20,
            flex: 1,
            backgroundColor: vars.colors.white,
          }}
          // defaultValues={() => ({
          //   financial_year_id: Math.floor((new Date().getMonth() + 3) / 3) === 1 ? `${new Date().getFullYear('YYYY') - 1}-${new Date().getFullYear('YYYY')}`
          //     : `${new Date().getFullYear('YYYY')}-${new Date().getFullYear('YYYY') + 1}`,
          //   month_id: props?.navigation?.state?.params?.month_id?.value,
          //   year_id: props?.navigation?.state?.params?.year_id?.value,
          // })}
        >
          {
            ({ form_context: formContext = {} }) => {
              const { handleSubmit } = formContext;

              return (
                <View>
                  <FormField
                    {...autoSuggestInput(
                      {
                        field: 'financial_year_id',
                        label: 'Select Financial Year',
                        placeholder: 'Select Financial Year',
                        variant: 'filled',
                        suggestionField: 'name',
                        model: MODELS.FINANCIAL_YEAR,
                        query: 'financialYearSuggestions',
                        mandatory: true,
                      },
                    )}
                  />
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'employee_type',
                          label: 'Select Employee Type',
                          placeholder: 'Select Employee Type',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.EMPLOYEETYPE,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 18,
                    }}
                  >
                    <FormField
                      {...autoSuggestInput(
                        {
                          field: 'staff_type',
                          label: 'Select Staff Type',
                          placeholder: 'Select Staff Type',
                          variant: 'filled',
                          suggestionField: 'name',
                          model: MODELS.STAFFTYPE,
                          query: 'masterDataList',
                        },
                      )}
                    />
                  </View>

                  <View
                    style={{
                      flex: 1,
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LargeButton
                      label="Generate"
                      onClick={handleSubmit}
                      width={109}
                      height={40}
                      borderRadius={4}
                    />
                  </View>
                </View>
              );
            }
          }
        </Form>
        )}
      {...props}
    />
  );
};

export default GenerateAllSalaryModal;
