import MasterForm from '../../../Wired/Master/MasterForm';
import { save, close } from '../../../app-components';
import { MODELS } from '../../../Lib/constants';

const header = {
  title: 'Add City',
  subTitle: 'Fill out the form to add new City',
  actions: [close],
};
const footer = {
  actions: [save()],
};

export default MasterForm({
  header,
  model: MODELS.CITY,
  footer,
});
