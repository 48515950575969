/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import {
  FormField,
  StandardButton,
  View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import { submit } from '../../../../../AppServices';
import ListActionModal from '../../../../../Components/ListActionModal';
import vars from '../../../../../theme/vars';
import { MODELS } from '../../../../../Lib/constants';

const AddAssetItemRemarks = (props) => {
  const { navigation: { state: { params: { item: { _id } } } } = {} } = props;
  const today = moment();
  const month = today.format('MMMM');
  const year = today.format('YYYY');

  return (
    <ListActionModal
      title="Add Remark"
      description="Add Monthly remarks for the assigned item."
      content={(
        <Form
          closeView={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={submit({
            model: MODELS.ASSET_MONTHLY_REMARKS,
          })}
          reloadEvent={`reload${MODELS.ASSET_MONTHLY_REMARKS}`}
          defaultValues={{
            month, item: { _id }, remarksDate: today, isMonthlyRemarksAdded: true,
          }}
          mandatory={{
            monthlyRemarks: 1,
          }}
          submitMessage={'asset.messages.monthlyRemarksAdded'.getLabel()}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 22,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="date"
                    label={'asset.labels.date'.getLabel()}
                    variant="filled"
                    value={today}
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="text"
                    label={'asset.labels.recordMonth'.getLabel()}
                    variant="filled"
                    value={month}
                    editable={false}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="text"
                    label={'asset.labels.recordYear'.getLabel()}
                    variant="filled"
                    value={year}
                    editable={false}
                  />
                </View>
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ justifyContent: 'center', width: 550 }}>
                  <FormField
                    type="textArea"
                    label={'asset.labels.remarks'.getLabel()}
                    variant="filled"
                    field="monthlyRemarks"
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label={'asset.buttons.confirm'.getLabel()}
                  onClick={formContext.handleSubmit}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default AddAssetItemRemarks;
