import React from 'react';
import moment from 'moment';
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
} from '@applane/react-core-components';
import { WithModal } from '@applane/react-with-modal';
import { ToggleButton } from '@applane/react-toggle';
import Modal from './CustomHeaderPopup';
import { dateInputStyle } from '../../../theme/DateBoxtheme';
import { dateFilterTheme } from '../../../theme/dateFilterTheme';
import { getInputTheme } from '../../input-components/themeUtility';

class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Range: '', // visible part of Day & Date filter at the browser
      Year: [], // previousFinancialYear, CurrentFinancialYear , ThisYear
      Month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      Week: [], // ... , LastWeek , ThisWeek , NextWeek , ...
      Day: '', // calculate the day like in range of previous , this Day and next Day
      filterOf: '', // which filter is applicable like Day Month Year Week,
      DayName: ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    };
  }

  rangeCalculator = ({ filterOf, from, to }) => {
    let Range = '';
    let toDate = new Date(to).getDate();
    if (toDate < 10) {
      toDate = `0${toDate}`;
    }
    const toDay = this.state.DayName[new Date(to).getDay()];
    const toMonth = this.state.Month[new Date(to).getMonth()];
    let toYear = new Date(to).getFullYear();
    let fromDate = new Date(from).getDate();
    if (fromDate < 10) {
      fromDate = `0${fromDate}`;
    }
    const fromDay = this.state.DayName[new Date(from).getDay()];
    const fromMonth = this.state.Month[new Date(from).getMonth()];
    let fromYear = new Date(from).getFullYear();
    if (filterOf === 'Day' && to) {
      Range = `${toDate} ${toMonth.slice(0, 3)}, ${toDay}`;
    } else if (filterOf === 'Month' && to) {
      Range = `${toMonth}, ${toYear}`;
    } else if (filterOf === 'Week' && from && to) {
      Range = `${fromDate},${fromMonth.slice(0, 3)}-${toDate},${toMonth.slice(
        0,
        3,
      )}`;
    } else if (filterOf === 'Year' && from && to) {
      fromYear = String(fromYear);
      fromYear = fromYear[fromYear.length - 2] + fromYear[fromYear.length - 1];
      toYear = String(toYear);
      toYear = toYear[toYear.length - 2] + toYear[toYear.length - 1];

      Range = `${fromMonth.slice(0, 3)},${fromYear}-${toMonth.slice(
        0,
        3,
      )},${toYear} `;
    } else if (filterOf === 'Custom') {
      if (from && to) {
        Range = `${fromDate} ${fromMonth.slice(
          0,
          3,
        )} - ${toDate} ${toMonth.slice(0, 3)}`;
      } else if (from) {
        Range = `${fromDate} ${fromMonth.slice(0, 3)}, ${fromYear}`;
      } else if (to) {
        Range = `${toDate} ${toMonth.slice(0, 3)}, ${toYear}`;
      }
    } else {
      Range = 'Custom';
    }
    return Range;
  };

  applyFilter = (data) => {
    const today = new Date();
    data = data || {};
    const { value, nav } = data;
    let from = '';
    let to = '';
    const {
      field,
      screenState: { dataParams } = {},
      setScreenState,
      asParams,
      onFilterChange,
      filterTabs,
      setTabsState,
    } = this.props;
    if (nav) {
      from = data && data.from;
      to = data && data.to;
    } else if (value == 'No Filter') {
      data.removeFilter = true;
    } else if (value == 'Day') {
      from = today.setHours(0, 0, 0);
      from = new Date(from);
      to = today.setHours(23, 59, 59);
      to = new Date(to);
    } else if (value == 'Week') {
      from = today.setHours(-72, 0, 0);
      from = new Date(from);
      to = new Date(today.setHours(168, 0, 0));
      to = new Date(to.setHours(0, 0, 0, -1));
    } else if (value == 'Month') {
      const month = today.getMonth();
      const year = today.getFullYear();
      from = moment(`${month + 1} ${1} ${year}`, 'MM DD YYYY');
      from = new Date(from);
      to = moment(`${month + 2} ${1} ${year}`, 'MM DD YYYY');
      to = new Date(to);
      to = new Date(to.setHours(0, 0, 0, -1));
    } else if (value == 'Year') {
      const month = today.getMonth();
      if (month > 3) {
        from = moment(`${4} ${1} ${today.getFullYear()}`, 'MM DD YYYY');
        from = new Date(from);
        to = moment(`${4} ${1} ${today.getFullYear() + 1}`, 'MM DD YYYY');
        to = new Date(to);
        to = new Date(to.setHours(0, 0, 0, -1));
      } else {
        from = moment(`${4} ${1} ${today.getFullYear() - 1}`, 'MM DD YYYY');
        from = new Date(from);
        to = moment(`${4} ${1} ${today.getFullYear()}`, 'MM DD YYYY');
        to = new Date(to);
        to = new Date(to.setHours(0, 0, 0, -1));
      }
    } else if (value == 'Custom') {
      from = data && data.from;
      to = data && data.to;
    }

    let { filters } = dataParams || {};
    filters = {
      ...filters,
    };
    const filterValue = { from, to, filterOf: value };
    filters[field] = {
      value: filterValue,
      filter: {
        asParams,
        value: filterValue,
        filterType: 'date',
      },
    };

    if (data && data.removeFilter) {
      delete filters[field];
    }

    if (onFilterChange && typeof onFilterChange === 'function') {
      onFilterChange({ filters });
    }

    if (filterTabs && setTabsState) {
      setTabsState({
        filters,
        filtersInfo: {
          [field]: { filterTabs },
        },
      });
    } else {
      setScreenState &&
        setScreenState({
          dataParams: {
            ...dataParams,
            filters,
          },
        });
    }
  };

  PrevNextFunctionOfDate = ({ nav, ...restValue }) => {
    let { filterOf, from, to } = restValue;
    const event = nav;
    from = new Date(from).setHours(0, 0, 0);
    to = new Date(to).setHours(23, 59, 59);
    if (filterOf === 'Day') {
      if (event === 'Prev') {
        from -= 86400000;
        to -= 86400000;
      } else if ('Next') {
        from += 86400000;
        to += 86400000;
      }
      from = new Date(from);
      to = new Date(to);
    } else if (filterOf === 'Week') {
      if (event === 'Prev') {
        from -= 86400000 * 7;
        to -= 86400000 * 7;
      } else if (event === 'Next') {
        from += 86400000 * 7;
        to += 86400000 * 7;
      }
      from = new Date(from);
      to = new Date(to);
    } else if (filterOf === 'Month') {
      const month = new Date(from).getMonth();
      let year = new Date(from).getFullYear();
      if (event === 'Prev') {
        if (month === 0) {
          year -= 1;
        }
        from = moment(`${month === 0 ? 12 : month} ${1} ${year}`, 'MM DD YYYY');
        from = new Date(from);
        to = moment(
          `${month === 0 ? 1 : month + 1} ${1} ${
            month === 0 ? year + 1 : year
          }`,
          'MM DD YYYY',
        );
        to = new Date(to);
        to = new Date(to.setHours(0, 0, 0, -1));
      } else if (event === 'Next') {
        if (month === 11) {
          year += 1;
        }
        from = moment(
          `${month === 11 ? 1 : month + 2} ${1} ${year}`,
          'MM DD YYYY',
        );
        from = new Date(from);

        to = moment(
          `${month >= 10 ? month - 9 : month + 3} ${1} ${year}`,
          'MM DD YYYY',
        );
        to = new Date(to);
        to = new Date(to.setHours(0, 0, 0, -1));
        if (month === 10) {
          to = moment(`${12} ${31} ${year} 23:59:59`, 'MM DD YYYY hh:mm::ss');
          to = new Date(to);
        }
      }
      from = new Date(from);
      to = new Date(to);
    } else if (filterOf === 'Year') {
      const fromYear = new Date(from).getFullYear();
      const toYear = new Date(to).getFullYear();
      const thisYear = new Date().getFullYear();
      if (event === 'Prev') {
        if (thisYear === fromYear) {
          from = moment(`${4} ${1} ${fromYear - 1}`, 'MM DD YYYY');
          from = new Date(from);
          to = moment(`${4} ${1} ${toYear}`, 'MM DD YYYY');
          to = new Date(to);
          to = new Date(to.setHours(0, 0, 0, -1));
        } else {
          from = moment(`${4} ${1} ${fromYear - 1}`, 'MM DD YYYY');
          from = new Date(from);
          to = moment(`${4} ${1} ${toYear - 1}`, 'MM DD YYYY');
          to = new Date(to);
          to = new Date(to.setHours(0, 0, 0, -1));
        }
      } else if (event === 'Next') {
        if (fromYear === thisYear && toYear === thisYear) {
          return;
        }
        if (thisYear === toYear) {
          from = moment(`${1} ${1} ${thisYear}`, 'MM DD YYYY');
          from = new Date(from);
          to = moment(`${1} ${1} ${thisYear + 1}`, 'MM DD YYYY');
          to = new Date(to);
          to = new Date(to.setHours(0, 0, 0, -1));
        } else {
          from = moment(`${4} ${1} ${fromYear + 1}`, 'MM DD YYYY');
          from = new Date(from);
          to = moment(`${4} ${1} ${toYear + 1}`, 'MM DD YYYY');
          to = new Date(to);
          to = new Date(to.setHours(0, 0, 0, -1));
        }
      }
    }
    this.applyFilter({
      from,
      to,
      nav: true,
      value: filterOf,
    });
  };

  render() {
    let {
      textStyle = {},
      dateFilterToggleTheme = {},
      customHeaderPopupTheme = {},
      containerStyle = {},
      leftContainerStyle = {},
      leftDropDownStyle = {},
      rightContainerStyle = {},
      rightDropDownStyle = {},
      leftArrowIcon,
      rightArrowIcon,
      arrowDownIcon,
      screenState,
      field,
      dateSelectorData,
      ...restProps
    } = this.props;
    let value = screenState && screenState.dataParams && screenState.dataParams.filters;
    if (value && value[field] && value[field].value) {
      value = value[field].value;
    }
    value = value || {};
    const { from, to, filterOf = 'No Filter' } = value;
    const dateForDay = new Date();
     dateSelectorData =dateSelectorData ?? [
      { label: 'Today', value: 'Day' },
      { label: 'Last 7 Days', value: 'Week' },
      { label: 'Last 30 Days', value: 'Month' },
      { label: 'Last 1 Year', value: 'Year' },
      { label: 'Lifetime', value: 'No Filter' },
    ];

    const Component = filterOf === 'No Filter' || filterOf === 'Custom' ? WithModal : View;

    const Range = this.rangeCalculator({ ...value });
    return (
      <View style={containerStyle}>
        <WithModal
          autoHide
          style={leftContainerStyle}
          dropdownStyle={leftDropDownStyle}
          renderModal={({ frameStyle, hideModal }) => (
            <View
              style={{
                ...frameStyle,
                ...leftDropDownStyle,
              }}
            >
              <ToggleButton
                value={filterOf}
                options={dateSelectorData}
                onChangeValue={(value) => {
                  filterOf !== value && this.applyFilter({ value });
                  hideModal && hideModal();
                }}
                {...dateFilterToggleTheme}
              />
            </View>
          )}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Text style={{ ...textStyle }}>
              {filterOf ? (filterOf === 'No Filter' ? 'All' : filterOf) : 'All'}
            </Text>
            <Image source={arrowDownIcon} />
          </View>
        </WithModal>

        <Component
          autoHide={Platform.OS === 'web'}
          {...(Platform.OS !== 'web'
            && (filterOf === 'No Filter' || filterOf === 'Custom') && {
            position: 'screenCenter',
          })}
          style={{
            ...rightContainerStyle,
            cursor:
              filterOf === 'No Filter' || filterOf === 'Custom'
                ? 'pointer'
                : 'default',
          }}
          {...(Platform.OS !== 'web' && {
            backdropStyle: { backgroundColor: 'rgba(0,0,0,0.2)' },
          })}
          dropdownStyle={rightDropDownStyle}
          renderModal={({ frameStyle, hideModal }) => (
            <Modal
              frameStyle={{
                ...frameStyle,
                ...rightDropDownStyle,
              }}
              hideModal={hideModal}
              onApply={this.applyFilter}
              item={this.state}
              from={from}
              to={to}
              filterOf={filterOf}
              {...restProps}
              customHeaderPopupTheme={customHeaderPopupTheme}
            />
          )}
        >
          {filterOf && filterOf !== 'No Filter' && filterOf !== 'Custom' ? (
            <TouchableOpacity
              onPress={() => this.PrevNextFunctionOfDate({ nav: 'Prev', ...value })}
              style={{ paddingLeft: 8, cursor: 'pointer' }}
              title={
                filterOf == 'Year'
                  ? 'Previous financial year'
                  : `Previous ${this.state.filterOf}`
              }
            >
              {leftArrowIcon ? (
                <Image source={leftArrowIcon} resizeMode="contain" />
              ) : (
                <Text style={{ ...textStyle }}>‹</Text>
              )}
            </TouchableOpacity>
          ) : (
            void 0
          )}
          <View style={{ flex: 1 }}>
            {(filterOf == 'Day' || filterOf === 'Week') && Range && (
              <Text
                style={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  ...textStyle,
                  textAlign: 'center',
                }}
              >
                {(filterOf == 'Day'
                  && (new Date(value.from).getFullYear()
                    == dateForDay.getFullYear()
                  && new Date(value.from).getMonth() == dateForDay.getMonth()
                    ? (new Date(value.from).getDate() == dateForDay.getDate()
                        && 'Today')
                      || (new Date(value.from).getDate()
                        == dateForDay.getDate() - 1
                        && 'Yesterday')
                      || (new Date(value.from).getDate()
                        == dateForDay.getDate() + 1
                        && 'Tomorrow')
                    : `${new Date(value.from).getFullYear()}`))
                  || `${
                    new Date(value.from).getFullYear()
                    === new Date(value.to).getFullYear()
                      ? new Date(value.from).getFullYear()
                      : `${new Date(value.from).getFullYear()}-${new Date(
                        value.to,
                      ).getFullYear()}`
                  }`}
              </Text>
            )}
            <Text
              numberOfLines={1}
              style={{
                paddingLeft: 4,
                paddingRight: 4,
                ...textStyle,
                textAlign: 'center',
              }}
              title={Range}
            >
              {Range === 'No Filter' ? 'Custom' : Range}
            </Text>
          </View>
          {(filterOf === 'No Filter' || filterOf === 'Custom') && (
            <Image source={arrowDownIcon} style={{ marginRight: 5 }} />
          )}
          {filterOf && filterOf !== 'No Filter' && filterOf !== 'Custom' ? (
            <TouchableOpacity
              style={{
                cursor: 'pointer',
                paddingRight: 8,
              }}
              onPress={() => this.PrevNextFunctionOfDate({ nav: 'Next', ...value })}
              title={filterOf == 'Year' ? 'This Year' : `Next ${filterOf}`}
            >
              {rightArrowIcon ? (
                <Image source={rightArrowIcon} resizeMode="contain" />
              ) : (
                <Text style={{ ...textStyle }}>›</Text>
              )}
            </TouchableOpacity>
          ) : (
            void 0
          )}
        </Component>
      </View>
    );
  }
}

export default (props) => {
  const theme = getInputTheme({ variant: 'filled', ...props });
  return (
    <DateFilter
      format="DD/MM/YYYY"
      zeroTimeDate
      {...theme}
      calendarStyle={dateInputStyle.dateCalendarStyle}
      floatLabelOnActive={Platform.OS === 'web'}
      {...dateFilterTheme}
      {...props}
    />
  );
};
