import { MODELS } from '../../../Lib/constants';

export const houseListURI = () => ({
  model: MODELS.HOUSE_DETAILS,
  query: {
    id: 'masterDataListForTable',
  },
});

export const EditHouseDetails = ({ navigation }) => ({
  query: {
    model: MODELS.HOUSE_DETAILS,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.HOUSE_DETAILS,
      _id: navigation.state.params.item._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.HOUSE_DETAILS,
});

export const ViewHouseAllotmentURI = (id) => ({
  model: MODELS.HOUSE_ALLOTMENT,
  query: {
    id: 'houseAllotmentDataList',
    addOnFilter: { houseID: { _id: id } },
  },
});

export const EditHouseAllotmentDetails = ({ props }) => ({
  query: {
    model: MODELS.HOUSE_ALLOTMENT,
    id: 'houseAllotmentDataList',
    relationValue: {
      model: MODELS.HOUSE_ALLOTMENT,
      _id: props?.navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.HOUSE_ALLOTMENT,
});

export const ViewElectricityBillURI = (id) => ({
  model: MODELS.ELECTRICITY_BILL,
  query: {
    id: 'electricityBillDataList',
    addOnFilter: { houseID: { _id: id } },
  },
});

export const electricityListURI = () => ({
  model: MODELS.ELECTRICITY_BILL,
  query: {
    id: 'electricityBillDataList',
  },
});

export const EditElectricityBillDetails = ({ props }) => ({
  query: {
    model: MODELS.ELECTRICITY_BILL,
    id: 'electricityBillDataList',
    relationValue: {
      model: MODELS.ELECTRICITY_BILL,
      _id: props?.navigation?.state?.params?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.ELECTRICITY_BILL,
});

export const EditHouseListDetails = ({ props }) => ({
  query: {
    model: MODELS.HOUSE_DETAILS,
    id: 'masterDataListForTable',
    relationValue: {
      model: MODELS.HOUSE_DETAILS,
      _id: props?.navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.HOUSE_DETAILS,
});
