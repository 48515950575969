import React from 'react';
import { size } from 'lodash';

import {
  Table,
  Text,
  TouchableOpacity,
} from '../../../../../../app-components';
import { AssetIndentRequestsListUri } from '../../../../../../Queries/asset';
import { MODELS, assetInventoryStockRequestStatus, ROUTES } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'asset.headers.dateRaised'.getLabel(),
        type: 'date',
        format: 'DD MMM YYYY',
        field: '_createdOn',
      },
      {
        header: 'asset.headers.indentNumber'.getLabel(),
        type: 'text',
        field: 'indentNumber',
      },
      {
        header: 'asset.headers.responseDate'.getLabel(),
        type: 'date',
        field: '_updatedOn',
      },
      {
        header: 'asset.headers.noOfItems'.getLabel(),
        type: 'number',
        value: ({ item: { indentDetails = [] } = {} }) => size(indentDetails),
      },
      {
        header: 'asset.headers.department'.getLabel(),
        type: 'text',
        value: ({ item: { department = {} } = {} }) => department?.name,
      },
      {
        header: 'asset.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { requestStatus } }) => {
          let indentRequestStatus = '';
          let statusColor = '';
          if (requestStatus === assetInventoryStockRequestStatus.approved) {
            indentRequestStatus = assetInventoryStockRequestStatus.approvedByMSI;
            statusColor = vars.colors.primary.color2;
          } else if (requestStatus === assetInventoryStockRequestStatus.raised) {
            indentRequestStatus = assetInventoryStockRequestStatus.pending;
            statusColor = vars.colors.warning;
          } else if (requestStatus === assetInventoryStockRequestStatus.stockProvided) {
            indentRequestStatus = assetInventoryStockRequestStatus.stockProvided;
            statusColor = vars.colors.primary.color2;
          } else {
            indentRequestStatus = assetInventoryStockRequestStatus.Rejected;
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {indentRequestStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Text style={{ color: vars.colors.secondary.color2 }}>
              View
            </Text>
          </TouchableOpacity>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.viewDepartmentIndentRequest.name,
            params: { item },
          },
        }),
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.ASSET_ITEM}`,
  uri: AssetIndentRequestsListUri,
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewDepartmentIndentRequest.name,
      params: { item },
    },
  }),
});
