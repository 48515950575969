/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import {
  FormField,
  Toast,
  View,
} from '../../../../../app-components';
import { Form } from '../../../../../app-components/form/Form';
import ListActionModal from '../../../../../Components/ListActionModal';
import vars from '../../../../../theme/vars';
import { invoke } from '../../../../../AppServices';
import { getErrorString } from '../../../../../Lib/helpers';
import { MODELS } from '../../../../../Lib/constants';
import { LoadingButton } from '../../../../../app-components/buttons/Buttons';

const PurchaseOrderApproveModal = (props) => {
  const { navigation: { state: { params } } = {} } = props;

  const ManageLastStatusApprove = useCallback(async ({ data }) => {
    const { navigation, eventDispatcher } = props;
    try {
      const {
        state: {
          params: { item },
        },
      } = navigation;
      const result = await invoke({
        id: 'approvePurchaseSupplyOrder',
        paramValue: { item, _id: item?._id, remarks: data?.remarks },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Request has been approved successfully by DIRECTOR',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
        eventDispatcher.notify(`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`);
        navigation.pop(2);
      }
    } catch (e) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(e)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, [props]);

  return (
    <ListActionModal
      title={'purchase.title.approve'.getLabel()}
      description={`This action will approve the ${
        params?.item?.type === 'PO' ? 'Purchase order' : 'Supply order'
      }.`}
      content={(
        <Form
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          reloadEvent={`reload${MODELS.PURCHASE_AND_SUPPLY_ORDER_QUEUE}`}
          defaultValues={() => ({
            isReview: false,
          })}
          mandatory={{
            remarks: 1,
          }}
          {...props}
        >
          {({ form_state: formState = {} }) => (
            <View
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                paddingLeft: 32,
                paddingRight: 22,
              }}
            >
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <View style={{ flex: 1 }}>
                  <FormField
                    type="textArea"
                    label={'purchase.labels.remark'.getLabel()}
                    variant="filled"
                    field="remarks"
                    mandatory
                    allowWhiteSpaces
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <LoadingButton
                  label={'purchase.button.confirm'.getLabel()}
                  onPress={() => ManageLastStatusApprove(formState)}
                  width={200}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
      )}
      {...props}
    />
  );
};
export default PurchaseOrderApproveModal;
