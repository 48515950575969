import React from 'react';
import {
  OutlineButton, StandardButton,
} from '../../../app-components';
import {
  TabNavigator,
} from '../../../app-components/tab/TabNavigator';
import ActivityLogsTabList from '../../../Wired/ActivityLogList';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { countUri } from '../../../Queries/countUri';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import AcpMatrix from './ACpMatrix/acpMatrix';
import AcpSchedule from './AcpSchedule/acpSchedule';
import GradePayLevel from './GradePayLevel/gradePayLevel';
import PayMatrix from './PayMatrix/payMatrix';

const getHeaderActionToRoute = (params) => {
  const { route, label } = params;

  return {
    render: () => (
    //   <WithPermission>
      <StandardButton label={label} />
    //   </WithPermission>
    ),
    type: 'link',
    link: (props) => ({
      view: route,
      push: true,
      expanded: true,
      params: { ...props?.navigation?.state?.params },
    }),
  };
};

const basicPayMatrix = (props) => (
  <TabNavigator
    {...props}
    tabs={{
      PayMatrix: {
        label: 'Pay Matrix',
        screen: PayMatrix,
        countUri: countUri({
          model: MODELS.PAY_MATRIX,
          id: 'gradePay',
        }),
        headerActions: [
          getHeaderActionToRoute({
            label: 'Add Acp Pay Matrix',
            route: ROUTES.addPayMatrixes.name,
            //   access_key: permissions.mdm.views.addRoom.permissions.addRoom.value,
          }),
        ],
      },
      AcpSchedule: {
        label: 'ACP Pay Schedule',
        screen: AcpSchedule,
        countUri: countUri({
          model: MODELS.ACP_PAY_SCHEDULE,
          id: 'gradePay',
        }),
        headerActions: [
          getHeaderActionToRoute({
            label: 'room.title.addRoom'.getLabel(),
            route: ROUTES.addPaySchedule.name,
            //   access_key: permissions.mdm.views.addRoom.permissions.addRoom.value,
          }),
        ],
      },
      GradePayLevel: {
        label: 'Grade Pay Level',
        screen: GradePayLevel,
        countUri: countUri({
          model: MODELS.GRADE_PAY_LEVEL,
          id: 'gradePay',
        }),
        headerActions: [
          getHeaderActionToRoute({
            label: 'room.title.addRoom'.getLabel(),
            route: ROUTES.addGradePayLevel.name,
            //   access_key: permissions.mdm.views.addRoom.permissions.addRoom.value,
          }),
        ],
      },
      AcpPayMatrix: {
        label: 'ACP Pay Matrix',
        screen: AcpMatrix,
        countUri: countUri({
          model: MODELS.ACP_PAY_MATRIX,
          id: 'gradePay',
        }),
      },

    }}
  />
);
export default basicPayMatrix;
