import React from 'react';

import HeaderTitleWithCount from '../../../Components/HeaderTitileWithCount';
import FTS from '../../../Components/TableFilters/FtsSearch';
import { MODELS, ROUTES } from '../../../Lib/constants';
import permissions from '../../../Lib/permissions';
import { countUri } from '../../../Queries/countUri';
import MasterList from '../../../Wired/Master/MasterList';

const tableColumns = [
  { type: 'selection' },
  {
    header: 'purchaseDepartmentTC.labels.purchaseDepartmentTCName'.getLabel(),
    type: 'text',
    field: 'name',
    sortable: 'name',
  },
  {
    header: 'purchaseDepartmentTC.labels.description'.getLabel(),
    type: 'text',
    field: 'description',
  },
  {
    header: 'purchaseDepartmentTC.labels.totalConditions'.getLabel(),
    type: 'text',
    field: 'conditions',
    value: ({ item }) => `${item?.conditions?.length || '0'}`,
  },
  {
    header: 'purchaseDepartmentTC.labels.status'.getLabel(),
    type: 'status',
    field: 'isActive',
  },
];

export default (props) => (
  <MasterList
    {...props}
    model={MODELS.PURCHASE_DEPARTMENT_TC}
    editFormId={ROUTES.editPurchaseDepartmentTC.name}
    tableColumns={tableColumns}
    access_key={permissions.mdm.views.modifyPurchaseTAndC.permissions.modifyPurchaseTAndC.value}
    header={({ aggregates }) => ({
      title: <HeaderTitleWithCount label={'purchaseDepartmentTC.title.purchaseDepartmentTCManagement'.getLabel()} count={aggregates?._count} />,
      countUri: countUri({
        model: MODELS.PURCHASE_DEPARTMENT_TC,
        id: 'masterDataList',
      }),
      actions: [
        (actionProps) => (<FTS {...actionProps} action={{ exp: ['name', 'description'] }} />),
      ],
    })}
    action={({ item }) => ({
      type: 'link',
      link: {
        view: ROUTES.viewPurchaseDepartmentTC.name,
        params: { item },
      },
    })}
  />
);
