import { ROUTES } from '../../../../../../Lib/constants';
import AddProgressPediatrics from './ProgressReport/AddProgressReport';
import ViewProgressReportPediatric from './ProgressReport/ViewReport';
import IpdViewVitalsPediatrics from './VitalSignsSheet/ViewVitalSheet';
import AddVitalsPediatrics from './VitalSignsSheet';
import EditVitalsPediatrics from './VitalSignsSheet/EditVitalSheet';
// import AddVitalsPediatrics from './VitalSignsSheet/AddVitals';

const pediatricScreen = {
  [ROUTES.AddProgressPediatrics.name]: {
    screen: AddProgressPediatrics,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.ViewProgressReportPediatric.name]: {
    screen: ViewProgressReportPediatric,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.AddVitalsPediatrics.name]: {
    screen: AddVitalsPediatrics,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.editVitalsPediatrics.name]: {
    screen: EditVitalsPediatrics,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
  [ROUTES.ViewVitalsPediatrics.name]: {
    screen: IpdViewVitalsPediatrics,
    expanded: true,
    modal: true,
    modalProps: {
      height: 840,
      width: 1000,
    },
  },
};

export default pediatricScreen;
