import React from 'react';
import { Table } from '../../../../../../../../app-components/table/Table';

const tabListColumns = [
  { width: 14 },
  {
    header: 'inventory.headers.dispensingDate'.getLabel(),
    type: 'date',
    format: 'DD-MM-YYYY',
    field: '_createdOn',
  },
  {
    header: 'inventory.headers.patientUHID'.getLabel(),
    type: 'text',
    field: 'patientId.uhid',
  },
  {
    header: 'inventory.headers.patientName'.getLabel(),
    type: 'text',
    field: 'patientId.patientName',
  },
  {
    header: 'inventory.headers.department'.getLabel(),
    type: 'text',
    field: 'patientId.lastConsultation.department.name',
  },
  {
    header: 'inventory.headers.speciality'.getLabel(),
    type: 'text',
    field: 'patientId.lastConsultation.speciality.name',
  },
  {
    header: 'inventory.headers.quantity'.getLabel(),
    type: 'number',
    field: 'pharmacyDetails.quantity',
  },
  {
    header: 'inventory.headers.avgConsumption'.getLabel(),
    type: 'text',
    value: ({
      item: { pharmacyDetails = [] } = {},
    }) => pharmacyDetails[0]?.item?.avgMonthlyConsumption,
  },
  {
    header: 'inventory.headers.issuedBy'.getLabel(),
    type: 'text',
    field: 'dispensedBy.name',
  },
];

const PatientWiseDispensingList = (props) => {
  const {
    id, model, tableColumns = tabListColumns, addOnFilter, ...restProps
  } = props;
  return (
    <Table
      uri={() => ({
        query: {
          id,
          addOnFilter,
          sort: { _createdOn: -1 },
        },
        model,
      })}
      lg={{ columns: tableColumns }}
      hoverable={false}
      {...restProps}
    />
  );
};
export default PatientWiseDispensingList;
