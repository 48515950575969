import { MODELS } from '../Lib/constants';

export const GetOTListUri = () => ({
  query: {
    model: MODELS.IPD_OT_LIST,
    id: 'otListIPDDataList',
  },
  aggregate: true,
  model: MODELS.IPD_OT_LIST,
});
