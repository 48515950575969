export default {
  headers: {
    issueDate: 'Date of Issue',
    newIssueDate: 'New Date of Issue',
    issueTime: 'Time of Issue',
    newIssueTime: 'New Time of Issue',
    issueNumber: 'Issue Number',
    issuer: 'Patient Name',
    reason: 'Reason For Issuing Blood',
    unitIssued: 'Unit Issued',
    donationDate: 'Date of Donation/Collection',
    bagNo: 'Bag NO.',
    donationNo: 'Donation No.',
    tubeNo: 'Tube No.',
    donorName: 'Donor Name',
    bloodGroup: 'Blood Group',
    date: 'Date',
    department: 'Department',
    speciality: 'Speciality',
    doctorName: 'Doctor Name',
    patientName: 'Patient Name',
    status: 'Status',
  },
  labels: {
    issueNumber: 'Issue Number',
    dateOfIssue: 'Date Of Issue',
    dateOfRequest: 'Date Of Request',
    newIssueDate: 'New Date of Issue',
    department: 'Department',
    speciality: 'Speciality',
    issueTime: 'Time of Issue',
    newIssueTime: 'New Time of Issue',
    doctorName: 'Doctor Name',
    patientName: 'Patient Name',
    age: 'Age',
    uhid: 'UHID',
    bloodGroup: 'Blood Group',
    reason: 'Reason For Issuing Blood',
    relation: 'Relation',
    gender: 'Gender',
    unitRequired: 'Unit Required',
    unitRequested: 'Unit Requested',
    dateIssue: 'Date Issue',
    category: 'Category',
    time: 'Time Of Issue',
    status: 'Status',
    remarks: 'Remarks',
    reasonOfIssue: 'Reason of Issue',
    timeOfIssue: 'Time Of Issue',
    doctor: 'Doctor Name',
    UHID: 'UHID',
    bloodGroupName: 'Blood Group Name',
    attachments: 'Attachments',
    requestedBy: 'Requested By',
    reasonForUpdation: 'Reason For Updation',
  },
  placeholders: {
    reasonForUpdation: 'Enter Reason For Updation',
  },
  title: {
    updationRequest: 'Updation Request',
  },
  message: {
    updationDescription: 'There are changes in date and time, MS approval is required to perform the action.',
    requestMessage: 'Request has been sent to MS successfully',
  },
  buttons: {
    sendForApproval: 'Send For Approval',
  },
};
