import React from 'react';
import {
  OutlineButton, StandardButton, View,
} from '../../../../app-components';

import { Form } from '../../../../app-components/form/Form';
import { submit } from '../../../../AppServices';
import ListActionModal from '../../../../Components/ListActionModal';
import { history } from '../../../../images';
import { MODELS } from '../../../../Lib/constants';
import vars from '../../../../theme/vars';

const ConfirmationDetail = (props) => {
  const { navigation } = props;
  const {
    navigation: {
      state: {
        params: {
          mainData,
          submitMessage,
          description,
        },
      } = {},
    } = {},
  } = props;

  return (
    <ListActionModal
      icon={history}
      title="Confirmation"
      description={description}
      content={(
        <Form
          closeView={2}
          popOnError={1}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          submitMessage={submitMessage}
          reloadEvent="UserCompensationList"
          defaultValues={() => ({
            ...mainData,
          })}
          onSubmit={submit({
            model: MODELS.EMPLOYEE_SALARY_COMPONENT,
          })}
          {...props}
        >
          {({ form_context: formContext }) => (
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 0.5,
                  bottom: 15,
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <OutlineButton
                  label="No"
                  onClick={() => navigation.pop()}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  flex: 0.5,
                  bottom: 15,
                  alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <StandardButton
                  label="Yes"
                  onClick={formContext.handleSubmit}
                  width={109}
                  height={40}
                  borderRadius={4}
                />
              </View>
            </View>
          )}
        </Form>
        )}
      {...props}
    />
  );
};

export default ConfirmationDetail;
