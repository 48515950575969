import React from 'react';

import {
  FormField, save, Text, Toast, View,
} from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import { MODELS } from '../../../../../../../../Lib/constants';
import { Form } from '../../../../../../../../app-components/form/Form';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import vars from '../../../../../../../../theme/vars';

const IpdAddInvestigationENT = (props) => {
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  const { navigation: { state: { params: { selectedDate } = {} } = {} } = {} } = props;
  return (
    <ListActionModal
      {...props}
      title="Add Investigations"
      description="Select Investigation(s) to recommend for the patient."
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_ENT_INVESTIGATIONS}`}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'right',
            },
            actions: [
              save,
            ],
          }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
            };
            const submitAPI = submit({
              model: MODELS.IPD_ENT_INVESTIGATIONS,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Order has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          defaultValues={{
            testDate: selectedDate,
          }}

          type="standardShadow"
          lg={{
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 2,
                        columns: [
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  1.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="hbTest"
                                    type="checkBox"
                                    label="HB"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  11.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    label="K.F.T"
                                    type="checkBox"
                                    field="kftTest"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  2.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="btctTest"
                                    type="checkBox"
                                    label="BT/CT"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  12.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="tripleTest"
                                    type="checkBox"
                                    label="Triple Test"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  3.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="dtcTest"
                                    type="checkBox"
                                    label="DTC"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  13.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="urineTest"
                                    type="checkBox"
                                    label="Urine"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  4.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="esrTest"
                                    type="checkBox"
                                    label="ESR"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  14.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="ecgTest"
                                    type="checkBox"
                                    label="ECG"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  5.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="bloodSugarTest"
                                    type="checkBox"
                                    label="Blood Sugar"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  15.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="cxrTest"
                                    type="checkBox"
                                    label="CXR"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  6.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="lftTest"
                                    type="checkBox"
                                    label="L.F.T."
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  16.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="pureToneAudiometryTest"
                                    type="checkBox"
                                    label="Pure tone audiometry"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  7.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="nctKhTest"
                                    type="checkBox"
                                    label="NCT/KH"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  17.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="xRayMortoidSolwllersViewTest"
                                    type="checkBox"
                                    label="X-ray Mortoid Solwellers View"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  8.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="p5Test"
                                    type="checkBox"
                                    label="P5"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  18.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="mrctTemporalBoneTest"
                                    type="checkBox"
                                    label="MRCT Temporal bone"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  9.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="aps5Test"
                                    type="checkBox"
                                    label="APS5"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View
                                style={{
                                  flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 13,
                                  }}
                                >
                                  19.&nbsp;&nbsp;Other
                                </Text>
                                <FormField
                                  type="text"
                                  field="otherTests"
                                  container="rightLabel"
                                  editable
                                  inputStyle={{
                                    paddingLeft: 15,
                                    borderWidth: 0,
                                    outline: 'none',
                                    height: '34px',
                                  }}
                                  style={{
                                    width: 'auto',
                                    position: 'absolute',
                                    minHeight: 20,
                                    paddingLeft: 2,
                                    fontSize: 13,
                                    marginLeft: 20,
                                    marginTop: -7,
                                    borderWidth: 1,
                                    borderColor: vars.colors.grey.color5,
                                    lineHeight: 36,
                                    borderRadius: 5,
                                  }}
                                />
                              </View>
                            ),
                          },
                          {
                            render: () => (
                              <View style={{
                                flex: 1, marginLeft: 22, marginBottom: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                              }}
                              >
                                <Text state={{ fontSize: 17 }}>
                                  10.
                                  {'  '}
                                </Text>
                                <View style={{ flex: 1, paddingLeft: 15 }}>
                                  <FormField
                                    style={{
                                      width: 100,
                                    }}
                                    field="irv5lTest"
                                    type="checkBox"
                                    label="IRV5L"
                                    variant="filled"
                                  />
                                </View>
                              </View>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
)}
    />
  );
};

const AddInvestigations = (props) => (<IpdAddInvestigationENT {...props} />);
export default AddInvestigations;
