import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Form } from '../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';

const ViewPurchaseRequest = (props) => {
  const { navigation: { state: { params: { item: purchaseRequest } = {} } = {} } = {} } = props;
  return (
    <Form
      type="standard"
      closeView={1}
      reloadEvent={`reload${MODELS.PHARMACY_BATCH}`}
      editable={false}
      defaultValues={() => ({ purchaseRequest })}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    label: 'inventory.labels.dateRaised'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'purchaseRequest._createdOn',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.purchaseRequestNumber'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestPharmacy.purchaseRequestNumber',
                  },
                  {
                    type: 'text',
                    label: 'inventory.labels.status'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestPharmacy.requestStatus',
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.title.purchaseRequestDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'number',
                    label: 'inventory.labels.requiredQuantity'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestPharmacy.requiredQuantity',
                  },
                  {
                    type: 'textArea',
                    label: 'inventory.labels.reasonForPurchase'.getLabel(),
                    field: 'purchaseRequest.purchaseRequestPharmacy.reasonForPurchase',
                  },
                ],
              },
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.itemCode',
                    label: 'inventory.labels.itemId'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.name',
                    label: 'inventory.labels.itemName'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.category.name',
                    label: 'inventory.labels.category'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.sellingPrice',
                    label: 'inventory.labels.unitPrice'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.avgMonthlyConsumption',
                    label: 'inventory.labels.avgConsumption'.getLabel(),
                  },
                  {
                    type: 'date',
                    label: 'inventory.labels.lastPurchaseDate'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'purchaseRequest.purchaseRequestPharmacy.lastPurchaseDate',
                  },
                  {
                    type: 'number',
                    field: 'purchaseRequest.purchaseRequestPharmacy.lastPurchaseQuantity',
                    label: 'inventory.labels.lastPurchaseQuantity'.getLabel(),
                  },
                  {
                    type: 'number',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.stockAvailable.stockAvailable',
                    label: 'inventory.labels.stockAvailable'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.rateContract.manufacturer.name',
                    label: 'inventory.labels.manufacturer'.getLabel(),
                  }, {
                    type: 'text',
                    field: 'purchaseRequest.purchaseRequestPharmacy.item.rateContract.supplier.name',
                    label: 'inventory.labels.supplier'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            label: 'inventory.labels.statusTracking'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 12,
            },
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'progressChart',
                    field: 'purchaseRequest._id',
                    id: 'purchaseRequestPharmacyList',
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewPurchaseRequest;
