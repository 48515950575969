import React from 'react';

import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { save, Text } from '../../../../../../../../app-components';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { validateInputLength } from '../../../../../../../../Lib/helpers';

const LogReferencesIPDForm = (props) => {
  const {
    navigation: {
      state: {
        params: { selectedDate }
        = {},
      } = {},
    } = {},
  } = props;
  const {
    navigation: {
      state: {
        params: {
          dataParams: {
            filters: { specialityFilter }
            = {},
          } = {},
        } = {},
      } = {},
    } = {},
  } = props;
  return (
    <ListActionModal
      title="Daily Examination/Notes"
      description="Log Daily Notes of the patient."
      content={(
        <Form
          {...props}
          footer={{
            containerStyle: {
              justifyContent: 'center',
              alignItems: 'center',
            },
            actions: [
              save({
                label: 'Confirm',
              }),
            ],
          }}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_DAILY_EXAMINATION_NOTES}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const newUpdate = {
              ...updates,
              admissionId: { _id: submitProps?.navigation?.state?.params?.patient?._id },
            };
            const submitAPI = submit({
              model: MODELS.IPD_DAILY_EXAMINATION_NOTES,
              ...submitProps,
              data,
              updates: newUpdate,
            });
            await submitAPI({ data, updates: newUpdate });
          }}
          defaultValues={() => ({
            currentDate: new Date(selectedDate),
            dateOfAdmission: new Date(),
            speciality: specialityFilter,
          })}
          mandatory={{
            _id: 1,
            systemicExamination: 1,
            generalExamination: 1,
            ventilatorDay: 1,
            currentDate: 1,
            dailyNotes: 1,
            bedNumber: 1,
            diagnosis: 1,
            foleyDay: 1,
            history: 1,
            ettDay: 1,
            rtDay: 1,
            other: 1,
            cvs: 1,
            giS: 1,
            cns: 1,
            sr: 1,
          }}
          validations={{
            bedNumber: ({ data }) => validateInputLength(data?.bedNumber, 1, 3),
            ventilatorDay: ({ data }) => validateInputLength(data?.ventilatorDay, 1, 3),
            ettDay: ({ data }) => validateInputLength(data?.ettDay, 1, 3),
            rtDay: ({ data }) => validateInputLength(data?.rtDay, 1, 3),
            foleyDay: ({ data }) => validateInputLength(data?.foleyDay, 1, 3),
            diagnosis: ({ data }) => validateInputLength(data?.diagnosis, 1, 250),
            history: ({ data }) => validateInputLength(data?.history, 1, 250),
            generalExamination: ({ data }) => validateInputLength(data?.generalExamination, 1, 250),
            systemicExamination: ({ data }) => validateInputLength(data?.systemicExamination, 1, 250),
            sr: ({ data }) => validateInputLength(data?.sr, 1, 250),
            cvs: ({ data }) => validateInputLength(data?.cvs, 1, 250),
            other: ({ data }) => validateInputLength(data?.other, 1, 250),
            dailyNotes: ({ data }) => validateInputLength(data?.dailyNotes, 1, 250),
          }}
          lg={{
            formGroups: [
              {
                fieldVariant: 'filled',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 10,
                },
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 4,
                    columns: [
                      {
                        type: 'date',
                        field: 'currentDate',
                        label: 'Date',
                        editable: false,
                        mandatory: true,
                      },
                      {
                        type: 'number',
                        field: 'bedNumber',
                        label: 'Bed Number',
                        editable: true,
                        mandatory: true,
                      },
                      {
                        type: 'number',
                        field: 'weight',
                        label: 'Weight',
                        editable: true,
                      },
                      {
                        type: 'date',
                        field: 'dateOfAdmission',
                        label: 'Date Of Admission',
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'crNumber',
                        label: 'Cr. Number',
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'department',
                        label: 'Department',
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'ventilatorDay',
                        label: 'Ventilator Day',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'ettDay',
                        label: 'ETT Day',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'rtDay',
                        label: 'RT Day',
                        mandatory: true,
                        editable: true,
                      },
                      {
                        type: 'number',
                        field: 'foleyDay',
                        label: 'foley\'s Day',
                        mandatory: true,
                        editable: true,
                      },
                      autoSuggestInput({
                        type: 'autoSuggest',
                        field: 'doctorName',
                        label: 'Doctor\'s Name',
                        suggestionField: 'firstName',
                        model: MODELS.EMPLOYEE,
                        query: 'employeeLists',
                      }),
                      {
                        type: 'text',
                        field: 'sr',
                        label: 'R/S',
                        editable: true,
                        mandatory: true,
                        allowWhiteSpaces: true,
                      },
                      {
                        type: 'text',
                        field: 'cvs',
                        label: 'CVS',
                        editable: true,
                        mandatory: true,
                        allowWhiteSpaces: true,
                      },
                      {
                        type: 'text',
                        field: 'giS',
                        label: 'GI/S',
                        editable: true,
                        mandatory: true,
                        allowWhiteSpaces: true,
                      },
                      {
                        type: 'text',
                        field: 'cns',
                        label: 'CNS',
                        editable: true,
                        mandatory: true,
                        allowWhiteSpaces: true,
                      },
                      {
                        type: 'text',
                        field: 'other',
                        label: 'Others',
                        editable: true,
                        mandatory: true,
                        allowWhiteSpaces: true,
                      },
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            Daignosis
                          </Text>
                        ),
                      },
                      {
                        type: 'textArea',
                        field: 'diagnosis',
                        label: 'Daignosis',
                        editable: true,
                        mandatory: true,
                        minHeight: 80,
                        allowWhiteSpaces: true,
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            History
                          </Text>
                        ),
                      },
                      {
                        type: 'textArea',
                        field: 'history',
                        label: 'History',
                        editable: true,
                        mandatory: true,
                        minHeight: 80,
                        allowWhiteSpaces: true,
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            General Examination
                          </Text>
                        ),
                      },
                      {
                        type: 'textArea',
                        field: 'generalExamination',
                        label: 'General Examination',
                        editable: true,
                        mandatory: true,
                        minHeight: 80,
                        allowWhiteSpaces: true,
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            Systemic Examination
                          </Text>
                        ),
                      },
                      {
                        type: 'textArea',
                        field: 'systemicExamination',
                        label: 'Systemic Examination',
                        editable: true,
                        mandatory: true,
                        minHeight: 80,
                        allowWhiteSpaces: true,
                      },
                      {
                        render: () => (
                          <Text style={{
                            fontSize: 14,
                          }}
                          >
                            Daily Notes
                          </Text>
                        ),
                      },
                      {
                        type: 'textArea',
                        field: 'dailyNotes',
                        label: 'Daily Notes',
                        editable: true,
                        mandatory: true,
                        minHeight: 80,
                        allowWhiteSpaces: true,
                      },

                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />

  );
};
export default LogReferencesIPDForm;
