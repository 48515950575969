import React from 'react';

import ViewGeneralMedicineTabs from '../GeneralMedicine';
import ViewGeneralSurgeryTabs from '../GeneralSurgery';
import ViewOperationTheatreTabs from '../OperationTheatre';
import ViewOrthopedicTabs from '../Orthopedic';
import ViewGynaecologyTabs from '../Gynaecology';
import ViewPsychiatryTabs from '../Psychiatry';
import ViewSkinTabs from '../Skin';
import ViewEmergencyTabs from '../Emergency';
import ViewNeurologyTabs from '../Neurology';
import ViewUrologyTabs from '../Urology';
import ViewPediatricsNICUTabs from '../PediatricsNICU';
import ViewPediatricsPICUTabs from '../PediatricsPICU';
import ViewICUTabs from '../ICU';
import ViewEyeTabs from '../Eye';
import ViewENTTabs from '../ENT';
import ViewPediatricsWardTabs from '../PediatricsWard';
import ViewDayCareCentreTabs from '../DayCareCentre';

const viewTabs = (props) => {
  const { dataParams } = props;
  if (dataParams?.filters?.specialityFilter === 'General Surgery') {
    return <ViewGeneralSurgeryTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'General Medicine') {
    return <ViewGeneralMedicineTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Operation Theatre') {
    return <ViewOperationTheatreTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Orthopedic') {
    return <ViewOrthopedicTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Gynaecology') {
    return <ViewGynaecologyTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Psychiatry') {
    return <ViewPsychiatryTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Skin') {
    return <ViewSkinTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Emergency') {
    return <ViewEmergencyTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Neurology') {
    return <ViewNeurologyTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Urology') {
    return <ViewUrologyTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Pediatrics NICU') {
    return <ViewPediatricsNICUTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Pediatrics PICU') {
    return <ViewPediatricsPICUTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Pediatrics Ward (PICU)') {
    return <ViewPediatricsWardTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Day Care Centre') {
    return <ViewDayCareCentreTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'ICU') {
    return <ViewICUTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'Eye') {
    return <ViewEyeTabs {...props} />;
  }
  if (dataParams?.filters?.specialityFilter === 'ENT') {
    return <ViewENTTabs {...props} />;
  }
  return '';
};

export default viewTabs;
