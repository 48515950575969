import axios from 'axios';

import uuid from 'uuid/v4';
import { isArray, isFunction } from 'lodash';
import { putDottedValue } from '@applane/react-form/src/Utility';
import { Storage, GpsStore, Toast } from './app-components';
import { BASE_URL, GPS_SOCKET_URL } from './Config';
import { isJSONObject } from './app-components/UtilityFunctions';
import { getDefaultLocationView } from './app-components/stack/Location';
import { getUserWiseDefaultView } from './ViewConstant';
import { isSuperadmin } from './Lib/helpers';
// import { ROUTES } from './Lib/constants';

const globalProps = {
  timezoneOffset: new Date().getTimezoneOffset(),
  platform: 'web',
};
const sessionToken = uuid();

let user = void 0;
let token = void 0;
let refreshToken = void 0;
let tokenExpiryTime = void 0;
let enforceChangePassword = void 0;
const baseUrl = BASE_URL;
let userViews = void 0;

export const gpsStore = new GpsStore({ url: GPS_SOCKET_URL });
export const setUser = (updatedUser = {}) => {
  user = updatedUser;
  user.permissions = {};
  let mergedPermissions = [];
  const roles = user?.employee?.role;
  if (Array.isArray(roles)) {
    for (let index = 0; index < roles?.length; index += 1) {
      const role = roles[index];
      const keys = Object.keys(role?.permissions || {});
      const permissions = keys.filter((key) => role?.permissions[key]);
      mergedPermissions = [...mergedPermissions, ...permissions];
    }
  }
  mergedPermissions.forEach((permission) => {
    user.permissions[permission] = true;
  });
};

const modifyUri = (props) => {
  let {
    uri: {
      skipData,
      fetchCount,
      query,
      model,
      id = '_find',
      subscribe,
      aggregate,
      props: uriProps,
      ...rest
    },
  } = props;
  if (typeof query === 'string') {
    query = { id: query };
  }
  let { limit } = query;
  let { skip } = query;
  if (!limit) {
    limit = 20;
    query = { ...query, limit };
  }
  if (!skip) {
    skip = 0;
    query = { ...query, skip };
  }

  if (fetchCount) {
    query = { ...query, skipData: true };
  } else {
    query = { ...query, skipAggregates: !aggregate };
  }

  if (subscribe) {
    query = { ...query, metadata: true };
  }

  const uri = {
    id,
    props: {
      ...uriProps,
      query,
      model,
      subscribe,
    },
  };
  return { uri, ...rest };
};

const modifyUriData = (props) => {
  let {
    aggregate,
    skipData,
    fetchCount,
    query,
    model,
    props: uriProps,
    id = '_find',
    subscribe,
  } = props;
  if (typeof query === 'string') {
    query = { id: query };
  }
  let { limit } = query;
  let { skip } = query;
  if (!limit) {
    limit = 20;
    query = { ...query, limit };
  }
  if (!skip) {
    skip = 0;
    query = { ...query, skip };
  }

  if (fetchCount) {
    query = { ...query, skipData: true };
  } else {
    query = { ...query, skipAggregates: !aggregate };
  }

  if (subscribe) {
    query = { ...query, metadata: true };
  }

  const uri = {
    id,
    props: {
      ...uriProps,
      query,
      model,
      subscribe,
    },
  };
  return { uri, subscribe };
};

export const fetchQuery = (props) => {
  const { uri, onResult } = modifyUri(props);
  const subscribe = props && props.uri && props.uri.subscribe;
  return fetch({
    uri,
  }).then((resp) => {
    if (onResult) {
      resp = onResult(resp, props);
    }
    return resp;
  });
};

export const getDefaultUserView = () => {
  const user = getUser();
  let defaultView = getUserWiseDefaultView({ user });
  if (!defaultView) {
    defaultView = 'analytics';
  }
  return {
    ...getDefaultLocationView({ view: defaultView }),
  };
};

export const canAccess = ({ view }) => userViews && userViews[view];
export const fetchQueryData = (props) => () => {
  const { uri } = modifyUriData(props);

  return fetch({
    uri,
  }).then((resp) => resp);
};
export const settingData = async ({ id, model }) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id,
        },
        model,
      },
    },
  });
  return data;
};
export const notificationCount = () => fetch({
  uri: {
    props: {
      query: {
        id: 'appNotificationList',
        addOnFilter: { isRead: { $exists: false }, user: { _id: user._id } },
        metadata: false,
        skipData: true,
      },
      model: 'app_notification',
    },
  },
});

export const getConfigurationAPI = () => invoke({
  id: 'getConfigurationData',
});

let configurations = {};

export const setConfiguration = (data) => {
  configurations = data;
};

export const getConfiguration = () => configurations;

export const markRead = (params) => fetch({
  uri: {
    id: 'markNotificationRead',
    props: {
      query: { params },
    },
  },
});
export const notificationData = () => fetch({
  uri: {
    props: {
      query: {
        id: 'appNotificationList',
        addOnFilter: { user: { _id: user._id } },
        metadata: false,
        skipData: false,
        limit: 40,
      },
      model: 'app_notification',
    },
  },
});

export const checkUserExist = async (user = {}) => {
  let { username, password } = user;
  username = username.trim();
  password = password.trim();

  const props = {
    paramValue: { email: username, password },
    id: '_isUserExist',
    token,
  };
  return await axios.post(`${baseUrl}/invoke`, props).then((user) => {
    const { result } = user && user.data && user.data.response;
    let error = void 0;
    if (!result) {
      error = `*${username} is not registered`;
    }
    if (error) {
      throw new Error(error);
    }
    return result;
  });
};

export const mamcAuthenticate = async (user = {}) => {
  let { username, password } = user;
  username = username.trim();
  password = password.trim();

  const props = {
    paramValue: { email: username, password },
    id: 'mamcAuthenticateUser',
    token: void 0,
  };
  return axios
    .post(`${baseUrl}/invoke`, props)
    .then((res) => {
      res = res.data.response;
      if (res && res.result && res.result.token) {
        token = res.result.token;
        refreshToken = res.result.refreshToken;
        tokenExpiryTime = res.result.tokenExpiryTime;
        user = res.result.user;
        enforceChangePassword = res?.result?.user?.enforceChangePassword;
        setUser(user);
        Storage.setItem('refreshToken', refreshToken);
        Storage.setItem('tokenExpiryTime', tokenExpiryTime);
        return Storage.setItem('token', token);
      }
      const error = new Error('user/incorrect_user_pwd');
      error.response = {
        data: {
          response: {
            error: {
              code: 'user/incorrect_user_pwd',
              message: 'user/incorrect_user_pwd',
            },
          },
        },
      };
      throw error;
    })
    .then(() => ({ user, token, enforceChangePassword }))
    .catch((e) => {
      let errorResponse = e.response && e.response.data && e.response.data.response;
      if (errorResponse) {
        if (errorResponse && errorResponse.error && errorResponse.error.code) {
          errorResponse = errorResponse.error.code;
        } else {
          errorResponse = errorResponse?.error?.message;
        }
      } else {
        errorResponse = e.message;
      }
      Toast.show({
        message: `${errorResponse}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
      return { error: errorResponse };
    });
};
export const mamcResetPassword = async (userInfo = {}) => {
  let { username, newPassword, confirmPassword } = userInfo;
  username = username?.trim();
  newPassword = newPassword?.trim();
  confirmPassword = confirmPassword?.trim();
  const props = {
    paramValue: { email: username, password: newPassword, confirmPassword },
    id: 'resetPassword',
    token,
  };
  return axios
    .post(`${baseUrl}/invoke`, props)
    .then((res) => {
      user.enforceChangePassword = false;
      setUser(user);
      res = res?.data?.response?.result;
      Toast.show({
        message: res.message,
        type: 'success',
        position: 'top',
        direction: 'right',
        description: 'Your password has been changed successfully.',
      });
      return res;
    })
    .catch((e) => {
      let errorResponse = e.response && e.response.data && e.response.data.response;
      if (errorResponse) {
        if (errorResponse && errorResponse.error && errorResponse.error.code) {
          errorResponse = errorResponse.error.code;
        } else {
          errorResponse = errorResponse?.error?.message;
        }
      } else {
        errorResponse = e.message;
      }
      Toast.show({
        message: `${errorResponse}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        description: 'Your password and confirmation password do not match.',
      });
      return { error: errorResponse };
    });
};
export const mamcChangePassword = async (userInfo = {}) => {
  let {
    username, newPassword, confirmPassword,
  } = userInfo;
  const { employeeId } = userInfo;
  username = username?.trim();
  newPassword = newPassword?.trim();
  confirmPassword = confirmPassword?.trim();
  const props = {
    paramValue: {
      email: username, password: newPassword, confirmPassword, employeeId,
    },
    id: 'changePassword',
    token,
  };
  return axios
    .post(`${baseUrl}/invoke`, props)
    .then((res) => {
      user.enforceChangePassword = false;
      setUser(user);
      res = res?.data?.response?.result;
      Toast.show({
        message: res.message,
        type: 'success',
        position: 'top',
        direction: 'right',
        description: 'Your password has been changed successfully.',
      });
      return res;
    })
    .catch((e) => {
      let errorResponse = e.response && e.response.data && e.response.data.response;
      if (errorResponse) {
        if (errorResponse && errorResponse.error && errorResponse.error.code) {
          errorResponse = errorResponse.error.code;
        } else {
          errorResponse = errorResponse?.error?.message;
        }
      } else {
        errorResponse = e.message;
      }
      Toast.show({
        message: `${errorResponse}`,
        type: 'error',
        position: 'top',
        direction: 'right',
        description: 'Your password and confirmation password do not match.',
      });
      return { error: errorResponse };
    });
};

const handleError = (e) => {
  const errorResponse = e.response && e.response.data && e.response.data.response;
  if (errorResponse && errorResponse.error) {
    const { message = void 0, code = void 0 } = errorResponse.error;

    if (message.includes('Token expired') || message.includes('Invalid token')) {
      Storage.removeItem('token');
      Storage.removeItem('refreshToken');
      Storage.removeItem('tokenExpiryTime');
      window.location.reload();
      throw new Error('Session Expired.');
    }
    const modifiedError = new Error(message);
    modifiedError.code = code;
    throw modifiedError;
  }
  throw e;
};

const ConnectServices = ({
  fetchUrl,
  postUrl,
  authenticateUrl,
  uploadUrl,
  downloadUrl,
}) => {
  const upload = async (file, options = {}) => {
    const { throwError } = options;
    const name = file.name.replace(/[&\/\\#,^@!+()$~%" "'":*?<>{}-]/g, '_');
    file = new File([file], name, { type: file.type });
    const { multiPart = true, uri = '/upload', s3 } = options;
    if (multiPart) {
      const formData = new FormData();
      formData.append('', file);
      formData.append('token', token);
      formData.append('timezoneOffset', globalProps.timezoneOffset);
      formData.append('platform', globalProps.platform);
      if (s3) {
        formData.append('s3', true);
      }
      return axios
        .post(`${uploadUrl}${uri}`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => {
          let result = res.data;
          result = result.response && result.response;
          result = result && result.length ? result[0] : result;
          return result;
        })
        .catch((e) => {
          handleError(e);
        });
    }
    console.warn(new Error('Upload not supported without multiPart'));
  };
  const fetch = ({ uri }) => {
    let {
      url, id, props, fetchCount,
    } = uri; // remove fetchCount after data get from server
    const { query: { computation, afterFetch } = {} } = props;
    url = url || fetchUrl;
    if (!id) {
      id = '_find';
    }
    const paramValue = { ...props };
    const uriProps = {
      id,
      paramValue,
      token,
    };
    return axios
      .post(`${url}`, uriProps)
      .then((res) => {
        let { result, ...rest } = res.data.response;
        if (isFunction(computation)) {
          result = computation(result);
        }

        if (isFunction(afterFetch)) {
          afterFetch(result && result[0]);
        }
        return {
          data: result,
          ...rest,
        };
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const post = async ({
    url = postUrl,
    id = '_batchSave',
    data,
    updates,
    batchUpdates,
    model,
    extraParams,
  }) => {
    if (!batchUpdates) {
      const { _id } = data;

      let op = void 0;
      if (!_id || (typeof _id === 'string' && _id.startsWith('new_'))) {
        op = { insert: updates };
      } else {
        op = { update: { changes: updates, _id } };
      }
      batchUpdates = [{ updates: op, model }];
    }
    const t = await Storage.getItem('token');

    const uriProps = {
      timezoneOffset: new Date().getTimezoneOffset(),
      platform: 'web',
      token: t,
      paramValue: {
        updates: batchUpdates,
        ...extraParams,
      },
      id,
    };
    return axios
      .post(`${url}`, uriProps)
      .then((res) => {
        res = res.data.response;
        return res;
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const getInvokeUriProps = (props) => {
    let {
      selectedIds,
      allPageSelected = false,
      dataParams,
      uri = {},
    } = props;
    if (typeof uri === 'function') {
      uri = uri(props);
    }
    const { query, model, args } = uri;
    const { limit, skip, ...restQueryParams } = query || {};
    if (
      !allPageSelected
      && selectedIds
      && selectedIds.length
      && restQueryParams
    ) {
      const paramsAddOnFilter = restQueryParams.addOnFilter;
      restQueryParams.addOnFilter = { ...paramsAddOnFilter } || {};
      restQueryParams.addOnFilter.$and = restQueryParams.addOnFilter.$and
        ? [...restQueryParams.addOnFilter.$and]
        : [];
      restQueryParams.addOnFilter.$and.push({ _id: { $in: selectedIds } });
      restQueryParams.dataParams = dataParams;
    }

    const uriProps = {
      ...globalProps,
      token,
      ...args,
      paramValue: {
        _selectedIds_: allPageSelected ? [] : selectedIds,
        _allPageSelected: allPageSelected,
        _query: restQueryParams,
        _model: model,
      },
    };
    return uriProps;
  };

  const authenticate = ({ data = {} }) => {
    const { email, mobile, ...restData } = data;
    if (mobile && typeof mobile === 'string' && mobile.indexOf('@') > 0) {
      restData.email = mobile.trim();
    } else if (email) {
      restData.email = email.trim();
    } else if (mobile) {
      restData.mobile = mobile.trim();
    }

    const uriProps = {
      paramValue: {
        ...restData,
      },
      id: '_authenticateUser',
    };

    return axios
      .post(`${authenticateUrl}`, uriProps)
      .then(async (res) => {
        res = res.data.response;
        if (res && res.result && res.result.token) {
          token = res.result.token;
          user = res.result.user;
          setUser(user);
          return Storage.setItem('token', token);
        }
        const error = new Error('user/incorrect_user_pwd');
        error.response = {
          data: {
            response: {
              error: {
                code: 'user/incorrect_user_pwd',
                message: 'user/incorrect_user_pwd',
              },
            },
          },
        };

        throw error;
      })
      .catch((e) => {
        let errorResponse = e.response && e.response.data && e.response.data.response;
        if (errorResponse) {
          if (
            errorResponse
            && errorResponse.error
            && errorResponse.error.code
          ) {
            errorResponse = errorResponse.error.code;
          } else {
            errorResponse = JSON.stringify(errorResponse);
          }
        } else {
          errorResponse = e.message;
        }
        Toast.show({
          text: `${errorResponse}`,
          duration: Toast.LENGTH_LONG,
        });
        if (errorResponse && errorResponse.error) {
          const {
            message = 'user/provide_credential',
            code = 'user/provide_credential',
          } = errorResponse.error;
          const modifiedError = new Error(message);
          modifiedError.code = code;
          // throw modifiedError;
        } else {
          // throw e;
        }
      })
      .then(() => ({ user, token }))
      .catch((e) => {
        // console.error('error in Todofetch', e);

        throw e;
      });
  };

  const getUser = () => user;

  const logout = () => Storage.removeItem('token').then(() => {
    token = void 0;
    user = void 0;
    userViews = void 0;
  });

  const initUser = async () => {
    if (user && token) {
      return { user };
    }
    return Storage.getItem('token').then((_token) => {
      if (_token) {
        token = _token;
        const uriProps = {
          timezoneOffset: new Date().getTimezoneOffset(),
          platform: 'web',
          token,
          paramValue: { token },
          id: '_getAuthenticatedUser',
        };

        return axios
          .post(`${authenticateUrl}`, uriProps)
          .then((res) => {
            res = res.data.response;
            if (res && res.result) {
              user = res.result;
              setUser(user);
              // userViews = getUserViewsMap({user});
              return Storage.setItem('token', token).then(() => ({ user }));
            }
            token = void 0;
            user = void 0;
            return { user: null };
          })
          .catch((e) => {
            handleError(e);
          });
      }
      return { user: null };
    });
  };

  const refreshUserToken = async () => Storage.getItem('refreshToken').then((_token) => {
    if (_token) {
      token = _token;
      const uriProps = {
        timezoneOffset: new Date().getTimezoneOffset(),
        platform: 'web',
        paramValue: { refreshToken: token },
        id: 'refreshUserToken',
      };

      return axios
        .post(`${authenticateUrl}`, uriProps)
        .then((res) => {
          res = res.data.response;
          if (res && res.result) {
            token = res.result.token;
            return Storage.setItem('token', res.result.token).then(() => ({ user }));
          }
          token = void 0;
          user = void 0;
          return { user: null };
        })
        .catch((e) => {
          handleError(e);
        });
    }
    return { user: null };
  });

  const getDownloadUrl = ({ key, name, openInNewTab }) => (key
    ? `${downloadUrl}/download?${
      openInNewTab ? 'inline' : 'download'
    }=true&token=${token}&fileKey=${key}&fileName=${name}`
    : void 0);

  const getImageUrl = (params) => {
    if (typeof params === 'string') {
      return params;
    }
    const {
      key, name, url, uri, file,
    } = params || {};
    if (file) {
      return file;
    }
    if (uri) {
      return uri;
    }
    if (url) {
      return url;
    }
    if (downloadUrl && key && name) {
      return `${downloadUrl}/download?inline=true&token=${token}&fileKey=${key}&fileName=${name}`;
    }
    return void 0;
  };

  const invoke = ({ url, paramValue, id }) => axios
    .post(url || postUrl, {
      id,
      paramValue,
      token,
    })
    .then((res) => {
      res = res.data.response;
      return res;
    })
    .catch((e) => {
      let errorResponse = e.response && e.response.data && e.response.data.response;
      if (errorResponse) {
        if (typeof errorResponse === 'object') {
          errorResponse = errorResponse?.error?.message;
        }
        e = new Error(errorResponse);
      }
      throw e;
    });
  const getExportColumns = (columns) => {
    if (!Array.isArray(columns)) {
      return columns;
    }
    const exportColumns = {};
    for (const item of columns) {
      exportColumns[item.header] = item.field;
    }
    return exportColumns;
  };

  const getQueryFields = ({ columns }) => {
    const fields = {};
    for (const column of columns) {
      if (!column) {
        continue;
      }
      let field = column;
      if (typeof column === 'object') {
        field = column.field;
      }
      if (field) {
        putDottedValue(fields, field, 1);
      }
    }
    return fields;
  };
  const downloadData = (props = {}) => {
    let {
      data,
      selectedData,
      service,
      selectedIds,
      file,
      column,
      columns,
      fields,
      state,
    } = props;

    const uriProps = getInvokeUriProps(props);
    // if (columns) {
    //   fields = getQueryFields({ columns });
    // }
    if (columns) {
      columns = getExportColumns(columns);
    }
    if (fields) {
      uriProps.paramValue._query = uriProps.paramValue._query || {};
      uriProps.paramValue._query.fields = fields;
    }
    uriProps.paramValue = {
      ...uriProps.paramValue,
      file,
      column: columns,
    };
    if (typeof service === 'function') {
      service = service({
        data,
        selectedData,
        selectedIds,
        uriProps,
        state,
      });
    }
    service = service || {};
    uriProps.id = service.id || '_downloadExcel';
    if (service.paramValue) {
      uriProps.paramValue = {
        ...uriProps.paramValue,
        ...service.paramValue,
      };
    }

    return new Promise((resolve) => {
      createFormAndSubmit({ uri: '/invoke', uriProps });
      resolve();
    });
  };
  return {
    fetch,
    post,
    authenticate,
    initUser,
    refreshUserToken,
    getUser,
    logout,
    upload,
    getDownloadUrl,
    getImageUrl,
    invoke,
    downloadData,
    getInvokeUriProps,
  };
};

const {
  fetch: _fetch,
  post: postData,
  authenticate: authenticateData,
  getUser: getUserData,
  initUser: initUserData,
  refreshUserToken: refreshUserTokenOnExpiry,
  logout: logoutData,
  downloadData: _downloadData,
  upload: uploadFile,
  getDownloadUrl: _getDownloadUrl,
  getImageUrl: _getImageUrl,
  invoke: _invoke,
  getInvokeUriProps,
} = ConnectServices({
  fetchUrl: `${baseUrl}/invoke`,
  postUrl: `${baseUrl}/invoke`,
  authenticateUrl: `${baseUrl}/invoke`,
  uploadUrl: `${baseUrl}`,
  downloadUrl: `${baseUrl}`,
});

export const fetch = _fetch;
export const post = postData;
export const authenticate = authenticateData;
export const getUser = getUserData;
export const logout = logoutData;
export const initUser = initUserData;
export const refreshUserToken = refreshUserTokenOnExpiry;
export const upload = uploadFile;
export const getDownloadUrl = _getDownloadUrl;
export const getImageUrl = _getImageUrl;
export const invoke = _invoke;
export const downloadData = _downloadData;

export const resetOTP = ({ data, eventDispatcher }) => {
  const { _id } = data;
  if (!_id) {
    return;
  }
  return invoke({
    paramValue: { _id },
    id: 'resetDefaultOTP',
  })
    .then(({ result }) => {
      Toast.show({
        text: 'reset OTP Successfully',
        duration: Toast.LENGTH_LONG,
      });
      eventDispatcher && eventDispatcher.notify('reloadusers');
      return result && result.length ? result[0] : result;
    })
    .catch((e) => {
      Toast.show({
        text: e,
        duration: Toast.LENGTH_LONG,
      });
    });
};

const createFormAndSubmitForExcel = ({ uri, uriProps }) => {
  const { paramValue: { file } } = uriProps;

  return axios.post(`${baseUrl}/invoke`, uriProps,
    {
      responseType: 'blob', // Important
    }).then((result) => {
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file}.xlsx`); // or any other extension
    document.body.appendChild(link);
    link.click();
  }).catch((err) => {
    Toast.show({
      message: 'Error',
      type: 'error',
      position: 'top',
      direction: 'right',
      description: 'Data too large. Maximum allowed are 10000, Please strong your filter to export data.',
    });
  });
};

export const downloadExcelData = (props = {}) => {
  let { uriProps } = props;
  if (!uriProps || !token) {
    return;
  }

  uriProps = {
    ...uriProps,
    token,
  };
  createFormAndSubmitForExcel({ uri: '/invoke', uriProps });
};

const createFormAndSubmit = ({ uri, uriProps }) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', `${baseUrl}${uri}`);
  for (const paramName in uriProps) {
    const paramValue = uriProps[paramName];
    const inputElm = document.createElement('input');
    inputElm.setAttribute('name', paramName);
    inputElm.setAttribute(
      'value',
      isJSONObject(paramValue) ? JSON.stringify(paramValue) : paramValue,
    );
    inputElm.setAttribute('type', 'hidden');
    form.appendChild(inputElm);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const autosuggestFetch = ({
  id,
  fields,
  relationValue,
  paramValue,
  addOnFilter,
  model,
  search,
  limit = 20,
  addSearchValueIfNoData,
  service = '_find',
  modifyResult,
}) => (props) => {
  const { searchValue } = props;
  let _addOnFilter = addOnFilter;
  let _paramValue = paramValue;
  if (typeof _addOnFilter === 'function') {
    _addOnFilter = _addOnFilter({
      ...props,
      user: getUser(),
    });
  }
  if (searchValue) {
    const searchFilter = {};
    searchFilter[search] = {
      $regex: `^${searchValue}|(?<= )${searchValue}`,
      $options: 'i',
    };
    _addOnFilter = { ..._addOnFilter, ...searchFilter };
  }

  if (typeof _paramValue === 'function') {
    _paramValue = _paramValue({
      ...props,
      user: getUser(),
    });
  }
  if (model) {
    return fetch({
      uri: {
        id: service,
        props: {
          query: {
            id,
            fields,
            limit,
            skip: 0,
            addOnFilter: _addOnFilter,
            paramValue: _paramValue,
            relationValue,
            skipAggregates: true,
            metadata: false,
          },
          model,
        },
      },
    }).then((result) => {
      if (modifyResult) {
        const modifiedResult = modifyResult(result);
        result = {
          ...result,
          ...modifiedResult,
        };
      }
      let { data } = result;
      if (addSearchValueIfNoData && (!data || !data.length)) {
        data = [{ [search]: searchValue }];
        result = { ...result, data };
      }

      return result;
    });
  }
  return fetch({
    uri: {
      id,
      props: {
        query: {
          id,
          fields,
          limit,
          skip: 0,
          addOnFilter: _addOnFilter,
          paramValue: _paramValue,
          relationValue,
          skipAggregates: true,
          metadata: false,
        },
      },
    },
  }).then((result) => {
    if (modifyResult) {
      const modifiedResult = modifyResult(result);
      result = {
        ...result,
        ...modifiedResult,
      };
    }

    let { data } = result;
    if (addSearchValueIfNoData && (!data || !data.length)) {
      data = [{ [search]: searchValue }];
      result = { ...result, data };
    }
    return result;
  });
};

export const gettingData = async ({ model, query, organization_id }) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id: query,
          addOnFilter: { organization_id },
        },
        model,
      },
    },
  });
  return data;
};

export const getData = async ({
  model, id, filter, paramValue,
}) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id,
          addOnFilter: filter,
          paramValue,
        },
        model,
      },
    },
  });
  return data;
};

export const getDataByService = async ({ id, paramValue = {} }) => {
  // let data = {
  //   appointment: _id,
  // };
  const result = await invoke({
    paramValue,
    id,
  });
  return result;
};

export const submit = ({
  model,
  updateUser = false,
  skipFields,
  lowerCaseFields,
  id,
  validate,
}) => (props) => {
  let { data, updates, remove } = props;

  if (validate) {
    validate(props);
  }

  if (remove) {
    return post({ data, model, remove });
  }
  data = removeFields(data, skipFields, lowerCaseFields);

  updates = removeFields(updates, skipFields, lowerCaseFields);
  return post({
    data,
    updates,
    model,
    id,
  }).then(({ result }) => {
    if (updateUser) {
      let newUser = result && result.length ? result[0] : result;
      newUser = newUser?.result || newUser;
      user.photo = newUser?.profile_picture || user.photo;
      user.name = newUser?.name || user.name;
      user.email = newUser?.email || user.email;
    }
    return result && result.length ? result[0] : result;
  }).catch((error) => {
    throw new Error(error);
  });
};

export const submitByQuery = ({ query_id }) => (props) => {
  const { data, updates, remove } = props;
  invoke({
    paramValue: { data },
    id: query_id,
  }).then(({ result }) => result);
};

const removeFields = (data, skipFields, lowerCaseFields) => {
  if (skipFields) {
    const newData = {};
    for (const key in data) {
      if (skipFields.indexOf(key) < 0) {
        newData[key] = data[key];
      }
    }
    data = newData;
  }
  if (lowerCaseFields) {
    const newData = {};
    for (const key in data) {
      if (lowerCaseFields.indexOf(key) < 0) {
        newData[key] = data[key];
      } else {
        let newValue = data[key];
        if (newValue && typeof newValue === 'string') {
          newValue = newValue.toLowerCase().trim();
        }
        newData[key] = newValue;
      }
    }
    data = newData;
  }
  return data;
};

export const googlePlaceFetch = ({ searchValue = '' }) => {
  searchValue = searchValue.toLowerCase();
  const path = `/maps/api/place/autocomplete/json?input=${encodeURI(
    searchValue,
  )}&inputtype=textquery&sessiontoken=${sessionToken}&key=AIzaSyBVpN48FtFrg5-iJ87VoJFSeGAQ6dRImsU&components=country:ind`;
  return fetch({
    uri: {
      id: '_getGooglePlace',
      props: {
        query: {
          addOnFilter: { path },
        },
      },
    },
  }).then((result) => {
    const predictions = (result && result.data && result.data.predictions) || [];
    return { data: predictions };
  });
};

const populateGoogleAddressLevels = async (result) => {
  const address_components = result && result.address_components;
  if (!address_components) {
    return;
  }
  let level = 0;
  let address_levels = void 0;
  const addressLevelsToManage = [];

  for (let i = address_components.length - 1; i >= 0; i--) {
    var locationValue = address_components[i];
    addressLevelsToManage.push(locationValue.types[0]);
  }
  for (let j = address_components.length - 1; j >= 0; j--) {
    var locationValue = address_components[j];
    const levelValue = addressLevelsToManage[level];
    if (locationValue.types && locationValue.types.indexOf(levelValue) !== -1) {
      address_levels = address_levels || {};
      address_levels[levelValue] = locationValue.long_name;
      level += 1;
    }
    if (level === addressLevelsToManage.length) {
      break;
    }
  }
  return address_levels;
};

export const removeData = async ({ item, model }) => {
  const updates = {
    isDeleted: true,
    _id: item._id,
  };
  const { _id, ...rest } = updates;
  await post({
    updates: rest,
    data: updates,
    model,
  });
};

export const getGooglePlaceDetail = (value) => {
  const { place_id, description } = value;
  const path = `/maps/api/place/details/json?placeid=${place_id}&sessiontoken=${sessionToken}&key=AIzaSyBVpN48FtFrg5-iJ87VoJFSeGAQ6dRImsU`;
  return fetch({
    uri: {
      id: '_getGooglePlace',
      props: {
        query: {
          addOnFilter: { path },
        },
      },
    },
  })
    .then(async (resp) => {
      let {
        data: { result },
      } = resp;

      result = result || {};
      if (result) {
        let value = void 0;
        value = await populateGoogleAddressLevels(result);
        if (value) {
          if (value.administrative_area_level_1) {
            value.state = value.administrative_area_level_1;
          }
          if (value.administrative_area_level_2) {
            value.city = value.administrative_area_level_2;
          }
          if (value.postal_code) {
            value.pin_code = value.postal_code;
          }

          result = { ...result, ...value };
        }
      }

      if (result.formatted_address && result.id) {
        result._id = result.id + result.formatted_address;
      }
      const {
        _id,
        country,
        city,
        state,
        pin_code,
        geometry: { location: { lat, lng } = {} } = {},
      } = result || {};
      const resultToSave = {
        _id,
        place_id,
        country,
        city,
        state,
        pin_code,
        description,
        latitude: lat,
        longitude: lng,
      };
      return resultToSave;
    })
    .catch((e) => {
      throw e;
    });
};

export const getOrganizationData = async ({ id }) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id: 'organizationLists',
          addOnFilter: {
            _id: id,
          },
        },

        model: 'Organizations',
      },
    },
  });
  return data;
};

export const getPatientProfile = async ({ _id }) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id: 'patientLists',
          addOnFilter: {
            _id,
          },
        },
        model: 'Patients',
      },
    },
  });
  return data;
};

export const getDoctorProfile = async ({ _id }) => {
  const data = await fetch({
    uri: {
      props: {
        query: {
          id: 'doctorLists',
          addOnFilter: {
            _id,
          },
        },

        model: 'Doctor',
      },
    },
  });
  return data;
};

export const actionImport = ({ data }) => {
  const { _id } = data || {};
  if (!_id) {
    return;
  }
  return invoke({
    paramValue: {
      _selectedIds_: [_id],
      _allPageSelected: false,
      _id,
      _query: {
        id: 'importHistoryLogList',
        metadata: false,
        dataParams: {},
        addOnFilter: {
          $and: [
            {
              _id: {
                $in: [_id],
              },
            },
          ],
        },
      },
      _model: 'ImportHistoryLogs',
    },
    id: '_importExcel',
  })
    .then(({ result }) => {
      Toast.show({
        text: 'import success',
        duration: Toast.LENGTH_LONG,
      });
      return result;
    })
    .catch((err) => {
      Toast.show({
        text: `${err}`,
        duration: Toast.LENGTH_LONG,
      });
      return err;
    });
};

export const importFile = ({ model, skipFields, id }) => async (props) => {
  let { data, updates } = props;
  if (updates && updates.model === 'Drugs') {
    updates = { ...updates, source: 'MasterDrug' };
  } else {
    updates = { ...updates, source: updates.model };
  }
  return submit({ model, skipFields, id })({ ...props, updates });
};

export const getPermission = (accesssKey) => {
  if (isSuperadmin(user)) {
    return true;
  }

  const isRouteAccessible = false;
  if (isArray(accesssKey)) {
    for (let index = 0; index < accesssKey.length; index++) {
      const access = accesssKey[index];
      if (user?.permissions && user?.permissions[access]) {
        return true;
      }
    }
  }
  if (user?.permissions && user?.permissions[accesssKey]) {
    return true;
  }

  // Object.keys(ROUTES).forEach((route) => {
  //   if (ROUTES[route].name === accesssKey) {
  //     if (user?.role?.permissions && user?.role?.permissions[ROUTES[route]?.accessKey]) {
  //       isRouteAccessible = true;
  //     }
  //   }
  // });
  return isRouteAccessible;
};

export const updateFormSubmit = ({
  url,
  id = 'updateData',
  model,
  paramValue,
  service,
  token,
} = {}) => (props) => {
  const { navigation, eventDispatcher, updates } = props;
  const { fetchUriEvent } = navigation.state.params.formProps || {};
  let uri;
  const { clearSelection, selectedIds, allPageSelected } = navigation.getParam(
    'formProps',
  );

  if (fetchUriEvent && eventDispatcher) {
    const uriEventInfo = eventDispatcher.notify(fetchUriEvent) || {};
    uri = uri || uriEventInfo.uri || {};
  }
  const resolvedUriProps = getInvokeUriProps({
    selectedIds,
    allPageSelected,
    uri,
    globalParamValue: uri.globalParamValue,
  });
  if (updates && updates._id) {
    delete updates._id;
  }
  const uriProps = {
    url,
    id,
    ...resolvedUriProps,
    paramValue: {
      ...paramValue,
      ...resolvedUriProps.paramValue,
      model,
      updates,
    },
  };
  if (token) {
    uriProps.token = token;
  }
  if (service) {
    if (typeof service === 'function') {
      service = service({
        uriProps,
        ...props,
      });
    }
    service = service || {};
    if (service.id) {
      uriProps.id = service.id;
    }

    if (service.paramValue) {
      uriProps.paramValue = {
        ...uriProps.paramValue,
        ...service.paramValue,
      };
    }
  }

  return invoke(uriProps).then((data) => {
    clearSelection && clearSelection();
    return data;
  });
};
