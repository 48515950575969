
export const durationForAttendance = (field) => ({ item }) => {
  const value = (item && item[field]) || null;
  if (!value) return undefined;
  let duration = '';
  const defaultDuration = 60;
  const hours = value && value / defaultDuration;
  const minute = value && value % defaultDuration;
  if (hours && hours > 0) duration += `${hours.toFixed(0)}h `;
  if (minute) duration += `${minute.toFixed(0)}m`;
  return duration;
};

export const MonthLayout = {
  header: 'MONTH',
  type: 'text',
  value: ({ item }) => (item && item.month_id && item.month_id.name) || '--',
};

export const YearLayout = {
  header: 'Year',
  type: 'text',
  value: ({ item }) => (item && item.year_id && item.year_id.name) || '--',
};

export const AvailableLayout = {
  type: 'number',
  header: 'Accured',
  // modifyValue: ({ value }) => ((value && Number(value)) || '0'),
  toFixed: 2,
  field: 'leaveDetails.accrued_leaves',
};

export const leaveNameLayout = {
  type: 'text',
  header: 'Leave Type',
  field: 'leaveDetails.leave_type_id.name',
};

export const ConsumedLayout = {
  type: 'number',
  field: 'leaveDetails.consumed_leaves',
  // modifyValue: ({ value }) => ((value && Number(value)) || '0'),
  header: 'CONSUMED',
  defaultText: 0,
  toFixed: 2,
};

export const BalanceLayout = {
  type: 'number',
  header: 'LEAVE BALANCE',
  field: 'leaveDetails.leave_balance',
  // modifyValue: ({ value }) => ((value && Number(value)) || '0'),
  defaultText: 0,
  toFixed: 2,
};

export const OpeningLayout = {
  type: 'number',
  header: 'OPENING BALANCE',
  field: 'leaveDetails.opening_leave_balance',
  // modifyValue: ({ value }) => ((value && Number(value)) || '0'),
  defaultText: 0,
  toFixed: 2,
};

export const AttendanceDateLayout = {
  header: 'DATE',
  type: 'date',
  field: 'attendance_date',
};

export const requestedDateLayout = {
  header: 'DATE',
  type: 'date',
  field: 'request_date',
};

export const FirstHalfLayout = {
  header: 'FIRST HALF',
  type: 'text',
  value: ({ item }) => item.first_attendance_type_id.name,

};

export const SecondHalfLayout = {
  header: 'SECOND HALF',
  type: 'text',
  value: ({ item }) => item?.second_attendance_type_id?.name,
};

export const StatusLayout = {
  header: 'STATUS',
  type: 'text',
  value: ({ item }) => (item && item.is_approved && 'Approved') || 'Unapproved',
};

export const TotalSalaryDaysLayout = {
  header: 'SALARY DAYS',
  type: 'text',
  field: 'details.total_salary_days',
};

export const presentDaysLayout = {
  header: 'PRESENT DAYS',
  type: 'text',
  field: 'details.present_days',
};

export const HolidaysLayout = {
  header: 'HOLIDAYS',
  type: 'text',
  field: 'details.holidays',
};

export const compensatoryOffLayout = {
  header: 'COMPENSATORY OFF',
  type: 'text',
  field: 'details.compensatory_off',
};

export const ewdPayableLayout = {
  header: 'EWD Payable',
  type: 'text',
  field: 'details.ewd_payable',
};

export const ewdAdjustWithLeavesLayout = {
  header: 'Ewd Adjust With Leaves',
  type: 'text',
  field: 'details.ewd_adjust_with_leaves',
};

export const employeeLayout = {
  header: 'EMP CODE',
  type: 'text',
  field: 'employee.employee_code',
};

export const accuredLeaveLayout = {
  header: 'ACCURED LEAVE',
  type: 'text',
  field: 'leaveDetails.accrued_leaves',
};
