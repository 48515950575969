import React from 'react';
import { TabNavigator } from '../../../../../app-components';
import { countUri } from '../../../../../Queries/countUri';
import { MODELS } from '../../../../../Lib/constants';
import ConsultationLogList from '../../../../../Wired/ConsultationLogTable';
import VaccinationLogList from '../../../../../Wired/VaccinationLogTable';

const ViewPatientTabs = (props) => {
  const { data } = props;
  if (!data?._id) {
    return null;
  }

  const PatientTabNavigation = TabNavigator({
    tabs: {
      Consultations: {
        label: 'Consultation Log',
        screen: ConsultationLogList,
        screenProps: {
          data,
          model: MODELS.CONSULTATION,
          id: 'consultationDataList',
          addOnFilter: { patient: { _id: data?._id } },
        },
        countUri: countUri({
          model: MODELS.CONSULTATION,
          id: 'consultationDataList',
          filter: { patient: { _id: data?._id } },
        }),
      },
      Vaccination: {
        label: 'Vaccination Log',
        screen: VaccinationLogList,
        screenProps: {
          data,
          model: MODELS.PATIENT_VACCINATION_HISTORY,
          id: 'patientVaccinationHistory',
          addOnFilter: { patient: { _id: data?._id } },
        },
        countUri: countUri({
          model: MODELS.PATIENT_VACCINATION_HISTORY,
          id: 'patientVaccinationHistory',
          filter: { patient: { _id: data?._id } },
        }),
      },
    },
  });
  return <PatientTabNavigation {...props} />;
};
export default ViewPatientTabs;
