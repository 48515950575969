import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';

import { NestedAction, NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { autoSuggestInput, multiAutoSuggestInput, searchInput } from '../../../autoSuggestions';
import { EditLeavePolicyType } from '../../../Queries/leavePolicyType';
import { FormHoc, Text, View } from '../../../app-components';
import { submit } from '../../../AppServices';
import vars from '../../../theme/vars';
import {
  gendersAll,
  leaveCreditType,
  MODELS,
} from '../../../Lib/constants';
import { validateSlashInputLength } from '../../../Lib/helpers';

const getNewId = () => `new_${uuid()}`;

const EditLeavePolicy = FormHoc({
  type: 'standard',
  closeView: 1,
  onSubmit: submit({
    model: MODELS.LEAVE_POLICY_TYPE,
  }),
  defaultValues: () => ({
    leave_policy_details: [{ _id: getNewId() }],
  }),
  uri: EditLeavePolicyType,
  reloadEvent: `reload${MODELS.LEAVE_POLICY_TYPE}`,
  submitMessage: 'leaveType.messages.leavePolicyUpdateMessage'.getLabel(),
  uniqueValidationMessage: 'leaveType.messages.uniqueLeavePolicy'.getLabel(),
  mandatory: {
    name: 1,
    staffType: 1,
    leave_policy_details: {
      leave_type_id: 1,
      gender: 1,
    },
  },
  validations: {
    leave_policy_details: {
      no_of_leaves_assigned: ({ data }) => validateSlashInputLength(
        data.no_of_leaves_assigned,
        2,
        'Only whole numbers with / are allowed.',
      ),
    },
  },
  lg: {
    formGroups: [
      {
        columnsPerRow: 4,
        fieldVariant: 'filled',
        groupsContainerStyle: { paddingLeft: 12 },
        groups: [
          {
            columns: [
              {
                type: 'text',
                field: 'name',
                label: 'leaveType.labels.leavePolicyName'.getLabel(),
                mandatory: true,
                allowWhiteSpaces: true,
              },
              autoSuggestInput({
                label: 'leaveType.labels.staffType'.getLabel(),
                field: 'staffType',
                valueField: 'name',
                suggestionField: 'name',
                placeholder: 'leaveType.placeholders.selectStaffType'.getLabel(),
                model: MODELS.STAFFTYPE,
                query: 'masterDataList',
                mandatory: true,
              }),
              {
                type: 'toggleSwitch',
                field: 'isActive',
                label: 'leaveType.labels.status'.getLabel(),
                container: 'leftLabel',
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'Short Leave Details',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 0 },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'shortLeaves',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  footer: ({ addRow }) => ({
                    leftActions: [{
                      render: () => (
                        <View style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderWidth: 1,
                          borderRadius: 5,
                          width: 120,
                          height: 40,
                          borderColor: 'grey',
                        }}
                        >
                          <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                            {` ${'leaveType.labels.addRow'.getLabel()}`}
                          </Text>
                        </View>
                      ),
                      onPress: addRow,
                    }],
                  }),
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      paddingLeft: 12,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      searchInput({
                        field: 'leave_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveType'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 185,
                        mandatory: true,
                      }),
                      {
                        type: 'number',
                        field: 'priority',
                        variant: 'filled',
                        header: 'Priority',
                        placeholder: 'Priority',
                        width: 200,
                        mandatory: true,
                      },
                      NestedAction(),
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
      {
        fieldVariant: 'filled',
        label: 'Leave Policy',
        labelStyle: {
          ...vars.headings.h8,
          color: vars.colors.grey.color2,
          textTransform: 'uppercase',
          paddingLeft: 12,
        },
        groupsContainerStyle: { paddingLeft: 0 },
        groups: [
          {
            columnsPerRow: 1,
            columns: [
              {
                field: 'leave_policy_details',
                fieldType: 'nested',
                Component: NestedTable,
                componentProps: {
                  maxHeight: 400,
                  footer: ({ addRow }) => ({
                    leftActions: [{
                      render: () => (
                        <View style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderWidth: 1,
                          borderRadius: 5,
                          width: 120,
                          height: 40,
                          borderColor: 'grey',
                        }}
                        >
                          <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color3 }}>
                            {` ${'leaveType.labels.addRow'.getLabel()}`}
                          </Text>
                        </View>
                      ),
                      onPress: addRow,
                    }],
                  }),
                  skipTableHeaderOnNoData: true,
                  listProps: {
                    hideColumnHeader: false,
                    headerRowColumnTextStyle: {
                      paddingLeft: 12,
                      ...vars.headings.h13,
                      color: vars.colors.grey.color3,
                      numberOfLines: 1,
                    },
                    columns: [
                      searchInput({
                        field: 'leave_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveType'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 185,
                        mandatory: true,
                      }),
                      autoSuggestInput({
                        field: 'gender',
                        variant: 'filled',
                        header: 'leaveType.labels.gender'.getLabel(),
                        suggestionField: 'label',
                        placeholder: 'leaveType.placeholders.selectGender'.getLabel(),
                        options: gendersAll,
                        width: 150,
                        mandatory: true,
                      }),
                      {
                        type: 'text',
                        field: 'no_of_leaves_assigned',
                        variant: 'filled',
                        header: 'leaveType.labels.noOfLeaves'.getLabel(),
                        placeholder: 'leaveType.placeholders.noOfLeaves'.getLabel(),
                        width: 200,
                        mandatory: true,
                      },
                      searchInput({
                        field: 'leave_credit_type_id',
                        variant: 'filled',
                        header: 'leaveType.labels.leaveCreditType'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectLeaveCredit'.getLabel(),
                        suggestionField: 'label',
                        options: leaveCreditType,
                        width: 230,
                      }),
                      multiAutoSuggestInput({
                        field: 'accuralExclude',
                        variant: 'filled',
                        header: 'leaveType.labels.accuralExcluding'.getLabel(),
                        placeholder: 'leaveType.placeholders.selectaccuralExcluding'.getLabel(),
                        keyField: 'name',
                        valueField: 'name',
                        suggestionField: 'name',
                        model: MODELS.LEAVE_TYPE,
                        query: 'leaveTypeSuggestions',
                        width: 230,

                      }),
                      {
                        type: 'text',
                        field: 'minExperienceMonths',
                        variant: 'filled',
                        header: 'leaveType.labels.minExperience'.getLabel(),
                        placeholder: 'leaveType.placeholders.minExp'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'text',
                        field: 'maxExperienceMonths',
                        variant: 'filled',
                        header: 'leaveType.labels.maxExperience'.getLabel(),
                        placeholder: 'leaveType.placeholders.maxExp'.getLabel(),
                        width: 150,
                      },
                      {
                        type: 'checkBox',
                        field: 'is_carry_forward',
                        variant: 'filled',
                        header: 'leaveType.labels.isCarryForward'.getLabel(),
                        width: 125,
                      },
                      {
                        type: 'text',
                        field: 'carry_forward_leaves',
                        variant: 'filled',
                        header: 'leaveType.labels.carryForwardLeaves'.getLabel(),
                        placeholder: 'leaveType.placeholders.carryForwardLeaves'.getLabel(),
                        width: 180,
                      },
                      {
                        type: 'text',
                        field: 'maximum_leave_accumulated',
                        variant: 'filled',
                        header: 'leaveType.labels.maximum_leave_accumulated'.getLabel(),
                        placeholder: 'leaveType.placeholders.maximum_leave_accumulated'.getLabel(),
                        width: 200,
                      },
                      {
                        type: 'text',
                        field: 'maximum_leave_allowed',
                        variant: 'filled',
                        header: 'leaveType.labels.maximum_leave_allowed'.getLabel(),
                        placeholder: 'leaveType.placeholders.maximum_leave_allowed'.getLabel(),
                        width: 180,
                      },
                      NestedAction(),
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
});

export default EditLeavePolicy;
