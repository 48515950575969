import React from 'react';

import {
  clearSelection,
  selectAll,
  showSelectedCount,
  Table,
} from '../../../app-components';
import { MODELS, ROUTES } from '../../../Lib/constants';
import { getString } from '../../../Lib/helpers';
import WithPermission from '../../../Components/WithPermission';
import permissions from '../../../Lib/permissions';
import { OutlineButton } from '../../../app-components/buttons/Buttons';
import vars from '../../../theme/vars';

const header = () => ({
  selectableActions: (props) => ([
    showSelectedCount(props),
    selectAll,
    clearSelection,
  ]),
});
const tableItems = {
  header,
  lg: {
    columns: [
      {
        header: 'Employee Name',
        type: 'text',
        sortable: 'firstName',
        value: ({ item }) => getString([item?.employee?.firstName, item?.employee?.lastName]),
      },
      {
        header: 'account.headers.ecrNo'.getLabel(),
        type: 'number',
        field: 'employee.ecrNumber',
      },
      {
        header: 'account.headers.ecrPageNo'.getLabel(),
        type: 'number',
        field: 'employee.ecrPageNumber',
      },
      {
        header: 'Month',
        type: 'text',
        field: 'month_id.name',
        sortable: true,
      },
      {
        header: 'Year',
        type: 'text',
        field: 'year_id.name',
        sortable: true,
      },
      {
        header: 'EmployeeId',
        type: 'text',
        field: 'employee.employeeId',
        sortable: true,
      },
      {
        header: 'Department',
        type: 'text',
        value: ({ item }) => (Array.isArray(item?.employee?.department) && getString(item?.employee?.department?.map((department) => department.name), ', ')),
      },
      // {
      //   header: 'Designation',
      //   type: 'text',
      //   field: 'designation',
      // },
      {
        header: 'Role',
        type: 'text',
        value: ({ item }) => (Array.isArray(item?.employee?.role) && getString(item?.employee?.role?.map((role) => role.name), ', ')),
      },
      {
        header: 'Employee Type',
        type: 'text',
        sortable: 'employeeType',
        value: ({ item }) => item?.employee?.employeeType?.name || '',
      },
      {
        header: 'Status',
        type: 'status',
        field: 'employee.isActive',
      },
      {
        header: 'Staff type',
        type: 'text',
        value: ({ item }) => item?.employee?.staffType?.name || '',
      },
    ],
  },
};

export default Table({
  reloadEvent: `reload${MODELS.MONTHLY_ATTENDANCE}`,
  model: MODELS.MONTHLY_ATTENDANCE,
  uri: () => ({
    query: {
      id: 'selfMonthlyAttendances',
      addOnFilter: {
        salaryStatus: 'hold',
        invoiceDate: {
          $exists: false,
        },
      },
    },
    model: MODELS.MONTHLY_ATTENDANCE,
  }),
  ...tableItems,
  action: ({ item }) => ({
    type: 'link',
    link: {
      view: ROUTES.viewEmployee.name,
      params: { item: { ...item.employee, editable: false } },
    },
  }),
});
