/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  isMobile,
  ScrollView,
} from '../../../app-components';
import { h14_Medium } from '../../../theme/fonts';
import { getUser } from '../../../AppServices';
import { bgs, colors } from '../../../theme/colors';
import SidePanelHeader from './SidePanelHeader';
import PatientDetailsUpdate from './updatePatientDetails';
import PatientConsultationHistory from './PatientConsultationHistory';
import DocumentationDetails from './documentation';

const { borderColor } = bgs;
const { themeColor } = colors;

const SidePanel = (props) => {
  const { publisherViewWidth } = props;

  const isUserDoctor = getUser().userType === 'Doctor';
  const [selectedTab, setSelectedTab] = useState('evaluate');
  const { item } = props;

  return (
    <View
      style={{
        // display: props.sideViewVisible,
        position: 'absolute',
        right: 0,
        width: isMobile
          ? '100%'
          : Dimensions.get('window').width - publisherViewWidth,
        height: '100%',
        borderLeftWidth: 1,
        borderColor,
        backgroundColor: '#fff',
        transition: 'width 2s',
      }}
    >
      <SidePanelHeader />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderBottomWidth: 1,
          borderColor,
          height: 45,
          overflow: 'hidden',
        }}
      >
        {[
          {
            label: 'Documentation',
            value: 'evaluate',
            visible: true,
          },
          {
            label: 'Patient Profile',
            value: 'patient_profile',
            visible: true,
          },
          {
            label: 'Consultation History',
            value: 'consultation_history',
            visible: true,
          },
          // { label: 'Chat', value: 'chat', visible: true },
        ].map((item) => (item.visible ? (
          <TouchableOpacity
            style={{ cursor: 'pointer' }}
            onPress={() => {
              setSelectedTab(item?.value);
            }}
          >
            <Text
              style={{
                ...h14_Medium,
                color: selectedTab === item?.value ? themeColor : '#383838',
              }}
            >
              {item?.label}
            </Text>
          </TouchableOpacity>
        ) : (
          <View />
        )))}
      </View>
      {/* {selectedTab === 'patient_profile' ? (
        <PatientProfile item={item} />
      ) : (
        void 0
      )} */}
      { selectedTab === 'patient_profile' ? (
        <ScrollView
          style={{
            flex: 1,
          }}
        >
          <PatientDetailsUpdate
            item={item}
            selectedTab={selectedTab}
            {...props}
          />
        </ScrollView>
      ) : (
        void 0
      )}
      { selectedTab === 'consultation_history' ? (
        <PatientConsultationHistory
          item={item}
          {...props}
          selectedTab={selectedTab}
        />
      ) : (
        void 0
      )}
      { selectedTab === 'evaluate' ? (
        <DocumentationDetails
          item={item}
          {...props}
          selectedTab={selectedTab}
        />
      ) : (
        void 0
      )}
      {/* {selectedTab === 'chat' ? (
        <ChatView
          item={{ ...item, appointment_id: { _id: item?._id } }}
          navigation={props?.navigation}
        />
      ) : (
        void 0
      )}
      {true || selectedTab === 'medical_history' ? (
        <MedicalHistory item={item} {...props} selectedTab={selectedTab} />
      ) : (
        void 0
      )} */}
    </View>
  );
};
export default SidePanel;
