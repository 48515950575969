import React, { useCallback } from 'react';

import {
  View, Text, Image, TouchableOpacity,
} from '../../../app-components';
import { backCrossIcon } from '../../../images';
import vars from '../../../theme/vars';

const FieldItem = ({ payable_amount: payableAmount, gross_amount: grossAmount = '--', salary_component_id: salaryComponent = {} }) => (
  <View
    style={{
      flexDirection: 'row',
      height: 30,
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
    }}
  >
    <Text
      style={{
        width: '33%',
      }}
    >
      {salaryComponent?.name}
    </Text>
    <Text
      style={{
        width: '33%',
        textAlign: 'center',
      }}
    >
      {grossAmount.toFixed(2)}
    </Text>
    <Text
      style={{
        width: '33%',
        textAlign: 'end',
      }}
    >
      {payableAmount.toFixed(2)}
    </Text>
  </View>
);

const getEarningDeductionComponents = (components = []) => {
  const earningComponents = [];
  const deductionComponents = [];

  components.forEach((component) => {
    if (component?.salary_component_id?.component_type === 'deduction_type') {
      deductionComponents.push(component);
    } else {
      earningComponents.push(component);
    }
  });

  return [earningComponents, deductionComponents];
};

const SalaryDetails = (props) => {
  const {
    salaryDetails,
    setSalaryDetails,
  } = props;

  const handleOnPressHideSalaryDetail = useCallback(() => {
    setSalaryDetails(null);
  }, [setSalaryDetails]);

  const [
    earningComponents,
    deductionComponents,
  ] = getEarningDeductionComponents(salaryDetails?.component_details);

  return (
    <View style={{
      backgroundColor: vars.colors.secondary.color4,
      borderRadius: 2,
      padding: 20,
      margin: 20,
    }}
    >
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      >
        <View>
          <Text style={{
            fontSize: 24,
            marginBottom: 12,
          }}
          >
            {`${salaryDetails?.month_id?.name} ${salaryDetails?.year_id?.name}`}
          </Text>
        </View>
        <View>
          <TouchableOpacity
            onPress={handleOnPressHideSalaryDetail}
            style={{
              alignItems: 'flex-end',
              height: 32,
              marginRight: 4,
            }}
          >
            <Image
              style={{
                height: 24,
                width: 24,
                cursor: 'pointer',
              }}
              source={backCrossIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: vars.colors.white,
          height: 30,
          marginTop: 30,
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Text>Gross Amount</Text>
        <Text>{salaryDetails?.gross_amount?.toFixed(2)}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: vars.colors.white,
          height: 30,
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Text>Payable Amount</Text>
        <Text>{salaryDetails?.payable_amount?.toFixed(2)}</Text>
      </View>
      <Text
        style={{
          marginTop: 15,
          fontSize: 18,
        }}
      >
        Earnings
      </Text>
      <View
        style={{
          flexDirection: 'row',
          height: 30,
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: vars.colors.white,
          marginTop: 15,
        }}
      >
        <Text
          style={{
            width: '33%',
          }}
        >
          Component
        </Text>
        <Text
          style={{
            width: '33%',
            textAlign: 'center',
          }}
        >
          Gross
        </Text>
        <Text
          style={{
            width: '33%',
            textAlign: 'end',
          }}
        >
          Payable
        </Text>
      </View>
      {
        earningComponents.map((component, index) => (
          <FieldItem key={index + 1} {...component} />
        ))
      }
      <Text
        style={{
          marginTop: 15,
          fontSize: 18,
        }}
      >
        Deductions
      </Text>
      <View
        style={{
          flexDirection: 'row',
          height: 30,
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: vars.colors.white,
          marginTop: 15,
        }}
      >
        <Text
          style={{
            width: '33%',
          }}
        >
          Component
        </Text>
        <Text
          style={{
            width: '33%',
            textAlign: 'center',
          }}
        >
          Gross
        </Text>
        <Text
          style={{
            width: '33%',
            textAlign: 'end',
          }}
        >
          Payable
        </Text>
      </View>
      {
        deductionComponents.map((component, index) => (
          <FieldItem key={index + 1} {...component} />
        ))
      }
    </View>
  );
};
export default SalaryDetails;
