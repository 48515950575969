import React from 'react';
import { Card } from '@applane/react-card';
import { ReactTable, HeaderRow, TableRow } from '@applane/react-table';
import { DataFetchConstants } from '@applane/react-datafetch';
import { Action } from '../action/Action';
import { ActivityIndicator } from '../loader/Loader';
import ToolBar from '../toolBar/ToolBar';
import { getUser } from '../../AppServices';
import { DataFetch } from '../data-fetch/DataFetch';
import {
  CellRender,
  FooterCellRender,
  HeaderCellRender,
} from './cell/CellRenders';
import Toast from '@applane/react-toast';
import TypeCast from '../render-components/TypeCast';
import { getResolvedMQProps } from '../../BreakPoints';
import {
  cardTheme,
  cardMobileTheme,
  titleCardTheme,
} from '../../theme/cardTheme';
import RenderRow from './RenderRow';
import MoreActions from '../action/MoreActions';
import cardRenderTypes from './CardRenderTypes';
import { WithHeaderSelector } from './selector/Selector';
import { getRenderComponent, isMobile } from '../UtilityFunctions';
import {
  tableHeaderTheme,
  tableFooterTheme,
  tableMobileTheme,
  tableTheme,
} from '../../theme/tableTheme';
import {
  expandedListTheme,
  expandedListMobileTheme,
  groupCardExpandedListTheme,
  groupCardExpandedListMobileTheme,
  filledExpandedListMobileTheme,
  filledExpandedListTheme,
} from '../../theme/expandedTableRowTheme';
import RenderNoData from '../../Components/TableNoData';
import { clearSelectionState } from './selector/utility';

const { fetchMoreEvent } = DataFetchConstants;

const getNextViewCount = ({ navigation }) => {
  if (navigation) {
    let isModalView = navigation.isModalView && navigation.isModalView();
    if (!isModalView) {
      let routeCount = navigation.getRouteCount && navigation.getRouteCount();
      if (routeCount && navigation.getIndex) {
        return routeCount - 1 - (navigation.getIndex() || 0);
      }
    }
  }
};

const getCardType = (type) => {
  return cardRenderTypes[type];
};

const StandardCard = (props) => {
  return (
    <Card
      typeCast={TypeCast}
      getTypeComponent={getCardType}
      {...(isMobile ? cardMobileTheme : cardTheme)}
      {...props}
    />
  );
};

const TitleCard = (props) => <Card {...titleCardTheme} {...props} />;

const cardTypes = {
  title: TitleCard,
  standard: StandardCard,
};
class CardRow extends React.Component {
  render() {
    let { type = 'standard', Component, ...restProps } = this.props;
    if (!Component) {
      Component = cardTypes[type];
    }
    if (!Component) {
      return null;
    }
    return <Component {...restProps} />;
  }
}
const typeWiseExpandableRow = {
  filledList: (props) => {
    return (
      <Table
        {...(isMobile
          ? filledExpandedListMobileTheme
          : filledExpandedListTheme)}
        {...props}
      />
    );
  },
  groupCardList: (props) => {
    return (
      <Table
        {...(isMobile
          ? groupCardExpandedListMobileTheme
          : groupCardExpandedListTheme)}
        {...props}
      />
    );
  },
  list: (props) => {
    return (
      <Table
        {...(isMobile ? expandedListMobileTheme : expandedListTheme)}
        {...props}
      />
    );
  },
};

class RenderHeaderRow extends React.PureComponent {
  render() {
    let { renderHeaderRow, ...restProps } = this.props;
    if (renderHeaderRow) {
      return getRenderComponent(renderHeaderRow, restProps);
    } else {
      return <HeaderRow {...restProps} />;
    }
  }
}

RenderHeaderRow = WithHeaderSelector(RenderHeaderRow);

class PureTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { columns: this.getResolvedColumns() };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeMQ !== this.props.activeMQ) {
      this.setState({
        columns: this.getResolvedColumns(),
      });
    }
  }

  getResolvedColumns = () => {
    let { columns } = this.props;
    if (columns && columns.length) {
      columns = columns.filter((column) => {
        if (column) {
          return this.resolveVisible(column.visible);
        }
      });
    }
    return columns;
  };

  resolveVisible = (visible = true) => {
    let { resolveVisible } = this.props;
    if (typeof visible === 'function') {
      let visibleProps = {
        user: getUser && getUser(),
        ...this.getRequiredProps(),
      };
      visible = resolveVisible
        ? resolveVisible({
            visible,
            ...visibleProps,
          })
        : visible({
            ...visibleProps,
          });
    }
    return visible;
  };

  onDataError = (error) => {
    const { onDataError } = this.props;
    if (onDataError) {
      onDataError(error);
    } else {
      error = error && error.message ? error.message : JSON.stringify(error);
      error && Toast.error(error);
    }
  };

  getRequiredProps = () => {
    let {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
    } = this.props;
    return {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
    };
  };

  onToggleExpanded = () => {
    let { navigation } = this.props;
    let count = getNextViewCount({ navigation });
    if (count) {
      navigation.pop(count);
    }
  };

  renderExpandedRow = (rowProps = {}) => {
    let {
      _level = 0,
      renderExpandedRow,
      disabledToggle,
      expanded,
    } = this.props;
    if (!renderExpandedRow) {
      return null;
    }
    let expandedComponent = getRenderComponent(renderExpandedRow, rowProps);
    if (expandedComponent && !React.isValidElement(expandedComponent)) {
      let { type, ...restRowProps } = expandedComponent || {};
      let params = {
        ...this.getRequiredProps(),
        disabledToggle,
        expanded,
        ...restRowProps,
      };
      if (type && type.startsWith('recursive')) {
        params.renderExpandedRow = renderExpandedRow;
        params._level = _level + 1;
      }
      expandedComponent =
        (type &&
          typeWiseExpandableRow[type] &&
          typeWiseExpandableRow[type](params)) ||
        typeWiseExpandableRow['list'](params);
    }
    return expandedComponent;
  };

  renderItem = (props) => {
    let {
      header,
      footer,
      renderHeader,
      renderFooter,
      typeWiseWidth,
      leftGap,
      RightGap,
      data,
      numColumns,
      multiCardWrapperStyle,
      extraData,
      ...restProps
    } = this.props;
    let extraProps = {
      Action,
      MultiActions: MoreActions,
      renderExpandedRow: this.renderExpandedRow,
      onToggleExpanded: this.onToggleExpanded,
    };
    if (restProps.card) {
      extraProps.Component = CardRow;
      if (numColumns && multiCardWrapperStyle) {
        extraProps.cardWrapperStyle = multiCardWrapperStyle;
      }
    } else {
      extraProps.Component = TableRow;
    }
    return (
      <RenderRow
        renderColumn={CellRender}
        hoverable={isMobile ? false : true}
        showSelected={getNextViewCount(restProps) > 0}
        {...restProps}
        {...props}
        {...extraProps}
      />
    );
  };

  renderHeaderRow = (props) => {
    let headerProps = {
      Action,
      ...this.props,
      ...props,
      _source_: 'header',
      renderColumn: HeaderCellRender,
    };
    return <RenderHeaderRow {...headerProps} />;
  };

  renderFooterRow = (props) => {
    let { renderFooterRow, ...restProps } = this.props;
    let footerProps = {
      Action,
      ...restProps,
      ...props,
      _source_: 'footer',
      renderColumn: FooterCellRender,
    };
    if (renderFooterRow) {
      return getRenderComponent(renderFooterRow, footerProps);
    } else {
      return <HeaderRow {...footerProps} />;
    }
  };

  renderHeader = (props) => {
    let { renderHeader, header } = this.props;
    let headerProps = {
      ...this.getRequiredProps(),
      ...props,
    };

    if (renderHeader) {
      return getRenderComponent(renderHeader, headerProps);
    } else if (header) {
      if (typeof header === 'function') {
        header = header(headerProps);
      }
      return <ToolBar {...tableHeaderTheme} {...headerProps} {...header} />;
    } else {
      return null;
    }
  };

  renderFooter = (props) => {
    let { renderFooter, footer } = this.props;
    let footerProps = {
      ...this.getRequiredProps(),
      ...props,
    };
    if (renderFooter) {
      return getRenderComponent(renderFooter, footerProps);
    } else if (footer) {
      if (typeof footer === 'function') {
        footer = footer(footerProps);
      }
      return <ToolBar {...tableFooterTheme} {...footerProps} {...footer} />;
    } else {
      return null;
    }
  };

  renderNoData = (props) => {
    let { skipRenderNoData, renderNoData = RenderNoData } = this.props;
    if (skipRenderNoData) {
      return null;
    }
    return getRenderComponent(renderNoData, {
      ...this.getRequiredProps(),
      ...props,
    });
  };

  renderLoading = ({ loading, source }) => {
    if (!loading || source === 'reloadEvent') {
      return null;
    }

    if (source === 'fetchMore') {
      return (
        <ActivityIndicator
          key="loading_table data"
          position={'relative'}
          size="small"
        />
      );
    }
    return <ActivityIndicator key="loading_table data" />;
  };
  onDataReload = () => {
    let { screenState, setScreenState } = this.props;
    clearSelectionState({ screenState, setScreenState });
  };

  render() {
    return (
      <ReactTable
        getUser={getUser}
        DataFetch={DataFetch}
        fetchMoreEvent={fetchMoreEvent}
        {...this.props}
        columns={this.state.columns}
        onDataError={this.onDataError}
        renderNoData={this.renderNoData}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        renderHeaderRow={this.renderHeaderRow}
        renderFooterRow={this.renderFooterRow}
        renderItem={this.renderItem}
        renderLoading={this.renderLoading}
        onDataReload={this.onDataReload}
      />
    );
  }
}

export class Table extends React.Component {
  render() {
    const { navigation } = this.props;
    const activeMQ =
      navigation && navigation.getActiveMQ && navigation.getActiveMQ();
    const resolvedProps = getResolvedMQProps(this.props, { activeMQ });
    return (
      <PureTable
        {...(isMobile ? tableMobileTheme : tableTheme)}
        {...resolvedProps}
        activeMQ={activeMQ}
      />
    );
  }
}

export const TableHoc = (defs) => {
  class TableComponent extends React.Component {
    render() {
      let _defs = defs;
      const { appointmentStore } = this.props;
      let key = void 0;
      if (appointmentStore) {
        const { newData: { _id } = {} } = appointmentStore || {};
        key = _id;
      }
      if (typeof _defs === 'function') {
        _defs = _defs(this.props);
      }
      return <Table {..._defs} {...this.props} key={key} />;
    }
  }
  return TableComponent;
};

export default TableHoc;
