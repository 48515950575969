import React, { useCallback } from 'react';

import {
  View,
  Text,
  TouchableOpacity,
  WithModal,
} from '../../../../app-components';
import { logout } from '../../../../AppServices';
import { ROUTES } from '../../../../Lib/constants';
import { getString } from '../../../../Lib/helpers';
import vars from '../../../../theme/vars';
import theme from '../theme';

const UserInfo = (props) => {
  const { user, navigation } = props;

  const getCharactersForAvatar = useCallback(() => {
    if (user?.role?.length && user?.role[0].code === 'superadmin') {
      return 'S';
    }
    return user?.employee?.firstName?.charAt(0) + user?.employee?.lastName?.charAt(0);
  }, [user]);

  return (
    <View>
      <WithModal
        autoHide
        dropdownStyle={{
          width: 242,
          height: 138,
        }}
        renderModal={({ frameStyle, hideModal }) => (
          <View
            style={{
              ...frameStyle,
              borderRadius: 4,
              backgroundColor: vars.colors.white,
              boxShadow: '0px 2px 14px rgba(56, 56, 56, 0.100005)',
            }}
          >
            <View
              style={{
                height: 63,
                backgroundColor: vars.colors.secondary.color4,
                flexDirection: 'row',
                borderRadius: 4,
              }}
            >
              <View
                style={{
                  ...theme.userInfoWrapper,
                  backgroundColor: vars.colors.secondary.color2,
                  marginTop: 14,
                  marginLeft: 14,
                  height: 34,
                  width: 34,
                }}
              >
                <Text numberOfLines={1} style={{ ...theme.usernameText, color: vars.colors.white }}>
                  {getCharactersForAvatar()}
                </Text>
              </View>
              <View style={{ paddingTop: 14, paddingLeft: 8 }}>
                <Text style={{ ...vars.headings.h7, color: vars.colors.secondary.color1 }}>
                  {getString([user?.employee?.firstName, user?.employee?.lastName]) || 'SuperAdmin'}
                </Text>
                <Text style={{
                  paddingTop: 4,
                  ...vars.headings.h13,
                  color: vars.colors.secondary.color1,
                }}
                >
                  {user?.username}
                </Text>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              {user?.employee?.role?.length && user?.employee?.role[0].code !== 'superadmin' && (
                <TouchableOpacity
                  onPress={() => {
                    navigation.push({
                      view: ROUTES.profile.name,
                      params: { items: user },
                    });
                    hideModal();
                  }}
                  style={{
                    width: 100,
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginTop: 17,
                  }}
                >
                  <Text style={{
                    ...vars.headings.h13,
                    color: vars.colors.secondary.color2,
                  }}
                  >
                    My Profile
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                onPress={() => {
                  logout().then(() => {
                    navigation.reset('authenticator');
                    hideModal();
                  });
                }}
                style={{
                  width: 100,
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginTop: 8,
                }}
              >
                <Text style={{
                  ...vars.headings.h13,
                  color: vars.colors.secondary.color2,
                  paddingRight: 8,
                }}
                >
                  Sign Out
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      >
        <View
          style={theme.userInfoWrapper}
        >
          <Text numberOfLines={1} style={theme.usernameText}>
            {getCharactersForAvatar()}
          </Text>
        </View>
      </WithModal>
    </View>
  );
};

export default UserInfo;
