import React, { useCallback, useEffect, useState } from 'react';

import { ActivityIndicator, Text, View } from '../../../../../../app-components';
import { MODELS } from '../../../../../../Lib/constants';
import vars from '../../../../../../theme/vars';
import { Form } from '../../../../../../app-components/form/Form';
import { fetch } from '../../../../../../AppServices';

const ViewLoanOutRequest = (props) => {
  const { navigation: { state: { params: { item } = {} } = {} } = {} } = props;
  const [assetItems, setAssetItem] = useState();
  const [loading, setLoading] = useState(true);

  const fetchAssetDetails = useCallback(async () => {
    const fetchedAssetDetails = await fetch({
      uri: {
        props: {
          query: {
            id: 'assetInventoryDataList',
            addOnFilter: {
              _id: { $in: item?.assets?.map(({ asset: { _id } }) => _id) },
            },
          },
          model: MODELS.ASSET,
          limit: 1,
        },
      },
    });
    setAssetItem(fetchedAssetDetails?.data);
    setLoading(false);
    return fetchedAssetDetails;
  }, [item]);

  useEffect(() => {
    fetchAssetDetails();
  }, []);

  if (loading) return <ActivityIndicator />;

  return (
    <Form
      type="standard"
      closeView={1}
      reloadEvent={`reload${MODELS.ASSET}`}
      editable={false}
      defaultValues={() => (item)}
      lg={{
        formGroups: [
          {
            columnsPerRow: 5,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'text',
                    label: 'asset.labels.assetName'.getLabel(),
                    render: () => {
                      const { item: { name } } = assetItems[0];
                      return (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ ...vars.headings.h9 }}>
                            {name}
                          </Text>
                        </View>
                      );
                    },
                  },
                  {
                    type: 'date',
                    label: 'asset.labels.loanOutDate'.getLabel(),
                    format: 'DD MMM YYYY',
                    field: 'loanedOutDate',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.loanOutQuantity'.getLabel(),
                    render: (renderProps) => (
                      <Text style={{ ...vars.headings.h9, color: vars.colors.grey.color1 }}>
                        {renderProps?.navigation?.state?.params?.item?.assets?.length}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.loanOutDays'.getLabel(),
                    field: 'loanedOutDays',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.department'.getLabel(),
                    field: 'loanedOutDept.name',
                  },
                  {
                    type: 'text',
                    label: 'asset.labels.speciality'.getLabel(),
                    field: 'loanedOutSpeciality.name',
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            label: 'inventory.title.itemDetails'.getLabel(),
            labelStyle: {
              ...vars.headings.h8,
              color: vars.colors.grey.color2,
              textTransform: 'uppercase',
              paddingLeft: 4,
            },
            groupsContainerStyle: { paddingLeft: 0 },
            groups: [
              {
                columns: [
                  {
                    type: 'custom',
                    render: () => (
                      <View style={{ flexDirection: 'row' }}>
                        {assetItems.map(({ serialNumber }) => (
                          <View
                            style={{
                              padding: 10,
                              margin: 5,
                              borderRadius: 50,
                              backgroundColor: vars.colors.white,
                            }}
                            key="serialNo"
                          >
                            <Text style={{ ...vars.headings.h9 }}>
                              {serialNumber}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ),
                    header: 'asset.labels.serialNo'.getLabel(),
                    label: 'asset.labels.serialNo'.getLabel(),
                    variant: 'filled',
                    field: 'serialNumber',
                    editable: false,
                  },
                ],
              },
            ],
          },
        ],
      }}
      {...props}
    />
  );
};

export default ViewLoanOutRequest;
