import React, { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { View, ScrollView, FormField } from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { MODELS } from '../../../../Lib/constants';
import { EditRole } from '../../../../Queries/role';
import { submit } from '../../../../AppServices';
import theme from '../theme';
import Views from './Views';
import { validateAlphabetsWithSpaceOnly, validateInputLength } from '../../../../Lib/helpers';

const AddRole = (props) => {
  const submitAPI = submit({
    model: MODELS.ROLE,
    ...props,
  });

  const handleOnChangeOnSubmit = useCallback(
    (data) => {
      const clonedData = cloneDeep(data);
      if (clonedData?.updates?.permissions) {
        clonedData.updates.editablePermissions = clonedData?.updates?.permissions;
        delete clonedData?.updates?.permissions;
      }
      return submitAPI(clonedData);
    },
    [submitAPI],
  );

  return (
    <ScrollView style={theme.scrollViewStyle}>
      <Form
        onSubmit={handleOnChangeOnSubmit}
        uri={EditRole}
        closeView={1}
        submitMessage={'role.messages.roleEditedSuccessfully'.getLabel()}
        reloadEvent="reloadroles"
        uniqueValidationMessage={'role.messages.roleAlreadyExists'.getLabel()}
        mandatory={{
          name: 1,
        }}
        validations={{
          name: ({ data }) => validateAlphabetsWithSpaceOnly(data?.name),
          description: ({ data }) => validateInputLength(data?.description, 2, 200),
        }}
        {...props}
      >
        <EditRoleInfoForm {...props} />
      </Form>
    </ScrollView>
  );
};

const EditRoleInfoForm = ({ form_context: formContext = {}, form_state: formState = {} }) => {
  const { setValue } = formContext;
  const { data } = formState;

  return (
    <View style={theme.containerStyle}>
      <View style={theme.infoFormWrapper}>
        <View style={theme.roleInputWrapper}>
          <FormField
            field="name"
            label="Role"
            type="text"
            placeholder="Role"
            variant="filled"
            mandatory
            allowWhiteSpaces
            allowConsecutiveSpaces
          />
        </View>
        <View style={theme.statusWrapper}>
          <FormField
            field="isActive"
            type="toggleSwitch"
            label="Status"
            container="leftLabel"
            align="center"
            placeholder="Status"
            showOuterLabel
            variant="filled"
            mandatory
          />
        </View>
        <View style={theme.descriptionWrapper}>
          <FormField
            field="description"
            type="textArea"
            label="Description"
            placeholder="Description"
            variant="filled"
            minHeight={80}
            maxHeight={80}
          />
        </View>
      </View>
      <Views
        setValue={setValue}
        formContext={formContext}
        data={data}
      />
    </View>
  );
};

export default AddRole;
