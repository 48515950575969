import React, { useCallback } from 'react';

import { NestedTable } from '../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../Components/ListActionModal';
import { Form } from '../../../../../app-components/form/Form';
import { currencyInput } from '../../../../../compositeInputs';
import { searchInput } from '../../../../../autoSuggestions';
import {
  save, Text, Toast, View,
} from '../../../../../app-components';
import { MODELS } from '../../../../../Lib/constants';
import { userView } from '../../../../../images';
import vars from '../../../../../theme/vars';
import { invoke } from '../../../../../AppServices';
import { getErrorString } from '../../../../../Lib/helpers';

const AssignBuyerItem = (props) => {
  const { navigation: { state: { params: { item } } } } = props;
  const modifiedItem = [{ item: { ...item } }];

  const handleAssignBuyer = useCallback(async ({ data }) => {
    try {
      const result = await invoke({
        id: 'assignBuyerToMultipleAuctionItems',
        paramValue: { data },
      });
      if (result) {
        Toast.show({
          message: 'Success',
          description: 'Buyer has been updated successfully.',
          type: 'success',
          position: 'top',
          direction: 'right',
          duration: 3000,
        });
      }
    } catch (err) {
      Toast.show({
        message: 'Error',
        description: `${getErrorString(err)}`,
        type: 'error',
        position: 'top',
        direction: 'right',
      });
    }
  }, []);

  return (
    <ListActionModal
      icon={userView}
      title="Assign Buyer"
      description="Assign a buyer to this item."
      content={(
        <Form
          {...props}
          closeView={1}
          footer={{
            actions: [
              save,
            ],
          }}
          reloadEvent={`reload${MODELS.AUCTION_ITEMS}`}
          bodyContainerStyle={{ backgroundColor: vars.colors.white }}
          defaultValues={() => ({ items: modifiedItem, currency: 'INR' })}
          onSubmit={handleAssignBuyer}
          mandatory={{
            currency: 1,
            soldAtPrice: 1,
            buyer: 1,
            items: {
              item: 1,
            },
          }}
          {...props}
          lg={{
            formGroups: [
              {
                columnsPerRow: 2,
                fieldVariant: 'filled',
                groupsContainerStyle: { paddingLeft: 12 },
                groups: [
                  {
                    columnsPerRow: 2,
                    columns: [
                      currencyInput({
                        variant: 'filled',
                        mandatory: true,
                        amount: {
                          field: 'soldAtPrice',
                          label: 'Sold At Price',
                          placeholder: 'Enter Amount',
                        },
                        currency: {
                          field: 'currency',
                        },
                      }),
                      searchInput(
                        {
                          field: 'buyer',
                          variant: 'filled',
                          label: 'Select Buyer',
                          placeholder: 'Select Buyer',
                          keyField: 'name',
                          valueField: 'name',
                          suggestionField: 'name',
                          model: MODELS.BUYERS,
                          query: 'masterDataList',
                          mandatory: true,
                        },
                      ),
                    ],
                  },
                ],
              },
              {
                fieldVariant: 'filled',
                label: 'LIST OF ITEMS',
                labelStyle: {
                  ...vars.headings.h8,
                  color: vars.colors.grey.color2,
                  textTransform: 'uppercase',
                  paddingLeft: 12,
                },
                groupsContainerStyle: { paddingLeft: 0 },
                groups: [
                  {
                    columns: [
                      {
                        field: 'items',
                        fieldType: 'nested',
                        editable: false,
                        Component: NestedTable,
                        componentProps: {
                          maxHeight: 400,
                          skipTableHeaderOnNoData: false,
                          listProps: {
                            hideColumnHeader: false,
                            headerRowContainerStyle: {
                              minHeight: 32,
                              paddingLeft: 12,
                              paddingRight: 4,
                              backgroundColor: vars.colors.white,
                            },
                            columns: [
                              {
                                variant: 'filled',
                                header: 'SERIAL NO.',
                                width: 260,
                                render: ({ item: renderItem }) => (
                                  <View
                                    style={{
                                      flex: 1,
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                      }}
                                    >
                                      {renderItem?.item?.item?.serialNumber}
                                    </Text>
                                  </View>
                                ),
                              },
                              {
                                type: 'custom',
                                header: 'ASSET/ITEM NAME',
                                variant: 'filled',
                                render: ({ item: renderItem }) => (
                                  <View
                                    style={{
                                      flex: 1,
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                      }}
                                    >
                                      {renderItem?.item?.item?.item?.name}
                                    </Text>
                                  </View>
                                ),
                                width: 180,
                              },
                              {
                                type: 'text',
                                header: 'CURRENT VALUE',
                                variant: 'filled',
                                render: ({ item: renderItem }) => (
                                  <View
                                    style={{
                                      flex: 1,
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        ...vars.headings.h9,
                                      }}
                                    >
                                      {renderItem?.item?.currentValue}
                                    </Text>
                                  </View>
                                ),
                                width: 180,
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
    )}
      {...props}
    />
  );
};

export default AssignBuyerItem;
