import React from 'react';
import {
  FormField, Image, Text, View,
} from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { barcode } from '../../../../images';
import vars from '../../../../theme/vars';
import { getString } from '../../../../Lib/helpers';
import PatientVaccinationHistoryTabs from './Tabs';
import { GetVaccineVaccineDetail } from '../../../../Queries/vaccination';
import { MODELS } from '../../../../Lib/constants';

const PatientDetails = (props) => {
  const { navigation: { state: { params: { item: patientInfo } } } } = props;

  return (
    <Form
      containerStyle={{
        backgroundColor: vars.colors.secondary.color4,
        flex: 1,
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 16,
        marginRight: 16,
      }}
      closeView={1}
      editable={false}
      uri={GetVaccineVaccineDetail}
      reloadEvent={`reload${MODELS.VACCINATION}`}
      {...props}
    >
      {() => (
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flexDirection: 'column', flex: 0.2 }}>
            <View style={{ flexDirection: 'row', marginLeft: 24, marginTop: 20 }}>
              <View>
                <Image
                  source={barcode}
                />
              </View>
              <View style={{ marginTop: 20, marginLeft: 24 }}>
                <Text style={{ ...vars.headings.h13, color: vars.colors.grey.color3 }}>
                  {'billing.labels.uhid'.getLabel()}
                </Text>
                <Text style={{ ...vars.headings.h8, marginTop: 4 }}>
                  {patientInfo?.patient?.uhid}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'column', flex: 0.8 }}>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.name'.getLabel()}
                  field="patient.patientName"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.relation'.getLabel()}
                  editable={false}
                  render={({ data: { patient } }) => (
                    <Text style={{ ...vars.headings.h8 }}>
                      {getString([patient?.relation, patient?.relationName])}
                    </Text>
                  )}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.gender'.getLabel()}
                  field="patient.gender"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.age'.getLabel()}
                  field="patient.age"
                  variant="filled"
                  editable={false}
                  render={({ data: { patient } }) => (
                    <Text style={{ ...vars.headings.h8 }}>
                      {getString([patient?.age, patient?.ageType])}
                    </Text>
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: 14 }}>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label={'billing.labels.mobile'.getLabel()}
                  field="patient.mobile"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label="Department"
                  field="consultation.department.name"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="text"
                  label="Speciality"
                  field="consultation.speciality.name"
                  variant="filled"
                  editable={false}
                />
              </View>
              <View style={{ flex: 1, marginLeft: 14 }}>
                <FormField
                  type="date"
                  label={'billing.labels.lastConsultation'.getLabel()}
                  field="patient.lastConsultationDate"
                  variant="filled"
                  editable={false}
                />
              </View>
            </View>
            <View style={{
              flex: 1, flexDirection: 'row', marginTop: 14, width: 200,
            }}
            />
          </View>
        </View>
      )}
    </Form>
  );
};

export default (props) => (
  <View style={{ flex: 1, overflow: 'hidden' }}>
    <View style={{ flex: 1 }}>
      <PatientDetails {...props} />
    </View>
    <View style={{ flex: 4, overflow: 'hidden' }}>
      <PatientVaccinationHistoryTabs {...props} />
    </View>
  </View>
);
