import React, { useState } from 'react';

import {
  View, Text, save, Toast,
} from '../../../../../../../../app-components';
import { NestedAction, NestedTable } from '../../../../../../../../app-components/input-components/nestedTable/NestedTable';
import ListActionModal from '../../../../../../../../Components/ListActionModal';
import { Form } from '../../../../../../../../app-components/form/Form';
import { MODELS } from '../../../../../../../../Lib/constants';
import { submit } from '../../../../../../../../AppServices';
import vars from '../../../../../../../../theme/vars';
import { autoSuggestInput } from '../../../../../../../../autoSuggestions';
import { validateInputLength } from '../../../../../../../../Lib/helpers';

const IpdAddProgress = (props) => {
  const { navigation: { state: { params: { dataParams } } } } = props;
  const { navigation: { state: { params: { data: paramsData } = {} } = {} } = {} } = props;
  const [sensoryDeficit, setSensoryDeficit] = useState(true);
  const [motorDeficit, setMotorDeficit] = useState(true);
  const [dressingSoakage, setDressingSoakage] = useState(true);
  return (
    <ListActionModal
      {...props}
      title="Daily Progress"
      description="Log Progress for the Patient for the selected date"
      content={(
        <Form
          {...props}
          closeView={1}
          reloadEvent={`reload${MODELS.IPD_PROGRESS_REPORT}`}
          footer={{
            containerStyle: {
              margin: 'auto',
            },
            actions: [
              save,
            ],
          }}
          defaultValues={{
            progressDate: new Date(),
          }}
          computations={{
            self: {
              sensoryDeficit: {
                compute: (data) => {
                  if (data?.isSensoryDeficit === 'No') {
                    setSensoryDeficit(true);
                    return ({
                      set: {
                        sensoryDeficit: '',
                      },
                    });
                  }
                  setSensoryDeficit(false);
                },
                onChange: ['isSensoryDeficit'],
              },
              MotorDeficit: {
                compute: (data) => {
                  if (data?.isMotorDeficit === 'No') {
                    setMotorDeficit(true);
                    return ({
                      set: {
                        motorDeficit: '',
                      },
                    });
                  }
                  setMotorDeficit(false);
                },
                onChange: ['isMotorDeficit'],
              },
              dressingSoakage: {
                compute: (data) => {
                  if (data?.isDressingSoakage === 'No') {
                    setDressingSoakage(true);
                    return ({
                      set: {
                        dressingSoakage: '',
                      },
                    });
                  }
                  setDressingSoakage(false);
                },
                onChange: ['isDressingSoakage'],
              },
            },
          }}

          onSubmit={async (submitProps) => {
            const { updates, data } = submitProps;
            const tempData = updates?.treatment?.insert;
            if (tempData) updates.treatment = tempData;
            updates.progressDate = new Date(
              new Date(
                new Date(
                  new Date(
                    new Date(updates?.progressDate).setUTCHours(0),
                  ).setUTCMinutes(0),
                ).setUTCSeconds(0),
              ).setUTCMilliseconds(0),
            );
            const newUpdate = {
              ...updates,
              ...paramsData,
              admissionID: { _id: submitProps?.navigation?.state?.params?.patient?._id },
              speciality: submitProps?.navigation?.state?.params?.dataParams?.filters?.specialityFilter,
            };

            const submitAPI = submit({
              model: MODELS.IPD_PROGRESS_REPORT,
              ...submitProps,
              data,
              updates: newUpdate,
            });

            await submitAPI({ data, updates: newUpdate });

            Toast.show({
              message: 'Success',
              description: 'Progress has been Created successfully.',
              type: 'success',
              position: 'top',
              direction: 'right',
              duration: 3000,
            });
          }}
          mandatory={{
            fever: 1,
            oralAcceptance: 1,
            vitalsP: 1,
            vitalsRR: 1,
            vitalsBP: 1,
            vitalsTemperature: 1,
            drainOutput: 1,
            intake: 1,
            output: 1,
            treatment: {
              drug: 1,
              route: 1,
              dose: 1,
              frequency: 1,
            },
          }}
          type="standardShadow"
          lg={{
            validations: {
              complaintOne: ({ data }) => validateInputLength(data?.complaintOne, 0, 100),
              complaintTwo: ({ data }) => validateInputLength(data?.complaintTwo, 0, 100),
              complaintThree: ({ data }) => validateInputLength(data?.complaintThree, 0, 100),
              complaintFour: ({ data }) => validateInputLength(data?.complaintFour, 0, 100),
              bladder: ({ data }) => validateInputLength(data?.bladder, 0, 100),
              gcs: ({ data }) => validateInputLength(data?.gcs, 0, 100),
              mentalStatus: ({ data }) => validateInputLength(data?.mentalStatus, 0, 100),
              vitalsP: ({ data }) => validateInputLength(data?.vitalsP, 1, 5),
              sensoryDeficit: ({ data }) => validateInputLength(data?.sensoryDeficit, 0, 100),
              motorDeficit: ({ data }) => validateInputLength(data?.motorDeficit, 0, 100),
              dressingSoakage: ({ data }) => validateInputLength(data?.dressingSoakage, 0, 100),
              drainOutput: ({ data }) => validateInputLength(data?.drainOutput, 0, 10),
              intake: ({ data }) => validateInputLength(data?.intake, 0, 10),
              output: ({ data }) => validateInputLength(data?.output, 0, 10),
              vitalsUrinalOutput: ({ data }) => validateInputLength(data?.vitalsUrinalOutput, 1, 4),
              vitalsDrainOutput: ({ data }) => validateInputLength(data?.vitalsDrainOutput, 1, 5),
              vitalsRtOutput: ({ data }) => validateInputLength(data?.vitalsRtOutput, 1, 5),
              route: ({ data }) => validateInputLength(data?.route, 1, 20),

              frequency: ({ data }) => validateInputLength(data?.frequency, 1, 50),
              description: ({ data }) => validateInputLength(data?.description, 1, 100),
            },
            verticalFormGroups: [
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'date',
                            field: 'progressDate',
                            label: 'Date',
                            editable: true,
                          },
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'doctorOnRound',
                            label: 'Doctor On Round',
                            variant: 'filled',
                            keyFiled: 'firstName',
                            suggestionField: 'firstName',
                            valueField: 'firstName',
                            model: MODELS.EMPLOYEE,
                            query: 'employeeLists',
                            mandatory: true,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    columnsPerRow: 1,
                    columns: [
                      {
                        render: () => 'Complaints',
                      },
                      {
                        type: 'text',
                        field: 'complaintOne',
                        editable: true,
                        variant: 'filled',
                      },
                      {
                        type: 'text',
                        field: 'complaintTwo',
                        variant: 'filled',
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'complaintThree',
                        variant: 'filled',
                        editable: true,
                      },
                      {
                        type: 'text',
                        field: 'complaintFour',
                        variant: 'filled',
                        editable: true,
                      },
                    ],
                  },
                ],
              },
              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'radioGroup',
                            field: 'fever',
                            label: 'Fever',
                            container: 'topLabel',
                            options: ['Yes', 'No'],
                            editable: true,
                          },
                          {
                            container: 'topLabel',
                            type: 'radioGroup',
                            label: 'Oral Acceptance',
                            field: 'oralAcceptance',
                            options: ['Yes', 'No'],
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                formGroups: [
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            type: 'text',
                            field: 'bladder',
                            label: 'Bladder',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'bowel',
                            label: 'Bowel',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'gcs',
                            label: 'GCS',
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'mentalStatus',
                            label: 'Mental Status',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  // Vitals
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>VITALS </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 4,
                        columns: [
                          {
                            type: 'text',
                            field: 'vitalsP',
                            label: 'P',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsBP',
                            label: 'BP',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsRR',
                            label: 'RR',
                            mandatory: true,
                            editable: true,
                          },
                          {
                            type: 'text',
                            field: 'vitalsTemperature',
                            label: 'Temperature',
                            mandatory: true,
                            editable: true,
                          },
                        ],
                      },

                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 3,
                        columns: [
                          {
                            type: 'radioGroup',
                            field: 'isSensoryDeficit',
                            container: 'topLabel',
                            label: 'Sensory Deficit',
                            options: ['Yes', 'No'],
                            editable: true,
                          },
                          {
                            render: () => <Text style={{ marginTop: 16 }}>If Yes Then</Text>,

                          },
                          {
                            type: 'text',
                            field: 'sensoryDeficit',
                            editable: true,
                            style: { flex: 3 },
                            disabled: sensoryDeficit,
                          },
                          {
                            type: 'radioGroup',
                            field: 'isMotorDeficit',
                            label: 'Motor Deficit',
                            container: 'topLabel',
                            options: ['Yes', 'No'],
                            editable: true,
                          },
                          {
                            render: () => <Text style={{ marginTop: 16 }}>If Yes Then</Text>,
                          },
                          {
                            type: 'text',
                            field: 'motorDeficit',
                            editable: true,
                            style: { flex: 3 },
                            disabled: motorDeficit,
                          },
                          {
                            type: 'radioGroup',
                            container: 'topLabel',
                            field: 'isDressingSoakage',
                            label: 'Dressing Soakage',
                            options: ['Yes', 'No'],
                            editable: true,
                          },
                          {
                            render: () => <Text style={{ marginTop: 16 }}>If Yes Then</Text>,
                          },
                          {
                            type: 'text',
                            field: 'dressingSoakage',
                            editable: true,
                            style: { flex: 3 },
                            disabled: dressingSoakage,
                          },
                          {
                            type: 'text',
                            field: 'drainOutput',
                            label: 'Drain Output',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'intake',
                            label: 'InTake(ml)',
                            editable: true,
                          },
                          {
                            type: 'number',
                            field: 'output',
                            label: 'Output',
                            editable: true,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    label: 'iii. TREATMENT',
                    labelStyle: {
                      ...vars.headings.h10,
                      color: vars.colors.grey.color2,
                    },
                    groupsContainerStyle: { paddingLeft: 0 },
                    groups: [
                      {
                        columns: [
                          {

                            field: 'treatment',
                            fieldType: 'nested',
                            Component: NestedTable,
                            componentProps: {
                              maxHeight: 400,
                              footer: ({ addRow }) => ({
                                leftActions: [{
                                  render: () => (
                                    <View style={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      width: 120,
                                      height: 40,
                                      borderColor: vars.colors.secondary.color2,
                                    }}
                                    >
                                      <Text style={{
                                        ...vars.headings.h9,
                                        color: vars.colors.secondary.color2,
                                      }}
                                      >
                                        {`+ ${'Add New Item'.getLabel()}`}
                                      </Text>
                                    </View>
                                  ),
                                  onPress: addRow,
                                }],
                              }),
                              skipTableHeaderOnNoData: true,
                              columnsPerRow: 4,
                              listProps: {
                                hideColumnHeader: false,
                                headerRowContainerStyle: {
                                  minHeight: 20,
                                  paddingLeft: 12,
                                  paddingRight: 4,
                                  backgroundColor: vars.colors.white,
                                },
                                columns: [
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'drug',
                                    label: 'Drug',
                                    editable: true,
                                    width: 250,
                                  },
                                  {
                                    type: 'text',
                                    variant: 'filled',
                                    field: 'route',
                                    label: 'Route',
                                    mandatory: true,
                                    editable: true,
                                    width: 150,
                                  },
                                  {
                                    type: 'number',
                                    field: 'dose',
                                    variant: 'filled',
                                    label: 'Dose (mg)',
                                    mandatory: true,
                                    editable: true,
                                    width: 100,
                                  },
                                  {
                                    type: 'text',
                                    field: 'frequency',
                                    label: 'Frequency',
                                    variant: 'filled',
                                    editable: true,
                                    mandatory: true,
                                    width: 200,
                                  },
                                  {
                                    type: 'textArea',
                                    field: 'description',
                                    label: 'Special Instruction',
                                    variant: 'filled',
                                    editable: true,
                                    width: 312,
                                  },
                                  NestedAction(),
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 1,
                        columns: [
                          {
                            fieldVariant: 'filled',
                            labelStyle: {
                              ...vars.headings.h8,
                              textTransform: 'uppercase',
                              paddingLeft: 10,
                            },
                            groupsContainerStyle: { paddingLeft: 12 },
                            render: () => (
                              <Text>Transfer Within Department </Text>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldVariant: 'filled',
                    direction: 'row',
                    groups: [
                      {
                        columnsPerRow: 5,
                        columns: [
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'transferTo',
                            label: 'Transfer To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.WARDS,
                            query: 'masterDataList',
                          }),
                          autoSuggestInput({
                            type: 'autoSuggest',
                            field: 'assignTO',
                            label: 'Assign To',
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.EMPLOYEE,
                            query: 'getEmployeeDetails',
                          },
                          {
                            addOnFilter: ({ data } = {}) => ({
                              speciality: { _id: data?.transferTo?._id },
                            })
                            ,
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },

            ],
          }}
        />
)}
    />

  );
};

const AddProgress = (props) => (<IpdAddProgress {...props} />);
export default AddProgress;
