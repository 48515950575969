/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react';

import { ActivityIndicator, View, Toast } from '../../../../app-components';
import { Form } from '../../../../app-components/form/Form';
import { fetch, getUser, submit } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { isSuperadmin } from '../../../../Lib/helpers';
import { GetOPDDepartment } from '../../../../Queries/department';
import TokenGenerationForm from './Components/TokenGenerationForm';

const StartSessionForm = (props) => {
  const { navigation } = props;

  const user = getUser();

  if (!navigation?.state?.params.isSessionInActive) {
    navigation.replace(ROUTES.viewActiveSession.name);
  }

  const [loading, setLoading] = useState();
  const [department, setDepartment] = useState();

  const getOPDDepartment = useCallback(async () => {
    try {
      const result = await fetch({ uri: GetOPDDepartment() });
      if (result?.data?.length) {
        setDepartment(result?.data[0]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return err;
    }
  }, []);

  useEffect(() => {
    getOPDDepartment();
  }, []);

  if (isSuperadmin(user)) {
    Toast.show({
      message: 'Error.',
      description: 'Superadmins are not allowed to create sessions, Please login with a employee to create sessions.',
      type: 'error',
      position: 'top',
      direction: 'right',
    });

    return <View />;
  }

  if (loading) return <ActivityIndicator />;

  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Form
        bodyContainerStyle={{
          flex: 1,
          justifyContent: 'space-between',
          marginTop: 20,
          marginBottom: 30,
        }}
        {...props}
        onSubmit={submit({ model: MODELS.SESSION })}
        submitNext={() => {
          navigation.replace(ROUTES.viewActiveSession.name);
        }}
        skipFields={['_id']}
        computations={{
          self: {
            department: {
              compute: () => ({
                set: { speciality: null },
              }),
              onChange: ['department'],
            },
            speciality: {
              compute: () => ({
                set: { queues: null },
              }),
              onChange: ['speciality'],
            },
          },
        }}
        {...props}
      >
        <TokenGenerationForm loading={loading} department={department} />
      </Form>
    </View>
  );
};

export default StartSessionForm;

