import React from 'react';

import { Table } from '../../../../../app-components/table/Table';
import {
  AttendanceDateLayout,
  FirstHalfLayout,
  SecondHalfLayout,
} from '../../../../../Wired/Attendance/LeaveRegister/Layouts';
import { UserLOPDrillUri } from '../../../../../Wired/Attendance/LeaveRegister/Uri';
import { RequestsStatusLayots } from '../../../../../Wired/Attendance/Self/AttendanceLayouts';

const AttendanceRequests = (props) => {
  const { user } = props;

  return (
    <Table
      {...props}
      listVersion={2}
      uri={() => {
        const addOnFilter = {
          employee: {
            _id: user?._id,
          },
          was_manual_update: true,
          first_attendance_type_id: {
            code: { $ne: 'leave' },
          },
          second_attendance_type_id: {
            code: { $ne: 'leave' },
          },
        };
        return UserLOPDrillUri({ addOnFilter });
      }}
      reloadEvent="UserLOPDrillRequestsList"
      hoverable={false}
      columns={[
        {
          width: 14,
        },
        {
          ...AttendanceDateLayout,
        },
        {
          ...FirstHalfLayout,
          width: 170,
        },
        {
          ...SecondHalfLayout,
          width: 170,
        },
        {
          ...RequestsStatusLayots,
          width: 160,
        },
      ]}
    />
  );
};

export default AttendanceRequests;
