import React from 'react';
import {
  clearSelection, FilterGroup, OutlineButton, selectAll, showSelectedCount, Text, View,
} from '../../../../app-components';

import { TabNavigator } from '../../../../app-components/tab/TabNavigator';
import tabTheme from '../../Tabs/tabTheme';
import EmployeeAttendanceChildTabNavigation from './ChildTabs';

const EmployeeAttendanceTabNavigation = (props) => (
  <TabNavigator
    theme={{ ...tabTheme }}
    tabs={{
      MonthlyAttendance: {
        label: 'Attendance',
        screen: EmployeeAttendanceChildTabNavigation,
      },
    }}
    {...props}
  />
);

export default EmployeeAttendanceTabNavigation;
