import React from 'react';

import {
  PharmacyDepartmentInventoryToggleFilter, Text, Toast, TouchableOpacity,
} from '../../../../app-components';
import { Table } from '../../../../app-components/table/Table';
import { getUser } from '../../../../AppServices';
import { MODELS, ROUTES } from '../../../../Lib/constants';
import { PharmacyDepartmentInventoryItemListUri } from '../../../../Queries/pharmacy';
import vars from '../../../../theme/vars';

const header = ({ queryId }) => ({
  leftActions: [(props) => {
    const user = getUser();
    const { employee: { department } = {} } = user;
    return (
      <PharmacyDepartmentInventoryToggleFilter
        model={MODELS.PHARMACY_DEPARTMENT_STOCKS}
        id={queryId || 'pharmacyDepartmentInventoryStocksDataList'}
        addOnFilter={{ department: { _id: { $in: department?.map(({ _id }) => _id) } } }}
        {...props}
      />
    );
  }],
});

const tableItems = ({ queryId }) => ({
  lg: {
    header: header({ queryId }),
    columns: [
      {
        type: 'selection',
      },
      {
        header: 'inventory.headers.itemId'.getLabel(),
        type: 'text',
        field: 'item.itemCode',
      },
      {
        header: 'inventory.headers.itemName'.getLabel(),
        type: 'text',
        field: 'item.name',
        sortable: 'name',
      },
      {
        header: 'inventory.headers.category'.getLabel(),
        type: 'text',
        value: ({ item: { item = {} } = {} }) => item?.category?.name,
      },
      {
        header: 'inventory.headers.subCategory'.getLabel(),
        type: 'text',
        value: ({ item: { item = {} } = {} }) => item?.subCategory?.name,
      },
      {
        header: 'inventory.headers.description'.getLabel(),
        type: 'text',
        field: 'item.description',
      },
      {
        header: 'inventory.headers.manufacturer'.getLabel(),
        type: 'text',
        value: ({ item: { item: { rateContract } = {} } = {} }) => rateContract?.map(
          (item) => item?.manufacturer.name,
        ).toString(),
        width: 150,
      },
      {
        header: 'inventory.headers.stockAvailable'.getLabel(),
        type: 'text',
        field: 'stockAvailable',
      },
      {
        header: 'inventory.headers.department'.getLabel(),
        type: 'text',
        field: 'department.name',
      },
      {
        header: 'inventory.headers.speciality'.getLabel(),
        type: 'text',
        field: 'speciality.name',
        visible: () => queryId,
      },
      {
        header: 'inventory.headers.status'.getLabel(),
        type: 'text',
        render: ({ item: { stockStatus } = {} }) => {
          let statusColor = vars.colors.warning;
          if (stockStatus === 'In-Stocks') {
            statusColor = vars.colors.primary.color2;
          } else if (stockStatus === 'Out-of-Stock') {
            statusColor = vars.colors.error;
          }
          return (
            <Text style={{ ...vars.headings.h11, color: statusColor }}>
              {stockStatus}
            </Text>
          );
        },
      },
      {
        header: 'Actions',
        type: 'text',
        render: () => (
          <TouchableOpacity>
            <Text style={{ color: vars.colors.secondary.color2 }}>
              View
            </Text>
          </TouchableOpacity>
        ),
        action: ({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.pharmacyViewDepartmentInventory.name,
            params: { item },
          },
        }),
      },
    ],
  },
});

const InventoryList = (props) => {
  const { queryId, hidePatientDispensing } = props;
  const user = getUser();
  const { employee: { department } = [] } = user;

  if (department && department.length && department[0]?._id) {
    return (
      <Table
        reloadEvent={`reload${MODELS.PHARMACY_DEPARTMENT_STOCKS}`}
      // eslint-disable-next-line max-len
        uri={PharmacyDepartmentInventoryItemListUri({ queryId, filter: { department: { _id: { $in: department?.map(({ _id }) => _id) } } } })}
        {...tableItems({ queryId })}
        action={({ item }) => ({
          type: 'link',
          link: {
            view: ROUTES.pharmacyViewDepartmentInventory.name,
            params: {
              item,
              hidePatientDispensing,
            },
          },
        })}
        {...props}
      />
    );
  }

  Toast.show({
    message: 'Error',
    description: 'inventory.messages.loginUserError'.getLabel(),
    type: 'error',
    position: 'top',
    direction: 'right',
    duration: 3000,
  });

  return null;
};

export default InventoryList;
